import {
  Component,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';
import { getContextByFolder, NavigationBarService } from '@shared/modules/main/navigation-bar/navigation-bar.service';
import { ContextEnum, ContextType, TagType } from '@shared/api/api-loop/models';
import { FolderNode } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';
import { DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { ConversationHeaderActionService } from '@shared/modules/conversations/components/conversation-header/conversation-header-action.service';
import { SmartContactsApiService } from '@shared/modules/contacts/shell/contacts-api-cache/smart-contacts-api.service';
import { DialogService } from '@shared/ui/kit/dialog/dialog.service';
import { AttachmentsListContentComponent } from '@shared/modules/files/components/attachments-list-content/attachments-list-content.component';
import { AttachmentsListContentModule } from '@shared/modules/files/components/attachments-list-content/attachments-list-content.module';
import { ChannelType } from '@shared/modules/comments/common/constants/channel-type';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';
import { CardChatModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import {
  KeyboardActionModeType,
  KeyboardActionType,
  KeyboardShortcut,
  keyboardShortcutsKeyMappings,
  shortcutByActionType
} from '@dta/shared/models/keyboard-shortcut.model';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  publishReplay,
  ReplaySubject,
  startWith,
  switchMap,
  take
} from 'rxjs';
import { filter, map, refCount } from 'rxjs/operators';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { MainMenuService } from '@dta/ui/components/menu/menu.service';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';
import { StaticSharedTagIds } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { ThreadHeaderDropdownOption } from '../../conversation-header.component';

@UntilDestroy()
@Component({
  selector: 'loop-conversation-header-dropdown',
  templateUrl: './conversation-header-dropdown.component.html',
  styleUrls: ['./conversation-header-dropdown.component.scss']
})
export class ConversationHeaderDropdownComponent implements OnDestroy {
  private readonly navigationBarService: NavigationBarService = inject(NavigationBarService);
  private readonly router: Router = inject(Router);
  private readonly menuService: MainMenuService = inject(MainMenuService);
  private readonly dialogService: DialogService = inject(DialogService);
  private readonly injector: Injector = inject(Injector);
  private readonly conversationHeaderActionService: ConversationHeaderActionService = inject(
    ConversationHeaderActionService
  );
  private readonly keyboardShortcutsListenerService: KeyboardShortcutsListenerService = inject(
    KeyboardShortcutsListenerService
  );
  private readonly smartContactsApiService: SmartContactsApiService = inject(SmartContactsApiService);

  protected readonly ContextEnum: typeof ContextEnum = ContextEnum;

  protected readonly conversation$: ReplaySubject<ConversationModel> = new ReplaySubject<ConversationModel>(1);

  @Input() set conversation(conversation: ConversationModel) {
    this.conversation$.next(conversation);
    this.localContactState$.next(null);
  }

  private readonly reducedContact$: ReplaySubject<ContactModel> = new ReplaySubject<ContactModel>(1);

  @Input() set contact(contact: ContactModel) {
    this.reducedContact$.next(contact);
  }

  @Output() selectionChange$: EventEmitter<string> = new EventEmitter();
  @ViewChild('dropdownTpl', { read: ViewContainerRef }) private dropdownViewContainerRef: ViewContainerRef;

  protected readonly dropdownSvg: string = require('shared/assets/img/svg/dropdown.svg');
  protected readonly archive22Svg: string = require('shared/assets/img/svg/newheader-archive.svg');
  protected readonly unarchiveSvg: string = require('shared/assets/img/svg/newheader-unarchive.svg');
  private readonly localContactState$: BehaviorSubject<ContactModel | null> = new BehaviorSubject<ContactModel | null>(
    null
  );

  protected isDarwin: boolean = checkIfOS(OperatingSystem.DARWIN);

  protected readonly isChatCard$: Observable<boolean> = this.conversation$.pipe(
    map(conversation => conversation.cardType === CardChatModel.type)
  );

  protected readonly context$: Observable<ContextType> = this.navigationBarService.selectedFolderChanged$.pipe(
    startWith(this.navigationBarService.folderSelected),
    map((folder: FolderNode) => {
      return getContextByFolder(folder);
    })
  );

  protected readonly sharedCardOptions$: Observable<{ text: string; action: () => void; icon: string }> =
    this.context$.pipe(
      map(context => {
        if (context.context === ContextEnum.FOLDER && context.folderId.includes(StaticSharedTagIds.SPAM_ID)) {
          return {
            text: 'Mark as not spam',
            action: () => this.selectionChange$.next(ThreadHeaderDropdownOption.MARK_AS_UNSPAM),
            icon: this.unarchiveSvg
          };
        }

        if (
          context.context === ContextEnum.INBOX ||
          (context.context === ContextEnum.FOLDER && context.folderId === 'assigned')
        ) {
          return {
            text: 'Archive',
            action: () => this.selectionChange$.next(ThreadHeaderDropdownOption.ARCHIVE),
            icon: this.unarchiveSvg
          };
        }

        return {
          text: 'Move to inbox',
          action: () => this.selectionChange$.next(ThreadHeaderDropdownOption.MOVE_TO_INBOX),
          icon: this.archive22Svg
        };
      })
    );

  protected readonly isChannelView: boolean = this.router.url.includes('channel');

  protected contact$: Observable<ContactModel> = this.reducedContact$.pipe(
    filter(contact => !!contact),
    switchMap(contact => this.smartContactsApiService.getSingle(contact.id))
  );

  protected readonly isFavorite$: Observable<{ isFavorite: boolean }> = this.localContactState$.pipe(
    filter(contact => !!contact),
    map(contact => {
      return {
        isFavorite: contact.hasTagType(TagType.FAVORITE)
      };
    })
  );

  protected readonly isDropdownVisible$: Observable<boolean> = this.conversation$.pipe(
    map(conversation => {
      return conversation.channelType !== ChannelType.loopSpam;
    }),
    publishReplay(1),
    refCount()
  );

  protected readonly dropdownOptions$: Observable<DropdownItem[]> = combineLatest([
    this.conversation$,
    this.context$,
    this.menuService.sidebarToggleObs
  ]).pipe(
    map(([conversation, context, isSideBarActive]) => {
      if (conversation.cardType === CardChatModel.type) {
        return [
          {
            title: conversation?.isSubscribed ? ThreadHeaderDropdownOption.UNFOLLOW : ThreadHeaderDropdownOption.FOLLOW,
            action: () =>
              this.selectionChange$.emit(
                conversation?.isSubscribed ? ThreadHeaderDropdownOption.UNFOLLOW : ThreadHeaderDropdownOption.FOLLOW
              )
          },
          ...(!this.isChannelView
            ? [
                {
                  title: conversation.isArchived
                    ? ThreadHeaderDropdownOption.MOVE_TO_INBOX
                    : ThreadHeaderDropdownOption.ARCHIVE,
                  action: () =>
                    this.selectionChange$.emit(
                      conversation.isArchived
                        ? ThreadHeaderDropdownOption.MOVE_TO_INBOX
                        : ThreadHeaderDropdownOption.ARCHIVE
                    )
                }
              ]
            : []),
          {
            title: conversation.isUnread
              ? ThreadHeaderDropdownOption.MARKASREAD
              : ThreadHeaderDropdownOption.MARKASUNREAD,
            shortcut: conversation.isUnread
              ? `${keyboardShortcutsKeyMappings.shiftKey} + I`
              : `${keyboardShortcutsKeyMappings.shiftKey} + U`,
            action: () =>
              this.selectionChange$.emit(
                conversation.isUnread ? ThreadHeaderDropdownOption.MARKASREAD : ThreadHeaderDropdownOption.MARKASUNREAD
              )
          }
        ];
      }
      return [
        ...this.getTriagingActions(context),
        ...this.getSideActions(conversation),
        ...this.getFolderActions(conversation),
        ...this.getOtherActions(conversation, { isSideBarActive: isSideBarActive })
      ];
    })
  );

  private getOtherActions(conversation: ConversationModel, options?: { isSideBarActive: boolean }): DropdownItem[] {
    return [
      {
        title: conversation.isSubscribed ? ThreadHeaderDropdownOption.UNFOLLOW : ThreadHeaderDropdownOption.FOLLOW,
        action: () =>
          this.selectionChange$.emit(
            conversation.isSubscribed ? ThreadHeaderDropdownOption.UNFOLLOW : ThreadHeaderDropdownOption.FOLLOW
          )
      },
      {
        title: options.isSideBarActive ? 'Expand email' : 'Collapse email',
        action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.EXPAND_CARD),
        shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.EXPAND_THREAD]).stringRepresentation
      },
      {
        title: ThreadHeaderDropdownOption.COPY_CONVERSATION_LINK,
        action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.COPY_CONVERSATION_LINK)
      },
      ...(conversation?.attachments?.length
        ? [
            {
              title: 'View attachments',
              action: () =>
                this.dialogService
                  .openDialog({
                    title$: of('Conversation attachments'),
                    isClosable: true,
                    componentData: {
                      component: AttachmentsListContentComponent,
                      module: AttachmentsListContentModule,
                      parentInjector: this.injector
                    },
                    componentParams: {
                      attachments: conversation.attachments?.map(
                        conversationAttachment => conversationAttachment.attachment
                      )
                    }
                  })
                  .pipe(take(1))
                  .subscribe()
            }
          ]
        : [])
    ];
  }

  private getFolderActions(conversation: ConversationModel): DropdownItem[] {
    return [
      {
        title: ThreadHeaderDropdownOption.MOVE_TO_FOLDER,
        shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.MOVE_TO_FOLDER]).stringRepresentation,
        action: () =>
          this.conversationHeaderActionService.openAddToFolder(
            [conversation.cardId],
            conversation.findGroupInShareList()?.id,
            this.dropdownViewContainerRef
          )
      },
      {
        title: ThreadHeaderDropdownOption.ADD_TAG,
        shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.TAG_CONVERSATION]).stringRepresentation,
        action: () =>
          this.conversationHeaderActionService.openLabelsDropdown([conversation], this.dropdownViewContainerRef)
      },
      {
        title: ThreadHeaderDropdownOption.MOVE_TO_TEAM,
        shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.MOVE_TO_TEAM]).stringRepresentation,
        action: () => this.conversationHeaderActionService.moveToTeam(conversation, this.dropdownViewContainerRef)
      },
      {
        isDelimiter: true
      } as DropdownItem
    ];
  }

  private getSideActions(conversation: ConversationModel): DropdownItem[] {
    const options = [];
    options.push(
      {
        title: conversation.isUnread ? ThreadHeaderDropdownOption.MARKASREAD : ThreadHeaderDropdownOption.MARKASUNREAD,
        shortcut: conversation.isUnread
          ? new KeyboardShortcut(shortcutByActionType[KeyboardActionType.MARK_AS_READ]).stringRepresentation
          : new KeyboardShortcut(shortcutByActionType[KeyboardActionType.MARK_AS_UNREAD]).stringRepresentation,
        action: () =>
          this.selectionChange$.emit(
            conversation.isUnread ? ThreadHeaderDropdownOption.MARKASREAD : ThreadHeaderDropdownOption.MARKASUNREAD
          )
      },
      {
        title: ThreadHeaderDropdownOption.SNOOZE,
        action: () =>
          this.conversationHeaderActionService.snoozeConversation(conversation, this.dropdownViewContainerRef)
      },
      {
        title: ThreadHeaderDropdownOption.ASSIGN,
        shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.ASSIGN_SOMEONE]).stringRepresentation,
        action: () =>
          this.conversationHeaderActionService.assignConversation(conversation, this.dropdownViewContainerRef)
      },
      {
        isDelimiter: true
      } as DropdownItem
    );
    return options;
  }

  toggleFavorite(isFavorite: boolean): void {
    const contact = this.localContactState$.getValue();
    let tag = new TagModel();
    tag.id = TagType.FAVORITE;
    tag.tagType = TagType.FAVORITE;
    if (isFavorite) {
      contact.removeTag(tag);
    } else {
      contact.addTag(tag);
    }

    this.localContactState$.next(contact);
    this.selectionChange$.next(
      isFavorite ? ThreadHeaderDropdownOption.UN_FAVORITE : ThreadHeaderDropdownOption.FAVORITE
    );
  }

  private getTriagingActions(context: ContextType): DropdownItem[] {
    const options = [];
    switch (context.context) {
      case ContextEnum.INBOX:
        options.push(
          {
            title: ThreadHeaderDropdownOption.ARCHIVE,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.ARCHIVE),
            shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.ARCHIVE]).stringRepresentation
          },
          {
            title: ThreadHeaderDropdownOption.DELETE,
            shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.DELETE]).stringRepresentation,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.DELETE)
          }
        );
        break;
      case ContextEnum.ARCHIVE:
        options.push(
          {
            title: ThreadHeaderDropdownOption.MOVE_TO_INBOX,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.ARCHIVE)
          },
          {
            title: ThreadHeaderDropdownOption.DELETE,
            shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.DELETE]).stringRepresentation,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.DELETE)
          }
        );
        break;
      case ContextEnum.DELETE:
        options.push(
          {
            title: ThreadHeaderDropdownOption.MOVE_TO_INBOX,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.MOVE_TO_INBOX)
          },
          {
            title: ThreadHeaderDropdownOption.ARCHIVE,
            shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.ARCHIVE]).stringRepresentation,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.ARCHIVE)
          }
        );
        break;
      case ContextEnum.FOLDER:
        options.push(
          {
            title: ThreadHeaderDropdownOption.MOVE_TO_INBOX,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.MOVE_TO_INBOX)
          },
          {
            title: ThreadHeaderDropdownOption.ARCHIVE,
            shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.ARCHIVE]).stringRepresentation,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.ARCHIVE)
          },
          {
            title: ThreadHeaderDropdownOption.DELETE,
            shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.DELETE]).stringRepresentation,
            action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.DELETE)
          }
        );
        break;
    }

    if (
      context.context !== ContextEnum.FOLDER &&
      !['Assigned', 'Drafts'].includes(context.folderId) &&
      this.isChannelView
    ) {
      options.push({
        title: ThreadHeaderDropdownOption.MARK_AS_SPAM,
        action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.MARK_AS_SPAM),
        shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.MARK_AS_SPAM]).stringRepresentation
      });
    }

    options.push(
      {
        title: ThreadHeaderDropdownOption.RESOLVE,
        shortcut: new KeyboardShortcut(shortcutByActionType[KeyboardActionType.RESOLVE]).stringRepresentation,
        action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.RESOLVE)
      },
      {
        title: ThreadHeaderDropdownOption.CLOSE,
        action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.CLOSE)
      }
    );

    if (context.context !== ContextEnum.ARCHIVE) {
      options.push({
        title: 'Close and archive',
        action: () => this.selectionChange$.emit(ThreadHeaderDropdownOption.CLOSE_AND_ARCHIVE)
      });
    }

    options.push({
      isDelimiter: true
    } as DropdownItem);
    return options;
  }

  setThreadMode(): void {
    this.keyboardShortcutsListenerService.setMode(KeyboardActionModeType.THREAD);
  }

  ngOnDestroy(): void {
    this.conversation$.complete();
    this.reducedContact$.complete();
    this.localContactState$.complete();
  }
}
