import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { AtomDirective } from '@shared/modules/color-scheme/atom/atom.directive';

@Component({
  selector: 'loop-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnInit {
  private readonly atomDirective: AtomDirective | null = inject(AtomDirective, {
    optional: true,
    host: true,
    self: true
  });

  @Input() name?: string;
  @Input() width: string = '100%';
  @Input() height!: string;

  @HostBinding('class.atomic-icon') get isUsingAtom(): boolean {
    return this.atomDirective !== null;
  }

  ngOnInit(): void {
    if (typeof this.height === 'undefined') {
      this.height = this.width;
    }
  }
}
