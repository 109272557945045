import * as _ from 'lodash';
import { SynchronizationMiddlewareService } from '../../../synchronization/synchronization-middleware/synchronization-middleware.service';
import { BaseModel } from '../../../../dta/shared/models-api-loop/base/base.model';
import { RetryModel } from '../../../../dta/shared/models-api-loop/retry.model';
import { Injectable } from '@angular/core';
import { SynchronizableModel } from '@shared/models/sync/synchronizable.model';

@Injectable()
export class BaseService {
  constructor(protected _syncMiddleware: SynchronizationMiddlewareService) {}

  protected enqueuePushSynchronization(forUserEmail: string, data: SynchronizableModel | SynchronizableModel[]) {
    this._syncMiddleware.enqueuePushSynchronization$.next({
      models: _.castArray(data),
      forUserEmail: forUserEmail,
    });
  }

  protected dequeuePushSynchronization(forUserEmail: string, data: BaseModel | BaseModel[]) {
    this._syncMiddleware.dequeuePushSynchronization$.next({
      models: data,
      forUserEmail: forUserEmail,
    });
  }

  protected enqueueRetrySynchronization(forUserEmail: string, data: RetryModel | RetryModel[]) {
    this._syncMiddleware.enqueueRetrySynchronization$.next({
      models: data,
      forUserEmail: forUserEmail,
    });
  }

  protected dequeueRetrySynchronization(forUserEmail: string, data: BaseModel | BaseModel[]) {
    this._syncMiddleware.dequeueRetrySynchronization$.next({
      data: data,
      forUserEmail: forUserEmail,
    });
  }
}
