import { Component, Input } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.html',
  styleUrls: ['./loader.scss'],
})
export class LoaderComponent {
  arrows = require('shared/assets/img/svg/sync.svg');

  @Input() text: string = '';
  @Input() fill: boolean = false;
  @Input() inverse: boolean = false;
  @Input() customClass: string = '';
  @Input() addPadding: boolean = true;
  @Input() style: 'circle' | 'arrows' | 'button-circle' = 'circle';
}
