import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedSubjects } from '../../../../shared/services/communication/shared-subjects/shared-subjects';
import { UserTokenInvalid } from '../../../../shared/services/communication/shared-subjects/shared-subjects-models';

@Injectable()
export class ReloginDialogService {
  public toggle: Subject<UserTokenInvalid> = new Subject();

  constructor() {}

  init() {
    this.subscribeToTokenInvalid();
  }

  private subscribeToTokenInvalid() {
    SharedSubjects._userTokenInvalid$.subscribe(data => {
      this.toggle.next(data);
    });
  }
}
