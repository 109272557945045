import { Pipe, PipeTransform } from '@angular/core';

/**
 * Wraps any function to pipe, so it can be executed as pure function in templates
 */
@Pipe({
  name: 'asPureArgumentFor'
})
export class AsPureArgumentForPipe implements PipeTransform {
  transform<A, R, ARGS extends unknown[]>(arg: A, fn: (arg: A, ...args: ARGS) => R, ...args: ARGS): R {
    return fn(arg, ...args);
  }
}
