import {
  createNgModule,
  Directive,
  Injector,
  Input,
  Type,
  ViewContainerRef,
  OnInit,
  EventEmitter,
  ComponentRef,
  Output
} from '@angular/core';
import { writeComponentRefChanges } from '@shared/utils/write-component-ref-changes';
@Directive({
  selector: '[loopDialogContent]'
})
export class BrowserDialogContentDirective<T> implements OnInit {
  @Input() loopDialogContent!: Type<T>;
  @Input() loopDialogContentModule!: Type<any>;
  @Input() loopDialogContentParams?: Partial<T>;
  @Input() loopDialogContentInjector?: Injector;
  @Output() componentRef: EventEmitter<ComponentRef<T>> = new EventEmitter<ComponentRef<T>>();

  constructor(
    public viewContainerRef: ViewContainerRef,
    private readonly injector: Injector
  ) {}

  ngOnInit(): void {
    const componentRef = this.viewContainerRef.createComponent(this.loopDialogContent, {
      ngModuleRef: createNgModule(this.loopDialogContentModule, this.loopDialogContentInjector ?? this.injector),
      injector: this.loopDialogContentInjector ?? this.injector
    });
    if (this.loopDialogContentParams) {
      writeComponentRefChanges(componentRef, this.loopDialogContentParams);
    }
    this.componentRef.emit(componentRef);
  }
}
