import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FileDownloadService } from './file-download.service';

@Injectable()
export class BrowserFileDownloadService extends FileDownloadService {
  downloadFromArrayBuffer(
    arrayBuffer: ArrayBuffer,
    options?: { fileName?: string; mimeType?: string }
  ): Observable<void> {
    const blob = new Blob([arrayBuffer]);
    const downloadLink = window.URL.createObjectURL(blob);

    const a = this.domDocument.createElement('a');
    a.href = downloadLink;
    a.download = options?.fileName ?? 'download';
    this.domDocument.body.appendChild(a);
    a.focus();
    a.click();
    this.domDocument.body.removeChild(a);
    return of(undefined);
  }
}
