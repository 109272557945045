/* tslint:disable */

/**
 * AccessScopeType
 */
export type AccessScopeType = 'All' | 'Settings' | 'Command' | 'Self' | 'Report';

export module AccessScopeType {
  export const ALL: AccessScopeType = 'All';
  export const SETTINGS: AccessScopeType = 'Settings';
  export const COMMAND: AccessScopeType = 'Command';
  export const SELF: AccessScopeType = 'Self';
  export const REPORT: AccessScopeType = 'Report';

  export function values(): AccessScopeType[] {
    return [ALL, SETTINGS, COMMAND, SELF, REPORT];
  }
}
