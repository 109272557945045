/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { AuthExchange } from '../models/auth-exchange';
import { Response } from '../models/response';
import { AuthMicrosoft } from '../models/auth-microsoft';
import { AuthGoogle } from '../models/auth-google';
import { UserEmailInfo } from '../models/user-email-info';
import { AuthCode } from '../models/auth-code';
import { AuthImapSmtp } from '../models/auth-imap-smtp';
import { AuthTest } from '../models/auth-test';
import { AuthIntegration } from '../models/auth-integration';
import { AuthRemoteImap } from '../models/auth-remote-imap';
import { AuthPassword } from '../models/auth-password';
import { AuthVerifyResponse } from '../models/auth-verify-response';

@Injectable()
export class AuthApiService extends BaseService {
  /**
   * @param params The `AuthApiService.Auth_CreateExchangeAuthParams` containing the following parameters:
   *
   * - `authExchange`: Exchange authorization
   *
   * - `loginPurpose`: login purpose enum
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateExchangeAuthResponse(
    params: AuthApiService.Auth_CreateExchangeAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthExchange>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/exchange`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreateExchangeAuth');

    // body, headers and query params
    __body = params.authExchange;
    if (params.loginPurpose != null) __params = __params.set('loginPurpose', params.loginPurpose.toString());
    if (params.isAddAccount != null) __params = __params.set('isAddAccount', params.isAddAccount.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthExchange;

          body = <AuthExchange>response.body;

          return <HttpResponse<AuthExchange>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreateExchangeAuthParams` containing the following parameters:
   *
   * - `authExchange`: Exchange authorization
   *
   * - `loginPurpose`: login purpose enum
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateExchangeAuth(
    params: AuthApiService.Auth_CreateExchangeAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthExchange> {
    const observable$ = this.Auth_CreateExchangeAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/exchange`;
      const url = this.rootUrl + `/api/v1/auth/exchange`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_MicrosoftProfileParams` containing the following parameters:
   *
   * - `authMicrosoft`: Microsoft authorization
   */
  Auth_MicrosoftProfileResponse(
    params: AuthApiService.Auth_MicrosoftProfileParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthMicrosoft>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/microsoftprofile`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_MicrosoftProfile');

    // body, headers and query params
    __body = params.authMicrosoft;

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthMicrosoft;

          body = <AuthMicrosoft>response.body;

          return <HttpResponse<AuthMicrosoft>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_MicrosoftProfileParams` containing the following parameters:
   *
   * - `authMicrosoft`: Microsoft authorization
   */
  Auth_MicrosoftProfile(
    params: AuthApiService.Auth_MicrosoftProfileParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthMicrosoft> {
    const observable$ = this.Auth_MicrosoftProfileResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/microsoftprofile`;
      const url = this.rootUrl + `/api/v1/auth/microsoftprofile`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_CreateMicrosoftAuthParams` containing the following parameters:
   *
   * - `authMicrosoft`: Microsoft authorization
   *
   * - `loginPurpose`: login purpose enum
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateMicrosoftAuthResponse(
    params: AuthApiService.Auth_CreateMicrosoftAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthMicrosoft>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/microsoft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreateMicrosoftAuth');

    // body, headers and query params
    __body = params.authMicrosoft;
    if (params.loginPurpose != null) __params = __params.set('loginPurpose', params.loginPurpose.toString());
    if (params.isAddAccount != null) __params = __params.set('isAddAccount', params.isAddAccount.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthMicrosoft;

          body = <AuthMicrosoft>response.body;

          return <HttpResponse<AuthMicrosoft>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreateMicrosoftAuthParams` containing the following parameters:
   *
   * - `authMicrosoft`: Microsoft authorization
   *
   * - `loginPurpose`: login purpose enum
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateMicrosoftAuth(
    params: AuthApiService.Auth_CreateMicrosoftAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthMicrosoft> {
    const observable$ = this.Auth_CreateMicrosoftAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/microsoft`;
      const url = this.rootUrl + `/api/v1/auth/microsoft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_CreateGmailAuthParams` containing the following parameters:
   *
   * - `authGoogle`: Google authorization
   *
   * - `loginPurpose`: login purpose enum
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateGmailAuthResponse(
    params: AuthApiService.Auth_CreateGmailAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthGoogle>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/gmail`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreateGmailAuth');

    // body, headers and query params
    __body = params.authGoogle;
    if (params.loginPurpose != null) __params = __params.set('loginPurpose', params.loginPurpose.toString());
    if (params.isAddAccount != null) __params = __params.set('isAddAccount', params.isAddAccount.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthGoogle;

          body = <AuthGoogle>response.body;

          return <HttpResponse<AuthGoogle>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreateGmailAuthParams` containing the following parameters:
   *
   * - `authGoogle`: Google authorization
   *
   * - `loginPurpose`: login purpose enum
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateGmailAuth(
    params: AuthApiService.Auth_CreateGmailAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthGoogle> {
    const observable$ = this.Auth_CreateGmailAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/gmail`;
      const url = this.rootUrl + `/api/v1/auth/gmail`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_ProfileGmailParams` containing the following parameters:
   *
   * - `authGoogle`: Google authorization
   */
  Auth_ProfileGmailResponse(
    params: AuthApiService.Auth_ProfileGmailParams,
    forUserEmail: string
  ): Observable<HttpResponse<UserEmailInfo>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/gmailprofile`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_ProfileGmail');

    // body, headers and query params
    __body = params.authGoogle;

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: UserEmailInfo;

          body = <UserEmailInfo>response.body;

          return <HttpResponse<UserEmailInfo>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_ProfileGmailParams` containing the following parameters:
   *
   * - `authGoogle`: Google authorization
   */
  Auth_ProfileGmail(
    params: AuthApiService.Auth_ProfileGmailParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<UserEmailInfo> {
    const observable$ = this.Auth_ProfileGmailResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/gmailprofile`;
      const url = this.rootUrl + `/api/v1/auth/gmailprofile`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_CreateTokenCodeAuthParams` containing the following parameters:
   *
   * - `authCode`: Code authorization
   *
   * - `otpCode`:
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateTokenCodeAuthResponse(
    params: AuthApiService.Auth_CreateTokenCodeAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthCode>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/tokenCode`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreateTokenCodeAuth');

    // body, headers and query params
    __body = params.authCode;
    if (params.otpCode != null) __params = __params.set('otpCode', params.otpCode.toString());
    if (params.isAddAccount != null) __params = __params.set('isAddAccount', params.isAddAccount.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthCode;

          body = <AuthCode>response.body;

          return <HttpResponse<AuthCode>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreateTokenCodeAuthParams` containing the following parameters:
   *
   * - `authCode`: Code authorization
   *
   * - `otpCode`:
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateTokenCodeAuth(
    params: AuthApiService.Auth_CreateTokenCodeAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthCode> {
    const observable$ = this.Auth_CreateTokenCodeAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/tokenCode`;
      const url = this.rootUrl + `/api/v1/auth/tokenCode`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_CreateImapAuthParams` containing the following parameters:
   *
   * - `authImapSmtp`: Exchange authorization
   *
   * - `loginPurpose`: Parameter set secret purpose
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateImapAuthResponse(
    params: AuthApiService.Auth_CreateImapAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthImapSmtp>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/imap`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreateImapAuth');

    // body, headers and query params
    __body = params.authImapSmtp;
    if (params.loginPurpose != null) __params = __params.set('loginPurpose', params.loginPurpose.toString());
    if (params.isAddAccount != null) __params = __params.set('isAddAccount', params.isAddAccount.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthImapSmtp;

          body = <AuthImapSmtp>response.body;

          return <HttpResponse<AuthImapSmtp>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreateImapAuthParams` containing the following parameters:
   *
   * - `authImapSmtp`: Exchange authorization
   *
   * - `loginPurpose`: Parameter set secret purpose
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateImapAuth(
    params: AuthApiService.Auth_CreateImapAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthImapSmtp> {
    const observable$ = this.Auth_CreateImapAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/imap`;
      const url = this.rootUrl + `/api/v1/auth/imap`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_CreateTestAuthParams` containing the following parameters:
   *
   * - `authTest`: Test authorization
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateTestAuthResponse(
    params: AuthApiService.Auth_CreateTestAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthTest>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/test`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreateTestAuth');

    // body, headers and query params
    __body = params.authTest;
    if (params.isAddAccount != null) __params = __params.set('isAddAccount', params.isAddAccount.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthTest;

          body = <AuthTest>response.body;

          return <HttpResponse<AuthTest>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreateTestAuthParams` containing the following parameters:
   *
   * - `authTest`: Test authorization
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateTestAuth(
    params: AuthApiService.Auth_CreateTestAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthTest> {
    const observable$ = this.Auth_CreateTestAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/test`;
      const url = this.rootUrl + `/api/v1/auth/test`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_CreateIntegrationAuthParams` containing the following parameters:
   *
   * - `authIntegration`: Integration authorization
   */
  Auth_CreateIntegrationAuthResponse(
    params: AuthApiService.Auth_CreateIntegrationAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthIntegration>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/integration`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreateIntegrationAuth');

    // body, headers and query params
    __body = params.authIntegration;

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthIntegration;

          body = <AuthIntegration>response.body;

          return <HttpResponse<AuthIntegration>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreateIntegrationAuthParams` containing the following parameters:
   *
   * - `authIntegration`: Integration authorization
   */
  Auth_CreateIntegrationAuth(
    params: AuthApiService.Auth_CreateIntegrationAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthIntegration> {
    const observable$ = this.Auth_CreateIntegrationAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/integration`;
      const url = this.rootUrl + `/api/v1/auth/integration`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_CreateRemoteImapAuthParams` containing the following parameters:
   *
   * - `authRemoteImap`: Remote Imap authorization
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateRemoteImapAuthResponse(
    params: AuthApiService.Auth_CreateRemoteImapAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthRemoteImap>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/remoteimap`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreateRemoteImapAuth');

    // body, headers and query params
    __body = params.authRemoteImap;
    if (params.isAddAccount != null) __params = __params.set('isAddAccount', params.isAddAccount.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthRemoteImap;

          body = <AuthRemoteImap>response.body;

          return <HttpResponse<AuthRemoteImap>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreateRemoteImapAuthParams` containing the following parameters:
   *
   * - `authRemoteImap`: Remote Imap authorization
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreateRemoteImapAuth(
    params: AuthApiService.Auth_CreateRemoteImapAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthRemoteImap> {
    const observable$ = this.Auth_CreateRemoteImapAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/remoteimap`;
      const url = this.rootUrl + `/api/v1/auth/remoteimap`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_CreatePasswordAuthParams` containing the following parameters:
   *
   * - `authPassword`: Integration authorization
   *
   * - `loginPurpose`: login purpose enum
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreatePasswordAuthResponse(
    params: AuthApiService.Auth_CreatePasswordAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthPassword>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/password`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_CreatePasswordAuth');

    // body, headers and query params
    __body = params.authPassword;
    if (params.loginPurpose != null) __params = __params.set('loginPurpose', params.loginPurpose.toString());
    if (params.isAddAccount != null) __params = __params.set('isAddAccount', params.isAddAccount.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthPassword;

          body = <AuthPassword>response.body;

          return <HttpResponse<AuthPassword>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_CreatePasswordAuthParams` containing the following parameters:
   *
   * - `authPassword`: Integration authorization
   *
   * - `loginPurpose`: login purpose enum
   *
   * - `isAddAccount`: Parameter whether or not auth was posted through add account action
   */
  Auth_CreatePasswordAuth(
    params: AuthApiService.Auth_CreatePasswordAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthPassword> {
    const observable$ = this.Auth_CreatePasswordAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/password`;
      const url = this.rootUrl + `/api/v1/auth/password`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_VerifyEmailAddressParams` containing the following parameters:
   *
   * - `verificationKey`: Verification key
   *
   * - `purpose`: Verification purpose
   *
   * - `emailAddress`: Email address to verify
   */
  Auth_VerifyEmailAddressResponse(
    params: AuthApiService.Auth_VerifyEmailAddressParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthVerifyResponse>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/verify`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_VerifyEmailAddress');

    // body, headers and query params
    if (params.verificationKey != null) __params = __params.set('verificationKey', params.verificationKey.toString());
    if (params.purpose != null) __params = __params.set('purpose', params.purpose.toString());
    if (params.emailAddress != null) __params = __params.set('emailAddress', params.emailAddress.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthVerifyResponse;

          body = <AuthVerifyResponse>response.body;

          return <HttpResponse<AuthVerifyResponse>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_VerifyEmailAddressParams` containing the following parameters:
   *
   * - `verificationKey`: Verification key
   *
   * - `purpose`: Verification purpose
   *
   * - `emailAddress`: Email address to verify
   */
  Auth_VerifyEmailAddress(
    params: AuthApiService.Auth_VerifyEmailAddressParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthVerifyResponse> {
    const observable$ = this.Auth_VerifyEmailAddressResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/verify`;
      const url = this.rootUrl + `/api/v1/auth/verify`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AuthApiService.Auth_SendVerifyEmailAddressParams` containing the following parameters:
   *
   * - `purpose`: Verification purpose
   *
   * - `emailAddress`: Email address to verify
   */
  Auth_SendVerifyEmailAddressResponse(
    params: AuthApiService.Auth_SendVerifyEmailAddressParams,
    forUserEmail: string
  ): Observable<HttpResponse<boolean>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/auth/sendverifyemail`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Auth_SendVerifyEmailAddress');

    // body, headers and query params
    if (params.purpose != null) __params = __params.set('purpose', params.purpose.toString());
    if (params.emailAddress != null) __params = __params.set('emailAddress', params.emailAddress.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: boolean;

          body = response.body == 'true';

          return <HttpResponse<boolean>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AuthApiService.Auth_SendVerifyEmailAddressParams` containing the following parameters:
   *
   * - `purpose`: Verification purpose
   *
   * - `emailAddress`: Email address to verify
   */
  Auth_SendVerifyEmailAddress(
    params: AuthApiService.Auth_SendVerifyEmailAddressParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<boolean> {
    const observable$ = this.Auth_SendVerifyEmailAddressResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/sendverifyemail`;
      const url = this.rootUrl + `/api/v1/auth/sendverifyemail`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module AuthApiService {
  /**
   * Parameters for Auth_CreateExchangeAuth
   */
  export type Auth_CreateExchangeAuthParams = {
    /**
     * Exchange authorization
     */
    authExchange: AuthExchange;

    /**
     * login purpose enum
     */
    loginPurpose?: string;

    /**
     * Parameter whether or not auth was posted through add account action
     */
    isAddAccount?: boolean;
  };

  /**
   * Parameters for Auth_MicrosoftProfile
   */
  export type Auth_MicrosoftProfileParams = {
    /**
     * Microsoft authorization
     */
    authMicrosoft: AuthMicrosoft;
  };

  /**
   * Parameters for Auth_CreateMicrosoftAuth
   */
  export type Auth_CreateMicrosoftAuthParams = {
    /**
     * Microsoft authorization
     */
    authMicrosoft: AuthMicrosoft;

    /**
     * login purpose enum
     */
    loginPurpose?: string;

    /**
     * Parameter whether or not auth was posted through add account action
     */
    isAddAccount?: boolean;
  };

  /**
   * Parameters for Auth_CreateGmailAuth
   */
  export type Auth_CreateGmailAuthParams = {
    /**
     * Google authorization
     */
    authGoogle: AuthGoogle;

    /**
     * login purpose enum
     */
    loginPurpose?: string;

    /**
     * Parameter whether or not auth was posted through add account action
     */
    isAddAccount?: boolean;
  };

  /**
   * Parameters for Auth_ProfileGmail
   */
  export type Auth_ProfileGmailParams = {
    /**
     * Google authorization
     */
    authGoogle: AuthGoogle;
  };

  /**
   * Parameters for Auth_CreateTokenCodeAuth
   */
  export type Auth_CreateTokenCodeAuthParams = {
    /**
     * Code authorization
     */
    authCode: AuthCode;

    otpCode?: string;

    /**
     * Parameter whether or not auth was posted through add account action
     */
    isAddAccount?: boolean;
  };

  /**
   * Parameters for Auth_CreateImapAuth
   */
  export type Auth_CreateImapAuthParams = {
    /**
     * Exchange authorization
     */
    authImapSmtp: AuthImapSmtp;

    /**
     * Parameter set secret purpose
     */
    loginPurpose?: string;

    /**
     * Parameter whether or not auth was posted through add account action
     */
    isAddAccount?: boolean;
  };

  /**
   * Parameters for Auth_CreateTestAuth
   */
  export type Auth_CreateTestAuthParams = {
    /**
     * Test authorization
     */
    authTest: AuthTest;

    /**
     * Parameter whether or not auth was posted through add account action
     */
    isAddAccount?: boolean;
  };

  /**
   * Parameters for Auth_CreateIntegrationAuth
   */
  export type Auth_CreateIntegrationAuthParams = {
    /**
     * Integration authorization
     */
    authIntegration: AuthIntegration;
  };

  /**
   * Parameters for Auth_CreateRemoteImapAuth
   */
  export type Auth_CreateRemoteImapAuthParams = {
    /**
     * Remote Imap authorization
     */
    authRemoteImap: AuthRemoteImap;

    /**
     * Parameter whether or not auth was posted through add account action
     */
    isAddAccount?: boolean;
  };

  /**
   * Parameters for Auth_CreatePasswordAuth
   */
  export type Auth_CreatePasswordAuthParams = {
    /**
     * Integration authorization
     */
    authPassword: AuthPassword;

    /**
     * login purpose enum
     */
    loginPurpose?: string;

    /**
     * Parameter whether or not auth was posted through add account action
     */
    isAddAccount?: boolean;
  };

  /**
   * Parameters for Auth_VerifyEmailAddress
   */
  export type Auth_VerifyEmailAddressParams = {
    /**
     * Verification key
     */
    verificationKey?: string;

    /**
     * Verification purpose
     */
    purpose?: string;

    /**
     * Email address to verify
     */
    emailAddress?: string;
  };

  /**
   * Parameters for Auth_SendVerifyEmailAddress
   */
  export type Auth_SendVerifyEmailAddressParams = {
    /**
     * Verification purpose
     */
    purpose?: string;

    /**
     * Email address to verify
     */
    emailAddress?: string;
  };
}
