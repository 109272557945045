/* tslint:disable */

/**
 * Threading mode for syncing
 */
export type ThreadingEnum = 'Recipient' | 'Standard';

export module ThreadingEnum {
  export const RECIPIENT: ThreadingEnum = 'Recipient';
  export const STANDARD: ThreadingEnum = 'Standard';

  export function values(): ThreadingEnum[] {
    return [RECIPIENT, STANDARD];
  }
}
