import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContextDropdownDirective } from '@shared/ui/dropdown/directives/context-dropdown.directive';
import { ClickDropdownDirective } from './directives/click-dropdown.directive';

@NgModule({
  declarations: [ClickDropdownDirective, ContextDropdownDirective],
  exports: [ClickDropdownDirective, ContextDropdownDirective],
  imports: [CommonModule]
})
export class DropdownModule {}
