import { SettingsService } from '@shared/services/settings/settings.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { PushSyncDaoService } from '@shared/database/dao/push-sync/push-sync-dao.service';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { DataServiceShared } from '@shared/services/data/data.service';
import { ElectronService } from '@shared/services/electron/electron';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { FlushDatabaseService } from '@shared/services/flush-db/flush-db.service';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { WatchdogService } from '@shared/services/watchdog/watchdog.service';
import { PushSynchronizationModuleService } from '@shared/synchronization/push-synchronization/push-synchronization.module';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { SynchronizationStatusService } from '@shared/synchronization/synchronization-status.service';
import { SynchronizationService } from '@shared/synchronization/synchronization.service';
import { SynchronizationManagerServiceWeb } from './synchronization-manager.service.web';
import { Time } from '@dta/shared/utils/common-utils';

export class SynchronizationServiceWeb extends SynchronizationService {
  constructor(
    protected _userEmail: string,
    protected _watchdogService: WatchdogService,
    protected _storageService: StorageService,
    protected _fileStorageService: FileStorageService,
    protected _syncStatusService: SynchronizationStatusService,
    protected _notificationsService: NotificationsService,
    protected _trackingService: TrackingService,
    protected _dataService: DataServiceShared,
    protected _apiService: ApiService,
    protected _electronService: ElectronService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _settingsService: SettingsService,
    protected _pushSynchronizationModuleService: PushSynchronizationModuleService,
    protected _synchronizationMiddlewareService: SynchronizationMiddlewareService,
    protected _flushDatabaseService: FlushDatabaseService,
    protected _databaseFactory: DatabaseFactory,
    protected _time: Time,
    private _pushSyncDaoService: PushSyncDaoService,
  ) {
    super(
      _userEmail,
      _watchdogService,
      _storageService,
      _fileStorageService,
      _syncStatusService,
      _notificationsService,
      _trackingService,
      _dataService,
      _apiService,
      _electronService,
      _sharedUserManagerService,
      _settingsService,
      _pushSynchronizationModuleService,
      _synchronizationMiddlewareService,
      _flushDatabaseService,
      _databaseFactory,
      _time,
    );
  }

  get constructorName(): string {
    return 'SynchronizationServiceWeb';
  }

  protected initSynchronizationManagerService() {
    this._syncManagerService = new SynchronizationManagerServiceWeb(
      this._userEmail,
      this._sharedUserManagerService,
      this._storageService,
      this._eventHubService,
      this._syncStatusService,
      this._trackingService,
      this._notificationsService,
      this._dataService,
      this._apiService,
      this._fileStorageService,
      this._pushSynchronizationModuleService,
      this._electronService,
      this._settingsService,
      this._synchronizationMiddlewareService,
      this._flushDatabaseService,
      this._db,
      this._time,
      this._pushSyncDaoService,
    );

    this._syncManagerService.init();
  }
}
