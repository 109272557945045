import * as _ from 'lodash';
import { BaseModel } from './base/base.model';
import { CollectionName } from '@shared/models/constants/collection.names';
import { ListOfResourcesOfTag, ListOfTags, ResourceBase, Tag, TagLabel, TimedTag } from '@shared/api/api-loop/models';
import { TagType } from '@shared/api/api-loop/models/tag-type';

export class TagModel extends BaseModel implements Tag {
  static collectionName: CollectionName = CollectionName.Tag;
  static type: string = 'Tag';

  readonly $type: string = TagModel.type;

  tagType: string;

  _ui?: TagViewData;

  static defaultBoards: string[] = [TagType.SENT, TagType.ARCHIVE, TagType.DELETED];

  constructor(data?: Tag) {
    super(data);
  }

  static create(doc: Tag): TagModel {
    switch (doc.$type) {
      case TagModel.type:
        return new TagModel(doc);
      case TagLabelModel.type:
        return new TagLabelModel(doc);
      case TimedTagModel.type:
        return new TimedTagModel(doc);
      default:
        break;
    }
  }

  static createList(docs: Tag[]): TagModel[] {
    return _.map(docs, (doc: Tag) => this.create(doc));
  }

  static buildSystemTag(type: TagType): TagModel {
    return new TagModel({
      $type: TagModel.type,
      id: type,
      tagType: type,
    });
  }
}

export class TimedTagModel extends TagModel implements TimedTag {
  static type: string = 'TimedTag';
  readonly $type: string = TimedTagModel.type;

  dueDate: string;

  constructor(data?: TimedTag) {
    super(data);
  }

  static buildSnoozeTag(dueDateUTC: string): TimedTagModel {
    return new TimedTagModel({
      $type: TimedTagModel.type,
      id: TagType.SNOOZE,
      tagType: TagType.SNOOZE,
      dueDate: dueDateUTC,
    });
  }
}

export class TagLabelModel extends TagModel implements TagLabel {
  static type: string = 'TagLabel';
  readonly $type: string = TagLabelModel.type;

  deleted?: boolean;
  parentTagId?: string;
  color?: string;

  // This is DTA property used for determining which shared
  // tag is disabled in filter view. It will be removed on reduce and will
  // not be persisted
  disabled?: boolean;

  constructor(data?: TagLabel) {
    super(data);
  }
}

export interface TagViewData {
  indentationLevel: number;
  displayName: string;
}

export class ListOfTagsModel extends BaseModel implements ListOfTags {
  static type: string = 'ListOfTags';

  readonly $type: string = ListOfTagsModel.type;

  /**
   * List of tags for resource
   */
  tags?: ListOfResourcesOfTag;
  /**
   * Resource the tags are set for
   */
  parent?: ResourceBase;

  _ex?: TagListExtraData;

  constructor(data?: ListOfTags) {
    super(data);
  }

  static create(doc: ListOfTags, areSharedTags: boolean = false): ListOfTagsModel {
    let model = new ListOfTagsModel(doc);

    if (!model._ex) {
      let ex: TagListExtraData = {
        areSharedTags: areSharedTags,
      };
      model._ex = ex;
    }

    return model;
  }

  static createList(docs: ListOfTags[], areSharedTags: boolean = false): ListOfTagsModel[] {
    return _.map(docs, (doc: ListOfTags) => this.create(doc, areSharedTags));
  }

  static buildFromParentAndTags(parent: ResourceBase, tags?: Tag[], revision: string = '0'): ListOfTagsModel {
    return new ListOfTagsModel({
      $type: ListOfTagsModel.type,
      parent: {
        $type: parent.$type,
        id: parent.id,
      },
      tags: TagModel.createListOfResources(tags || []),
      revision: revision, // Revision 0 is valid only if tags will be set for the first time
    });
  }
}

export interface TagListExtraData {
  areSharedTags: boolean;
}
