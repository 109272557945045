import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { SharedTagDataAccessModule } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/shared-tag-data-access.module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';
import { AddToFolderDropdownComponent } from './add-to-folder-dropdown.component';

@NgModule({
  declarations: [AddToFolderDropdownComponent],
  imports: [
    CommonModule,
    TextControlModule,
    FormsModule,
    SharedTagDataAccessModule,
    GraphicsModule,
    IconModule,
    ContactModule,
  ],
  exports: [AddToFolderDropdownComponent],
})
export class AddToFolderDropdownModule {}
