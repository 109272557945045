/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { ListOfResourcesOfConversation } from '../models/list-of-resources-of-conversation';
import { Response } from '../models/response';
import { CommentMail } from '../models/comment-mail';

@Injectable()
export class SpamApiService extends BaseService {
  /**
   * @param params The `SpamApiService.Spam_GetSpamMessagesParams` containing the following parameters:
   *
   * - `pageSize`: page size
   *
   * - `historyId`: historyId - for paging purpose
   *
   * - `groupId`: groupId - it should be personal or shared inbox
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Spam_GetSpamMessagesResponse(
    params: SpamApiService.Spam_GetSpamMessagesParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfConversation>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/spam`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Spam_GetSpamMessages');

    // body, headers and query params
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.historyId != null) __params = __params.set('historyId', params.historyId.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfConversation;

          body = <ListOfResourcesOfConversation>response.body;

          return <HttpResponse<ListOfResourcesOfConversation>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SpamApiService.Spam_GetSpamMessagesParams` containing the following parameters:
   *
   * - `pageSize`: page size
   *
   * - `historyId`: historyId - for paging purpose
   *
   * - `groupId`: groupId - it should be personal or shared inbox
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Spam_GetSpamMessages(
    params: SpamApiService.Spam_GetSpamMessagesParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfConversation> {
    const observable$ = this.Spam_GetSpamMessagesResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/spam`;
      const url = this.rootUrl + `/api/v1/spam`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SpamApiService.Spam_UnSpamMessageParams` containing the following parameters:
   *
   * - `messageId`: messageId
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Spam_UnSpamMessageResponse(
    params: SpamApiService.Spam_UnSpamMessageParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/spam/notspam`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Spam_UnSpamMessage');

    // body, headers and query params
    if (params.messageId != null) __params = __params.set('messageId', params.messageId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SpamApiService.Spam_UnSpamMessageParams` containing the following parameters:
   *
   * - `messageId`: messageId
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Spam_UnSpamMessage(
    params: SpamApiService.Spam_UnSpamMessageParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Spam_UnSpamMessageResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/spam/notspam`;
      const url = this.rootUrl + `/api/v1/spam/notspam`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SpamApiService.Spam_GetSpamDetailMessageParams` containing the following parameters:
   *
   * - `messageId`: messageId
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Spam_GetSpamDetailMessageResponse(
    params: SpamApiService.Spam_GetSpamDetailMessageParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentMail>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/spam/message`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Spam_GetSpamDetailMessage');

    // body, headers and query params
    if (params.messageId != null) __params = __params.set('messageId', params.messageId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentMail;

          body = <CommentMail>response.body;

          return <HttpResponse<CommentMail>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SpamApiService.Spam_GetSpamDetailMessageParams` containing the following parameters:
   *
   * - `messageId`: messageId
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Spam_GetSpamDetailMessage(
    params: SpamApiService.Spam_GetSpamDetailMessageParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentMail> {
    const observable$ = this.Spam_GetSpamDetailMessageResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/spam/message`;
      const url = this.rootUrl + `/api/v1/spam/message`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module SpamApiService {
  /**
   * Parameters for Spam_GetSpamMessages
   */
  export type Spam_GetSpamMessagesParams = {
    /**
     * page size
     */
    pageSize?: number;

    /**
     * historyId - for paging purpose
     */
    historyId?: string;

    /**
     * groupId - it should be personal or shared inbox
     */
    groupId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Spam_UnSpamMessage
   */
  export type Spam_UnSpamMessageParams = {
    /**
     * messageId
     */
    messageId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Spam_GetSpamDetailMessage
   */
  export type Spam_GetSpamDetailMessageParams = {
    /**
     * messageId
     */
    messageId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
