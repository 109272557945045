import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Directive } from '@angular/core';
import { catchError, Observable, of, Subject, Subscription, tap } from 'rxjs';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ChannelSetupService, SetupType } from '@shared/services/data/channel-inbox-setup/channel-setup.service';
import {
  PersonalInboxSetupData,
  PersonalSharedInboxSetupData,
  SharedInboxSetupData,
  TeamSetupData,
} from '@shared/services/data/channel-inbox-setup/channel-setup.service.interface';
import { User } from '@shared/api/api-loop/models';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { CreateChannelFormDialogService } from '@shared/services/create-channel-form-dialog/create-channel-form-dialog.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { TrackingConstants } from '@dta/shared/services/tracking/tracking.constants';
import { RouterService } from '@dta/ui/services/router/router.service';
import { TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';
import { AppStateService } from '@shared/services/data/app-state/app-state.service';
import { UserSwitch } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { Router } from '@angular/router';
import { ConfirmDialogService } from '@shared/modules/common/confirm-dialog/confirm-dialog.service';

export interface CreateChannelTexts {
  namePlaceholder: string;
  nameLabel: string;
  descriptionPlaceholder: string;
  overviewSubtitle: string;
  membersNotificationTitle?: string;
  membersNotificationContent?: string;
  successTitle: string;
  successCtaCopy: string;
  channelNotificationTitle?: string;
  channelNotificationContent?: string;
  membersNotificationCtaUrl?: string;
  membersNotificationCtaCopy?: string;
}

@Directive()
export abstract class CreateChannelBaseComponent {
  SetupStep = SetupStep;
  SetupType = SetupType;
  TooltipPlacement = TooltipPlacement;

  ////////
  // Svgs
  ////////
  googleSvg = require('shared/assets/img/svg/google_logo_big.svg');
  imapSvg = require('shared/assets/img/svg/imap_logo_big.svg');
  microsoftSvg = require('shared/assets/img/svg/microsoft_logo_big_round.svg');
  addMorePeopleSvg = require('shared/assets/img/svg/add-more-people-v2.svg');
  backSvg = require('shared/assets/img/svg/back.svg');
  trashSvg = require('shared/assets/img/svg/trash-22-px.svg');

  //////////////
  // Properties
  //////////////
  userQuery: string;
  isActionInProgress: boolean;
  currentActionErrorMessage: string;
  currentUserEmail: string;
  createdChannel: ContactModel;
  query$: Subject<string> = new Subject();
  currentIndexEvent: Subject<string> = new Subject();

  abstract textBank: CreateChannelTexts;
  abstract step: number;
  abstract data: SharedInboxSetupData | TeamSetupData | PersonalSharedInboxSetupData;
  abstract steps: Step[];
  abstract trackingLocation: string;
  protected currentAutosuggestItem: User;
  abstract setupType: SetupType;

  /////////////////
  // Subscriptions
  /////////////////
  protected currentUserSub: Subscription;

  constructor(
    protected _appStateService: AppStateService,
    protected _changeDetection: ChangeDetectorRef,
    protected _channelSetupService: ChannelSetupService,
    protected _userManagerService: UserManagerService,
    protected _createChanelFormDialogService: CreateChannelFormDialogService,
    protected _settingsService: SettingsService,
    protected _trackingService: TrackingService,
    protected _routerService: RouterService,
    protected _confirmDialogService: ConfirmDialogService,
    protected _router: Router,
  ) {}

  abstract get backButtonText(): string;
  abstract get nextButtonText(): string;
  abstract get additionalTrackingProperties(): Object;
  abstract nextStep(): void;
  protected abstract setStepFromData(permissionDeniedReason?: string): void;
  protected abstract getNewDataModel(): SharedInboxSetupData | TeamSetupData;
  protected abstract persistData(): void;
  protected abstract navigateToSettings(): void;
  protected abstract getDataFromRoute(): void;
  protected abstract setupServiceCreate(): Observable<ContactModel>;

  get offline(): boolean {
    return !this._appStateService.isConnectionActive;
  }

  ngOnInit() {
    this.subscribeToCurrentUser();
    this.getDataFromRoute();
  }

  ngOnDestroy() {}

  ////////////////
  // View methods
  ////////////////
  create(stepOnError?: number) {
    this.isActionInProgress = true;
    this.currentActionErrorMessage = undefined;

    this.setupServiceCreate()
      .pipe(
        tap((data: ContactModel) => {
          this.isActionInProgress = false;
          this.createdChannel = data;
          this.triggerChangeDetection();
        }),
        catchError((err: HttpErrorResponse) => {
          this.currentActionErrorMessage = this.processBeError(err.error.message);
          this.isActionInProgress = false;
          if (stepOnError !== undefined) {
            this.step = stepOnError;
          }
          return of(undefined);
        }),
      )
      .subscribe();
  }

  onInputKeyEvent($event: KeyboardEvent) {
    switch ($event.key) {
      case 'ArrowUp':
        this.currentIndexEvent.next('UP');
        break;
      case 'ArrowDown':
        this.currentIndexEvent.next('DOWN');
        break;
      case 'Tab':
      case 'Enter':
        this.addMember(this.currentAutosuggestItem);
        break;
      default:
        break;
    }
  }

  addMember(user: User) {
    if (!user) {
      return;
    }
    if (!this.data.members) {
      this.data.members = [];
    }

    this.data.members.push(user);
    this.persistData();
    this.userQuery = undefined;
    this.triggerChangeDetection();

    this._trackingService.trackUserClick(
      this.currentUserEmail,
      this.trackingLocation,
      'AddMember',
      this.additionalTrackingProperties,
    );
  }

  removeMember(user: User) {
    if (!user) {
      return;
    }

    this.data.members = this.data.members.filter(member => member.email !== user.email);

    this.persistData();
    this.triggerChangeDetection();
  }

  selectedItemEvent(user: User) {
    this.currentAutosuggestItem = user;
  }

  goBack() {
    this._trackingService.trackUserClick(
      this.currentUserEmail,
      this.trackingLocation,
      this.backButtonText,
      this.additionalTrackingProperties,
    );

    if (this.step === 0) {
      this.navigateToSettings();
      return;
    }
    if (this.step === 1 || this.step === 2) {
      this.data = _.pick(this.data, ['name', 'description', 'state']) as
        | SharedInboxSetupData
        | TeamSetupData
        | PersonalSharedInboxSetupData;
      this.persistData();

      this.stepStatusToIdle();
      this.step = (this.step - 1) as 0 | 1;
    }
  }

  ///////////////////
  // Private methods
  ///////////////////
  private processBeError(err: string): string {
    if (_.isEmpty(err)) {
      return err;
    }

    let _err = err.toLowerCase();

    if (_err.includes('duplicate name')) {
      return 'This name is already taken.';
    }

    return err;
  }

  protected resetState() {
    this.step = 0;
    this.data = this.getNewDataModel();
    this.trackView();
  }

  protected trackView() {
    this._trackingService.track(
      this.currentUserEmail,
      TrackingConstants.desktopSetup,
      this.additionalTrackingProperties,
    );
  }

  protected triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }

  protected stepStatusToIdle() {
    this.currentActionErrorMessage = undefined;
  }

  ////////////////
  // Subscribers
  ////////////////
  protected subscribeToCurrentUser() {
    this.currentUserSub?.unsubscribe();
    this.currentUserSub = this._userManagerService.userSwitch$
      .pipe(
        tap((data: UserSwitch) => {
          this.currentUserEmail = data.newUserEmail;
        }),
      )
      .subscribe();
  }
}

export enum SetupStep {
  Overview = 'Overview',
  Channels = 'Connect',
  Members = 'Members',
  CreatingInbox = 'CreatingInbox',
}

export interface Step {
  id: string;
  title: string;
  hidden?: boolean;
}
