import { inject, Injectable } from '@angular/core';
import { ElectronService } from '@shared/services/electron/electron';
import { SingleConversationCollection } from '@dta/ui/collections/conversations/single-conversation.collection';
import { Observable } from 'rxjs';
import { v1 } from 'uuid';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ObserverResponse } from '@dta/ui/collections/collection-subscriber.service';
import * as _ from 'lodash';
import { CardService } from '@shared/services/data/card/card.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Injectable()
export class DetailViewConversationSubscriberService {
  protected _singleConversationCollectionService: SingleConversationCollection = inject(SingleConversationCollection);
  protected _electronService: ElectronService = inject(ElectronService);
  protected _cardService: CardService = inject(CardService);
  protected _commentService: CommentService = inject(CommentService);
  protected _userManagerService: UserManagerService = inject(UserManagerService);

  get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  public registerConversationObservable(conversationId: string): Observable<ConversationModel> {
    return this._singleConversationCollectionService
      .registerCollection({
        offset: 0,
        size: 1,
        conversationId: conversationId,
        sessionId: v1(),
      })
      .pipe(
        mergeMap((collectionKey: string) => {
          return this._singleConversationCollectionService.registerObserver(collectionKey);
        }),
        map((response: ObserverResponse<ConversationModel>) => {
          return response.models;
        }),
        filter((conversations: ConversationModel[]) => !_.isEmpty(conversations)),
        map((conversations: ConversationModel[]) => _.first(conversations)),
      );
  }

  public registerConversation(conversationId: string): Observable<ConversationModel> {
    return this.registerConversationObservable(conversationId);
  }
}
