import { ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';

/**
 * File includes list of commonly used CDK position pairs.
 * Inspired by https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/core/overlay/overlay-position.ts
 *
 * Feel free to add more positions when desired.
 */

export const enum CdkPosition {
  'left' = 'left',
  'right' = 'right',
}
export const enum CdkPositionVariant {
  'top' = 'top',
  'bottom' = 'bottom',
}

export const CDK_POSITIONS: Record<CdkPosition, Record<CdkPositionVariant, ConnectedPosition>> = {
  [CdkPosition.left]: {
    [CdkPositionVariant.bottom]: new ConnectionPositionPair(
      { originX: 'start', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'top' },
      undefined,
      4,
      'transform-origin--top-left',
    ),
    [CdkPositionVariant.top]: new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' },
      undefined,
      -4,
      'transform-origin--bottom-left',
    ),
  },

  [CdkPosition.right]: {
    [CdkPositionVariant.bottom]: new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' },
      undefined,
      4,
      'transform-origin--top-right',
    ),

    [CdkPositionVariant.top]: new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'end', overlayY: 'bottom' },
      undefined,
      -4,
      'transform-origin--bottom-right',
    ),
  },
};
