import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { BaseModel } from '../../models-api-loop/base/base.model';
import { SharedSubjects } from '../../../../shared/services/communication/shared-subjects/shared-subjects';
import {
  PublishEvent,
  PublishEventType,
} from '../../../../shared/services/communication/shared-subjects/shared-subjects-models';
import { from, Observable } from 'rxjs';
import { filter, flatMap, map, toArray } from 'rxjs/operators';
import { StateUpdates } from '../../models/state-updates';

@Injectable()
// Class for broadcasting publish events for models that extends BaseModel
export class PublisherService {
  static publishStateUpdates(forUserEmail: string, stateUpdates: StateUpdates) {
    PublisherService.publishEvent(forUserEmail, stateUpdates.all);
    PublisherService.publishEvent(forUserEmail, stateUpdates.remove, PublishEventType.Remove);
    PublisherService.publishEvent(forUserEmail, stateUpdates.purge, PublishEventType.Purge);
  }

  static publishEvent(
    forUserEmail: string,
    data: BaseModel | BaseModel[],
    eventType: PublishEventType = PublishEventType.Upsert,
    broadcast: boolean = true,
  ) {
    if (!data || _.isEmpty(data)) {
      return;
    }

    let publishEvent = new PublishEvent();
    publishEvent.forUserEmail = forUserEmail;
    publishEvent.models = [].concat(data);
    publishEvent.eventType = eventType;

    SharedSubjects._publishedEvents$.next(publishEvent, broadcast);
  }

  static publishedEventsForUser(
    forUserEmail: string,
    eventType: PublishEventType = PublishEventType.Upsert,
  ): Observable<PublishEvent> {
    return SharedSubjects._publishedEvents$.forUserEmail(forUserEmail).pipe(
      filter((event: PublishEvent) => {
        return event && event.models && event.models.length > 0 && event.eventType === eventType;
      }),
    );
  }

  static getModelsForUser(
    forUserEmail: string,
    eventType: PublishEventType = PublishEventType.Upsert,
  ): Observable<BaseModel[]> {
    return PublisherService.publishedEventsForUser(forUserEmail, eventType).pipe(
      map((event: PublishEvent) => {
        return event.models;
      }),
    );
  }

  static getModelsForUserAndModelType(
    forUserEmail: string,
    modelTypes: (typeof BaseModel)[],
    eventType: PublishEventType = PublishEventType.Upsert,
  ): Observable<BaseModel[]> {
    return PublisherService.getModelsForUser(forUserEmail, eventType).pipe(
      // Filter in separate pipe because "_publishedEvents$" is hot observable
      flatMap((models: BaseModel[]) => {
        return from(models).pipe(
          filter((model: BaseModel) => {
            return _.some(modelTypes, modelType => model instanceof modelType);
          }),
          toArray(),
          /**
           * Don't emit empty list
           */
          filter((models: BaseModel[]) => {
            return !_.isEmpty(models);
          }),
        );
      }),
    );
  }
}
