import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { TrackingService } from './tracking.service';

@Directive({
  selector: '[trackUserClick]',
})
export class TrackUserClickDirective implements OnInit {
  @Input() trackUserClick: TrackingProperties;

  constructor(private _trackingService: TrackingService) {}

  @HostListener('click', ['$event']) onClick($event) {
    this._trackingService.trackUserClick(
      this.trackUserClick.forUserEmail,
      this.trackUserClick.location,
      this.trackUserClick.action,
      {
        buttonClass: $event?.currentTarget?.className,
        buttonId: $event?.currentTarget?.id,
        ...(this.trackUserClick.additionalProperties || {}),
      },
    );
  }

  ngOnInit() {}
}

interface TrackingProperties {
  forUserEmail: string;
  location: string;
  action: string;
  additionalProperties?: Object;
}
