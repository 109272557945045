import { Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownActionItem, DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { EnumObjectValue } from '@shared/utils/common/types';
import { LOOP_DYNAMIC_COMPONENT_NAME } from '@shared/modules/loop/components/loop-dynamic-components';

export const ICON_SUBMIT_BUTTON = 'ICON_SUBMIT_BUTTON' as const;

export type OpenDialogOptions<C> = OverlayDialogOptions<C> | NewWindowDialogOptions;

export interface OverlayDialogOptions<C> extends BaseDialogOptions {
  componentData?: {
    component: Type<C>;
    module: Type<any>;
    parentInjector?: Injector;
  };
  componentParams?: Partial<C>;
  isClosable?: boolean;
  primaryButton?: {
    icon?: string | typeof ICON_SUBMIT_BUTTON;
    action?: (componentInstance: C | undefined) => void;
    isLoading$?: Observable<boolean>;
    isDisabled$?: Observable<boolean>;
    text$?: Observable<string>;
  };
  isCloseAllowed$?: Observable<boolean>;
  isFullScreen?: boolean;
  hasCustomHeader?: boolean;
}

export interface NewWindowDialogOptions extends BaseDialogOptions {
  componentId?: EnumObjectValue<typeof LOOP_DYNAMIC_COMPONENT_NAME>;
  windowIdentifier: string | number;
  componentParams?: Record<string, string | number>;
  windowWidth?: number;
  windowHeight?: number;
  windowMinWidth?: number;
  windowMinHeight?: number;
  isNotResizable?: boolean;
  windowBackgroundColor?: string;
}

export interface BaseDialogOptions {
  title$?: Observable<string>;
  width?: string;
  useCustomLayout?: boolean;
}

export function isNewWindowDialogOptions(options: BaseDialogOptions): options is NewWindowDialogOptions {
  return !!(options as NewWindowDialogOptions).componentId;
}

export function isOverlayDialogOptions(options: BaseDialogOptions): options is OverlayDialogOptions<any> {
  return !!(options as OverlayDialogOptions<any>).componentData;
}
