import { Directive } from '@angular/core';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import {
  AppStatusNotification,
  AppStatusType,
  ConnectionStatus,
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { Observable, pairwise } from 'rxjs';
import { filter, startWith, tap } from 'rxjs/operators';
import { AppStateServiceI } from './app-state.service.interface';
import { WatchdogService } from '@shared/services/watchdog/watchdog.service';

@Directive()
export class AppStateService implements AppStateServiceI {
  //////////
  // State
  //////////
  private _isConnectionActive: boolean = true; // Default should be online

  constructor(private _watchdogService: WatchdogService) {
    this.setInitialConnectionStatus();
  }

  get constructorName(): string {
    return 'AppStateService';
  }

  get connectionStatus$(): Observable<ConnectionStatus> {
    let connectionStatus = new ConnectionStatus();
    connectionStatus.connectionActive = this.isConnectionActive;

    return SharedSubjects._connectionStatus$.asObservable().pipe(
      tap((_connectionStatus: ConnectionStatus) => (this._isConnectionActive = _connectionStatus.connectionActive)),
      startWith(connectionStatus),
    );
  }

  get appStatus$(): Observable<AppStatusNotification> {
    let connectionStatus = new ConnectionStatus();
    connectionStatus.connectionActive = this._isConnectionActive;

    let connectionStatusEvent = new AppStatusNotification();
    connectionStatusEvent.status = connectionStatus;
    connectionStatusEvent.type = AppStatusType.connection;

    return SharedSubjects._appStatus$.asObservable().pipe(startWith(connectionStatusEvent));
  }

  get isConnectionActive(): boolean {
    return this._isConnectionActive;
  }

  private setInitialConnectionStatus() {
    this._watchdogService
      .getIsConnectionActive()
      .pipe(
        tap((isConnectionActive: boolean) => {
          this._isConnectionActive = isConnectionActive;
        }),
      )
      .subscribe();
  }

  backOnline$(): Observable<any> {
    return this.connectionStatus$.pipe(
      pairwise(),
      filter(([previous, current]) => {
        return !previous.connectionActive && current.connectionActive;
      }),
      startWith(true),
    );
  }
}
