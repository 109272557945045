<span id="add-to-folder-move" class="dropdown dropdown-down">
  <!-- Dropdown -->
  <div *ngIf="open" class="dropdown-menu">
    <!-- Header -->
    <div class="dropdown-header">
      <div class="title">MOVE TO TEAM</div>
    </div>

    <!-- Input -->
    <div class="input-wrapper">
      <svg-comp class="svg-icon" [src]="searchSvg"></svg-comp>
      <input
        type="text"
        class="allow-paste"
        autocomplete="off"
        placeholder="Search teams"
        [(ngModel)]="folderSearchQuery"
        (input)="handleInputChange()"
        [focusStateNotifier]="'textSecondary'"
        [myAutoFocus]="true"
      />
    </div>

    <autosuggest
      [query$]="searchQuery$"
      [isAssigneesDropdown]="true"
      [limit]="20"
      [openUp]="false"
      [ignoreGroups]="false"
      [showWithEmptyQuery]="true"
      [currentIndexEvent]="currentIndexEvent$"
      [groupSubTypes]="[GroupSubType.PERSONAL_INBOX, GroupSubType.SHARED_INBOX, GroupSubType.MANAGED_TEAM]"
      [contactTypes]="[ContactType.GROUP]"
      (clicked)="moveToTeam($event)"
    >
    </autosuggest>
  </div>
</span>
