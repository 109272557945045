/* tslint:disable */

/**
 * Query relation enum.
 */
export type QueryRelation = 'Or' | 'And' | 'OrNot' | 'AndNot';

export module QueryRelation {
  export const OR: QueryRelation = 'Or';
  export const AND: QueryRelation = 'And';
  export const OR_NOT: QueryRelation = 'OrNot';
  export const AND_NOT: QueryRelation = 'AndNot';

  export function values(): QueryRelation[] {
    return [OR, AND, OR_NOT, AND_NOT];
  }
}
