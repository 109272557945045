export const channelSettingsCopy = {
  notificationCopy: {
    'shared-inboxes': {
      title: 'What is a shared inbox?',
      content: [
        'A shared inbox is an inbox that allows multiple people to receive, read, manage, and reply to emails inside the same email account.',
        'When multiple people are handling incoming emails in one account, it’s easy to lose track of the big picture. Our shared inbox changes that. Every email is visible in the shared inbox and the whole team has access to it. You’re are able to easily chat around all the emails in private.',
      ],
      ctaUrl: 'https://help.intheloop.io/hc/en-gb/sections/4413371355665-Shared-Inbox',
      ctaCopy: 'Learn more',
    },
    'personal-inboxes': {
      title: 'What is a personal inbox?',
      content: [
        'COPY CHANGED NEEDEDA shared inbox is an inbox that allows multiple people to receive, read, manage, and reply to emails inside the same email account.',
        'When multiple people are handling incoming emails in one account, it’s easy to lose track of the big picture. Our shared inbox changes that. Every email is visible in the shared inbox and the whole team has access to it. You’re are able to easily chat around all the emails in private.',
      ],
      ctaUrl: 'https://help.intheloop.io/hc/en-gb/sections/4413371355665-Shared-Inbox',
      ctaCopy: 'Learn more',
    },
    'teams': {
      title: 'What is a team?',
      content:
        'Here you can create teams based on departments, functions or projects. You can also use them to replace traditional email groups.',
      ctaUrl: 'https://help.intheloop.io/hc/en-gb/sections/4413630957457-Teams',
      ctaCopy: 'Learn more',
    },
  },
  viewCopy: {
    'shared-inboxes': {
      title: 'Shared inboxes',
      searchPlaceholder: 'Search for shared inboxes',
      linkToCreate: '/user-settings/shared-inboxes/create',
      createButtonTooltip: 'You need to be online to add a shared inbox',
      createButtonCopy: 'Add a shared inbox',
      listTitle: 'List of shared inboxes',
      loadingCopy: 'Loading shared inboxes',
    },
    'personal-inboxes': {
      title: 'Personal inboxes',
      searchPlaceholder: 'Search for personal inboxes',
      linkToCreate: '/user-settings/personal-inboxes/create',
      createButtonTooltip: 'You need to be online to add a personal inbox',
      createButtonCopy: 'Add a personal inbox',
      listTitle: 'List of personal inboxes',
      loadingCopy: 'Loading personal inboxes',
    },
    'teams': {
      title: 'Teams',
      searchPlaceholder: 'Search for teams',
      linkToCreate: '/user-settings/teams/create',
      createButtonTooltip: 'You need to be online to add a team',
      createButtonCopy: 'Add a team',
      listTitle: 'List of teams',
      loadingCopy: 'Loading teams',
    },
  },
};
