<div *ngIf="options$ | async; let formOptions" [class.browser-modal-form]="true">
  <ng-container *ngIf="formOptions.formPrependComponent">
    <ng-template
      [loopModalFormContent]="formOptions.formPrependComponent.component"
      [loopModalFormContentModule]="formOptions.formPrependComponent.module"
      [loopModalFormContentParams]="formOptions.formPrependComponent.params"
      [loopModalFormContentInjector]="formOptions.formPrependComponent.parentInjector"
    ></ng-template>
  </ng-container>
  <ng-container *ngIf="formOptions.componentId">
    <ng-container *ngIf="componentData$ | async; let componentData">
      <ng-template
        loopComponentRenderer
        [component]="componentData.component"
        [module]="componentData.module"
        [componentParams]="componentData.componentParams"
        (componentRef)="setComponentRef($event)"
      >
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="formOptions.formComponent">
    <ng-template
      [loopModalFormContent]="formOptions.formComponent.component"
      [loopModalFormContentModule]="formOptions.formComponent.module"
      [loopModalFormContentParams]="formOptions.formComponentParams"
      [loopModalFormContentInjector]="formOptions.formComponent.parentInjector"
      (componentRef)="setComponentRef($event)"
    ></ng-template>
  </ng-container>

  <ng-container *ngIf="!formOptions.useCustomLayout">
    <loop-modal-form-footer [useSingleButtonLayout]="!!formOptions.buttons && formOptions.buttons.length === 1">
      <ng-container *ngIf="formOptions?.buttons && formOptions?.buttons?.length; else defaultFooterButtonsTpl">
        <loop-button
          *ngFor="let button of formOptions.buttons"
          [fill]="button.type === 'submit' ? 'color' : 'transparent'"
          [isLoading]="(isLoading$ | async) === true && button.type === 'submit'"
          (click)="handleButtonClick(button)"
          [color]="button.type === 'submit' ? 'primary' : 'systemContrast'"
          >{{ button.label$ | async }}</loop-button
        >
      </ng-container>
    </loop-modal-form-footer>
  </ng-container>

  <ng-template #defaultFooterButtonsTpl
    ><loop-modal-form-default-footer-buttons></loop-modal-form-default-footer-buttons
  ></ng-template>
</div>
