import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentRendererDirective } from '@shared/ui/component-renderer/component-renderer.directive';

@NgModule({
  declarations: [ComponentRendererDirective],
  imports: [CommonModule],
  exports: [ComponentRendererDirective]
})
export class ComponentRendererModule {}
