import {
  createAtomColorPaletteCssVariablesAliasesFactory,
  createThemeColorPaletteCssVariablesFactory
} from '@shared/modules/color-scheme/common/helpers/create-theme-color-palette-css-variables-factory';
import { css } from '@shared/modules/comments/helpers/lit-css';
import { ATOM_COLOR_PREFIX, THEME_COLOR_PREFIX } from '@shared/modules/color-scheme/common/css-color-variable-prefix';
import { ColorPalette } from '@shared/modules/color-scheme/common/color-palette';
import { defaultColors } from '@shared/modules/color-scheme/common/default-colors';

export const createAtomCssColorPalette = createAtomColorPaletteCssVariablesAliasesFactory(
  ATOM_COLOR_PREFIX,
  THEME_COLOR_PREFIX
);
export const createCssColorPalette = createThemeColorPaletteCssVariablesFactory(THEME_COLOR_PREFIX);

export function createEssentialThemeStyles(): string {
  const lightColorPaletteWithFallbacks = new ColorPalette({
    ...defaultColors
  });
  const styles = css`
    :root {
      ${createCssColorPalette(lightColorPaletteWithFallbacks)}
    }
    ${createAtomCssColorPalette()}
  `;

  return (
    styles
      // remove all white space characters (excluding spaces)
      .replace(/[\r\n\t\f\v]/g, '')
      // replace multiple spaces with single one
      .replace(/\s+/g, ' ')
  );
}
