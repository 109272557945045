import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { KeyboardShortcutFilter } from 'dta/shared/models/keyboard-shortcut.model';

@Injectable()
export class ShortcutsDialogService {
  toggleDialog$: Subject<boolean | void> = new Subject();
  updateFilter$: Subject<KeyboardShortcutFilter> = new Subject();

  constructor() {}

  get toggleDialogSubject() {
    return this.toggleDialog$.asObservable();
  }
}
