import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderNewComponent } from '@dta/ui/components/common/loader-new/loader-new.component';
import { SharelistTextComponent } from '@dta/ui/components/common/sharelist-text/sharelist-text.component';
import { HiglightPipeModule } from '@dta/ui/pipes/highlight/higlight-pipe.module';

@NgModule({
  declarations: [SharelistTextComponent],
  imports: [CommonModule, HiglightPipeModule],
  exports: [SharelistTextComponent],
})
export class SharelistTextModule {}
