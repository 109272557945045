import { Injectable } from '@angular/core';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { ConversationDaoService } from '@shared/database/dao/conversation/conversation-dao.service';
import { ConversationPopulateService } from '@shared/populators/conversation-card-populate/conversation-populate/conversation-populate.service';
import { ConversationApiService } from '@shared/api/api-loop/services/conversation-api.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { CardService } from '@shared/services/data/card/card.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConversationServiceWeb extends ConversationService {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _conversationDaoService: ConversationDaoService,
    protected _conversationPopulateService: ConversationPopulateService,
    protected _conversationApiService: ConversationApiService,
    protected _contactService: ContactService,
    protected _commentService: CommentService,
    protected _cardService: CardService,
  ) {
    super(
      _syncMiddleware,
      _conversationDaoService,
      _conversationPopulateService,
      _conversationApiService,
      _contactService,
      _commentService,
      _cardService,
    );
  }

  get constructorName(): string {
    return 'ConversationServiceWeb';
  }

  beforeSyncServiceInit(forUserEmail: string): Observable<any> {
    return this._conversationDaoService.removeAllConversations(forUserEmail);
  }
}
