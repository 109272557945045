import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { LoginUserModel } from '../../../dta/shared/models-api-loop/contact/contact.model';
import { SettingsApiService } from '@shared/api/api-loop/services';
import { mergeMap, tap } from 'rxjs/operators';
import { StorageKey, StorageService } from '../../../dta/shared/services/storage/storage.service';
import { SharedUserManagerService } from '../../../dta/shared/services/shared-user-manager/shared-user-manager.service';
import { SharedSubjects } from '../communication/shared-subjects/shared-subjects';
import { UserTrigger } from '../communication/shared-subjects/shared-subjects-models';
import {
  User,
  UserAlias,
  UserAliasSettings,
  UserClientSettings,
  UserPaymentSettings,
  UserSettings,
} from '@shared/api/api-loop/models';

@Injectable()
export class SettingsService {
  // Default values
  private clientSettings: ClientSettings = {
    sentItems: true,
    privateTriage: true,
    logsDisabled: false,
    defaultFromAddress: undefined,
  };

  private userAliasSettings: UserAliasSettings;

  constructor(
    private _storageService: StorageService,
    private _settingsApiService: SettingsApiService,
    private _sharedUserManagerService: SharedUserManagerService,
  ) {}

  getPrivateTriageSetting(forUserEmail: string): boolean {
    return this.getClientSettings(forUserEmail).privateTriage;
  }

  getShowSentSetting(forUserEmail: string): boolean {
    return this.getClientSettings(forUserEmail).sentItems;
  }

  getDefaultFromAddress(forUserEmail: string): string {
    return this.getClientSettings(forUserEmail).defaultFromAddress;
  }

  getDisableLoggingSetting(forUserEmail: string): boolean {
    return this.getClientSettings(forUserEmail).logsDisabled;
  }

  getClientSettings(forUserEmail: string): ClientSettings {
    let key = this._storageService.getKey(forUserEmail, StorageKey.userClientSettings);
    let storedSettings = this._storageService.getParsedItem(key);

    return storedSettings || this.clientSettings;
  }

  getAliasSettings(forUserEmail: string): UserAliasSettings {
    let key = this._storageService.getKey(forUserEmail, StorageKey.userAliasSettings);
    let storedSettings = this._storageService.getParsedItem(key);

    return storedSettings || this.userAliasSettings;
  }

  setUserClientSettings(forUserEmail: string, settings: ClientSettings) {
    let loginUser = this._sharedUserManagerService.getUserLoginByEmail(forUserEmail);
    if (!loginUser) {
      return;
    }

    of(loginUser)
      .pipe(
        mergeMap((loginUser: LoginUserModel) => {
          let updatedUserClientSettings: UserClientSettings = {
            showEmailsInAllMessages: true, // Deprecated BE doesn't want to remove it
            showSendEmailsInAllMessages: settings.sentItems ?? true,
            enablePrivateTriagingInMyLoopInbox: settings.privateTriage ?? true,
            logsDisabled: settings.logsDisabled ?? false,
            defaultFromAddress: settings.defaultFromAddress,
          };

          let updatedUserSettings = loginUser.userSettings;
          updatedUserSettings.userClientSettings = updatedUserClientSettings;

          let params: SettingsApiService.Settings_UpdateUserSettingsParams = {
            settingsUser: updatedUserSettings,
          };

          return this._settingsApiService.Settings_UpdateUserSettings(params, forUserEmail);
        }),
        tap((updatedSettings: UserSettings) => {
          this.saveUserSettings(updatedSettings, forUserEmail);
        }),
      )
      .subscribe();
  }

  saveUserSettings(settings: UserSettings, forUserEmail: string) {
    // Save client settings
    if (settings.userClientSettings) {
      let clientSettings: ClientSettings = {
        sentItems: settings.userClientSettings.showSendEmailsInAllMessages ?? true,
        privateTriage: settings.userClientSettings.enablePrivateTriagingInMyLoopInbox ?? true,
        logsDisabled: settings.userClientSettings.logsDisabled ?? false,
        defaultFromAddress: settings.userClientSettings.defaultFromAddress,
      };
      let clientSettingsKey = this._storageService.getKey(forUserEmail, StorageKey.userClientSettings);
      this._storageService.setStringifiedItem(clientSettingsKey, clientSettings);
    }

    // Save client payment settings
    if (settings.userPaymentSettings) {
      let userPaymentSettings: UserPaymentSettings = {
        paymentStatusType: settings.userPaymentSettings.paymentStatusType,
        paymentStatusDate: settings.userPaymentSettings.paymentStatusDate,
      };

      let paymentSettingsKey = this._storageService.getKey(forUserEmail, StorageKey.userPaymentSettings);
      this._storageService.setStringifiedItem(paymentSettingsKey, userPaymentSettings);
    }

    // Save alias settings
    let defaultSenderEmail = forUserEmail;
    let userAliasKey = this._storageService.getKey(forUserEmail, StorageKey.userAliasSettings);
    if (settings.userAliasSettings) {
      let aliasSettings: UserAliasSettings = settings.userAliasSettings;
      this._storageService.setStringifiedItem(userAliasKey, settings.userAliasSettings);

      // Get default sender
      _.forEach(aliasSettings.aliases.resources, (alias: UserAlias) => {
        if (alias.defaultAlias) {
          defaultSenderEmail = (<User>alias.alias).email;
        }
      });
    } else {
      this._storageService.removeItem(userAliasKey);
    }

    // Save default sender
    let key = this._storageService.getKey(forUserEmail, StorageKey.defaultSenderEmail);
    this._storageService.setItem(key, defaultSenderEmail);

    // Persist settings to users array in LS
    this._sharedUserManagerService.updateUserSettings(forUserEmail, settings, 'SettingsService.saveUserSettings');

    // Broadcast event
    let userTrigger = new UserTrigger();
    userTrigger.forUserEmail = forUserEmail;
    SharedSubjects._settingsChange$.next(userTrigger);
  }
}

export interface ClientSettings {
  sentItems: boolean;
  privateTriage: boolean;
  logsDisabled: boolean;
  defaultFromAddress: string;
}
