import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardServiceModule } from '@shared/shared/clipboard/clipboard-service/clipboard-service.module';
import { MailCommentSendMailOperationsModule } from '@shared/modules/comments/components/mail-comment/mail-comment-send-mail-operations/mail-comment-send-mail-operations.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ClipboardServiceModule, MailCommentSendMailOperationsModule]
})
export class MailCommentOperationsModule {}
