/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { AuthBase } from '../models/auth-base';
import { Response } from '../models/response';

@Injectable()
export class TimedAuthApiService extends BaseService {
  /**
   * @param params The `TimedAuthApiService.TimedAuth_GetTimedAuthParams` containing the following parameters:
   *
   * - `otpCode`: Add secure authorization
   *
   * - `X-Impersonate-User`: Impersonate user header
   *
   * - `Authorization`: Authorization header
   */
  TimedAuth_GetTimedAuthResponse(
    params: TimedAuthApiService.TimedAuth_GetTimedAuthParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/auth/timed`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('TimedAuth_GetTimedAuth');

    // body, headers and query params
    if (params.otpCode != null) __params = __params.set('otpCode', params.otpCode.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthBase;

          body = <AuthBase>response.body;

          return <HttpResponse<AuthBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TimedAuthApiService.TimedAuth_GetTimedAuthParams` containing the following parameters:
   *
   * - `otpCode`: Add secure authorization
   *
   * - `X-Impersonate-User`: Impersonate user header
   *
   * - `Authorization`: Authorization header
   */
  TimedAuth_GetTimedAuth(
    params: TimedAuthApiService.TimedAuth_GetTimedAuthParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthBase> {
    const observable$ = this.TimedAuth_GetTimedAuthResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/auth/timed`;
      const url = this.rootUrl + `/api/v1/auth/timed`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module TimedAuthApiService {
  /**
   * Parameters for TimedAuth_GetTimedAuth
   */
  export type TimedAuth_GetTimedAuthParams = {
    /**
     * Add secure authorization
     */
    otpCode?: string;

    /**
     * Impersonate user header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
