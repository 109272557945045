import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SleepClickEventDirective } from '@dta/ui/directives/sleep-click-event/sleep-click-event.directive';
import { HighlightPipe } from '@dta/ui/pipes/highlight/highlight.pipe';

@NgModule({
  declarations: [HighlightPipe],
  imports: [CommonModule],
  exports: [HighlightPipe],
})
export class HiglightPipeModule {}
