import { Injectable } from '@angular/core';
import { SharedAvatarService } from '@shared/services/shared-avatar/shared-avatar.service';
import { StorageKey } from '@dta/shared/services/storage/storage.service';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';

export const NO_AVATAR = 'no_avatar';

@Injectable()
export class SharedAvatarServiceWeb extends SharedAvatarService {
  get constructorName(): string {
    return 'SharedAvatarServiceWeb';
  }

  isFileNameEmptyPlaceholder(fileName: string): boolean {
    return fileName === NO_AVATAR;
  }

  getAvatarFile(
    forUserEmail: string,
    filesUri: string,
    avatarFileName: string,
    contactId: string,
    contact?: ContactBase,
  ): string {
    let key = this._storageService.getKey(forUserEmail, StorageKey.avatarUrls);
    let avatarUrls = this._storageService.getParsedItem(key);

    if (!avatarUrls) {
      return undefined;
    }

    return avatarUrls[contactId];
  }
}
