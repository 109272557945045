export const CREATE_PERSONAL_COPY = {
  // Step 1 Form
  namePlaceholder: 'How would you like to call this inbox?',
  nameLabel: 'Personal Inbox Name',
  descriptionPlaceholder: 'What will you use this inbox for?',
  overviewSubtitle: 'Add personal inbox name and description below.',

  // Step 2
  channelSubtitle: 'Select the email account that will be connected as a personal inbox.',

  // Channels notification
  channelNotificationTitle: 'Connecting a personal inbox',
  channelNotificationContent:
    'Connecting your personal inbox is the most effective way to test how Loop can work for your business. Your emails from your personal inbox will come into Loop ready for you to assign, tag. You can reply directly to your customers from within Loop as well.',

  // Success
  successTitle: 'Success, your personal inbox has been created',
  successCtaCopy: 'Take me to the personal inbox',
};
