import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { intersection } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'custom-input',
  templateUrl: './custom-input.html',
  styleUrls: ['./custom-input.scss']
})
export class InputComponent implements OnInit, OnDestroy {
  readonly _element: ElementRef = inject(ElementRef);

  @Input() label;
  @Input() value;
  @Input() name;
  @Input() disabled = false;
  @Input() type = 'text';
  @Input() hasElements;
  @Input() placeholder = '';
  @Input() autofocus = false;
  @Input() preventSubmitEvents = false;
  @Input() preventSubmitOnFocus = false;
  @Input() pattern = undefined;
  @Input() required: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() class = '';
  @Input() isChipInput: boolean = true;
  @Input() isAssigneesDropdown: boolean = false;
  @Input() clearInput$: Observable<never>;

  @Output() modelValueChanged = new EventEmitter();
  @Output() keydownEvent = new EventEmitter();
  @Output() keyupEvent = new EventEmitter();
  @Output() blurEvent = new EventEmitter();
  @Output() closeRecipients: EventEmitter<boolean> = new EventEmitter();
  @Output() focusEvent: EventEmitter<any> = new EventEmitter();
  focused: boolean = false;
  control;

  ////////////////////
  // Subscriptions
  ////////////////////
  private clearInputSub: Subscription;

  onPaste($event) {
    if (!this.isChipInput) {
      return;
    }

    let data;
    if ($event.original$Event && $event.original$Event.clipboardData !== null) {
      data = this.value + $event.original$Event.clipboardData.getData('text');
      $event.preventDefault();
    } else if ($event.clipboardData !== null) {
      data = this.value + $event.clipboardData.getData('text');
      $event.preventDefault();
    } else {
      data = this.value;
    }
    this.value = data;
    this.setInputWidth();
  }

  handleFocus = event => {
    this.focused = true;
    this.focusEvent.emit();

    this.setInputWidth();
    // Broadcast focus event
    this.focusEvent.emit(true);
  };

  handleBlur = event => {
    this.focused = false;

    this.setInputWidth();
    // Broadcast blur event
    this.blurEvent.emit(false);
    this.shouldCloseRecipients(event);
  };

  focusToInput() {
    this._element.nativeElement.querySelector('input').focus();
  }

  onChangeInput = $event => {
    this.setInputWidth();
    this.modelValueChanged.emit($event);
  };

  keydown = $event => {
    this.setInputWidth();

    if (
      this.preventSubmitEvents &&
      (this.value || (this.preventSubmitOnFocus && this.focused)) &&
      $event.keyCode === 13
    ) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    if (this.isAssigneesDropdown && ($event.keyCode === 38 || $event.keyCode === 40)) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.keydownEvent.emit($event);
  };

  keyup = $event => {
    this.keyupEvent.emit($event);
  };

  get empty() {
    return this.value === undefined || this.value === '';
  }
  isEmpty() {
    return this.value === undefined || this.value === '';
  }

  ngOnInit() {
    this.subscribeToInputReset();

    if (this.required) {
      this.control = new FormControl(name, Validators.compose([Validators.required]));
    } else {
      this.control = new FormControl(name, Validators.compose([]));
    }

    if (this.value) {
      this.focused = true;
    }

    this.setFocusToInput();

    if (this.hasElements > 0) {
      this.focused = true;
    }
  }

  ngOnDestroy() {}

  public setFocusToInput() {
    // Focus to input if autofocus is set
    if (this.autofocus) {
      setTimeout(() => {
        this._element.nativeElement.querySelector('input').focus();
      }, 500);
    }
  }

  private setInputWidth() {
    // adjust width
    setTimeout(() => {
      let input = this._element.nativeElement.querySelector('input');
      let minWidth = 210;
      let addedWidth = this._element.nativeElement.querySelector('input').value.length * 11; // character width should be arount 14px
      if (minWidth + addedWidth >= 210) {
        this._element.nativeElement.querySelector('input').style.width = addedWidth + 'px';
      } else {
        this._element.nativeElement.querySelector('input').style.width = minWidth + addedWidth + 'px';
      }
    }, 0);
  }

  private shouldCloseRecipients(event) {
    let whitelistedClasses = ['email-card-recipients', 'email-card-to-cc'];

    if (!event.relatedTarget && !event.currentTarget) {
      this.closeRecipients.emit(true);
      return;
    }

    let focusToElement: HTMLElement = event.relatedTarget || event.currentTarget;

    if (intersection(focusToElement.classList, whitelistedClasses).length === 0) {
      this.closeRecipients.emit(true);
    }
  }

  private subscribeToInputReset() {
    if (!this.clearInput$) {
      return;
    }

    this.clearInputSub?.unsubscribe();
    this.clearInputSub = this.clearInput$
      .pipe(
        tap(() => {
          this.value = '';
        })
      )
      .subscribe();
  }
}
