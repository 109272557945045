import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  inject,
  OnDestroy,
  Output
} from '@angular/core';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';

@Component({
  template: '',
  selector: 'loop-custom-dropdown-base',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class CustomDropdownBase<T> implements OnDestroy {
  private readonly keyboardShortcutsListenerService: KeyboardShortcutsListenerService = inject(
    KeyboardShortcutsListenerService
  );

  protected currentIndex: number = 0;

  protected visibleDataLength?: number = undefined;
  protected abstract currentItemClick(): void;
  protected currentItems: T[] = [];

  @Output() itemClick: EventEmitter<T> = new EventEmitter<T>();
  @Output() closeDropdown: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      if (this.currentIndex === (this.visibleDataLength ?? this.currentItems.length) - 1) {
        return;
      }

      this.currentIndex = this.currentIndex + 1;
    } else if (event.code === 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();
      if (this.currentIndex <= 0) {
        return;
      }
      this.currentIndex = this.currentIndex - 1;
    } else if (event.code === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.currentItemClick();
    }
  }

  ngOnDestroy(): void {
    this.keyboardShortcutsListenerService.goToPreviousMode();
  }
}
