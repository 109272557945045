import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import {
  AppointmentResponse,
  CardAppointment,
  CardBase,
  CardDraft,
  ListOfResourcesOfAttendee,
  Tag,
} from '@shared/api/api-loop/models';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { ChatCardContactIdIndex, CollectionNameWeb } from '../../database-schema';
import { CardDaoServiceI } from '@shared/database/dao/card/card-dao.service';
import {
  CardAppointmentModel,
  CardBaseModel,
  CardChatModel,
  CardDraftModel,
  CardMailModel,
  CardModel,
  CardSharedModel,
  SubscriptionState,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { Observable, of } from 'rxjs';
import { DatabaseQuery } from '@dta/backend/database/database.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';
import {
  AllSharedInboxesCollectionParams,
  ChannelCardsCollectionParams,
  ChatCardsCollectionParams,
  MyLoopInboxCollectionParams,
  PersonalInboxCollectionParams,
  SearchableViewCollectionParams,
} from '@dta/shared/models/collection.model';
import { map, mergeMap } from 'rxjs/operators';
import { DatabaseServiceWeb } from '../../database.service.web';
import { CardPopulateService } from '@shared/populators/conversation-card-populate/card-populate/card-populate.service';

@Injectable()
export class CardDaoServiceWeb extends BaseDaoServiceWeb<CardModel, CardBase> implements CardDaoServiceI {
  constructor(
    protected _databaseFactory: DatabaseFactory,
    protected _cardPopulateService: CardPopulateService,
  ) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'CardDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.Card;
  }

  protected toModel(doc: Tag): CardModel {
    return CardBaseModel.create(doc);
  }

  protected populate(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return this._cardPopulateService.populate(forUserEmail, cards);
  }

  findTemplateCardIds(forUserEmail: string): Observable<string[]> {
    return of([]);
  }

  findChatCardByContactId(forUserEmail: string, contactId: string): Observable<CardChatModel> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(ChatCardContactIdIndex.indexName, [contactId], this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
      map(_.first),
    );
  }

  findChatCardSubscriptionStatus(
    forUserEmail: string,
    cardId: string,
  ): Observable<{ _ex: { subscriptionState: SubscriptionState } }> {
    return this.findById(forUserEmail, cardId).pipe(map((card: CardModel) => ({ _ex: card._ex })));
  }

  unlinkDraftCard(forUserEmail: string, cardDraft: CardDraft): Observable<any> {
    return this.findById(forUserEmail, cardDraft.parentCard.id).pipe(
      map(CardBaseModel.create),
      mergeMap((card: CardMailModel) => {
        card.removeLinkToDraftCard(cardDraft.id || cardDraft.clientId);
        return this.saveAll(forUserEmail, [card]);
      }),
    );
  }

  ///////////////////////////
  // CONVERSATION VIEW CACHE
  ///////////////////////////
  findMyLoopInboxCards(forUserEmail: string, params: MyLoopInboxCollectionParams): Observable<CardModel[]> {
    return of([]);
  }

  findCardsForAssignedView(forUserEmail: string, params: any): Observable<CardSharedModel[]> {
    return of([]);
  }

  findPersonalInboxCards(forUserEmail: string, params: PersonalInboxCollectionParams): Observable<CardModel[]> {
    return of([]);
  }

  findChatCards(forUserEmail: string, params: ChatCardsCollectionParams): Observable<CardChatModel[]> {
    return of([]);
  }

  findCardsByChannel(forUserEmail: string, params: ChannelCardsCollectionParams): Observable<CardModel[]> {
    return of([]);
  }

  findDraftsOrCardsWithDrafts(forUserEmail: string, params: SearchableViewCollectionParams): Observable<CardModel[]> {
    return of([]);
  }

  findFolderCards(forUserEmail: string, params: SearchableViewCollectionParams): Observable<CardModel[]> {
    return of([]);
  }

  ////////////////
  ////////////////
  ////////////////
  ////////////////
  /// TODO
  removeTag(forUserEmail: string, card: CardModel, tag: TagModel): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  unsetId(forUserEmail: string, card: CardModel): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  updateAppointmentAttendees(
    forUserEmail: string,
    card: CardAppointmentModel,
    attendees: ListOfResourcesOfAttendee,
  ): Observable<CardAppointmentModel> {
    throw new Error('Method not implemented.');
  }
  updatedAppointmentResponse(
    forUserEmail: string,
    cardId: string,
    response: AppointmentResponse,
  ): Observable<CardAppointmentModel> {
    throw new Error('Method not implemented.');
  }
  checkIfCardHasLiveLoops(forUserEmail: string, cardId: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  findNonStubCardBaseByIds(forUserEmail: string, cards: CardBase[]): Observable<BaseModel[]> {
    throw new Error('Method not implemented.');
  }
  findCardIds(forUserEmail: string, cardIds: string[]): Observable<string[]> {
    throw new Error('Method not implemented.');
  }
  findCardBySourceResourceId(forUserEmail: string, sourceCardId: string): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  findCardsBySourceResourceId(forUserEmail: string, sourceCardId: string): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findLoopsBySourceAndSnapshotIds(
    forUserEmail: string,
    sourceCards: CardMailModel[],
    excludedCards: CardModel[],
  ): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findLiveLoopsBySourceResourceIds(
    forUserEmail: string,
    sourceResourceCardIds: string[],
  ): Observable<CardSharedModel[]> {
    throw new Error('Method not implemented.');
  }
  findCardMailByIds(forUserEmail: string, cardIds: string[], excludedCards: CardModel[]): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findCardSharedBySnapshotResource(forUserEmail: string, card: CardMailModel): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  findLocalChatCardsBySharelist(forUserEmail: string, emailsFromChatCards: string[]): Observable<CardChatModel[]> {
    throw new Error('Method not implemented.');
  }
  findAllSharedInboxCards(forUserEmail: string, params: AllSharedInboxesCollectionParams): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findCardIdsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string,
  ): Observable<string[]> {
    throw new Error('Method not implemented.');
  }
  findAppointments(forUserEmail: string, params: any): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findCards(forUserEmail: string, cards: CardBase[]): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findCardsWithoutSynchronizedComments(forUserEmail: string): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findAppointmentsByCards(
    forUserEmail: string,
    cardsWithAppointmentsLink: CardMailModel[],
  ): Observable<CardAppointmentModel[]> {
    throw new Error('Method not implemented.');
  }
  findCardByAppointmentLink(forUserEmail: string, appointment: CardAppointment): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  findCardSharedWithMissingSourceCards(forUserEmail: string): Observable<CardSharedModel[]> {
    throw new Error('Method not implemented.');
  }
  getParentLoop(forUserEmail: string, card: CardDraftModel): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  buildAllSharedInboxesCardsFromParams(params: AllSharedInboxesCollectionParams): DatabaseQuery {
    throw new Error('Method not implemented.');
  }
}
