import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Modal } from '../../../../dta/ui/components/common/modal/modal.component';
import { ConfirmDialogService } from './confirm-dialog.service';
import { Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../../dta/shared/utils/subscriptions/auto-unsubscribe';
import { KeyboardActions } from '../../../../dta/ui/components/common/keyboard-actions/keyboard-actions.component';
import { KeyboardShortcutsListenerService } from '../../../../dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { KeyboardActionModeType } from '../../../../dta/shared/models/keyboard-shortcut.model';

@AutoUnsubscribe()
@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.html',
  styleUrls: ['./confirm-dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent extends KeyboardActions implements OnInit, OnDestroy {
  // SVGs
  closeMediumSvg = require('shared/assets/img/svg/close-medium.svg');
  logo = require('shared/assets/img/svg/logo.svg');

  text: string = '';
  title: string = '';
  noCancelButton: boolean = false;
  confirmButtonText: string = 'OK';
  closeButton: boolean = false;
  confirmButtonDanger: boolean = false;
  private completeResponse: boolean = true;
  protected keyboardActions = {
    Enter: this.confirm.bind(this),
    Escape: this.cancel.bind(this),
  };

  // Modal from view
  @ViewChild('confirmModal', { static: true }) confirmModal: Modal;

  // Subjects
  private response$: Subject<boolean>;

  // Subscription
  private toggleSub: Subscription;

  constructor(
    private _changeDetection: ChangeDetectorRef,
    private _confirmDialogService: ConfirmDialogService,
    private _keyboardShortcutsListenerService: KeyboardShortcutsListenerService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToToggle();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }

  onModalClose() {
    this.text = '';
    this.title = '';
    this.confirmModal.isOpened = false;
    this.removeKeyboardListener();
    this._keyboardShortcutsListenerService.goToPreviousMode(KeyboardActionModeType.MODAL);
  }

  confirm(): void {
    this.confirmModal.close();
    if (this.response$) {
      this.response$.next(true);

      if (this.completeResponse) {
        this.response$.complete();
      }
    }
  }

  cancel(): void {
    this.confirmModal.close();
    if (this.response$) {
      this.response$.next(false);

      if (this.completeResponse) {
        this.response$.complete();
      }
    }
  }

  private open() {
    this.confirmModal.open();
    this.triggerChangeDetection();
    this.addKeyboardListener();
    this._keyboardShortcutsListenerService.setMode(KeyboardActionModeType.MODAL);
  }

  private close() {
    this.confirmModal.close();
    this.triggerChangeDetection();
    this.removeKeyboardListener();
  }

  private subscribeToToggle() {
    this.toggleSub && this.toggleSub.unsubscribe();
    this.toggleSub = this._confirmDialogService.toggle
      .pipe(
        tap((data: ConfirmDialogData) => {
          if (!data.open) {
            this.close();
            return;
          }

          this.title = data.title || '';
          this.text = data.text || '';
          this.confirmButtonText = data.confirmButtonText || 'OK';
          this.response$ = data.subject || undefined;
          this.confirmButtonDanger = data.confirmButtonDanger ?? false;
          this.closeButton = data.closeButton ?? false;
          this.completeResponse = data.completeResponse ?? true;
          this.noCancelButton = data.noCancelButton;

          this.open();
        }),
      )
      .subscribe();
  }
}

export type ConfirmDialogData = {
  open?: boolean;
  title?: string;
  text?: string;
  confirmButtonText?: string;
  subject?: Subject<boolean>;
  confirmButtonDanger?: boolean;
  closeButton?: boolean;
  completeResponse?: boolean;
  noCancelButton?: boolean;
};
