import { Injectable } from '@angular/core';
import { BaseObservableCacheService } from '@shared/cache/base-observable-cache.service';
import { getLinkExpirationDate, isLinkExpired } from '@shared/modules/files/common/helpers/link-helpers';
import { ContactAvatarsApiCacheModule } from '@shared/modules/files/shell/contact-avatars-api-cache/contact-avatars-api-cache.module';
import { LRUCache } from 'lru-cache';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: ContactAvatarsApiCacheModule
})
export class ContactAvatarsApiCacheService extends BaseObservableCacheService<string> {
  protected readonly cacheStorage: LRUCache<string, Observable<any>> = this.storageProviderService.provideStorage(
    'contactAvatarsCache',
    50
  );

  getOrStoreObservable(
    user_id: string,
    observable$: Observable<string | null>,
    expiration: number = 600000
  ): Observable<string> {
    if (!this.cacheStorage.has(user_id)) {
      this.storeObservable(this.cacheStorage, user_id, observable$, expiration);
    }

    return this.cacheStorage.get(user_id).pipe(
      switchMap(avatarUrl => {
        if (!avatarUrl) {
          this.cacheStorage.delete(user_id);
          return observable$;
        }

        const isExpired: boolean = isLinkExpired(getLinkExpirationDate(avatarUrl));
        if (isExpired) {
          return observable$;
        }

        return of(avatarUrl);
      })
    );
  }
}
