/* tslint:disable */

/**
 * Possible email service types
 */
export type EmailServiceType = 'Google' | 'Exchange' | 'Microsoft' | 'Imap' | 'Other' | 'NotSupported' | 'RemoteImap';

export module EmailServiceType {
  export const GOOGLE: EmailServiceType = 'Google';
  export const EXCHANGE: EmailServiceType = 'Exchange';
  export const MICROSOFT: EmailServiceType = 'Microsoft';
  export const IMAP: EmailServiceType = 'Imap';
  export const OTHER: EmailServiceType = 'Other';
  export const NOT_SUPPORTED: EmailServiceType = 'NotSupported';
  export const REMOTE_IMAP: EmailServiceType = 'RemoteImap';

  export function values(): EmailServiceType[] {
    return [GOOGLE, EXCHANGE, MICROSOFT, IMAP, OTHER, NOT_SUPPORTED, REMOTE_IMAP];
  }
}
