<div *ngIf="context$ | async; let context" class="action-button">
  <ng-container *ngIf="(isChatCard$ | async) && isChannelView; else SharedCardTpl">
    <ng-container *ngIf="isFavorite$ | async; let isFavoriteObject">
      <div class="main-action" (click)="toggleFavorite(isFavoriteObject.isFavorite)">
        <span>{{ isFavoriteObject.isFavorite ? 'Unfavorite' : 'Favorite' }}</span>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #SharedCardTpl>
    <div
      *ngIf="sharedCardOptions$ | async; let sharedCardOptions"
      class="main-action"
      [class.standalone]="!(isDropdownVisible$ | async)"
      (click)="sharedCardOptions.action()"
    >
      <svg-comp [src]="sharedCardOptions.icon"></svg-comp>
      <span>{{ sharedCardOptions.text }}</span>
    </div>
  </ng-template>
  <div
    *ngIf="isDropdownVisible$ | async"
    #dropdownTpl
    [loopClickDropdown]="(dropdownOptions$ | async) || []"
    (click)="setThreadMode()"
    class="dropdown-toggle"
  >
    <svg-comp [src]="dropdownSvg"> </svg-comp>
  </div>
</div>
