import * as _ from 'lodash';
import { LoginUserModel, UserModel } from '../../models-api-loop/contact/contact.model';
import { Injectable, OnDestroy } from '@angular/core';
import { StorageKey, StorageService } from '../storage/storage.service';
import { Observable, of, Subscription } from 'rxjs';
import { AuthUser } from '../../models/auth.model';
import { SharedSubjects } from '../../../../shared/services/communication/shared-subjects/shared-subjects';
import { catchError, tap } from 'rxjs/operators';
import { IPC } from '../../communication/ipc-constants';
import {
  ConfirmationPopupOpenData,
  ConfirmationPopupType,
  Trigger,
  UserAuthUpdate,
  UserRemove,
  UserSet,
  UserSwitch,
  UserSwitchReason,
  UserUpdate,
} from '../../../../shared/services/communication/shared-subjects/shared-subjects-models';
import { AutoUnsubscribe } from '../../utils/subscriptions/auto-unsubscribe';
import { EmailServiceAuthType, User, UserSettings } from '@shared/api/api-loop/models';
import { BaseModel } from '../../models-api-loop/base/base.model';
import { EmailTypeEnum, SyncSettingsType } from '../../models/sync-settings-type.model';
import { LogLevel, LogTag } from '../../models/logger.model';
import { Logger } from '@shared/services/logger/logger';
import { ElectronService } from '@shared/services/electron/electron';
import { isWebApp, Time } from '@dta/shared/utils/common-utils';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';

@Injectable()
@AutoUnsubscribe()
export class SharedUserManagerService implements OnDestroy {
  ////////////////////////////
  // Private state variables
  ////////////////////////////
  protected static _users: LoginUserModel[] = [];
  protected static _init: boolean = false;

  //////////
  // Cache
  //////////
  protected static _userAuthByEmail: _.Dictionary<AuthUser>;
  protected static _usersInitiallySynced: string[];

  //////////////////
  // Subscriptions
  //////////////////
  private userAuthUpdateSub: Subscription;
  private _userRemovedSub: Subscription;
  private _userResetSub: Subscription;

  constructor(
    protected _storageService: StorageService,
    protected _electronService: ElectronService,
  ) {
    if (!SharedUserManagerService._init) {
      this.init();
    }
  }

  // Call once when app starts
  init() {
    this.initUserAuthByEmail();
    this.subscribeToUserAuthChange();
    this.subscribeToUserRemoved();
    this.subscribeToUserReset();

    SharedUserManagerService._init = true;
  }

  ngOnDestroy(): void {}

  get constructorName(): string {
    return 'SharedUserManagerService';
  }

  ///////////////////
  // Shared subjects
  ///////////////////
  get userRemove$(): Observable<UserRemove> {
    return SharedSubjects._userRemove$.asObservable();
  }

  get userSet$(): Observable<UserSet> {
    return SharedSubjects._userSet$.asObservable();
  }

  get userUpdate$(): Observable<UserUpdate> {
    return SharedSubjects._userUpdate$.asObservable();
  }

  get resetLoggedInUsers$(): Observable<Trigger> {
    return SharedSubjects._resetLoggedInUsers$.asObservable();
  }

  get userAuthUpdate$(): Observable<UserAuthUpdate> {
    return SharedSubjects._userAuthUpdate$.asObservable();
  }

  get userInitiallySynced$(): Observable<any> {
    return SharedSubjects._userInitiallySynced$.asObservable();
  }

  //////////////////
  // Public methods
  //////////////////
  onAllBlockingSyncDone(forUserEmail: string): Observable<any> {
    let done = SynchronizationMiddlewareService.allBlockingPrefetchActionsDone(forUserEmail);
    return done ? of(undefined) : SharedSubjects._prefetchDone$.forUserEmail(forUserEmail);
  }

  reloadUsersCache() {
    SharedUserManagerService._users = undefined;
    this.getUsers();

    // Invalidate auth cache
    SharedUserManagerService._userAuthByEmail = {};
    this.initUserAuthByEmail();
  }

  getUsers(): LoginUserModel[] {
    if (_.isEmpty(SharedUserManagerService._users)) {
      SharedUserManagerService._users = this._storageService.getParsedItem(StorageKey.users) || [];
    }

    return [...SharedUserManagerService._users];
  }

  setNewUserOrder(orderedEmails: string[], debugInfo: string) {
    let currentUsers = this.getUsers();

    // Sort
    currentUsers.sort(function (a, b) {
      let indexOfA = orderedEmails.indexOf(a.email);
      let indexOfB = orderedEmails.indexOf(b.email);

      if (indexOfA === -1 && indexOfB === -1) {
        return 1;
      }

      if (indexOfA === -1) {
        return 1;
      }

      if (indexOfB === -1) {
        return -1;
      }

      return indexOfA - indexOfB;
    });

    // Persist
    SharedUserManagerService._users = currentUsers;
    this.persistLoggedInUsers(debugInfo + '.setNewUserOrder');
  }

  getAllUserEmails(): string[] {
    return _.map(this.getUsers(), (user: LoginUserModel) => {
      return user.email;
    });
  }

  getTokenByEmail(userEmail: string): string {
    if (!userEmail) {
      return undefined;
    }

    let auth: AuthUser = this.getUserAuthByEmail(userEmail);

    return auth ? auth.token.accessToken : undefined;
  }

  getUserIdByEmail(userEmail: string): string {
    if (!userEmail) {
      return undefined;
    }

    let user = this.getUserByEmail(userEmail);
    return user ? user.id : undefined;
  }

  getUserEmailById(userId: string): string {
    if (!userId) {
      return undefined;
    }

    let user = this.getUserById(userId);
    return user ? user.email : undefined;
  }

  getUserByEmail(userEmail: string): UserModel {
    if (!userEmail) {
      return undefined;
    }

    let userLogin = this.getUserLoginByEmail(userEmail);

    return userLogin ? userLogin.toUserModel() : undefined;
  }

  getUserById(userId: string): UserModel {
    if (!userId) {
      return undefined;
    }

    let userLogin = this.getUserLoginById(userId);

    return userLogin ? userLogin.toUserModel() : undefined;
  }

  getAccountSyncSettingsTypeByEmail(userEmail: string): SyncSettingsType {
    let loginData = this.getUserLoginByEmail(userEmail);

    if (!loginData) {
      return undefined;
    }

    return loginData.syncSettings.$type as SyncSettingsType;
  }

  getUserLoginByEmail(userEmail: string): LoginUserModel {
    if (!userEmail) {
      Logger.customLog(
        this.constructorName + ':getUserLoginByEmail() called with undefined email. Will return undefined',
        LogLevel.ERROR,
      );
      return undefined;
    }

    let user = _.find(this.getUsers(), { email: userEmail });

    // Fallback: reset local state and reload it from local-storage
    // (in case change was made on BE side)
    if (!user) {
      this.reloadUsersCache();
    }

    // Try again
    user = _.find(this.getUsers(), { email: userEmail });

    if (!user) {
      Logger.customLog(
        this.constructorName +
          ':getUserLoginByEmail() called with email that is not on the list of users. Email: ' +
          userEmail,
        LogLevel.ERROR,
      );
    }

    return user ? new LoginUserModel(user) : undefined;
  }

  getUserLoginById(userId: string): LoginUserModel {
    if (!userId) {
      return undefined;
    }

    let user = _.find(this.getUsers(), { id: userId });

    return user ? new LoginUserModel(user) : undefined;
  }

  getUserEmailAuthTypeEmail(userEmail: string): EmailServiceAuthType {
    return this._storageService.getItem(
      this._storageService.getKey(userEmail, StorageKey.emailAuthType),
    ) as EmailServiceAuthType;
  }

  getUserAuthByEmail(userEmail: string): AuthUser {
    if (_.isEmpty(SharedUserManagerService._userAuthByEmail)) {
      this.initUserAuthByEmail();
    }

    let auth: AuthUser = SharedUserManagerService._userAuthByEmail[userEmail];

    if (!auth) {
      auth = this.getUserAuth(userEmail);

      SharedUserManagerService._userAuthByEmail[userEmail] = auth;
    }

    if (_.isEmpty(auth)) {
      // Handle, log and thorw
      this.handleMissingAuth(userEmail);
      Logger.customLog(
        'No user auth data found for email ' + userEmail + ' popup will be opened and error will be thrown',
        LogLevel.WARN,
        LogTag.AUTH_AND_LOGIN,
      );
      throw new Error('No user auth data found for email ' + userEmail);
    }

    return auth;
  }

  isUserAccountSyncable(userEmail: string): boolean {
    let loginData = this.getUserLoginByEmail(userEmail);

    return !_.isEmpty(loginData.syncSettings) && loginData.syncSettings.isEnabled;
  }

  isUserOnboardingComplete(userEmail: string): boolean {
    let loginData = this.getUserLoginByEmail(userEmail);

    return !_.isEmpty(loginData.userSettings) && loginData.userSettings.onboardingComplete;
  }

  isUserAccountVerified(userEmail: string): boolean {
    let loginData = this.getUserLoginByEmail(userEmail);

    if (!_.isEmpty(loginData?.syncSettings)) {
      return true;
    } else {
      return loginData?.userSettings?.isPasswordVerified;
    }
  }

  setOnboardingCompleteOnServerByEmail(userEmail: string, debugInfo: string) {
    let userLogin = this.getUserLoginByEmailOrThrow(userEmail);

    userLogin.userSettings.onboardingComplete = true;
    this.addUser(userLogin, debugInfo + '.setOnboardingCompleteOnServerByEmail');
    this.userUpdate(userLogin, debugInfo + '.setOnboardingCompleteOnServerByEmail');
  }

  getUserLoginByEmailOrThrow(userEmail: string): LoginUserModel {
    let userLogin = this.getUserLoginByEmail(userEmail);

    if (_.isEmpty(userLogin)) {
      throw new Error('UserLogin data not found, userEmail=' + userEmail);
    }
    if (!userLogin.id) {
      throw new Error('UserLogin.id is invalid, id=' + userLogin.id);
    }

    return userLogin;
  }

  ///////////////////
  // GET EMAIL TYPE
  ///////////////////
  isGMailUser(forUserEmail: string): boolean {
    let auth = this.getUserAuthByEmail(forUserEmail);
    if (auth) {
      return auth.$type === EmailTypeEnum.AUTH_GOOGLE;
    }
    return false;
  }

  isMicrosoftExchangeUser(forUserEmail: string): boolean {
    let auth = this.getUserAuthByEmail(forUserEmail);
    if (auth) {
      return auth.$type === EmailTypeEnum.AUTH_EXCHANGE;
    }
    return false;
  }

  isMSGraphUser(forUserEmail: string): boolean {
    let auth = this.getUserAuthByEmail(forUserEmail);
    if (auth) {
      return auth.$type === EmailTypeEnum.AUTH_MICROSOFT;
    }
    return false;
  }

  isRemoteImapUser(forUserEmail: string): boolean {
    let auth = this.getUserAuthByEmail(forUserEmail);
    if (auth) {
      return auth.$type === EmailTypeEnum.AUTH_REMOTE_IMAP;
    }
    return false;
  }

  isImapUser(forUserEmail: string): boolean {
    let auth = this.getUserAuthByEmail(forUserEmail);
    if (auth) {
      return auth.$type === EmailTypeEnum.AUTH_IMAP_SMTP;
    }
    return false;
  }

  userUpdate(updatedUser: LoginUserModel, debugInfo: string) {
    if (!updatedUser) {
      return;
    }

    this.updateUsers(updatedUser, debugInfo + '.userUpdate');

    // Broadcast event
    let userUpdate = new UserUpdate();
    userUpdate.updatedUser = updatedUser;
    SharedSubjects._userUpdate$.next(userUpdate);
  }

  setOrUpdateUser(user: User, debugInfo: string) {
    let users = this.getUsers();
    if (_.isEmpty(users) || !_.find(users, u => u.id === user.id)) {
      this.setUser(<LoginUserModel>user, debugInfo + '.setOrUpdateUser');
    } else {
      this.userUpdate(<LoginUserModel>user, debugInfo + '.setOrUpdateUser');
    }
  }

  setUser(user: LoginUserModel, debugInfo: string) {
    if (!user || !user.email) {
      throw new Error('UserLogin cannot be nil');
    }

    this.addUser(user, debugInfo + '.setUser');
    this.updateUserMenu();

    // Send event for user set
    let userSetEvent = new UserSet();
    userSetEvent.userEmail = user.email;
    SharedSubjects._userSet$.next(userSetEvent);

    // Send event for user switch
    let userSwitch = new UserSwitch();
    userSwitch.reason = UserSwitchReason.addAccount;
    userSwitch.newUserEmail = user.email;
    SharedSubjects._userSwitch$.next(userSwitch);

    // Send event for user update
    let userUpdate = new UserUpdate();
    userUpdate.updatedUser = user;
    SharedSubjects._userUpdate$.next(userUpdate);
  }

  private handleMissingAuth(userEmail: string) {
    let popupData = new ConfirmationPopupOpenData();
    popupData.popupType = ConfirmationPopupType.AuthError;
    popupData.additionalData = userEmail;
    popupData.shared = true;

    SharedSubjects._confirmationPopup$.next(popupData);
  }

  isOnboardingCompleteOnServerByEmail(userEmail: string): boolean {
    let userLogin = this.getUserLoginByEmail(userEmail);
    if (_.isEmpty(userLogin)) {
      Logger.warn('User data cannot be found');
      return false;
    }

    return userLogin.userSettings.onboardingComplete;
  }

  setUserEmailAuthType(userEmail: string, emailAuthType: EmailServiceAuthType) {
    if (_.isEmpty(emailAuthType) || !userEmail) {
      throw new Error('User emailAuthType or user email cannot be nil');
    }

    this._storageService.setItem(this._storageService.getKey(userEmail, StorageKey.emailAuthType), emailAuthType);
  }

  setUserAuth(
    auth: AuthUser,
    reason: UserSwitchReason.addAccount | UserSwitchReason.refreshAccount = UserSwitchReason.refreshAccount,
    userEmail: string = '',
  ) {
    if (_.isEmpty(auth) || (!auth.email && !userEmail)) {
      Logger.customLog('User auth or user email cannot be nil', LogLevel.ERROR, LogTag.AUTH_AND_LOGIN);
      throw new Error('User auth or user email cannot be nil');
    }

    if (auth.email) {
      userEmail = auth.email;
    }

    // Store
    this._storageService.setStringifiedItem(this._storageService.getKey(userEmail, StorageKey.userAuth), auth, true);

    // Log
    Logger.customLog('Have set auth for ' + userEmail + ' because: ' + reason, LogLevel.INFO, LogTag.AUTH_AND_LOGIN);

    // Broadcast user auth change event
    let userAuthUpdate = new UserAuthUpdate();
    userAuthUpdate.updatedUserEmail = userEmail;
    SharedSubjects._userAuthUpdate$.next(userAuthUpdate);

    // No need to switch to this user when only refreshing the token
    if (reason === UserSwitchReason.refreshAccount) {
      return;
    }

    this._storageService.setItem(this._storageService.getKey(userEmail, StorageKey.userRegistered), true);
    Logger.customLog('Have set user registered for ' + userEmail, LogLevel.INFO, LogTag.AUTH_AND_LOGIN);
  }

  /**
   * Remove account
   * - stop sync for passed account SynchronizationFactory.destroy()
   * - remove passed account
   * - remove all localStorage data from this account
   * - if this is the current active user, login as next available user
   */
  removeAccountByEmail(userEmail: string, debugInfo: string) {
    if (!userEmail) {
      return;
    }

    Logger.customLog('Remove account (removeAccountByEmail)', LogLevel.INFO, LogTag.AUTH_AND_LOGIN);

    // Remove auth and other user data (LocalStorage)
    this.removeUserStorageItems(userEmail);

    // Remove user data (LocalStorage)
    this.removeUserByEmail(userEmail, debugInfo + '.removeAccountByEmail');

    // Remove from unread counts (LocalStorage)
    this.removeUnreadCountByEmail(userEmail);

    // Update main-thread menu
    this.updateUserMenu();

    // Signal user removal
    let userRemove = new UserRemove();
    userRemove.userEmail = userEmail;
    SharedSubjects._userRemove$.next(userRemove);
  }

  updateUserSettings(forUserEmail: string, settings: UserSettings, debugInfo: string) {
    let users = this.getUsers();
    let newUsers = users.map(user => {
      if (user.email === forUserEmail) {
        Object.assign(user.userSettings, settings);
      }
      return user;
    });

    SharedUserManagerService._users = newUsers;
    this.persistLoggedInUsers(debugInfo + '.updateUserSettings');
  }

  ///////////////////
  // Private helpers
  ///////////////////
  protected updateUsers(updatedUser: LoginUserModel, debugInfo: string) {
    let users = this.getUsers();
    let newUsers = users.map(user => {
      if (
        user.id === updatedUser.id &&
        (BaseModel.isRevisionGreaterThan(updatedUser, user) ||
          LoginUserModel.haveSyncSettingsChanged(updatedUser, user))
      ) {
        return Object.assign({}, user, updatedUser);
      }
      return user;
    });

    SharedUserManagerService._users = newUsers;
    this.persistLoggedInUsers(debugInfo + '.updateUsers');
  }

  protected persistLoggedInUsers(debugInfo: string) {
    // Persist
    this.persistUsersToLS(SharedUserManagerService._users, debugInfo + '.persistLoggedInUsers');

    // Broadcast
    SharedSubjects._resetLoggedInUsers$.next(new Trigger());
  }

  protected persistUsersToLS(users: LoginUserModel[], debugInfo: string) {
    // TMP debugging for lost auth issue. Remove as soon as that is solved
    Logger.customLog(
      `${Time.getTimestamp()} - Will set users to storage called from ${debugInfo}: ${JSON.stringify(_.map(users, u => u.email))}`,
      LogLevel.INFO,
      LogTag.AUTH_AND_LOGIN,
    );
    this._storageService.setStringifiedItem(StorageKey.users, users);
  }

  handleTokenRevokedForUser(userEmail: string, debugInfo: string) {
    // Filter out disconnected account
    let validUserList = _.filter(
      SharedUserManagerService._users,
      (user: User) => user.email !== userEmail,
    ) as LoginUserModel[];

    // Fix last user state
    this._storageService.setItem(StorageKey.lastUser, _.first(validUserList)?.email);

    // Persist valid list. Leave cache as is. Will be updated on next user action
    this.persistUsersToLS(validUserList, debugInfo + '.handleTokenRevokedForUser');
  }

  /**
   * From 5.31.1 on, '<email>_auth' needs to be encrypted
   */
  private initUserAuthByEmail() {
    SharedUserManagerService._userAuthByEmail = {};

    _.forEach(this.getAllUserEmails(), userEmail => {
      let auth = this.getUserAuth(userEmail);

      if (auth) {
        SharedUserManagerService._userAuthByEmail[userEmail] = auth;
      }
    });
  }

  protected getUserAuth(userEmail: string): any {
    let key = this._storageService.getKey(userEmail, StorageKey.userAuth);
    let auth: AuthUser = this._storageService.getParsedItem(key, true);

    // If we can't get parsed auth with decryption, then auth is
    // NOT encrypted. We need to encrypt it and delete Local Storage log.
    if (!auth) {
      // Get plain text auth
      auth = this._storageService.getParsedItem(key);

      // If auth is still undefined we handle it
      if (!auth) {
        Logger.customLog('No user auth in local storage for ' + userEmail, LogLevel.WARN, LogTag.AUTH_AND_LOGIN);
        return undefined;
      }

      // Store encrypted
      this._storageService.setStringifiedItem(key, auth, true);

      Logger.customLog('Auth for user ' + userEmail + ' is now encrypted.', LogLevel.INFO, LogTag.AUTH_AND_LOGIN);
    }

    return auth;
  }

  protected removeUserStorageItems(userEmail: string) {
    if (!userEmail) {
      return;
    }

    /**
     * Get user keys
     */
    let keys = this._storageService.findKeysByPrefix(userEmail);

    /**
     * Remove keys
     */
    _.forEach(keys, (key: string) => {
      this._storageService.removeItem(key);
    });

    Logger.customLog('Have removed storage items for user: ' + userEmail, LogLevel.INFO, LogTag.AUTH_AND_LOGIN);
  }

  protected removeUserByEmail(userEmail: string, debugInfo: string) {
    if (!userEmail) {
      return;
    }

    // Remove from user list
    SharedUserManagerService._users = _.filter(SharedUserManagerService._users, (entry: LoginUserModel) => {
      return entry.email !== userEmail;
    });

    this.persistLoggedInUsers(debugInfo + '.removeUserByEmail');

    // Remove from list of synced users
    this.removeInitiallySyncedUserEmail(userEmail);

    Logger.customLog('Have removed user: ' + userEmail, LogLevel.INFO, LogTag.AUTH_AND_LOGIN);
  }

  private removeInitiallySyncedUserEmail(userEmail: string) {
    if (!userEmail) {
      return;
    }

    let users = this.getInitiallySyncedUserEmails();
    _.remove(users, email => userEmail === email);

    this._storageService.setStringifiedItem(StorageKey.usersInitiallySynced, users);
  }

  protected getInitiallySyncedUserEmails(): string[] {
    if (!SharedUserManagerService._usersInitiallySynced) {
      SharedUserManagerService._usersInitiallySynced =
        this._storageService.getParsedItem(StorageKey.usersInitiallySynced) || [];
    }

    return SharedUserManagerService._usersInitiallySynced;
  }

  protected removeUnreadCountByEmail(userEmail: string) {
    let unreadCountByUser: any[] = this._storageService.getParsedItem(StorageKey.userUnreadCount);

    unreadCountByUser = _.filter(unreadCountByUser, unreadByUser => {
      return unreadByUser.id !== userEmail;
    });

    this._storageService.setStringifiedItem(StorageKey.userUnreadCount, unreadCountByUser);
  }

  protected clearUserFromCache(userEmail: string) {
    // remove from user list
    SharedUserManagerService._users = _.filter(SharedUserManagerService._users, (entry: LoginUserModel) => {
      return entry.email !== userEmail;
    });
  }

  protected updateUserMenu() {
    this._electronService.ipcRenderer.send(IPC.SET_LOGGED_IN_USERS, this.getUsers());
  }

  protected addUser(userLogin: LoginUserModel, debugInfo: string) {
    if (_.isEmpty(userLogin)) {
      return;
    }

    SharedUserManagerService._users = _.filter(
      SharedUserManagerService._users,
      (entry: LoginUserModel) => entry.email !== userLogin.email,
    );

    SharedUserManagerService._users.push(userLogin);
    this.persistLoggedInUsers(debugInfo + '.addUser');
  }

  /////////////////
  // Subscriptions
  /////////////////
  private subscribeToUserAuthChange() {
    this.userAuthUpdateSub?.unsubscribe();
    this.userAuthUpdateSub = this.userAuthUpdate$
      .pipe(
        tap((userAuthUpdate: UserAuthUpdate) => {
          // Delete from cache. New value will be loaded from storage
          delete SharedUserManagerService._userAuthByEmail[userAuthUpdate.updatedUserEmail];

          Logger.customLog(
            `Have deleted ${userAuthUpdate.updatedUserEmail} from _userAuthByEmail because of this.userAuthUpdate$`,
            LogLevel.INFO,
            LogTag.AUTH_AND_LOGIN,
          );
        }),
        catchError(err => {
          Logger.error(err, 'Error in subscribeToUserAuthChange()', [LogTag.AUTH_AND_LOGIN, LogTag.INTERESTING_ERROR]);
          return of(undefined);
        }),
      )
      .subscribe();
  }

  private subscribeToUserReset() {
    this._userResetSub?.unsubscribe();
    this._userResetSub = this.resetLoggedInUsers$
      .pipe(
        tap(() => {
          this.reloadUsersCache();
        }),
      )
      .subscribe();
  }

  private subscribeToUserRemoved() {
    this._userRemovedSub?.unsubscribe();
    this._userRemovedSub = this.userRemove$
      .pipe(
        tap((removeEvent: UserRemove) => {
          this.reloadUsersCache();
        }),
      )
      .subscribe();
  }
}
