import * as _ from 'lodash';
import {
  OptimisticResponseData,
  OptimisticResponseState,
  PublishEventType,
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { FilterEnum, ListOfTags, ShowInViewObject } from '@shared/api/api-loop/models';
import { BatchActions } from '@dta/shared/models/batch-actions.model';
import { UnreadOptimisticResponseViewDefiners } from '@dta/shared/models/unread.model';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

/**
 * Transitional service to help us improve user perception of app speed.
 * This should be removed and replaced with a proper solution once "everything is a conversation"
 * API models are supported.
 */

export class OptimisticResponseHelper {
  // Mark changed objects with this property to have more control on UI what to override
  static optimisticResponseMarkerProperty: string = 'optimisticResponseAppliedAtISO';

  //////////////////
  // Public methods
  //////////////////
  static applyOptimisticResponse(
    forUserEmail: string,
    conversations: ConversationModel[],
    data: OptimisticResponseData,
    shouldRemoveConversation: (conversation: ConversationModel) => boolean,
  ) {
    if (_.isEmpty(conversations)) {
      return;
    }
    let conversationsToModify = this.getConversationsToModify(conversations, data);
    let modifiedConversations = this.applyOptimisticResponseToConversations(
      _.cloneDeep(conversationsToModify), // Clone before mutating
      data,
    );

    this.publishUpdates(forUserEmail, modifiedConversations, shouldRemoveConversation);
  }

  static triggerApplyOptimisticResponse(
    forUserEmail: string,
    conversationIdsToModify: string[] | 'all-in-view',
    optimisticResponseStatus: OptimisticResponseState,
    extraData: OptimisticResponseExtraData = {},
    broadcast?: boolean,
  ) {
    let optimisticResponseData = new OptimisticResponseData(forUserEmail);
    optimisticResponseData.conversationIdsToModify = conversationIdsToModify;
    optimisticResponseData.stateToApply = optimisticResponseStatus;
    optimisticResponseData.listOfSharedTags = extraData.listOfSharedTags;
    optimisticResponseData.listOfTags = extraData.listOfTags;
    optimisticResponseData.snippet = extraData.snippet;
    SharedSubjects._applyOptimisticResponse$.next(optimisticResponseData, broadcast);
  }

  static batchActionsToOptimisticResponseState(
    action: BatchActions,
    removeTag: boolean,
    sharedAction: boolean,
  ): OptimisticResponseState {
    switch (action) {
      case BatchActions.STAR:
      case BatchActions.UNSTAR:
        return removeTag ? OptimisticResponseState.UNSTAR : OptimisticResponseState.STAR;
      case BatchActions.FOLDER:
        return OptimisticResponseState.MOVE_TO_FOLDER;
      case BatchActions.MARKREAD:
      case BatchActions.MARKUNREAD:
        return removeTag ? OptimisticResponseState.READ : OptimisticResponseState.UNREAD;
      case BatchActions.TRASH:
      case BatchActions.UNTRASH:
        return removeTag
          ? sharedAction
            ? OptimisticResponseState.SHARED_UNDELETE
            : OptimisticResponseState.UNDELETE
          : sharedAction
            ? OptimisticResponseState.SHARED_DELETE
            : OptimisticResponseState.DELETE;
      case BatchActions.ARCHIVE:
      case BatchActions.UNARCHIVE:
        return removeTag
          ? sharedAction
            ? OptimisticResponseState.SHARED_UNARCHIVE
            : OptimisticResponseState.UNARCHIVE
          : sharedAction
            ? OptimisticResponseState.SHARED_ARCHIVE
            : OptimisticResponseState.ARCHIVE;
      default:
        return undefined;
    }
  }

  static calculateUnreadCounterDiffFromUpdates(
    currentConversations: ConversationModel[],
    updates: ConversationModel[],
    viewDefiners: UnreadOptimisticResponseViewDefiners,
  ): number {
    let diff = 0;

    let currentConversationsDict = _.keyBy(currentConversations, 'cardId' || '_id');
    updates = _.uniqBy(updates, 'cardId' || '_id');
    _.forEach(updates, (update: ConversationModel) => {
      if (update.cardId in currentConversationsDict || update.clientId in currentConversationsDict) {
        let id = update.cardId in currentConversationsDict ? update.cardId : update.clientId;
        let updatedUnreadCount = update.unreadCount.totalUnreadCount + update.unreadCount.totalUnreadCount;
        let currentUnreadCount =
          currentConversationsDict[id].unreadCount.totalUnreadCount +
          currentConversationsDict[id].unreadCount.totalUnreadCount;

        diff = this.processViewUnreads(diff, currentUnreadCount, updatedUnreadCount, viewDefiners);
      }
    });

    return diff;
  }

  ///////////////////
  // Private helpers
  ///////////////////
  private static processViewUnreads(
    diff: number,
    currentUnreadCount: number,
    updatedUnreadCount: number,
    viewDefiners: UnreadOptimisticResponseViewDefiners,
  ): number {
    if (!viewDefiners.isTeamContact) {
      if (currentUnreadCount > 0 && updatedUnreadCount === 0) {
        diff += -1;
      } else if (currentUnreadCount === 0 && updatedUnreadCount > 0) {
        diff += 1;
      }
    }
    return diff;
  }

  private static publishUpdates(
    forUserEmail: string,
    modifiedConversations: ConversationModel[],
    shouldRemoveConversation: (conversation: ConversationModel) => boolean,
  ) {
    if (_.isEmpty(modifiedConversations)) {
      return;
    }

    let { toRemove, toUpsert } = _.groupBy(modifiedConversations, c =>
      shouldRemoveConversation(c) ? 'toRemove' : 'toUpsert',
    );

    PublisherService.publishEvent(forUserEmail, toRemove, PublishEventType.Remove, false);

    PublisherService.publishEvent(forUserEmail, toUpsert, PublishEventType.Upsert, false);
  }

  private static getConversationsToModify(
    conversations: ConversationModel[],
    data: OptimisticResponseData,
  ): ConversationModel[] {
    if (data.conversationIdsToModify === 'all-in-view') {
      return conversations;
    }

    return _.filter(conversations, c => data.conversationIdsToModify.includes(c.cardId));
  }

  private static applyOptimisticResponseToConversations(
    conversations: ConversationModel[],
    data: OptimisticResponseData,
  ): ConversationModel[] {
    let changeDateIso = new Date().toISOString();

    return _.map(conversations, c => {
      switch (data.stateToApply) {
        //////////////////
        // READ / UNREAD
        //////////////////
        case OptimisticResponseState.READ:
          c.isUnread = false;
          break;
        case OptimisticResponseState.UNREAD:
          c.isUnread = true;
          break;
        //////////////////////
        // DELETE / UNDELETE
        //////////////////////
        case OptimisticResponseState.UNDELETE:
          c = this.removeFromShowInViewsByFilters(c, [FilterEnum.DELETED]);
          break;
        case OptimisticResponseState.DELETE:
          c = this.removeFromAllShowInViewsButByFilters(c, [FilterEnum.ALL_MESSAGES, FilterEnum.DELETED]);
          break;
        case OptimisticResponseState.SHARED_UNDELETE:
          c = this.removeFromShowInViewsByFilters(c, [FilterEnum.DELETED]);
          break;
        case OptimisticResponseState.SHARED_DELETE:
          c = this.removeFromAllShowInViewsButByFilters(c, [FilterEnum.ALL_MESSAGES, FilterEnum.DELETED]);
          break;
        ///////////////////////
        // ARCHIVE / UNARCHIVE
        ///////////////////////
        case OptimisticResponseState.UNARCHIVE:
          c = this.removeFromShowInViewsByFilters(c, [FilterEnum.ARCHIVED]);
          break;
        case OptimisticResponseState.ARCHIVE:
          c = this.removeFromAllShowInViewsButByFilters(c, [FilterEnum.ALL_MESSAGES, FilterEnum.ARCHIVED]);
          break;
        case OptimisticResponseState.SHARED_UNARCHIVE:
          c = this.removeFromShowInViewsByFilters(c, [FilterEnum.ARCHIVED]);
          break;
        case OptimisticResponseState.SHARED_ARCHIVE:
          c = this.removeFromAllShowInViewsButByFilters(c, [FilterEnum.ALL_MESSAGES, FilterEnum.ARCHIVED]);
          break;
        //////////////////
        // STAR / UNSTAR
        //////////////////
        case OptimisticResponseState.UNSTAR:
          c = this.removeFromShowInViewsByFilters(c, [FilterEnum.STARRED]);
          c.isStarred = false;
          break;
        case OptimisticResponseState.STAR:
          c.isStarred = true;
          break;
        //////////////////
        // MOVE TO FOLDER
        //////////////////
        case OptimisticResponseState.MOVE_TO_FOLDER:
          // Remove all
          c = this.removeFromAllShowInViewsButByFilters(c, [FilterEnum.ALL_MESSAGES]);
          break;
        case OptimisticResponseState.MOVE_TO_INBOX:
          // Remove all
          c = this.removeFromAllShowInViewsButByFilters(c, [FilterEnum.ALL_MESSAGES]);
          // Add Inbox
          c.showInViews = this.modifyShowInViewsObject(c.showInViews, [], FilterEnum.INBOX, false);
          break;
        ///////////////
        // SHARED-TAGS
        ///////////////
        case OptimisticResponseState.SHARED_TAGS_UPDATE:
          c.sharedTags = _.cloneDeep(data.listOfSharedTags);
          c.sharedTags[this.optimisticResponseMarkerProperty] = changeDateIso;
          break;
        ///////////////
        // TAGS
        ///////////////
        case OptimisticResponseState.TAGS_UPDATE:
          c.tags = _.cloneDeep(data.listOfTags);
          c.tags[this.optimisticResponseMarkerProperty] = changeDateIso;
          break;
        ///////////////
        // SNIPPET
        ///////////////
        case OptimisticResponseState.SNIPPET_UPDATE:
          if (!c.snippet) {
            return;
          }
          c.snippet.content = data.snippet;
          break;
        ///////////////
        // SNIPPET
        ///////////////
        case OptimisticResponseState.DRAFT_DELETE:
          c.draftTypes = [];
          c.privateDraft = undefined;
          break;
        default:
          break;
      }

      c[this.optimisticResponseMarkerProperty] = changeDateIso;
      return c;
    });
  }

  private static removeFromShowInViewsByFilters(
    conversation: ConversationModel,
    filters: FilterEnum[],
  ): ConversationModel {
    let showInViewsData: ShowInViewObject[] = conversation.showInViews;
    showInViewsData = _.filter(showInViewsData, (viewData: ShowInViewObject) => !filters.includes(viewData.filter));
    conversation.showInViews = showInViewsData;
    return conversation;
  }

  private static removeFromAllShowInViewsButByFilters(
    conversation: ConversationModel,
    filters: FilterEnum[],
  ): ConversationModel {
    let showInViewsData: ShowInViewObject[] = conversation.showInViews;
    showInViewsData = _.filter(showInViewsData, (viewData: ShowInViewObject) => filters.includes(viewData.filter));
    conversation.showInViews = showInViewsData;
    return conversation;
  }

  static modifyShowInViewsObject(
    showInViews: ShowInViewObject[],
    removeFilters: string[],
    addFilter: string,
    isMoveToFolder: boolean,
  ): ShowInViewObject[] {
    let uniqueShowInViews: ShowInViewObject[] = _.uniqBy(showInViews, (context: ShowInViewObject) =>
      [context.view, context.channelId].join(),
    );

    showInViews = _.filter(
      showInViews,
      showInView => !removeFilters.includes(showInView.filter) && !removeFilters.includes(showInView.folderId),
    );
    if (addFilter) {
      showInViews.push(
        ..._.map(_.cloneDeep(uniqueShowInViews), showInView => {
          if (isMoveToFolder) {
            showInView.folderId = addFilter;
          } else {
            showInView.filter = addFilter as FilterEnum;
          }
          return showInView;
        }),
      );
    }
    return showInViews;
  }
}

export interface OptimisticResponseExtraData {
  listOfSharedTags?: ListOfTags;
  listOfTags?: ListOfTags;
  snippet?: string;
  fromFolderId?: string;
  toFolderId?: string;
}
