import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsLabelSelectedPipe } from './is-label-selected.pipe';

@NgModule({
  declarations: [IsLabelSelectedPipe],
  exports: [IsLabelSelectedPipe],
  imports: [CommonModule],
})
export class IsLabelSelectedPipeModule {}
