import { LoopIDBDatabaseSchema, StoreTypes } from './indexedDB/interfaces';

export enum CollectionNameWeb {
  SharedTag = 'SharedTag',
  Template = 'Template',
  Tag = 'Tag',
  Contact = 'Contact',
  File = 'File',
  Comment = 'Comment',
  Conversation = 'Conversation',
  ConversationChange = 'ConversationChange',
  Card = 'Card',
  RetryQueue = 'RetryQueue',
  PushSync = 'PushSync',
  AvailabilityStatus = 'AvailabilityStatus',
  UserAvailabilityStatus = 'UserAvailabilityStatus',
  Integration = 'Integration'
}

///////////////////////////
/////// ~ INDEXES ~ ///////
///////////////////////////
/**
 * General (keep same name for both and use default one in code)
 */
export enum TypeIndex {
  indexName = '$type',
  keyPath = '$type'
}

export enum TypeIndexCache {
  indexName = '$type',
  keyPath = 'model.$type'
}

/**
 * Tag
 */
export enum TagTypeIndex {
  indexName = 'tagType',
  keyPath = 'tagType'
}

/**
 * Shared-tag
 */
export enum WorkspaceIdIndex {
  indexName = 'workspaceId',
  keyPath = 'workspaceId'
}

/**
 * Conversation
 */
export enum ConversationCardIdIndex {
  indexName = 'cardId',
  keyPath = 'model.cardId'
}
export enum ConversationSyncedCommentsIndex {
  indexName = 'SyncedComments',
  keyPath = 'model._syncedComments'
}

/**
 * Comment
 */
export enum ParentCardIdIndex {
  indexName = 'parentCardId',
  keyPath = 'model.parent.id'
}

export enum ParentCardClientIdIndex {
  indexName = 'parentCardClientId',
  keyPath = 'model.parent.clientId'
}

export enum QuoteCommentIdIndex {
  indexName = 'quoteCommentId',
  keyPath = 'model.quoteCommentId'
}

export enum CommentClientIdIndex {
  indexName = 'commentClientId',
  keyPath = 'model.clientId'
}

/**
 * Card
 */
export enum ChatCardContactIdIndex {
  indexName = 'chatCardContactId',
  keyPath = 'model._ex.chatCardContactId'
}

/**
 * Contact
 */
export enum EmailIndex {
  indexName = 'email',
  keyPath = 'email'
}

/**
 * Card
 */
export enum ConversationChangeIdIndex {
  indexName = 'cardId',
  keyPath = 'model.cardId'
}

/**
 * Retry queue
 */
export enum RetryAfter {
  indexName = 'retryAfter',
  keyPath = 'retryAfter'
}

/**
 * PushSync queue
 */
export enum PushSyncDataTypeIndex {
  indexName = '$type',
  keyPath = 'data.$type'
}

export enum RetryAfterTimestampIndex {
  indexName = 'retryAfterTimestamp',
  keyPath = 'retryAfterTimestamp'
}

export const WEB_DATABASE_SCHEMA: LoopIDBDatabaseSchema = {
  version: DB_REV,
  stores: [
    ////////////////////////
    // PERSISTENT STORAGE
    ////////////////////////
    /**
     * SHARED-TAG
     */
    {
      name: CollectionNameWeb.SharedTag,
      indexes: [
        {
          name: TypeIndex.indexName,
          keyPath: TypeIndex.keyPath,
          options: { unique: false }
        },
        {
          name: WorkspaceIdIndex.indexName,
          keyPath: WorkspaceIdIndex.keyPath,
          options: { unique: false }
        }
      ]
    },
    /**
     * TAG
     */
    {
      name: CollectionNameWeb.Tag,
      indexes: [
        {
          name: TagTypeIndex.indexName,
          keyPath: TagTypeIndex.keyPath,
          options: { unique: false }
        },
        {
          name: TypeIndex.indexName,
          keyPath: TypeIndex.keyPath,
          options: { unique: false }
        }
      ]
    },
    /**
     * TEMPLATE
     */
    {
      name: CollectionNameWeb.Template
    },
    /**
     * CONTACT
     */
    {
      name: CollectionNameWeb.Contact,
      indexes: [
        {
          name: EmailIndex.indexName,
          keyPath: EmailIndex.keyPath,
          options: { unique: false }
        }
      ]
    },

    //////////
    // QUEUE
    //////////
    /**
     * Retry queue
     */
    {
      name: CollectionNameWeb.RetryQueue,
      indexes: [
        {
          name: RetryAfter.indexName,
          keyPath: RetryAfter.keyPath,
          options: { unique: false }
        }
      ]
    },
    /**
     * Push sync queue
     */
    {
      name: CollectionNameWeb.PushSync,
      options: {
        alternativePrimaryKey: '_id'
      },
      indexes: [
        {
          name: PushSyncDataTypeIndex.indexName,
          keyPath: PushSyncDataTypeIndex.keyPath,
          options: { unique: false }
        },
        {
          name: RetryAfterTimestampIndex.indexName,
          keyPath: RetryAfterTimestampIndex.keyPath,
          options: { unique: false }
        }
      ]
    },

    /////////////////
    // CACHE STORAGE
    /////////////////
    /**
     * CONVERSATION
     */
    {
      name: CollectionNameWeb.Conversation,
      options: {
        storageType: StoreTypes.CACHE,
        maxCacheSize: 200
      },
      indexes: [
        {
          name: ConversationCardIdIndex.indexName,
          keyPath: ConversationCardIdIndex.keyPath,
          options: { unique: false }
        },
        {
          name: ConversationSyncedCommentsIndex.indexName,
          keyPath: ConversationSyncedCommentsIndex.keyPath,
          options: { unique: false }
        }
      ]
    },
    /**
     * COMMENT
     */
    {
      name: CollectionNameWeb.Comment,
      options: {
        storageType: StoreTypes.CACHE,
        maxCacheSize: 1024
      },
      indexes: [
        {
          name: TypeIndexCache.indexName,
          keyPath: TypeIndexCache.keyPath,
          options: { unique: false }
        },
        {
          name: ParentCardIdIndex.indexName,
          keyPath: ParentCardIdIndex.keyPath,
          options: { unique: false }
        },
        {
          name: ParentCardClientIdIndex.indexName,
          keyPath: ParentCardClientIdIndex.keyPath,
          options: { unique: false }
        },
        {
          name: QuoteCommentIdIndex.indexName,
          keyPath: QuoteCommentIdIndex.keyPath,
          options: { unique: false }
        },
        {
          name: CommentClientIdIndex.indexName,
          keyPath: CommentClientIdIndex.keyPath,
          options: { unique: false }
        }
      ]
    },
    /**
     * CARD
     */
    {
      name: CollectionNameWeb.Card,
      options: {
        storageType: StoreTypes.CACHE,
        maxCacheSize: 300
      },
      indexes: [
        {
          name: ChatCardContactIdIndex.indexName,
          keyPath: ChatCardContactIdIndex.keyPath,
          options: { unique: false }
        }
      ]
    },
    /**
     * FILE
     */
    {
      name: CollectionNameWeb.File,
      options: {
        storageType: StoreTypes.CACHE,
        maxCacheSize: 100
      }
    },
    /**
     * STATUS
     */
    {
      name: CollectionNameWeb.AvailabilityStatus
    },
    /**
     * USER AVAILABILITY STATUS
     */
    {
      name: CollectionNameWeb.UserAvailabilityStatus,
      options: {
        alternativePrimaryKey: 'userId'
      }
    },
    /**
     * INTEGRATION
     */
    {
      name: CollectionNameWeb.Integration
    },
    /**
     * ConversationChange
     */
    {
      name: CollectionNameWeb.ConversationChange,
      indexes: [
        {
          name: ConversationChangeIdIndex.indexName,
          keyPath: ConversationChangeIdIndex.keyPath,
          options: { unique: false }
        }
      ]
    }
  ]
};
