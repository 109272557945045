<div class="folder-wrapper">
  <ng-container *ngFor="let folder of folders; let i = index">
    <div
      *ngIf="folder.name"
      class="thread-folder"
      [tooltip]="{ content: folder.name, placement: TooltipPlacement.BOTTOM }"
    >
      {{ folder.name | folder }}
    </div>
  </ng-container>
</div>
