import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { ModalFormContentComponent } from './modal-form-content.component';
import { ModalFormContentDirective } from './modal-form-content.directive';
import { ButtonModule } from '@shared/ui/button/button.module';
import { ModalFormFooterModule } from '@shared/forms/shell/modal-form/components/modal-form-footer/modal-form-footer.module';
import { ModalFormDefaultFooterButtonsModule } from '@shared/forms/shell/modal-form/components/modal-form-default-footer-buttons/modal-form-default-footer-buttons.module';
import { ComponentRendererModule } from '@shared/ui/component-renderer/component-renderer.module';

@NgModule({
  declarations: [ModalFormContentComponent, ModalFormContentDirective],
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    ModalFormFooterModule,
    ButtonModule,
    ModalFormFooterModule,
    ModalFormDefaultFooterButtonsModule,
    ComponentRendererModule
  ],
  exports: [ModalFormContentComponent]
})
export class ModalFormContentModule {}
