import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TimerService } from '../../../../shared/utils/timer.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';

const momentConstructor: (value?: any) => moment.Moment = (<any>moment).default || moment;

@AutoUnsubscribe()
@Component({
  selector: 'time-ago',
  templateUrl: './time-ago.html',
  styleUrls: ['./time-ago.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeAgoComponent implements OnInit, OnDestroy, OnChanges {
  // Input / Output
  @Input() time: string;
  @Input() extraClass;
  @Input() extra: boolean;

  @Input() isCard: boolean = false;
  @Input() isComment: boolean = false;
  @Input() isChatComment: boolean = false;
  @Input() isThread: boolean = false;
  @Input() isFromSearch: boolean = false;
  @Input() isOnNewMessagesIndicator: boolean = false;

  text = '';
  titleText = '';

  // Subs
  private timerSub: Subscription;

  constructor(
    private _timerService: TimerService,
    private _changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['time']) {
      this.init();
    }
  }

  private init() {
    this.titleText = moment(this.time).format('D MMM YYYY HH:mm');

    this.subscribeToTimer();
  }

  private subscribeToTimer() {
    let diff = this.transform();
    let timer = this._timerService.minuteTimer;
    if (diff > 60) {
      timer = this._timerService.thirtyMinuteTimer;
    }

    this.timerSub && this.timerSub.unsubscribe();
    this.timerSub = timer.subscribe(() => {
      this.transform();
      this._changeDetector.detectChanges();
    });
  }

  /**
   * If used on Cards
   * FOURTH-1373
   *
   * CARDS: (Streams, Folders, Drafts, Trash)
   * Since 00:00 that day, format = "07:20"
   * Anytime during the previous 7 days, format = "Wed"
   * Anytime prior to the previous 7 days, within this calendar year, format = "10 May 12:19"
   * Anytime prior to the previous 7 days, prior to this calendar year, format = "10 May 2016 12:19"
   * @param {string} time
   */
  getCardsTimeFormat(time: string) {
    let isToday = moment(time).isSame(new Date(), 'day');
    let isLast7Days = moment(time).diff(moment(), 'day') > -7;
    let isSameYear = moment(time).isSame(new Date(), 'year');
    if (isToday) {
      return moment(time).format('HH:mm');
    } else if (isLast7Days) {
      return moment(time).format('ddd');
    } else if (isSameYear) {
      return moment(time).format('D MMM HH:mm');
    } else {
      return moment(time).format('D MMM YYYY HH:mm');
    }
  }

  getThreadTimeFormat(time: string) {
    let isToday = moment(time).isSame(new Date(), 'day');
    let isYesterday = moment(time).isSame(moment().add(-1, 'day'), 'day');
    let isLast7Days = moment(time).diff(moment(), 'day') > -7;
    let isSameYear = moment(time).isSame(new Date(), 'year');
    if (isToday) {
      return moment(time).format('HH:mm');
    } else if (isYesterday) {
      return 'Yesterday ';
    } else if (isLast7Days) {
      return moment(time).format('ddd');
    } else if (isSameYear) {
      return moment(time).format('D MMM');
    } else {
      return moment(time).format('D MMM YYYY');
    }
  }

  /**
   * If used on Comments
   * FOURTH-1373
   *
   * COMMENTS:
   * Since 00:00 that day, format = "07:20"
   * Anytime during the previous 7 days, format = "Wed 07:20"
   * If within the last 364 days, format = "10 May 12:19"
   * If prior to the last 364 days, format = "10 May 2016 12:19"
   * Week-day formats are: "Mon / Tue / Wed / Thu / Fri / Sat / Sun"
   * @param {string} time
   */
  getCommentTimeFormat(time) {
    let isToday = moment(time).isSame(new Date(), 'day');
    let isLast7Days = moment(time).diff(moment(), 'day') > -7;
    let isSameYear = moment(time).isSame(new Date(), 'year');
    if (isToday) {
      return moment(time).format('HH:mm');
    } else if (isLast7Days) {
      return moment(time).format('ddd HH:mm');
    } else if (isSameYear) {
      return moment(time).format('D MMM HH:mm');
    } else {
      return moment(time).format('D MMM YYYY HH:mm');
    }
  }

  getChatCommentTimeFormat(time) {
    return moment(time).format('HH:mm');
  }

  transform() {
    let now = moment();
    let diff = now.diff(moment(this.time), 'seconds');
    if (diff < 60 && !this.isThread && !this.isOnNewMessagesIndicator) {
      this.text = 'just now';
    } else if (this.isThread) {
      this.text = this.getThreadTimeFormat(this.time);
    } else if (this.isCard) {
      this.text = this.getCardsTimeFormat(this.time);
    } else if (this.isComment) {
      this.text = this.getCommentTimeFormat(this.time);
    } else if (this.isChatComment) {
      this.text = this.getChatCommentTimeFormat(this.time);
    } else if (this.isOnNewMessagesIndicator) {
      this.text = moment(this.time).format('HH:mm on MMMM Do');
    } else if (this.isFromSearch) {
      if (moment(this.time).isSame(moment(), 'year')) {
        this.text = moment(this.time).format('DD MMM');
      } else {
        this.text = moment(this.time).format('DD MMM YY');
      }
    } else if (!this.isCard && this.extra && now.diff(moment(this.time), 'hours') > 2) {
      this.text = momentConstructor(this.time).format('MMM Do YYYY, h:mm:ss a');
    } else if (now.diff(moment(this.time), 'years') > 2000) {
      this.text = '';
    } else {
      this.text = momentConstructor(this.time).from(momentConstructor());
    }
    return now.diff(moment(this.time), 'minutes');
  }
}
