import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ModalFormContentComponentData } from '../../internals/modal-form-content-component-data';

@Component({
  selector: 'loop-modal-form-default-footer-buttons',
  template: `<loop-button
      *ngIf="isCloseButtonVisible"
      [hasSmallBorder]="true"
      (click)="modalFormContentComponent.closeForm()"
      color="systemContrast"
      fill="transparent"
      >{{ closeButtonText }}</loop-button
    >
    <loop-button
      [isLoading]="(modalFormContentComponent.isLoading$ | async) === true"
      [hasSmallBorder]="true"
      (click)="modalFormContentComponent.submitForm()"
      color="primary"
      >{{ okButtonText }}</loop-button
    >`,
  styles: [':host { display: contents; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFormDefaultFooterButtonsComponent {
  protected readonly modalFormContentComponent: ModalFormContentComponentData = inject(ModalFormContentComponentData);

  @Input() okButtonText: string = 'Ok';
  @Input() closeButtonText: string = 'Cancel';
  @Input() isCloseButtonVisible: boolean = true;
}
