import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { BasePopulateService } from '../base-populate/base-populate.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import {
  CommentBaseModel,
  CommentChatModel,
  CommentMailExtraData,
  CommentModel,
  CommentTemplateModel,
  QuoteCommentModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import { ContactBaseModel, ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { SharedTagReactionModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { CommentDraftModel } from 'dta/shared/models-api-loop/comment/comment.model';
import { GroupType } from '@shared/api/api-loop/models/group-type';

@Injectable()
export class CommentPopulateService extends BasePopulateService<CommentModel> {
  private _commentService: CommentService;

  constructor(
    protected _contactService: ContactService,
    protected _contactStoreFactory: ContactStoreFactory,
  ) {
    super(_contactService, _contactStoreFactory);
  }

  get constructorName(): string {
    return 'CommentPopulateService';
  }

  set commentService(_service: CommentService) {
    this._commentService = _service;
  }

  populate(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return this.populateWithContacts(forUserEmail, comments).pipe(
      mergeMap((_comments: CommentModel[]) => {
        return this.populateWithQuotes(forUserEmail, _comments);
      }),
      mergeMap((_comments: CommentModel[]) => {
        return this.populateSharedTags(forUserEmail, _comments);
      }),
      mergeMap((_comments: CommentModel[]) => {
        return this.populateSendAs(forUserEmail, _comments);
      }),
    );
  }

  reduce(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return this.contactsToReducedForm(forUserEmail, comments);
  }

  private populateWithContacts(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    let undecoratedContactsIds = comments.map(comment => comment.getAllContacts().map(contact => contact.id)).flat();
    undecoratedContactsIds = _.filter(undecoratedContactsIds, id => id !== undefined);
    undecoratedContactsIds = _.uniq(undecoratedContactsIds);
    return this.findOrFetchContactsByCard(forUserEmail, undecoratedContactsIds, comments[0]?.parent?.id).pipe(
      map((contacts: ContactModel[]) => {
        comments.forEach(comment => comment.populateWithContacts(contacts));
        return comments;
      }),
    );
  }

  private populateWithQuotes(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    let quotedCommentIds = _.map(comments, comment => {
      if (comment instanceof CommentChatModel && !_.isUndefined(comment.quoteCommentId)) {
        return comment.quoteCommentId;
      }
    });
    quotedCommentIds = quotedCommentIds.filter(id => id !== undefined);

    if (quotedCommentIds === undefined || quotedCommentIds.length === 0) {
      return of(comments);
    }

    return this._commentService.findOrFetchCommentsById(forUserEmail, quotedCommentIds).pipe(
      map((_comments: CommentModel[]) => {
        let quotesById = _.keyBy(_comments, 'id');

        _.forEach(comments, comment => {
          if (comment instanceof CommentChatModel && !_.isUndefined(comment.quoteCommentId)) {
            comment.quotedComment = new CommentChatModel(quotesById[comment.quoteCommentId]);
          }
        });

        return comments;
      }),
    );
  }

  private populateSendAs(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return from(comments).pipe(
      mergeMap((comment: CommentModel) => {
        if (comment instanceof CommentDraftModel) {
          return this.findAuthorAndAppendToEx(forUserEmail, comment);
        }

        return of(comment);
      }),
      toArray(),
    );
  }

  private findAuthorAndAppendToEx(forUserEmail: string, comment: CommentDraftModel): Observable<CommentDraftModel> {
    if (!comment.sendAs || comment.author.email === comment.sendAs) {
      return of(comment);
    }

    return this._contactService.findOrFetchContactByEmail(forUserEmail, comment.sendAs).pipe(
      mergeMap((contact: ContactModel) => {
        comment._ex = {
          ...comment._ex,
          sendAsContact: contact,
        };

        return of(comment);
      }),
    );
  }

  private populateSharedTags(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    let contactIds = [];
    let reactionTags = [];
    _.forEach(comments, comment => {
      if (comment instanceof CommentChatModel) {
        reactionTags = [...reactionTags, ...comment.getReactionSharedTags()];
      }
    });

    contactIds = _.uniq(
      _.map(reactionTags, (tag: SharedTagReactionModel) => {
        return tag.userId;
      }),
    );

    if (contactIds.length === 0) {
      return of(comments);
    }

    return this._contactService.getContactsByIds(forUserEmail, contactIds).pipe(
      map((contacts: ContactModel[]) => {
        let contactsById = _.keyBy(contacts, 'id');
        _.map(reactionTags, (tag: SharedTagReactionModel) => {
          // There is a chance you get reaction from someone that is not your contact
          // Keep reactorName in that case
          tag.reactorName = contactsById[tag.userId]?.name || tag.reactorName;
        });
        return comments;
      }),
    );
  }
  private contactsToReducedForm(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    // NOTE: This clone will prevent backwards reference mutation that can cause crash
    // due to reduced form of contact. This will mask all old references being used
    // that would cause crash otherwise.
    let clonedComments = CommentBaseModel.createList(_.cloneDeep(comments));
    let _allContacts = [];
    return from(clonedComments).pipe(
      map((comment: CommentModel) => {
        // Stash all contacts
        _allContacts.push(..._.cloneDeep(comment.getAllContacts()));

        if (comment instanceof CommentTemplateModel && comment.shareList === undefined) {
          return comment;
        }

        comment.contactsToReducedForm();
        return comment;
      }),
      toArray(),
      mergeMap((comment: CommentModel[]) => {
        // Get unique contacts and save unknown-ones
        let allUniqueContacts = _.uniqBy(_allContacts, 'id');
        allUniqueContacts = ContactBaseModel.createList(allUniqueContacts);

        return this._contactService.saveUnknownContacts(forUserEmail, allUniqueContacts).pipe(map(() => comment));
      }),
    );
  }
}
