import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { mergeMap, of, Subscription, tap } from 'rxjs';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { AppStateService } from '@shared/services/data/app-state/app-state.service';
import { TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';
import { RouterService } from '@dta/ui/services/router/router.service';
import { ContactHelperService } from 'web/app/services/contact-helper/contact-helper.service';
import { startWith } from 'rxjs/operators';
import { channelSettingsCopy } from '@shared/modules/main/user-settings/channels/channel-settings-copy';

@AutoUnsubscribe()
@Component({
  selector: 'channels-settings',
  templateUrl: './channels-settings.html',
  styleUrls: ['./../channels-settings.scss'],
  host: { class: 'main' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelsSettingsComponent implements OnInit, OnDestroy {
  TooltipPlacement = TooltipPlacement;

  //////////////
  // Properties
  //////////////
  channels: ContactModel[];
  showLoader: boolean = true;
  query: string;
  route: InboxSetup;
  notificationCopy = channelSettingsCopy.notificationCopy;

  /////////////////
  // Subscriptions
  /////////////////
  userSwitchSub: Subscription;

  constructor(
    private _changeDetection: ChangeDetectorRef,
    private _contactService: ContactService,
    private _contactHelperService: ContactHelperService,
    private _userManagerService: UserManagerService,
    private _webOnboardingService: WebOnboardingService,
    private _appStateService: AppStateService,
    private _routerService: RouterService,
  ) {}

  get viewCopy() {
    return channelSettingsCopy.viewCopy[this.route];
  }

  get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  get offline() {
    return !this._appStateService.isConnectionActive;
  }

  ngOnInit() {
    this.getDataFromRoute();
    this.getContacts();
  }

  ngOnDestroy() {}

  ////////////////
  // View methods
  ////////////////
  openWebSettings(channel: ContactModel) {
    this._webOnboardingService.openWebSettings(undefined, '/teams/' + channel.id, window.open());
  }

  ///////////////////
  // Private methods
  ///////////////////
  private triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }

  private getDataFromRoute() {
    let { routeParts } = this._routerService.getCurrentRouteData();

    if (routeParts.some(p => p === 'teams')) {
      this.route = InboxSetup.TEAMS;
    } else if (routeParts.some(p => p === 'shared-inboxes')) {
      this.route = InboxSetup.SHARED_INBOXES;
    } else if (routeParts.some(p => p === 'personal-inboxes')) {
      this.route = InboxSetup.PERSONAL_INBOXES;
    } else {
      throw new Error('Component is not supposed to be used outside of shared-inboxes or teams routes');
    }
  }

  private getContacts() {
    this.userSwitchSub?.unsubscribe();
    this.userSwitchSub = this._userManagerService.userSwitch$
      .pipe(
        tap(() => {
          this.showLoader = true;
        }),
        mergeMap(() =>
          this._contactHelperService.subscribeForSidebarChanges(this.currentUserEmail).pipe(startWith(undefined)),
        ),
        mergeMap(() => this._userManagerService.onAllBlockingSyncDone(this.currentUserEmail)),
        mergeMap(() => {
          switch (this.route) {
            case InboxSetup.SHARED_INBOXES:
              return this._contactService.getAllSharedInboxes(this.currentUserEmail);
            case InboxSetup.TEAMS:
              return this._contactService.getTeamChannels(this.currentUserEmail);
            case InboxSetup.PERSONAL_INBOXES:
              return this._contactService.getAllPersonalInboxes(this.currentUserEmail);
          }
        }),
        tap(channels => {
          this.channels = channels;
          this.showLoader = false;
          this.triggerChangeDetection();
        }),
      )
      .subscribe();
  }
}

export enum InboxSetup {
  TEAMS = 'teams',
  SHARED_INBOXES = 'shared-inboxes',
  PERSONAL_INBOXES = 'personal-inboxes',
}
