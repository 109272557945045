import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAttachmentComponent } from '@shared/modules/files/components/common-attachment/common-attachment.component';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { FileNameModule } from '@shared/modules/files/components/file-name/file-name.module';
import { DropdownModule } from '@shared/ui/dropdown/dropdown.module';
import { ClipboardServiceModule } from '@shared/shared/clipboard/clipboard-service/clipboard-service.module';
import { FileSizeModule } from '@dta/ui/pipes/file-size/file-size.module';
import { FileIconModule } from '@dta/ui/components/common/file-icon/file-icon.module';
import { LoaderNewModule } from '@dta/ui/components/common/loader-new/loader-new.module';
import { FileDragDropModule } from '@dta/ui/directives/file-drag-drop/file-drag-drop.module';
import { SleepClickEventModule } from '@dta/ui/directives/sleep-click-event/sleep-click-event.module';
import { IsLoadingAttachmentPipe } from './pipes/is-loading-attachment.pipe';

@NgModule({
  declarations: [CommonAttachmentComponent, IsLoadingAttachmentPipe],
  imports: [
    CommonModule,
    IconModule,
    FileSizeModule,
    FileIconModule,
    LoaderNewModule,
    FileDragDropModule,
    FileNameModule,
    DropdownModule,
    SleepClickEventModule,
    ClipboardServiceModule
  ],
  exports: [CommonAttachmentComponent]
})
export class CommonAttachmentModule {}
