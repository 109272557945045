import { Observable } from 'rxjs';
import { BaseDaoServiceDta } from '@dta/backend/dao/base-dao.service.dta';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ResourceBase } from '@shared/api/api-loop/models';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { PushSyncModel } from '@dta/shared/models-api-loop/push-sync.model';

export interface PushSyncDaoServiceI {
  saveAllToQueue(forUserEmail: string, models: PushSyncModel[]): Observable<PushSyncModel[]>;

  // Remove
  removeAllFromQueue(forUserEmail: string, models: PushSyncModel[]): Observable<any>;
  removeListOfTags(forUserEmail: string, listOfTags: PushSyncModel[]): Observable<any>;
  removeDraftsForCommentMail(forUserEmail: string, comments: PushSyncModel[]): Observable<any>;

  // WEB methods
  getAllInQueue(forUserEmail: string): Observable<PushSyncModel[]>;
  clearCollection(forUserEmail: string): Observable<any>;

  // DTA methods
  count(forUserEmail: string): any;
  forceSave(forUserEmail: string): Observable<any>;
  findComments(forUserEmail: string): Observable<PushSyncModel[]>;
  findConversationActions(forUserEmail: string): Observable<PushSyncModel[]>;
  findTemplates(forUserEmail: string): Observable<PushSyncModel[]>;
  findListOfTags(forUserEmail: string): Observable<PushSyncModel[]>;
  findCardUpdates(forUserEmail: string): Observable<PushSyncModel[]>;
  findAppointments(forUserEmail: string): Observable<PushSyncModel[]>;
  findAvailabilityStatuses(forUserEmail: string): Observable<PushSyncModel[]>;

  updateCommentsParentByOldCardId(forUserEmail: string, oldCardId: string, comment: CommentModel);
  findFolders(forUserEmail: string): Observable<PushSyncModel[]>;
}

export abstract class PushSyncDaoService
  extends BaseDaoServiceDta<BaseModel, ResourceBase>
  implements PushSyncDaoServiceI
{
  abstract saveAllToQueue(forUserEmail: string, models: PushSyncModel[]): Observable<PushSyncModel[]>;

  abstract removeAllFromQueue(forUserEmail: string, models: PushSyncModel[]): Observable<any>;
  abstract removeListOfTags(forUserEmail: string, listOfTags: PushSyncModel[]): Observable<any>;
  abstract removeDraftsForCommentMail(forUserEmail: string, comments: PushSyncModel[]): Observable<any>;

  abstract getAllInQueue(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract clearCollection(forUserEmail: string): Observable<any>;

  abstract count(forUserEmail: string): any;
  abstract forceSave(forUserEmail: string): Observable<any>;
  abstract findComments(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract findTemplates(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract findListOfTags(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract findCardUpdates(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract findAppointments(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract findAvailabilityStatuses(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract findSharedLabelTags(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract updateCommentsParentByOldCardId(forUserEmail: string, oldCardId: string, comment: CommentModel);
  abstract findConversationActions(forUserEmail: string): Observable<PushSyncModel[]>;
  abstract findFolders(forUserEmail: string): Observable<PushSyncModel[]>;
}
