import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { Conversation, ResourceBase } from '@shared/api/api-loop/models';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { BaseDaoServiceWeb } from '../../base/base-dao.service.web';
import { CollectionNameWeb } from '../../../database-schema';
import { ConversationChangeDaoServiceI } from '@shared/database/dao/conversation/conversation-change-dao.service';
import { ModelMappers } from '@dta/shared/models-api-loop/model-mappers/model-mappers';
import { DatabaseServiceWeb } from '../../../database.service.web';
import { ConversationChangeModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-change.model';

@Injectable()
export class ConversationChangeDaoServiceWeb
  extends BaseDaoServiceWeb<BaseModel, ResourceBase>
  implements ConversationChangeDaoServiceI
{
  constructor(protected _databaseFactory: DatabaseFactory) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'ConversationChangeDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.ConversationChange;
  }

  protected toModel(doc: Conversation): ConversationChangeModel {
    return ModelMappers.castToEndModel(doc);
  }

  findAllConversationsChanges(forUserEmail: string): Observable<ConversationChangeModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.getAll(this.collectionName);
      }),
      map(docs => this.toModels(docs)),
    ) as Observable<ConversationChangeModel[]>;
  }
}
