import { Injectable } from '@angular/core';
import { ContactModel } from 'dta/shared/models-api-loop/contact/contact.model';
import { Observable } from 'rxjs';
import { ContactViewDecorateService } from './view-data-decorators/contact-view-decorator/contact-view-decorate.service';
import { BaseDecorateService } from './base-decorate.service';
import { ContactExDecorateService } from './extra-data-decorators/contact-ex-decorator/contact-ex-decorate.service';

@Injectable()
export class ContactDecorateService extends BaseDecorateService<ContactModel> {
  constructor(
    private _contactViewDecorateService: ContactViewDecorateService,
    private _contactExDecorateService: ContactExDecorateService,
  ) {
    super();
  }

  decorateExtraData(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    return this._contactExDecorateService.decorate(forUserEmail, contact);
  }

  decorateViewData(forUserEmail: string, contact: ContactModel, force?: boolean): Observable<ContactModel> {
    return this._contactViewDecorateService.decorate(forUserEmail, contact, force);
  }
}
