import { Directive } from '@angular/core';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { catchError, filter, from, Observable, of } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { UserAvailabilityStatusDaoService } from '@shared/database/dao/user-availability/user-availability-dao.service';
import { UserAvailabilityStatusServiceI } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service.interface';
import { BaseService } from '../../base/base.service';
import { ContactModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { UserApiService } from '@shared/api/api-loop/services/user-api.service';
import { AvailabilityUserStatus } from '@shared/api/api-loop/models/availability-user-status';
import * as _ from 'lodash';

@Directive()
export class UserAvailabilityStatusService extends BaseService implements UserAvailabilityStatusServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _userAvailabilityStatusDaoService: UserAvailabilityStatusDaoService,
    protected _userApiService: UserApiService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'AvailabilityStatusService';
  }

  saveAll(
    forUserEmail: string,
    userAvailabilityStatuses: UserAvailabilityStatusModel[],
  ): Observable<UserAvailabilityStatusModel[]> {
    if (_.isEmpty(userAvailabilityStatuses)) {
      return of(userAvailabilityStatuses);
    }
    return this._userAvailabilityStatusDaoService.saveAll(forUserEmail, userAvailabilityStatuses);
  }

  saveAllAndPublish(
    forUserEmail: string,
    userAvailabilityStatuses: UserAvailabilityStatusModel[],
  ): Observable<UserAvailabilityStatusModel[]> {
    return this.saveAll(forUserEmail, userAvailabilityStatuses).pipe(
      tap((_userAvailabilityStatuses: UserAvailabilityStatusModel[]) => {
        PublisherService.publishEvent(forUserEmail, _userAvailabilityStatuses);
      }),
    );
  }

  findAllUserAvailabilityStatuses(forUserEmail: string): Observable<UserAvailabilityStatusModel[]> {
    return this._userAvailabilityStatusDaoService.findAllUserAvailabilityStatuses(forUserEmail);
  }

  createOrUpdateUserAvailabilityStatus(
    forUserEmail: string,
    userId: string,
    availabilityStatus: AvailabilityUserStatus,
  ): Observable<UserAvailabilityStatusModel> {
    return this._userApiService
      .User_PutUserAvailabilityStatus({ availabilityStatus: availabilityStatus }, forUserEmail)
      .pipe(
        mergeMap(() => {
          return this._userAvailabilityStatusDaoService.save(
            forUserEmail,
            UserAvailabilityStatusModel.createUserAvailabilityFromStatus(userId, availabilityStatus),
          );
        }),
        tap((status: UserAvailabilityStatusModel) => {
          PublisherService.publishEvent(forUserEmail, status);
        }),
      );
  }

  deleteUserAvailabilityStatus(
    forUserEmail: string,
    userAvailabilityStatus: UserAvailabilityStatusModel,
  ): Observable<any> {
    return this._userApiService
      .User_DeleteUserAvailabilityStatus({}, forUserEmail)
      .pipe(mergeMap(() => this.deleteUserAvailabilityStatusLocal(forUserEmail, userAvailabilityStatus)));
  }

  deleteUserAvailabilityStatusLocal(
    forUserEmail: string,
    userAvailabilityStatus: UserAvailabilityStatusModel,
  ): Observable<any> {
    return this._userAvailabilityStatusDaoService.removeById(forUserEmail, userAvailabilityStatus.userId).pipe(
      tap(() => {
        PublisherService.publishEvent(forUserEmail, userAvailabilityStatus, PublishEventType.Remove);
      }),
    );
  }

  findByUserId(forUserEmail: string, userId: string): Observable<UserAvailabilityStatusModel> {
    return this._userAvailabilityStatusDaoService.findByUserId(forUserEmail, userId).pipe(
      catchError(() => {
        return of(undefined);
      }),
    );
  }

  populateContactWithUserAvailability(forUserEmail: any, contact: ContactModel): Observable<ContactModel> {
    if (!(contact instanceof UserModel)) {
      return of(contact);
    }

    return this._userAvailabilityStatusDaoService.findByUserId(forUserEmail, contact.id).pipe(
      map((status: UserAvailabilityStatusModel) => {
        // BE returning stale data
        if (contact instanceof UserModel && contact.availabilityStatus) {
          contact.availabilityStatus = undefined;
        }

        if (status && contact instanceof UserModel) {
          contact.availabilityStatus = status.availabilityStatus;
        }
        return contact;
      }),
    );
  }

  populateContactsWithUserAvailability(forUserEmail, contacts: ContactModel[]): Observable<ContactModel[]> {
    return this._userAvailabilityStatusDaoService.findAllUserAvailabilityStatuses(forUserEmail).pipe(
      mergeMap((statuses: UserAvailabilityStatusModel[]) => {
        let statusesByUserId = _.keyBy(statuses, 'userId');
        return from(contacts).pipe(
          map((contact: ContactModel) => {
            // BE returning stale data
            if (contact instanceof UserModel && contact.availabilityStatus) {
              contact.availabilityStatus = undefined;
            }

            if (contact.id in statusesByUserId && contact instanceof UserModel) {
              contact.availabilityStatus = statusesByUserId[contact.id].availabilityStatus;
            }
            return contact;
          }),
          toArray(),
        );
      }),
    );
  }

  // Dao wrappers
  removeCollection(forUserEmail): Observable<any> {
    return this._userAvailabilityStatusDaoService.removeCollection(forUserEmail);
  }
}
