import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseFormComponent } from '@shared/forms/base-form.component';
import { AgendaSettings, ContactType, ResponseTypeModel } from '@shared/api/api-loop/models';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { RangeChooserFormGroup } from '@shared/modules/date/range-chooser/range-chooser.component';
import { SelectControlOption } from '@shared/modules/form-controls/select-control/select-control.component';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { buildCommentBody } from '@shared/modules/comments/helpers/build-comment-body';
import { IsOrganizerPipe } from '@shared/modules/agenda/pipes/is-organizer.pipe';
import { AppointmentService } from '@shared/services/data/appointment/appointment.service';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { ToolbarOption } from '@shared/modules/form-controls/wysiwyg-control/internal/toolbar-option';
import { map, Observable, of, startWith, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { publishReplay, refCount, take } from 'rxjs/operators';
import { CommentBaseModel, CommentMailModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { addHours } from 'date-fns';
import {
  AttendeeModel,
  ContactBaseModel,
  ContactModel,
  GroupModel
} from '@dta/shared/models-api-loop/contact/contact.model';
import { CardAppointmentModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';

type PersonalInboxesWithSettings = {
  group: GroupModel;
  agendaSettings?: AgendaSettings;
};

@UntilDestroy()
@Component({
  selector: 'agenda-form',
  templateUrl: './agenda-form.component.html',
  styleUrls: ['./agenda-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IsOrganizerPipe]
})
export class AgendaFormComponent extends BaseFormComponent<CardAppointmentModel> implements OnInit {
  private readonly commentService: CommentService = inject(CommentService);
  private readonly appointmentService: AppointmentService = inject(AppointmentService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly contactService: ContactService = inject(ContactService);
  private readonly settingsService: SettingsService = inject(SettingsService);
  private readonly isOrganizerPipe: IsOrganizerPipe = inject(IsOrganizerPipe);

  protected readonly ToolBarOption: typeof ToolbarOption = ToolbarOption;

  form: UntypedFormGroup;
  currentInboxSettings?: AgendaSettings;

  protected readonly clockSvg: NodeRequire = require('shared/assets/img/svg/time-later.svg');
  protected readonly peopleSvg: NodeRequire = require('shared/assets/img/svg/icons-people.svg');
  protected readonly locationSvg: NodeRequire = require('shared/assets/img/svg/location.svg');
  protected readonly sharedInboxIconSvg: NodeRequire = require('shared/assets/img/svg/shared_inbox_icon.svg');
  protected readonly bellSvg: NodeRequire = require('shared/assets/img/svg/bell.svg');
  protected readonly notesSvg: NodeRequire = require('shared/assets/img/svg/notes.svg');
  protected readonly checkmarkSvg: NodeRequire = require('shared/assets/img/svg/checkmark.svg');
  protected readonly questionMarkSvg: NodeRequire = require('shared/assets/img/svg/questionmark.svg');
  protected readonly declineSvg: NodeRequire = require('shared/assets/img/svg/close.svg');
  protected readonly organizerSvg: NodeRequire = require('shared/assets/img/svg/fu-organiser.svg');
  protected readonly removeSvg: NodeRequire = require('shared/assets/img/svg/close-icon.svg');

  protected readonly additionalContactFilter: Partial<ContactApiService.Contact_GetListParams> = {
    contactTypes: [ContactType.USER]
  };
  hoveredIndex: number | null = null;
  readonly ResponseTypeModel: typeof ResponseTypeModel = ResponseTypeModel;

  @Input() defaultDate: Date = new Date();
  @Input() appointment: CardAppointmentModel = new CardAppointmentModel();

  readonly personalInboxes$: Observable<PersonalInboxesWithSettings[]> = this.contactService
    .getAllPersonalInboxes(this.userManagerService.getCurrentUserEmail())
    .pipe(
      switchMap(personalInboxes => {
        return this.appointmentService
          .fetchAppointmentListSettings(this.userManagerService.getCurrentUserEmail(), {
            allTeamContexts: true
          })
          .pipe(
            map(settings => {
              const settingsByGroupId = (settings.resources || []).reduce((acc, curr) => {
                acc[curr.sharedContext.id] = curr;
                return acc;
              }, {});

              return personalInboxes.map(inbox => {
                return {
                  group: inbox,
                  agendaSettings: settingsByGroupId[inbox.id]
                };
              });
            })
          );
      }),
      publishReplay(1),
      refCount()
    );

  readonly options$: Observable<SelectControlOption<PersonalInboxesWithSettings>[]> = this.personalInboxes$.pipe(
    map((personalInboxes: PersonalInboxesWithSettings[]) => {
      return personalInboxes.map(personalInbox => {
        return {
          label: personalInbox.group.name,
          value: personalInbox.group.id,
          originalValue: personalInbox
        };
      });
    })
  );

  protected readonly reminderOptions: SelectControlOption[] = [
    { value: 5, label: '5 minutes before' },
    { value: 10, label: '10 minutes before' },
    { value: 15, label: '15 minutes before' },
    { value: 30, label: '30 minutes before' },
    { value: 60, label: '1 hour before' },
    { value: 120, label: '2 hours before' },
    { value: 60 * 24, label: '1 day before' },
    { value: 2 * 60 * 24, label: '2 days before' },
    { value: 7 * 60 * 24, label: '1 week before' }
  ];

  ngOnInit(): void {
    this.setForm();
    this.setInitialPersonalInbox();
    this.observeContextChange();
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      name: [this.appointment?.name || ''],
      rangeChooser: this.buildRangeChooserFormGroup(),
      attendees: [this.appointment?.attendees?.resources || []],
      location: [this.appointment.location || ''],
      description: [null], // has to be null in order for control to set the initial value correctly,
      contextId: [null],
      hasOnlineMeeting: [this.appointment.onlineMeeting ?? false],
      remindersInMinutes: [this.appointment?.remindersInMinutes?.resources || []]
    });

    if (this.appointment.id) {
      this.resolveCommentBody();
      if (this.isOrganizerPipe.transform(this.appointment)) {
        this.form.enable();
      } else {
        this.form.disable();
      }

      if (this.form.controls['hasOnlineMeeting'].value) {
        this.form.controls['hasOnlineMeeting'].disable();
      }
    }
  }

  private setInitialPersonalInbox(): void {
    this.personalInboxes$.pipe(untilDestroyed(this)).subscribe((personalInboxes: PersonalInboxesWithSettings[]) => {
      const inbox = personalInboxes.find(
        inbox =>
          inbox.group.syncingAccount.email ===
          this.settingsService.getDefaultFromAddress(this.userManagerService.getCurrentUserEmail())
      );

      this.form.controls['contextId'].setValue(inbox?.group.id || null);
    });
  }

  private observeContextChange(): void {
    this.form.controls['contextId'].valueChanges
      .pipe(
        startWith(this.form.controls['contextId'].value),
        switchMap(contextId => {
          return this.personalInboxes$.pipe(
            map(inboxes => {
              return inboxes.find(inbox => inbox.group.id === contextId);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((inbox: PersonalInboxesWithSettings | undefined) => {
        this.currentInboxSettings = inbox?.agendaSettings;
        this.changeDetector.detectChanges();
      });
  }

  private resolveCommentBody(): void {
    this.form.controls['description'].setValue(null);
    this.appointmentService
      .fetchAppointment(this.userManagerService.getCurrentUserEmail(), this.appointment.id)
      .pipe(
        switchMap(cardAppointmentModel => {
          if (cardAppointmentModel.comments.size <= 0) {
            return of(undefined);
          }
          const appointmentComment = new CommentMailModel(cardAppointmentModel.comments.resources[0]);
          return this.commentService.updateCommentBody(
            this.userManagerService.getCurrentUserEmail(),
            appointmentComment
          );
        }),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(comment => {
        if (!!comment && comment instanceof CommentMailModel) {
          this.form.controls['description'].setValue(comment.body?.content || '');
        } else {
          this.form.controls['description'].setValue(null);
        }
      });
  }

  removeAttendee(contact: ContactModel): void {
    const newValue = this.form.controls['attendees'].value.filter(
      attendee => (attendee.id && attendee.id !== contact.id) || attendee.email !== contact.email
    );
    this.form.controls['attendees'].setValue(newValue);
  }

  submitForm(): void {
    this.validateForm();

    if (this.form.valid) {
      const attendees: AttendeeModel[] = ContactBaseModel.createList(
        this.form.controls['attendees'].value.map(attendee => {
          attendee.$type = AttendeeModel.type;
          return attendee;
        })
      ) as AttendeeModel[];

      const rangeChooseControls = (this.form.controls['rangeChooser'] as RangeChooserFormGroup).controls.range.controls;
      const startDate = rangeChooseControls.from.value;
      const endDate = rangeChooseControls.to.value;

      const comment = buildCommentBody(
        this.userManagerService.getCurrentUserEmail(),
        this.userManagerService.getCurrentUser(),
        this.form.controls['description'].value || ''
      );

      const cardAppointmentModel = new CardAppointmentModel({
        ...this.appointment,
        $type: CardAppointmentModel.type,
        name: this.form.controls['name'].value,
        location: this.form.controls['location'].value,
        attendees: ContactBaseModel.createListOfResources(attendees),
        remindersInMinutes: BaseModel.createListOfResources(this.form.controls['remindersInMinutes'].value),
        startTime: startDate,
        endTime: endDate,
        comments: CommentBaseModel.createListOfResources([comment])
      });

      if (!this.appointment.id) {
        cardAppointmentModel.contextId = this.form.controls['contextId'].value;
      }

      if (this.form.controls['hasOnlineMeeting'].value) {
        cardAppointmentModel.onlineMeeting = this.currentInboxSettings?.onlineMeetings?.resources?.[0];
      }

      this.emitValue(cardAppointmentModel);
    }
  }

  private buildRangeChooserFormGroup(): RangeChooserFormGroup {
    return this.formBuilder.group({
      range: this.formBuilder.group({
        from: [this.appointment?.startTimeDate || addHours(new Date(), 4)],
        to: [this.appointment?.endTimeDate || addHours(new Date(), 6)]
      })
    }) as RangeChooserFormGroup;
  }
}
