import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'drag-bar',
  templateUrl: './drag-bar.component.html',
  styleUrls: ['./drag-bar.component.scss'],
})
export class DragBarComponent implements OnInit, OnDestroy {
  dragging: boolean;
  mouseUpFun;
  mouseDownFun;
  mouseMoveFun;

  private unListenMouseMove: () => void;
  private unListenMouseUp: () => void;

  @Input() right: boolean;
  @Output() onResize = new EventEmitter<MouseEvent>();
  @Output() onContextMenu = new EventEmitter<MouseEvent>();
  @Output() onDragChange = new EventEmitter<boolean>();

  constructor(private _renderer2: Renderer2) {}

  ngOnInit(): void {
    this.onMouseUpListener();
  }

  ngOnDestroy(): void {
    this.unListenMouseUp();
  }

  onMouseDown() {
    this.onDragChange.next(true);
    this.unListenMouseMove = this._renderer2.listen('document', 'mousemove', event => {
      this.resize(event);
    });
  }

  onMouseUpListener() {
    this.unListenMouseUp = this._renderer2.listen('document', 'mouseup', event => {
      this.onDragChange.next(false);
      if (this.unListenMouseMove) {
        this.unListenMouseMove();
      }
    });
  }

  private resize($event: MouseEvent) {
    this.onResize.next($event);
  }
}
