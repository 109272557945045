import { Pipe, PipeTransform } from '@angular/core';
import { CardAppointmentModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ResponseTypeModel } from '@shared/api/api-loop/models/response-type-model';

@Pipe({
  name: 'getAppointmentResponse',
})
export class GetAppointmentResponsePipe implements PipeTransform {
  transform(appointment: CardAppointmentModel, attendee: ContactModel): string {
    const appointmentAttendee = (appointment.attendees?.resources || []).find(
      _attendee => _attendee.id === attendee.id,
    );
    if (appointmentAttendee) {
      return appointmentAttendee.status;
    }
    return ResponseTypeModel.NEEDS_ACTION;
  }
}
