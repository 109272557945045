import { Injectable } from '@angular/core';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';

@Injectable()
export class WebOnboardingServiceWeb extends WebOnboardingService {
  protected readonly origin: string = 'web-app';

  protected appendAdditionalParameters(urlParams: URLSearchParams): URLSearchParams {
    urlParams.append('redirect_url', window.location.host);
    return urlParams;
  }

  protected open(url: string, newTab?: Window) {
    if (!newTab) {
      window.open(url, '_self');
      return;
    }

    newTab.location.href = url;
  }
}
