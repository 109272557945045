import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { AccountActionType, AuthActionType, TimeEvent, TrackingConstants } from './tracking.constants';
import { CommentChatModel, CommentMailModel } from '../../models-api-loop/comment/comment.model';
import { UserModel } from '../../models-api-loop/contact/contact.model';
import { SharedUserManagerService } from '../shared-user-manager/shared-user-manager.service';
import { EmailUtils, isWebApp } from '../../utils/common-utils';
import { SearchFilterType } from '../../models/search.model';
import { Logger } from '@shared/services/logger/logger';
import { FolderBase } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';

const MIXPANEL_CONSTS = {
  MIXPANEL_TOKEN_DEMO: 'ee8ad6e8c54c6ccd5c7bebb2ed2fa51f',
  MIXPANEL_TOKEN: 'fe4375b4ca18b647e8abd76492c997db'
};

const mixpanel = require('mixpanel-browser');

const allowedAnonymousEvents: string[] = [TrackingConstants.login];

@Injectable()
export class TrackingService {
  ////////////////////
  // State variables
  ////////////////////
  protected appVersion: string;

  constructor(protected _sharedUserManagerService: SharedUserManagerService) {}

  ////////////
  // TRACKERS
  ////////////
  track(forUserEmail: string, event: TrackingConstants | string, properties?: any) {
    if (!this.allowTrackingForEvent(forUserEmail, event)) {
      return;
    }

    this.getInstance(forUserEmail).track(event, properties);
  }

  timeEventStart(forUserEmail: string, timeEvent: TimeEvent) {
    if (!this.allowTrackingForEvent(forUserEmail, undefined)) {
      return;
    }

    let eventName = timeEvent + ' Completed';
    this.getInstance(forUserEmail).time_event(eventName);
  }

  timeEventMark(forUserEmail: string, timeEvent: TimeEvent, countOfItems?: number, msg?: string) {
    let eventName = timeEvent + ' Completed';

    let properties = {};
    if (countOfItems) {
      properties['Items'] = countOfItems;
    }
    if (msg) {
      properties['Context'] = msg;
    }
    this.track(forUserEmail, eventName, properties);
  }

  trackDetailViewTiming(forUserEmail: string, cardId: string, durationInSeconds: number) {
    let properties = {
      $duration: durationInSeconds,
      CardId: cardId
    };
    this.track(forUserEmail, TrackingConstants.detailViewTiming, properties);
  }

  trackFirstItemTiming(forUserEmail: string, view: string, durationInSeconds: number) {
    let properties = {
      $duration: durationInSeconds,
      View: view
    };
    this.track(forUserEmail, TrackingConstants.firstItemTiming, properties);
  }

  trackMenuAction(forUserEmail: string, action: string) {
    let properties = {
      Action: action
    };
    this.track(forUserEmail, TrackingConstants.menuAction, properties);
  }

  trackNewInstallation() {
    this.getInstance(undefined, true).track('New installation');
  }

  trackContextMenuOpened(forUserEmail: string) {
    this.track(forUserEmail, TrackingConstants.contextMenuOpened);
  }

  trackNoticeBarAction(forUserEmail: string, properties: NoticeActionProperties) {
    this.track(forUserEmail, TrackingConstants.noticeBarAction, properties);
  }

  trackDropAction(forUserEmail: string, dropLocationType: 'accepting' | 'non-accepting', dropLocation?: string) {
    let properties = {
      'Drop Location Type': dropLocationType,
      'Drop Location': dropLocation
    };
    this.track(forUserEmail, TrackingConstants.dropAction, properties);
  }

  updateLastSeenParameter(forUserEmail: string) {
    if (!this.allowTrackingForEvent(forUserEmail, undefined)) {
      return;
    }

    this.getInstance(forUserEmail).people.set(TrackingConstants.updateProfileReason, 'App usage');
  }

  trackFavorite(forUserEmail: string, value: boolean, origin: string = 'Channel Header') {
    let properties = {
      'Type': value ? 'Favorite' : 'Unfavorite',
      'Action Origin': origin
    };
    this.track(forUserEmail, TrackingConstants.trackFavoriteChange, properties);
    if (value) {
      this.setIncrementProp(forUserEmail, TrackingConstants.incrementalFavoritesAdded);
    }
  }

  trackUpdater(forUserEmail: string, action: string) {
    let properties = {
      Action: action
    };
    this.track(forUserEmail, TrackingConstants.updaterEventName, properties);
  }

  trackCreateResources(forUserEmail: string, type: string) {
    let properties = {
      Resource: type
    };
    this.track(forUserEmail, TrackingConstants.create, properties);
  }

  trackDidPin(forUserEmail: string, origin: string) {
    this.setIncrementProp(forUserEmail, TrackingConstants.incrementalPinnedMessages);

    let properties = {
      Type: 'Pin',
      Automatic: false,
      SystemBoard: false,
      Origin: origin
    };
    this.track(forUserEmail, TrackingConstants.pinUnpinEventName, properties);
  }

  trackDidUnpin(forUserEmail: string, origin: string) {
    let properties = {
      Type: 'Unpin',
      Automatic: false,
      SystemBoard: false,
      Origin: origin
    };
    this.track(forUserEmail, TrackingConstants.pinUnpinEventName, properties);
  }

  trackNewChatMessage(forUserEmail: string, post, sentToTeam) {
    let properties = {
      messageType: 'chatReply',
      file: post.attachments && post.attachments.resources && post.attachments.resources.length,
      signature: false,
      sentToTeam: sentToTeam
    };
    this.setIncrementProp(forUserEmail, TrackingConstants.incrementalMessagesSent);
    this.track(forUserEmail, TrackingConstants.newMessageEventName, properties);
  }

  trackNewMessage(
    forUserEmail: string,
    post: CommentMailModel,
    draft?,
    reply?,
    forward?,
    replyToOne?,
    inviteActionType?: string,
    hasSignature?: boolean
  ) {
    let fileCount = _.has(post, 'attachments.resources') ? post.attachments.resources.length : 0;
    let shareList = post.to.resources.length;
    let ccUsers = _.has(post, 'cc.resources') ? post.cc.resources.length : 0;
    let bccUsers = _.has(post, 'bcc.resources') ? post.bcc.resources.length : 0;
    let sentToTeam =
      post.to.resources && post.to.resources.some(recipient => recipient.$type && recipient.$type === 'Group');
    let actionType = 'New';
    if (reply) {
      actionType = 'Reply To All';
    } else if (replyToOne) {
      actionType = 'Reply To One';
    } else if (forward) {
      actionType = 'Forward';
    } else if (inviteActionType) {
      actionType = inviteActionType;

      let recipients = post.to.resources;
      recipients.forEach(recipient => {
        if (recipient.$type === UserModel.type) {
          let props = {
            Invitee: (recipient as UserModel).email,
            InvitedBy: forUserEmail
          };
          this.track(forUserEmail, TrackingConstants.userInvitedEventName, props);
        }
      });
    }

    let properties = {
      'messageType': reply ? 'reply' : 'card',
      'Files': fileCount,
      'Included Users': shareList,
      'Included CC Users': ccUsers,
      'Included BCC Users': bccUsers,
      'fromDraft': !_.isEmpty(draft),
      'actionType': actionType,
      'Sent To Team': sentToTeam,
      'signature': hasSignature
    };

    this.setIncrementProp(forUserEmail, TrackingConstants.incrementalMessagesSent);
    this.track(forUserEmail, TrackingConstants.newMessageEventName, properties);
  }

  trackLoopMessages(forUserEmail: string, posts: CommentChatModel[]) {
    posts.forEach(post => {
      const sentToTeam =
        post.shareList &&
        post.shareList.resources &&
        post.shareList.resources.some(recipient => recipient.$type === 'Group');

      const properties = {
        'messageType': post.parent && post.parent.id ? 'loopReply' : 'newLoopIn',
        'Included Users': post.shareList.resources?.length,
        'Included CC Users': 0,
        'Included BCC Users': 0,
        'Files': post.attachments && post.attachments.resources && post.attachments.resources.length,
        'fromDraft': false,
        'actionType': post.parent ? 'Reply To All' : 'New',
        'Sent To Team': sentToTeam,
        'signature': false
      };

      this.setIncrementProp(forUserEmail, TrackingConstants.incrementalMessagesSent);
      this.track(forUserEmail, TrackingConstants.newMessageEventName, properties);
    });
  }

  trackViewOpened(forUserEmail: string, origin: string) {
    let properties = {
      View: 'Compose Email',
      Origin: origin,
      Action: 'Click'
    };
    this.track(forUserEmail, TrackingConstants.navigationEventName, properties);
  }

  trackAccountAction(forUserEmail: string, type: AccountActionType, reason?: string) {
    if (reason === 'Refresh token') {
      return;
    }

    let properties = {
      Action: type
    };

    if (!_.isEmpty(reason)) {
      properties['Reason'] = reason;
    }

    this.track(forUserEmail, TrackingConstants.accountActionEventName, properties);
  }

  trackAuthAction(forUserEmail: string, type: AuthActionType) {
    let properties = {
      Action: type
    };

    this.track(forUserEmail, TrackingConstants.authActionEventName, properties);
  }

  trackSubscribeStatusChange(forUserEmail: string, action: 'subscribe' | 'unsubscribe') {
    let properties = {
      Action: action
    };

    this.track(forUserEmail, TrackingConstants.ContactSubscribeStatus, properties);
  }

  trackFilterSelected(forUserEmail: string, filterType) {
    let properties = {
      'Action': 'Select Filter',
      'Filter Type': filterType === SearchFilterType.TO ? 'To' : 'From' // To, From
    };
    this.track(forUserEmail, TrackingConstants.searchActionEventName, properties);
  }

  trackChannelSearch(
    forUserEmail: string,
    unreadFilter: boolean,
    view: FolderBase,
    sharedTags: string[],
    querySearch: boolean
  ) {
    let properties = {
      UnreadFilter: unreadFilter,
      View: view.name,
      SharedTags: sharedTags,
      QuerySearch: querySearch
    };

    this.track(forUserEmail, TrackingConstants.channelSearchAction, properties);
  }

  trackSearchAction(forUserEmail: string, action: string = 'Select recent search') {
    let properties = {
      Action: action
    };
    this.track(forUserEmail, TrackingConstants.searchActionEventName, properties);
  }

  trackRsvpAction(forUserEmail: string, properties) {
    this.track(forUserEmail, TrackingConstants.rsvpAction, properties);
  }

  trackReadStatusChange(
    forUserEmail: string,
    read: boolean = true,
    origin = 'Discussion opened',
    numberOfMessages: number = 1
  ) {
    let properties = {
      'Action Origin': origin,
      'Change': read ? 'Read' : 'Unread',
      'Number of messages': numberOfMessages
    };
    this.track(forUserEmail, TrackingConstants.readStatusChangeAction, properties);
  }

  trackStarring(forUserEmail: string, origin: string = 'Discussion Toolbar', starred: boolean = true) {
    let properties = {
      Action: starred ? 'Starred' : 'Unstarred',
      Origin: origin
    };
    this.track(forUserEmail, TrackingConstants.trackStarredStatusChange, properties);
  }

  trackMuteCard(forUserEmail: string, origin: string, mute: boolean = true) {
    let properties = {
      Action: mute ? 'Mute' : 'Unmute',
      Origin: origin
    };
    this.track(forUserEmail, TrackingConstants.trackCardMuteStatusChange, properties);
  }

  trackTeamAction(
    forUserEmail: string,
    type: string,
    addedAdmins: number,
    removedAdmins: number,
    addedMembers: number,
    removedMembers: number
  ) {
    let options = {};
    options[TrackingConstants.teamActionType] = type;
    options[TrackingConstants.teamActionAddedAdmins] = addedAdmins;
    options[TrackingConstants.teamActionRemovedAdmins] = removedAdmins;
    options[TrackingConstants.teamActionAddedMembers] = addedMembers;
    options[TrackingConstants.teamActionRemovedMembers] = removedMembers;

    if (type === 'Create') {
      this.setIncrementProp(forUserEmail, TrackingConstants.incrementalGroupsCreated);
    }
    this.track(forUserEmail, TrackingConstants.teamActionEventName, options);
  }

  trackTrashing(
    forUserEmail: string,
    deleted: boolean = true,
    origin: string = 'Thread Detail',
    isSharedAction: boolean = false
  ) {
    let properties = {
      Result: deleted
        ? isSharedAction
          ? 'Shared Email Deleted'
          : 'Email Deleted'
        : isSharedAction
          ? 'Shared Email Undeleted'
          : 'Email Undeleted',
      Gesture: 'Click',
      Origin: origin
    };
    this.track(forUserEmail, TrackingConstants.trashAction, properties);
  }

  trackArchiving(
    forUserEmail: string,
    archived: boolean = true,
    origin: string = 'Thread Detail',
    isSharedAction: boolean = false
  ) {
    let properties = {
      Result: archived
        ? isSharedAction
          ? 'Shared Email Archived'
          : 'Email Archived'
        : isSharedAction
          ? 'Shared Email Unarchived'
          : 'Email Unarchived',
      Gesture: 'Click',
      Origin: origin
    };
    this.track(forUserEmail, TrackingConstants.archiveAction, properties);
  }

  trackFolderAction(forUserEmail: string, type: string = 'Created', origin: string = 'Folder List') {
    let properties = {
      'Type': type,
      'Board Type': 'Personal',
      'Origin': origin
    };
    this.track(forUserEmail, TrackingConstants.folderAction, properties);
    if (type === 'Create') {
      this.setIncrementProp(forUserEmail, TrackingConstants.incrementalBoardsCreated);
    }
  }

  trackEmailSync(forUserEmail: string, isEnabled: boolean) {
    let properties = {
      IsEnabled: isEnabled
    };
    this.track(forUserEmail, TrackingConstants.syncSettingsUpdated, properties);
  }

  trackVerificationPopup(forUserEmail: string, action: string) {
    let properties = {
      Action: action
    };

    this.track(forUserEmail, TrackingConstants.verificationPopup, properties);
  }

  trackLogin(forUserEmail: string, action: string, additionalProperties?: Object) {
    let properties = {
      Location: 'Login',
      Action: action,
      Email: forUserEmail
    };

    if (additionalProperties) {
      properties = Object.assign(properties, additionalProperties);
    }

    this.track(forUserEmail, TrackingConstants.login, properties);
  }

  trackUserClick(forUserEmail: string, location: string, action: string, additionalProperties?: Object) {
    let properties = {
      Location: location,
      Action: action,
      Email: forUserEmail
    };

    if (additionalProperties) {
      properties = Object.assign(properties, additionalProperties);
    }

    this.track(forUserEmail, TrackingConstants.click, properties);
  }

  trackShortcutAction(forUserEmail: string, action: string, location: string, triggerType: string) {
    this.track(forUserEmail, TrackingConstants.shortcutAction, {
      Action: action,
      Location: location,
      Email: forUserEmail,
      TriggerType: triggerType
    });
  }

  trackKeyboardShortcut(forUserEmail: string, key: string, location: string) {
    this.track(forUserEmail, TrackingConstants.keyboardShortcut, { Key: key, Location: location, Email: forUserEmail });
  }

  /**
   * User register
   * We call register only when user creates new account on DesktopApp in order to be able to identify him on
   * Mixpanel with their name and email.
   */
  userRegister(forUserEmail: string) {
    let userData = this._sharedUserManagerService.getUserLoginByEmail(forUserEmail);

    let mixpanelInstance = this.getInstance(forUserEmail);

    // Set properties
    let generalProperties = {};
    generalProperties[TrackingConstants.userEmail] = userData.email;
    generalProperties[TrackingConstants.userName] = userData.name;
    generalProperties[TrackingConstants.userTimeRegistered] =
      userData.userSettings && userData.userSettings.registeredDate;
    generalProperties[TrackingConstants.appRegistrationPlatform] = isWebApp() ? 'Web app' : 'Desktop';
    generalProperties[TrackingConstants.appRegistrationVersion] = this.appVersion;
    generalProperties[TrackingConstants.updateProfileReason] = 'Registration';
    generalProperties[TrackingConstants.userTimezone] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    generalProperties[TrackingConstants.userLanguage] = navigator.language;
    generalProperties[TrackingConstants.userPushNotificationsEnabled] = false;
    generalProperties[TrackingConstants.deviceId] = Logger.getMachineId();

    // Make one call
    mixpanelInstance.people.set(generalProperties);
  }

  private allowTrackingForEvent(forUserEmail: string, event: TrackingConstants | string): boolean {
    return !_.isEmpty(forUserEmail) || allowedAnonymousEvents.includes(event);
  }

  private getInstance(forUserEmail: string, newInstallation: boolean = false) {
    // When use email is undefined, fallback to anonymous user
    if (!forUserEmail) {
      forUserEmail = 'anonymous_user';
    }

    if (newInstallation) {
      forUserEmail = 'new-installation';
    }

    if (!mixpanel[forUserEmail]) {
      // Set token based on ENV
      let token;

      if (ENV === 'webapp') {
        token = window.location.host.startsWith('app')
          ? MIXPANEL_CONSTS.MIXPANEL_TOKEN
          : MIXPANEL_CONSTS.MIXPANEL_TOKEN_DEMO;
      } else {
        token =
          ENV === 'production' || ENV === 'production-store'
            ? MIXPANEL_CONSTS.MIXPANEL_TOKEN
            : MIXPANEL_CONSTS.MIXPANEL_TOKEN_DEMO;
      }

      // Set identify ID based on email
      let userId: string = undefined;
      switch (forUserEmail) {
        case 'anonymous_user':
          // Mixpanel will merge false-unique profiles
          userId = undefined;
          break;
        case 'new-installation':
          // New installations that result in registration/login will
          // be identified under that profile. Others should be anonymously tracked
          userId = undefined;
          break;
        default:
          // Get user id for valid email
          userId = this._sharedUserManagerService.getUserIdByEmail(forUserEmail);
      }

      // This function initializes a new instance of the Mixpanel tracking object.
      // All new instances are added to the main mixpanel object as sub properties
      // (such as mixpanel.forUserEmail) and also returned by this function
      mixpanel.init(token, this.getMixpanelConfig(), forUserEmail);

      // Identify a user with a unique ID to track user activity across devices,
      // tie a user to their events, and create a user profile. If you never call this method,
      // unique visitors are tracked using a UUID generated the first time they visit the site.
      if (userId) {
        mixpanel[forUserEmail].identify(userId);
      }

      // Identify a user on new-installation instance so that mixpanel will connect accounts
      // and then remove it
      if (mixpanel['new-installation'] && forUserEmail !== 'anonymous_user' && userId) {
        mixpanel['new-installation'].identify(userId);
        delete mixpanel['new-installation'];
      }

      // Identify anonymous instance and remove it
      if (mixpanel['anonymous_user'] && forUserEmail !== 'anonymous_user' && userId) {
        mixpanel['anonymous_user'].identify(userId);
        delete mixpanel['anonymous_user'];
      }

      // Set properties on a user record.
      let userRecordProperties = {};
      userRecordProperties[TrackingConstants.updateProfileReason] = 'App usage';
      userRecordProperties[TrackingConstants.userEmail] = forUserEmail;
      userRecordProperties[TrackingConstants.deviceId] = Logger.getMachineId();
      userRecordProperties['AppEnvironment'] = ENV;
      isWebApp()
        ? (userRecordProperties['WEB App Version'] = this.appVersion)
        : (userRecordProperties['DTA App Version'] = this.appVersion);

      // Make one call
      mixpanel[forUserEmail].people.set(userRecordProperties);

      // This will overwrite previous super property values
      // This values will be added to every event
      let userEventProperties = {
        Application: isWebApp() ? 'Loop Web Client' : 'Loop Desktop',
        Request_UserAgent: navigator.userAgent,
        Request_Source: 'EventClient',
        UserAgent: isWebApp() ? 'WebApp' : 'DesktopApp',
        Domain: EmailUtils.getDomain(forUserEmail),
        AppEnvironment: ENV,
        Email: forUserEmail,
        IP: Logger.machineIP,
        Offline: false // No support for offline mode out-of-the-box
      };

      // Add version
      isWebApp()
        ? (userEventProperties['WEB App Version'] = this.appVersion)
        : (userEventProperties['DTA App Version'] = this.appVersion);

      // Make one call
      mixpanel[forUserEmail].register(userEventProperties);
    }

    return mixpanel[forUserEmail];
  }

  protected getMixpanelConfig(): Object {
    return {};
  }

  private setIncrementProp(forUserEmail: string, property) {
    if (!this.allowTrackingForEvent(forUserEmail, undefined)) {
      return;
    }

    this.getInstance(forUserEmail).people.increment(property);
  }
}

interface NoticeActionProperties {
  noticeType: string;
  action: string;
}
