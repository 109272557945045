import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { SharedTagDaoServiceI } from '@shared/database/dao/shared-tag/shared-tag-dao.service';
import {
  SharedTagBaseModel,
  SharedTagFolderModel,
  SharedTagLabelModel,
  SharedTagModel,
  SharedTagStatusModel,
  SharedTagSystemModel,
} from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { SharedTagBase, Tag, TagRights } from '@shared/api/api-loop/models';
import { combineLatest, Observable } from 'rxjs';
import { DatabaseServiceWeb } from '../../database.service.web';
import { map, mergeMap } from 'rxjs/operators';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { CollectionNameWeb, TypeIndex, WorkspaceIdIndex } from '../../database-schema';
import { SharedTagPopulateService } from '@shared/populators/shared-tags-populate/shared-tags-populate.service';

@Injectable()
export class SharedTagDaoServiceWeb
  extends BaseDaoServiceWeb<SharedTagModel, SharedTagBase>
  implements SharedTagDaoServiceI
{
  constructor(
    protected _databaseFactory: DatabaseFactory,
    private _sharedTagPopulateService: SharedTagPopulateService,
  ) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'SharedTagDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.SharedTag;
  }

  protected toModel(doc: Tag): SharedTagBaseModel {
    return SharedTagBaseModel.create(doc);
  }

  protected populate(forUserEmail: string, sharedTags: SharedTagModel[]): Observable<SharedTagModel[]> {
    return this._sharedTagPopulateService.populate(forUserEmail, sharedTags);
  }

  findLabels(forUserEmail: string, contextId?: string, minimumRight?: TagRights): Observable<SharedTagLabelModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndex(TypeIndex.indexName, SharedTagLabelModel.type, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      /**
       * Filter out deleted and labels for other contexts
       */
      map((labels: SharedTagLabelModel[]) =>
        _.filter(
          labels,
          (label: SharedTagLabelModel) =>
            label.isAllowedInContext(contextId) &&
            !label.deleted &&
            (!minimumRight || label.hasHigherOrEqualRightsThan(minimumRight)),
        ),
      ),
    );
  }

  findStatusSharedTags(forUserEmail: string): Observable<SharedTagStatusModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndex(TypeIndex.indexName, SharedTagStatusModel.type, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
    );
  }

  findLocalSharedTagIds(forUserEmail: string, ids: string[]): Observable<SharedTagModel[]> {
    return this.findByIds(forUserEmail, ids);
  }

  updateRightsOfLabelsForWorkspace(
    forUserEmail: string,
    workspaceId: string,
    rights: TagRights,
  ): Observable<SharedTagModel[]> {
    return this.db(forUserEmail).pipe(
      /**
       * Get workspace labels
       */
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndex(WorkspaceIdIndex.indexName, workspaceId, this.collectionName);
      }),
      /**
       * Update rights
       */
      map((sharedTags: SharedTagLabelModel[]) =>
        _.map(sharedTags, (sharedTag: SharedTagLabelModel) => (sharedTag.rights = rights) && sharedTag),
      ),
      /**
       * Store
       */
      mergeMap((sharedTags: SharedTagLabelModel[]) => {
        return this.saveAll(forUserEmail, sharedTags);
      }),
    );
  }

  findAllFolders(forUserEmail: string): Observable<SharedTagFolderModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndex(TypeIndex.indexName, SharedTagFolderModel.type, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
    ) as Observable<SharedTagFolderModel[]>;
  }

  findFoldersForContext(forUserEmail: string, contextId: string): Observable<SharedTagFolderModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndex(TypeIndex.indexName, SharedTagFolderModel.type, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      map((folders: SharedTagFolderModel[]) => {
        return _.filter(folders, (label: SharedTagFolderModel) => label.isAllowedInContext(contextId));
      }),
    );
  }
}
