/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Device } from '../models/device';
import { Response } from '../models/response';

@Injectable()
export class DeviceApiService extends BaseService {
  /**
   * @param params The `DeviceApiService.Device_RegisterDeviceParams` containing the following parameters:
   *
   * - `device`: Device object
   *
   * - `removeOther`: Remove all other devices for user
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Device_RegisterDeviceResponse(
    params: DeviceApiService.Device_RegisterDeviceParams,
    forUserEmail: string
  ): Observable<HttpResponse<Device>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/device`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Device_RegisterDevice');

    // body, headers and query params
    __body = params.device;
    if (params.removeOther != null) __params = __params.set('removeOther', params.removeOther.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Device;

          body = <Device>response.body;

          return <HttpResponse<Device>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `DeviceApiService.Device_RegisterDeviceParams` containing the following parameters:
   *
   * - `device`: Device object
   *
   * - `removeOther`: Remove all other devices for user
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Device_RegisterDevice(
    params: DeviceApiService.Device_RegisterDeviceParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Device> {
    const observable$ = this.Device_RegisterDeviceResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/device`;
      const url = this.rootUrl + `/api/v1/device`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `DeviceApiService.Device_UpdateDeviceParams` containing the following parameters:
   *
   * - `id`: Device id
   *
   * - `device`: Device object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Device_UpdateDeviceResponse(
    params: DeviceApiService.Device_UpdateDeviceParams,
    forUserEmail: string
  ): Observable<HttpResponse<Device>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/device/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Device_UpdateDevice');

    // body, headers and query params
    __body = params.device;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Device;

          body = <Device>response.body;

          return <HttpResponse<Device>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `DeviceApiService.Device_UpdateDeviceParams` containing the following parameters:
   *
   * - `id`: Device id
   *
   * - `device`: Device object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Device_UpdateDevice(
    params: DeviceApiService.Device_UpdateDeviceParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Device> {
    const observable$ = this.Device_UpdateDeviceResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/device/${params.id}`;
      const url = this.rootUrl + `/api/v1/device/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `DeviceApiService.Device_GetDeviceParams` containing the following parameters:
   *
   * - `id`: Device id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Device_GetDeviceResponse(
    params: DeviceApiService.Device_GetDeviceParams,
    forUserEmail: string
  ): Observable<HttpResponse<Device>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/device/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Device_GetDevice');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Device;

          body = <Device>response.body;

          return <HttpResponse<Device>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `DeviceApiService.Device_GetDeviceParams` containing the following parameters:
   *
   * - `id`: Device id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Device_GetDevice(
    params: DeviceApiService.Device_GetDeviceParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Device> {
    const observable$ = this.Device_GetDeviceResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/device/${params.id}`;
      const url = this.rootUrl + `/api/v1/device/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `DeviceApiService.Device_UnregisterDeviceParams` containing the following parameters:
   *
   * - `id`: Device id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Device_UnregisterDeviceResponse(
    params: DeviceApiService.Device_UnregisterDeviceParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/device/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Device_UnregisterDevice');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `DeviceApiService.Device_UnregisterDeviceParams` containing the following parameters:
   *
   * - `id`: Device id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Device_UnregisterDevice(
    params: DeviceApiService.Device_UnregisterDeviceParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Device_UnregisterDeviceResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/device/${params.id}`;
      const url = this.rootUrl + `/api/v1/device/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module DeviceApiService {
  /**
   * Parameters for Device_RegisterDevice
   */
  export type Device_RegisterDeviceParams = {
    /**
     * Device object
     */
    device: Device;

    /**
     * Remove all other devices for user
     */
    removeOther?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Device_UpdateDevice
   */
  export type Device_UpdateDeviceParams = {
    /**
     * Device id
     */
    id: string;

    /**
     * Device object
     */
    device: Device;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Device_GetDevice
   */
  export type Device_GetDeviceParams = {
    /**
     * Device id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Device_UnregisterDevice
   */
  export type Device_UnregisterDeviceParams = {
    /**
     * Device id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
