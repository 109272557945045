import { Injectable, NgModule } from '@angular/core';
import { SharedTagDaoService } from '@shared/database/dao/shared-tag/shared-tag-dao.service';
import { TagDaoService } from '@shared/database/dao/tag/tag-dao.service';
import { CommentDaoService } from '@shared/database/dao/comment/comment-dao.service';
import { CommentDaoServiceWeb } from './comment/comment-dao.service.web';
import { TagDaoServiceWeb } from './tag/tag-dao.service.web';
import { SharedTagDaoServiceWeb } from './shared-tag/shared-tag-dao.service.web';
import { ConversationDaoServiceWeb } from './conversation/conversation-dao.service.web';
import { CardDaoService } from '@shared/database/dao/card/card-dao.service';
import { CardDaoServiceWeb } from './card/card-dao.service.web';
import { TemplateDaoServiceWeb } from './template/template-dao.service.web';
import { ContactDaoServiceWeb } from './contact/contact-dao.service.web';
import { ContactDaoService } from '@shared/database/dao/contact/contact-dao.service';
import { ConversationDaoService } from '@shared/database/dao/conversation/conversation-dao.service';
import { FileDaoServiceWeb } from './file/file-dao.service.web';
import { FileDaoService } from '@shared/database/dao/file/file-dao.service';
import { RetryQueueDaoService } from '@shared/database/dao/retry-queue/retry-queue-dao.service';
import { RetryQueueDaoServiceWeb } from './retry-queue/retry-queue-dao.service.web';
import { PushSyncDaoServiceWeb } from './push-sync/push-sync-dao.service.web';
import { PushSyncDaoService } from '@shared/database/dao/push-sync/push-sync-dao.service';
import { AvailabilityStatusDaoServiceWeb } from './availability-status/availability-status-dao.service.web';
import { AvailabilityStatusDaoService } from '@shared/database/dao/availability-status/availability-status-dao.service';
import { UserAvailabilityStatusDaoService } from '@shared/database/dao/user-availability/user-availability-dao.service';
import { UserAvailabilityStatusDaoServiceWeb } from './user-availability/user-availability-dao.service.web';
import { IntegrationDaoService } from '@shared/database/dao/integrations/integrations-dao.service';
import { IntegrationDaoServiceWeb } from './integration/integration-dao.service.web';
import { ConversationChangeDaoService } from '@shared/database/dao/conversation/conversation-change-dao.service';
import { ConversationChangeDaoServiceWeb } from './conversation/conversation-change-dao/conversation-change-dao.service.web';

@Injectable()
export class DaoService {
  constructor(
    public CommentDaoService: CommentDaoService,
    public TagDaoService: TagDaoService,
    public SharedTagDaoService: SharedTagDaoService
  ) {}
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DaoService,
    TemplateDaoServiceWeb,
    { provide: PushSyncDaoService, useClass: PushSyncDaoServiceWeb },
    { provide: RetryQueueDaoService, useClass: RetryQueueDaoServiceWeb },
    { provide: FileDaoService, useClass: FileDaoServiceWeb },
    { provide: ContactDaoService, useClass: ContactDaoServiceWeb },
    { provide: ConversationDaoService, useClass: ConversationDaoServiceWeb },
    { provide: CardDaoService, useClass: CardDaoServiceWeb },
    { provide: CommentDaoService, useClass: CommentDaoServiceWeb },
    { provide: TagDaoService, useClass: TagDaoServiceWeb },
    { provide: SharedTagDaoService, useClass: SharedTagDaoServiceWeb },
    { provide: AvailabilityStatusDaoService, useClass: AvailabilityStatusDaoServiceWeb },
    { provide: UserAvailabilityStatusDaoService, useClass: UserAvailabilityStatusDaoServiceWeb },
    { provide: IntegrationDaoService, useClass: IntegrationDaoServiceWeb },
    { provide: ConversationChangeDaoService, useClass: ConversationChangeDaoServiceWeb }
  ]
})
export class DaoModule {}
