import { Pipe, PipeTransform } from '@angular/core';
import { DropdownDelimiterItem, DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';

@Pipe({
  name: 'asDropdownDelimiterItemPipe',
})
export class AsDropdownDelimiterItemPipe implements PipeTransform {
  transform(item: DropdownItem): DropdownDelimiterItem {
    return item as DropdownDelimiterItem;
  }
}
