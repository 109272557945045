import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asArray',
})
export class AsArrayPipe<T> implements PipeTransform {
  transform(value: T | T[]): T[] {
    if (!Array.isArray(value)) {
      if (!value) {
        return [];
      }

      return [value];
    }
    return value;
  }
}
