import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import {
  ISynchronizationStatusEvent,
  SynchronizationStatusService,
  SynchronizationStatusType,
} from '../../synchronization/synchronization-status.service';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { WatchdogService } from '../watchdog/watchdog.service';
import { HttpResponseEventType } from '../../../dta/shared/models/http-events.model';
import { SharedSubjects } from '../communication/shared-subjects/shared-subjects';
import {
  AppEventApiStatus,
  AppStatusNotification,
  AppStatusType,
  AppSyncStatus,
  ConnectionStatus,
  HttpResponseEventData,
} from '../communication/shared-subjects/shared-subjects-models';
import { AutoUnsubscribe } from '../../../dta/shared/utils/subscriptions/auto-unsubscribe';
import { HttpEventService } from '@shared/interceptors/http-event.service';

const SUPPORTED_SYNC_TYPES: SynchronizationStatusType[] = [
  SynchronizationStatusType.PULL_ONBOARDING,
  SynchronizationStatusType.PULL_CHECKING,
  SynchronizationStatusType.PULL_PAST,
  SynchronizationStatusType.PULL_UPDATES,
  SynchronizationStatusType.PULL_ACTIVE,
];

@AutoUnsubscribe()
@Injectable()
export class NotifierService implements OnDestroy {
  //////////////////
  // Subscriptions
  //////////////////
  private _watchdogSub: Subscription;
  private _synchronizationEventsSub: Subscription;
  private _eventApiSub: Subscription;

  constructor(
    private _watchDogService: WatchdogService,
    private _synchronizationStatusService: SynchronizationStatusService,
    private _httpResponseEventService: HttpEventService,
  ) {}

  init() {
    this.subscribeToConnectionStatus();
    this.subscribeToSynchronizationEvents();
    this.subscribeToEventApiEvents();
  }

  ngOnDestroy() {}

  private subscribeToConnectionStatus() {
    this._watchdogSub?.unsubscribe();
    this._watchdogSub = this._watchDogService.connectionStatus$
      .pipe(
        tap((status: ConnectionStatus) => {
          let connectionStatusEvent = new AppStatusNotification();
          connectionStatusEvent.status = status;
          connectionStatusEvent.type = AppStatusType.connection;

          SharedSubjects._appStatus$.next(connectionStatusEvent);
        }),
      )
      .subscribe();
  }

  private subscribeToSynchronizationEvents() {
    this._synchronizationEventsSub?.unsubscribe();
    this._synchronizationEventsSub = this._synchronizationStatusService.enqueued$
      .pipe(
        map((events: ISynchronizationStatusEvent[]) => {
          return events.filter(event => _.includes(SUPPORTED_SYNC_TYPES, event.type));
        }),
        map((events: ISynchronizationStatusEvent[]) => {
          // Prioritize as syncType importance
          let sortedEvents = _.sortBy(events, event => event.type);
          let eventType: SynchronizationStatusType = _.isEmpty(sortedEvents) ? null : _.first(sortedEvents).type;
          let status: AppSyncStatus;

          switch (eventType) {
            case SynchronizationStatusType.PULL_ONBOARDING:
              status = AppSyncStatus.onboarding;
              break;
            case SynchronizationStatusType.PULL_CHECKING:
            case SynchronizationStatusType.PULL_PAST:
            case SynchronizationStatusType.PULL_UPDATES:
            case SynchronizationStatusType.PULL_ACTIVE:
              status = AppSyncStatus.syncing;
              break;
            default:
              status = AppSyncStatus.done;
              break;
          }

          return status;
        }),
        tap((status: AppSyncStatus) => {
          let appStatusEvent = new AppStatusNotification();
          appStatusEvent.status = status;
          appStatusEvent.type = AppStatusType.sync;

          SharedSubjects._appStatus$.next(appStatusEvent);
        }),
      )
      .subscribe();
  }

  private subscribeToEventApiEvents() {
    let supportedEvents = [HttpResponseEventType.success, HttpResponseEventType.timeout, HttpResponseEventType.error];

    let lastEvent: HttpResponseEventData;

    this._eventApiSub?.unsubscribe();
    this._eventApiSub = this._httpResponseEventService.events$
      .pipe(
        /**
         * Only listen to events when we're online
         */
        filter(() => {
          return this._watchDogService.isConnectionActive;
        }),
        filter((event: HttpResponseEventData) => {
          return supportedEvents.includes(event.httpResponseEvent.type);
        }),
        /**
         * Success event can only pass by once
         */
        filter((event: HttpResponseEventData) => {
          if (event.httpResponseEvent.type === HttpResponseEventType.success) {
            return !lastEvent || lastEvent.httpResponseEvent.type !== event.httpResponseEvent.type;
          }

          return true;
        }),
        map((event: HttpResponseEventData) => {
          lastEvent = event;

          if (event.httpResponseEvent.type === HttpResponseEventType.timeout) {
            return AppEventApiStatus.timeout;
          }
          if (event.httpResponseEvent.type === HttpResponseEventType.error) {
            return AppEventApiStatus.error;
          }

          return AppEventApiStatus.ok;
        }),
        tap((status: AppEventApiStatus) => {
          let appStatusEvent = new AppStatusNotification();
          appStatusEvent.status = status;
          appStatusEvent.type = AppStatusType.eventApi;

          SharedSubjects._appStatus$.next(appStatusEvent);
        }),
      )
      .subscribe();
  }
}
