import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { ListOfTags, SharedTagBase, TagRights } from '@shared/api/api-loop/models';
import {
  SharedTagAssigneeModel,
  SharedTagBaseModel,
  SharedTagFolderModel,
  SharedTagLabelModel,
  SharedTagModel,
  SharedTagStatusModel,
  StaticSharedTagPrefix,
} from 'dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { from, Observable, of, throwError } from 'rxjs';
import { BaseService } from '../base/base.service';
import { SharedTagServiceI } from './shared-tag.service.interface';
import { ListOfTagsModel } from '@dta/shared/models-api-loop/tag.model';
import { catchError, defaultIfEmpty, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { LogTag } from '@dta/shared/models/logger.model';
import { Logger } from '@shared/services/logger/logger';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { SharedTagApiService } from '@shared/api/api-loop/services';
import { ContactService } from '../contact/contact.service';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { SharedTagPopulateService } from '@shared/populators/shared-tags-populate/shared-tags-populate.service';
import { SharedTagDaoService } from '@shared/database/dao/shared-tag/shared-tag-dao.service';

@Injectable()
export class SharedTagService extends BaseService implements SharedTagServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _sharedTagApiService: SharedTagApiService,
    protected _contactService: ContactService,
    protected _sharedTagPopulateService: SharedTagPopulateService,
    protected _sharedTagDaoService: SharedTagDaoService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'SharedTagService';
  }

  /////////////////////////
  // PUSH SYNCHRONIZATION
  /////////////////////////
  enqueueSharedTagsSynchronization(forUserEmail: string, listOfTags: ListOfTags[]) {
    super.enqueuePushSynchronization(forUserEmail, ListOfTagsModel.createList(listOfTags, true));
  }

  ///////////////////////////
  // SAVE AND REMOVE METHODS
  ///////////////////////////
  protected saveToDB(forUserEmail: string, sharedTags: SharedTagModel[]): Observable<SharedTagModel[]> {
    return of(sharedTags);
  }

  save(forUserEmail: string, sharedTag: SharedTagModel): Observable<SharedTagModel> {
    return this.saveAll(forUserEmail, [sharedTag]).pipe(map((sharedTags: SharedTagModel[]) => _.first(sharedTags)));
  }

  saveAllAndPublish(forUserEmail: string, sharedTags: SharedTagModel[]): Observable<SharedTagModel[]> {
    return this.saveAll(forUserEmail, sharedTags).pipe(
      map((_sharedTags: SharedTagModel[]) => {
        PublisherService.publishEvent(forUserEmail, _sharedTags);
        return sharedTags;
      }),
    );
  }

  saveAll(forUserEmail: string, sharedTags: SharedTagModel[]): Observable<SharedTagModel[]> {
    if (_.isEmpty(sharedTags)) {
      return of([]);
    }

    let watch = new StopWatch(
      this.constructorName + '.saveAll: ' + sharedTags.length,
      ProcessType.SERVICE,
      forUserEmail,
    );

    return of(undefined).pipe(
      /**
       * Reduce sharedTags
       */
      mergeMap(() => {
        watch.log('reduce');
        return this._sharedTagPopulateService.reduce(forUserEmail, sharedTags);
      }),
      /**
       * Save sharedTags
       */
      mergeMap((_sharedTags: SharedTagModel[]) => {
        watch.log('saveToDB');
        return this._sharedTagDaoService.saveAll(forUserEmail, _sharedTags);
      }),
      /**
       * Populate with contacts
       */
      mergeMap((_sharedTags: SharedTagModel[]) => {
        watch.log('populate');
        return this._sharedTagPopulateService.populate(forUserEmail, _sharedTags);
      }),
    );
  }

  //////////
  // Other
  //////////
  fetchMissingCardSharedTags(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    return this.fetchAndReturnMissingCardSharedTags(forUserEmail, cards).pipe(map(() => cards));
  }

  fetchAndReturnMissingCardSharedTags(forUserEmail: string, cards: CardModel[]): Observable<SharedTagModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    // Bundle sharedTags under card ids (for authorization)
    let cardIdBySharedTagId: _.Dictionary<string> = {};
    _.forEach(cards, (card: CardModel) => {
      if (!card.hasSharedTags()) {
        return;
      }

      _.forEach(card.getSharedTags(), (sharedTag: SharedTagBase) => {
        cardIdBySharedTagId[sharedTag.id] = card.id;
      });
    });

    // Don't proceeded if there is no shared tags on given batch of cards
    if (_.isEmpty(cardIdBySharedTagId)) {
      return of([]);
    }

    return of(undefined).pipe(
      /**
       * Find local sharedTags (return only ids to save resources)
       */
      mergeMap(() => {
        return this.findLocalSharedTagIds(forUserEmail, Object.keys(cardIdBySharedTagId));
      }),
      /**
       * Filter out new shared tags that need to be fetched
       */
      mergeMap((sharedTags: SharedTagBase[]) => {
        _.forEach(_.map(sharedTags, 'id'), (sharedTagId: string) => {
          delete cardIdBySharedTagId[sharedTagId];
        });

        return this.fetchAndSaveMissingSharedTagsForParents(forUserEmail, cardIdBySharedTagId);
      }),
      /**
       * Log error but don't handle
       */
      catchError(err => {
        Logger.error(
          err,
          `Error in ${this.constructorName}:fetchAndReturnMissingCardSharedTags()`,
          LogTag.INTERESTING_ERROR,
          true,
        );

        return throwError(err);
      }),
    );
  }

  fetchAndSaveMissingSharedTagsForParents(
    forUserEmail: string,
    parentBySharedTagId: { [sharedTagId: string]: string },
  ): Observable<SharedTagModel[]> {
    if (_.isEmpty(parentBySharedTagId)) {
      return of([]);
    }

    return from(Object.keys(parentBySharedTagId)).pipe(
      /**
       * Filter out folder shared tag -> they should be loaded on init
       */
      filter((sharedTagId: string) => {
        return !sharedTagId.startsWith(StaticSharedTagPrefix.FOLDER_PREFIX);
      }),
      /**
       * Fetch missing shared tag id (limit concurrency to 5)
       */
      mergeMap((sharedTagId: string) => {
        return this.fetchMissingSharedTagForParent(forUserEmail, sharedTagId, parentBySharedTagId[sharedTagId]);
      }, 5),
      /**
       * Filter out any undefined shared tag
       * (most likely due to error in request)
       */
      filter((sharedTag: SharedTagBase) => {
        return !_.isEmpty(sharedTag);
      }),
      toArray(),
      /**
       * Cast to models
       */
      map((sharedTags: SharedTagBase[]) => {
        return SharedTagBaseModel.createList(sharedTags);
      }),
      defaultIfEmpty([]),
      /**
       * Fetch any missing assignees
       */
      mergeMap((newSharedTags: SharedTagModel[]) => {
        return this.fetchMissingAssignUsers(forUserEmail, newSharedTags, parentBySharedTagId);
      }),
      /**
       * Save newly fetched shared tags
       */
      mergeMap((newSharedTags: SharedTagModel[]) => {
        return this.saveAll(forUserEmail, newSharedTags);
      }),
    );
  }

  private fetchMissingSharedTagForParent(
    forUserEmail: string,
    sharedTagId: string,
    parentId: string,
  ): Observable<SharedTagBase> {
    let params: SharedTagApiService.SharedTag_GetParams = {
      id: sharedTagId,
      parentId: parentId,
    };

    return this._sharedTagApiService.SharedTag_Get(params, forUserEmail).pipe(
      /**
       * Catch and handle all request errors as undefined
       */
      catchError(err => {
        Logger.error(
          err,
          `Could not get shared tag by id: ${sharedTagId} for parent: ${parentId} for user: ${forUserEmail}`,
          LogTag.INTERESTING_ERROR,
          true,
          'Could not get shared tag by id',
        );

        return of(undefined);
      }),
    );
  }

  private fetchMissingAssignUsers(
    forUserEmail: string,
    sharedTags: SharedTagModel[],
    parentBySharedTagId: _.Dictionary<string>,
  ): Observable<SharedTagModel[]> {
    if (_.isEmpty(sharedTags) || _.isEmpty(parentBySharedTagId)) {
      return of([]);
    }

    // Filter out assignee shared tags
    let assigneeSharedTags = _.filter(
      sharedTags,
      (sharedTag: SharedTagModel) => sharedTag.$type === SharedTagAssigneeModel.type,
    ) as SharedTagAssigneeModel[];

    // Bundle user ids under parent card ids (for authorization)
    let cardIdsByAssigneeId = {};
    _.forEach(assigneeSharedTags, (sharedTag: SharedTagAssigneeModel) => {
      cardIdsByAssigneeId[sharedTag.userId] = parentBySharedTagId[sharedTag.id];
    });

    if (_.isEmpty(cardIdsByAssigneeId)) {
      return of(sharedTags);
    }

    return of(undefined).pipe(
      /**
       * Get local contacts from store
       */
      mergeMap(() => {
        return this._contactService.getContactsByIds(forUserEmail, Object.keys(cardIdsByAssigneeId));
      }),
      /**
       * Filter out any undefined contacts and fetch them
       */
      mergeMap((contacts: ContactModel[]) => {
        _.forEach(contacts, (localContact: ContactModel) => {
          if (!localContact) {
            return;
          }

          delete cardIdsByAssigneeId[localContact.id];
        });
        return this._contactService.fetchUnknownUsersByIds(forUserEmail, cardIdsByAssigneeId);
      }),
      /**
       * Save and publish freshly fetched contacts
       */
      mergeMap((newContacts: ContactModel[]) => {
        return this._contactService.saveAllAndPublish(forUserEmail, newContacts);
      }),
      map(() => sharedTags),
    );
  }

  deleteFolder(forUserEmail: string, folder: SharedTagFolderModel): Observable<any> {
    return this._sharedTagApiService.SharedTag_DeleteSharedTag(
      {
        sharedTag: folder,
      },
      forUserEmail,
    );
  }

  updateFolder(forUserEmail: string, folder: SharedTagFolderModel): Observable<any> {
    return this._sharedTagApiService.SharedTag_UpdateSharedTag(
      {
        sharedTag: folder,
      },
      forUserEmail,
    );
  }

  ////////////////
  // DAO WRAPPERS
  ////////////////
  findAllFolders(forUserEmail: string): Observable<SharedTagFolderModel[]> {
    return this._sharedTagDaoService.findAllFolders(forUserEmail);
  }

  findFoldersForContext(forUserEmail: string, contextId: string): Observable<SharedTagFolderModel[]> {
    return this._sharedTagDaoService.findFoldersForContext(forUserEmail, contextId);
  }

  findByIds(forUserEmail: string, sharedTagIds: string[]): Observable<SharedTagModel[]> {
    return this._sharedTagDaoService.findByIds(forUserEmail, sharedTagIds);
  }

  findAvailableLabels(
    forUserEmail: string,
    contextId?: string,
    minimumRight?: TagRights,
  ): Observable<SharedTagLabelModel[]> {
    return this._sharedTagDaoService.findLabels(forUserEmail, contextId, minimumRight);
  }

  updateRightsForWorkspaceLabels(
    forUserEmail: string,
    workspaceId: string,
    rights: TagRights,
  ): Observable<SharedTagModel[]> {
    return this._sharedTagDaoService.updateRightsOfLabelsForWorkspace(forUserEmail, workspaceId, rights);
  }

  getStatusSharedTags(forUserEmail: string): Observable<SharedTagStatusModel[]> {
    return this._sharedTagDaoService.findStatusSharedTags(forUserEmail);
  }

  removeAll(forUserEmail: string, models: SharedTagModel[]): Observable<any> {
    return this._sharedTagDaoService.removeAll(forUserEmail, models);
  }

  removeCollection(forUserEmail: string): Observable<any> {
    return this._sharedTagDaoService.removeCollection(forUserEmail);
  }

  protected findLocalSharedTagIds(forUserEmail: string, ids: string[]): Observable<SharedTagModel[]> {
    return this._sharedTagDaoService.findLocalSharedTagIds(forUserEmail, ids);
  }
}
