import { Inject, Injectable } from '@angular/core';
import { BaseDateFormatterResource } from './internal/base-date-formatter-resource';
import { DATE_FORMATTER_RESOURCE } from './internal/date-formatter-resource.injection-token';
import { DateFormat } from '@shared/shared/fancy-date/common/enumerators/date-format';

@Injectable()
export class FancyDateService {
  constructor(@Inject(DATE_FORMATTER_RESOURCE) private readonly dateFormatterResource: BaseDateFormatterResource) {}

  formatDate(date: Date, dateFormat: DateFormat): string {
    return this.dateFormatterResource.formatDate(date, dateFormat);
  }

  parseDate(input: string): Date | null {
    return this.dateFormatterResource.parseDate(input);
  }

  getLocale(): Locale {
    return this.dateFormatterResource.dateFnsLocale;
  }
}
