/* tslint:disable */

/**
 * classification tag type
 */
export type ClassificationTagType = 'Category' | 'Classification';

export module ClassificationTagType {
  export const CATEGORY: ClassificationTagType = 'Category';
  export const CLASSIFICATION: ClassificationTagType = 'Classification';

  export function values(): ClassificationTagType[] {
    return [CATEGORY, CLASSIFICATION];
  }
}
