import { Observable } from 'rxjs';
import { CacheIndex } from './enums';
import {
  LoopIDBDatabaseOptions,
  LoopIDBDatabaseSchema,
  LoopIDBIndexSchema,
  LoopIDBObjectStoreParameters,
  LoopIDBStoreSchema,
  StoreTypes,
} from './interfaces';
import { LoopIDBObjectStore } from './object-store';
import { LoopIDBTransaction } from './transaction';
import { StorageKey } from '@dta/shared/services/storage/storage.service';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { SharedSubjectScope } from '@shared/services/communication/shared-subjects/shared-subjects-models';

/**
 * The following code is based on 'reactive-idb'
 * Project: https://github.com/creasource/reactive-idb
 * We use our implementation for customization
 */

export class LoopIDBDatabase {
  private _schema: LoopIDBDatabaseSchema;

  constructor(
    private readonly database: IDBDatabase,
    schema: LoopIDBDatabaseSchema,
  ) {
    this._schema = schema;
  }

  get schema(): LoopIDBDatabaseSchema {
    return this._schema;
  }

  get name(): string {
    return this.database.name;
  }

  get objectStoreNames(): DOMStringList {
    return this.database.objectStoreNames;
  }

  get version(): number {
    return this.database.version;
  }

  static create(options: LoopIDBDatabaseOptions): Observable<LoopIDBDatabase> {
    return new Observable<LoopIDBDatabase>(subscriber => {
      const opts: Required<LoopIDBDatabaseOptions> = {
        schema: undefined,
        factory: window.indexedDB,
        onUpgrade: () => void 0,
        onBlocked: () => void 0,
        autoCloseOnVersionChange: true,
        ...options,
      };

      const version = Math.max(opts.schema?.version, 1);

      // Set schema default values
      opts.schema.stores = opts.schema.stores.map((store: LoopIDBStoreSchema) => {
        store.options = store.options || {};
        // Default to persistent type of store
        store.options.storageType = store.options.storageType || StoreTypes.PERSISTENT;

        // Default max cache size to 100
        if (store.options.storageType === StoreTypes.CACHE) {
          store.options.maxCacheSize = store.options.maxCacheSize || 100;
        }

        return store;
      });

      const request = opts.factory.open(opts.name, version);

      request.onupgradeneeded = versionChange => {
        const database = request.result;

        if (opts.schema.version > versionChange.oldVersion) {
          opts.schema.stores.forEach((store: LoopIDBStoreSchema) => {
            let name: string, _options: LoopIDBObjectStoreParameters, indexes: LoopIDBIndexSchema[];
            name = store.name;
            _options = store.options;

            // Create indexes
            indexes = (store.indexes || []).map(index => {
              return typeof index === 'string' ? { name: index, keyPath: index } : index;
            });

            // Clear old
            try {
              database.deleteObjectStore(name);
            } catch (err) {}

            const objStore = database.createObjectStore(name, _options);
            indexes.forEach(index => objStore.createIndex(index.name, index.keyPath || index.name, index.options));

            // Create index by inserted time for cache
            if (_options?.storageType === StoreTypes.CACHE) {
              objStore.createIndex(CacheIndex, CacheIndex);
            }
          });
        }

        opts.onUpgrade(
          database,
          versionChange.oldVersion,
          versionChange.newVersion,
          (versionChange.target as unknown as { transaction: IDBTransaction }).transaction,
        );
      };

      request.onsuccess = () => {
        subscriber.next(new LoopIDBDatabase(request.result, opts.schema));
        subscriber.complete();
      };

      request.onblocked = () => {
        localStorage.setItem(StorageKey.closeOldTabsRequired, 'true');
      };

      if (options.onBlocked) {
        request.onblocked = options.onBlocked;
      }

      request.onerror = ev => subscriber.error(ev);
    });
  }

  close(): void {
    return this.database.close();
  }

  transaction(names: string | string[], mode?: IDBTransactionMode): LoopIDBTransaction {
    return new LoopIDBTransaction(this.database.transaction(names, mode), this);
  }

  transaction$(names: string | string[], mode?: IDBTransactionMode): Observable<LoopIDBTransaction> {
    return new Observable<LoopIDBTransaction>(observer => {
      const transaction = new LoopIDBTransaction(this.database.transaction(names, mode), this);
      transaction.addEventListener('complete', () => observer.complete());
      observer.next(transaction);
      return () => {
        try {
          transaction.abort();
          // eslint-disable-next-line no-empty
        } catch (e) {}
      };
    });
  }

  createObjectStore(name: string, options?: IDBObjectStoreParameters): LoopIDBObjectStore {
    const objStore = this.database.createObjectStore(name, options);
    const transaction = objStore.transaction;

    return new LoopIDBObjectStore(objStore, new LoopIDBTransaction(transaction, this));
  }

  deleteObjectStore(name: string): void {
    return this.database.deleteObjectStore(name);
  }

  addEventListener<K extends keyof IDBDatabaseEventMap>(
    type: K,
    listener: (this: IDBDatabase, ev: IDBDatabaseEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions,
  ): void {
    this.database.addEventListener(type, listener, options);
  }

  removeEventListener<K extends keyof IDBDatabaseEventMap>(
    type: K,
    listener: (this: IDBDatabase, ev: IDBDatabaseEventMap[K]) => void,
    options?: boolean | EventListenerOptions,
  ): void {
    this.database.removeEventListener(type, listener, options);
  }

  drop$(onBlocked?: (event: Event) => void): Observable<void> {
    this.database.close();
    return LoopIDBDatabase.dropAny$(this.name, onBlocked);
  }

  static dropAny$(databaseName: string, onBlocked?: (event: Event) => void): Observable<void> {
    return new Observable(observer => {
      const request = indexedDB.deleteDatabase(databaseName);
      request.onerror = event => observer.error(event);
      request.onsuccess = () => {
        observer.next();
        observer.complete();
      };
      if (onBlocked) {
        request.onblocked = onBlocked;
      }
    });
  }
}
