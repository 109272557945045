export const CREATE_TEAM_COPY = {
  // Step 1 Form
  namePlaceholder: 'How is this team called?',
  nameLabel: 'Team Name',
  descriptionPlaceholder: 'What does this team focus on?',
  overviewSubtitle: 'Add team name and description below.',

  // Step 2
  channelSubtitle: 'Select the email account that will be connected to this shared inbox.',

  // Members notification
  membersNotificationTitle: 'How to add Members to a team',
  membersNotificationContent:
    'Grant access to teammates who will be collaborating with you in this team. You will also be able to edit this later.',

  // Success
  successTitle: 'Success, your team has been created',
  successCtaCopy: 'Take me to the team',
};
