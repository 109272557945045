import * as _ from 'lodash';
import { BaseModel } from '../../shared/models-api-loop/base/base.model';
import { EMPTY, map, ObjectUnsubscribedError, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CollectionParams, FetchResult } from '../../shared/models/collection.model';
import {
  CollectionOptions,
  CollectionSubscriberService,
  LoadMoreType,
  ObserverResponse,
} from './collection-subscriber.service';
import { Logger } from '@shared/services/logger/logger';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { LoadMoreResult, LoadMoreResultStatus } from '@dta/ui/components/common/load-more/load-more.component';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseCollectionService<T extends BaseModel> {
  protected abstract supportedTypes: (typeof BaseModel)[];
  readonly loadMoreType: LoadMoreType = LoadMoreType.QUERY_THEN_FETCH;
  private _collectionKey: string = undefined;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _collectionSubscriberService: CollectionSubscriberService,
  ) {}

  abstract get constructorName(): string;

  // Extra processing after reduce
  protected abstract doBeforePublish(models: T[], params: CollectionParams, forUserEmail: string): Observable<T[]>;

  // Extra processing for reduce
  protected abstract doBeforeReduce(models: T[], params: CollectionParams, forUserEmail: string): Observable<T[]>;

  /**
   * Reduces list of given models based on given parameters
   */
  protected abstract reduce(models: T[], params: CollectionParams, forUserEmail: string): Observable<T[]>;

  abstract count(params: CollectionParams, forUserEmail: string): Observable<Number>;

  abstract query(params: CollectionParams, forUserEmail: string): Observable<T[]>;

  abstract fetch(params: CollectionParams, forUserEmail: string): Observable<FetchResult>;

  conversationSubscriber(forUserEmail: string, cutOffTime: string): Observable<any> {
    return of([]);
  }

  get collectionKey(): string {
    if (!this._collectionKey) {
      throw new Error('Register collection before getting collection key');
    }
    return this._collectionKey;
  }

  registerCollection(
    params: CollectionParams,
    options?: CollectionOptions,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<string> {
    return this._collectionSubscriberService.registerCollection(forUserEmail, this, params, options).pipe(
      tap((collectionKey: string) => {
        this._collectionKey = collectionKey;
      }),
    );
  }

  registerObserver(
    collectionKey: string,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<ObserverResponse<T>> {
    return this._collectionSubscriberService.registerObserver(forUserEmail, collectionKey).pipe(
      switchMap((response: ObserverResponse<T>) => {
        return this.handlePublishEvent(forUserEmail, collectionKey, response.models as T[]).pipe(
          map((models: T[]) => {
            response.models = models;
            return response;
          }),
        );
      }),
    );
  }

  triggerLoadMore(
    collectionKey: string,
    offset: number,
    size: number,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<LoadMoreResult> {
    return this._collectionSubscriberService.triggerLoadMore(forUserEmail, collectionKey, offset, size);
  }

  getCorrectlyPositionedItems(collectionKey: string) {
    return this._collectionSubscriberService.getFetchedDataSize(collectionKey);
  }

  setCorrectlyPositionedItems(collectionKey: string, size: number) {
    return this._collectionSubscriberService.setFetchedDataSize(collectionKey, size);
  }

  isTypeSupported(model: T): boolean {
    return _.some(this.supportedTypes, type => model instanceof type);
  }

  // [!] NOTE [!]
  // Return empty array if nothing comes through reduce function.
  // Handle empty array later on in each collection
  private handlePublishEvent(forUserEmail: string, collectionKey: string, models: T[]): Observable<T[]> {
    // Filter-out the needles publish
    if (_.isEmpty(models)) {
      return of(models);
    }

    let params = this._collectionSubscriberService.getParametersForKey(forUserEmail, collectionKey);
    let beforeReduceCount = models.length;

    return of(undefined).pipe(
      /**
       * Process before release
       */
      mergeMap(() => {
        return this.doBeforeReduce(models, params, forUserEmail);
      }),
      /**
       * Reduce
       */
      mergeMap((models: T[]) => {
        return this.reduce(models, params, forUserEmail);
      }),
      /**
       * Log
       */
      tap((models: T[]) => {
        // Log to console
        CollectionSubscriberService.log(collectionKey, 'Reduce throughput ' + models.length + '/' + beforeReduceCount);

        // Add to cache
        params.cache.addModels(models);
      }),
      /**
       * Process after reduce and before publishing to observer
       */
      mergeMap((models: T[]) => {
        return this.doBeforePublish(models, params, forUserEmail);
      }),
      /**
       * Catch errors
       */
      catchError(err => {
        // If subject is no longer active - suppress the error
        if (err instanceof ObjectUnsubscribedError) {
          return EMPTY;
        }
        return throwError(err);
      }),
    );
  }

  protected get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  protected get currentUserId(): string {
    return this._userManagerService.getCurrentUserId();
  }

  removeModelsFromCache(forUserEmail: string, models: BaseModel[]) {
    let params = this._collectionSubscriberService.getParametersForKey(forUserEmail, this.collectionKey);

    params.cache.removeModels(models);
  }

  addModelsToCache(forUserEmail: string, models: BaseModel[]) {
    let params = this._collectionSubscriberService.getParametersForKey(forUserEmail, this.collectionKey);

    params.cache.addModels(models);
  }
}
