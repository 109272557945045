import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { addHours, isAfter } from 'date-fns';
import { filter, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';

interface FromToFormGroup extends UntypedFormGroup {
  controls: {
    from: UntypedFormControl;
    to: UntypedFormControl;
  };
}

export interface RangeChooserFormGroup extends UntypedFormGroup {
  controls: {
    range: FromToFormGroup;
  };
}

@Component({
  selector: 'loop-range-chooser',
  templateUrl: './range-chooser.component.html',
  styleUrls: ['./range-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeChooserComponent implements OnInit {
  private readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Input() rangeChooserFormGroup: RangeChooserFormGroup;

  private rangeSubscription: Subscription;
  constructor() {}

  ngOnInit(): void {
    this.observeRangeChanges();
  }

  private get rangeFromControl(): AbstractControl {
    return this.rangeChooserFormGroup.get('range.from');
  }

  private get rangeToControl(): AbstractControl {
    return this.rangeChooserFormGroup.get('range.to');
  }

  private observeRangeChanges(): void {
    this.rangeSubscription?.unsubscribe();
    this.rangeSubscription = this.rangeFromControl.valueChanges
      .pipe(
        startWith(this.rangeFromControl.value as Date),
        filter(from => !!from),
      )
      .subscribe(from => {
        if (!this.rangeToControl.value || (!!this.rangeToControl.value && isAfter(from, this.rangeToControl.value))) {
          this.rangeToControl.setValue(addHours(from, 1));
        }
        this.changeDetector.detectChanges();
      });
  }
}
