import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RsvpActionComponent } from '@dta/ui/components/email-card/rsvp/rsvp-action/rsvp-action.component';
import { ButtonModule } from '@shared/ui/button/button.module';

@NgModule({
  declarations: [RsvpActionComponent],
  imports: [CommonModule, ButtonModule],
  exports: [RsvpActionComponent, RsvpActionComponent],
})
export class RsvpActionModule {}
