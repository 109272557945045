import { ClassificationNode } from '@shared/modules/shared-tag/common/interfaces/classification-node';
import { SharedTagClassificationModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

export function buildClassificationTree(
  classificationTags: SharedTagClassificationModel[],
  selectedClassificationNode?: SharedTagClassificationModel
): ClassificationNode[] {
  const nodeMap: { [id: string]: ClassificationNode } = {};
  const rootNodes: ClassificationNode[] = [];

  classificationTags.forEach(tag => {
    nodeMap[tag.id] = {
      classificationTag: tag,
      childNodes: [],
      isNodeExpanded: true,
      isNodeSelected: selectedClassificationNode?.id === tag.id
    };
  });

  classificationTags.forEach(tag => {
    const node = nodeMap[tag.id];

    if (tag.parentTagId) {
      const parentNode = nodeMap[tag.parentTagId];
      if (parentNode) {
        parentNode.childNodes.push(node);
      }
    } else {
      rootNodes.push(node);
    }
  });

  if (selectedClassificationNode) {
    let currentNode = nodeMap[selectedClassificationNode.id];
    while (currentNode) {
      currentNode.isNodeExpanded = true;
      const parentTagId = currentNode.classificationTag.parentTagId;
      currentNode = parentTagId ? nodeMap[parentTagId] : null;
    }
  }

  return rootNodes;
}
