import * as _ from 'lodash';
import { ContactStore } from './contact.store';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Injectable, Injector } from '@angular/core';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel } from '@dta/shared/models/logger.model';

@Injectable()
export class ContactStoreFactory {
  private _instances: { [userEmail: string]: ContactStore } = {};

  constructor(protected _injector: Injector) {
    if (!CONSTANTS.PRODUCTION) {
      window['ContactStoreFactory'] = this;
    }
  }

  forUser(userEmail: string): ContactStore {
    if (_.isNil(this._instances[userEmail])) {
      this._instances[userEmail] = this.createContactStoreForUser(userEmail);
    }

    return this._instances[userEmail];
  }

  removeForUser(userEmail: string) {
    if (_.isNil(this._instances[userEmail])) {
      return;
    }

    // Destroy and remove
    this._instances[userEmail].destroy();
    delete this._instances[userEmail];

    Logger.customLog('Have destroyed and removed ContactStore for ' + userEmail, LogLevel.INFO);
  }

  protected createContactStoreForUser(userEmail: string): ContactStore {
    Logger.customLog('Will cerate new instance of ContactStore for ' + userEmail, LogLevel.INFO);

    return new ContactStore(userEmail, this._injector);
  }
}
