import { inject, Injectable } from '@angular/core';
import { SharedTagDataAccessModule } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/shared-tag-data-access.module';
import { SharedTagApiService } from '@shared/api/api-loop/services/shared-tag-api.service';
import { StorageProviderService } from '@shared/cache/storage-provider/storage-provider';
import { ListOfResourcesOfListOfGroupSharedFolderTags } from '@shared/api/api-loop/models/list-of-resources-of-list-of-group-shared-folder-tags';
import { LRUCache } from 'lru-cache';
import { map, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SharedTagFolderModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

export const GROUP_FOLDER_SHARED_TAG_CACHE_NAME: string = 'groupSharedTagCache' as const;

@Injectable({ providedIn: SharedTagDataAccessModule })
export class CachedFolderSharedTagApiService {
  private readonly sharedTagApiService: SharedTagApiService = inject(SharedTagApiService);
  private readonly storageProviderService: StorageProviderService = inject(StorageProviderService);

  private readonly groupSharedTagStorage: LRUCache<string, SharedTagFolderModel[]> =
    this.storageProviderService.provideStorage<string, SharedTagFolderModel[]>(
      GROUP_FOLDER_SHARED_TAG_CACHE_NAME,
      50,
      1000 * 60 * 5
    );

  getFoldersForGroup$(groupId: string, userEmail: string): Observable<SharedTagFolderModel[]> {
    if (this.groupSharedTagStorage.get(groupId)) {
      return of(this.groupSharedTagStorage.get(groupId));
    }

    return this.sharedTagApiService
      .SharedTag_GetListOfSharedFolderTags2(
        {
          groupId: groupId
        },
        userEmail
      )
      .pipe(
        map(response => {
          const sharedTagFolders: SharedTagFolderModel[] = [];
          response.tags?.resources?.map(folder => {
            sharedTagFolders.push(new SharedTagFolderModel(folder));
          });
          response.systemTags?.resources?.map(folder => {
            sharedTagFolders.push(new SharedTagFolderModel(folder));
          });
          this.groupSharedTagStorage.set(groupId, sharedTagFolders);

          return sharedTagFolders;
        })
      );
  }

  clearCacheForGroup(groupId: string): void {
    this.groupSharedTagStorage.delete(groupId);
  }

  // Replace in prefecth-sync
  fetchAllFolders$(userEmail: string): Observable<ListOfResourcesOfListOfGroupSharedFolderTags> {
    return this.sharedTagApiService
      .SharedTag_GetListOfGroupsSharedFolderTags(
        {
          size: 1024
        },
        userEmail
      )
      .pipe(
        take(1),
        tap(response => {
          response?.resources.forEach(groupTags => {
            const folders: SharedTagFolderModel[] = [];
            groupTags.tags?.resources?.map(folder => {
              folders.push(new SharedTagFolderModel(folder));
            });
            groupTags.systemTags?.resources?.map(folder => {
              folders.push(new SharedTagFolderModel(folder));
            });
            this.groupSharedTagStorage.set(groupTags.parent?.id, folders);
          });
        })
      );
  }
}
