<div class="rsvp-container" *ngIf="appointment">
  <!-- Date -->
  <div class="rsvp-date" *ngIf="appointment.startTimeDate | isSameDay : appointment.endTimeDate;">
    <div class="month">{{ appointment.startTimeDate | date : 'MMM' }}</div>
    <div class="day">{{ appointment.startTimeDate | date : 'd' }}</div>
  </div>

  <div class="rsvp-data clearfix">
    <!-- Date/Time/Duration -->
    <div class="rsvp-meta-block">
      <svg-comp [src]="fuDateSvgUri"></svg-comp>
      <span class="rsvp-meta-title selectable"> Time: </span>

      <span class="rsvp-meta-content selectable">
        <ng-container *ngIf="appointment.startTimeDate | isSameDay : appointment.endTimeDate; else multiDayEventTpl">
          <ng-container *ngIf="appointment.isAllDay; else timeRepresentationTpl"> All day </ng-container>
          <ng-template #timeRepresentationTpl>
            {{ appointment.startTimeDate | date : 'HH:mm' }} - {{ appointment.endTimeDate | date : 'HH:mm' }} ({{
            appointment.startTimeDate | eventLength : appointment.endTimeDate }})
          </ng-template>
        </ng-container>
        <ng-template #multiDayEventTpl>
          <div class="description-text">
            {{ appointment.startTimeDate | date : 'EEE, MMM d, y, h:mm a' }} - {{ appointment.startTimeDate | date :
            'EEE, MMM d, y, h:mm a' }}
          </div>
        </ng-template>
      </span>
    </div>

    <!-- Location -->
    <div class="rsvp-meta-block">
      <svg-comp [src]="fuLocationSvgUri"></svg-comp>
      <span class="rsvp-meta-title selectable">Location:</span>

      <a
        *ngIf="appointment.location && (appointment.location | startWith :'http')"
        class="rsvp-meta-content selectable blue"
        href="{{appointment.location}}"
        target="_blank"
        >{{ appointment.location }}>
      </a>

      <a
        *ngIf="appointment.location && !(appointment.location | startWith :'http')"
        class="rsvp-meta-content selectable blue"
        href="https://www.google.com/maps/place/{{appointment.location}}"
        target="_blank"
      >
        {{ appointment.location }}
      </a>

      <span class="rsvp-meta-content selectable" *ngIf="!appointment.location">'N/A'</span>
    </div>

    <!-- Organizer -->
    <div class="rsvp-meta-block" *ngIf="(appointment.attendees?.resources || []) | organizer; let organizer">
      <svg-comp [src]="fuOrganizerSvgUri"></svg-comp>
      <span class="rsvp-meta-title selectable">Organizer:</span>
      <span class="rsvp-meta-content selectable"> {{ organizer | userName: true }} </span>
    </div>

    <!-- Invitees -->
    <div
      class="rsvp-meta-block t-name-list"
      *ngIf="((appointment.attendees?.resources || []) | invitees); let invitees"
    >
      <svg-comp [src]="fuInviteesSvgUri"></svg-comp>
      <span class="rsvp-meta-title selectable">Invitees:</span>

      <span class="rsvp-meta-content rsvp-meta-invitees">
        <div class="recipients-line">
          <span *ngFor="let invitee of invitees; let index=index" class="token" [title]="invitee.email">
            <span *ngIf="index < 3" class="name selectable">{{ invitee | userName: true }}</span>
          </span>
        </div>

        <span *ngIf="invitees.length > 3">
          <span class="name">& {{ invitees.length - 3 }} more</span>
        </span>
      </span>
    </div>

    <loop-button
      *ngIf="appointment.isCancelled"
      [isDisabled]="true"
      fill="color"
      color="danger"
      style="pointer-events: none"
    >
      Cancelled
    </loop-button>
  </div>
</div>
