import { inject, Injectable } from '@angular/core';
import { ContactsApiCacheModule } from '@shared/modules/contacts/shell/contacts-api-cache/contacts-api-cache.module';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { StorageProviderService } from '@shared/cache/storage-provider/storage-provider';
import { GroupSubType } from '@shared/api/api-loop/models/group-sub-type';
import { QueryRelation } from '@shared/api/api-loop/models/query-relation';
import { ContactSortOrder } from '@shared/api/api-loop/models/contact-sort-order';
import { GroupType } from '@shared/api/api-loop/models';
import { LRUCache } from 'lru-cache';
import { Observable } from 'rxjs';
import { ContactBaseModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { finalize, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: ContactsApiCacheModule
})
export class SmartGroupApiService {
  private readonly contactApiService: ContactApiService = inject(ContactApiService);
  private readonly storageProviderService: StorageProviderService = inject(StorageProviderService);

  protected readonly groupsApiCacheStorage: LRUCache<string, GroupModel[]> = this.storageProviderService.provideStorage(
    'smartGroupCache',
    300,
    1000 * 60 * 15
  );

  private ongoingGetAllRequest$: { [forUserEmail: string]: Observable<GroupModel[]> | null } = {};

  getAllInboxes$(forUserEmail: string): Observable<GroupModel[]> {
    const getAll$: Observable<GroupModel[]> = this.contactApiService
      .Contact_GetList(
        {
          subGroupTypes: [GroupSubType.SHARED_INBOX, GroupSubType.MANAGED_TEAM, GroupSubType.PERSONAL_INBOX],
          tagFilterRelation: QueryRelation.AND,
          offset: 0,
          size: 1024,
          offsetHistoryId: '',
          contactSortOrder: ContactSortOrder.WEIGHT
        },
        forUserEmail,
        true
      )
      .pipe(
        map(response => response.resources ?? []),
        map(response => ContactBaseModel.createList(response)),
        tap(groupModels => {
          this.groupsApiCacheStorage.set(forUserEmail, groupModels as GroupModel[]);
        }),
        finalize(() => (this.ongoingGetAllRequest$[forUserEmail] = null))
      ) as Observable<GroupModel[]>;

    this.ongoingGetAllRequest$[forUserEmail] = getAll$;

    return getAll$;
  }

  getAllPersonalInboxes$(forUserEmail: string): Observable<GroupModel[]> {
    return this.getAllInboxes$(forUserEmail).pipe(
      map(inboxes => inboxes.filter(group => group.groupType === GroupType.PERSONAL_INBOX))
    );
  }

  getAllSharedInboxes$(forUserEmail: string): Observable<GroupModel[]> {
    return this.getAllInboxes$(forUserEmail).pipe(
      map(inboxes => inboxes.filter(group => [GroupType.SHARED_INBOX].includes(group.groupType)))
    );
  }
}
