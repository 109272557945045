import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { RetryQueueServiceI } from './retry-queue.service.interface';
import { RetryModel } from '@dta/shared/models-api-loop/retry.model';
import { Observable, of } from 'rxjs';
import { RetryQueueDaoService } from '@shared/database/dao/retry-queue/retry-queue-dao.service';

@Injectable()
export class RetryQueueService implements RetryQueueServiceI {
  constructor(private _retrySyncDaoService: RetryQueueDaoService) {}

  get constructorName(): string {
    return 'RetryQueueService';
  }

  saveToQueue(forUserEmail: string, model: RetryModel): Observable<RetryModel[]> {
    if (_.isUndefined(model)) {
      return of(model);
    }

    return this.saveAllToQueue(forUserEmail, [model]);
  }

  saveAllToQueue(forUserEmail: string, models: RetryModel[]): Observable<RetryModel[]> {
    return this._retrySyncDaoService.saveAllToQueue(forUserEmail, models);
  }

  countWaiting(forUserEmail: string): Observable<Number> {
    return this._retrySyncDaoService.countWaiting(forUserEmail);
  }

  findNextBatch(forUserEmail: string, dbPageSize: number): Observable<RetryModel[]> {
    return this._retrySyncDaoService.findNextBatch(forUserEmail, dbPageSize);
  }

  findByDataId(forUserEmail: string, dataIds: string[]): Observable<RetryModel[]> {
    return this._retrySyncDaoService.findByDataId(forUserEmail, dataIds);
  }

  removeByDataId(forUserEmail: string, dataIds: string[]): Observable<RetryModel[]> {
    return this._retrySyncDaoService.removeByDataId(forUserEmail, dataIds);
  }

  removeById(forUserEmail: string, id: string): Observable<any> {
    return this._retrySyncDaoService.removeById(forUserEmail, id);
  }
}
