import * as _ from 'lodash';
import {
  ChannelCardsCollectionParams,
  ConversationFetchRequest,
  SearchableViewCollectionParams,
  ViewsFilter
} from '@dta/shared/models/collection.model';
import {
  FilterEnum,
  QueryRelation,
  SearchQueryCommonTagsConditions,
  SearchQueryConversation,
  SearchQueryConversationAction,
  SearchQueryConversationConditions,
  SearchQueryConversationConditionsWithConditions,
  ShowInViewObject,
  SortOrder,
  SortType,
  TagType,
  ViewEnum
} from '@shared/api/api-loop/models';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';
import { EventApiService } from '@shared/api/api-loop/services/event-api.service';
import { FolderBase } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';
import Event_GetConversationsEventParams = EventApiService.Event_GetConversationsEventParams;

export class ConversationHelper {
  static viewsFilterToFilterEnum(folder: FolderBase): FilterEnum {
    switch (folder.name) {
      case ViewsFilter.SENT:
        return FilterEnum.SENT;
      case ViewsFilter.STARRED:
        return FilterEnum.STARRED;
      case ViewsFilter.ASSIGNED:
        return FilterEnum.ASSIGNED;
      case ViewsFilter.DELETED:
        return FilterEnum.DELETED;
      case ViewsFilter.ARCHIVED:
        return FilterEnum.ARCHIVED;
      case ViewsFilter.CHAT:
        return FilterEnum.CHATS;
      case ViewsFilter.MUTED:
        return FilterEnum.MUTED;
      case ViewsFilter.ALL:
        return FilterEnum.ALL_MESSAGES;
      case ViewsFilter.DRAFTS:
        return FilterEnum.DRAFTS;
      case ViewsFilter.SNOOZE:
        return FilterEnum.SNOOZED;
      case ViewsFilter.SPAM:
        return FilterEnum.SPAM;
      // Boards only have boardId, no filterView
      case ViewsFilter.BOARD:
        return undefined;
      case ViewsFilter.INBOX:
      default:
        return FilterEnum.INBOX;
    }
  }

  static buildViewDataFromViewParameters(
    viewParameters: SearchableViewCollectionParams,
    mainView: ViewEnum
  ): ShowInViewObject {
    let viewData: ShowInViewObject = {
      view: mainView,
      filter: this.viewsFilterToFilterEnum(viewParameters.view)
    };

    // Add channel data
    if (mainView === ViewEnum.CHANNEL) {
      viewData.channelId = (<ChannelCardsCollectionParams>viewParameters).contacts[0].id;
    }

    // Add board (folder) data
    if (mainView === ViewEnum.PERSONAL_INBOX && !_.isEmpty(viewParameters.boardId)) {
      let genericBoardId = ConversationHelper.genericBoardIdToFilter(viewParameters.boardId);

      genericBoardId ? (viewData.filter = genericBoardId) : (viewData.folderId = viewParameters.boardId);
    }

    return viewData;
  }

  static getEventConversationQuery(
    size: number,
    historyId: string,
    maxChanges?: number
  ): Event_GetConversationsEventParams {
    return {
      size: size,
      historyId: historyId,
      maxChanges: maxChanges
    };
  }

  static getSearchQueryConversationForInitialUpdatesHistoryId(updatesFromDate?: string): SearchQueryConversation {
    let query: SearchQueryConversation = {
      $type: 'SearchQueryConversation',
      size: 0,
      sortOrder: SortOrder.ASCENDING,
      sortType: SortType.MODIFIED_DATE
    };

    if (updatesFromDate) {
      query.historyStartDate = updatesFromDate;
    }

    return query;
  }

  static getSearchQueryConversationForUpdates(historyId: string, cutOffTime: string): SearchQueryConversation {
    let query = this.getSearchQueryConversationForInitialUpdatesHistoryId();

    // Clear data that is imbedded in history id
    query.historyStartDate = undefined;

    // Set request properties
    query.historyId = historyId;
    query.size = 1024;

    // Fetch all updates
    // query.viewDateGreaterThan = cutOffTime;

    return query;
  }

  static genericBoardIdToFilter(boardId: string): FilterEnum {
    if (!TagModel.defaultBoards.includes(boardId)) {
      return undefined;
    }

    switch (boardId) {
      case TagType.SENT:
        return FilterEnum.SENT;
      case TagType.ARCHIVE:
        return FilterEnum.ARCHIVED;
      case TagType.DELETED:
        return FilterEnum.DELETED;
      default:
        return undefined;
    }
  }

  static getFetchRequestToSearchQueryConversation(fetchRequest: ConversationFetchRequest): SearchQueryConversation {
    // Base query
    let query: SearchQueryConversation = {
      $type: 'SearchQueryConversation',
      showInView: fetchRequest.showInViewData,
      hasUnreads: fetchRequest.unreadOnly || undefined,
      size: fetchRequest.size,
      historyId: fetchRequest.offsetHistoryId,
      sortOrder: fetchRequest.sortOrder || SortOrder.DESCENDING,
      sortType: SortType.VIEW_DATE,
      queryText: fetchRequest.textQuery,
      conversationDateFrom: fetchRequest.fromDate,
      conversationDateTo: fetchRequest.toDate,
      channelIds: fetchRequest.channelIds,
      conversationIds: fetchRequest.conversationsIds,
      commonConversationConditions: fetchRequest.commonConversationConditions
    };

    return this.addConversationConditions(
      query,
      fetchRequest.includeSharedTagIds,
      fetchRequest.excludeSharedTagIds,
      fetchRequest.includePrivateLabelIds,
      fetchRequest.includeClassificationTagIds
    );
  }

  static addConversationConditions<T extends SearchQueryConversation | SearchQueryConversationAction>(
    query: T,
    includeSharedTagIds?: string[],
    excludeSharedTagIds?: string[],
    includeTagIds?: string[],
    includeClassificationTagIds?: string[]
  ): T {
    // Additional query
    let conversationConditions: SearchQueryConversationConditionsWithConditions[] = [];

    // Condition for include Tags/Shared tags
    if (!_.isEmpty(includeSharedTagIds)) {
      let includeSharedTagsCondition = this.tagsAndSharedTagsConversationCondition(
        includeSharedTagIds,
        'include',
        'shared'
      );

      conversationConditions.push(includeSharedTagsCondition);
    }

    if (!_.isEmpty(includeClassificationTagIds)) {
      let includeClassificationConditions = this.tagsAndSharedTagsConversationCondition(
        includeClassificationTagIds,
        'atLeastOne',
        'shared'
      );

      conversationConditions.push(includeClassificationConditions);
    }

    // Condition for exclude Tags/Shared tags
    if (!_.isEmpty(excludeSharedTagIds)) {
      let excludeTagsCondition = this.tagsAndSharedTagsConversationCondition(excludeSharedTagIds, 'exclude', 'shared');

      conversationConditions.push(excludeTagsCondition);
    }

    // Condition for include tags
    if (!_.isEmpty(includeTagIds)) {
      let includeTagsCondition = this.tagsAndSharedTagsConversationCondition(includeTagIds, 'include', 'private');

      conversationConditions.push(includeTagsCondition);
    }

    // Add additional conditions
    if (!_.isEmpty(conversationConditions)) {
      query.conversationConditions = conversationConditions;
      query.conversationConditionsRelation = QueryRelation.AND;
    }

    return query;
  }

  private static tagsAndSharedTagsConversationCondition(
    tagIds: string[],
    relation: 'include' | 'exclude' | 'atLeastOne',
    tagType: 'shared' | 'private'
  ): SearchQueryConversationConditionsWithConditions {
    let tagsConditions = this.buildTagsAndSharedTagsCondition(tagIds, relation, tagType);

    let searchQueryCardConditions: SearchQueryConversationConditions = {
      tagsConditions: tagsConditions
    };

    return {
      conversationConditions: [searchQueryCardConditions],
      conversationConditionsRelation: QueryRelation.AND
    };
  }

  private static buildTagsAndSharedTagsCondition(
    tagIds: string[],
    relation: 'include' | 'exclude' | 'atLeastOne',
    tagType: 'shared' | 'private'
  ): SearchQueryCommonTagsConditions {
    let queryRelation: QueryRelation = undefined;

    switch (relation) {
      case 'include':
        queryRelation = QueryRelation.AND;
        break;
      case 'exclude':
        queryRelation = QueryRelation.AND_NOT;
        break;
      default:
        queryRelation = QueryRelation.OR;
    }

    // Don't POST empty arrays, replace them with undefined to omit parameter from query
    let tagsConditions: SearchQueryCommonTagsConditions = {
      // Shared tag condition
      sharedTagFilter: tagType === 'shared' && !_.isEmpty(tagIds) ? tagIds : undefined,
      sharedTagFilterRelation: tagType === 'shared' && !_.isEmpty(tagIds) ? queryRelation : undefined,

      // Tag condition
      tagFilter: tagType === 'private' && !_.isEmpty(tagIds) ? tagIds : undefined,
      tagFilterRelation: tagType === 'private' && !_.isEmpty(tagIds) ? queryRelation : undefined
    };

    return tagsConditions;
  }
}
