export const ClipboardCommentKeys = {
  commentId: 'commentId',
  fileName: 'fileName'
} as const;

export interface CommentClipboardData {
  [ClipboardCommentKeys.commentId]: string;
  [ClipboardCommentKeys.fileName]: string;
}

export function createCommentClipboardData(commentClipboardData: CommentClipboardData): string {
  return JSON.stringify(commentClipboardData);
}

export function parseCommentClipboardData(stringCommentClipboardData: string): CommentClipboardData {
  return JSON.parse(stringCommentClipboardData);
}
