import * as _ from 'lodash';
import { filter, from, Observable, of, toArray } from 'rxjs';
import { Injectable } from '@angular/core';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { BaseCollectionService } from '../base.collection';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CollectionOptions, CollectionSubscriberService, LoadMoreType } from '../collection-subscriber.service';
import { FetchResult, SingleConversationCollectionParams } from '@dta/shared/models/collection.model';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { CollectionService } from '@shared/services/data/collection/collection.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SingleConversationCollection extends BaseCollectionService<ConversationModel> {
  protected lastCommentId: string;
  protected supportedTypes: (typeof BaseModel)[] = [ConversationModel];
  loadMoreType: LoadMoreType = LoadMoreType.QUERY_THEN_FETCH;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _conversationService: ConversationService,
    protected _collectionSubscriberService: CollectionSubscriberService,
    protected _commentService: CommentService,
    protected _collectionService: CollectionService,
  ) {
    super(_userManagerService, _collectionSubscriberService);
  }

  get constructorName(): string {
    return 'SingleConversationCollection';
  }

  registerCollection(
    params: SingleConversationCollectionParams,
    options?: CollectionOptions,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<string> {
    if (!params || _.isNil(params.conversationId)) {
      throw new Error('CardId param cannot be nil');
    }
    return super.registerCollection(params, options, forUserEmail);
  }

  protected reduce(
    models: ConversationModel[],
    params: SingleConversationCollectionParams,
    forUserEmail: string,
  ): Observable<ConversationModel[]> {
    return from(models).pipe(
      filter((model: ConversationModel) => model.id === params.conversationId),
      toArray(),
    );
  }

  count(params: SingleConversationCollectionParams, forUserEmail: string): Observable<Number> {
    throw new Error('Not implemented yet');
  }

  query(params: SingleConversationCollectionParams, forUserEmail: string): Observable<ConversationModel[]> {
    return this._conversationService.findByCardId(forUserEmail, params.conversationId).pipe(
      map((conversation: ConversationModel) => {
        if (!conversation) {
          return [];
        }

        return [conversation];
      }),
    );
  }

  fetch(params: SingleConversationCollectionParams, forUserEmail: string): Observable<FetchResult> {
    return this._conversationService.fetchConversationsByCardIds(forUserEmail, [params.conversationId]);
  }

  protected doBeforeReduce(
    models: ConversationModel[],
    params: SingleConversationCollectionParams,
    forUserEmail: string,
  ): Observable<ConversationModel[]> {
    return of(models);
  }

  protected doBeforePublish(
    models: ConversationModel[],
    params: SingleConversationCollectionParams,
    forUserEmail: string,
  ): Observable<ConversationModel[]> {
    return of(models);
  }
}
