import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { debounceTime, map, publishReplay, refCount, switchMap, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { ContactBaseModel, ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'loop-mention',
  templateUrl: './mention.component.html',
  styleUrls: ['./mention.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MentionComponent implements OnDestroy {
  private readonly contactApiService: ContactApiService = inject(ContactApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  protected currentIndex: number = 0;
  private contacts: ContactModel[] = [];
  private readonly query$: ReplaySubject<string> = new ReplaySubject<string>(1);
  @Input() set query(query: string) {
    this.query$.next(query);
  }

  @Input() overrideMentionOptions?: ContactApiService.Contact_GetListParams;

  @Output() contactClick: EventEmitter<ContactModel> = new EventEmitter<ContactModel>();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      if (this.currentIndex === this.contacts?.length - 1) {
        return;
      }

      this.currentIndex = this.currentIndex + 1;
    } else if (event.code === 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();
      if (this.currentIndex <= 0) {
        return;
      }
      this.currentIndex = this.currentIndex - 1;
    }
  }

  protected readonly contacts$: Observable<ContactModel[]> = this.query$.pipe(
    debounceTime(200),
    switchMap(query => {
      return this.contactApiService.Contact_GetList(
        {
          contactIds: this.userManagerService.getMemberIdsOfUserLicenses(),
          searchQuery: query,
          size: 5,
          excludeContactIds: [this.userManagerService.getCurrentUserId()],
          ...this.overrideMentionOptions
        },
        this.userManagerService.getCurrentUserEmail()
      );
    }),
    map(response => ContactBaseModel.createList(response.resources)),
    tap(contacts => {
      this.contacts = contacts;
    }),
    publishReplay(1),
    refCount()
  );

  handleCurrentContactClick(): void {
    this.handleContactClick(this.contacts?.[this.currentIndex]);
  }

  handleContactClick(contact: ContactModel): void {
    if (!contact) {
      return;
    }

    this.contactClick.next(contact);
  }

  ngOnDestroy(): void {
    this.query$.complete();
  }
}
