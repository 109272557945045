import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@dta/ui/guards/auth.guard';
import { DeeplinkComponent } from './components/deeplink/deeplink.component';

export const deeplinkRoute = 'deeplink';

const routes: Routes = [
  {
    path: deeplinkRoute,
    component: DeeplinkComponent,
    children: [
      {
        path: '**',
        component: DeeplinkComponent,
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('../../shared/modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    loadChildren: () => import('../../shared/modules/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class WebRoutingModule {}
