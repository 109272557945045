import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ClassificationNode } from '@shared/modules/shared-tag/common/interfaces/classification-node';
import { ConversationActionService } from '@shared/services/data/conversation-action/conversation-action.service';
import { ActionEnum } from '@shared/api/api-loop/models/action-enum';
import { findSelectedNodeInArray } from '@shared/modules/shared-tag/common/helpers/find-selected-node';
import { take } from 'rxjs/operators';
import { SharedTagClassificationModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

@Component({
  selector: 'loop-classification-dropdown',
  templateUrl: './classification-dropdown.component.html',
  styleUrls: ['./classification-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassificationDropdownComponent {
  private readonly conversationActionService: ConversationActionService = inject(ConversationActionService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  @Input() selectedClassificationTag?: SharedTagClassificationModel;
  @Input() conversationIds: string[] = [];
  @Output() closeDropdown: EventEmitter<void> = new EventEmitter<void>();

  protected handleNodeClick(classificationNode: ClassificationNode): void {
    this.addOrRemoveClassificationTag(classificationNode);
  }

  private addOrRemoveClassificationTag(classificationNode: ClassificationNode): void {
    if (classificationNode.isNodeSelected) {
      this.closeDropdown.next();
      this.conversationActionService
        .conversationAction(this.userManagerService.getCurrentUserEmail(), {
          action: ActionEnum.CHANGE_CLASSIFICATION,
          removeTags: [classificationNode.classificationTag],
          conversationIds: this.conversationIds,
          isSharedAction: true
        })
        .pipe(take(1))
        .subscribe();
    } else {
      this.closeDropdown.next();
      this.conversationActionService
        .conversationAction(this.userManagerService.getCurrentUserEmail(), {
          action: ActionEnum.CHANGE_CLASSIFICATION,
          addTags: [classificationNode.classificationTag],
          conversationIds: this.conversationIds,
          isSharedAction: true
        })
        .pipe(take(1))
        .subscribe();
    }
  }

  protected removeClassificationTag(classificationTags: ClassificationNode[]): void {
    this.closeDropdown.next();
    const classificationNode = findSelectedNodeInArray(classificationTags);
    if (!classificationNode) {
      return;
    }

    this.conversationActionService
      .conversationAction(this.userManagerService.getCurrentUserEmail(), {
        action: ActionEnum.CHANGE_CLASSIFICATION,
        removeTags: [classificationNode.classificationTag],
        conversationIds: this.conversationIds,
        isSharedAction: true
      })
      .pipe(take(1))
      .subscribe();
  }
}
