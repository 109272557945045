import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmWithSingleButtonDialogContentComponent } from './confirm-with-single-button-dialog-content.component';
import { ButtonModule } from '@shared/ui/button/button.module';
import { AlertModule } from '@shared/ui/alert/alert.module';
import { AutofocusModule } from '@dta/ui/directives/autofocus.directive';

@NgModule({
  declarations: [ConfirmWithSingleButtonDialogContentComponent],
  imports: [CommonModule, ButtonModule, AlertModule, AlertModule]
})
export class ConfirmWithSingleButtonDialogContentModule {}
