import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileNameComponent } from '@shared/modules/files/components/file-name/file-name.component';

@NgModule({
  declarations: [FileNameComponent],
  imports: [CommonModule],
  exports: [FileNameComponent]
})
export class FileNameModule {}
