<span class="dropdown" [class.open-right]="openRight">
  <!-- Dropdown toggle -->
  <span (click)="toggleDropdown()">
    <ng-content select="dropdown-toggle"> </ng-content>
  </span>

  <!-- Dropdown -->
  <div *ngIf="open" aria-labelledby="simple-dropdown" class="dropdown-menu">
    <div *ngIf="!datePickerTab">
      <div class="dropdown-header">
        <div class="title">SNOOZE</div>
      </div>

      <div #itemsWrapper class="dropdown-items-wrapper">
        <!-- Snooze due time -->
        <div
          *ngIf="snoozeDueDate"
          class="dropdown-item snooze-due top-row"
          [tooltip]="{content: selectedSnoozeTimeTooltip, placement: TooltipPlacement.LEFT}"
        >
          <!-- Icon -->
          <svg-comp [src]="laterSvg"> </svg-comp>

          <span>{{hasPendingSnooze ? 'Snoozed' : 'Snooze due'}}</span>

          <!-- Display Time -->
          <div class="option-time">{{selectedSnoozeTimeFormatted}}</div>
        </div>

        <ng-template ngFor let-option [ngForOf]="options" let-i="index">
          <div
            class="dropdown-item {{ option.extraClass }}"
            [class.selected]="i === selectionIndex"
            [class.action-button]="option.uiDiscard"
            (click)="selectOption(option)"
            (mouseenter)="setSelectionIndex(i, true)"
          >
            <!-- Icon -->
            <svg-comp *ngIf="option.svg" [src]="option.svg"> </svg-comp>

            <!-- Name -->
            <span>{{option.name}}</span>

            <!-- Display Time -->
            <div *ngIf="option.displayTime" class="option-time">{{option.displayTime}}</div>

            <!-- Subsection indicator -->
            <svg-comp *ngIf="option.subsection" class="subsection" [src]="dropdownToggleSvg"> </svg-comp>
          </div>
        </ng-template>
      </div>
    </div>

    <div *ngIf="datePickerTab">
      <!-- Back -->
      <div class="subsection-back" (click)="hideDatePicker()">Back</div>

      <!-- Header -->
      <div class="dropdown-header">
        <div class="title">Select date and time</div>
      </div>

      <!-- Date picker -->
      <date-picker
        [submitButtonText]="'Snooze'"
        [upperLimitDate]="upperLimitDate"
        [initialSelectedTime]="selectedSnoozeTime"
        (dateSelected)="dateSelected($event)"
      >
      </date-picker>
    </div>
  </div>
</span>
