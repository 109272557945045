/* tslint:disable */

/**
 * Sort Type enum
 */
export type SortType = 'ModifiedDate' | 'CreatedDate' | 'ViewDate';

export module SortType {
  export const MODIFIED_DATE: SortType = 'ModifiedDate';
  export const CREATED_DATE: SortType = 'CreatedDate';
  export const VIEW_DATE: SortType = 'ViewDate';

  export function values(): SortType[] {
    return [MODIFIED_DATE, CREATED_DATE, VIEW_DATE];
  }
}
