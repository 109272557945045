import { combineLatest, Observable } from 'rxjs';
import { debounceTime, defaultIfEmpty, map } from 'rxjs/operators';

export function everyTrue(...inputs: Observable<any>[]): Observable<boolean> {
  return combineLatest(inputs).pipe(
    debounceTime(0),
    map(values => values.every(value => !!value)),
    defaultIfEmpty(true)
  );
}
