import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { KeyboardActionModeType } from '@dta/shared/models/keyboard-shortcut.model';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';

@Component({
  selector: 'pdfjs',
  styleUrls: ['./pdfjs.scss'],
  templateUrl: './pdfjs.html',
})
export class PdfJsComponent implements OnInit, AfterViewInit, OnChanges {
  ///////////////////
  // Inputs/Outputs
  ///////////////////
  @Input() pdfUri: string;

  @ViewChild('iframe', { static: false }) private iframe;

  ////////////////////
  // State variables
  ////////////////////
  pdfJsUri: SafeResourceUrl;
  private contextmenuBroadcaster = undefined;
  private clickBroadcaster = undefined;

  constructor(
    private _sanitizer: DomSanitizer,
    private _keyboardShortcutsListenerService: KeyboardShortcutsListenerService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pdfUri) {
      // Encode url because it can include some invalid characters (BE generates ids that have "==" in them)
      this.pdfUri = encodeURIComponent(this.pdfUri);

      let uri = 'shared/assets/pdfjs/web/viewer.html?file=' + this.pdfUri;

      this.pdfJsUri = this._sanitizer.bypassSecurityTrustResourceUrl(uri);
    }
  }

  ngOnInit() {
    this._keyboardShortcutsListenerService.setMode(KeyboardActionModeType.FILE_PREVIEWER);
  }

  ngAfterViewInit(): void {
    this.listenForContextMenuEvents();
    this.listenForClickEvents();
  }

  private listenForContextMenuEvents() {
    this.contextmenuBroadcaster = new BroadcastChannel('contextmenu-pdfjs');
    this.contextmenuBroadcaster.onmessage = this.onContextMenuEvent.bind(this);
  }

  private listenForClickEvents() {
    this.clickBroadcaster = new BroadcastChannel('click-pdfjs');
    this.clickBroadcaster.onmessage = this.onClickEvent.bind(this);
  }

  private onContextMenuEvent = message => {
    // Get offset
    let clRect = this.iframe.nativeElement.getBoundingClientRect();

    // Create event
    let event = new MouseEvent('contextmenu-pdfjs', {
      bubbles: true,
      cancelable: false,
      clientX: message.data.clientX + clRect.left,
      clientY: message.data.clientY + clRect.top,
    });

    // Dispatch
    this.iframe.nativeElement.dispatchEvent(event);
  };

  private onClickEvent = message => {
    // Create event
    let event = new MouseEvent('click-pdfjs', {
      bubbles: true,
      cancelable: false,
    });

    // Dispatch
    this.iframe.nativeElement.dispatchEvent(event);
  };
}
