import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { DropdownActionItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { AttachmentService } from '@shared/modules/main/common/attachments/attachment/attachment.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { DIALOG_CONTENT, DialogContent } from '@shared/ui/kit/dialog/modal-content-element-ref';
import { CommentApiService, FileApiService } from '@shared/api/api-loop/services';
import { CommentFormComponent } from '@shared/modules/comments/components/comment-form/comment-form.component';
import { LOOP_DYNAMIC_COMPONENT_NAME } from '@shared/modules/loop/components/loop-dynamic-components';
import { ModalFormService } from '@shared/forms/shell/modal-form/modal-form.service';
import { MailCommentOperationsService } from '@shared/modules/comments/components/mail-comment/mail-comment-operations/mail-comment-operations.service';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { v1 } from 'uuid';

@UntilDestroy()
@Component({
  selector: 'loop-attachments-list-content',
  templateUrl: './attachments-list-content.component.html',
  styleUrls: ['./attachments-list-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsListContentComponent {
  private readonly fileApiService: FileApiService = inject(FileApiService);
  private readonly attachmentService: AttachmentService = inject(AttachmentService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly dialogContent: DialogContent | null = inject(DIALOG_CONTENT, { optional: true });
  private readonly modalFormService: ModalFormService = inject(ModalFormService);
  private readonly mailCommentOperationsService: MailCommentOperationsService = inject(MailCommentOperationsService);

  @Input() attachments: IFile[] = [];

  protected readonly getDropdownItemsFn: (attachment: IFile) => DropdownActionItem[] = (attachment: IFile) => {
    return [
      {
        title: 'Preview',
        icon: 'eye',
        action: () => this.openAttachment(attachment)
      },
      {
        title: 'Send',
        icon: 'send',
        action: () => this.sendAttachment(attachment)
      },
      // ...isWebApp()
      //   ? []
      //   : [{
      //     title: 'Show message',
      //     icon: 'message',
      //     action: () => this.showMessage(attachment)
      //   }],
      {
        title: 'Download',
        icon: 'download',
        action: () => this.downloadAttachment(attachment)
      }
    ];
  };

  private sendAttachment(attachment: IFile): void {
    this.modalFormService.create<CommentApiService.Comment_CreateCommentMailParams, CommentFormComponent, any>({
      modalTitle$: of('New mail'),
      componentId: LOOP_DYNAMIC_COMPONENT_NAME.CommentFormComponent,
      windowIdentifier: v1(),
      useCustomLayout: true,
      componentParams: {
        defaults: {
          attachments: [attachment]
        }
      },
      modalWidth: '1000px',
      afterSubmit$: (value: CommentApiService.Comment_CreateCommentMailParams) => {
        return this.mailCommentOperationsService.createCommentMail$(value);
      },
      observer: () => {
        //noop
      }
    });
  }

  private downloadAttachment(file: IFile): void {
    this.fileApiService
      .File_GetFile({ id: file.id }, this.userManagerService.getCurrentUserEmail(), true)
      .pipe(
        switchMap(file => {
          return this.attachmentService.downloadAttachment(
            this.userManagerService.getCurrentUserEmail(),
            new FileModel({ ...file })
          );
        })
      )
      .pipe(untilDestroyed(this), take(1))
      .subscribe();
  }

  private openAttachment(file: IFile): void {
    this.attachmentService.onFileIconClick({
      forUserEmail: this.userManagerService.getCurrentUserEmail(),
      file: file,
      files: this.attachments
    });
  }

  protected readonly onAttachmentClickFn: (file: IFile) => () => void = file => {
    return () => this.openAttachment(file);
  };
}
