import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentsListContentComponent } from '@shared/modules/files/components/attachments-list-content/attachments-list-content.component';
import { CommonAttachmentModule } from '@shared/modules/files/components/common-attachment/common-attachment.module';
import { AsPureArgumentForModule } from '@shared/ui/pipes/as-pure-argument-for/as-pure-argument-for.module';

@NgModule({
  declarations: [AttachmentsListContentComponent],
  imports: [CommonModule, CommonAttachmentModule, AsPureArgumentForModule],
  exports: [AttachmentsListContentComponent]
})
export class AttachmentsListContentModule {}
