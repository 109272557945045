import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FolderNode } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';

@Injectable()
export class FolderDialogService {
  toggleDialog$: Subject<FolderDialogData> = new Subject();

  constructor() {}
}

export class FolderDialogData {
  constructor(
    public title: string,
    public folders: FolderNode[],
    public groupId: string,
    public folder?: FolderNode,
  ) {}
}

export enum FolderTitleEnum {
  CREATE = 'New folder',
  EDIT = 'Edit folder',
}
