import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { EMPTY, from, Observable, of } from 'rxjs';
import { SharedTagAssignee, User } from '@shared/api/api-loop/models';
import { catchError, defaultIfEmpty, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { BaseExDecorateService } from '../base-ex-decorate.service';
import {
  SharedTagAssigneeModel,
  SharedTagBaseModel,
  SharedTagModel,
} from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';

@Injectable()
export class SharedTagExDecorateService extends BaseExDecorateService<SharedTagModel> {
  constructor(private _contactStoreFactory: ContactStoreFactory) {
    super();
  }

  decorateExtraData(forUserEmail: string, sharedTag: SharedTagModel, force?: boolean): Observable<SharedTagModel> {
    if (sharedTag._ex && !force) {
      return of(sharedTag);
    }

    let ex = {};
    sharedTag._ex = ex;

    return of(sharedTag);
  }

  decorateGroupAvailableSharedTags(group: GroupModel, forUserEmail: string): Observable<GroupModel> {
    if (!group.hasAvailableSharedTags()) {
      return of(group);
    }

    let sharedTags = SharedTagBaseModel.createList(group.getAvailableSharedTags()).filter(sharedTag => {
      return !_.isNil(sharedTag);
    });
    let usersById;

    return this.findNonGroupMemberAssignees(group, forUserEmail).pipe(
      tap((assignees: ContactModel[]) => {
        let users = _.unionBy(group.getAllMembers(), assignees, 'id');
        usersById = _.keyBy(users, 'id');
      }),
      mergeMap(() => {
        return from(sharedTags);
      }),
      mergeMap((sharedTag: SharedTagModel) => {
        if (sharedTag instanceof SharedTagAssigneeModel) {
          let user = usersById[sharedTag.userId];
          return of(this.decorateSharedTagAssigneeExtraData(sharedTag, user));
        }
        return of(sharedTag);
      }),
      toArray(),
      map((sharedTags: SharedTagModel[]) => {
        group.availableSharedTags.resources = sharedTags;
        return group;
      }),
    );
  }

  private findNonGroupMemberAssignees(group: GroupModel, forUserEmail: string): Observable<ContactModel[]> {
    let assigneeSharedTags = group.getAssigneeSharedTags();
    let members = group.getAllMembers();
    let missingUserIds = _.differenceWith(assigneeSharedTags, members, (tag: SharedTagAssignee, user: User) => {
      return tag.userId === user.id;
    }).map((tag: SharedTagAssignee) => {
      return tag.userId;
    });

    // There is cyclic dependency that breaks app. So we inject
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return from(missingUserIds).pipe(
      mergeMap((missingUserId: string) => {
        return contactStore.getContactByIdOrThrow(missingUserId);
      }),
      catchError(err => {
        return EMPTY;
      }),
      toArray(),
      defaultIfEmpty([]),
    );
  }

  private decorateSharedTagAssigneeExtraData(sharedTag: SharedTagAssigneeModel, user: User): SharedTagAssigneeModel {
    if (user) {
      sharedTag._ex = {
        user: user,
      };
    }

    return sharedTag;
  }
}
