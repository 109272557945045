import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactBase, User } from '@shared/api/api-loop/models';
import { UserModel } from '../../../../shared/models-api-loop/contact/contact.model';
import { TooltipPlacement } from '../tooltip/tooltip.component';
import { TooltipService } from '../../common/tooltip/tooltip.service';

@Component({
  selector: 'chip',
  templateUrl: './chip.html',
  styleUrls: ['./chip.scss'],
})
export class ChipComponent implements OnInit {
  @Input() chip: ContactBase;
  @Input() preview = false;
  @Input() passive: boolean;
  @Input() big: boolean = false;
  @Input() removable: boolean = false;
  @Input() unremovable: boolean = false;
  @Input() last: boolean = false;
  @Input() draggable: boolean = false;

  @Output() chipRemoved = new EventEmitter();

  placeholder: string;
  name: string;
  email: string = '';
  TooltipPlacement = TooltipPlacement;

  chipRemoveSvg = require('shared/assets/img/svg/close-icon.svg');

  constructor(private _tooltipService: TooltipService) {}

  ngOnInit() {
    if (!this.chip) {
      return;
    }

    // generate placeholder
    if (this.chip.$type === UserModel.type && this.chip.name) {
      this.placeholder = this.generatePlaceholder((<User>this.chip).email, this.chip.name);
    } else if (this.chip.$type === UserModel.type && !this.chip.name) {
      this.placeholder = this.generatePlaceholder((<User>this.chip).email, (<User>this.chip).email);
    }

    // generate name if no name is present
    if (!this.name) {
      if (this.chip.$type === UserModel.type) {
        this.name = this.generateName((<User>this.chip).email, this.chip.name, (<User>this.chip).displayName);
      } else if (this.chip.id) {
        this.name = this.generateName(this.chip.name, this.chip.name, undefined);
      }
    } else {
      this.name = this.chip.name || '';
    }

    if (this.chip.$type === UserModel.type) {
      this.email = (<User>this.chip).email;
    }
  }

  // Remove chip
  // send remove event up to parent
  remove($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.chipRemoved.emit(this.chip);
  }

  dragStart($event: DragEvent) {
    // remove tooltip on dragstart
    this._tooltipService.removeTooltip();
    $event.dataTransfer.setData('chip', JSON.stringify(this.chip));
  }

  // Generate name from email
  private generateName(email: string, name: string, displayName: string): string {
    if (displayName) {
      return displayName;
    }

    if (!email && name) {
      return name;
    } else if (name && email) {
      return name;
    } else if (email) {
      let parts = email.split('@');
      let displayName = parts[0];
      return displayName.replace('.', ' ');
    } else {
      return '';
    }
  }

  // Generate placeholder from email
  private generatePlaceholder(email: string, name: string): string {
    if (!email && name) {
      return name.substr(0, 2);
    } else if (email) {
      let preword = email.split('@');
      let words = preword[0].split('.');
      let initials = '';

      if (words.length >= 2) {
        initials = words[0].substring(0, 1) + words[1].substring(0, 1);
      } else {
        if (words[0].length === 1) {
          initials = words[0].substring(0, 1) + words[0].substring(0, 1);
        } else {
          initials = words[0].substring(0, 2);
        }
      }
      return initials;
    }
  }
}
