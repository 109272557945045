import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import {
  DraftCardsCollectionParams,
  DraftCommentCollectionParams,
  FetchResult,
} from '../../../../../dta/shared/models/collection.model';
import { forkJoin, Observable, of } from 'rxjs';
import { CardApiService, CommentApiService } from '@shared/api/api-loop/services';
import { CardService } from '@shared/services/data/card/card.service';
import { BaseCollectionService } from '@shared/services/data/collection/base-collection/base-collection.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { CommentDraft, ListOfResourcesOfCardBase, ListOfResourcesOfCommentBase } from '@shared/api/api-loop/models';
import {
  CardBaseModel,
  CardDraftModel,
  CardMailModel,
  CardModel,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CommentBaseModel, CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class DraftCollectionService extends BaseCollectionService {
  constructor(
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
    private _cardApiService: CardApiService,
    private _commentApiService: CommentApiService,
  ) {
    super(_commentService, _cardService, _contactService);
  }

  get constructorName(): string {
    return 'DraftCollectionService';
  }

  fetchDraftsForCard(forUserEmail: string, params: DraftCardsCollectionParams): Observable<FetchResult> {
    let draftCardIds = (<CardMailModel>CardMailModel.create(params.forCard)).getDraftCardIds();
    if (_.isEmpty(draftCardIds)) {
      let result: FetchResult = {
        dataLength: 0,
        hasData: false,
        offsetHistoryId: undefined,
      };

      return of(result);
    }

    return this._cardApiService.Card_GetList({ cardIds: draftCardIds, size: draftCardIds.length }, forUserEmail).pipe(
      mergeMap((draftCards: ListOfResourcesOfCardBase) => {
        let draftCardModels = CardBaseModel.createList(draftCards.resources);

        return forkJoin([this.getDraftCommentsForCards(forUserEmail, draftCardModels), of(draftCardModels)]);
      }),
      mergeMap(([draftComments, draftCardModels]: [CommentModel[], CardDraftModel[]]) => {
        // Map comments to cards
        let draftCommentsByIds = _.keyBy(draftComments, 'id');
        draftCardModels = _.map(draftCardModels, (draftCard: CardDraftModel) => {
          let commentId = draftCard.commentDraft.id;
          draftCard.commentDraft = draftCommentsByIds[commentId] || draftCard.commentDraft;

          return draftCard;
        });

        return this._cardService.saveAllAndPublish(forUserEmail, draftCardModels);
      }),
      map((draftCards: CardDraftModel[]) => {
        let fetchResult: FetchResult = {
          offsetHistoryId: undefined,
          dataLength: draftCards?.length || 0,
          hasData: !!draftCards,
        };

        return fetchResult;
      }),
    );
  }

  private getDraftCommentsForCards(forUserEmail: string, draftCardModels: CardModel[]): Observable<CommentModel[]> {
    let draftCommentIds = _.map(draftCardModels, (c: CardDraftModel) => c.commentDraft.id);
    return this._commentApiService
      .Comment_GetList({ commentIds: draftCommentIds, size: draftCommentIds.length }, forUserEmail)
      .pipe(
        mergeMap((draftComments: ListOfResourcesOfCommentBase) => {
          return forkJoin([
            of(CommentBaseModel.createList(draftComments.resources)),
            this._commentService.findCommentsByIdsOrClientIds(forUserEmail, draftCommentIds),
          ]);
        }),
        mergeMap(([commentModels, drafts]) => {
          drafts.forEach(_draft => {
            let comment = _.find(commentModels, _comment => _comment.id === _draft.id);
            if (comment) {
              comment._ex = _draft._ex;
            }
          });
          return this._commentService.saveAllAndPublish(forUserEmail, commentModels);
        }),
      );
  }

  fetchDraftComment(forUserEmail: string, params: DraftCommentCollectionParams): Observable<FetchResult> {
    if (_.isEmpty(params.draftComment?.id)) {
      let result: FetchResult = {
        dataLength: 0,
        hasData: false,
        offsetHistoryId: undefined,
      };

      return of(result);
    }

    return this._commentApiService.Comment_Get({ id: params.draftComment?.id }, forUserEmail).pipe(
      mergeMap((comment: CommentDraft) =>
        this._commentService.saveAndPublish(forUserEmail, CommentBaseModel.create(comment)),
      ),
      map((savedComment: CommentDraft) => {
        let fetchResult: FetchResult = {
          dataLength: savedComment ? 1 : 0,
          hasData: !!savedComment,
          offsetHistoryId: undefined,
        };

        return fetchResult;
      }),
    );
  }
}
