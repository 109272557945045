import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MoveToTeamDropdownComponent } from '@shared/modules/teams/components/move-to-team-dropdown/move-to-team-dropdown.component';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { IsLabelSelectedPipeModule } from '@shared/modules/shared-tag/pipes/is-label-selected.pipe/is-label-selected.pipe.module';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';
import { AvatarModule } from '@dta/ui/components/common/avatar/avatar.module';

@NgModule({
  declarations: [MoveToTeamDropdownComponent],
  imports: [
    CommonModule,
    TextControlModule,
    FormsModule,
    IconModule,
    IsLabelSelectedPipeModule,
    AvatarModule,
    ContactModule,
  ],
  exports: [MoveToTeamDropdownComponent],
})
export class MoveToTeamDropdownModule {}
