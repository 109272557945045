import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'loop-conversation-tag',
  templateUrl: './conversation-tag.component.html',
  styleUrls: ['./conversation-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationTagComponent implements OnDestroy {
  private isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set isDisabled(isDisabled: boolean) {
    this.isDisabled$.next(isDisabled);
  }

  private color$: ReplaySubject<string> = new ReplaySubject<string>(1);
  @Input() set color(color: string) {
    this.color$.next(color);
  }

  @Input() content?: string;
  @Input() isLoading?: boolean;
  @Input() suffixTpl?: TemplateRef<void>;
  @Input() prefixTpl?: TemplateRef<void>;

  private readonly disabledColor = '#9d9ba0';

  protected readonly textColor$: Observable<string> = combineLatest([this.color$, this.isDisabled$]).pipe(
    map(([color, isDisabled]) => {
      if (isDisabled) {
        return this.disabledColor;
      }

      return this.getColor(color);
    })
  );

  protected readonly borderColor$: Observable<string> = combineLatest([this.color$, this.isDisabled$]).pipe(
    map(([color, isDisabled]) => {
      if (isDisabled) {
        return this.disabledColor + '1a';
      }

      return this.getColor(color);
    })
  );

  protected readonly backgroundColor$: Observable<string> = combineLatest([this.color$, this.isDisabled$]).pipe(
    map(([color, isDisabled]) => {
      if (isDisabled) {
        return this.disabledColor + '1a';
      }

      return this.getColor(color) + '1a';
    })
  );

  private getColor(color: string): string {
    if (color?.includes('#')) {
      return color;
    }
    return getComputedStyle(document.documentElement).getPropertyValue(color).trim();
  }

  ngOnDestroy(): void {
    this.color$.complete();
    this.isDisabled$.complete();
  }
}
