export function hash(s: string): Number {
  /*
   * Hashing function.
   *
   * Source: http://stackoverflow.com/a/7616484/861170
   * */
  let hashString = 0,
    i: number,
    chr: number,
    len: number;
  if (s.length === 0) {
    return hashString;
  }
  for (i = 0, len = s.length; i < len; i++) {
    chr = s.charCodeAt(i);
    hashString = (hashString << 5) - hashString + chr;
    hashString |= 0; // Convert to 32bit integer
  }
  return hashString;
}
