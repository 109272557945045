import { ChangeDetectionStrategy, Component, inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { GroupSubType } from '@shared/api/api-loop/models/group-sub-type';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { CardApiService } from '@shared/api/api-loop/services/card-api.service';
import { moveToNextCard } from '@shared/modules/middle-view/helpers/move-to-next-card';
import { CustomDropdownBase } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown-base';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { debounceTime, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { ContactBaseModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';

@UntilDestroy()
@Component({
  selector: 'loop-move-to-team-dropdown',
  templateUrl: './move-to-team-dropdown.component.html',
  styleUrls: ['./move-to-team-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoveToTeamDropdownComponent extends CustomDropdownBase<GroupModel> implements OnInit, OnDestroy {
  private readonly contactApiService: ContactApiService = inject(ContactApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly cardApiService: CardApiService = inject(CardApiService);
  private readonly injector: Injector = inject(Injector);
  private readonly trackingService: TrackingService = inject(TrackingService);

  protected visibleDataLength: number = 0;
  protected readonly teamSearchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input() conversation?: ConversationModel;

  protected readonly teams$: Observable<GroupModel[]> = this.teamSearchQuery$.pipe(
    debounceTime(500),
    switchMap(teamSearchQuery => {
      return this.contactApiService.Contact_GetList(
        {
          size: 1024,
          subGroupTypes: [GroupSubType.PERSONAL_INBOX, GroupSubType.MANAGED_TEAM, GroupSubType.SHARED_INBOX],
          searchQuery: teamSearchQuery
        },
        this.userManagerService.getCurrentUserEmail()
      );
    }),
    map(contacts => ContactBaseModel.createList(contacts.resources) as GroupModel[]),
    publishReplay(1),
    refCount()
  );

  ngOnInit(): void {
    this.teams$.pipe(untilDestroyed(this)).subscribe(teams => {
      this.visibleDataLength = teams.length;
      this.currentItems = teams;
    });
  }

  protected currentItemClick(): void {
    this.moveToTeam(this.currentItems[this.currentIndex]);
  }

  moveToTeam(team: GroupModel): void {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'MoveToTeamDropdown',
      'Move to team',
      {
        conversationIds: this.conversation.cardId,
        team: team.id
      }
    );

    this.conversation.moveToTeam(team);
    this.closeDropdown.next();
    let params: CardApiService.Card_CardMoveToTeamResponseParams = {
      id: this.conversation.cardId,
      groupId: team.id
    };

    this.cardApiService
      .Card_CardMoveToTeamResponse(params, this.userManagerService.getCurrentUserEmail())
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        moveToNextCard(this.conversation.cardId, this.injector);
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.teamSearchQuery$.complete();
  }
}
