import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Injector,
  Input,
  OnDestroy
} from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { moveToNextCard } from '@shared/modules/middle-view/helpers/move-to-next-card';
import { SnoozeApiActionsService } from '@shared/modules/tags/services/snooze-api-actions/snooze-api-actions.service';
import { CustomDropdownBase } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown-base';
import * as moment from 'moment/moment';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { Observable, publishReplay, ReplaySubject, switchMap } from 'rxjs';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { map, refCount, take, tap } from 'rxjs/operators';
import { RangeDate } from '@dta/ui/components/date-picker/date-picker.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';

interface SnoozeDropdownOptions {
  name: string;
  callback: () => void;
  svg?: NodeRequire;
  displayTime?: string;
  subsection?: boolean;
  uiDiscard?: boolean;
  extraClass?: string;
}

@UntilDestroy()
@Component({
  selector: 'loop-snooze-dropdown',
  templateUrl: './snooze-dropdown.component.html',
  styleUrls: ['./snooze-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnoozeDropdownComponent extends CustomDropdownBase<SnoozeDropdownOptions> implements OnDestroy {
  private readonly storageService: StorageService = inject(StorageService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly injector: Injector = inject(Injector);
  private readonly snoozeApiActionsService: SnoozeApiActionsService = inject(SnoozeApiActionsService);

  protected isDatePickerTabSelected: boolean = false;
  protected readonly TooltipPlacement: typeof TooltipPlacement = TooltipPlacement;

  protected readonly laterSvg: NodeRequire = require('shared/assets/img/svg/time-later.svg');
  protected readonly calendarSvg: NodeRequire = require('shared/assets/img/svg/calendar2.svg');
  protected readonly cupSvg: NodeRequire = require('shared/assets/img/svg/medium-cup.svg');
  protected readonly briefcaseSvg: NodeRequire = require('shared/assets/img/svg/briefcase.svg');
  protected readonly dropdownToggleSvg: NodeRequire = require('shared/assets/img/svg/open-dropdown.svg');
  protected readonly sunSvg: NodeRequire = require('shared/assets/img/svg/medium-sun.svg');
  protected readonly replySvg: NodeRequire = require('shared/assets/img/svg/replay-circle.svg');
  protected visibleDataLength: number = 0;

  private readonly defaultOptions: SnoozeDropdownOptions[] = [
    {
      name: 'Tomorrow',
      displayTime: moment(this.getDateAt(1, 9, 0)).format('ddd HH:mm'),
      callback: () => {
        this.snooze(this.getDateAt(1, 9, 0) as string);
      },
      svg: this.cupSvg
    },
    {
      name: 'Next week',
      displayTime: moment(this.getNextMondayDateAt(9, 0)).format('ddd HH:mm'),
      callback: () => {
        this.snooze(this.getNextMondayDateAt(9, 0));
      },
      svg: this.briefcaseSvg
    },
    {
      name: 'Someday',
      svg: this.sunSvg,
      callback: () => {
        this.someday();
      }
    }
  ];

  protected readonly conversation$: ReplaySubject<ConversationModel> = new ReplaySubject<ConversationModel>(1);
  @Input() set conversation(conversation: ConversationModel) {
    this.conversation$.next(conversation);
  }

  get storageKey(): string {
    return this.storageService.getKey(this.userManagerService.getCurrentUserEmail(), StorageKey.lastSelectedSnooze);
  }

  get upperLimitDate(): Date {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    return date;
  }

  protected readonly snoozeDueDate$: Observable<string> = this.conversation$.pipe(
    map(conversation => conversation.getSnoozeDueDate())
  );

  protected readonly selectedSnoozeTimeTooltip$: Observable<string> = this.conversation$.pipe(
    map(conversation => {
      const snoozeDueDate = conversation.getSnoozeDueDate();
      if (!snoozeDueDate || conversation.hasSnoozeSomeday()) {
        return undefined;
      }

      return moment(snoozeDueDate).format('MMMM Do YYYY, h:mm a');
    })
  );

  protected readonly hasPendingSnooze$: Observable<boolean> = this.conversation$.pipe(
    map(conversation => {
      return conversation.hasPendingSnooze();
    })
  );

  protected readonly selectedSnoozeTime$: Observable<string> = this.conversation$.pipe(
    map(conversation => {
      if (conversation.hasPendingSnooze()) {
        return conversation.getSnoozeDueDate();
      }

      return undefined;
    }),
    publishReplay(1),
    refCount()
  );

  protected readonly selectedSnoozeTimeFormatted$: Observable<string> = this.conversation$.pipe(
    map(conversation => {
      const snoozeDueDate = conversation.getSnoozeDueDate();
      if (conversation.hasSnoozeSomeday()) {
        return 'Someday';
      }

      return moment(snoozeDueDate).format('ddd HH:mm');
    })
  );

  protected readonly snoozeDropdownOptions$: Observable<SnoozeDropdownOptions[]> = this.conversation$.pipe(
    map(conversation => {
      const snoozeDueDate = conversation.getSnoozeDueDate();
      const lastSelected: string = this.getLastSelectedFromLocalStorage();
      const showLastSelected: boolean =
        lastSelected && !moment(lastSelected).isSame(snoozeDueDate) && moment(new Date()).isBefore(lastSelected);

      return [
        ...(this.isTimeTodayBeforeHour(16)
          ? [
              {
                name: 'Later today',
                displayTime: moment(this.getDateAt(0, 16, 30)).format('HH:mm'),
                callback: () => {
                  this.snooze(this.getDateAt(0, 16, 30) as string);
                },
                svg: this.laterSvg
              }
            ]
          : []),
        // Last selected
        ...(showLastSelected
          ? [
              {
                name: 'Last selected',
                displayTime: moment(lastSelected).format('ddd HH:mm'),
                callback: () => {
                  this.snooze(lastSelected);
                },
                svg: this.replySvg,
                extraClass: 'last-selected border-top'
              }
            ]
          : []),

        // Default
        ...this.defaultOptions,

        // Date picker
        {
          name: 'Pick a date',
          callback: () => {
            this.showDatePicker();
          },
          svg: this.calendarSvg,
          subsection: true,
          extraClass: showLastSelected ? undefined : 'border-top'
        },

        // If snooze due
        ...(conversation?.getSnoozeDueDate()
          ? [
              {
                name: 'Discard snooze',
                uiDiscard: true,
                callback: () => {
                  this.dismissSnooze();
                }
              }
            ]
          : [])
      ];
    }),
    tap(dropdownOptions => {
      this.visibleDataLength = dropdownOptions.length;
      this.currentItems = dropdownOptions;
    }),
    publishReplay(1),
    refCount()
  );

  protected currentItemClick(): void {
    this.currentItems[this.currentIndex]?.callback();
  }

  showDatePicker(): void {
    this.isDatePickerTabSelected = true;
    this.changeDetectorRef.detectChanges();
  }

  hideDatePicker(): void {
    this.isDatePickerTabSelected = false;
    this.changeDetectorRef.detectChanges();
  }

  someday(): void {
    this.snooze(new Date(9999, 0).toISOString(), true);
  }

  private isTimeTodayBeforeHour(hour: number): boolean {
    return new Date() < this.getDateAt(0, hour, 0, true);
  }

  dateSelected(date: RangeDate): void {
    this.snooze(date.from.toISOString(), true);
  }

  dismissSnooze(): void {
    this.conversation$
      .pipe(
        switchMap(conversation => {
          return this.snoozeApiActionsService.discardSnooze$(conversation);
        }),
        untilDestroyed(this),
        take(1)
      )
      .subscribe(() => {
        this.closeDropdown.next();
      });
  }

  snooze(time: string, fromDatePicker = false): void {
    if (fromDatePicker) {
      this.persistLastSelectedToLocalStorage(time);
    }

    this.conversation$
      .pipe(
        switchMap(conversation => {
          return this.snoozeApiActionsService.setSnooze$(conversation, time).pipe(map(() => conversation));
        }),
        untilDestroyed(this),
        take(1)
      )
      .subscribe(conversation => {
        this.closeDropdown.next();
        this.closeCard(conversation.cardId);
      });
  }

  closeCard(conversationCardId: string): void {
    moveToNextCard(conversationCardId, this.injector);
  }
  private getNextMondayDateAt(hour: number, minutes: number, date = new Date()): string {
    date.setHours(hour);
    date.setMinutes(minutes);

    do {
      date.setDate(date.getDate() + 1);
    } while (date.getDay() !== 1);

    return date.toISOString();
  }

  private getDateAt(
    daysFromNow: number,
    hour: number,
    minutes: number,
    returnDateObject: boolean = false
  ): string | Date {
    const date = new Date();

    date.setDate(date.getDate() + daysFromNow);
    date.setHours(hour);
    date.setMinutes(minutes);

    if (returnDateObject) {
      return date;
    }

    return date.toISOString();
  }

  private persistLastSelectedToLocalStorage(time: string): void {
    this.storageService.setItem(this.storageKey, time);
  }

  private getLastSelectedFromLocalStorage(): string {
    return this.storageService.getItem(this.storageKey);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.conversation$.complete();
  }
}
