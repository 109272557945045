import * as moment from 'moment';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { KeyboardActions } from '../common/keyboard-actions/keyboard-actions.component';

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'date-picker',
  styleUrls: ['./date-picker.scss'],
  templateUrl: './date-picker.html',
})
export class DatePickerComponent extends KeyboardActions implements OnInit, OnDestroy {
  ////////////////
  // View queries
  ////////////////
  @ViewChild('inputTimeRef0', { static: false }) private inputTimeRef0: ElementRef;
  @ViewChild('inputTimeRef1', { static: false }) private inputTimeRef1: ElementRef;

  /////////
  // SVGs
  /////////
  dropdownToggleSvg = require('shared/assets/img/svg/small-dropdown.svg');

  ////////////////////
  // Inputs, Outputs
  ////////////////////
  @Input() upperLimitDate: Date;
  @Input() initialSelectedTime: string;
  @Input() backButtonIncluded: boolean = false;
  @Input() selectFutureDate: boolean = true; // used to select dates in the future
  @Input() submitButtonText: string = 'Select';
  @Input() backButtonText: string = 'Back';
  @Input() showTimeInput: boolean = true;
  @Input() rangeDatePicker: boolean = false;
  @Input() extraClass: string = '';
  @Input() submitOnDatePick: boolean = false;

  @Output() dateSelected: EventEmitter<RangeDate> = new EventEmitter();
  @Output() backClicked: EventEmitter<Date> = new EventEmitter();

  ///////////////////
  // State variables
  ///////////////////
  days = DAYS;
  threeMonths: Date[][];
  selectedYear: number;
  now: Date = new Date();
  selectedDate: RangeDate;
  inputHours: string = '08';
  inputMinutes: string = '00';
  submitError: string;

  private selectedMonth: number = undefined;
  private focusedInput: number;
  private selectedHours: number = 8;
  private selectedMinutes: number = 0;

  protected keyboardActions = {
    ArrowDown: this.onArrowDown.bind(this),
    ArrowUp: this.onArrowUp.bind(this),
    Enter: this.submit.bind(this),
  };

  constructor(private _changeDetection: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.setInitialSelectedDate();
    this.setAllDatesInSelectedMonth();
    this.addKeyboardListener();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  get monthAndYear() {
    const date = this.threeMonths[1][0];

    if (!date) {
      return '';
    }

    return MONTHS[date.getMonth()] + ' ' + date.getFullYear();
  }

  get selectedDateForUi(): string {
    if (this.showTimeInput) {
      return moment(this.selectedDate.from).format('D MMM YYYY');
    }
  }

  inputKeyDown(event: KeyboardEvent, input: number) {
    const pattern = /[0-9]/;
    const char = event?.key;

    if (char === 'Tab') {
      return;
    }

    event.preventDefault();

    if (char === 'ArrowRight') {
      this.inputTimeRef1.nativeElement.focus();
    }
    if (char === 'ArrowLeft') {
      this.inputTimeRef0.nativeElement.focus();
    }
    if (['Backspace', 'Delete'].includes(char)) {
      this.deleteValue(input);
    }
    if (['+'].includes(char)) {
      this.incrementInput(1, input);
    }
    if (['-'].includes(char)) {
      this.incrementInput(-1, input);
    }
    if (pattern.test(char)) {
      this.insertInputValue(parseInt(char, 10), input);
    }
  }

  onInputFocus(input: number) {
    this.focusedInput = input;
  }

  onInputBlur(input: number) {
    this.focusedInput = undefined;
    this.updateInputValue(input);
  }

  ////////////////
  // View methods
  ////////////////
  submit() {
    this.submitError = undefined;

    if (!this.rangeDatePicker) {
      this.selectedDate.from.setHours(this.selectedHours, this.selectedMinutes);
    }

    if (new Date() > this.selectedDate.from && this.selectFutureDate) {
      this.submitError = `Can't pick a date in the past.`;
      this.triggerChangeDetection();
      return;
    }

    if (this.rangeDatePicker) {
      if (!this.selectedDate.to) {
        this.submitError = `Pick two dates.`;
        this.triggerChangeDetection();
        return;
      }
      this.selectedDate.to.setHours(this.selectedHours, this.selectedMinutes);
    }

    this.dateSelected.emit(this.selectedDate);
    this.triggerChangeDetection();
  }

  back() {
    this.backClicked.emit();
  }

  isDateDisabled(date: Date) {
    const now = new Date();

    if (this.isSameDay(date, now)) {
      return false;
    }

    if (this.selectFutureDate) {
      return now > date || (this.upperLimitDate && date > this.upperLimitDate);
    } else {
      return now <= date;
    }
  }

  isInRange(date: Date): boolean {
    return this.rangeDatePicker && this.selectedDate.from < date && this.selectedDate.to > date;
  }

  isSameDay(a: RangeDate | Date, b: Date) {
    return a instanceof RangeDate ? this.isSameDate(a.from, b) || this.isSameDate(a.to, b) : this.isSameDate(a, b);
  }

  selectDate($event: Event, date: Date) {
    $event.stopPropagation();

    if (this.isDateDisabled(date)) {
      return;
    }

    this.setDate(date);
    this.triggerChangeDetection();

    if (this.submitOnDatePick) {
      this.submit();
    }
  }

  incrementCalendarMonth(increase: boolean) {
    this.selectedMonth = this.selectedMonth + (increase ? 1 : -1);
    if (this.selectedMonth < 0) {
      this.selectedMonth = (this.selectedMonth % 12) + 12;
      this.selectedYear = this.selectedYear - 1;
    }

    this.setAllDatesInSelectedMonth();
    this.triggerChangeDetection();
  }

  ///////////////////
  // Private methods
  ///////////////////
  private deleteValue(input: number) {
    let inputValue = input === 0 ? this.inputTimeRef0.nativeElement.value : this.inputTimeRef1.nativeElement.value;
    inputValue = parseInt(inputValue, 10);

    if (inputValue > 9) {
      this.setInputValue(Math.floor(inputValue / 10), input);
      return;
    }

    this.setInputValue(undefined, input);
  }

  private insertInputValue(value: number, input: number) {
    let inputValue = input === 0 ? this.inputTimeRef0.nativeElement.value : this.inputTimeRef1.nativeElement.value;

    if (inputValue.length === 1) {
      this.setInputValue(Math.min(parseInt(inputValue, 10) * 10 + value, input === 0 ? 23 : 59), input);
      return;
    }

    this.setInputValue(value, input);
  }

  private setInputValue(value: number, input: number) {
    if (input === 0) {
      this.selectedHours = value;
      this.inputTimeRef0.nativeElement.value = value === undefined ? '' : value + '';
    }
    if (input === 1) {
      this.selectedMinutes = value;
      this.inputTimeRef1.nativeElement.value = value === undefined ? '' : value + '';
    }

    this.triggerChangeDetection();
  }

  private updateInputValue(input: number) {
    if (input === 0) {
      this.selectedHours = this.selectedHours ?? 0;
      this.inputTimeRef0.nativeElement.value = this.stringifyTimeNumber(this.selectedHours);
      this.triggerChangeDetection();
    }
    if (input === 1) {
      this.selectedMinutes = this.selectedMinutes ?? 0;
      this.inputTimeRef1.nativeElement.value = this.stringifyTimeNumber(this.selectedMinutes);
      this.triggerChangeDetection();
    }
  }

  private incrementInput(increment: number, input: number) {
    if (input === undefined) {
      return;
    }

    if (input === 0) {
      this.selectedHours = (this.selectedHours + 24 + increment) % 24;
      this.inputTimeRef0.nativeElement.value = this.stringifyTimeNumber(this.selectedHours);
      this.triggerChangeDetection();
    }
    if (input === 1) {
      this.selectedMinutes = (this.selectedMinutes + 60 + increment) % 60;
      this.inputTimeRef1.nativeElement.value = this.stringifyTimeNumber(this.selectedMinutes);
      this.triggerChangeDetection();
    }
  }

  private stringifyTimeNumber(num: number): string {
    return (num < 10 ? '0' : '') + num;
  }

  private onArrowUp() {
    this.incrementInput(1, this.focusedInput);
  }

  private onArrowDown() {
    this.incrementInput(-1, this.focusedInput);
  }

  private setAllDatesInSelectedMonth() {
    this.threeMonths = [];

    const current = this.getDaysInMonth(this.selectedMonth, this.selectedYear);
    let previous = this.getDaysInMonth(this.selectedMonth - 1, this.selectedYear);
    let next = this.getDaysInMonth(this.selectedMonth + 1, this.selectedYear);

    const lastDayIndex = previous
      .slice()
      .reverse()
      .findIndex(date => date.getDay() === 1);
    previous = previous.slice(previous.length - 1 - lastDayIndex);

    const firstSunday = next.slice().findIndex(date => date.getDay() === 0);
    next = next.slice(0, firstSunday + 1);

    this.threeMonths.push(previous);
    this.threeMonths.push(current);
    this.threeMonths.push(next);
  }

  private getDaysInMonth(month: number, year: number): Date[] {
    const date = new Date(year, month, 1);
    const days = [];

    while (date.getMonth() === ((month % 12) + 12) % 12) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return days;
  }

  private setInitialSelectedDate() {
    const date = this.initialSelectedTime ? new Date(this.initialSelectedTime) : new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();

    this.selectedDate = new RangeDate(new Date(year, month, day), undefined);
    this.selectedHours = this.initialSelectedTime ? date.getHours() : date.getHours() + (1 % 24);
    this.selectedMinutes = this.initialSelectedTime ? date.getMinutes() : 0;
    this.selectedMonth = date.getMonth();
    this.selectedYear = date.getFullYear();

    this.inputHours = this.stringifyTimeNumber(this.selectedHours);
    this.inputMinutes = this.stringifyTimeNumber(this.selectedMinutes);
  }

  private setDate(date: Date) {
    if (!this.rangeDatePicker) {
      this.selectedDate = new RangeDate(date, undefined);
    } else {
      // Set only from if both dates are already set
      if (this.selectedDate.from && this.selectedDate.to) {
        this.selectedDate.from = date;
        this.selectedDate.to = undefined;
      } else if (this.selectedDate.from) {
        // Sort dates, from should always be lower
        if (this.selectedDate.from > date) {
          this.selectedDate.to = this.selectedDate.from;
          this.selectedDate.from = date;
        } else {
          this.selectedDate.to = date;
        }
      }
    }
  }

  private isSameDate(a: Date, b: Date): boolean {
    if (!a || !b) {
      return false;
    }

    return a.getFullYear() === b.getFullYear() && a.getDate() === b.getDate() && a.getMonth() === b.getMonth();
  }

  private triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }
}

export class RangeDate {
  constructor(
    public from: Date,
    public to?: Date,
  ) {}
}
