import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { tap } from 'rxjs/operators';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'user-settings-general',
  templateUrl: './general.html',
  styleUrls: ['./general.scss'],
  host: { class: 'main' }
})
export class UserSettingsGeneralComponent implements OnInit {
  protected readonly checkSvg: NodeRequire = require('@shared/assets/img/svg/checkmark.svg');
  protected readonly closeSvg: NodeRequire = require('@shared/assets/img/svg/close.svg');

  currentUserId: string = '';
  notificationSettings: INotificationSettings;
  notificationPermission: boolean;
  allowNotifications: boolean;
  requestingPermission: boolean = false;

  constructor(
    private _storageService: StorageService,
    private _userManagerService: UserManagerService,
    private _changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setNotificationPermission();
    this.subscribeToUserSwitch();
  }

  onAllowNotificationToggle(event?: MouseEvent, calledFromPermissionCallback: boolean = false): void {
    event?.preventDefault();

    if (this.allowNotifications || Notification.permission === 'granted') {
      this.allowNotifications = !this.allowNotifications;
      this.notificationSettings.allowNotifications = this.allowNotifications;
      this.requestingPermission = false;
      this.onNotificationChange();
    } else if (Notification.permission === 'denied') {
      this.allowNotifications = false;
      this.notificationSettings.allowNotifications = false;
      this.requestingPermission = false;
      this.onNotificationChange();
    } else if (Notification.permission === 'default') {
      if (!calledFromPermissionCallback) {
        // Prevent recursive calls
        this.requestNotificationPermission();
      }
    }

    this.setNotificationPermission();
    this.triggerChangeDetection();
  }

  onNotificationChange(): void {
    this.setUserNotificationSettingsByEmail(this.currentUserId, this.notificationSettings);
  }

  ///////////////////
  // Private methods
  ///////////////////
  private subscribeToUserSwitch(): void {
    this._userManagerService.userSwitch$
      .pipe(
        tap(() => {
          this.currentUserId = this._userManagerService.getCurrentUserEmail();
          this.init();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private init(): void {
    if (!this.currentUserId) {
      return;
    }

    this.notificationSettings =
      this.getUserNotificationSettingsByEmail(this.currentUserId) || new INotificationSettings();

    this.allowNotifications = this.notificationSettings.allowNotifications;
    this.requestingPermission = false;

    this.triggerChangeDetection();
  }

  private setNotificationPermission(): void {
    this.notificationPermission = Notification.permission !== 'denied';
  }

  private requestNotificationPermission(): void {
    this.requestingPermission = true;
    this.triggerChangeDetection();

    /**
     * Notification.requestPermission() returns a promise
     * Safari still uses the deprecated callback syntax to get the permission
     * https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission
     */
    try {
      Notification.requestPermission().then(() => {
        this.onAllowNotificationToggle(undefined, true);
      });
    } catch (error) {
      Notification.requestPermission(() => {
        this.onAllowNotificationToggle(undefined, true);
      });
    }
  }

  private getUserNotificationSettingsByEmail(userEmail: string): INotificationSettings {
    let key = this._storageService.getKey(userEmail, StorageKey.userNotificationSettings);
    return this._storageService.getParsedItem(key);
  }

  private setUserNotificationSettingsByEmail(userEmail: string, settings: INotificationSettings): void {
    let key = this._storageService.getKey(userEmail, StorageKey.userNotificationSettings);
    return this._storageService.setStringifiedItem(key, settings);
  }

  protected triggerChangeDetection(): void {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }
}

export class INotificationSettings {
  allowNotifications: boolean;
  notifications: {
    chat: boolean;
    email: boolean;
    teamEmail: boolean;
  };
  notificationSound: boolean;
  isNotifyWhenFocusedEnabled: boolean;

  constructor() {
    this.setDefaultValues();
  }

  setDefaultValues(): void {
    this.allowNotifications = false;
    this.notifications = {
      chat: true,
      email: true,
      teamEmail: true
    };
    this.notificationSound = false;
    this.isNotifyWhenFocusedEnabled = false;
  }
}
