/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { ListOfResourcesOfIntegration } from '../models/list-of-resources-of-integration';
import { Response } from '../models/response';
import { Integration } from '../models/integration';
import { OpenAIResponse } from '../models/open-airesponse';
import { OpenAIRequest } from '../models/open-airequest';

@Injectable()
export class IntegrationsApiService extends BaseService {
  /**
   * @param params The `IntegrationsApiService.Integrations_GetIntegrationListParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Integrations_GetIntegrationListResponse(
    params: IntegrationsApiService.Integrations_GetIntegrationListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfIntegration>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/integrations`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Integrations_GetIntegrationList');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfIntegration;

          body = <ListOfResourcesOfIntegration>response.body;

          return <HttpResponse<ListOfResourcesOfIntegration>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `IntegrationsApiService.Integrations_GetIntegrationListParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Integrations_GetIntegrationList(
    params: IntegrationsApiService.Integrations_GetIntegrationListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfIntegration> {
    const observable$ = this.Integrations_GetIntegrationListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/integrations`;
      const url = this.rootUrl + `/api/v1/integrations`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `IntegrationsApiService.Integrations_UpsertIntegrationParams` containing the following parameters:
   *
   * - `integration`: Integration object.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Integrations_UpsertIntegrationResponse(
    params: IntegrationsApiService.Integrations_UpsertIntegrationParams,
    forUserEmail: string
  ): Observable<HttpResponse<Integration>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/integrations`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Integrations_UpsertIntegration');

    // body, headers and query params
    __body = params.integration;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Integration;

          body = <Integration>response.body;

          return <HttpResponse<Integration>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `IntegrationsApiService.Integrations_UpsertIntegrationParams` containing the following parameters:
   *
   * - `integration`: Integration object.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Integrations_UpsertIntegration(
    params: IntegrationsApiService.Integrations_UpsertIntegrationParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Integration> {
    const observable$ = this.Integrations_UpsertIntegrationResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/integrations`;
      const url = this.rootUrl + `/api/v1/integrations`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `IntegrationsApiService.Integrations_DeleteIntegrationParams` containing the following parameters:
   *
   * - `integrationId`: Impersonation header.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Integrations_DeleteIntegrationResponse(
    params: IntegrationsApiService.Integrations_DeleteIntegrationParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/integrations/id`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Integrations_DeleteIntegration');

    // body, headers and query params
    if (params.integrationId != null) __params = __params.set('integrationId', params.integrationId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `IntegrationsApiService.Integrations_DeleteIntegrationParams` containing the following parameters:
   *
   * - `integrationId`: Impersonation header.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Integrations_DeleteIntegration(
    params: IntegrationsApiService.Integrations_DeleteIntegrationParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Integrations_DeleteIntegrationResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/integrations/id`;
      const url = this.rootUrl + `/api/v1/integrations/id`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `IntegrationsApiService.Integrations_GetOpenAIResponseParams` containing the following parameters:
   *
   * - `openAIRequest`: OpenAIRequest
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Integrations_GetOpenAIResponseResponse(
    params: IntegrationsApiService.Integrations_GetOpenAIResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<OpenAIResponse>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/integrations/openai`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Integrations_GetOpenAIResponse');

    // body, headers and query params
    __body = params.openAIRequest;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: OpenAIResponse;

          body = <OpenAIResponse>response.body;

          return <HttpResponse<OpenAIResponse>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `IntegrationsApiService.Integrations_GetOpenAIResponseParams` containing the following parameters:
   *
   * - `openAIRequest`: OpenAIRequest
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Integrations_GetOpenAIResponse(
    params: IntegrationsApiService.Integrations_GetOpenAIResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<OpenAIResponse> {
    const observable$ = this.Integrations_GetOpenAIResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/integrations/openai`;
      const url = this.rootUrl + `/api/v1/integrations/openai`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module IntegrationsApiService {
  /**
   * Parameters for Integrations_GetIntegrationList
   */
  export type Integrations_GetIntegrationListParams = {
    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Integrations_UpsertIntegration
   */
  export type Integrations_UpsertIntegrationParams = {
    /**
     * Integration object.
     */
    integration: Integration;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Integrations_DeleteIntegration
   */
  export type Integrations_DeleteIntegrationParams = {
    /**
     * Impersonation header.
     */
    integrationId?: string;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Integrations_GetOpenAIResponse
   */
  export type Integrations_GetOpenAIResponseParams = {
    /**
     * OpenAIRequest
     */
    openAIRequest: OpenAIRequest;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };
}
