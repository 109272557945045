import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsImagePipe } from './is-image.pipe';

@NgModule({
  declarations: [IsImagePipe],
  imports: [CommonModule],
  exports: [IsImagePipe],
})
export class IsImageModule {}
