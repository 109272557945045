<div
  [attr.draggable]="draggable ? 'true' : undefined"
  (dragstart)="dragStart($event)"
  class="token"
  [class.passive]="passive"
  [class.draggable]="draggable"
  [class.big]="big"
  [class.removable]="removable"
  [class.last]="last"
  [id]="email"
  [tooltip]="{content: email, placement: TooltipPlacement.BOTTOM}"
>
  <div class="info">
    <span class="name">{{ name }}</span>
    <span class="remove" *ngIf="!passive && !unremovable" (click)="remove($event)">
      <svg-comp [src]="chipRemoveSvg"></svg-comp>
    </span>
  </div>
</div>
