import { Component, Input, OnInit } from '@angular/core';
import { FileViewDecorateService } from '@shared/decorators/view-data-decorators/file-view-decorator/file-view-decorate.service';
import { FileModel } from '../../../../shared/models-api-loop/file.model';
import { File as IFile } from '@shared/api/api-loop/models/file';

@Component({
  selector: 'file-icon',
  styles: [
    `
      :host {
        display: inline-block;
      }

      svg-comp {
        width: 100%;
        height: 100%;
      }
    `,
  ],
  template: ` <svg-comp [src]="fileIcon"></svg-comp> `,
})
export class FileIconComponent implements OnInit {
  @Input() file: FileModel | IFile;
  fileIcon: string = '';

  ngOnInit() {
    if (!this.file) {
      this.fileIcon = require(`shared/assets/img/file-icons/icon-file.svg`);
      return;
    }

    const category = FileViewDecorateService.getCategoryFromExtension(this.file);
    this.fileIcon = require(`shared/assets/img/file-icons/icon-${category}.svg`);
  }
}
