import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Directive, Input, OnDestroy } from '@angular/core';
import { TooltipBaseDirective } from '@shared/ui/tooltip/directives/tooltip-base.directive';
import { distinctUntilChanged, filter, fromEvent, merge, race, switchMap, timer } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {
  TooltipContent,
  TooltipInterface,
  TooltipTrigger
} from '@shared/ui/tooltip/browser/browser-tooltip-initializer';

@UntilDestroy()
@Directive({
  selector: '[loopHoverTooltip]'
})
export class TooltipHoverDirective extends TooltipBaseDirective implements OnDestroy {
  protected getTrigger(): keyof typeof TooltipTrigger {
    return TooltipTrigger.hover;
  }

  @Input() set loopHoverTooltip(content: TooltipContent | TooltipInterface | null | undefined) {
    this.setLoopTooltipContent(content);
  }

  protected initializeTriggerSubscription(): void {
    this.triggerSubscription?.unsubscribe();
    const initializedTooltip = this.initializedTooltip;

    if (!initializedTooltip) {
      return;
    }

    this.triggerSubscription = this.ngZone.runOutsideAngular(() => {
      return fromEvent(this.elementRef.nativeElement, 'mouseenter')
        .pipe(
          switchMap(() =>
            race(
              timer(150).pipe(map(() => true)),
              fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(map(() => false))
            )
          ),
          filter(isOpen => isOpen),
          map(() => this.ngZone.run(() => initializedTooltip.show())),
          switchMap(elementRef =>
            merge(
              merge(fromEvent(elementRef.nativeElement, 'mouseenter')).pipe(map(() => true)),
              merge(
                fromEvent(this.elementRef.nativeElement, 'mouseleave'),
                fromEvent(elementRef.nativeElement, 'mouseleave')
              ).pipe(map(() => false))
            ).pipe(debounceTime(200), distinctUntilChanged())
          ),
          filter(isOpen => !isOpen),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.ngZone.run(() => initializedTooltip.hide());
        });
    });
  }
}
