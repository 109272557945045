import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { EventHubObserverService } from './event-hub-observer.service';
import { bufferTime, filter, flatMap, map, mergeMap, tap } from 'rxjs/operators';
import {
  EventBadgeCountChanged,
  EventBase,
  EventConversationUpdated,
  EventType,
  EventUserTyping,
} from '@shared/api/api-loop/models';
import { Injectable, OnDestroy } from '@angular/core';
import { EventUserActiveReply } from '@shared/api/api-loop/models/event-user-active-reply';
import { HubEventType } from '@dta/shared/models/event.models';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import {
  CountersChangedTrigger,
  HubEventUserTypingData,
  UserTrigger,
  UserTypingData,
} from '@shared/services/communication/shared-subjects/shared-subjects-models';

@Injectable()
export class EventHubService extends EventHubObserverService implements IEventHub, OnDestroy {
  private baseHubEventTypes: EventType[] = [
    EventType.USER_ONLINE_STATUS_CHANGE,
    EventType.USER_TYPING,
    EventType.USER_ACTIVE_REPLY,
    EventType.BADGE_COUNT_UPDATED,
    EventType.CONVERSATION_UPDATED,
  ];

  //////////////////
  // Subscriptions
  //////////////////
  private localUserTypingSub: Subscription;
  private eventTypingSub: Subscription;
  private eventBadgeCountUpdateSub: Subscription;
  private eventConversationUpdatedSub: Subscription;
  private eventActiveReplySub: Subscription;
  private localActiveReplyEventsSub: Subscription;
  private connectionSubscriber: Subscription;

  constructor(
    protected _userEmail: string,
    protected _sharedUserManagerService: SharedUserManagerService,
  ) {
    super(_userEmail, _sharedUserManagerService);

    this.init();

    this.subscribeToLocalUserTypingEvents();
    this.subscribeToEventTyping();
    this.subscribeToEventBadgeCountChanged();
    this.subscribeToEventActiveReply();
    this.subscribeToLocalActiveReplyEvents();
    this.subscribeToEventConversationUpdated();
  }

  ngOnDestroy() {}

  ///////////////
  // HUB METHODS
  ///////////////
  start() {
    if (!this._sharedUserManagerService.isUserAccountVerified(this._userEmail)) {
      return;
    }

    this.connectionSubscriber?.unsubscribe();
    this.connectionSubscriber = this.startConnection()
      .pipe(mergeMap(() => this.eventListTypeFilter(this.baseHubEventTypes)))
      .subscribe();
  }

  stop() {
    this.connectionSubscriber?.unsubscribe();
    this.connectionSubscriber = this.stopConnection().subscribe();
  }

  //////////////////
  // CLIENT METHODS
  //////////////////
  event(eventTypes?: HubEventType[]): Observable<EventBase> {
    if (_.isEmpty(eventTypes)) {
      return this.on(EventHubMethodName.Event);
    }

    return this.on(EventHubMethodName.Event).pipe(
      filter((event: EventBase) => {
        return eventTypes.includes(<HubEventType>event.$type);
      }),
      map((event: EventBase) => {
        return event;
      }),
    );
  }

  eventListHtmlFormat(mimeType: MimeType): Observable<MimeType> {
    return this.invoke(EventHubMethodName.EventListHtmlFormat, mimeType);
  }

  eventListTypeFilter(eventTypeList: EventType[]): Observable<EventType[]> {
    return this.invoke(EventHubMethodName.EventListTypeFilter, eventTypeList);
  }

  ///////////
  // HELPERS
  ///////////
  private subscribeToEventBadgeCountChanged() {
    this.eventBadgeCountUpdateSub?.unsubscribe();
    this.eventBadgeCountUpdateSub = this.connectionEstablished$
      .pipe(
        mergeMap(() => this.event([HubEventType.EventBadgeCountChanged])),
        /**
         * Buffer updates in N time span
         */
        bufferTime(700),
        filter((events: EventBadgeCountChanged[]) => {
          return !_.isEmpty(events);
        }),
        tap((events: EventBadgeCountChanged[]) => {
          let broadcastData = new CountersChangedTrigger();
          broadcastData.forUserEmail = this._userEmail;
          broadcastData.events = events;
          SharedSubjects._countersChangedTrigger$.next(broadcastData, true);
        }),
      )
      .subscribe();
  }

  private subscribeToEventConversationUpdated() {
    this.eventConversationUpdatedSub?.unsubscribe();
    this.eventConversationUpdatedSub = this.connectionEstablished$
      .pipe(
        mergeMap(() => this.event([HubEventType.EventConversationUpdated])),
        /**
         * Buffer updates in N time span
         */
        bufferTime(700),
        filter((events: EventConversationUpdated[]) => {
          return !_.isEmpty(events);
        }),
        tap((events: EventConversationUpdated[]) => {
          let broadcastData = new UserTrigger();
          broadcastData.forUserEmail = this._userEmail;
          SharedSubjects._conversationUpdated$.next(broadcastData, true);
        }),
      )
      .subscribe();
  }

  private subscribeToEventTyping() {
    this.eventTypingSub?.unsubscribe();
    this.eventTypingSub = this.connectionEstablished$
      .pipe(
        mergeMap(() => this.event([HubEventType.EventUserTyping])),
        tap((event: EventUserTyping) => {
          let data = new HubEventUserTypingData();
          data.forUserEmail = this._userEmail;
          data.event = event;

          SharedSubjects._hubEventTyping$.next(data);
        }),
      )
      .subscribe();
  }

  private subscribeToLocalUserTypingEvents() {
    this.localUserTypingSub?.unsubscribe();
    this.localUserTypingSub = SharedSubjects._localUserTyping$
      .forUserEmail(this._userEmail)
      .pipe(
        flatMap((data: UserTypingData) => {
          return this.eventUserTyping(data.cardId);
        }),
      )
      .subscribe();
  }

  private eventUserTyping(cardId: string): Observable<EventUserTyping> {
    let params: EventUserTyping = {
      $type: HubEventType.EventUserTyping,
      cardId: cardId,
    };

    return this.invoke(EventHubMethodName.EventUserTyping, params);
  }

  private subscribeToEventActiveReply() {
    this.eventActiveReplySub?.unsubscribe();
    this.eventActiveReplySub = this.connectionEstablished$
      .pipe(
        mergeMap(() => this.event([HubEventType.EventUserActiveReply])),
        tap((event: EventUserActiveReply) => {
          let data = new HubEventUserTypingData();
          data.forUserEmail = this._userEmail;
          data.event = event;

          SharedSubjects._hubEventActiveReply$.next(data);
        }),
      )
      .subscribe();
  }

  private subscribeToLocalActiveReplyEvents() {
    this.localActiveReplyEventsSub?.unsubscribe();
    this.localActiveReplyEventsSub = SharedSubjects._localActiveReply$
      .forUserEmail(this._userEmail)
      .pipe(
        flatMap((data: UserTypingData) => {
          return this.eventActiveEditor(data.cardId);
        }),
      )
      .subscribe();
  }

  private eventActiveEditor(cardId: string): Observable<EventUserActiveReply> {
    let params: EventUserActiveReply = {
      $type: HubEventType.EventUserActiveReply,
      cardId: cardId,
    };

    return this.invoke(EventHubMethodName.EventUserActiveReply, params);
  }
}

export interface IEventHub {
  eventListTypeFilter(eventTypeList: EventType[]): Observable<EventType[]>;
  eventListHtmlFormat(mimeType: MimeType): Observable<MimeType>;

  event(eventTypes?: HubEventType[]): Observable<EventBase>;
}

enum EventHubMethodName {
  EventActiveUsers = 'EventActiveUsers',
  EventUserTyping = 'EventUserTyping',
  EventUserActiveReply = 'EventUserActiveReply',
  EventListTypeFilter = 'EventListTypeFilter',
  EventListHtmlFormat = 'EventListHtmlFormat',
  Event = 'Event',
}
