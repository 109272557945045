import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { CardBase, CommentBase, ListOfTags, Tag, TagType } from '@shared/api/api-loop/models';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import {
  CollectionNameWeb,
  CommentClientIdIndex,
  ParentCardClientIdIndex,
  ParentCardIdIndex,
  QuoteCommentIdIndex,
} from '../../database-schema';
import {
  CommentBaseModel,
  CommentDraftModel,
  CommentModel,
  CommentTemplateModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import { CommentDaoServiceI } from '@shared/database/dao/comment/comment-dao.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { ListOfTagsModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { DatabaseServiceWeb } from '../../database.service.web';
import { CardModel, CardSharedModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CommentPopulateService } from '@shared/populators/comment-populate/comment-populate.service';
import { TemplateDaoServiceWeb } from '../template/template-dao.service.web';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ChannelChatsCollectionParams } from '@dta/ui/collections/comments/channel-chats.collection';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';

@Injectable()
export class CommentDaoServiceWeb extends BaseDaoServiceWeb<CommentModel, CommentBase> implements CommentDaoServiceI {
  constructor(
    protected _databaseFactory: DatabaseFactory,
    private _templateDaoService: TemplateDaoServiceWeb,
    private _commentPopulateService: CommentPopulateService,
  ) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'CommentDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.Comment;
  }

  protected toModel(doc: Tag): CommentModel {
    return CommentBaseModel.create(doc);
  }

  protected populate(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return this._commentPopulateService.populate(forUserEmail, comments);
  }

  saveAll(forUserEmail: string, models: CommentModel[]): Observable<CommentModel[]> {
    // Split cards
    let templates = [];
    let otherComments = [];
    models.forEach((model: CommentModel) =>
      model.$type === CommentTemplateModel.type ? templates.push(model) : otherComments.push(model),
    );

    // Save templates in separate store
    return forkJoin([
      super.saveAll(forUserEmail, otherComments),
      this._templateDaoService.saveAll(forUserEmail, templates as CommentTemplateModel[]),
    ]).pipe(map((response: [CommentModel[], CommentTemplateModel[]]) => response.flat()));
  }

  protected doBeforeSave(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return from(comments).pipe(
      map((comment: CommentModel) => {
        if (!comment.hasTags()) {
          comment.tags = ListOfTagsModel.buildFromParentAndTags(comment);
        }
        return comment;
      }),
      toArray(),
    );
  }

  findCommentsByCard(forUserEmail: string, card: CardModel): Observable<CommentModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(ParentCardIdIndex.indexName, [card.id], this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  findCommentsByCards(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
  ): Observable<CommentModel[]> {
    let cardIds = _.map(cards, c => c.id || c._id);

    if (includeSnapshotComments) {
      _.map(cards, (card: CardModel) => {
        if (card instanceof CardSharedModel) {
          cardIds.push(card.snapshotResource?.id || card.sourceResource?.id);
        }
      });
    }

    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(ParentCardIdIndex.indexName, cardIds, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  findAllTemplates(forUserEmail: string): Observable<CommentTemplateModel[]> {
    return this._templateDaoService.findAllTemplates(forUserEmail).pipe(
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    ) as Observable<CommentTemplateModel[]>;
  }

  countAllTemplates(forUserEmail: string): Observable<Number> {
    return this._templateDaoService.countAllTemplates(forUserEmail);
  }

  findCommentsByQuoteIds(forUserEmail: string, commentIds: string[]): Observable<CommentModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(QuoteCommentIdIndex.indexName, commentIds, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  findChatsByChatCard(forUserEmail: string, params: ChannelChatsCollectionParams): Observable<CommentModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(ParentCardIdIndex.indexName, [params.chatCardId], this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
      /**
       * Do in memory paging
       */
      map(docs => _.slice(_.orderBy(docs, 'created', 'desc'), params.offset, params.offset + params.size)),
    );
  }

  findLastCommentsByCardsForFoldering(forUserEmail: string, cards: CardModel[]): Observable<CommentBaseModel[]> {
    let cardIds = _.map(cards, c => c.id);
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => db.findByIndex(ParentCardIdIndex.indexName, cardIds, this.collectionName)),
      /**
       * Pick latest comment from each card
       */
      map(docs => {
        let commentsByParentId = _.groupBy(docs, (doc: CommentBase) => doc.parent.id);
        let lastComments = [];

        // Get last comments for each parent
        _.forEach(Object.values(commentsByParentId), (comments: CommentBase[]) =>
          lastComments.push(_.orderBy(comments, 'created', 'desc')[0]),
        );

        return lastComments;
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  findCommentsByIdsOrClientIds(forUserEmail: string, idOrClientIds: string[]): Observable<CommentModel[]> {
    if (_.isEmpty(idOrClientIds)) {
      return of([]);
    }

    let { clientIds, ids } = _.groupBy(idOrClientIds, id => (id.startsWith(BaseModel.idPrefix) ? 'clientIds' : 'ids'));

    return forkJoin([
      this.findByIds(forUserEmail, ids || []),
      this.db(forUserEmail).pipe(
        mergeMap((db: DatabaseServiceWeb) =>
          db.findByIndex(CommentClientIdIndex.indexName, clientIds || [], this.collectionName),
        ),
      ),
    ]).pipe(
      map((comments: CommentBase[][]) => comments.flat()),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  findCommentsByParentIdsOrClientIds(forUserEmail: string, idOrClientIds: string[]): Observable<CommentModel[]> {
    if (_.isEmpty(idOrClientIds)) {
      return of([]);
    }

    let { clientIds, ids } = _.groupBy(idOrClientIds, id => (id.startsWith(BaseModel.idPrefix) ? 'clientIds' : 'ids'));

    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) =>
        forkJoin([
          db.findByIndex(ParentCardIdIndex.indexName, ids || [], this.collectionName),
          db.findByIndex(ParentCardClientIdIndex.indexName, clientIds || [], this.collectionName),
        ]),
      ),
      map((comments: CommentBase[][]) => comments.flat()),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  findUnreadCommentsByCards(forUserEmail: string, cards: CardModel[]): Observable<CommentModel[]> {
    let cardIds = _.map(cards, c => c.id);
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => db.findByIndex(ParentCardIdIndex.indexName, cardIds, this.collectionName)),
      map((docs: CommentBase[]) => this.toModels(docs)),
      map((docs: CommentBaseModel[]) =>
        _.filter(
          docs,
          (comment: CommentBaseModel) => comment.hasTagId(TagType.UNREAD) || comment.hasTagId(TagType.UNREAD_VIRTUAL),
        ),
      ),
      mergeMap((docs: CommentModel[]) => this.populate(forUserEmail, docs)),
    );
  }

  removeAllTemplates(forUserEmail: string): Observable<any> {
    return this._templateDaoService.removeCollection(forUserEmail);
  }

  ////////////
  getLastCommentByCardForFoldering(forUserEmail: string, card: CardModel): Observable<CommentModel> {
    return of(undefined);
  }

  ////////////
  ////////////
  ////////////
  // TODO
  removeUndoData(forUserEmail: string, comment: CommentModel): Observable<any> {
    throw new Error('Method not implemented.');
  }
  findCommentsBaseByCard(forUserEmail: string, card: CardModel): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  findCommentsByTag(forUserEmail: string, tagId: string): Observable<CommentBaseModel[]> {
    throw new Error('Method not implemented.');
  }
  findCommentsForOutbox(forUserEmail: string): Observable<CommentBaseModel[]> {
    throw new Error('Method not implemented.');
  }
  findCommentsForCards(forUserEmail: string, cards: CardBase[]): Observable<CommentBaseModel[]> {
    throw new Error('Method not implemented.');
  }
  updateCommentById(forUserEmail: string, id: string, update: Object): Observable<any> {
    throw new Error('Method not implemented.');
  }
  updateCommentTagsById(forUserEmail: string, id: string, tags: ListOfTags) {
    throw new Error('Method not implemented.');
  }
  deleteCommentById(forUserEmail: string, id: string) {
    throw new Error('Method not implemented.');
  }
  findCommentsByCardsAndTags(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
    tag: TagModel,
    includeTag: boolean,
  ): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  findGroupedCommentIdsByCards(
    forUserEmail: string,
    cards: CardModel[],
    includeSnapshotComments: boolean,
  ): Observable<_.Dictionary<CommentBase[]>> {
    throw new Error('Method not implemented.');
  }
  findComments(forUserEmail: string, comments: CommentBase[]): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  findTagsByCard(forUserEmail: string, card: CardModel): Observable<ListOfTags[]> {
    throw new Error('Method not implemented.');
  }
  findTagsByComment(forUserEmail: string, comment: CommentModel): Observable<ListOfTagsModel> {
    throw new Error('Method not implemented.');
  }
  findSharedDraftBySourceCardOrThrow(forUserEmail: string, card_id: string): Observable<CommentDraftModel> {
    throw new Error('Method not implemented.');
  }
  addTagByComments(forUserEmail: string, comments: CommentModel[], tag: TagModel): Observable<any> {
    throw new Error('Method not implemented.');
  }
  removeTagsByComments(forUserEmail: string, comments: CommentModel[], tags: TagModel[]): Observable<any> {
    throw new Error('Method not implemented.');
  }
  updateTagsForComments(
    forUserEmail: string,
    comments: CommentModel[],
    addTag: TagModel,
    removeTags: TagModel[],
  ): Observable<any> {
    throw new Error('Method not implemented.');
  }

  findChatCommentsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string,
  ): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  countChatsByChatCard(forUserEmail: string, params: any): Observable<Number> {
    throw new Error('Method not implemented.');
  }
  countOutboxComments(forUserEmail: string): Observable<Number> {
    throw new Error('Method not implemented.');
  }
  findOutboxComments(forUserEmail: string): Observable<CommentBaseModel[]> {
    throw new Error('Method not implemented.');
  }
  findCommentsBySearchQuery(
    forUserEmail: string,
    query: string,
    filter: any,
    offset: number,
    limit: number,
  ): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  contactHasUnreadChat(forUserEmail: string, contact: ContactModel): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  contactHasUnreadMail(forUserEmail: string, contact: ContactModel): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  findCommentsWithUnsyncedAttachments(
    forUserEmail: string,
    size: number,
    commentBlacklist: CommentModel[],
    failedAttachmentsOnly: boolean,
    previewsOnly: boolean,
    parentCard?: CardModel,
    lastComment?: CommentModel,
  ): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  findCommentsWithTagIdByCards(forUserEmail: string, cards: CardBase[], tagId: string): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  updateCommentsParentByOldCardId(forUserEmail: string, oldCardId: string, comment: CommentModel): Observable<any> {
    throw new Error('Method not implemented.');
  }
  updateCommentWithCorrectTags(forUserEmail: string, comment: CommentModel): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
