import { inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';

export abstract class FileDownloadService {
  protected readonly domDocument: Document = inject(DOCUMENT);

  abstract downloadFromArrayBuffer(
    arrayBuffer: ArrayBuffer,
    options?: { fileName?: string; mimeType?: string }
  ): Observable<void>;

  downloadFromLink(downloadLink: string, options?: { fileName?: string; mimeType?: string }): Observable<void> {
    const a = this.domDocument.createElement('a');
    a.href = downloadLink;
    a.target = '_blank';
    a.download = options?.fileName ?? 'download';
    this.domDocument.body.appendChild(a);
    a.tabIndex = -1;
    a.focus();
    a.click();
    this.domDocument.body.removeChild(a);
    return of(undefined);
  }
}
