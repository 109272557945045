import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { FileViewDecorateService } from '@shared/decorators/view-data-decorators/file-view-decorator/file-view-decorate.service';
import { AppStateService } from '@shared/services/data/app-state/app-state.service';
import { CardService } from '@shared/services/data/card/card.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { FileService } from '@shared/services/data/file/file.service';
import { SearchService } from '@shared/services/search/search.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { RecentSearchFilter, SearchResultType } from 'dta/shared/models/search.model';
import { map, mergeMap, Observable, of } from 'rxjs';

@Injectable()
export class SearchServiceWeb extends SearchService {
  constructor(
    protected _router: Router,
    protected _userManager: UserManagerService,
    protected _appStateService: AppStateService,
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _fileService: FileService,
    private _fileViewDecorateService: FileViewDecorateService,
  ) {
    super(_router, _userManager, _appStateService, _commentService, _cardService, _fileService);
  }

  protected searchCards(
    query: string,
    searchFilter?: RecentSearchFilter,
    offset?: number,
    limit?: number,
  ): Observable<SearchResultType> {
    return of({ type: 'offline', data: { Card: [] } } as SearchResultType);
  }

  protected searchFiles(
    query: string,
    searchFilter?: RecentSearchFilter,
    offset?: number,
    size?: number,
  ): Observable<SearchResultType> {
    return of({ type: 'offline', data: { File: [] } } as SearchResultType);
  }

  protected searchApiFiles(
    query: string,
    searchFilter: RecentSearchFilter,
    offset?: number,
    size?: number,
  ): Observable<SearchResultType> {
    return super.searchApiFiles(query, searchFilter, offset, size).pipe(
      mergeMap((result: SearchResultType) => {
        return this._fileViewDecorateService
          .decorateListViewData(this._userManager.getCurrentUserEmail(), result.data.File)
          .pipe(
            map((decoratedFiles: FileModel[]) => {
              result.data.File = decoratedFiles;
              return result;
            }),
          );
      }),
    );
  }
}
