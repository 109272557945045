import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { map, mergeMap, Observable, of } from 'rxjs';
import { ConversationChangeModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-change.model';
import { ConversationChangeDaoService } from '@shared/database/dao/conversation/conversation-change-dao.service';
import * as _ from 'lodash';
import { ConversationChangeServiceI } from '@shared/services/data/conversation-change/conversation-change.service.interface';

@Injectable()
export class ConversationChangeService extends BaseService implements ConversationChangeServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _conversationChangeDaoService: ConversationChangeDaoService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'ConversationChangeService';
  }

  saveAll(forUserEmail: string, changes: ConversationChangeModel[]): Observable<ConversationChangeModel[]> {
    if (_.isEmpty(changes)) {
      return of([]);
    }

    return this._conversationChangeDaoService.saveAll(forUserEmail, changes) as Observable<ConversationChangeModel[]>;
  }

  findAllValidConversationsChanges(forUserEmail: string): Observable<ConversationChangeModel[]> {
    return this._conversationChangeDaoService.findAllConversationsChanges(forUserEmail).pipe(
      mergeMap((changes: ConversationChangeModel[]) => {
        let validChanges = [];
        let invalidChangesId = [];
        let now = new Date();

        _.forEach(changes, change => {
          change.isValid(now) ? validChanges.push(change) : invalidChangesId.push(change._id);
        });

        return this._conversationChangeDaoService.removeByIds(forUserEmail, invalidChangesId).pipe(
          map(() =>
            validChanges.sort((change1: ConversationChangeModel, change2: ConversationChangeModel) => {
              return change1 < change2 ? -1 : 1;
            }),
          ),
        );
      }),
    );
  }

  // Dao Wrapper
  removeByIds(forUserEmail: string, ids: string[]) {
    return this._conversationChangeDaoService.removeByIds(forUserEmail, ids);
  }

  removeCollection(forUserEmail: string): Observable<any> {
    return this._conversationChangeDaoService.removeCollection(forUserEmail);
  }
}
