import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsComponent } from '@dta/ui/components/common/chips/chips.component';
import { ChipComponent } from '@dta/ui/components/common/chips/chip.component';
import { CustomInputModule } from '@dta/ui/components/common/custom-input/custom-input.module';
import { AutosuggestModule } from '@dta/ui/components/common/autosuggest/autosuggest.module';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';

@NgModule({
  declarations: [ChipsComponent, ChipComponent],
  imports: [CommonModule, CustomInputModule, AutosuggestModule, TooltipModule, GraphicsModule],
  exports: [ChipsComponent, ChipComponent],
})
export class ChipsModule {}
