<ng-container *ngFor="let contact of contacts$ | async; let index = index">
  <div
    class="auto-suggest-item"
    [class.bg-light-ui-element]="index === currentIndex"
    (click)="handleSelectContact(contact)"
    [class.selected]="selectedContacts | containsByProperty: contact.id : 'id'"
  >
    <loop-contact [contact]="contact"></loop-contact>
    <div class="flex gap-x-half items-center">
      <svg-comp *ngIf="selectedContacts | containsByProperty: contact.id : 'id'" [src]="checkMarkSvg"></svg-comp>
    </div>
  </div>
</ng-container>
