import * as Autolinker from 'loop-autolinker';
import { Injectable } from '@angular/core';
import {
  CommentChatModel,
  CommentDraftModel,
  CommentMailModel,
  CommentModel,
  CommentTemplateModel,
  MimeType
} from 'dta/shared/models-api-loop/comment/comment.model';
import { from, Observable, of } from 'rxjs';
import { mergeMap, toArray } from 'rxjs/operators';
import { BBCodeService } from '@dta/shared/services/bbcode/bbcode.service';
// @ts-ignore
import DOMPurify from 'dompurify';
import { ADDITIONAL_ATTR, DOM_PURIFY_CONFIG } from '@shared/modules/comments/common/constants/dom-purify-config';

@Injectable()
export class CommentBodyHelperService {
  private autolinker = new Autolinker({
    className: 'parsed',
    stripPrefix: false
  });

  constructor(private _bbCodeService: BBCodeService) {}

  get constructorName(): string {
    return 'CommentBodyHelperService';
  }

  processCommentsBody(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return from(comments).pipe(
      mergeMap((comment: CommentModel) => {
        if (!comment.hasBody()) {
          return of(comment);
        }

        // DRAFTS
        if (comment instanceof CommentDraftModel) {
          return of(comment);
        }

        // TEMPLATE
        if (comment instanceof CommentTemplateModel) {
          return this.processCommentTemplateBody(comment);
        }

        // SNIPPET
        this.processCommentSnippet(comment);

        // MAIL
        if (comment instanceof CommentMailModel) {
          return this.processCommentMailBody(forUserEmail, comment);
        }

        // CHAT
        return this.processCommentChatBody(comment);
      }),
      toArray()
    );
  }

  private processCommentTemplateBody(comment: CommentTemplateModel): Observable<CommentTemplateModel> {
    if (!comment || !comment.body) {
      return of(comment);
    }

    comment.body.content = DOMPurify.sanitize(comment.body.content || '', DOM_PURIFY_CONFIG);
    return of(comment);
  }

  private processCommentMailBody(forUserEmail: string, comment: CommentMailModel): Observable<CommentModel> {
    let body = comment.body.content;

    // Don't parse comment body on each save as that will escape HTML tags that were parsed out the first time
    // and cause weird email rendering
    if (comment.body.mimeType === MimeType.bbtag && !comment?._ex?.isBodyParsed) {
      body = this._bbCodeService.process(
        {
          text: body,
          removeMisalignedTags: false,
          addInLineBreaks: false
        },
        false
      );
      comment._ex = {
        ...comment._ex,
        isBodyParsed: true
      };
      comment.body.mimeType = MimeType.html;
    }

    // Don't replace image cids on local comments except if there was error sending. In that case
    // the comment will be in outbox and we want to see inline images.
    if (!comment.id && comment._ex && !comment._ex.errorSending) {
      comment.body.content = DOMPurify.sanitize(body, DOM_PURIFY_CONFIG);
      return of(comment);
    }

    // Link URLs in body
    body = this.autolinker.link(body);
    comment.body.content = body;
    return of(comment);
  }

  private processCommentChatBody(comment: CommentChatModel): Observable<CommentModel> {
    // let body = comment.comment;
    //
    // if (comment.id && comment.revision && !comment._ex?.bodyProcessed) {
    //   // Escape </> (with custom escape charters. bbParser will replace all
    //   // default escape charters)
    //   body = body.replace(/>/g, 'loop_gt;').replace(/</g, 'loop_lt;');
    //
    //   body = this._bbCodeService.process({
    //     text: body,
    //     removeMisalignedTags: false,
    //     addInLineBreaks: false,
    //   });
    //
    //   body = this._xssService.process(body);
    //
    //   // Unescape </>
    //   body = body.replace(/loop_gt;/g, '&gt;').replace(/loop_lt;/g, '&lt;');
    //
    //   // Replace deeplink on webapp
    //   if (isWebApp()) {
    //     body = body.replace(
    //       new RegExp(CONSTANTS.DEEPLINK_PREFIX, 'g'),
    //       `https://${window.location.host}/${deeplinkRoute}/`,
    //     );
    //   }
    //
    //   // Keep order of operations!!
    //   // Autolinker will not work correctly with custom escape characters, so we need to unescape before linking
    //   body = this.autolinker.link(body);
    //
    //   // Make sure body gets processed only once
    //   comment._ex?.bodyProcessed = true;
    // }
    //
    // comment.comment = body;
    return of(comment);
  }

  private processCommentSnippet(comment: CommentModel): CommentModel {
    comment.snippet = this._bbCodeService.process({
      text: comment.snippet,
      removeMisalignedTags: false,
      addInLineBreaks: false,
      removeTags: true
    });

    return comment;
  }
}
