import { EnumObjectValue } from '@shared/utils/common/types';

export const MailCommentAttachmentPosition = {
  top: 'top',
  bottom: 'bottom'
} as const;

export const ConversationSettingsScopeProperty = {
  is_first_unread_viewport: 'is_first_unread_viewport',
  mail_comment_attachment_position: 'mail_comment_attachment_position',
  are_attachments_previews_shown: 'are_attachments_previews_shown',
  should_open_attachments_in_native_apps: 'should_open_attachments_in_native_apps',
  is_email_history_expanded_on_replies: 'is_email_history_expanded_on_replies'
} as const;

export interface ConversationSettingsScope {
  [ConversationSettingsScopeProperty.is_first_unread_viewport]?: boolean;
  [ConversationSettingsScopeProperty.mail_comment_attachment_position]?: EnumObjectValue<
    typeof MailCommentAttachmentPosition
  >;
  [ConversationSettingsScopeProperty.are_attachments_previews_shown]?: boolean;
  [ConversationSettingsScopeProperty.should_open_attachments_in_native_apps]?: boolean;
  [ConversationSettingsScopeProperty.is_email_history_expanded_on_replies]?: boolean;
}
