import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { CustomDropdownBase } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown-base';
import { TextControlComponent } from '@shared/modules/form-controls/text-control/text-control.component';
import { ConversationHeaderActionService } from '@shared/modules/conversations/components/conversation-header/conversation-header-action.service';
import { Observable, ReplaySubject, startWith } from 'rxjs';
import { ContactBaseModel, ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { debounceTime, map, publishReplay, refCount, switchMap, take } from 'rxjs/operators';
import { TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { SharedTagAssigneeModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'loop-assign-dropdown',
  templateUrl: './assign-dropdown.component.html',
  styleUrls: ['./assign-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignDropdownComponent extends CustomDropdownBase<ContactModel> implements OnInit, AfterViewInit {
  private readonly contactApiService: ContactApiService = inject(ContactApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly formBuilder: UntypedFormBuilder = inject(UntypedFormBuilder);
  private readonly conversationHeaderActionService: ConversationHeaderActionService = inject(
    ConversationHeaderActionService
  );
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  protected readonly TooltipPlacement: typeof TooltipPlacement = TooltipPlacement;
  protected readonly isDarwin: boolean = checkIfOS(OperatingSystem.DARWIN);
  protected form: UntypedFormGroup = this.formBuilder.group({
    query: []
  });

  @ViewChild(TextControlComponent) textControlElement?: TextControlComponent;

  protected readonly conversation$: ReplaySubject<ConversationModel> = new ReplaySubject<ConversationModel>();
  @Input() set conversation(conversation: ConversationModel) {
    this.conversation$.next(conversation);
  }

  @Input() shouldReturnOnlySelectedUser: boolean = false;
  @Output() selectedContact: EventEmitter<ContactModel> = new EventEmitter<ContactModel>();

  protected isLoading: boolean = false;
  protected visibleDataLength: number = 0;

  protected readonly currentAssignId$: Observable<string> = this.conversation$.pipe(
    map(conversation => {
      return (
        conversation?.sharedTags?.tags?.resources?.find(tag => {
          return tag.$type === SharedTagAssigneeModel.type;
        }) as SharedTagAssigneeModel
      )?.userId;
    })
  );

  protected readonly contacts$: Observable<ContactModel[]> = this.currentAssignId$.pipe(
    switchMap(currentAssigneeId => {
      return this.form.controls['query'].valueChanges.pipe(
        debounceTime(200),
        startWith(''),
        switchMap(query => {
          this.isLoading = true;
          this.changeDetectorRef.detectChanges();

          return this.contactApiService.Contact_GetWorkspaceContacts(
            {
              explicitContactId:
                this.userManagerService.getCurrentUserId() === currentAssigneeId ? undefined : currentAssigneeId,
              searchQuery: query,
              size: 5
            },
            this.userManagerService.getCurrentUserEmail()
          );
        })
      );
    }),
    map(response => {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
      const contacts = ContactBaseModel.createList(response.resources);
      contacts.unshift(this.userManagerService.getCurrentUser());
      return contacts;
    }),
    publishReplay(1),
    refCount()
  );

  ngOnInit(): void {
    this.contacts$.pipe(untilDestroyed(this)).subscribe(contacts => {
      this.currentItems = contacts;
    });
  }

  ngAfterViewInit(): void {
    this.textControlElement?.focus();
  }

  protected currentItemClick(): void {
    this.conversation$.pipe(take(1)).subscribe(conversation => {
      this.handleContactClick(this.currentItems[this.currentIndex], conversation);
    });
  }

  handleContactClick(contact: ContactModel, conversation: ConversationModel): void {
    if (this.shouldReturnOnlySelectedUser) {
      this.selectedContact.emit(contact);
      return;
    }
    this.conversationHeaderActionService.assignContact(contact, conversation);
    this.closeDropdown.next();
  }

  sendStatusCommand(command: string, conversation: ConversationModel): void {
    this.conversationHeaderActionService.sendStatusCommand(command, conversation);
    this.closeDropdown.next();
  }
}
