import { Injectable } from '@angular/core';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';
import { TranslationsDataAccessModule } from '@shared/modules/translations/data-access/translations-data-access/translations-data-access.module';
import { SmartCommandsApiService } from '@shared/modules/smart-commands/data-access/smart-commands-data-access/smart-commands-api.service';
import { SmartCommandModuleName } from '@shared/modules/smart-commands/common/constants/smart-command-module-name';
import { EnumObjectValue } from '@shared/utils/common/types';
import { TranslationMethod } from '@shared/modules/smart-commands/common/translations/constants/translation-method';
import { TranslateRequest } from '@shared/modules/smart-commands/common/translations/interfaces/translate-request';
import {
  GetLanguagesResponse,
  TranslateResponse
} from '@shared/modules/smart-commands/common/translations/interfaces/responses';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: TranslationsDataAccessModule
})
export class TranslationsSmartCommandsApiService extends SmartCommandsApiService {
  protected moduleName: EnumObjectValue<typeof SmartCommandModuleName> = SmartCommandModuleName.translation;

  getAvailableLanguages$(): Observable<GetLanguagesResponse> {
    const observable$ = this.sendSmartCommandRequest({
      method: TranslationMethod.get_languages,
      payload: undefined
    });

    return this.getOrStoreObservable(
      createKeyFromRequest({ endpoint: this.moduleName, url: TranslationMethod.get_languages }),
      observable$
    );
  }

  translateText$(translateRequest: TranslateRequest): Observable<TranslateResponse> {
    return this.sendSmartCommandRequest({
      method: TranslationMethod.translate,
      payload: translateRequest
    });
  }
}
