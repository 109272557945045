import * as _ from 'lodash';
import { v1 } from 'uuid';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { merge, Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { PublishEventType } from '../communication/shared-subjects/shared-subjects-models';
import { Injectable } from '@angular/core';
import { AvailabilityStatusCollectionService } from '@dta/ui/collections/availability-statuses/availability-status.collection';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import {
  AvailabilityStatusSubscriberEvent,
  StatusSubscriberServiceI,
} from '@shared/services/availability-status-subscriber/availability-status-subscriber.interface';
import { ObserverResponse } from '@dta/ui/collections/collection-subscriber.service';

@Injectable()
export class AvailabilityStatusSubscriberService implements StatusSubscriberServiceI {
  constructor(private _availabilityStatusCollectionService: AvailabilityStatusCollectionService) {}

  static processAvailabilityStatusSubscriberEvent(
    event: AvailabilityStatusSubscriberEvent,
    currentListOfAvailabilityStatuses: AvailabilityStatusModel[],
  ) {
    if (event.eventType === 'removed') {
      return AvailabilityStatusSubscriberService.processAvailabilityStatusRemoveEvent(
        event.availabilityStatuses,
        currentListOfAvailabilityStatuses,
      );
    }

    return AvailabilityStatusSubscriberService.processAvailabilityStatusUpdateEvent(
      event.availabilityStatuses,
      currentListOfAvailabilityStatuses,
    );
  }

  static processAvailabilityStatusRemoveEvent(
    removedAvailabilityStatuses: AvailabilityStatusModel[],
    currentListOfAvailabilityStatuses: AvailabilityStatusModel[],
  ): AvailabilityStatusModel[] {
    let removedStatusIds = _.map(removedAvailabilityStatuses, status => status.id);
    return currentListOfAvailabilityStatuses.filter(
      (item: AvailabilityStatusModel) => !removedStatusIds.includes(item.id),
    );
  }

  static processAvailabilityStatusUpdateEvent(
    updatedAvailabilityStatuses: AvailabilityStatusModel[],
    currentListOfStatuses: AvailabilityStatusModel[],
  ) {
    let mergedList = AvailabilityStatusModel.mergeList(updatedAvailabilityStatuses, currentListOfStatuses);

    // Sort alphabetically
    mergedList = this.sortAvailabilityStatusesAlphabetically(mergedList);

    return mergedList;
  }

  static sortAvailabilityStatusesAlphabetically(statuses: AvailabilityStatusModel[]): AvailabilityStatusModel[] {
    return statuses.sort((availabilityStatusA, availabilityStatusB) =>
      availabilityStatusA.name >= availabilityStatusB.name ? -1 : 1,
    );
  }

  subscribeToAvailabilityStatuses(
    forUserEmail: string,
    emitIfEmpty?: boolean,
  ): Observable<AvailabilityStatusSubscriberEvent> {
    return merge(
      this.subscribeToRemoveEvents(forUserEmail),
      this.subscribeToAvailabilityStatusUpdates(forUserEmail, emitIfEmpty),
    );
  }

  private subscribeToRemoveEvents(forUserEmail: string): Observable<AvailabilityStatusSubscriberEvent> {
    return PublisherService.getModelsForUserAndModelType(
      forUserEmail,
      [AvailabilityStatusModel],
      PublishEventType.Remove,
    ).pipe(
      map((availabilityStatuses: AvailabilityStatusModel[]) => {
        return {
          eventType: 'removed',
          availabilityStatuses: availabilityStatuses,
        } as AvailabilityStatusSubscriberEvent;
      }),
    );
  }

  private subscribeToAvailabilityStatusUpdates(
    forUserEmail: string,
    emitIfEmpty?: boolean,
  ): Observable<AvailabilityStatusSubscriberEvent> {
    return this._availabilityStatusCollectionService
      .registerCollection(
        {
          offset: -1,
          size: -1,
          sessionId: v1(),
        },
        undefined,
        forUserEmail,
      )
      .pipe(
        mergeMap((collectionKey: string) => {
          return this._availabilityStatusCollectionService.registerObserver(collectionKey);
        }),
        debounceTime(100),
        map((response: ObserverResponse<AvailabilityStatusModel>) => {
          return response.models;
        }),
        filter((availabilityStatuses: AvailabilityStatusModel[]) => {
          return emitIfEmpty || !_.isEmpty(availabilityStatuses);
        }),
        map((availabilityStatuses: AvailabilityStatusModel[]) => {
          return { eventType: 'update', availabilityStatuses: availabilityStatuses };
        }),
      );
  }
}
