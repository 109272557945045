import * as _ from 'lodash';
import { interval, Observable, of } from 'rxjs';
import { filter, first, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { DatabaseService } from './database.service';
import { DatabaseFactoryI } from '@dta/backend/database/database.service';
import { Logger } from '@shared/services/logger/logger';
import { Time } from '@dta/shared/utils/common-utils';

export abstract class DatabaseFactory implements DatabaseFactoryI {
  private _namespace: string = 'loop_store';
  protected _instances: { [userEmail: string]: DatabaseService } = {};

  protected abstract get constructorName(): string;

  protected abstract openDB(forUserEmail: string): Observable<DatabaseService>;

  abstract destroy(forUserEmail: string): Observable<any>;

  protected getDatabaseKey(forUserEmail: string): string {
    return this._namespace + '_' + forUserEmail;
  }

  open(forUserEmail: string): Observable<DatabaseService> {
    if (!forUserEmail) {
      throw new Error(`${this.constructorName}: forUserEmail cannot be empty`);
    }

    if (this._instances[forUserEmail]) {
      return of(this._instances[forUserEmail]);
    }

    return this.openDB(forUserEmail).pipe(
      tap(db => {
        Logger.log(`${Time.getTimestamp()} ${this.constructorName}  [${forUserEmail}]: opened DB`);
        this._instances[forUserEmail] = db;
      }),
    );
  }

  whenDbInit(forUserEmail: string): Observable<boolean> {
    if (this._instances[forUserEmail] !== undefined) {
      return of(true);
    }

    return interval(100).pipe(
      map(() => {
        return this._instances[forUserEmail] !== undefined;
      }),
      filter((isInit: boolean) => {
        return isInit;
      }),
      first(),
      publishReplay(1),
      refCount(),
    );
  }

  forUser(forUserEmail: string): DatabaseService {
    if (!forUserEmail) {
      throw new Error(`${this.constructorName}: forUserEmail cannot be empty`);
    }

    return this._instances[forUserEmail];
  }
}
