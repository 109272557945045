<div class="title">ADD CLASSIFICATION TAG</div>
<loop-classification-tree
  [selectedClassificationTag]="selectedClassificationTag"
  [classificationTagsListTpl]="classificationTagsListTpl"
  (nodeClick)="handleNodeClick($event)"
></loop-classification-tree>
<ng-template #classificationTagsListTpl let-classificationNodeTags>
  <div
    class="padding-vertical-quarter unclassified-button-container"
    (click)="removeClassificationTag(classificationNodeTags)"
  >
    <div
      class="unclassified-button padding-vertical-quarter padding-horizontal-quarter flex items-center font-new-default"
    >
      <div class="classification-circle-wrapper">
        <div class="classification-circle"></div>
      </div>
      Unclassified
    </div>
  </div>
</ng-template>
