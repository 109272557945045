import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  formatDistanceToNowStrict,
  isToday,
  isTomorrow,
  isYesterday
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
  isInFourDaysAndLessThanFiveDays,
  isInMoreThanFiveDaysAndLessThanWeek,
  isInMoreThanWeekAndLessThanMonth,
  isInMoreThanMonthAndLessThanYear,
  isMoreThanTwoDaysAgoAndLessThanWeek,
  isMoreThanWeekAgoAndLessThanMonth,
  isMoreThanMonthAgoAndLessThanYear,
  isMoreThanYearAgo
} from '../relative-today-off-short';
import { BaseDateFormatterResource } from '../base-date-formatter-resource';
import { DateFormatter } from '../date-formatter';
import { DateFormat } from '@shared/shared/fancy-date/common/enumerators/date-format';

export class EnResource extends BaseDateFormatterResource {
  readonly dateFormats: Record<DateFormat, DateFormatter> = {
    [DateFormat.systemDate]: 'dd/MM/yyyy',
    [DateFormat.systemTime]: 'HH:mm',
    [DateFormat.systemDateTime]: 'dd/MM/yyyy HH:mm',
    [DateFormat.fancyDate]: 'd/M/yyyy',
    [DateFormat.fancyTime]: 'H:mm',
    [DateFormat.fancyDateTime]: 'd/M/yyyy H:mm',
    [DateFormat.year]: 'yyyy',
    [DateFormat.wiseDateTime]: (date: Date) => {
      if (isToday(date)) {
        return `today ${this.formatDate(date, DateFormat.fancyTime)}`;
      }
      if (isYesterday(date)) {
        return `yesterday ${this.formatDate(date, DateFormat.fancyTime)}`;
      }
      if (isTomorrow(date)) {
        return `tomorrow ${this.formatDate(date, DateFormat.fancyTime)}`;
      }
      return this.formatDate(date, DateFormat.fancyDateTime);
    },
    [DateFormat.shortWiseDateTime]: (date: Date) => {
      const time = `${this.formatDate(date, DateFormat.fancyTime)}`;
      if (isToday(date)) {
        return `today ${time}`;
      }
      if (isYesterday(date)) {
        return `yesterday ${time}`;
      }
      if (isTomorrow(date)) {
        return `tomorrow ${time}`;
      }
      return `${this.formatDate(date, DateFormat.dayNameShort)} ${this.formatDate(
        date,
        DateFormat.reducedDate
      )} ${time}`;
    },
    [DateFormat.reducedDate]: 'd/M',
    [DateFormat.dayName]: 'EEEE',
    [DateFormat.dayNameShort]: 'EEEEEE',
    [DateFormat.relativeTodayOffsetShort]: (date: Date) => {
      const today = new Date();
      const numberOfDays = Math.abs(differenceInDays(date, today));
      const numberOfWeeks = Math.abs(differenceInWeeks(date, today));
      const numberOfMonths = Math.abs(differenceInMonths(date, today));
      if (isToday(date)) {
        return `today`;
      }
      if (isYesterday(date)) {
        return `yesterday`;
      }
      if (isTomorrow(date)) {
        return `tomorrow`;
      }
      if (isInFourDaysAndLessThanFiveDays(date)) {
        return `in ${numberOfDays} days`;
      }
      if (isInMoreThanFiveDaysAndLessThanWeek(date)) {
        return `in ${numberOfDays} days`;
      }
      if (isInMoreThanWeekAndLessThanMonth(date)) {
        return `in ${numberOfWeeks} wks.`;
      }
      if (isInMoreThanMonthAndLessThanYear(date)) {
        return `in ${numberOfMonths} mo.`;
      }
      if (isMoreThanTwoDaysAgoAndLessThanWeek(date)) {
        return `before ${numberOfDays} days`;
      }
      if (isMoreThanWeekAgoAndLessThanMonth(date)) {
        return `before ${numberOfWeeks} wks.`;
      }
      if (isMoreThanMonthAgoAndLessThanYear(date)) {
        return `before ${numberOfMonths} mo.`;
      }
      if (isMoreThanYearAgo(date)) {
        return `over a year`;
      }
      return `in a year or more`;
    },
    [DateFormat.relativeNowOffsetShort]: (date: Date) =>
      formatDistanceToNowStrict(date, { addSuffix: true, locale: this.dateFnsLocale }),
    [DateFormat.yearWeek]: `'w.' w/yyyy`
  };

  readonly dateFnsLocale: Locale = enUS;
}
