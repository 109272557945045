/* tslint:disable */

/**
 * payment status type
 */
export type UserPaymentStatusType = 'Trial' | 'Paid' | 'Suspend' | 'WhiteList' | 'PersonalAccount' | 'SharedInbox';

export module UserPaymentStatusType {
  export const TRIAL: UserPaymentStatusType = 'Trial';
  export const PAID: UserPaymentStatusType = 'Paid';
  export const SUSPEND: UserPaymentStatusType = 'Suspend';
  export const WHITE_LIST: UserPaymentStatusType = 'WhiteList';
  export const PERSONAL_ACCOUNT: UserPaymentStatusType = 'PersonalAccount';
  export const SHARED_INBOX: UserPaymentStatusType = 'SharedInbox';

  export function values(): UserPaymentStatusType[] {
    return [TRIAL, PAID, SUSPEND, WHITE_LIST, PERSONAL_ACCOUNT, SHARED_INBOX];
  }
}
