import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { FetchRequestFileList, FetchResult } from '@dta/shared/models/collection.model';
import { concat, EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import { defaultIfEmpty, filter, flatMap, map, tap, toArray } from 'rxjs/operators';
import { FileApiService } from '@shared/api/api-loop/services';
import { ListOfResourcesOfFile } from '@shared/api/api-loop/models';
import { CardService } from '@shared/services/data/card/card.service';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { FileService } from '@shared/services/data/file/file.service';
import { BaseCollectionService } from '@shared/services/data/collection/base-collection/base-collection.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { ContactService } from '@shared/services/data/contact/contact.service';

@Injectable()
export class FileCollectionService extends BaseCollectionService {
  constructor(
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
    private _fileApiService: FileApiService,
    private _fileService: FileService,
  ) {
    super(_commentService, _cardService, _contactService);
  }

  get constructorName(): string {
    return 'FileCollectionService';
  }

  ///////////////
  // FETCH FILES
  ///////////////
  fetchFiles(forUserEmail: string, fetchRequest: FetchRequestFileList): Observable<FetchResult> {
    let watch = new StopWatch(this.constructorName + '.fetchCards', ProcessType.SERVICE, forUserEmail);

    let dataLength: number = 0;

    watch.log('_fetchFiles');
    return this._fetchFiles(forUserEmail, fetchRequest.apiParams, fetchRequest.minNumberOfEntities, watch).pipe(
      /**
       * Set list getter status
       */
      tap((files: FileModel[]) => {
        dataLength = files.length;
      }),
      /**
       * Process files
       */
      flatMap((files: FileModel[]) => {
        watch.log('processFetchedResponses: ' + files.length);
        return this.processFetchedResponses(forUserEmail, files, fetchRequest.apiParams.recipientIds, watch);
      }),
      /**
       * Save all and publish
       */
      flatMap((files: FileModel[]) => {
        watch.log('saveAllAndPublish: ' + files.length);
        return this._fileService.saveAllAndPublish(forUserEmail, files);
      }),
      /**
       * Return
       */
      map(() => {
        watch.log('done');

        let fetchResult: FetchResult = {
          offsetHistoryId: fetchRequest.apiParams.offsetHistoryId,
          dataLength: dataLength,
          hasData: dataLength > 0,
        };

        return fetchResult;
      }),
    );
  }

  private _fetchFiles(
    forUserEmail: string,
    apiParams: FileApiService.File_GetListParams,
    minNumberOfFiles: number,
    watch: StopWatch,
  ): Observable<FileModel[]> {
    // Fetch
    watch.log('_fetchFilesRecursive');
    return this._fetchFilesRecursive(forUserEmail, apiParams, minNumberOfFiles, watch).pipe(
      // Merge results from all fetch requests
      flatMap((files: FileModel[]) => {
        return from(files);
      }),
      toArray(),
    );
  }

  protected _fetchFilesRecursive(
    forUserEmail: string,
    apiParams: FileApiService.File_GetListParams,
    minNumberOfFiles: number,
    watch: StopWatch,
    result: FileModel[] = [],
  ): Observable<FileModel[]> {
    let _response: ListOfResourcesOfFile;

    watch.log('File_GetList');
    return this._fileApiService.File_GetList(apiParams, forUserEmail).pipe(
      filter((response: ListOfResourcesOfFile) => {
        _response = response;
        apiParams.offsetHistoryId = response.offsetHistoryId;
        return !_.isEmpty(response.resources);
      }),
      /**
       * Cast to models
       */
      map((response: ListOfResourcesOfFile) => {
        return FileModel.createList(response.resources);
      }),
      /**
       * Add to result set of fetched cards
       */
      tap((files: FileModel[]) => {
        result.push(...files);
      }),
      defaultIfEmpty([]),
      flatMap((files: FileModel[]) => {
        let next$: Observable<FileModel[]>;
        let hasMore = _response.offset + _response.size < _response.totalSize;
        let needMore = result.length < minNumberOfFiles;

        if (hasMore && needMore) {
          watch.log('will fetch next batch');
          next$ = this._fetchFilesRecursive(forUserEmail, apiParams, minNumberOfFiles, watch, result);
        } else {
          watch.log('end');
          next$ = <Observable<FileModel[]>>EMPTY;
        }

        return concat(of(files), next$);
      }),
    );
  }

  private decorateFilesChannelIds(files: FileModel[], channelId: string): Observable<FileModel[]> {
    if (_.isEmpty(channelId)) {
      return of(files);
    }

    return from(files).pipe(
      map((file: FileModel) => {
        file._ex.channelIds = _.isEmpty(file._ex.channelIds)
          ? [channelId]
          : _.uniq([...file._ex.channelIds, channelId]);
        return file;
      }),
      toArray(),
    );
  }

  private processFetchedResponses(
    forUserEmail: string,
    files: FileModel[],
    recipientIds: string[] = [],
    watch: StopWatch,
  ): Observable<FileModel[]> {
    if (_.isEmpty(files)) {
      return of([]);
    }

    let updatedCards: FileModel[] = [];

    // All files
    let observables = [this._fileService.filterNewFiles(forUserEmail, files)];

    // Files for channel/contact
    if (!_.isEmpty(recipientIds)) {
      let updateExistingCards = this._fileService.updateChannelIds(forUserEmail, files, recipientIds[0]);
      observables.push(updateExistingCards);
    }

    watch.log('forkJoin - filter new and update');
    return forkJoin(observables).pipe(
      flatMap((response: Array<FileModel[]>) => {
        if (response.length > 1) {
          // Save updated files
          updatedCards.push(...response[1]);
        }

        // Return new data
        return of(response[0]);
      }),
      // Save so new file will have extra data decorated
      flatMap((files: FileModel[]) => {
        watch.log('saveAll new: ' + files.length);
        return this._fileService.saveAll(forUserEmail, files);
      }),
      // Decorate new files
      flatMap((files: FileModel[]) => {
        watch.log('decorateFilesChannelIds new: ' + files.length);
        return this.decorateFilesChannelIds(files, recipientIds[0]);
      }),
      // Force thumbnail download for new files
      flatMap((files: FileModel[]) => {
        watch.log('downloadThumbnails new: ' + files.length);
        return this._fileService.downloadThumbnails(forUserEmail, files);
      }),
      map((files: FileModel[]) => [...files, ...updatedCards]),
    );
  }
}
