import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import {
  BaseFormControlComponent,
  provideControlValueAccessor
} from '@shared/modules/form-controls/base-form-control.component';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ContactType } from '@shared/api/api-loop/models/contact-type';
import { ContactApiService } from '@shared/api/api-loop/services';
import { ContactSortOrder } from '@shared/api/api-loop/models/contact-sort-order';
import { Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { ContactBaseModel, ContactModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { debounceTime, distinctUntilChanged, map, publishReplay, refCount, startWith } from 'rxjs/operators';
import { EmailUtils } from '@dta/shared/utils/common-utils';

@Component({
  selector: 'loop-auto-suggest-control',
  templateUrl: './auto-suggest-control.component.html',
  styleUrls: ['./auto-suggest-control.component.scss'],
  providers: [
    provideControlValueAccessor(AutoSuggestControlComponent),
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutoSuggestControlComponent),
      multi: true
    }
  ]
})
export class AutoSuggestControlComponent
  extends BaseFormControlComponent<ContactModel | ContactModel[]>
  implements OnDestroy, Validator
{
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly contactApiService: ContactApiService = inject(ContactApiService);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  searchValue: string = '';
  showAutoSuggest: boolean = false;
  searchChanged$: ReplaySubject<string> = new ReplaySubject<string>();
  hoveredIndex: number | null = null;
  protected contactToBeDeleted: ContactModel | null = null;
  protected readonly checkMarkSvg: NodeRequire = require('shared/assets/img/svg/filter-chooser-checkmark.svg');
  protected readonly removeSvg: NodeRequire = require('shared/assets/img/svg/remove.svg');
  protected isInvalidRecipientWarning: boolean = false;

  @Input() mode: 'multiple' | 'default' = 'default';
  @Input() customTemplateRenderer?: TemplateRef<{ $implicit: ContactModel }>;
  @Input() areSelectedInPlace: boolean = false;
  @Input() querySizeInitialLimit: number = 0;
  @Input() additionalContactFilter: Partial<ContactApiService.Contact_GetListParams> = {
    contactTypes: [ContactType.USER, ContactType.GROUP]
  };

  @ViewChild('inputElement') private readonly inputElement?: ElementRef<HTMLInputElement>;

  protected readonly contacts$: Observable<ContactModel[]> = this.searchChanged$.pipe(
    debounceTime(200),
    startWith(''),
    distinctUntilChanged(),
    switchMap(searchValue => {
      if (searchValue.length < this.querySizeInitialLimit) {
        return of([]);
      }

      const currentValue = this.value$.getValue();
      const excludeContactIds = (currentValue ? (Array.isArray(currentValue) ? currentValue : [currentValue]) : []).map(
        contact => contact.id
      );

      return this.contactApiService
        .Contact_GetList(
          {
            searchQuery: searchValue,
            size: 5,
            excludeContactIds: excludeContactIds,
            contactSortOrder: ContactSortOrder.WEIGHT,
            ...this.additionalContactFilter
          },
          this.userManagerService.getCurrentUserEmail(),
          true
        )
        .pipe(map(response => ContactBaseModel.createList(response.resources)));
    }),
    publishReplay(1),
    refCount()
  );

  onSearchChange(value): void {
    this.contactToBeDeleted = null;
    this.searchValue = value;
    this.searchChanged$.next(value);
    this.onSearchFocus();

    if (!value) {
      this.isInvalidRecipientWarning = false;
      this.ngControl.control.updateValueAndValidity();
    }
  }

  toggleItem(contact: ContactModel): void {
    if (!contact) {
      return;
    }

    this.searchValue = '';
    this.searchChanged$.next('');
    this.showAutoSuggest = false;
    let currentValue = this.value$.getValue();
    if (this.mode === 'multiple') {
      if (!Array.isArray(currentValue)) {
        currentValue = [];
      }

      if (currentValue.some(value => (value.id && value.id === contact.id) || value.email === contact.email)) {
        currentValue = currentValue.filter(
          value => (value.id && value.id !== contact.id) || value.email !== contact.email
        );
      } else {
        currentValue = [...currentValue, contact];
      }
      this.handleModelChange(currentValue);
    } else {
      this.handleModelChange([contact]);
    }
    this.focus();
  }

  createEmptyContact({ shouldFocus }: { shouldFocus: boolean }): void {
    const email = this.searchValue.trim();
    if (EmailUtils.validateEmail(email)) {
      const user = new UserModel({
        $type: UserModel.type,
        email: email,
        name: email
      });
      this.toggleItem(user);
      this.isInvalidRecipientWarning = false;
      this.searchValue = '';
    }

    if (shouldFocus) {
      this.focus();
    }
  }

  protected deleteContact(): void {
    let currentSelectedContacts = this.value$.getValue();

    if (!currentSelectedContacts || (Array.isArray(currentSelectedContacts) && currentSelectedContacts.length === 0)) {
      return;
    }

    if (!Array.isArray(currentSelectedContacts)) {
      currentSelectedContacts = [currentSelectedContacts];
    }

    if (!this.contactToBeDeleted) {
      this.contactToBeDeleted = currentSelectedContacts[currentSelectedContacts.length - 1];
    } else {
      this.toggleItem(this.contactToBeDeleted);
      this.contactToBeDeleted = null;
    }
  }

  onSearchFocus(): void {
    this.onFocus();
    const isDisabled = this.isDisabled$.getValue();
    if (!isDisabled) {
      this.contactToBeDeleted = null;
      this.showAutoSuggest = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  protected focusHandler(): void {
    this.inputElement?.nativeElement?.focus();
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors {
    if (this.searchValue) {
      this.isInvalidRecipientWarning = true;
      this.changeDetectorRef.detectChanges();

      return {
        'auto-suggest-control-invalid-recipients': true
      };
    }

    return null;
  }

  ngOnDestroy(): void {
    this.searchChanged$.complete();
  }
}
