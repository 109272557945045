import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { Subject } from 'rxjs';
import { QuoteCommentModel } from '@dta/shared/models-api-loop/comment/comment.model';

@Component({
  selector: 'notification-card',
  styleUrls: ['./notification-card.scss'],
  template: `
    <div *ngIf="show" class="notification-card" [class.warning]="type === 'warning'">
      <div class="dismiss" (click)="dismiss()">
        <svg-comp [src]="removeSvg"></svg-comp>
      </div>

      <div class="title">
        {{ title }}
      </div>

      <div *ngFor="let _content of content" class="card-content">
        {{ _content }}
      </div>

      <a *ngIf="ctaCopy" [href]="ctaUrl" target="_blank" (click)="onCtaCLick($event)">
        {{ ctaCopy }}
      </a>
    </div>
  `,
})
export class NotificationCardComponent implements OnInit {
  removeSvg = require('shared/assets/img/svg/close-icon.svg');

  @Input() title: string;
  @Input() content: string | string[];
  @Input() ctaCopy: string;
  @Input() ctaUrl: string;
  @Input() type: 'warning' | 'info' = 'info';
  @Input() dontShowAgainAfterDismissed: boolean = true;

  @Output() onCtaClick: EventEmitter<any> = new EventEmitter<any>();

  show: boolean;

  constructor(
    private _storageService: StorageService,
    private _userManagerService: UserManagerService,
  ) {}

  ngOnInit() {
    this.content = [this.content].flat();
    this.show = this.dontShowAgainAfterDismissed ? !this._storageService.getItem(this.storageKey) : true;
  }

  dismiss() {
    if (this.dontShowAgainAfterDismissed) {
      this._storageService.setItem(this.storageKey, true);
    }

    this.show = false;
  }

  onCtaCLick($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.onCtaClick.emit(true);
  }

  private get storageKey(): string {
    return (
      this._userManagerService.getCurrentUserEmail() +
      '_dismiss_notification_card_' +
      this.title.split(' ').join('_').toLowerCase()
    );
  }
}
