import { Pipe, PipeTransform } from '@angular/core';
import { getFileExtension } from '@shared/utils/get-file-extension';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { videoExtensions } from '@shared/modules/files/common/constants/video-extensions';
import { imageExtensions } from '@shared/modules/files/common/constants/image-extensions';
import { FileModel } from '@dta/shared/models-api-loop/file.model';

@Pipe({
  name: 'hasPreview',
  standalone: true
})
export class HasPreviewPipe implements PipeTransform {
  transform(file: FileModel | IFile): boolean {
    const extension = getFileExtension(file.name).toLowerCase();
    return videoExtensions.includes(extension) || imageExtensions.includes(extension) || extension === 'pdf';
  }
}
