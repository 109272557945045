import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WysiwygControlComponent } from '@shared/modules/form-controls/wysiwyg-control/wysiwyg-control.component';
import { FormControlWrapperModule } from '@shared/modules/form-controls/base/form-control-wrapper/form-control-wrapper.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { DropdownModule } from '@shared/ui/dropdown/dropdown.module';
import { MentionModule } from '@shared/modules/contacts/components/mention/mention.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

@NgModule({
  declarations: [WysiwygControlComponent],
  imports: [
    CommonModule,
    FormControlWrapperModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    IconModule,
    DropdownModule,
    MentionModule
  ],
  exports: [WysiwygControlComponent]
})
export class WysiwygControlModule {}
