import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import {
  ConfirmSingleButtonParams,
  DescriptionType
} from '@shared/shared/confirm/common/interfaces/confirm-single-button-params';

@Component({
  templateUrl: './confirm-with-single-button-dialog-content.component.html',
  styleUrls: ['./confirm-with-single-button-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmWithSingleButtonDialogContentComponent {
  @Input() params?: ConfirmSingleButtonParams;
  @Output() confirmResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected readonly DescriptionType_Alert: DescriptionType.alert = DescriptionType.alert;

  confirm(): void {
    this.confirmResult.emit(true);
  }
}
