import { AbstractControl, FormArray, UntypedFormGroup } from '@angular/forms';

export function updateEntireFormValidity(control: AbstractControl): void {
  control.markAsTouched();
  control.markAsDirty();
  control.updateValueAndValidity();

  if (control instanceof FormArray) {
    control.controls.forEach(formArrayControl => {
      updateEntireFormValidity(formArrayControl);
    });
  }

  if (control instanceof UntypedFormGroup) {
    Object.keys(control.controls).forEach(formGroupControlName => {
      updateEntireFormValidity(control.controls[formGroupControlName]);
    });
  }
}
