import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@shared/modules/common/icon/icon.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, IconSpriteModule],
  exports: [IconComponent],
})
export class IconModule {}
