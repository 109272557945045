import * as _ from 'lodash';
import { v1 } from 'uuid';
import { merge, Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ObserverResponse } from '@dta/ui/collections/collection-subscriber.service';
import {
  IntegrationSubscriberEvent,
  IntegrationSubscriberInterface,
} from '@shared/services/integration-subscriber/integration-subscriber.interface';
import { IntegrationsCollectionService } from '@dta/ui/collections/integrations/integrations.collection';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { PublishEventType } from '../communication/shared-subjects/shared-subjects-models';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';

@Injectable()
export class IntegrationSubscriberService implements IntegrationSubscriberInterface {
  constructor(private _integrationCollectionService: IntegrationsCollectionService) {}

  static processIntegrationSubscriberEvent(
    event: IntegrationSubscriberEvent,
    currentListOfIntegrations: IntegrationModel[],
  ) {
    if (event.eventType === 'removed') {
      return IntegrationSubscriberService.processIntegrationRemoveEvent(event.integrations, currentListOfIntegrations);
    }

    return IntegrationSubscriberService.processIntegrationUpdateEvent(event.integrations, currentListOfIntegrations);
  }

  static processIntegrationRemoveEvent(
    removedIntegrations: IntegrationModel[],
    currentListOfIntegrations: IntegrationModel[],
  ): IntegrationModel[] {
    let removedStatusIds = _.map(removedIntegrations, status => status.id);
    return currentListOfIntegrations.filter((item: IntegrationModel) => !removedStatusIds.includes(item.id));
  }

  static processIntegrationUpdateEvent(
    updatedIntegrations: IntegrationModel[],
    currentListOfStatuses: IntegrationModel[],
  ) {
    let mergedList = IntegrationModel.mergeList(updatedIntegrations, currentListOfStatuses);

    // Sort alphabetically
    mergedList = this.sortIntegrationsAlphabetically(mergedList);

    return mergedList;
  }

  static sortIntegrationsAlphabetically(statuses: IntegrationModel[]): IntegrationModel[] {
    return statuses.sort((integrationA, integrationB) => (integrationA.name >= integrationB.name ? -1 : 1));
  }

  subscribeToIntegrations(forUserEmail: string, emitIfEmpty?: boolean): Observable<IntegrationSubscriberEvent> {
    return merge(
      this.subscribeToRemoveEvents(forUserEmail),
      this.subscribeToIntegrationUpdates(forUserEmail, emitIfEmpty),
    );
  }

  private subscribeToRemoveEvents(forUserEmail: string): Observable<IntegrationSubscriberEvent> {
    return PublisherService.getModelsForUserAndModelType(
      forUserEmail,
      [IntegrationModel],
      PublishEventType.Remove,
    ).pipe(
      map((integrations: IntegrationModel[]) => {
        return {
          eventType: 'removed',
          integrations: integrations,
        } as IntegrationSubscriberEvent;
      }),
    );
  }

  private subscribeToIntegrationUpdates(
    forUserEmail: string,
    emitIfEmpty?: boolean,
  ): Observable<IntegrationSubscriberEvent> {
    return this._integrationCollectionService
      .registerCollection(
        {
          offset: -1,
          size: -1,
          sessionId: v1(),
        },
        undefined,
        forUserEmail,
      )
      .pipe(
        mergeMap((collectionKey: string) => {
          return this._integrationCollectionService.registerObserver(collectionKey);
        }),
        debounceTime(100),
        map((response: ObserverResponse<IntegrationModel>) => {
          return response.models;
        }),
        filter((integrations: IntegrationModel[]) => {
          return emitIfEmpty || !_.isEmpty(integrations);
        }),
        map((integrations: IntegrationModel[]) => {
          return { eventType: 'update', integrations: integrations };
        }),
      );
  }
}
