import { ClassificationNode } from '@shared/modules/shared-tag/common/interfaces/classification-node';

export function findSelectedNodeInArray(nodes: ClassificationNode[]): ClassificationNode | null {
  // Iterate over the array of ClassificationNodes
  for (const node of nodes) {
    const selectedNode = findSelectedNode(node);
    if (selectedNode) {
      return selectedNode;
    }
  }

  // Return null if no selected node was found in any of the nodes in the array
  return null;
}

function findSelectedNode(node: ClassificationNode): ClassificationNode | null {
  // Check if the current node is selected
  if (node.isNodeSelected) {
    return node;
  }

  // Recursively check the child nodes
  for (const childNode of node.childNodes) {
    const selectedNode = findSelectedNode(childNode);
    if (selectedNode) {
      return selectedNode;
    }
  }

  // Return null if no selected node was found
  return null;
}
