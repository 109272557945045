import { ApiService } from '@shared/api/api-loop/api.module';
import { ElectronService } from '@shared/services/electron/electron';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { Logger } from '@shared/services/logger/logger';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { OnDestroy } from '@angular/core';
import { SyncServiceStatus } from '@shared/models/sync/synchronization-service-status.model';
import { DataServiceShared } from '@shared/services/data/data.service';
import { PullSynchronizationService } from '@shared/synchronization/pull-synchronization/pull-synchronization.service';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { SynchronizationStatusService } from './synchronization-status.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { PushSynchronizationModuleService } from './push-synchronization/push-synchronization.module';
import { PushSynchronizationService } from './push-synchronization/push-synchronization.service';
import { SynchronizationMiddlewareService } from './synchronization-middleware/synchronization-middleware.service';
import { RetrySynchronizationService } from './retry-synchronization/retry-synchronization.service';
import { FlushDatabaseService } from '@shared/services/flush-db/flush-db.service';
import { EventSynchronizationService } from './event-synchronization/event-synchronization.service';
import { EventHubService } from './websockets/event-hub.service';
import { DatabaseService } from '@shared/database/database.service';
import { Time } from '@dta/shared/utils/common-utils';
import { ConversationSynchronizationService } from '@shared/synchronization/conversation-synchronization/conversation-synchronization.service';
import { Observable, of } from 'rxjs';

@AutoUnsubscribe()
export abstract class SynchronizationManagerService implements OnDestroy {
  ////////////////////////////
  // Synchronization services
  ////////////////////////////

  // EVENT SYNC PROCESSING - service for processing synced events
  protected _eventSynchronizationService: EventSynchronizationService;

  // CONVERSATION SYNC PROCESSING - service for processing conversations
  protected _conversationSynchronizationService: ConversationSynchronizationService;

  // PULL SYNC - sync for incoming events
  protected _pullSynchronization: PullSynchronizationService;

  // RETRY SYNC - pull sync for items that might need to be awaited (thumbnail, html comments, ...)
  private _retrySynchronization: RetrySynchronizationService;

  // PUSH SYNC - sync for outgoing events
  protected _pushSynchronization: PushSynchronizationService;

  constructor(
    protected _userEmail: string,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _storageService: StorageService,
    protected _eventHubService: EventHubService,
    protected _syncStatusService: SynchronizationStatusService,
    protected _trackingService: TrackingService,
    protected _notificationsService: NotificationsService,
    protected _dataService: DataServiceShared,
    protected _apiService: ApiService,
    protected _fileStorageService: FileStorageService,
    protected _pushSynchronizationModuleService: PushSynchronizationModuleService,
    protected _electronService: ElectronService,
    protected _settingsService: SettingsService,
    protected _synchronizationMiddlewareService: SynchronizationMiddlewareService,
    protected _flushDatabaseService: FlushDatabaseService,
    protected _databaseService: DatabaseService,
    protected _time: Time,
  ) {}

  ngOnDestroy() {}

  protected abstract initPullSynchronizationService(): void;

  protected abstract initPushSynchronizationService(): void;

  /**
   * Starts all synchronization services unless they are already started
   */
  startSync() {
    // EVENT SYNC PROCESSING
    if (!this._eventHubService.active) {
      this._eventHubService.start();
    }

    // PULL SYNC
    if (!this._pullSynchronization.active) {
      this._pullSynchronization.start();
    }

    // RETRY SYNC
    if (!this._retrySynchronization.active) {
      this._retrySynchronization.start();
    }

    // PUSH SYNC
    if (!this._pushSynchronization.active) {
      this._pushSynchronization.start();
    }
  }

  /**
   * Stops all synchronization services
   */
  stopSync() {
    // EVENT SYNC PROCESSING
    this._eventHubService.stop();

    // PULL SYNC
    this._pullSynchronization?.stop();

    // RETRY SYNC
    this._retrySynchronization?.stop();

    // PUSH SYNC
    this._pushSynchronization?.stop();

    Logger.log(`[SYNC] - SyncService [${this._userEmail}]: synchronization stopped`);
  }

  /**
   * Check the status of synchronization services
   */
  statusSync(): boolean {
    let active =
      this._eventHubService.active &&
      this._pullSynchronization.active &&
      this._pushSynchronization.active &&
      this._retrySynchronization.active;

    return active;
  }

  /**
   * For in-app status
   */
  getSyncServicesStatus(): UserSyncStatus {
    let eventHubStatus = this._eventHubService.getStatus();
    let retrySynchronizationStatus = this._retrySynchronization.getStatus();
    let pushSynchronizationStatus = this._pushSynchronization.getStatus();
    let pullSynchronizationStatus = this._pullSynchronization.getStatus();

    let activeSynchronizationStatus = this._pullSynchronization.getActiveSynchronizationStatus();
    let followUpSynchronizationStatus = this._pullSynchronization.getFollowUpSynchronizationStatus();

    let userStatus: UserSyncStatus = {
      userEmail: this._userEmail,
      eventHubStatus: eventHubStatus,
      retrySynchronizationStatus: retrySynchronizationStatus,
      pushSynchronizationStatus: pushSynchronizationStatus,
      pullSynchronizationStatus: pullSynchronizationStatus,
      activeSynchronizationStatus: activeSynchronizationStatus,
      followUpSynchronizationStatus: followUpSynchronizationStatus,
    };

    return userStatus;
  }

  /**
   * For console debug (only on WEB, use BE thread on DTA)
   */
  reportSyncServicesStatus() {
    // Statuses
    let eventHubStatus = this._eventHubService.getStatus();
    let retrySynchronizationStatus = this._retrySynchronization.getStatus();
    let pushSynchronizationStatus = this._pushSynchronization.getStatus();
    let pullSynchronizationStatus = this._pullSynchronization.getStatus();

    let activeSynchronizationStatus = this._pullSynchronization.getActiveSynchronizationStatus();
    let followUpSynchronizationStatus = this._pullSynchronization.getFollowUpSynchronizationStatus();

    let status = '';
    status += '%cSynchronization services status:%c\n';
    status += 'eventHub: %c' + eventHubStatus + '%c\n';
    status += 'retrySynchronization: %c' + retrySynchronizationStatus + '%c\n';
    status += 'pushSynchronization: %c' + pushSynchronizationStatus + '%c\n';
    status += 'pullSynchronization: %c' + pullSynchronizationStatus + '%c\n';
    status += '\tactiveSynchronization: %c' + activeSynchronizationStatus + '%c\n';
    status += '\tfollowUpSynchronization: %c' + followUpSynchronizationStatus + '%c\n';

    console.log(
      '[SYNC] - SyncService [%s]: synchronization services status\n' + status,
      this._userEmail,
      'background-color: yellow',
      'background-color: white;',
      SyncServiceStatusColors.getStatusColor(eventHubStatus),
      SyncServiceStatusColors.getStatusColor(),
      SyncServiceStatusColors.getStatusColor(retrySynchronizationStatus),
      SyncServiceStatusColors.getStatusColor(),
      SyncServiceStatusColors.getStatusColor(pushSynchronizationStatus),
      SyncServiceStatusColors.getStatusColor(),
      SyncServiceStatusColors.getStatusColor(pullSynchronizationStatus),
      SyncServiceStatusColors.getStatusColor(),
      SyncServiceStatusColors.getStatusColor(activeSynchronizationStatus),
      SyncServiceStatusColors.getStatusColor(),
      SyncServiceStatusColors.getStatusColor(followUpSynchronizationStatus),
      SyncServiceStatusColors.getStatusColor(),
    );
  }

  /**
   * Getters for sync services (per user)
   */
  get pushSynchronization(): PushSynchronizationService {
    return this._pushSynchronization;
  }

  get retrySynchronization(): RetrySynchronizationService {
    return this._retrySynchronization;
  }

  get pullSynchronization(): PullSynchronizationService {
    return this._pullSynchronization;
  }

  /**
   * Init sync services
   */
  init() {
    // RETRY SYNC
    this._retrySynchronization = new RetrySynchronizationService(
      this._userEmail,
      this._dataService,
      this._sharedUserManagerService,
    );

    // EVENT SYNC PROCESSING
    this._eventSynchronizationService = new EventSynchronizationService(
      this._userEmail,
      this._dataService,
      this._apiService,
      this._notificationsService,
      this._sharedUserManagerService,
      this._settingsService,
      this._databaseService,
      this._time,
      this._storageService,
    );

    // PULL SYNC
    this.initPullSynchronizationService();

    // PUSH SYNC
    this.initPushSynchronizationService();
  }

  clearPushSyncQueue(forUserEmail: string): Observable<any> {
    return of(undefined);
  }
}

export class SyncServiceStatusColors {
  static getStatusColor(status?: SyncServiceStatus) {
    switch (status) {
      case SyncServiceStatus.ACTIVE:
        return 'color: #439137;font-weight: bold;';
      case SyncServiceStatus.INACTIVE:
        return 'color: #ad271d;font-weight: bold;';
      case SyncServiceStatus.WAITING:
        return 'color: #a6912b;font-weight: bold;';
      case SyncServiceStatus.COMPLETE:
        return 'color: #439137;font-weight: bold;';
      default:
        return 'color: #000000;font-weight: normal;';
    }
  }
}
export class UserSyncStatus {
  userEmail: string;
  eventHubStatus: SyncServiceStatus;
  retrySynchronizationStatus: SyncServiceStatus;
  pushSynchronizationStatus: SyncServiceStatus;
  pullSynchronizationStatus: SyncServiceStatus;
  activeSynchronizationStatus: SyncServiceStatus;
  followUpSynchronizationStatus: SyncServiceStatus;
}
