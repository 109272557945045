import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { filter, toArray } from 'rxjs/operators';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CollectionOptions, CollectionSubscriberService, LoadMoreType } from '../collection-subscriber.service';
import { DraftCommentCollectionParams, FetchResult } from '@dta/shared/models/collection.model';
import { BaseCollectionService } from '../base.collection';
import { CommentDraftModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { CollectionService } from '@shared/services/data/collection/collection.service';
import { DraftService } from '@shared/services/data/draft/draft.service';

@Injectable()
export class DraftCommentCollectionService extends BaseCollectionService<CommentDraftModel> {
  protected supportedTypes: (typeof BaseModel)[] = [CommentDraftModel];
  loadMoreType: LoadMoreType = LoadMoreType.QUERY_THEN_FETCH;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _collectionSubscriberService: CollectionSubscriberService,
    private _collectionService: CollectionService,
    private _draftService: DraftService,
  ) {
    super(_userManagerService, _collectionSubscriberService);
  }

  get constructorName(): string {
    return 'DraftsForCardCollectionService';
  }

  registerCollection(
    params: DraftCommentCollectionParams,
    options?: CollectionOptions,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<string> {
    if (!params || _.isNil(params.draftComment)) {
      throw new Error('draftComment param cannot be nil');
    }
    return super.registerCollection(params, options, forUserEmail);
  }

  protected doBeforePublish(
    models: CommentDraftModel[],
    params: DraftCommentCollectionParams,
    forUserEmail: string,
  ): Observable<CommentDraftModel[]> {
    return of(models);
  }

  protected doBeforeReduce(
    models: CommentDraftModel[],
    params: DraftCommentCollectionParams,
    forUserEmail: string,
  ): Observable<CommentDraftModel[]> {
    return of(models);
  }

  protected reduce(
    models: CommentDraftModel[],
    params: DraftCommentCollectionParams,
    forUserEmail: string,
  ): Observable<CommentDraftModel[]> {
    return from(models).pipe(
      filter((comment: CommentDraftModel) => {
        // Pass through updates for models in cache
        if (params.cache.hasModel(comment)) {
          return true;
        }

        // Pass through if id match
        if (this.isObservedId(params, comment)) {
          return true;
        }

        return false;
      }),
      toArray(),
    );
  }

  count(params: DraftCommentCollectionParams, forUserEmail: string): Observable<Number> {
    throw new Error('Method not implemented.');
  }

  query(params: DraftCommentCollectionParams, forUserEmail: string): Observable<CommentDraftModel[]> {
    return this._draftService.findDraftComment(forUserEmail, params);
  }

  fetch(params: DraftCommentCollectionParams, forUserEmail: string): Observable<FetchResult> {
    return this._collectionService.fetchDraftComment(forUserEmail, params);
  }

  ////////////////////
  // Private helpers
  ////////////////////
  private isObservedId(params: DraftCommentCollectionParams, comment: CommentDraftModel) {
    let matchingIds = _.intersection(
      [params.draftComment.id, params.draftComment.clientId],
      [comment.id, comment.clientId],
    );

    return !_.isEmpty(matchingIds) && _.some(matchingIds, id => !_.isUndefined(id));
  }
}
