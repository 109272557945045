import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { CommentMail, ContactBase, ThreadingEnum, User } from '@shared/api/api-loop/models';
import { SettingsService } from '@shared/services/settings/settings.service';
import { GroupModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { CommentMailModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { SendAsOption, SendAsType } from '@dta/shared/models/send-as.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

/**
 * Logic in this component should follow specs defined below
 * https://github.com/4thOffice/Product-and-staff/wiki/FE-Email-Actions-and-Thread-Splitting
 */

@Injectable()
export class RecipientListHelperService {
  constructor(
    private _settingsService: SettingsService,
    private _userManagerService: UserManagerService
  ) {}

  get constructorName(): string {
    return 'RecipientListHelperService';
  }

  getInitialFrom(
    sharedInboxIds: string[],
    sendAsOptions: SendAsOption[],
    lastCommentShareList: ContactBase[] = []
  ): SendAsOption {
    sharedInboxIds = sharedInboxIds.filter(sharedInboxId => !!sharedInboxId);
    const isSharedInbox = sendAsOptions.some(option => sharedInboxIds.includes(option.groupId));
    if (!sharedInboxIds.length || !isSharedInbox) {
      // Get default from last comment
      let aliasInShareList = _.find(sendAsOptions, option => {
        return _.some(
          lastCommentShareList,
          (contact: ContactBase) =>
            contact.$type === UserModel.type &&
            option.type === SendAsType.Alias &&
            option.user.email === (<User>contact).email
        );
      });

      if (aliasInShareList) {
        // Set default from last comment
        return aliasInShareList;
      } else {
        let sharedInbox = _.find(sendAsOptions, option => {
          return _.some(lastCommentShareList, (contact: ContactBase) => option.user.email === (<User>contact).email);
        });

        if (sharedInbox) {
          // Set default from last comment
          return sharedInbox;
        }

        const initialFromAddress = this._settingsService.getDefaultFromAddress(
          this._userManagerService.getCurrentUserEmail()
        );
        let initialSendAsOption = sendAsOptions.find(option => option.user.email === initialFromAddress);

        // Always select something
        return initialSendAsOption ?? sendAsOptions[0];
      }
    } else {
      let sharedInboxId = sharedInboxIds.find(sharedInboxId =>
        sendAsOptions.find(option => option.groupId === sharedInboxId)
      );
      const curSIImpersonatedUser = sendAsOptions.find(sendAsOption => sendAsOption.groupId === sharedInboxId);
      return curSIImpersonatedUser
        ? _.find(sendAsOptions, option => option.user.id === curSIImpersonatedUser.user.id)
        : sendAsOptions[0];
    }
  }

  getReplyRecipientList({
    lastMailCommentOnThread,
    sendAs,
    replyType,
    personalInboxes,
    threadingMode,
    commentForReply = lastMailCommentOnThread
  }: GetRecipientListData): RecipientList {
    // Required recipient list is build from last mail comment on thread
    // It represents set of recipients that must be present on recipient
    // list in order for the thread to not be split
    let requiredRecipientList: ContactBase[] = [];

    if (threadingMode !== ThreadingEnum.STANDARD) {
      requiredRecipientList = RecipientListHelperService.getUniqueUnionOfRecipients(
        this.isGroupThread(commentForReply)
          ? []
          : lastMailCommentOnThread.replyTo?.resources?.[0]
            ? lastMailCommentOnThread.replyTo?.resources
            : [lastMailCommentOnThread.author],
        lastMailCommentOnThread.to.resources,
        lastMailCommentOnThread.cc?.resources || []
      );

      requiredRecipientList = this.minimizeRequiredRecipientList(personalInboxes, requiredRecipientList);
    }

    // FORWARD
    if (replyType === ReplyType.FORWARD) {
      let recipientList: RecipientList = {
        requiredRecipientList: requiredRecipientList,
        to: [],
        cc: [],
        bcc: [],
        replyTo: []
      };
      return recipientList;
    }

    let to = [];
    // Set TO following the logic from specs
    if (
      this.isReplyToOwnEmail(personalInboxes, commentForReply) ||
      (replyType === ReplyType.REPLY_ALL && this.isGroupThread(commentForReply)) ||
      sendAs.user.id === commentForReply.author.id
    ) {
      if (this.isMeToMeReply(sendAs.user.id, commentForReply) && commentForReply.replyTo?.resources?.[0]) {
        to = commentForReply.replyTo?.resources;
      } else {
        to = this.isAnyPersonalInboxOnToList(personalInboxes, commentForReply)
          ? [commentForReply.author]
          : commentForReply.replyTo?.resources.length
            ? commentForReply.replyTo?.resources
            : commentForReply.to?.resources;
      }
    } else {
      to = commentForReply.replyTo?.resources?.[0] ? commentForReply.replyTo?.resources : [commentForReply.author];
    }

    let replyTo =
      sendAs.user.id === commentForReply.author.id && !this.isMeToMeReply(sendAs.user.id, commentForReply)
        ? commentForReply.replyTo?.resources
        : [];

    // REPLY TO ONE
    if (replyType === ReplyType.REPLY_ONE) {
      let recipientList: RecipientList = {
        requiredRecipientList: requiredRecipientList,
        to: to,
        cc: [],
        bcc: [],
        replyTo: replyTo
      };
      return recipientList;
    }

    // Set CC following the logic from specs
    let cc = this.isGroupThread(commentForReply)
      ? []
      : this.isReplyToOwnEmail(personalInboxes, commentForReply) &&
          !this.isAnyPersonalInboxOnToList(personalInboxes, commentForReply)
        ? commentForReply.cc?.resources
        : RecipientListHelperService.getUniqueUnionOfRecipients(
            commentForReply.to.resources,
            commentForReply.cc?.resources || []
          );

    // REPLY TO ALL
    if (replyType === ReplyType.REPLY_ALL) {
      let filteredRecipients = this.filterDoublesFromRecipientLists(personalInboxes, to, cc, [], sendAs.user.id);

      let recipientList: RecipientList = {
        requiredRecipientList: requiredRecipientList,
        to: filteredRecipients.to,
        cc: filteredRecipients.cc,
        bcc: [],
        replyTo: replyTo
      };
      return recipientList;
    }
  }

  private isReplyToOwnEmail(personalInboxes: GroupModel[], commentForReply: CommentMail): boolean {
    let allPossibleIds = _.flatten(personalInboxes.map(pi => pi.allowedImpersonatedSenders.resources)).map(
      user => user.id
    );
    return allPossibleIds.includes(commentForReply.author.id);
  }

  private isAnyPersonalInboxOnToList(personalInboxes: GroupModel[], commentForReply: CommentMail): boolean {
    let allPossibleIds = _.flatten(personalInboxes.map(pi => pi.allowedImpersonatedSenders.resources)).map(
      user => user.id
    );
    return commentForReply.to.resources.some(contact => allPossibleIds.includes(contact.id));
  }

  private isMeToMeReply(initialSendAsUserId: string, commentForReply: CommentMail): boolean {
    return (
      commentForReply.author.id === initialSendAsUserId &&
      commentForReply.to.resources.some(toRecipient => toRecipient.id === initialSendAsUserId)
    );
  }

  private isGroupThread(commentForReply: CommentMailModel): boolean {
    return _.some(commentForReply.getShareList(), contact => contact.$type === GroupModel.type);
  }

  static checkRequiredRecipientsPresent(
    selectedRecipientsTo: ContactBase[],
    selectedRecipientsCc: ContactBase[],
    selectedFrom: ContactBase,
    requiredRecipientList: ContactBase[],
    sendAsOptions: SendAsOption[]
  ): boolean {
    if (_.isUndefined(requiredRecipientList)) {
      return false;
    }

    let selectedRecipientList = RecipientListHelperService.getUniqueUnionOfRecipients(
      [selectedFrom],
      selectedRecipientsTo,
      selectedRecipientsCc
    );

    const selectedFromOption: SendAsOption | undefined = sendAsOptions.find(
      sendAsOption => sendAsOption.user.id === selectedFrom.id
    );
    if (selectedFromOption?.threadingMode === ThreadingEnum.STANDARD) {
      return selectedRecipientList.some(recipient => recipient.id === selectedFrom.id);
    }

    let selectedRecipientListContactIds = _.map(selectedRecipientList, contact => contact.id);
    let requiredRecipientListContactIds = _.map(requiredRecipientList, contact => contact.id);

    // If original recipients are present on recipient list -> thread does not split
    return requiredRecipientListContactIds.every(id => selectedRecipientListContactIds.includes(id));
  }

  filterDoublesFromRecipientLists(
    personalInboxes: GroupModel[],
    selectedRecipientsTo: ContactBase[],
    selectedRecipientsCc: ContactBase[],
    selectedRecipientsBcc: ContactBase[],
    selectedFromId?: string
  ): { to: ContactBase[]; cc: ContactBase[]; bcc: ContactBase[] } {
    if (selectedFromId) {
      let { cc, bcc } = this.removeSenderFromRecipients(
        personalInboxes,
        selectedRecipientsCc,
        selectedRecipientsBcc,
        selectedFromId
      );

      selectedRecipientsCc = cc;
      selectedRecipientsBcc = bcc;
    }

    // Remove recipients that are on to
    selectedRecipientsCc = _.differenceBy(selectedRecipientsCc, selectedRecipientsTo, 'id');
    selectedRecipientsBcc = _.differenceBy(selectedRecipientsBcc, selectedRecipientsTo, 'id');

    // Remove recipients that are on bcc
    selectedRecipientsBcc = _.differenceBy(selectedRecipientsBcc, selectedRecipientsCc, 'id');

    // Return
    return {
      to: RecipientListHelperService.uniqByIdOrClientId(selectedRecipientsTo),
      cc: RecipientListHelperService.uniqByIdOrClientId(selectedRecipientsCc),
      bcc: RecipientListHelperService.uniqByIdOrClientId(selectedRecipientsBcc)
    };
  }

  private static uniqByIdOrClientId(recipients: ContactBase[]): ContactBase[] {
    return _.uniqBy(recipients, c => c.id || c.clientId);
  }

  static getUniqueUnionOfRecipients(...recipients: any[]): ContactBase[] {
    return RecipientListHelperService.uniqByIdOrClientId(recipients.flat());
  }

  private removeSenderFromRecipients(
    personalInboxes: GroupModel[],
    selectedRecipientsCc: ContactBase[],
    selectedRecipientsBcc: ContactBase[],
    selectedFromId?: string
  ): { cc: ContactBase[]; bcc: ContactBase[] } {
    // Remove sender and its aliases
    let idsToRemove = [selectedFromId];

    const personalInbox = this.findPersonalInboxFromIdOrAliasId(personalInboxes, selectedFromId);
    if (personalInbox) {
      idsToRemove.push(...this.pickAliasProperties(personalInbox, 'id'));
    }

    // Remove selected recipients by ids
    _.remove(selectedRecipientsCc, r => idsToRemove.includes(r.id));
    _.remove(selectedRecipientsBcc, r => idsToRemove.includes(r.id));

    return { cc: selectedRecipientsCc, bcc: selectedRecipientsBcc };
  }

  private minimizeRequiredRecipientList(
    personalInboxes: GroupModel[],
    requiredRecipientList: ContactBase[]
  ): ContactBase[] {
    // Replace all aliases with primary account
    let required = _.map(requiredRecipientList, (recipient: ContactBase) => {
      let personalInboxFromAlias = this.findPersonalInboxFromEmailOrAliasEmail(
        personalInboxes,
        (<UserModel>recipient).email
      );
      return recipient.$type === UserModel.type && personalInboxFromAlias
        ? personalInboxFromAlias.syncingAccount
        : recipient;
    });

    // Take only one primary account
    return _.uniqBy(required, 'id');
  }

  private findPersonalInboxFromIdOrAliasId(personalInboxes: GroupModel[], id: string): GroupModel | undefined {
    return personalInboxes.find(pi => {
      pi.allowedImpersonatedSenders.resources.some(allowedSender => allowedSender.id === id);
    });
  }

  private findPersonalInboxFromEmailOrAliasEmail(personalInboxes: GroupModel[], email: string): GroupModel | undefined {
    return personalInboxes.find(pi => {
      pi.allowedImpersonatedSenders.resources.some(allowedSender => allowedSender.email === email);
    });
  }

  private pickAliasProperties(personalInbox: GroupModel, property: string): string[] {
    return _.map(personalInbox.allowedImpersonatedSenders.resources, (alias: User) => alias[property]);
  }
}

export enum ReplyType {
  REPLY_ALL = 'REPLY_ALL',
  REPLY_ONE = 'REPLY_ONE',
  FORWARD = 'FORWARD'
}

export interface RecipientList {
  // When contact is not present on requiredRecipientList
  // thread should be splitted
  requiredRecipientList: ContactBase[];

  to: ContactBase[];
  cc: ContactBase[];
  bcc: ContactBase[];
  replyTo: ContactBase[];
}

export interface GetRecipientListData {
  lastMailCommentOnThread: CommentMailModel;
  replyType: ReplyType;
  sendAs: SendAsOption;
  personalInboxes: GroupModel[];
  threadingMode: ThreadingEnum;
  commentForReply?: CommentMailModel;
}
