import { ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import {
  BaseFormControlComponent,
  provideControlValueAccessor
} from '@shared/modules/form-controls/base-form-control.component';
import { BehaviorSubject, map, Observable, combineLatest } from 'rxjs';

export interface SelectControlOption<T = any, V = any> {
  label: string;
  value: V;
  isDisabled?: boolean;
  originalValue?: T;
}

export type SelectControlMode = 'default' | 'multiple';

@Component({
  selector: 'loop-select-control',
  templateUrl: './select-control.component.html',
  styleUrls: ['./select-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideControlValueAccessor(SelectControlComponent)]
})
export class SelectControlComponent<V extends string | number | boolean, T = any>
  extends BaseFormControlComponent<V | V[]>
  implements OnDestroy
{
  private readonly options$: BehaviorSubject<
    SelectControlOption<T, V>[] | readonly SelectControlOption<T, V>[] | null
  > = new BehaviorSubject<SelectControlOption<T, V>[] | readonly SelectControlOption<T, V>[] | null>([]);
  @Input() set options(options: SelectControlOption<T, V>[] | readonly SelectControlOption<T, V>[] | null) {
    this.options$.next(options);
  }

  @Input() customOptionTemplate?: TemplateRef<{ $implicit: SelectControlOption<T> }>;
  @Input() mode: SelectControlMode = 'default';
  @Input() customValueTpl?: TemplateRef<{ $implicit: V | V[] }>;

  showDropdown: boolean = false;
  hoveredIndex: number | null = null;

  protected readonly queryString$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  protected readonly filteredOptions$: Observable<
    SelectControlOption<T, V>[] | readonly SelectControlOption<T, V>[] | null
  > = combineLatest([this.queryString$, this.options$]).pipe(
    map(([queryString, options]) => {
      if (!queryString) {
        return options;
      }

      return options.filter(option => option.label.toLowerCase().includes(queryString.toLowerCase()));
    })
  );

  protected readonly selectedValue$: Observable<T[]> = combineLatest(this.options$, this.value$).pipe(
    map(([options, value]: [SelectControlOption<T, V>[] | readonly SelectControlOption<T, V>[] | null, V | V[]]) => {
      if (!value) {
        value = [];
      }

      if (!Array.isArray(value)) {
        value = [value];
      }

      return value.reduce((acc, curr) => {
        const option = options.find(_option => _option.value === curr);
        if (option) {
          acc.push(option);
        }

        return acc;
      }, []);
    })
  );

  override handleModelChange(value: V | V[]): void {
    if (this.mode === 'default') {
      this.queryString$.next(null);
      this.showDropdown = false;
      super.handleModelChange(value);
    } else {
      let currentValue = this.value$.getValue();
      if (!Array.isArray(currentValue)) {
        if (currentValue) {
          currentValue = [currentValue];
        } else {
          currentValue = [];
        }
      }

      // TODO fix
      if (Array.isArray(value)) {
        return;
      }

      if (currentValue.includes(value)) {
        currentValue = currentValue.filter(currVal => currVal !== value);
      } else {
        currentValue = Array.isArray(value) ? [...currentValue, ...value] : [...currentValue, value];
      }

      super.handleModelChange(currentValue);
    }
  }

  onSearchFocus(): void {
    const isDisabled = this.isDisabled$.getValue();
    if (!isDisabled) {
      this.showDropdown = true;
    }
  }

  removeClick(option, event): void {
    event.stopPropagation();
    if (this.mode === 'default') {
      this.handleModelChange(null);
    } else {
      this.handleModelChange(option);
    }
  }

  protected handleOutsideClick(): void {
    this.showDropdown = false;
    this.queryString$.next(null);
  }

  protected focusHandler(): void {
    //noop
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.queryString$.complete();
    this.options$.complete();
  }
}
