/* tslint:disable */

/**
 * Sort by enum.
 */
export type OffsetDateType = 'CreatedDate' | 'ModifiedDate' | 'SystemModifiedDate';

export module OffsetDateType {
  export const CREATED_DATE: OffsetDateType = 'CreatedDate';
  export const MODIFIED_DATE: OffsetDateType = 'ModifiedDate';
  export const SYSTEM_MODIFIED_DATE: OffsetDateType = 'SystemModifiedDate';

  export function values(): OffsetDateType[] {
    return [CREATED_DATE, MODIFIED_DATE, SYSTEM_MODIFIED_DATE];
  }
}
