import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CustomDropdownBase } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown-base';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'loop-contacts-dropdown',
  templateUrl: './contacts-dropdown.component.html',
  styleUrls: ['./contacts-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsDropdownComponent extends CustomDropdownBase<ContactModel> implements OnDestroy {
  protected readonly contacts$: BehaviorSubject<ContactModel[]> = new BehaviorSubject([]);
  @Input() set contacts(contacts: ContactModel[]) {
    this.visibleDataLength = contacts?.length;
    this.currentItems = contacts;
    this.contacts$.next(contacts);
  }

  @Input() selectedContacts: ContactModel | ContactModel[] = [];

  protected visibleDataLength: number = this.contacts$.getValue()?.length;
  @Output() selectContact: EventEmitter<ContactModel> = new EventEmitter<ContactModel>();

  protected readonly checkMarkSvg: NodeRequire = require('shared/assets/img/svg/filter-chooser-checkmark.svg');

  protected currentItemClick(): void {
    this.selectContact.emit(this.currentItems[this.currentIndex]);
  }

  protected handleSelectContact(contact: ContactModel): void {
    this.selectContact.emit(contact);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.contacts$.complete();
  }
}
