import { Component, Input } from '@angular/core';
import { CardAppointmentModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';

@Component({
  selector: 'rsvp-info',
  templateUrl: './rsvp-info.html',
  styleUrls: ['./rsvp-info.scss'],
})
export class RsvpInfoComponent {
  //////////////
  // RSVP SVGs
  //////////////
  fuDateSvgUri = require('shared/assets/img/svg/fu-date.svg');
  fuOrganizerSvgUri = require('shared/assets/img/svg/fu-organiser.svg');
  fuInviteesSvgUri = require('shared/assets/img/svg/fu-invitees.svg');
  fuLocationSvgUri = require('shared/assets/img/svg/fu-location.svg');

  ////////////////////
  // Inputs / Outputs
  ////////////////////
  @Input() appointment: CardAppointmentModel;
}
