import { NgModule } from '@angular/core';
import { ElectronService } from '@shared/services/electron/electron';
import { LoginService } from '@shared/services/login/login.service';
import { SearchService } from '@shared/services/search/search.service';
import { SubscriptionService } from '@shared/services/data/subscription/subscription.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { AvatarService } from '@shared/services/data/avatar/avatar.service';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { NewMessageModalService } from '@shared/services/new-message-modal/new-message-modal.service';
import { SendAsService } from '@shared/services/send-as/send-as.service';
import { AvatarServiceWeb } from './avatar/avatar.service.web';
import { DataModuleWeb } from './data/data.module.web';
import { ElectronServiceWeb } from './electron/electron.service.web';
import { FileStorageServiceWeb } from './file-storage/file-storage.service.web';
import { LoginServiceWeb } from './login/login-service.web';
import { SearchServiceWeb } from './search/search.service.web';
import { UserManagerServiceWeb } from './user-manager/user-manager.service.web';
import { BBCodeService } from '@dta/shared/services/bbcode/bbcode.service';
import { HtmlFormattingHelper } from '@dta/shared/utils/html-formatting/html-formatting.helper';
import { HistoryAndSignatureHelper } from '@dta/shared/utils/history-and-signature/history-and-signature.helper';
import { LoginDialogService } from '@dta/ui/components/common/login-dialog/login-dialog.service';
import { DeepLinksService } from '@shared/services/deeplinks/deeplinks.service';
import { DeepLinksServiceWeb } from './deeplinks/deeplinks.service.web';
import { CacheService } from '@dta/backend/services/cache/cache.service';
import { BatchProcessingService } from '@dta/backend/services/batch-processing/batch-processing.service';
import { SubscriptionHelperService } from '@shared/services/subscription-helper/subscription-helper.service';
import { SharedAvatarService } from '@shared/services/shared-avatar/shared-avatar.service';
import { SharedAvatarServiceWeb } from './shared-avatar/shared-avatar.service.web';
import { BodyClippingService } from '@dta/shared/services/body-clipping/body-clipping.service';
import { ContactHelperService } from './contact-helper/contact-helper.service';
import { FileUrlStorageService } from './file-storage/file-url-storage/file-url-storage.service';
import { CollectionSubscriberService } from '@dta/ui/collections/collection-subscriber.service';
import { DraftCommentCollectionService } from '@dta/ui/collections/drafts/draft-comment.collection';
import { DialogHelperService } from '@shared/services/dialog-helper/dialog-helper.service';
import { RecipientListHelperService } from '@shared/services/recipient-list-helper/recipient-list-helper.service';
import { OAuthService } from '@shared/services/oauth/oauth.service';
import { ContactDetailHelperService } from '@shared/services/contact-detail-helper/contact-detail-helper.service';

@NgModule({
  imports: [DataModuleWeb],
  exports: [],
  declarations: [],
  providers: [
    LoginDialogService,
    BBCodeService,
    BodyClippingService,
    HtmlFormattingHelper,
    HistoryAndSignatureHelper,
    CacheService,
    BatchProcessingService,
    SubscriptionService,
    SubscriptionHelperService,
    SendAsService,
    ContactDetailHelperService,
    NewMessageModalService,
    ContactHelperService,
    FileUrlStorageService,
    DialogHelperService,
    RecipientListHelperService,
    OAuthService,
    { provide: SharedAvatarService, useClass: SharedAvatarServiceWeb },
    { provide: AvatarService, useClass: AvatarServiceWeb },
    { provide: FileStorageService, useClass: FileStorageServiceWeb },
    { provide: ElectronService, useClass: ElectronServiceWeb },
    { provide: DeepLinksService, useClass: DeepLinksServiceWeb },
    { provide: UserManagerService, useClass: UserManagerServiceWeb },
    { provide: LoginService, useClass: LoginServiceWeb },
    { provide: SearchService, useClass: SearchServiceWeb },
    DraftCommentCollectionService,
    CollectionSubscriberService
  ]
})
export class ServiceProviderModule {}
