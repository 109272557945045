import * as _ from 'lodash';
import { Directive } from '@angular/core';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { Observable, of } from 'rxjs';
import { BaseService } from '../base/base.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { AvailabilityStatusApiService } from '@shared/api/api-loop/services/availability-status-api.service';
import { AvailabilityStatusList } from '@shared/api/api-loop/models/availability-status-list';
import { AvailabilityStatusServiceI } from '@shared/services/data/availability-status/availability-status.service.interface';
import { AvailabilityStatusDaoService } from '@shared/database/dao/availability-status/availability-status-dao.service';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { AvailabilityStatus } from '@shared/api/api-loop/models/availability-status';

@Directive()
export class AvailabilityStatusService extends BaseService implements AvailabilityStatusServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _availabilityStatusDaoService: AvailabilityStatusDaoService,
    protected _availabilityStatusApiService: AvailabilityStatusApiService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'AvailabilityStatusService';
  }

  saveOnly(forUserEmail: string, availabilityStatus: AvailabilityStatusModel): Observable<AvailabilityStatusModel> {
    return this._availabilityStatusDaoService.saveAll(forUserEmail, [availabilityStatus]).pipe(map(_.first));
  }

  save(forUserEmail: string, availabilityStatus: AvailabilityStatusModel): Observable<AvailabilityStatusModel> {
    return this.saveAll(forUserEmail, [availabilityStatus]).pipe(map(_.first));
  }

  saveAll(
    forUserEmail: string,
    availabilityStatuses: AvailabilityStatusModel[],
  ): Observable<AvailabilityStatusModel[]> {
    if (_.isEmpty(availabilityStatuses)) {
      return of(availabilityStatuses);
    }
    return this._availabilityStatusDaoService.saveAll(forUserEmail, availabilityStatuses);
  }

  findAllAvailabilityStatuses(forUserEmail: string): Observable<AvailabilityStatusModel[]> {
    return this._availabilityStatusDaoService.findAllAvailabilityStatuses(forUserEmail);
  }

  syncAvailabilityStatuses(forUserEmail: string): Observable<AvailabilityStatusModel[]> {
    return this.fetchAllAvailabilityStatuses(forUserEmail);
  }

  saveAllAndPublish(
    forUserEmail: string,
    availabilityStatuses: AvailabilityStatusModel[],
  ): Observable<AvailabilityStatusModel[]> {
    return this.saveAll(forUserEmail, availabilityStatuses).pipe(
      tap((_availabilityStatuses: AvailabilityStatusModel[]) => {
        PublisherService.publishEvent(forUserEmail, _availabilityStatuses);
      }),
    );
  }

  private fetchAllAvailabilityStatuses(forUserEmail: string): Observable<AvailabilityStatusModel[]> {
    let watch = new StopWatch(this.constructorName + '.fetchAllStatuses', ProcessType.SERVICE, forUserEmail);

    watch.log('_fetchAllStatuses');
    return this._fetchAllAvailabilityStatuses(forUserEmail).pipe(
      mergeMap((availabilityStatuses: AvailabilityStatusModel[]) => {
        return this.processFetchedAvailabilityStatuses(forUserEmail, availabilityStatuses);
      }),
    );
  }

  protected processFetchedAvailabilityStatuses(
    forUserEmail: string,
    availabilityStatuses: AvailabilityStatusModel[],
  ): Observable<AvailabilityStatusModel[]> {
    return this._availabilityStatusDaoService.removeAllAvailabilityStatuses(forUserEmail).pipe(
      mergeMap(() => {
        return this.saveAllAndPublish(forUserEmail, availabilityStatuses);
      }),
    );
  }

  createAvailabilityStatus(
    forUserEmail: string,
    availabilityStatus: AvailabilityStatusModel,
  ): Observable<AvailabilityStatusModel> {
    return of(availabilityStatus).pipe(
      tap((_availabilityStatus: AvailabilityStatusModel) => {
        this.enqueuePushSynchronization(forUserEmail, _availabilityStatus);
        return _availabilityStatus;
      }),
    );
  }

  updateAvailabilityStatus(
    forUserEmail: string,
    updatedAvailabilityStatus: AvailabilityStatusModel,
  ): Observable<AvailabilityStatusModel> {
    return this.createAvailabilityStatus(forUserEmail, updatedAvailabilityStatus);
  }

  removeAvailabilityStatus(forUserEmail: string, availabilityStatus: AvailabilityStatusModel): Observable<any> {
    return this._availabilityStatusDaoService.remove(forUserEmail, availabilityStatus).pipe(
      tap(() => {
        availabilityStatus._ex = {
          deleted: true,
        };
        this.enqueuePushSynchronization(forUserEmail, availabilityStatus);
        PublisherService.publishEvent(
          forUserEmail,
          new AvailabilityStatusModel(availabilityStatus),
          PublishEventType.Remove,
        );
      }),
    );
  }

  findOrFetch(forUserEmail: string, availabilityStatusId: string): Observable<AvailabilityStatusModel> {
    return this._availabilityStatusDaoService.findById(forUserEmail, availabilityStatusId).pipe(
      catchError(err => {
        return of(undefined);
      }),
      mergeMap((availabilityStatus: AvailabilityStatusModel) => {
        if (_.isEmpty(availabilityStatus)) {
          return this._fetchAvailabilityStatus(forUserEmail, availabilityStatusId);
        }
        return of(availabilityStatus);
      }),
    );
  }

  protected _fetchAllAvailabilityStatuses(forUserEmail: string): Observable<AvailabilityStatusModel[]> {
    let watch = new StopWatch(this.constructorName + '._fetchAllStatuses', ProcessType.SERVICE, forUserEmail);

    watch.log('Starting availability status sync');
    return this._availabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusList({}, forUserEmail).pipe(
      mergeMap((response: AvailabilityStatusList) => {
        watch.log('Processing response of size: ' + response.availabilityStatuses.size);

        return of(AvailabilityStatusModel.createList(response.availabilityStatuses.resources));
      }),
    );
  }

  protected _fetchAvailabilityStatus(
    forUserEmail: string,
    availabilityStatusId: string,
  ): Observable<AvailabilityStatusModel> {
    let watch = new StopWatch(this.constructorName + '._fetchAvailabilityStatus', ProcessType.SERVICE, forUserEmail);

    watch.log('Fetching availability status');
    return this._availabilityStatusApiService
      .AvailabilityStatus_GetAvailabilityStatus({ id: availabilityStatusId }, forUserEmail)
      .pipe(
        mergeMap((response: AvailabilityStatus) => {
          watch.log('Processing response');

          return of(AvailabilityStatusModel.create(response));
        }),
      );
  }

  ////////////////
  // DAO WRAPPERS
  ////////////////
  findById(forUserEmail: string, availabilityStatusId: string): Observable<AvailabilityStatusModel> {
    return this._availabilityStatusDaoService.findById(forUserEmail, availabilityStatusId);
  }

  removeCollection(forUserEmail): Observable<any> {
    return this._availabilityStatusDaoService.removeCollection(forUserEmail);
  }
}
