import { BaseDaoService } from '../base/base-dao.service';
import { Tag } from '@shared/api/api-loop/models';
import { TagLabelModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { Observable } from 'rxjs';

export interface TagDaoServiceI {
  findExclusiveTag(forUserEmail: string, tags: Tag[]): Observable<TagModel>;
}

export abstract class TagDaoService extends BaseDaoService<TagModel, Tag> implements TagDaoServiceI {
  abstract findAllLabelTags(forUserEmail: string): Observable<TagLabelModel[]>;
  abstract findExclusiveTag(forUserEmail: string, tags: Tag[]): Observable<TagModel>;
}
