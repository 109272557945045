import { Injectable, NgModule } from '@angular/core';
import { CardViewDecorateService } from './card-view-decorator/card-view-decorate.service';
import { CommentViewDecorateService } from './comment-view-decorator/comment-view-decorate.service';
import { ContactViewDecorateService } from './contact-view-decorator/contact-view-decorate.service';
import { FileViewDecorateService } from './file-view-decorator/file-view-decorate.service';
import { SharedTagViewDecorateService } from './shared-tag-view-decorator/shared-tag-view-decorate.service';

@Injectable()
export class ViewDecoratorService {
  constructor(
    public CardDecorateService: CardViewDecorateService,
    public CommentDecorateService: CommentViewDecorateService,
    public ContactDecorateService: ContactViewDecorateService,
    public FileDecorateService: FileViewDecorateService,
    public SharedTagDecorateService: SharedTagViewDecorateService,
  ) {}
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ViewDecoratorService,
    CardViewDecorateService,
    CommentViewDecorateService,
    ContactViewDecorateService,
    FileViewDecorateService,
    SharedTagViewDecorateService,
  ],
})
export class ViewDecoratorModule {}
