import * as URI from 'uri-js/dist/es5/uri.all.js';
import { Injectable, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { DeepLinksService, DeepLinksServiceI } from '@shared/services/deeplinks/deeplinks.service';
import { deeplinkRoute } from 'web/app/web-routing.module';
import { ContactModel, GroupModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { Observable, of } from 'rxjs';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@AutoUnsubscribe()
@Injectable()
export class DeepLinksServiceWeb extends DeepLinksService implements DeepLinksServiceI, OnDestroy {
  protected redirectToDefaultView() {
    this._redirectService.navigateToDefaultView();
  }

  protected findOrFetchContactById(forUserEmail: string, contactId: string): Observable<ContactModel> {
    // No database on WEB so we mock contact
    if (!contactId) {
      return of(undefined);
    }

    let mockedContact: ContactModel;
    if (contactId.startsWith('user')) {
      mockedContact = new UserModel();
    } else {
      mockedContact = new GroupModel();
    }

    mockedContact.id = contactId;
    mockedContact._id = contactId;

    return of(mockedContact);
  }

  protected navigateToChannel(
    contact: ContactModel,
    conversation?: ConversationModel,
    tab?: string,
    queryParams?: object
  ) {
    // Don't quick jump on Web as no data is preloaded. Use hard redirects
    super.navigateToChannel(contact, conversation, tab || contact instanceof UserModel ? 'chat' : 'email', queryParams);
  }

  protected exposeDeeplinkTrigger() {
    window['loopExternalDeeplinkTrigger'] = (type: string) => {
      switch (type) {
        case 'add-new-shared-inbox':
          this.onAddNewSharedInbox();
          return;
        default:
          return;
      }
    };
  }
}
