import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'svg-comp',
  templateUrl: './svg.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent {
  @Input() src: any;
}
