import { Component, Input } from '@angular/core';

@Component({
  selector: 'skeleton-loader',
  templateUrl: './skeleton-loader.html',
  styleUrls: ['./skeleton-loader.scss'],
})
export class SkeletonLoaderComponent {
  @Input() style: 'comment' = 'comment';
}
