import {
  ComponentRef,
  createNgModule,
  Directive,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  Type,
  ViewContainerRef
} from '@angular/core';
import { writeComponentRefChanges } from '@shared/utils/write-component-ref-changes';

@Directive({
  selector: '[loopComponentRenderer]'
})
export class ComponentRendererDirective<T> implements OnInit {
  private readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  private readonly injector: Injector = inject(Injector);

  @Input() component!: Type<T>;
  @Input() module!: Type<any>;
  @Input() componentInjector?: Injector;
  @Input() componentParams?: Partial<T>;
  @Output() componentRef: EventEmitter<ComponentRef<T>> = new EventEmitter<ComponentRef<T>>();

  ngOnInit(): void {
    const componentRef = this.viewContainerRef.createComponent(this.component, {
      ngModuleRef: createNgModule(this.module, this.componentInjector ?? this.injector),
      injector: this.componentInjector ?? this.injector
    });

    if (this.componentParams) {
      writeComponentRefChanges(componentRef, this.componentParams as Partial<T>);
    }
    this.componentRef.emit(componentRef);
  }
}
