import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { Observable, tap } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { BaseDaoService } from '@shared/database/dao/base/base-dao.service';
import { DatabaseServiceWeb } from '../../database.service.web';
import { CollectionNameWeb } from '../../database-schema';

@Injectable()
export abstract class BaseDaoServiceWeb<T extends BaseModel, B extends ResourceBase> extends BaseDaoService<T, B> {
  abstract get collectionName(): CollectionNameWeb;

  getAll(forUserEmail: string): Observable<T[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.getAll(this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  saveAll(forUserEmail: string, models: T[]): Observable<T[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return this.doBeforeSave(forUserEmail, models).pipe(
          mergeMap((_models: T[]) => {
            let clonedInstanceOfModels = _.cloneDeep(_models);
            let dbObjects = _.map(clonedInstanceOfModels, model => model.toObject());

            return db.insertAll(dbObjects, this.collectionName).pipe(map(() => clonedInstanceOfModels));
          }),
        );
      }),
    );
  }

  findById(forUserEmail: string, id: string): Observable<T> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findById(id, this.collectionName);
      }),
      map((doc: B) => this.toModel(doc)),
      mergeMap(doc => this.populate(forUserEmail, [doc])),
      map(_.first),
    );
  }

  findByIds(forUserEmail: string, ids: string[]): Observable<T[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIds(ids, this.collectionName);
      }),
      map((docs: B[]) => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  findBaseByIds(forUserEmail: string, entities: B[]): Observable<B[]> {
    let ids = _.map(entities, e => e.id);
    return this.findByIds(forUserEmail, ids) as any as Observable<B[]>;
  }

  findSyncedByIds(forUserEmail: string, entities: B[]): Observable<T[]> {
    let ids = _.map(entities, e => e.id);
    return this.findByIds(forUserEmail, ids);
  }

  protected removeAllInCollection(forUserEmail: string): Observable<any> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.removeAll(this.collectionName);
      }),
    );
  }

  removeAll(forUserEmail: string, models: T[]): Observable<T[]> {
    let ids = _.map(models, model => model.id);
    return this.removeByIds(forUserEmail, ids).pipe(map(() => models));
  }

  removeById(forUserEmail: string, id: string): Observable<any> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.removeById(id, this.collectionName);
      }),
    );
  }

  removeAllByIndex(
    forUserEmail: string,
    indexName: string,
    indexValue: string,
    collection: CollectionNameWeb,
  ): Observable<any> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.removeAllByIndex(indexName, indexValue, collection);
      }),
    );
  }

  removeByIds(forUserEmail: string, ids: string[]): Observable<any> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.removeByIds(ids, this.collectionName);
      }),
    );
  }

  removeCollection(forUserEmail: string): Observable<any> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.removeAll(this.collectionName);
      }),
    );
  }
}
