import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignDropdownComponent } from '@dta/ui/components/common/assign-dropdown/assign-dropdown.component';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';
import { CustomInputModule } from '@dta/ui/components/common/custom-input/custom-input.module';

@NgModule({
  declarations: [AssignDropdownComponent],
  imports: [CommonModule, GraphicsModule, TooltipModule, CustomInputModule],
  exports: [AssignDropdownComponent],
})
export class AssignDropdownModule {}
