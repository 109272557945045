import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { DropdownModule } from '@shared/ui/dropdown/dropdown.module';
import { ContactsApiCacheModule } from '@shared/modules/contacts/shell/contacts-api-cache/contacts-api-cache.module';
import { ConversationHeaderDropdownComponent } from './conversation-header-dropdown.component';

@NgModule({
  declarations: [ConversationHeaderDropdownComponent],
  imports: [CommonModule, GraphicsModule, DropdownModule, ContactsApiCacheModule],
  exports: [ConversationHeaderDropdownComponent]
})
export class ConversationHeaderDropdownModule {}
