import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  Optional,
  TemplateRef
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { EnumObjectValue } from '@shared/utils/common/types';
import { map } from 'rxjs/operators';
import { AtomDirective } from '@shared/modules/color-scheme/atom/atom.directive';

@UntilDestroy()
@Component({
  selector: 'loop-alert[loopAtom]',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  @Input() heading?: string;
  @Input() content?: string | TemplateRef<void>;
  @Input() isDismissible: boolean = false;

  protected readonly icon$?: Observable<string> = this.atomDirective?.atom$.pipe(
    map(atom => (atom === 'success' ? 'check-circle' : atom === 'danger' ? 'circle-xmark' : 'alert-circle'))
  );

  protected isVisible: boolean = true;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    @Optional() @Inject(AtomDirective) private readonly atomDirective?: AtomDirective
  ) {}

  closeAlert(): void {
    this.isVisible = false;
    this.changeDetector.detectChanges();
  }
}
