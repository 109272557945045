import clip from 'text-clipper';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Logger } from '@shared/services/logger/logger';

@Injectable()
export class BodyClippingService {
  getClippedBody(
    body: string,
    commentId: string,
    clippingPurpose: 'reply-history' | 'view',
  ): { body: string; wasBodyClipped: boolean } {
    if (!body || body?.length < CONSTANTS.BODY_CLIPPING_LIMIT) {
      return { body, wasBodyClipped: false };
    }

    let clippedBody = clip(body, CONSTANTS.BODY_CLIPPING_LIMIT, { html: true, maxLines: 500, indicator: '' }) || '';
    let wasBodyClipped = body.length !== clippedBody.length;

    if (wasBodyClipped) {
      Logger.logCommentClipping(
        `Comment was clipped for ${clippingPurpose}. Comment was ${body.length} characters long. Clipped to ${clippedBody.length}`,
        commentId,
        body.length,
        clippedBody.length,
      );
    }

    return { body: clippedBody, wasBodyClipped };
  }
}
