import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { from, Observable, of } from 'rxjs';
import { concatMap, flatMap, tap, toArray } from 'rxjs/operators';
import { NotificationEventProgress, NotificationEventType } from '../../../shared/models/notifications.model';
import { TimeEvent } from '../../../shared/services/tracking/tracking.constants';
import { TrackingService } from '../../../shared/services/tracking/tracking.service';
import { NotificationsService } from '@shared/services/notification/notification.service';

@Injectable()
export class BatchProcessingService {
  private subBatchSize: number = 20;

  constructor(
    private _notificationsService: NotificationsService,
    private _trackingService: TrackingService,
  ) {}

  /**
   * Function for running a function in batches
   * IMPORTANT: Make sure the function you want to batch-run has parameters in correct order.
   *      1. parameter = forUserEmail
   *      2. parameter = parameter you want to process in batches
   *      3. up to 6 additional parameters
   *
   *
   */
  batchProcessor<T>(
    forUserEmail: string,
    _function: (
      forUserEmail: string,
      iterArg: any[],
      opt1?: any,
      opt2?: any,
      opt3?: any,
      opt4?: any,
      opt5?: any,
      opt6?: any,
    ) => Observable<T[]>,
    iterArgument: any[],
    batchSize: number,
    context: any,
    progressNotificationMsg: string,
    otherArgs: any[],
  ): Observable<T[]> {
    if (iterArgument.length === 0) {
      return of(<T[]>[]);
    }

    let chunkedArray = _.chunk(iterArgument, batchSize);
    let itemsCount = iterArgument.length;
    let itemsLeft = iterArgument.length;
    let completedChunks = 0;

    // Track and show on UI
    this._trackingService.timeEventStart(forUserEmail, TimeEvent.batchAction);

    // Process first batch in smaller batches for quicker feedback for user
    let subChunks = _.chunk(chunkedArray[0], this.subBatchSize);
    chunkedArray = _.concat(subChunks, _.slice(chunkedArray, 1));

    this.updateProgressNotification(forUserEmail, itemsCount, itemsLeft, progressNotificationMsg);

    return from(chunkedArray).pipe(
      /**
       * Process chunks
       */
      concatMap((chunk: any[]) => {
        itemsLeft = itemsLeft - chunk.length;
        return <Observable<T[]>>_function.call(context, forUserEmail, chunk, ...otherArgs);
      }),
      /**
       * Update progress for each
       */
      tap(() => {
        completedChunks++;
        this.updateProgressNotification(forUserEmail, itemsCount, itemsLeft, progressNotificationMsg);
      }),
      flatMap((cards: T[]) => {
        return from(cards);
      }),
      /**
       * Wait for all of them to complete
       */
      toArray(),
      /**
       * Track
       */
      tap(() => {
        this._trackingService.timeEventMark(forUserEmail, TimeEvent.batchAction, itemsCount, progressNotificationMsg);
      }),
    );
  }

  private updateProgressNotification(forUserEmail: string, itemsTotal: number, itemsLeft: number, message: string) {
    if (_.isEmpty(message)) {
      return;
    }

    let msg = '';

    if (itemsLeft > 0) {
      msg = message.substring(0, message.indexOf(' ')) + ' ' + itemsLeft + message.substring(message.indexOf(' '));

      if (itemsLeft === 1) {
        msg = msg.replace('items', 'item');
      }
    } else {
      msg =
        'Finished ' +
        message.substring(0, message.indexOf(' ')).toLowerCase() +
        ' ' +
        itemsTotal +
        message.substring(message.indexOf(' '));

      if (itemsTotal === 1) {
        msg = msg.replace('items', 'item');
      }
    }

    this._notificationsService.setInAppNotification(forUserEmail, {
      type: NotificationEventType.ProgressUpdate,
      msg: msg,
      progressGoal: itemsTotal,
      progressState: itemsTotal - itemsLeft,
    } as NotificationEventProgress);
  }
}
