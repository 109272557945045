<div #contextMenu class="context-menu" [class.hidden]="!opened">
  <div *ngIf="opened">
    <ng-template ngFor let-option [ngForOf]="options" let-i="index">
      <ng-template [ngIf]="!option.subsection" [ngIfElse]="subsection">
        <div class="option" (click)="onOptionClick(option)">
          {{ option.option }}
        </div>
      </ng-template>

      <ng-template #subsection>
        <div class="subsection-wrapper">
          <div class="subsection-title">
            {{ option.option }}
          </div>
          <ng-template ngFor let-subOption [ngForOf]="option.subsection.options" let-i="index">
            <div class="option" (click)="onOptionClick(subOption)">
              {{ subOption.option }}
              <div class="after-option">
                <svg-comp *ngIf="subOption.selected" [src]="checkmarkSvg"></svg-comp>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
