import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { SynchronizableModel } from '@shared/models/sync/synchronizable.model';
import { BaseModel } from '../../../dta/shared/models-api-loop/base/base.model';
import { RetryModel } from '../../../dta/shared/models-api-loop/retry.model';
import { tap } from 'rxjs/operators';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { ActionEnum } from '@shared/api/api-loop/models/action-enum';

@Injectable()
export class SynchronizationMiddlewareService {
  /////////////
  // Subjects
  /////////////
  enqueuePushSynchronization$: Subject<PushSyncData> = new Subject();
  dequeuePushSynchronization$: Subject<PushSyncData> = new Subject();
  enqueueRetrySynchronization$: Subject<RetrySyncData> = new Subject();
  dequeueRetrySynchronization$: Subject<RetrySyncData> = new Subject();

  allBlockingSyncDone$: Subject<{ forUserEmail: string }> = new Subject();

  /////////////////
  // Subscriptions
  /////////////////
  private allBlockingSyncDoneSub: Subscription;

  constructor(private _storageService: StorageService) {
    this.subscribeToAllBlockingSyncDone();
  }

  private subscribeToAllBlockingSyncDone() {
    this.setSyncStateToDefault();

    this.allBlockingSyncDoneSub?.unsubscribe();
    this.allBlockingSyncDoneSub = this.allBlockingSyncDone$
      .pipe(
        /**
         * Mark allBlockingSync as done
         */
        tap((data: { forUserEmail: string }) => {
          this.setAllBlockingSyncDone(data.forUserEmail);
        }),
      )
      .subscribe();
  }

  private setSyncStateToDefault() {
    Object.keys(localStorage).forEach(key => {
      if (key.includes(StorageKey.internalSyncData)) {
        this._storageService.removeItem(key);
      }
    });
  }

  private setAllBlockingSyncDone(forUserEmail: string) {
    let key = SynchronizationMiddlewareService.syncStateKey(forUserEmail);
    let userSyncStatus = this._storageService.getParsedItem(key) || ({} as StateOfSyncServices);
    userSyncStatus.allBlockingSyncDone = true;

    this._storageService.setStringifiedItem(key, userSyncStatus);
  }

  private static syncStateKey(forUserEmail: string): string {
    return `${forUserEmail}_${StorageKey.internalSyncData}`;
  }

  static allBlockingPrefetchActionsDone(userEmail: string): boolean {
    let key = SynchronizationMiddlewareService.syncStateKey(userEmail);
    let lsItem = localStorage[key];
    let userSyncStatus = lsItem ? JSON.parse(lsItem) : ({} as StateOfSyncServices);
    return !!userSyncStatus.allBlockingSyncDone;
  }
}

export interface PushSyncData {
  models: SynchronizableModel | SynchronizableModel[];
  forUserEmail?: string;
}

export interface RetrySyncData {
  models?: RetryModel | RetryModel[];
  data?: BaseModel | BaseModel[];
  forUserEmail?: string;
}

interface StateOfSyncServices {
  allBlockingSyncDone: boolean;
}
