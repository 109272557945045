import { inject } from '@angular/core';
import { BaseApiCacheService } from '@shared/api/api-cache/base-api-cache.service';
import { CommandApiService } from '@shared/api/api-loop/services/command-api.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { RawCommand } from '@shared/api/api-loop/models/raw-command';
import { SmartCommand } from '@shared/modules/smart-commands/common/interfaces/smart-command';
import { EnumObjectValue } from '@shared/utils/common/types';
import { SmartCommandModuleName } from '@shared/modules/smart-commands/common/constants/smart-command-module-name';
import { SMART_COMMAND_TYPE } from '@shared/modules/smart-commands/common/constants/smart-commant-type';
import {
  PayloadForResponse,
  SMART_METHODS
} from '@shared/modules/smart-commands/common/constants/method-to-payload-response-mapper';
import { RawCommandResponse } from '@shared/api/api-loop/models/raw-command-response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export abstract class SmartCommandsApiService extends BaseApiCacheService {
  private readonly commandApiService: CommandApiService = inject(CommandApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  protected abstract moduleName: EnumObjectValue<typeof SmartCommandModuleName>;

  protected sendSmartCommandRequest<METHOD = EnumObjectValue<SMART_METHODS>>(
    request: Omit<SmartCommand<METHOD>, 'module' | 'type'>
  ): Observable<PayloadForResponse<METHOD>> {
    const fullRequest: SmartCommand<METHOD> = {
      ...request,
      module: this.moduleName,
      type: SMART_COMMAND_TYPE
    };

    const rawCommand: RawCommand = {
      $type: 'RawCommand',
      request: `//JSON:${JSON.stringify(fullRequest)}`
    };
    return this.commandApiService
      .Command_RunCommand({ command: rawCommand }, this.userManagerService.getCurrentUserEmail())
      .pipe(
        map((response: RawCommandResponse) => {
          return JSON.parse(response.rawResponse);
        })
      );
  }
}
