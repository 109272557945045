/**
 * Get the caret position, relative to the window
 * @returns {object} left, top distance in pixels
 */
export function getCaretGlobalPosition(): { left: number; top: number; bottom: number } {
  const r = document.getSelection().getRangeAt(0);
  const node = r.startContainer;
  const offset = r.startOffset;
  const pageOffset = { x: window.pageXOffset, y: window.pageYOffset };
  let rect, r2;

  if (offset > 0) {
    r2 = document.createRange();
    r2.setStart(node, offset - 1);
    r2.setEnd(node, offset);
    rect = r2.getBoundingClientRect();
    return { left: rect.right + pageOffset.x, top: rect.bottom + pageOffset.y, bottom: rect.bottom };
  }

  return { left: 0, top: 0, bottom: 0 };
}
