import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BBCodePipe } from './bbcode.pipe';

@NgModule({
  declarations: [BBCodePipe],
  imports: [CommonModule],
  exports: [BBCodePipe],
})
export class BbcodeModule {}
