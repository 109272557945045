import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { SelectControlComponent } from '@shared/modules/form-controls/select-control/select-control.component';
import { FormControlWrapperModule } from '@shared/modules/form-controls/base/form-control-wrapper/form-control-wrapper.module';
import { AsArrayPipe } from '@shared/modules/form-controls/select-control/as-array.pipe';
import { ContainsPipe } from '@shared/modules/form-controls/select-control/contains.pipe';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { TagModule } from '@shared/ui/tag/tag.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { SelectItemComponent } from '@shared/modules/form-controls/select-control/internal/select-item.component';
import { OutsideClickModule } from '@dta/ui/directives/outside-click/outside-click.module';

@NgModule({
  declarations: [SelectControlComponent, AsArrayPipe, ContainsPipe, SelectItemComponent],
  imports: [
    CommonModule,
    FormControlWrapperModule,
    OutsideClickModule,
    GraphicsModule,
    TagModule,
    IconModule,
    TextControlModule,
    FormsModule,
    OverlayModule
  ],
  exports: [SelectControlComponent]
})
export class SelectControlModule {}
