/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Tag } from '../models/tag';
import { Response } from '../models/response';
import { ResponseConflict } from '../models/response-conflict';
import { ListOfResourcesOfTag } from '../models/list-of-resources-of-tag';
import { ListOfTags } from '../models/list-of-tags';
import { ListOfResourcesOfTagLabel } from '../models/list-of-resources-of-tag-label';

@Injectable()
export class TagApiService extends BaseService {
  /**
   * @param params The `TagApiService.Tag_CreateTagParams` containing the following parameters:
   *
   * - `tag`: Tag object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_CreateTagResponse(
    params: TagApiService.Tag_CreateTagParams,
    forUserEmail: string
  ): Observable<HttpResponse<Tag>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/tag`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Tag_CreateTag');

    // body, headers and query params
    __body = params.tag;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Tag;

          body = <Tag>response.body;

          return <HttpResponse<Tag>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TagApiService.Tag_CreateTagParams` containing the following parameters:
   *
   * - `tag`: Tag object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_CreateTag(
    params: TagApiService.Tag_CreateTagParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Tag> {
    const observable$ = this.Tag_CreateTagResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/tag`;
      const url = this.rootUrl + `/api/v1/tag`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TagApiService.Tag_GetParams` containing the following parameters:
   *
   * - `id`: Tag id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_GetResponse(params: TagApiService.Tag_GetParams, forUserEmail: string): Observable<HttpResponse<Tag>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/tag/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Tag_Get');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Tag;

          body = <Tag>response.body;

          return <HttpResponse<Tag>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TagApiService.Tag_GetParams` containing the following parameters:
   *
   * - `id`: Tag id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_Get(params: TagApiService.Tag_GetParams, forUserEmail: string, cacheEnabled?: boolean): Observable<Tag> {
    const observable$ = this.Tag_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/tag/${params.id}`;
      const url = this.rootUrl + `/api/v1/tag/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TagApiService.Tag_UpdateTagParams` containing the following parameters:
   *
   * - `tag`: Updated tag object
   *
   * - `id`: Tag id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_UpdateTagResponse(
    params: TagApiService.Tag_UpdateTagParams,
    forUserEmail: string
  ): Observable<HttpResponse<Tag>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/tag/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Tag_UpdateTag');

    // body, headers and query params
    __body = params.tag;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Tag;

          body = <Tag>response.body;

          return <HttpResponse<Tag>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TagApiService.Tag_UpdateTagParams` containing the following parameters:
   *
   * - `tag`: Updated tag object
   *
   * - `id`: Tag id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_UpdateTag(
    params: TagApiService.Tag_UpdateTagParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Tag> {
    const observable$ = this.Tag_UpdateTagResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/tag/${params.id}`;
      const url = this.rootUrl + `/api/v1/tag/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TagApiService.Tag_DeleteTagParams` containing the following parameters:
   *
   * - `id`: Tag id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_DeleteTagResponse(
    params: TagApiService.Tag_DeleteTagParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/tag/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Tag_DeleteTag');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TagApiService.Tag_DeleteTagParams` containing the following parameters:
   *
   * - `id`: Tag id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_DeleteTag(
    params: TagApiService.Tag_DeleteTagParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Tag_DeleteTagResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/tag/${params.id}`;
      const url = this.rootUrl + `/api/v1/tag/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TagApiService.Tag_GetListOfTagsParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_GetListOfTagsResponse(
    params: TagApiService.Tag_GetListOfTagsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfTag>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/tag/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Tag_GetListOfTags');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfTag;

          body = <ListOfResourcesOfTag>response.body;

          return <HttpResponse<ListOfResourcesOfTag>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TagApiService.Tag_GetListOfTagsParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_GetListOfTags(
    params: TagApiService.Tag_GetListOfTagsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfTag> {
    const observable$ = this.Tag_GetListOfTagsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/tag/list`;
      const url = this.rootUrl + `/api/v1/tag/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TagApiService.Tag_UpdateTagsParams` containing the following parameters:
   *
   * - `tags`: List of resources that need to be modified
   *
   * - `processAsync`: If true, backend will process the request asynchrounosly and return HTTP accepted if everything is OK with request
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_UpdateTagsResponse(
    params: TagApiService.Tag_UpdateTagsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfTags[]>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/tag/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Tag_UpdateTags');

    // body, headers and query params
    __body = params.tags;
    if (params.processAsync != null) __params = __params.set('processAsync', params.processAsync.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfTags[];

          body = <ListOfTags[]>response.body;

          return <HttpResponse<ListOfTags[]>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TagApiService.Tag_UpdateTagsParams` containing the following parameters:
   *
   * - `tags`: List of resources that need to be modified
   *
   * - `processAsync`: If true, backend will process the request asynchrounosly and return HTTP accepted if everything is OK with request
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_UpdateTags(
    params: TagApiService.Tag_UpdateTagsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfTags[]> {
    const observable$ = this.Tag_UpdateTagsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/tag/list`;
      const url = this.rootUrl + `/api/v1/tag/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TagApiService.Tag_GetUserLabelsParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_GetUserLabelsResponse(
    params: TagApiService.Tag_GetUserLabelsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfTagLabel>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/tag/labels`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Tag_GetUserLabels');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfTagLabel;

          body = <ListOfResourcesOfTagLabel>response.body;

          return <HttpResponse<ListOfResourcesOfTagLabel>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TagApiService.Tag_GetUserLabelsParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Tag_GetUserLabels(
    params: TagApiService.Tag_GetUserLabelsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfTagLabel> {
    const observable$ = this.Tag_GetUserLabelsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/tag/labels`;
      const url = this.rootUrl + `/api/v1/tag/labels`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module TagApiService {
  /**
   * Parameters for Tag_CreateTag
   */
  export type Tag_CreateTagParams = {
    /**
     * Tag object
     */
    tag: Tag;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Tag_Get
   */
  export type Tag_GetParams = {
    /**
     * Tag id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Tag_UpdateTag
   */
  export type Tag_UpdateTagParams = {
    /**
     * Updated tag object
     */
    tag: Tag;

    /**
     * Tag id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Tag_DeleteTag
   */
  export type Tag_DeleteTagParams = {
    /**
     * Tag id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Tag_GetListOfTags
   */
  export type Tag_GetListOfTagsParams = {
    /**
     * Paging size
     */
    size?: number;

    /**
     * Paging offset
     */
    offset?: number;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Tag_UpdateTags
   */
  export type Tag_UpdateTagsParams = {
    /**
     * List of resources that need to be modified
     */
    tags: ListOfTags[];

    /**
     * If true, backend will process the request asynchrounosly and return HTTP accepted if everything is OK with request
     */
    processAsync?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Tag_GetUserLabels
   */
  export type Tag_GetUserLabelsParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
