import { inject, Injectable } from '@angular/core';
import { SettingsApiService } from '@shared/api/api-loop/services/settings-api.service';
import { EnumObjectValue } from '@shared/utils/common/types';
import { SettingsScope } from '@shared/modules/auth/settings/common/settings-scope';
import { ScopeSettings } from '@shared/api/api-loop/models/scope-settings';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import {
  ConversationSettingsScopeProperty,
  MailCommentAttachmentPosition
} from '@shared/modules/auth/settings/common/conversation-settings-scope';
import { AISettingsScopeProperty } from '@shared/modules/auth/settings/common/ai-settings-scope';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable, startWith, combineLatest, of } from 'rxjs';
import { InboxBadgeCountType, InboxSettingsScopeProperty } from '../settings/common/inbox-settings-scope';

interface CurrentSession {
  userConfiguration: Record<EnumObjectValue<typeof SettingsScope>, any>;
}

function convertStringsToValues(obj: Record<string, string>): Record<string, any> {
  let newObj: Record<string, any> = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];
      if (value === 'true') {
        newObj[key] = true;
      } else if (value === 'false') {
        newObj[key] = false;
      } else if (!isNaN(Number(value))) {
        newObj[key] = Number(value);
      } else {
        newObj[key] = value;
      }
    }
  }
  return newObj;
}

@Injectable()
export class UserAuthService {
  private readonly settingsApiService: SettingsApiService = inject(SettingsApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  readonly currentSession$: BehaviorSubject<CurrentSession> = new BehaviorSubject<CurrentSession>({
    userConfiguration: {
      [SettingsScope.conversation]: {
        [ConversationSettingsScopeProperty.is_first_unread_viewport]: true,
        [ConversationSettingsScopeProperty.mail_comment_attachment_position]: MailCommentAttachmentPosition.bottom,
        [ConversationSettingsScopeProperty.are_attachments_previews_shown]: false,
        [ConversationSettingsScopeProperty.should_open_attachments_in_native_apps]: false,
        [ConversationSettingsScopeProperty.is_email_history_expanded_on_replies]: false
      },
      [SettingsScope.AI]: {
        [AISettingsScopeProperty.default_translate_language]: 'en'
      },
      [SettingsScope.inbox]: {
        [InboxSettingsScopeProperty.inbox_badge]: InboxBadgeCountType.unreadBadge
      }
    }
  });

  constructor() {
    this.observeSettingsChange();
  }

  private observeSettingsChange(): void {
    SharedSubjects._settingsChange$
      .pipe(
        startWith(undefined),
        switchMap(() => this.loadCurrentSession())
      )
      .subscribe();
  }

  loadCurrentSession(): Observable<void> {
    return combineLatest([
      this.settingsApiService
        .Settings_GetScopeSettings({ scope: SettingsScope.conversation }, this.userManagerService.getCurrentUserEmail())
        .pipe(
          catchError(() => {
            return of({});
          })
        ),
      this.settingsApiService
        .Settings_GetScopeSettings({ scope: SettingsScope.AI }, this.userManagerService.getCurrentUserEmail())
        .pipe(
          catchError(() => {
            return of({});
          })
        ),
      this.settingsApiService
        .Settings_GetScopeSettings({ scope: SettingsScope.inbox }, this.userManagerService.getCurrentUserEmail())
        .pipe(
          catchError(() => {
            return of({});
          })
        )
    ]).pipe(
      tap(
        ([scopeConversationSettings, scopeAISettings, inboxSettings]: [
          ScopeSettings,
          ScopeSettings,
          ScopeSettings
        ]) => {
          this.currentSession$.next({
            userConfiguration: {
              [SettingsScope.conversation]: convertStringsToValues(scopeConversationSettings.keyValues),
              [SettingsScope.AI]: convertStringsToValues(scopeAISettings.keyValues),
              [SettingsScope.inbox]: convertStringsToValues(inboxSettings.keyValues)
            }
          });
        }
      ),
      map(() => undefined),
      catchError(() => {
        this.currentSession$.next({
          userConfiguration: {
            [SettingsScope.conversation]: {},
            [SettingsScope.AI]: {},
            [SettingsScope.inbox]: {}
          }
        });
        return EMPTY;
      }),
      take(1)
    );
  }
}
