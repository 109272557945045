export const ClickTrackingLocation = {
  // Views
  AllSharedInboxes: 'All Shared Inboxes',
  MyLoopInbox: 'My loop inbox',
  Chats: 'Chats',
  Assigned: 'Assigned',
  Starred: 'Starred',
  PersonalInbox: 'Personal Inbox',
  Agenda: 'Agenda',
  Outbox: 'Outbox',
  Files: 'Files',
  Sidebar: 'Sidebar',
  Themes: 'Themes',
  Settings: 'Settings',
  Default: 'Default',
  GettingStarted: 'Getting Started',
  Converastions: 'Converastions',

  // Channels
  Channel: 'Channel',
  Favorites: 'Favorites',
  SharedInbox: 'Shared inbox',
  Teams: 'Teams',
  People: 'People',
  ChannelHeader: 'Channel Header',
  TagsDropdown: 'Tags dropdown',

  // Search
  Search: 'Search',
  GlobalSearch: 'Global search',
  ContextualSearch: 'Contextual search',
  ViewSearch: 'View search',

  // Folders
  Sent: 'Sent',
  Drafts: 'Drafts',
  Archive: 'Archive',
  Trash: 'Trash',
  UserFolder: 'Folder',

  // Dialogs
  ComposerDialog: 'Composer dialog',
  PopOutDialog: 'Pop-out dialog',
  QuickReply: 'Quick reply',
  ShortcutsDialog: 'Shortcuts dialog',

  // Settings
  CannedResponses: 'Canned responses',
  AccountSettings: 'Account settings',
  MyLoopSettings: 'My Loop settings',

  // Toast message
  ToastMessage: 'ToastMessage',

  // Other/general
  ThreadHeader: 'Thread header',
  ChannelFilterDropdown: 'Channel filter dropdown',
  SnoozeDropdown: 'Snooze dropdown',
  AccountDropdown: 'Account dropdown',
  SharedInboxSetup: 'Shared inbox setup',
  PrivateInboxSetup: 'Private inbox setup',
  StatusesSetup: 'Statuses setup',
  SharedTagLabelSetup: 'Shared tag label setup',
  TeamSetup: 'Team setup'
};

export enum TrackingConstants {
  // Session
  sessionAppOpened = 'Opened app',

  // User

  /*
     Mixpanel special people properties.
     https://mixpanel.com/help/reference/http#people-special-properties
     */
  userName = '$name',
  userEmail = '$email',
  userTimeRegistered = '$created',
  mixpanelDateFormat = 'YYYY-MM-DDThh:mm:ss',

  userIsTestUser = 'TestUser',
  userTimezone = 'UserTimezone',
  deviceId = 'deviceId',

  userLanguage = 'UserLanguage',
  userPushNotificationsEnabled = 'PushNotificationsEnabled',

  // Attribution
  userUtmCampaign = 'UtmCampaign',
  userUtmMedium = 'UtmMedium',
  userUtmSource = 'UtmSource',

  // Super properties
  superIsOffline = 'Offline',
  superDeviceIdentifier = 'DeviceIdentifier',

  // Incremental properties
  incrementalAppOpened = 'AppOpened',
  incrementalMessagesSent = 'MessagesSent',
  incrementalBoardsCreated = 'BoardsCreated',
  incrementalGroupsCreated = 'GroupsCreated',
  incrementalPinnedMessages = 'PinnedMessages',
  incrementalFavoritesAdded = 'FavoritesAdded',

  // View opened (navigation)
  navigationEventName = 'View opened',
  navigationView = 'View',
  navigationOrigin = 'Origin',
  navigationAction = 'Action',

  // Pin/unpin
  pinUnpinEventName = 'Pin/Unpin',
  pinUnpinType = 'Type',
  pinUnpinAutomatic = 'Automatic',
  pinUnpinSystemBoard = 'SystemBoard',

  // New message
  newMessageEventName = 'New message',
  newMessageType = 'messageType',
  newMessageFile = 'file',
  newMessageIncludedUsers = 'includedUsers',
  newMessageIncludedCcUsers = 'includedCcUsers',
  newMessageIncludedBccUsers = 'includedBccUsers',
  newMessageSignature = 'signature',
  newMessageFromDraft = 'fromDraft',

  // New message
  userInvitedEventName = 'User Invited',

  // Search action
  searchActionEventName = 'Search action',
  searchAction = 'Action', // Select filter, Select recent search,
  searchActionFilterType = 'Filter type', // To, From,
  channelSearchAction = 'Channel search',

  // New board
  newBoardEventName = 'New board',

  // Login
  login = 'Login',
  verificationPopup = 'Email Verification Popup',

  // Onboarding
  onboardingEventName = 'Onboarding',
  onboardingTypeExtended = 'Extended',
  extendedStarted = 'extendedStarted',
  extendedSkip = 'extendedSkip',
  extendedTry = 'extendedTry',
  extendedCompleted = 'extendedCompleted',
  desktopGettingStarted = 'DesktopGettingStarted',

  // Updater
  updaterEventName = 'Upgrade',
  updaterActionShow = 'Show',
  updaterActionCompleted = 'Completed',
  updaterActionRestart = 'Restart',

  // Account action
  accountActionEventName = 'Account action',
  accountAction = 'Action', // Switch, Add, Remove,
  accountActionEmail = 'Email',

  syncSettingsUpdated = 'Sync Settings Updated',

  // Team
  teamActionEventName = 'Team Action',
  teamActionType = 'Type',
  teamActionAddedAdmins = 'Added Admins',
  teamActionRemovedAdmins = 'Removed Admins',
  teamActionAddedMembers = 'Added Members',
  teamActionRemovedMembers = 'Removed Members',

  // Trash
  trashAction = 'Trash',

  // Comment action
  commentAction = 'Comment action',
  editLastComment = 'Edit last comment',

  // Stream actions
  ContactSubscribeStatus = 'Contact subscribe status',

  readStatusChangeAction = 'Read Status Change',
  followupCreatedAction = 'Follow up created',
  followupCompletedAction = 'Follow up completed',
  folderAction = 'Folder action',
  rsvpAction = 'RSVP Action',

  // Registration metadata
  appRegistrationVersion = 'Registration App Version',
  appRegistrationPlatform = 'Registration Platform',
  updateProfileReason = 'Profile Update Reason',

  // Create resources
  create = 'Create',

  // Click event
  click = 'Click',
  shortcutAction = 'ShortcutAction',
  keyboardShortcut = 'KeyboardShortcut',
  contextMenuOpened = 'Context Menu Opened',
  menuAction = 'Menu Action',
  dropAction = 'Drop Action',
  noticeBarAction = 'Notice Bar Action',

  // View timing
  firstItemTiming = 'First item Timing',
  detailViewTiming = 'Detail view Timing',

  // Other
  trackFavoriteChange = 'Favorite Change',
  trackStarredStatusChange = 'Starred Status Change',
  trackCardMuteStatusChange = 'Card mute status change',
  archiveAction = 'Archive',

  loadMore = 'Load More',
  openLoopbot = 'Open Loopbot',
  didSeeLoopBot = 'Did see bot after registration',
  authActionEventName = 'Auth action',
  desktopSetup = 'DesktopSetup',

  conversationAction = 'Conversation Action'
}

export enum TimeEvent {
  pastSync = 'Past Sync',
  initialContactSync = 'Initial Contact Sync',
  importDrafts = 'Import Drafts',
  folderSync = 'Folder Sync',
  labelSync = 'Label Sync',
  signatureSync = 'Signature Sync',
  cannedResponseSync = 'Canned Response Sync',
  batchAction = 'Batch Action'
}

export enum MenuActionForTracking {
  newEmail = 'New Email',
  reply = 'Reply',
  forward = 'Forward',
  print = 'Print',
  learnMore = 'Learn More',
  checkForUpdates = 'Check For Updates',
  resetAccount = 'Reset Account',
  resetLoopEmailClient = 'Reset Loop Email client',
  preferences = 'Preferences',
  openApp = 'OpenApp'
}

export enum AccountActionType {
  SWITCH = 'Switch',
  LOGIN = 'Login',
  LOGOUT = 'Logout'
}

export enum AuthActionType {
  COULD_NOT_REFRESH_TOKEN = 'Could not refresh token',
  TOKEN_REVOKED = 'Token revoked',
  TOKEN_REVOKED_USER_ACTION_LOGOUT = 'Token revoked user action: logout',
  TOKEN_REVOKED_USER_ACTION_LOGIN = 'Token revoked user action: login'
}
