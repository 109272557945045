import * as _ from 'lodash';
import { EMPTY, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { BasePushSynchronizationService } from '../base-push-synchronization/base-push-synchronization.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { Logger } from '@shared/services/logger/logger';
import { map, tap } from 'rxjs/operators';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AvailabilityStatusService } from '@shared/services/data/availability-status/availability-status.service';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';

@Injectable()
export class AvailabilityStatusPushSynchronizationService extends BasePushSynchronizationService<AvailabilityStatusModel> {
  constructor(
    protected _api: ApiService,
    protected _availabilityStatusService: AvailabilityStatusService,
  ) {
    super();
  }

  get constructorName(): string {
    return 'AvailabilityStatusPushSynchronizationService';
  }

  protected synchronize(forUserEmail: string, availabilityStatus: AvailabilityStatusModel): Observable<any> {
    if (availabilityStatus.id && availabilityStatus._ex && availabilityStatus._ex.deleted) {
      return this.synchronizeDeleteStatus(forUserEmail, availabilityStatus);
    } else if (!availabilityStatus.id) {
      return this.synchronizeCreateStatus(forUserEmail, availabilityStatus);
    } else if (!availabilityStatus._ex || !availabilityStatus._ex.deleted) {
      return this.synchronizeUpdateStatus(forUserEmail, availabilityStatus);
    }
  }

  protected afterSynchronize(
    forUserEmail: string,
    availabilityStatus: AvailabilityStatusModel,
  ): Observable<AvailabilityStatusModel> {
    let obs$ = _.isEmpty(availabilityStatus)
      ? of(availabilityStatus)
      : this._availabilityStatusService.save(forUserEmail, availabilityStatus);

    return obs$.pipe(
      tap((_availabilityStatus: AvailabilityStatusModel) => {
        PublisherService.publishEvent(forUserEmail, _availabilityStatus);
      }),
    );
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    availabilityStatus: AvailabilityStatusModel,
  ): Observable<any> {
    // We lost connection and will retry
    if (err.status === 0) {
      return EMPTY;
    }

    Logger.error(
      err,
      `PushSync [${forUserEmail}]: Could not sync status, will not retry statusId: ${availabilityStatus._id}`,
    );
    return of(availabilityStatus);
  }

  private synchronizeDeleteStatus(forUserEmail: string, availabilityStatus: AvailabilityStatusModel): Observable<void> {
    return this._api.AvailabilityStatusApiService.AvailabilityStatus_DeleteAvailabilityStatus(
      { availabilityStatusId: availabilityStatus.id },
      forUserEmail,
    );
  }

  private synchronizeCreateStatus(
    forUserEmail: string,
    availabilityStatus: AvailabilityStatusModel,
  ): Observable<AvailabilityStatusModel> {
    return this._api.AvailabilityStatusApiService.AvailabilityStatus_CreateAvailabilityStatus(
      { availabilityStatus: availabilityStatus },
      forUserEmail,
    ).pipe(map(AvailabilityStatusModel.create));
  }

  private synchronizeUpdateStatus(
    forUserEmail: string,
    availabilityStatus: AvailabilityStatusModel,
  ): Observable<AvailabilityStatusModel> {
    return this._api.AvailabilityStatusApiService.AvailabilityStatus_UpdateAvailabilityStatus(
      {
        availabilityStatus: availabilityStatus,
      },
      forUserEmail,
    ).pipe(map(AvailabilityStatusModel.create));
  }
}
