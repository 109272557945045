import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { CONSTANTS } from '@shared/models/constants/constants';
import { TimedAuthApiService } from '@shared/api/api-loop/services';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { AuthBase } from '@shared/api/api-loop/models';
import { map, tap } from 'rxjs/operators';

@Injectable()
export abstract class WebOnboardingService {
  ///////////////////
  // State variables
  ///////////////////
  private webOnboardingUrl: string = CONSTANTS.WEB_ONBOARDING_URL;
  protected abstract readonly origin: string;

  /////////////
  // Subjects
  /////////////
  toggle: Subject<boolean> = new Subject();

  /////////////////
  // Subscriptions
  /////////////////
  private anonymousSubscriptions: Subscription[] = [];

  constructor(
    private _userManagerService: UserManagerService,
    private _timedAuthApiService: TimedAuthApiService,
  ) {}

  protected abstract appendAdditionalParameters(urlParams: URLSearchParams): URLSearchParams;
  protected abstract open(url: string, newTab?: Window): void;

  // For teams: /settings/teams/{team_id}?extra-params...
  openWebSettings(urlParams: URLSearchParams, extraPath = '', newTab?: Window): void {
    this.openCustom(this._userManagerService.getCurrentUserEmail(), urlParams, 'settings' + extraPath, newTab);
  }

  openWebReports(newTab?: Window): void {
    this.openCustom(
      this._userManagerService.getCurrentUserEmail(),
      new URLSearchParams(),
      'settings/workspace/reporting',
      newTab,
    );
  }

  openSharedInboxInvitation(teamId: string, teamName: string): void {
    let urlParams = new URLSearchParams({
      team_id: teamId,
      team_name: teamName,
    });

    this.openCustom(this._userManagerService.getCurrentUserEmail(), urlParams, 'team-invitations');
  }

  setupSISla(teamId: string) {
    let urlParams = new URLSearchParams({
      'team-id': teamId,
    });

    this.openCustom(this._userManagerService.getCurrentUserEmail(), urlParams, 'shared-inbox-sla');
  }

  // [!] ONLY FOR WEB ONBOARDING [!]
  openCustom(forUserEmail: string, urlParams: URLSearchParams, path: string, newTab?: Window) {
    this.anonymousSubscriptions.push(
      this.generateCustomUrlWithAuth(forUserEmail, path, urlParams)
        .pipe(
          tap((url: string) => {
            this.open(url, newTab);
          }),
        )
        .subscribe(),
    );
  }

  private generateCustomUrlWithAuth(
    forUserEmail: string,
    additionalPath: string,
    urlParams: URLSearchParams = new URLSearchParams(),
  ): Observable<string> {
    return this.getShortLivedToken(forUserEmail).pipe(
      map((response: AuthBase) => {
        let token = response.token.accessToken;
        let url = this.webOnboardingUrl;

        if (additionalPath) {
          url += '/' + additionalPath;
        }
        url += '?';

        this.appendAdditionalParameters(urlParams);
        urlParams.append('from', this.origin);
        urlParams.append('version', APP_VERSION);
        urlParams.append('access_token', token);
        urlParams.append('short_lived', 'true');

        urlParams.forEach((value, key) => {
          url += key + '=' + value + '&';
        });

        return url;
      }),
    );
  }

  public getShortLivedToken(forUserEmail: string): Observable<AuthBase> {
    let userAuth = this._userManagerService.getUserAuthByEmail(forUserEmail);

    let params: TimedAuthApiService.TimedAuth_GetTimedAuthParams = {
      Authorization: userAuth.token.accessToken,
    };

    return this._timedAuthApiService.TimedAuth_GetTimedAuth(params, forUserEmail);
  }
}
