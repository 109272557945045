import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '@shared/services/data/app-state/app-state.service';
import { ElectronService } from '@shared/services/electron/electron';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { LabelService } from '@shared/services/data/label/label.service';
import { LightBackgroundService } from '@shared/services/light-background.service/light-background.service';
import { ConversationActionService } from '@shared/services/data/conversation-action/conversation-action.service';
import { NavigationBarService } from '@shared/modules/main/navigation-bar/navigation-bar.service';
import { CommandService } from '@shared/services/data/command/command.service';
import { ConnectionStatus } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { ActionEnum } from '@shared/api/api-loop/models/action-enum';
import { createConversationDeeplink } from '@shared/modules/conversations/common/helpers/create-conversation-deeplink';
import { TagApiService } from '@shared/api/api-loop/services/tag-api.service';
import { moveToNextCard } from '@shared/modules/middle-view/helpers/move-to-next-card';
import { ConversationHeaderActionService } from '@shared/modules/conversations/components/conversation-header/conversation-header-action.service';
import { CardType } from '@shared/api/api-loop/models/card-type';
import { TagType } from '@shared/api/api-loop/models/tag-type';
import { SmartContactsApiService } from '@shared/modules/contacts/shell/contacts-api-cache/smart-contacts-api.service';
import { ConversationActionParams } from '@shared/services/data/conversation-action/conversation-action.service.interface';
import { ChannelType } from '@shared/modules/comments/common/constants/channel-type';
import { SpamApiService } from '@shared/api/api-loop/services/spam-api.service';
import {
  KeyboardActionModeType,
  KeyboardActionType,
  KeyboardShortcutData,
  ModeSwitchKeyboardAction
} from '@dta/shared/models/keyboard-shortcut.model';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { ThemesService } from '@dta/ui/services/themes/themes.service';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { MainMenuService } from '@dta/ui/components/menu/menu.service';
import { RouterService, SupportedRouteParts } from '@dta/ui/services/router/router.service';
import { TooltipAlignment, TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';
import { writeTextToClipboard } from '@dta/shared/clipboard/write-text-to-clipboard';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SharedTagLabelModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { TagLabelModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { CardChatModel, CardMailModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';

const HANDLED_KEYBOARD_ACTIONS: KeyboardActionType[] = [
  KeyboardActionType.ARCHIVE,
  KeyboardActionType.DELETE,
  KeyboardActionType.STAR,
  KeyboardActionType.MARK_AS_READ,
  KeyboardActionType.MARK_AS_UNREAD,
  KeyboardActionType.MODE_SWITCH,
  KeyboardActionType.TAG_CONVERSATION,
  KeyboardActionType.MOVE_TO_TEAM,
  KeyboardActionType.MOVE_TO_FOLDER,
  KeyboardActionType.EXPAND_THREAD,
  KeyboardActionType.ASSIGN_SOMEONE,
  KeyboardActionType.RESOLVE,
  KeyboardActionType.ASSIGN_SELF,
  KeyboardActionType.MARK_AS_SPAM
];

export enum ThreadHeaderDropdownOption {
  MOVE_TO_INBOX = 'Move to inbox',
  ARCHIVE = 'Archive',
  DELETE = 'Delete',
  RESOLVE = 'Resolve',
  CLOSE = 'Close',
  CLOSE_AND_ARCHIVE = 'CloseAndArchive',

  MARKASUNREAD = 'Mark as unread',
  MARKASREAD = 'Mark as read',
  SNOOZE = 'Snooze',
  ASSIGN = 'Assign',

  MOVE_TO_FOLDER = 'Move to folder',
  ADD_TAG = 'Add tag',
  MOVE_TO_TEAM = 'Move to team',

  FOLLOW = 'Follow',
  UNFOLLOW = 'Unfollow',
  EXPAND_CARD = 'Expand thread',
  TOGGLE_EMAIL_BACKGROUND = 'Toggle email background',
  COPY_CONVERSATION_LINK = 'Copy conversation link',
  FAVORITE = 'Favorite',
  UN_FAVORITE = 'Unfavorite',
  MARK_AS_UNSPAM = 'Mark as not spam',
  MARK_AS_SPAM = 'Mark as spam'
}

@UntilDestroy()
@Component({
  selector: 'loop-conversation-header',
  styleUrls: ['./conversation-header.component.scss'],
  templateUrl: './conversation-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationHeaderComponent implements OnInit, OnDestroy {
  protected _notificationsService: NotificationsService = inject(NotificationsService);
  protected userManagerService: UserManagerService = inject(UserManagerService);
  protected _trackingService: TrackingService = inject(TrackingService);
  protected _electronService: ElectronService = inject(ElectronService);
  protected _changeDetection: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected _storageService: StorageService = inject(StorageService);
  protected _appStateService: AppStateService = inject(AppStateService);
  protected _labelService: LabelService = inject(LabelService);
  protected _themesService: ThemesService = inject(ThemesService);
  protected _keyboardShortcutsListenerService: KeyboardShortcutsListenerService = inject(
    KeyboardShortcutsListenerService
  );
  protected _lightBackgroundService: LightBackgroundService = inject(LightBackgroundService);
  private _conversationActionService: ConversationActionService = inject(ConversationActionService);
  private _navigationBarService: NavigationBarService = inject(NavigationBarService);
  private _routerService: RouterService = inject(RouterService);
  private readonly slashCommandService: CommandService = inject(CommandService);
  private readonly tagApiService: TagApiService = inject(TagApiService);
  private readonly smartContactsApiService: SmartContactsApiService = inject(SmartContactsApiService);
  private readonly mainMenuService: MainMenuService = inject(MainMenuService);
  private readonly injector: Injector = inject(Injector);
  private readonly spamApiService: SpamApiService = inject(SpamApiService);
  private readonly conversationHeaderActionService: ConversationHeaderActionService = inject(
    ConversationHeaderActionService
  );
  private readonly router: Router = inject(Router);
  private readonly trackingService: TrackingService = inject(TrackingService);

  protected readonly TooltipPlacement: typeof TooltipPlacement = TooltipPlacement;
  protected readonly TooltipAlignment: typeof TooltipAlignment = TooltipAlignment;
  protected readonly ActionEnum: typeof ActionEnum = ActionEnum;
  protected readonly CardType_Chat: CardType = CardType.CARD_CHAT;

  protected readonly star22Svg: NodeRequire = require('shared/assets/img/svg/star-22-px.svg');
  protected readonly fullstar22Svg: NodeRequire = require('shared/assets/img/svg/full-star-22-px.svg');

  private _conversation?: ConversationModel;
  protected readonly conversation$: ReplaySubject<ConversationModel> = new ReplaySubject<ConversationModel>(1);

  @Input() set conversation(conversation: ConversationModel) {
    this._conversation = conversation;
    this.conversation$.next(conversation);
  }

  @Input() lastCommentDate?: string;

  @Output() lightBackgroundChanged: EventEmitter<boolean> = new EventEmitter();

  protected offline: boolean = false;
  protected lightBackground: boolean;
  protected isDarwin: boolean;

  @ViewChild('dropdownContainerTpl', { read: ViewContainerRef }) private dropdownViewContainerRef: ViewContainerRef;

  protected readonly contact$: Observable<ContactModel> = this.conversation$.pipe(
    filter(conversation => conversation.cardType === CardChatModel.type),
    map(conversation => {
      return conversation.getOtherContact(this.userManagerService.getCurrentUserId());
    }),
    switchMap(contact => {
      return this.smartContactsApiService.getSingle(contact.id);
    })
  );

  protected readonly sharedLabelTags$: Observable<SharedTagLabelModel[]> = this.conversation$.pipe(
    map(conversation => {
      return conversation.getSharedLabelTags() as SharedTagLabelModel[];
    }),
    distinctUntilChanged((prevTags, currentTags) => {
      const prevIds = prevTags.map(tag => tag.id);
      const currIds = currentTags.map(tag => tag.id);
      return prevIds.length === currIds.length && prevIds.every(prevId => [...currIds].includes(prevId));
    })
  );

  protected readonly privateLabelTags$: Observable<TagLabelModel[]> = this.conversation$.pipe(
    map(conversation => {
      return conversation.getLabelTags() as TagLabelModel[];
    }),
    distinctUntilChanged((prevTags, currentTags) => {
      const prevIds = prevTags.map(tag => tag.id);
      const currIds = currentTags.map(tag => tag.id);
      return prevIds.length === currIds.length && prevIds.every(prevId => [...currIds].includes(prevId));
    })
  );

  protected readonly canAddSharedTags$: Observable<boolean> = this.conversation$.pipe(
    map(conversation => {
      return conversation.cardType !== CardType.CARD_CHAT && conversation.channelType !== ChannelType.loopSpam;
    })
  );

  get currentUserEmail(): string {
    return this.userManagerService.getCurrentUserEmail();
  }

  get isUserAssigned(): boolean {
    return this._conversation.hasSharedTagAssigneeId(this.userManagerService.getCurrentUserId());
  }

  ngOnInit(): void {
    this.isDarwin = checkIfOS(OperatingSystem.DARWIN);

    this.subscribeToConnectionStatus();
    this.subscribeAndRegisterKeyboardShortcuts();
    this.lightBackground = this._lightBackgroundService.getLightBackgroundState();
  }

  ngOnDestroy(): void {
    this.mainMenuService.openSidebar();
  }

  private copyConversationLink(): void {
    writeTextToClipboard(createConversationDeeplink(this._conversation.id));
    this.trackingService.trackUserClick(this.currentUserEmail, 'ConversationHeader', 'Copy conversation link', {
      conversationId: this._conversation.cardId
    });

    this._notificationsService.setInAppNotification(this.currentUserEmail, {
      type: NotificationEventType.BasicNotification,
      msg: 'Conversation link copied to clipboard'
    });
  }

  copyTitleToClipboard(conversation: ConversationModel): void {
    if (conversation.cardType === CardType.CARD_CHAT) {
      return;
    }

    writeTextToClipboard(conversation.name);

    this._notificationsService.setInAppNotification(this.currentUserEmail, {
      type: NotificationEventType.CopiedToClipboard,
      msg: 'Title copied to clipboard'
    });
  }

  onDropdownSelect(option: string): void {
    let action: ActionEnum;

    switch (option) {
      case ThreadHeaderDropdownOption.EXPAND_CARD:
        /**
         * We set thread mode here,
         * so when closing the DD we don't go back to navigation mode
         * - that would collapse the thread
         */
        this._keyboardShortcutsListenerService.setMode(KeyboardActionModeType.THREAD);
        this._trackingService.trackUserClick(this.currentUserEmail, undefined, 'Expand/Collapse email header');

        this.mainMenuService.toggleSidebar();
        break;
      case ThreadHeaderDropdownOption.MARKASREAD:
        action = ActionEnum.MARK_AS_READ;
        break;
      case ThreadHeaderDropdownOption.MARKASUNREAD:
        action = ActionEnum.MARK_AS_UNREAD;
        break;
      case ThreadHeaderDropdownOption.ARCHIVE:
        action = ActionEnum.ARCHIVE;
        break;
      case ThreadHeaderDropdownOption.DELETE:
        action = ActionEnum.DELETE;
        break;
      case ThreadHeaderDropdownOption.MOVE_TO_INBOX:
        action = ActionEnum.MOVE_TO_INBOX;
        break;
      case ThreadHeaderDropdownOption.COPY_CONVERSATION_LINK:
        this.copyConversationLink();
        break;
      case ThreadHeaderDropdownOption.FOLLOW:
      case ThreadHeaderDropdownOption.UNFOLLOW:
        this.toggleFollowStatus();
        break;
      case ThreadHeaderDropdownOption.RESOLVE:
        this.resolve();
        break;
      case ThreadHeaderDropdownOption.FAVORITE:
        this.favoriteContact();
        break;
      case ThreadHeaderDropdownOption.UN_FAVORITE:
        this.unFavoriteContact();
        break;
      case ThreadHeaderDropdownOption.MARK_AS_UNSPAM:
        this.markAsUnSpam();
        break;
      case ThreadHeaderDropdownOption.MARK_AS_SPAM:
        action = ActionEnum.MARK_AS_JUNK;
        break;
      case ThreadHeaderDropdownOption.CLOSE:
        action = ActionEnum.CLOSE_CONVERSATION;
        break;
      case ThreadHeaderDropdownOption.CLOSE_AND_ARCHIVE:
        this.applyConversationAction(ActionEnum.ARCHIVE);
        action = ActionEnum.CLOSE_CONVERSATION;
        break;
      default:
        break;
    }

    if (action) {
      this.applyConversationAction(action);
    }
  }

  private markAsUnSpam(): void {
    this.conversation$
      .pipe(
        take(1),
        switchMap(conversation => {
          return this.spamApiService
            .Spam_UnSpamMessage(
              {
                messageId: conversation.id
              },
              this.userManagerService.getCurrentUserEmail()
            )
            .pipe(
              map(() => {
                conversation.markAsNotSpam();
                return conversation;
              })
            );
        })
      )
      .pipe(take(1), untilDestroyed(this))
      .subscribe(conversation => {
        PublisherService.publishEvent(this.userManagerService.getCurrentUserEmail(), [conversation]);
        this.closeCard();
      });
  }

  private favoriteContact(): void {
    this.contact$
      .pipe(
        switchMap(contact => {
          let tag = new TagModel();
          tag.id = TagType.FAVORITE;
          tag.tagType = TagType.FAVORITE;
          contact.addTag(tag);
          return this.tagApiService
            .Tag_UpdateTags({ tags: [contact.tags] }, this.currentUserEmail)
            .pipe(map(() => contact));
        })
      )
      .pipe(take(1), untilDestroyed(this))
      .subscribe(contact => {
        this.smartContactsApiService.removeContactFromStorage(contact.id);
      });
  }

  private unFavoriteContact(): void {
    this.contact$
      .pipe(
        switchMap(contact => {
          let tag = new TagModel();
          tag.id = TagType.FAVORITE;
          tag.tagType = TagType.FAVORITE;
          contact.removeTag(tag);
          return this.tagApiService
            .Tag_UpdateTags({ tags: [contact.tags] }, this.currentUserEmail)
            .pipe(map(() => contact));
        })
      )
      .pipe(take(1), untilDestroyed(this))
      .subscribe(contact => {
        this.smartContactsApiService.removeContactFromStorage(contact.id);
      });
  }

  private resolve(): void {
    this.slashCommandService
      .sendCommand(
        this.userManagerService.getCurrentUserEmail(),
        '/resolve',
        [this._conversation.id],
        this._conversation.cardType === CardMailModel.type
      )
      .pipe(take(1))
      .subscribe();

    this.closeCard();
  }

  toggleFollowStatus(): void {
    // Close card
    if (
      this._routerService.routePartsIncludes(SupportedRouteParts.LoopInbox) ||
      this._routerService.routePartsIncludes(SupportedRouteParts.Messages)
    ) {
      this.closeCard();
    }

    const action = this._conversation.isSubscribed ? ActionEnum.UN_FOLLOW : ActionEnum.FOLLOW;
    this._conversationActionService
      .conversationAction(this.userManagerService.getCurrentUserEmail(), {
        action: action,
        conversationIds: [this._conversation.id],
        isSharedAction: !this.router.url.includes('myloopinbox'),
        actionDate: this.lastCommentDate
      })
      .pipe(take(1))
      .subscribe();
  }

  applyConversationAction(action: ActionEnum): void {
    switch (action) {
      case ActionEnum.ARCHIVE:
      case ActionEnum.DELETE:
      case ActionEnum.MOVE_TO_INBOX:
      case ActionEnum.MOVE_TO_FOLDER:
      case ActionEnum.MARK_AS_JUNK:
        this.closeCard();
    }

    this._conversationActionService
      .conversationAction(this.currentUserEmail, {
        action: action,
        conversationIds: [this._conversation.id],
        folder: undefined,
        context: this._navigationBarService.getContext(),
        isSharedAction: !this.router.url.includes('myloopinbox'),
        actionDate: this.lastCommentDate
      })
      .pipe(take(1))
      .subscribe();
  }

  closeCard(): void {
    moveToNextCard(this._conversation.cardId, this.injector);
  }

  private handleModeSwitch(action: ModeSwitchKeyboardAction): void {
    if (action.toMode.type === KeyboardActionModeType.NAVIGATION) {
      if (!this.mainMenuService.sidebarToggle$.getValue()) {
        this.mainMenuService.openSidebar();
      }
    }
  }

  private subscribeToConnectionStatus(): void {
    this._appStateService.connectionStatus$
      .pipe(
        tap((connectionStatus: ConnectionStatus) => {
          this.offline = !connectionStatus.connectionActive;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private subscribeAndRegisterKeyboardShortcuts(): void {
    this._keyboardShortcutsListenerService
      .getActionsByTypes(HANDLED_KEYBOARD_ACTIONS)
      .pipe(
        switchMap(action =>
          this.conversation$.pipe(
            take(1),
            map(conversation => [conversation, action] as [ConversationModel, KeyboardShortcutData])
          )
        )
      )
      .pipe(
        map(([conversation, action]) => {
          if (conversation.cardType === CardChatModel.type || conversation.channelType === ChannelType.loopSpam) {
            return;
          }

          switch (action.action.type) {
            case KeyboardActionType.DELETE:
              this.onDropdownSelect(ActionEnum.DELETE);
              break;
            case KeyboardActionType.ARCHIVE:
              this.onDropdownSelect(ActionEnum.ARCHIVE);
              break;
            case KeyboardActionType.STAR:
              this.applyConversationAction(
                this._conversation.isStarred ? ActionEnum.MARK_AS_UNSTARRED : ActionEnum.MARK_AS_STARRED
              );
              break;
            case KeyboardActionType.MARK_AS_READ:
              this.applyConversationAction(ActionEnum.MARK_AS_READ);
              break;
            case KeyboardActionType.MODE_SWITCH:
              this.handleModeSwitch(<ModeSwitchKeyboardAction>action.action);
              break;
            case KeyboardActionType.MARK_AS_UNREAD:
              this.applyConversationAction(ActionEnum.MARK_AS_UNREAD);
              break;
            case KeyboardActionType.MOVE_TO_TEAM:
              this.conversationHeaderActionService.moveToTeam(conversation, this.dropdownViewContainerRef);
              break;
            case KeyboardActionType.MOVE_TO_FOLDER:
              this.conversationHeaderActionService.openAddToFolder(
                [conversation.cardId],
                conversation.findGroupInShareList()?.id,
                this.dropdownViewContainerRef
              );
              break;
            case KeyboardActionType.TAG_CONVERSATION:
              this.conversationHeaderActionService.openLabelsDropdown([conversation], this.dropdownViewContainerRef);
              break;
            case KeyboardActionType.ASSIGN_SOMEONE:
              this.conversationHeaderActionService.assignConversation(conversation, this.dropdownViewContainerRef);
              break;
            case KeyboardActionType.RESOLVE:
              this.conversationHeaderActionService.sendStatusCommand('/resolve', conversation);
              break;
            case KeyboardActionType.EXPAND_THREAD:
              this.onDropdownSelect(ThreadHeaderDropdownOption.EXPAND_CARD);
              break;
            case KeyboardActionType.ASSIGN_SELF:
              this.conversationHeaderActionService.assignContact(
                this.userManagerService.getCurrentUser(),
                conversation
              );
              break;
            case KeyboardActionType.MARK_AS_SPAM:
              this.applyConversationAction(ActionEnum.MARK_AS_JUNK);
              break;
            default:
              break;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  protected readonly removeLabel: (label: SharedTagLabelModel | TagLabelModel) => () => void = (
    label: SharedTagLabelModel | TagLabelModel
  ) => {
    return () => this.addOrRemoveLabel(label, 'remove');
  };

  protected readonly addOrRemoveLabel: (label: SharedTagLabelModel | TagLabelModel, action: 'add' | 'remove') => void =
    (label: SharedTagLabelModel | TagLabelModel, action: 'add' | 'remove') => {
      this._conversation.addOrRemoveLabel(label, action);
      this.conversation$.next(this._conversation);

      const conversationActionParams: ConversationActionParams = {
        action: label.$type === SharedTagLabelModel.type ? ActionEnum.CHANGE_LABELS : ActionEnum.CHANGE_PRIVATE_LABELS,
        conversationIds: [this._conversation.cardId],
        isSharedAction: !this.router.url.includes('myloopinbox'),
        actionDate: this.lastCommentDate
      };
      if (action === 'add') {
        conversationActionParams.addTags = [label];
      } else {
        conversationActionParams.removeTags = [label];
      }
      this._conversationActionService
        .conversationAction(this.userManagerService.getCurrentUserEmail(), conversationActionParams)
        .pipe(untilDestroyed(this), take(1))
        .subscribe();
    };
}
