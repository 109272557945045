import { EnumObjectValue } from '@shared/utils/common/types';
import { MonoTypeOperatorFunction, filter } from 'rxjs';
import { RESOURCE_BASE_TYPE_TO_ACTION } from '@shared/services/communication/shared-subjects/common/interfaces/resource-base-type-to-action';
import { ResourceBaseType } from '@shared/api/common/contatns/ResourceBaseType';
import { ModelActionData } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { isModelAction } from '@shared/services/communication/shared-subjects/common/helpers/is-model-action';

// Utility function for type-safe filtering
export function filterModelAction<
  RESOURCE extends EnumObjectValue<typeof ResourceBaseType>,
  ACTION extends RESOURCE_BASE_TYPE_TO_ACTION<RESOURCE>
>(resource: RESOURCE, action: ACTION): MonoTypeOperatorFunction<ModelActionData<RESOURCE, ACTION>> {
  return filter((modelActionData): modelActionData is ModelActionData<RESOURCE, ACTION> => {
    return isModelAction(modelActionData, resource, action);
  });
}
