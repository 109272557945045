<div class="option" [class.selected]="isSelected">
  <ng-container *ngTemplateOutlet="optionTpl; context: { $implicit: option }"></ng-container>

  <div class="flex gap-x-half items-center">
    <svg-comp *ngIf="isSelected" [src]="checkMarkSvg"></svg-comp>
  </div>
</div>

<ng-template #optionTpl let-option>
  <div class="flex items-center gap-x-half">
    <ng-container *ngIf="customOptionTemplate; else defaultTpl">
      <ng-container *ngTemplateOutlet="customOptionTemplate; context: { $implicit: option }"></ng-container>
    </ng-container>
    <ng-template #defaultTpl>
      {{ option.label }}
    </ng-template>
  </div>
</ng-template>
