import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { UserSwitch } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { ContactHelperService } from 'web/app/services/contact-helper/contact-helper.service';
import { RedirectService } from '../../../../shared/services/redirect/redirect.service';
import { TrackingConstants } from '../../../shared/services/tracking/tracking.constants';
import { merge, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface GettingStartedCard {
  title: string;
  description: string;
  addMoreCopy: string;
  done: boolean;
  cta?: Function;
  learnMore?: Function;
}

@AutoUnsubscribe()
@Component({
  selector: 'getting-started',
  styleUrls: ['./getting-started.scss'],
  template: `
    <div class="getting-started">
      <getting-started-card
        *ngFor="let card of cards"
        [title]="card.title"
        [description]="card.description"
        [trackUserClick]="{
          forUserEmail: currentUserEmail,
          location: trackingLocation,
          action: 'Start' + card.title.split(' ').join(''),
          additionalProperties
        }"
        [addMore]="card.addMoreCopy"
        [done]="card.done"
        [learnMore]="!!card.learnMore"
        (onLearnMoreClick$)="card.learnMore && card.learnMore()"
        (onCtaClick$)="card.cta && card.cta()"
      >
      </getting-started-card>
    </div>
  `,
})
export class GettingStartedComponent implements OnInit, OnDestroy {
  /////////////
  // Variables
  /////////////
  private privateInbox: GettingStartedCard = {
    title: 'Personal inbox',
    description: 'Connect your personal inbox to sync your personal emails with Loop',
    done: false,
    addMoreCopy: 'Modify in settings',
    cta: () => this.addPersonalInbox(),
    learnMore: () =>
      this.goTo('https://help.intheloop.io/hc/en-gb/articles/5472930834193-Connecting-your-personal-mailbox'),
  };
  private teams: GettingStartedCard = {
    title: 'Teams',
    description: 'Create a team to manage all your internal and external communications in Loop',
    done: false,
    addMoreCopy: 'Create another Team',
    cta: () => this.addTeam(),
    learnMore: () => this.goTo('https://help.intheloop.io/hc/en-gb/articles/360038720154-How-to-create-a-team'),
  };
  private sharedInboxes: GettingStartedCard = {
    title: 'Shared inboxes',
    description: 'Connect a shared inbox to boost your productivity and respond to your customers faster',
    addMoreCopy: 'Add more Shared Inboxes',
    done: false,
    cta: () => this.createNewSharedInbox(),
    learnMore: () => this.goTo('https://help.intheloop.io/hc/en-gb/sections/4413371355665-Shared-Inbox'),
  };
  private inviteTeammates: GettingStartedCard = {
    title: 'Invite teammates',
    description: 'Invite teammates and start collaborating with them',
    addMoreCopy: 'Add more teammates',
    done: false, // Always false, because we don't know if the user has invited teammates
    cta: () => this.invite(),
  };

  cards: GettingStartedCard[] = [this.sharedInboxes, this.teams, this.privateInbox, this.inviteTeammates];

  trackingLocation: string = TrackingConstants.desktopGettingStarted;
  additionalProperties: Object = {
    workflow: 'GettingStarted',
    view: 'GettingStartedHome',
  };

  /////////////////
  // Subscriptions
  /////////////////
  private contactsSub: Subscription;

  constructor(
    private _webOnboardingService: WebOnboardingService,
    private _userManagerService: UserManagerService,
    private _contactHelperService: ContactHelperService,
    private _redirectService: RedirectService,
    private _trackingService: TrackingService,
  ) {}

  ngOnInit() {
    this.subscribeToContacts();

    this._trackingService.track(
      this.currentUserEmail,
      TrackingConstants.desktopGettingStarted,
      this.additionalProperties,
    );
  }

  ngOnDestroy() {}

  private get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  private invite() {
    this._webOnboardingService.openWebSettings(
      new URLSearchParams({ action: 'invite_people' }),
      '/members/invite',
      window.open(),
    );
  }

  private addTeam() {
    this._redirectService.navigateTo('/user-settings/teams/create');
  }

  private createNewSharedInbox() {
    this._redirectService.navigateTo('/user-settings/shared-inboxes/create');
  }

  private addPersonalInbox() {
    this._redirectService.navigateTo('/user-settings/personal-inboxes');
  }

  private goTo(url: string) {
    window.open(url, '_blank');
  }

  private subscribeToContacts() {
    this.contactsSub?.unsubscribe();
    this.contactsSub = merge(this._userManagerService.userSwitch$, this._userManagerService.userUpdate$)
      .pipe(
        tap(update => {
          if (update instanceof UserSwitch) {
            this.cards.forEach(c => (c.done = false));
          }

          this.privateInbox.done = this._userManagerService.isCurrentUserAccountSyncable();
        }),
        switchMap(() => this._contactHelperService.subscribeToTeamsAndSharedInboxesCount(this.currentUserEmail)),
        tap(({ teamChannels, sharedInboxChannels }) => {
          this.sharedInboxes.done = sharedInboxChannels > 0;
          this.teams.done = teamChannels > 0;
        }),
      )
      .subscribe();
  }
}
