import * as _ from 'lodash';
import { Observable, of, switchMap } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { SharedTagService } from '@shared/services/data/shared-tags/shared-tags.service';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { SharedTagBase } from '@shared/api/api-loop/models';
import { ConversationCardPopulateService } from '../conversation-card-populate.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { TagLabelService } from '@shared/services/data/tag-label/tag-label.service';
import { StaticSharedTagPrefix } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { SmartClassificationSharedTagApi } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/smart-classification-shared-tag-api';

@Injectable()
export class ConversationPopulateService extends ConversationCardPopulateService<ConversationModel> {
  constructor(
    protected _contactService: ContactService,
    protected _contactStoreFactory: ContactStoreFactory,
    protected _sharedTagService: SharedTagService,
    protected _tagLabelService: TagLabelService,
    protected readonly smartClassificationSharedTagApi: SmartClassificationSharedTagApi
  ) {
    super(_contactService, _contactStoreFactory, _sharedTagService, _tagLabelService, smartClassificationSharedTagApi);
  }

  get constructorName(): string {
    return 'ConversationPopulateService';
  }

  populate(forUserEmail: string, conversations: ConversationModel[]): Observable<ConversationModel[]> {
    return of(conversations).pipe(
      /**
       * Populate with sharedTags
       */
      switchMap((_conversations: ConversationModel[]) => {
        return this.populateWithSharedTags(forUserEmail, _conversations);
      }),
      /**
       * Populate with classificationTags
       */
      switchMap((_conversations: ConversationModel[]) => {
        return this.populateWithClassificationTags(forUserEmail, _conversations);
      }),
      /**
       * Populate with private Tags
       */
      switchMap((_cards: ConversationModel[]) => {
        return this.populateWithPrivateTags(forUserEmail, _cards);
      }),
      /**
       * Populate with contacts
       */
      switchMap((_conversations: ConversationModel[]) => {
        return this.populateWithContacts(forUserEmail, _conversations, true);
      })
    );
  }

  reduce(forUserEmail: string, conversations: ConversationModel[]): Observable<ConversationModel[]> {
    // Don't reduce conversations
    return of(conversations);
  }

  //////////////
  // SharedTags
  //////////////
  protected buildCardIdBySharedTagId(
    conversations: ConversationModel[],
    missingSharedTagIds: string[]
  ): { [sharedTagId: string]: string } {
    // Bundle sharedTags under card ids (for authorization)
    let cardIdBySharedTagId: _.Dictionary<string> = {};
    _.forEach(conversations, (conversation: ConversationModel) => {
      if (!conversation.hasSharedTags()) {
        return;
      }

      _.forEach(conversation.getSharedTags(), (sharedTag: SharedTagBase) => {
        if (missingSharedTagIds.includes(sharedTag.id) && _.isEmpty(cardIdBySharedTagId[sharedTag.id])) {
          cardIdBySharedTagId[sharedTag.id] = conversation.cardId;
        }
      });
    });

    return cardIdBySharedTagId;
  }
}
