import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDecorateService } from './base-decorate.service';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { FileViewDecorateService } from './view-data-decorators/file-view-decorator/file-view-decorate.service';
import { FileExDecorateService } from './extra-data-decorators/file-ex-decorator/file-ex-decorate.service';

@Injectable()
export class FileDecorateService extends BaseDecorateService<FileModel> {
  constructor(
    private _fileViewDecorateService: FileViewDecorateService,
    private _fileExDecorateService: FileExDecorateService,
  ) {
    super();
  }

  decorateExtraData(forUserEmail: string, file: FileModel): Observable<FileModel> {
    return this._fileExDecorateService.decorate(forUserEmail, file);
  }

  decorateViewData(forUserEmail: string, file: FileModel, force?: boolean): Observable<FileModel> {
    return this._fileViewDecorateService.decorate(forUserEmail, file, force);
  }
}
