import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Hash } from '../../../shared/utils/common-utils';
import { CONSTANTS } from '@shared/models/constants/constants';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

@Injectable()
export class GifPickerApiService {
  constructor(
    private _http: HttpClient,
    private _userManagerService: UserManagerService,
  ) {}

  public getGifUrls(searchString: string, size: number, next?: string): Observable<TenorApiResponse> {
    let suffix = searchString ? '/search' : '/featured';

    let __method = 'GET';
    let __url = CONSTANTS.TENOR_API + suffix;
    let __params = new HttpParams();

    __params = __params.set('q', searchString);
    __params = __params.set('key', CONSTANTS.TENOR_API_KEY);
    __params = __params.set('limit', size.toString());
    __params = __params.set('contentfilter', CONSTANTS.TENOR_CONTENT_FILTER_OPTIONS);
    __params = __params.set('media_filter', 'gif,tinygif,mp4,tinymp4,mediumgif');

    if (!_.isEmpty(next)) {
      __params = __params.set('pos', next);
    }

    return this._http
      .request(__method, __url, {
        params: __params,
        responseType: 'json',
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return <TenorApiResponse>response.body;
        }),
      );
  }

  public getGifContent(gifUrl: string): Observable<ArrayBuffer> {
    let __method = 'GET';
    let __url = gifUrl;

    return this._http
      .request(__method, __url, {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        }),
      );
  }
}

export interface TenorApiResponse {
  weburl: string;
  results: TenorApiResult[];
  next: string;
}

export interface TenorApiResult {
  tags: string[];
  url: string;
  media_formats: { [id: string]: TenorApiMedia };
  created: number;
  shares: number;
  itemurl: string;
  composite?: any;
  hasaudio: boolean;
  title: string;
  id: string;
}

export interface TenorApiMedia {
  url: string;
  dims: number[];
  preview: string;
  size: number;
}
