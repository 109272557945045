/* tslint:disable */

/**
 * Subscription Status types
 */
export type SubscriptionStatusType = 'Trial' | 'Suspended' | 'Active';

export module SubscriptionStatusType {
  export const TRIAL: SubscriptionStatusType = 'Trial';
  export const SUSPENDED: SubscriptionStatusType = 'Suspended';
  export const ACTIVE: SubscriptionStatusType = 'Active';

  export function values(): SubscriptionStatusType[] {
    return [TRIAL, SUSPENDED, ACTIVE];
  }
}
