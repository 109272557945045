/* tslint:disable */

/**
 * Enum of all possible tag names on shared tag system type.
 */
export type SharedTagSystemNames =
  | 'SPAM'
  | 'DELETED'
  | 'ARCHIVE'
  | 'SYSTEMMESSAGE'
  | 'UNFOLLOW'
  | 'SENT'
  | 'INBOX'
  | 'SYSTEMBUMP';

export module SharedTagSystemNames {
  export const SPAM: SharedTagSystemNames = 'SPAM';
  export const DELETED: SharedTagSystemNames = 'DELETED';
  export const ARCHIVE: SharedTagSystemNames = 'ARCHIVE';
  export const SYSTEMMESSAGE: SharedTagSystemNames = 'SYSTEMMESSAGE';
  export const UNFOLLOW: SharedTagSystemNames = 'UNFOLLOW';
  export const SENT: SharedTagSystemNames = 'SENT';
  export const INBOX: SharedTagSystemNames = 'INBOX';
  export const SYSTEMBUMP: SharedTagSystemNames = 'SYSTEMBUMP';

  export function values(): SharedTagSystemNames[] {
    return [SPAM, DELETED, ARCHIVE, SYSTEMMESSAGE, UNFOLLOW, SENT, INBOX, SYSTEMBUMP];
  }
}
