/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { User } from '../models/user';
import { Response } from '../models/response';
import { ListOfResourcesOfUser } from '../models/list-of-resources-of-user';
import { AvailabilityUserStatus } from '../models/availability-user-status';
import { ApiKeyToken } from '../models/api-key-token';
import { Token } from '../models/token';

@Injectable()
export class UserApiService extends BaseService {
  /**
   * @param params The `UserApiService.User_CreateContactParams` containing the following parameters:
   *
   * - `user`: User object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_CreateContactResponse(
    params: UserApiService.User_CreateContactParams,
    forUserEmail: string
  ): Observable<HttpResponse<User>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/user`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_CreateContact');

    // body, headers and query params
    __body = params.user;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: User;

          body = <User>response.body;

          return <HttpResponse<User>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_CreateContactParams` containing the following parameters:
   *
   * - `user`: User object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_CreateContact(
    params: UserApiService.User_CreateContactParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<User> {
    const observable$ = this.User_CreateContactResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user`;
      const url = this.rootUrl + `/api/v1/user`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_GetSelfParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_GetSelfResponse(
    params: UserApiService.User_GetSelfParams,
    forUserEmail: string
  ): Observable<HttpResponse<User>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/user`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_GetSelf');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: User;

          body = <User>response.body;

          return <HttpResponse<User>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_GetSelfParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_GetSelf(
    params: UserApiService.User_GetSelfParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<User> {
    const observable$ = this.User_GetSelfResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user`;
      const url = this.rootUrl + `/api/v1/user`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_UpdateSelfParams` containing the following parameters:
   *
   * - `user`: User object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_UpdateSelfResponse(
    params: UserApiService.User_UpdateSelfParams,
    forUserEmail: string
  ): Observable<HttpResponse<User>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/user`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_UpdateSelf');

    // body, headers and query params
    __body = params.user;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: User;

          body = <User>response.body;

          return <HttpResponse<User>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_UpdateSelfParams` containing the following parameters:
   *
   * - `user`: User object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_UpdateSelf(
    params: UserApiService.User_UpdateSelfParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<User> {
    const observable$ = this.User_UpdateSelfResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user`;
      const url = this.rootUrl + `/api/v1/user`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_GetParams` containing the following parameters:
   *
   * - `id`: User id (leave empty, if you want to get self - authorized user)
   *
   * - `authorizedCardId`: Card id where user can see contact but doesn't actually have contact with him/her
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_GetResponse(params: UserApiService.User_GetParams, forUserEmail: string): Observable<HttpResponse<User>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/user/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_Get');

    // body, headers and query params
    if (params.authorizedCardId != null)
      __params = __params.set('authorizedCardId', params.authorizedCardId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: User;

          body = <User>response.body;

          return <HttpResponse<User>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_GetParams` containing the following parameters:
   *
   * - `id`: User id (leave empty, if you want to get self - authorized user)
   *
   * - `authorizedCardId`: Card id where user can see contact but doesn't actually have contact with him/her
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_Get(params: UserApiService.User_GetParams, forUserEmail: string, cacheEnabled?: boolean): Observable<User> {
    const observable$ = this.User_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/${params.id}`;
      const url = this.rootUrl + `/api/v1/user/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_UpdateContactParams` containing the following parameters:
   *
   * - `user`: User object
   *
   * - `id`: User id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_UpdateContactResponse(
    params: UserApiService.User_UpdateContactParams,
    forUserEmail: string
  ): Observable<HttpResponse<User>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/user/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_UpdateContact');

    // body, headers and query params
    __body = params.user;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: User;

          body = <User>response.body;

          return <HttpResponse<User>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_UpdateContactParams` containing the following parameters:
   *
   * - `user`: User object
   *
   * - `id`: User id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_UpdateContact(
    params: UserApiService.User_UpdateContactParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<User> {
    const observable$ = this.User_UpdateContactResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/${params.id}`;
      const url = this.rootUrl + `/api/v1/user/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_GetListOfUsersParams` containing the following parameters:
   *
   * - `tags`: List of tags to filter card list by.
   *
   * - `size`: Requested page size.
   *
   * - `searchQuery`: Plaintext search query to filter list of users by.
   *
   * - `offset`: Requested page offset.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  User_GetListOfUsersResponse(
    params: UserApiService.User_GetListOfUsersParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfUser>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/user/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_GetListOfUsers');

    // body, headers and query params
    (params.tags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('tags', val.toString());
    });
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfUser;

          body = <ListOfResourcesOfUser>response.body;

          return <HttpResponse<ListOfResourcesOfUser>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_GetListOfUsersParams` containing the following parameters:
   *
   * - `tags`: List of tags to filter card list by.
   *
   * - `size`: Requested page size.
   *
   * - `searchQuery`: Plaintext search query to filter list of users by.
   *
   * - `offset`: Requested page offset.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  User_GetListOfUsers(
    params: UserApiService.User_GetListOfUsersParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfUser> {
    const observable$ = this.User_GetListOfUsersResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/list`;
      const url = this.rootUrl + `/api/v1/user/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_GetUserAvatarParams` containing the following parameters:
   *
   * - `userId`: User id
   *
   * - `imageSize`: Avatar size (all avatars are square - one dimension is enough, default is 256)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  User_GetUserAvatarResponse(
    params: UserApiService.User_GetUserAvatarParams,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/user/${params.userId}/avatar`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_GetUserAvatar');

    // body, headers and query params
    if (params.imageSize != null) __params = __params.set('imageSize', params.imageSize.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `UserApiService.User_GetUserAvatarParams` containing the following parameters:
   *
   * - `userId`: User id
   *
   * - `imageSize`: Avatar size (all avatars are square - one dimension is enough, default is 256)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  User_GetUserAvatar(
    params: UserApiService.User_GetUserAvatarParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.User_GetUserAvatarResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/${params.userId}/avatar`;
      const url = this.rootUrl + `/api/v1/user/${params.userId}/avatar`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_UpdateUserAvatarParams` containing the following parameters:
   *
   * - `userId`: User id
   *
   * - `file`: Avatar object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_UpdateUserAvatarResponse(
    params: UserApiService.User_UpdateUserAvatarParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/user/${params.userId}/avatar`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_UpdateUserAvatar');

    // body, headers and query params
    let formData: FormData = new FormData();
    formData.append('file', params.file, params.file['name']);
    __body = formData;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_UpdateUserAvatarParams` containing the following parameters:
   *
   * - `userId`: User id
   *
   * - `file`: Avatar object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_UpdateUserAvatar(
    params: UserApiService.User_UpdateUserAvatarParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.User_UpdateUserAvatarResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/${params.userId}/avatar`;
      const url = this.rootUrl + `/api/v1/user/${params.userId}/avatar`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_GetUserAvatarSignedLinkParams` containing the following parameters:
   *
   * - `userId`: User id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_GetUserAvatarSignedLinkResponse(
    params: UserApiService.User_GetUserAvatarSignedLinkParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/user/${params.userId}/avatarsignedlink`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_GetUserAvatarSignedLink');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_GetUserAvatarSignedLinkParams` containing the following parameters:
   *
   * - `userId`: User id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  User_GetUserAvatarSignedLink(
    params: UserApiService.User_GetUserAvatarSignedLinkParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.User_GetUserAvatarSignedLinkResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/${params.userId}/avatarsignedlink`;
      const url = this.rootUrl + `/api/v1/user/${params.userId}/avatarsignedlink`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_GetUserAvailabilityStatusParams` containing the following parameters:
   *
   * - `userId`: get payment status for userId
   *
   * - `Authorization`: Authorization header.
   */
  User_GetUserAvailabilityStatusResponse(
    params: UserApiService.User_GetUserAvailabilityStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<AvailabilityUserStatus>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/user/useravailabilitystatus`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_GetUserAvailabilityStatus');

    // body, headers and query params
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AvailabilityUserStatus;

          body = <AvailabilityUserStatus>response.body;

          return <HttpResponse<AvailabilityUserStatus>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_GetUserAvailabilityStatusParams` containing the following parameters:
   *
   * - `userId`: get payment status for userId
   *
   * - `Authorization`: Authorization header.
   */
  User_GetUserAvailabilityStatus(
    params: UserApiService.User_GetUserAvailabilityStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AvailabilityUserStatus> {
    const observable$ = this.User_GetUserAvailabilityStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/useravailabilitystatus`;
      const url = this.rootUrl + `/api/v1/user/useravailabilitystatus`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_PutUserAvailabilityStatusParams` containing the following parameters:
   *
   * - `availabilityStatus`: set availabilitiy status
   *
   * - `userId`: set availabilitiy status for userId if null - current user
   *
   * - `Authorization`: Authorization header.
   */
  User_PutUserAvailabilityStatusResponse(
    params: UserApiService.User_PutUserAvailabilityStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<AvailabilityUserStatus>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/user/useravailabilitystatus`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_PutUserAvailabilityStatus');

    // body, headers and query params
    __body = params.availabilityStatus;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AvailabilityUserStatus;

          body = <AvailabilityUserStatus>response.body;

          return <HttpResponse<AvailabilityUserStatus>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_PutUserAvailabilityStatusParams` containing the following parameters:
   *
   * - `availabilityStatus`: set availabilitiy status
   *
   * - `userId`: set availabilitiy status for userId if null - current user
   *
   * - `Authorization`: Authorization header.
   */
  User_PutUserAvailabilityStatus(
    params: UserApiService.User_PutUserAvailabilityStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AvailabilityUserStatus> {
    const observable$ = this.User_PutUserAvailabilityStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/useravailabilitystatus`;
      const url = this.rootUrl + `/api/v1/user/useravailabilitystatus`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_DeleteUserAvailabilityStatusParams` containing the following parameters:
   *
   * - `userId`: set availabilitiy status for userId if null - current user
   *
   * - `Authorization`: Authorization header.
   */
  User_DeleteUserAvailabilityStatusResponse(
    params: UserApiService.User_DeleteUserAvailabilityStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/user/useravailabilitystatus`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_DeleteUserAvailabilityStatus');

    // body, headers and query params
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_DeleteUserAvailabilityStatusParams` containing the following parameters:
   *
   * - `userId`: set availabilitiy status for userId if null - current user
   *
   * - `Authorization`: Authorization header.
   */
  User_DeleteUserAvailabilityStatus(
    params: UserApiService.User_DeleteUserAvailabilityStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.User_DeleteUserAvailabilityStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/useravailabilitystatus`;
      const url = this.rootUrl + `/api/v1/user/useravailabilitystatus`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_GetUserApiKeysStatusParams` containing the following parameters:
   *
   * - `Authorization`: Authorization header.
   */
  User_GetUserApiKeysStatusResponse(
    params: UserApiService.User_GetUserApiKeysStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<ApiKeyToken[]>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/user/apikey`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_GetUserApiKeysStatus');

    // body, headers and query params
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ApiKeyToken[];

          body = <ApiKeyToken[]>response.body;

          return <HttpResponse<ApiKeyToken[]>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_GetUserApiKeysStatusParams` containing the following parameters:
   *
   * - `Authorization`: Authorization header.
   */
  User_GetUserApiKeysStatus(
    params: UserApiService.User_GetUserApiKeysStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ApiKeyToken[]> {
    const observable$ = this.User_GetUserApiKeysStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/apikey`;
      const url = this.rootUrl + `/api/v1/user/apikey`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_AddUserApiKeysStatusParams` containing the following parameters:
   *
   * - `apiKeyToken`: api key token
   *
   * - `Authorization`: Authorization header.
   */
  User_AddUserApiKeysStatusResponse(
    params: UserApiService.User_AddUserApiKeysStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<Token[]>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/user/apikey`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_AddUserApiKeysStatus');

    // body, headers and query params
    __body = params.apiKeyToken;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token[];

          body = <Token[]>response.body;

          return <HttpResponse<Token[]>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_AddUserApiKeysStatusParams` containing the following parameters:
   *
   * - `apiKeyToken`: api key token
   *
   * - `Authorization`: Authorization header.
   */
  User_AddUserApiKeysStatus(
    params: UserApiService.User_AddUserApiKeysStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token[]> {
    const observable$ = this.User_AddUserApiKeysStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/apikey`;
      const url = this.rootUrl + `/api/v1/user/apikey`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_DeleteUserApiKeysParams` containing the following parameters:
   *
   * - `id`: Id of api key
   *
   * - `Authorization`: Authorization header.
   */
  User_DeleteUserApiKeysResponse(
    params: UserApiService.User_DeleteUserApiKeysParams,
    forUserEmail: string
  ): Observable<HttpResponse<ApiKeyToken[]>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/user/apikey/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_DeleteUserApiKeys');

    // body, headers and query params
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ApiKeyToken[];

          body = <ApiKeyToken[]>response.body;

          return <HttpResponse<ApiKeyToken[]>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_DeleteUserApiKeysParams` containing the following parameters:
   *
   * - `id`: Id of api key
   *
   * - `Authorization`: Authorization header.
   */
  User_DeleteUserApiKeys(
    params: UserApiService.User_DeleteUserApiKeysParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ApiKeyToken[]> {
    const observable$ = this.User_DeleteUserApiKeysResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/apikey/${params.id}`;
      const url = this.rootUrl + `/api/v1/user/apikey/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `UserApiService.User_RenameUserApiKeyParams` containing the following parameters:
   *
   * - `id`: Id of api key
   *
   * - `name`: new name of api key
   *
   * - `Authorization`: Authorization header.
   */
  User_RenameUserApiKeyResponse(
    params: UserApiService.User_RenameUserApiKeyParams,
    forUserEmail: string
  ): Observable<HttpResponse<ApiKeyToken>> {
    let __method = 'PATCH';
    let __url = this.rootUrl + `/api/v1/user/apikey/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('User_RenameUserApiKey');

    // body, headers and query params
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ApiKeyToken;

          body = <ApiKeyToken>response.body;

          return <HttpResponse<ApiKeyToken>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `UserApiService.User_RenameUserApiKeyParams` containing the following parameters:
   *
   * - `id`: Id of api key
   *
   * - `name`: new name of api key
   *
   * - `Authorization`: Authorization header.
   */
  User_RenameUserApiKey(
    params: UserApiService.User_RenameUserApiKeyParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ApiKeyToken> {
    const observable$ = this.User_RenameUserApiKeyResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/user/apikey/${params.id}`;
      const url = this.rootUrl + `/api/v1/user/apikey/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module UserApiService {
  /**
   * Parameters for User_CreateContact
   */
  export type User_CreateContactParams = {
    /**
     * User object
     */
    user: User;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_GetSelf
   */
  export type User_GetSelfParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_UpdateSelf
   */
  export type User_UpdateSelfParams = {
    /**
     * User object
     */
    user: User;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_Get
   */
  export type User_GetParams = {
    /**
     * User id (leave empty, if you want to get self - authorized user)
     */
    id: string;

    /**
     * Card id where user can see contact but doesn't actually have contact with him/her
     */
    authorizedCardId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_UpdateContact
   */
  export type User_UpdateContactParams = {
    /**
     * User object
     */
    user: User;

    /**
     * User id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_GetListOfUsers
   */
  export type User_GetListOfUsersParams = {
    /**
     * List of tags to filter card list by.
     */
    tags?: string[];

    /**
     * Requested page size.
     */
    size?: number;

    /**
     * Plaintext search query to filter list of users by.
     */
    searchQuery?: string;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_GetUserAvatar
   */
  export type User_GetUserAvatarParams = {
    /**
     * User id
     */
    userId: string;

    /**
     * Avatar size (all avatars are square - one dimension is enough, default is 256)
     */
    imageSize?: number;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * EntityTag header
     */
    ETag?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_UpdateUserAvatar
   */
  export type User_UpdateUserAvatarParams = {
    /**
     * User id
     */
    userId: string;

    /**
     * Avatar object
     */
    file?: Blob;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_GetUserAvatarSignedLink
   */
  export type User_GetUserAvatarSignedLinkParams = {
    /**
     * User id
     */
    userId: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_GetUserAvailabilityStatus
   */
  export type User_GetUserAvailabilityStatusParams = {
    /**
     * get payment status for userId
     */
    userId?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_PutUserAvailabilityStatus
   */
  export type User_PutUserAvailabilityStatusParams = {
    /**
     * set availabilitiy status
     */
    availabilityStatus: AvailabilityUserStatus;

    /**
     * set availabilitiy status for userId if null - current user
     */
    userId?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_DeleteUserAvailabilityStatus
   */
  export type User_DeleteUserAvailabilityStatusParams = {
    /**
     * set availabilitiy status for userId if null - current user
     */
    userId?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_GetUserApiKeysStatus
   */
  export type User_GetUserApiKeysStatusParams = {
    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_AddUserApiKeysStatus
   */
  export type User_AddUserApiKeysStatusParams = {
    /**
     * api key token
     */
    apiKeyToken: ApiKeyToken;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_DeleteUserApiKeys
   */
  export type User_DeleteUserApiKeysParams = {
    /**
     * Id of api key
     */
    id: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for User_RenameUserApiKey
   */
  export type User_RenameUserApiKeyParams = {
    /**
     * Id of api key
     */
    id: string;

    /**
     * new name of api key
     */
    name?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };
}
