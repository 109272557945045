import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive()
export abstract class OutsideClickComponent implements OnInit, OnDestroy {
  opened: boolean = false;
  checkoutSideClickBound = this.checkOutsideClick.bind(this);

  constructor(protected _elementRef: ElementRef) {}

  abstract handleData(data);

  abstract handleCloseEvent();

  ngOnInit() {}

  ngOnDestroy() {
    document.removeEventListener('click', this.checkoutSideClickBound);
  }

  checkOutsideClick($event) {
    if (
      !this._elementRef.nativeElement.contains($event.target) &&
      !(
        $event.target.parentNode.classList.contains('collapsable-item') ||
        $event.target.parentNode.classList.contains('search-item-wrapper')
      )
    ) {
      this.opened = false;
      this.handleCloseEvent();
      this.removeClickEventListener();
    }
  }

  protected handleOpenEvent(data) {
    this.opened = !this.opened;
    if (this.opened) {
      setTimeout(() => {
        this.removeClickEventListener();

        document.addEventListener('click', this.checkoutSideClickBound, { capture: true });
      }, 0);
    }
    this.handleData(data);
  }

  protected removeClickEventListener() {
    document.removeEventListener('click', this.checkoutSideClickBound, { capture: true });
  }
}
