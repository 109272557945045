/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Group } from '../models/group';
import { Response } from '../models/response';
import { SharedInboxRequest } from '../models/shared-inbox-request';
import { ListOfResourcesOfGroup } from '../models/list-of-resources-of-group';

@Injectable()
export class GroupApiService extends BaseService {
  /**
   * @param params The `GroupApiService.Group_CreateSharedInboxParams` containing the following parameters:
   *
   * - `sharedInbox`: SharedInboxRequest object
   *
   * - `getGroupByCreator`: Return group requsted by creator
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_CreateSharedInboxResponse(
    params: GroupApiService.Group_CreateSharedInboxParams,
    forUserEmail: string
  ): Observable<HttpResponse<Group>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/group/sharedinbox`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_CreateSharedInbox');

    // body, headers and query params
    __body = params.sharedInbox;
    if (params.getGroupByCreator != null)
      __params = __params.set('getGroupByCreator', params.getGroupByCreator.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Group;

          body = <Group>response.body;

          return <HttpResponse<Group>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_CreateSharedInboxParams` containing the following parameters:
   *
   * - `sharedInbox`: SharedInboxRequest object
   *
   * - `getGroupByCreator`: Return group requsted by creator
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_CreateSharedInbox(
    params: GroupApiService.Group_CreateSharedInboxParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Group> {
    const observable$ = this.Group_CreateSharedInboxResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/sharedinbox`;
      const url = this.rootUrl + `/api/v1/group/sharedinbox`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_CreateGroupParams` containing the following parameters:
   *
   * - `group`: Group object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_CreateGroupResponse(
    params: GroupApiService.Group_CreateGroupParams,
    forUserEmail: string
  ): Observable<HttpResponse<Group>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/group`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_CreateGroup');

    // body, headers and query params
    __body = params.group;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Group;

          body = <Group>response.body;

          return <HttpResponse<Group>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_CreateGroupParams` containing the following parameters:
   *
   * - `group`: Group object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_CreateGroup(
    params: GroupApiService.Group_CreateGroupParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Group> {
    const observable$ = this.Group_CreateGroupResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group`;
      const url = this.rootUrl + `/api/v1/group`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_GetGroupParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GetGroupResponse(
    params: GroupApiService.Group_GetGroupParams,
    forUserEmail: string
  ): Observable<HttpResponse<Group>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_GetGroup');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Group;

          body = <Group>response.body;

          return <HttpResponse<Group>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_GetGroupParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GetGroup(
    params: GroupApiService.Group_GetGroupParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Group> {
    const observable$ = this.Group_GetGroupResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_UpdateGroupParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `group`: Group object
   *
   * - `doNotAddSystemMessage`: Setting that chooses whether to add system message when adding/removing users in group or not
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_UpdateGroupResponse(
    params: GroupApiService.Group_UpdateGroupParams,
    forUserEmail: string
  ): Observable<HttpResponse<Group>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_UpdateGroup');

    // body, headers and query params
    __body = params.group;
    if (params.doNotAddSystemMessage != null)
      __params = __params.set('doNotAddSystemMessage', params.doNotAddSystemMessage.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Group;

          body = <Group>response.body;

          return <HttpResponse<Group>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_UpdateGroupParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `group`: Group object
   *
   * - `doNotAddSystemMessage`: Setting that chooses whether to add system message when adding/removing users in group or not
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_UpdateGroup(
    params: GroupApiService.Group_UpdateGroupParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Group> {
    const observable$ = this.Group_UpdateGroupResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_DeleteGroupParams` containing the following parameters:
   *
   * - `groupId`:
   *
   * - `X-Impersonate-User`:
   *
   * - `Authorization`:
   */
  Group_DeleteGroupResponse(
    params: GroupApiService.Group_DeleteGroupParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_DeleteGroup');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_DeleteGroupParams` containing the following parameters:
   *
   * - `groupId`:
   *
   * - `X-Impersonate-User`:
   *
   * - `Authorization`:
   */
  Group_DeleteGroup(
    params: GroupApiService.Group_DeleteGroupParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Group_DeleteGroupResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_GetListOfGroupsParams` containing the following parameters:
   *
   * - `tags`: List of tags to filter card list by.
   *
   * - `size`: Requested page size.
   *
   * - `searchQuery`: Plaintext search query to filter list of groups by.
   *
   * - `offset`: Requested page offset.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   *
   * @return ListOfResources<Group>
   */
  Group_GetListOfGroupsResponse(
    params: GroupApiService.Group_GetListOfGroupsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfGroup>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/group/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_GetListOfGroups');

    // body, headers and query params
    (params.tags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('tags', val.toString());
    });
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfGroup;

          body = <ListOfResourcesOfGroup>response.body;

          return <HttpResponse<ListOfResourcesOfGroup>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_GetListOfGroupsParams` containing the following parameters:
   *
   * - `tags`: List of tags to filter card list by.
   *
   * - `size`: Requested page size.
   *
   * - `searchQuery`: Plaintext search query to filter list of groups by.
   *
   * - `offset`: Requested page offset.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   *
   * @return ListOfResources<Group>
   */
  Group_GetListOfGroups(
    params: GroupApiService.Group_GetListOfGroupsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfGroup> {
    const observable$ = this.Group_GetListOfGroupsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/list`;
      const url = this.rootUrl + `/api/v1/group/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_GetGroupAvatarParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `imageSize`: Avatar size (all avatars are square - one dimension is enough, default is 256)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  Group_GetGroupAvatarResponse(
    params: GroupApiService.Group_GetGroupAvatarParams,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}/avatar`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_GetGroupAvatar');

    // body, headers and query params
    if (params.imageSize != null) __params = __params.set('imageSize', params.imageSize.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `GroupApiService.Group_GetGroupAvatarParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `imageSize`: Avatar size (all avatars are square - one dimension is enough, default is 256)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  Group_GetGroupAvatar(
    params: GroupApiService.Group_GetGroupAvatarParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.Group_GetGroupAvatarResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}/avatar`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}/avatar`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_UpdateGroupAvatarParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `file`: Avatar object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_UpdateGroupAvatarResponse(
    params: GroupApiService.Group_UpdateGroupAvatarParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}/avatar`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_UpdateGroupAvatar');

    // body, headers and query params
    let formData: FormData = new FormData();
    formData.append('file', params.file, params.file['name']);
    __body = formData;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_UpdateGroupAvatarParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `file`: Avatar object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_UpdateGroupAvatar(
    params: GroupApiService.Group_UpdateGroupAvatarParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Group_UpdateGroupAvatarResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}/avatar`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}/avatar`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_GetUserAvatarSignedLinkParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GetUserAvatarSignedLinkResponse(
    params: GroupApiService.Group_GetUserAvatarSignedLinkParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}/avatarsignedlink`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_GetUserAvatarSignedLink');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_GetUserAvatarSignedLinkParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GetUserAvatarSignedLink(
    params: GroupApiService.Group_GetUserAvatarSignedLinkParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Group_GetUserAvatarSignedLinkResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}/avatarsignedlink`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}/avatarsignedlink`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_GroupSubscribeParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GroupSubscribeResponse(
    params: GroupApiService.Group_GroupSubscribeParams,
    forUserEmail: string
  ): Observable<HttpResponse<Group>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}/subscribe`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_GroupSubscribe');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Group;

          body = <Group>response.body;

          return <HttpResponse<Group>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_GroupSubscribeParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GroupSubscribe(
    params: GroupApiService.Group_GroupSubscribeParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Group> {
    const observable$ = this.Group_GroupSubscribeResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}/subscribe`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}/subscribe`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_GroupUnSubscribeParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GroupUnSubscribeResponse(
    params: GroupApiService.Group_GroupUnSubscribeParams,
    forUserEmail: string
  ): Observable<HttpResponse<Group>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}/subscribe`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_GroupUnSubscribe');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Group;

          body = <Group>response.body;

          return <HttpResponse<Group>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_GroupUnSubscribeParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GroupUnSubscribe(
    params: GroupApiService.Group_GroupUnSubscribeParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Group> {
    const observable$ = this.Group_GroupUnSubscribeResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}/subscribe`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}/subscribe`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `GroupApiService.Group_GroupSetColourParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `colour`: Group colour
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GroupSetColourResponse(
    params: GroupApiService.Group_GroupSetColourParams,
    forUserEmail: string
  ): Observable<HttpResponse<Group>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/group/${params.groupId}/setcolour`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Group_GroupSetColour');

    // body, headers and query params
    if (params.colour != null) __params = __params.set('colour', params.colour.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Group;

          body = <Group>response.body;

          return <HttpResponse<Group>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `GroupApiService.Group_GroupSetColourParams` containing the following parameters:
   *
   * - `groupId`: Group id
   *
   * - `colour`: Group colour
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Group_GroupSetColour(
    params: GroupApiService.Group_GroupSetColourParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Group> {
    const observable$ = this.Group_GroupSetColourResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/group/${params.groupId}/setcolour`;
      const url = this.rootUrl + `/api/v1/group/${params.groupId}/setcolour`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module GroupApiService {
  /**
   * Parameters for Group_CreateSharedInbox
   */
  export type Group_CreateSharedInboxParams = {
    /**
     * SharedInboxRequest object
     */
    sharedInbox: SharedInboxRequest;

    /**
     * Return group requsted by creator
     */
    getGroupByCreator?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_CreateGroup
   */
  export type Group_CreateGroupParams = {
    /**
     * Group object
     */
    group: Group;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_GetGroup
   */
  export type Group_GetGroupParams = {
    /**
     * Group id
     */
    groupId: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_UpdateGroup
   */
  export type Group_UpdateGroupParams = {
    /**
     * Group id
     */
    groupId: string;

    /**
     * Group object
     */
    group: Group;

    /**
     * Setting that chooses whether to add system message when adding/removing users in group or not
     */
    doNotAddSystemMessage?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_DeleteGroup
   */
  export type Group_DeleteGroupParams = {
    groupId: string;

    XImpersonateUser?: string;

    Authorization?: string;
  };

  /**
   * Parameters for Group_GetListOfGroups
   */
  export type Group_GetListOfGroupsParams = {
    /**
     * List of tags to filter card list by.
     */
    tags?: string[];

    /**
     * Requested page size.
     */
    size?: number;

    /**
     * Plaintext search query to filter list of groups by.
     */
    searchQuery?: string;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_GetGroupAvatar
   */
  export type Group_GetGroupAvatarParams = {
    /**
     * Group id
     */
    groupId: string;

    /**
     * Avatar size (all avatars are square - one dimension is enough, default is 256)
     */
    imageSize?: number;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * EntityTag header
     */
    ETag?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_UpdateGroupAvatar
   */
  export type Group_UpdateGroupAvatarParams = {
    /**
     * Group id
     */
    groupId: string;

    /**
     * Avatar object
     */
    file?: Blob;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_GetUserAvatarSignedLink
   */
  export type Group_GetUserAvatarSignedLinkParams = {
    /**
     * Group id
     */
    groupId: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_GroupSubscribe
   */
  export type Group_GroupSubscribeParams = {
    /**
     * Group id
     */
    groupId: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_GroupUnSubscribe
   */
  export type Group_GroupUnSubscribeParams = {
    /**
     * Group id
     */
    groupId: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Group_GroupSetColour
   */
  export type Group_GroupSetColourParams = {
    /**
     * Group id
     */
    groupId: string;

    /**
     * Group colour
     */
    colour?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
