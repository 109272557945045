import { inject, Injectable } from '@angular/core';
import { SnoozeApiActionsModule } from '@shared/modules/tags/services/snooze-api-actions/snooze-api-actions.module';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { OptimisticResponseState } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { ConversationActionService } from '@shared/services/data/conversation-action/conversation-action.service';
import { ActionEnum } from '@shared/api/api-loop/models';
import { map, take, tap } from 'rxjs/operators';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { OptimisticResponseHelper } from '@dta/ui/components/common/conversation-list/optimistic-response.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';

@UntilDestroy()
@Injectable({
  providedIn: SnoozeApiActionsModule
})
export class SnoozeApiActionsService {
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly notificationsService: NotificationsService = inject(NotificationsService);
  private readonly trackingService: TrackingService = inject(TrackingService);
  private readonly conversationActionService: ConversationActionService = inject(ConversationActionService);

  discardSnooze$(conversation: ConversationModel): Observable<void> {
    conversation.removeAllSnoozeTagsOnCard();
    return this.conversationActionService
      .conversationAction(this.userManagerService.getCurrentUserEmail(), {
        action: ActionEnum.SNOOZE_DISCARD,
        conversationIds: [conversation.id],
        isSharedAction: false
      })
      .pipe(
        map(() => conversation),
        tap(() => {
          this.trackingService.trackUserClick(
            this.userManagerService.getCurrentUserEmail(),
            'SnoozeDropdown',
            'Discard Snooze',
            {
              conversationId: conversation.cardId
            }
          );

          this.notificationsService.setInAppNotification(this.userManagerService.getCurrentUserEmail(), {
            type: NotificationEventType.Snooze,
            msg: 'Snooze discarded'
          });
          OptimisticResponseHelper.triggerApplyOptimisticResponse(
            this.userManagerService.getCurrentUserEmail(),
            [conversation.id],
            OptimisticResponseState.TAGS_UPDATE,
            { listOfTags: conversation.tags }
          );
        }),
        take(1),
        untilDestroyed(this),
        map(() => undefined)
      );
  }

  setSnooze$(conversation: ConversationModel, time: string): Observable<void> {
    conversation.removeAllSnoozeTagsOnCard();
    conversation.setSnoozeTag(time);
    this.trackingService.trackUserClick(this.userManagerService.getCurrentUserEmail(), 'SnoozeDropdown', 'Set Snooze', {
      conversationId: conversation.cardId,
      snoozeTime: time
    });

    return this.conversationActionService
      .conversationAction(this.userManagerService.getCurrentUserEmail(), {
        action: ActionEnum.SNOOZE,
        conversationIds: [conversation.id],
        isSharedAction: false,
        dueDate: time
      })
      .pipe(
        tap(() => {
          this.notificationsService.setInAppNotification(this.userManagerService.getCurrentUserEmail(), {
            type: NotificationEventType.Snooze,
            msg: `Snoozed till ${moment(time).format('D MMM HH:mm')}`
          });

          OptimisticResponseHelper.triggerApplyOptimisticResponse(
            this.userManagerService.getCurrentUserEmail(),
            [conversation.id],
            OptimisticResponseState.TAGS_UPDATE,
            { listOfTags: conversation.tags }
          );
        }),
        map(() => undefined)
      );
  }
}
