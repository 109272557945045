import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { TagApiService } from '@shared/api/api-loop/services';
import { from, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { TagDaoService } from '@shared/database/dao/tag/tag-dao.service';
import { TagLabelModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { Tag } from '@shared/api/api-loop/models';
import { CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';

export interface TagLabelServiceI {
  findByIds(forUserEmail: string, tagIds: string[]): Observable<TagLabelModel[]>;
  fetchAndSaveTags(forUserEmail: string, tagIds: string[]): Observable<TagLabelModel[]>;
}

@Injectable()
export class TagLabelService implements TagLabelServiceI {
  constructor(
    private _tagApiService: TagApiService,
    private _tagDaoService: TagDaoService,
  ) {}

  findByIds(forUserEmail: string, tagIds: string[]): Observable<TagLabelModel[]> {
    return this._tagDaoService.findByIds(forUserEmail, tagIds);
  }

  fetchMissingCardTags(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    let allTagIds = cards
      .map(c => c.getTags())
      .flat()
      .filter(t => t.$type === TagLabelModel.type)
      .map(t => t.id);

    if (_.isEmpty(allTagIds)) {
      return of(cards);
    }

    return of(undefined).pipe(
      mergeMap(() => this._tagDaoService.findAllLabelTags(forUserEmail)),
      map((dbTags: TagLabelModel[]) =>
        _.difference(
          allTagIds,
          dbTags.map(t => t.id),
        ),
      ),
      mergeMap((missingTagIds: string[]) => this.fetchAndSaveTags(forUserEmail, missingTagIds)),
      map(() => cards),
    );
  }

  fetchAndSaveTags(forUserEmail: string, tagIds: string[]): Observable<TagLabelModel[]> {
    if (_.isEmpty(tagIds)) {
      return of([]);
    }

    return from(tagIds).pipe(
      mergeMap(id => this._tagApiService.Tag_Get({ id }, forUserEmail).pipe(catchError(() => of(undefined))), 5),
      filter(Boolean),
      toArray(),
      mergeMap((result: Tag[]) => this._tagDaoService.saveAll(forUserEmail, TagModel.createList(result))),
      defaultIfEmpty([]),
    );
  }
}
