import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ProcessType, StopWatch } from '../../../../../dta/shared/utils/stop-watch';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CommentBaseModel, CommentModel } from '../../../../../dta/shared/models-api-loop/comment/comment.model';
import {
  CardDraftModel,
  CardModel,
  CardSharedModel,
} from '../../../../../dta/shared/models-api-loop/conversation-card/card/card.model';
import { CardService } from '@shared/services/data/card/card.service';
import { CommentBase } from '@shared/api/api-loop/models';
import { StateUpdates } from '../../../../../dta/shared/models/state-updates';
import { CommentService } from '@shared/services/data/comment/comment.service';

@Injectable()
export class SingleCardCollectionService {
  constructor(
    private _commentService: CommentService,
    private _cardService: CardService,
  ) {}

  get constructorName(): string {
    return 'SingleCardCollectionService';
  }

  fetchCard(forUserEmail: string, cardId: string): Observable<CardModel> {
    let watch = new StopWatch(this.constructorName + '.fetchCard', ProcessType.SERVICE, forUserEmail);
    let stateUpdates = new StateUpdates();

    let cardToReturn: CardModel;

    return of(undefined).pipe(
      /**
       * Fetch card and its comments
       */
      mergeMap(() => {
        watch.log('getCardCommentsById');
        return this._cardService.getCardCommentsById(forUserEmail, cardId, true);
      }),
      /**
       * Fetch all missing source cards and comment drafts
       */
      mergeMap((card: CardModel) => {
        cardToReturn = _.cloneDeep(card);

        if (card instanceof CardSharedModel) {
          watch.log('fetchMissingSourceCards');
          return this._cardService.fetchMissingSourceCards(forUserEmail, [<CardSharedModel>card]);
        }

        if (card instanceof CardDraftModel) {
          watch.log('fetchMissingDraftComment');
          return this._commentService.fetchMissingDraftCommentForCard(forUserEmail, card, stateUpdates);
        }

        return of(stateUpdates);
      }),
      /**
       * Save and publish all card and comment updates
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);
        stateUpdates.add([cardToReturn]);

        watch.log('saveAndPublishAll');
        return this.saveAndPublishCardsAndComments(forUserEmail, stateUpdates, watch);
      }),
      /**
       * Return
       */
      map((savedCards: CardModel[]) => {
        watch.log('done');
        return _.find(savedCards, c => c.id === cardToReturn.id);
      }),
    );
  }

  private saveAndPublishCardsAndComments(
    forUserEmail: string,
    stateUpdates: StateUpdates,
    watch: StopWatch,
  ): Observable<CardModel[]> {
    return of(undefined).pipe(
      /**
       * Save and publish comments
       */
      mergeMap(() => {
        let commentsFromCards = this.getCommentsFromCards(stateUpdates.cards);
        let combinedComments = [...stateUpdates.comments, ...commentsFromCards];

        watch.log('save comments', combinedComments);
        return this._commentService.saveAllAndPublish(forUserEmail, combinedComments);
      }),
      /**
       * Save and publish cards
       */
      mergeMap(() => {
        watch.log('save cards: ', stateUpdates.cards.length);
        return this._cardService.saveAllAndPublish(forUserEmail, stateUpdates.cards);
      }),
    );
  }

  private getCommentsFromCards(cards: CardModel[]): CommentModel[] {
    let comments: CommentBase[] = _.flatten(_.map(cards, card => card.getComments()));
    return CommentBaseModel.createList(comments);
  }
}
