import * as _ from 'lodash';
import { v1 } from 'uuid';
import { Observable, Subject } from 'rxjs';
import {
  ActiveReplyData,
  AppScopeData,
  AppStatusNotification,
  ClearStorageCache,
  ConfirmationPopupData,
  ConnectionStatus,
  ContactSidebarChange,
  ContactStoreChange,
  CountersChangedTrigger,
  DesktopNotificationEventWrapper,
  DraftDeleted,
  FileUploadProgress,
  FollowUpSyncFor,
  HttpResponseEventData,
  HubEventActiveReplyData,
  HubEventUserTypingData,
  ModelActionData,
  ModelRemoved,
  NoticeBarData,
  NotificationEventWrapper,
  OptimisticResponseData,
  PowerStateStatus,
  PrefetchStatus,
  PublishEvent,
  RedoInitSyncRequired,
  SharedSubjectData,
  SharedSubjectScope,
  SystemInfoPopupData,
  Trigger,
  UnreadStateChange,
  UserAuthUpdate,
  UserRemove,
  UserScopeData,
  UserSet,
  UserSwitch,
  UserTokenInvalid,
  UserTrigger,
  UserTypingData,
  UserUpdate,
  WrittenFileNames
} from './shared-subjects-models';
import { filter, finalize } from 'rxjs/operators';
import { Broadcaster } from './broadcaster';
import { ResourceBaseType } from '@shared/api/common/contatns/ResourceBaseType';
import { EnumObjectValue } from '@shared/utils/common/types';
import { CommentDraftActions } from '@shared/modules/comments/common/constants/comment-draft-actions';
import { RESOURCE_BASE_TYPE_TO_ACTION } from '@shared/services/communication/shared-subjects/common/interfaces/resource-base-type-to-action';

export class SharedSubject<T extends SharedSubjectData> extends Subject<T> {
  private userEmailByObservableUuid: _.Dictionary<string> = {};

  constructor(
    private subjectName: string,
    private dataObject
  ) {
    super();
  }

  next(data: T, broadcast: boolean = true) {
    // Emit subject
    super.next(data);

    // Broadcast emit
    if (broadcast) {
      Broadcaster.broadcast(this.subjectName, data);
    }
  }

  forUserEmail(forUserEmail: string): Observable<T> {
    let uuid = v1();

    // Add observable to dictionary; mark user observer
    this.userEmailByObservableUuid[uuid] = forUserEmail;

    return this.pipe(
      filter((data: T) => {
        return data.$scope === SharedSubjectScope.USER && data['forUserEmail'] === forUserEmail;
      }),
      finalize(() => {
        delete this.userEmailByObservableUuid[uuid];
      })
    );
  }

  getScope(): SharedSubjectScope {
    return this.dataObject.scope;
  }

  getUserObserversCount(forUserEmail: string): number {
    let allObservedUsers = Object.values(this.userEmailByObservableUuid);
    return _.filter(allObservedUsers, email => email === forUserEmail).length;
  }
}

// [!] NOTE [!]
// When using user scope shared-subjects you MUST use 'forUserEmail()' method. DOn't filter
// for user inside pipe. 'forUserEmail()' makes sure that scout packets recognize user observables
//  o YES : _sharedSubject$.forUserEmail(<email>)
//  o NO  : _sharedSubject$.pipe(filter((data) => data.forUserEmail === forUserEmail) ...)

// Class for subjects that will be broadcasted and available to everyone
export class SharedSubjects {
  ////////////////
  // User changes
  ////////////////
  static _userSwitch$: SharedSubject<UserSwitch> = new SharedSubject('_userSwitch$', UserSwitch);
  static _userRemove$: SharedSubject<UserRemove> = new SharedSubject('_userRemove$', UserRemove);
  static _userUpdate$: SharedSubject<UserUpdate> = new SharedSubject('_userUpdate$', UserUpdate);
  static _resetLoggedInUsers$: SharedSubject<Trigger> = new SharedSubject('_resetLoggedInUsers$', Trigger);
  static _userAuthUpdate$: SharedSubject<UserAuthUpdate> = new SharedSubject('_userAuthUpdate$', UserAuthUpdate);
  static _userSet$: SharedSubject<UserSet> = new SharedSubject('_userSet$', UserSet);
  static _userInitiallySynced$: SharedSubject<AppScopeData> = new SharedSubject('_userInitiallySynced$', AppScopeData);

  ///////////////////////
  // App state subjects
  ///////////////////////
  static _connectionStatus$: SharedSubject<ConnectionStatus> = new SharedSubject(
    '_connectionStatus$',
    ConnectionStatus
  );
  static _powerState$: SharedSubject<PowerStateStatus> = new SharedSubject('_powerState$', PowerStateStatus);
  static _appStatus$: SharedSubject<AppStatusNotification> = new SharedSubject('_appStatus$', AppStatusNotification);
  static _httpResponseEvent$: SharedSubject<HttpResponseEventData> = new SharedSubject(
    '_httpResponseEvent$',
    HttpResponseEventData
  );

  //////////////////
  // Sync subjects
  //////////////////
  static _publishedEvents$: SharedSubject<PublishEvent> = new SharedSubject('_publishedEvents$', PublishEvent);
  static _localUserTyping$: SharedSubject<UserTypingData> = new SharedSubject('_localUserTyping$', UserTypingData);
  static _hubEventTyping$: SharedSubject<HubEventUserTypingData> = new SharedSubject(
    '_hubEventTyping$',
    HubEventUserTypingData
  );
  static _localActiveReply$: SharedSubject<ActiveReplyData> = new SharedSubject('_localActiveReply$', ActiveReplyData);
  static _hubEventActiveReply$: SharedSubject<HubEventActiveReplyData> = new SharedSubject(
    '_hubEventActiveReply$',
    HubEventActiveReplyData
  );
  static _followUpSyncFor$: SharedSubject<FollowUpSyncFor> = new SharedSubject('_followUpSyncFor$', FollowUpSyncFor);
  static _prefetchDone$: SharedSubject<UserScopeData> = new SharedSubject('_prefetchDone$', UserScopeData);
  static _prefetchStatus$: SharedSubject<PrefetchStatus> = new SharedSubject('_prefetchStatus$', PrefetchStatus);

  /////////////////
  // Store events
  /////////////////
  static _contactStoreChange$: SharedSubject<ContactStoreChange> = new SharedSubject(
    '_contactStoreChange$',
    ContactStoreChange
  );
  static _contactSidebarChange$: SharedSubject<ContactSidebarChange> = new SharedSubject(
    '_contactSidebarChange$',
    ContactSidebarChange
  );
  //////////////////
  // Notifications
  //////////////////
  static _inAppNotifications$: SharedSubject<NotificationEventWrapper> = new SharedSubject(
    '_inAppNotifications$',
    NotificationEventWrapper
  );
  static _desktopNotifications$: SharedSubject<DesktopNotificationEventWrapper> = new SharedSubject(
    '_desktopNotifications$',
    DesktopNotificationEventWrapper
  );
  static _noticeBarNotification$: SharedSubject<NoticeBarData> = new SharedSubject(
    '_noticeBarNotification$',
    NoticeBarData
  );

  ////////////////////////////
  // Triggers, events, ...
  ////////////////////////////
  static _writtenFileNames$: SharedSubject<WrittenFileNames> = new SharedSubject(
    '_writtenFileNames$',
    WrittenFileNames
  );
  static _fileUploadProgress$: SharedSubject<FileUploadProgress> = new SharedSubject(
    '_fileUploadProgres$',
    FileUploadProgress
  );
  static _userTokenInvalid$: SharedSubject<UserTokenInvalid> = new SharedSubject(
    '_userTokenInvalid$',
    UserTokenInvalid
  );
  static _redoInitSyncRequired$: SharedSubject<RedoInitSyncRequired> = new SharedSubject(
    '_redoInitSyncRequired$',
    RedoInitSyncRequired
  );
  static _settingsChange$: SharedSubject<UserTrigger> = new SharedSubject('_settingsChange$', UserTrigger);
  static _userLicenseUpdate$: SharedSubject<UserScopeData> = new SharedSubject('_userLicenseUpdate$', UserScopeData);
  static _systemInfoPopupOpen$: SharedSubject<SystemInfoPopupData> = new SharedSubject(
    '_systemInfoPopupOpen$',
    SystemInfoPopupData
  );
  static _confirmationPopup$: SharedSubject<ConfirmationPopupData> = new SharedSubject(
    '_confirmationPopup$',
    ConfirmationPopupData
  );
  static _unreadStateChange$: SharedSubject<UnreadStateChange> = new SharedSubject(
    '_unreadStateChange$',
    UnreadStateChange
  );
  static _countersChangedTrigger$: SharedSubject<CountersChangedTrigger> = new SharedSubject(
    '_countersChangedTrigger$',
    CountersChangedTrigger
  );

  static _conversationUpdated$: SharedSubject<UserTrigger> = new SharedSubject('_conversationUpdated$', UserTrigger);
  static _possibleModelRemoved$: SharedSubject<ModelRemoved> = new SharedSubject(
    '_possibleModelRemoved$',
    ModelRemoved
  );

  //////////////////////////////////////////
  // Optimistic response to apply to UI view
  //////////////////////////////////////////
  static _applyOptimisticResponse$: SharedSubject<OptimisticResponseData> = new SharedSubject(
    '_applyOptimisticResponse$',
    OptimisticResponseData
  );

  static _clearStorageCache: SharedSubject<ClearStorageCache> = new SharedSubject(
    '_clearStorageCache',
    ClearStorageCache
  );

  static _modelAction$: SharedSubject<
    ModelActionData<
      EnumObjectValue<typeof ResourceBaseType>,
      RESOURCE_BASE_TYPE_TO_ACTION<EnumObjectValue<typeof ResourceBaseType>>
    >
  > = new SharedSubject('_modelAction$', ModelActionData);
}
