import { CommentDraft } from '@shared/api/api-loop/models/comment-draft';

export const CommentDraftActions = {
  draftDeleted: 'draftDeleted',
  draftCreated: 'draftCreated'
} as const;

export type COMMENT_DRAFT_ACTION_TO_VALUE_MAPPER = {
  [CommentDraftActions.draftDeleted]: { draftCardId: string };
  [CommentDraftActions.draftCreated]: CommentDraft;
};
