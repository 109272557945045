<loop-form-control-wrapper [baseFormControl]="this" class="w-100">
  <div class="auto-suggest-wrapper flex flex-wrap items-center">
    <loop-icon
      *ngIf="isInvalidRecipientWarning"
      name="alert-circle"
      loopAtom="warning"
      width="14px"
      [loopHoverTooltip]="'Make sure all addresses are valid'"
    ></loop-icon>

    <ng-container *ngIf="areSelectedInPlace">
      <ng-container *ngFor="let selectedContact of value$ | async; let index = index">
        <loop-tag
          class="margin-quarter"
          [class.selected-contact]="contactToBeDeleted?.id === selectedContact.id"
          [value]="selectedContact?.name || selectedContact?.email"
          [loopHoverTooltip]="selectedContact?.email"
          [canRemove]="true"
          (remove)="toggleItem(selectedContact)"
        ></loop-tag>
      </ng-container>
    </ng-container>

    <div
      class="position-relative flex-1"
      style="min-width: 80px"
      outsideClick
      (onOutsideClick)="showAutoSuggest = false"
      (keydown.enter)="createEmptyContact({ shouldFocus: true })"
      (keydown.space)="createEmptyContact({ shouldFocus: true })"
      (keydown.backspace)="deleteContact()"
    >
      <input
        #inputElement
        [attr.placeholder]="placeholder"
        [ngModel]="searchValue"
        [ngClass]="size"
        (ngModelChange)="onSearchChange($event)"
        [class.warning-recipient]="isInvalidRecipientWarning"
        [id]="_id"
        [attr.disabled]="(isDisabled$ | async) ? '' : null"
        [disabled]="(isDisabled$ | async) === true"
        type="text"
        (focus)="onSearchFocus()"
        (blur)="createEmptyContact({ shouldFocus: false })"
        [autofocus]="false"
      />

      <div *ngIf="showAutoSuggest" class="autosuggest-wrapper">
        <loop-contacts-dropdown
          [contacts]="contacts$ | async"
          [selectedContacts]="value$ | async"
          (selectContact)="toggleItem($event)"
        ></loop-contacts-dropdown>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!areSelectedInPlace">
    <ng-container *ngFor="let selectedContact of value$ | async; let index = index">
      <ng-container *ngIf="customTemplateRenderer; else defaultList">
        <ng-container
          *ngTemplateOutlet="customTemplateRenderer; context: { contact: selectedContact, index: index }"
        ></ng-container>
      </ng-container>

      <ng-template #defaultList>
        <div class="item-list" (mouseenter)="hoveredIndex = index" (mouseleave)="hoveredIndex = null">
          <div class="auto-suggest-item selected" [class.disabled]="isDisabled$ | async">
            <ng-container *ngTemplateOutlet="contactTpl; context: { $implicit: selectedContact }"></ng-container>
            <div class="flex gap-x-half items-center">
              <svg-comp
                *ngIf="index === hoveredIndex"
                class="cursor-pointer"
                [src]="removeSvg"
                (click)="toggleItem(selectedContact)"
              ></svg-comp>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #contactTpl let-contact>
    <loop-contact [contact]="contact"></loop-contact>
  </ng-template>
</loop-form-control-wrapper>
