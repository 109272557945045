import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { KeyboardShortcutsListenerService } from '../../../services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { DropdownComponent } from '../../common/dropdown/dropdown.component';

@AutoUnsubscribe()
@Component({
  selector: 'select-dropdown',
  templateUrl: './select-dropdown.html',
  styleUrls: ['./select-dropdown.scss'],
  host: { class: 'select-dropdown' },
})
export class SelectDropdownComponent extends DropdownComponent implements OnInit, OnDestroy, AfterViewInit {
  ////////
  // SVGs
  ////////
  dropdownToggleSvg = require('shared/assets/img/svg/open-dropdown.svg');
  defaultCheckMarkSvg = require('shared/assets/img/svg/checkmark.svg');
  defaultMoreSvg = require('shared/assets/img/svg/more.svg');

  ///////////////////
  // Input / Output
  ///////////////////
  @Input() iconForToggle: boolean = false; // Button to open dropdown if false, only icon if true
  @Input() extraClass: string = '';
  @Input() capitalize: boolean = false;
  @Input() openBottomLeft: boolean = false;
  @Input() openBottomRight: boolean = false;
  @Input() options: SelectDropdownOptions;
  @Input() checkWidowProximity: boolean = false; // Check if dropdown is close to bottom
  @Input() bottomBuffer: number = 0;
  @Input() noTransitions: boolean = false;
  @Input() moreSvg: NodeRequire = this.defaultMoreSvg;
  @Input() checkMarkSvg: NodeRequire = this.defaultCheckMarkSvg;
  // When passing toggle content this flag is optional (can be automatically calculated)
  @Input() hasToggleContent: boolean = false;
  @Input() additionalDropdownOpenTarget: any;

  @Output() toggle$ = new EventEmitter();
  @Output() selectionChange$ = new EventEmitter();

  //////////////
  // Properties
  //////////////
  openTop: boolean = false;
  viewOptions: SelectDropdownOptions;
  subsectionStack: SubsectionStack;

  ////////////////
  // View queries
  ////////////////
  @ViewChild('optionsWrapper', { static: false }) optionsWrapper;
  @ViewChild('toggleContentRef', { static: false }) toggleContentRef;

  constructor(
    protected _elementRef: ElementRef,
    protected _changeDetection: ChangeDetectorRef,
    protected _keyboardShortcutListenerService: KeyboardShortcutsListenerService,
  ) {
    super(_elementRef, _changeDetection, _keyboardShortcutListenerService);
  }

  ngOnInit() {
    this.addKeyboardNavigationOptions();
    this.setInitialProperties();
  }

  ngAfterViewInit() {
    // Check if toggle content has been passed
    if (this.hasToggleContent) {
      return;
    }

    this.hasToggleContent = this.toggleContentRef.nativeElement.childNodes.length !== 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.setInitialProperties();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  toggleDropdown(event?: MouseEvent) {
    event?.stopPropagation();

    if (this.checkWidowProximity) {
      this.calculateOpenPosition();
    }

    super.toggleDropdown(event);
  }

  goToPreviousSection() {
    if (this.subsectionStack.isEmpty) {
      return;
    }

    this.subsectionStack.pop();
    this.setViewOptions();
  }

  getOptionId(option: string): string {
    return 'select-dropdown-' + option.toLowerCase().replace(/\s/g, '-').replace(/\./g, '-').replace(/\@/g, '-');
  }

  onSelect(event: MouseEvent, index: number) {
    event?.stopPropagation();
    const option = this.viewOptions.options[index];

    if (!option) {
      return;
    }

    if (option.subsection) {
      this.subsectionStack.push(index);
      this.setViewOptions();

      return;
    }

    this.selectionChange$.emit(option.option);
    this.closeDropdown();
  }

  onDropdownOpen() {
    this.toggle$.emit(true);
  }

  onDropdownClosed() {
    this.subsectionStack.clear();
    this.viewOptions = this.options;

    // Reset property, so open position can be calculated correctly
    this.openTop = false;
    this.toggle$.emit(false);
  }

  setSelectionIndex(index: number, mouseEvent: boolean = false) {
    if (this.viewOptions.options[index] && this.viewOptions.options[index].disabled) {
      return;
    }

    super.setSelectionIndex(index, mouseEvent);
  }

  protected increaseSelectionIndex() {
    const nextAvailableIndex = this.viewOptions.options
      .slice(this.selectionIndex + 1)
      .findIndex(option => !option.disabled);

    this.setSelectionIndex(this.selectionIndex + nextAvailableIndex + 1);
  }

  protected decreaseSelectionIndex() {
    const nextAvailableIndexReverse = this.viewOptions.options
      .slice(0, this.selectionIndex)
      .reverse()
      .findIndex(option => !option.disabled);

    this.setSelectionIndex(this.selectionIndex - nextAvailableIndexReverse - 1);
  }

  protected confirmListItemSelection() {
    this.onSelect(undefined, this.selectionIndex);
  }

  private setViewOptions() {
    this.viewOptions = this.options;

    this.subsectionStack.toArray.forEach(index => {
      this.viewOptions = this.viewOptions.options[index].subsection;
    });

    this.selectionIndex = -1;
    this.maxSelectionIndex = this.viewOptions.options.length - 1;
    this.triggerChangeDetection();
  }

  private openSubsection() {
    if (this.viewOptions.options[this.selectionIndex] && this.viewOptions.options[this.selectionIndex].subsection) {
      this.onSelect(undefined, this.selectionIndex);
    }
  }

  private calculateOpenPosition() {
    // this.optionsWrapper should be defined to calculate position
    if (!this.checkWidowProximity || !this.optionsWrapper) {
      return;
    }

    let isCloseToBottom =
      this.optionsWrapper.nativeElement.getBoundingClientRect().top +
        this.optionsWrapper.nativeElement.offsetHeight +
        this.bottomBuffer >
      window.innerHeight;

    if (isCloseToBottom !== this.openTop) {
      this.openTop = !this.openTop;
      this.triggerChangeDetection();
    }
  }

  private setInitialProperties() {
    this.viewOptions = this.options;
    this.maxSelectionIndex = this.viewOptions.options.length - 1;
    this.subsectionStack = new SubsectionStack();
  }

  private addKeyboardNavigationOptions() {
    this.keyboardActions['ArrowLeft'] = this.goToPreviousSection.bind(this);
    this.keyboardActions['ArrowRight'] = this.openSubsection.bind(this);
  }
}

export interface SelectDropdownOption {
  option: string;
  displayName?: string;
  subtitle?: string;
  svgIcon?: string;
  disabled?: boolean;
  additionalClass?: string;
  subsection?: SelectDropdownOptions;
  colorCircle?: string;
  hidden?: boolean;
  shortcut?: string;
  dividerAfter?: boolean;
}

export interface SelectDropdownOptions {
  options: SelectDropdownOption[];
  type?: string;
  title?: string;
  subtitle?: string;
  hasSelection?: boolean;
  selectionTypeRadio?: boolean;
  selectedOption?: string;
}

class SubsectionStack {
  private subsectionStack: number[];

  constructor() {
    this.subsectionStack = [];
  }

  get toArray(): number[] {
    return this.subsectionStack;
  }
  get isEmpty(): boolean {
    return this.subsectionStack.length === 0;
  }
  clear() {
    this.subsectionStack = [];
  }
  push(index: number) {
    this.subsectionStack.push(index);
  }
  pop() {
    this.subsectionStack.pop();
  }
}
