import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentRef, ElementRef, Injector, TemplateRef } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { BrowserTooltipRendererComponent } from '@shared/ui/tooltip/browser/tooltip-renderer/browser-tooltip-renderer.component';

export type TooltipPlacement = 'bottom' | 'top' | 'left' | 'right';
export type TooltipContent = string | TemplateRef<void> | null;
export interface TooltipInterface {
  content: TooltipContent;
  color?: TooltipColor;
}
export type TooltipColor = 'auto' | 'dark' | 'light';
export interface InitializedTooltip {
  setContent: (content: TooltipContent) => void;
  getContent: () => TooltipContent;
  setBackgroundColor: (color: 'auto' | 'dark' | 'light') => void;
  setPlacement: (placement: TooltipPlacement) => void;
  show: () => ElementRef;
  hide: () => void;
  destroy: () => void;
}
export const TooltipTrigger = {
  hover: 'hover',
  click: 'click',
} as const;

export type TooltipInitializer = null | ((injector: Injector, hostElement: ElementRef) => InitializedTooltip);

export type TooltipInitializers = Record<keyof typeof TooltipTrigger, TooltipInitializer>;

const TOOLTIP_POSITIONS: Record<TooltipPlacement, ConnectedPosition> = {
  top: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    panelClass: 'loop-tooltip-overlay--top',
  },
  bottom: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    panelClass: 'loop-tooltip-overlay--bottom',
  },
  left: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    panelClass: 'loop-tooltip-overlay--left',
  },
  right: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    panelClass: 'loop-tooltip-overlay--right',
  },
} as const;

const hoverTooltipInitializer: TooltipInitializer = (injector, hostElement): InitializedTooltip => {
  const overlayPositionBuilder = injector.get(OverlayPositionBuilder);

  let tooltipPlacement: TooltipPlacement = 'bottom';

  function createPositionStrategy(preferredPlacement: TooltipPlacement): PositionStrategy {
    const preferredPosition = TOOLTIP_POSITIONS[preferredPlacement];
    return overlayPositionBuilder
      .flexibleConnectedTo(hostElement)
      .withPositions([preferredPosition, ...Object.values(TOOLTIP_POSITIONS)]);
  }

  function createOverlayRef(): OverlayRef {
    return injector.get(Overlay).create({ positionStrategy: createPositionStrategy(tooltipPlacement) });
  }

  function updateOverlayRefPosition(): void {
    if (!overlayRef) {
      return;
    }
    overlayRef.updatePositionStrategy(createPositionStrategy(tooltipPlacement));
  }

  let overlayRef: OverlayRef | null = null;
  let tooltipContent: TooltipContent = null;
  let tooltipBackgroundColor: TooltipColor = 'auto';
  let _tooltipRef: ComponentRef<any> | null = null;

  return {
    setPlacement: (placement: TooltipPlacement) => {
      tooltipPlacement = placement;
      updateOverlayRefPosition();
    },
    setContent: (content: TooltipContent) => {
      tooltipContent = content;
    },
    getContent: () => {
      return tooltipContent;
    },
    setBackgroundColor: (color: TooltipColor) => {
      tooltipBackgroundColor = color;
    },
    show: () => {
      if (!overlayRef) {
        overlayRef = createOverlayRef();
      }

      if (overlayRef.hasAttached() && _tooltipRef) {
        return _tooltipRef.instance.elementRef;
      }

      const tooltipPortal = new ComponentPortal(BrowserTooltipRendererComponent);
      const tooltipRef: ComponentRef<BrowserTooltipRendererComponent> = overlayRef.attach(tooltipPortal);
      if (tooltipBackgroundColor === 'auto') {
        tooltipBackgroundColor = typeof tooltipContent === 'string' ? 'dark' : 'light';
      }
      tooltipRef.instance.setBackgroundColor(tooltipBackgroundColor);
      tooltipRef.instance.setContent(tooltipContent);
      _tooltipRef = tooltipRef;
      return tooltipRef.instance.elementRef;
    },
    hide: () => {
      overlayRef?.detach();
    },
    destroy: () => {
      overlayRef?.dispose();
    },
  };
};

export const BrowserTooltipInitializer: TooltipInitializers = {
  [TooltipTrigger.hover]: hoverTooltipInitializer,
  [TooltipTrigger.click]: hoverTooltipInitializer,
} as const;
