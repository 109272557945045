import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfJsComponent } from '@dta/ui/components/common/pdfjs/pdfjs.component';

@NgModule({
  declarations: [PdfJsComponent],
  imports: [CommonModule],
  exports: [PdfJsComponent]
})
export class PdfjsModule {}
