import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmDialogData } from './confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
  toggle: Subject<ConfirmDialogData> = new Subject();

  constructor() {}
}
