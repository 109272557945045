import { Injectable } from '@angular/core';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { CollectionNameWeb } from '../../database-schema';
import { filter, from, Observable, toArray } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DatabaseServiceWeb } from '../../database.service.web';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { Integration } from '@shared/api/api-loop/models/integration';
import { IntegrationDaoServiceI } from '@shared/database/dao/integrations/integrations-dao.service';
import { IntegrationType } from '@shared/api/api-loop/models/integration-type';

@Injectable()
export class IntegrationDaoServiceWeb
  extends BaseDaoServiceWeb<IntegrationModel, Integration>
  implements IntegrationDaoServiceI
{
  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.Integration;
  }

  get constructorName(): string {
    return 'IntegrationDaoServiceWeb';
  }

  protected toModel(doc: ResourceBase): IntegrationModel {
    return IntegrationModel.create(doc);
  }

  findAllIntegrations(forUserEmail: string): Observable<IntegrationModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.getAll(this.collectionName);
      }),
      map(docs => this.toModels(docs)),
    );
  }

  removeAllIntegrations(forUserEmail: string): Observable<any> {
    return this.removeAllInCollection(forUserEmail);
  }

  findByType(forUserEmail: string, integrationType: IntegrationType): Observable<IntegrationModel[]> {
    return this.findAllIntegrations(forUserEmail).pipe(
      mergeMap((integrations: IntegrationModel[]) => {
        return from(integrations);
      }),
      filter((integration: IntegrationModel) => integration.integrationType === integrationType),
      toArray(),
    );
  }
}
