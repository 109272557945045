import { Injectable } from '@angular/core';
import { CardService } from '@shared/services/data/card/card.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { LabelService } from '@shared/services/data/label/label.service';
import { UserService } from '@shared/services/data/user/user.service';
import { TagService } from '@shared/services/data/tag/tag.service';
import { FileService } from '@shared/services/data/file/file.service';
import { AuthService } from '@shared/services/data/auth/auth.service';
import { RetryQueueService } from '@shared/services/data/retry-queue/retry-queue.service';
import { FolderService } from '@shared/services/data/folder/folder.service';
import { SnoozeService } from '@shared/services/data/snooze/snooze.service';
import { CardUnreadService } from '@shared/services/data/card-unread/card-unread.service';
import { TagLabelService } from '@shared/services/data/tag-label/tag-label.service';
import { AvailabilityStatusService } from '@shared/services/data/availability-status/availability-status.service';
import { UserAvailabilityStatusService } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service';
import { CollectionService } from '@shared/services/data/collection/collection.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { IntegrationService } from '@shared/services/data/integration/integration.service';
import { ConversationChangeService } from '@shared/services/data/conversation-change/conversation-change.service';
import { UserAuthService } from '@shared/modules/auth/auth-data-access/user-auth.service';
import { SmartClassificationSharedTagApi } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/smart-classification-shared-tag-api';

@Injectable()
export class DataServiceBaseShared {}

@Injectable()
export class DataServiceShared extends DataServiceBaseShared {
  constructor(
    public CardService: CardService,
    public CommentService: CommentService,
    public ContactService: ContactService,
    public LabelService: LabelService,
    public UserService: UserService,
    public TagService: TagService,
    public TagLabelService: TagLabelService,
    public FileService: FileService,
    public AuthService: AuthService,
    public RetryQueueService: RetryQueueService,
    public FolderService: FolderService,
    public SnoozeService: SnoozeService,
    public CardUnreadService: CardUnreadService,
    public AvailabilityStatusService: AvailabilityStatusService,
    public UserAvailabilityStatusService: UserAvailabilityStatusService,
    public CollectionService: CollectionService,
    public ConversationService: ConversationService,
    public IntegrationService: IntegrationService,
    public ConversationChangeService: ConversationChangeService,
    public readonly UserAuthService: UserAuthService,
    public readonly smartClassificationSharedTagApi: SmartClassificationSharedTagApi
  ) {
    super();
  }
}
