import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomInputModule } from '@dta/ui/components/common/custom-input/custom-input.module';
import { AutosuggestComponent } from '@dta/ui/components/common/autosuggest/autosuggest.component';
import { AvatarModule } from '@dta/ui/components/common/avatar/avatar.module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { SleepClickEventModule } from '@dta/ui/directives/sleep-click-event/sleep-click-event.module';
import { OutsideClickModule } from '@dta/ui/directives/outside-click/outside-click.module';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';

@NgModule({
  declarations: [AutosuggestComponent],
  imports: [
    CommonModule,
    CustomInputModule,
    AvatarModule,
    GraphicsModule,
    SleepClickEventModule,
    OutsideClickModule,
    TooltipModule,
  ],
  exports: [AutosuggestComponent],
})
export class AutosuggestModule {}
