import { AuthExchange, AuthGoogle } from '@shared/api/api-loop/models';
import { AuthRemoteImap } from '@shared/api/api-loop/models/auth-remote-imap';
import { AuthMicrosoft } from '@shared/api/api-loop/models/auth-microsoft';
import { AuthImapSmtp } from '@shared/api/api-loop/models/auth-imap-smtp';

export type AuthUser = AuthExchange | AuthGoogle | AuthRemoteImap | AuthMicrosoft | AuthImapSmtp;

export enum EmailServiceType {
  google = 'Google',
  exchange = 'Exchange',
  microsoft = 'Microsoft',
  remoteImap = 'RemoteImap',
  imap = 'Imap',
  other = 'Other',
  notSupported = 'NotSupported',
}
