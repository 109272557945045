import { Injectable } from '@angular/core';
import {
  ContactExtraData,
  ContactModel,
  GroupModel,
  SendAsOptions,
  UserModel,
} from 'dta/shared/models-api-loop/contact/contact.model';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TagType } from '@shared/api/api-loop/models';
import { StringUtils } from '@dta/shared/utils/common-utils';
import { SharedTagExDecorateService } from '../shared-tag-ex-decorator/shared-tag-ex-decorate.service';
import { BaseExDecorateService } from '../base-ex-decorate.service';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel } from '@dta/shared/models/logger.model';

@Injectable()
export class ContactExDecorateService extends BaseExDecorateService<ContactModel> {
  constructor(private _sharedTagExDecorateService: SharedTagExDecorateService) {
    super();
  }

  decorateExtraData(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    if (!contact) {
      return of(undefined);
    }

    return this.decorateBaseExtraData(forUserEmail, contact).pipe(
      mergeMap((ex: ContactExtraData) => {
        if (contact instanceof GroupModel) {
          return this.decorateGroupExtraData(forUserEmail, ex, contact);
        }

        return of(ex);
      }),
      map((ex: ContactExtraData) => {
        contact._ex = ex;
        return contact;
      }),
    );
  }

  private decorateBaseExtraData(forUserEmail: string, contact: ContactModel): Observable<ContactExtraData> {
    let ex: ContactExtraData = {
      isNotAccessible: false,
      favorite: false,
      localName: '',
    };

    ex.favorite = this.isFavorite(contact);
    ex.localName = contact.name ? StringUtils.toLocale(contact.name) : '';

    if (contact.$type === GroupModel.type) {
      // If you can't get members of a team, you don't have access to it
      ex.isNotAccessible =
        (<GroupModel>contact).members === undefined || (<GroupModel>contact).members.resources.length === 0;
      if (ex.isNotAccessible) {
        Logger.customLog(`Group ${contact.id} was removed from the sidebar for user ${forUserEmail}`, LogLevel.INFO);
      }
    } else if (contact.$type === UserModel.type) {
      // If contact don't have weight, it is not on your contact list
      ex.isNotAccessible = !!(<UserModel>contact).weight === undefined;
    }

    return of(ex);
  }

  private decorateGroupExtraData(
    forUserEmail: string,
    ex: ContactExtraData,
    group: GroupModel,
  ): Observable<ContactExtraData> {
    if (!group.hasAvailableSharedTags()) {
      return of(ex);
    }

    if (group.allowedImpersonatedSenders && group.allowedImpersonatedSenders.resources.length > 0) {
      ex = this.setSendAsOptions(group, ex);
    }

    return this._sharedTagExDecorateService.decorateGroupAvailableSharedTags(group, forUserEmail).pipe(
      map(() => {
        return ex;
      }),
    );
  }

  private setSendAsOptions(group: GroupModel, ex: ContactExtraData): ContactExtraData {
    let impersonatedSender = group.allowedImpersonatedSenders.resources[0];
    let options: SendAsOptions = {
      impersonatedUser: impersonatedSender,
      groupId: group.id,
    };
    ex.sendAsOptions = options;
    return ex;
  }

  private isFavorite(contact: ContactModel): boolean {
    return contact.hasTagType(TagType.FAVORITE);
  }
}
