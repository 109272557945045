import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

@Injectable()
export class WorkspaceGuard implements CanActivate {
  constructor(
    private _userManager: UserManagerService,
    private _router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (this._userManager.isCurrentUserWorkspaceAdmin()) {
      return true;
    }

    this._router.navigate([route.data.redirectUrl]);
    return false;
  }
}
