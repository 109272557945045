// Import all Froala Editor plugins.
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/froala_editor.pkgd.min.js';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { ConfirmDialogComponent } from '@shared/modules/common/confirm-dialog/confirm-dialog.component';
import { EmptyStateComponent } from '@shared/modules/common/empty-state/empty-state.component';
import { SettingsService } from '@shared/services/settings/settings.service';
import { DecoratorModule } from '@shared/decorators/decorator.module';
import { AvailabilityStatusDialogComponent } from '@shared/modules/main/common/availability-status-dialog/availability-status-dialog.component';
import { CreateSharedInboxComponent } from '@shared/modules/main/user-settings/channels/create/create-inbox/create-shared-inbox/create-shared-inbox.component';
import { CreatePersonalInboxComponent } from '@shared/modules/main/user-settings/channels/create/create-inbox/create-personal-inbox/create-personal-inbox.component';
import { ContextMenuService } from '@shared/services/context-menu/context-menu.service';
import { ContextMenuComponent } from '@shared/modules/common/context-menu/context-menu.component';
import { DragBarComponent } from '@shared/modules/main/common/drag-bar/drag-bar.component';
import { FolderHelper } from '@shared/services/data/folder/folder-helper';
import { MoveToTeamComponent } from '@shared/ui/move-to-team/move-to-team.component';
import { DropdownModule } from '@shared/ui/dropdown/dropdown.module';
import { IsImageModule } from '@shared/modules/files/components/pipes/is-image/is-image.module';
import { IsPdfModule } from '@shared/modules/files/components/pipes/is-pdf/is-pdf.module';
import { IsVideoModule } from '@shared/modules/files/components/pipes/is-video/is-video.module';
import { DisplayNameModule } from '@shared/ui/pipes/display-name/display-name.module';
// todo: move all of the components below into shared folder
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { JoinPipeModule } from '@shared/ui/pipes/join/join-pipe.module';
import { ConversationHeaderModule } from '@shared/modules/conversations/components/conversation-header/conversation-header.module';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { FormControlWrapperModule } from '@shared/modules/form-controls/base/form-control-wrapper/form-control-wrapper.module';
import { ButtonModule } from '@shared/ui/button/button.module';
import { AgendaPipesModule } from '@shared/modules/agenda/pipes/agenda-pipes/agenda-pipes.module';
import { StartWithPipeModule } from '@shared/ui/pipes/start-with-pipe/start-with-pipe.module';
import { CheckboxControlModule } from '@shared/modules/form-controls/checkbox-control/checkbox-control.module';
import { SelectControlModule } from '@shared/modules/form-controls/select-control/select-control.module';
import { TagModule } from '@shared/ui/tag/tag.module';
import { AlertModule } from '@shared/ui/alert/alert.module';
import { BbcodeModule } from '@shared/modules/comments/components/pipes/bbcode/bbcode.module';
import { WysiwygControlModule } from '@shared/modules/form-controls/wysiwyg-control/wysiwyg-control.module';
import { IframeIntegrationModule } from '@shared/modules/main/plugins/integration/iframe-integration/iframe-integration.module';
import { AsPureArgumentForModule } from '@shared/ui/pipes/as-pure-argument-for/as-pure-argument-for.module';
import { AgendaFormModule } from '@shared/modules/agenda/agenda-form/agenda-form.module';
import { FocusStateNotifier } from '@dta/ui/directives/focus-state-notifier.directive';
import { TrackUserClickDirective } from '@dta/shared/services/tracking/track-user-click.directive';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { PrintComponent } from '@dta/ui/components/common/print/print.component';
import { Trim } from '@dta/ui/pipes/trim/trim.pipe';
import { TrackingDirective } from '@dta/shared/services/tracking/tracking.directive';
import { SomeoneIsTypingService } from '@dta/ui/services/someone-is-typing/someone-is-typing.service';
import { LabelsDisplayHeaderComponent } from '@dta/ui/components/common/labels-display-header/labels-display-header.component';
import { LabelTagComponent } from '@dta/ui/components/common/label-tag/label-tag.component';
import { LabelDropdownComponent } from '@dta/ui/components/common/label-dropdown/label-dropdown.component';
import { AutofocusModule } from '@dta/ui/directives/autofocus.directive';
import { CollisionDetectionService } from '@dta/ui/services/collision-detection/collision-detection.service';
import { ThumbnailComponent } from '@dta/ui/components/search/thumbnail/thumbnail.component';
import { GettingStartedComponent } from '@dta/ui/components/getting-started/getting-started.component';
import { GettingStartedCardComponent } from '@dta/ui/components/getting-started/getting-started-card/getting-started-card.component';
import { SearchFilterPipe } from '@dta/ui/pipes/search-filter/search-filter.pipe';
import { SuggestedContactsComponent } from '@dta/ui/components/common/suggested-contacts/suggested-contacts.component';
import { SimpleInputComponent } from '@dta/ui/components/common/simple-input/simple-input.component';
import { SnoozeDropdownComponent } from '@dta/ui/components/snooze-dropdown/snooze-dropdown.component';
import { FromSelectorService } from '@dta/ui/components/common/from-selector/from-selector.service';
import { UrlifyPipe } from '@dta/ui/pipes/urlify/urlify.pipe';
import { SkeletonLoaderComponent } from '@dta/ui/components/common/loaders/skeleton-loader/skeleton-loader.component';
import { ReversePipe } from '@dta/ui/pipes/reverse.pipe';
import { ScrollNearEndDirective } from '@dta/ui/directives/scroll-near-end.directive';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SelectDropdownComponent } from '@dta/ui/components/common/select-dropdown/select-dropdown.component';
import { Modal } from '@dta/ui/components/common/modal/modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LabelsDropdownComponent } from '@dta/ui/components/common/labels-dropdown/labels-dropdown.component';
import { SafeModule } from '@dta/ui/pipes/safe/safe.module';
import { UserNameModule } from '@dta/ui/pipes/user-name/user-name.module';
import { AvatarModule } from '@dta/ui/components/common/avatar/avatar.module';
import { TimeAgoModule } from '@dta/ui/components/common/time-ago/time-ago.module';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';
import { SleepClickEventModule } from '@dta/ui/directives/sleep-click-event/sleep-click-event.module';
import { LoaderModule } from '@dta/ui/components/common/loader/loader.module';
import { FileIconModule } from '@dta/ui/components/common/file-icon/file-icon.module';
import { FileSizeModule } from '@dta/ui/pipes/file-size/file-size.module';
import { EmojiPickerModule } from '@dta/ui/components/common/emoji-picker/emoji-picker.module';
import { GifPickerModule } from '@dta/ui/components/common/gif-picker/gif-picker.module';
import { OutsideClickModule } from '@dta/ui/directives/outside-click/outside-click.module';
import { AssignDropdownModule } from '@dta/ui/components/common/assign-dropdown/assign-dropdown.module';
import { CustomInputModule } from '@dta/ui/components/common/custom-input/custom-input.module';
import { DatePickerModule } from '@dta/ui/components/date-picker/date-picker.module';
import { RsvpInfoModule } from '@dta/ui/components/email-card/rsvp/rsvp-info/rsvp-info.module';
import { LoaderNewModule } from '@dta/ui/components/common/loader-new/loader-new.module';
import { RsvpActionModule } from '@dta/ui/components/email-card/rsvp/rsvp-action/rsvp-action.module';
import { VerticalResizeModule } from '@dta/shared/components/vertical-resize/vertical-resize.module';
import { ChipsModule } from '@dta/ui/components/common/chips/chips.module';
import { AutosuggestModule } from '@dta/ui/components/common/autosuggest/autosuggest.module';
import { SharelistTextModule } from '@dta/ui/components/common/sharelist-text/sharelist-text.module';
import { HiglightPipeModule } from '@dta/ui/pipes/highlight/higlight-pipe.module';
import { FromSelectorModule } from '@dta/ui/components/common/from-selector/from-selector.module';
import { BotResponseRatingModule } from '@dta/ui/components/post-comment/bot-response-rating/bot-response-rating.module';
import { SubjectPipeModule } from '@dta/ui/pipes/subject/subject-pipe.module';
import { PostCommentPlaceholderModule } from '@dta/ui/components/post-comment/post-comment-placeholder/post-comment-placeholder.module';
import { BatchSelectModule } from '@dta/ui/components/batch-select/batch-select.module';
import { TimeDividerModule } from '@dta/ui/components/common/time-divider/time-divider.module';
import { TypingIndicatorModule } from '@dta/ui/components/common/typing-indicator/typing-indicator.module';
import { PdfjsModule } from '@dta/ui/components/common/pdfjs/pdfjs.module';
import { VideoModule } from '@dta/ui/components/common/video/video.module';
import { NotificationCardModule } from '@dta/ui/components/common/notification-card/notification-card.module';
import { ChannelsSettingsComponent } from '../main/user-settings/channels/channels-settings.component';
import { CreateSuccessfulComponent } from '../main/user-settings/channels/create/create-successful/create-successful.component';
import { CreateChannelSidebarComponent } from '../main/user-settings/channels/create/create-channel-sidebar/create-channel-sidebar.component';
import { CreateTeamComponent } from '../main/user-settings/channels/create/create-team/create-team.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { TeamsEmptyStateComponent } from './empty-state/teams-empty-state/teams-empty-state.component';
import { SharedInboxesEmptyStateComponent } from './empty-state/shared-inboxes-empty-state/shared-inboxes-empty-state.component';

const commonComponents = [
  ConfirmDialogComponent,
  SelectDropdownComponent,
  Modal,
  EmptyStateComponent,
  TeamsEmptyStateComponent,
  SharedInboxesEmptyStateComponent,
  FocusStateNotifier,
  MoveToTeamComponent,
  LabelsDisplayHeaderComponent,
  LabelTagComponent,
  LabelDropdownComponent,
  SnoozeDropdownComponent,
  SimpleInputComponent,
  SuggestedContactsComponent,
  ChannelsSettingsComponent,
  CreateSharedInboxComponent,
  CreatePersonalInboxComponent,
  CreateTeamComponent,
  CreateChannelSidebarComponent,
  CreateSuccessfulComponent,
  PrintComponent,
  SkeletonLoaderComponent,
  GettingStartedComponent,
  GettingStartedCardComponent,
  ThumbnailComponent,
  AvailabilityStatusDialogComponent,
  ContextMenuComponent,
  DragBarComponent,
  LabelsDropdownComponent
];

const commonDirectives = [TrackUserClickDirective, TrackingDirective, ScrollNearEndDirective];

const commonPipes = [Trim, UrlifyPipe, SearchFilterPipe, ReversePipe];

const commonModules = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  PickerModule,
  AutofocusModule,
  DropdownModule,
  CdkMenuModule,
  SafeModule,
  UserNameModule,
  IsImageModule,
  IsPdfModule,
  IsVideoModule,
  DisplayNameModule,
  AvatarModule,
  TimeAgoModule,
  TooltipModule,
  GraphicsModule,
  IconModule,
  SleepClickEventModule,
  LoaderModule,
  FileIconModule,
  FileSizeModule,
  EmojiPickerModule,
  GifPickerModule,
  OutsideClickModule,
  JoinPipeModule,
  ConversationHeaderModule,
  AssignDropdownModule,
  CustomInputModule,
  TextControlModule,
  FormControlWrapperModule,
  DatePickerModule,
  RsvpInfoModule,
  ButtonModule,
  LoaderNewModule,
  RsvpActionModule,
  AgendaPipesModule,
  StartWithPipeModule,
  VerticalResizeModule,
  ChipsModule,
  AutosuggestModule,
  SharelistTextModule,
  HiglightPipeModule,
  FromSelectorModule,
  BotResponseRatingModule,
  SubjectPipeModule,
  PostCommentPlaceholderModule,
  BatchSelectModule,
  FroalaEditorModule,
  TimeDividerModule,
  TypingIndicatorModule,
  CheckboxControlModule,
  SelectControlModule,
  TagModule,
  AlertModule,
  BbcodeModule,
  WysiwygControlModule,
  IframeIntegrationModule,
  AsPureArgumentForModule,
  PdfjsModule,
  VideoModule,
  NotificationCardModule,
  AgendaFormModule
];

@NgModule({
  imports: [CommonModule, BsDropdownModule.forRoot(), FroalaViewModule.forRoot(), DecoratorModule, ...commonModules],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [...commonComponents, ...commonDirectives, ...commonPipes],
  providers: [
    CollisionDetectionService,
    ConfirmDialogService,
    SettingsService,
    SomeoneIsTypingService,
    FromSelectorService,
    FolderHelper,
    ContextMenuService
  ],
  exports: [...commonComponents, ...commonDirectives, ...commonModules, ...commonPipes]
})
// todo: create CommonModule for main-view only and move most of the stuff there
export class CommonLoopModule {}
