import { format, Locale } from 'date-fns';
import { DateFormat } from '../../common/enumerators/date-format';
import { DateFormatter, DateFormatterResource } from './date-formatter';
import { parseDateWithFormats } from '@shared/shared/utils/common/parse-date';

const ACCEPTABLE_DATE_FORMATS: readonly string[] = [
  'd. M. yy', // 1. 2. 20
  'd.M. yy', // 1.2. 20
  'd. M.yy', // 1. 2.20
  'd.M.yy', // 1.2.20
  'dd. MM. yy', // 01. 02. 20
  'dd.MM. yy', // 01.02. 20
  'dd. MM.yy', // 01. 02.20
  'dd.MM.yy', // 01.02.20
  'd. M. yyyy', // 1. 2. 2000
  'd.M. yyyy', // 1.2. 2000
  'd. M.yyyy', // 1. 2.2000
  'd.M.yyyy', // 1.2.2000
  'dd. MM. yyyy', // 01. 02. 2000
  'dd.MM. yyyy', // 01.02. 2000
  'dd. MM.yyyy', // 01. 02.2000
  'dd.MM.yyyy', // 01.02.2000
  'dd. MM', // 01. 02
  'dd.MM', // 01.02
  'd. M', // 1. 2
  'd.M', // 1.2
  'dd. MM.', // 01. 02.
  'dd.MM.', // 01.02.
  'd.M.', // 1.2.
  'd. M.', // 1. 2.

  // 1/2/20
  'd/M/yy',
  'd/M/ yy',
  'd/ M/yy',
  'd /M/yy',
  'd/M /yy',
  'd/ M/ yy',
  'd/ M /yy',
  'd / M/yy',
  'd /M/ yy',
  'd /M /yy',
  'd/M / yy',
  'd/ M / yy',
  'd / M /yy',
  'd / M/ yy',
  'd /M / yy',
  'd / M / yy',

  // 01/02/20
  'dd/MM/yy',
  'dd /MM/yy',
  'dd/MM /yy',
  'dd/MM/ yy',
  'dd/ MM/yy',
  'dd /MM/ yy',
  'dd /MM /yy',
  'dd / MM/yy',
  'dd/ MM/ yy',
  'dd/MM / yy',
  'dd/ MM /yy',
  'dd / MM /yy',
  'dd/ MM / yy',
  'dd /MM / yy',
  'dd / MM/ yy',
  'dd / MM / yy',

  // 1/2/2000
  'd/M/yyyy',
  'd/ M/yyyy',
  'd/M/ yyyy',
  'd/M /yyyy',
  'd /M/yyyy',
  'd / M/yyyy',
  'd/ M /yyyy',
  'd /M /yyyy',
  'd/ M/ yyyy',
  'd/M / yyyy',
  'd /M/ yyyy',
  'd /M / yyyy',
  'd / M/ yyyy',
  'd / M /yyyy',
  'd/ M / yyyy',
  'd / M / yyyy',

  // 01/02/2000
  'dd/MM/yyyy',
  'dd /MM/yyyy',
  'dd/MM /yyyy',
  'dd/MM/ yyyy',
  'dd/ MM/yyyy',
  'dd/ MM/ yyyy',
  'dd/ MM /yyyy',
  'dd/MM / yyyy',
  'dd /MM/ yyyy',
  'dd /MM /yyyy',
  'dd / MM/yyyy',
  'dd/ MM / yyyy',
  'dd / MM /yyyy',
  'dd /MM / yyyy',
  'dd / MM/ yyyy',
  'dd / MM / yyyy',

  // 01/02
  'dd/MM',
  'dd /MM',
  'dd/ MM',
  'dd / MM',

  // 1/2
  'd/M',
  'd /M',
  'd/ M',
  'd / M'
] as const;

export abstract class BaseDateFormatterResource implements DateFormatterResource {
  abstract readonly dateFnsLocale: Locale;
  abstract readonly dateFormats: { [key in DateFormat]: DateFormatter };

  formatDate(date: Date, dateFormat: DateFormat): string {
    const dateFormatter: DateFormatter = this.dateFormats[dateFormat];

    if (typeof dateFormatter === 'function') {
      return dateFormatter(date);
    }

    return this.formatDateWithLocale(date, dateFormatter);
  }

  parseDate(input: string): Date | null {
    return parseDateWithFormats(input, ACCEPTABLE_DATE_FORMATS);
  }

  protected formatDateWithLocale(date: Date, dateFormat: string): string {
    return format(date, dateFormat, { locale: this.dateFnsLocale });
  }
}
