import { Injectable } from '@angular/core';
import { FileModel } from 'dta/shared/models-api-loop/file.model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LoopContextMenuService {
  ////////////////////
  // State variables
  ////////////////////
  private attachmentOnClipboard: FileModel;

  ////////////
  // Subjects
  ////////////
  private showContextMenuSubject: Subject<ContextMenuData> = new Subject<ContextMenuData>();
  private hideContextMenuSubject: Subject<boolean> = new Subject<boolean>();
  private cutActionSubject: Subject<boolean> = new Subject<boolean>();
  private copyActionSubject: Subject<boolean> = new Subject<boolean>();
  private pasteActionSubject: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  get showMenu$(): Observable<ContextMenuData> {
    return this.showContextMenuSubject.asObservable();
  }

  get closeMenu$(): Observable<boolean> {
    return this.hideContextMenuSubject.asObservable();
  }

  get cut$(): Observable<boolean> {
    return this.cutActionSubject.asObservable();
  }

  get copy$(): Observable<boolean> {
    return this.copyActionSubject.asObservable();
  }

  get paste$(): Observable<boolean> {
    return this.pasteActionSubject.asObservable();
  }

  get attachmentOnClipboard$(): FileModel {
    return this.attachmentOnClipboard;
  }

  showMenu($event: ContextMenuData) {
    this.showContextMenuSubject.next($event);
  }

  closeMenu() {
    this.hideContextMenuSubject.next(true);
  }

  copyAction() {
    this.copyActionSubject.next(true);
  }

  cutAction() {
    this.cutActionSubject.next(true);
  }

  pasteAction() {
    this.pasteActionSubject.next(true);
  }

  setAttachmentOnClipboard(file: FileModel) {
    this.attachmentOnClipboard = file;
  }
}

export interface ContextMenuData {
  x: number;
  y: number;
  allowedActions: ContextMenuActions;
  attachmentElement?: string;
  suggestedWords?: string[];
  eventTarget: EventTarget;
}

export interface ContextMenuActions {
  pasteAllowed?: boolean;
  copyAllowed?: boolean;
  cutAllowed?: boolean;
  canCopyAttachment?: boolean;
  canPasteInline?: boolean;
  canCopyLink?: string;
  canCopyImage?: string;
  canPasteText?: boolean;
}
