import { EnumObjectValue } from '@shared/utils/common/types';

export const InboxSettingsScopeProperty = {
  inbox_badge: 'inbox_badge'
} as const;

export const InboxBadgeCountType = {
  unreadBadge: 'unreadBadge',
  totalBadge: 'totalBadge'
} as const;

export interface InboxSettingsScope {
  [InboxSettingsScopeProperty.inbox_badge]?: EnumObjectValue<typeof InboxBadgeCountType>;
}
