import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { BasePopulateService } from '../base-populate/base-populate.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { SharedTagAssigneeModel, SharedTagModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';

@Injectable()
export class SharedTagPopulateService extends BasePopulateService<SharedTagModel> {
  constructor(
    protected _contactService: ContactService,
    protected _contactStoreFactory: ContactStoreFactory,
  ) {
    super(_contactService, _contactStoreFactory);
  }

  get constructorName(): string {
    return 'SharedTagPopulateService';
  }

  populate(forUserEmail: string, sharedTags: SharedTagModel[]): Observable<SharedTagModel[]> {
    return this.populateWithContacts(forUserEmail, sharedTags);
  }

  reduce(forUserEmail: string, sharedTags: SharedTagModel[]): Observable<SharedTagModel[]> {
    return this.contactsToReducedForm(forUserEmail, sharedTags);
  }

  private populateWithContacts(forUserEmail: string, sharedTags: SharedTagModel[]): Observable<SharedTagModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);

    return from(sharedTags).pipe(
      /**
       * Populate shared tags with user (atm only assignee)
       */
      mergeMap((sharedTag: SharedTagModel) => {
        if (sharedTag.$type !== SharedTagAssigneeModel.type) {
          return of(sharedTag);
        }

        // Get contact from store and populate shared tag
        return contactStore.getContactById((<SharedTagAssigneeModel>sharedTag).userId).pipe(
          map((contact: ContactModel) => {
            (<SharedTagAssigneeModel>sharedTag).populateWithContact(contact);
            return sharedTag;
          }),
        );
      }),
      toArray(),
    );
  }

  private contactsToReducedForm(forUserEmail: string, sharedTags: SharedTagModel[]): Observable<SharedTagModel[]> {
    /**
     * Reduce shared tag based on type
     */
    let reducedSharedTags = _.map(sharedTags, (sharedTag: SharedTagModel) => {
      // Remove disabled property as it is added by DTA
      delete sharedTag.disabled;

      switch (sharedTag.$type) {
        case SharedTagAssigneeModel.type:
          (<SharedTagAssigneeModel>sharedTag).assigneeToReducedForm();
          break;
        default:
          break;
      }

      return sharedTag;
    });

    return of(reducedSharedTags);
  }
}
