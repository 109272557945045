/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { LogConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LogEntry } from '../models/log-entry';

@Injectable()
export class LogApiService extends BaseService {
  constructor(
    protected _config: LogConfiguration,
    protected _http: HttpClient
  ) {
    super(_config, _http);
  }

  /**
   * @param params The `LogApiService.Log_ClientLogParams` containing the following parameters:
   *
   * - `logEntries`:
   */
  Log_ClientLogResponse(params: LogApiService.Log_ClientLogParams, bearer: string): Observable<HttpResponse<void>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/log`;
    let __headers = this.newHeaders(bearer);
    let __params = this.newParams();
    let __body: any;

    // body, headers and query params
    __body = params.logEntries;

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `LogApiService.Log_ClientLogParams` containing the following parameters:
   *
   * - `logEntries`:
   */
  Log_ClientLog(params: LogApiService.Log_ClientLogParams, bearer: string): Observable<void> {
    return this.Log_ClientLogResponse(params, bearer).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );
  }
}

export module LogApiService {
  /**
   * Parameters for Log_ClientLog
   */
  export interface Log_ClientLogParams {
    logEntries: LogEntry[];
  }
}
