<div class="dialog-container">
  <div class="content-container flex gap-x-half items-center padding-bottom">
    <ng-container *ngIf="params && params.descriptionOptions; else defaultDescriptionTpl">
      <ng-container
        *ngIf="params.descriptionOptions.descriptionType === DescriptionType_Alert; else defaultDescriptionTpl"
      >
        <loop-alert class="w-100" [loopAtom]="params.descriptionOptions.atomType" [content]="params?.description">
        </loop-alert>
      </ng-container>
    </ng-container>
    <ng-template #defaultDescriptionTpl>
      <div class="description flex-1">{{ params?.description }}</div>
    </ng-template>
  </div>
  <div class="buttons-container">
    <loop-button (click)="confirm()" (keydown.enter)="confirm()" color="primary" fill="color">
      {{ params?.confirmMessage }}
    </loop-button>
  </div>
</div>
