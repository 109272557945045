<span class="dropdown">
  <!-- DROPDOWN TOGGLE -->
  <div class="dropdown-toggle" [class.opened]="open" (click)="toggleDropdown()">
    <!-- TAG CONTAINER -->
    <label-tag
      *ngIf="labelTag.name"
      [content]="labelTag.name"
      [isPrivate]="isPrivate"
      [isOnHeader]="true"
      [titleContent]="labelTag.name"
      [color]="labelTag.color"
    >
    </label-tag>
  </div>

  <!-- DROPDOWN MENU -->
  <div *ngIf="open" class="dropdown-menu">
    <!-- DROPDOWN ITEM -->
    <div class="dropdown-item" (click)="onClickRemove(labelTag)">
      <svg-comp [src]="removeTagSvg"> </svg-comp>

      Remove
    </div>
  </div>
</span>
