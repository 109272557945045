import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentionComponent } from '@shared/modules/contacts/components/mention/mention.component';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';
import { MentionDirective } from './mention.directive';

@NgModule({
  declarations: [MentionDirective, MentionComponent],
  imports: [CommonModule, ContactModule],
  exports: [MentionDirective, MentionComponent],
})
export class MentionModule {}
