import * as _ from 'lodash';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';

export abstract class BasePopulateService<T extends BaseModel> {
  constructor(
    protected _contactService: ContactService,
    protected _contactStoreFactory: ContactStoreFactory,
  ) {}

  abstract get constructorName(): string;

  abstract populate(forUserEmail: string, cards: T[]): Observable<T[]>;

  abstract reduce(forUserEmail: string, cards: T[]): Observable<T[]>;

  findOrFetchContactsByCard(
    forUserEmail: string,
    undecoratedContactsIds: string[],
    authorizedCardId: string,
  ): Observable<ContactModel[]> {
    return of(undefined).pipe(
      /**
       * Try to get contacts from store (local and fastest)
       */
      mergeMap(() => this.findContactsByCard(forUserEmail, undecoratedContactsIds)),
      /**
       * Fetch any unknown contacts (authorize by parent. Will not work with teams)
       */
      mergeMap((localContacts: ContactModel[]) => {
        let filteredContacts = _.filter(localContacts, (contact: ContactModel) => !_.isEmpty(contact));

        return this.fetchUnknownContactsByParent(
          forUserEmail,
          undecoratedContactsIds,
          filteredContacts,
          authorizedCardId,
        );
      }),
      // Tmp for debugging on production
      ////////////////////////////////////////////// - remove once debugged
      map((contacts: ContactModel[]) => {
        let falseState =
          contacts.length < undecoratedContactsIds.length || // Missing contacts
          _.some(contacts, c => !c); // Contact undefined

        if (falseState) {
          let contactsIds = _.map(contacts, c => c && c.id);
          let diff = _.xor(contactsIds, undecoratedContactsIds);

          // Log
          Logger.customLog(
            `False state when decorating contact for ${forUserEmail}. ` +
              `Missing contact ids: ${diff.join(', ')} for card with id: ${authorizedCardId}`,
            LogLevel.WARN,
            LogTag.INTERESTING_ERROR,
            true,
            'False state when decorating contacts',
          );

          // Remove undefined contact
          contacts = _.filter(contacts, c => !_.isNil(c));
        }

        return contacts;
      }),
      ////////////////////////////////////////////// - remove once debugged
    );
  }

  findContactsByCard(
    forUserEmail: string,
    undecoratedContactsIds: string[],
    undefinedIfMissing?: boolean,
  ): Observable<ContactModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getContactsByIds(undecoratedContactsIds, undefinedIfMissing);
  }

  private fetchUnknownContactsByParent(
    forUserEmail: string,
    undecoratedContactsIds: string[],
    localContacts: ContactModel[],
    authorizedCardId: string,
  ): Observable<ContactModel[]> {
    let unknownContactIds = this.filterUnknownContactIds(localContacts, undecoratedContactsIds);

    if (_.isEmpty(unknownContactIds) || !authorizedCardId) {
      return of(localContacts);
    }

    return of(undefined).pipe(
      /**
       * Fetch users for parent
       */
      mergeMap(() => {
        return this._contactService.fetchUnknownUsersByIds(
          forUserEmail,
          this.mapParentIdWithContactIds(unknownContactIds, authorizedCardId),
        );
      }),
      mergeMap((contacts: ContactModel[]) => {
        return this._contactService.saveAll(forUserEmail, contacts);
      }),
      /**
       * Catch, log and handle error. Return empty
       */
      catchError(err => {
        Logger.error(
          err,
          `Could not fetch unknown users ${unknownContactIds.join(',')} for card with id ${authorizedCardId} for user: ${forUserEmail}`,
          LogTag.INTERESTING_ERROR,
          true,
          'Could not fetch unknown users by auth card',
        );

        return of([]);
      }),
      /**
       * Merge both local and new contacts
       */
      map((unknownContacts: ContactModel[]) => {
        return [...unknownContacts, ...localContacts];
      }),
    );
  }

  ///////////
  // Helpers
  ///////////
  private filterUnknownContactIds(localContacts: ContactModel[], undecoratedContactsIds: string[]): string[] {
    let contactsIds = _.map(localContacts, c => c && c.id);
    return _.xor(contactsIds, undecoratedContactsIds);
  }

  private mapParentIdWithContactIds(contactIds: string[], authorizedCardId: string): _.Dictionary<string> {
    let parentIdByUserId = {};

    _.forEach(contactIds, contactId => {
      parentIdByUserId[contactId] = authorizedCardId;
    });

    return parentIdByUserId;
  }
}
