import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AvailabilityUserStatus } from '@shared/api/api-loop/models/availability-user-status';

@Injectable()
export class AvailabilityStatusDialogService {
  toggleDialog$: Subject<AvailabilityUserStatusDialogData> = new Subject();

  constructor() {}
}

export class AvailabilityUserStatusDialogData {
  constructor(public availabilityUserStatus: AvailabilityUserStatus) {}
}
