import * as _ from 'lodash';
import { BaseModel } from '../models-api-loop/base/base.model';
import { CardApiService, CommentApiService, FileApiService } from '@shared/api/api-loop/services';
import { User } from '@shared/api/api-loop/models/user';
import { ContactBaseModel, ContactModel } from '../models-api-loop/contact/contact.model';
import {
  CardType,
  CommentDraft,
  FilterEnum,
  SearchQueryConversationConditions,
  SharedTagBase,
  SharedTagClassification,
  ShowInViewObject,
  SortOrder,
  ViewEnum
} from '@shared/api/api-loop/models';
import { CardMailModel } from '../models-api-loop/conversation-card/card/card.model';
import { TagLabelModel } from '../models-api-loop/tag.model';
import { FolderBase } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';

export interface PagingParams {
  offset: number;
  size: number;
}

export interface CollectionParams extends PagingParams {
  cache?: CollectionCache;
  offsetDate?: string;
  offsetHistoryId?: string;
  collectionKey?: string;
}

export interface DraftCardsCollectionParams extends CollectionParams {
  forCard: CardMailModel;
}

export interface DraftCommentCollectionParams extends CollectionParams {
  draftComment: CommentDraft;
}

export interface CardsBaseCollectionParams extends CollectionParams {
  ignoreSentCommentsFromAuthorId?: string;
}

export interface AssignedCollectionParams extends SearchableViewCollectionParams {
  assignee?: User;
  assignedBy?: User;
  unreadOnly?: boolean;
  starredOnly?: boolean;
}

export interface SearchableViewCollectionParams extends CardsBaseCollectionParams {
  cardTypes: CardType[];
  view: FolderBase;
  unreadOnly?: boolean;
  searchQuery?: string;
  fromDate?: string;
  toDate?: string;
  sharedTags?: SharedTagBase[];
  privateLabels?: TagLabelModel[];
  boardId?: string;
  channelTagContact?: ContactBaseModel;
  isForSharedInbox?: boolean;
  showSharedDrafts?: boolean;
  isPrivateTriageDisabledInMyLoopInbox?: boolean;
  showSent?: boolean;
  requestDate?: Date;
}

export interface ChannelCardsCollectionParams extends SearchableViewCollectionParams {
  contacts: ContactModel[];
  isCurrentUserChannel: boolean;
  allowHasLiveLoopInDifferentChannel?: boolean; // This is needed for correct calculation of unread count
  cardIds?: string[]; // Fot deeplink 'show-cards-in-channel'
}

export interface ConversationCollectionParams extends CollectionParams {
  showInView?: ShowInViewObject;
  sortOrder?: SortOrder;

  searchQuery?: string;
  conversationsIds?: string[];

  unreadOnly?: boolean;

  sharedTags?: SharedTagBase[];
  privateLabels?: TagLabelModel[];
  channelTagContact?: ContactBaseModel[];

  fromDate?: string;
  toDate?: string;
  requestDate?: Date;

  assigneeByOrToId?: string;

  // Force follow up fetch
  forceFollowUpFetch?: boolean;

  // Only in vase load more type searches on BE
  onlineSearch?: boolean;
}

export interface MyLoopInboxCollectionParams extends SearchableViewCollectionParams {
  currentUserId: string;
}

export interface AllSharedInboxesCollectionParams extends SearchableViewCollectionParams {
  contacts: ContactModel[];
}

export interface ChannelChatCardCollectionParams extends CardsBaseCollectionParams {
  contactId: string;
}

export interface ChatCardsCollectionParams extends CardsBaseCollectionParams {
  unreadOnly?: boolean;
}

export interface MultiSessionCollectionParams extends CollectionParams {
  sessionId: string; // We can have multiple registered observers, Tell them apart by session id
}

export interface FoldersCollectionParams extends CollectionParams {
  contextId: string;
}

export interface PersonalInboxCollectionParams extends SearchableViewCollectionParams {
  currentUserId: string;
  unreadOnly?: boolean;
  showSent?: boolean;
  starredOnly?: boolean;
}

export interface SingleCardSnapshotCollectionParams extends CardsBaseCollectionParams {
  cardId: string;
}

export interface SingleCardCollectionParams extends CardsBaseCollectionParams {
  oldCardId?: string;
  cardId?: string;
}

export interface SingleConversationCollectionParams extends CardsBaseCollectionParams {
  conversationId: string;
  sessionId: string;
}

export interface FileCollectionParams extends CollectionParams {
  fileId: string;
}

export interface SharedTagLabelParams extends CollectionParams {
  showDeleted?: boolean;
}

export interface UserAvailabilityCollectionParam extends CollectionParams {
  userIds?: string[];
  sessionId: string;
}

////////////////
// FETCH MODELS
////////////////
export interface FetchRequest {}

export interface ConversationFetchRequest extends FetchRequest {
  showInViewData?: ShowInViewObject;
  size: number;
  offsetHistoryId?: string;
  unreadOnly?: boolean;
  sortOrder?: SortOrder;

  fromDate?: string;
  toDate?: string;

  // At least one classification tag
  includeClassificationTagIds?: string[];

  // Every shared tag id from this list must be on conversation
  includeSharedTagIds?: string[];

  // No shared tag id from this list can be on conversation
  excludeSharedTagIds?: string[];

  // Every private label tag from this list must be on conversation
  includePrivateLabelIds?: string[];

  // Users input
  textQuery?: string;

  // Filter by channel
  channelIds?: string[];

  // Filter by cardId
  conversationsIds?: string[];

  // Do follow-up requests
  syncCommentsAndCards?: boolean;
  forceFollowUpFetch?: boolean;

  commonConversationConditions?: SearchQueryConversationConditions;

  /**
   * [!] DTA ONLY [!]
   * Set to true when doing online search. This way method will
   * return all fetched cards not only the ones not in local DB.
   */
  keepAllResults?: boolean;
}

export interface FetchRequestCommentList extends FetchRequest {
  minNumberOfEntities: number;

  apiParams: CommentApiService.Comment_GetListParams;

  /**
   * Set to true when doing online search. This way method will
   * return all fetched cards not only the ones not in local DB.
   */
  keepAllResults?: boolean;
}

export interface FetchRequestCardList extends FetchRequest {
  minNumberOfEntities: number;

  apiParams: CardApiService.Card_GetListParams;
}

export interface FetchRequestFileList extends FetchRequest {
  minNumberOfEntities: number;

  apiParams: FileApiService.File_GetListParams;
}

export interface FetchRequestCard extends FetchRequest {
  minNumberOfEntities: number;

  apiParams: CardApiService.Card_GetParams;
}

export interface FetchRequestAgendaList extends FetchRequest {
  minNumberOfEntities: number;

  apiParams: CardApiService.Card_GetAgendaListParams;
}

export interface FetchResult {
  offsetHistoryId: string;
  dataLength: number;
  hasData: boolean;
}

export class CollectionCache {
  private modelIds: Set<string> = new Set();
  private cutOffDateByModelId: { [modelId: string]: string } = {};
  private oldestCutOffDate: string;

  constructor() {}

  hasModel(model: BaseModel): boolean {
    return this.modelIds.has(model._id);
  }

  addModels(models: BaseModel[]) {
    _.forEach(models, model => {
      this.addModel(model);
    });
  }

  private addModel(model: BaseModel) {
    if (model.id) {
      this.modelIds.add(model.id);
    }

    if (model.clientId) {
      this.modelIds.add(model.clientId);
    }

    try {
      // Try getting view date
      let modelViewDate = model.getViewDate();
      this.cutOffDateByModelId[model._id] = modelViewDate;

      // Check if date is oldest
      if (this.oldestCutOffDate > modelViewDate) {
        this.oldestCutOffDate = modelViewDate;
      }
    } catch (err) {}
  }

  removeModels(models: BaseModel[]) {
    _.forEach(models, model => {
      this.removeModel(model);
    });
  }

  removeModel(model: BaseModel) {
    this.modelIds.delete(model._id);
    delete this.cutOffDateByModelId[model._id];
    this.oldestCutOffDate = undefined;
  }

  getOldestCutOffDate(): string {
    if (!this.oldestCutOffDate) {
      this.setOldestCutOffDate();
    }

    return this.oldestCutOffDate;
  }

  private setOldestCutOffDate() {
    // Sort ascending
    let sorted = _.orderBy(
      Object.keys(this.cutOffDateByModelId),
      (modelId: string) => this.cutOffDateByModelId[modelId],
      ['asc']
    );

    // Set as lowest or fallback to current date
    this.oldestCutOffDate = sorted[0] || new Date().toISOString();
  }
}

///////////////////////
// FILTER VIEWS MODEL
///////////////////////
export enum ViewsFilter {
  INBOX = 'Inbox',
  DRAFTS = 'Drafts',
  SENT = 'Sent',
  STARRED = 'Starred',
  ASSIGNED = 'Assigned',
  CHAT = 'Chat',
  MUTED = 'Muted',
  ARCHIVED = 'Archived',
  DELETED = 'Deleted',
  ALL = 'All messages',
  SPAM = 'Spam',
  SNOOZE = 'Snoozed',
  BOARD = 'Board'
}
