import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { GroupModel, UserModel } from '../../../../../shared/models-api-loop/contact/contact.model';

const avatarColors = [
  'rgb(85, 69, 127)',
  'rgb(13, 158, 201)',
  'rgb(68, 109, 160)',
  'rgb(231, 136, 73)',
  'rgb(212, 52, 60)',
  'rgb(158, 144, 217)',
  'rgb(115, 193, 216)',
  'rgb(69, 123, 127)',
  'rgb(143, 45, 45)',
  'rgb(232, 101, 109)'
];

@Component({
  selector: 'avatar-file',
  templateUrl: './avatar-file.html',
  styleUrls: ['./avatar-file.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AvatarFileComponent {
  ////////
  // SVGs
  ////////
  multiple = require('shared/assets/img/svg/default-team.svg');

  ////////////////////
  // Inputs / Outputs
  ////////////////////
  @Input() file: string;
  @Input() contact: UserModel | GroupModel;
  @Input() multipleRecipients: boolean;
  @Input() extraClass = '';
  @Input() sharedTag: boolean = false;
  @Input() forUserEmail: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() hasBorder?: boolean;

  @Output() requestAvatar = new EventEmitter<never>();

  ////////////////////
  // Properties
  ////////////////////
  private contactInitials: string;
  private imageErrorOcurredAlready: boolean = false;

  constructor(private _changeDetection: ChangeDetectorRef) {}

  onImageError() {
    if (this.imageErrorOcurredAlready) {
      this.file = undefined;
      this.triggerChangeDetection();
      return;
    }

    this.imageErrorOcurredAlready = true;
    this.requestAvatar.emit();
  }

  get backgroundColor(): string {
    let num = this.initials.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return avatarColors[num % avatarColors.length];
  }

  get initials(): string {
    if (this.contactInitials === undefined) {
      this.contactInitials =
        (this.contact.name || this.contact.email || '')
          // Replace all non ASCII chars and replace them with closest equivalent (č => c)
          .normalize('NFKD')
          .replace(/[\u0300-\u036F]/g, '')
          .split(' ')
          .reduce((acc, word) => {
            let firstLetter = word.replace(/[^a-zA-Z]/g, '')[0]?.toUpperCase();

            if (!firstLetter || acc.length === 2) {
              return acc;
            }

            return acc + firstLetter;
          }, '') || '?';
    }

    return this.contactInitials;
  }

  private triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }
}
