import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  IFrameAppRequest,
  IFrameContactRequest,
  IFrameConversationRequest,
  IFrameMethod,
  IFrameOpenAIRequest,
  IFrameRequest,
  IFrameWindowRequest
} from '@intheloop/loop-integration';
import { OpenAiHelper } from '@shared/services/integrations/helper/open-ai-helper';
import { IFrameRequestHandlerI } from '@shared/services/integrations/iframe-request-handler.interface';
import { ContactHelper } from '@shared/services/integrations/helper/contact-helper';
import { ConversationHelper } from '@shared/services/integrations/helper/conversation-helper';
import { AuthHelper } from '@shared/services/integrations/helper/auth-helper';
import { AppHelper } from '@shared/services/integrations/helper/app-helper';
import { IframeWindowHelper } from '@shared/services/integrations/helper/iframe-window-helper';

@Injectable()
export class IntegrationMethodsHelper implements IFrameRequestHandlerI {
  constructor(
    private _openAiHelper: OpenAiHelper,
    private _contactHelper: ContactHelper,
    private _conversationHelper: ConversationHelper,
    private _appHelper: AppHelper,
    private _authHelper: AuthHelper,
    private iframeWindowHelper: IframeWindowHelper
  ) {}

  handleRequest(forUserEmail: string, request: IFrameRequest, integrationId: string): Observable<any> {
    switch (true) {
      case request.$type === IFrameOpenAIRequest.type:
        return this._openAiHelper.handleRequest(forUserEmail, request as IFrameOpenAIRequest, integrationId);
      case request.$type === IFrameContactRequest.type:
        return this._contactHelper.handleRequest(forUserEmail, request as IFrameContactRequest, integrationId);
      case request.$type === IFrameConversationRequest.type:
        return this._conversationHelper.handleRequest(
          forUserEmail,
          request as IFrameConversationRequest,
          integrationId
        );
      case request.$type === IFrameAppRequest.type:
        return this._appHelper.handleRequest(forUserEmail, request as IFrameAppRequest, integrationId);
      case request.$type === IFrameWindowRequest.type:
        return this.iframeWindowHelper.handleRequest(forUserEmail, request as IFrameWindowRequest, integrationId);
      case request.$type === IFrameRequest.type:
        return this.handleBasicRequest(forUserEmail, request, integrationId);
    }
    return of(undefined);
  }

  private handleBasicRequest(forUserEmail: string, request: IFrameRequest, integrationId: string): Observable<any> {
    switch (request.method) {
      case IFrameMethod.GET_SHORT_LIVED_TOKEN:
        return this._authHelper.handleRequest(forUserEmail, request, integrationId);
    }
    return of(undefined);
  }
}
