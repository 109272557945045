<div class="flex flex-col gap-half">
  <div class="dropdown-header">
    <div class="flex justify-between flex-1">MOVE TO TEAM</div>
  </div>
  <loop-text-control
    class="padding-horizontal"
    [ngModel]="teamSearchQuery$ | async"
    (ngModelChange)="teamSearchQuery$.next($event)"
    size="small"
    placeholder="Search"
  ></loop-text-control>

  <ng-container *ngIf="teams$ | async; let teams">
    <div *ngIf="teams && teams.length; else noTeams" class="flex flex-col teams-wrapper">
      <ng-container *ngFor="let team of teams; let index = index">
        <div
          class="team flex justify-between gap-half"
          [class.bg-light-ui-element]="index === currentIndex"
          (click)="moveToTeam(team)"
        >
          <div class="team-name">
            <avatar-image [contact]="team" [extraClass]="'autosuggest-assign-avatar'"></avatar-image>
            <div class="text-overflow">
              {{ team.name }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-template #noTeams>
      <div>
        <span>No teams</span>
      </div>
    </ng-template>
  </ng-container>
</div>
