import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHelper } from '@shared/services/integrations/helper/app-helper';
import { AuthHelper } from '@shared/services/integrations/helper/auth-helper';
import { ContactHelper } from '@shared/services/integrations/helper/contact-helper';
import { ConversationHelper } from '@shared/services/integrations/helper/conversation-helper';
import { IframeWindowHelper } from '@shared/services/integrations/helper/iframe-window-helper';
import { IntegrationMethodsHelper } from '@shared/services/integrations/helper/integration-methods-helper';
import { OpenAiHelper } from '@shared/services/integrations/helper/open-ai-helper';

@NgModule({
  imports: [CommonModule]
})
export class IntegrationsHelperModule {
  static forRoot(): ModuleWithProviders<IntegrationsHelperModule> {
    return {
      ngModule: IntegrationsHelperModule,
      providers: [
        AppHelper,
        AuthHelper,
        ContactHelper,
        ConversationHelper,
        IframeWindowHelper,
        IntegrationMethodsHelper,
        OpenAiHelper
      ]
    };
  }
}
