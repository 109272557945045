import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '@dta/ui/components/common/loader/loader.component';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, GraphicsModule],
  exports: [LoaderComponent],
})
export class LoaderModule {}
