import { Component } from '@angular/core';
import { SharedTagBase } from '@shared/api/api-loop/models/shared-tag-base';
import { tap } from 'rxjs/operators';
import {
  BatchSelectionState,
  BatchSelectService,
  CountState,
} from '@dta/ui/services/batch-select/batch-select.service';
import { RouterService, SupportedRouteParts } from '@dta/ui/services/router/router.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'empty-state',
  templateUrl: 'empty-state.html',
  styleUrls: ['empty-state.scss'],
  host: { class: 'main no-bckg' },
})
export class EmptyStateComponent {
  protected readonly mainEmptySvg: string = require('@shared/assets/img/svg/empty-states/main-empty.svg');

  isBatchSelectActive: boolean = false;
  markedCardsNumber: number;
  emptyStateType: 'default' | 'shared-inboxes' | 'teams' | 'personal-inboxes' = 'default';
  protected state: CountState = {
    starredCount: 0,
    unStarredCount: 0,
    readCount: 0,
    unReadCount: 0,
  };
  protected sharedLabels: SharedTagBase[] = [];

  constructor(
    private _batchSelectService: BatchSelectService,
    private _routerService: RouterService,
  ) {}

  ngOnInit(): void {
    this.subscribeToMarkedCardsNumber();
    this.resolveParametersFromRoute();
  }

  resolveParametersFromRoute(): void {
    let { routeParts } = this._routerService.getCurrentRouteData();

    switch (routeParts[1]) {
      case SupportedRouteParts.EmptyTeams:
        this.emptyStateType = 'teams';
        break;
      case SupportedRouteParts.EmptySharedInboxes:
        this.emptyStateType = 'shared-inboxes';
        break;
      case SupportedRouteParts.EmptyPersonalInboxes:
        this.emptyStateType = 'personal-inboxes';
        break;
      default:
        this.emptyStateType = 'default';
        break;
    }
  }

  private subscribeToMarkedCardsNumber(): void {
    this._batchSelectService.batchSelectionState$
      .pipe(
        untilDestroyed(this),
        tap((selectionState: BatchSelectionState) => {
          this.markedCardsNumber = selectionState.markedConversationsNumber;
          this.isBatchSelectActive = this.markedCardsNumber > 0;
          this.state = selectionState.countState;
          this.sharedLabels = selectionState.sharedLabels;
        }),
      )
      .subscribe();
  }
}
