import { inject, Injectable } from '@angular/core';
import { CommentMailModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { Observable } from 'rxjs';
import { FileDownloadService } from '@shared/modules/files/shell/services/file-download.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FileStorageServiceWeb extends FileStorageService {
  private readonly fileDownloadService: FileDownloadService = inject(FileDownloadService);
  get constructorName(): string {
    return 'FileStorageServiceWeb';
  }

  // TODOS
  getFilesUri(): string {
    return '';
  }

  getFilesPath(): string {
    return '';
  }

  writeCommentBody(comment: CommentMailModel): string {
    return;
  }

  download(
    fileUri: string,
    fileName: string,
    saveToDownloads?: boolean,
    openDestinationFolderAfter?: boolean
  ): Observable<string> {
    return this.fileDownloadService.downloadFromLink(fileUri, { fileName: fileName }).pipe(map(() => fileUri));
  }
}
