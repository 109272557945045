import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {
  transform(value: Object[], query: string, properties: string[]): any {
    if (_.isEmpty(value)) {
      return value;
    }
    if (_.isEmpty(properties)) {
      throw new Error('properties cannot be nil');
    }
    if (!Array.isArray(value)) {
      throw new Error('value must be an array');
    }
    if (!query) {
      return value;
    }

    let _query = query.toLowerCase();

    return value.filter((item: any) => {
      return properties.some(prop => {
        let val = item[prop];

        if (!val || typeof val !== 'string') {
          return false;
        }

        return val.toLowerCase().includes(_query);
      });
    });
  }
}
