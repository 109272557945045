import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { KeyboardActionModeType } from '@dta/shared/models/keyboard-shortcut.model';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { LoopContextMenuService } from '@shared/services/loop-context-menu/loop-context-menu.service';

@Component({
  selector: 'modal',
  styleUrls: ['./modal.scss'],
  templateUrl: './modal.template.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Modal implements OnInit {
  /////////
  // SVGs
  /////////
  closeMediumSvg = require('shared/assets/img/svg/close-medium.svg');

  /////////////////////
  // Inputs, Outputs
  /////////////////////
  @Input() zindex: string = '10';
  @Input() modalClass: string;
  @Input() isFullscreen: boolean = false;
  @Input() isMainScreen: boolean = false;
  @Input() closeOnEscape: boolean = true;
  @Input() closeOnOutsideClick: boolean = true;
  @Input() title: string;
  @Input() hideCloseButton = true;
  @Input() cancelButtonLabel: string;
  @Input() submitButtonLabel: string;
  @Input() header: boolean = false;
  @Input() footer: boolean = false;
  @Input() scrollable: boolean = false;
  @Input() hideBackground: boolean = false;
  @Input() collapsedBottom: boolean = false;
  @Input() propagateClicks: boolean = false;
  @Input() autofocus: boolean = true;
  @Input() setMode: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() onModalClick = new EventEmitter();

  @ViewChild('modalRoot', { static: true }) modalRoot: ElementRef;

  ////////////////////
  // State variables
  ////////////////////
  isOpened = false;
  backdropElement: HTMLElement;
  isDarwin: boolean;

  constructor(
    private _changeDetection: ChangeDetectorRef,
    private _loopContextMenuService: LoopContextMenuService,
    private _keyboardShortcutsListenerService: KeyboardShortcutsListenerService,
  ) {}

  ngOnInit() {
    this.isDarwin = checkIfOS(OperatingSystem.DARWIN);
  }

  open(...args: any[]) {
    if (!this.backdropElement) {
      this.createBackDrop();
    }

    if (this.setMode) {
      this._keyboardShortcutsListenerService.setMode(KeyboardActionModeType.MODAL);
    }

    this.isOpened = true;
    document.body.appendChild(this.backdropElement);
    this._changeDetection.markForCheck();
    this._loopContextMenuService.closeMenu();

    if (this.autofocus) {
      window.setTimeout(() => this.modalRoot.nativeElement.focus(), 50);
    }
  }

  close(...args: any[]) {
    this.onClose.emit(args);

    if (this.setMode) {
      this._keyboardShortcutsListenerService.goToPreviousMode(KeyboardActionModeType.MODAL);
    }

    try {
      document.body.removeChild(this.backdropElement);
    } catch (err) {}
    this._changeDetection.markForCheck();
  }

  onKeyDown(event) {
    if (event.key === 'Escape') {
      if (this.closeOnEscape && this.isOpened) {
        this.close();
      }
      event.stopPropagation();
    }
  }

  preventClosing(event: MouseEvent) {
    this.onModalClick.emit();

    if (!this.propagateClicks) {
      event.stopPropagation();
    }
  }

  private createBackDrop() {
    this.backdropElement = document.createElement('div');
    this.backdropElement.classList.add('modal-backdrop', 'fade', 'in');
  }

  onOutsideClick() {
    if (this.closeOnOutsideClick) {
      this.close();
    }
  }
}
