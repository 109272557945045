import {
  CardBaseModel,
  CardDraftModel,
  CardModel,
  CardSharedModel,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { StateUpdates } from '@dta/shared/models/state-updates';
import { StopWatch } from '@dta/shared/utils/stop-watch';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CardService } from '../../card/card.service';
import { CommentService } from '../../comment/comment.service';
import { ContactService } from '../../contact/contact.service';

export abstract class BaseCollectionService {
  constructor(
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
  ) {}

  abstract get constructorName(): string;

  ///////////////////
  // SHARED METHODS
  ///////////////////
  protected fetchSaveAndPublishNewContacts(forUserEmail: string, stateUpdates: StateUpdates): Observable<StateUpdates> {
    let comments = CardBaseModel.getCommentsFromCards(stateUpdates.cards);

    return this._contactService.fetchNewContactsByCommentShareLists(forUserEmail, comments).pipe(
      map(() => {
        return stateUpdates;
      }),
    );
  }

  protected saveAndPublishCardsAndComments(forUserEmail: string, stateUpdates: StateUpdates, watch: StopWatch) {
    return of(undefined).pipe(
      /**
       * Save and publish comments
       */
      mergeMap(() => {
        watch.log('save comments: ' + stateUpdates.comments.length);
        return this._commentService.saveAllAndPublish(forUserEmail, stateUpdates.comments);
      }),
      /**
       * Save and publish cards
       */
      mergeMap(() => {
        watch.log('save cards: ' + stateUpdates.cards.length);
        return this._cardService.saveAllAndPublish(forUserEmail, stateUpdates.cards);
      }),
    );
  }

  protected followUpGetForAllCards(
    forUserEmail: string,
    cards: CardModel[],
    watch: StopWatch,
  ): Observable<StateUpdates> {
    if (_.isEmpty(cards)) {
      return of(new StateUpdates());
    }

    let parentCardsFromDraftCards: CardModel[] = [];

    return of(undefined).pipe(
      /**
       * Fetch parent cards from draft cards
       */
      mergeMap(() => {
        let draftParentCardsIds = cards
          .filter(c => c instanceof CardDraftModel && c.parentCard?.id)
          .map(c => (<CardDraftModel>c).parentCard.id);

        return this._cardService.findOrFetchCardsById(forUserEmail, draftParentCardsIds).pipe(
          map((_cards: CardModel[]) => {
            parentCardsFromDraftCards = _cards;
            return new StateUpdates(_cards);
          }),
        );
      }),
      /**
       * Fetch all missing source cards
       */
      mergeMap((stateUpdates: StateUpdates) => {
        let sharedCards = _.filter(cards, card => card instanceof CardSharedModel);

        watch.log('fetchMissingSourceCards for ' + sharedCards.length + ' cards');

        return this._cardService
          .fetchMissingSourceCards(forUserEmail, <CardSharedModel[]>sharedCards)
          .pipe(map((_stateUpdates: StateUpdates) => stateUpdates.mergeWith(_stateUpdates)));
      }),
      /**
       * Fetch all missing shared cards
       */
      mergeMap((stateUpdates: StateUpdates) => {
        let cardsToFetch = _.uniqBy([...parentCardsFromDraftCards, ...cards], 'id');

        watch.log('fetchMissingSharedCards for ' + cardsToFetch.length + ' cards');

        return this._cardService
          .fetchMissingSharedCards(forUserEmail, cardsToFetch)
          .pipe(map((_cards: CardModel[]) => stateUpdates.add(_cards)));
      }),
    );
  }
}
