/* tslint:disable */

/**
 * Group types
 */
export type ContactType = 'User' | 'MultiUser' | 'Group';

export module ContactType {
  export const USER: ContactType = 'User';
  export const MULTI_USER: ContactType = 'MultiUser';
  export const GROUP: ContactType = 'Group';

  export function values(): ContactType[] {
    return [USER, MULTI_USER, GROUP];
  }
}
