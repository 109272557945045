/* tslint:disable */

/**
 * Contact sort order
 */
export type ContactSortOrder = 'Weight' | 'ModifiedDate' | 'CreatedDate';

export module ContactSortOrder {
  export const WEIGHT: ContactSortOrder = 'Weight';
  export const MODIFIED_DATE: ContactSortOrder = 'ModifiedDate';
  export const CREATED_DATE: ContactSortOrder = 'CreatedDate';

  export function values(): ContactSortOrder[] {
    return [WEIGHT, MODIFIED_DATE, CREATED_DATE];
  }
}
