import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AgendaFormComponent } from '@shared/modules/agenda/agenda-form/agenda-form.component';
import { AutoSuggestControlModule } from '@shared/modules/form-controls/auto-suggest-control/auto-suggest-control.module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { CheckboxControlModule } from '@shared/modules/form-controls/checkbox-control/checkbox-control.module';
import { SelectControlModule } from '@shared/modules/form-controls/select-control/select-control.module';
import { WysiwygControlModule } from '@shared/modules/form-controls/wysiwyg-control/wysiwyg-control.module';
import { AgendaPipesModule } from '@shared/modules/agenda/pipes/agenda-pipes/agenda-pipes.module';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { LocationControlModule } from '@shared/modules/form-controls/location-control/location-control.module';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';
import { RangeChooserComponent } from '@shared/modules/date/range-chooser/range-chooser.component';
import { DateTimeControlModule } from '@shared/modules/form-controls/date-time-control/date-time-control.module';

@NgModule({
  declarations: [AgendaFormComponent, RangeChooserComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutoSuggestControlModule,
    GraphicsModule,
    CheckboxControlModule,
    SelectControlModule,
    WysiwygControlModule,
    AgendaPipesModule,
    TextControlModule,
    LocationControlModule,
    ContactModule,
    DateTimeControlModule
  ],
  exports: [AgendaFormComponent]
})
export class AgendaFormModule {}
