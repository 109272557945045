import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContextMenuData, ContextMenuService, ContextOption } from '@shared/services/context-menu/context-menu.service';
import { Subscription, tap } from 'rxjs';
import { OutsideClickComponent } from '@dta/ui/components/common/outside-click-close/outside-click-close.component';

@Component({
  selector: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent extends OutsideClickComponent implements OnInit {
  options: ContextOption[];

  checkmarkSvg = require('shared/assets/img/svg/checkmark.svg');

  private contextMenuShowSub: Subscription;
  private contextMenuCloseSub: Subscription;

  ////////////////
  // View queries
  ////////////////
  @ViewChild('contextMenu', { static: false }) private contextMenu;

  constructor(
    protected _elementRef: ElementRef,
    private _contextMenuService: ContextMenuService,
  ) {
    super(_elementRef);
  }

  ngOnInit(): void {
    this.subscribeShowToContextMenu();
    this.subscribeToCloseContextMenu();
  }

  onOptionClick(option: ContextOption) {
    this._contextMenuService.onOptionClick(option);
    this.handleCloseEvent();
  }

  private subscribeShowToContextMenu() {
    this.contextMenuShowSub?.unsubscribe();
    this.contextMenuShowSub = this._contextMenuService.showMenu$
      .pipe(
        tap((val: ContextMenuData) => {
          this.handleOpenEvent(val);
        }),
      )
      .subscribe();
  }

  private subscribeToCloseContextMenu() {
    this.contextMenuCloseSub?.unsubscribe();
    this.contextMenuCloseSub = this._contextMenuService.closeMenu$
      .pipe(
        tap(() => {
          this.handleCloseEvent();
        }),
      )
      .subscribe();
  }

  handleCloseEvent() {
    this.opened = false;
  }

  handleData(data: ContextMenuData) {
    this.contextMenu.nativeElement.style.top = data.y + 'px';
    this.contextMenu.nativeElement.style.left = data.x + 'px';
    this.options = data.context.options;
  }
}

export enum ContextChoice {
  RESET_WIDTH = 'Reset column width',
  FIXED = 'Fixed',
  FLOATING = 'Floating',
}
