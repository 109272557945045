import { Component, Input } from '@angular/core';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';

@Component({
  selector: 'loop-conversation-folders',
  templateUrl: './conversation-folders.component.html',
  styleUrls: ['./conversation-folders.component.scss'],
})
export class ConversationFoldersComponent {
  protected readonly TooltipPlacement: typeof TooltipPlacement = TooltipPlacement;

  @Input() conversation?: ConversationModel;

  get folders(): any[] {
    return this.conversation?.getFolders() || [];
  }
}
