/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { OptionsContactList } from '../models/options-contact-list';
import { ListOfResourcesOfContactBase } from '../models/list-of-resources-of-contact-base';
import { Response } from '../models/response';
import { GroupSubType } from '../models/group-sub-type';
import { GroupType } from '../models/group-type';
import { ContactType } from '../models/contact-type';
import { ListOfResourcesOfUser } from '../models/list-of-resources-of-user';

@Injectable()
export class ContactApiService extends BaseService {
  Contact_GetContactListOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsContactList>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/contact/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Contact_GetContactListOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsContactList;

          body = <OptionsContactList>response.body;

          return <HttpResponse<OptionsContactList>>response.clone({ body: body });
        })
      );
  }

  Contact_GetContactListOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsContactList> {
    const observable$ = this.Contact_GetContactListOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/contact/list`;
      const url = this.rootUrl + `/api/v1/contact/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `ContactApiService.Contact_GetListParams` containing the following parameters:
   *
   * - `tags`: List of tags to filter contact list by.
   *
   * - `tagFilterRelation`: Query relation for tag filters. Default is 'and'.
   *
   * - `subGroupTypes`: List of group sub types, applicable only when getting group contacts.
   *
   * - `subContactTypes`: List of group types, applicable only when getting group contacts.
   *
   * - `sortOrder`: Order of contacts by contact sort parameter (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `searchQuery`: Plain text search query to filter contact list by.
   *
   * - `offsetModified`: Include contacts from/since (as per requested sort order) its modified date.
   *
   * - `offsetId`: Id of last recieved contact in the previous batch when using offsetModified or offsetCreated
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offsetDateType`: Offset date type.
   *
   * - `offsetDateEnd`: Include cards to/until (as per requested sort order) its offset date type.
   *
   * - `offsetDate`: Include cards from/since (as per requested sort order) its offset date type.
   *
   * - `offsetCreated`: Include contacts from/since (as per requested sort order) its created date.
   *
   * - `offset`: Requested page offset.
   *
   * - `groupId`: get contact from group
   *
   * - `excludeTags`: List of exclude tags to filter contact list by.
   *
   * - `excludeTagFilterRelation`: Query relation for excludeTag filters. Default is 'and'.
   *
   * - `excludeContactIds`: List of contact ids to exclude from list.
   *
   * - `contactTypes`: List of contact types.
   *
   * - `contactSortOrder`: Order of contact sorting (default is by weight).
   *
   * - `contactIds`: List of contact ids to filter contact list by.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Contact_GetListResponse(
    params: ContactApiService.Contact_GetListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfContactBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/contact/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Contact_GetList');

    // body, headers and query params
    (params.tags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('tags', val.toString());
    });
    if (params.tagFilterRelation != null)
      __params = __params.set('tagFilterRelation', params.tagFilterRelation.toString());
    (params.subGroupTypes || []).forEach((val, index) => {
      if (val != null) __params = __params.append('subGroupTypes', val.toString());
    });
    (params.subContactTypes || []).forEach((val, index) => {
      if (val != null) __params = __params.append('subContactTypes', val.toString());
    });
    if (params.sortOrder != null) __params = __params.set('sortOrder', params.sortOrder.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.offsetModified != null) __params = __params.set('offsetModified', params.offsetModified.toString());
    if (params.offsetId != null) __params = __params.set('offsetId', params.offsetId.toString());
    if (params.offsetHistoryId != null) __params = __params.set('offsetHistoryId', params.offsetHistoryId.toString());
    if (params.offsetDateType != null) __params = __params.set('offsetDateType', params.offsetDateType.toString());
    if (params.offsetDateEnd != null) __params = __params.set('offsetDateEnd', params.offsetDateEnd.toString());
    if (params.offsetDate != null) __params = __params.set('offsetDate', params.offsetDate.toString());
    if (params.offsetCreated != null) __params = __params.set('offsetCreated', params.offsetCreated.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    (params.excludeTags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('excludeTags', val.toString());
    });
    if (params.excludeTagFilterRelation != null)
      __params = __params.set('excludeTagFilterRelation', params.excludeTagFilterRelation.toString());
    (params.excludeContactIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('excludeContactIds', val.toString());
    });
    (params.contactTypes || []).forEach((val, index) => {
      if (val != null) __params = __params.append('contactTypes', val.toString());
    });
    if (params.contactSortOrder != null)
      __params = __params.set('contactSortOrder', params.contactSortOrder.toString());
    (params.contactIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('contactIds', val.toString());
    });
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfContactBase;

          body = <ListOfResourcesOfContactBase>response.body;

          return <HttpResponse<ListOfResourcesOfContactBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `ContactApiService.Contact_GetListParams` containing the following parameters:
   *
   * - `tags`: List of tags to filter contact list by.
   *
   * - `tagFilterRelation`: Query relation for tag filters. Default is 'and'.
   *
   * - `subGroupTypes`: List of group sub types, applicable only when getting group contacts.
   *
   * - `subContactTypes`: List of group types, applicable only when getting group contacts.
   *
   * - `sortOrder`: Order of contacts by contact sort parameter (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `searchQuery`: Plain text search query to filter contact list by.
   *
   * - `offsetModified`: Include contacts from/since (as per requested sort order) its modified date.
   *
   * - `offsetId`: Id of last recieved contact in the previous batch when using offsetModified or offsetCreated
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offsetDateType`: Offset date type.
   *
   * - `offsetDateEnd`: Include cards to/until (as per requested sort order) its offset date type.
   *
   * - `offsetDate`: Include cards from/since (as per requested sort order) its offset date type.
   *
   * - `offsetCreated`: Include contacts from/since (as per requested sort order) its created date.
   *
   * - `offset`: Requested page offset.
   *
   * - `groupId`: get contact from group
   *
   * - `excludeTags`: List of exclude tags to filter contact list by.
   *
   * - `excludeTagFilterRelation`: Query relation for excludeTag filters. Default is 'and'.
   *
   * - `excludeContactIds`: List of contact ids to exclude from list.
   *
   * - `contactTypes`: List of contact types.
   *
   * - `contactSortOrder`: Order of contact sorting (default is by weight).
   *
   * - `contactIds`: List of contact ids to filter contact list by.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Contact_GetList(
    params: ContactApiService.Contact_GetListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfContactBase> {
    const observable$ = this.Contact_GetListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/contact/list`;
      const url = this.rootUrl + `/api/v1/contact/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Contact_GetContactWorkspaceOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsContactList>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/contact/workspace`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Contact_GetContactWorkspaceOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsContactList;

          body = <OptionsContactList>response.body;

          return <HttpResponse<OptionsContactList>>response.clone({ body: body });
        })
      );
  }

  Contact_GetContactWorkspaceOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsContactList> {
    const observable$ = this.Contact_GetContactWorkspaceOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/contact/workspace`;
      const url = this.rootUrl + `/api/v1/contact/workspace`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `ContactApiService.Contact_GetWorkspaceContactsParams` containing the following parameters:
   *
   * - `size`: Requested page size.
   *
   * - `searchQuery`: Plain text search query to filter contact list by.
   *
   * - `explicitContactId`: return contact on first position
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Contact_GetWorkspaceContactsResponse(
    params: ContactApiService.Contact_GetWorkspaceContactsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfContactBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/contact/workspace`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Contact_GetWorkspaceContacts');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.explicitContactId != null)
      __params = __params.set('explicitContactId', params.explicitContactId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfContactBase;

          body = <ListOfResourcesOfContactBase>response.body;

          return <HttpResponse<ListOfResourcesOfContactBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `ContactApiService.Contact_GetWorkspaceContactsParams` containing the following parameters:
   *
   * - `size`: Requested page size.
   *
   * - `searchQuery`: Plain text search query to filter contact list by.
   *
   * - `explicitContactId`: return contact on first position
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Contact_GetWorkspaceContacts(
    params: ContactApiService.Contact_GetWorkspaceContactsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfContactBase> {
    const observable$ = this.Contact_GetWorkspaceContactsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/contact/workspace`;
      const url = this.rootUrl + `/api/v1/contact/workspace`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `ContactApiService.Contact_GetWorkspaceListParams` containing the following parameters:
   *
   * - `workspaceId`: workspace id
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Contact_GetWorkspaceListResponse(
    params: ContactApiService.Contact_GetWorkspaceListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfUser>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/contact/workspacelist`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Contact_GetWorkspaceList');

    // body, headers and query params
    if (params.workspaceId != null) __params = __params.set('workspaceId', params.workspaceId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfUser;

          body = <ListOfResourcesOfUser>response.body;

          return <HttpResponse<ListOfResourcesOfUser>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `ContactApiService.Contact_GetWorkspaceListParams` containing the following parameters:
   *
   * - `workspaceId`: workspace id
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Contact_GetWorkspaceList(
    params: ContactApiService.Contact_GetWorkspaceListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfUser> {
    const observable$ = this.Contact_GetWorkspaceListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/contact/workspacelist`;
      const url = this.rootUrl + `/api/v1/contact/workspacelist`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module ContactApiService {
  /**
   * Parameters for Contact_GetList
   */
  export type Contact_GetListParams = {
    /**
     * List of tags to filter contact list by.
     */
    tags?: string[];

    /**
     * Query relation for tag filters. Default is 'and'.
     */
    tagFilterRelation?: string;

    /**
     * List of group sub types, applicable only when getting group contacts.
     */
    subGroupTypes?: GroupSubType[];

    /**
     * List of group types, applicable only when getting group contacts.
     */
    subContactTypes?: GroupType[];

    /**
     * Order of contacts by contact sort parameter (default is descending).
     */
    sortOrder?: string;

    /**
     * Requested page size.
     */
    size?: number;

    /**
     * Plain text search query to filter contact list by.
     */
    searchQuery?: string;

    /**
     * Include contacts from/since (as per requested sort order) its modified date.
     */
    offsetModified?: string;

    /**
     * Id of last recieved contact in the previous batch when using offsetModified or offsetCreated
     */
    offsetId?: string;

    /**
     * Next page of resources when offsetModified set
     */
    offsetHistoryId?: string;

    /**
     * Offset date type.
     */
    offsetDateType?: string;

    /**
     * Include cards to/until (as per requested sort order) its offset date type.
     */
    offsetDateEnd?: string;

    /**
     * Include cards from/since (as per requested sort order) its offset date type.
     */
    offsetDate?: string;

    /**
     * Include contacts from/since (as per requested sort order) its created date.
     */
    offsetCreated?: string;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * get contact from group
     */
    groupId?: string;

    /**
     * List of exclude tags to filter contact list by.
     */
    excludeTags?: string[];

    /**
     * Query relation for excludeTag filters. Default is 'and'.
     */
    excludeTagFilterRelation?: string;

    /**
     * List of contact ids to exclude from list.
     */
    excludeContactIds?: string[];

    /**
     * List of contact types.
     */
    contactTypes?: ContactType[];

    /**
     * Order of contact sorting (default is by weight).
     */
    contactSortOrder?: string;

    /**
     * List of contact ids to filter contact list by.
     */
    contactIds?: string[];

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Contact_GetWorkspaceContacts
   */
  export type Contact_GetWorkspaceContactsParams = {
    /**
     * Requested page size.
     */
    size?: number;

    /**
     * Plain text search query to filter contact list by.
     */
    searchQuery?: string;

    /**
     * return contact on first position
     */
    explicitContactId?: string;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Contact_GetWorkspaceList
   */
  export type Contact_GetWorkspaceListParams = {
    /**
     * workspace id
     */
    workspaceId?: string;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };
}
