import { Injectable } from '@angular/core';
import { SettingsApiService } from '@shared/api/api-loop/services';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SyncSettingsType } from '@dta/shared/models/sync-settings-type.model';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { SyncSettings } from '@dta/shared/models/settings.model';
import {
  CreateSyncSettingParameters,
  SyncSettingServiceI,
  UpdateSyncSettingParameters,
} from './sync-settings.service.interface';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import {
  AuthExchange,
  AuthImapSmtp,
  AuthRemoteImap,
  SyncSettingsExchange,
  SyncSettingsGmail,
  SyncSettingsImapSmtp,
  SyncSettingsMicrosoft,
  SyncSettingsRemoteImap,
} from '@shared/api/api-loop/models';

@Injectable()
export class SyncSettingService implements SyncSettingServiceI {
  constructor(
    private _settingsApiService: SettingsApiService,
    private _sharedUserManagerService: SharedUserManagerService,
  ) {}

  get constructorName(): string {
    return 'SyncSettingService';
  }

  fetchSyncSettings(forUserEmail: string, customAuthorizationToken?: string): Observable<SyncSettings> {
    return this._settingsApiService
      .Settings_GetSyncSettings(
        { Authorization: customAuthorizationToken },
        customAuthorizationToken ? undefined : forUserEmail,
      )
      .pipe(
        catchError(err => {
          Logger.error(err, `Could not fetch sync settings for: ${forUserEmail}`, LogTag.AUTH_AND_LOGIN);

          return throwError(() => err);
        }),
      );
  }

  createSyncSettings(forUserEmail: string, parameters?: CreateSyncSettingParameters): Observable<SyncSettings> {
    return this.createOrUpdateSyncSettings(forUserEmail, 'create', parameters);
  }

  updateSyncSettings(forUserEmail: string, parameters?: UpdateSyncSettingParameters): Observable<SyncSettings> {
    return this.createOrUpdateSyncSettings(forUserEmail, 'update', parameters);
  }

  private createOrUpdateSyncSettings(
    forUserEmail: string,
    action: 'create' | 'update',
    parameters?: CreateSyncSettingParameters | UpdateSyncSettingParameters,
  ): Observable<SyncSettings> {
    Logger.customLog(
      `createOrUpdateSyncSettings(): will ${action} user sync settings for ${forUserEmail}`,
      LogLevel.INFO,
      LogTag.AUTH_AND_LOGIN,
    );

    // Reset forUserEmail is action will be executed for custom account
    forUserEmail = parameters.customAuthorizationToken ? undefined : forUserEmail;

    ////////////////////
    // OAuth providers
    ////////////////////
    if (
      parameters.syncSettingsType === SyncSettingsType.SYNC_SETTINGS_GMAIL ||
      (forUserEmail && this._sharedUserManagerService.isGMailUser(forUserEmail))
    ) {
      return this.createOrUpdateGmailSyncSettings(
        forUserEmail,
        parameters.oAuthRefreshToken,
        action,
        !parameters.syncDisabled,
        (<UpdateSyncSettingParameters>parameters).settingsToUpdate,
        parameters.customAuthorizationToken,
      );
    }

    if (
      parameters.syncSettingsType === SyncSettingsType.SYNC_SETTINGS_MICROSOFT ||
      (forUserEmail && this._sharedUserManagerService.isMSGraphUser(forUserEmail))
    ) {
      return this.createOrUpdateMicrosoftSyncSettings(
        forUserEmail,
        parameters.oAuthRefreshToken,
        action,
        !parameters.syncDisabled,
        (<UpdateSyncSettingParameters>parameters).settingsToUpdate,
        parameters.customAuthorizationToken,
      );
    }

    //////////
    // Others
    //////////
    let auth = (<CreateSyncSettingParameters>parameters).auth;
    if (!auth && forUserEmail) {
      auth = this._sharedUserManagerService.getUserAuthByEmail(forUserEmail);
    }

    if (
      parameters.syncSettingsType === SyncSettingsType.SYNC_SETTINGS_EXCHANGE ||
      (forUserEmail && this._sharedUserManagerService.isMicrosoftExchangeUser(forUserEmail))
    ) {
      return this.createOrUpdateExchangeSyncSettings(
        forUserEmail,
        auth,
        action,
        !parameters.syncDisabled,
        (<UpdateSyncSettingParameters>parameters).settingsToUpdate,
        parameters.customAuthorizationToken,
      ).pipe(
        tap(() => {
          // Set empty secret after updating exchange sync registration
          // (to not store passwd in plaintext)
          (<AuthExchange>auth).secret = '';
          this._sharedUserManagerService.setUserAuth(auth);
        }),
      );
    }

    if (
      parameters.syncSettingsType === SyncSettingsType.SYNC_SETTINGS_REMOTE_IMAP ||
      (forUserEmail && this._sharedUserManagerService.isRemoteImapUser(forUserEmail))
    ) {
      return this.createOrUpdateRemoteImapSyncSettings(
        forUserEmail,
        auth,
        action,
        !parameters.syncDisabled,
        (<UpdateSyncSettingParameters>parameters).settingsToUpdate,
        parameters.customAuthorizationToken,
      ).pipe(
        tap(() => {
          (<AuthRemoteImap>auth).imapPassword = '';
          (<AuthRemoteImap>auth).smtpPassword = '';
          this._sharedUserManagerService.setUserAuth(auth);
        }),
      );
    }

    if (
      parameters.syncSettingsType === SyncSettingsType.SYNC_SETTINGS_IMAP_SMTP ||
      (forUserEmail && this._sharedUserManagerService.isImapUser(forUserEmail))
    ) {
      return this.createOrUpdateImapSyncSettings(
        forUserEmail,
        auth,
        action,
        !parameters.syncDisabled,
        (<UpdateSyncSettingParameters>parameters).settingsToUpdate,
        parameters.customAuthorizationToken,
      ).pipe(
        tap(() => {
          (<AuthImapSmtp>auth).imapPassword = '';
          (<AuthImapSmtp>auth).smtpPassword = '';
          this._sharedUserManagerService.setUserAuth(auth);
        }),
      );
    }

    return throwError(
      () => new Error(`Trying to update unknown type of sync settings: ${parameters.syncSettingsType}`),
    );
  }

  /////////////////////////////////
  // Manage settings per provider
  /////////////////////////////////
  private createOrUpdateGmailSyncSettings(
    forUserEmail: string,
    oAuthRefreshToken: string,
    action: 'create' | 'update',
    syncEnabled: boolean,
    settingsToUpdate?: SyncSettings,
    customAuthorizationToken?: string,
  ): Observable<SyncSettingsExchange> {
    // Use refresh token from parameter or none
    if (settingsToUpdate) {
      delete settingsToUpdate['refreshToken'];
    }

    let settingsGmail: SyncSettingsGmail = Object.assign(settingsToUpdate || {}, {
      $type: SyncSettingsType.SYNC_SETTINGS_GMAIL,
      isEnabled: syncEnabled,
      isPaused: syncEnabled ? false : settingsToUpdate?.isPaused,
    });

    // Set 7 days as default import day range for shared inbox
    if (customAuthorizationToken && action === 'create') {
      settingsGmail.importDayRange = 7;
    }

    // Add refresh token if provided. Must be provided at login
    if (oAuthRefreshToken) {
      (settingsGmail as SyncSettingsMicrosoft).refreshToken = oAuthRefreshToken;
    }

    if (!oAuthRefreshToken && action === 'create') {
      Logger.customLog(
        `${this.constructorName}:createSyncSettings missing refresh ` + `token for gmail account ${forUserEmail}`,
        LogLevel.ERROR,
        LogTag.AUTH_AND_LOGIN,
      );
    }

    let actionObs$ =
      action === 'create'
        ? this._settingsApiService.Settings_CreateGmailSettings(
            { settingsGmail, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          )
        : this._settingsApiService.Settings_UpdateGmailSettings(
            { settingsGmail, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          );

    return actionObs$.pipe(
      catchError(err => {
        Logger.error(err, 'Could not update Gmail sync settings for: ' + forUserEmail, LogTag.AUTH_AND_LOGIN);
        return throwError(() => err);
      }),
    );
  }

  private createOrUpdateMicrosoftSyncSettings(
    forUserEmail: string,
    oAuthRefreshToken: string,
    action: 'create' | 'update',
    syncEnabled: boolean,
    settingsToUpdate?: SyncSettings,
    customAuthorizationToken?: string,
  ): Observable<SyncSettingsExchange> {
    // Use refresh token from parameter or none
    if (settingsToUpdate) {
      delete settingsToUpdate['refreshToken'];
    }

    let settingsMicrosoft: SyncSettingsMicrosoft = Object.assign(settingsToUpdate || {}, {
      $type: SyncSettingsType.SYNC_SETTINGS_MICROSOFT,
      isEnabled: syncEnabled,
      isPaused: syncEnabled ? false : settingsToUpdate?.isPaused,
      email: forUserEmail,
    });

    // Set 7 days as default import day range for shared inbox
    if (customAuthorizationToken && action === 'create') {
      settingsMicrosoft.importDayRange = 7;
    }

    // Add refresh token if provided. Must be provided at login
    if (oAuthRefreshToken) {
      (settingsMicrosoft as SyncSettingsMicrosoft).refreshToken = oAuthRefreshToken;
    }

    if (!oAuthRefreshToken && action === 'create') {
      Logger.customLog(
        `${this.constructorName}:createMicrosoftSyncSettings missing refresh token for MSGraph account ${forUserEmail}`,
        LogLevel.ERROR,
        LogTag.AUTH_AND_LOGIN,
      );
    }

    let actionObs$ =
      action === 'create'
        ? this._settingsApiService.Settings_CreateMicrosoftSettings(
            { settingsMicrosoft, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          )
        : this._settingsApiService.Settings_UpdateMicrosoftSettings(
            { settingsMicrosoft, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          );

    return actionObs$.pipe(
      catchError(err => {
        Logger.error(err, 'Could not update Microsoft Graph sync settings for: ' + forUserEmail, LogTag.AUTH_AND_LOGIN);
        return throwError(() => err);
      }),
    );
  }

  private createOrUpdateExchangeSyncSettings(
    forUserEmail: string,
    auth: AuthExchange,
    action: 'create' | 'update',
    syncEnabled: boolean,
    settingsToUpdate?: SyncSettings,
    customAuthorizationToken?: string,
  ): Observable<SyncSettingsExchange> {
    let settingsExchange: SyncSettingsExchange = Object.assign(settingsToUpdate || {}, {
      $type: SyncSettingsType.SYNC_SETTINGS_EXCHANGE,
      isEnabled: syncEnabled,
      isPaused: syncEnabled ? false : settingsToUpdate?.isPaused,
      email: forUserEmail || auth.email,
      secret: auth.secret,
    });

    // Set 7 days as default import day range for shared inbox
    if (customAuthorizationToken && action === 'create') {
      settingsExchange.importDayRange = 7;
    }

    let actionObs$ =
      action === 'create'
        ? this._settingsApiService.Settings_CreateExchangeSettings(
            { settingsExchange, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          )
        : this._settingsApiService.Settings_UpdateExchangeSettings(
            { settingsExchange, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          );

    return actionObs$.pipe(
      catchError(err => {
        Logger.error(err, 'Could not update exchange sync settings fro: ' + forUserEmail, LogTag.AUTH_AND_LOGIN);
        return throwError(() => err);
      }),
    );
  }

  private createOrUpdateRemoteImapSyncSettings(
    forUserEmail: string,
    auth: AuthRemoteImap,
    action: 'create' | 'update',
    syncEnabled: boolean,
    settingsToUpdate?: SyncSettings,
    customAuthorizationToken?: string,
  ): Observable<SyncSettingsRemoteImap> {
    let settingsRemoteImap: SyncSettingsRemoteImap = Object.assign(settingsToUpdate || {}, {
      $type: SyncSettingsType.SYNC_SETTINGS_REMOTE_IMAP,
      isEnabled: syncEnabled,
      isPaused: syncEnabled ? false : settingsToUpdate?.isPaused,
      name: auth.name,
      email: auth.email,
      imapHost: auth.imapHost,
      imapPort: auth.imapPort,
      imapUsername: auth.imapUsername,
      imapPassword: auth.imapPassword,
      smtpHost: auth.smtpHost,
      smtpPort: auth.smtpPort,
      smtpUsername: auth.smtpUsername || auth.imapUsername,
      smtpPassword: auth.smtpPassword || auth.imapPassword,
      sslRequired: auth.sslRequired,
    });

    // Set 7 days as default import day range for shared inbox
    if (customAuthorizationToken && action === 'create') {
      settingsRemoteImap.importDayRange = 7;
    }

    let actionObs$ =
      action === 'create'
        ? this._settingsApiService.Settings_CreateRemoteImapSettings(
            { settingsRemoteImap, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          )
        : this._settingsApiService.Settings_UpdateRemoteImapSettings(
            { settingsRemoteImap, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          );

    return actionObs$.pipe(
      catchError(err => {
        Logger.error(err, `Could not ${action} remote imap sync settings for: ${forUserEmail}`, LogTag.AUTH_AND_LOGIN);
        return throwError(() => err);
      }),
    );
  }

  private createOrUpdateImapSyncSettings(
    forUserEmail: string,
    auth: AuthImapSmtp,
    action: 'create' | 'update',
    syncEnabled: boolean,
    settingsToUpdate?: SyncSettings,
    customAuthorizationToken?: string,
  ): Observable<SyncSettingsImapSmtp> {
    let settingsImap: SyncSettingsImapSmtp = Object.assign(settingsToUpdate || {}, {
      $type: SyncSettingsType.SYNC_SETTINGS_IMAP_SMTP,
      isEnabled: syncEnabled,
      isPaused: syncEnabled ? false : settingsToUpdate?.isPaused,
      email: auth.email,
      name: auth.name,
      imapHost: auth.imapHost,
      imapPort: auth.imapPort,
      imapUsername: auth.imapUsername,
      imapPassword: auth.imapPassword,
      smtpHost: auth.smtpHost,
      smtpPort: auth.smtpPort,
      smtpUsername: auth.smtpUsername || auth.imapUsername,
      smtpPassword: auth.smtpPassword || auth.imapPassword,
      sslRequired: auth.sslRequired,
    });

    // Set 7 days as default import day range for shared inbox
    if (customAuthorizationToken && action === 'create') {
      settingsImap.importDayRange = 7;
    }

    let actionObs$ =
      action === 'create'
        ? this._settingsApiService.Settings_CreateImapSettings(
            { settingsImap, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          )
        : this._settingsApiService.Settings_UpdateImapSettings(
            { settingsImap, Authorization: customAuthorizationToken },
            customAuthorizationToken ? undefined : forUserEmail,
          );

    return actionObs$.pipe(
      catchError(err => {
        Logger.error(err, `Could not ${action} imap sync settings for: ${forUserEmail}`, LogTag.AUTH_AND_LOGIN);
        return throwError(() => err);
      }),
    );
  }
}
