import userflow from 'userflow.js';
import { Injectable, OnDestroy } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import {
  checkIfOS,
  Environment,
  EnvironmentType,
  isWebApp,
  OperatingSystem,
} from '../../../dta/shared/utils/common-utils';
import { UserflowEventName } from './userflow.constants';
import { LoginUserModel } from '../../../dta/shared/models-api-loop/contact/contact.model';
import { Event, NavigationStart, Router } from '@angular/router';
import { AutoUnsubscribe } from '../../../dta/shared/utils/subscriptions/auto-unsubscribe';
import { Logger } from '@shared/services/logger/logger';
import { LogTag } from '../../../dta/shared/models/logger.model';
import { memoryLeakGuard } from '@dta/shared/utils/subscriptions/actionable-subscription';

const USERFLOW_TOKENS = {
  BETA: 'ct_oukcnnqi7zggljet3myiordr5y',
  PRODUCTION: 'ct_f2vhmgpxcjaz3cca4gme47iklq',
};

@AutoUnsubscribe()
@Injectable()
export class UserflowService implements OnDestroy {
  ///////////////////
  // State variables
  ///////////////////
  private isInit: boolean = false; // Is init only after identify has been called

  /////////////
  // Subjects
  /////////////
  private recallIdentify$: Subject<void> = new Subject();

  /////////////////
  // Subscriptions
  /////////////////
  private usersUpdatesSub: Subscription;
  private navigationSub: Subscription;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _router: Router,
  ) {}

  ngOnDestroy(): void {}

  protected shouldInit(): Observable<boolean> {
    return of(false);
  }

  init() {
    this.shouldInit()
      .pipe(
        memoryLeakGuard(),
        tap((shouldInit: boolean) => {
          if (!shouldInit) {
            return;
          }

          let apiKey = this.getTokenForCurrentEnvironment();

          if (!apiKey) {
            return;
          }

          // Init but only on beta and production
          userflow.init(apiKey);

          // Hide resource center. We open it with toggleResourceCenter
          userflow.setResourceCenterLauncherHidden(true);

          // Subscribe to user switch
          this.subscribeToUserSwitch();

          // Subscribe to navigation (to trigger on view viewed)
          this.subscribeToNavigation();
        }),
      )
      .subscribe();
  }

  toggleResourceCenter() {
    userflow.toggleResourceCenter();
  }

  recallIdentify() {
    this.recallIdentify$.next();
  }

  private subscribeToUserSwitch() {
    this.usersUpdatesSub?.unsubscribe();
    this.usersUpdatesSub = merge(this._userManagerService.userSwitch$, this.recallIdentify$)
      .pipe(
        tap(() => {
          // Reset flow (hide currently active)
          userflow.reset();

          // Identify user (first emit will be empty if new installation)
          let currentUser = this._userManagerService.getCurrentUserLogin();
          if (currentUser) {
            this.identifyUser(currentUser);
          }
        }),
      )
      .subscribe();
  }

  private identifyUser(user: LoginUserModel) {
    // Use try/catch to handle any undefined values
    try {
      let humanReadableOs = checkIfOS(OperatingSystem.DARWIN)
        ? 'MacOS'
        : checkIfOS(OperatingSystem.WINDOWS)
          ? 'Windows'
          : 'Linux';

      userflow.identify(user.id, {
        name: user.name,
        email: user.email,
        signed_up_at: user.created,
        registered_at: user.userSettings.registeredDate,
        os: humanReadableOs,
        web_app: isWebApp(),
        dta_version: Logger.getVersion() || 'unknown',
        intent: user.userSettings.intent,
      });

      this.isInit = true;
    } catch (err) {
      Logger.error(err, 'Error in identifyUser()', LogTag.INTERESTING_ERROR, true);
    }
  }

  private getTokenForCurrentEnvironment(): string {
    let env = isWebApp() ? Environment.getWebEnvironment() : Environment.getEnvironment();

    switch (env) {
      case EnvironmentType.BETA:
        return USERFLOW_TOKENS.BETA;
      case EnvironmentType.PRODUCTION:
      case EnvironmentType.PRODUCTION_STORE:
        return USERFLOW_TOKENS.PRODUCTION;
      case EnvironmentType.ALPHA:
      case EnvironmentType.DEVELOPMENT:
      default:
        return undefined;
    }
  }

  //////////////////
  // Event triggers
  //////////////////
  private triggerEventForUser(forUserEmail: string, eventName: UserflowEventName, attributes?: any) {
    if (!this.isInit) {
      return;
    }

    let hadToSwitch = false;
    if (forUserEmail !== this._userManagerService.getCurrentUserEmail()) {
      // Mark switch
      hadToSwitch = true;

      // Switch
      this.identifyUser(this._userManagerService.getUserLoginByEmail(forUserEmail));
    }

    // Track
    try {
      userflow.track(eventName, attributes);
    } catch (err) {
      console.error(err);
    }

    if (hadToSwitch) {
      // Switch back to current user
      this.identifyUser(this._userManagerService.getCurrentUserLogin());
    }
  }

  triggerEventWithNoAttributes(forUserEmail: string, eventName: UserflowEventName) {
    this.triggerEventForUser(forUserEmail, eventName);
  }

  triggerFolderOpenedEvent(forUserEmail: string, folderName: string) {
    this.triggerEventForUser(forUserEmail, UserflowEventName.FolderOpened, { folder_name: folderName });
  }

  triggerViewedEvent(forUserEmail: string, eventName: UserflowEventName) {
    this.triggerEventForUser(forUserEmail, eventName);
  }

  triggerFolderCreatedEvent(forUserEmail: string, folderName: string) {
    this.triggerEventForUser(forUserEmail, UserflowEventName.FolderCreated, { folder_name: folderName });
  }

  triggerTeamCreatedEvent(forUserEmail: string, teamName: string) {
    this.triggerEventForUser(forUserEmail, UserflowEventName.TeamCreated, { team_name: teamName });
  }

  triggerEmailSentEvent(forUserEmail: string, isSendAs: boolean) {
    this.triggerEventForUser(forUserEmail, UserflowEventName.EmailSent, { is_send_as: isSendAs });
  }

  //////////////////////
  // Delegation events
  //////////////////////
  triggerDelegationEvent(
    forUserEmail: string,
    eventName: UserflowEventName,
    isSharedAction: boolean,
    view: string,
    isUserAssignedToThread: boolean,
    additionalProperties?: Object,
  ) {
    let baseProperties = this.getDelegationCommonProperties(isSharedAction, view, isUserAssignedToThread);

    this.triggerEventForUser(forUserEmail, eventName, { ...baseProperties, ...additionalProperties });
  }

  triggerEmailAssignedEvent(
    forUserEmail: string,
    assigneeEmail: string,
    isSharedAction: boolean,
    view: string,
    isUserAssignedToThread: boolean,
  ) {
    this.triggerDelegationEvent(
      forUserEmail,
      UserflowEventName.EmailAssigned,
      isSharedAction,
      view,
      isUserAssignedToThread,
      { assignee_email: assigneeEmail },
    );
  }

  private subscribeToNavigation() {
    this.navigationSub?.unsubscribe();
    this.navigationSub = this._router.events
      .pipe(
        /**
         * Trigger only on navigation start
         */
        filter(e => e instanceof NavigationStart),
        /**
         * Trigger if path matches any supported events
         */
        tap((_event: Event) => {
          let eventMessage = undefined;
          let event = <NavigationStart>_event;

          switch (true) {
            case event.url.startsWith('/myloopinbox'):
              eventMessage = UserflowEventName.LoopInboxOpened;
              break;
            case event.url.startsWith('/inbox/messages'):
              eventMessage = UserflowEventName.ChatsOpened;
              break;
            case event.url.startsWith('/assigned'):
              eventMessage = UserflowEventName.AssignedOpened;
              break;
            case event.url.startsWith('/starred'):
              eventMessage = UserflowEventName.StarredOpened;
              break;
            case event.url.startsWith('/allSharedInboxes'):
              eventMessage = UserflowEventName.UnifiedSharedInboxViewOpened;
              break;
            default:
              break;
          }

          if (eventMessage) {
            this.triggerEventForUser(this._userManagerService.getCurrentUserEmail(), eventMessage);
          }
        }),
      )
      .subscribe();
  }

  private getDelegationCommonProperties(
    isSharedAction: boolean,
    view: string,
    isUserAssignedToThread: boolean,
  ): Object {
    return {
      isSharedAction: isSharedAction,
      view: view,
      isUserAssignedToThread: isUserAssignedToThread,
    };
  }
}
