export const ADDITIONAL_ATTR = [
  'style',
  'fr-original-style',
  'ogsbc',
  'ogsc',
  'loop-bg-color-shifted',
  'loop-color-shifted'
];

export const ALLOWED_URI_REGEXP =
  /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp|xxx|intheloop):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i;

export const DOM_PURIFY_CONFIG = { ADD_ATTR: ADDITIONAL_ATTR, ALLOWED_URI_REGEXP: ALLOWED_URI_REGEXP };
