import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PrintService {
  public printSub: Subject<any> = new Subject();

  constructor() {}

  public print(html: string): void {
    this.printSub.next(html);
  }
}
