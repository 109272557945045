import { Injectable, OnDestroy } from '@angular/core';
import { from, Observable, Subscription } from 'rxjs';
import { filter, tap, toArray } from 'rxjs/operators';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';

@AutoUnsubscribe()
@Injectable()
export class FlushDatabaseService implements OnDestroy {
  //////////////////
  // Subscriptions
  //////////////////
  protected cleanLocalStorageSub: Subscription;

  constructor(protected _storageService: StorageService) {}

  ngOnDestroy(): void {}

  init() {}

  changeAPI(data) {
    if (!data || !data.environment) {
      return;
    }

    this.flushDb();

    this._storageService.setItem(StorageKey.apiEnvironment, data.environment);
  }

  flushDb() {
    localStorage.clear();

    this.afterCleanLocalStorage();
  }

  redoInitSync(showPopup: boolean = true, reload: boolean = false) {
    if (showPopup) {
      localStorage.setItem(StorageKey.redoInitSyncRequired, 'true');
    }

    this.cleanLocalStorageSub?.unsubscribe();
    this.cleanLocalStorageSub = this.cleanLocalStorage()
      .pipe(
        tap(() => {
          this.afterCleanLocalStorage(reload);
        }),
      )
      .subscribe();
  }

  protected afterCleanLocalStorage(reload: boolean = false) {
    // Do nothing by default
    if (reload) {
      location.reload();
    }
  }

  private cleanLocalStorage(): Observable<any> {
    return from(Object.keys(localStorage)).pipe(
      filter(key => {
        return (
          false ||
          // Load more collection cursors
          key.includes(StorageKey.collectionHistoryIds) ||
          key.startsWith(StorageKey.cutOffTimeStorageKey) ||
          key.startsWith('cutOffTimeStorageKey') || // <- legacy. Remove after 31.3.2022
          // Pull sync parameters
          key.includes(StorageKey.activeSyncHistoryId) ||
          key.includes(StorageKey.activeConversationSyncHistoryId) ||
          key.includes(StorageKey.pastSyncHistoryId) || // <- legacy. Remove after 31.2.2023
          key.includes(StorageKey.pastSyncCutOffDate) || // <- legacy. Remove after 31.2.2023
          key.includes(StorageKey.pastSyncComplete) || // <- legacy. Remove after 31.2.2023
          key.includes(StorageKey.historySyncComplete) ||
          key.includes(StorageKey.initContactSyncComplete) ||
          key.includes(StorageKey.contactSyncHistoryId) ||
          // User properties
          key.includes(StorageKey.userEmailSettings) ||
          key.includes(StorageKey.userClientSettings) ||
          // Other
          key.includes('unread') ||
          key.includes(StorageKey.dbRev) ||
          key.includes(StorageKey.fileRev) ||
          // we should not be clearing app preferences
          (key.includes('preferences') && key !== 'app_preferences') ||
          key.includes(StorageKey.avatarIdSync) ||
          key.includes(StorageKey.activated) ||
          key.includes(StorageKey.lastCompletedPurgeAt) ||
          key.includes(StorageKey.emailAuthType) ||
          key.includes(StorageKey.lastRoute)
        );
      }),
      tap(key => {
        localStorage.removeItem(key);
      }),
      toArray(),
    );
  }
}
