/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Signature } from '../models/signature';
import { Response } from '../models/response';
import { ResponseConflict } from '../models/response-conflict';
import { ListOfResourcesOfSignature } from '../models/list-of-resources-of-signature';

@Injectable()
export class SignatureApiService extends BaseService {
  /**
   * @param params The `SignatureApiService.Signature_CreateSignatureParams` containing the following parameters:
   *
   * - `signature`: Signature object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_CreateSignatureResponse(
    params: SignatureApiService.Signature_CreateSignatureParams,
    forUserEmail: string
  ): Observable<HttpResponse<Signature>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/signature`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Signature_CreateSignature');

    // body, headers and query params
    __body = params.signature;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Signature;

          body = <Signature>response.body;

          return <HttpResponse<Signature>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SignatureApiService.Signature_CreateSignatureParams` containing the following parameters:
   *
   * - `signature`: Signature object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_CreateSignature(
    params: SignatureApiService.Signature_CreateSignatureParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Signature> {
    const observable$ = this.Signature_CreateSignatureResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/signature`;
      const url = this.rootUrl + `/api/v1/signature`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SignatureApiService.Signature_GetParams` containing the following parameters:
   *
   * - `id`: Signature id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_GetResponse(
    params: SignatureApiService.Signature_GetParams,
    forUserEmail: string
  ): Observable<HttpResponse<Signature>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/signature/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Signature_Get');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Signature;

          body = <Signature>response.body;

          return <HttpResponse<Signature>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SignatureApiService.Signature_GetParams` containing the following parameters:
   *
   * - `id`: Signature id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_Get(
    params: SignatureApiService.Signature_GetParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Signature> {
    const observable$ = this.Signature_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/signature/${params.id}`;
      const url = this.rootUrl + `/api/v1/signature/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SignatureApiService.Signature_UpdateSignatureParams` containing the following parameters:
   *
   * - `signature`: Updated signature object
   *
   * - `id`: Signature id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_UpdateSignatureResponse(
    params: SignatureApiService.Signature_UpdateSignatureParams,
    forUserEmail: string
  ): Observable<HttpResponse<Signature>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/signature/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Signature_UpdateSignature');

    // body, headers and query params
    __body = params.signature;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Signature;

          body = <Signature>response.body;

          return <HttpResponse<Signature>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SignatureApiService.Signature_UpdateSignatureParams` containing the following parameters:
   *
   * - `signature`: Updated signature object
   *
   * - `id`: Signature id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_UpdateSignature(
    params: SignatureApiService.Signature_UpdateSignatureParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Signature> {
    const observable$ = this.Signature_UpdateSignatureResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/signature/${params.id}`;
      const url = this.rootUrl + `/api/v1/signature/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SignatureApiService.Signature_DeleteSignatureParams` containing the following parameters:
   *
   * - `id`: Signature id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_DeleteSignatureResponse(
    params: SignatureApiService.Signature_DeleteSignatureParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/signature/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Signature_DeleteSignature');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SignatureApiService.Signature_DeleteSignatureParams` containing the following parameters:
   *
   * - `id`: Signature id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_DeleteSignature(
    params: SignatureApiService.Signature_DeleteSignatureParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Signature_DeleteSignatureResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/signature/${params.id}`;
      const url = this.rootUrl + `/api/v1/signature/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SignatureApiService.Signature_GetListOfSignaturesParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_GetListOfSignaturesResponse(
    params: SignatureApiService.Signature_GetListOfSignaturesParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfSignature>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/signature/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Signature_GetListOfSignatures');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfSignature;

          body = <ListOfResourcesOfSignature>response.body;

          return <HttpResponse<ListOfResourcesOfSignature>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SignatureApiService.Signature_GetListOfSignaturesParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Signature_GetListOfSignatures(
    params: SignatureApiService.Signature_GetListOfSignaturesParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfSignature> {
    const observable$ = this.Signature_GetListOfSignaturesResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/signature/list`;
      const url = this.rootUrl + `/api/v1/signature/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module SignatureApiService {
  /**
   * Parameters for Signature_CreateSignature
   */
  export type Signature_CreateSignatureParams = {
    /**
     * Signature object
     */
    signature: Signature;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Signature_Get
   */
  export type Signature_GetParams = {
    /**
     * Signature id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Signature_UpdateSignature
   */
  export type Signature_UpdateSignatureParams = {
    /**
     * Updated signature object
     */
    signature: Signature;

    /**
     * Signature id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Signature_DeleteSignature
   */
  export type Signature_DeleteSignatureParams = {
    /**
     * Signature id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Signature_GetListOfSignatures
   */
  export type Signature_GetListOfSignaturesParams = {
    /**
     * Paging size
     */
    size?: number;

    /**
     * Paging offset
     */
    offset?: number;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
