import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { RetryQueueDaoServiceI } from '@shared/database/dao/retry-queue/retry-queue-dao.service';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { RetryModel } from '@dta/shared/models-api-loop/retry.model';
import { from, Observable } from 'rxjs';
import { CollectionNameWeb, RetryAfter } from '../../database-schema';
import { flatMap, map, mergeMap, toArray } from 'rxjs/operators';
import { DatabaseServiceWeb } from '../../database.service.web';

@Injectable()
export class RetryQueueDaoServiceWeb
  extends BaseDaoServiceWeb<BaseModel, ResourceBase>
  implements RetryQueueDaoServiceI
{
  constructor(protected _databaseFactory: DatabaseFactory) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'RetryQueueDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.RetryQueue;
  }

  protected toModel(doc: ResourceBase): BaseModel {
    throw new Error('Not implemented yet');
  }

  saveAllToQueue(forUserEmail: string, models: RetryModel[]): Observable<RetryModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return from(models).pipe(
          map((model: RetryModel) => {
            if (!model.created) {
              model.created = new Date().toISOString();
            }
            return model;
          }),
          toArray(),
          flatMap((_models: RetryModel[]) => {
            let dbObjects = _.cloneDeep(_models);
            return db.insertAll(dbObjects, this.collectionName);
          }),
          map(() => models),
        );
      }),
    );
  }

  countWaiting(forUserEmail: string): Observable<number> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndexBounded(RetryAfter.indexName, undefined, new Date().toISOString(), this.collectionName);
      }),
      map(models => (_.isEmpty(models) ? 0 : models.length)),
    );
  }

  findNextBatch(forUserEmail: string, dbPageSize: number): Observable<RetryModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndexBounded(RetryAfter.indexName, undefined, new Date().toISOString(), this.collectionName);
      }),
      /**
       * Do in memory paging
       */
      map(docs => _.slice(_.orderBy(docs, 'price', 'asc'), 0, dbPageSize)),
    ) as Observable<RetryModel[]>;
  }

  findByDataId(forUserEmail: string, dataIds: string[]): Observable<RetryModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIds(dataIds, this.collectionName);
      }),
    ) as Observable<RetryModel[]>;
  }

  removeByDataId(forUserEmail: string, dataIds: string[]): Observable<RetryModel[]> {
    return this.removeByIds(forUserEmail, dataIds);
  }
}
