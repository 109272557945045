import { HttpErrorResponse } from '@angular/common/http';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { ErrorCode } from '@shared/api/api-loop/models';
import { Logger } from '@shared/services/logger/logger';

export class AuthErrorHelper {
  static get errorsThatRequrieAdvancedForm(): (ErrorCode | 'OTHER_LOGIN_ERROR' | 'UNAUTHORIZED')[] {
    return [
      ErrorCode.AUTODISCOVERY_ERROR,
      ErrorCode.MISSING_FIELDS,
      ErrorCode.INVALID_SERVER,
      ErrorCode.REMOTE_SERVER_TEMPORARY_UNAVAILABLE,
      'OTHER_LOGIN_ERROR',
    ];
  }

  static handleAuthError(error: HttpErrorResponse): AuthErrorForUI {
    Logger.customLog(`Error in auth: ${JSON.stringify(error.error)}`, LogLevel.ERROR, LogTag.AUTH_AND_LOGIN, true);

    return this.getErrorAsUIError(error);
  }

  static getErrorAsUIError(error: HttpErrorResponse): AuthErrorForUI {
    let errorBody = error.error;
    let authErrorForUI: AuthErrorForUI = new AuthErrorForUI();
    authErrorForUI.errorCode = errorBody.errorCode;

    if (errorBody === "Token code doesn't exist") {
      authErrorForUI.errorCode = 'UNAUTHORIZED';
      return authErrorForUI;
    }

    switch (!errorBody || errorBody.errorCode) {
      case ErrorCode.INVALID_PASSWORD:
        authErrorForUI.errorMessageForUI = errorBody.message || 'Incorrect password. Please try again.';
        break;
      case ErrorCode.AUTODISCOVERY_ERROR:
        authErrorForUI.errorMessageForUI = errorBody.message || 'Exchange login requires more detail';
        break;
      case ErrorCode.OLD_EXCHANGE_SERVER:
        authErrorForUI.errorMessageForUI =
          errorBody.message ||
          'Sorry, we only support Exchange version 2010 SP2 or higher. Please try another email account.';
        break;
      case ErrorCode.AUTHENTICATION_PROVIDER_URI_MISMATCH:
        authErrorForUI.errorMessageForUI =
          errorBody.message ||
          'Your email address doesn’t match your company’s web address. Please speak to your IT guru.';
        break;
      case ErrorCode.ALIAS_USED:
        authErrorForUI.errorMessageForUI =
          errorBody.message ||
          'Sorry, we don’t support alias addresses yet. Please use your main email address instead.';
        break;
      case ErrorCode.INVALID_SERVER:
        authErrorForUI.errorMessageForUI =
          errorBody.message ||
          'Your server configuration appears to be invalid. Please enter the correct settings or get in touch with our support.';
        break;
      case ErrorCode.REMOTE_SERVER_TEMPORARY_UNAVAILABLE:
        authErrorForUI.errorMessageForUI =
          errorBody.message ||
          'Sorry, we’re experiencing a temporary hiccup (we can’t connect to the server). Please try again later.';
        break;
      case ErrorCode.USERNAME_EMAIL_MISMATCH:
        authErrorForUI.errorMessageForUI =
          errorBody.message || 'Your email and username do not match. Please try again or talk to our support.';
        break;
      case ErrorCode.MISSING_FIELDS:
        authErrorForUI.errorMessageForUI =
          errorBody.message || 'Some of the mandatory fields are missing. Please fill out all the fields.';
        break;
      case ErrorCode.INVALID_PASSWORD_OR_USERNAME:
        authErrorForUI.errorMessageForUI = errorBody.message || 'Incorrect email or password. Please try again.';
        break;
      case ErrorCode.EMAIL_NOT_VERIFIED:
        authErrorForUI.errorMessageForUI =
          errorBody.message || 'Please verify your account first. Check your email for an activation link.';
        break;
      default:
        authErrorForUI.errorCode = 'OTHER_LOGIN_ERROR';
        authErrorForUI.errorMessageForUI = errorBody.message;
        break;
    }

    return authErrorForUI;
  }
}

export class AuthErrorForUI {
  errorCode: ErrorCode | 'OTHER_LOGIN_ERROR' | 'UNAUTHORIZED';
  errorMessageForUI: string;
}
