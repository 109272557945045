import { Injectable, NgModule } from '@angular/core';
import { CardDecorateService } from './card-decorate.service';
import { CommentDecorateService } from './comment-decorate.service';
import { ContactDecorateService } from './contact-decorator.service';
import { ExDecoratorModule } from './extra-data-decorators/ex-decorator.module';
import { FileDecorateService } from './file-decorate.service';
import { ViewDecoratorModule } from './view-data-decorators/view-decorator.module';

@Injectable()
export class DecoratorService {
  constructor(
    public ContactDecorateService: ContactDecorateService,
    public CardDecorateService: CardDecorateService,
    public CommentDecorateService: CommentDecorateService,
    public FileDecorateService: FileDecorateService,
  ) {}
}

@NgModule({
  imports: [ViewDecoratorModule, ExDecoratorModule],
  exports: [ViewDecoratorModule, ExDecoratorModule],
  declarations: [],
  providers: [
    DecoratorService,
    ContactDecorateService,
    CardDecorateService,
    CommentDecorateService,
    FileDecorateService,
  ],
})
export class DecoratorModule {}
