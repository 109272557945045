import { ChangeDetectionStrategy, Component, HostBinding, inject, Injector, Input } from '@angular/core';
import { CustomDropdownComponentConfiguration } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.directive';
import { ClassificationDropdownComponent } from '@shared/modules/shared-tag/components/classification-dropdown/classification-dropdown.component';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { SubscriptionLicense } from '@shared/api/api-loop/models/subscription-license';
import { SharedTagClassificationModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';

@Component({
  selector: 'loop-classification-tag',
  templateUrl: './classification-tag.component.html',
  styleUrls: ['./classification-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassificationTagComponent {
  private readonly injector: Injector = inject(Injector);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly storageService: StorageService = inject(StorageService);

  @Input() classificationTag: SharedTagClassificationModel;
  @Input() conversationIds: string[] = [];
  @Input() isClassificationAssignVisible: boolean = true;

  @HostBinding('class.hide-empty') hideEmpty: boolean = true;

  protected readonly dropdownSvg: NodeRequire = require('shared/assets/img/svg/dropdown.svg');
  protected readonly unClassifiedColor: string = getComputedStyle(document.documentElement)
    .getPropertyValue('--medium-ui-element')
    .trim();

  protected readonly isClassificationTagEnabled: boolean = (
    this.storageService.getParsedItem(
      this.storageService.getKey(this.userManagerService.getCurrentUserEmail(), StorageKey.userTopPriorityLicense)
    ) as SubscriptionLicense
  ).classificationEnabled;

  protected readonly classificationDropdownComponent: CustomDropdownComponentConfiguration<any> = {
    component: {
      component: ClassificationDropdownComponent,
      parentInjector: this.injector
    }
  };
}
