import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PrintService } from './print.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { tap } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'print-container',
  styles: [
    `
      iframe {
        display: none;
      }
    `,
  ],
  template: `<iframe #printIframe></iframe>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintComponent implements OnInit, OnDestroy {
  @ViewChild('printIframe', { static: true }) printIframe;

  private printSub: Subscription;

  constructor(private _printService: PrintService) {}

  ngOnInit() {
    this.subscribeToPrint();
  }

  ngOnDestroy() {}

  private subscribeToPrint() {
    this.printSub && this.printSub.unsubscribe();
    this.printSub = this._printService.printSub
      .pipe(
        tap((html: string) => {
          this.print(html);
        }),
      )
      .subscribe();
  }

  private print(html: string) {
    let iframe = this.printIframe;
    let doc =
      iframe.nativeElement.contentWindow ||
      iframe.nativeElement.contentDocument.document ||
      iframe.nativeElement.contentDocument;

    // Set content
    doc.document.open();
    doc.document.write(html);
    doc.document.close();

    iframe.nativeElement.focus();

    setTimeout(() => {
      iframe.nativeElement.contentWindow.print();
    }, 200);
  }
}
