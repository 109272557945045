import { Injector } from '@angular/core';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { FileApiService } from '@shared/api/api-loop/services';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { defer, Observable, of } from 'rxjs';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { map } from 'rxjs/operators';

export function convertAttachmentsToTemp$(attachments: IFile[], injector: Injector): Observable<IFile[]> {
  const fileApiService = injector.get(FileApiService);
  const userManagerService = injector.get(UserManagerService);

  const afAttachments = attachments.filter(attachment => attachment.id.includes('AF_'));
  return defer(() => {
    if (afAttachments.length === 0) {
      return of(attachments);
    }

    return fileApiService
      .File_ConvertFileAttachmentToTempAttachmentPOST(
        {
          bodyFileAttachmentIds: BaseModel.createListOfResources(afAttachments.map(attachment => attachment.id))
        },
        userManagerService.getCurrentUserEmail()
      )
      .pipe(
        map(response => {
          return attachments.map(attachment => {
            if (!attachment.id.includes('AF_')) {
              return attachment;
            }

            return response.tempAttachment.find(tempAttachment => tempAttachment.fileAttachmentId === attachment.id)
              ?.tempAttachment;
          });
        })
      );
  });
}
