import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { RedirectService } from '@shared/services/redirect/redirect.service';
import { StorageKey, StorageService } from '../../../shared/services/storage/storage.service';
import { PermissionDeniedReason } from '@shared/services/data/channel-inbox-setup/channel-setup.service.interface';
import { filter, Observable } from 'rxjs';

@Injectable()
export class RouterService {
  constructor(
    private _router: Router,
    private _storageService: StorageService,
    private _redirectService: RedirectService,
  ) {}

  get navigationEnd$(): Observable<Event> {
    return this._router.events.pipe(filter((event: Event) => event instanceof NavigationEnd));
  }

  navigateBack() {
    let lastUser = this._storageService.getItem(StorageKey.lastUser);
    let key = this._storageService.getKey(lastUser, StorageKey.previousRoute);
    let previousUserRoute = this._storageService.getItem(key);

    if (previousUserRoute && previousUserRoute.startsWith('/myloopinbox')) {
      previousUserRoute = '/myloopinbox';
    }

    if (previousUserRoute) {
      this._router.navigateByUrl(previousUserRoute);
    } else {
      this._redirectService.navigateToDefaultView();
    }
  }

  hasPathFocusAndMutedOptions(): boolean {
    return this._router.url.includes('muted') || this._router.url.includes('focused');
  }

  getCurrentRouteData(): RouteData {
    return this.getRouteData(this._router.url);
  }

  getRouteData(url: string): RouteData {
    return new RouteData(this._router, url);
  }

  routePartsIncludes(part: SupportedRouteParts): boolean {
    return this.getCurrentRouteData().routeParts.includes(part);
  }
}

export class RouteData {
  url: string;
  queryParameters: QueryParameters;
  routeParts: string[] = [];
  fragment: any;

  constructor(router: Router, urlToParse: string) {
    let parsedUrl = router.parseUrl(urlToParse);
    this.setQueryParameters(parsedUrl.queryParams);
    this.setRouteParts(parsedUrl.root);
    this.setOtherRouteProperties(parsedUrl, urlToParse);
  }

  private setQueryParameters(queryParams: { [key: string]: string }) {
    this.queryParameters = {
      navigatedWithClick: this.parseBoolean(queryParams.navigatedWithClick),
      focusOnNext: this.parseBoolean(queryParams.focusOnNext),
      openedViaShortcuts: this.parseBoolean(queryParams.openedViaShortcuts),
      createCannedResponse: this.parseBoolean(queryParams.createCannedResponse),
      createSignature: this.parseBoolean(queryParams.createSignature),
      navigatedFromSideMenu: this.parseBoolean(queryParams.navigatedFromSideMenu),
      navigatedFromThreadId: queryParams.navigatedFromThreadId,
      subThread: queryParams.subThread,
      enableSyncSuccess: this.parseBoolean(queryParams.enableSyncSuccess),
      scrollToTopOfList: this.parseBoolean(queryParams.scrollToTopOfList),
      showCardsInChannel: queryParams.showCardsInChannel,
      permissionDeniedReason: queryParams.permissionDeniedReason as PermissionDeniedReason,
      filter: queryParams.filter,
      sharedInboxState: queryParams.sharedInboxState ? decodeURIComponent(queryParams.sharedInboxState) : undefined,
    };
  }

  private setRouteParts(urlSegment: UrlSegmentGroup) {
    let segments = urlSegment?.children?.primary?.segments || [];
    this.routeParts = _.map(segments, (segment: UrlSegment) => segment.path);
  }

  private setOtherRouteProperties(parsedUrl: UrlTree, url: string) {
    this.url = url;
    this.fragment = parsedUrl.fragment;
  }

  private parseBoolean(value: string) {
    if (_.isUndefined(value)) {
      return undefined;
    }

    return value === 'true';
  }
}

export interface QueryParameters {
  navigatedWithClick: boolean;
  focusOnNext: boolean;
  openedViaShortcuts: boolean;
  createCannedResponse: boolean;
  createSignature: boolean;
  navigatedFromSideMenu: boolean;
  navigatedFromThreadId: string;
  subThread: string;
  showCardsInChannel: string;
  sharedInboxState: string;
  permissionDeniedReason: PermissionDeniedReason;
  enableSyncSuccess: boolean;
  scrollToTopOfList: boolean;
  filter: string;
}

export enum SupportedRouteParts {
  Inbox = 'inbox',
  Trash = 'trash',
  Outbox = 'outbox',
  Thread = 'thread',
  Archive = 'archive',
  Messages = 'messages',
  Sent = 'sent',
  Boards = 'boards',
  Assigned = 'assigned',
  Channel = 'channel',
  Agenda = 'agenda',
  Account = 'account',
  Search = 'search',
  LoopInbox = 'myloopinbox',
  AllSharedInboxes = 'allSharedInboxes',
  EmptySharedInboxes = 'emptySharedInboxes',
  EmptyPersonalInboxes = 'emptyPersonalInboxes',
  EmptyTeams = 'emptyTeams',
  EmptyPersonalInbox = 'emptyPersonalInbox',
}
