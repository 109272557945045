import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[loopStringTemplateOutlet]',
})
export class StringTemplateOutletDirective {
  @Input()
  set loopStringTemplateOutlet(loopStringTemplateOutlet: string | number | TemplateRef<void> | any) {
    this.viewContainer.clear();
    if (loopStringTemplateOutlet === null || loopStringTemplateOutlet === undefined) {
      return;
    }
    if (loopStringTemplateOutlet instanceof TemplateRef) {
      this.viewContainer.createEmbeddedView(loopStringTemplateOutlet);
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<void>,
  ) {}
}
