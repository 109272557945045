<!-- Loader style: circle -->
<div
  *ngIf="style === 'circle'"
  [class]="customClass + ' loader-wrapper'"
  [class.fill]="fill"
  [class.inverse]="inverse"
  [class.padding]="addPadding"
>
  <div class="loader"></div>
  <div *ngIf="text" class="loader-text" [innerHTML]="text"></div>
</div>

<!-- Loader style: arrows -->
<div *ngIf="style === 'arrows'" [class]="customClass + ' loader-wrapper'" [class.fill]="fill" [class.inverse]="inverse">
  <svg-comp class="arrows-loader" [src]="arrows"></svg-comp>

  <div *ngIf="text" class="loader-text" [innerHTML]="text"></div>
</div>

<!-- Loader style: button-circle -->
<div *ngIf="style === 'button-circle'" [class]="customClass + ' loader-wrapper'">
  <div class="button-circle-wrapper">
    <div class="button-circle"></div>
  </div>
</div>
