import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from '@dta/ui/components/date-picker/date-picker.component';
import { FormsModule } from '@angular/forms';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [CommonModule, FormsModule, GraphicsModule],
  exports: [DatePickerComponent],
})
export class DatePickerModule {}
