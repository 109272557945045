import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ClipboardServiceModule } from '@shared/shared/clipboard/clipboard-service/clipboard-service.module';
import { EnumObjectValue } from '@shared/utils/common/types';
import { ClipboardEvents } from '@shared/shared/clipboard/common/constants/clipboard-events';
import { CLIPBOARD_PREFIX } from '@shared/shared/clipboard/common/constants/clipboard-prefix';
import { merge, Observable } from 'rxjs';

export function getLoopClipboardKey(key: EnumObjectValue<typeof ClipboardEvents>): string {
  return `${CLIPBOARD_PREFIX}_${key}¨_`;
}

@Injectable({
  providedIn: ClipboardServiceModule
})
export class ClipboardService {
  private renderer2?: Renderer2;
  private readonly rendererFactory: RendererFactory2 = inject(RendererFactory2);

  constructor() {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }

  storeDataToClipboard(key: EnumObjectValue<typeof ClipboardEvents>, value: string): void {
    navigator.clipboard.writeText(`${getLoopClipboardKey(key)}${value}`);
  }

  observePasteEvents$(
    key: EnumObjectValue<typeof ClipboardEvents>,
    options?: { shouldPreventDefault: boolean }
  ): Observable<string> {
    return new Observable(observer => {
      const listener = this.renderer2.listen('document', 'paste', (event: ClipboardEvent) => {
        const data = event.clipboardData.getData('text/plain');
        const clipboardKey = getLoopClipboardKey(key);
        if (!data || !data.includes(clipboardKey)) {
          return;
        }

        if (options.shouldPreventDefault) {
          event.preventDefault();
          event.stopPropagation();
        }

        observer.next(data.split(clipboardKey)[1]);
      });

      // Clean up the listener when the observable is unsubscribed
      return () => {
        listener();
      };
    });
  }

  private getLoopClipboardKey(key: EnumObjectValue<typeof ClipboardEvents>): string {
    return `${CLIPBOARD_PREFIX}_${key}¨_`;
  }
}
