<div
  class="modal"
  tabindex="-1"
  role="dialog"
  #modalRoot
  (keydown.esc)="onKeyDown($event)"
  [class.fullscreen]="isFullscreen"
  [class.main-screen]="isMainScreen"
  [class.scrollable]="scrollable"
  [class.hide-background]="hideBackground"
  [class.pin-to-bottom]="collapsedBottom"
  [class.is-darwin]="isDarwin"
  [ngClass]="{ in: isOpened, fade: isOpened}"
  [ngStyle]="{ display: isOpened ? 'block' : 'none'}"
>
  <div class="background" (click)="onOutsideClick()"></div>

  <div [class]="'modal-dialog ' + modalClass" (click)="preventClosing($event)">
    <div class="modal-content" tabindex="0" *ngIf="isOpened">
      <!-- Header -->
      <div
        *ngIf="header || !hideCloseButton"
        class="modal-header"
        [class.no-border-bottom]="!header && !hideCloseButton"
      >
        <ng-content select="modal-header"></ng-content>
        <button
          *ngIf="!hideCloseButton"
          type="button"
          class="close-button"
          data-dismiss="modal"
          [attr.aria-label]="closeButtonLabel || 'Close'"
          (click)="close()"
        >
          <span aria-hidden="true">
            <svg-comp class="svg-icon svg-no-modal-color" [src]="closeMediumSvg"></svg-comp>
          </span>
        </button>
      </div>

      <!-- Body -->
      <div class="modal-body">
        <ng-content select="modal-content"></ng-content>
      </div>

      <!-- Footer -->
      <div class="modal-footer" *ngIf="footer">
        <ng-content select="modal-footer"></ng-content>
        <button *ngIf="cancelButtonLabel" type="button" class="btn btn-default" data-dismiss="modal" (click)="close()">
          {{ cancelButtonLabel }}
        </button>
        <button *ngIf="submitButtonLabel" type="button" class="btn btn-primary">{{ submitButtonLabel }}</button>
      </div>
    </div>
  </div>
</div>
