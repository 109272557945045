import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'urlify' })
export class UrlifyPipe {
  constructor() {}

  transform(text: any, newTab?: boolean) {
    if (!text) {
      return;
    }

    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return newTab ? '<a href="' + url + '" target="_blank">' + url + '</a>' : '<a href="' + url + '">' + url + '</a>';
    });
  }
}
