import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderPipe } from '@dta/ui/pipes/folder/folder.pipe';
import { SubjectPipe } from '@dta/ui/pipes/subject/subject.pipe';

@NgModule({
  declarations: [SubjectPipe],
  imports: [CommonModule],
  exports: [SubjectPipe],
})
export class SubjectPipeModule {}
