<div class="position-relative" outsideClick (onOutsideClick)="focused = false">
  <loop-text-control
    [placeholder]="placeholder"
    [size]="size"
    [label]="label"
    [submitted]="submitted"
    [disabled]="isDisabled$ | async"
    [ngModel]="value$ | async"
    (ngModelChange)="onModelChange($event)"
    (controlFocus)="onFocus()"
  >
  </loop-text-control>

  <ng-container *ngIf="focused">
    <div *ngIf="locations$ | async; let locations" class="locations-wrapper">
      <div
        class="location"
        *ngFor="let location of locations || []"
        (click)="handleModelChange(location.name + ',\n ' + location.formatted_address)"
      >
        <div class="name">
          {{ location.name }}
        </div>
        <div class="address">
          {{ location.formatted_address }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
