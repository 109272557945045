import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { Conversation, DraftType, ShowInViewObject, SortOrder } from '@shared/api/api-loop/models';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { CollectionNameWeb, ConversationCardIdIndex, ConversationSyncedCommentsIndex } from '../../database-schema';
import { filter, map, mergeMap, toArray } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { ConversationDaoServiceI } from '@shared/database/dao/conversation/conversation-dao.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { ConversationPopulateService } from '@shared/populators/conversation-card-populate/conversation-populate/conversation-populate.service';
import { ConversationCollectionParams } from '@dta/shared/models/collection.model';
import { DatabaseServiceWeb } from '../../database.service.web';

@Injectable()
export class ConversationDaoServiceWeb
  extends BaseDaoServiceWeb<ConversationModel, Conversation>
  implements ConversationDaoServiceI
{
  constructor(
    protected _databaseFactory: DatabaseFactory,
    protected _conversationPopulateService: ConversationPopulateService,
  ) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'ConversationDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.Conversation;
  }

  protected toModel(doc: Conversation): ConversationModel {
    return ConversationModel.create(doc);
  }

  protected populate(forUserEmail: string, conversations: ConversationModel[]): Observable<ConversationModel[]> {
    return this._conversationPopulateService.populate(forUserEmail, conversations);
  }

  removeAllConversations(forUserEmail: string): Observable<any> {
    return this.removeAllInCollection(forUserEmail);
  }

  findConversationsForView(
    forUserEmail: string,
    params: ConversationCollectionParams,
  ): Observable<ConversationModel[]> {
    return of(undefined).pipe(
      /**
       * Get all conversations in local DB
       */
      mergeMap(() => {
        return this.getAll(forUserEmail);
      }),
      mergeMap((conversations: Conversation[]) => {
        return from(conversations);
      }),
      /**
       * Filter for conversations that match view
       */
      filter((conversation: Conversation) =>
        _.some(
          conversation.showInViews,
          (showInView: ShowInViewObject) =>
            showInView.view === params.showInView.view &&
            (!showInView.filter || showInView.filter === params.showInView.filter) &&
            (!showInView.channelId || showInView.channelId === params.showInView.channelId) &&
            (!showInView.folderId || showInView.filter === params.showInView.folderId),
        ),
      ),
      toArray(),
      /**
       * Do in memory paging
       */
      map(docs =>
        _.slice(
          _.orderBy(docs, 'viewSortDate', params.sortOrder === SortOrder.DESCENDING ? 'desc' : 'asc'),
          params.offset,
          params.size,
        ),
      ),
      /**
       * Get models and populate
       */
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  /**
   * Used on DTA/WEB to sync comments that are out of sync
   * @param forUserEmail
   * @param conversationIds
   */
  findConversationsWithoutSynchronizedComments(
    forUserEmail: any,
    conversationIds: string[],
  ): Observable<ConversationModel[]> {
    // Uncomment if we want to preload first 40 cards (on web is kinda slow)
    return of([]);
    // return this.findByIds(forUserEmail, conversationIds)
    //     .pipe(
    //         mergeMap((_conversations: ConversationModel[]) => {
    //             return from(_conversations);
    //         }),
    //         filter((_conversation: ConversationModel) => {
    //             return !_conversation._syncedComments && _.some(
    //                 [CardType.CARD_MAIL, CardType.CARD_SHARED, CardType.CARD_APPOINTMENT, CardType.CARD_CHAT],
    //                     type => type === _conversation.cardType
    //             );
    //         }),
    //         toArray()
    //     );
  }

  setSyncedCommentAttribute(forUserEmail: string, conversationIds: string[]): Observable<any> {
    return this.findByIds(forUserEmail, conversationIds).pipe(
      mergeMap((_conversations: ConversationModel[]) => {
        return from(_conversations);
      }),
      map((_conversation: ConversationModel) => {
        _conversation._syncedComments = true;
        return _conversation;
      }),
      toArray(),
      mergeMap((_conversations: ConversationModel[]) => {
        return this.saveAll(forUserEmail, _conversations);
      }),
    );
  }

  // Improve deleting only by view
  removeSyncedCommentAttribute(forUserEmail: string, showInViews: ShowInViewObject): Observable<any> {
    return this.removeAllByIndex(
      forUserEmail,
      ConversationSyncedCommentsIndex.indexName,
      undefined,
      this.collectionName,
    );
  }

  findByCardId(forUserEmail: string, cardId: string): Observable<ConversationModel> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(ConversationCardIdIndex.indexName, [cardId], this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
      map((_conversations: ConversationModel[]) => _.first(_conversations)),
    );
  }

  findByCardIds(forUserEmail: string, cardIds: string[]): Observable<ConversationModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(ConversationCardIdIndex.indexName, cardIds, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
    );
  }

  removeByCardIds(forUserEmail: string, cardIds: string[]): Observable<any> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(ConversationCardIdIndex.indexName, cardIds, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.removeAll(forUserEmail, docs)),
    );
  }

  unlinkPrivateDraft(forUserEmail: string, conversationId: string): Observable<any> {
    return this.findByCardId(forUserEmail, conversationId).pipe(
      mergeMap((conversation: ConversationModel) => {
        conversation.privateDraft = undefined;
        conversation.draftTypes = conversation.draftTypes.filter(draftType => draftType !== DraftType.PRIVATE);
        return this.saveAll(forUserEmail, [conversation]);
      }),
    );
  }
}
