import { ActionEnum } from '@shared/api/api-loop/models/action-enum';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ContextType } from '@shared/api/api-loop/models/context-type';

export interface ConversationActionModelBaseModel extends ResourceBase {
  actionToApply: ActionEnum;
  conversationIds: string[];
  actionDate: string;
  folderId: string;
  sharedActionContext: boolean;
  context: ContextType;
  addTags?: string[];
  removeTags?: string[];
  dueDate?: string;
}

export class ConversationActionModel extends BaseModel implements ConversationActionModelBaseModel {
  static type: string = 'ConversationAction';
  readonly $type: string = ConversationActionModel.type;

  actionDate: string;
  actionToApply: ActionEnum;
  conversationIds: string[];
  folderId: string;
  sharedActionContext: boolean;
  addTags?: string[];
  removeTags?: string[];
  context: ContextType;
  dueDate?: string;

  constructor(model?: ConversationActionModelBaseModel) {
    super(model);
  }
}
