import { Dictionary } from 'lodash';
import { Injectable } from '@angular/core';
import { AvatarService } from '@shared/services/data/avatar/avatar.service';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { Observable } from 'rxjs/internal/Observable';
import { map, of, Subject } from 'rxjs';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { GroupApiService, UserApiService } from '@shared/api/api-loop/services';
import { catchError, finalize, tap } from 'rxjs/operators';
import { SharedAvatarService } from '@shared/services/shared-avatar/shared-avatar.service';
import { AttendeeModel, GroupModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AvatarServiceI } from '@shared/services/data/avatar/avatar.service.interface';
import { NO_AVATAR } from '../shared-avatar/shared-avatar.service.web';
import { finalizeWithValue } from '@shared/utils/rxjs/finalize-with-value';

@Injectable()
export class AvatarServiceWeb extends AvatarService implements AvatarServiceI {
  downloadingAvatars: Dictionary<Subject<string>> = {};
  /////////////
  // Subjects
  /////////////
  avatarUpdated$: Subject<string> = new Subject();

  constructor(
    private _userApiService: UserApiService,
    private _groupApiService: GroupApiService,
    private _storageService: StorageService,
    protected _sharedAvatarService: SharedAvatarService,
  ) {
    super(_sharedAvatarService);
  }

  get constructorName(): string {
    return 'AvatarService';
  }

  private addAvatarUrlToStorage(forUserEmail: string, contact: ContactBase, url: string): void {
    if (url) {
      this._sharedAvatarService.addIdToLocalStore(forUserEmail, contact.id);
      this.addAvatarUrlToLocalStore(forUserEmail, contact.id, url);
    }
  }

  downloadAvatar(forUserEmail: string, contact: ContactBase): Observable<boolean> {
    let obs$ =
      contact.$type === GroupModel.type
        ? this.downloadGroupAvatar(forUserEmail, new GroupModel(contact))
        : this.downloadUserAvatar(
            forUserEmail,
            contact.$type === AttendeeModel.type ? new AttendeeModel(contact) : new UserModel(contact),
          );

    return obs$.pipe(
      map(url => {
        this.addAvatarUrlToStorage(forUserEmail, contact, url);
        return !!url;
      }),
      catchError((err: HttpErrorResponse) => {
        if ([401, 403, 404].includes(err.status)) {
          // Not found || no rights
          // Save no avatar in LS to prevent requests every time avatar is opened
          this._sharedAvatarService.addIdToLocalStore(forUserEmail, contact.id);
          this.addAvatarUrlToLocalStore(forUserEmail, contact.id, NO_AVATAR);
        }

        return of(false);
      }),
    );
  }

  protected downloadUserAvatar(forUserEmail: string, contact: UserModel | AttendeeModel): Observable<string> {
    if (contact.id in this.downloadingAvatars) {
      return this.downloadingAvatars[contact.id];
    } else {
      this.downloadingAvatars[contact.id] = new Subject<string>();
      let params: UserApiService.User_GetUserAvatarSignedLinkParams = {
        userId: contact.id,
      };

      return this._userApiService.User_GetUserAvatarSignedLink(params, forUserEmail).pipe(
        finalizeWithValue((url: string) => {
          this.downloadingAvatars[contact.id].next(url);
          this.downloadingAvatars[contact.id].complete();
          delete this.downloadingAvatars[contact.id];
        }),
      );
    }
  }

  protected downloadGroupAvatar(forUserEmail: string, contact: GroupModel): Observable<string> {
    let params: GroupApiService.Group_GetGroupAvatarParams = {
      groupId: contact.id,
    };

    return this._groupApiService.Group_GetUserAvatarSignedLink(params, forUserEmail);
  }

  getAvatar(forUserEmail: string, contact: ContactBase, refresh?: boolean): Observable<boolean> {
    return this.downloadAvatar(forUserEmail, contact);
  }

  private addAvatarUrlToLocalStore(forUserEmail: string, contactId: string, url: string) {
    let key = this._storageService.getKey(forUserEmail, StorageKey.avatarUrls);
    let avatars = this._storageService.getParsedItem(key) || {};

    avatars[contactId] = url;

    this._storageService.setStringifiedItem(key, avatars);
  }
}
