<div class="flex gap-half items-center">
  <div class="avatar-wrapper">
    <loop-contact-avatar [contact]="contact"></loop-contact-avatar>
    <!--    <avatar-image-->
    <!--      [contact]="contact"-->
    <!--      [extraClass]="CONTACT_SIZE.small === size ? 'autosuggest-assign-avatar' : 'autosuggest-avatar'"-->
    <!--    >-->
    <!--    </avatar-image>-->

    <ng-container *ngIf="showAvailabilityStatus && (contact | isUser) && (contact | isAvailabilityStatusValid)">
      <div class="availability-status-emoji">
        {{ contact.availabilityStatus?.emoji }}
      </div>
    </ng-container>
  </div>

  <div class="flex justify-between flex-1 items-center gap min-width-0">
    <div class="flex flex-col min-width-0">
      <div class="contact-name font-strong font-new-default text-overflow">
        {{ contact.name }}
        <span
          class="availability-status-name text-overflow"
          *ngIf="showAvailabilityStatus && (contact | isUser) && (contact | isAvailabilityStatusValid)"
        >
          ({{ contact.availabilityStatus.status }})
        </span>
      </div>
      <div *ngIf="(contact | isUser) && isEmailVisible" class="font-small font-medium text-overflow">
        {{ contact.email }}
      </div>
    </div>

    <ng-container *ngIf="suffixTpl">
      <ng-container *ngTemplateOutlet="suffixTpl"></ng-container>
    </ng-container>
  </div>
</div>
