import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetClassificationSharedTagPipe } from './get-classification-shared-tag.pipe';

@NgModule({
  declarations: [GetClassificationSharedTagPipe],
  exports: [GetClassificationSharedTagPipe],
  imports: [CommonModule]
})
export class GetClassificationSharedTagPipeModule {}
