import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Logger, LoggerI } from './logger';
import { ElectronProcessType, LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { PurgeStatistics } from '@dta/shared/models/purge.model';
import { CustomDateArg, CustomNumArg, CustomStringArg, LogEntry } from '@shared/api/log-loop/models';
import { Breadcrumbs } from '@dta/shared/utils/breadcrumbs';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { Environment, EnvironmentType, Time } from '@dta/shared/utils/common-utils';
import { ProcessData } from '../electron/electron';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { Report } from '@shared/services/reporter/reporting.service';

const passTroughTags = [LogTag.RENDERER_CRASHED, LogTag.INTERESTING_ERROR];

@Injectable()
export class LoggerHelperService implements LoggerI {
  constructor(
    protected _storageService: StorageService,
    protected _trackingService: TrackingService
  ) {}

  get shouldExposeLogger(): boolean {
    return true;
  }

  setProcessData(processData: ProcessData) {}

  init(_userPath?: string, _electronProcessType?: ElectronProcessType) {
    // Marked as init
    Logger._wasInit = true;
  }

  customLog(
    message: string,
    logLevel: LogLevel,
    _tags?: LogTag | LogTag[],
    messageAsArgument: boolean = false,
    customLogMessage: string = '',
    customStrArgs: { [key: string]: string } = {}
  ) {
    if (_.isEmpty(_tags)) {
      _tags = [];
    }

    let tags = Array.isArray(_tags) ? _tags : [_tags];

    switch (logLevel) {
      case LogLevel.ERROR:
        tags.push(LogTag.ERROR);
        break;
      case LogLevel.WARN:
        tags.push(LogTag.WARN);
        break;
      default:
        break;
    }

    this.logToProcessConsole(message, logLevel);

    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();

    // Add breadcrumbs
    this.addBreadcrumbs(strArg);

    // Add message as separate field
    // ('message' field will be joined with all args in 'longMessage' field)
    if (messageAsArgument) {
      strArg.push({ name: 'logMessage', value: _.isEmpty(customLogMessage) ? message : customLogMessage });
    }

    // Add custom str args
    if (customStrArgs) {
      for (let key in customStrArgs) {
        strArg.push({ name: key, value: customStrArgs[key] });
      }
    }

    this.sendLogToLoopLogServerInternal(message, logLevel, undefined, undefined, tags, strArg, numArg, dateArg);
  }

  protected addBreadcrumbs(strArg: CustomStringArg[]) {
    strArg.push({ name: 'userClicksBreadcrumbs', value: Breadcrumbs.getClicksBreadcrumbs() });
    strArg.push({ name: 'userXHRBreadcrumbs', value: Breadcrumbs.getXHRBreadcrumbs() });
    strArg.push({ name: 'userShortcuts', value: Breadcrumbs.getShortcutBreadcrumbs() });
    strArg.push({ name: 'userRoutes', value: Breadcrumbs.getRouteBreadcrumbs() });
  }

  protected logToProcessConsole(log: string, logLevel: LogLevel) {
    switch (logLevel) {
      case LogLevel.ERROR:
        console.error(`[${Time.getTimestamp()}]:`, log);
        break;
      case LogLevel.WARN:
        console.warn(`[${Time.getTimestamp()}]:`, log);
        break;
      default:
        console.log(`[${Time.getTimestamp()}]:`, log);
    }
  }

  protected getDefaultStringArg(): CustomStringArg[] {
    let customStringArgs = [];

    customStringArgs.push({ name: 'env', value: Logger._env });
    customStringArgs.push({ name: 'apiEnv', value: Logger._apiEnv });

    return customStringArgs;
  }

  protected getDefaultNumArg(): CustomNumArg[] {
    let customNumArgs = [];
    return customNumArgs;
  }

  private getDefaultDateArg(): CustomDateArg[] {
    let customDateArgs = [];
    customDateArgs.push({ name: 'logDateTime', value: new Date().toISOString() });

    return customDateArgs;
  }

  /**
   * Log error to console and report via Sentry
   */
  error(
    error: Error,
    message?: string,
    _tags?: LogTag | LogTag[],
    messageAsArgument: boolean = false,
    customLogMessage: string = ''
  ) {
    // Logic for tags is the same. Not the best, but will do for now
    if (!_tags || _.isEmpty(_tags)) {
      _tags = [];
    }

    let tags = Array.isArray(_tags) ? _tags : [_tags];
    tags.push(LogTag.ERROR);

    let combinedMessage = message;

    // Parse and combine it with message
    if (error !== undefined) {
      if (typeof error === 'string') {
        combinedMessage += ' - ' + error;
      } else if (error.stack) {
        combinedMessage += ' - ' + error.message + ' ' + error.stack;
      } else {
        try {
          combinedMessage += ' - ' + JSON.stringify(error);
        } catch (err) {
          combinedMessage += ' - Error while parsing error in logger';
        }
      }
    }

    // Always show errors in console as console is not accessible by users in production environment
    console.error.apply(console, [combinedMessage]);

    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();

    if (error !== undefined) {
      strArg.push({ name: 'errorMessage', value: error.message });
      strArg.push({ name: 'errorName', value: error.name });
      strArg.push({ name: 'errorStack', value: error.stack });
    }

    // Add breadcrumbs
    this.addBreadcrumbs(strArg);

    // Add message as separate field
    // ('message' field will be joined with all args in 'longMessage' field)
    if (messageAsArgument) {
      strArg.push({ name: 'logMessage', value: _.isEmpty(customLogMessage) ? message : customLogMessage });
    }

    this.sendLogToLoopLogServerInternal(combinedMessage, 4, undefined, undefined, tags, strArg, numArg, dateArg);
  }

  log(...args: any[]) {
    if (Logger.isMuted) {
      return;
    }

    this._log('log', args);
  }

  debug(...args: any[]) {
    if (Logger.isMuted) {
      return;
    }

    this._log('debug', args);
  }

  protected _log(level, args) {
    // Don't print anything starting with '(WebSockets transport)...'
    if (
      Logger._turnOffWebsocketLogging &&
      args.length >= 2 &&
      typeof args[1] === 'string' &&
      args[1].includes('(WebSockets transport)')
    ) {
      return;
    }
    console[level].apply(console, args);
  }

  /**
   * Always report via Sentry but to console only when not muted
   */
  warn(...args: any[]) {
    if (!Logger.isMuted) {
      this._log('warn', args);
    }

    let msg = _.join(args, ' ');

    this.sendLogToLoopLogServer(msg, 3, [LogTag.WARN]);
  }

  logViewTiming(view: string, timeSec: number) {
    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();

    strArg.push({ name: 'view', value: view });
    numArg.push({ name: 'timeSec', value: timeSec });

    this.sendLogToLoopLogServerInternal(
      undefined,
      LogLevel.INFO,
      undefined,
      undefined,
      [LogTag.TIMING],
      strArg,
      numArg,
      dateArg
    );
  }

  logCommentClipping(message: string, commentId: string, commentLength: number, clippedCommentLength: number) {
    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();

    strArg.push({ name: 'commentId', value: commentId });
    numArg.push({ name: 'commentLength_kB', value: commentLength });
    numArg.push({ name: 'clippedCommentLength_kB', value: clippedCommentLength });

    this.sendLogToLoopLogServerInternal(
      message,
      LogLevel.EXCEPTION,
      undefined,
      undefined,
      [LogTag.PERFORMANCE],
      strArg,
      numArg,
      dateArg
    );
  }

  logComponentTiming(view: string, description: string, timeMs: number, forAccount: string) {
    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();

    strArg.push({ name: 'component', value: view });
    strArg.push({ name: 'description', value: description });
    strArg.push({ name: 'forAccount', value: forAccount });
    numArg.push({ name: 'timeMs', value: timeMs });

    this.sendLogToLoopLogServerInternal(
      undefined,
      LogLevel.INFO,
      undefined,
      undefined,
      [LogTag.TIMING],
      strArg,
      numArg,
      dateArg
    );
  }

  logPurgeStatistics(forUserEmail: string, statistics: PurgeStatistics, instanceId: string) {
    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();

    /////////////////////
    // STRING ARGUMENTS
    /////////////////////
    strArg.push({ name: 'instanceId', value: instanceId });
    strArg.push({ name: 'forAccount', value: forUserEmail });
    strArg.push({ name: 'createdCutoffDate', value: statistics.createdCutoffDate });
    strArg.push({ name: 'accessedCutoffDate', value: statistics.accessedCutoffDate });

    ////////////
    // COUNTERS
    ////////////
    numArg.push({ name: 'cardModelsToPurge', value: statistics.cardModelsToPurge });
    numArg.push({ name: 'commentModelsToPurge', value: statistics.commentModelsToPurge });
    numArg.push({ name: 'chatCommentModelsToPurge', value: statistics.chatCommentModelsToPurge });
    numArg.push({ name: 'purgedCommentBodies', value: statistics.purgedCommentBodies });
    numArg.push({ name: 'fileModelsToPurge', value: statistics.fileModelsToPurge });
    numArg.push({ name: 'purgedFiles', value: statistics.purgedFiles });

    this.sendLogToLoopLogServerInternal(
      undefined,
      LogLevel.INFO,
      undefined,
      undefined,
      [LogTag.PURGE_STATISTICS],
      strArg,
      numArg,
      dateArg
    );
  }

  durationStart(forUserEmail: string, metricName: string) {
    Logger._durationLogStartByName[forUserEmail + '-' + metricName] = new Date();
  }

  durationStopAndLog(forUserEmail: string, metricName: string, count?: number) {
    let start = Logger._durationLogStartByName[forUserEmail + '-' + metricName];

    if (!start) {
      return;
    }

    let durationInMs = new Date().getTime() - start.getTime();

    delete Logger._durationLogStartByName[forUserEmail + '-' + metricName];

    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();

    strArg.push({ name: 'forAccount', value: forUserEmail });
    strArg.push({ name: 'metricName', value: metricName });

    numArg.push({ name: 'duration', value: durationInMs });

    if (!_.isUndefined(count)) {
      numArg.push({ name: 'count', value: count });
    }

    this.sendLogToLoopLogServerInternal(
      undefined,
      LogLevel.INFO,
      undefined,
      undefined,
      [LogTag.DURATION],
      strArg,
      numArg,
      dateArg
    );
  }

  logReport(forUserEmail: string, report: Report) {
    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();

    strArg.push({ name: 'forAccount', value: forUserEmail });
    strArg.push({ name: 'fromWindow', value: report.from });

    ////////////
    // COUNTERS
    ////////////
    numArg.push({ name: 'allUnreadsFocusedCount', value: report.allUnreadsFocusedCount });
    numArg.push({ name: 'allUnreadsInboxCount', value: report.allUnreadsInboxCount });

    numArg.push({ name: 'allContactsCount', value: report.allContactsCount });
    numArg.push({ name: 'groupContactsCount', value: report.groupContactsCount });
    numArg.push({ name: 'mutedContactsCount', value: report.mutedContactsCount });

    numArg.push({ name: 'allFoldersCount', value: report.allFoldersCount });
    numArg.push({ name: 'allSignaturesCount', value: report.allSignaturesCount });
    numArg.push({ name: 'allOutboxedCount', value: report.allOutboxedCount });
    numArg.push({ name: 'allAliasesCount', value: report.allAliasesCount });
    numArg.push({ name: 'allCannedResponsesCount', value: report.allCannedResponsesCount });

    numArg.push({ name: 'allConnectedAccountCount', value: report.allConnectedAccountCount });

    if (report.databaseReport) {
      numArg.push({ name: 'allCommentsCount', value: report.databaseReport.allCommentsCount });
      numArg.push({ name: 'commentCollectionsCount', value: report.databaseReport.commentCollectionsCount });

      numArg.push({ name: 'allCardsCount', value: report.databaseReport.allCardsCount });
      numArg.push({ name: 'cardCollectionsCount', value: report.databaseReport.cardCollectionsCount });

      // Database
      numArg.push({ name: 'allCardUnreadCount', value: report.databaseReport.allCardUnreadCount });
      numArg.push({ name: 'allFavoriteCount', value: report.databaseReport.allFavoriteCount });
      numArg.push({ name: 'allContactCount', value: report.databaseReport.allContactCount });
      numArg.push({ name: 'allTagCount', value: report.databaseReport.allTagCount });
      numArg.push({ name: 'allSharedTagCount', value: report.databaseReport.allSharedTagCount });
      numArg.push({ name: 'allFileCount', value: report.databaseReport.allFileCount });
      numArg.push({ name: 'allRetrySyncQueueCount', value: report.databaseReport.allRetrySyncQueueCount });
      numArg.push({ name: 'allPushSyncQueueCount', value: report.databaseReport.allPushSyncQueueCount });
    }

    if (report.memoryReport) {
      numArg.push({ name: 'ProcessMemoryInfoShared', value: report.memoryReport.processMemoryInfo.shared });
      numArg.push({ name: 'ProcessMemoryInfoPrivate', value: report.memoryReport.processMemoryInfo.private });
      numArg.push({ name: 'ProcessMemoryInfoResidentSet', value: report.memoryReport.processMemoryInfo.residentSet });

      numArg.push({ name: 'SystemMemoryInfoTotal', value: report.memoryReport.systemMemoryInfo.total });
      numArg.push({ name: 'SystemMemoryInfoFree', value: report.memoryReport.systemMemoryInfo.free });
      numArg.push({ name: 'SystemMemoryInfoSwapFree', value: report.memoryReport.systemMemoryInfo.swapFree });
      numArg.push({ name: 'SystemMemoryInfoSwapTotal', value: report.memoryReport.systemMemoryInfo.swapTotal });

      numArg.push({ name: 'HeapStatisticsHeapSizeLimit', value: report.memoryReport.heapStatistics.heapSizeLimit });
      numArg.push({ name: 'HeapStatisticsTotalHeapSize', value: report.memoryReport.heapStatistics.totalHeapSize });
      numArg.push({ name: 'HeapStatisticsUsedHeapSize', value: report.memoryReport.heapStatistics.usedHeapSize });
      numArg.push({ name: 'HeapStatisticsMallocedMemory', value: report.memoryReport.heapStatistics.mallocedMemory });
      numArg.push({
        name: 'HeapStatisticsTotalHeapSizeExecutable',
        value: report.memoryReport.heapStatistics.totalHeapSizeExecutable
      });
      numArg.push({
        name: 'HeapStatisticsPeakMallocedMemory',
        value: report.memoryReport.heapStatistics.peakMallocedMemory
      });
      numArg.push({
        name: 'HeapStatisticsTotalAvailableSize',
        value: report.memoryReport.heapStatistics.totalAvailableSize
      });
      numArg.push({
        name: 'HeapStatisticsTotalPhysicalSize',
        value: report.memoryReport.heapStatistics.totalPhysicalSize
      });
    }

    this.sendLogToLoopLogServerInternal(
      undefined,
      LogLevel.INFO,
      undefined,
      undefined,
      [LogTag.COUNTERS],
      strArg,
      numArg,
      dateArg
    );
  }

  /**
   * Log handled error to console and report via Sentry with tag
   */
  handled_error(error: Error, errorId: string, ...args: string[]) {
    // always show errors in console as console is not accessible by users in production environment
    console.error.apply(console, _.concat(args, ['[errorId: ' + errorId + ']', '-', error]));

    let stack = '';
    try {
      stack = error.stack;
    } catch (e) {}

    let argsStr = _.concat(args, ['[errorId: ' + errorId + ']', '-', stack]);
    let msg = _.join(argsStr, ' ');
    let strArg = this.getDefaultStringArg();
    let dateArg = this.getDefaultDateArg();
    let numArg = this.getDefaultNumArg();
    if (error !== undefined) {
      strArg.push({ name: 'errorId', value: errorId });
      strArg.push({ name: 'errorMessage', value: error.message });
      strArg.push({ name: 'errorName', value: error.name });
      strArg.push({ name: 'errorStack', value: error.stack });
    }

    // Add breadcrumbs
    this.addBreadcrumbs(strArg);

    this.sendLogToLoopLogServerInternal(
      msg,
      5,
      undefined,
      undefined,
      [LogTag.HANDLED_ERROR],
      strArg,
      numArg,
      dateArg,
      true
    );
  }

  remoteLog(msg: string, level: number, tags: string[]) {
    Logger.log(msg);

    this.sendLogToLoopLogServer(msg, level === undefined ? 2 : level, tags);
  }

  private hasPassTroughTags(tags: LogTag[] = []): boolean {
    return tags.some(tag => passTroughTags.includes(tag));
  }

  protected sendLogToLoopLogServerInternal(
    _msg: string,
    _level: number,
    _eventType?: string,
    _eventDuration?: number,
    _tags?: string[],
    _customStrArg?: CustomStringArg[],
    _customNumArg?: CustomNumArg[],
    _customDateArg?: CustomDateArg[],
    _forceSend?: boolean
  ) {
    this.trackToMixpanel(_level, _msg, _tags, _customStrArg);

    // Switch for turning developer logs off
    if (Logger.localLogOnly && !_forceSend && _level < LogLevel.ERROR && !this.hasPassTroughTags(_tags as LogTag[])) {
      return;
    }

    if (!Logger._wasInit) {
      return;
    }

    let log: LogEntry = {
      message: _msg,
      level: _level,
      userEmail: Logger._userEmail,
      clientAppId: Environment.getEnvironment() === EnvironmentType.WEB_APP ? 'web_LoopEmail' : 'dta_LoopEmail',
      clientAppVersion: Logger._version,
      deviceId: Logger._machineId,
      localTime: new Date().toISOString(),
      eventType: _eventType,
      eventDuration: _eventDuration,
      tags: _tags,
      customStringArgs: _customStrArg,
      customNumArgs: _customNumArg,
      customDateArgs: _customDateArg
    };

    this.handleSendLog(log);
  }

  private trackToMixpanel(level: number, msg: string, tags: string[], customStrArg: CustomStringArg[] = []) {
    if (_.isUndefined(this._trackingService) || level < LogLevel.ERROR) {
      return;
    }

    this._trackingService.track(Logger._userEmail, 'Error', {
      ErrorType: tags,
      ApiEnvironment: Logger._apiEnv,
      ErrorMessage: this.getLongMessage(msg, customStrArg),
      ElectronProcess: customStrArg?.find(arg => arg.name === 'electronProcessType')?.value,
      ErrorName: customStrArg?.find(arg => arg.name === 'errorName')?.value
    });
  }

  private getLongMessage(msg: string, customStrArg: CustomStringArg[] = []): string {
    return msg + customStrArg.map(arg => `\n${arg.name}=${arg.value}`).join('');
  }

  protected handleSendLog(log: LogEntry) {
    let logString = JSON.stringify(log);
    if (_.isUndefined(this._storageService)) {
      return;
    }
    // Get object from LS
    let logBundle = this._storageService.getParsedItem(StorageKey.logBundle) || [];

    if (logBundle.length < 100) {
      // Add log to bundle
      logBundle.push(logString);
    }

    // Persist to LS
    this._storageService.setStringifiedItem(StorageKey.logBundle, logBundle);
  }

  protected sendLogToLoopLogServer(_msg: string, _level: number, tags: string[]) {
    // Switch for turning developer logs off
    if (Logger.localLogOnly) {
      return;
    }

    return this.sendLogToLoopLogServerInternal(
      _msg,
      _level,
      undefined,
      undefined,
      tags,
      this.getDefaultStringArg(),
      this.getDefaultNumArg(),
      this.getDefaultDateArg()
    );
  }
}
