import { EMPTY, forkJoin, from, Observable, of, throwError, timer } from 'rxjs';
import { LoginUserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { User } from '@shared/api/api-loop/models/user';
import { SyncSettings } from '@dta/shared/models/settings.model';
import { UserSettings } from '@shared/api/api-loop/models/user-settings';
import { Injectable } from '@angular/core';
import { catchError, defaultIfEmpty, filter, map, mergeMap, retryWhen, tap, toArray } from 'rxjs/operators';
import { UserModel } from 'dta/shared/models-api-loop/contact/contact.model';
import { SharedUserManagerService } from 'dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StorageKey, StorageService } from 'dta/shared/services/storage/storage.service';
import { TrackingService } from 'dta/shared/services/tracking/tracking.service';
import { UserApiService } from '@shared/api/api-loop/services/user-api.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { SettingsApiService } from '@shared/api/api-loop/services/settings-api.service';
import { LogLevel, LogTag } from 'dta/shared/models/logger.model';
import { ListOfResourcesOfSubscriptionLicense, SubscriptionLicense, UserAlias } from '@shared/api/api-loop/models';
import { UserServiceI } from './user.service.interface';
import { BaseService } from '../base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { Logger } from '@shared/services/logger/logger';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { UserScopeData, UserTokenInvalid } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { AuthActionType } from '@dta/shared/services/tracking/tracking.constants';
import { SubscriptionLicenseApiService } from '@shared/api/api-loop/services/subscription-license-api.service';
import { SyncSettingService } from '../sync-settings/sync-settings.service';

@Injectable()
export class UserService extends BaseService implements UserServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _storageService: StorageService,
    protected _trackingService: TrackingService,
    protected _settingsService: SettingsService,
    protected _settingsApiService: SettingsApiService,
    protected _userApiService: UserApiService,
    protected _notificationsService: NotificationsService,
    protected _syncSettingService: SyncSettingService,
    private _subscriptionLicenseApiService: SubscriptionLicenseApiService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'UserService';
  }

  /////////////////////
  // INTERFACE METHODS
  /////////////////////
  updateLocalSyncSettings(forUserEmail: string): Observable<SyncSettings> {
    if (!forUserEmail) {
      throw new Error('No user email provided.');
    }

    return this._syncSettingService.fetchSyncSettings(forUserEmail).pipe(
      tap((syncSettings: SyncSettings) => {
        let user = this._sharedUserManagerService.getUserLoginByEmail(forUserEmail);

        if (!syncSettings) {
          Logger.customLog(this.constructorName + '.updateLocalSyncSettings: syncSettings undefined', LogLevel.ERROR);
          return;
        }

        if (!user) {
          Logger.customLog(this.constructorName + '.updateLocalSyncSettings: user undefined', LogLevel.ERROR);
          return;
        }

        // Set updated sync settings fro user
        user.syncSettings = syncSettings;
        this._sharedUserManagerService.setUser(user, 'UserService.updateLocalSyncSettings');
      }),
    );
  }

  handleTokenRevokeEvent(forUserEmail: string): Observable<SyncSettings> {
    // Get sync settings
    return this.updateLocalSyncSettings(forUserEmail).pipe(
      tap(() => {
        // Log to mixpanel
        this._trackingService.trackAuthAction(forUserEmail, AuthActionType.TOKEN_REVOKED);

        // Invalidate local storage data
        this._sharedUserManagerService.handleTokenRevokedForUser(forUserEmail, 'UserService.handleTokenRevokeEvent');

        // Broadcast event (only when sync settings are defined)
        let data = new UserTokenInvalid();
        data.userEmail = forUserEmail;
        data.showDialog = true;

        SharedSubjects._userTokenInvalid$.next(data);
      }),
    );
  }

  deleteAlias(forUserEmail: string, aliasEmail: string): Observable<UserSettings> {
    return this._settingsApiService.Settings_DeleteUserAlias({ aliasEmail: aliasEmail }, forUserEmail).pipe(
      tap((userSettings: UserSettings) => {
        this._settingsService.saveUserSettings(userSettings, forUserEmail);
      }),
    );
  }

  addOrUpdateAlias(forUserEmail: string, alias: UserAlias): Observable<UserSettings | string> {
    return this._settingsApiService
      .Settings_UpdateUserAlias(
        {
          aliasEmail: (<User>alias.alias).email,
          enableForSend: alias.enableForSend,
          defaultAlias: alias.defaultAlias,
        },
        forUserEmail,
      )
      .pipe(
        tap((userSettings: UserSettings) => {
          this._settingsService.saveUserSettings(userSettings, forUserEmail);
        }),
        catchError(err => {
          Logger.error(err, 'error when updating alias');
          let aliasErrorMessage = err.error
            ? err.error.message
            : 'There was a problem with adding this email. Please try again later';
          return of(aliasErrorMessage);
        }),
      );
  }

  updateSelf(forUserEmail: string): Observable<any> {
    return this.fetchUser(forUserEmail).pipe(
      tap((fetchedUser: User) => {
        let currentLoginData = this._sharedUserManagerService.getUserLoginByEmail(forUserEmail);

        let updatedLoginUser = new LoginUserModel(new UserModel(fetchedUser));
        updatedLoginUser.userSettings = currentLoginData.userSettings;
        updatedLoginUser.syncSettings = currentLoginData.syncSettings;

        this._sharedUserManagerService.setOrUpdateUser(updatedLoginUser, 'UserService.updateSelf');
      }),
    );
  }

  fetchOrUpdateUser(forUserEmail: string, refreshToken?: string): Observable<LoginUserModel> {
    let userSettings: UserSettings;
    let userSyncSettings: SyncSettings;

    return forkJoin([
      this.fetchUserSettings(forUserEmail),
      this.fetchOrUpdateSyncSettings(forUserEmail, refreshToken),
      this.fetchTopPriorityLicense(forUserEmail), // only stored to LS
    ]).pipe(
      // Save user/sync settings and fetch user again for correct name
      // The important thing here is that we never save user without sync settings
      mergeMap(result => {
        userSettings = result[0];
        userSyncSettings = result[1];

        return this.fetchUser(forUserEmail);
      }),
      /**
       * Create login user model
       */
      map((fetchedUser: User) => {
        let loginUser = new LoginUserModel(new UserModel(fetchedUser));
        loginUser.userSettings = userSettings;
        loginUser.syncSettings = userSyncSettings;

        return loginUser;
      }),
      tap((_user: LoginUserModel) => {
        // Persist logged in model
        this._sharedUserManagerService.setOrUpdateUser(_user, 'UserService.fetchOrUpdateUser');

        // Track user registered
        let key = this._storageService.getKey(forUserEmail, StorageKey.userRegistered);
        if (this._storageService.getItem(key)) {
          this._trackingService.userRegister(_user.email);
        }
      }),
      catchError(err => {
        Logger.error(err, 'Could not get user licenses for: ' + forUserEmail, LogTag.AUTH_AND_LOGIN);
        if (err.status === 451) {
          let loginUser = new LoginUserModel(new UserModel());
          loginUser.throwVerifiedError = true;
          return of(loginUser);
        }

        return throwError(err);
      }),
    );
  }

  fetchUserSettings(userEmail: string): Observable<UserSettings> {
    if (!userEmail) {
      return throwError(() => new Error(`In ${this.constructorName}: No user email provided.`));
    }

    return this._settingsApiService.Settings_GetUserSettings({}, userEmail).pipe(
      tap((userSettings: UserSettings) => {
        this._settingsService.saveUserSettings(userSettings, userEmail);
      }),
      catchError(err => {
        Logger.error(err, 'Could not get user settings for: ' + userEmail, LogTag.AUTH_AND_LOGIN);
        return throwError(err);
      }),
    );
  }

  ///////////////////
  // OTHER METHODS
  ///////////////////
  updateAllUsersSettingsIfNeeded(_: string): Observable<UserSettings[]> {
    let userEmails = this._sharedUserManagerService.getAllUserEmails();

    return from(userEmails).pipe(
      filter((userEmail: string) => {
        return this._sharedUserManagerService.isUserAccountVerified(userEmail);
      }),
      mergeMap((userEmail: string) => {
        return this.updateUserSettingsIfNeeded(userEmail);
      }),
      toArray(),
      defaultIfEmpty([]),
    );
  }

  private updateUserSettingsIfNeeded(forUserEmail: string): Observable<UserSettings> {
    return of(undefined).pipe(
      /**
       * Fetch user settings (to get latest version)
       */
      mergeMap(() => {
        return this.fetchUserSettings(forUserEmail);
      }),
      /**
       * Update time zone and PUT updated settings
       */
      mergeMap((userSettings: UserSettings) => {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let cultureCode = navigator.language;

        if (timeZone !== userSettings.timeZone || cultureCode !== userSettings.cultureCode) {
          userSettings.cultureCode = cultureCode;
          userSettings.timeZone = timeZone;

          return this._settingsApiService.Settings_UpdateUserSettings({ settingsUser: userSettings }, forUserEmail);
        } else {
          return of(userSettings);
        }
      }),
    );
  }

  updateUser(forUserEmail: string, user: User): Observable<User> {
    let params: UserApiService.User_UpdateSelfParams = {
      user: user,
    };

    return this._userApiService.User_UpdateSelf(params, forUserEmail).pipe(
      catchError(err => {
        Logger.error(err, 'Could not update user with id: ' + user.id, LogTag.AUTH_AND_LOGIN);
        return throwError(() => err);
      }),
    );
  }

  //////////////////
  // PRIVATE HELPER
  //////////////////
  private fetchOrUpdateSyncSettings(userEmail: string, refreshToken?: string): Observable<SyncSettings> {
    if (!userEmail) {
      throw new Error('No user email provided.');
    }

    const emailAuthType = this._sharedUserManagerService.getUserEmailAuthTypeEmail(userEmail);
    const syncEnabled = emailAuthType?.includes('Sync');

    return of(undefined).pipe(
      /**
       * Fetch sync settings from BE
       */
      mergeMap(() => {
        return this._syncSettingService.fetchSyncSettings(userEmail);
      }),
      /**
       * Update if requested
       */
      mergeMap((syncSettings: SyncSettings) => {
        const shouldUpdate = !syncSettings.isEnabled && syncEnabled;

        if (shouldUpdate && refreshToken) {
          return this._syncSettingService.updateSyncSettings(userEmail, {
            syncSettingsType: syncSettings.$type,
            oAuthRefreshToken: refreshToken,
          });
        }
        return of(syncSettings);
      }),
      catchError(err => {
        if (err.status === 404) {
          let auth = this._sharedUserManagerService.getUserAuthByEmail(userEmail);

          // Do not use AuthType enum as it does not reflect auth object type
          if (auth.$type === 'AuthPassword') {
            return of({} as SyncSettings);
          }

          let key = this._storageService.getKey(userEmail, StorageKey.userRegistered);
          this._storageService.setItem(key, true);

          // Create sync settings and then fetch user for correct data (display name mostly)
          return this._syncSettingService.createSyncSettings(userEmail, {
            oAuthRefreshToken: refreshToken,
            syncDisabled: !syncEnabled,
          });
        } else if (err.status === 451) {
          return of({} as SyncSettings);
        }

        return throwError(() => err);
      }),
      retryWhen((handler: Observable<any>) => {
        return handler.pipe(
          mergeMap((err, retryCount) => {
            // Retry when BE not available
            if ([0, 408, 429, 503, 504].includes(err.status)) {
              return timer(Math.pow(2, retryCount) * 1000);
            }

            // Remove account
            this._sharedUserManagerService.removeAccountByEmail(userEmail, 'UserService.fetchOrUpdateSyncSettings');
            return EMPTY;
          }),
        );
      }),
    );
  }

  fetchTopPriorityLicense(forUserEmail: string): Observable<SubscriptionLicense> {
    if (!forUserEmail) {
      return throwError(() => new Error(`In ${this.constructorName}: No user email provided.`));
    }

    return this._subscriptionLicenseApiService.SubscriptionLicense_GetTopPrioritySubscription({}, forUserEmail).pipe(
      tap((license: SubscriptionLicense) => {
        let key = this._storageService.getKey(forUserEmail, StorageKey.userTopPriorityLicense);
        this._storageService.setStringifiedItem(key, license);

        SharedSubjects._userLicenseUpdate$.next(new UserScopeData(forUserEmail));
      }),
      catchError(err => {
        Logger.error(err, 'Could not get user licenses for: ' + forUserEmail, LogTag.AUTH_AND_LOGIN);
        return throwError(err);
      }),
    );
  }

  private fetchUser(userEmail: string): Observable<User> {
    if (!userEmail) {
      throw new Error('No user email provided.');
    }

    let params: UserApiService.User_GetSelfParams = {};

    return this._userApiService.User_GetSelf(params, userEmail).pipe(
      catchError(err => {
        Logger.error(err, 'Could not get user with email: ' + userEmail);
        return throwError(() => err);
      }),
    );
  }
}
