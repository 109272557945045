/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Token } from '../models/token';
import { Response } from '../models/response';
import { AuthenticationCode } from '../models/authentication-code';

@Injectable()
export class TokenApiService extends BaseService {
  /**
   * @param params The `TokenApiService.Token_RefreshTokenParams` containing the following parameters:
   *
   * - `token`: Token
   *
   * - `Authorization`: Authorization header
   */
  Token_RefreshTokenResponse(
    params: TokenApiService.Token_RefreshTokenParams,
    forUserEmail: string
  ): Observable<HttpResponse<Token>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/token`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_RefreshToken');

    // body, headers and query params
    __body = params.token;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token;

          body = <Token>response.body;

          return <HttpResponse<Token>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_RefreshTokenParams` containing the following parameters:
   *
   * - `token`: Token
   *
   * - `Authorization`: Authorization header
   */
  Token_RefreshToken(
    params: TokenApiService.Token_RefreshTokenParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token> {
    const observable$ = this.Token_RefreshTokenResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/token`;
      const url = this.rootUrl + `/api/v1/token`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleTokenParams` containing the following parameters:
   *
   * - `redirect_uri`: Redirect uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetGoogleTokenResponse(
    params: TokenApiService.Token_GetGoogleTokenParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/token/google`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetGoogleToken');

    // body, headers and query params
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleTokenParams` containing the following parameters:
   *
   * - `redirect_uri`: Redirect uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetGoogleToken(
    params: TokenApiService.Token_GetGoogleTokenParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetGoogleTokenResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/token/google`;
      const url = this.rootUrl + `/api/v1/token/google`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftTokenParams` containing the following parameters:
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftTokenResponse(
    params: TokenApiService.Token_GetMicrosoftTokenParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/token/microsoft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftToken');

    // body, headers and query params
    if (params.sharedScope != null) __params = __params.set('shared_scope', params.sharedScope.toString());
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftTokenParams` containing the following parameters:
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftToken(
    params: TokenApiService.Token_GetMicrosoftTokenParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetMicrosoftTokenResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/token/microsoft`;
      const url = this.rootUrl + `/api/v1/token/microsoft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftCodeExchangeTokenParams` containing the following parameters:
   *
   * - `state`: Current state
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftCodeExchangeTokenResponse(
    params: TokenApiService.Token_GetMicrosoftCodeExchangeTokenParams,
    forUserEmail: string
  ): Observable<HttpResponse<Token>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/token/microsoft/codeExchange`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftCodeExchangeToken');

    // body, headers and query params
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.sharedScope != null) __params = __params.set('shared_scope', params.sharedScope.toString());
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token;

          body = <Token>response.body;

          return <HttpResponse<Token>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftCodeExchangeTokenParams` containing the following parameters:
   *
   * - `state`: Current state
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftCodeExchangeToken(
    params: TokenApiService.Token_GetMicrosoftCodeExchangeTokenParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token> {
    const observable$ = this.Token_GetMicrosoftCodeExchangeTokenResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/token/microsoft/codeExchange`;
      const url = this.rootUrl + `/api/v1/token/microsoft/codeExchange`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetCodeParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `X-ClientId`: Client id (randomly generated)
   *
   * - `Authorization`: Authorization header
   */
  Token_GetCodeResponse(
    params: TokenApiService.Token_GetCodeParams,
    forUserEmail: string
  ): Observable<HttpResponse<AuthenticationCode>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/token/code`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetCode');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.XClientId != null) __headers = __headers.set('X-ClientId', params.XClientId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AuthenticationCode;

          body = <AuthenticationCode>response.body;

          return <HttpResponse<AuthenticationCode>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetCodeParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `X-ClientId`: Client id (randomly generated)
   *
   * - `Authorization`: Authorization header
   */
  Token_GetCode(
    params: TokenApiService.Token_GetCodeParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AuthenticationCode> {
    const observable$ = this.Token_GetCodeResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/token/code`;
      const url = this.rootUrl + `/api/v1/token/code`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleToken2Params` containing the following parameters:
   *
   * - `state`: State
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetGoogleToken2Response(
    params: TokenApiService.Token_GetGoogleToken2Params,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.4/token/google`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetGoogleToken2');

    // body, headers and query params
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.responseForm != null) __params = __params.set('responseForm', params.responseForm.toString());
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleToken2Params` containing the following parameters:
   *
   * - `state`: State
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetGoogleToken2(
    params: TokenApiService.Token_GetGoogleToken2Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetGoogleToken2Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.4/token/google`;
      const url = this.rootUrl + `/api/v1.4/token/google`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftToken2Params` containing the following parameters:
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftToken2Response(
    params: TokenApiService.Token_GetMicrosoftToken2Params,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.4/token/microsoft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftToken2');

    // body, headers and query params
    if (params.sharedScope != null) __params = __params.set('shared_scope', params.sharedScope.toString());
    if (params.responseForm != null) __params = __params.set('responseForm', params.responseForm.toString());
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftToken2Params` containing the following parameters:
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftToken2(
    params: TokenApiService.Token_GetMicrosoftToken2Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetMicrosoftToken2Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.4/token/microsoft`;
      const url = this.rootUrl + `/api/v1.4/token/microsoft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftLimitedTokenParams` containing the following parameters:
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftLimitedTokenResponse(
    params: TokenApiService.Token_GetMicrosoftLimitedTokenParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.4/token/microsoftLimited`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftLimitedToken');

    // body, headers and query params
    if (params.responseForm != null) __params = __params.set('responseForm', params.responseForm.toString());
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftLimitedTokenParams` containing the following parameters:
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftLimitedToken(
    params: TokenApiService.Token_GetMicrosoftLimitedTokenParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetMicrosoftLimitedTokenResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.4/token/microsoftLimited`;
      const url = this.rootUrl + `/api/v1.4/token/microsoftLimited`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftTokenFromCodeParams` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetMicrosoftTokenFromCodeResponse(
    params: TokenApiService.Token_GetMicrosoftTokenFromCodeParams,
    forUserEmail: string
  ): Observable<HttpResponse<Token>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.4/token/microsoft/code`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftTokenFromCode');

    // body, headers and query params
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token;

          body = <Token>response.body;

          return <HttpResponse<Token>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftTokenFromCodeParams` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetMicrosoftTokenFromCode(
    params: TokenApiService.Token_GetMicrosoftTokenFromCodeParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token> {
    const observable$ = this.Token_GetMicrosoftTokenFromCodeResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.4/token/microsoft/code`;
      const url = this.rootUrl + `/api/v1.4/token/microsoft/code`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleTokenFromCodeParams` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetGoogleTokenFromCodeResponse(
    params: TokenApiService.Token_GetGoogleTokenFromCodeParams,
    forUserEmail: string
  ): Observable<HttpResponse<Token>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.4/token/google/code`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetGoogleTokenFromCode');

    // body, headers and query params
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token;

          body = <Token>response.body;

          return <HttpResponse<Token>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleTokenFromCodeParams` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetGoogleTokenFromCode(
    params: TokenApiService.Token_GetGoogleTokenFromCodeParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token> {
    const observable$ = this.Token_GetGoogleTokenFromCodeResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.4/token/google/code`;
      const url = this.rootUrl + `/api/v1.4/token/google/code`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleToken22Params` containing the following parameters:
   *
   * - `state`: State
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetGoogleToken22Response(
    params: TokenApiService.Token_GetGoogleToken22Params,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.3/token/google`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetGoogleToken22');

    // body, headers and query params
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.responseForm != null) __params = __params.set('responseForm', params.responseForm.toString());
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleToken22Params` containing the following parameters:
   *
   * - `state`: State
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetGoogleToken22(
    params: TokenApiService.Token_GetGoogleToken22Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetGoogleToken22Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.3/token/google`;
      const url = this.rootUrl + `/api/v1.3/token/google`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftToken22Params` containing the following parameters:
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftToken22Response(
    params: TokenApiService.Token_GetMicrosoftToken22Params,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.3/token/microsoft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftToken22');

    // body, headers and query params
    if (params.sharedScope != null) __params = __params.set('shared_scope', params.sharedScope.toString());
    if (params.responseForm != null) __params = __params.set('responseForm', params.responseForm.toString());
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftToken22Params` containing the following parameters:
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `responseForm`: responseForm default is landing other posible is token
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftToken22(
    params: TokenApiService.Token_GetMicrosoftToken22Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetMicrosoftToken22Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.3/token/microsoft`;
      const url = this.rootUrl + `/api/v1.3/token/microsoft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftTokenFromCode2Params` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetMicrosoftTokenFromCode2Response(
    params: TokenApiService.Token_GetMicrosoftTokenFromCode2Params,
    forUserEmail: string
  ): Observable<HttpResponse<Token>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.3/token/microsoft/code`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftTokenFromCode2');

    // body, headers and query params
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token;

          body = <Token>response.body;

          return <HttpResponse<Token>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftTokenFromCode2Params` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetMicrosoftTokenFromCode2(
    params: TokenApiService.Token_GetMicrosoftTokenFromCode2Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token> {
    const observable$ = this.Token_GetMicrosoftTokenFromCode2Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.3/token/microsoft/code`;
      const url = this.rootUrl + `/api/v1.3/token/microsoft/code`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleTokenFromCode2Params` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetGoogleTokenFromCode2Response(
    params: TokenApiService.Token_GetGoogleTokenFromCode2Params,
    forUserEmail: string
  ): Observable<HttpResponse<Token>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.3/token/google/code`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetGoogleTokenFromCode2');

    // body, headers and query params
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token;

          body = <Token>response.body;

          return <HttpResponse<Token>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleTokenFromCode2Params` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetGoogleTokenFromCode2(
    params: TokenApiService.Token_GetGoogleTokenFromCode2Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token> {
    const observable$ = this.Token_GetGoogleTokenFromCode2Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.3/token/google/code`;
      const url = this.rootUrl + `/api/v1.3/token/google/code`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftToken23Params` containing the following parameters:
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftToken23Response(
    params: TokenApiService.Token_GetMicrosoftToken23Params,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.2/token/microsoft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftToken23');

    // body, headers and query params
    if (params.sharedScope != null) __params = __params.set('shared_scope', params.sharedScope.toString());
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftToken23Params` containing the following parameters:
   *
   * - `shared_scope`: Shared scope grant
   *
   * - `redirect_uri`: Redirect Uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetMicrosoftToken23(
    params: TokenApiService.Token_GetMicrosoftToken23Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetMicrosoftToken23Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/token/microsoft`;
      const url = this.rootUrl + `/api/v1.2/token/microsoft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftTokenFromCode22Params` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetMicrosoftTokenFromCode22Response(
    params: TokenApiService.Token_GetMicrosoftTokenFromCode22Params,
    forUserEmail: string
  ): Observable<HttpResponse<Token>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.2/token/microsoft/code`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetMicrosoftTokenFromCode22');

    // body, headers and query params
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token;

          body = <Token>response.body;

          return <HttpResponse<Token>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetMicrosoftTokenFromCode22Params` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetMicrosoftTokenFromCode22(
    params: TokenApiService.Token_GetMicrosoftTokenFromCode22Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token> {
    const observable$ = this.Token_GetMicrosoftTokenFromCode22Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/token/microsoft/code`;
      const url = this.rootUrl + `/api/v1.2/token/microsoft/code`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleToken23Params` containing the following parameters:
   *
   * - `redirect_uri`: Redirect uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetGoogleToken23Response(
    params: TokenApiService.Token_GetGoogleToken23Params,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.2/token/google`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetGoogleToken23');

    // body, headers and query params
    if (params.redirectUri != null) __params = __params.set('redirect_uri', params.redirectUri.toString());
    if (params.error != null) __params = __params.set('error', params.error.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleToken23Params` containing the following parameters:
   *
   * - `redirect_uri`: Redirect uri
   *
   * - `error`: Error
   *
   * - `code`: Code
   */
  Token_GetGoogleToken23(
    params: TokenApiService.Token_GetGoogleToken23Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.Token_GetGoogleToken23Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/token/google`;
      const url = this.rootUrl + `/api/v1.2/token/google`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleTokenFromCode22Params` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetGoogleTokenFromCode22Response(
    params: TokenApiService.Token_GetGoogleTokenFromCode22Params,
    forUserEmail: string
  ): Observable<HttpResponse<Token>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.2/token/google/code`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Token_GetGoogleTokenFromCode22');

    // body, headers and query params
    if (params.code != null) __params = __params.set('code', params.code.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Token;

          body = <Token>response.body;

          return <HttpResponse<Token>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `TokenApiService.Token_GetGoogleTokenFromCode22Params` containing the following parameters:
   *
   * - `code`: Code
   */
  Token_GetGoogleTokenFromCode22(
    params: TokenApiService.Token_GetGoogleTokenFromCode22Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Token> {
    const observable$ = this.Token_GetGoogleTokenFromCode22Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/token/google/code`;
      const url = this.rootUrl + `/api/v1.2/token/google/code`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module TokenApiService {
  /**
   * Parameters for Token_RefreshToken
   */
  export type Token_RefreshTokenParams = {
    /**
     * Token
     */
    token: Token;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Token_GetGoogleToken
   */
  export type Token_GetGoogleTokenParams = {
    /**
     * Redirect uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftToken
   */
  export type Token_GetMicrosoftTokenParams = {
    /**
     * Shared scope grant
     */
    sharedScope?: boolean;

    /**
     * Redirect Uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftCodeExchangeToken
   */
  export type Token_GetMicrosoftCodeExchangeTokenParams = {
    /**
     * Current state
     */
    state?: string;

    /**
     * Shared scope grant
     */
    sharedScope?: boolean;

    /**
     * Redirect Uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetCode
   */
  export type Token_GetCodeParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Client id (randomly generated)
     */
    XClientId?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Token_GetGoogleToken2
   */
  export type Token_GetGoogleToken2Params = {
    /**
     * State
     */
    state?: string;

    /**
     * responseForm default is landing other posible is token
     */
    responseForm?: string;

    /**
     * Redirect uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftToken2
   */
  export type Token_GetMicrosoftToken2Params = {
    /**
     * Shared scope grant
     */
    sharedScope?: boolean;

    /**
     * responseForm default is landing other posible is token
     */
    responseForm?: string;

    /**
     * Redirect Uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftLimitedToken
   */
  export type Token_GetMicrosoftLimitedTokenParams = {
    /**
     * responseForm default is landing other posible is token
     */
    responseForm?: string;

    /**
     * Redirect Uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftTokenFromCode
   */
  export type Token_GetMicrosoftTokenFromCodeParams = {
    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetGoogleTokenFromCode
   */
  export type Token_GetGoogleTokenFromCodeParams = {
    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetGoogleToken22
   */
  export type Token_GetGoogleToken22Params = {
    /**
     * State
     */
    state?: string;

    /**
     * responseForm default is landing other posible is token
     */
    responseForm?: string;

    /**
     * Redirect uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftToken22
   */
  export type Token_GetMicrosoftToken22Params = {
    /**
     * Shared scope grant
     */
    sharedScope?: boolean;

    /**
     * responseForm default is landing other posible is token
     */
    responseForm?: string;

    /**
     * Redirect Uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftTokenFromCode2
   */
  export type Token_GetMicrosoftTokenFromCode2Params = {
    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetGoogleTokenFromCode2
   */
  export type Token_GetGoogleTokenFromCode2Params = {
    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftToken23
   */
  export type Token_GetMicrosoftToken23Params = {
    /**
     * Shared scope grant
     */
    sharedScope?: boolean;

    /**
     * Redirect Uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetMicrosoftTokenFromCode22
   */
  export type Token_GetMicrosoftTokenFromCode22Params = {
    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetGoogleToken23
   */
  export type Token_GetGoogleToken23Params = {
    /**
     * Redirect uri
     */
    redirectUri?: string;

    /**
     * Error
     */
    error?: string;

    /**
     * Code
     */
    code?: string;
  };

  /**
   * Parameters for Token_GetGoogleTokenFromCode22
   */
  export type Token_GetGoogleTokenFromCode22Params = {
    /**
     * Code
     */
    code?: string;
  };
}
