<!-- All -->
<div *ngIf="!isOnLoopInPopup" class="chips-wrapper" (click)="showActiveDisplay()">
  <custom-input
    #inputElement
    [disabled]="preview"
    [value]="value"
    [hasElements]="recipients.length"
    [autofocus]="autofocus"
    [preventSubmitEvents]="true"
    [preventSubmitOnFocus]="preventSubmitOnFocus"
    [class]="class"
    [placeholder]="placeholder ? placeholder: ''"
    [(label)]="label"
    (modelValueChanged)="onValueChange($event)"
    (keydownEvent)="keydown($event)"
    (blurEvent)="handleBlur()"
    (closeRecipients)="closeRecipients.emit(true)"
    (paste)="onPaste()"
    (focusEvent)="onFocus()"
  >
    <div
      *ngIf="isOnAddToLoop"
      class="chips"
      #chipsWrapper
      [style.max-height.px]="chipsWrapperHeight ? chipsWrapperHeight : 10000"
    >
      <div *ngFor="let chip of recipients; let i = index; trackBy: trackById" [class.chip-wrapper]="!preview">
        <chip
          [chip]="chip"
          [last]="recipients.length <= 2 || i === recipients.length - 1"
          [passive]="passiveDisplay"
          [removable]="chipToRemoveId === chip.id"
          (chipRemoved)="removeChip($event)"
        >
        </chip>
      </div>
    </div>

    <ng-template [ngIf]="!isOnAddToLoop">
      <div [class.chip-wrapper]="!preview" *ngFor="let chip of recipients; let i = index; trackBy: trackById">
        <chip
          [chip]="chip"
          [big]="isOnComposer"
          [last]="recipients.length <= 2 || i === recipients.length - 1"
          [passive]="passiveDisplay"
          [draggable]="draggable"
          [unremovable]="unremovable"
          [removable]="chipToRemoveId === chip.id"
          (chipRemoved)="removeChip($event)"
        >
        </chip>
      </div>
    </ng-template>

    <autosuggest
      *ngIf="!preview && showAutosuggester"
      [query$]="currentQuery"
      [ignoreGroups]="disableGroups || ignoreGroups"
      [filter]="recipients"
      [isOnNewMessage]="isOnNewMessage"
      [extraFilter]="previousRecipients"
      [isOnComposer]="isOnComposer"
      [isOnLoopInPopup]="isOnLoopInPopup"
      [showInvites]="isOnLoopInPopup"
      [showWithEmptyQuery]="alwaysShowAutoSuggest || (showAutosuggestIfEmpty && recipients.length === 0)"
      [extraClass]="class"
      [isFocused]="isFocused"
      [limit]="autosuggestLimit"
      [groupSubTypes]="groupSubTypes"
      [(currentIndexEvent)]="currentIndexEvent"
      (selectedItemEvent)="selectedItemEvent($event)"
      (clicked)="contactClicked($event)"
    >
    </autosuggest>
  </custom-input>
</div>

<!-- Loopin popup -->
<div class="chips-wrapper" (click)="showActiveDisplay()" *ngIf="isOnLoopInPopup">
  <custom-input
    #inputElement
    [disabled]="preview"
    [value]="value"
    [hasElements]="recipients.length"
    [autofocus]="autofocus"
    [preventSubmitEvents]="true"
    [preventSubmitOnFocus]="preventSubmitOnFocus"
    [class]="class"
    [placeholder]="placeholder ? placeholder: ''"
    [(label)]="label"
    (modelValueChanged)="onValueChange($event)"
    (keydownEvent)="keydown($event)"
    (blurEvent)="handleBlur($event)"
    (paste)="onPaste($event)"
    (focusEvent)="onFocus()"
  >
    <div *ngFor="let chip of recipients; let i = index; trackBy: trackById" [class.chip-wrapper]="!preview">
      <chip
        [preview]="preview"
        [chip]="chip"
        [last]="recipients.length <= 2 || i === recipients.length - 1"
        [passive]="passiveDisplay"
        [removable]="chipToRemoveId === chip.id"
        [hidden]="hideCurrentUser && chip.$type === 'User' && chip.email == currentUserId"
        (chipRemoved)="removeChip($event)"
      >
      </chip>
    </div>

    <autosuggest
      *ngIf="!preview && showAutosuggester"
      [ignoreGroups]="disableGroups || ignoreGroups"
      [filter]="recipients"
      [isOnNewMessage]="isOnNewMessage"
      [extraFilter]="previousRecipients"
      [isOnLoopInPopup]="isOnLoopInPopup"
      [showInvites]="isOnLoopInPopup"
      [isOnReplyTo]="isOnReplyTo"
      [showWithEmptyQuery]="alwaysShowAutoSuggest || (showAutosuggestIfEmpty && recipients.length === 0)"
      [extraClass]="class"
      [isFocused]="isFocused"
      [limit]="autosuggestLimit"
      [groupSubTypes]="groupSubTypes"
      [(currentIndexEvent)]="currentIndexEvent"
      (selectedItemEvent)="selectedItemEvent($event)"
      (clicked)="contactClicked($event)"
      [query$]="currentQuery"
    >
    </autosuggest>
  </custom-input>
</div>
