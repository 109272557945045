import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ShortcutsDialogService } from '@shared/services/shortcuts-dialog/shortcuts-dialog.service';
import { RedirectService } from '@shared/services/redirect/redirect.service';
import { UserPreferencesService } from '@shared/services/user-preferences/user-preferences.service';
import { LoopContextMenuService } from '@shared/services/loop-context-menu/loop-context-menu.service';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { ApiModule } from '@shared/api/api-loop/api.module';
import { HttpLoopApiAuthInterceptor } from '@shared/interceptors/http-loop-api-auth.interceptor';
import { UserflowService } from '@shared/services/userflow/userflow.service';
import { LoggerHelperService } from '@shared/services/logger/logger-helper.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { SynchronizationStatusService } from '@shared/synchronization/synchronization-status.service';
import { PushSynchronizationModule } from '@shared/synchronization/push-synchronization/push-synchronization.module';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { CommonLoopModule } from '@shared/modules/common/common-loop.module';
import { StoreModule } from '@shared/stores/store.module';
import { SynchronizationFactory } from '@shared/synchronization/synchronization.factory';
import { WatchdogService } from '@shared/services/watchdog/watchdog.service';
import { FlushDatabaseService } from '@shared/services/flush-db/flush-db.service';
import { LogModule } from '@shared/api/log-loop/api.module';
import { HttpEventService } from '@shared/interceptors/http-event.service';
import { HttpEventApiInterceptor } from '@shared/interceptors/http-event-api.interceptor';
import { HttpResponseInterceptor } from '@shared/interceptors/http-response.interceptor';
import { PopulationModule } from '@shared/populators/populator.module';
import { NotifierService } from '@shared/services/notifier/notifier.service';
import { ChannelSetupService } from '@shared/services/data/channel-inbox-setup/channel-setup.service';
import { SyncSettingService } from '@shared/services/data/sync-settings/sync-settings.service';
import { CreateChannelFormDialogService } from '@shared/services/create-channel-form-dialog/create-channel-form-dialog.service';
import { AvailabilityStatusDialogService } from '@shared/services/availability-status-dialog/availability-status-dialog.service';
import { AccountDropdownService } from '@shared/services/account-dropdown-service/account-dropdown.service';
import { LightBackgroundService } from '@shared/services/light-background.service/light-background.service';
import { IntegrationsProviderService } from '@shared/services/integrations/integrations-provider.service';
import { FolderDialogService } from '@shared/services/folder-dialog/folder-dialog.service';
import { DetailViewConversationSubscriberService } from '@shared/services/detail-view-collection-service/detail-view-collection-service';
import { HttpApiAppDataInterceptor } from '@shared/interceptors/http-api-app-data.interceptor';
import { FileApiCacheService } from '@shared/modules/files/shell/file-api-cache/file-api-cache.service';
import { BaseApiCacheService } from '@shared/api/api-cache/base-api-cache.service';
import { FileDownloadService } from '@shared/modules/files/shell/services/file-download.service';
import { BrowserFileDownloadService } from '@shared/modules/files/shell/services/browser-file-download.service';
import { ContactsPipesModule } from '@shared/modules/contacts/components/pipes/contacts-pipes.module';
import { ErrorClientAppNotSupportedInterceptor } from '@shared/interceptors/errors/error-client-app-not-supported.interceptor';
import { IntegrationDetailsDialogService } from '@dta/ui/services/integration-details-dialog/integration-details-dialog.service';
import { WorkspaceGuard } from '@dta/ui/guards/workspace.guard';
import { ReloginDialogService } from '@dta/ui/components/relogin-dialog/relogin-dialog.service';
import { Time } from '@dta/shared/utils/common-utils';
import { DesktopNotificationsService } from '@dta/ui/services/desktop-notifications/desktop-notifications.service';
import { AccountManagerDialogService } from 'dta/ui/components/common/account-manager-dialog/account-manager-dialog.service';
import { MenuChannelsListService } from 'dta/ui/components/menu/menu-channels-list/menu-channels-list.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { AuthGuard } from '@dta/ui/guards/auth.guard';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { EnableEmailSyncPopupService } from '@dta/ui/components/common/enableEmailSyncPopup/enable-email-sync-popup.service';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { GifPickerApiService } from '@dta/ui/services/gif-picker/gif-picker-api.service';
import { TrackingServiceWeb } from './services/tracking/tracking.service.web';
import { LoggerHelperServiceWeb } from './services/logger/logger-helper.service.web';
import { WebOnboardingServiceWeb } from './services/web-onboarding/web-onboarding.service.web';
import { DatabaseModule } from './database/database.module';
import { SynchronizationFactoryWeb } from './synchronization/synchronization.factory.web';
import { ServiceProviderModule } from './services/service-provider.module.web';
import { WebComponent } from './web.component';
import { WebRoutingModule } from './web-routing.module';
import { HttpProxyHelperInterceptor } from './interceptors/http-proxy-helper.interceptor';
import { StorageProviderService } from '@shared/cache/storage-provider/storage-provider';
import { BrowserComponentModule } from '@shared/ui/browser-component.module';
import { AuthDataAccessModule } from '@shared/modules/auth/auth-data-access/auth-data-access.module';
import { AttachmentService } from '@shared/modules/main/common/attachments/attachment/attachment.service';
import { AppLoadService } from '@shared/modules/app/components/app-load.service';
import { initApp } from '@shared/modules/app/helpers/init-app';
import { ModalFormModule } from '@shared/forms/shell/modal-form/modal-form.module';
import { BrowserConfirmModule } from '@shared/shared/confirm/browser/browser-confirm/browser-confirm.module';
import { ContactsApiCacheModule } from '@shared/modules/contacts/shell/contacts-api-cache/contacts-api-cache.module';
import { IntegrationsHelperModule } from '@shared/services/integrations/helper/integrations-helper.module';
import { FancyDateModule } from '@shared/shared/fancy-date/shell/fancy-date.module';

@NgModule({
  imports: [
    BrowserModule,
    WebRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    ServiceProviderModule,
    ApiModule,
    LogModule,
    CommonLoopModule,
    PushSynchronizationModule,
    StoreModule,
    PopulationModule,
    DatabaseModule,
    ContactsPipesModule,
    BrowserComponentModule.forRoot(),
    AuthDataAccessModule.forRoot(),
    ModalFormModule.forRoot(),
    BrowserConfirmModule.forRoot(),
    IntegrationsHelperModule.forRoot(),
    ContactsApiCacheModule,
    FancyDateModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [WebComponent],
  providers: [
    NotifierService,
    GifPickerApiService,
    AuthGuard,
    WorkspaceGuard,
    KeyboardShortcutsListenerService,
    EnableEmailSyncPopupService,
    StorageService,
    MenuChannelsListService,
    LoopContextMenuService,
    DesktopNotificationsService,
    UserPreferencesService,
    SharedUserManagerService,
    RedirectService,
    DetailViewConversationSubscriberService,
    AccountManagerDialogService,
    ShortcutsDialogService,
    AvailabilityStatusDialogService,
    FolderDialogService,
    AccountDropdownService,
    LightBackgroundService,
    CreateChannelFormDialogService,
    IntegrationDetailsDialogService,
    UserflowService,
    SynchronizationMiddlewareService,
    NotificationsService,
    FlushDatabaseService,
    Time,
    ReloginDialogService,
    ChannelSetupService,
    SyncSettingService,
    IntegrationsProviderService,
    FileApiCacheService,
    BaseApiCacheService,
    StorageProviderService,
    AttachmentService,
    { provide: TrackingService, useClass: TrackingServiceWeb },
    { provide: LoggerHelperService, useClass: LoggerHelperServiceWeb },
    { provide: WebOnboardingService, useClass: WebOnboardingServiceWeb },
    { provide: FileDownloadService, useClass: BrowserFileDownloadService },

    /////////////////////////
    // SYNCHRONIZATION
    /////////////////////////
    SynchronizationStatusService,
    { provide: SynchronizationFactory, useClass: SynchronizationFactoryWeb },
    WatchdogService,
    HttpEventService,
    AppLoadService,
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppLoadService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpProxyHelperInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoopApiAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpApiAppDataInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpEventApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorClientAppNotSupportedInterceptor,
      multi: true
    }
  ],
  exports: [],
  bootstrap: [WebComponent]
})
export class WebModule {}
