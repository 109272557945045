<div class="special-empty">
  <!-- Title -->
  <div class="title">To add your first {{ title }} click add {{ title }} below.</div>

  <!-- Illustration -->
  <svg-comp [src]="illustrationSvg"> </svg-comp>

  <!-- Features -->
  <div class="check-marks">
    <!-- Left column -->
    <div>
      <ng-template ngFor let-item [ngForOf]="['Assign emails', 'Tag emails', 'Mark as read', 'Chat']">
        <div class="item">
          <svg-comp class="check-mark-round" [src]="checkmarkSvg"></svg-comp>
          <span>{{item}}</span>
        </div>
      </ng-template>
    </div>

    <!-- Right column -->
    <div>
      <ng-template
        ngFor
        let-item
        [ngForOf]="[
                    {text: 'Reply emails', icon: replySvg}, 
                    {text: 'Forward emails', icon: forwardSvg}
                ]"
      >
        <div class="item">
          <svg-comp [src]="item.icon"></svg-comp>
          <span>{{item.text}}</span>
        </div>
      </ng-template>

      <div class="some-text">These actions will be reflected on your existing {{ title }}</div>
    </div>
  </div>

  <!-- Cta button -->
  <div class="cta" sleepClickEvent [routerLink]="createLink">Add a {{ title }}</div>

  <a
    *ngIf="sharedInboxes"
    href="https://help.intheloop.io/hc/en-gb/articles/360020453534-How-do-I-set-up-a-Shared-Inbox-"
    target="_blank"
    rel="noopener noreferrer"
  >
    Learn more
  </a>
</div>
