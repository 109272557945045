import { Injector, Type } from '@angular/core';
import { EnumObjectValue } from '@shared/utils/common/types';

export interface DynamicComponentDefinition<C> {
  component: Type<C>;
  module: Type<any>;
  parentInjector?: Injector;
}

export const LOOP_DYNAMIC_COMPONENT_NAME = {
  ModalFormContentComponent: 'ModalFormContentComponent',
  CommentFormComponent: 'CommentFormComponent',
  MailFileComponent: 'MailFileComponent',
  UpdateAppComponent: 'UpdateAppComponent'
} as const;

export const LOOP_DYNAMIC_COMPONENTS: Record<
  EnumObjectValue<typeof LOOP_DYNAMIC_COMPONENT_NAME>,
  { component: () => Promise<DynamicComponentDefinition<any>> }
> = {
  [LOOP_DYNAMIC_COMPONENT_NAME.CommentFormComponent]: {
    component: () =>
      import(/* webpackPrefetch: true */ '@shared/modules/comments/components/comment-form/public-api').then(mod => ({
        component: mod.CommentFormComponent,
        module: mod.CommentFormModule
      }))
  },
  [LOOP_DYNAMIC_COMPONENT_NAME.ModalFormContentComponent]: {
    component: () =>
      import(/* webpackPrefetch: true */ '@shared/forms/shell/modal-form/internals/public-api').then(mod => ({
        component: mod.ModalFormContentComponent,
        module: mod.ModalFormContentModule
      }))
  },
  [LOOP_DYNAMIC_COMPONENT_NAME.MailFileComponent]: {
    component: () =>
      import(/* webpackPrefetch: true */ '@shared/modules/comments/components/mail-file/public-api').then(mod => ({
        component: mod.MailFileComponent,
        module: mod.MailFileModule
      }))
  },
  [LOOP_DYNAMIC_COMPONENT_NAME.UpdateAppComponent]: {
    component: () =>
      import(/* webpackPrefetch: true */ '@shared/modules/updates/components/update-app/public-api').then(mod => ({
        component: mod.UpdateAppComponent,
        module: mod.UpdateAppModule
      }))
  }
};
