import { inject, Injectable } from '@angular/core';
import { Observable, of, OperatorFunction, pipe, race, skipWhile } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { DialogService } from '@shared/ui/kit/dialog/dialog.service';
import { ConfirmService } from '@shared/shared/confirm/common/confirm.service';
import { ReturnDialogData } from '@shared/ui/kit/dialog/return-dialog-data';
import { ConfirmOrDeclineParams } from '@shared/shared/confirm/common/interfaces/confirm-or-decline-params';
import { ConfirmOrDeclineDialogContentComponent } from '@shared/shared/confirm/browser/browser-confirm/internal/confirm-or-decline-dialog-content/confirm-or-decline-dialog-content.component';
import { ConfirmOrDeclineDialogContentModule } from '@shared/shared/confirm/browser/browser-confirm/internal/confirm-or-decline-dialog-content/confirm-or-decline-dialog-content.module';
import { ConfirmSingleButtonParams } from '@shared/shared/confirm/common/interfaces/confirm-single-button-params';
import { ConfirmWithSingleButtonDialogContentComponent } from '@shared/shared/confirm/browser/browser-confirm/internal/confirm-with-single-button-dialog-content/confirm-with-single-button-dialog-content.component';
import { ConfirmWithSingleButtonDialogContentModule } from '@shared/shared/confirm/browser/browser-confirm/internal/confirm-with-single-button-dialog-content/confirm-with-single-button-dialog-content.module';
import { finalizeWithValue } from '@shared/utils/rxjs/finalize-with-value';

@Injectable()
export class BrowserConfirmService extends ConfirmService {
  private readonly dialogService: DialogService = inject(DialogService);

  confirm(params: ConfirmOrDeclineParams): Observable<unknown | never> {
    return this.dialogService
      .openDialog({
        title$: of(params.message),
        componentData: {
          component: ConfirmOrDeclineDialogContentComponent,
          module: ConfirmOrDeclineDialogContentModule
        },
        componentParams: {
          params: {
            confirmButton: {
              fill: 'color',
              color: 'primary'
            },
            declineButton: {
              fill: 'transparent',
              color: 'systemContrast'
            },
            ...params
          }
        },
        width: '500px',
        isClosable: true
      })
      .pipe(
        this.waitForConfirmResult(false),
        first(),
        skipWhile(result => !result)
      );
  }

  confirmOrDecline(params: ConfirmOrDeclineParams): Observable<boolean | never> {
    return this.dialogService
      .openDialog({
        title$: of(params.message),
        componentData: {
          component: ConfirmOrDeclineDialogContentComponent,
          module: ConfirmOrDeclineDialogContentModule
        },
        componentParams: {
          params: {
            confirmButton: {
              fill: 'color',
              color: 'primary'
            },
            declineButton: {
              fill: 'transparent',
              color: 'systemContrast'
            },
            ...params
          }
        },
        width: '500px',
        isClosable: params.isClosable ?? true
      })
      .pipe(
        this.waitForConfirmResult(undefined),
        first(),
        skipWhile(result => result === undefined),
        map(result => result as unknown as boolean)
      );
  }

  confirmWithSingleButton(params: ConfirmSingleButtonParams): Observable<boolean | never> {
    return this.dialogService
      .openDialog({
        title$: of(params.message),
        componentData: {
          component: ConfirmWithSingleButtonDialogContentComponent,
          module: ConfirmWithSingleButtonDialogContentModule
        },
        componentParams: {
          params: params
        },
        width: '340px',
        isClosable: true
      })
      .pipe(
        this.waitForConfirmResult(false),
        first(),
        skipWhile(result => !result)
      );
  }

  private waitForConfirmResult<T extends boolean | undefined>(
    afterCloseFallbackValue: T
  ): OperatorFunction<ReturnDialogData<any>, T> {
    return pipe(
      finalizeWithValue(returnDialogData => {
        returnDialogData.close();
      }),
      switchMap(returnDialogData =>
        race(
          (returnDialogData.componentInstance.confirmResult as Observable<T>).pipe(
            tap(() => {
              setTimeout(() => returnDialogData.close());
            })
          ),
          returnDialogData.afterClose$.pipe(map(() => afterCloseFallbackValue))
        )
      )
    );
  }
}
