import * as _ from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { CommentService } from '../../comment/comment.service';
import { CardBase } from '@shared/api/api-loop/models/card-base';
import { ListOfResourcesOfCardBase } from '@shared/api/api-loop/models';
import { ContactService } from '../../contact/contact.service';
import { CardService } from '../../card/card.service';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { ConversationFetchRequest, FetchResult } from '@dta/shared/models/collection.model';
import { StateUpdates } from '@dta/shared/models/state-updates';
import { CardBaseModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { BaseCollectionService } from '../base-collection/base-collection.service';
import { CardApiService } from '@shared/api/api-loop/services';
import { ConversationHelper } from '../conversation-collection/conversation-helper';

@Injectable()
export class CardsCollectionService extends BaseCollectionService {
  constructor(
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
    private _cardApiService: CardApiService,
  ) {
    super(_commentService, _cardService, _contactService);
  }

  get constructorName(): string {
    return 'CardsCollectionService';
  }

  ///////////////
  // FETCH CARDS
  ///////////////
  fetchCards(forUserEmail: string, fetchRequest: ConversationFetchRequest): Observable<FetchResult> {
    let watch = new StopWatch(this.constructorName + '.fetchCards', ProcessType.SERVICE, forUserEmail);

    let stateUpdates = new StateUpdates();
    let dataLength: number = 0;
    let hasMore: boolean = false;

    return of(undefined).pipe(
      /**
       * Fetch cards
       */
      mergeMap(() => {
        watch.log('_fetchCards');
        return this._fetchCards(forUserEmail, fetchRequest, watch);
      }),
      /**
       * Set list getter status
       */
      map((response: { cards: CardsByOrigin; hasMore: boolean }) => {
        watch.log(
          `_fetchCards.done. new: ${response.cards.getExistingCards().length}, existing: ${response.cards.getNewCards().length}`,
        );
        dataLength = response.cards.getCardCount(fetchRequest.keepAllResults);
        hasMore = response.hasMore;

        return response.cards;
      }),
      /**
       * Publish existing cards (if needed) before we fetch all missing
       * entities for new cards (for speeding up search)
       */
      mergeMap((cards: CardsByOrigin) => {
        if (fetchRequest.keepAllResults) {
          watch.log('publishExistingCards');
          return this.publishExistingCards(forUserEmail, cards.getExistingCards()).pipe(map(() => cards));
        }

        return of(cards);
      }),
      /**
       * Fetch comments for new cards
       */
      mergeMap((cards: CardsByOrigin) => {
        stateUpdates.add(cards.getNewCards());

        watch.log('fetchCommentsForCards: ' + cards.getNewCards().length);
        return this._cardService.fetchCommentsForCards(forUserEmail, cards.getNewCards());
      }),
      /**
       * Fetch, save and publish new contacts
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        watch.log('fetchSaveAndPublishNewContacts');
        return this.fetchSaveAndPublishNewContacts(forUserEmail, _stateUpdates);
      }),
      /**
       * Do follow up get requests for new cards
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        watch.log('followUpGetForNewCards: ' + stateUpdates.cards?.length);
        return this.followUpGetForAllCards(forUserEmail, stateUpdates.cards, watch);
      }),
      /**
       * Save and publish all card and comment updates
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        watch.log('saveAndPublishAll');
        return this.saveAndPublishCardsAndComments(forUserEmail, stateUpdates, watch);
      }),
      /**
       * Return
       */
      map(() => {
        watch.log('done');

        let fetchResult: FetchResult = {
          offsetHistoryId: fetchRequest.offsetHistoryId,
          dataLength: dataLength,
          hasData: hasMore,
        };

        return fetchResult;
      }),
    );
  }

  private _fetchCards(
    forUserEmail: string,
    fetchRequest: ConversationFetchRequest,
    watch: StopWatch,
  ): Observable<{ cards: CardsByOrigin; hasMore: boolean }> {
    return of(undefined).pipe(
      mergeMap(() => {
        watch.log('_cardApiService.Card_GetListByQuery');
        let query = ConversationHelper.getFetchRequestToSearchQueryConversation(fetchRequest);
        return this._cardApiService.Card_GetListByQuery({ query }, forUserEmail);
      }),
      tap((response: ListOfResourcesOfCardBase) => {
        fetchRequest.offsetHistoryId = response.offsetHistoryId;
      }),
      /**
       * Filter out cards that are not new
       */
      mergeMap((response: ListOfResourcesOfCardBase) => {
        watch.log('_searchService.processCards');
        return zip(this.processCards(forUserEmail, response.resources, watch), of(response));
      }),
      /**
       * Join in response and return
       */
      map(([cards, response]) => {
        watch.log('_searchService.processCards.done');
        return { cards: cards, hasMore: response.totalSize !== 0 };
      }),
    );
  }

  private processCards(forUserEmail: string, cards: CardBase[], watch: StopWatch): Observable<CardsByOrigin> {
    return of(undefined).pipe(
      /**
       * Filter new cards
       */
      mergeMap(() => {
        watch.log('filterNewNonStubCards');
        return this._cardService.filterNewNonStubCards(forUserEmail, cards);
      }),
      /**
       * Return union of updated and new cards
       */
      map((newCards: CardBase[]) => {
        let existingCards = _.differenceBy(cards, newCards, card => card.id);

        return new CardsByOrigin(newCards, existingCards);
      }),
    );
  }

  private publishExistingCards(forUserEmail: string, existingCards: CardBase[]): Observable<any> {
    return this._cardService
      .findByIds(
        forUserEmail,
        _.map(existingCards, c => c.id),
      )
      .pipe(
        tap((cards: CardBaseModel[]) => {
          PublisherService.publishStateUpdates(forUserEmail, new StateUpdates(cards));
        }),
      );
  }
}

class CardsByOrigin {
  private newCards: CardBaseModel[];
  private existingCards: CardBaseModel[];

  constructor(newCards: CardBase[] = [], existingCards: CardBase[] = []) {
    this.newCards = CardBaseModel.createList(newCards);
    this.existingCards = CardBaseModel.createList(existingCards);
  }

  getCardCount(keepAllResults: boolean): number {
    return keepAllResults ? this.newCards.length + this.existingCards.length : this.newCards.length;
  }

  getNewCards(): CardBaseModel[] {
    return this.newCards;
  }

  getExistingCards(): CardBaseModel[] {
    return this.existingCards;
  }

  addNewBatch(cards: CardsByOrigin) {
    this.newCards.push(...CardBaseModel.createList(cards.newCards));
    this.existingCards.push(...CardBaseModel.createList(cards.existingCards));
  }
}
