import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'loop-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent {
  @Input() value: string;
  @Input() canRemove: boolean = true;
  @Input() isClickable: boolean = false;

  @Output() remove: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  removeSvg = require('shared/assets/img/svg/close-icon.svg');
}
