/* tslint:disable */

/**
 * User status resource
 */
export type UserStatus = 'Active' | 'Inactive' | 'NotRegistered';

export module UserStatus {
  export const ACTIVE: UserStatus = 'Active';
  export const INACTIVE: UserStatus = 'Inactive';
  export const NOT_REGISTERED: UserStatus = 'NotRegistered';

  export function values(): UserStatus[] {
    return [ACTIVE, INACTIVE, NOT_REGISTERED];
  }
}
