/* tslint:disable */

/**
 * Possible email service auth types
 */
export type EmailServiceAuthType =
  | 'NotSupported'
  | 'LoopLogin'
  | 'GoogleLogin'
  | 'GoogleSync'
  | 'ExchangeLogin'
  | 'ExchangeSync'
  | 'MicrosoftLogin'
  | 'MicrosoftSync'
  | 'ImapLogin'
  | 'ImapSync'
  | 'RemoteImapLogin'
  | 'RemoteImapSync';

export module EmailServiceAuthType {
  export const NOT_SUPPORTED: EmailServiceAuthType = 'NotSupported';
  export const LOOP_LOGIN: EmailServiceAuthType = 'LoopLogin';
  export const GOOGLE_LOGIN: EmailServiceAuthType = 'GoogleLogin';
  export const GOOGLE_SYNC: EmailServiceAuthType = 'GoogleSync';
  export const EXCHANGE_LOGIN: EmailServiceAuthType = 'ExchangeLogin';
  export const EXCHANGE_SYNC: EmailServiceAuthType = 'ExchangeSync';
  export const MICROSOFT_LOGIN: EmailServiceAuthType = 'MicrosoftLogin';
  export const MICROSOFT_SYNC: EmailServiceAuthType = 'MicrosoftSync';
  export const IMAP_LOGIN: EmailServiceAuthType = 'ImapLogin';
  export const IMAP_SYNC: EmailServiceAuthType = 'ImapSync';
  export const REMOTE_IMAP_LOGIN: EmailServiceAuthType = 'RemoteImapLogin';
  export const REMOTE_IMAP_SYNC: EmailServiceAuthType = 'RemoteImapSync';

  export function values(): EmailServiceAuthType[] {
    return [
      NOT_SUPPORTED,
      LOOP_LOGIN,
      GOOGLE_LOGIN,
      GOOGLE_SYNC,
      EXCHANGE_LOGIN,
      EXCHANGE_SYNC,
      MICROSOFT_LOGIN,
      MICROSOFT_SYNC,
      IMAP_LOGIN,
      IMAP_SYNC,
      REMOTE_IMAP_LOGIN,
      REMOTE_IMAP_SYNC
    ];
  }
}
