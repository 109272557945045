import { Injectable } from '@angular/core';
import { File } from '@shared/api/api-loop/models';
import { FileSynchronizationService } from '@shared/services/data/file-synchronization/file-synchronization.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { FileService } from '@shared/services/data/file/file.service';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { Observable, of } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, tap } from 'rxjs/operators';
import { FileExDecorateService } from '@shared/decorators/extra-data-decorators/file-ex-decorator/file-ex-decorate.service';
import { FileViewDecorateService } from '@shared/decorators/view-data-decorators/file-view-decorator/file-view-decorate.service';
import { FileApiCacheService } from '@shared/modules/files/shell/file-api-cache/file-api-cache.service';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';

@Injectable()
export class FileSynchronizationServiceWeb extends FileSynchronizationService {
  constructor(
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _fileStorageService: FileStorageService,
    protected _api: ApiService,
    protected _fileService: FileService,
    protected _fileExDecorateService: FileExDecorateService,
    protected _fileViewDecorateService: FileViewDecorateService,
    protected readonly fileApiCacheService: FileApiCacheService,
  ) {
    super(
      _sharedUserManagerService,
      _fileStorageService,
      _api,
      _fileService,
      _fileExDecorateService,
      _fileViewDecorateService,
      fileApiCacheService,
    );
  }
}
