import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserConfirmService } from './browser-confirm.service';
import { ConfirmService } from '../../common/confirm.service';

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class BrowserConfirmModule {
  static forRoot(): ModuleWithProviders<BrowserConfirmModule> {
    return {
      ngModule: BrowserConfirmModule,
      providers: [{ provide: ConfirmService, useClass: BrowserConfirmService }]
    };
  }
}
