/* tslint:disable */

/**
 * Possible Action Types
 */
export type ActionEnum =
  | 'MarkAsRead'
  | 'MarkAsUnread'
  | 'MarkAsStarred'
  | 'MarkAsUnstarred'
  | 'Archive'
  | 'Delete'
  | 'MoveToInbox'
  | 'MoveToFolder'
  | 'MarkAsReadForAllUsers'
  | 'Follow'
  | 'UnFollow'
  | 'ChangeLabels'
  | 'ChangePrivateLabels'
  | 'ChangeClassification'
  | 'Snooze'
  | 'SnoozeDiscard'
  | 'MarkAsJunk'
  | 'CloseConversation';

export module ActionEnum {
  export const MARK_AS_READ: ActionEnum = 'MarkAsRead';
  export const MARK_AS_UNREAD: ActionEnum = 'MarkAsUnread';
  export const MARK_AS_STARRED: ActionEnum = 'MarkAsStarred';
  export const MARK_AS_UNSTARRED: ActionEnum = 'MarkAsUnstarred';
  export const ARCHIVE: ActionEnum = 'Archive';
  export const DELETE: ActionEnum = 'Delete';
  export const MOVE_TO_INBOX: ActionEnum = 'MoveToInbox';
  export const MOVE_TO_FOLDER: ActionEnum = 'MoveToFolder';
  export const MARK_AS_READ_FOR_ALL_USERS: ActionEnum = 'MarkAsReadForAllUsers';
  export const FOLLOW: ActionEnum = 'Follow';
  export const UN_FOLLOW: ActionEnum = 'UnFollow';
  export const CHANGE_LABELS: ActionEnum = 'ChangeLabels';
  export const CHANGE_PRIVATE_LABELS: ActionEnum = 'ChangePrivateLabels';
  export const CHANGE_CLASSIFICATION: ActionEnum = 'ChangeClassification';
  export const SNOOZE: ActionEnum = 'Snooze';
  export const SNOOZE_DISCARD: ActionEnum = 'SnoozeDiscard';
  export const MARK_AS_JUNK: ActionEnum = 'MarkAsJunk';
  export const CLOSE_CONVERSATION: ActionEnum = 'CloseConversation';

  export function values(): ActionEnum[] {
    return [
      MARK_AS_READ,
      MARK_AS_UNREAD,
      MARK_AS_STARRED,
      MARK_AS_UNSTARRED,
      ARCHIVE,
      DELETE,
      MOVE_TO_INBOX,
      MOVE_TO_FOLDER,
      MARK_AS_READ_FOR_ALL_USERS,
      FOLLOW,
      UN_FOLLOW,
      CHANGE_LABELS,
      CHANGE_PRIVATE_LABELS,
      CHANGE_CLASSIFICATION,
      SNOOZE,
      SNOOZE_DISCARD,
      MARK_AS_JUNK,
      CLOSE_CONVERSATION
    ];
  }
}
