import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { KeyboardActionModeType } from '@dta/shared/models/keyboard-shortcut.model';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { v1 } from 'uuid';

export interface Focusable {
  focus(): void;
}

export type HandleModelChangeOptions = { isSilent?: boolean };
export type OnTouchCallbackTriggerReason = 'MODEL_VALUE_CHANGE' | 'CONTROL_BLUR';

@Component({
  template: ''
})
export abstract class BaseFormControlComponent<T> implements ControlValueAccessor, OnInit, OnDestroy, Focusable {
  private injector: Injector = inject(Injector);
  private keyboardShortcutsListenerService: KeyboardShortcutsListenerService = inject(KeyboardShortcutsListenerService);

  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() submitted?: boolean;
  @Input() size?: 'big' | 'normal' | 'small' = 'normal';
  @Input() labelOnTop?: boolean;

  @Input() @HostBinding('class.bordered') hasBorder: boolean = true;
  @Input() @HostBinding('class.big-radius') hasBigRadius: boolean = false;

  @Input() formControl!: FormControl;
  @Output() controlBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() controlFocus: EventEmitter<void> = new EventEmitter<void>();

  ngControl: NgControl | null = null;
  readonly value$: BehaviorSubject<T | undefined | null> = new BehaviorSubject<T | undefined | null>(undefined);
  readonly isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected destroy$: Subject<void> = new Subject<void>();

  readonly _id: string = v1().substring(0, 12);

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl) {
      this.formControl = this.ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }
  }

  onChange: (value: T) => void = () => {
    // noop
  };

  onTouched: () => void = () => {
    // noop
  };

  writeValue(value: T): void {
    this.value$.next(value);
  }

  handleModelChange(value: T, options?: HandleModelChangeOptions): void {
    this.value$.next(value);
    this.onChange(value);
    if (options?.isSilent !== true && this.shouldTriggerOnTouchCallback('MODEL_VALUE_CHANGE')) {
      this.onTouched();
    }
  }

  protected shouldTriggerOnTouchCallback(triggerReason: OnTouchCallbackTriggerReason): boolean {
    if (triggerReason === 'MODEL_VALUE_CHANGE') {
      // TODO this could be potential problem and we probably should access the `this.ngControl` using the similar way as "initializableCallbackFn", to ensure the ngControl is always available and value is read properly every single time.
      return this.ngControl?.control?.updateOn === 'change';
    }

    if (triggerReason === 'CONTROL_BLUR') {
      // TODO this could be potential problem and we probably should access the `this.ngControl` using the similar way as "initializableCallbackFn", to ensure the ngControl is always available and value is read properly every single time.
      return this.ngControl?.control?.updateOn === 'blur';
    }

    return false;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
    if (!this.formControl) {
      return;
    }
    if ((disabled && this.formControl.disabled) || (!disabled && !this.formControl.disabled)) {
      return;
    }
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  handleBlur(): void {
    this.controlBlur.emit();
  }

  onFocus(): void {
    this.controlFocus.emit();
    this.keyboardShortcutsListenerService.setMode(KeyboardActionModeType.TEXT);
  }

  protected abstract focusHandler(): void;

  focus(): void {
    try {
      setTimeout(() => this.focusHandler());
    } catch {
      // noop
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

// eslint-disable-next-line
export function provideControlValueAccessor(component: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true
  };
}
