import * as _ from 'lodash';
import { AfterViewInit, ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { EMPTY, fromEvent, Observable, of, Subject, Subscription, throttleTime } from 'rxjs';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { catchError, defaultIfEmpty, exhaustMap, filter, finalize, tap } from 'rxjs/operators';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Time } from '../../../../shared/utils/common-utils';
import { Logger } from '@shared/services/logger/logger';
import { LoadMoreType } from '@dta/ui/collections/collection-subscriber.service';

@Directive()
@AutoUnsubscribe()
export abstract class LoadMoreComponent implements AfterViewInit, OnDestroy {
  ///////////////
  // Parameters
  ///////////////
  scrollDebounceTimeMs: number = 100;
  loadMoreDebounceTimeMs: number = 500;
  periodForViewPopulationValidationMs: number = 200;
  redoLoadMoreIfEmptyAfterMs: number = 0.5 * 1000; // Time to wait for view to populate after loadMore before triggering new loadMore
  loadMoreDebounceWaitMs: number = 50;

  loadMoreDirection: LoadMoreDirection = LoadMoreDirection.DOWN;
  loadMoreSize: number = 10;
  initGetSize: number = 20;
  loadMoreScrollThreshold: number = 400; // Trigger load more # px from bottom/top
  autoLoadMoreOnEmptyView: boolean = true;

  ////////////////////
  // State variables
  ////////////////////
  public loadMoreStatus: LoadMoreStatus = LoadMoreStatus.NOT_AVAILABLE; // What is the status of load more process
  private loadMoreState: LoadMoreState = new LoadMoreState();
  private static _logEnabled: boolean = false;
  private loadMoreDebounceFunc: Function; // Must be defined here for "this" context to be correct
  // Set to true if collection reduce can return empty
  // (mismatch between query and reduce is possible. i.e. word search)
  private _canHaveNoMatchesAfterReduce: boolean = false;

  /////////////
  // Subjects
  /////////////
  private loadMore$: Subject<void> = new Subject<void>();

  //////////////////
  // Subscriptions
  //////////////////
  protected loadMoreSub: Subscription;
  protected scrollSub: Subscription;

  ////////////////////
  // ABSTRACT METHODS
  ////////////////////
  protected abstract getScrollWrapper(): Element;
  protected abstract getItems(): any[];
  protected abstract loadMoreCollectionData(): Observable<LoadMoreResult>;

  constructor(protected _changeDetectorRef: ChangeDetectorRef) {
    if (!CONSTANTS.PRODUCTION) {
      window['LoadMore'] = LoadMoreComponent;
    }
  }

  get constructorName(): string {
    return 'LoadMoreComponent';
  }

  ngAfterViewInit() {
    // Subscribe to scroll events after scroll element is rendered
    this.subscribeToScrollEvents();
  }

  ngOnDestroy() {
    this.loadMoreStatus = LoadMoreStatus.NOT_AVAILABLE;

    // Make sure to destroy all references
    this.resetLoadMore();

    // Tmp for trying to fix memory-leaks
    this.loadMoreSub?.unsubscribe();
    this.scrollSub?.unsubscribe();
  }

  protected getMiddleItem(): Element {
    return undefined;
  }

  protected getCorrectlyPositionedItems(): number {
    return 0;
  }

  protected triggerChangeDetection() {
    if (!this._changeDetectorRef['destroyed']) {
      this._changeDetectorRef.detectChanges();
    }
  }

  ///////////
  // GETTERS
  ///////////
  get loadMoreByUser(): boolean {
    return this.loadMoreState.triggeredByUser;
  }

  // True if:
  // 1. Completed but view not full OR
  // 2. In progress OR
  // 3. Waiting
  get isLoadMoreInProgressOrWaiting(): boolean {
    return (
      (this.autoLoadMoreOnEmptyView &&
        !this.loadMoreState.isViewFull &&
        this.loadMoreStatus !== LoadMoreStatus.COMPLETED) ||
      this.loadMoreStatus === LoadMoreStatus.IN_PROGRESS ||
      this.loadMoreStatus === LoadMoreStatus.WAITING_FOR_VIEW_POPULATION
    );
  }

  get isLoadMoreCompleted(): boolean {
    return this.loadMoreStatus === LoadMoreStatus.COMPLETED;
  }

  get isLoadMoreIdle(): boolean {
    return this.loadMoreStatus === LoadMoreStatus.IDLE;
  }

  get size(): number {
    return this.loadMoreSize;
  }

  // [!] NOTE [!]
  // This is not good. It assumes every db query succeeds
  get offset(): number {
    if (_.isEmpty(this.getItems()) && !this._canHaveNoMatchesAfterReduce) {
      this.loadMoreState.offset = 0;
      return this.loadMoreState.offset;
    }

    this.loadMoreState.offset += this.loadMoreSize;
    return this.loadMoreState.offset;
  }

  setCanHaveNoMatchesAfterReduce(value: boolean) {
    this._canHaveNoMatchesAfterReduce = value;
  }

  /////////////////////
  // AVAILABLE METHODS
  /////////////////////
  /**
   * For action that can be triggered multiple times (ex. load more on resize)
   */
  loadMoreDebounce() {
    if (!this.loadMoreDebounceFunc) {
      this.loadMoreDebounceFunc = _.debounce(this._emitLoadMore, this.loadMoreDebounceWaitMs);
    }
    this.loadMoreDebounceFunc(false);
  }

  protected initLoadMore(items: any[]) {
    if (this.loadMoreState._init) {
      return;
    }

    LoadMoreComponent.log(this.constructorName, 'init loadMore');
    this.resetLoadMore();
    this.loadMoreState._init = true;

    if (this.canTriggerLoadMore() || !this.autoLoadMoreOnEmptyView || !this.isViewFull()) {
      this.loadMore$.next();
    }
  }

  protected completeLoadMore() {
    LoadMoreComponent.log(this.constructorName, 'loadMore status set to complete');
    this.loadMoreStatus = LoadMoreStatus.COMPLETED;
  }

  protected loadMoreToIdle() {
    LoadMoreComponent.log(this.constructorName, 'loadMore status set to idle');
    this.loadMoreStatus = LoadMoreStatus.IDLE;
  }

  protected resetLoadMore() {
    LoadMoreComponent.log(this.constructorName, 'resetLoadMore called');

    this.loadMoreStatus = LoadMoreStatus.IDLE;

    // Clear interval before creating new load-more reference
    clearInterval(this.loadMoreState.periodicViewPopulationValidationInterval);
    this.loadMoreState = new LoadMoreState();

    this.loadMoreDebounceFunc = undefined;

    this.subscribeToLoadMore();
  }

  protected showLoader() {
    this.loadMoreStatus = LoadMoreStatus.IN_PROGRESS;

    this.triggerChangeDetection();
  }

  protected hideLoader(force: boolean = false) {
    if (this.loadMoreStatus === LoadMoreStatus.IN_PROGRESS || force) {
      this.loadMoreStatus = LoadMoreStatus.IDLE;
    }

    this.triggerChangeDetection();
  }

  protected canTriggerLoadMore(): boolean {
    return (
      this.loadMoreStatus === LoadMoreStatus.IDLE ||
      this.loadMoreStatus === LoadMoreStatus.WAITING_FOR_VIEW_POPULATION ||
      this.loadMoreStatus === LoadMoreStatus.NOT_AVAILABLE
    );
  }

  protected emitLoadMore(userTriggered: boolean) {
    this._emitLoadMore(userTriggered);
  }

  // Write custom condition for auto load-more detection
  // Besides view being empty and load more not loading enough items
  // Return true to do autoLoadMore and false to not
  protected customAutoLoadMoreCondition(): boolean {
    return true;
  }

  ///////////////
  // SUBSCRIBERS
  ///////////////
  private subscribeToLoadMore() {
    this.loadMoreSub?.unsubscribe();
    this.loadMoreSub = this.loadMore$
      .pipe(
        /**
         * Prevent load-more being called before finishing processing the previous one
         */
        filter(() => {
          return this.canTriggerLoadMore();
        }),
        exhaustMap(() => {
          // Ignore other emmitions until loadMore completes
          return this.triggerLoadMore();
        }),
      )
      .subscribe();

    LoadMoreComponent.log(this.constructorName, 'have subscribed to loadMore$');
  }

  private subscribeToScrollEvents() {
    let scrollWrapper = this.getScrollWrapper();

    if (!scrollWrapper) {
      Logger.warn('Could not subscribe to load-more scroll events on', 'LoadMoreComponent');
      LoadMoreComponent.log(this.constructorName, 'scrollWrapper is undefined');
      return;
    }

    this.scrollSub?.unsubscribe();
    this.scrollSub = fromEvent(scrollWrapper, 'scroll')
      .pipe(
        /**
         * Filter based on scroll direction
         */
        filter(() => {
          let scrollTop = scrollWrapper.scrollTop;
          let emitEvent = false;

          if (this.loadMoreState.lastScrollTop) {
            // Scrolling up
            if (this.loadMoreState.lastScrollTop > scrollTop) {
              emitEvent = this.loadMoreDirection === LoadMoreDirection.UP;
            }

            // Scrolling down
            if (this.loadMoreState.lastScrollTop < scrollTop) {
              emitEvent = this.loadMoreDirection === LoadMoreDirection.DOWN;
            }
          }

          this.loadMoreState.lastScrollTop = scrollTop;

          return emitEvent;
        }),
        throttleTime(this.scrollDebounceTimeMs),
        tap(() => {
          // Scroll is user triggered
          this._emitLoadMore(true);
        }),
      )
      .subscribe();

    LoadMoreComponent.log(this.constructorName, 'have subscribed to scroll events');
  }

  ///////////////////
  // PRIVATE HELPERS
  ///////////////////
  private _emitLoadMore(userTriggered: boolean) {
    if (!this.canTriggerLoadMore()) {
      return;
    }

    // Get scroll wrapper
    let scrollWrapper = this.getScrollWrapper();
    if (!scrollWrapper) {
      Logger.warn('Scroll wrapper is null on', this.constructorName);
      LoadMoreComponent.log(this.constructorName, 'scrollWrapper is undefined');
      return;
    }

    // Get scroll parameters
    let scrollTop = scrollWrapper.scrollTop; // Invisible content on top
    let scrollHeight = scrollWrapper.scrollHeight; // Height of whole scroll content
    let clientHeight = scrollWrapper.clientHeight; // Height of visible  field
    let heightDiff = scrollHeight - (scrollTop + clientHeight); // Distance till the bottom of list

    let shouldLoadMore =
      this.loadMoreDirection === LoadMoreDirection.UP
        ? scrollTop <= this.loadMoreScrollThreshold
        : heightDiff <= this.loadMoreScrollThreshold;

    if (this.getMiddleItem() && this.getCorrectlyPositionedItems() > 0) {
      let itemHeight = this.getMiddleItem().clientHeight;
      let lastItemIndexInViewPort = Math.floor(scrollTop / itemHeight + clientHeight / itemHeight);

      let currentPage = Math.floor((lastItemIndexInViewPort / this.loadMoreSize) * 2);
      let loadedPage = Math.ceil((this.getCorrectlyPositionedItems() / this.loadMoreSize) * 2);

      if (currentPage > loadedPage - 3) {
        // Load more if only 3 pages are left
        this.loadMore$.next();
        this.loadMoreState.triggeredByUser = userTriggered;
      }
    }

    if (shouldLoadMore || (this.autoLoadMoreOnEmptyView && !this.isViewFull())) {
      // We need this for 'End of load more' info
      this.loadMoreState.triggeredByUser = userTriggered;
      this.loadMore$.next();
    }
  }

  private triggerLoadMore(): Observable<LoadMoreResult> {
    if (!this.canTriggerLoadMore()) {
      return;
    }
    LoadMoreComponent.log(this.constructorName, 'loadMore triggered', SpecialLogAction.LOAD_MORE_TRIGGERED);

    // Set current clientHeight
    this.loadMoreState.scrollHeightBeforeLoadMore = this.getScrollHeight();

    // Start loadMore
    this.showLoader();
    LoadMoreComponent.log(this.constructorName, 'loadMoreCollectionData() called');
    return this.loadMoreCollectionData().pipe(
      defaultIfEmpty(undefined),
      /**
       * Catch any error and handle it
       */
      catchError(err => {
        LoadMoreComponent.log(this.constructorName, 'error while calling loadMoreCollectionData');

        if (err.status !== 0) {
          this.loadMoreStatus = LoadMoreStatus.COMPLETED;
          return EMPTY;
        }
        return of({});
      }),
      /**
       * Handle load more status
       */
      tap((loadMoreResult: LoadMoreResult) => {
        // Empty result (error)
        if (!loadMoreResult || _.isNil(loadMoreResult) || loadMoreResult.status === LoadMoreResultStatus.NULL) {
          LoadMoreComponent.log(this.constructorName, 'loadMore is not available');
          this.loadMoreStatus = LoadMoreStatus.NOT_AVAILABLE;

          // Restart load more after 3 seconds
          _.delay(() => {
            LoadMoreComponent.log(this.constructorName, 'loadMore restarted');
            this.loadMore$.next();
          }, 3 * 1000);

          return;
        }

        // No data loaded
        if (
          loadMoreResult.status === LoadMoreResultStatus.NO_DATA &&
          !(
            loadMoreResult.loadMoreType === LoadMoreType.QUERY_AND_FETCH &&
            loadMoreResult.method === LoadMoreMethod.QUERY
          )
        ) {
          LoadMoreComponent.log(this.constructorName, 'loadMore returned no results');
          this.completeLoadMore();
          return;
        }

        // New data loaded and published or load more in progress
        LoadMoreComponent.log(this.constructorName, 'loadMore returned results');
        this.loadMoreStatus = LoadMoreStatus.WAITING_FOR_VIEW_POPULATION;

        this.periodicViewPopulationValidation();
      }),
      finalize(() => {
        LoadMoreComponent.log(this.constructorName, 'loadMore finished', SpecialLogAction.LOAD_MORE_FINISHED);

        // Detect changes that come from load more status
        this.triggerChangeDetection();
      }),
    );
  }

  // Will return true, if middle list is full or false if
  // we need to fetch/query more elements to fill the list view
  private isViewFull(): boolean {
    if (this.loadMoreState.isViewFull) {
      return true;
    }

    let scrollWrapper = this.getScrollWrapper();

    if (!scrollWrapper) {
      Logger.warn('Scroll wrapper is null on', 'LoadMoreComponent.isViewFull');
      LoadMoreComponent.log(this.constructorName, 'Scroll wrapper is null');
      return true;
    }

    let scrollHeight = scrollWrapper.scrollHeight; // Height of whole scroll content
    let clientHeight = scrollWrapper.clientHeight; // Height of visible  field

    let isViewFull = scrollHeight > clientHeight;
    if (!isViewFull) {
      LoadMoreComponent.log(this.constructorName, 'view is not full');
    } else {
      this.loadMoreState.isViewFull = isViewFull;
    }

    return isViewFull;
  }

  private getScrollHeight(): number {
    let scrollWrapper = this.getScrollWrapper();

    if (!scrollWrapper) {
      Logger.warn('Scroll wrapper is null on', 'LoadMoreComponent.getClientHeight');
      LoadMoreComponent.log(this.constructorName, 'Scroll wrapper is null');
      return 0;
    }

    return scrollWrapper.scrollHeight;
  }

  /**
   * Will periodically check if enough data was shown (for user to have ability to
   * trigger another loadMore or for view to be full).
   * After some timeout time, it will automatically trigger another page of loadMore.
   */
  private periodicViewPopulationValidation() {
    // Clear old periodic check
    clearInterval(this.loadMoreState.periodicViewPopulationValidationInterval);

    // Set new periodic task
    this.loadMoreState.periodicViewPopulationValidationInterval = setInterval(() => {
      let scrollHeightNow = this.getScrollHeight();
      let diff = scrollHeightNow - this.loadMoreState.scrollHeightBeforeLoadMore;
      let spaceToScroll = this.getSpaceToScroll();
      let isViewFull = this.isViewFull();
      let customCondition = this.customAutoLoadMoreCondition();

      //////////////////
      // VALIDATION: OK
      //////////////////
      if (
        (diff > this.loadMoreScrollThreshold && // Did load more
          spaceToScroll > this.loadMoreScrollThreshold && // Has space to scroll
          (!this.autoLoadMoreOnEmptyView || isViewFull)) || // View is full
        !customCondition // Custom condition satisfied
      ) {
        // LoadMore to idle if it is still in waiting state
        if (this.loadMoreStatus === LoadMoreStatus.WAITING_FOR_VIEW_POPULATION) {
          this.loadMoreStatus = LoadMoreStatus.IDLE;
        }

        // Clear timeout
        clearInterval(this.loadMoreState.periodicViewPopulationValidationInterval);
        return;
      }

      ///////////////////////
      // VALIDATION: NOT OK
      ///////////////////////
      // Increment counter.
      this.loadMoreState.periodicViewPopulationValidationCounter++;
      let passedTime =
        this.loadMoreState.periodicViewPopulationValidationCounter * this.periodForViewPopulationValidationMs;

      // Trigger load more after timeout or when view is not full
      if (
        (passedTime > this.redoLoadMoreIfEmptyAfterMs || // Timeout OR
          (this.autoLoadMoreOnEmptyView && !isViewFull)) && // View empty AND
        customCondition // CUstom condition
      ) {
        // Trigger loadMore
        this.loadMore$.next();

        // Clear timeout
        clearInterval(this.loadMoreState.periodicViewPopulationValidationInterval);
        return;
      }
    }, this.periodForViewPopulationValidationMs);
  }

  private getSpaceToScroll(): number {
    // Get scroll wrapper
    let scrollWrapper = this.getScrollWrapper();
    if (!scrollWrapper) {
      Logger.warn('Scroll wrapper is null on', this.constructorName + '.getSpaceToScroll()');
      LoadMoreComponent.log(this.constructorName, 'scrollWrapper is undefined');
      return;
    }

    if (this.loadMoreDirection === LoadMoreDirection.DOWN) {
      let scrollHeight = scrollWrapper.scrollHeight;
      let scrollTop = scrollWrapper.scrollTop;
      let clientHeight = scrollWrapper.clientHeight;

      return scrollHeight - (clientHeight + scrollTop);
    }

    if (this.loadMoreDirection === LoadMoreDirection.UP) {
      return scrollWrapper.scrollTop;
    }
  }

  ///////////
  // LOGGING
  ///////////
  static enableLogging(): void {
    this._logEnabled = true;
  }

  static disableLogging(): void {
    this._logEnabled = false;
  }

  // NOTE: turn logging on by calling "IPCBase.enableLogging()" from console
  static log(component: string, message: string, specialLogAction?: SpecialLogAction): void {
    if (!LoadMoreComponent._logEnabled) {
      return;
    }

    // Header
    let header = '%cLOADMORE: %c ';
    let headerData = ['color: #000000' + '; font-weight: 600', void 0];

    // TimeStamp
    let timeStamp = '%c[%s]%c ~ ';
    let timeStampData = ['color: #54C8E8', Time.getTimestamp(true), void 0];

    // Component
    let componentMsg = '%c[component: %s]%c - ';
    let componentData = ['color: #536157', component, void 0];

    let msg = '%c%s%c';
    let msgData: string[] = [void 0, message, void 0];
    if (specialLogAction !== undefined) {
      // Convert enum to number by '+' and compare it
      switch (+specialLogAction) {
        case SpecialLogAction.LOAD_MORE_FINISHED:
          msgData = ['color: #eb34bd', message, void 0];
          break;
        case SpecialLogAction.LOAD_MORE_TRIGGERED:
          msgData = ['color: #eb34bd; font-weight: 600', message, void 0];
          break;
        default:
          throw new Error('Unsupported specialLogAction: ' + specialLogAction);
      }
    }

    // Combine all
    let logMsg = header + timeStamp + componentMsg + msg;
    let logData = [logMsg, ...headerData, ...timeStampData, ...componentData, ...msgData];

    Logger['log'](...logData);
  }
}

export class LoadMoreState {
  _init: boolean = false;
  isViewFull: boolean = false;
  offset: number = 0;
  triggeredByUser: boolean = false; // For correctly showing "End of list" info
  scrollHeightBeforeLoadMore: number = 0.0; // For calculating if content was loaded to view or nor
  periodicViewPopulationValidationInterval;
  periodicViewPopulationValidationCounter: number = 0;

  //                         ~
  //  |/////////////////|    |
  //  |// Scroll top ///|    |
  //  |/////////////////|    |
  //  +-----------------+    | s  h
  //  |                 |    | c  e
  //  |                 |    | r  i
  //  |  Client height  |    | o  g
  //  |                 |    | l  h
  //  |                 |    | l  t
  //  +-----------------+    |
  //  |/////////////////|    |
  //  |/////////////////|    |
  //                         ~

  // Scroll parameters
  lastScrollTop: number = undefined;
}

export class LoadMoreResult {
  constructor(
    public status: LoadMoreResultStatus,
    public method: LoadMoreMethod,
    public loadMoreType: LoadMoreType,
  ) {}
}

export enum LoadMoreDirection {
  UP, // (ex.: chat)
  DOWN,
}

export enum LoadMoreStatus {
  IDLE = 'IDLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  WAITING_FOR_VIEW_POPULATION = 'WAITING_FOR_VIEW_POPULATION',
}

export enum LoadMoreMethod {
  QUERY,
  FETCH,
}

export enum LoadMoreResultStatus {
  NO_DATA,
  HAVE_DATA,
  NULL,
}

export enum SpecialLogAction {
  LOAD_MORE_TRIGGERED,
  LOAD_MORE_FINISHED,
}
