import { ClassificationNode } from '@shared/modules/shared-tag/common/interfaces/classification-node';

export function filterClassificationTree(
  classificationNode: ClassificationNode,
  query: string
): ClassificationNode | null {
  const currentNodeMatches = classificationNode.classificationTag.name.toLowerCase().includes(query.toLowerCase());

  const filteredChildNodes = classificationNode.childNodes
    .map(childNode => filterClassificationTree(childNode, query))
    .filter(Boolean) as ClassificationNode[]; // Filter out null nodes

  if (currentNodeMatches || filteredChildNodes.length > 0) {
    return {
      ...classificationNode,
      childNodes: filteredChildNodes
    };
  }

  // Otherwise, exclude this node
  return null;
}
