import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { ModalFormService } from './modal-form.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, DialogModule]
})
export class ModalFormModule {
  static forRoot(): ModuleWithProviders<ModalFormModule> {
    return {
      ngModule: ModalFormModule,
      providers: [ModalFormService]
    };
  }
}
