import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import {
  AvailabilityStatusDialogService,
  AvailabilityUserStatusDialogData
} from '@shared/services/availability-status-dialog/availability-status-dialog.service';
import { AvailabilityStatusSubscriberService } from '@shared/services/availability-status-subscriber/availability-status-subscriber.service';
import { AvailabilityStatusService } from '@shared/services/data/availability-status/availability-status.service';
import { AvailabilityClearAfterEnum } from '@shared/api/api-loop/models/availability-clear-after-enum';
import { AvailabilityUserStatus } from '@shared/api/api-loop/models/availability-user-status';
import { UserAvailabilityStatusService } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { CommentOutOfOffice } from '@shared/api/api-loop/models/comment-out-of-office';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { Modal } from '@dta/ui/components/common/modal/modal.component';
import { mergeMap, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { AvailabilityStatusListComponent } from '@dta/ui/components/common/availability-status/availability-status-list.component';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { EmojiPosition } from '@dta/ui/components/common/emoji-picker/emoji-picker.component';
import { TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';
import { SelectDropdownOptions } from '@dta/ui/components/common/select-dropdown/select-dropdown.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { KeyboardActionModeType } from '@dta/shared/models/keyboard-shortcut.model';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { RangeDate } from '@dta/ui/components/date-picker/date-picker.component';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';

@Component({
  selector: 'availability-status-dialog',
  templateUrl: './availability-status-dialog.html',
  styleUrls: ['./availability-status-dialog.scss']
})
export class AvailabilityStatusDialogComponent extends AvailabilityStatusListComponent implements OnInit {
  protected readonly emojiPosition: typeof EmojiPosition = EmojiPosition;
  protected readonly TooltipPlacement: typeof TooltipPlacement = TooltipPlacement;

  ///////////
  // SVGs
  ///////////
  protected readonly closeMediumSvg: string = require('shared/assets/img/svg/close-medium.svg');
  protected readonly checkSvg: string = require('@shared/assets/img/svg/checkmark.svg');
  protected readonly closeSvg: string = require('@shared/assets/img/svg/close.svg');

  ///////////
  // State
  ///////////
  status: AvailabilityStatusModel = new AvailabilityStatusModel();
  dropdownOptions: SelectDropdownOptions = { options: [], hasSelection: true };
  dateSelected: string;
  datePickerOpen: boolean = false;
  statusData: AvailabilityUserStatusDialogData;
  hasOutOfOffice: boolean;

  private dateFormat: string = 'MMMM Do, YYYY [at] h:mm a';

  @ViewChild('statusPopup', { static: true }) statusPopup: Modal;

  //////////////////
  // Subscriptions
  //////////////////
  private openSub: Subscription;
  private createStatus: Subscription;

  constructor(
    protected _availabilityStatusDialogService: AvailabilityStatusDialogService,
    protected _userManagerService: UserManagerService,
    protected _availabilityStatusSubscribedService: AvailabilityStatusSubscriberService,
    protected _changeDetection: ChangeDetectorRef,
    protected _availabilityStatusService: AvailabilityStatusService,
    private _userAvailabilityStatusService: UserAvailabilityStatusService,
    private _keyboardShortcutsListenerService: KeyboardShortcutsListenerService,
    private _commentService: CommentService,
    private _storageService: StorageService,
    private _router: Router,
    private _notificationsService: NotificationsService,
    private trackingService: TrackingService
  ) {
    super(_userManagerService, _availabilityStatusSubscribedService, _availabilityStatusService, _changeDetection);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.status.enabledAssignments = true;
    this.triggerChangeDetection();
    this.subscribeToDialogToggle();
    this.setClearAfterDropdownOptions();
  }

  get outOfOfficeKey(): string {
    return this._storageService.getKey(this._userManagerService.getCurrentUserEmail(), StorageKey.outOfOffice);
  }

  onPredefinedStatusClick(status: AvailabilityStatusModel): void {
    // Deep copy status
    this.status = JSON.parse(JSON.stringify(status));
    this.status.outOfOffice = this.hasOutOfOffice ? this.status.outOfOffice : false;
    this.dropdownOptions.selectedOption = this.status.clearAfter;
  }

  initStatus(): void {
    this.status = new AvailabilityStatusModel();
  }

  onInputFocus(): void {
    this._keyboardShortcutsListenerService.setMode(KeyboardActionModeType.MODAL);
  }

  openOutOfOffice(): void {
    this._router.navigate(['/user-settings/out-of-office']);
    this.statusPopup.close();
  }

  onValueChange(status: AvailabilityStatusModel): void {
    if (!status.emoji && status.name.length > 0) {
      status.emoji = '💬';
    }
  }

  onDropdownSelect($event: AvailabilityClearAfterEnum | CustomOption): void {
    if ($event === CustomOption.CHOOSE_DATE_AND_TIME) {
      this.datePickerOpen = true;
      return;
    }

    this.dropdownOptions.selectedOption = $event;
    this.status.clearAfter = $event as AvailabilityClearAfterEnum;
  }

  onConfirm(confirm: boolean): void {
    if (confirm) {
      if (!this.status.name || !this.status.emoji || this.status.name.length > 128) {
        return;
      }

      const userAvailabilityStatus: AvailabilityUserStatus = {
        status: this.status.name,
        clearAfter: this.getClearAfterTime(),
        emoji: this.status.emoji,
        enabledAssignments: this.status.enabledAssignments,
        outOfOffice: this.status.outOfOffice
      };

      this.trackingService.track(this._userManagerService.getCurrentUserEmail(), 'Change Status', {
        selectedStatusName: userAvailabilityStatus.status,
        selectedStatus: userAvailabilityStatus,
        previousStatusName: this.statusData?.availabilityUserStatus?.status,
        previousStatus: this.statusData?.availabilityUserStatus
      });

      this.createStatus?.unsubscribe();
      this.createStatus = of(undefined)
        .pipe(
          mergeMap(() => {
            return this._userAvailabilityStatusService.createOrUpdateUserAvailabilityStatus(
              this._userManagerService.getCurrentUserEmail(),
              this._userManagerService.getCurrentUser().id,
              userAvailabilityStatus
            );
          }),
          tap(() => {
            this._notificationsService.setInAppNotification(this._userManagerService.getCurrentUserEmail(), {
              type: NotificationEventType.AvailabilityStatusCreated
            });
            this.statusPopup.close();
          })
        )
        .subscribe();
    } else {
      this.statusPopup.close();
    }
  }

  onClose(): void {
    this.status = new AvailabilityStatusModel();
    this.statusPopup.isOpened = false;
  }

  onDateSelected($event: RangeDate): void {
    this.datePickerOpen = false;
    this.dropdownOptions.selectedOption = CustomOption.CUSTOM;
    this.dateSelected = $event.from.toISOString();

    // Set display name for selected item
    _.map(this.dropdownOptions.options, option => {
      if (option.option === CustomOption.CUSTOM) {
        let dt = moment($event.from);
        option.displayName = dt.format(this.dateFormat);
      }
    });
  }

  closeDatePicker(): void {
    this.datePickerOpen = false;
  }

  private subscribeToDialogToggle(): void {
    this.openSub?.unsubscribe();
    this.openSub = this._availabilityStatusDialogService.toggleDialog$
      .pipe(
        tap(() => {
          let outOfOfficeKey = this._storageService.getParsedItem(this.outOfOfficeKey);
          if (outOfOfficeKey) {
            this.hasOutOfOffice = true;
          }
        }),
        /**
         * Set passed status
         */
        tap((statusData: AvailabilityUserStatusDialogData) => {
          this.statusData = statusData;
          this.dateSelected = moment(new Date()).startOf('hour').add(1, 'h').toDate().toISOString();
          if (statusData && statusData.availabilityUserStatus) {
            this.status = AvailabilityStatusModel.createFromUserStatus(statusData.availabilityUserStatus);

            // Set display name for defined status
            if (statusData.availabilityUserStatus.clearAfter) {
              this.dropdownOptions.selectedOption = CustomOption.CUSTOM;
              _.map(this.dropdownOptions.options, option => {
                if (option.option === CustomOption.CUSTOM) {
                  let dt = moment(statusData.availabilityUserStatus.clearAfter);
                  let format =
                    dt.hours() === 0 && dt.minutes() === 0 && dt.seconds() === 0 ? 'MMMM Do, YYYY' : this.dateFormat;
                  option.displayName = dt.format(format);
                }
              });

              // Set initial date-picker date
              this.dateSelected = statusData.availabilityUserStatus.clearAfter;
            } else {
              this.dateSelected = new Date().toISOString();
              this.dropdownOptions.selectedOption = AvailabilityClearAfterEnum.NONE;
            }
          } else {
            _.map(this.dropdownOptions.options, option => {
              if (option.option === CustomOption.CUSTOM) {
                option.displayName = '';
              }
            });
            this.dropdownOptions.selectedOption = AvailabilityClearAfterEnum.TODAY;
            this.status.clearAfter = AvailabilityClearAfterEnum.TODAY;
          }
          this.statusPopup.open();
          this._keyboardShortcutsListenerService.setMode(KeyboardActionModeType.MODAL);
        }),
        /**
         * Fetch out of office
         */
        mergeMap(() => {
          if (!this.hasOutOfOffice) {
            return this._commentService.fetchOutOfOffice(this._userManagerService.getCurrentUserEmail()).pipe(
              tap((outOfOffice: CommentOutOfOffice) => {
                if (outOfOffice) {
                  this._storageService.setStringifiedItem(this.outOfOfficeKey, true);
                  this.hasOutOfOffice = true;
                }
              })
            );
          }
          return of(undefined);
        })
      )
      .subscribe();
  }

  private setClearAfterDropdownOptions(): void {
    this.dropdownOptions.selectedOption = AvailabilityClearAfterEnum.TODAY;
    this.dropdownOptions.options = [
      { option: AvailabilityClearAfterEnum.NONE, displayName: "Don't clear" },
      { option: AvailabilityClearAfterEnum.MINUTES__30, displayName: '30 minutes' },
      { option: AvailabilityClearAfterEnum.HOURS__1, displayName: '1 hour' },
      { option: AvailabilityClearAfterEnum.HOURS__4, displayName: '4 hours' },
      { option: AvailabilityClearAfterEnum.TODAY, displayName: 'Today' },
      { option: AvailabilityClearAfterEnum.THIS_WEEK, displayName: 'This week' },
      { option: CustomOption.CHOOSE_DATE_AND_TIME },
      { option: CustomOption.CUSTOM, displayName: '', hidden: true }
    ];
  }

  private getClearAfterTime(): string {
    if (this.dropdownOptions.selectedOption === CustomOption.CUSTOM) {
      return this.dateSelected;
    }

    let date = new Date();
    switch (this.status.clearAfter) {
      case AvailabilityClearAfterEnum.NONE:
        return null;
      case AvailabilityClearAfterEnum.MINUTES__30:
        date.setTime(date.getTime() + 30 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.HOURS__1:
        date.setTime(date.getTime() + 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.HOURS__2:
        date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.HOURS__4:
        date.setTime(date.getTime() + 4 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.HOURS__8:
        date.setTime(date.getTime() + 8 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.HOURS__12:
        date.setTime(date.getTime() + 12 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.DAYS__1:
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.DAYS__2:
        date.setTime(date.getTime() + 2 * 24 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.DAYS__3:
        date.setTime(date.getTime() + 3 * 24 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.DAYS__7:
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.DAYS__14:
        date.setTime(date.getTime() + 14 * 24 * 60 * 60 * 1000);
        break;
      case AvailabilityClearAfterEnum.TODAY:
        this.getMidnightAfterDays(date, 1).toISOString();
        break;
      case AvailabilityClearAfterEnum.TOMORROW:
        this.getMidnightAfterDays(date, 2).toISOString();
        break;
      case AvailabilityClearAfterEnum.THIS_WEEK:
        let daysToNextMonday = ((7 - new Date().getDay()) % 7) + 1;
        this.getMidnightAfterDays(date, daysToNextMonday).toISOString();
        break;
      case AvailabilityClearAfterEnum.NEXT_BUSINESS_DAY:
        return null;
      case AvailabilityClearAfterEnum.NEXT_WEEK:
        return null;
    }

    return date.toISOString();
  }

  private getMidnightAfterDays(date: Date, days: number): Date {
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + days);
    return date;
  }
}

export enum CustomOption {
  CUSTOM = 'Custom',
  CHOOSE_DATE_AND_TIME = 'Choose date and time'
}
