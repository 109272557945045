import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IntegrationType } from '@shared/api/api-loop/models/integration-type';

@Injectable()
export class IntegrationDetailsDialogService {
  dialogEvents$: Subject<IntegrationDialogData> = new Subject();

  get dialogEventsObs(): Observable<IntegrationDialogData> {
    return this.dialogEvents$.asObservable();
  }
}

export interface IntegrationDialogData {
  type: 'open' | 'close' | 'update';
  data?: AddIntegrationExtraData;
}

export interface AddIntegrationExtraData {
  subject?: Subject<any>;
  integrationType?: IntegrationType;

  errorCode?: number;
  errorText?: string;
}

export interface IntegrationDetailsResponseType {
  integrationType: IntegrationType;
  data: IntegrationDetailsResponseBase;
}

export class IntegrationDetailsResponseBase {
  constructor(public type: string) {}
}

export class IntegrationOpenAIDetails extends IntegrationDetailsResponseBase {
  static type: string = 'OpenAIDetails';

  constructor(public apiKey: string) {
    super(IntegrationOpenAIDetails.type);
  }
}

export class IntegrationCustomDetails extends IntegrationDetailsResponseBase {
  static type: string = 'IFrameDetails';

  constructor(public iFrameUrl: string) {
    super(IntegrationCustomDetails.type);
  }
}
