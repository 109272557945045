import * as _ from 'lodash';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  SharedTagAssigneeModel,
  SharedTagClassificationModel,
  SharedTagLabelModel,
  SharedTagStatusModel,
  SharedTagSystemModel,
  StaticSharedTagIds
} from '../../../../shared/models-api-loop/shared-tag/shared-tag.model';
import { ListOfTags } from '@shared/api/api-loop/models/list-of-tags';
import { BaseModel } from '../../../../shared/models-api-loop/base/base.model';
import { SharedTagBase } from '@shared/api/api-loop/models/shared-tag-base';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { SharedTagSystemNames, User } from '@shared/api/api-loop/models';
import { TrackingService } from '../../../../shared/services/tracking/tracking.service';
import { ClickTrackingLocation } from '../../../../shared/services/tracking/tracking.constants';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';
import { OptimisticResponseHelper } from '../conversation-list/optimistic-response.helper';
import { TagLabelModel } from '@dta/shared/models-api-loop/tag.model';

const fallbackColor = '#ff0000';

@Component({
  selector: 'shared-inbox-tag',
  templateUrl: 'shared-inbox-tag.html',
  styleUrls: ['shared-inbox-tag.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedInboxTagComponent implements OnChanges {
  ////////
  // SVGs
  ////////
  dropdownSvg = require('shared/assets/img/svg/dropdown.svg');
  assignSvg = require('shared/assets/img/svg/small-assigned-to-me.svg');
  smallDropdownSvg = require('shared/assets/img/svg/smaller-dropdown.svg');
  snoozeSvg = require('shared/assets/img/svg/snooze.svg');

  /////////////////
  // Input/Output
  /////////////////
  @Input() sharedTags: ListOfTags;
  @Input() labelTags: TagLabelModel[] = [];
  @Input() snoozeDueDate: string;
  @Input() isOnHeader: boolean = false;
  @Input() isClickable: boolean = false;
  @Input() delegating: boolean = false;
  @Input() offline: boolean = false;
  @Input() hideSpam: boolean = false;
  @Input() recipient: string;
  @Input() shouldShowRecipientTag: boolean = false;

  @Output() tagAssignee = new EventEmitter();
  @Output() tagStatus = new EventEmitter();
  @Output() tagsSystem = new EventEmitter();
  @Output() tagClicked = new EventEmitter<SharedTagBase>();
  @Output() channelTagClicked = new EventEmitter<boolean>();

  @ViewChild('sharedTagsContainer', { static: false }) protected sharedTagsContainer: ElementRef;

  ///////////////////
  // State variables
  ///////////////////
  assigneeTag: SharedTagAssigneeModel;
  statusTag: SharedTagStatusModel;
  systemTags: SharedTagSystemModel[];
  sharedLabelTags: SharedTagLabelModel[];
  classificationTag: SharedTagClassificationModel;

  showHiddenTags: boolean = false;
  hasOverflow: boolean = false;
  color: string;
  name: string;
  displayAssignButton: boolean = false;
  isWin: boolean;
  SharedTagSystemNames = SharedTagSystemNames;
  protected debounceTagsOverflow: Function = undefined;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _changeDetection: ChangeDetectorRef,
    protected _trackingService: TrackingService
  ) {
    this.isWin = checkIfOS(OperatingSystem.WINDOWS);
    this.debounceTagsOverflow = _.debounce(this.checkTagsOverflow.bind(this), 500);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.labelTags) {
      this.handleLabelTagsChange();
    }

    if (changes.sharedTags) {
      this.handleSharedLabelTagsChange(changes);
    }
  }

  toggleHiddenTags(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.showHiddenTags = !this.showHiddenTags;
    this.triggerChangeDetection();
  }

  onResize() {
    this.debounceTagsOverflow();
  }

  onTagClick(event: MouseEvent, tag: SharedTagBase) {
    if (!this.isClickable || _.isEmpty(tag)) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    // Track user click action
    this._trackingService.trackUserClick(
      this._userManagerService.getCurrentUserEmail(),
      ClickTrackingLocation.ViewSearch,
      'Add tag to filter',
      { tagId: tag.id }
    );

    // Emit to parent
    this.tagClicked.emit(tag);
  }

  onChannelTagClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    // Track user click action
    this._trackingService.trackUserClick(
      this._userManagerService.getCurrentUserEmail(),
      ClickTrackingLocation.ViewSearch,
      'Add channel tag to filter'
    );

    this.channelTagClicked.emit(true);
  }

  private handleSharedLabelTagsChange(changes: SimpleChanges) {
    let sharedTagsPrevious = <ListOfTags>changes.sharedTags.previousValue;
    let sharedTags = <ListOfTags>changes.sharedTags.currentValue;

    let hasMissingFields =
      _.isNil(sharedTagsPrevious) ||
      (!_.isEmpty(sharedTagsPrevious.tags.resources) && _.isNil(sharedTagsPrevious.tags.resources[0].revision));

    // if there is no shared tag, display assign button
    if (!sharedTags && this.isOnHeader) {
      this.setAssignButton();
      return;
    }

    this.setDisplayProperties(sharedTags?.tags?.resources);
  }

  private handleLabelTagsChange() {
    setTimeout(() => {
      this.checkTagsOverflow();
    }, 0);
  }

  private setDisplayProperties(sharedTags: SharedTagBase[]) {
    this.reset();

    if (_.isEmpty(sharedTags)) {
      this.setAssignButton();
      return;
    }

    let classificationTag = undefined;
    sharedTags.filter(tag => {
      if (this.hideSpam && tag.name === SharedTagSystemNames.SPAM) {
        return false;
      }

      if (tag.$type === SharedTagAssigneeModel.type) {
        this.assigneeTag = <SharedTagAssigneeModel>tag;
        return true;
      }

      if (tag.$type === SharedTagStatusModel.type) {
        this.statusTag = <SharedTagStatusModel>tag;
        return true;
      }

      if (tag.$type === SharedTagSystemModel.type) {
        this.systemTags.push(<SharedTagSystemModel>tag);
        return true;
      }

      if (tag.$type === SharedTagLabelModel.type) {
        this.sharedLabelTags.push(<SharedTagLabelModel>tag);
        return true;
      }

      if (tag.$type === SharedTagClassificationModel.type) {
        classificationTag = tag as SharedTagClassificationModel;
      }

      return false;
    });

    this.classificationTag = classificationTag;

    if ((_.isEmpty(this.statusTag) || this.statusTag.name === 'Unassigned') && this.isOnHeader) {
      this.setAssignButton();
    } else {
      if (this.statusTag && this.statusTag.name !== 'Unassigned') {
        this.color = this.statusTag.color || fallbackColor;
        this.name = this.statusTag.name || 'no status';
      }

      // Set Me if assignee is current user
      if (this.assigneeTag && this.assigneeTag._ex) {
        this.name = this.assigneeTag._ex.user
          ? this.getAssigneeName(this.assigneeTag._ex.user)
          : this.assigneeTag.userId;
      }

      this.tagAssignee.emit(this.assigneeTag);
      this.tagStatus.emit(this.statusTag);
      this.tagsSystem.emit(this.systemTags);
    }

    setTimeout(() => {
      this.checkTagsOverflow();
    }, 0);
  }

  protected checkTagsOverflow() {
    if (_.isEmpty(this.sharedTagsContainer) || this.showHiddenTags) {
      return;
    }

    const padding = 5;
    const container = this.sharedTagsContainer.nativeElement;

    this.hasOverflow = container.clientHeight + padding < container.scrollHeight;
    this.showHiddenTags = false;

    this.triggerChangeDetection();
  }

  private setAssignButton(): void {
    this.displayAssignButton = true;

    let assignColorValue = getComputedStyle(document.documentElement).getPropertyValue('--medium-ui-element').trim();

    this.color = assignColorValue;

    this.name = 'Assign';

    this.tagAssignee.emit(this.assigneeTag);
    this.tagStatus.emit(this.statusTag);
    this.tagsSystem.emit(this.systemTags);
  }

  private getAssigneeName(user: User): string {
    if (user.id === this._userManagerService.getCurrentUserId()) {
      return 'Me';
    }

    return this.assigneeTag._ex.user.name.split(' ')[0];
  }

  private reset() {
    this.assigneeTag = undefined;
    this.statusTag = undefined;
    this.color = undefined;
    this.name = undefined;
    this.displayAssignButton = false;
    this.sharedLabelTags = [];
    this.systemTags = [];
  }

  protected triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }
}
