<div class="labels-display-header" [class.disabled]="offline" (window:resize)="onResize()">
  <label-tag
    *ngIf="hasSnoozeDue"
    [extraIcon]="snoozeSvg"
    [content]="'Snooze due'"
    [color]="'#f5a623'"
    [isOnHeader]="true"
    [displayWideContent]="true"
    [displayRemoveIcon]="true"
    [isClickable]="false"
    (removeIconClick)="removeSnoozeDueTag()"
  >
  </label-tag>

  <!-- Snooze tag -->
  <snooze-dropdown *ngIf="hasPendingSnooze" [conversation]="conversation" [openRight]="true" (closeCard$)="closeCard()">
    <dropdown-toggle>
      <label-tag
        [extraIcon]="snoozeSvg"
        [content]="snoozeDueDate"
        [color]="'#f5a623'"
        [isOnHeader]="true"
        [displayWideContent]="true"
        [isClickable]="false"
      >
      </label-tag>
    </dropdown-toggle>
  </snooze-dropdown>

  <!-- Shared tags -->
  <div *ngIf="showSharedTags" class="shared-tags" #sharedTagsContainer [class.show-hidden-tags]="showHiddenTags">
    <!-- Shared label tags -->
    <ng-container *ngFor="let labelTag of sharedLabelTags;">
      <label-dropdown class="traversable-do-not-remove" [labelTag]="labelTag" (removeLabel)="onRemoveLabel($event)">
      </label-dropdown>
    </ng-container>

    <ng-container *ngFor="let labelTag of labelTags;">
      <label-dropdown
        class="traversable-do-not-remove"
        [isPrivate]="true"
        [labelTag]="labelTag"
        (removeLabel)="onRemoveLabel($event)"
      >
      </label-dropdown>
    </ng-container>

    <!-- Toggle hidden tags button -->
    <div
      *ngIf="hasOverflow"
      class="toggle-hidden-tags-button"
      [class.hide-button]="showHiddenTags"
      (click)="toggleHiddenTags($event)"
    >
      <svg-comp [src]="dropdownSvg"> </svg-comp>
    </div>
  </div>

  <div *ngIf="showSystemAssigneeTags" class="system-assigne-tags">
    <!-- System tags -->
    <ng-container *ngFor="let systemTag of systemTags;">
      <label-tag
        *ngIf="systemTag.name === SharedTagSystemNames.SPAM"
        [isOnHeader]="true"
        [content]="systemTag.name"
        [color]="systemTag.color"
      >
      </label-tag>
    </ng-container>

    <!-- Assignee tag -->
    <label-tag
      [content]="name"
      [color]="color"
      [isOnHeader]="true"
      [assigneeTag]="assigneeTag"
      [delegating]="delegating"
      [displayAssignIcon]="displayAssignButton"
      [displayDropdownIcon]="true"
    >
    </label-tag>
  </div>
</div>
