import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { ContactBase, ListOfTags, Tag, User, UserStatus } from '@shared/api/api-loop/models';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { CollectionNameWeb, EmailIndex } from '../../database-schema';
import { ContactBaseModel, ContactModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ContactDaoServiceI } from '@shared/database/dao/contact/contact-dao.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DatabaseServiceWeb } from '../../database.service.web';

@Injectable()
export class ContactDaoServiceWeb extends BaseDaoServiceWeb<ContactModel, ContactBase> implements ContactDaoServiceI {
  constructor(protected _databaseFactory: DatabaseFactory) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'ContactDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.Contact;
  }

  protected toModel(doc: Tag): ContactModel {
    return ContactBaseModel.create(doc);
  }

  getAllContacts(forUserEmail: string): Observable<ContactModel[]> {
    return this.getAll(forUserEmail);
  }

  getActiveUserCount(forUserEmail: string, users: User[]): Observable<Number> {
    return of(0);
  }

  findByEmail(forUserEmail: string, email: string): Observable<ContactModel> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIndex(EmailIndex.indexName, [email], this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      map(_.first),
    );
  }

  //////////
  //////////
  //////////
  //////////
  // TODO
  updateUserName(forUserEmail: string, user: UserModel): Observable<any> {
    throw new Error('Method not implemented.');
  }

  updateContactTagsById(forUserEmail: string, id: string, tags: ListOfTags) {
    throw new Error('Method not implemented.');
  }

  updateUsersStatusById(forUserEmail: string, userIds: string[], onlineStatus: UserStatus): Observable<ContactModel[]> {
    throw new Error('Method not implemented.');
  }

  findAllAccessibleGroups(forUserEmail: string): Observable<ContactModel[]> {
    throw new Error('Method not implemented.');
  }

  removeAccountsByEmails(forUserEmail: string, contactEmails: string[]): Observable<ContactModel[]> {
    throw new Error('Method not implemented.');
  }

  removeAvailabilityStatus(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    throw new Error('Method not implemented.');
  }
}
