import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { CommentBase, Tag, TagType } from '@shared/api/api-loop/models';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { CollectionNameWeb } from '../../database-schema';
import {
  CommentBaseModel,
  CommentModel,
  CommentTemplateModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import { from, Observable } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { DatabaseServiceWeb } from '../../database.service.web';
import { ListOfTagsModel } from '@dta/shared/models-api-loop/tag.model';
import { StaticSharedTagIds } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

@Injectable()
export class TemplateDaoServiceWeb extends BaseDaoServiceWeb<CommentModel, CommentBase> {
  constructor(protected _databaseFactory: DatabaseFactory) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'TemplateDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.Template;
  }

  protected toModel(doc: Tag): CommentTemplateModel {
    return CommentBaseModel.create(doc) as CommentTemplateModel;
  }

  protected doBeforeSave(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return from(comments).pipe(
      map((comment: CommentModel) => {
        if (!comment.hasTags()) {
          comment.tags = ListOfTagsModel.buildFromParentAndTags(comment);
        }
        return comment;
      }),
      toArray(),
    );
  }

  countAllTemplates(forUserEmail: string): Observable<Number> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.countAll(this.collectionName);
      }),
    );
  }

  findAllTemplates(forUserEmail: string): Observable<CommentTemplateModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.getAll(this.collectionName);
      }),
      mergeMap(docs => from(this.toModels(docs))),
      /**
       * Filter out deleted templates
       */
      filter(
        (template: CommentTemplateModel) =>
          !template.hasTagId(TagType.DELETED) && !template.hasSharedTagId(StaticSharedTagIds.DELETED_ID),
      ),
      toArray(),
      defaultIfEmpty([]),
    ) as Observable<CommentTemplateModel[]>;
  }
}
