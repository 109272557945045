import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { SharedSubjects } from '../../../../shared/services/communication/shared-subjects/shared-subjects';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { AutoUnsubscribe } from '../../../shared/utils/subscriptions/auto-unsubscribe';
import {
  WebsocketCardEventsAggregationService,
  WebsocketCardEventType,
  WebsocketEventCard,
} from '../websocket-card-events-aggregation/websocket-card-events-aggregation.service';
import { ActiveReplyData } from '../../../../shared/services/communication/shared-subjects/shared-subjects-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@shared/api/api-loop/models';

@AutoUnsubscribe()
@Injectable()
export class CollisionDetectionService extends WebsocketCardEventsAggregationService implements OnDestroy {
  ////////////////////
  // State variables
  ////////////////////
  private activeIntervalByCard = {};
  private pingInterval: number = 1.5 * 1000;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _contactService: ContactService,
  ) {
    super(WebsocketCardEventType.ActiveReply, _userManagerService, _contactService);
  }

  ngOnDestroy() {}

  registerCardAndGetActiveUsers(cardId: string): Observable<string> {
    return this.getWebsocketCard(cardId).pipe(
      map((cards: WebsocketEventCard[]) => {
        return this.activeUsersToString(cards[0].users);
      }),
    );
  }

  registerActiveReplyOnCard(forUserEmail: string, cardId: string): void {
    // Clear existing interval
    clearInterval(this.activeIntervalByCard[cardId]);

    // Ping right away
    this.pingActiveReply(forUserEmail, cardId);

    // Ping every N seconds
    this.activeIntervalByCard[cardId] = setInterval(() => {
      this.pingActiveReply(forUserEmail, cardId);
    }, this.pingInterval);
  }

  unregisterActiveReplyOnCard(cardId: string): void {
    // Clear existing interval
    clearInterval(this.activeIntervalByCard[cardId]);
  }

  private pingActiveReply(forUserEmail: string, cardId: string): void {
    if (_.isEmpty(cardId) || _.isEmpty(forUserEmail)) {
      return;
    }

    let data = new ActiveReplyData();
    data.forUserEmail = forUserEmail;
    data.cardId = cardId;

    SharedSubjects._localActiveReply$.next(data);
  }

  private activeUsersToString(users: User[]): string {
    if (_.isEmpty(users)) {
      return '';
    }

    let text = '';

    text += _.map(users.slice(0, 2), (user: User) => user.name).join(users.length === 2 ? ' and ' : ', ');

    if (users.length > 2) {
      text += ' +' + (users.length - 2);
    }

    if (users.length > 1) {
      text += ' are';
    } else {
      text += ' is';
    }

    return text;
  }
}
