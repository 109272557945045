<div [formGroup]="form" class="flex flex-col gap-quarter">
  <ng-container *ngIf="classificationNodeTags$ | async; let classificationNodeTags">
    <loop-text-control formControlName="query" size="small" placeholder="Search"></loop-text-control>
    <div>
      <loader *ngIf="isLoading" [style]="'button-circle'" [customClass]="'blue-loader'"></loader>
      <div class="flex flex-col gap-quarter">
        <ng-container
          *ngTemplateOutlet="classificationTreeTpl; context: { $implicit: classificationNodeTags, indentIndex: 0 }"
        ></ng-container>
      </div>
    </div>
    <ng-container *ngIf="classificationTagsListTpl">
      <ng-container
        *ngTemplateOutlet="classificationTagsListTpl; context: { $implicit: classificationNodeTags }"
      ></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #classificationTreeTpl let-nodes let-indentIndex="indentIndex">
  <ng-container *ngFor="let node of nodes; trackBy: trackByFn">
    <div class="flex flex-col">
      <div
        class="flex padding-vertical-quarter border-radius-normal hover:bg-light-ui-element cursor-pointer flex-1"
        (click)="handleNodeClick(node)"
        [style.padding-left.px]="4 + indentIndex * 13"
      >
        <ng-container *ngIf="node.childNodes && node.childNodes.length > 0">
          <div class="flex items-center">
            <loop-icon
              [class.is-expanded]="node.isNodeExpanded"
              class="clickable-icon caret"
              name="angle-up"
              width="12px"
            ></loop-icon>
          </div>
        </ng-container>

        <div class="flex items-center flex-1">
          <ng-container *ngIf="node.classificationTag.classificationType === ClassificationTagType_Classification">
            <div class="classification-circle-wrapper">
              <div class="classification-circle" [style.background-color]="node.classificationTag.color"></div>
            </div>
          </ng-container>
          <div class="flex gap-half justify-between flex-1 items-center padding-right-half">
            {{ node.classificationTag.name }}
            <div class="flex gap-half">
              <ng-container
                *ngIf="
                  classificationCategoryButtonTpl &&
                  node.classificationTag.classificationType === ClassificationTagType_Category
                "
              >
                <ng-container
                  *ngTemplateOutlet="classificationCategoryButtonTpl; context: { $implicit: node }"
                ></ng-container>
              </ng-container>
              <loop-icon *ngIf="node.isNodeSelected" name="check" width="12px"></loop-icon>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="node.childNodes && node.childNodes.length > 0 && node.isNodeExpanded">
        <ng-container
          *ngTemplateOutlet="
            classificationTreeTpl;
            context: { $implicit: node.childNodes, indentIndex: indentIndex + 1 }
          "
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
