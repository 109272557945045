/* tslint:disable */
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/models/constants/constants';

/**
 * Contains global configuration for API services
 */
@Injectable()
export class LogConfiguration {
  rootUrl: string = CONSTANTS.LOOP_LOG_ROOT_URI;
}
