import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from 'date-fns';

@Pipe({
  name: 'isSameDay'
})
export class IsSameDayPipe implements PipeTransform {
  transform(first: Date | string, second: Date | string): boolean {
    if (typeof first === 'string' || typeof second === 'string') {
      first = new Date(first);
      second = new Date(second);
    }
    return isSameDay(first, second);
  }
}
