import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePreviewComponent } from '@shared/modules/files/components/file-preview/file-preview.component';
import { HasPreviewPipe } from '@shared/modules/files/components/files-preview-dialog-content/has-preview.pipe';
import { IsImageModule } from '@shared/modules/files/components/pipes/is-image/is-image.module';
import { IsPdfModule } from '@shared/modules/files/components/pipes/is-pdf/is-pdf.module';
import { IsVideoModule } from '@shared/modules/files/components/pipes/is-video/is-video.module';
import { AsPureArgumentForModule } from '@shared/ui/pipes/as-pure-argument-for/as-pure-argument-for.module';
import { PdfjsModule } from '@dta/ui/components/common/pdfjs/pdfjs.module';
import { VideoModule } from '@dta/ui/components/common/video/video.module';

@NgModule({
  declarations: [FilePreviewComponent],
  imports: [
    CommonModule,
    HasPreviewPipe,
    IsImageModule,
    IsPdfModule,
    PdfjsModule,
    IsVideoModule,
    AsPureArgumentForModule,
    VideoModule
  ],
  exports: [FilePreviewComponent]
})
export class FilePreviewModule {}
