import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ErrorInterceptorName } from '../common/enum/error-interceptor-name';
import { BaseErrorInterceptor } from './base-error-interceptor';
import { ElectronService } from '@shared/services/electron/electron';
import { ErrorCode } from '@shared/interceptors/common/enum/error-code';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorClientAppNotSupportedInterceptor extends BaseErrorInterceptor {
  private readonly errorHandler: ErrorHandler = inject(ErrorHandler);
  private readonly electronService: ElectronService = inject(ElectronService);

  readonly interceptorName: ErrorInterceptorName = ErrorInterceptorName.clientAppVersionNotSupported as const;
  readonly httpStatusCode: HttpStatusCode = HttpStatusCode.Forbidden as const;
  readonly errorCode: ErrorCode = ErrorCode.ClientAppVersionNotSupported;

  constructor() {
    super();
  }

  protected handleError(err: HttpErrorResponse): Observable<any> {
    return this.electronService.checkForUpdates({ isDeprecatedVersion: true }).pipe(
      tap(() => {
        this.errorHandler.handleError(err);
      }),
    );
  }
}
