import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable()
export class TooltipService {
  private tooltipState$: Subject<any> = new Subject();

  constructor() {}

  getTooltip(): Observable<any> {
    return this.tooltipState$.pipe(startWith());
  }

  setTooltip(tooltipData: any) {
    this.tooltipState$.next(tooltipData);
  }

  removeTooltip() {
    this.tooltipState$.next(undefined);
  }
}
