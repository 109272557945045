import { forkJoin, from, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { DataServiceShared } from '@shared/services/data/data.service';
import { Logger } from '@shared/services/logger/logger';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';
import { LogTag } from '@dta/shared/models/logger.model';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import {
  SharedTagBaseModel,
  SharedTagClassificationModel
} from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { SignatureModel } from '@dta/shared/models-api-loop/signature.model';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { PrefetchStatus, UserScopeData } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { LoginUserModel } from '../../../../../dta/shared/models-api-loop/contact/contact.model';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { SharedTagFolder } from '@shared/api/api-loop/models/shared-tag-folder';

export class PrefetchSynchronizationService {
  status: PrefetchSyncState = PrefetchSyncState.INACTIVE;

  constructor(
    private _userEmail: string,
    private _data: DataServiceShared
  ) {}

  get constructorName(): string {
    return 'PrefetchSynchronizationService';
  }

  get waitForPrefetchSyncDone$(): Observable<any> {
    return this.isPrefetchDone()
      ? of(undefined)
      : SharedSubjects._prefetchDone$.forUserEmail(this._userEmail).pipe(take(1));
  }

  isPrefetchDone(): boolean {
    return this.status === PrefetchSyncState.COMPLETED;
  }

  startPrefetchSync(): Subscription {
    if (this.status !== PrefetchSyncState.INACTIVE) {
      return undefined;
    }

    this.status = PrefetchSyncState.ACTIVE;

    let syncJobs = [
      this.syncAllWorkspaceContacts,
      this.startLabelSync,
      this.startFolderSync,
      this.startContactSyncAndTemplateSync,
      this.fetchUserAndSyncSettings,
      this.startAvailabilityStatusSync,
      this.startIntegrationsSync,
      () => this.syncClassificationTags$()
    ];

    let status = new PrefetchStatus(this._userEmail);
    status.totalJobs = syncJobs.length;
    status.finishedJobs = 0;

    return this.clearDatabase()
      .pipe(
        mergeMap(() => from(syncJobs)),
        /**
         * Run prefetch in parallel
         */
        mergeMap(syncJob => {
          return syncJob();
        }, 5),
        tap(() => {
          status.finishedJobs++;
          SharedSubjects._prefetchStatus$.next(status);
        }),
        toArray(),
        tap(() => {
          SharedSubjects._prefetchDone$.next(new UserScopeData(this._userEmail));
          this.status = PrefetchSyncState.COMPLETED;
        }),
        catchError(err => {
          this.status = PrefetchSyncState.INACTIVE;

          Logger.error(err, `[SYNC] - PrefetchSync [${this._userEmail}]: error`, LogTag.SYNC);

          return throwError(err);
        })
      )
      .subscribe();
  }

  //////////////////////////
  // Individual sync tasks
  //////////////////////////
  private startFolderSync = (): Observable<SharedTagFolder[]> => {
    return this._data.FolderService.syncSharedFolders(this._userEmail).pipe(
      // Handle error and don't block sync
      catchError(err => {
        Logger.error(err, `${this.constructorName}:startFolderSync [${this._userEmail}].`, LogTag.SYNC);

        return of(undefined);
      })
    );
  };

  private startLabelSync = (): Observable<(TagModel | SharedTagBaseModel)[]> => {
    return this._data.LabelService.syncLabels(this._userEmail).pipe(
      // Handle error and don't block sync
      catchError(err => {
        Logger.error(err, `${this.constructorName}:startLabelSync [${this._userEmail}].`, LogTag.SYNC);

        return of(undefined);
      })
    );
  };

  private syncAllWorkspaceContacts = (): Observable<UserAvailabilityStatusModel[]> => {
    return this._data.ContactService.fetchWorkspaceContacts(this._userEmail).pipe(
      // Handle error and don't block sync
      catchError(err => {
        Logger.error(err, `${this.constructorName}:startTemplateSync [${this._userEmail}]`, LogTag.SYNC);

        return of(undefined);
      })
    );
  };

  private startAvailabilityStatusSync = (): Observable<AvailabilityStatusModel[]> => {
    return this._data.AvailabilityStatusService.syncAvailabilityStatuses(this._userEmail).pipe(
      // Handle error and don't block sync
      catchError(err => {
        Logger.error(err, `${this.constructorName}:startTemplateSync [${this._userEmail}]`, LogTag.SYNC);

        return of(undefined);
      })
    );
  };

  private syncClassificationTags$(): Observable<SharedTagClassificationModel[]> {
    return this._data.smartClassificationSharedTagApi.getAll$(this._userEmail).pipe(
      // Handle error and don't block sync
      catchError(err => {
        Logger.error(err, `${this.constructorName}:startIntegrationsSync [${this._userEmail}]`, LogTag.SYNC);

        return of(undefined);
      })
    );
  }

  private startIntegrationsSync = (): Observable<IntegrationModel[]> => {
    return this._data.IntegrationService.syncIntegrations(this._userEmail).pipe(
      // Handle error and don't block sync
      catchError(err => {
        Logger.error(err, `${this.constructorName}:startIntegrationsSync [${this._userEmail}]`, LogTag.SYNC);

        return of(undefined);
      })
    );
  };

  // Templates have a lot of contacts, thus we have to sync side menu contacts first
  private startContactSyncAndTemplateSync = (): Observable<ContactModel[]> => {
    return this._data.ContactService.syncSideMenuContacts(this._userEmail).pipe(
      // Handle error and don't block sync
      catchError(err => {
        Logger.error(err, `${this.constructorName}:startSideMenuContactsSync [${this._userEmail}]`, LogTag.SYNC);

        return of(undefined);
      })
    );
  };

  private fetchUserAndSyncSettings = (): Observable<LoginUserModel> => {
    return this._data.UserService.fetchOrUpdateUser(this._userEmail).pipe(
      // Handle error and don't block sync
      catchError(err => {
        Logger.error(err, `${this.constructorName}:fetchUserAnySyncSettings [${this._userEmail}]`, LogTag.SYNC);

        return of(undefined);
      })
    );
  };

  private clearDatabase(): Observable<any> {
    return forkJoin([
      this._data.FolderService.removeCollection(this._userEmail),
      this._data.IntegrationService.removeCollection(this._userEmail),
      this._data.UserAvailabilityStatusService.removeCollection(this._userEmail),
      this._data.AvailabilityStatusService.removeCollection(this._userEmail),
      this._data.ConversationChangeService.removeCollection(this._userEmail),
      this._data.ContactService.removeCollection(this._userEmail)
    ]);
  }
}

export enum PrefetchSyncState {
  ACTIVE,
  INACTIVE,
  COMPLETED
}
