import * as _ from 'lodash';
import {
  CardAppointmentModel,
  CardChatModel,
  CardDraftModel,
  CardMailModel,
  CardSharedModel,
  CardTemplateModel,
} from '../conversation-card/card/card.model';
import {
  CommentChatModel,
  CommentDraftModel,
  CommentMailModel,
  CommentTemplateModel,
  QuoteCommentModel,
} from './../comment/comment.model';
import {
  SharedTagAssignedByModel,
  SharedTagAssigneeModel,
  SharedTagCustomModel,
  SharedTagFolderModel,
  SharedTagLabelModel,
  SharedTagReactionModel,
  SharedTagStatusModel,
  SharedTagSystemModel,
} from './../shared-tag/shared-tag.model';
import { ListOfTagsModel, TagModel } from './../tag.model';
import { FileModel } from './../file.model';
import { SignatureModel } from './../signature.model';
import { GroupModel, UserModel } from './../contact/contact.model';
import { StateUpdates } from '../../models/state-updates';
import { LibrarySearchResults, SearchResultType } from '../../models/library.model';
import { PublishEvent } from '../../../../shared/services/communication/shared-subjects/shared-subjects-models';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { LogModel } from '@dta/shared/models-api-loop/log.model';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { ConversationChangeModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-change.model';

// Methods in this class will cast all models passing through IPC communication
// Make sure you include all known model mappers and be careful when making changes
export class ModelMappers {
  static deepMapTypes = [
    CardMailModel.type,
    CardSharedModel.type,
    CardChatModel.type,
    CardDraftModel.type,
    CardAppointmentModel.type,
    CardTemplateModel.type,
    StateUpdates.type,
  ];

  static castToEndModelOrModels(data: any) {
    return Array.isArray(data) ? ModelMappers.castToEndModels(data) : ModelMappers.castToEndModel(data);
  }

  static castToEndModels(models: any[]) {
    // Handle empty lists
    if (_.isEmpty(models)) {
      return models;
    }
    return _.map(models, model => {
      return this.castToEndModel(model);
    });
  }

  static castToEndModel(model: any, deep: boolean = true) {
    // Handle undefined values
    if (!model) {
      return model;
    }

    // Deep map models that require it
    if (ModelMappers.deepMapTypes.includes(model.$type) && deep) {
      return this.deepCastToEndModel(model);
    }

    // Map by type
    switch (model.$type) {
      /**
       * Conversation
       */
      case ConversationModel.type:
        return new ConversationModel(model);
      case ConversationChangeModel.type:
        return new ConversationChangeModel(model);
      /**
       * Card
       */
      case CardMailModel.type:
        return new CardMailModel(model);
      case CardSharedModel.type:
        return new CardSharedModel(model);
      case CardTemplateModel.type:
        return new CardTemplateModel(model);
      case CardAppointmentModel.type:
        return new CardAppointmentModel(model);
      case CardChatModel.type:
        return new CardChatModel(model);
      case CardDraftModel.type:
        return new CardDraftModel(model);
      /**
       * Comment
       */
      case CommentMailModel.type:
        return new CommentMailModel(model);
      case CommentChatModel.type:
        if (model.quotedComment) {
          model.quotedComment = new QuoteCommentModel(model.quotedComment);
        }
        if (model.sharedTags && model.sharedTags.tags.resources) {
          model.sharedTags.tags.resources = this.castToEndModels(model.sharedTags.tags.resources);
        }
        return new CommentChatModel(model);
      case CommentTemplateModel.type:
        return new CommentTemplateModel(model);
      case CommentDraftModel.type:
        return new CommentDraftModel(model);
      /**
       * Contact
       */
      case UserModel.type:
        return new UserModel(model);
      case GroupModel.type:
        return new GroupModel(model);
      /**
       * Shared tags
       */
      case SharedTagAssigneeModel.type:
        return new SharedTagAssigneeModel(model);
      case SharedTagStatusModel.type:
        return new SharedTagStatusModel(model);
      case SharedTagCustomModel.type:
        return new SharedTagCustomModel(model);
      case SharedTagSystemModel.type:
        return new SharedTagSystemModel(model);
      case SharedTagAssignedByModel.type:
        return new SharedTagAssignedByModel(model);
      case SharedTagReactionModel.type:
        return new SharedTagReactionModel(model);
      case SharedTagLabelModel.type:
        return new SharedTagLabelModel(model);
      case SharedTagFolderModel.type:
        return new SharedTagFolderModel(model);
      /**
       * Tags
       */
      case TagModel.type:
        return new TagModel(model);
      case ListOfTagsModel.type:
        return new ListOfTagsModel(model);
      /**
       * Status
       */
      case AvailabilityStatusModel.type:
        return new AvailabilityStatusModel(model);
      case UserAvailabilityStatusModel.type:
        return new UserAvailabilityStatusModel(model);
      /**
       * Integration
       */
      case IntegrationModel.type:
        return new IntegrationModel(model);
      /**
       * Other
       */
      case FileModel.type:
        return new FileModel(model);
      case SignatureModel.type:
        return new SignatureModel(model);
      case StateUpdates.type:
        return new StateUpdates(model);
      case PublishEvent.type:
        (<PublishEvent>model).models = this.castToEndModelOrModels(model.models);
        return model;
      case SearchResultType.Offline:
      case SearchResultType.Online:
        (<LibrarySearchResults>model).result = this.castToEndModelOrModels(model.result);
        return model;
      case LogModel.type:
        return new LogModel(model);
      default:
        return model;
    }
  }

  static deepCastToEndModel(model: any): Object {
    // Handle undefined values
    if (!model) {
      return model;
    }

    if (Object.prototype.toString.call(model) === '[object Object]') {
      let _object = {};

      for (let key in model) {
        _object[key] = this.deepCastToEndModel(model[key]);
      }
      return this.castToEndModel(_object, false);
    }

    if (Array.isArray(model)) {
      return _.map(model, part => {
        return this.deepCastToEndModel(part);
      });
    }

    return model;
  }
}
