/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Subscription } from '../models/subscription';
import { Response } from '../models/response';
import { ResponseConflict } from '../models/response-conflict';
import { ListOfResourcesOfSubscription } from '../models/list-of-resources-of-subscription';

@Injectable()
export class SubscriptionApiService extends BaseService {
  /**
   * @param params The `SubscriptionApiService.Subscription_CreateSubscriptionParams` containing the following parameters:
   *
   * - `subscription`: Subscription object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_CreateSubscriptionResponse(
    params: SubscriptionApiService.Subscription_CreateSubscriptionParams,
    forUserEmail: string
  ): Observable<HttpResponse<Subscription>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/subscription`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Subscription_CreateSubscription');

    // body, headers and query params
    __body = params.subscription;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Subscription;

          body = <Subscription>response.body;

          return <HttpResponse<Subscription>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_CreateSubscriptionParams` containing the following parameters:
   *
   * - `subscription`: Subscription object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_CreateSubscription(
    params: SubscriptionApiService.Subscription_CreateSubscriptionParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Subscription> {
    const observable$ = this.Subscription_CreateSubscriptionResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription`;
      const url = this.rootUrl + `/api/v1/subscription`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_GetParams` containing the following parameters:
   *
   * - `id`: Subscription id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_GetResponse(
    params: SubscriptionApiService.Subscription_GetParams,
    forUserEmail: string
  ): Observable<HttpResponse<Subscription>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/subscription/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Subscription_Get');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Subscription;

          body = <Subscription>response.body;

          return <HttpResponse<Subscription>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_GetParams` containing the following parameters:
   *
   * - `id`: Subscription id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_Get(
    params: SubscriptionApiService.Subscription_GetParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Subscription> {
    const observable$ = this.Subscription_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription/${params.id}`;
      const url = this.rootUrl + `/api/v1/subscription/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_UpdateSubscriptionParams` containing the following parameters:
   *
   * - `subscription`: Updated Subscription object
   *
   * - `id`: Subscription id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_UpdateSubscriptionResponse(
    params: SubscriptionApiService.Subscription_UpdateSubscriptionParams,
    forUserEmail: string
  ): Observable<HttpResponse<Subscription>> {
    let __method = 'PATCH';
    let __url = this.rootUrl + `/api/v1/subscription/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Subscription_UpdateSubscription');

    // body, headers and query params
    __body = params.subscription;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Subscription;

          body = <Subscription>response.body;

          return <HttpResponse<Subscription>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_UpdateSubscriptionParams` containing the following parameters:
   *
   * - `subscription`: Updated Subscription object
   *
   * - `id`: Subscription id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_UpdateSubscription(
    params: SubscriptionApiService.Subscription_UpdateSubscriptionParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Subscription> {
    const observable$ = this.Subscription_UpdateSubscriptionResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription/${params.id}`;
      const url = this.rootUrl + `/api/v1/subscription/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_DeleteSubscriptionParams` containing the following parameters:
   *
   * - `id`: Subscription id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_DeleteSubscriptionResponse(
    params: SubscriptionApiService.Subscription_DeleteSubscriptionParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/subscription/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Subscription_DeleteSubscription');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_DeleteSubscriptionParams` containing the following parameters:
   *
   * - `id`: Subscription id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_DeleteSubscription(
    params: SubscriptionApiService.Subscription_DeleteSubscriptionParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Subscription_DeleteSubscriptionResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription/${params.id}`;
      const url = this.rootUrl + `/api/v1/subscription/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_GetListOfSubscriptionsParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_GetListOfSubscriptionsResponse(
    params: SubscriptionApiService.Subscription_GetListOfSubscriptionsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfSubscription>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/subscription/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Subscription_GetListOfSubscriptions');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfSubscription;

          body = <ListOfResourcesOfSubscription>response.body;

          return <HttpResponse<ListOfResourcesOfSubscription>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionApiService.Subscription_GetListOfSubscriptionsParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Subscription_GetListOfSubscriptions(
    params: SubscriptionApiService.Subscription_GetListOfSubscriptionsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfSubscription> {
    const observable$ = this.Subscription_GetListOfSubscriptionsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription/list`;
      const url = this.rootUrl + `/api/v1/subscription/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module SubscriptionApiService {
  /**
   * Parameters for Subscription_CreateSubscription
   */
  export type Subscription_CreateSubscriptionParams = {
    /**
     * Subscription object
     */
    subscription: Subscription;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Subscription_Get
   */
  export type Subscription_GetParams = {
    /**
     * Subscription id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Subscription_UpdateSubscription
   */
  export type Subscription_UpdateSubscriptionParams = {
    /**
     * Updated Subscription object
     */
    subscription: Subscription;

    /**
     * Subscription id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Subscription_DeleteSubscription
   */
  export type Subscription_DeleteSubscriptionParams = {
    /**
     * Subscription id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Subscription_GetListOfSubscriptions
   */
  export type Subscription_GetListOfSubscriptionsParams = {
    /**
     * Paging size
     */
    size?: number;

    /**
     * Paging offset
     */
    offset?: number;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
