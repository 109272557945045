import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderNewComponent } from '@dta/ui/components/common/loader-new/loader-new.component';

@NgModule({
  declarations: [LoaderNewComponent],
  imports: [CommonModule],
  exports: [LoaderNewComponent],
})
export class LoaderNewModule {}
