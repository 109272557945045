import { ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { take, tap } from 'rxjs/operators';
import { KeyboardActions } from '../keyboard-actions/keyboard-actions.component';

/**
 * Class implements keyboard selection on a list
 * Some properties and methods should be defined in a component,
 * that extends this class
 */

@AutoUnsubscribe()
export abstract class ListKeyboardSelection extends KeyboardActions implements OnDestroy {
  /**
   * Set in component only if list needs scrolling.
   * Set in component for a specific class inside listWrapper
   */
  protected listWrapper: ElementRef = undefined; // Element has to have position other than static.
  listItemClass: string = undefined; // If undefined direct descendants will be selected.

  /**
   * Properties that should be set in component, that extends this class
   */
  protected maxSelectionIndex: number = undefined; // Set in component based on list length
  protected startSelectionIndex: number = 0; // Set to -1 for no initial selection
  protected listKeyboardSelectionEnabled = true;

  ////////////////////
  // State properties
  ////////////////////
  selectionIndex: number = undefined;
  protected suppressMouseEventsUntilMove: boolean = false;
  protected keyboardActions = {
    ArrowDown: this.increaseSelectionIndex.bind(this),
    ArrowUp: this.decreaseSelectionIndex.bind(this),
    Enter: this.confirmListItemSelection.bind(this),
    Escape: this.closeList.bind(this),
  };

  /////////////////
  // Subscriptions
  /////////////////
  private mouseMoveSub: Subscription;

  constructor() {
    super();
  }

  ////////////////////
  // Abstract methods
  ////////////////////
  protected abstract triggerChangeDetection(): void;

  //////////////////////
  // Life cycle methods
  //////////////////////
  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.mouseMoveSub) {
      this.mouseMoveSub.unsubscribe();
    }
  }

  ////////////////
  // View Methods
  ////////////////
  setSelectionIndex(index: number, mouseEvent: boolean = false) {
    if (
      this.maxSelectionIndex === undefined ||
      !(this.maxSelectionIndex > -1) ||
      index === undefined ||
      index === this.selectionIndex ||
      (mouseEvent && this.suppressMouseEventsUntilMove)
    ) {
      return;
    }

    this.subscribeToSuppressMouseEventsUntilMove();

    this.selectionIndex = Math.max(0, Math.min(this.maxSelectionIndex, index));

    this.onSelectionIndexChange();
    this.triggerChangeDetection();

    if (!mouseEvent) {
      this.scrollIfNeeded();
    }
  }

  ///////////////////
  // Private methods
  ///////////////////
  protected handleKeyDown(event: KeyboardEvent) {
    if (!this.keyboardActions[event.key]) {
      return;
    }

    // Allow Escape key to propagate
    if (event.key !== 'Escape') {
      event.preventDefault();
      event.stopPropagation();
    }

    this.keyboardActions[event.key]();
  }

  protected increaseSelectionIndex() {
    this.setSelectionIndex(this.selectionIndex + 1);
  }

  protected decreaseSelectionIndex() {
    this.setSelectionIndex(this.selectionIndex - 1);
  }

  private scrollIfNeeded() {
    if (this.listWrapper === undefined) {
      return;
    }

    /**
     * Selects descendant elements with provided class,
     * if class in undefined all direct descendants will be selected
     */
    let selectedEl = this.listWrapper.nativeElement.querySelectorAll(
      `${this.listItemClass ? '.' + this.listItemClass : ':scope > *'}`,
    )[this.selectionIndex];

    let scrollWrapper = this.listWrapper.nativeElement;
    let elHeight = parseFloat(getComputedStyle(selectedEl).height);
    let currentScrollTop = scrollWrapper.scrollTop;
    let elLocation = selectedEl.offsetTop;
    let scrollTopWithWrapperHeight =
      currentScrollTop +
      parseFloat(getComputedStyle(scrollWrapper).height) -
      parseFloat(getComputedStyle(scrollWrapper).paddingTop) -
      parseFloat(getComputedStyle(scrollWrapper).paddingBottom);

    /**
     *  Scroll list up/down if necessary when navigating with keyboard
     */
    if (elLocation < currentScrollTop) {
      scrollWrapper.scrollTop = Math.max(
        Math.floor(
          currentScrollTop - (currentScrollTop - elLocation) - parseFloat(getComputedStyle(scrollWrapper).paddingTop),
        ),
        0,
      );
    } else if (elLocation + elHeight > scrollTopWithWrapperHeight) {
      scrollWrapper.scrollTop = Math.ceil(currentScrollTop + (elLocation - scrollTopWithWrapperHeight) + elHeight);
    }
  }

  protected resetScroll() {
    let scrollWrapper = this.listWrapper?.nativeElement;
    if (scrollWrapper) {
      scrollWrapper.scrollTop = 0;
    }
  }

  private subscribeToSuppressMouseEventsUntilMove() {
    /**
     * Prevents accidental hover selection,
     * when scrolling the list with keyboard
     */
    this.suppressMouseEventsUntilMove = true;

    if (this.mouseMoveSub) {
      this.mouseMoveSub.unsubscribe();
    }
    this.mouseMoveSub = fromEvent(document, 'mousemove')
      .pipe(
        take(1),
        tap(() => {
          this.suppressMouseEventsUntilMove = false;
        }),
      )
      .subscribe();
  }

  ///////////////////
  // Protected methods
  ///////////////////
  protected addKeyboardListener() {
    if (this.listKeyboardSelectionEnabled) {
      super.addKeyboardListener();
      this.selectionIndex = this.startSelectionIndex;
    }
  }

  protected removeKeyboardListener() {
    if (this.listKeyboardSelectionEnabled) {
      super.removeKeyboardListener();
    }

    this.mouseMoveSub?.unsubscribe();
    this.suppressMouseEventsUntilMove = false;
  }

  /**
   * Implement in component if needed
   */
  protected confirmListItemSelection() {}
  protected onSelectionIndexChange() {}
  protected closeList() {}
}
