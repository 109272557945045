import { ChangeDetectorRef, Component, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { updateEntireFormValidity } from '@shared/forms/helpers/update-entire-form-validity';

export interface FormComponent {
  form: FormGroup;
}

@Component({
  template: ''
})
export abstract class BaseFormComponent<T> implements OnDestroy, FormComponent {
  protected readonly formBuilder: UntypedFormBuilder = inject(UntypedFormBuilder);
  protected readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);

  form!: UntypedFormGroup;
  @Output() formSubmit: EventEmitter<T> = new EventEmitter<T>();

  protected destroy$: Subject<void> = new Subject<any>();

  protected validateForm(formGroup: UntypedFormGroup = this.form): void {
    updateEntireFormValidity(formGroup);
  }

  protected emitValue(value: T): void {
    this.formSubmit.emit(value);
  }

  resetForm: () => void = () => {
    if (this.form) {
      this.form.reset();
    }
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  abstract submitForm(): void;
}
