import { CdkMenuModule } from '@angular/cdk/menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { DropdownItemRouterLinkPipe } from './browser-menu-renderer/dropdown-item-router-link.pipe';
import { AsDropdownRouterLinkItemPipe } from './browser-menu-renderer/as-dropdown-router-link-item.pipe';
import { AsDropdownActionItemPipe } from './browser-menu-renderer/as-dropdown-action-item.pipe';
import { AsDropdownHelpTextItemPipe } from './browser-menu-renderer/as-dropdown-help-text-item.pipe';
import { AsDropdownDelimiterItemPipe } from './browser-menu-renderer/as-dropdown-delimiter-item.pipe';
import { IsDropdownRouterLinkItemPipe } from './browser-menu-renderer/is-dropdown-router-link-item.pipe';
import { IsDropdownHelpTextItemPipe } from './browser-menu-renderer/is-dropdown-help-text-item.pipe';
import { BrowserDropdownRendererComponent } from './browser-menu-renderer/browser-menu-renderer.component';

@NgModule({
  declarations: [
    BrowserDropdownRendererComponent,
    DropdownItemRouterLinkPipe,
    AsDropdownRouterLinkItemPipe,
    AsDropdownActionItemPipe,
    AsDropdownHelpTextItemPipe,
    AsDropdownDelimiterItemPipe,
    IsDropdownRouterLinkItemPipe,
    IsDropdownHelpTextItemPipe,
  ],
  imports: [CommonModule, CdkMenuModule, RouterModule, IconModule, GraphicsModule],
})
export class BrowserDropdownModule {}
