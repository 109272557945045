import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import {
  CommentBaseExtraData,
  CommentChatExtraData,
  CommentChatModel,
  CommentExtraData,
  CommentMailExtraData,
  CommentMailModel,
  CommentModel
} from 'dta/shared/models-api-loop/comment/comment.model';
import { from, Observable, of } from 'rxjs';
import { FileModel } from 'dta/shared/models-api-loop/file.model';
import { GroupModel } from 'dta/shared/models-api-loop/contact/contact.model';
import { flatMap, map, tap, toArray } from 'rxjs/operators';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel } from 'dta/shared/models/logger.model';
import { BaseExDecorateService } from '../base-ex-decorate.service';
import { FileExDecorateService } from '../file-ex-decorator/file-ex-decorate.service';
import { GroupType } from '@shared/api/api-loop/models';

@Injectable()
export class CommentExDecorateService extends BaseExDecorateService<CommentModel> {
  // Fields that are added to pass data but should not block decoration
  private static passThroughFields = [
    'sendAsGroupId',
    'bodyProcessed',
    'undoData',
    'createdFromDraftCardId',
    'createdFromDraftCommentId',
    'shouldSplitOnSend',
    'saveAndClose',
    'unSyncedBodyChanges',
    'sendAsContact'
  ];
  private imgSrcRegex: RegExp = new RegExp(' src=', 'g');

  constructor(private _fileExDecorateService: FileExDecorateService) {
    super();
  }

  decorateExtraData(forUserEmail: string, comment: CommentModel, force?: boolean): Observable<CommentModel> {
    if (!this.shouldDecorate(comment) && !force) {
      return of(comment);
    }

    return of(undefined).pipe(
      flatMap(() => {
        return this.decorateBaseExtraData(forUserEmail, comment, force);
      }),
      flatMap((ex: CommentBaseExtraData) => {
        if (comment instanceof CommentChatModel) {
          return this.decorateChatCommentExtraData(forUserEmail, ex, comment);
        } else if (comment instanceof CommentMailModel) {
          return this.decorateMailCommentExtraData(forUserEmail, ex, comment);
        } else {
          return of(ex);
        }
      }),
      flatMap((ex: CommentExtraData) => {
        comment._ex = ex;
        return this.decorateAttachments(forUserEmail, comment, force);
      })
    );
  }

  private shouldDecorate(comment: CommentModel) {
    if (!comment._ex) {
      return true;
    }

    // Decorated fields
    let decoratedFields = Object.getOwnPropertyNames(comment._ex);
    let isSubset =
      decoratedFields.length === _.intersection(decoratedFields, CommentExDecorateService.passThroughFields).length;

    return isSubset;
  }

  private decorateBaseExtraData(
    forUserEmail: string,
    comment: CommentModel,
    force?: boolean
  ): Observable<CommentBaseExtraData> {
    /**
     * Defaults
     */
    let ex: CommentBaseExtraData = {
      errorMessage: '',
      errorSending: false,
      isGroupComment: false,
      sourceResourceId: undefined,
      hasMentions: undefined
    };

    ex.isGroupComment = this.isGroupComment(comment);
    ex.hasMentions = this.hasMentions(comment);

    return of(ex);
  }

  private decorateChatCommentExtraData(
    forUserEmail: string,
    ex: CommentBaseExtraData,
    comment: CommentChatModel
  ): Observable<CommentChatExtraData> {
    let _ex = <CommentChatExtraData>ex;
    _ex.editedLocally = false;

    return of(_ex);
  }

  private decorateMailCommentExtraData(
    forUserEmail: string,
    ex: CommentBaseExtraData,
    comment: CommentMailModel
  ): Observable<CommentMailExtraData> {
    let _ex = <CommentMailExtraData>ex;

    if (!comment.author || !comment.to) {
      console.log('ASDASD');
    }

    // If Normal group in TO, don't use sendAs
    if (
      !comment.id &&
      comment.author?.email !== forUserEmail &&
      !_.some(comment.to.resources, contact => contact instanceof GroupModel && contact.groupType === GroupType.NORMAL)
    ) {
      _ex.useSendAs = true;

      if (comment._ex) {
        _ex.sendAsGroupId = (<CommentMailExtraData>comment._ex).sendAsGroupId;
      }
    }

    // Preserve undo data and draftId if existing on comment
    if (comment._ex) {
      _ex.undoData = (<CommentMailExtraData>comment._ex).undoData;
      _ex.createdFromDraftCardId = (<CommentMailExtraData>comment._ex).createdFromDraftCardId;
      _ex.createdFromDraftCommentId = (<CommentMailExtraData>comment._ex).createdFromDraftCommentId;
    }

    // Pass through body complexityIndex because it will be calculated later
    _ex.bodyComplexityIndex = _ex.bodyComplexityIndex || 0;

    // Pass through should-parse flag because it will be set after parsing
    _ex.isBodyParsed = _ex.isBodyParsed || false;

    return of(_ex);
  }

  private decorateAttachments(forUserId: string, comment: CommentModel, force?: boolean): Observable<CommentModel> {
    if (!comment.hasAttachments()) {
      return of(comment);
    } else {
      let attachments = this.getAttachments(comment);
      return this._fileExDecorateService.decorateListExtraData(forUserId, attachments, force).pipe(
        tap((attachments: FileModel[]) => {
          if (!_.isEmpty(attachments)) {
            comment.attachments.resources = attachments;
          }
        }),
        map(() => {
          return comment;
        })
      );
    }
  }

  private getAttachments(comment: CommentModel): FileModel[] {
    return FileModel.createList(comment.getResources(comment.attachments));
  }

  private isGroupComment(comment: CommentModel): boolean {
    let shareList = comment.getShareList();

    // TODO API by Neyko - remove when BE fixes their buggo
    if (_.isEmpty(shareList)) {
      return true;
    }

    return _.some(shareList, contact => contact.$type === GroupModel.type);
  }

  private hasMentions(comment: CommentModel): boolean {
    if (!(comment instanceof CommentChatModel)) {
      return false;
    }

    return _.includes(comment.comment, '[user id=') || _.includes(comment.comment, '[group id=');
  }

  // Should be done AFTER XSS is prevented because we render HTML
  decorateBodyComplexityIndexList(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return from(comments).pipe(
      map((comment: CommentModel) => this.decorateBodyComplexityIndex(comment)),
      toArray()
    );
  }

  private decorateBodyComplexityIndex(comment: CommentModel) {
    if (comment instanceof CommentMailModel) {
      comment._ex.bodyComplexityIndex = this.getBodyHtmlComplexity(comment);
    }

    return comment;
  }

  /**
   * Complexity is defined as max depth of nested tags. Depth of
   * <a><p></p></a> is 2.
   */
  private getBodyHtmlComplexity(comment: CommentMailModel): number {
    // Set default to 0
    let maxBodyComplexityIndex = 0;

    // Get old bodyComplexityIndex (if existing). Comments with
    // large body will not have it attached on every decorate
    if (comment._ex && comment._ex.bodyComplexityIndex && comment._ex.bodyComplexityIndex > 0) {
      maxBodyComplexityIndex = comment._ex.bodyComplexityIndex;
    }

    if (!comment.hasBody() || !comment.hasHtmlBody()) {
      return maxBodyComplexityIndex;
    }

    // If body consists of shortest html tags nested to depth of 300
    // that gives length of 1400. Anything less than that is ok.
    let content = comment.body.content;
    if (!content || content.length < 2100) {
      return maxBodyComplexityIndex;
    }

    // Get max depth of parsed DOM
    // NOTE: make sure we remove node
    let wrapper = document.createElement('div');
    wrapper.innerHTML = content.replace(this.imgSrcRegex, ` data-src=`);
    let depth = this.maxHtmlDepth(wrapper);
    wrapper.remove();

    // Log for statistics
    if (depth > CONSTANTS.BODY_COMPLEXITY_LIMIT) {
      Logger.customLog(
        `Calculated body complexity index that is higher than limit [${CONSTANTS.BODY_COMPLEXITY_LIMIT}]`,
        LogLevel.INFO
      );
    }

    return depth;
  }

  private maxHtmlDepth(el: Element, depth: number = 0) {
    if (!el) {
      return 0;
    }

    let mx = depth;
    let numbOfChildren = el.children ? el.children.length : 0;
    for (let i = 0; i < numbOfChildren; i++) {
      let cur = this.maxHtmlDepth(el.children[i], depth + 1);
      if (cur > mx) {
        mx = cur;
      }
    }
    return mx;
  }
}
