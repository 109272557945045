import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ThemesService } from '../../../ui/services/themes/themes.service';
import { ColorReturnType } from '../../../ui/services/themes/themes';

@Injectable()
export class HtmlFormattingHelper {
  private imgSrcRegex: RegExp = new RegExp(' src=', 'g');
  private imgDataSrcRegex: RegExp = new RegExp(` data-src=`, 'g');

  constructor(private _themesService: ThemesService) {}

  private unescapeImages(html: string): string {
    return html.replace(this.imgDataSrcRegex, ' src=');
  }

  private escapeImages(html: string): string {
    return html.replace(this.imgSrcRegex, ` data-src=`);
  }

  /**
   * @param {string} html                   html to search
   * @param {string} attributeName          attribute name to look for
   * @param {PasteReturnType} returnFormat  specify return type: text for chat, html for froala
   * @returns {string}                      concatenated content from every specified attribute
   *
   * This function will iterate through html and return content inside a specified attribute.
   */
  searchHTMLForAttribute(html: string, attributeName: string, returnType: PasteReturnType): string {
    let wrapper = document.createElement('div');
    wrapper.innerHTML = this.escapeImages(html);

    let result = this.iterateOverChildren(wrapper, attributeName, this.getAttributeFromElement);

    // Cleanup
    wrapper.remove();

    // Format and return the result as requested
    switch (returnType) {
      case PasteReturnType.HTML:
        result = this.formatAsHtml(result);
        break;
      case PasteReturnType.Text:
      default:
        break;
    }

    return this.unescapeImages(result);
  }

  /**
   * @param html            html to decorate with original styles
   * @returns {string}      decorated html
   *
   * This function will look for elements that have custom style set. It will add a new attribute,
   * ogsc (original style color) and ogsbc (original style background color).
   * Those will hold default values so we can restore them when sending email.
   */
  decorateHTMLWithOriginalStyle(html: string): string {
    let wrapper = document.createElement('div');
    wrapper.innerHTML = this.escapeImages(html);

    HtmlFormattingHelper.applyToAllChildren(wrapper, (e: HTMLElement) => {
      if (!e.hasAttribute('ogsc')) {
        if (e.style.color) {
          e.setAttribute('ogsc', e.style.color);
        }
        // font tag case
        else if ((<any>e).color) {
          e.setAttribute('ogsc', (<any>e).color);
        } else {
          e.setAttribute('ogsc', 'none');
          e.setAttribute('loop-color-shifted', 'true');
        }
      }

      if (!e.hasAttribute('ogsbc')) {
        if (e.style.backgroundColor) {
          e.setAttribute('ogsbc', e.style.backgroundColor);
        } else {
          e.setAttribute('ogsbc', 'none');
          e.setAttribute('loop-bg-color-shifted', 'true');
        }
      }
    });

    let parsedContent = wrapper.innerHTML;
    wrapper.remove();
    return this.unescapeImages(parsedContent);
  }

  /**
   * @param html            html to restore original styles
   * @returns {string}      html with original styles
   *
   * This function will look for elements with attributes that indicate no custom colors and background.
   * It will remove any custom colors forced by Froala in combination with dark theme.
   */
  restoreHTMLOriginalStyle(html: string): string {
    let wrapper = document.createElement('div');
    wrapper.innerHTML = this.escapeImages(html);

    HtmlFormattingHelper.applyToAllChildren(wrapper, (e: HTMLElement) => {
      let ogsc = e.getAttribute('ogsc');
      let ogsbc = e.getAttribute('ogsbc');

      if (ogsc && ogsc === 'none') {
        if ((<any>e).color) {
          (<any>e).color = '';
        }
        e.style.color = '';
        e.removeAttribute('ogsc');
      } else if (ogsc) {
        if ((<any>e).color) {
          (<any>e).color = ogsc;
        }
        e.style.color = ogsc;
        e.removeAttribute('ogsc');
      }

      if (ogsbc && ogsbc === 'none') {
        e.style.backgroundColor = '';
        e.removeAttribute('ogsbc');
      } else if (ogsbc) {
        e.style.backgroundColor = ogsbc;
        e.removeAttribute('ogsbc');
      }

      e.removeAttribute('loop-color-shifted');
      e.removeAttribute('loop-bg-color-shifted');
    });

    let parsedContent = wrapper.innerHTML;
    wrapper.remove();
    return this.unescapeImages(parsedContent);
  }

  /**
   * @param html            html to prepare for view
   * @returns {string}      html with mapped grey colors
   *
   * This function will calculate colors according to currently selected theme and map grey tones
   * according to theme-local black and white color. This function is used only for displaying html!
   */
  shiftColorsForView(html: string): string {
    let wrapper = document.createElement('div');
    wrapper.innerHTML = this.escapeImages(html);

    HtmlFormattingHelper.applyToAllChildren(wrapper, (e: HTMLElement) => {
      if (e.style.color && !e.hasAttribute('loop-color-shifted')) {
        e.style.color = this._themesService.currentSelectedTheme.mapToLocalColor(e.style.color, ColorReturnType.RGBA);
        e.setAttribute('loop-color-shifted', 'true');
      }
      // font tag case
      else if ((<any>e).color && !e.hasAttribute('loop-color-shifted')) {
        (<any>e).color = this._themesService.currentSelectedTheme.mapToLocalColor((<any>e).color, ColorReturnType.HEX);
        e.setAttribute('loop-color-shifted', 'true');
      }

      if (e.style.backgroundColor && !e.hasAttribute('loop-bg-color-shifted')) {
        e.style.backgroundColor = this._themesService.currentSelectedTheme.mapToLocalColor(
          e.style.backgroundColor,
          ColorReturnType.RGBA
        );
        e.setAttribute('loop-bg-color-shifted', 'true');
      }
    });

    let parsedContent = wrapper.innerHTML;
    wrapper.remove();
    return this.unescapeImages(parsedContent);
  }

  removeAllColorShiftAttributes(html: string) {
    let wrapper = document.createElement('div');
    wrapper.innerHTML = html;

    HtmlFormattingHelper.applyToAllChildren(wrapper, (e: HTMLElement) => {
      e.removeAttribute('loop-color-shifted');
      e.removeAttribute('loop-color-shifted');
      e.removeAttribute('loop-bg-color-shifted');
      e.removeAttribute('ogsc');
      e.removeAttribute('ogsbc');
      e.removeAttribute('fr-original-style');
    });

    return wrapper.innerHTML;
  }

  setOriginalColors(html: string) {
    let wrapper = document.createElement('div');
    wrapper.innerHTML = html;

    HtmlFormattingHelper.applyToAllChildren(wrapper, (e: HTMLElement) => {
      if (e.style.color) {
        e.setAttribute(
          'ogsc',
          this._themesService.currentSelectedTheme.mapToLocalColor(e.style.color, ColorReturnType.RGBA, true)
        );
      }
      if (e.style.backgroundColor) {
        e.setAttribute(
          'ogsbc',
          this._themesService.currentSelectedTheme.mapToLocalColor(e.style.backgroundColor, ColorReturnType.RGBA, true)
        );
      }
    });

    return wrapper.innerHTML;
  }

  /**
   * Helpers
   */
  static applyToAllChildren(el: Node, _fun: (a: Node) => void) {
    if (!el) {
      return;
    }

    let elementsToCheck: Node[] = [el];

    while (elementsToCheck.length > 0) {
      let el = elementsToCheck.shift();

      // Check if element has any children and
      // add child elements to array if they are of correct type
      if (el.childNodes && el.childNodes.length > 0) {
        _.forEach(el.childNodes, (node: Node) => {
          node instanceof HTMLElement && elementsToCheck.push(node);
        });
      }

      _fun(el);
    }
  }

  private iterateOverChildren(_el: HTMLElement, attrName: string, _fun: (a: HTMLElement, b: string) => string): string {
    if (_el.childElementCount === 0) {
      return _fun(_el, attrName);
    } else {
      return (
        _fun(_el, attrName) +
        _.map(_el.children, (child: HTMLElement) => this.iterateOverChildren(child, attrName, _fun)).join('')
      );
    }
  }

  private formatAsHtml(text: string): string {
    if (_.isEmpty(text)) {
      return '';
    }

    let textParts = text.split('\n');

    return '<div><div>' + textParts.join('</div><div>') + '</div></div>';
  }

  private getAttributeFromElement(_el: HTMLElement, attrName: string): string {
    return _el.hasAttribute(attrName) ? _el.getAttribute(attrName) : '';
  }
}

export enum PasteReturnType {
  Text,
  HTML
}
