import * as _ from 'lodash';
import { BaseModel, ListOfResourcesOfResourceBase } from '../base/base.model';
import { SharedTagBase } from '@shared/api/api-loop/models/shared-tag-base';
import { SharedTagAssignee } from '@shared/api/api-loop/models/shared-tag-assignee';
import { SharedTagStatus } from '@shared/api/api-loop/models/shared-tag-status';
import { User } from '@shared/api/api-loop/models/user';
import { SharedTagCustom } from '@shared/api/api-loop/models/shared-tag-custom';
import {
  ActionEnum,
  ClassificationTagType,
  FilterEnum,
  Group,
  SharedTagAssignedBy,
  SharedTagClassification,
  SharedTagFolder,
  SharedTagLabel,
  SharedTagMention,
  SharedTagPriority,
  SharedTagReaction,
  SharedTagSystem,
  SharedTagSystemNames
} from '@shared/api/api-loop/models';
import { ContactModel, GroupModel, UserModel } from '../contact/contact.model';
import { TagRights } from '@shared/api/api-loop/models/tag-rights';

export type SharedTagModel =
  | SharedTagAssigneeModel
  | SharedTagStatusModel
  | SharedTagCustomModel
  | SharedTagAssignedByModel
  | SharedTagPriorityModel
  | SharedTagLabelModel
  | SharedTagFolderModel
  | SharedTagClassificationModel;

export abstract class SharedTagBaseModel extends BaseModel implements SharedTagBase {
  color?: string;

  // This is DTA property used for determining which shared
  // tag is disabled in filter view. It will be removed on reduce and will
  // not be persisted
  disabled?: boolean;

  _ex: SharedTagExtraData;
  _ui: SharedTagViewData;

  static create(doc: SharedTagBase): SharedTagModel {
    if (!doc || !doc.$type) {
      throw new Error(`Invalid $type given ${JSON.stringify(doc)}`);
    }
    if (doc instanceof SharedTagBaseModel) {
      return doc;
    }

    switch (doc.$type) {
      case SharedTagAssigneeModel.type:
        return new SharedTagAssigneeModel(doc);
      case SharedTagStatusModel.type:
        return new SharedTagStatusModel(doc);
      case SharedTagCustomModel.type:
        return new SharedTagCustomModel(doc);
      case SharedTagSystemModel.type:
        return new SharedTagSystemModel(doc);
      case SharedTagAssignedByModel.type:
        return new SharedTagAssignedByModel(doc);
      case SharedTagLabelModel.type:
        return new SharedTagLabelModel(doc);
      case SharedTagPriorityModel.type:
        return new SharedTagPriorityModel(doc);
      case SharedTagFolderModel.type:
        return new SharedTagFolderModel(doc);
      case SharedTagClassificationModel.type:
        return new SharedTagClassificationModel(doc);
      default:
        break;
    }
  }

  /**
   * Cast documents to shared tag models and remove undefined values from list
   * Undefined values can appear due to new models added to API at any time
   */
  static createList(docs: SharedTagBase[]): SharedTagModel[] {
    return _.filter(
      _.map(docs, doc => SharedTagBaseModel.create(doc)),
      st => st !== undefined
    );
  }
}

export class SharedTagAssigneeModel extends SharedTagBaseModel implements SharedTagAssignee {
  static type: string = 'SharedTagAssignee';

  readonly $type: string = SharedTagAssigneeModel.type;

  userId?: string;

  _ex: SharedTagAssigneeExtraData;

  constructor(data?: SharedTagAssignee) {
    super(data);
  }

  populateWithContact(contact: ContactModel) {
    this._ex = {
      user: contact
    };
  }

  assigneeToReducedForm() {
    if (this._ex) {
      delete this._ex.user;
    }
  }

  static createSharedTagFromContact(contact: UserModel): SharedTagAssigneeModel {
    return new SharedTagAssigneeModel({
      $type: SharedTagAssigneeModel.type,
      id: 'ST::assignee::' + contact.id,
      userId: contact.id
    });
  }
}

export class SharedTagStatusModel extends SharedTagBaseModel implements SharedTagStatus {
  static type: string = 'SharedTagStatus';

  readonly $type: string = SharedTagStatusModel.type;

  constructor(data?: SharedTagStatus) {
    super(data);
  }

  static createDefaultStatusSharedTag(tagId: StaticSharedTagIds): SharedTagStatusModel {
    let statusTag = new SharedTagStatusModel({
      $type: SharedTagStatusModel.type,
      id: tagId
    });

    // Pre-fill static data
    if (tagId === StaticSharedTagIds.RESOLVED_ID) {
      statusTag.color = '#5ABA62';
      statusTag.created = new Date().toISOString();
      statusTag.name = 'Resolved';
      statusTag.revision = '0';
    } else if (tagId === StaticSharedTagIds.UNASSIGNED_ID) {
      statusTag.color = '#9d9ba0';
      statusTag.created = new Date().toISOString();
      statusTag.name = 'Unassigned';
      statusTag.revision = '0';
    }

    return statusTag;
  }
}

export class SharedTagCustomModel extends SharedTagBaseModel implements SharedTagCustom {
  static type: string = 'SharedTagCustom';

  readonly $type: string = SharedTagCustomModel.type;

  constructor(data?: SharedTagCustom) {
    super(data);
  }
}

export class SharedTagFolderModel extends SharedTagBaseModel implements SharedTagFolder {
  static type: string = 'SharedTagFolder';

  readonly $type: string = SharedTagFolderModel.type;

  constructor(data?: SharedTagFolder) {
    super(data);
  }

  groupId: string;
  filterEnum?: FilterEnum;
  actionEnum?: ActionEnum;
  position?: number;
  _ex: SharedTagFolderExtraData;

  isAllowedInContext(contextId: string) {
    return !contextId || this.groupId === contextId;
  }
}

export class SharedTagPriorityModel extends SharedTagBaseModel implements SharedTagPriority {
  static type: string = 'SharedTagPriority';

  readonly $type: string = SharedTagPriorityModel.type;

  constructor(data?: SharedTagPriority) {
    super(data);
  }
}

export class SharedTagSystemModel extends SharedTagBaseModel implements SharedTagSystem {
  static type: string = 'SharedTagSystem';

  readonly $type: string = SharedTagSystemModel.type;

  constructor(data?: SharedTagSystem) {
    super(data);
  }

  static buildSystemSharedTag(type: SharedTagSystemNames): SharedTagSystemModel {
    return new SharedTagSystemModel({
      $type: SharedTagSystemModel.type,
      id: this.buildSystemSharedTagId(type),
      name: type
    });
  }

  static buildSystemSharedTagId(type: SharedTagSystemNames): string {
    return 'ST::system::' + btoa(type);
  }
}

export class SharedTagAssignedByModel extends SharedTagBaseModel implements SharedTagAssignedBy {
  static type: string = 'SharedTagAssignedBy';

  readonly $type: string = SharedTagAssignedByModel.type;

  userId?: string;

  _ex: SharedTagAssigneeExtraData;

  constructor(data?: SharedTagAssignedBy) {
    super(data);
  }

  static createSharedTagFromContact(contact: UserModel): SharedTagAssignedByModel {
    return new SharedTagAssignedByModel({
      $type: SharedTagAssignedByModel.type,
      userId: contact.id,
      color: '#F5A623',
      id: 'ST::assignedby::' + contact.id
    });
  }
}

export class SharedTagMentionModel extends SharedTagBaseModel implements SharedTagMention {
  static type: string = 'SharedTagMention';

  readonly $type: string = SharedTagMentionModel.type;

  constructor(data?: SharedTagMention) {
    super(data);
  }
}

export class SharedTagReactionModel extends SharedTagBaseModel implements SharedTagReaction {
  static type: string = 'SharedTagReaction';

  readonly $type: string = SharedTagReactionModel.type;

  userId: string;
  reactorName?: string;

  constructor(data?: SharedTagReaction) {
    super(data);
  }

  static buildReactionSharedTag(content: string, userId: string): SharedTagReactionModel {
    return new SharedTagReactionModel({
      $type: SharedTagReactionModel.type,
      id: this.buildReactionSharedTagId(content, userId),
      name: content,
      userId: userId
    });
  }

  static buildReactionSharedTagId(name: string, userId: string): string {
    let b64TagName = btoa(unescape(encodeURIComponent(name)));
    return 'ST::reaction::' + b64TagName + ':' + userId;
  }
}

export class SharedTagLabelModel extends SharedTagBaseModel implements SharedTagLabel {
  static type: string = 'SharedTagLabel';

  readonly $type: string = SharedTagLabelModel.type;

  parentTagId?: string;
  deleted?: boolean;
  allowOnContext?: ListOfResourcesOfResourceBase;
  workspaceId?: string;
  rights?: TagRights;

  private static rightsHierarchy = [TagRights.VIEW, TagRights.ASSIGN, TagRights.EDIT];

  constructor(data?: SharedTagLabel) {
    super(data);
  }

  hasHigherOrEqualRightsThan(rightToCompare: TagRights) {
    return (
      _.findIndex(SharedTagLabelModel.rightsHierarchy, r => r === this.rights) >=
      _.findIndex(SharedTagLabelModel.rightsHierarchy, r => r === rightToCompare)
    );
  }

  static getHigherOrEqualRightsThen(rightToCompare: TagRights) {
    let cutOff = _.findIndex(SharedTagLabelModel.rightsHierarchy, r => r === rightToCompare);
    return SharedTagLabelModel.rightsHierarchy.slice(cutOff);
  }

  isAllowedInContext(contextId: string): boolean {
    return (
      !contextId || _.isEmpty(this.allowOnContext) || _.map(this.allowOnContext.resources, 'id').includes(contextId)
    );
  }
}

export class SharedTagClassificationModel extends SharedTagBaseModel implements SharedTagClassification {
  static type: string = 'SharedTagClassification';

  readonly $type: string = SharedTagClassificationModel.type;

  constructor(data?: SharedTagClassification) {
    super(data);
  }

  workspaceId?: string;
  parentTagId?: string;
  deleted?: boolean;
  classificationType?: ClassificationTagType;
  description?: string;
  rights?: TagRights;
}

export interface SharedTagExtraData {
  labelIsToggleable?: boolean;
}

export interface SharedTagViewData {}

export interface SharedTagFolderExtraData extends SharedTagExtraData {
  deleted?: boolean;
}

export interface SharedTagAssigneeExtraData extends SharedTagExtraData {
  user: User;
  disableRemove?: boolean;
  hideAvatar?: boolean;
}

export enum StaticSharedTagIds {
  INBOX_ID = 'ST::system::SU5CT1g=',
  ARCHIVED_ID = 'ST::system::QVJDSElWRQ==',
  DELETED_ID = 'ST::system::REVMRVRFRA==',
  SENT_ID = 'ST::system::U0VOVA==',
  SPAM_ID = 'ST::system::U1BBTQ==',
  UNFOLLOW_ID = 'ST::system::VU5GT0xMT1c=',
  SYSTEMMESSAGE_ID = 'ST::system::U1lTVEVNTUVTU0FHRQ==',

  UNASSIGNED_ID = 'ST::status::VW5hc3NpZ25lZA==',
  RESOLVED_ID = 'ST::status::UmVzb2x2ZWQ='
}

export enum StaticSharedTagPrefix {
  LABEL_PREFIX = 'ST::label::',
  FOLDER_PREFIX = 'ST::folder::',
  CLASSIFICATION_PREFIX = 'ST::classification::'
}
