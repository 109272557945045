import * as _ from 'lodash';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CardBaseModel, CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { ContactBaseModel, ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ConversationCardPopulateService } from '../conversation-card-populate.service';
import { SharedTagBase } from '@shared/api/api-loop/models';
import { SharedTagService } from '@shared/services/data/shared-tags/shared-tags.service';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { TagLabelService } from '@shared/services/data/tag-label/tag-label.service';
import { SmartClassificationSharedTagApi } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/smart-classification-shared-tag-api';

@Injectable()
export class CardPopulateService extends ConversationCardPopulateService<CardModel> {
  constructor(
    protected _contactService: ContactService,
    protected _contactStoreFactory: ContactStoreFactory,
    protected _sharedTagService: SharedTagService,
    protected _tagLabelService: TagLabelService,
    protected readonly smartClassificationSharedTagApi: SmartClassificationSharedTagApi
  ) {
    super(_contactService, _contactStoreFactory, _sharedTagService, _tagLabelService, smartClassificationSharedTagApi);
  }

  get constructorName(): string {
    return 'CardPopulateService';
  }

  populate(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return of(cards).pipe(
      /**
       * Populate with sharedTags
       */
      mergeMap((_cards: CardModel[]) => {
        return this.populateWithSharedTags(forUserEmail, _cards);
      }),
      /**
       * Populate with private Tags
       */
      mergeMap((_cards: CardModel[]) => {
        return this.populateWithPrivateTags(forUserEmail, _cards);
      }),
      /**
       * Populate with contacts
       */
      mergeMap((_cards: CardModel[]) => {
        return this.populateWithContacts(forUserEmail, _cards);
      }),
      /**
       * Populate channel tag
       */
      mergeMap((_cards: CardModel[]) => {
        return this.populateWithChannelTag(forUserEmail, _cards);
      })
    );
  }

  reduce(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    // NOTE: This clone will prevent backwards reference mutation that can cause crash
    // due to reduced form of contact. This will mask all old references being used
    // that would cause crash otherwise.
    let clonedCards = CardBaseModel.createList(_.cloneDeep(cards));

    return of(clonedCards).pipe(
      /**
       * Reduce sharedTags
       */
      mergeMap((_cards: CardModel[]) => {
        return this.sharedTagsToReducedForm(forUserEmail, _cards);
      }),
      /**
       * Reduce contacts
       */
      mergeMap((_cards: CardModel[]) => {
        return this.contactsToReducedForm(forUserEmail, _cards);
      }),
      /**
       * Reduce channel tag
       */
      mergeMap((_cards: CardModel[]) => {
        return this.channelTagToReducedForm(forUserEmail, _cards);
      })
    );
  }

  //////////////
  // SharedTags
  //////////////
  private sharedTagsToReducedForm(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return from(cards).pipe(
      /**
       * Reduce to minimal form (id and $type)
       */
      map((card: CardModel) => {
        card.sharedTagsToReducedForm();
        return card;
      }),
      toArray()
    );
  }

  protected buildCardIdBySharedTagId(
    cards: CardModel[],
    missingSharedTagIds: string[]
  ): { [sharedTagId: string]: string } {
    // Bundle sharedTags under card ids (for authorization)
    let cardIdBySharedTagId: _.Dictionary<string> = {};
    _.forEach(cards, (card: CardModel) => {
      if (!card.hasSharedTags()) {
        return;
      }

      _.forEach(card.getSharedTags(), (sharedTag: SharedTagBase) => {
        if (missingSharedTagIds.includes(sharedTag.id) && _.isEmpty(cardIdBySharedTagId[sharedTag.id])) {
          cardIdBySharedTagId[sharedTag.id] = card.id;
        }
      });
    });

    return cardIdBySharedTagId;
  }

  /////////////
  // Contacts
  /////////////
  private contactsToReducedForm(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    let _allContacts = [];
    return from(cards).pipe(
      map((card: CardModel) => {
        // Stash all contacts
        _allContacts.push(..._.cloneDeep(card.getAllContacts()));

        card.contactsToReducedForm();
        return card;
      }),
      toArray(),
      mergeMap((_cards: CardModel[]) => {
        // Get unique contacts and save unknown-ones
        let allUniqueContacts = _.uniqBy(_allContacts, 'id');
        allUniqueContacts = ContactBaseModel.createList(allUniqueContacts);

        return this._contactService.saveUnknownContacts(forUserEmail, allUniqueContacts).pipe(map(() => _cards));
      })
    );
  }

  ///////////////
  // Channel tag
  ///////////////
  private channelTagToReducedForm(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return from(cards).pipe(
      map((card: CardModel) => {
        if (card._ex && card._ex.channelTag) {
          delete card._ex.channelTag.displayName;
        }
        return card;
      }),
      toArray()
    );
  }

  private populateWithChannelTag(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    if (!cards || cards.length === 0) {
      return of(cards);
    }

    let contactIds = _.uniq(_.map(cards, card => card._ex.channelTag && card._ex.channelTag.contactId));
    contactIds = contactIds.filter(id => id !== undefined);
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    let contactsById;

    return contactStore.getContactsByIds(contactIds).pipe(
      mergeMap((contacts: ContactModel[]) => {
        contactsById = _.keyBy(contacts, 'id');
        return from(cards);
      }),
      map((card: CardModel) => {
        if (!card._ex.channelTag) {
          return card;
        }

        let contact = contactsById[card._ex.channelTag.contactId];

        if (contact) {
          card._ex.channelTag.displayName = contact.$type === GroupModel.type ? contact.name : contact.email;
        }

        return card;
      }),
      toArray(),
      catchError(err => {
        console.log('Error when populating channel tag');
        return throwError(err);
      })
    );
  }
}
