import * as _ from 'lodash';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { ApiService } from '@shared/api/api-loop/api.module';
import { Logger } from '@shared/services/logger/logger';
import {
  catchError,
  concatMap,
  delay,
  filter,
  finalize,
  map,
  mergeMap,
  repeatWhen,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { EventList, SortOrder } from '@shared/api/api-loop/models';
import { CONSTANTS } from '@shared/models/constants/constants';
import { EventApiService } from '@shared/api/api-loop/services';
import { SyncServiceStatus } from '@shared/models/sync/synchronization-service-status.model';
import {
  EventFilterType,
  PullSynchronizationService,
} from '@shared/synchronization/pull-synchronization/pull-synchronization.service';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { MimeType } from '@dta/shared/models-api-loop/comment/comment.model';
import { EventSynchronizationService } from '@shared/synchronization/event-synchronization/event-synchronization.service';
import { SynchronizationStatusType } from '@shared/synchronization/synchronization-status.service';
import { DataServiceShared } from '@shared/services/data/data.service';

export class ActiveSynchronizationService {
  // State variables
  active: boolean = false;
  private historyId: string;

  // Active sync for unverified user is allow only
  // for 'UserSettingsUpdated' event
  private unverifiedUserEventTypeList = [EventFilterType.UserSettingsUpdated];

  constructor(
    private _userEmail: string,
    private _apiService: ApiService,
    private _storageService: StorageService,
    private _eventSynchronizationService: EventSynchronizationService,
    private _pullSynchronizationService: PullSynchronizationService,
    private _sharedUserManagerService: SharedUserManagerService,
    private _data: DataServiceShared,
  ) {}

  get constructorName(): string {
    return 'ActiveSynchronizationService';
  }

  /**
   * Start active synchronization
   */
  startActiveSync(unsubscribe$: Subject<void>): Subscription {
    if (this.active) {
      Logger.customLog(
        `[SYNC] - ActiveSync [${this._userEmail}]: startActiveSync() called but process is marked as active`,
        LogLevel.TRACE,
        LogTag.SYNC,
      );

      return;
    }

    let watch: StopWatch;
    this.active = true;

    Logger.log(`[SYNC] - ActiveSync [${this._userEmail}]: started. waiting for contact sync to complete`);
    return this.getHistoryIdFromBackend()
      .pipe(
        tap((newHistoryId: string) => {
          this.persistHistoryId(newHistoryId);
        }),
      )
      .pipe(
        mergeMap(() => {
          Logger.log(`[SYNC] - ActiveSync [${this._userEmail}]: contact sync completed. Will start past sync`);
          Logger.log(`[SYNC] - ActiveSync [${this._userEmail}]: contact sync completed. Will start past sync`);
          return this.runPeriodicActiveSync(unsubscribe$, watch);
        }),
        /**
         * Always di-active
         */
        finalize(() => {
          this.active = false;
          this._pullSynchronizationService.notifyDequeue(SynchronizationStatusType.PULL_ACTIVE);
        }),
      )
      .subscribe();
  }

  private getHistoryIdFromBackend(): Observable<string> {
    return this._apiService.EventApiService.Event_GetList({}, this._userEmail).pipe(
      map((eventList: EventList) => {
        return eventList.lastEventId;
      }),
    );
  }

  private runPeriodicActiveSync(unsubscribe$: Subject<void>, watch: StopWatch): Observable<any> {
    return of(undefined).pipe(
      tap(() => {
        watch = new StopWatch(this.constructorName + '.startActiveSync', ProcessType.SERVICE, this._userEmail);
      }),
      /**
       * Get events
       */
      concatMap(() => {
        watch.log('getting events');
        return this.getEvents();
      }),
      /**
       * Filter out empty list
       */
      filter((eventList: EventList) => {
        return !_.isNil(eventList) && !_.isUndefined(eventList);
      }),
      filter((eventList: EventList) => {
        if (eventList.size <= 0) {
          this.persistHistoryId(eventList.lastEventId);
          return false;
        }

        return true;
      }),
      /**
       * Process events
       */
      concatMap((eventList: EventList) => {
        watch.log('will process events (' + eventList.resources.length + ')');

        // Don't show spinner for small event batches (usually long polling)
        if (eventList.size > 10) {
          this._pullSynchronizationService.notifyEnqueue(SynchronizationStatusType.PULL_ACTIVE);
        }

        return this._eventSynchronizationService.processEventList(eventList, SynchronizationStatusType.PULL_ACTIVE);
      }),
      /**
       * Update historyId
       */
      tap((eventList: EventList) => {
        watch.log('have process events (' + eventList.resources.length + ')');
        this._pullSynchronizationService.notifyDequeue(SynchronizationStatusType.PULL_ACTIVE);
        this.persistHistoryId(eventList.lastEventId);
      }),
      /**
       * Trigger follow up sync
       */
      tap(() => {
        this._pullSynchronizationService.getFollowUpSyncSubject().next(undefined);
      }),
      /**
       * Repeat
       */
      repeatWhen((repeat: Observable<any>) => {
        return repeat.pipe(
          takeUntil(unsubscribe$),
          filter(() => this.active),
          delay(CONSTANTS.ACTIVE_SYNC_PULL_INTERVAL),
        );
      }),
      /**
       * Catch any error
       */
      catchError(err => {
        // Watchdog will restart the sync
        this.active = false;

        Logger.error(err, `[SYNC] - ActiveSync [${this._userEmail}]: error`, LogTag.SYNC);
        return throwError(err);
      }),
    );
  }

  getStatus(): SyncServiceStatus {
    return this.active ? SyncServiceStatus.ACTIVE : SyncServiceStatus.INACTIVE;
  }

  ///////////
  // HELPERS
  ///////////
  /**
   * Returns event id that was last synced
   */
  getActiveSyncHistoryId(): string {
    if (_.isEmpty(this.historyId)) {
      let key = this._storageService.getKey(this._userEmail, StorageKey.activeSyncHistoryId);
      this.historyId = this._storageService.getParsedItem(key);
    }

    return this.historyId;
  }

  /**
   * Set synced history id
   */
  persistHistoryId(historyId: string) {
    this.historyId = historyId;

    let key = this._storageService.getKey(this._userEmail, StorageKey.activeSyncHistoryId);
    this._storageService.setStringifiedItem(key, historyId);
  }

  /**
   * Get events since now
   */
  private getEvents(): Observable<EventList> {
    let historyId = this.getActiveSyncHistoryId();
    let apiParameters: EventApiService.Event_GetListParams = {
      sortOrder: SortOrder.ASCENDING,
      historyId: historyId,
      htmlFormat: MimeType.html,
      longPolling: true,
      size: CONSTANTS.ACTIVE_SYNC_PAGE_SIZE,
      skipOlderThan: this._pullSynchronizationService.getCutOffDate().toISOString(),
      eventTypeList: this._sharedUserManagerService.isUserAccountVerified(this._userEmail)
        ? []
        : this.unverifiedUserEventTypeList,
    };

    return this._apiService.EventApiService.Event_GetList(apiParameters, this._userEmail);
  }
}
