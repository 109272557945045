import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { catchError, defaultIfEmpty, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { from, Observable, of, throwError } from 'rxjs';
import { ConversationPopulateService } from '@shared/populators/conversation-card-populate/conversation-populate/conversation-populate.service';
import { ContactBaseModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ContactService } from '../contact/contact.service';
import {
  CardShared,
  CardType,
  ContactBase,
  ListOfResourcesOfConversation,
  ListOfTags,
  SearchQueryConversation,
  ShowInViewObject,
  SortOrder,
  SortType,
} from '@shared/api/api-loop/models';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ConversationDaoService } from '@shared/database/dao/conversation/conversation-dao.service';
import { ConversationServiceI } from '@shared/services/data/conversation/conversation.service.interface';
import { ConversationCollectionParams } from '@dta/shared/models/collection.model';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { Logger } from '@shared/services/logger/logger';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { CardService } from '@shared/services/data/card/card.service';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { StateUpdates } from '@dta/shared/models/state-updates';
import { LogLevel } from '@dta/shared/models/logger.model';
import { ConversationApiService } from '@shared/api/api-loop/services/conversation-api.service';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';

@Injectable()
export class ConversationService extends BaseService implements ConversationServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _conversationDaoService: ConversationDaoService,
    protected _conversationPopulateService: ConversationPopulateService,
    protected _conversationApiService: ConversationApiService,
    protected _contactService: ContactService,
    protected _commentService: CommentService,
    protected _cardService: CardService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'ConversationService';
  }

  saveAll(forUserEmail: string, conversations: ConversationModel[]): Observable<ConversationModel[]> {
    if (_.isEmpty(conversations)) {
      return of([]);
    }

    return of(conversations).pipe(
      mergeMap((_conversations: ConversationModel[]) => {
        return this.doBeforeSave(forUserEmail, _conversations);
      }),
      mergeMap((_conversations: ConversationModel[]) => {
        return this._conversationDaoService.saveAll(forUserEmail, _conversations);
      }),
      mergeMap((_conversations: ConversationModel[]) => {
        return this._conversationPopulateService.populate(forUserEmail, _conversations);
      }),
    );
  }

  saveAllAndPublish(forUserEmail: string, conversations: ConversationModel[]): Observable<ConversationModel[]> {
    if (_.isEmpty(conversations)) {
      return of([]);
    }

    return this.saveAll(forUserEmail, conversations).pipe(
      tap((_conversations: ConversationModel[]) => {
        PublisherService.publishEvent(forUserEmail, _conversations);
      }),
    );
  }

  public getConversationsFromAPI(
    forUserEmail: string,
    searchQuery: SearchQueryConversation,
  ): Observable<ListOfResourcesOfConversation> {
    return of(undefined).pipe(
      /**
       * Fetch
       */
      mergeMap(() => this._conversationApiService.Conversation_GetList({ query: searchQuery }, forUserEmail)),
    );
  }

  findConversationsForView(
    forUserEmail: string,
    params: ConversationCollectionParams,
  ): Observable<ConversationModel[]> {
    return this._conversationDaoService.findConversationsForView(forUserEmail, params);
  }

  fetchAllMissingCommentsAndCardsForConversations(
    forUserEmail: string,
    conversationIds: string[],
  ): Observable<ConversationModel[]> {
    return this.findConversationsWithoutSynchronizedComments(forUserEmail, conversationIds).pipe(
      /**
       * Remove all chat comments as we don't load them all and thus can have wrong state
       */
      mergeMap((_conversations: ConversationModel[]) => {
        return this.deleteCommentsForChatConversations(
          forUserEmail,
          _.map(
            _.filter(_conversations, conversation => conversation.cardType === CardType.CARD_CHAT),
            'cardId',
          ),
        ).pipe(map(() => _conversations));
      }),
      /**
       * Fetch missing comments and cards (follow-up sync)
       */
      mergeMap((_conversations: ConversationModel[]) => {
        return this.fetchMissingCommentsAndCardsForConversations(forUserEmail, _conversations);
      }),
    );
  }

  findOrFetchByCardId(forUserEmail: string, cardId: string): Observable<ConversationModel> {
    return this.findOrFetchByCardIds(forUserEmail, [cardId]).pipe(
      map((conversations: ConversationModel[]) => _.first(conversations)),
    );
  }

  findOrFetchByCardIds(forUserEmail: string, cardIds: string[]): Observable<ConversationModel[]> {
    return this.findByCardIds(forUserEmail, cardIds).pipe(
      catchError(err => {
        return of([]);
      }),
      mergeMap((localConversations: ConversationModel[]) => {
        let localCardIds = _.map(localConversations, (card: ConversationModel) => card.cardId);
        let missingCardIds = _.difference(cardIds, localCardIds);

        return this.fetchConversationsByCardIds(forUserEmail, missingCardIds).pipe(
          catchError(err => {
            // When offline, return empty
            if (err.status === 0) {
              return of([]);
            }

            Logger.customLog('Error when fetching conversations in ConversationService. Err: ' + err, LogLevel.ERROR);

            return throwError(() => err);
          }),
          map((apiConversations: ConversationModel[]) => {
            return [...localConversations, ...apiConversations];
          }),
        );
      }),
    );
  }

  fetchConversationsByCardIds(forUserEmail: string, cardIds: string[]): Observable<any> {
    if (!cardIds || _.isEmpty(cardIds)) {
      return of([]);
    }

    let query: SearchQueryConversation = {
      $type: 'SearchQueryConversation',
      conversationIds: cardIds,
      sortOrder: SortOrder.ASCENDING,
      sortType: SortType.MODIFIED_DATE,
      size: cardIds.length,
    };
    return this._conversationApiService.Conversation_GetList({ query: query }, forUserEmail).pipe(
      /**
       * Process and save updates
       */
      mergeMap((response: ListOfResourcesOfConversation) => {
        let _conversations = ConversationModel.createList(response.resources);

        return this.saveAllAndPublish(forUserEmail, _conversations);
      }),
    );
  }

  public deleteCommentsForChatConversations(
    forUserEmail: string,
    conversationIds: string[],
  ): Observable<CommentModel[]> {
    return this._commentService.findCommentsByParentIdsOrClientIds(forUserEmail, conversationIds).pipe(
      mergeMap((comments: CommentModel[]) => {
        let stateUpdates = new StateUpdates([], comments, []);
        PublisherService.publishStateUpdates(forUserEmail, stateUpdates);
        return this._commentService.removeAll(forUserEmail, comments);
      }),
    );
  }

  public fetchMissingCommentsAndCardsForConversations(
    forUserEmail: string,
    conversations: ConversationModel[],
  ): Observable<ConversationModel[]> {
    if (_.isEmpty(conversations)) {
      return of([]);
    }

    let watch = new StopWatch(
      this.constructorName + '.fetchMissingCommentsForConversations',
      ProcessType.SERVICE,
      forUserEmail,
    );

    watch.log('Found', conversations.length, 'conversations without synchronized comments');

    /**
     * Fetch missing comments
     */
    return from(conversations).pipe(
      mergeMap((conversation: ConversationModel) => {
        let id = [conversation.cardId, conversation.snapshotCard?.id];
        return from(id).pipe(filter((cardId: string) => !!cardId));
      }),
      toArray(),
      mergeMap((cardIds: string[]) => {
        return this._cardService.fetchCardsAndCommentsById(forUserEmail, cardIds);
        // fetch one by one
        // return this._cardService.findOrFetchCardsById(forUserEmail, cardIds);
      }),
      /**
       * Save conversations
       */
      mergeMap(() => {
        return this.setSyncedCommentAttribute(forUserEmail, _.map(conversations, 'id'));
      }),
      tap(() => {
        watch.log('done');
      }),
      catchError(err => {
        Logger.log(err, 'Could not fetchCommentsAndSaveForCardIds');
        return of([]);
      }),
    );
  }

  beforeSyncServiceInit(forUserEmail: string): Observable<any> {
    return of(undefined);
  }

  removeSyncedCommentAttribute(forUserEmail: string, showInViews: ShowInViewObject): Observable<any> {
    return this._conversationDaoService.removeSyncedCommentAttribute(forUserEmail, showInViews);
  }

  unlinkPrivateDraft(forUserEmail: string, conversationId: string): Observable<any> {
    return this._conversationDaoService.unlinkPrivateDraft(forUserEmail, conversationId);
  }

  setSyncedCommentAttribute(forUserEmail: string, conversationIds: string[]): Observable<any> {
    return this._conversationDaoService.setSyncedCommentAttribute(forUserEmail, conversationIds);
  }

  updateCardsSharedTags(forUserEmail: string, sharedTags: ListOfTags[]): Observable<ConversationModel[]> {
    if (_.isEmpty(sharedTags)) {
      return of([]);
    }

    let conversations: ConversationModel[] = [];
    let sharedTagsByParentId = _.keyBy(sharedTags, 'parent.id');
    let ids: string[] = _.keys(sharedTagsByParentId);

    /**
     * Find local cards
     */
    return this.findByCardIds(forUserEmail, ids).pipe(
      /**
       * Combine local and fetched cards
       */
      mergeMap((_conversations: ConversationModel[]) => from(_conversations)),
      /**
       * Filter cards that should be updated
       */
      filter((conversation: ConversationModel) => {
        let tags = sharedTagsByParentId[conversation.cardId];
        let shouldUpdate =
          tags && (!conversation.sharedTags || BaseModel.isRevisionGreaterOrEqualThan(tags, conversation.sharedTags));

        return shouldUpdate;
      }),
      /**
       * Append shared tags to cards
       */
      map((conversation: ConversationModel) => {
        conversation.sharedTags = sharedTagsByParentId[conversation.cardId];
        return conversation;
      }),
      toArray(),
      defaultIfEmpty([]),
      /**
       * Save all cards
       */
      mergeMap((_conversation: ConversationModel[]) => {
        return this.saveAll(forUserEmail, _conversation);
      }),
    );
  }

  protected findConversationsWithoutSynchronizedComments(
    forUserEmail,
    conversationIds: string[],
  ): Observable<ConversationModel[]> {
    return this._conversationDaoService.findConversationsWithoutSynchronizedComments(forUserEmail, conversationIds);
  }

  protected doBeforeSave(forUserEmail: string, conversations: ConversationModel[]): Observable<ConversationModel[]> {
    return of(undefined).pipe(
      mergeMap(() => {
        return this.saveLatestContactsFromConversations(forUserEmail, conversations);
      }),
    );
  }

  private saveLatestContactsFromConversations(
    forUserEmail: string,
    conversations: ConversationModel[],
  ): Observable<ConversationModel[]> {
    // Don't override data until blocking sync have completed
    if (!SynchronizationMiddlewareService.allBlockingPrefetchActionsDone(forUserEmail)) {
      return of(conversations);
    }

    let allContacts = _.map(conversations, (conversation: ConversationModel) => conversation.getAllContacts());
    let uniqContactsWithHighestRevision = ContactBaseModel.getUniqueContactsWithHighestRevision(allContacts.flat());
    let contactIds = _.map(uniqContactsWithHighestRevision, c => c.id);

    return of(undefined).pipe(
      mergeMap(() => this._contactService.findContactsByIds(forUserEmail, contactIds)),
      mergeMap((localContacts: ContactBase[]) => {
        let localContactsByIds = _.keyBy(localContacts, 'id');
        let newContacts = _.filter(
          uniqContactsWithHighestRevision,
          (contact: ContactBase) =>
            _.isEmpty(localContactsByIds[contact.id]) ||
            BaseModel.isRevisionGreaterThan(contact, localContactsByIds[contact.id]),
        );

        return this._contactService.saveUnknownContacts(forUserEmail, ContactBaseModel.createList(newContacts));
      }),
      map(() => conversations),
    );
  }

  moveToTeam(forUserEmail: string, conversation: ConversationModel, group: GroupModel): Observable<CardShared> {
    if (!conversation) {
      return;
    }
    conversation.moveToTeam(group);

    return this.saveAllAndPublish(forUserEmail, [conversation]).pipe(
      mergeMap(() => {
        return this._cardService.moveToTeam(forUserEmail, conversation.cardId, group.id);
      }),
    );
  }

  ////////////////
  // DAO WRAPPERS
  ////////////////
  findByCardId(forUserEmail: string, id: string): Observable<ConversationModel> {
    return this._conversationDaoService.findByCardId(forUserEmail, id);
  }

  findByCardIds(forUserEmail: string, ids: string[]): Observable<ConversationModel[]> {
    return this._conversationDaoService.findByCardIds(forUserEmail, ids);
  }

  findByIds(forUserEmail: string, ids: string[]): Observable<ConversationModel[]> {
    return this._conversationDaoService.findByIds(forUserEmail, ids);
  }

  removeByCardIds(forUserEmail: string, ids: string[]): Observable<any> {
    return this._conversationDaoService.removeByCardIds(forUserEmail, ids);
  }

  removeAll(forUserEmail: string): Observable<any> {
    return this._conversationDaoService.removeAllConversations(forUserEmail);
  }
}
