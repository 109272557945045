import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { SubscriptionState } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CommentChatModel, CommentModel } from 'dta/shared/models-api-loop/comment/comment.model';
import { SharedTagBaseModel, SharedTagReactionModel } from 'dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { ListOfTagsModel, TagLabelModel, TagModel } from 'dta/shared/models-api-loop/tag.model';
import { MarkAllReadView } from 'dta/shared/models/mark-all-read-constants';
import { from, Observable, of, throwError } from 'rxjs';
import { BaseService } from '../base/base.service';
import { TagServiceI } from './tag.service.interface';
import { ActionEnum, SharedTagSystemNames, Tag, TagType, ViewEnum } from '@shared/api/api-loop/models';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { CommentService } from '../comment/comment.service';
import { TagApiService } from '@shared/api/api-loop/services';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { CacheService } from '@dta/backend/services/cache/cache.service';
import { SharedTagService } from '../shared-tags/shared-tags.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { BatchProcessingService } from '@dta/backend/services/batch-processing/batch-processing.service';
import { TagDaoService } from '@shared/database/dao/tag/tag-dao.service';
import { ConversationCollectionParams } from '@dta/shared/models/collection.model';
import { ConversationActionService } from '../conversation-action/conversation-action.service';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';

@Injectable()
export class TagService extends BaseService implements TagServiceI {
  protected readonly exclusiveTags: string[] = [
    TagType.ARCHIVE,
    TagType.SENT,
    TagType.INBOX,
    TagType.DELETED,
    TagType.FOLDER
  ];

  protected readonly exclusiveSharedTags: string[] = [SharedTagSystemNames.ARCHIVE, SharedTagSystemNames.DELETED];

  // Tags that are property of a card and not of a comment
  protected readonly cardTags: string[] = [TagType.FOLLOW, TagType.UNFOLLOW, TagType.MENTION, TagType.MUTED];

  protected readonly exclusiveCardTags: string[] = [TagType.FOLLOW, TagType.UNFOLLOW];

  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _commentService: CommentService,
    protected _tagApiService: TagApiService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _cacheService: CacheService,
    protected _batchProcessingService: BatchProcessingService,
    protected _sharedTagService: SharedTagService,
    protected _tagDaoService: TagDaoService,
    private _conversationActionService: ConversationActionService,
    private _notificationsService: NotificationsService,
    private _conversationService: ConversationService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'TagService';
  }

  ///////////////////////////
  // SAVE AND REMOVE METHODS
  ///////////////////////////
  save(forUserEmail: string, tag: TagModel): Observable<TagModel> {
    return this.saveAll(forUserEmail, [tag]).pipe(map((tags: TagModel[]) => _.first(tags)));
  }

  saveAll(forUserEmail: string, tags: TagModel[]): Observable<TagModel[]> {
    return this._tagDaoService.saveAll(forUserEmail, tags);
  }

  saveAllAndPublish(forUserEmail: string, tags: TagModel[]): Observable<TagModel[]> {
    return this.saveAll(forUserEmail, tags).pipe(
      map((_tags: TagModel[]) => {
        PublisherService.publishEvent(forUserEmail, _tags);
        return _tags;
      })
    );
  }

  removeAll(forUserEmail: string, tags: TagModel[]): Observable<any> {
    return this._tagDaoService.removeAll(forUserEmail, tags);
  }

  syncSaveAndPublishCardTagUpdates(
    forUserEmail: string,
    conversation: ConversationModel
  ): Observable<ConversationModel> {
    return of(undefined).pipe(
      tap(() => {
        this.enqueuePushSynchronization(forUserEmail, ListOfTagsModel.createList([conversation.tags]));
      }),
      mergeMap(() => {
        return this._conversationService.saveAllAndPublish(forUserEmail, [conversation]).pipe(map(() => conversation));
      })
    );
  }

  toggleSubscribeOfCard(
    forUserEmail: string,
    cardId: string,
    ensureStatus?: 'subscribed' | 'unsubscribed'
  ): Observable<ConversationModel> {
    if (!cardId) {
      return throwError('cardId cannot be nil');
    }

    return this._conversationService.findOrFetchByCardId(forUserEmail, cardId).pipe(
      mergeMap((conversation: ConversationModel) => {
        return this.toggleSubscribeForCard(conversation, ensureStatus);
      }),
      mergeMap((conversation: ConversationModel) => {
        return this.syncSaveAndPublishCardTagUpdates(forUserEmail, conversation);
      })
    );
  }

  ensureCardSubscribed(forUserEmail: string, conversation: ConversationModel): Observable<ConversationModel> {
    if (conversation && conversation.calculateSubscriptionState() !== SubscriptionState.SUBSCRIBED) {
      return this.toggleSubscribeOfCard(forUserEmail, conversation.cardId, 'subscribed');
    }

    return of(conversation);
  }

  private muteCard(conversation: ConversationModel): Observable<ConversationModel> {
    conversation = this.modifyTagsOnCard(conversation, TagModel.buildSystemTag(TagType.MUTED), false);
    conversation = this.modifyTagsOnCard(conversation, TagModel.buildSystemTag(TagType.FOLLOW), false);
    conversation.removeShowInView(ViewEnum.LOOP_INBOX);

    return of(conversation);
  }

  private subscribeToCard(conversation: ConversationModel): Observable<ConversationModel> {
    conversation = this.modifyTagsOnCard(conversation, TagModel.buildSystemTag(TagType.MUTED), true);
    conversation = this.modifyTagsOnCard(conversation, TagModel.buildSystemTag(TagType.FOLLOW), false);

    return of(conversation);
  }

  private toggleSubscribeForCard(
    conversation: ConversationModel,
    ensureStatus?: 'subscribed' | 'unsubscribed'
  ): Observable<ConversationModel> {
    if ((!ensureStatus && conversation.isSubscribed) || ensureStatus === 'unsubscribed') {
      return this.muteCard(conversation);
    }

    return this.subscribeToCard(conversation);
  }

  /////////
  // OTHER
  /////////
  enqueuePushSynchronization(forUserEmail: string, listOfTags: ListOfTagsModel[]) {
    listOfTags = listOfTags.filter(model => {
      if (_.isEmpty(model.parent) || _.isEmpty(model.parent.id)) {
        Logger.warn('TagService: could not add ListOfTags without parent to queue', model, forUserEmail);
        return false;
      } else {
        return true;
      }
    });
    super.enqueuePushSynchronization(forUserEmail, listOfTags);
  }

  markAllCardsAsRead(
    forUserEmail: string,
    view: MarkAllReadView,
    params: ConversationCollectionParams
  ): Observable<any> {
    return this._conversationActionService.markAllInViewAsRead(forUserEmail, params).pipe(
      tap(() => {
        this._notificationsService.setInAppNotification(forUserEmail, { type: NotificationEventType.MarkAllAsRead });
      })
    );
  }

  private getMainView(view: MarkAllReadView): ViewEnum {
    switch (view) {
      case MarkAllReadView.MyLoopInbox:
      case MarkAllReadView.Chats:
        return ViewEnum.LOOP_INBOX;
      case MarkAllReadView.PersonalInbox:
      case MarkAllReadView.Folder:
        return ViewEnum.PERSONAL_INBOX;
      case MarkAllReadView.Channel:
        return ViewEnum.CHANNEL;
      case MarkAllReadView.AllSharedInboxes:
      default:
        throw new Error(`Provided view (${view}) not supporting conversation actions`);
    }
  }

  private modifyTagsOnCard(
    conversation: ConversationModel,
    tag: TagModel,
    shouldRemoveTag: boolean
  ): ConversationModel {
    let cardTags = conversation.getTags();

    if (shouldRemoveTag) {
      // Remove tag
      cardTags = _.filter(cardTags, (_tag: Tag) => _tag.id !== tag.id);
    } else {
      // Remove exclusive tag
      cardTags = _.filter(cardTags, (_tag: Tag) => _tag.id === tag.id || !this.exclusiveCardTags.includes(_tag.id));

      // Add new tag
      cardTags.push(tag);
    }

    // Remove duplicates
    cardTags = _.uniqBy(cardTags, _tag => _tag.id);

    // Set new array of tags
    conversation.setTags(cardTags);

    return conversation;
  }

  addSharedTagToComment(
    forUserEmail: string,
    comments: CommentChatModel[],
    sharedTag: SharedTagBaseModel
  ): Observable<CommentModel[]> {
    let parentCardIdsToUpdate = [];
    return from(comments).pipe(
      tap((comment: CommentChatModel) => {
        sharedTag.$type === SharedTagReactionModel.type
          ? comment.addOrRemoveReactionSharedTag(<SharedTagReactionModel>sharedTag)
          : comment.setSharedTag(sharedTag);

        parentCardIdsToUpdate.push(comment.parent.id);
      }),
      toArray(),
      mergeMap((_comments: CommentChatModel[]) => {
        return this._commentService.saveAllAndPublish(forUserEmail, _comments);
      }),
      mergeMap((_comments: CommentModel[]) => {
        let listOfSharedTags = _comments.map(comment => comment.sharedTags);
        this._sharedTagService.enqueueSharedTagsSynchronization(forUserEmail, listOfSharedTags);
        return of(_comments as CommentChatModel[]);
      }),
      mergeMap((_comments: CommentChatModel[]) => {
        return this.findAndUpdateParentCards(forUserEmail, parentCardIdsToUpdate, _comments);
      })
    );
  }

  private findAndUpdateParentCards(
    forUserEmail: string,
    cardIds: string[],
    comments: CommentChatModel[]
  ): Observable<CommentModel[]> {
    if (cardIds.length === 0) {
      return of(comments);
    }

    return this._conversationService.findByIds(forUserEmail, cardIds).pipe(
      mergeMap((_conversations: ConversationModel[]) => {
        return this._conversationService.saveAllAndPublish(forUserEmail, _conversations);
      }),
      map(() => {
        return comments;
      })
    );
  }

  /////////////////
  // DAO WRAPPERS
  /////////////////
  findAllLabelTags(forUserEmail: string): Observable<TagLabelModel[]> {
    return this._tagDaoService.findAllLabelTags(forUserEmail);
  }

  findByIds(forUserEmail: string, tagIds: string[]): Observable<TagModel[]> {
    return this._tagDaoService.findByIds(forUserEmail, tagIds);
  }

  findTagById(forUserEmail: string, tagId: string): Observable<TagModel> {
    return this._tagDaoService.findById(forUserEmail, tagId);
  }

  protected findLocalExclusiveTag(forUserEmail: string, tags: Tag[]): Observable<TagModel> {
    return this._tagDaoService.findExclusiveTag(forUserEmail, tags);
  }
}
