export interface HttpResponseEvent {
  type: HttpResponseEventType;
  data?: any;
}

export enum HttpResponseEventType {
  offline = 'offline',
  success = 'success',
  unauthorized = 'unauthorized',
  error = 'error',
  deprecated = 'deprecated',
  timeout = 'timeout',
}
