import * as _ from 'lodash';
import { from, Observable, of, throwError } from 'rxjs';
import { concatMap, map, mergeMap, takeWhile, tap, toArray } from 'rxjs/operators';
import { DatabaseService } from '@shared/database/database.service';
import { LoopIDBDatabase } from './indexedDB/database';
import { CacheIndex, TransactionMode } from './indexedDB/enums';
import { CollectionNameWeb, WEB_DATABASE_SCHEMA } from './database-schema';
import { LoopIDBDatabaseOptions, LoopIDBDatabaseSchema, LoopIDBStoreSchema, StoreTypes } from './indexedDB/interfaces';
import { LoopIDBStoreCacheItem } from './indexedDB/store-cache-item';
import { LoopIDBIndex } from './indexedDB';
import { ResourceBase } from '@shared/api/api-loop/models';

export class DatabaseServiceWeb extends DatabaseService {
  private db$: Observable<LoopIDBDatabase>;

  constructor(private databaseName: string) {
    super();

    this.createDatabase();
  }

  private createDatabase() {
    this.db$ = LoopIDBDatabase.create({
      name: this.databaseName,
      schema: WEB_DATABASE_SCHEMA,
    } as LoopIDBDatabaseOptions);
  }

  private static filterOutUndefinedList(models: ResourceBase[]): ResourceBase[] {
    return _.filter(models, m => !_.isUndefined(m));
  }

  private static getModels(models: ResourceBase[] | LoopIDBStoreCacheItem[]): ResourceBase[] {
    if (_.isEmpty(models)) {
      return [];
    }

    return models[0].$type === LoopIDBStoreCacheItem.type
      ? LoopIDBStoreCacheItem.getModels(models as LoopIDBStoreCacheItem[])
      : (models as ResourceBase[]);
  }

  dropDatabase(): Observable<any> {
    return this.db$.pipe(mergeMap(db => db.drop$()));
  }

  static dropDatabaseByName(databaseName: string): Observable<any> {
    return LoopIDBDatabase.dropAny$(databaseName);
  }

  saveThrottled(): Observable<any> {
    // WEB db is persisted on insert and does not need extra save calls
    return of(undefined);
  }

  getDatabaseReport(): Observable<any> {
    throw new Error('Not supported on web');
  }

  ////////////////
  // DAO METHODS
  ////////////////
  findAllByIndex(indexName: string, indexValue: string, collection: CollectionNameWeb): Observable<ResourceBase[]> {
    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READONLY)),
      map(transaction => transaction.objectStore(collection).index(indexName)),
      concatMap(index => index.getAll$(indexValue)),
      map(DatabaseServiceWeb.filterOutUndefinedList),
      map(DatabaseServiceWeb.getModels),
    );
  }

  findAllByIndexBounded(
    indexName: string,
    indexBoundLowerValue: string | number,
    indexBoundUpperValue: string | number,
    collection: CollectionNameWeb,
  ): Observable<ResourceBase[]> {
    if (!indexBoundLowerValue && !indexBoundUpperValue) {
      return throwError("Upper and lower bounds can't both be null");
    }
    let keyRange: IDBKeyRange;

    if (!indexBoundLowerValue) {
      keyRange = IDBKeyRange.upperBound(indexBoundUpperValue);
    } else if (!indexBoundUpperValue) {
      keyRange = IDBKeyRange.lowerBound(indexBoundLowerValue);
    } else {
      keyRange = IDBKeyRange.bound(indexBoundLowerValue, indexBoundUpperValue);
    }

    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READONLY)),
      map(transaction => transaction.objectStore(collection).index(indexName)),
      concatMap(index => index.getAll$(keyRange)),
      map(DatabaseServiceWeb.filterOutUndefinedList),
      map(DatabaseServiceWeb.getModels),
    );
  }

  findByIndex(indexName: string, indexValues: string[], collection: CollectionNameWeb): Observable<ResourceBase[]> {
    if (_.isEmpty(indexValues)) {
      return of([]);
    }

    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READONLY)),
      map(transaction => transaction.objectStore(collection).index(indexName)),
      concatMap(index =>
        from(indexValues).pipe(
          mergeMap(value => index.getAll$(value)),
          mergeMap(result => from(result.flat())),
          toArray(),
        ),
      ),
      map(DatabaseServiceWeb.filterOutUndefinedList),
      map(DatabaseServiceWeb.getModels),
    );
  }

  findById(id: string, collection: CollectionNameWeb): Observable<ResourceBase> {
    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READONLY)),
      concatMap(transaction => transaction.objectStore(collection).get$(id)),
      mergeMap((model: ResourceBase) => {
        if (_.isEmpty(model)) {
          return throwError({ message: `Object by id: ${id} not found in DB`, status: 404 });
        }

        return of(model);
      }),
      map((model: ResourceBase) => DatabaseServiceWeb.getModels([model])),
      map(_.first),
    );
  }

  findByIds(ids: string[], collection: CollectionNameWeb): Observable<ResourceBase[]> {
    if (_.isEmpty(ids)) {
      return of([]);
    }

    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READONLY)),
      map(transaction => transaction.objectStore(collection)),
      concatMap(objectStore =>
        from(ids).pipe(
          mergeMap(id => objectStore.get$(id)),
          toArray(),
        ),
      ),
      map(DatabaseServiceWeb.filterOutUndefinedList),
      map(DatabaseServiceWeb.getModels),
    );
  }

  getAll(collection: CollectionNameWeb): Observable<any[]> {
    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READONLY)),
      map(transaction => transaction.objectStore(collection)),
      concatMap(objectStore => objectStore.getAll$()),
      map(DatabaseServiceWeb.getModels),
    );
  }

  //////////
  // INSERT
  //////////
  insertAll<T extends ResourceBase>(models: T[], collection: CollectionNameWeb): Observable<T[]> {
    if (_.isEmpty(models)) {
      return of([]);
    }

    return this.db$.pipe(
      map(db => db.schema),
      mergeMap((schema: LoopIDBDatabaseSchema) => {
        let collectionSchema = _.find(schema.stores, (store: LoopIDBStoreSchema) => store.name === collection);

        return collectionSchema?.options?.storageType === StoreTypes.CACHE
          ? this._insertAllCache(models, collection, collectionSchema.options.maxCacheSize)
          : this._insertAll(models, collection, false, collectionSchema.options.alternativePrimaryKey);
      }),
    );
  }

  private _insertAll<T extends ResourceBase>(
    models: T[],
    collection: CollectionNameWeb,
    insertAsCacheObject?: boolean,
    alternativePrimaryKey?: string,
  ): Observable<T[]> {
    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READWRITE)),
      map(transaction => transaction.objectStore(collection)),
      concatMap(objectStore =>
        from(models).pipe(
          mergeMap(model => {
            let key = this.getModelInsertKey(model, alternativePrimaryKey);

            if (!key) {
              return of(undefined);
            }

            return objectStore.put$(insertAsCacheObject ? LoopIDBStoreCacheItem.create(model) : model, key);
          }),
          toArray(),
          map(() => models),
        ),
      ),
    );
  }

  private getModelInsertKey<T extends ResourceBase>(model: T, alternativePrimaryKey?: string): string {
    return model.id || model[alternativePrimaryKey] || undefined;
  }

  private _insertAllCache<T extends ResourceBase>(
    models: T[],
    collection: CollectionNameWeb,
    maxCacheSize: number,
  ): Observable<T[]> {
    return this.db$.pipe(
      /**
       * Open by index
       */
      concatMap(db => db.transaction$(collection, TransactionMode.READWRITE)),
      map(transaction => transaction.objectStore(collection).index(CacheIndex)),
      /**
       * Get keys from items to be removed
       */
      concatMap((index: LoopIDBIndex) => {
        return index.count$().pipe(
          mergeMap((countOfItems: number) => {
            let cleanupSpaceSize = Math.min(models.length, maxCacheSize);
            if (maxCacheSize - countOfItems >= cleanupSpaceSize) {
              return of([]);
            }

            let keysToRemove: string[] = [];
            return index
              .openKeyCursor$(null, 'next') // <- should be null
              .pipe(
                takeWhile(cursor => cleanupSpaceSize > keysToRemove.length && !!cursor),
                tap(cursor => keysToRemove.push(cursor.primaryKey as string)),
                tap(cursor => cursor.continue()),
                toArray(),
                map(() => keysToRemove),
              );
          }),
        );
      }),
      /**
       * Remove by keys
       */
      mergeMap((keysToRemove: string[]) => this.removeByIds(keysToRemove, collection)),
      /**
       * Insert up to a limit count of objects
       */
      mergeMap(() => this._insertAll(models.slice(0, maxCacheSize), collection, true)),
    );
  }

  /////////
  // COUNT
  /////////
  countAll(collection: CollectionNameWeb) {
    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READONLY)),
      map(transaction => transaction.objectStore(collection)),
      concatMap(objectStore => objectStore.count$()),
    );
  }

  //////////
  // REMOVE
  //////////
  removeAll(collection: CollectionNameWeb): Observable<any> {
    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READWRITE)),
      map(transaction => transaction.objectStore(collection)),
      concatMap(objectStore => objectStore.getAllKeys$()),
      concatMap((keys: string[]) => this.removeByIds(keys, collection)),
    );
  }

  removeAllByIndex(indexName: string, indexValue: string, collection: CollectionNameWeb): Observable<any> {
    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READONLY)),
      map(transaction => transaction.objectStore(collection).index(indexName)),
      concatMap(index => index.getAllKeys$(indexValue)),
      concatMap((keys: string[]) => this.removeByIds(keys, collection)),
    );
  }

  removeById(id: string, collection: CollectionNameWeb): Observable<any> {
    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READWRITE)),
      concatMap(transaction => transaction.objectStore(collection).delete$(id)),
    );
  }

  removeByIds(ids: string[], collection: CollectionNameWeb): Observable<any[]> {
    if (_.isEmpty(ids)) {
      return of([]);
    }

    return this.db$.pipe(
      concatMap(db => db.transaction$(collection, TransactionMode.READWRITE)),
      map(transaction => transaction.objectStore(collection)),
      concatMap(objectStore =>
        from(ids).pipe(
          mergeMap(id => objectStore.delete$(id)),
          toArray(),
        ),
      ),
    );
  }
}
