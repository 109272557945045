import { inject, Pipe, PipeTransform } from '@angular/core';
import { CardAppointmentModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ResponseTypeModel } from '@shared/api/api-loop/models/response-type-model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

@Pipe({
  name: 'isOrganizer',
})
export class IsOrganizerPipe implements PipeTransform {
  transform(appointment: CardAppointmentModel | null): boolean {
    if (!appointment) {
      return false;
    }

    const organizer = (appointment.attendees?.resources || []).find(at => at.status === ResponseTypeModel.ORGANIZER);
    return organizer && organizer.id === appointment.author.id;
  }
}
