import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { LoaderNewModule } from '@dta/ui/components/common/loader-new/loader-new.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { DropdownModule } from '@shared/ui/dropdown/dropdown.module';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, LoaderNewModule, IconModule, DropdownModule],
  exports: [ButtonComponent]
})
export class ButtonModule {}
