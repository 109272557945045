<ng-container *ngIf="file | hasPreview; else loadThumbnailDataTpl">
  <ng-container *ngIf="file | asPureArgumentFor: populateFileWithUrlLinkFn$ | async; let fileWithUrlLink">
    <div>
      <img
        *ngIf="fileWithUrlLink | isImage"
        imgRotate
        class="icon file loop-file w-100"
        [src]="fileWithUrlLink.urlLink"
        alt="page 0"
      />
    </div>

    <pdfjs *ngIf="fileWithUrlLink | isPdf" [pdfUri]="fileWithUrlLink.urlLink" class="w-100 h-100"></pdfjs>

    <video-component
      *ngIf="fileWithUrlLink | isVideo"
      class="loop-file w-100"
      [videoUrl]="fileWithUrlLink.urlLink"
      [thumbnailPath]="fileWithUrlLink.urlLink"
      [fileDialog]="true"
    >
    </video-component>
  </ng-container>
</ng-container>

<ng-template #loadThumbnailDataTpl>
  <ng-container *ngIf="file.id | asPureArgumentFor: getFileMetadataFn$ | async; let fileMetadata">
    <ng-container *ngIf="fileMetadata.thumbnailInfo.supported; else unableToLoadPreviewTpl">
      <pdfjs class="w-100 h-100" [pdfUri]="fileMetadata.id | asPureArgumentFor: getThumbnailFn$ | async"> </pdfjs>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #unableToLoadPreviewTpl>
  <span>Sorry, we cannot generate a preview</span>
  <br />
  <span>for this kind of file.</span>
</ng-template>
