/* tslint:disable */

/**
 * Sort direction enum
 */
export type SortOrder = 'Descending' | 'Ascending';

export module SortOrder {
  export const DESCENDING: SortOrder = 'Descending';
  export const ASCENDING: SortOrder = 'Ascending';

  export function values(): SortOrder[] {
    return [DESCENDING, ASCENDING];
  }
}
