/* tslint:disable */

export type ErrorCode =
  | 'Other'
  | 'AttachmentTooLarge'
  | 'InvalidSinceId'
  | 'OldExchangeServer'
  | 'AutodiscoveryError'
  | 'InvalidPassword'
  | 'InvalidToken'
  | 'UserNotRegistered'
  | 'ForbiddenFileType'
  | 'AuthenticationProviderUriMismatch'
  | 'TokenExpired'
  | 'OtpSecretNotSet'
  | 'OtpCodeNotValid'
  | 'TokenScopeInvalid'
  | 'ThumbnailNotAvailableYet'
  | 'PdfNotAvailableYet'
  | 'HtmlNotAvailableYet'
  | 'ActionNotPermitted'
  | 'Deprecated'
  | 'AliasUsed'
  | 'InvalidServer'
  | 'RemoteServerTemporaryUnavailable'
  | 'InvalidCertificate'
  | 'ThumbnailNotSupported'
  | 'SSLMismatch'
  | 'ResourceNotAvailableYet'
  | 'UserLoginBlocked'
  | 'UserSyncBlocked'
  | 'ServiceTempUnavailable'
  | 'UserLoginBlockedPurgeInProgress'
  | 'GraphApiDisabled'
  | 'InvalidPasswordOrUsername'
  | 'UsernameEmailMismatch'
  | 'MissingFields'
  | 'EmailNotVerified'
  | 'ClientAppVersionNotSupported';

export module ErrorCode {
  export const OTHER: ErrorCode = 'Other';
  export const ATTACHMENT_TOO_LARGE: ErrorCode = 'AttachmentTooLarge';
  export const INVALID_SINCE_ID: ErrorCode = 'InvalidSinceId';
  export const OLD_EXCHANGE_SERVER: ErrorCode = 'OldExchangeServer';
  export const AUTODISCOVERY_ERROR: ErrorCode = 'AutodiscoveryError';
  export const INVALID_PASSWORD: ErrorCode = 'InvalidPassword';
  export const INVALID_TOKEN: ErrorCode = 'InvalidToken';
  export const USER_NOT_REGISTERED: ErrorCode = 'UserNotRegistered';
  export const FORBIDDEN_FILE_TYPE: ErrorCode = 'ForbiddenFileType';
  export const AUTHENTICATION_PROVIDER_URI_MISMATCH: ErrorCode = 'AuthenticationProviderUriMismatch';
  export const TOKEN_EXPIRED: ErrorCode = 'TokenExpired';
  export const OTP_SECRET_NOT_SET: ErrorCode = 'OtpSecretNotSet';
  export const OTP_CODE_NOT_VALID: ErrorCode = 'OtpCodeNotValid';
  export const TOKEN_SCOPE_INVALID: ErrorCode = 'TokenScopeInvalid';
  export const THUMBNAIL_NOT_AVAILABLE_YET: ErrorCode = 'ThumbnailNotAvailableYet';
  export const PDF_NOT_AVAILABLE_YET: ErrorCode = 'PdfNotAvailableYet';
  export const HTML_NOT_AVAILABLE_YET: ErrorCode = 'HtmlNotAvailableYet';
  export const ACTION_NOT_PERMITTED: ErrorCode = 'ActionNotPermitted';
  export const DEPRECATED: ErrorCode = 'Deprecated';
  export const ALIAS_USED: ErrorCode = 'AliasUsed';
  export const INVALID_SERVER: ErrorCode = 'InvalidServer';
  export const REMOTE_SERVER_TEMPORARY_UNAVAILABLE: ErrorCode = 'RemoteServerTemporaryUnavailable';
  export const INVALID_CERTIFICATE: ErrorCode = 'InvalidCertificate';
  export const THUMBNAIL_NOT_SUPPORTED: ErrorCode = 'ThumbnailNotSupported';
  export const SSLMISMATCH: ErrorCode = 'SSLMismatch';
  export const RESOURCE_NOT_AVAILABLE_YET: ErrorCode = 'ResourceNotAvailableYet';
  export const USER_LOGIN_BLOCKED: ErrorCode = 'UserLoginBlocked';
  export const USER_SYNC_BLOCKED: ErrorCode = 'UserSyncBlocked';
  export const SERVICE_TEMP_UNAVAILABLE: ErrorCode = 'ServiceTempUnavailable';
  export const USER_LOGIN_BLOCKED_PURGE_IN_PROGRESS: ErrorCode = 'UserLoginBlockedPurgeInProgress';
  export const GRAPH_API_DISABLED: ErrorCode = 'GraphApiDisabled';
  export const INVALID_PASSWORD_OR_USERNAME: ErrorCode = 'InvalidPasswordOrUsername';
  export const USERNAME_EMAIL_MISMATCH: ErrorCode = 'UsernameEmailMismatch';
  export const MISSING_FIELDS: ErrorCode = 'MissingFields';
  export const EMAIL_NOT_VERIFIED: ErrorCode = 'EmailNotVerified';
  export const CLIENT_APP_VERSION_NOT_SUPPORTED: ErrorCode = 'ClientAppVersionNotSupported';

  export function values(): ErrorCode[] {
    return [
      OTHER,
      ATTACHMENT_TOO_LARGE,
      INVALID_SINCE_ID,
      OLD_EXCHANGE_SERVER,
      AUTODISCOVERY_ERROR,
      INVALID_PASSWORD,
      INVALID_TOKEN,
      USER_NOT_REGISTERED,
      FORBIDDEN_FILE_TYPE,
      AUTHENTICATION_PROVIDER_URI_MISMATCH,
      TOKEN_EXPIRED,
      OTP_SECRET_NOT_SET,
      OTP_CODE_NOT_VALID,
      TOKEN_SCOPE_INVALID,
      THUMBNAIL_NOT_AVAILABLE_YET,
      PDF_NOT_AVAILABLE_YET,
      HTML_NOT_AVAILABLE_YET,
      ACTION_NOT_PERMITTED,
      DEPRECATED,
      ALIAS_USED,
      INVALID_SERVER,
      REMOTE_SERVER_TEMPORARY_UNAVAILABLE,
      INVALID_CERTIFICATE,
      THUMBNAIL_NOT_SUPPORTED,
      SSLMISMATCH,
      RESOURCE_NOT_AVAILABLE_YET,
      USER_LOGIN_BLOCKED,
      USER_SYNC_BLOCKED,
      SERVICE_TEMP_UNAVAILABLE,
      USER_LOGIN_BLOCKED_PURGE_IN_PROGRESS,
      GRAPH_API_DISABLED,
      INVALID_PASSWORD_OR_USERNAME,
      USERNAME_EMAIL_MISMATCH,
      MISSING_FIELDS,
      EMAIL_NOT_VERIFIED,
      CLIENT_APP_VERSION_NOT_SUPPORTED
    ];
  }
}
