import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownTriggerDirective } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.directive';
import { CustomDropdownService } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.service';

@NgModule({
  declarations: [DropdownTriggerDirective],
  imports: [CommonModule],
  exports: [DropdownTriggerDirective],
  providers: [CustomDropdownService],
})
export class CustomDropdownModule {}
