import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Injector,
  Input,
  Output
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MentionComponent } from '@shared/modules/contacts/components/mention/mention.component';
import { getTextBeforeCaret } from '@shared/modules/detail-view/common/helpers/get-text-before-caret';
import { getCaretGlobalPosition } from '@shared/modules/contacts/components/mention/internal/get-caret-global-position';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { EnumObjectValue } from '@shared/utils/common/types';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';

export const MentionPlacement = {
  top: 'top',
  bottom: 'bottom'
} as const;

const MENTION_POSITIONS: Record<EnumObjectValue<typeof MentionPlacement>, ConnectedPosition> = {
  [MentionPlacement.top]: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  [MentionPlacement.bottom]: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  }
} as const;

@UntilDestroy()
@Directive({
  selector: '[loopMention]'
})
export class MentionDirective {
  private readonly overlay: Overlay = inject(Overlay);
  private readonly overlayPositionBuilder: OverlayPositionBuilder = inject(OverlayPositionBuilder);
  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly injector: Injector = inject(Injector);

  isMenuOpened: boolean = false;
  componentRef?: ComponentRef<MentionComponent>;
  private overlayRef?: OverlayRef;
  private contactClickSubscription: Subscription;

  private customElementRef?: Element | ElementRef;

  @Input() isLoopMentionDisabled?: boolean;
  @Input() loopMentionPreferredPosition?: EnumObjectValue<typeof MentionPlacement>;
  @Input() overrideMentionOptions?: ContactApiService.Contact_GetListParams;
  @Output() openMention: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeMention: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('input')
  onInput(): void {
    this.canOpenMentionComponent();
  }

  @HostListener('focus')
  @HostListener('click')
  onFocus(): void {
    setTimeout(() => {
      this.canOpenMentionComponent();
    }, 50);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'Enter' && this.isMenuOpened) {
      event.preventDefault();
      event.stopPropagation();
      this.componentRef?.instance?.handleCurrentContactClick();
    }
  }

  @Output() loopMentionContactClick: EventEmitter<ContactModel> = new EventEmitter<ContactModel>();

  setCustomElementRef(elementRef?: Element | ElementRef): void {
    this.customElementRef = elementRef;
  }

  canOpenMentionComponent(): void {
    const textBeforeCaret = getTextBeforeCaret();
    const hasAtSign = textBeforeCaret?.includes('@') && textBeforeCaret?.match('^[a-zA-Z@]+$');
    if (!hasAtSign) {
      this.closeMentionComponent();
    } else if (hasAtSign && !this.isMenuOpened) {
      this.openMentionComponent();
    } else if (hasAtSign && this.isMenuOpened) {
      this.moveMentionComponent();
    }

    if (this.componentRef) {
      this.componentRef.instance.query = textBeforeCaret?.split('@')?.[1];
      this.componentRef.instance.overrideMentionOptions = this.overrideMentionOptions;
    }
  }

  private moveMentionComponent(): void {
    const caretPosition = getCaretGlobalPosition();
    const elementRefOrElement = this.customElementRef ?? this.elementRef;
    const nativeElement =
      elementRefOrElement instanceof ElementRef ? elementRefOrElement.nativeElement : elementRefOrElement;

    const preferredPosition = MENTION_POSITIONS[this.loopMentionPreferredPosition ?? MentionPlacement.top];
    const positions = [preferredPosition, ...Object.values(MENTION_POSITIONS)].map(position => {
      return {
        ...position,
        offsetX:
          caretPosition.left -
          (nativeElement.getBoundingClientRect().left ?? nativeElement.getBoundingClientRect().left)
      };
    });

    this.overlayRef.updatePositionStrategy(
      this.overlayPositionBuilder.flexibleConnectedTo(elementRefOrElement).withPositions(positions)
    );
  }

  private openMentionComponent(): void {
    if (this.isLoopMentionDisabled) {
      return;
    }

    this.isMenuOpened = true;
    this.overlayRef = this.overlay.create({
      // TODO create Input to directive where we want to display our overlay and add it to positions,
      panelClass: 'loop-mention-overlay',
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });

    const componentPortal = new ComponentPortal(MentionComponent, null, this.injector);
    this.componentRef = this.overlayRef.attach(componentPortal);
    this.openMention.next(true);
    this.subscribeToContactClick();
    this.moveMentionComponent();

    const backdropClickToCloseSubscription = this.overlayRef.backdropClick().subscribe(() => {
      this.closeMentionComponent();
    });
    this.componentRef.onDestroy(() => {
      backdropClickToCloseSubscription.unsubscribe();
    });
  }

  private subscribeToContactClick(): void {
    this.contactClickSubscription?.unsubscribe();
    this.contactClickSubscription = this.componentRef?.instance?.contactClick
      .pipe(untilDestroyed(this), take(1))
      .subscribe(contact => {
        this.loopMentionContactClick.next(contact);
        this.closeMentionComponent();
      });
  }

  private closeMentionComponent(): void {
    this.componentRef = undefined;
    this.isMenuOpened = false;
    this.overlayRef?.dispose();
    this.contactClickSubscription?.unsubscribe();
    this.closeMention.next(false);
  }
}
