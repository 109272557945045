/* tslint:disable */

/**
 * Subscription Role types
 */
export type SubscriptionMemberRole = 'Owner' | 'Admin' | 'Member';

export module SubscriptionMemberRole {
  export const OWNER: SubscriptionMemberRole = 'Owner';
  export const ADMIN: SubscriptionMemberRole = 'Admin';
  export const MEMBER: SubscriptionMemberRole = 'Member';

  export function values(): SubscriptionMemberRole[] {
    return [OWNER, ADMIN, MEMBER];
  }
}
