import { MENU_STACK, MenuStack } from '@angular/cdk/menu';
import { Directive, Input } from '@angular/core';
import { DropdownTrigger } from '../enumerators/dropdown-trigger';
import { DropdownItem } from '../interfaces/dropdown-item';
import { DropdownBase } from './dropdown-base.directive';

@Directive({
  selector: '[loopClickDropdown]',
  providers: [{ provide: MENU_STACK, useClass: MenuStack }]
})
export class ClickDropdownDirective extends DropdownBase {
  protected override getTrigger(): DropdownTrigger {
    return DropdownTrigger.click;
  }

  @Input() set loopClickDropdown(items: readonly DropdownItem[]) {
    this.setItems(items);
  }
}
