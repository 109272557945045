<div
  class="select-wrapper {{ extraClass }}"
  tabindex="-1"
  [class.icon-for-toggle]="iconForToggle"
  [class.open]="open"
  [class.capitalize-first-letter]="capitalize"
  [class.no-transitions]="noTransitions"
>
  <!-- Open dropdown button -->
  <div
    class="select"
    id="select-dropdown-toggle"
    [class.has-toggle-content]="hasToggleContent"
    (click)="toggleDropdown($event)"
  >
    <div #toggleContentRef>
      <ng-content select="dropdown-toggle"></ng-content>
    </div>

    <div *ngIf="hasToggleContent === false">
      <ng-template *ngIf="viewOptions.hasSelection" ngFor let-option [ngForOf]="viewOptions.options">
        <span *ngIf="option.option === viewOptions.selectedOption"> {{ option.displayName || option.option }} </span>
      </ng-template>

      <svg-comp [src]="viewOptions.hasSelection ? dropdownToggleSvg : moreSvg"></svg-comp>
    </div>
  </div>

  <!-- Options -->
  <div
    class="options"
    [class.open-top]="openTop"
    [class.open-bottom-left]="openBottomLeft"
    [class.open-bottom-right]="openBottomRight"
    #optionsWrapper
  >
    <!-- Back button -->
    <div *ngIf="!subsectionStack.isEmpty" class="subsection-back" (click)="goToPreviousSection()">Back</div>

    <!-- Title -->
    <div class="title" *ngIf="viewOptions.title">{{ viewOptions.title }}</div>

    <ng-template ngFor let-option [ngForOf]="viewOptions.options" let-i="index">
      <div
        class="option {{option.additionalClass}}"
        *ngIf="!option.hidden"
        [id]="getOptionId(option.option)"
        [class.option-focused]="selectionIndex === i"
        [class.space-between]="option.shortcut"
        [class.with-icon]="option.svgIcon"
        [class.disabled]="option.disabled"
        [class.has-subsection]="option.subsection"
        (mouseenter)="setSelectionIndex(i, true)"
        (click)="onSelect($event, i)"
      >
        <span *ngIf="option.colorCircle" class="circle-color" [style.background-color]="option.colorCircle"> </span>

        <!-- Icon -->
        <svg-comp *ngIf="option.svgIcon" class="option-icon" [src]="option.svgIcon"> </svg-comp>

        <!-- Radio button -->
        <div
          *ngIf="viewOptions.hasSelection && viewOptions.selectionTypeRadio"
          class="radio-button"
          [class.selected]="viewOptions.selectedOption === option.option"
        ></div>

        <!-- Option text -->
        <span>
          <div [class.has-subtitle]="option.subtitle">{{ option.displayName || option.option }}</div>
          <div class="subtitle" *ngIf="option.subtitle">{{ option.subtitle }}</div>
        </span>

        <div class="shortcut" *ngIf="option.shortcut">{{ option.shortcut }}</div>

        <!-- Check mark -->
        <svg-comp
          *ngIf="!viewOptions.selectionTypeRadio && viewOptions.hasSelection && viewOptions.selectedOption === option.option"
          class="check-mark"
          [src]="checkMarkSvg"
        >
        </svg-comp>

        <!-- Subsection indicator -->
        <svg-comp *ngIf="option.subsection" class="subsection-mark" [src]="dropdownToggleSvg"> </svg-comp>
      </div>

      <div *ngIf="option.dividerAfter" class="divider"></div>
    </ng-template>

    <!-- Subtitle -->
    <div class="options-subtitle" *ngIf="viewOptions.subtitle">{{ viewOptions.subtitle }}</div>
  </div>
</div>
