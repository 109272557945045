import { Injectable } from '@angular/core';
import { SmartCommandsApiService } from '@shared/modules/smart-commands/data-access/smart-commands-data-access/smart-commands-api.service';
import { SmartCommandModuleName } from '@shared/modules/smart-commands/common/constants/smart-command-module-name';
import { EnumObjectValue } from '@shared/utils/common/types';
import { CommentDraftHandleTimeResponse } from '@shared/modules/smart-commands/common/handle-time/interfaces/handle-time-responses';
import { HandleTimeMethod } from '@shared/modules/smart-commands/common/handle-time/constants/handle-time-method';
import { CommentDraftHandleTimeActionPayload } from '@shared/modules/smart-commands/common/handle-time/interfaces/handle-time-payload';
import { HandleTimeDataAccessModule } from '@shared/modules/comments/data-access/handle-time-data-access/handle-time-data-access.module';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: HandleTimeDataAccessModule
})
export class HandleTimeSmartCommandsApiService extends SmartCommandsApiService {
  protected moduleName: EnumObjectValue<typeof SmartCommandModuleName> = SmartCommandModuleName.handle_time;

  sendHandleTimeStatus$(
    method: Exclude<EnumObjectValue<typeof HandleTimeMethod>, typeof HandleTimeMethod.get_comment_draft_handle_time>,
    payload: CommentDraftHandleTimeActionPayload
  ): Observable<CommentDraftHandleTimeResponse> {
    return this.sendSmartCommandRequest({
      method: method,
      payload: payload
    });
  }

  getCommentDraftHandleTime$(
    commentDraftId: string,
    conversationId: string
  ): Observable<CommentDraftHandleTimeResponse> {
    return this.sendSmartCommandRequest({
      method: HandleTimeMethod.get_comment_draft_handle_time,
      payload: {
        comment_draft_id: commentDraftId,
        conversation_id: conversationId
      }
    });
  }
}
