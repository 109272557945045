import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { GifPickerComponent } from '@dta/ui/components/common/gif-picker/gif-picker.component';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { FormsModule } from '@angular/forms';
import { LoaderModule } from '@dta/ui/components/common/loader/loader.module';

@NgModule({
  declarations: [GifPickerComponent],
  imports: [CommonModule, IconModule, GraphicsModule, FormsModule, LoaderModule],
  exports: [GifPickerComponent],
})
export class GifPickerModule {}
