export interface NotificationEvent {
  type: NotificationEventType;
  value?: any;
  msg?: string;
  icon?: string;
  loopId?: string;
}

export interface NotificationEventEmailSending extends NotificationEvent {
  forUserEmail: string;
}

export interface NotificationEventProgress extends NotificationEvent {
  progressState: number;
  progressGoal: number;
}

export enum NotificationEventType {
  Remove = 'Remove',

  // ACCOUNT
  AccountSyncInProgress = 'AccountSyncInProgress',
  AccountSettingsUpdated = 'AccountSettingsUpdated',
  AccountProfilePhotoUploading = 'AccountProfilePhotoUploading',
  AccountProfilePhotoUploadingFailed = 'AccountProfilePhotoUploadingFailed',

  // CONTACT
  ContactMuted = 'ContactMuted',
  ContactUnmuted = 'ContactUnmuted',
  ContactPhotoUpdatedStarted = 'ContactPhotoUpdatedStarted',
  ContactPhotoUpdated = 'ContactPhotoUpdated',
  ContactPhotoUpdatedFailed = 'ContactPhotoUpdatedFailed',
  ContactNameUpdated = 'ContactNameUpdated',
  ContactProfileUpdated = 'ContactProfileUpdated',
  ContactAddedToFavorites = 'ContactAddedToFavorites',
  ContactRemovedFromFavorites = 'ContactRemovedFromFavorites',
  ContactRemovedFromPeople = 'ContactRemovedFromPeople',

  // EMAIL
  EmailToQueue = 'EmailToQueue',
  EmailSending = 'EmailSending',
  EmailSent = 'EmailSent',
  EmailSendingError = 'EmailSendingError',
  TooLargeAttachments = 'TooLargeAttachments',
  EmailUndoDone = 'EmailUndoDone',
  EmailUndoInProgress = 'EmailInProgress',

  // DRAFT
  DraftCreated = 'DraftCreated',
  DraftUpdated = 'DraftUpdated',
  DraftRemoved = 'DraftRemoved',

  // TEAM
  TeamCreated = 'TeamCreated',
  TeamUpdated = 'TeamUpdated',

  // BOARD
  BoardCreated = 'BoardCreated',
  BoardUpdated = 'BoardUpdated',
  BoardRemoved = 'BoardRemoved',

  // CARD ACTIONS
  CardDeleted = 'CardDeleted',
  CardPinned = 'CardPinned',
  CardUnpinned = 'CardUnpinned',
  CardStarred = 'CardStarred',
  CardUnstarred = 'CardUnstarred',
  CardArchived = 'CardArchived',
  CardUnarchived = 'CardUnarchived',
  CardMovedToInbox = 'CardMovedToInbox',
  CardMovedToFolder = 'CardMovedToFolder',

  // LOOPIN
  LoopInCreated = 'LoopInCreated',

  // SNOOZE
  Snooze = 'Snooze',

  // ASSIGNMENT
  EmailAssigned = 'EmailAssigned',
  EmailUnassigned = 'EmailUnassigned',
  EmailResolved = 'EmailResolved',

  // STATUSES
  StatusCreated = 'StatusCreated',
  StatusEdited = 'StatusEdited',

  // SHARED TAGS LABEL
  SharedTagLabelCreated = 'SharedTagLabelCreated',
  SharedTagLabelEdited = 'SharedTagLabelEdited',

  // OTHERS
  MarkAllAsRead = 'MarkAllAsRead',
  MarkAllAsUnread = 'MarkAllAsUnread',
  MarkOneAsRead = 'MarkOneAsRead',
  MarkOneAsUnread = 'MarkOneAsUnread',
  SubscribeToOne = 'SubscribeToOne',
  MuteOne = 'MuteOne',
  UnmuteOne = 'UnmuteOne',
  AppointmentStatusUpdated = 'AppointmentStatusUpdated',
  AppointmentStatusUpdateFailed = 'AppointmentStatusUpdateFailed',
  CardClearedFromOutbox = 'CardClearedFromOutbox',
  TemplateCommentDeleted = 'TemplateCommentDeleted',
  CopiedToClipboard = 'CopiedToClipboard',
  CommentContentDownloadComplete = 'CommentContentDownloadComplete',
  CommentContentDownloadError = 'CommentContentDownloadError',

  // App state
  AppOffline = 'AppOffline',
  PushSyncIssue = 'PushSyncIssue',

  // Custom message
  CustomMessage = 'CustomMessage',

  // Progress update
  ProgressUpdate = 'ProgressUpdate',

  // Availability status, out of office
  OutOfOfficeUpdated = 'OutOfOfficeUpdated',
  AvailabilityStatusCleared = 'AvailabilityStatusCleared',
  AvailabilityStatusCreated = 'AvailabilityStatusCreated',

  // Follow / unfollow
  FollowConversation = 'FollowConversation',
  UnFollowConversation = 'UnFollowConversation',

  BasicNotification = 'BasicNotification',
}
