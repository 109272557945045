import { Injectable } from '@angular/core';
import { Encryption, getOriginFromRoute, isWebApp } from '@dta/shared/utils/common-utils';
import { CONSTANTS } from '@shared/models/constants/constants';
import { microsoftExtendedPrefixWithSeparator } from '../data/channel-inbox-setup/channel-setup.service.interface';
import { ElectronService } from '../electron/electron';
import { OAuthServiceI } from './oauth.service.interface';

@Injectable()
export class OAuthService implements OAuthServiceI {
  constructor(private _electronService: ElectronService) {}

  static getNewOauthState(): string {
    return Encryption.encryptStandard('LOOP_EMAIL_STATE:' + new Date().getTime());
  }

  openExternalOAuth(
    forEmail: string,
    oAuthType: 'google' | 'microsoft' | 'microsoft-extended',
    flags?: { fullScope?: boolean; sameWindow?: boolean },
    state?: string,
    microsoftSharedInboxEmail?: string,
  ) {
    if (!state) {
      state = OAuthService.getNewOauthState();
    }

    /**
     * We add extra prefix, so registration knows the scope.
     * We strip this prefix when it gets returned.
     */
    if (oAuthType === 'microsoft-extended') {
      state = microsoftSharedInboxEmail + '::' + microsoftExtendedPrefixWithSeparator + state;
    }

    let link =
      oAuthType === 'microsoft-extended'
        ? this.getMicrosoftExtendedOauthLink()
        : oAuthType === 'google'
          ? this.getGoogleOauthLink(flags.fullScope)
          : this.getMicrosoftOauthLink(flags.fullScope);

    let url = link + encodeURIComponent(forEmail || '') + '&state=' + encodeURIComponent(state);

    this._electronService.openExternal(url, flags.sameWindow);
  }

  private getGoogleOauthLink(fullScope: boolean): string {
    let googleOauthLink = fullScope ? CONSTANTS.GOOGLE_OAUTH : CONSTANTS.GOOGLE_OAUTH_LIMITED;

    if (isWebApp()) {
      googleOauthLink = googleOauthLink.replace(
        '/loading-second-google',
        `/web-app-${getOriginFromRoute()}-loading-second-google`,
      );
    }

    return googleOauthLink;
  }

  private getMicrosoftOauthLink(fullScope: boolean): string {
    let microsoftOauthLink = fullScope ? CONSTANTS.MICROSOFT_OAUTH : CONSTANTS.MICROSOFT_OAUTH_LIMITED;

    if (isWebApp()) {
      microsoftOauthLink = microsoftOauthLink.replace(
        '/loading-second-microsoft',
        `/web-app-${getOriginFromRoute()}-loading-second-microsoft`,
      );
    }

    return microsoftOauthLink;
  }

  private getMicrosoftExtendedOauthLink(): string {
    let microsoftOauthLink = CONSTANTS.MICROSOFT_OAUTH_EXTENDED;

    if (isWebApp()) {
      microsoftOauthLink = microsoftOauthLink.replace(
        '/loading-second-microsoft',
        `/web-app-${getOriginFromRoute()}-loading-second-microsoft`,
      );
    }

    return microsoftOauthLink;
  }
}
