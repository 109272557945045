import { FilterEnum } from '@shared/api/api-loop/models/filter-enum';

export function filterEnumToSvg(filterEnum: FilterEnum) {
  switch (filterEnum) {
    case FilterEnum.INBOX:
      return require('@shared/assets/img/svg/inbox-icon.svg');
    case FilterEnum.DELETED:
      return require('@shared/assets/img/svg/delete-icon.svg');
    case FilterEnum.ARCHIVED:
      return require('@shared/assets/img/svg/archived-small.svg');
    case FilterEnum.ALL_MESSAGES:
      return require('@shared/assets/img/svg/all-small.svg');
    case FilterEnum.SENT:
      return require('@shared/assets/img/svg/sent-icon-small.svg');
    default:
      return null;
  }
}
