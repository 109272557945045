import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotResponseRatingComponent } from '@dta/ui/components/post-comment/bot-response-rating/bot-response-rating.component';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';

@NgModule({
  declarations: [BotResponseRatingComponent],
  imports: [CommonModule, TooltipModule, GraphicsModule],
  exports: [BotResponseRatingComponent],
})
export class BotResponseRatingModule {}
