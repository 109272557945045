/* tslint:disable */

export type ResponseTypeModel = 'Accept' | 'Tentative' | 'Decline' | 'Organizer' | 'NeedsAction';

export module ResponseTypeModel {
  export const ACCEPT: ResponseTypeModel = 'Accept';
  export const TENTATIVE: ResponseTypeModel = 'Tentative';
  export const DECLINE: ResponseTypeModel = 'Decline';
  export const ORGANIZER: ResponseTypeModel = 'Organizer';
  export const NEEDS_ACTION: ResponseTypeModel = 'NeedsAction';

  export function values(): ResponseTypeModel[] {
    return [ACCEPT, TENTATIVE, DECLINE, ORGANIZER, NEEDS_ACTION];
  }
}
