import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { Directive } from '@angular/core';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import {
  CommentChatModel,
  CommentMailModel,
  CommentModel,
  CommentTemplateModel
} from '@dta/shared/models-api-loop/comment/comment.model';
import {
  CardBase,
  ContactSortOrder,
  ContactType,
  Group,
  GroupSubType,
  GroupType,
  ListOfResourcesOfContactBase,
  ListOfResourcesOfUser,
  ListOfTags,
  QueryRelation,
  SharedInboxRequest,
  TagType,
  User,
  UserStatus
} from '@shared/api/api-loop/models';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import {
  AttendeeModel,
  ContactBaseModel,
  ContactModel,
  GroupModel,
  UserModel
} from 'dta/shared/models-api-loop/contact/contact.model';
import { ContactFilter, SidebarContacts } from 'dta/shared/models/contact.model';
import { combineLatest, EMPTY, forkJoin, from, merge, Observable, of, Subject, throwError } from 'rxjs';
import { BaseService } from '../base/base.service';
import { ContactServiceI } from './contact.service.interface';
import { ContactApiService, GroupApiService, UserApiService } from '@shared/api/api-loop/services';
import {
  bufferCount,
  catchError,
  defaultIfEmpty,
  distinct,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  toArray
} from 'rxjs/operators';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { ContactExDecorateService } from '@shared/decorators/extra-data-decorators/contact-ex-decorator/contact-ex-decorate.service';
import { ListOfTagsModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { SharedAvatarService } from '@shared/services/shared-avatar/shared-avatar.service';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { ContactDaoService } from '@shared/database/dao/contact/contact-dao.service';
import { isWebApp } from '@dta/shared/utils/common-utils';
import { InboxProvider, SharedInboxSetupData } from '../channel-inbox-setup/channel-setup.service.interface';
import { AvatarService } from '@shared/services/data/avatar/avatar.service';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { UserAvailabilityStatusService } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service';
import { ExpandedChannelListLimit } from '@dta/ui/components/menu/menu-channels-list/menu-channels-list.component';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { SmartGroupApiService } from '@shared/modules/contacts/shell/contacts-api-cache/smart-group-api.service';

@Directive()
export abstract class ContactService extends BaseService implements ContactServiceI {
  fetchingContacts: Dictionary<Subject<ContactModel>> = {};
  fetchingContactsWithParent: Dictionary<Subject<ContactModel>> = {};

  ////////////
  // Subject
  ////////////
  private static _groupContactsAfterSaveHook$: Subject<GroupModel[]> = new Subject();

  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _contactApiService: ContactApiService,
    protected _contactStoreFactory: ContactStoreFactory,
    protected _groupApiService: GroupApiService,
    protected _contactExDecorateService: ContactExDecorateService,
    protected _userApiService: UserApiService,
    protected _sharedAvatarService: SharedAvatarService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _contactDaoService: ContactDaoService,
    protected _avatarService: AvatarService,
    protected _userAvailabilityStatusService: UserAvailabilityStatusService,
    protected _storageService: StorageService,
    protected readonly smartGroupApiService: SmartGroupApiService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'ContactService';
  }

  get groupContactsAfterSaveHook$(): Subject<GroupModel[]> {
    return ContactService._groupContactsAfterSaveHook$;
  }

  syncSideMenuContacts(forUserEmail: string): Observable<ContactModel[]> {
    return this.fetchSidebarContacts(forUserEmail);
  }

  fetchSidebarContacts(forUserEmail: string): Observable<ContactModel[]> {
    return merge(
      this.fetchFavoriteContacts(forUserEmail, ExpandedChannelListLimit.FAVORITES),
      this.fetchPeopleContacts(forUserEmail, ExpandedChannelListLimit.PEOPLE),
      this.fetchTeamContacts(forUserEmail, ExpandedChannelListLimit.TEAMS),
      this.fetchSharedInboxes(forUserEmail),
      this.fetchPersonalInboxes(forUserEmail)
    ).pipe(
      mergeMap((contacts: ContactModel[]) => this.saveAllAndPublish(forUserEmail, contacts)),
      toArray(),
      map((contacts: ContactModel[][]) => contacts.flat())
    );
  }

  protected saveToDb(forUserEmail: string, contacts: ContactModel[]): Observable<ContactModel[]> {
    return this._contactDaoService.saveAll(forUserEmail, contacts);
  }

  protected save(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    return this.saveAll(forUserEmail, [contact]).pipe(map((contacts: ContactModel[]) => _.first(contacts)));
  }

  saveAllAndPublish(forUserEmail: string, contacts: ContactModel[]): Observable<ContactModel[]> {
    if (_.isEmpty(contacts)) {
      return of(contacts);
    }

    return this.saveAll(forUserEmail, contacts).pipe(
      tap((contacts: ContactModel[]) => {
        PublisherService.publishEvent(forUserEmail, contacts);
      })
    );
  }

  saveAndPublish(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    return this.save(forUserEmail, contact).pipe(
      tap((contact: ContactModel) => {
        PublisherService.publishEvent(forUserEmail, contact);
      })
    );
  }

  saveAll(forUserEmail: string, contacts: ContactModel[]): Observable<ContactModel[]> {
    if (_.isEmpty(contacts)) {
      return of(contacts);
    }

    let watch = new StopWatch(this.constructorName + '.saveAll: ' + contacts.length, ProcessType.SERVICE, forUserEmail);

    watch.log('doBeforeSave');
    return this.doBeforeSave(forUserEmail, contacts).pipe(
      mergeMap((contacts: ContactModel[]) => {
        watch.log('saveToDb');
        return this.saveToDb(forUserEmail, contacts);
      }),
      mergeMap((contacts: ContactModel[]) => {
        watch.log('doAfterSave');
        return this.doAfterSave(forUserEmail, contacts);
      })
    );
  }

  createContact(forUserEmail: string, userEmail: string): Observable<ContactModel> {
    let newContact = new UserModel({
      $type: UserModel.type,
      email: userEmail,
      name: userEmail,
      onlineStatus: UserStatus.NOT_REGISTERED
    });

    newContact.tags = ListOfTagsModel.buildFromParentAndTags(newContact, [TagModel.buildSystemTag(TagType.FOCUSED)]);

    if (isWebApp()) {
      // We dont save models without id on web
      newContact.id = userEmail;
    }

    return this.saveAndPublish(forUserEmail, newContact);
  }

  protected doBeforeSave(forUserEmail: string, contacts: ContactModel[]): Observable<ContactModel[]> {
    let watch = new StopWatch(this.constructorName + '.doBeforeSave', ProcessType.SERVICE, forUserEmail);

    return this._contactExDecorateService.decorateListExtraData(forUserEmail, contacts).pipe(
      mergeMap((contacts: ContactModel[]) => {
        watch.log('removeLocalContacts');
        return this.removeLocalContacts(forUserEmail, contacts);
      }),
      map((contacts: ContactModel[]) => {
        watch.log('removeDisplayNames');
        return this.removeDisplayNames(contacts);
      }),
      map((contacts: ContactModel[]) => {
        watch.log('setOnlineStatusForSelfIfUnset');
        return this.setOnlineStatusForSelfIfUnset(forUserEmail, contacts);
      })
    );
  }

  private doAfterSave(forUserEmail: string, contacts: ContactModel[]): Observable<ContactModel[]> {
    let watch = new StopWatch(this.constructorName + '.doAfterSave', ProcessType.SERVICE, forUserEmail);

    watch.log('triggerUpdateForGroupChatCards');
    return this.triggerUpdateForGroupChatCards(forUserEmail, contacts).pipe(
      tap(() => {
        watch.log('done');
      })
    );
  }

  private triggerUpdateForGroupChatCards(forUserEmail: string, contacts: ContactModel[]): Observable<ContactModel[]> {
    // Why we do this: BE has same entity for group chat cards ang group contact. Because of this, we only get
    // tags updated events for group and never for card.
    // The fix is not possible on BE without user impact, so we do it.

    if (_.isEmpty(contacts)) {
      return of([]);
    }

    // Filter only group contacts
    let groupContacts = _.filter(contacts, (contact: ContactModel) => contact.$type === GroupModel.type);

    // Trigger to avoid circular dependency
    if (!_.isEmpty(groupContacts)) {
      this.groupContactsAfterSaveHook$.next(<GroupModel[]>groupContacts);
    }

    return of(contacts);
  }

  favoriteContact(forUserEmail: string, contactId: string): Observable<ContactModel> {
    return this._updateTagByContact(forUserEmail, contactId, true, TagType.FAVORITE).pipe(
      tap(updatedContact => {
        PublisherService.publishEvent(forUserEmail, updatedContact);
      })
    );
  }

  unfavoriteContact(forUserEmail: string, contactId: string): Observable<ContactModel> {
    return this._updateTagByContact(forUserEmail, contactId, false, TagType.FAVORITE).pipe(
      tap(updatedContact => {
        PublisherService.publishEvent(forUserEmail, updatedContact);
      })
    );
  }

  removeContactFromPeople(forUserEmail: string, contactId: string): Observable<ContactModel> {
    return this._updateTagByContact(forUserEmail, contactId, false, TagType.CHATEE).pipe(
      tap(updatedContact => {
        PublisherService.publishEvent(forUserEmail, updatedContact);
      })
    );
  }

  findOrFetchContactById(forUserEmail: string, id: string, isGroup: boolean = false): Observable<ContactModel> {
    if (!id) {
      throw new Error('Id cannot be nil');
    }

    return this.findById(forUserEmail, id).pipe(
      catchError(err => {
        if (err.status === 404) {
          return of(undefined);
        }

        return throwError(err);
      }),
      mergeMap((contact: ContactModel) => {
        if (_.isEmpty(contact)) {
          if (isGroup || !id.startsWith('user')) {
            // Get group by id
            return this.fetchAndSaveGroupById(forUserEmail, id);
          } else {
            // Get contact by id regardless of type.
            // (will get by list getter. BE might not have it indexed yet)
            return this.fetchContactById(forUserEmail, id);
          }
        }

        return of(contact);
      })
    );
  }

  findOrFetchContactsById(forUserEmail: string, ids: string[]): Observable<ContactModel[]> {
    if (_.isEmpty(ids)) {
      return of([]);
    }

    return this._contactDaoService.findByIds(forUserEmail, ids).pipe(
      mergeMap((localContacts: ContactModel[]) => {
        let localContactsIds = _.map(localContacts, contact => contact.id);
        let missingContactIds = _.differenceBy(ids, localContactsIds);
        let isListOfUsers = _.groupBy(missingContactIds, id => id.includes('user'));

        return combineLatest([
          this.fetchContactsByIds(forUserEmail, isListOfUsers['false']),
          this.fetchAndSaveUsersByIds(forUserEmail, isListOfUsers['true'])
        ]).pipe(
          map(([contactByIds, save]) => {
            return [...localContacts, ...contactByIds, ...save];
          })
        );
      })
    );
  }

  fetchAndSaveGroup(forUserEmail: string, group: GroupModel): Observable<ContactModel> {
    return this.fetchAndSaveGroupById(forUserEmail, group.id);
  }

  private fetchAndSaveGroupById(forUserEmail: string, groupId: string): Observable<ContactModel> {
    return this._groupApiService.Group_GetGroup({ groupId: groupId }, forUserEmail).pipe(
      map((response: Group) => {
        return new GroupModel(response);
      }),
      mergeMap((group: GroupModel) => {
        return this.save(forUserEmail, group);
      })
    );
  }

  private _updateTagByContact(
    forUserEmail: string,
    contactId: string,
    value: boolean,
    tagType: TagType
  ): Observable<ContactModel> {
    return this.findOrFetchContactById(forUserEmail, contactId).pipe(
      mergeMap((dbContact: ContactModel) => {
        let tag = new TagModel();
        tag.id = tagType;
        tag.tagType = tagType;

        if (value) {
          dbContact.addTag(tag);
        } else {
          dbContact.removeTag(tag);
        }

        // We don't want to have multiple tags of the same type on contact
        dbContact.tags.tags.resources = _.uniqBy(dbContact.getTags(), 'id');

        // Don't just update, save. This way all triggers and hooks will be called
        return this.saveAndPublish(forUserEmail, dbContact);
      }),
      tap((updatedContact: ContactModel) => {
        this.enqueuePushSynchronization(forUserEmail, new ListOfTagsModel(updatedContact.tags));
      })
    );
  }

  /**
   * Might solve issue where user can't assign self, but can find in jump-to
   * Speculation is that self onlineStatus is not set or is set incorrectly
   * Related issue: https://github.com/4thOffice/brisket/issues/7385
   */
  private setOnlineStatusForSelfIfUnset(forUserEmail: string, contacts: ContactModel[]): ContactModel[] {
    if (_.isEmpty(contacts)) {
      return [];
    }

    return _.map(contacts, (contact: ContactModel) => {
      if (contact.$type === UserModel.type && contact.email === forUserEmail && !contact.isRegistered()) {
        Logger.customLog(
          `OnlineStatus set to '${(<UserModel>contact).onlineStatus}'. Will set it to active`,
          LogLevel.INFO,
          LogTag.INTERESTING_ERROR,
          true
        );

        (<UserModel>contact).onlineStatus = UserStatus.ACTIVE;
      }

      return contact;
    });
  }

  private removeDisplayNames(contacts: ContactModel[]): ContactModel[] {
    return contacts.map(contact => {
      delete contact['displayName'];
      return contact;
    });
  }

  private removeLocalContacts(forUserEmail: string, contacts: ContactModel[]): Observable<ContactModel[]> {
    let localContacts = _.filter(contacts, (contact: ContactModel) => !contact.id);
    let contactEmails = _.map(localContacts, (contact: ContactModel) => contact.email);
    return this.removeAccountsByEmails(forUserEmail, contactEmails).pipe(
      map(() => {
        return contacts;
      })
    );
  }

  fetchAndSaveNewContacts(forUserEmail: string, contacts: ContactBase[]): Observable<ContactBase[]> {
    if (_.isEmpty(contacts)) {
      return of([]);
    }

    return this.filterNewContacts(forUserEmail, contacts).pipe(
      mergeMap((newContacts: ContactBase[]) => {
        return this.fetchContacts(forUserEmail, newContacts);
      }),
      mergeMap((contacts: ContactModel[]) => {
        return this.saveAll(forUserEmail, contacts);
      }),
      tap((contacts: ContactModel[]) => {
        PublisherService.publishEvent(forUserEmail, contacts);
      })
    );
  }

  fetchUnknownUsersByIds(forUserEmail: string, parentIdByUserId: _.Dictionary<string>): Observable<ContactModel[]> {
    return from(Object.keys(parentIdByUserId)).pipe(
      mergeMap((userId: string) => {
        if (!userId.startsWith('user_')) {
          return of(undefined);
        }
        return this.fetchUnknownUser(forUserEmail, userId, parentIdByUserId[userId]);
      }, 5),
      toArray()
    );
  }

  fetchUnknownContacts(
    forUserEmail: string,
    contacts: ContactBase[],
    cardByContactId: _.Dictionary<CardBase>
  ): Observable<ContactModel[]> {
    return from(contacts).pipe(
      mergeMap((contact: ContactBase) => {
        if (contact.$type === GroupModel.type) {
          return <Observable<ContactModel>>EMPTY;
        }

        return this.fetchUnknownUser(forUserEmail, contact.id, cardByContactId[contact.id].id);
      }, 5),
      toArray()
    );
  }

  fetchUnknownUser(forUserEmail: string, userId: string, cardId: string): Observable<ContactModel> {
    if (userId in this.fetchingContactsWithParent) {
      return this.fetchingContactsWithParent[userId];
    } else {
      this.fetchingContactsWithParent[userId] = new Subject<ContactModel>();
      return this._userApiService
        .User_Get(
          {
            authorizedCardId: cardId,
            id: userId
          },
          forUserEmail
        )
        .pipe(
          catchError(err => {
            Logger.error(
              err,
              `Could not fetch unknown user ${userId} for card with id ${cardId} for user: ${forUserEmail}`,
              LogTag.INTERESTING_ERROR,
              true,
              'Could not fetch unknown user by auth card'
            );

            // Ignore error unless we should retry
            if (![503, 504].includes(err.status)) {
              return EMPTY;
            }

            return throwError(err);
          }),
          map((user: User) => {
            return ContactBaseModel.create(user);
          }),
          tap((contact: ContactModel) => {
            this.fetchingContactsWithParent[contact.id].next(contact);
            this.fetchingContactsWithParent[contact.id].complete();
            delete this.fetchingContactsWithParent[contact.id];
          })
        );
    }
  }

  saveUnknownContacts(forUserEmail: string, contacts: ContactModel[]): Observable<ContactModel[]> {
    if (!SynchronizationMiddlewareService.allBlockingPrefetchActionsDone(forUserEmail)) {
      return of(contacts);
    }

    let contactStore = this._contactStoreFactory.forUser(forUserEmail);

    return from(contacts).pipe(
      /**
       * Get contacts that need to be saved
       */
      mergeMap((contact: ContactModel) => {
        return contactStore.existsWithHigherOrEqualRevision(contact).pipe(
          filter((exists: boolean) => {
            return !exists;
          }),
          map(() => contact)
        );
      }),
      toArray(),
      defaultIfEmpty([]),
      /**
       * Remove avatars from cache
       */
      tap((newContacts: ContactModel[]) => {
        this.removeCachedAvatarForContact(forUserEmail, newContacts);
      }),
      /**
       * Merge partial contacts with full model (if existing)
       */
      mergeMap((newContacts: ContactModel[]) => {
        return contactStore.getMergedWithExisting(newContacts);
      }),
      /**
       * Cast Attendee models to users
       */
      map((newContacts: ContactModel[]) => {
        return _.map(newContacts, (contact: ContactModel) => {
          if (contact.$type === AttendeeModel.type) {
            return AttendeeModel.castToUserModel(contact as AttendeeModel);
          }

          return contact;
        });
      }),
      /**
       * Save and publish updates
       */
      mergeMap((newContacts: ContactModel[]) => {
        return this.saveAllAndPublish(forUserEmail, newContacts);
      })
    );
  }

  removeAll(forUserEmail: string, contacts: ContactModel[]): Observable<any> {
    return this._contactDaoService.removeAll(forUserEmail, contacts);
  }

  removeByIds(forUserEmail: string, contactIds: string[]): Observable<any> {
    return this._contactDaoService.removeByIds(forUserEmail, contactIds);
  }

  findOrFetchContactByEmail(forUserEmail: string, email: string): Observable<ContactModel> {
    if (!email) {
      return throwError('Email cannot be nil');
    }

    return this._contactDaoService.findByEmail(forUserEmail, email).pipe(
      catchError(err => {
        if (err.status === 404) {
          // Use search to fetch user by email
          return this.fetchContactByEmail(forUserEmail, email);
        }

        return throwError(err);
      })
    );
  }

  findContactById(forUserEmail: string, id: string): Observable<ContactModel> {
    return this._contactDaoService.findById(forUserEmail, id).pipe(
      catchError(err => {
        console.log(err);
        return of(null);
      }),
      filter((contact: ContactModel) => !_.isEmpty(contact))
    );
  }

  findContactsByIds(forUserEmail: string, ids: string[]): Observable<ContactModel[]> {
    return this._contactDaoService.findByIds(forUserEmail, ids);
  }

  findContactByEmail(forUserEmail: string, email: string): Observable<ContactModel> {
    return this._contactDaoService.findByEmail(forUserEmail, email).pipe(
      // When contact is not found (404 error from db) return undefined and
      // silence error
      catchError(err => of(undefined))
    );
  }

  changeContactName(forUserEmail: string, user: UserModel, value: string): Observable<UserModel> {
    let newUser = new UserModel(user);
    newUser.name = value;
    return this.updateUserName(forUserEmail, newUser).pipe(
      mergeMap(() => {
        return this._userApiService.User_UpdateContact({ id: user.id, user: user }, forUserEmail);
      }),
      tap(() => {
        PublisherService.publishEvent(forUserEmail, newUser);
      }),
      map(() => {
        return newUser;
      })
    );
  }

  protected abstract updateUserName(forUserEmail: string, user: UserModel): Observable<any>;

  createGroup(forUserEmail: string, group: GroupModel): Observable<ContactModel> {
    return this._groupApiService.Group_CreateGroup({ group: group }, forUserEmail).pipe(
      map((response: Group) => GroupModel.create(response)),
      mergeMap((_group: GroupModel) => this.saveAndPublish(forUserEmail, _group)),
      /**
       * Log any error
       */
      catchError(err => {
        Logger.error(
          err,
          `Error in createGroup for ${forUserEmail}`,
          [LogTag.INTERESTING_ERROR],
          true,
          'Error in createGroup'
        );

        return throwError(() => err);
      })
    );
  }

  deleteGroup(forUserEmail: string, group: GroupModel): Observable<any> {
    throw new Error('Method not implemented.');
  }

  updateGroup(forUserEmail: string, group: GroupModel): Observable<ContactModel> {
    throw new Error('Method not implemented.');
  }

  leaveGroup(forUserEmail: string, group: GroupModel): Observable<ContactModel> {
    let userId = this._sharedUserManagerService.getUserIdByEmail(forUserEmail);

    group.members.resources = _.filter(group.members.resources, contact => contact.id !== userId);
    group.admins.resources = _.filter(group.admins.resources, contact => contact.id !== userId);
    group._ex.isNotAccessible = true;

    return this._groupApiService
      .Group_UpdateGroup(
        {
          groupId: group.id,
          group: group.toObject()
        },
        forUserEmail
      )
      .pipe(
        map((response: Group) => {
          return new GroupModel(response);
        }),
        mergeMap((group: GroupModel) => {
          return this.save(forUserEmail, group);
        }),
        tap(() => {
          PublisherService.publishEvent(forUserEmail, group, PublishEventType.Remove);
        })
      );
  }

  getActiveUserCount(forUserEmail: string, contact: ContactModel): Observable<Number> {
    let contacts = contact instanceof GroupModel ? contact.getAllMembers() : [contact];
    return this._contactDaoService.getActiveUserCount(forUserEmail, contacts);
  }

  getTeamsAndSharedInboxesCount(
    forUserEmail: string
  ): Observable<{ teamChannels: number; sharedInboxChannels: number }> {
    return this.getSidebarContacts(forUserEmail).pipe(
      map(({ teamChannels, sharedInboxChannels }: SidebarContacts) => {
        return {
          teamChannels: teamChannels?.length || 0,
          sharedInboxChannels: sharedInboxChannels?.length || 0
        };
      })
    );
  }

  updateUsersActivityAndPublish(forUserEmail: string, users: User[]): Observable<UserModel[]> {
    throw new Error('Method not implemented.');
  }

  filterContactUpdates(forUserEmail: string, contactUpdates: ContactModel[]): Observable<ContactModel[]> {
    let ids = _.map(contactUpdates, contact => contact.id);
    let contactUpdatedById = _.keyBy(contactUpdates, contact => contact.id);

    // Find local contacts
    return this._contactDaoService.findByIds(forUserEmail, ids).pipe(
      mergeMap((localContacts: ContactModel[]) => {
        return from(localContacts);
      }),
      // Remove all contacts that came with lower revision
      tap((localContact: ContactModel) => {
        let contactUpdate = contactUpdatedById[localContact.id];
        if (BaseModel.isRevisionGreaterThan(localContact, contactUpdate)) {
          delete contactUpdatedById[localContact.id];
        }
      }),
      toArray(),
      // Return new updates
      map(() => {
        return Object.values(contactUpdatedById);
      })
    );
  }

  removeCachedAvatarForContact(forUserEmail: string, contacts: ContactModel[]): void {
    /**
     * Remove cached avatar entry in local storage. Do this when contact gets updated
     * with higher revision. That can happen via:
     *  > contact sync
     *  > contact on comments/cards
     *  > contact updated via event-sync
     */
    if (!contacts || contacts.length === 0) {
      return;
    }

    if (_.isEmpty(forUserEmail)) {
      Logger.customLog(`${this.constructorName}:removeCachedAvatarForContact forUserEmail empty`, LogLevel.WARN);
      return;
    }

    let contactIds = _.map(contacts, (contact: ContactModel) => contact.id);
    this._sharedAvatarService.removeTimestampsFromLocalStore(forUserEmail, contactIds);
  }

  subscribeToContactById(forUserEmail: string, id: string) {
    throw new Error('Method not implemented.');
  }

  subscribeToContactByEmail(forUserEmail: string, email: string) {
    throw new Error('Method not implemented.');
  }

  subscribeToContactByIdOrEmail(forUserEmail: string, idOrEmail: string): Observable<ContactModel> {
    throw new Error('Method not implemented.');
  }

  subscribeToContact(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    throw new Error('Method not implemented.');
  }

  setUnreadCountForContact(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    throw new Error('Method not implemented.');
  }

  updateAvatar(forUserEmail: string, contact: ContactModel, file: File): Observable<any> {
    if (contact instanceof GroupModel) {
      return this.uploadGroupAvatar(forUserEmail, contact, file);
    } else {
      return this.uploadUserAvatar(forUserEmail, contact, file);
    }
  }

  uploadUserAvatar(forUserEmail: string, contact: UserModel, file: File): Observable<any> {
    return this._userApiService.User_UpdateUserAvatar({ userId: contact.id, file: file }, forUserEmail);
  }

  uploadGroupAvatar(forUserEmail: string, contact: GroupModel, file: File): Observable<any> {
    return this._groupApiService.Group_UpdateGroupAvatar({ groupId: contact.id, file: file }, forUserEmail);
  }

  findOrFetchContactByIdOrEmail(forUserEmail: string, idOrEmail: string): Observable<ContactModel> {
    if (idOrEmail.includes('@')) {
      return this.findOrFetchContactByEmail(forUserEmail, idOrEmail);
    } else {
      return this.findOrFetchContactById(forUserEmail, idOrEmail);
    }
  }

  filterNewContacts(forUserEmail: string, contacts: ContactBase[]): Observable<ContactBase[]> {
    if (_.isEmpty(contacts)) {
      return of([]);
    }

    return this._contactDaoService.findBaseByIds(forUserEmail, contacts).pipe(
      map((dbContacts: ContactBase[]) => {
        return _.differenceBy(contacts, dbContacts, 'id');
      })
    );
  }

  updateContactsTags(forUserEmail: string, tags: ListOfTags[]): Observable<ContactModel[]> {
    if (_.isEmpty(tags)) {
      return of([]);
    }

    let tagsByParentId = _.keyBy(tags, 'parent.id');
    let ids: string[] = _.keys(tagsByParentId);
    let contactMutedStatusChanged: ContactModel[] = [];

    return this._contactDaoService.findByIds(forUserEmail, ids).pipe(
      mergeMap((contacts: ContactModel[]) => {
        return from(contacts);
      }),
      filter((contact: ContactModel) => {
        let tags = tagsByParentId[contact.id];
        return tags && (!contact.tags || BaseModel.isRevisionGreaterThan(tags, contact.tags));
      }),
      map((contact: ContactModel) => {
        let wasFocused = !contact.hasTagType(TagType.MUTED);
        contact.tags = tagsByParentId[contact.id];
        let isFocused = !contact.hasTagType(TagType.MUTED);

        if (wasFocused !== isFocused) {
          contactMutedStatusChanged.push(contact);
        }

        return contact;
      }),
      toArray(),
      mergeMap((contacts: ContactModel[]) => {
        return this.saveAll(forUserEmail, contacts);
      })
    );
  }

  protected fetchContactByEmail(forUserEmail: string, email: string): Observable<ContactModel> {
    if (!email) {
      throw new Error('Email cannot be nil');
    }

    let params: ContactApiService.Contact_GetListParams = {
      offset: 0,
      size: 1,
      searchQuery: email
    };
    return this._contactApiService.Contact_GetList(params, forUserEmail).pipe(
      filter((response: ListOfResourcesOfContactBase) => {
        return !_.isEmpty(response.resources);
      }),
      map((response: ListOfResourcesOfContactBase) => {
        let contacts = ContactBaseModel.createList(response.resources);
        return _.first(contacts);
      }),
      mergeMap((contact: ContactModel) => {
        return this.saveAndPublish(forUserEmail, contact);
      }),
      defaultIfEmpty(undefined),
      catchError(err => {
        Logger.error(err, 'Could not fetch contact by email');
        return of(undefined);
      })
    );
  }

  fetchContactById(forUserEmail: string, id: string): Observable<ContactModel> {
    if (!id) {
      throw new Error('Id cannot be nil');
    }

    let params: ContactApiService.Contact_GetListParams = {
      contactIds: [id],
      offset: 0,
      size: 1
    };
    return this._contactApiService.Contact_GetList(params, forUserEmail).pipe(
      filter((response: ListOfResourcesOfContactBase) => {
        return !_.isEmpty(response.resources);
      }),
      map((response: ListOfResourcesOfContactBase) => {
        let contacts = ContactBaseModel.createList(response.resources);
        return _.first(contacts);
      }),
      mergeMap((contact: ContactModel) => {
        return this.saveAndPublish(forUserEmail, contact);
      }),
      defaultIfEmpty(undefined),
      catchError(err => {
        Logger.error(err, 'Could not fetch contact by id');
        return of(undefined);
      })
    );
  }

  fetchNewContactsByCommentShareLists(forUserEmail: string, comments: CommentModel[]): Observable<ContactModel[]> {
    let cardByContactId: _.Dictionary<CardBase> = {};
    let knownContacts: ContactModel[] = [];
    let allContacts: ContactBase[] = [];
    let missingContacts: ContactBase[] = [];

    return from(comments).pipe(
      mergeMap((comment: CommentModel) => {
        let shareList = comment.getAllContacts();

        return from(shareList).pipe(
          /**
           * Filter out local contacts, they can't be fetched
           */
          filter((contact: ContactBase) => !_.isEmpty(contact.id)),
          tap((contact: ContactBase) => {
            cardByContactId[contact.id] = comment.parent;
          })
        );
      }),
      distinct((contact: ContactBase) => {
        return contact.id;
      }),
      toArray(),
      /**
       * Filter only new contacts
       */
      mergeMap((contacts: ContactBase[]) => {
        return this.filterNewContacts(forUserEmail, contacts);
      }),
      /**
       * Fetch known contacts from contact list
       */
      mergeMap((contacts: ContactBase[]) => {
        allContacts = contacts;
        return this.fetchContacts(forUserEmail, contacts);
      }),
      /**
       * Fallback get for unknown contacts
       */
      mergeMap((_knownContacts: ContactModel[]) => {
        knownContacts = _knownContacts;

        missingContacts = _.differenceBy(allContacts, knownContacts, 'id');
        return this.fetchUnknownContacts(forUserEmail, missingContacts, cardByContactId);
      }),
      map((unknownContacts: ContactModel[]) => {
        let notGonnaGet = ContactBaseModel.createList(_.differenceBy(missingContacts, unknownContacts, 'id'));
        return _.concat(knownContacts, unknownContacts, notGonnaGet);
      }),
      /**
       * Save and publish new contacts
       */
      mergeMap((contacts: ContactModel[]) => {
        return this.saveAll(forUserEmail, contacts);
      }),
      tap((contacts: ContactModel[]) => {
        PublisherService.publishEvent(forUserEmail, contacts);
      })
    );
  }

  protected fetchContacts(forUserEmail: string, contacts: ContactBase[]): Observable<ContactModel[]> {
    if (_.isEmpty(contacts)) {
      return of([]);
    }

    return this.fetchContactsByIds(
      forUserEmail,
      _.map(contacts, c => c.id)
    );
  }

  protected fetchContactsByIds(forUserEmail: string, contactIds: string[]): Observable<ContactModel[]> {
    if (_.isEmpty(contactIds)) {
      return of([]);
    }
    let response$ = new Subject<ContactModel>();
    let subjects = [];

    const fetchingContactIds = contactIds.filter(contactId => {
      if (_.isEmpty(contactId)) {
        return false;
      }

      if (contactId in this.fetchingContacts) {
        subjects.push(this.fetchingContacts[contactId]);
        return false;
      } else {
        this.fetchingContacts[contactId] = response$;
        return true;
      }
    });

    return of(fetchingContactIds).pipe(
      switchMap((chunks: string[]) => {
        let params: ContactApiService.Contact_GetListParams = {
          contactIds: chunks,
          offset: 0,
          size: 1024
        };

        return this._contactApiService.Contact_GetList(params, forUserEmail);
      }),
      map((response: ListOfResourcesOfContactBase) => {
        let contacts = ContactBaseModel.createList(response.resources);
        _.forEach(contacts, contact => {
          if (contact.id in this.fetchingContacts) {
            this.fetchingContacts[contact.id].next(contact);
            this.fetchingContacts[contact.id].complete();
            delete this.fetchingContacts[contact.id];
          }
        });

        // Sometimes we don't get response form BE, thus we have to complete subjects...

        contactIds.forEach(contactId => {
          if (contactId in this.fetchingContacts) {
            this.fetchingContacts[contactId].complete();
            delete this.fetchingContacts[contactId];
          }
        });
        return contacts;
      }),
      mergeMap((contacts: ContactModel[]) => {
        return this.saveAllAndPublish(forUserEmail, contacts);
      }),
      mergeMap((contacts: ContactModel[]) => {
        return forkJoin([of(contacts), ...subjects]);
      }),
      map((contacts: ContactModel[][]) => {
        return contacts.flat();
      })
    );
  }

  protected fetchAndSaveUsersByIds(forUserEmail: string, userIds: string[]): Observable<ContactModel[]> {
    if (_.isEmpty(userIds)) {
      return of([]);
    }

    return this.fetchContactsByIds(forUserEmail, userIds);
  }

  protected fetchFavoriteContacts(forUserEmail: string, size: number): Observable<ContactModel[]> {
    let params: ContactApiService.Contact_GetListParams = {
      tags: [TagType.FAVORITE],
      tagFilterRelation: QueryRelation.AND,
      offset: 0,
      size: size,
      offsetHistoryId: '',
      contactSortOrder: ContactSortOrder.WEIGHT
    };

    return this.fetchContactsForParameters(forUserEmail, params);
  }

  protected fetchPersonalInboxes(forUserEmail: string): Observable<ContactModel[]> {
    return this.smartGroupApiService.getAllPersonalInboxes$(forUserEmail).pipe(
      tap((personalInboxes: GroupModel[]) => {
        this._storageService.setStringifiedItem(
          StorageKey.allowedImpersonatedPIIds,
          _.flatten(
            personalInboxes.map(personalInbox =>
              personalInbox?.allowedImpersonatedSenders?.resources.map(user => user.id)
            )
          )
        );
      })
    );
  }

  protected fetchSharedInboxes(forUserEmail: string): Observable<ContactModel[]> {
    return this.smartGroupApiService.getAllSharedInboxes$(forUserEmail);
  }

  protected fetchTeamContacts(forUserEmail: string, size: number): Observable<ContactModel[]> {
    let params: ContactApiService.Contact_GetListParams = {
      subGroupTypes: [GroupSubType.NORMAL],
      tagFilterRelation: QueryRelation.AND,
      offset: 0,
      size: size,
      offsetHistoryId: '',
      contactSortOrder: ContactSortOrder.WEIGHT
    };

    return this.fetchContactsForParameters(forUserEmail, params);
  }

  protected fetchPeopleContacts(forUserEmail: string, size: number): Observable<ContactModel[]> {
    let params: ContactApiService.Contact_GetListParams = {
      tags: [TagType.CHATEE],
      tagFilterRelation: QueryRelation.AND,
      offset: 0,
      size: size,
      contactSortOrder: ContactSortOrder.WEIGHT,
      offsetHistoryId: ''
    };
    return this.fetchContactsForParameters(forUserEmail, params);
  }

  protected fetchContactsForParameters(
    forUserEmail: string,
    params: ContactApiService.Contact_GetListParams
  ): Observable<ContactModel[]> {
    return this._contactApiService.Contact_GetList(params, forUserEmail).pipe(
      filter((response: ListOfResourcesOfContactBase) => {
        return !_.isEmpty(response.resources);
      }),
      mergeMap((response: ListOfResourcesOfContactBase) => {
        return of(ContactBaseModel.createList(response.resources));
      }),
      defaultIfEmpty([])
    );
  }

  publishContactsFromComments(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return from(comments).pipe(
      filter((comment: CommentModel) => {
        // Don't publish contacts for template comments
        return comment.$type !== CommentTemplateModel.type;
      }),
      map((comment: CommentModel) => {
        return this.getContactIdFromComment(comment);
      }),
      distinct(),
      filter((contactId: string) => !_.isUndefined(contactId)),
      toArray(),
      mergeMap((contactIds: string[]) => {
        return this._contactDaoService.findByIds(forUserEmail, contactIds);
      }),
      tap((contacts: ContactModel[]) => {
        PublisherService.publishEvent(forUserEmail, contacts);
      }),
      defaultIfEmpty([]),
      map(() => {
        return comments;
      })
    );
  }

  private getContactIdFromComment(comment: CommentModel): string {
    let contactId = '';

    if (comment?._ex?.isGroupComment && comment instanceof CommentMailModel) {
      let groupResource = comment.to.resources.find(contact => contact.$type === GroupModel.type);
      if (groupResource) {
        // TODO API - this should be always set but sometimes isn't
        contactId = groupResource.id;
      }
    } else if (comment._ex.isGroupComment && comment instanceof CommentChatModel) {
      if (!_.isEmpty(comment.shareList)) {
        let groupResource = comment.shareList.resources.find(contact => contact.$type === GroupModel.type);
        contactId = groupResource.id;
      } else {
        // TODO API - BE workaround
        contactId = comment.parent.id;
      }
    } else {
      contactId = comment.author.id;
    }

    return contactId;
  }

  fetchForQuery(
    forUserEmail: string,
    contactFilter: ContactFilter,
    currentLocalResults: ContactModel[] = []
  ): Observable<ContactModel[]> {
    let {
      size,
      searchQuery,
      excludeContactIds,
      assignSuggestionIds,
      ignoreGroups,
      currentUserId,
      excludeMe,
      availableContactsIds
    } = contactFilter;

    let filterIds = (contactFilter.filter || []).map(u => u.id).filter(id => !!id);
    let localIds = currentLocalResults.reduce((acc, c) => (c.id ? acc.push(c.id) && acc : acc), []);
    let contactTypes = ignoreGroups ? [ContactType.USER] : contactFilter.contactTypes;
    let subGroupTypes = ignoreGroups ? [] : contactFilter.groupSubTypes;

    let _excludeContactIds = _.uniq([
      ...filterIds,
      ...(excludeContactIds ? excludeContactIds : []),
      ...(localIds ? localIds : []),
      ...(excludeMe && currentUserId ? [currentUserId] : [])
    ]).slice(0, 100); // Limit to 100, so request is not to long

    let params: ContactApiService.Contact_GetListParams = {
      offset: 0,
      size,
      searchQuery,
      contactTypes,
      subGroupTypes,
      excludeContactIds: _excludeContactIds,
      contactIds: _.uniq([...(assignSuggestionIds || []), ...(availableContactsIds || [])])
    };

    return this._contactApiService.Contact_GetList(params, forUserEmail).pipe(
      filter((response: ListOfResourcesOfContactBase) => {
        return !_.isEmpty(response.resources);
      }),
      map((response: ListOfResourcesOfContactBase) => ContactBaseModel.createList(response.resources)),
      mergeMap((contacts: ContactModel[]) => {
        return this.saveAllAndPublish(forUserEmail, contacts);
      }),
      defaultIfEmpty([]),
      catchError(err => {
        Logger.error(err, 'Could not fetch contacts by query');
        return of([]);
      })
    );
  }
  createSharedInboxGroup(
    forUserEmail: string,
    data: SharedInboxSetupData,
    sharedInboxAuthorizationToken: string,
    isPersonal: boolean
  ): Observable<ContactModel> {
    let sharedInboxAuthToken = data.provider === InboxProvider.GOOGLE_ALIAS ? undefined : sharedInboxAuthorizationToken;

    let sharedInbox: SharedInboxRequest = {
      group: {
        $type: GroupModel.type,
        name: data.name,
        description: data.description,
        members: {
          resources: data.members,
          size: data.members?.length || 0,
          offset: 0,
          totalSize: data.members?.length || 0
        }
      },
      sharedInboxExtraData: {
        creatorEmail: forUserEmail,
        aliasEmail: data.aliasEmail,
        sharedInboxType: isPersonal ? 'PersonalInbox' : 'SharedInbox'
      }
    };

    return this._groupApiService
      .Group_CreateSharedInbox(
        { sharedInbox, Authorization: sharedInboxAuthToken },
        sharedInboxAuthToken ? undefined : forUserEmail
      )
      .pipe(
        map(GroupModel.create),
        mergeMap((shardInbox: GroupModel) => this.saveAndPublish(forUserEmail, shardInbox))
      );
  }

  ////////////////
  // DAO WRAPPERS
  ////////////////
  getAllContacts(forUserEmail: string): Observable<ContactModel[]> {
    return this._contactDaoService.getAllContacts(forUserEmail);
  }

  findById(forUserEmail: string, id: string): Observable<ContactModel> {
    return this._contactDaoService.findById(forUserEmail, id);
  }

  protected removeAccountsByEmails(forUserEmail: string, contactEmails: string[]): Observable<ContactModel[]> {
    return of([]);
  }

  protected removeAvailabilityStatus(forUserEmail: string, contact: ContactModel) {
    return of(contact);
  }

  ////////////////
  // STORE PROXY
  ////////////////
  getContactsForChannelTags(forUserEmail: string): Observable<ContactModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getContactsForChannelTags();
  }

  getContactById(forUserEmail: string, id: string): Observable<ContactModel> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getContactById(id);
  }

  getContactByEmail(forUserEmail: string, email: string): Observable<ContactModel> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getContactByEmail(email);
  }

  getContactsByIds(forUserEmail: string, ids: string[]): Observable<ContactModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getContactsByIds(ids);
  }

  getSidebarContacts(forUserEmail: string): Observable<SidebarContacts> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getSidebarContacts();
  }

  getTeamChannels(forUserEmail: string): Observable<ContactModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getTeamChannels();
  }

  getUserEmailsBySharedInboxId(forUserEmail: string): Observable<{ [id: string]: string }> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getUserEmailsBySharedInboxId();
  }

  getAllowedImpersonatedUsersFromSharelist(forUserEmail: string, shareList: ContactBase[]): Observable<User[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getAllowedImpersonatedUsersFromSharelist(shareList);
  }

  getAllAllowedImpersonatedSenders(forUserEmail: string): Observable<User[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getAllAllowedImpersonatedSenders();
  }

  getInboxesWithAllowedImpersonatedSenders(forUserEmail: string): Observable<GroupModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getInboxesWithAllowedImpersonatedSenders();
  }

  getPersonalInboxesWithAllowedImpersonatedSenders(forUserEmail: string): Observable<GroupModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getPersonalInboxesWithAllowedImpersonatedSenders();
  }

  getSharedInboxesWithAllowedImpersonatedSenders(forUserEmail: string): Observable<GroupModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getSharedInboxesWithAllowedImpersonatedSenders();
  }

  getAllPersonalAndSharedInboxes(forUserEmail): Observable<ContactModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getAllPersonalAndSharedInboxes();
  }

  getAllSharedInboxes(forUserEmail: string): Observable<ContactModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getAllSharedInboxes();
  }

  getAllPersonalInboxes(forUserEmail: string): Observable<GroupModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getAllPersonalInboxes();
  }

  findAllAccessibleGroups(forUserEmail: string): Observable<ContactModel[]> {
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore
      .findAllAccessibleGroupIds()
      .pipe(mergeMap((ids: string[]) => contactStore.getContactsByIds(ids)));
  }

  ///////////////////////
  // AUTOSUGGEST METHODS
  ///////////////////////
  getFilteredContacts(forUserEmail: string, contactFilter: ContactFilter): Observable<ContactModel[]> {
    let watch = new StopWatch(this.constructorName + '.getFilteredContacts');

    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.getFilteredContacts(contactFilter, watch);
  }

  ///////////////////////
  // Workspace contacts
  ///////////////////////

  fetchWorkspaceContacts(forUserEmail): Observable<UserAvailabilityStatusModel[]> {
    return this._contactApiService.Contact_GetWorkspaceList({}, forUserEmail).pipe(
      map((response: ListOfResourcesOfUser) => {
        return _.map(response.resources, user => new UserModel(user));
      }),
      mergeMap((users: UserModel[]) => {
        users = _.filter(users, user => !!user.availabilityStatus);
        return this._userAvailabilityStatusService.saveAllAndPublish(
          forUserEmail,
          UserAvailabilityStatusModel.createUserAvailabilities(users)
        );
      })
    );
  }

  removeCollection(forUserEmail: string): Observable<any> {
    return of(undefined);
  }
}
