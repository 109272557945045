import * as _ from 'lodash';
import { BaseModel } from './base/base.model';
import { UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { AvailabilityUserStatus } from '@shared/api/api-loop/models/availability-user-status';
import { CollectionName } from '@shared/models/constants/collection.names';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { LogEntry } from '@shared/api/log-loop/models/log-entry';
import { Tag } from '@shared/api/api-loop/models/tag';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';

export interface LogBaseModel extends ResourceBase {
  log?: LogEntry;
}

export class LogModel extends BaseModel implements LogBaseModel {
  static collectionName: CollectionName = CollectionName.Log;
  static type: string = 'Log';

  readonly $type: string = LogModel.type;

  log?: LogEntry;

  constructor(model?: LogBaseModel) {
    super(model);
    if (model && model.log) {
      this.log = model.log;
    }
  }

  static createLogFromLogEntry(log: LogEntry) {
    let logModel = new LogModel();
    logModel.log = log;
    return logModel;
  }

  static createList(models: LogModel[]) {
    return _.map(models, model => new LogModel(model));
  }
}
