import { Color, ColorType } from '@shared/modules/color-scheme/common/color-types-array';

export const defaultColors: Record<ColorType, Color> = {
  success: new Color({
    base: '#17A14E',
    contrast: '#F3FAF6'
  }),
  warning: new Color({
    base: '#EA9000',
    contrast: '#FEF9F2'
  }),
  danger: new Color({
    base: '#CF3C4F',
    contrast: '#FDF5F6'
  }),
  dark: new Color({
    base: '#404040',
    contrast: '#FFFFFF'
  })
};
