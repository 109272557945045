export const IPC = {
  //////////
  // FILES
  //////////
  DOWNLOAD_FILE: 'download-file',
  DOWNLOAD_FILE_DONE: 'download-file-done',
  DOWNLOAD_ALL_FILES: 'download-all-files',
  DOWNLOAD_PUBLIC_FILE: 'download-public-file',
  FILE_EXISTS: 'file-exists',
  FILE_EXISTS_DONE: 'file-exists-done',
  WRITE_FILE: 'write-file',
  FILE_WRITE_DONE: 'file-write-done',
  GET_FILE_PATH_FROM_DIALOG: 'get-file-path-from-dialog',
  GET_FILE_PATH_FROM_DIALOG_DONE: 'get-file-path-from-dialog-done',
  READ_FILE: 'read-file',
  FILE_READ_DONE: 'file-read-done',
  RENAME_FILE: 'rename-file',
  FILE_RENAME_DONE: 'file-rename-done',
  CREATE_PATH: 'create-path',
  CREATE_PATH_DONE: 'create-path-done',
  DELETE_FILES: 'delete-files',
  DELETE_FILES_DONE: 'delete-files-done',
  GET_DIR_FILES: 'get-dir-files',
  GET_DIR_FILES_DONE: 'get-dir-files-done',
  APPEND_TO_FILE: 'append-to-file',
  APPEND_TO_FILE_DONE: 'append-to-file-done',

  /////////////////////////
  // APP STATE AND ACTIONS
  /////////////////////////
  UPDATE_BADGE_COUNT: 'update-badge-count',
  SET_FAVORITES: 'set-favorites',
  SET_FAVORITES_ROUTE: 'set-favorites-route',
  ON_DRAG_START: 'ondragstart',
  CLEAR_DRAG_DROP_FOLDER: 'clear-drag-drop-folder',
  LINK_CLICKED: 'link-clicked',
  FOCUS_ON_APP: 'focus-on-app',
  CHANGE_THEME: 'change-theme',
  TOGGLE_DARK: 'toggle-dark',
  TOGGLE_DEV_TOOLS: 'toggle-dev-tools',

  /////////////////////////////
  // RELOAD, SAVE, DROP, ...
  /////////////////////////////
  CLEAR_STORAGE_DATA: 'clear-storage-data',
  REDO_INIT_SYNC: 'redo-init-sync',
  FLUSH_DATABASE: 'flush-database',
  DB_SAVE_ALL: 'db-save-all',
  CHANGE_API: 'change-api',
  RELOAD: 'reload',
  AFTER_DB_SAVE_ALL: 'after-db-save-all',
  QUIT_AND_INSTALL: 'quit-and-install',

  ///////////////////////////
  // SET VARIABLES OR FLAGS
  ///////////////////////////
  SET_ENVIRONMENT: 'set-environment',
  SET_LOGGED_IN_USERS: 'set-logged-in-users',
  SET_LOGGER_USER: 'set-logger-user',
  SET_LOGGING_DISABLED: 'set-logging-disabled',
  CHECK_IF_FRESH_INSTALL: 'check-if-fresh-install',
  SET_SPELLCHECKER: 'set-spellchecker',

  /////////////////
  // DIALOG WINDOW
  /////////////////
  DIALOG_SEND_WINDOW: 'DIALOG:send-window',
  DIALOG: 'DIALOG:',
  DIALOG_PROXY: 'DIALOG:proxy',

  /////////////
  // DEEPLINKS
  /////////////
  DEEPLINK_PROCESSED: 'DEEPLINK:processed',
  DEEPLINK_EXTENDED_ONBOARDING: 'DEEPLINK:extended-onboarding',
  DEEPLINK_PING: 'DEEPLINK:ping',
  DEEPLINK_CHECK_FOR_UPDATES: 'DEEPLINK:check-for-updates',
  DEEPLINK: 'DEEPLINK:intheloop',
  MAILTO: 'mailto',
  OPEN_MAIL_FILE: 'openMailFile',

  ////////////////////////
  // NATIVE WINDOW EVENTS
  ////////////////////////
  REGISTER_WINDOW_CLOSE_EVENT: 'register-window-close-event',
  CLOSE_WINDOW_WITHOUT_CONFIRM: 'close-window-without-confirm',
  REGISTER_CLOSE_WINDOW_WITHOUT_CONFIRM: 'register-close-window-without-confirm',

  ///////////
  // UPDATER
  ///////////
  UPDATE_COMPLETE: 'update-complete',
  DOWNLOAD_PROGRESS: 'download-progress',
  UPDATE_DOWNLOAD_ERROR: 'update-download-error',
  INSTALL_UPDATE: 'install-update',
  TRACK_UPDATER: 'IPC:track:updater',

  /////////////
  // SHORTCUTS
  /////////////
  NEW_EMAIL: 'new-email',
  THREAD_REPLY: 'thread-reply',
  THREAD_FORWARD: 'thread-forward',
  THREAD_PRINT: 'thread-print',
  OPEN_FAVORITE: 'open-favorite',
  OPEN_PREFERENCES: 'open-preferences',
  OPEN_UPDATER: 'open-updater',
  CONTEXT_MENU: 'context-menu',

  /////////////////////////////
  // RENDERER PROCESS HELPER
  /////////////////////////////
  HOT_WINDOW_REQUEST: 'hot-window-request',
  BROWSER_WINDOW_REQUEST: 'browser-window-request',
  GENERIC_REMOTE_METHOD: 'generic-remote-method',
  FILE_LOGGER: 'file-logger',

  /////////
  // OTHER
  /////////
  UNFURL_REQUEST: 'unfurl-request',
  UNFURL_DONE: 'unfurl-done',
  REMOVE_OLD_FILES_FOLDER: 'remove-old-files-folder',
  OPEN_COMMENT_IN_BROWSER: 'open-comment-in-browser',
  HIDE_BACKEND_WINDOW: 'hide-backend-window',
  LOG_MAIN_TO_FILE: 'log-main-to-file',
  LOG_RESOURCE_USAGE: 'log-resource-usage',
  MAIN_PROCESS_TRACKING: 'main-process-tracking',
  SWITCH_SPELLCHECKER_LANGUAGE: 'switch-spellchecker-language',
  REMOVED_WINDOW_IDS: 'removed-window-ids',
};

export enum REMOTE_ELECTRON_METHODS {
  GET_MACHINE_ID = 'getMachineId',
  GET_MACHINE_IP = 'getMachineIp',
  REMOTE_QUITE_THIS_WINDOW = 'remoteQuitThisWindow',
  REPLACE_MISSPELLING = 'replaceMisspelling',
  SET_SPELLCHECKER = 'setSpellchecker',
  SHRINK_LOGIN_WINDOW = 'shrinkLoginWindow',
  EXPAND_LOGIN_WINDOW = 'expandLoginWindow',
  SET_NATIVE_THEME = 'setNativeTheme',
  HANDLE_DOUBLE_CLICK = 'handleDoubleClick',
  SHOW_ITEM_IN_FOLDER = 'showItemInFolder',
  IS_FOR_E2E = 'isForE2E',
  GET_PROCESS_DATA = 'getProcessData',
  OPEN_EXTERNAL = 'openExternal',
  OPEN_FILE_IN_NATIVE_APP = 'openFileInNativeApp',
  GET_STATISTICS = 'getStatistics',
  checkForUpdates = 'checkForUpdates',
  SET_SPELLCHECKER_LANGUAGES = 'setSpellCheckerLanguages',
  GET_SPELLCHECKER_LANGUAGES = 'getSpellCheckerLanguages',
  DISABLE_SPELL_CHECKER = 'disableSpellChecker',
  READ_MAIL_FILE = 'readMailFile',
  OPEN_COMPOSER = 'openComposer',
}
