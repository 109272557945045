import { Injectable, NgModule } from '@angular/core';
import { CardExDecorateService } from './card-decorator/card-ex-decorate.service';
import { CommentExDecorateService } from './comment-ex-decorator/comment-ex-decorate.service';
import { ContactExDecorateService } from './contact-ex-decorator/contact-ex-decorate.service';
import { FileExDecorateService } from './file-ex-decorator/file-ex-decorate.service';
import { SharedTagExDecorateService } from './shared-tag-ex-decorator/shared-tag-ex-decorate.service';

@Injectable()
export class ExDecoratorService {
  constructor(
    public ContactExDecorateService: ContactExDecorateService,
    public SharedTagExDecorateService: SharedTagExDecorateService,
    public CardExDecorateService: CardExDecorateService,
    public CommentExDecorateService: CommentExDecorateService,
    public FileExDecorateService: FileExDecorateService,
  ) {}
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ExDecoratorService,
    ContactExDecorateService,
    SharedTagExDecorateService,
    CardExDecorateService,
    CommentExDecorateService,
    FileExDecorateService,
  ],
})
export class ExDecoratorModule {}
