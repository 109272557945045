import { Directive, ElementRef, inject, Injector, Input } from '@angular/core';
import { InitializedDropdown } from '@shared/ui/dropdown/interfaces/dropdown-initializers';
import { DropdownTrigger } from '@shared/ui/dropdown/enumerators/dropdown-trigger';
import { DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isWebApp } from '@dta/shared/utils/common-utils';
import { DROPDOWN_INITIALIZERS } from '../injection-tokens/dropdown-initializers';

@Directive()
@UntilDestroy()
export abstract class DropdownBase {
  protected abstract getTrigger(): DropdownTrigger;

  private readonly initializedDropdown: InitializedDropdown | null = inject(DROPDOWN_INITIALIZERS)[this.getTrigger()](
    inject(Injector),
    inject(ElementRef, { self: true, host: true }),
    { disabledOnBrowser: isWebApp() }
  );

  @Input() set loopDropdownPosition(position: 'left' | 'right') {
    this.initializedDropdown?.setPosition(position);
  }

  @Input() set loopDropdownDisabled(isDisabled: boolean) {
    this.initializedDropdown?.setIsDisabled(isDisabled);
  }

  constructor() {
    this.observeDropdownInitialization();
  }

  protected setItems(items: readonly DropdownItem[]): void {
    this.initializedDropdown?.setItems(items);
  }

  /**
   * Observe dropdown menu trigger changes emitted by external code.
   * This is essential for native menu to open and close properly when desired.
   */
  private observeDropdownInitialization(): void {
    this.initializedDropdown?.dropdown$.pipe(untilDestroyed(this)).subscribe(() => {
      this.initializedDropdown.focus();
    });
  }
}
