import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { SelectControlOption } from '@shared/modules/form-controls/select-control/select-control.component';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'loop-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectItemComponent<V extends string | number | boolean, T = any> implements OnInit {
  private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private ngZone: NgZone = inject(NgZone);

  protected readonly checkMarkSvg: NodeRequire = require('shared/assets/img/svg/filter-chooser-checkmark.svg');

  @Input() option: SelectControlOption<T, V>;
  @Input() isSelected: boolean;
  @Input() customOptionTemplate?: TemplateRef<{ $implicit: SelectControlOption<T> }>;

  @Output() itemClick: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.elementRef.nativeElement, 'click')
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.ngZone.run(() => this.itemClick.emit());
        });

      // fromEvent(this.elementRef.nativeElement, 'mouseenter')
      //   .pipe(untilDestroyed(this))
      //   .subscribe(() => {
      //     if (!this.disabled) {
      //       this.ngZone.run(() => this.itemHover.emit(this.value));
      //     }
      //   });
    });
  }
}
