import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';

@Component({
  selector: 'create-channel-sidebar',
  styleUrls: ['./create-channel-sidebar.scss'],
  host: { class: 'right-sidebar' },
  template: `
    <!-- Step -->
    <ng-template ngFor [ngForOf]="steps" let-_step let-i="index">
      <div *ngIf="_step && !_step.hidden" class="step">
        <div class="number" [class.active]="i === step">
          {{ i + 1 }}
        </div>

        <span>{{ _step.id }}</span>
      </div>
    </ng-template>

    <!-- Action error -->
    <div *ngIf="currentActionErrorMessage" class="action-error">
      {{ currentActionErrorMessage }}
    </div>

    <!-- Action -->
    <button
      *ngIf="nextButtonText"
      class="btn button primary-green next-complete-btn"
      [class.in-progress]="isActionInProgress"
      [class.disabled]="offline"
      [class.no-error]="!currentActionErrorMessage"
      [trackUserClick]="{
        forUserEmail: currentUserEmail,
        location: trackingLocation,
        action: nextButtonText,
        additionalProperties: additionalTrackingProperties
      }"
      [tooltip]="{
        content: offline ? 'You need to be online to continue' : undefined,
        placement: TooltipPlacement.TOP
      }"
      (click)="!offline && nextStep()"
    >
      <loader *ngIf="isActionInProgress" [style]="'button-circle'"> </loader>

      {{ nextButtonText }}
    </button>
  `,
})
export class CreateChannelSidebarComponent {
  TooltipPlacement = TooltipPlacement;

  @Input() step: number;
  @Input() steps: string[];
  @Input() currentActionErrorMessage: string;
  @Input() nextButtonText: string;
  @Input() isActionInProgress: boolean;
  @Input() channelName: string;
  @Input() currentUserEmail: string;
  @Input() trackingLocation: string;
  @Input() additionalTrackingProperties: Object;

  @Output() nextStep$ = new EventEmitter<void>();

  nextStep() {
    this.nextStep$.emit();
  }
}
