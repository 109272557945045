import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ProcessType, StopWatch } from '../../../../../dta/shared/utils/stop-watch';
import { FetchRequestCard, FetchResult } from '../../../../../dta/shared/models/collection.model';
import { from, Observable, of, switchMap } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { CommentBaseModel, CommentModel } from '../../../../../dta/shared/models-api-loop/comment/comment.model';
import { CardBase } from '@shared/api/api-loop/models/card-base';
import { CardBaseModel, CardModel } from '../../../../../dta/shared/models-api-loop/conversation-card/card/card.model';
import { CardApiService } from '@shared/api/api-loop/services';
import { CardService } from '@shared/services/data/card/card.service';
import { BaseCollectionService } from '@shared/services/data/collection/base-collection/base-collection.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { Environment, EnvironmentType } from '@dta/shared/utils/common-utils';

@Injectable()
export class CardCollectionService extends BaseCollectionService {
  constructor(
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
    private _cardApiService: CardApiService,
  ) {
    super(_commentService, _cardService, _contactService);
  }

  get constructorName(): string {
    return 'CardCollectionService';
  }

  //////////////////////////
  // FETCH COMMENT BY CARD
  //////////////////////////
  fetchCommentsByCard(forUserEmail: string, fetchRequest: FetchRequestCard): Observable<FetchResult> {
    let watch = new StopWatch(this.constructorName + '.fetchCommentsByCard', ProcessType.SERVICE, forUserEmail);

    return of(undefined).pipe(
      /**
       * Fetch card
       */
      mergeMap(() => {
        watch.log('will get card');
        if (Environment.getEnvironment() === EnvironmentType.WEB_APP) {
          fetchRequest.apiParams.includeSignedLinks = true;
        }
        return this._cardApiService.Card_Get(fetchRequest.apiParams, forUserEmail);
      }),
      map(CardBaseModel.create),
      mergeMap((card: CardModel) => {
        watch.log('will process card');
        return this.processComments(card, forUserEmail, watch);
      }),
      /**
       * Return
       */
      map((comments: CommentModel[]) => {
        watch.log('done');

        let fetchResult: FetchResult = {
          offsetHistoryId: undefined,
          dataLength: comments.length,
          hasData: comments.length > 0,
        };

        return fetchResult;
      }),
    );
  }

  private processComments(card: CardModel, forUserEmail: string, watch: StopWatch): Observable<CommentModel[]> {
    if (_.isEmpty(card) || _.isEmpty(card.getComments())) {
      return of([]);
    }

    let comments: CommentModel[] = CommentBaseModel.createList(card.getComments());
    let cardRef: CardBase = card;
    comments = comments.map(comment => {
      comment.parent = cardRef;
      return comment;
    });

    return this._commentService.updateCommentsBody(forUserEmail, comments).pipe(
      switchMap((_comments: CommentModel[]) => {
        watch.log('saveAllAndPublish');
        return this._commentService.saveAllAndPublish(forUserEmail, _comments);
      }),
    );
  }
}
