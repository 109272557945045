import { Component } from '@angular/core';
import {
  BaseFormControlComponent,
  provideControlValueAccessor
} from '@shared/modules/form-controls/base-form-control.component';

@Component({
  selector: 'loop-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss'],
  providers: [provideControlValueAccessor(DateControlComponent)]
})
export class DateControlComponent extends BaseFormControlComponent<Date> {
  protected focusHandler(): void {
    //noop
  }
}
