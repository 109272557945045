import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderPipe } from '@dta/ui/pipes/folder/folder.pipe';

@NgModule({
  declarations: [FolderPipe],
  imports: [CommonModule],
  exports: [FolderPipe],
})
export class FolderPipeModule {}
