import { EnumObjectValue } from '@shared/utils/common/types';
import { ResourceBaseType } from '@shared/api/common/contatns/ResourceBaseType';
import { RESOURCE_BASE_TYPE_TO_ACTION } from '@shared/services/communication/shared-subjects/common/interfaces/resource-base-type-to-action';
import { ModelActionData } from '@shared/services/communication/shared-subjects/shared-subjects-models';

export function isModelAction<
  RESOURCE extends EnumObjectValue<typeof ResourceBaseType>,
  ACTION extends RESOURCE_BASE_TYPE_TO_ACTION<RESOURCE>
>(
  modelActionData: ModelActionData<RESOURCE, ACTION>,
  resource: RESOURCE,
  action: ACTION
): modelActionData is ModelActionData<RESOURCE, ACTION> {
  return modelActionData.resource === resource && modelActionData.action === action;
}
