import { Component, Input } from '@angular/core';

export type ButtonFill = 'color' | 'outline' | 'transparent' | 'link';
export type ButtonColor = 'primary' | 'systemContrast' | 'danger' | 'warning' | 'success' | 'light';
export type ButtonSize = 'normal' | 'large' | 'small';
export type ButtonType = 'button' | 'submit';

@Component({
  selector: 'loop-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() fill: ButtonFill = 'color';
  @Input() color: ButtonColor = 'primary';
  @Input() size: ButtonSize = 'normal';
  @Input() type: ButtonType = 'button';
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() hasSmallBorder: boolean = false;
  @Input() allowTabIndex: boolean = true;
}
