import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { DatabaseService } from '@shared/database/database.service';
import { Logger } from '@shared/services/logger/logger';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { DatabaseServiceWeb } from './database.service.web';

@Injectable()
export class DatabaseFactoryWeb extends DatabaseFactory {
  protected get constructorName(): string {
    return 'DatabaseFactoryWeb';
  }

  destroy(forUserEmail: string): Observable<any> {
    let db: DatabaseService = this.forUser(forUserEmail);

    return (db ? db.dropDatabase() : DatabaseServiceWeb.dropDatabaseByName(this.getDatabaseKey(forUserEmail))).pipe(
      tap(() => {
        delete this._instances[forUserEmail];
        Logger.log(`${this.constructorName} [${forUserEmail}]: destroyed DB`);
      }),
    );
  }

  protected openDB(userEmail: string): Observable<DatabaseService> {
    return of(undefined).pipe(
      /**
       * Create new database
       */
      mergeMap(() => of(new DatabaseServiceWeb(this.getDatabaseKey(userEmail)))),
    );
  }
}
