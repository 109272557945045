import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { FileModel } from 'dta/shared/models-api-loop/file.model';
import { TagType } from '@shared/api/api-loop/models/tag-type';
import { FileViewDecorateService } from '../file-view-decorator/file-view-decorate.service';
import { BaseViewDecorateService } from '../base-view-decorate.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { SharedTagReactionModel, StaticSharedTagIds } from 'dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import {
  CommentBaseViewData,
  CommentChatModel,
  CommentMailModel,
  CommentModel,
  CommentQuoteViewData,
  ReactionData,
} from '@dta/shared/models-api-loop/comment/comment.model';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Injectable()
export class CommentViewDecorateService extends BaseViewDecorateService<CommentModel> {
  constructor(
    private _fileDecorateService: FileViewDecorateService,
    private _userManagerService: UserManagerService,
  ) {
    super();
  }

  decorateViewData(foruserEmail: string, comment: CommentModel, force?: boolean): Observable<CommentModel> {
    if (comment._ui && !force) {
      return of(comment);
    }

    let ui: CommentBaseViewData = comment._ui || {
      attachmentsSynchronized: false,
      isUnread: false,
      isTrashed: false,
      isDeleted: false,
      isForwardedCopy: false,
      isVisiblyUnread: false,
      recalculateCount: 0,
      expanded: false,
    };

    let attachments = this.getAttachments(comment);
    return this._fileDecorateService.decorateListViewData(foruserEmail, attachments, force).pipe(
      /**
       * Decorate attachments ui data
       */
      tap((attachments: FileModel[]) => {
        if (!_.isEmpty(attachments)) {
          comment.attachments.resources = attachments;
        }

        ui.attachmentsSynchronized =
          _.isEmpty(attachments) || _.every(attachments, file => file._ex?.syncStatus?.binary);
      }),
      /**
       * Decorate other ui data
       */
      tap(() => {
        ui.isUnread = comment.hasTagId(TagType.UNREAD) || comment.hasTagId(TagType.UNREAD_VIRTUAL);
        ui.isVisiblyUnread = ui.isUnread;
        ui.isTrashed = comment.hasTagId(TagType.DELETED);
        ui.isDeleted = comment.hasSharedTagId(StaticSharedTagIds.DELETED_ID);
        ui.isForwardedCopy = comment instanceof CommentMailModel && comment.forwardedCopy;
      }),
      /**
       * Return decorated comment
       */
      mergeMap(() => {
        comment._ui = ui;

        if (comment instanceof CommentChatModel) {
          return this.decorateChatViewData(foruserEmail, comment);
        } else {
          return of(comment);
        }
      }),
    );
  }

  private getAttachments(comment: CommentModel): FileModel[] {
    return FileModel.createList(comment.getResources(comment.attachments));
  }

  private getQuotedAttachments(comment: CommentChatModel): FileModel[] {
    return FileModel.createList(comment.getQuotedAttachmentResources());
  }

  private decorateChatViewData(foruserEmail: string, comment: CommentChatModel): Observable<CommentChatModel> {
    comment._ui.isEdited = parseInt(comment.revision, 10) > 1;

    return this.decorateQuotedComment(foruserEmail, comment).pipe(
      mergeMap((_comment: CommentChatModel) => {
        return this.decorateReactions(foruserEmail, _comment);
      }),
    );
  }

  private decorateQuotedComment(foruserEmail: string, comment: CommentChatModel): Observable<CommentChatModel> {
    if (!comment.quotedComment) {
      return of(comment);
    }

    let ui: CommentQuoteViewData = {
      isDeleted: false,
      isEdited: false,
    };

    ui.isDeleted = comment.quotedComment.hasSharedTagId(StaticSharedTagIds.DELETED_ID);
    ui.isEdited = parseInt(comment.quotedComment.revision, 10) > 1;

    let attachments = this.getQuotedAttachments(comment);

    return this._fileDecorateService.decorateListViewData(foruserEmail, attachments).pipe(
      map((attachments: FileModel[]) => {
        comment.quotedComment.attachments.resources = attachments;
        return comment;
      }),
    );
  }

  private decorateReactions(foruserEmail: string, comment: CommentChatModel): Observable<CommentChatModel> {
    let currentUserId = this._userManagerService.getCurrentUserId();
    if (!comment.hasReactions()) {
      return of(comment);
    }

    let reactionDataByReaction: { [reaction: string]: ReactionData } = {};

    let reactionTags = comment.sharedTags.tags.resources.filter(tag => tag.$type === SharedTagReactionModel.type);

    _.forEach(reactionTags, (tag: SharedTagReactionModel) => {
      let isMyReaction = tag.userId === currentUserId;
      let reactorName = isMyReaction ? 'you' : tag.reactorName;

      if (!reactionDataByReaction[tag.name]) {
        reactionDataByReaction[tag.name] = {
          reactorNames: [reactorName],
          isMyReaction,
        };
      } else {
        // We want 'you' always at the end of the list
        reactionDataByReaction[tag.name].reactorNames[isMyReaction ? 'push' : 'unshift'](reactorName);
        reactionDataByReaction[tag.name].isMyReaction = reactionDataByReaction[tag.name].isMyReaction || isMyReaction;
      }
    });

    comment._ui.reactionDictionary = reactionDataByReaction;
    return of(comment);
  }

  setSummaryText(comments: CommentChatModel[], firstComment: CommentChatModel, conversation: ConversationModel) {
    comments.forEach(comment => {
      comment._ui.summaryText = firstComment._id === comment._id ? this.getSummaryText(comment, conversation) : '';

      comment._ui.hasBubbleContent = this.getHasBubbleContent(comment);
    });
  }

  private getHasBubbleContent(comment: CommentChatModel) {
    return !(comment._ui.summaryText && comment._ui.summaryText !== '' && comment.comment === '');
  }

  private getSummaryText(comment: CommentChatModel, conversation: ConversationModel): string {
    let firstShareList = comment.shareList?.resources?.filter(contact => {
      return contact.id !== comment.author.id;
    });

    if (_.isEmpty(firstShareList)) {
      return '';
    }

    let length = firstShareList.length;
    let text = " shared '" + conversation.name + "' email with ";

    _.forEach(firstShareList, (contact, i: number) => {
      if (length === 1) {
        text += this.getContactName(contact, false);
      } else if (length > 0 && i === 0) {
        text += this.getContactName(contact, true);
      }
      if (length === 2 && i === 1) {
        text += ' and ' + this.getContactName(contact, true);
      }
      if (length === 3) {
        if (i === 1) {
          text += ', ' + this.getContactName(contact, true);
        } else if (i === 2) {
          text += ' and ' + this.getContactName(contact, true);
        }
      }
      if (length > 3) {
        if (i === 1) {
          text += ', ' + this.getContactName(contact, true);
        }
      }
    });

    if (length > 3) {
      text += ' and ' + (length - 2) + ' others';
    }

    text += '.';

    return text;
  }

  private getContactName(contact: ContactBase, firstNameOnly: boolean = false): string {
    if (!contact) {
      return '';
    }

    let currentUserId = this._userManagerService.getCurrentUserId();

    if (currentUserId && currentUserId === contact.id) {
      return 'me';
    }
    if (firstNameOnly && contact.$type === UserModel.type && contact.name) {
      return contact.name.split(' ')[0];
    }

    return contact.name;
  }
}
