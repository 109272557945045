import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ListOfTagsModel, TagLabelModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { StateUpdates } from '@dta/shared/models/state-updates';
import { HistoryAndSignatureHelper } from '@dta/shared/utils/history-and-signature/history-and-signature.helper';
import { HtmlFormattingHelper } from '@dta/shared/utils/html-formatting/html-formatting.helper';
import {
  CardBase,
  CommentBase,
  CommentBody,
  CommentOutOfOffice,
  ContactBase,
  ListOfResourcesOfCardBase,
  ListOfResourcesOfCommentBase,
  ListOfTags,
  QueryRelation,
  TagType,
  User
} from '@shared/api/api-loop/models';
import { CardApiService, CommentApiService, FileApiService } from '@shared/api/api-loop/services';
import { UnfurlData } from '@shared/models/unfurl/unfurl.model';
import {
  CardBaseModel,
  CardChatModel,
  CardDraftModel,
  CardModel
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import {
  CommentBaseModel,
  CommentChatModel,
  CommentDraftModel,
  CommentMailModel,
  CommentModel,
  CommentTemplateModel,
  MimeType,
  UndoData
} from 'dta/shared/models-api-loop/comment/comment.model';
import {
  ContactBaseModel,
  ContactModel,
  GroupModel,
  UserModel
} from 'dta/shared/models-api-loop/contact/contact.model';
import { FileModel } from 'dta/shared/models-api-loop/file.model';
import { RecentSearchFilter, SearchFilterType } from 'dta/shared/models/search.model';
import { concat, EMPTY, forkJoin, from, Observable, of, Subject, switchMap, throwError, zip } from 'rxjs';
import { BaseService } from '../base/base.service';
import { BuildCommentsChatsData, CommentServiceI } from './comment.service.interface';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { Logger } from '@shared/services/logger/logger';
import { HtmlImageService } from '../html-image/html-image.service';
import { ChannelChatsCollectionParams } from '@dta/ui/collections/comments/channel-chats.collection';
import {
  bufferCount,
  catchError,
  concatMap,
  defaultIfEmpty,
  distinct,
  filter,
  map,
  mergeMap,
  pluck,
  tap,
  toArray
} from 'rxjs/operators';
import { RetryModel, RetryType } from '@dta/shared/models-api-loop/retry.model';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { CommentExDecorateService } from '@shared/decorators/extra-data-decorators/comment-ex-decorator/comment-ex-decorate.service';
import { CommentBodyHelperService } from './comment-body-helper/comment-body-helper.service';
import { ContactService } from '../contact/contact.service';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { CommentPopulateService } from '@shared/populators/comment-populate/comment-populate.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { CommentDaoService } from '@shared/database/dao/comment/comment-dao.service';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { FileService } from '../file/file.service';
import { CONSTANTS } from '@shared/models/constants/constants';
import { FileExDecorateService } from '@shared/decorators/extra-data-decorators/file-ex-decorator/file-ex-decorate.service';
import { SharedTagLabelModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { FileDownloadService } from '@shared/modules/files/shell/services/file-download.service';
import { SyncSettingsType } from '@dta/shared/models/sync-settings-type.model';
import { getBuffer } from '@dta/shared/utils/common-utils';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { FileUrlEntryI } from '../../../../web/app/services/file-storage/file-url-storage/file-url-storage.service';
// @ts-ignore
import DOMPurify from 'dompurify';
import { ADDITIONAL_ATTR, DOM_PURIFY_CONFIG } from '@shared/modules/comments/common/constants/dom-purify-config';

@Injectable()
export abstract class CommentService extends BaseService implements CommentServiceI {
  fetchingComments: Dictionary<Subject<CommentModel[]>> = {};

  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _htmlFormattingHelper: HtmlFormattingHelper,
    protected _historyAndSignatureHelper: HistoryAndSignatureHelper,
    protected _htmlImageService: HtmlImageService,
    protected _commentApiService: CommentApiService,
    protected _commentExDecorateService: CommentExDecorateService,
    protected _commentBodyHelperService: CommentBodyHelperService,
    protected _contactService: ContactService,
    protected _fileStorageService: FileStorageService,
    protected _cardApiService: CardApiService,
    protected _commentPopulateService: CommentPopulateService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _commentDaoService: CommentDaoService,
    protected _fileApiService: FileApiService,
    protected _fileService: FileService,
    protected _fileExDecorateService: FileExDecorateService,
    protected httpClient: HttpClient,
    protected fileDownloadService: FileDownloadService
  ) {
    super(_syncMiddleware);

    _commentPopulateService.commentService = this;
  }

  get constructorName(): string {
    return 'CommentService';
  }

  static isHtmlNotAvailableYetError(err: HttpErrorResponse) {
    return err.status === 503 && err.error.errorCode === 'HtmlNotAvailableYet';
  }

  findCurrentUserLastComment(forUserEmail: string, comments: CommentChatModel[]): CommentChatModel {
    return _.findLast(
      comments,
      comment => comment.author.email === forUserEmail && !comment.isSystemMessage() && !comment.hasAttachments()
    );
  }

  save(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    return this.saveAllAndPublish(forUserEmail, [comment]).pipe(map((comments: CommentModel[]) => _.first(comments)));
  }

  protected saveToDb(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return this._commentDaoService.saveAll(forUserEmail, comments);
  }

  saveAll(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    if (_.isEmpty(comments)) {
      return of(comments);
    }

    comments = CommentBaseModel.createList(comments);
    let watch = new StopWatch(this.constructorName + '.saveAll:' + comments.length, ProcessType.SERVICE, forUserEmail);

    watch.log('doBeforeSave');
    return this.doBeforeSave(forUserEmail, comments).pipe(
      mergeMap((comments: CommentModel[]) => {
        watch.log('saveAllAndPublish');
        return this.saveToDb(forUserEmail, comments);
      }),
      mergeMap((comments: CommentModel[]) => {
        watch.log('doAfterSave');
        return this.doAfterSave(forUserEmail, comments);
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  saveAndPublish(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    return this.saveAllAndPublish(forUserEmail, [comment]).pipe(map(_.first));
  }

  saveAllAndPublish(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    if (_.isEmpty(comments)) {
      return of(comments);
    }

    comments = CommentBaseModel.createList(comments);
    let watch = new StopWatch(
      this.constructorName + '.saveAllAndPublish:' + comments.length,
      ProcessType.SERVICE,
      forUserEmail
    );

    watch.log('saveAll');
    return this.saveAll(forUserEmail, comments).pipe(
      tap((comments: CommentModel[]) => {
        watch.log('publish');
        let stateUpdates = new StateUpdates(comments);
        PublisherService.publishStateUpdates(forUserEmail, stateUpdates);
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  protected doBeforeSave(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    let watch = new StopWatch(
      this.constructorName + '.doBeforeSave:' + comments.length,
      ProcessType.SERVICE,
      forUserEmail
    );
    watch.log('decorateListExtraData');
    return this._commentBodyHelperService.processCommentsBody(forUserEmail, comments).pipe(
      // mergeMap((comments: CommentModel[]) => {
      //   watch.log('decorateBodyComplexityIndexList');
      //   return this._commentExDecorateService.decorateBodyComplexityIndexList(forUserEmail, comments);
      // }),
      mergeMap((comments: CommentModel[]) => {
        watch.log('writeCommentsBodyToDisk');
        return this.writeCommentsBodyToDisk(comments);
      }),
      mergeMap((comments: CommentModel[]) => {
        watch.log('transformContactsToBaseForm');
        return this._commentPopulateService.reduce(forUserEmail, comments);
      })
    );
  }

  protected doAfterSave(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return of(comments).pipe(
      mergeMap((comments: CommentModel[]) => {
        return this._commentPopulateService.populate(forUserEmail, comments);
      }),
      // mergeMap((comments: CommentModel[]) => {
      //   return this._contactService.publishContactsFromComments(forUserEmail, comments);
      // }),
      mergeMap((comments: CommentModel[]) => {
        return this.updateLinkedQuotes(forUserEmail, comments);
      })
    );
  }

  private updateLinkedQuotes(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    let commentIds = [];

    _.forEach(comments, (comment: CommentModel) => {
      if (comment instanceof CommentChatModel && comment.id) {
        commentIds.push(comment.id);
      }
    });

    if (commentIds.length === 0) {
      return of(comments);
    }

    return this.findCommentsByQuoteIds(forUserEmail, commentIds).pipe(
      map((_comments: CommentModel[]) => {
        let stateUpdates = new StateUpdates();
        stateUpdates.add(_comments);
        PublisherService.publishStateUpdates(forUserEmail, stateUpdates);
        return comments;
      })
    );
  }

  private writeCommentsBodyToDisk(comments: CommentModel[]): Observable<CommentModel[]> {
    return from(comments).pipe(
      map((comment: CommentModel) => {
        return this.writeCommentBodyToDisk(comment);
      }),
      toArray()
    );
  }

  private writeCommentBodyToDisk(comment: CommentModel): CommentModel {
    if (
      !(comment instanceof CommentMailModel) ||
      comment instanceof CommentDraftModel ||
      !this.shouldWriteBodyToDisk(comment)
    ) {
      return comment;
    }

    this._fileStorageService.writeCommentBody(comment);

    // Replace body content with placeholder, so we know to load it from disc
    comment.body.content = CONSTANTS.BODY_SAVED_ON_DISC_HTML;

    return comment;
  }

  protected shouldWriteBodyToDisk(comment: CommentMailModel): boolean {
    return (
      !(comment instanceof CommentDraftModel) &&
      comment.id &&
      comment.body.content &&
      comment.body.content.length > 1024
    );
  }

  createMail(forUserEmail: string, comment: CommentMailModel): Observable<CommentMailModel> {
    // this.validateCommentOrThrow(comment);

    return this.save(forUserEmail, comment).pipe(
      map((comment: CommentModel) => {
        return <CommentMailModel>comment;
      })
    );
  }

  createComments(forUserEmail: string, comments: CommentChatModel[]): Observable<CommentChatModel[]> {
    comments.forEach(comment => {
      this.validateCommentOrThrow(comment);
    });

    return this.saveAllAndPublish(forUserEmail, comments).pipe(
      map((_comments: CommentModel[]) => {
        return <CommentChatModel[]>_comments;
      })
    );
  }

  createLoopin(
    forUserEmail: string,
    comment: CommentChatModel,
    sourceResourceId?: string,
    labels?: (SharedTagLabelModel | TagLabelModel)[]
  ): Observable<CommentChatModel> {
    this.validateCommentOrThrow(comment);

    return this.save(forUserEmail, comment).pipe(
      map((comment: CommentModel) => {
        return <CommentChatModel>comment;
      }),
      tap((comment: CommentChatModel) => {
        // Append data to send to Backend on sync
        comment._ex.sourceResourceId = sourceResourceId;
        comment._ex.labels = labels;

        PublisherService.publishEvent(forUserEmail, comment);
      })
    );
  }

  removeComments(forUserEmail: string, comments: CommentModel[]): Observable<any> {
    if (_.isEmpty(comments)) {
      return of([]);
    }

    return from(comments).pipe(
      mergeMap((comment: CommentModel) => {
        return this.removeComment(forUserEmail, comment);
      }),
      toArray(),
      defaultIfEmpty([])
    );
  }

  removeComment(forUserEmail: string, comment: CommentModel, undoSent?: boolean): Observable<any> {
    return this._commentDaoService.removeById(forUserEmail, comment._id).pipe(
      tap(() => {
        this.dequeuePushSynchronization(forUserEmail, comment);

        if (undoSent && comment.$type === CommentMailModel.type) {
          (<CommentMailModel>comment)._ex.undoData.undoSent = undoSent;
        }
        PublisherService.publishEvent(forUserEmail, comment, PublishEventType.Remove);
      })
    );
  }

  hasBody(comment: CommentModel): boolean {
    if (!comment || !comment.id) {
      return true;
    }

    // DRAFT
    if (comment instanceof CommentDraftModel) {
      return comment.hasBody();
    }

    // CHAT
    if (comment instanceof CommentChatModel) {
      return comment.hasBody() || comment.hasAttachments();
    }

    // MAIL
    if (!comment.body || _.isNil(comment.body.content)) {
      return false;
    }

    return !comment.supportsHtmlBody() || (comment.body.mimeType === MimeType.html && comment.hasBody());
  }

  findByIdOrFetchAndSave(forUserEmail: string, id: string): Observable<CommentModel> {
    return this.findById(forUserEmail, id).pipe(
      catchError(err => {
        if (err.status === 404) {
          return this.fetchCommentAsBBtagById(forUserEmail, id).pipe(
            mergeMap(comment => {
              return this.save(forUserEmail, comment);
            })
          );
        }
      })
    );
  }

  private fetchCommentAsBBtagById(forUserEmail: string, id: string): Observable<CommentModel> {
    let params: CommentApiService.Comment_GetParams = { id };
    return this._commentApiService.Comment_Get(params, forUserEmail).pipe(
      map((comment: CommentBase) => {
        return CommentBaseModel.create(comment);
      })
    );
  }

  abstract saveUnfurlDataById(forUserEmail: string, id: string, unfurlData: UnfurlData): Observable<CommentChatModel>;

  abstract findCommentsForSearch(
    forUserEmail: string,
    query: string,
    offset: number,
    size: number,
    filter?: RecentSearchFilter
  ): Observable<CommentModel[]>;

  fetchCommentsForSearch(
    forUserEmail: string,
    query: string,
    offset: number,
    size: number,
    filter?: RecentSearchFilter
  ): Observable<CommentModel[]> {
    let currentUserId = this._sharedUserManagerService.getUserIdByEmail(forUserEmail);
    let queryParams: CommentApiService.Comment_GetListParams = {
      size: size,
      offset: offset,
      searchQuery: query,
      htmlFormat: MimeType.html,
      cardTypes: ['CardShared', 'CardAppointment', 'CardCopied']
    };

    if (filter) {
      if (filter.Type === SearchFilterType.TO || filter.IsGroup) {
        _.assign(queryParams, {
          senderIds: filter.IsGroup ? [] : [currentUserId],
          recipientIds: filter.Ids,
          contactFilterRelation: filter.IsGroup ? 'or' : 'and'
        });
      }
      if (filter.Type === SearchFilterType.FROM && !filter.IsGroup) {
        _.assign(queryParams, {
          senderIds: filter.Ids
        });
      }
      if (filter.Type === SearchFilterType.ANY && !filter.IsGroup) {
        _.assign(queryParams, {
          senderIds: filter.Ids,
          recipientIds: filter.Ids
        });
      }
    }

    return this._commentApiService.Comment_GetList(queryParams, forUserEmail).pipe(
      catchError(err => {
        if (err.status === 503) {
          delete queryParams.htmlFormat;
          return this._commentApiService.Comment_GetList(queryParams, forUserEmail);
        } else {
          throwError(err);
        }
      }),
      map((response: ListOfResourcesOfCommentBase) => {
        return response.resources;
      }),
      map((comments: CommentBase[]) => {
        return CommentBaseModel.createList(comments);
      }),
      mergeMap((comments: CommentModel[]) => {
        return this.saveAllAndPublish(forUserEmail, comments);
      })
    );
  }

  addTagByComment(forUserEmail: string, comment: CommentModel, tag: TagModel): Observable<CommentModel> {
    return this.addTagByComments(forUserEmail, [comment], tag).pipe(
      map((comments: CommentModel[]) => {
        return _.first(comments);
      })
    );
  }

  removeTagByComment(forUserEmail: string, comment: CommentModel, tag: TagModel): Observable<CommentModel> {
    return this.removeTagsByComment(forUserEmail, comment, [tag]);
  }

  removeTagsByComment(forUserEmail: string, comment: CommentModel, tags: TagModel[]): Observable<CommentModel> {
    return this.removeTagsByComments(forUserEmail, [comment], tags).pipe(
      map((comments: CommentModel[]) => {
        return _.first(comments);
      })
    );
  }

  addTagByComments(forUserEmail: string, comments: CommentModel[], tag: TagModel): Observable<CommentModel[]> {
    return from(comments).pipe(
      map((comment: CommentModel) => {
        comment.setTags(_.uniqBy([...comment.getTags(), tag], 'id'));
        return comment;
      }),
      toArray(),
      mergeMap((_comments: CommentModel[]) => {
        return this.saveAllAndPublish(forUserEmail, _comments);
      }),
      tap((_comments: CommentModel[]) => {
        let arrayOfListOfTags: ListOfTags[] = _comments.map(comment => comment.tags);
        this.enqueuePushSynchronization(forUserEmail, ListOfTagsModel.createList(arrayOfListOfTags));
      })
    );
  }

  removeTagsByComments(
    forUserEmail: string,
    comments: CommentModel[],
    tags: TagModel[],
    post: boolean = true
  ): Observable<CommentModel[]> {
    if (_.isEmpty(comments)) {
      return of([]);
    }

    return from(comments).pipe(
      map((comment: CommentModel) => {
        comment.tags.tags.resources = _.differenceBy(comment.tags.tags.resources, tags, 'id');
        return comment;
      }),
      toArray(),
      mergeMap((_comments: CommentModel[]) => {
        return this.saveAllAndPublish(forUserEmail, _comments);
      }),
      tap((comments: CommentModel[]) => {
        if (post) {
          let arrayOfListOfTags: ListOfTags[] = comments.map(comment => comment.tags);
          this.enqueuePushSynchronization(forUserEmail, ListOfTagsModel.createList(arrayOfListOfTags));
        }
      })
    );
  }

  updateTagsByComments(
    forUserEmail: string,
    comments: CommentModel[],
    addTag: TagModel,
    removeTags: TagModel[]
  ): Observable<CommentModel[]> {
    return this.updateTagsForComments(forUserEmail, comments, addTag, removeTags).pipe(
      mergeMap(() => {
        return from(comments);
      }),
      map((comment: CommentModel) => {
        // add tags
        comment.tags.tags.resources = _.unionBy(comment.tags.tags.resources, [addTag], 'id');

        // remove tags
        comment.tags.tags.resources = _.differenceBy(comment.tags.tags.resources, removeTags, 'id');
        return comment;
      }),
      toArray(),
      tap((comments: CommentModel[]) => {
        let arrayOfListOfTags: ListOfTags[] = comments.map(comment => comment.tags);
        this.enqueuePushSynchronization(forUserEmail, ListOfTagsModel.createList(arrayOfListOfTags));
      })
    );
  }

  filterNewCommentsAndUpdateTagsWithHigherRevision(
    forUserEmail: string,
    comments: CommentBase[]
  ): Observable<CommentBase[]> {
    return this.findSyncedByIds(forUserEmail, comments).pipe(
      mergeMap((dbComments: CommentModel[]) => {
        return this.updateCommentTagsWithHigherRevision(forUserEmail, comments, dbComments);
      })
    );
  }

  fetchMissingDraftCommentForCard(
    forUserEmail: string,
    card: CardDraftModel,
    stateUpdates: StateUpdates
  ): Observable<StateUpdates> {
    let params = {
      id: card.commentDraft.id,
      htmlFormat: MimeType.html
    } as CommentApiService.Comment_GetParams;
    return this._commentApiService.Comment_Get(params, forUserEmail).pipe(
      map((comment: CommentBase) => {
        return CommentBaseModel.create(comment);
      }),
      mergeMap((_comment: CommentModel) => {
        return this.saveAllAndPublish(forUserEmail, [_comment]);
      }),
      map((_comments: CommentModel[]) => {
        stateUpdates.add(_comments);
        return stateUpdates;
      })
    );
  }

  findMissingCommentsForCards(forUserEmail: string, cards: CardModel[], skipPublish: boolean): Observable<CardModel[]> {
    return from(cards).pipe(
      filter((card: CardModel) => {
        return card._ex && !card._ex.commentsSynchronized && !(card instanceof CardChatModel);
      }),
      toArray(),
      mergeMap((_cards: CardModel[]) => {
        return this.fetchAndSaveCommentsForCards(forUserEmail, _cards, skipPublish, false);
      })
    );
  }

  fetchAndSaveCommentsForCards(
    forUserEmail: string,
    cards: CardModel[],
    skipPublish: boolean,
    shouldFetchCards: boolean
  ): Observable<CardModel[]> {
    let completeCards: CardModel[];
    let cards$: Observable<CardModel[]> = of(cards);

    if (shouldFetchCards) {
      cards$ = this.fetchIncompleteCards(forUserEmail, cards);
    }

    return cards$.pipe(
      map((_cards: CardModel[]) => {
        completeCards = _cards;
        return _cards.map(card => card.id);
      }),
      mergeMap((ids: string[]) => this.fetchCommentsAndSaveForCardIds(forUserEmail, ids, skipPublish)),
      map(() => completeCards),
      defaultIfEmpty(completeCards)
    );
  }

  fetchCommentsAndSaveForCardIds(
    forUserEmail: string,
    cardIds: string[],
    skipPublish: boolean
  ): Observable<CommentModel[][]> {
    return from(_.chunk(cardIds, 100)).pipe(
      mergeMap((ids: string[]) => {
        let params: CommentApiService.Comment_GetListParams = {
          offset: 0,
          size: 1024,
          htmlFormat: MimeType.html,
          offsetModified: new Date().toISOString(),
          cardIds: ids
        };
        return this.fetchListOfComments(forUserEmail, params);
      }),
      mergeMap((comments: CommentModel[]) => {
        if (skipPublish) {
          return this.saveAll(forUserEmail, comments);
        } else {
          return this.saveAllAndPublish(forUserEmail, comments);
        }
      }),
      toArray()
    );
  }

  private fetchIncompleteCards(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return from(cards).pipe(
      pluck('id'),
      filter((id: string) => id !== undefined),
      bufferCount(100),
      mergeMap((cardIds: string[]) => {
        let params: CardApiService.Card_GetListParams = {
          offset: 0,
          size: 1024,
          cardIds: cardIds
        };
        return this._cardApiService.Card_GetList(params, forUserEmail);
      }, 5),
      mergeMap((response: ListOfResourcesOfCardBase) => {
        let _cards = CardBaseModel.createList(response.resources);
        return of(_cards);
      }),
      catchError(err => {
        Logger.customLog('Error when fetching incomplete cards in CommentService. Err: ' + err, LogLevel.ERROR);
        return throwError(err);
      })
    );
  }

  abstract loadMissingBodiesFromDisc(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]>;

  loadOrFetchCommentBody(
    forUserEmail: string,
    comment: CommentMailModel,
    replaceCID?: boolean
  ): Observable<CommentMailModel> {
    const comment$ =
      comment.hasBody() || comment instanceof CommentDraftModel
        ? of(comment)
        : this.loadLocalCommentBody(comment).pipe(
            switchMap((_comment: CommentMailModel) => {
              if (!_comment || !_comment.hasBody()) {
                return this.fetchCommentBody(forUserEmail, _comment);
              }

              return of(comment);
            })
          );

    return comment$.pipe(
      switchMap(_comment => {
        if (replaceCID) {
          return this.replaceCidsWithImageLinks(forUserEmail, _comment);
        }
        return of(_comment);
      })
    );
  }

  replaceCidsWithImageLinks(forUserEmail: string, comment: CommentMailModel): Observable<CommentMailModel> {
    if (comment?.body?.content) {
      return this._htmlImageService
        .replaceImgCidWithFilePaths(forUserEmail, comment.body.content, comment.getAttachments())
        .pipe(
          map((body: string) => {
            // Process css service
            body = DOMPurify.sanitize(body, DOM_PURIFY_CONFIG);

            // Replace styles for correct rendering on dark themes
            body = this._htmlFormattingHelper.decorateHTMLWithOriginalStyle(body);

            comment.body.content = body;
            return comment;
          })
        );
    }
    return of(comment);
  }

  protected fetchCommentBody(forUserEmail: string, comment: CommentMailModel): Observable<CommentMailModel> {
    return this.updateCommentBody(forUserEmail, comment, true).pipe(
      mergeMap((_comment: CommentMailModel) => {
        if (_comment.id) {
          return this.saveAndPublish(forUserEmail, _comment) as Observable<CommentMailModel>;
        }

        return of(_comment);
      })
    );
  }

  protected abstract loadLocalCommentBody(comment: CommentMailModel): Observable<CommentMailModel>;

  redecorateAttachmentsForComment(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    let attachments = FileModel.createList(comment.getResources(comment.attachments));
    return this._fileExDecorateService.decorateListExtraData(forUserEmail, attachments).pipe(
      mergeMap((files: FileModel[]) => {
        if (!_.isEmpty(files)) {
          comment.attachments.resources = files;
        }
        return of(comment);
      })
    );
  }

  abstract openCommentInBrowser(forUserEmail: string, comment: CommentMailModel);

  abstract undoEmailByClientId(forUserEmail: string, commentClientId: string): Observable<CommentModel>;

  abstract downloadCommentContent(forUserEmail: string, commentId: string, commentSubject: string): Observable<any>;

  abstract countOutboxComments(forUserEmail: string);

  abstract countChatsByChatCard(forUserEmail: string, params: ChannelChatsCollectionParams): Observable<Number>;

  abstract findCommentsForCards(forUserEmail: string, cards: CardBase[]): Observable<CommentBaseModel[]>;

  abstract findCommentsForOutbox(forUserEmail: string): Observable<CommentBaseModel[]>;

  abstract findOutboxComments(forUserEmail: string): Observable<CommentBaseModel[]>;

  abstract findChatCommentsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string
  ): Observable<CommentModel[]>;

  abstract findSharedDraftBySourceCardOrEmpty(forUserEmail: string, card_id): Observable<CommentDraftModel>;

  abstract beforeSyncServiceInit(forUserEmail: string): Observable<any>;

  protected getCommentArrayBuffer(forUserEmail: string, commentId: string): Observable<ArrayBuffer> {
    let accountType = this._sharedUserManagerService.getAccountSyncSettingsTypeByEmail(forUserEmail);
    let isMsgFormat =
      accountType === SyncSettingsType.SYNC_SETTINGS_EXCHANGE ||
      accountType === SyncSettingsType.SYNC_SETTINGS_MICROSOFT;

    let obs: Observable<ArrayBuffer> = of(undefined);
    if (isMsgFormat) {
      let parameter: CommentApiService.Comment_GetCommentMsgParams = {
        id: commentId
      };
      obs = this._commentApiService.Comment_GetCommentMsg(parameter, forUserEmail);
    } else {
      let parameter: CommentApiService.Comment_GetCommentEmlParams = {
        id: commentId
      };
      obs = this._commentApiService.Comment_GetCommentEml2(parameter, forUserEmail);
    }

    return obs;
  }

  getCommentAsEmlFile(forUserEmail: string, commentId: string, commentName: string): Observable<FileModel> {
    return this._commentApiService.Comment_GetCommentEml2({ id: commentId }, forUserEmail, true).pipe(
      switchMap((arrayBuffer: ArrayBuffer) => {
        const blob = new Blob([arrayBuffer]);
        const file = new File([blob], `${commentName}.eml`);
        return this._fileService.storeLocalFile(forUserEmail, file, false, getBuffer(arrayBuffer, 'base64'));
      })
    );
  }

  fetchComments(forUserEmail: string, comments: CommentBase[]): Observable<CommentModel[]> {
    if (_.isEmpty(comments)) {
      return of([]);
    }

    return from(comments).pipe(
      filter((comment: CommentBase) => {
        return !_.isNil(comment.id);
      }),
      pluck('id'),
      distinct(),
      bufferCount(20),
      bufferCount(5),
      concatMap((chunks: string[][]) => {
        return from(chunks).pipe(
          mergeMap((commentIds: string[]) => {
            let params: CommentApiService.Comment_GetListParams = {
              offset: 0,
              size: 1024,
              htmlFormat: MimeType.html,
              commentIds: commentIds
            };
            return this._commentApiService.Comment_GetList(params, forUserEmail);
          })
        );
      }),
      mergeMap((response: ListOfResourcesOfCommentBase) => {
        let comments = CommentBaseModel.createList(response.resources);
        return from(comments);
      }),
      tap((comment: CommentModel) => {
        // TODO: temporary fix
        if (comment instanceof CommentMailModel && !comment.cc) {
          comment.cc = ContactBaseModel.createListOfResources([]);
        }
      }),
      toArray(),
      /**
       * Fallback fetchById
       */
      mergeMap((fetchedComments: CommentModel[]) => {
        let missingComments = _.differenceBy(comments, fetchedComments, 'id');
        return this.fetchCommentsById(forUserEmail, missingComments).pipe(
          map((_comments: CommentModel[]) => {
            return [...fetchedComments, ..._comments];
          })
        );
      }),
      /**
       * Update body if needed
       */
      mergeMap((comments: CommentModel[]) => {
        return this.updateCommentsBody(forUserEmail, comments);
      })
    ) as Observable<CommentModel[]>;
  }

  findOrFetchCommentsById(forUserEmail: string, commentIds: string[]): Observable<CommentModel[]> {
    return this.findCommentsByIdsOrClientIds(forUserEmail, commentIds).pipe(
      mergeMap((dbComments: CommentChatModel[]) => {
        let dbCommentIds = dbComments
          .flatMap(dbComment => [dbComment.id, dbComment.clientId])
          .filter(id => !_.isUndefined(id));
        let missingIds = _.differenceBy(commentIds, dbCommentIds);

        let missingComments = missingIds.map(missingId => ({ id: missingId }) as CommentBase);

        return forkJoin([this.fetchCommentsById(forUserEmail, missingComments), of(dbComments)]);
      }),
      map((comments: CommentModel[][]) => {
        return comments.flat();
      })
    );
  }

  updateCommentsSharedTags(forUserEmail: string, sharedTags: ListOfTags[]): Observable<CommentModel[]> {
    if (_.isEmpty(sharedTags)) {
      return of([]);
    }

    let comments: CommentModel[] = [];
    let sharedTagsByParentId = _.keyBy(sharedTags, 'parent.id');
    let ids: string[] = _.keys(sharedTagsByParentId);

    // Find local comments
    return this.findByIds(forUserEmail, ids).pipe(
      mergeMap((_comments: CommentModel[]) => {
        comments = [...comments, ..._comments];
        let localCommentIds: string[] = _.map(comments, 'id');
        let missingCommentIds = _.difference(ids, localCommentIds);

        let missingComments = missingCommentIds.map(missingId => {
          return {
            id: missingId
          } as CommentBase;
        });

        return this.fetchCommentsById(forUserEmail, missingComments);
      }),
      mergeMap((_fetchedComments: CommentModel[]) => {
        return from([...comments, ..._fetchedComments]);
      }),
      filter((comment: CommentModel) => {
        let _sharedTags = sharedTagsByParentId[comment.id];
        return (
          _sharedTags &&
          (!comment.sharedTags || BaseModel.isRevisionGreaterOrEqualThan(_sharedTags, comment.sharedTags))
        );
      }),
      map((comment: CommentModel) => {
        comment.sharedTags = sharedTagsByParentId[comment.id];
        return comment;
      }),
      toArray(),
      mergeMap((comments: CommentModel[]) => {
        return this.saveAllAndPublish(forUserEmail, comments);
      })
    );
  }

  fetchCommentsByConversationId(
    forUserEmail: string,
    options: CommentApiService.Comment_GetCardCommentsParams
  ): Observable<CommentModel[]> {
    return this._commentApiService.Comment_GetCardComments(options, forUserEmail).pipe(
      /**
       * Push comments with empty body to retry queue
       */
      mergeMap((response: ListOfResourcesOfCommentBase) => {
        if (!response && !response.resources) {
          return from([]);
        }

        return this.processFetchedCommentList(forUserEmail, response.resources);
      }),
      mergeMap(comments => {
        return this.saveAllAndPublish(forUserEmail, comments);
      }),
      catchError(err => {
        Logger.customLog('Error when fetching comments for card in CommentService. Err: ' + err, LogLevel.ERROR);

        // When offline, return empty
        if (err.status === 0) {
          return of([]);
        }

        return throwError(() => err);
      })
    );
  }

  fetchListOfComments(
    forUserEmail: string,
    params: CommentApiService.Comment_GetListParams
  ): Observable<CommentModel[]> {
    return this._commentApiService.Comment_GetList(params, forUserEmail).pipe(
      mergeMap((response: ListOfResourcesOfCommentBase) => {
        let hasMore = false;

        if (response.offsetHistoryId === params.offsetHistoryId) {
          hasMore = false;
        } else if (response.size !== response.totalSize) {
          params.offsetHistoryId = response.offsetHistoryId;
          hasMore = true;
        }

        return zip(of(hasMore), of(response.resources));
      }),
      filter((results: [boolean, CommentBase[]]) => !_.isEmpty(results[1])),
      mergeMap((results: [boolean, CommentBase[]]) => {
        let next$: Observable<CommentModel[]>;

        if (results[0]) {
          next$ = this.fetchListOfComments(forUserEmail, params);
        } else {
          next$ = EMPTY;
        }

        return concat(of(CommentBaseModel.createList(results[1])), next$);
      }),
      catchError(err => {
        Logger.customLog('Error when fetching comments for cards in CommentService. Err: ' + err, LogLevel.ERROR);

        // When offline, return empty
        if (err.status === 0) {
          return of([]);
        }

        return throwError(() => err);
      }),
      defaultIfEmpty([])
    );
  }

  protected fetchCommentsById(forUserEmail: string, comments: CommentBase[]): Observable<CommentModel[]> {
    if (_.isEmpty(comments)) {
      return of([]);
    }

    let commentsIds = _.map(comments, 'id');
    let fetchingCommentsIds = _.keys(this.fetchingComments);

    /**
     * Create observables for already fetching comments
     */
    let observables = _.intersection(commentsIds, fetchingCommentsIds).map(
      commentId => this.fetchingComments[commentId]
    );

    /**
     * Create subjects for comments to be fetched
     */
    let commentsToBeFetched = _.difference(commentsIds, fetchingCommentsIds);
    _.forEach(commentsToBeFetched, commentId => {
      this.fetchingComments[commentId] = new Subject<CommentModel[]>();
    });

    /**
     * Wait for observables if all comments are already fetching
     */
    if (_.isEmpty(commentsToBeFetched)) {
      return forkJoin([...observables]).pipe(map((_comments: CommentModel[][]) => _.flatten(_comments)));
    }

    return from(commentsToBeFetched).pipe(
      distinct(),
      /**
       * Split into batches of 1024
       */
      bufferCount(1024),
      /**
       * Fetch list of comments
       */
      mergeMap((commentBatchIds: string[]) => {
        let params: CommentApiService.Comment_GetListParams = {
          commentIds: commentBatchIds,
          htmlFormat: MimeType.html,
          size: 1024
        };
        return this._commentApiService.Comment_GetList(params, forUserEmail);
      }),
      /**
       * Push comments with empty body to retry queue
       */
      mergeMap((response: ListOfResourcesOfCommentBase) => {
        if (!response && !response.resources) {
          return from([]);
        }

        return this.processFetchedCommentList(forUserEmail, response.resources);
      }),
      mergeMap((_comments: CommentModel[]) => from(_comments)),
      toArray(),
      mergeMap((fetchedComments: CommentModel[]) => {
        return concat(this.saveAll(forUserEmail, fetchedComments), ...observables);
      }),
      tap((_savedComments: CommentModel[]) => {
        _.forEach(_savedComments, comment => {
          if (comment.id in this.fetchingComments) {
            this.fetchingComments[comment.id].next([comment]);
            this.fetchingComments[comment.id].complete();
            delete this.fetchingComments[comment.id];
          }
        });
      })
    );
  }

  private processFetchedCommentList(forUserEmail: string, comments: CommentBase[]): Observable<CommentModel[]> {
    if (_.isEmpty(comments)) {
      return of([]);
    }

    const fileUrls: FileUrlEntryI[] = [];

    comments.forEach(comment => {
      if (!comment.attachments?.resources) {
        return;
      }

      comment.attachments.resources.forEach(attachment => {
        if (attachment.urlLink) {
          fileUrls.push({
            fileUrl: attachment.urlLink,
            fileName: attachment.hash
          });
        }
      });
    });

    if (fileUrls.length) {
      return this._fileStorageService.writeFileUrls(fileUrls).pipe(map(() => comments as CommentModel[]));
    }

    return of(comments as CommentModel[]);
  }

  updateCommentsBody(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    if (_.isEmpty(comments)) {
      return of([]);
    }

    let parentRefById = {};

    return from(comments).pipe(
      mergeMap((comment: CommentModel) => {
        return this.updateCommentBody(forUserEmail, comment).pipe(
          map((comment: CommentModel) => {
            if (comment instanceof CommentDraftModel) {
              return comment;
            }

            let parentRef = parentRefById[comment.parent.id];

            // build and add to cache
            if (!parentRef) {
              parentRef = CardBaseModel.buildFromBaseAsReference(comment.parent);
              parentRefById[comment.parent.id] = parentRef;
            }

            comment.parent = parentRef;
            comment.name = comment.name || parentRef.name;

            return comment;
          })
        );
      }),
      catchError(err => {
        Logger.error(err, 'Could not update comment body');
        return <Observable<any>>EMPTY;
      }),
      toArray()
    );
  }

  updateCommentDraftBody(forUserEmail: string, comment: CommentDraftModel): Observable<CommentDraftModel> {
    return of(comment);
    // if (!comment || !comment.contentFileId) {
    //     return of(comment);
    // }

    // return this.fetchSharedDraftContent(forUserEmail, comment.contentFileId)
    //     .pipe(
    //         map((body: string) => {
    //             comment.body = {
    //                 mimeType: MimeType.html,
    //                 content: body
    //             } as CommentBody;
    //             comment.snippet = this.buildSnippetForSharedDraft(body);
    //             return comment;
    //         })
    //     );
  }

  private loadFromDisc(comment: CommentModel): Observable<CommentModel> {
    if (
      comment instanceof CommentMailModel &&
      comment.body.mimeType === MimeType.html &&
      comment.hasBodySavedOnDisc()
    ) {
      // If comment has HTML content and is missing body content read it from disk
      return this._fileStorageService.readFile('body/' + comment._id).pipe(
        mergeMap(body => {
          if (!_.isEmpty(body)) {
            try {
              comment.body.content = new TextDecoder('utf-8').decode(body);
            } catch (e) {
              // JSON parse failed, ignore
              console.log(
                'error in loopin thread component failed json parse or decode string err=' + JSON.stringify(e)
              );
            }
          }

          return of(comment);
        })
      );
    }
    return of(comment);
  }

  updateCommentBody(
    forUserEmail: string,
    comment: CommentModel,
    queueForRetryOn503: boolean = true
  ): Observable<CommentModel> {
    if (this.hasBody(comment)) {
      return of(comment);
    }

    if (comment instanceof CommentDraftModel) {
      return this.updateCommentDraftBody(forUserEmail, comment);
    }

    if (comment instanceof CommentMailModel && comment.hasBodySavedOnDisc()) {
      return this.loadFromDisc(comment);
    }

    // Comments with large bodies have content given via CDN link
    if (
      (comment instanceof CommentMailModel || comment instanceof CommentTemplateModel) &&
      comment.hasBodyContentFetchLink() &&
      comment.body.mimeType === MimeType.html
    ) {
      return this.updateCommentBodyViaLink(forUserEmail, comment);
    }

    // Cast to comment with body
    let clientId = comment.clientId;

    // Prepare parameters
    let params: CommentApiService.Comment_GetParams = {
      id: comment.id,
      htmlFormat: comment.supportsHtmlBody() ? MimeType.html : undefined
    };

    return this._commentApiService.Comment_Get(params, forUserEmail).pipe(
      mergeMap((response: CommentBase) => {
        let commentModel = CommentBaseModel.create(response);

        // This is because BE does not return client id for get comment body
        if (clientId) {
          commentModel.clientId = clientId;
          commentModel._id = clientId;
        }

        // Comments with large bodies need to be re-fetched from CDN
        if (!this.hasBody(commentModel) && commentModel.hasBodyContentFetchLink()) {
          return this.updateCommentBodyViaLink(forUserEmail, commentModel);
        }

        return of(commentModel);
      }),
      catchError(err => {
        // Handle HtmlNotAvailableYet (503)
        if (
          CommentService.isHtmlNotAvailableYetError(err) &&
          queueForRetryOn503 &&
          (comment instanceof CommentMailModel || comment instanceof CommentTemplateModel)
        ) {
          // Add to retry queue
          let retryEntry = RetryModel.createRetryObject(comment, RetryType.GET_COMMENT_503);
          this.enqueueRetrySynchronization(forUserEmail, retryEntry);

          // Set to undefined so update query doesn't override fields in db
          comment._synced = undefined;
          comment.body.mimeType = undefined;
          comment.body.content = '';
          comment.body.contentSignedLink = undefined;
          return of(comment);
        }

        return throwError(err);
      })
    );
  }

  protected updateCommentBodyViaLink(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    if (
      !(comment instanceof CommentMailModel || comment instanceof CommentTemplateModel) ||
      comment instanceof CommentDraftModel
    ) {
      return of(comment);
    }

    if (comment instanceof CommentMailModel && comment.hasBodySavedOnDisc()) {
      return of(comment);
    }

    if (!comment.hasBodyContentFetchLink()) {
      return of(comment);
    }

    let signedLink = comment.getBodyContentFetchLink();
    return this.httpClient.get(signedLink, { responseType: 'arraybuffer' }).pipe(
      map((response: ArrayBuffer) => {
        comment['body'].content = new TextDecoder().decode(response);
        return comment;
      })
    );
  }

  buildCommentMail(
    forUserEmail: string,
    author: User,
    name: string,
    to: ContactBase[],
    cc: ContactBase[],
    bcc: ContactBase[],
    body: string = '',
    attachments: FileModel[],
    parent: CardBase,
    history: string,
    signatureHtml: string,
    isForward: boolean = false,
    shouldHaveOutboxTag: boolean = true,
    createdFromDraftCommentId?: string,
    shouldSplitOnSend?: boolean
  ): Observable<CommentMailModel> {
    let comment = new CommentMailModel();

    comment.created = new Date().toISOString();
    comment.author = author;
    comment.name = name;
    comment.to = BaseModel.createListOfResources(to);
    comment.cc = BaseModel.createListOfResources(cc);
    comment.bcc = BaseModel.createListOfResources(bcc);
    comment.attachments = BaseModel.createListOfResources(attachments);

    // Parent
    if (!_.isEmpty(parent)) {
      comment.parent = parent;
    }

    // Body
    this.buildCommentBody(comment, body, signatureHtml, history, isForward);

    // Save data for undo (will be removed once comment is synced)
    comment.setExPassthroughData('undoData', {
      undoSent: false,
      body: body,
      history: history,
      signatureHtml: signatureHtml,
      isForward: isForward
    } as UndoData);

    // Tags
    if (shouldHaveOutboxTag) {
      comment.tags = ListOfTagsModel.buildFromParentAndTags(comment, [TagModel.buildSystemTag(TagType.OUTBOX)]);
    }

    // Link local draft to local mail card
    comment.setExPassthroughData('createdFromDraftCommentId', createdFromDraftCommentId);
    comment.setExPassthroughData('shouldSplitOnSend', shouldSplitOnSend);

    if (!createdFromDraftCommentId) {
      return of(comment);
    }

    return this.findCommentsByIdsOrClientIds(forUserEmail, [createdFromDraftCommentId]).pipe(
      map((comments: CommentDraftModel[]) => {
        let draftCard = _.first(comments)?.parent;
        if (draftCard) {
          comment.setExPassthroughData('createdFromDraftCardId', draftCard.id || draftCard.clientId);
        }
        return comment;
      })
    );
  }

  buildCommentsChats(
    forUserEmail: string,
    {
      author,
      shareList,
      message,
      files,
      parent,
      mentions,
      quotedCommentId,
      existingComment,
      tagTypes
    }: BuildCommentsChatsData
  ): Observable<CommentChatModel[]> {
    let comments = [];

    if (files?.length > 0) {
      files.forEach(file => {
        comments = [
          ...comments,
          this.buildCommentChat(
            forUserEmail,
            author,
            shareList,
            '',
            [file],
            parent,
            mentions,
            quotedCommentId,
            existingComment,
            tagTypes || []
          )
        ];
      });
    }

    if (message) {
      comments = [
        ...comments,
        this.buildCommentChat(
          forUserEmail,
          author,
          shareList,
          message,
          [],
          parent,
          mentions,
          quotedCommentId,
          existingComment,
          tagTypes || []
        )
      ];
    }

    return of(comments);
  }

  private buildCommentChat(
    forUserEmail: string,
    author: UserModel,
    shareList: ContactModel[],
    message: string,
    files: FileModel[],
    parent?: CardChatModel | ConversationModel,
    mentions?: ContactModel[],
    quotedCommentId?: string,
    existingComment?: CommentChatModel,
    tagTypes: TagType[] = []
  ): CommentChatModel {
    // Edit comment case
    if (existingComment) {
      existingComment.comment = this.buildChatMessage(message, [], true);
      return existingComment;
    }

    let comment: CommentChatModel = new CommentChatModel();

    comment.created = new Date().toISOString();
    comment.author = author;

    // ShareList
    shareList = _.unionBy(shareList, [author], contact => contact.id);
    comment.shareList = BaseModel.createListOfResources(shareList);

    if (parent) {
      comment.name = parent.name;
      comment.parent = CardBaseModel.buildAsReference(parent);
    }

    // Msg / Attachments
    if (_.isEmpty(files)) {
      comment.comment = this.buildChatMessage(message, mentions);
      comment.snippet = comment.comment;
      comment.attachments = BaseModel.createListOfResources([]);
    } else {
      comment.comment = '';
      comment.snippet = '<' + files[0].name + '>';
      comment.attachments = BaseModel.createListOfResources(files);
    }

    if (quotedCommentId) {
      comment.quoteCommentId = quotedCommentId;
    }

    let tags = [TagType.SENT, ...tagTypes].map((tagType: TagType) => {
      return TagModel.buildSystemTag(tagType);
    });
    comment.tags = ListOfTagsModel.buildFromParentAndTags(comment, tags);

    return comment;
  }

  private buildChatMessage(message: string, mentions?: ContactModel[], isEdit?: boolean): string {
    message = this.replaceMentions(message, mentions, isEdit);

    return message
      .replace(/(<div><br><\/div>)/gim, '\n')
      .replace(/(<br>|<\/br>|<br \/>)/gim, '\n')
      .replace(/<div>/gm, '\n')
      .replace(/<(?:.|\n)*?>/gm, '')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, ' ')
      .replace('<', '[code]<[/code]')
      .replace('>', '[code]>[/code]');
  }

  private replaceMentions(message: string, mentions?: ContactModel[], isEdit?: boolean): string {
    if (isEdit) {
      return this.replaceMentionsForEdit(message);
    }

    _.forEach(mentions, contact => {
      let tagType = contact.$type === GroupModel.type ? 'group' : 'user';

      let mentionHTML = `<span class="tag" data-tag="${contact.id}" contenteditable="false">${contact.name.split('@')[0].trim()}</span>`;

      if (message.includes(mentionHTML)) {
        message = message.replace(mentionHTML, `[${tagType} id="${contact.id}"]${contact.name}[/${tagType}]`);
      } else {
        Logger.customLog(
          'Contact is in mentions array, but not mentioned in the message',
          LogLevel.WARN,
          LogTag.INTERESTING_ERROR
        );
      }
    });

    return message;
  }

  private replaceMentionsForEdit(content: string): string {
    let wrapper = document.createElement('div');
    let result = '';

    wrapper.innerHTML = content;

    wrapper.querySelectorAll('span').forEach((mention: HTMLSpanElement) => {
      let contactId = mention.getAttribute('contact-id');
      if (contactId && contactId.startsWith('user')) {
        mention.outerHTML = `[user id="${contactId}"]${mention.textContent}[/user]`;
      } else if (contactId) {
        mention.outerHTML = `[group id="${contactId}"]${mention.textContent}[/group]`;
      }
    });

    result = wrapper.innerHTML;
    wrapper.remove();
    return result;
  }

  protected buildCommentBody(
    comment: CommentMailModel,
    content: string,
    signatureHtml: string,
    history: string,
    isForward: boolean
  ) {
    let body: CommentBody = {
      $type: 'CommentBody'
    };

    // Remove our dark mode styles before sending
    content = this._htmlFormattingHelper.restoreHTMLOriginalStyle(content);

    // Set correct paths for inline images
    content = this._htmlImageService.replaceImgSrcWithDataSrc(content);

    // Remove signature from body and append it later combined with history
    content = this._historyAndSignatureHelper.removeSignatureFromBody(content);

    // Add signature and history at the end of body
    content = this._historyAndSignatureHelper.appendHistoryAndSignatureToBody(
      content,
      history,
      signatureHtml,
      isForward
    );

    // Strip Froala default html tags from content
    let strippedContent = content
      .replace(/<div><br><\/div>/g, '\r\n')
      .replace(/<\/div>/g, '\r\n')
      .replace(/<div>/g, '')
      .replace(/<\/ div>/g, '\r\n')
      .replace(/<br \/>/g, '\r\n')
      .replace(/<br\/>/g, '\r\n')
      .replace(/<br>/g, '\r\n');

    // Check if stripped content includes html tags
    if (strippedContent.match(/<(?:.|\n)*?>/gm)) {
      /**
       * HTML
       */
      body.mimeType = MimeType.html;
      body.content = content
        .replace(/<div>\xA0<\/div>/gi, '<div><br /></div>')
        .replace(/\r/g, ' ')
        .replace(/\n/g, ' ')
        .replace('fr-original-class', 'class');
      comment.snippet = strippedContent.replace(/(<([^>]+)>)/gi, '');
    } else {
      /**
       * BBTAG
       */
      body.mimeType = MimeType.bbtag;
      body.content = strippedContent;
      comment.snippet = strippedContent;
    }

    // Shorten snippet
    if (comment.snippet.length > 128) {
      comment.snippet = comment.snippet.substring(0, 128);
    }

    comment.body = body;
  }

  protected validateCommentOrThrow(comment: CommentModel) {
    if (!comment) {
      throw new Error('Comment cannot be nil');
    }
    if (!comment.author) {
      throw new Error('Comment.author cannot be nil');
    }

    if (!comment.hasBody() && !comment.hasAttachments()) {
      throw new Error('Comment body and attachments cannot be empty');
    }

    if (!comment.parent && _.isEmpty(comment.getShareList())) {
      throw new Error('Comment parent and shareList cannot be nil');
    }
  }

  fetchAndSaveComment(forUserEmail: string, commentId: string): Observable<CommentModel> {
    return this._commentApiService.Comment_Get({ id: commentId }, forUserEmail).pipe(
      map(CommentBaseModel.create),
      mergeMap((comment: CommentModel) => this.saveAllAndPublish(forUserEmail, [comment])),
      map(_.first)
    );
  }

  fetchLastCommentByCardForFoldering(forUserEmail: string, cardId: string): Observable<CommentModel> {
    let queryParams: CommentApiService.Comment_GetListParams = {
      size: 1,
      offset: 0,
      cardIds: [cardId]
    };

    return this._commentApiService.Comment_GetList(queryParams, forUserEmail).pipe(
      map((resources: ListOfResourcesOfCommentBase) => {
        return CommentBaseModel.create(_.first(resources.resources));
      })
    );
  }

  fetchLastCommentsByCardsForFoldering(forUserEmail: string, cardIds: string[]): Observable<CommentModel[]> {
    if (_.isEmpty(cardIds)) {
      return of([] as CommentModel[]);
    }

    let queryParams: CardApiService.Card_GetListParams = {
      cardIds: cardIds,
      size: cardIds.length,
      offset: 0
    };

    return this._cardApiService.Card_GetList(queryParams, forUserEmail).pipe(
      map((resources: ListOfResourcesOfCardBase) => {
        if (_.isEmpty(resources.resources)) {
          return [];
        }

        let cards = CardBaseModel.createList(resources.resources);
        let comments = _.map(cards, card => card.getLastComment());
        return CommentBaseModel.createList(comments);
      })
    );
  }

  updateCommentsTags(forUserEmail: string, tags: ListOfTags[]): Observable<CommentModel[]> {
    if (_.isEmpty(tags)) {
      return of([]);
    }

    let tagsByParentId = _.keyBy(tags, 'parent.id');
    let ids: string[] = _.keys(tagsByParentId);

    return this.findByIds(forUserEmail, ids).pipe(
      mergeMap((comments: CommentModel[]) => from(comments)),
      filter((comment: CommentModel) => {
        let tagsForThisComment = tagsByParentId[comment.id];

        return (
          tagsForThisComment &&
          (!comment.tags || BaseModel.isRevisionGreaterOrEqualThan(tagsForThisComment, comment.tags))
        );
      }),
      map((comment: CommentModel) => {
        comment.tags = tagsByParentId[comment.id];
        return comment;
      }),
      toArray(),
      mergeMap((comments: CommentModel[]) => this.saveAllAndPublish(forUserEmail, comments))
    );
  }

  updateCommentTagsWithHigherRevision(
    forUserEmail: string,
    newComments: CommentBase[],
    dbComments: CommentModel[]
  ): Observable<CommentModel[]> {
    let dbCommentsById = _.keyBy(dbComments, 'id');

    return from(newComments).pipe(
      map((newComment: CommentModel) => {
        let dbComment = dbCommentsById[newComment.id];
        if (
          dbComment &&
          dbComment.tags &&
          newComment.tags &&
          BaseModel.isRevisionGreaterThan(dbComment.tags, newComment.tags)
        ) {
          newComment.tags = dbComment.tags;
        }
        return newComment;
      }),
      toArray()
    );
  }

  filterOutNewCommentUpdates(forUserEmail: string, comments: CommentBase[]): Observable<CommentBase[]> {
    let localCommentsByCommentId: _.Dictionary<CommentBase> = {};

    return this.findComments(forUserEmail, comments).pipe(
      mergeMap((localComments: CommentBase[]) => {
        localCommentsByCommentId = _.keyBy(localComments, 'id');
        return from(comments);
      }),
      /**
       * Filter out old updates
       */
      filter((commentUpdate: CommentBase) => {
        let localComment = localCommentsByCommentId[commentUpdate.id];

        // All updates without local comment are passed through
        if (!localComment) {
          return true;
        }

        return BaseModel.isRevisionGreaterThan(commentUpdate, localComment);
      }),
      /**
       * Take 'children' with greater revision
       */
      mergeMap((newCommentUpdate: CommentBase) => {
        let localComment = localCommentsByCommentId[newCommentUpdate.id];

        if (localComment) {
          // Compare tags revision and take greater
          if (
            localComment.tags &&
            newCommentUpdate.tags &&
            BaseModel.isRevisionGreaterThan(localComment.tags, newCommentUpdate.tags)
          ) {
            newCommentUpdate.tags = localComment.tags;
          }
        }

        return of(newCommentUpdate);
      }),
      toArray()
    );
  }

  findOrFetchCommentsByCardsAndTags(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
    tag: TagModel,
    includeTag: boolean
  ): Observable<CommentModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    return of(undefined).pipe(
      mergeMap(() => {
        return this.findCommentsByCardsAndTags(
          forUserEmail,
          cards,
          baseResourceOnly,
          includeSnapshotComments,
          tag,
          includeTag
        );
      }),
      mergeMap((dbComments: CommentModel[]) => {
        if (_.isEmpty(dbComments)) {
          return this.fetchCommentsByCardsAndTags(
            forUserEmail,
            cards,
            baseResourceOnly,
            includeSnapshotComments,
            tag,
            includeTag
          );
        }

        return of(dbComments);
      })
    );
  }

  private fetchCommentsByCardsAndTags(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
    tag?: TagModel,
    includeTag?: boolean
  ): Observable<CommentModel[]> {
    let cardIds = _.map(cards, (card: CardModel) => card.id);
    if (includeSnapshotComments) {
      cardIds.push(...CardBaseModel.getSnapshotOrSourceCardIds(cards));
    }

    if (_.isEmpty(cardIds)) {
      return of([]);
    }

    let params: CommentApiService.Comment_GetListParams = {
      offset: 0,
      size: 1024,
      htmlFormat: MimeType.html,
      cardIds: cardIds
    };

    if (tag) {
      params.tags = [tag.id];
      params.tagFilterRelation = includeTag ? QueryRelation.OR : QueryRelation.AND_NOT;

      // Also get virtual unreads when fetching unreads
      if (includeTag && tag.id === TagType.UNREAD) {
        params.tags.push(TagType.UNREAD_VIRTUAL);
      }
    }

    return this.fetchListOfComments(forUserEmail, params);
  }

  getLastCommentByCardForFoldering(forUserEmail: string, card: CardModel): Observable<CommentModel> {
    return this.findLastCommentByCardForFoldering(forUserEmail, card).pipe(
      catchError(err => {
        return of(undefined);
      }),
      mergeMap((comment: CommentModel) => {
        if (_.isEmpty(comment)) {
          return this.fetchLastCommentByCardForFoldering(forUserEmail, card.id);
        }

        return of(comment);
      })
    );
  }

  findOrFetchCommentsByCards(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly?: boolean,
    includeSnapshotComments?: boolean
  ): Observable<CommentModel[]> {
    return of(undefined).pipe(
      mergeMap(() => {
        return this.findCommentsByCards(forUserEmail, cards, baseResourceOnly, includeSnapshotComments);
      }),
      mergeMap((dbComments: CommentModel[]) => {
        if (_.isEmpty(dbComments)) {
          return this.fetchCommentsByCardsAndTags(forUserEmail, cards, baseResourceOnly, includeSnapshotComments);
        }

        return of(dbComments);
      })
    );
  }

  findOrFetchLastCommentsByCardsForFoldering(forUserEmail: string, cards: CardModel[]): Observable<CommentBaseModel[]> {
    return this.findLastCommentsByCardsForFoldering(forUserEmail, cards).pipe(
      mergeMap((localComments: CommentBaseModel[]) => {
        let requestedCardIds = _.map(cards, card => card.id);
        let localCommentsParentIds = _.map(localComments, comment => comment.parent.id);
        let missingParentCardIds = _.difference(requestedCardIds, localCommentsParentIds);

        return this.fetchLastCommentsByCardsForFoldering(forUserEmail, missingParentCardIds).pipe(
          mergeMap((apiComments: CommentModel[]) => this.saveAll(forUserEmail, apiComments)),
          map((apiComments: CommentModel[]) => [...apiComments, ...localComments])
        );
      })
    );
  }

  ////////////////
  // Out of office
  ////////////////
  fetchOutOfOffice(forUserEmail: string): Observable<CommentOutOfOffice> {
    return this._commentApiService.Comment_GetOutOfOfficeCommentResponse({}, forUserEmail).pipe(tap(() => {}));
  }

  updateOutOfOffice(forUserEmail: string, outOfOffice: CommentOutOfOffice): Observable<CommentOutOfOffice> {
    return this._commentApiService.Comment_UpdateOutOfOfficeCommentResponse(
      { commentOutOfOffice: outOfOffice },
      forUserEmail
    );
  }

  ////////////////
  // DAO WRAPPERS
  ////////////////
  findById(forUserEmail: string, id: string): Observable<CommentModel> {
    return this._commentDaoService.findById(forUserEmail, id);
  }

  findByIds(forUserEmail: string, ids: string[]): Observable<CommentModel[]> {
    return this._commentDaoService.findByIds(forUserEmail, ids);
  }

  findCommentsByCard(forUserEmail: string, card: CardModel): Observable<CommentModel[]> {
    return this._commentDaoService.findCommentsByCard(forUserEmail, card);
  }

  findCommentsByIdsOrClientIds(forUserEmail: string, idOrClientIds: string[]): Observable<CommentModel[]> {
    return this._commentDaoService.findCommentsByIdsOrClientIds(forUserEmail, idOrClientIds);
  }

  findCommentsByParentIdsOrClientIds(forUserEmail: string, idOrClientIds: string[]): Observable<CommentModel[]> {
    return this._commentDaoService.findCommentsByParentIdsOrClientIds(forUserEmail, idOrClientIds);
  }

  findCommentsByCards(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean
  ): Observable<CommentModel[]> {
    return this._commentDaoService.findCommentsByCards(forUserEmail, cards, baseResourceOnly, includeSnapshotComments);
  }

  findGroupedCommentIdsByCards(
    forUserEmail: string,
    cards: CardModel[],
    includeSnapshotComments: boolean
  ): Observable<_.Dictionary<CommentBase[]>> {
    return of({});
  }

  findComments(forUserEmail: string, comments: CommentBase[]): Observable<CommentModel[]> {
    return of([]);
  }

  findTagsByCard(forUserEmail: string, card: CardModel): Observable<ListOfTags[]> {
    return of([]);
  }

  findTagsByComment(forUserEmail: string, comment: CommentModel): Observable<ListOfTagsModel> {
    return of(undefined);
  }

  findCommentsByCardsAndTags(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
    tag: TagModel,
    includeTag: boolean
  ): Observable<CommentModel[]> {
    return of([]);
  }

  findLastCommentByCardForFoldering(forUserEmail: string, card: CardModel): Observable<CommentModel> {
    return this._commentDaoService.getLastCommentByCardForFoldering(forUserEmail, card);
  }

  findLastCommentsByCardsForFoldering(forUserEmail: string, cards: CardModel[]): Observable<CommentBaseModel[]> {
    return this._commentDaoService.findLastCommentsByCardsForFoldering(forUserEmail, cards);
  }

  removeAll(forUserEmail: string, comments: CommentModel[]): Observable<any> {
    return this._commentDaoService.removeAll(forUserEmail, comments);
  }

  removeById(forUserEmail: string, id: string): Observable<CommentModel[]> {
    return this._commentDaoService.removeById(forUserEmail, id);
  }

  findUnreadCommentsByCards(forUserEmail: string, cards: CardModel[]): Observable<CommentModel[]> {
    return this._commentDaoService.findUnreadCommentsByCards(forUserEmail, cards);
  }

  updateTagsForComments(
    forUserEmail: string,
    comments: CommentModel[],
    addTag: TagModel,
    removeTags: TagModel[]
  ): Observable<any> {
    return of(undefined);
  }

  findChatsByChatCard(forUserEmail: string, params: ChannelChatsCollectionParams): Observable<CommentModel[]> {
    return this._commentDaoService.findChatsByChatCard(forUserEmail, params);
  }

  protected findSyncedByIds(forUserEmail: string, comments: CommentBase[]): Observable<CommentModel[]> {
    return this._commentDaoService.findSyncedByIds(forUserEmail, comments);
  }

  protected findCommentsByQuoteIds(forUserEmail: string, ids: string[]): Observable<CommentModel[]> {
    return this._commentDaoService.findCommentsByQuoteIds(forUserEmail, ids);
  }
}
