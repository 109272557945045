<div class="flex flex-col gap-half">
  <div class="dropdown-header">
    <div class="flex justify-between flex-1">
      TAGS
      <div class="flex gap-quarter" (click)="openTagsSettings()">
        <loop-icon name="circle-plus" width="16px" height="16px"></loop-icon>
        <span>Add</span>
      </div>
    </div>
  </div>
  <loop-text-control
    class="padding-horizontal"
    [ngModel]="folderSearchQuery$ | async"
    (ngModelChange)="folderSearchQuery$.next($event)"
    size="small"
    placeholder="Search"
  ></loop-text-control>

  <ng-container *ngIf="filteredLabels$ | async; let labels">
    <div *ngIf="labels && labels.length; else noFoldersTpl" class="flex flex-col labels-wrapper">
      <ng-container *ngFor="let label of labels; let index = index">
        <div
          class="label flex justify-between gap-half"
          [class.bg-light-ui-element]="index === currentIndex"
          (click)="addOrRemoveLabel(label)"
        >
          <div class="label-name">
            <loop-icon name="hashtag" [style.color]="label.color" width="14px" height="14px"></loop-icon>
            <div class="text-overflow">
              {{ label.name }}
            </div>
            <div *ngIf="label.$type === 'TagLabel'">
              <loop-icon name="lock" width="14px" height="14px"></loop-icon>
            </div>
          </div>
          <div *ngIf="conversations[0] && conversations[0] | isLabelSelected: label" class="flex items-center">
            <loop-icon name="check" width="14px" height="14px"></loop-icon>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-template #noFoldersTpl>
      <div>
        <span>No labels</span>
      </div>
    </ng-template>
  </ng-container>
</div>
