import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { ClassificationTreeModule } from '@shared/modules/shared-tag/components/classification-tree/classification-tree.module';
import { LoaderModule } from '@dta/ui/components/common/loader/loader.module';
import { ClassificationDropdownComponent } from './classification-dropdown.component';

@NgModule({
  declarations: [ClassificationDropdownComponent],
  imports: [
    CommonModule,
    ContactModule,
    IconModule,
    LoaderModule,
    ReactiveFormsModule,
    TextControlModule,
    ClassificationTreeModule
  ],
  exports: [ClassificationDropdownComponent]
})
export class ClassificationDropdownModule {}
