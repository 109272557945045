import { Injectable, NgModule } from '@angular/core';
import { ContactStoreFactory } from './contact-store/contact-store.factory';

@Injectable()
export class StoreService {
  constructor(public ContactStoreFactory: ContactStoreFactory) {}
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [StoreService, ContactStoreFactory],
})
export class StoreModule {}
