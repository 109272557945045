import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagComponent } from '@shared/ui/tag/tag.component';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';

@NgModule({
  declarations: [TagComponent],
  imports: [CommonModule, IconModule],
  exports: [TagComponent]
})
export class TagModule {}
