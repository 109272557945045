import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export abstract class BasePushSynchronizationService<T> {
  constructor() {}

  abstract get constructorName(): string;

  protected abstract synchronize(forUserEmail: string, model: T | T[]): Observable<T | T[] | Error>;

  protected beforeSynchronize(forUserEmail: string, model: T | T[]): Observable<T | T[]> {
    return of(model);
  }

  protected afterSynchronize(forUserEmail: string, model: T | T[]): Observable<T | T[]> {
    return of(model);
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    model: any,
  ): Observable<any> {
    return throwError(err);
  }

  synchronizeModel(forUserEmail: string, model: T | T[]): Observable<T> {
    return of(undefined).pipe(
      mergeMap(() => {
        return this.beforeSynchronize(forUserEmail, model);
      }),
      mergeMap((_model: T | T[]) => {
        return this.synchronize(forUserEmail, _model);
      }),
      mergeMap((_model: T | T[]) => {
        return this.afterSynchronize(forUserEmail, _model);
      }),
      catchError(err => {
        return this.generalSynchronizationErrorHandler(forUserEmail, err, model);
      }),
    );
  }
}
