/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { AvailabilityStatusList } from '../models/availability-status-list';
import { Response } from '../models/response';
import { AvailabilityStatus } from '../models/availability-status';

@Injectable()
export class AvailabilityStatusApiService extends BaseService {
  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusListParams` containing the following parameters:
   *
   * - `workspaceId`: optional workspace id otherwise is your default workspace
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_GetAvailabilityStatusListResponse(
    params: AvailabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusListParams,
    forUserEmail: string
  ): Observable<HttpResponse<AvailabilityStatusList>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/availabilitystatus/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('AvailabilityStatus_GetAvailabilityStatusList');

    // body, headers and query params
    if (params.workspaceId != null) __params = __params.set('workspaceId', params.workspaceId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AvailabilityStatusList;

          body = <AvailabilityStatusList>response.body;

          return <HttpResponse<AvailabilityStatusList>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusListParams` containing the following parameters:
   *
   * - `workspaceId`: optional workspace id otherwise is your default workspace
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_GetAvailabilityStatusList(
    params: AvailabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AvailabilityStatusList> {
    const observable$ = this.AvailabilityStatus_GetAvailabilityStatusListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/availabilitystatus/list`;
      const url = this.rootUrl + `/api/v1/availabilitystatus/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_CreateAvailabilityStatusParams` containing the following parameters:
   *
   * - `availabilityStatus`: Update availability status
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_CreateAvailabilityStatusResponse(
    params: AvailabilityStatusApiService.AvailabilityStatus_CreateAvailabilityStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<AvailabilityStatus>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/availabilitystatus`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('AvailabilityStatus_CreateAvailabilityStatus');

    // body, headers and query params
    __body = params.availabilityStatus;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AvailabilityStatus;

          body = <AvailabilityStatus>response.body;

          return <HttpResponse<AvailabilityStatus>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_CreateAvailabilityStatusParams` containing the following parameters:
   *
   * - `availabilityStatus`: Update availability status
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_CreateAvailabilityStatus(
    params: AvailabilityStatusApiService.AvailabilityStatus_CreateAvailabilityStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AvailabilityStatus> {
    const observable$ = this.AvailabilityStatus_CreateAvailabilityStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/availabilitystatus`;
      const url = this.rootUrl + `/api/v1/availabilitystatus`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_UpdateAvailabilityStatusParams` containing the following parameters:
   *
   * - `availabilityStatus`: Update availability status
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_UpdateAvailabilityStatusResponse(
    params: AvailabilityStatusApiService.AvailabilityStatus_UpdateAvailabilityStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<AvailabilityStatus>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/availabilitystatus`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('AvailabilityStatus_UpdateAvailabilityStatus');

    // body, headers and query params
    __body = params.availabilityStatus;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AvailabilityStatus;

          body = <AvailabilityStatus>response.body;

          return <HttpResponse<AvailabilityStatus>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_UpdateAvailabilityStatusParams` containing the following parameters:
   *
   * - `availabilityStatus`: Update availability status
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_UpdateAvailabilityStatus(
    params: AvailabilityStatusApiService.AvailabilityStatus_UpdateAvailabilityStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AvailabilityStatus> {
    const observable$ = this.AvailabilityStatus_UpdateAvailabilityStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/availabilitystatus`;
      const url = this.rootUrl + `/api/v1/availabilitystatus`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_GetAvailabilityStatusResponse(
    params: AvailabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<AvailabilityStatus>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/availabilitystatus`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('AvailabilityStatus_GetAvailabilityStatus');

    // body, headers and query params
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AvailabilityStatus;

          body = <AvailabilityStatus>response.body;

          return <HttpResponse<AvailabilityStatus>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_GetAvailabilityStatus(
    params: AvailabilityStatusApiService.AvailabilityStatus_GetAvailabilityStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AvailabilityStatus> {
    const observable$ = this.AvailabilityStatus_GetAvailabilityStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/availabilitystatus`;
      const url = this.rootUrl + `/api/v1/availabilitystatus`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_DeleteAvailabilityStatusParams` containing the following parameters:
   *
   * - `availabilityStatusId`:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_DeleteAvailabilityStatusResponse(
    params: AvailabilityStatusApiService.AvailabilityStatus_DeleteAvailabilityStatusParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/availabilitystatus/${params.availabilityStatusId}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('AvailabilityStatus_DeleteAvailabilityStatus');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AvailabilityStatusApiService.AvailabilityStatus_DeleteAvailabilityStatusParams` containing the following parameters:
   *
   * - `availabilityStatusId`:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  AvailabilityStatus_DeleteAvailabilityStatus(
    params: AvailabilityStatusApiService.AvailabilityStatus_DeleteAvailabilityStatusParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.AvailabilityStatus_DeleteAvailabilityStatusResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/availabilitystatus/${params.availabilityStatusId}`;
      const url = this.rootUrl + `/api/v1/availabilitystatus/${params.availabilityStatusId}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module AvailabilityStatusApiService {
  /**
   * Parameters for AvailabilityStatus_GetAvailabilityStatusList
   */
  export type AvailabilityStatus_GetAvailabilityStatusListParams = {
    /**
     * optional workspace id otherwise is your default workspace
     */
    workspaceId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for AvailabilityStatus_CreateAvailabilityStatus
   */
  export type AvailabilityStatus_CreateAvailabilityStatusParams = {
    /**
     * Update availability status
     */
    availabilityStatus: AvailabilityStatus;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for AvailabilityStatus_UpdateAvailabilityStatus
   */
  export type AvailabilityStatus_UpdateAvailabilityStatusParams = {
    /**
     * Update availability status
     */
    availabilityStatus: AvailabilityStatus;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for AvailabilityStatus_GetAvailabilityStatus
   */
  export type AvailabilityStatus_GetAvailabilityStatusParams = {
    id?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for AvailabilityStatus_DeleteAvailabilityStatus
   */
  export type AvailabilityStatus_DeleteAvailabilityStatusParams = {
    availabilityStatusId: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
