import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { CreateChannelBaseComponent, SetupStep, Step } from '../create-channel.base.component';
import { ClickTrackingLocation } from '@dta/shared/services/tracking/tracking.constants';
import { CREATE_TEAM_COPY } from './create-team-copy';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { Observable } from 'rxjs';
import { TeamSetupData } from '@shared/services/data/channel-inbox-setup/channel-setup.service.interface';
import { SetupType } from '@shared/services/data/channel-inbox-setup/channel-setup.service';

@AutoUnsubscribe()
@Component({
  selector: 'create-team',
  styleUrls: ['./../../../create-view.scss'],
  templateUrl: './../create-channel.html',
  host: { class: 'main' },
})
export class CreateTeamComponent extends CreateChannelBaseComponent implements OnInit, OnDestroy {
  //////////////
  // Properties
  //////////////
  data: TeamSetupData;
  step: 0 | 1 = 0;
  steps: Step[] = [
    { id: SetupStep.Overview, title: 'Overview' },
    { id: SetupStep.Members, title: 'members' },
  ];
  textBank = CREATE_TEAM_COPY;
  trackingLocation: string = ClickTrackingLocation.TeamSetup;
  setupType: SetupType = SetupType.TEAM;

  get backButtonText(): string {
    return {
      0: 'Back to Teams',
      1: 'Back to Overview',
    }[this.step];
  }

  get nextButtonText(): string {
    return {
      0: 'Next',
      1: this.data?.members?.length > 0 ? 'Send invites' : 'Create',
    }[this.step];
  }

  get additionalTrackingProperties(): Object {
    return {
      workflow: 'TeamSetup',
      step: 'TeamStep' + (this.step + 1),
    };
  }

  ////////////////
  // View methods
  ////////////////
  nextStep() {
    if (!this.data?.name) {
      return;
    }

    if (this.step === 0) {
      this.step = 1;
    } else if (this.step === 1) {
      this.create();
    }

    this.trackView();
  }

  ///////////
  // Helpers
  ///////////
  protected navigateToSettings() {
    this._router.navigate(['/user-settings/teams']);
  }

  protected getNewDataModel(): TeamSetupData {
    return new TeamSetupData();
  }

  protected setStepFromData() {
    let step: 0 | 1 = 0;

    if (this.data.name) {
      step = 1;
    }

    this.stepStatusToIdle();

    this.step = step;
    this.trackView();
  }

  protected persistData() {}

  protected getDataFromRoute() {
    this.resetState();
  }

  protected setupServiceCreate(): Observable<ContactModel> {
    return this._channelSetupService.createTeam(this.currentUserEmail, this.data);
  }
}
