<ng-container *ngIf="contacts$ | async; let contacts">
  <div>
    <ng-container *ngFor="let contact of contacts; let index = index">
      <loop-contact
        class="padding-vertical-half padding-horizontal"
        [class.hover-background]="index === currentIndex"
        [contact]="contact"
        [showAvailabilityStatus]="true"
        (click)="handleContactClick(contact)"
      ></loop-contact>
    </ng-container>
  </div>
</ng-container>
