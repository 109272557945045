import * as _ from 'lodash';
import * as moment from 'moment';
import { v1 } from 'uuid';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CONSTANTS } from '@shared/models/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

const charList = {
  Ą: 'A',
  ą: 'a',
  Ć: 'C',
  Č: 'C',
  ć: 'c',
  č: 'c',
  Ę: 'E',
  ę: 'e',
  Ł: 'L',
  ł: 'l',
  Ó: 'O',
  ó: 'o',
  Ś: 'S',
  Š: 'S',
  ś: 's',
  š: 's',
  Ž: 'Z',
  Ź: 'Z',
  ź: 'z',
  Ż: 'Z',
  ż: 'z',
  ž: 'z',
  Ä: 'A',
  ä: 'a',
  Ö: 'O',
  ö: 'o',
  Ü: 'U',
  ü: 'u'
};

const forge = require('node-forge');
const cryptoKey: string = 'ZinXEz8Zj6Ix0D6wACUD-7YSwGLCN5nodsJZ9lk8O';
const cryptoKeyIv: string = 'kYp3s6v8y/B?E(H+';
const iv: string = '$C&F)J@NcQfTjWnZ';
const algorithm: string = 'AES-CBC';
const logBearerAlgorithm: string = 'AES-CBC';

export class ID {
  static getUniqueId(length: number): string {
    if (length === undefined || length < 0 || length > 32) {
      length = 32;
    }

    return v1().substr(0, length);
  }
}

export class EmailUtils {
  static getDomain(email: string): string {
    if (!email) {
      return;
    }
    let domain = _.split(email, '@');
    if (domain.length !== 2) {
      return email;
    }
    return domain[1];
  }

  static validateEmail(email: string): boolean {
    let re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email && email.length && re.test(email);
  }
}

export class StringUtils {
  static getPadded(msg: string | number, endLength: number, before: boolean = false, delimiter: string = ' ') {
    msg = msg.toString();
    endLength = Math.max(msg.length, endLength);

    if (before) {
      return Array(endLength - msg.length + 1).join(delimiter) + msg;
    }
    return msg + Array(endLength - msg.length + 1).join(delimiter);
  }

  static toLocale(input: string): string {
    if (_.isEmpty(input)) {
      return input;
    }

    return input.replace(/./g, char => charList[char] || char);
  }

  /**
   * Prepares query before digest
   * - escapes breaking regex related characters
   * - trims string
   */
  static prepareSearchQuery(query: string = ''): string {
    return query.replace(/[[\]{}()*+?.,\\^$|#]/g, '\\$&').trim();
  }
}

@Injectable()
export class Time {
  constructor(private _http: HttpClient) {}

  static getTimestamp(excludeDate: boolean = false): string {
    let date = new Date();

    let _date =
      StringUtils.getPadded(date.getDate(), 2, true, '0') +
      '.' +
      StringUtils.getPadded(date.getMonth() + 1, 2, true, '0') +
      '.' +
      date.getFullYear();

    let _time =
      StringUtils.getPadded(date.getHours(), 2, true, '0') +
      ':' +
      StringUtils.getPadded(date.getMinutes(), 2, true, '0') +
      ':' +
      StringUtils.getPadded(date.getSeconds(), 2, true, '0') +
      '.' +
      StringUtils.getPadded(date.getMilliseconds(), 3, false, '0');

    if (excludeDate) {
      return _time;
    }

    return _date + ' ' + _time;
  }

  static getDate(): string {
    let date = new Date();
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
  }

  getServerTime(): Observable<moment.Moment> {
    let query = '/api?t=' + new Date().getTime();
    return this._http.get(CONSTANTS.LOOP_API_ROOT_URI + query, { observe: 'response' }).pipe(
      map((response: HttpResponse<Object>) => {
        let dateString = response.headers.get('Date');
        if (dateString) {
          return moment(dateString);
        } else {
          return moment();
        }
      }),
      catchError(err => {
        return of(moment());
      })
    );
  }
}

export class Debug {
  static getCallStack(depth: number = 2): string {
    let debugData = '';

    // Disable for production
    if (ENV === 'production') {
      return debugData;
    }

    // Fail safe
    try {
      let stack = new Error().stack;
      let lines = stack.split('\n');

      let offset = 2; // Self and header

      for (let i = offset; i < Math.min(offset + depth, lines.length); i++) {
        if (!_.isEmpty(debugData)) {
          debugData += '.';
        }

        let matches = lines[i].match(/.*?at(.*?)\(.*?\)/);
        if (!_.isEmpty(matches) && matches.length >= 2) {
          let names = matches[1].split('.');
          let funName = names[names.length - 1].trim();

          debugData += funName;
        } else {
          debugData += '??';
        }
      }
    } catch (err) {
      console.error('Could not get debug data: ' + err);
    }

    return debugData;
  }
}

export class Hash {
  static stringToNaiveHash(str: string): string {
    if (str.length === 0) {
      return '';
    }

    let hash = '';
    for (let i = 0; i < str.length; i++) {
      hash += String.fromCharCode(str[i].charCodeAt(0) + 1);
    }

    return hash;
  }
}

export class Encryption {
  static decrypt(value: string): string {
    return this.decryptStandard(value);
  }

  static encrypt(value: string): string {
    return this.encryptStandard(value);
  }

  // Use this to match standard
  static decryptStandard(value: string): string {
    const decipher = forge.cipher.createDecipher(algorithm, cryptoKeyIv);
    decipher.start({ iv: iv });
    decipher.update(forge.util.createBuffer(forge.util.decode64(value)));
    decipher.finish();

    return decipher.output.toString();
  }

  // Use this to match standard
  static encryptStandard(value: string): string {
    const cipher = forge.cipher.createCipher(algorithm, cryptoKeyIv);
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(value, 'utf8'));
    cipher.finish();

    return forge.util.encode64(cipher.output.getBytes());
  }

  static getCryptedLogBearer(logBearer: string, aesKey: string, aesIv: string): string {
    const cipher = forge.cipher.createCipher(logBearerAlgorithm, aesKey);
    cipher.start({ iv: aesIv });
    cipher.update(forge.util.createBuffer(logBearer, 'utf8'));
    cipher.finish();

    return forge.util.encode64(cipher.output.getBytes());
  }
}

export function getBuffer(
  data: WithImplicitCoercion<ArrayBuffer | Uint8Array | string>,
  encoding?: BufferEncoding
): Buffer {
  return isWebApp() ? Buffer.from(<any>data, encoding) : (<any>window).PreloadedBuffer.from(data, encoding);
}

export class Environment {
  static getEnvironment(): EnvironmentType {
    switch (ENV) {
      case 'development':
        return EnvironmentType.DEVELOPMENT;
      case 'alpha':
        return EnvironmentType.ALPHA;
      case 'beta':
        return EnvironmentType.BETA;
      case 'production':
        return EnvironmentType.PRODUCTION;
      case 'production-store':
        return EnvironmentType.PRODUCTION_STORE;
      case 'webapp':
        return EnvironmentType.WEB_APP;
      default:
        return EnvironmentType.DEVELOPMENT;
    }
  }

  static getWebEnvironment(): EnvironmentType {
    let rootLocation = window.location.host.split('.')[0] || 'localhost';

    switch (rootLocation) {
      case 'alpha':
        return EnvironmentType.ALPHA;
      case 'beta':
        return EnvironmentType.BETA;
      case 'app':
        return EnvironmentType.PRODUCTION;
      case 'localhost':
      default:
        return EnvironmentType.DEVELOPMENT;
    }
  }
}

export enum EnvironmentType {
  DEVELOPMENT,
  ALPHA,
  BETA,
  PRODUCTION,
  PRODUCTION_STORE,
  WEB_APP
}

export enum OperatingSystem {
  LINUX,
  WINDOWS,
  DARWIN
}

export function checkIfOS(os: OperatingSystem): boolean {
  switch (os) {
    case OperatingSystem.LINUX:
      return (<any>window).process?.platform === 'linux' || window?.navigator?.userAgent?.includes('Linux');
    case OperatingSystem.WINDOWS:
      return (<any>window).process?.platform === 'win32' || window?.navigator?.userAgent?.includes('Win');
    case OperatingSystem.DARWIN:
      return (<any>window).process?.platform === 'darwin' || window?.navigator?.userAgent?.includes('Mac');
    default:
      return false;
  }
}

export function isSafari(): boolean {
  return !window.process && /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);
}

export function isFirefox(): boolean {
  return navigator?.userAgent?.toLowerCase().indexOf('firefox') > -1;
}

export function isWebApp(): boolean {
  return Environment.getEnvironment() === EnvironmentType.WEB_APP;
}

export function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || (<any>window).opera;
  const test1 =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
  const test2 =
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;

  return test1.test(userAgent) || test2.test(userAgent.substr(0, 4));
}

export function getOriginFromRoute(): string {
  if (!isWebApp()) {
    return undefined;
  }

  if (window.location.host.includes('localhost')) {
    return 'local-host';
  }

  /**
   * alpha => alpha.intheloop.io => integrations parameter: 'web-app-alpha'
   * beta => beta.intheloop.io => integrations parameter: 'web-app-beta'
   * prod => app.intheloop.io => integrations parameter: 'web-app-prod'
   */
  let rootLocation = window.location.host.split('.')[0] || 'app';

  return rootLocation === 'app' ? 'prod' : rootLocation;
}
