import { from, Observable, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, mergeMap, toArray } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';

export abstract class BaseViewDecorateService<T extends BaseModel> {
  constructor() {}

  abstract decorateViewData(forUserEmail: string, model: T, force?: boolean): Observable<T>;

  decorate(forUserEmail: string, model: T, force?: boolean): Observable<T> {
    return this.decorateViewData(forUserEmail, model, force).pipe(
      catchError(err => {
        Logger.error(err, 'Could not decorate');
        return throwError(err);
      }),
    );
  }

  decorateListViewData(forUserEmail: string, models: T[], force?: boolean): Observable<T[]> {
    return from(models).pipe(
      mergeMap((model: T) => {
        return this.decorateViewData(forUserEmail, model, force);
      }),
      toArray(),
      defaultIfEmpty([]),
      catchError(err => {
        Logger.error(err, 'Could not decorate list view data');
        return throwError(err);
      }),
    );
  }
}
