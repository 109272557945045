import { Directive, ElementRef, inject, Injector, Input, NgZone, OnDestroy, TemplateRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {
  InitializedTooltip,
  TooltipContent,
  TooltipInterface,
  TooltipPlacement,
  TooltipTrigger,
} from '@shared/ui/tooltip/browser/browser-tooltip-initializer';
import { TOOLTIP_INITIALIZERS } from '@shared/ui/tooltip/injection-tokens/tooltip-initializers';

@UntilDestroy()
@Directive()
export abstract class TooltipBaseDirective implements OnDestroy {
  protected readonly ngZone: NgZone = inject(NgZone);
  protected readonly elementRef: ElementRef = inject(ElementRef, { self: true, host: true });

  protected abstract getTrigger(): keyof typeof TooltipTrigger;
  protected abstract initializeTriggerSubscription(): void;
  protected triggerSubscription?: Subscription;

  protected readonly initializedTooltip?: InitializedTooltip = inject(TOOLTIP_INITIALIZERS)[this.getTrigger()]?.(
    inject(Injector),
    this.elementRef,
  );

  @Input() loopTooltipPlacement: TooltipPlacement = 'bottom';
  setLoopTooltipContent(content: TooltipContent | TooltipInterface | null | undefined) {
    this.initializedTooltip?.hide();

    if (!content) {
      this.triggerSubscription?.unsubscribe();
      this.initializedTooltip?.setContent(null);
      return;
    }

    this.initializeTriggerSubscription();

    if (!this.initializedTooltip) {
      return;
    }

    if (typeof content === 'string' || content instanceof TemplateRef) {
      this.initializedTooltip.setContent(content);
    } else {
      this.initializedTooltip.setContent(content.content);
      if (content.color) {
        this.initializedTooltip.setBackgroundColor(content.color);
      }
    }

    this.initializedTooltip.setPlacement(this.loopTooltipPlacement);
  }

  ngOnDestroy(): void {
    this.initializedTooltip?.destroy();
    this.triggerSubscription?.unsubscribe();
  }
}
