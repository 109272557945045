/* tslint:disable */

/**
 * Possible Integration Types
 */
export type IntegrationType = 'Grammarly' | 'Custom' | 'OpenAI' | 'Insights' | 'HubSpot';

export module IntegrationType {
  export const GRAMMARLY: IntegrationType = 'Grammarly';
  export const CUSTOM: IntegrationType = 'Custom';
  export const OPEN_AI: IntegrationType = 'OpenAI';
  export const INSIGHTS: IntegrationType = 'Insights';
  export const HUB_SPOT: IntegrationType = 'HubSpot';

  export function values(): IntegrationType[] {
    return [GRAMMARLY, CUSTOM, OPEN_AI, INSIGHTS, HUB_SPOT];
  }
}
