import { Pipe, PipeTransform } from '@angular/core';
import { Attendee } from '@shared/api/api-loop/models/attendee';
import { ResponseTypeModel } from '@shared/api/api-loop/models';

@Pipe({
  name: 'invitees',
})
export class InviteesPipe implements PipeTransform {
  transform(attendees: Attendee[]): Attendee[] | null {
    return attendees.filter(attendee => attendee.status !== ResponseTypeModel.ORGANIZER);
  }
}
