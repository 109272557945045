/* tslint:disable */

/**
 * Card types
 */
export type CardType =
  | 'CardMail'
  | 'CardAppointment'
  | 'CardChat'
  | 'CardShared'
  | 'CardTemplates'
  | 'CardCopied'
  | 'CardDraft';

export module CardType {
  export const CARD_MAIL: CardType = 'CardMail';
  export const CARD_APPOINTMENT: CardType = 'CardAppointment';
  export const CARD_CHAT: CardType = 'CardChat';
  export const CARD_SHARED: CardType = 'CardShared';
  export const CARD_TEMPLATES: CardType = 'CardTemplates';
  export const CARD_COPIED: CardType = 'CardCopied';
  export const CARD_DRAFT: CardType = 'CardDraft';

  export function values(): CardType[] {
    return [CARD_MAIL, CARD_APPOINTMENT, CARD_CHAT, CARD_SHARED, CARD_TEMPLATES, CARD_COPIED, CARD_DRAFT];
  }
}
