import { Injectable } from '@angular/core';
import { LoggerHelperService } from '@shared/services/logger/logger-helper.service';
import { ElectronProcessType } from '@dta/shared/models/logger.model';
import { CustomStringArg } from '@shared/api/log-loop/models';
import { StorageKey } from '@dta/shared/services/storage/storage.service';
import { v1 } from 'uuid';
import { Logger } from '@shared/services/logger/logger';

@Injectable()
export class LoggerHelperServiceWeb extends LoggerHelperService {
  //////////////////////////
  // Static properties
  //////////////////////////
  // Should be set one time
  private _browserType: BrowserType;
  private _browserVersion: string;
  private _domain: string;

  init(_userPath?: string, _electronProcessType?: ElectronProcessType) {
    super.init(_userPath, _electronProcessType);

    this.setBrowserType();
    this.setBrowserVersion();
    this.setDomain();
    this.setDeviceId();
  }

  private setBrowserType() {
    if (navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR') !== -1) {
      this._browserType = BrowserType.OPERA;
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      this._browserType = BrowserType.EDGE;
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      this._browserType = BrowserType.CHROME;
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      this._browserType = BrowserType.SAFARI;
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      this._browserType = BrowserType.FIREFOX;
    } else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document['documentMode'] === true) {
      this._browserType = BrowserType.EXPLORER;
    } else {
      this._browserType = BrowserType.OTHER;
    }
  }

  private setBrowserVersion() {
    this._browserVersion = navigator.appVersion;
  }

  private setDomain() {
    this._domain = window.location.hostname;
  }

  private setDeviceId() {
    let existingDeviceId = this._storageService.getItem(StorageKey.deviceId);
    if (!existingDeviceId) {
      existingDeviceId = v1();
      this._storageService.setItem(StorageKey.deviceId, existingDeviceId);
    }

    Logger._machineId = existingDeviceId;
  }

  protected getDefaultStringArg(): CustomStringArg[] {
    let customStringArgs = super.getDefaultStringArg();

    customStringArgs.push({ name: 'browserType', value: this._browserType });
    customStringArgs.push({ name: 'browserVersion', value: this._browserVersion });
    customStringArgs.push({ name: 'domain', value: this._domain });

    return customStringArgs;
  }
}

export enum BrowserType {
  CHROME = 'Chrome',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
  EDGE = 'Edge',
  EXPLORER = 'Explorer',
  OPERA = 'Opera',
  OTHER = 'Other',
}
