import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'video-component',
  templateUrl: './video.html',
  styleUrls: ['./video.scss'],
})
export class VideoComponent implements OnChanges, AfterViewInit {
  @Input() videoUrl: string;
  @Input() thumbnailPath: string;
  @Input() fileDialog: boolean = false;
  @Input() resumeAt: number = 0;
  @Input() fileName: string;
  @Output() onVideoError = new EventEmitter<never>();

  @ViewChild('videoPlayer', { static: true }) private videoPlayer;

  videoNotSupported: boolean = false;

  constructor(private _changeDetection: ChangeDetectorRef) {}

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['videoUrl']) {
      this.videoPlayer.nativeElement.load();

      if (this.fileDialog) {
        if (this.resumeAt && this.resumeAt > 0) {
          this.videoPlayer.nativeElement.currentTime = this.resumeAt;
        }
        this.videoPlayer.nativeElement.play();
      }
    }
  }

  onloadedmetadata() {
    let vH = this.videoPlayer.nativeElement.videoHeight;
    let vW = this.videoPlayer.nativeElement.videoHeight;

    if (vH === 0 || vW === 0) {
      this.videoNotSupported = true;

      this._changeDetection.detectChanges();
    }
  }

  togglePlay() {
    if (!this.fileDialog) {
      return;
    }
    if (this.videoPlayer.nativeElement.paused) {
      this.videoPlayer.nativeElement.play();
    } else {
      this.videoPlayer.nativeElement.pause();
    }
  }

  increaseVolume() {
    let newVolume = this.videoPlayer.nativeElement.volume + 0.2;
    if (newVolume > 1) {
      return;
    }

    this.videoPlayer.nativeElement.volume += 0.2;
  }

  decreaseVolume() {
    let newVolume = this.videoPlayer.nativeElement.volume - 0.2;
    if (newVolume < 0) {
      return;
    }

    this.videoPlayer.nativeElement.volume -= 0.2;
  }

  preventClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowUp':
        this.increaseVolume();
        break;
      case 'ArrowDown':
        this.decreaseVolume();
        break;
      case ' ':
        this.togglePlay();
        break;
      default:
        break;
    }
  }
}
