import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import {
  AuthenticateSharedInboxExtraData,
  ChannelSetupServiceI,
  InboxProvider,
  PermissionDeniedReason,
  PersonalInboxSetupData,
  SetupData,
  SharedInboxSetupData,
  TeamSetupData,
} from './channel-setup.service.interface';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthBase, AuthExchange, AuthImapSmtp, AuthRemoteImap } from '@shared/api/api-loop/models';
import { ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ContactService } from '../contact/contact.service';
import { Router } from '@angular/router';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { OAuthService } from '@shared/services/oauth/oauth.service';
import { SyncSettingService } from '../sync-settings/sync-settings.service';
import { SyncSettings } from '@dta/shared/models/settings.model';
import { HttpErrorResponse } from '@angular/common/http';
import { EmailTypeEnum, SyncSettingsType } from '@dta/shared/models/sync-settings-type.model';
import { AuthService } from '../auth/auth.service';
import { ExchangeAuthData, ImapAuthData } from '../auth/auth.service.interface';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { AuthErrorForUI } from '../auth/auth-error.helper';
import { UserService } from '../user/user.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';

@Injectable()
export class ChannelSetupService implements ChannelSetupServiceI {
  constructor(
    private _contactService: ContactService,
    private _syncSettingService: SyncSettingService,
    private _authService: AuthService,
    private _router: Router,
    private _storageService: StorageService,
    private _oAuthService: OAuthService,
    private _userManagerService: UserManagerService,
    private _userService: UserService,
  ) {}

  private getSharedInboxAuthorizationToken(data: SharedInboxSetupData): string {
    return data?.inboxAuth?.token?.accessToken ? `Bearer ${data.inboxAuth.token.accessToken}` : undefined;
  }

  private cleanupSetupData(forUserEmail: string, privateInbox = false) {
    let storageKey = privateInbox ? StorageKey.privateInboxSetup : StorageKey.sharedInboxSetup;

    let key = this._storageService.getKey(forUserEmail, storageKey);
    this._storageService.removeItem(key);
  }

  /////////////////////////////
  // State management methods
  /////////////////////////////
  getSetupDataByState(state: string, privateInbox = false): { data: SetupData; forUserEmail: string } {
    let storageKey = privateInbox ? StorageKey.privateInboxSetup : StorageKey.sharedInboxSetup;

    let allSharedInboxSetupKeys = Object.keys(localStorage).filter(key => key.includes(storageKey));
    let setupDataKeyForState = allSharedInboxSetupKeys.find(
      key => (this._storageService.getParsedItem(key) || {}).state === state,
    );

    let data = this._storageService.getParsedItem(setupDataKeyForState) || {};
    let forUserEmail = setupDataKeyForState?.split(StorageKey.sharedInboxSetup)[0]?.split('_')[0];
    return { data, forUserEmail };
  }

  getSetupData(forUserEmail: string, privateInbox = false): SetupData {
    let storageKey = privateInbox ? StorageKey.privateInboxSetup : StorageKey.sharedInboxSetup;

    let key = this._storageService.getKey(forUserEmail, storageKey);
    return this._storageService.getParsedItem(key) || {};
  }

  persistSetupData(forUserEmail: string, data: SetupData, privateInbox = false) {
    let storageKey = privateInbox ? StorageKey.privateInboxSetup : StorageKey.sharedInboxSetup;

    let key = this._storageService.getKey(forUserEmail, storageKey);
    this._storageService.setStringifiedItem(key, data);
  }

  ////////////////////
  // My inboxes setup
  ////////////////////
  enableEmilSync(
    forUserEmail: string,
    provider: InboxProvider,
    extraData?: AuthenticateSharedInboxExtraData,
  ): Observable<any> {
    let syncSettingsType = this.getSyncSettingsType(provider);

    let data = new PersonalInboxSetupData();
    data = Object.assign(data, { provider, syncSettingsType });

    if ([InboxProvider.GOOGLE_OAUTH, InboxProvider.MICROSOFT_OAUTH].includes(provider)) {
      this.persistSetupData(forUserEmail, data, true);
      return this.authorizeInbox(forUserEmail, provider, data, SetupType.PERSONAL_INBOX, extraData);
    }

    return this.authorizeInbox(forUserEmail, provider, data, SetupType.PERSONAL_INBOX, extraData).pipe(
      tap(auth => (data.inboxAuth = auth)),
      mergeMap(() => this.manageSyncSettings(forUserEmail, data)),
      mergeMap(() => this._userService.fetchOrUpdateUser(forUserEmail)),
    );
  }

  handlePersonalSharedInboxDeeplink(
    state: string,
    code: string,
    oAuthRefreshToken: string,
    oAuthEmail: string,
    permissionDenied?: boolean,
  ): Observable<any> {
    return this.handleSharedInboxOauthDeeplink(
      state,
      code,
      oAuthRefreshToken,
      oAuthEmail,
      SetupType.PERSONAL_SHARED_INBOX,
      permissionDenied,
    );
  }

  handlePersonalInboxDeeplink(
    state: string,
    code: string,
    oAuthRefreshToken: string,
    oAuthEmail: string,
    permissionDenied?: boolean,
  ): Observable<any> {
    let { data, forUserEmail } = this.getSetupDataByState(state, true);

    if (oAuthEmail !== forUserEmail) {
      permissionDenied = true;
    }

    if (permissionDenied) {
      return this.onPermissionDenied(forUserEmail, 'permissionDenied', SetupType.PERSONAL_INBOX);
    }

    return this.createTokenCodeAuth(forUserEmail, code, oAuthRefreshToken, SetupType.PERSONAL_INBOX).pipe(
      /**
       * Assign auth data to setup data
       */
      tap(auth => {
        data.inboxAuth = auth;
        data.oAuthRefreshToken = oAuthRefreshToken;
      }),
      /**
       * Manage sync settings
       */
      mergeMap(() => this.manageSyncSettings(forUserEmail, data)),
      /**
       * Update user
       */
      mergeMap(() => this._userService.fetchOrUpdateUser(forUserEmail)),
      /**
       * Cleanup and reroute
       */
      tap(() => {
        this.cleanupSetupData(forUserEmail, true);
        this._router.navigate(['/user-settings/my-inboxes'], { queryParams: { enableSyncSuccess: true } });
      }),
      /**
       * Handle err
       */
      catchError((err: AuthErrorForUI) =>
        err.errorCode === 'UNAUTHORIZED'
          ? this.handleOauthUnauthorized(forUserEmail, true)
          : this.onPermissionDenied(forUserEmail, 'unknown', SetupType.PERSONAL_INBOX),
      ),
    );
  }

  //////////////////////////////
  // Shared inbox authorization
  //////////////////////////////
  handleSharedInboxOauthDeeplink(
    state: string,
    code: string,
    oAuthRefreshToken: string,
    oAuthEmail: string,
    setupType: SetupType,
    permissionDenied?: boolean,
  ): Observable<any> {
    let { data, forUserEmail } = this.getSetupDataByState(state);

    if (
      oAuthEmail === forUserEmail &&
      !data.microsoftSharedInboxEmail &&
      setupType !== SetupType.PERSONAL_SHARED_INBOX
    ) {
      return this.onPermissionDenied(forUserEmail, 'emailSameAsLoggedInUser', setupType);
    }

    if (permissionDenied) {
      return this.onPermissionDenied(forUserEmail, 'permissionDenied', setupType);
    }

    let nextStepObs =
      data.provider === InboxProvider.MICROSOFT_SHARED_INBOX
        ? this.createMicrosoftSharedInboxAuth(forUserEmail, oAuthRefreshToken, oAuthEmail, setupType)
        : this.createTokenCodeAuth(forUserEmail, code, oAuthRefreshToken, setupType);

    return nextStepObs.pipe(
      catchError((err: AuthErrorForUI) =>
        err.errorCode === 'UNAUTHORIZED'
          ? this.handleOauthUnauthorized(forUserEmail)
          : this.onPermissionDenied(forUserEmail, 'unknown', setupType),
      ),
    );
  }

  authenticateInbox(
    forUserEmail: string,
    provider: InboxProvider,
    setupType: SetupType,
    extraData?: AuthenticateSharedInboxExtraData,
  ): Observable<any> {
    let syncSettingsType = this.getSyncSettingsType(provider);

    // Persist type (and additional data)
    let data = this.getSetupData(forUserEmail) as SharedInboxSetupData;
    data.provider = provider;
    data.syncSettingsType = syncSettingsType;
    data.aliasEmail = extraData?.gmailAlias;
    data.microsoftSharedInboxEmail = extraData?.microsoftSharedInboxEmail;
    data.inboxAuth = undefined;
    data.oAuthRefreshToken = undefined;

    this.persistSetupData(forUserEmail, data);

    // Authorize
    return this.authorizeInbox(forUserEmail, provider, data, setupType, extraData);
  }

  private authorizeInbox(
    forUserEmail: string,
    provider: InboxProvider,
    data: SetupData,
    setupType: SetupType,
    extraData?: AuthenticateSharedInboxExtraData,
  ): Observable<any> {
    try {
      switch (provider) {
        case InboxProvider.GOOGLE_ALIAS:
          this.onSharedInboxAuthSuccess(forUserEmail, undefined, setupType);
          return of(undefined);
        case InboxProvider.GOOGLE_OAUTH:
          return this.openExternalOAuth(data.state, 'google', setupType === SetupType.PERSONAL_INBOX && forUserEmail);
        case InboxProvider.MICROSOFT_OAUTH:
          return this.openExternalOAuth(
            data.state,
            'microsoft',
            setupType === SetupType.PERSONAL_INBOX && forUserEmail,
          );
        case InboxProvider.MICROSOFT_SHARED_INBOX:
          return this.openExternalOAuth(
            data.state,
            'microsoft-extended',
            undefined,
            extraData?.microsoftSharedInboxEmail,
          );
        case InboxProvider.MICROSOFT_EXCHANGE:
          return this.createExchangeAuth(forUserEmail, extraData.exchangeAuthData, setupType);
        case InboxProvider.IMAP:
          return this.createImapAuth(forUserEmail, extraData.imapAuthData, setupType);
        default:
          return throwError(() => `sharedInboxProvider ${provider} not supported`);
      }
    } catch (err) {
      Logger.error(
        err,
        `Error in authorizeInbox for ${forUserEmail} - ${provider}`,
        [LogTag.INTERESTING_ERROR, LogTag.SHARED_INBOX_SETUP],
        true,
        'Error in authorizeInbox',
      );

      return throwError(() => err);
    }
  }

  createInbox(forUserEmail: string, isPersonal: boolean = false): Observable<ContactModel> {
    let data = this.getSetupData(forUserEmail) as SharedInboxSetupData;
    return of(undefined).pipe(
      /**
       * Manage sync settings
       */
      mergeMap(() => this.manageSyncSettings(forUserEmail, data, true)),
      /**
       * Create shared inbox
       */
      mergeMap(() => {
        return this._contactService.createSharedInboxGroup(
          forUserEmail,
          data,
          this.getSharedInboxAuthorizationToken(data),
          isPersonal,
        );
      }),
      /**
       * Cleanup
       */
      tap(() => this.cleanupSetupData(forUserEmail)),
      /**
       * Log any error
       */
      catchError(err => {
        Logger.error(
          err,
          `Error in createSharedInbox for ${forUserEmail}`,
          [LogTag.INTERESTING_ERROR, LogTag.SHARED_INBOX_SETUP],
          true,
          'Error in createSharedInbox',
        );

        return throwError(() => err);
      }),
    );
  }

  createTeam(forUserEmail: string, data: TeamSetupData): Observable<ContactModel> {
    if (!data?.name) {
      throw new Error('data.name cannot be nil');
    }

    let currentUser = this._userManagerService.getCurrentUser();

    let group = new GroupModel({
      $type: GroupModel.type,
      name: data.name,
      description: data.description,
      admins: BaseModel.createListOfResources([currentUser]),
      members: BaseModel.createListOfResources([...(data.members || []), currentUser]),
    });

    return this._contactService.createGroup(forUserEmail, group);
  }

  ///////////////////
  // Private helpers
  ///////////////////
  private manageSyncSettings(forUserEmail: string, data: SetupData, forSharedInbox = false): Observable<SyncSettings> {
    // When connecting google alias, sync settings should already exist
    if (data.provider === InboxProvider.GOOGLE_ALIAS) {
      return of(undefined);
    }

    let customAuthorizationToken = forSharedInbox
      ? this.getSharedInboxAuthorizationToken(data as SharedInboxSetupData)
      : undefined;

    return of(undefined).pipe(
      /**
       * Fetch current settings
       */
      mergeMap(() => this._syncSettingService.fetchSyncSettings(forUserEmail, customAuthorizationToken)),
      /**
       * Update if needed (sync not enabled)
       */
      mergeMap((syncSettings: SyncSettings) =>
        !syncSettings.isEnabled
          ? this.updateSyncSettings(forUserEmail, data, syncSettings, customAuthorizationToken)
          : of(syncSettings),
      ),
      /**
       * Handle missing (create)
       */
      catchError((err: HttpErrorResponse) =>
        err.status === 404
          ? this.createSyncSettings(forUserEmail, data, customAuthorizationToken)
          : throwError(() => err),
      ),
    );
  }

  private createSyncSettings(
    forUserEmail: string,
    data: SetupData,
    customAuthorizationToken?: string,
  ): Observable<SyncSettings> {
    return this._syncSettingService.createSyncSettings(forUserEmail, {
      oAuthRefreshToken: data.oAuthRefreshToken,
      auth: data.inboxAuth,
      syncSettingsType: data.syncSettingsType,
      customAuthorizationToken,
    });
  }

  private updateSyncSettings(
    forUserEmail: string,
    data: SetupData,
    settingsToUpdate: SyncSettings,
    customAuthorizationToken?: string,
  ): Observable<SyncSettings> {
    return this._syncSettingService.updateSyncSettings(forUserEmail, {
      oAuthRefreshToken: data.oAuthRefreshToken,
      settingsToUpdate,
      auth: data.inboxAuth,
      syncSettingsType: data.syncSettingsType,
      customAuthorizationToken,
    });
  }

  private getSyncSettingsType(provider: InboxProvider): SyncSettingsType {
    switch (provider) {
      case InboxProvider.GOOGLE_OAUTH:
      case InboxProvider.GOOGLE_ALIAS:
        return SyncSettingsType.SYNC_SETTINGS_GMAIL;
      case InboxProvider.MICROSOFT_OAUTH:
      case InboxProvider.MICROSOFT_SHARED_INBOX:
        return SyncSettingsType.SYNC_SETTINGS_MICROSOFT;
      case InboxProvider.MICROSOFT_EXCHANGE:
        return SyncSettingsType.SYNC_SETTINGS_EXCHANGE;
      case InboxProvider.IMAP:
        // Default to SMTP, change to remote imap if needed
        return SyncSettingsType.SYNC_SETTINGS_IMAP_SMTP;
      default:
        throw Error(`No syncSettingsType mappings for ${provider}`);
    }
  }

  /////////////////////////
  // Authorization helpers
  /////////////////////////
  private openExternalOAuth(
    state: string,
    oAuthType: 'google' | 'microsoft' | 'microsoft-extended',
    emailHint?: string,
    microsoftSharedInboxEMail?: string,
  ): Observable<any> {
    this._oAuthService.openExternalOAuth(
      emailHint,
      oAuthType,
      { sameWindow: true, fullScope: true },
      state,
      microsoftSharedInboxEMail,
    );
    return of(undefined);
  }

  private createExchangeAuth(
    forUserEmail: string,
    authData: ExchangeAuthData,
    setupType: SetupType,
  ): Observable<AuthBase> {
    return this._authService.createExchangeAuth(authData).pipe(
      // Preserve password for creation of sync settings, will be removed once SI is created
      map((auth: AuthExchange) => ((auth.secret = authData.password), auth)),
      tap(
        (auth: AuthBase) =>
          setupType !== SetupType.PERSONAL_INBOX && this.onSharedInboxAuthSuccess(forUserEmail, auth, setupType),
      ),
      /**
       * Log any error
       */
      catchError(err => {
        Logger.error(
          err,
          `Error in createExchangeAuth for ${forUserEmail}`,
          [LogTag.INTERESTING_ERROR, LogTag.SHARED_INBOX_SETUP],
          true,
          'Error in createExchangeAuth',
        );

        return throwError(() => err);
      }),
    );
  }

  private createMicrosoftSharedInboxAuth(
    forUserEmail: string,
    oAuthRefreshToken: string,
    oAuthEmail: string,
    setupType: SetupType,
  ): Observable<AuthBase> {
    let data = this.getSetupData(forUserEmail);
    return this._authService.createMicrosoftAuth(oAuthRefreshToken, oAuthEmail, data.microsoftSharedInboxEmail).pipe(
      tap((auth: AuthBase) => this.onSharedInboxAuthSuccess(forUserEmail, auth, setupType, oAuthRefreshToken)),
      /**
       * Log any error
       */
      catchError(err => {
        Logger.error(
          err,
          `Error in createMicrosoftSharedInboxAuth for ${forUserEmail}`,
          [LogTag.INTERESTING_ERROR, LogTag.SHARED_INBOX_SETUP],
          true,
          'Error in createMicrosoftSharedInboxAuth',
        );

        if (err.errorCode === 'OTHER_LOGIN_ERROR') {
          return this.onPermissionDenied(forUserEmail, 'permissionDenied', setupType);
        }

        return throwError(() => err);
      }),
    );
  }

  private createImapAuth(forUserEmail: string, authData: ImapAuthData, setupType: SetupType): Observable<AuthBase> {
    return this._authService.createImapAuth(authData).pipe(
      // Preserve password for creation of sync settings, will be removed once SI is created
      map((auth: AuthRemoteImap | AuthImapSmtp) => {
        auth.imapPassword = authData.incomingServerPassword || authData.password;
        auth.smtpPassword = authData.outgoingServerPassword;
        return auth;
      }),
      tap((auth: AuthBase) => {
        // Correct sync setting type if needed
        if (auth.$type === EmailTypeEnum.AUTH_REMOTE_IMAP) {
          let data = this.getSetupData(forUserEmail);
          data.syncSettingsType = SyncSettingsType.SYNC_SETTINGS_REMOTE_IMAP;
          this.persistSetupData(forUserEmail, data);
        }
      }),
      tap(
        (auth: AuthBase) =>
          setupType !== SetupType.PERSONAL_INBOX && this.onSharedInboxAuthSuccess(forUserEmail, auth, setupType),
      ),
      /**
       * Log any error
       */
      catchError(err => {
        Logger.error(
          err,
          `Error in createImapAuth for ${forUserEmail}`,
          [LogTag.INTERESTING_ERROR, LogTag.SHARED_INBOX_SETUP],
          true,
          'Error in createImapAuth',
        );

        return throwError(() => err);
      }),
    );
  }

  private createTokenCodeAuth(
    forUserEmail: string,
    code: string,
    oAuthRefreshToken: string,
    setupType: SetupType,
  ): Observable<AuthBase> {
    return this._authService.createTokenCodeAuth(code).pipe(
      tap(
        (auth: AuthBase) =>
          setupType !== SetupType.PERSONAL_INBOX &&
          this.onSharedInboxAuthSuccess(forUserEmail, auth, setupType, oAuthRefreshToken),
      ),
      /**
       * Log any error
       */
      catchError(err => {
        Logger.error(
          err,
          `Error in createTokenCodeAuth for ${forUserEmail}`,
          [LogTag.INTERESTING_ERROR, LogTag.SHARED_INBOX_SETUP],
          true,
          'Error in createTokenCodeAuth',
        );

        return throwError(() => err);
      }),
    );
  }

  private onSharedInboxAuthSuccess(
    forUserEmail: string,
    authData: AuthBase,
    setupType: SetupType,
    oAuthRefreshToken?: string,
  ) {
    let data = this.getSetupData(forUserEmail);
    data.inboxAuth = authData;
    data.oAuthRefreshToken = oAuthRefreshToken;
    this.persistSetupData(forUserEmail, data);

    this._userManagerService.ensureCurrentUser(forUserEmail);

    let url =
      setupType === SetupType.SHARED_INBOX
        ? ['/user-settings/shared-inboxes/create']
        : ['/user-settings/personal-inboxes/create'];

    this._router.navigate(url, { queryParams: { sharedInboxState: data.state } });
  }

  private onPermissionDenied(
    forUserEmail: string,
    permissionDeniedReason: PermissionDeniedReason,
    privateInbox: SetupType,
  ): Observable<any> {
    let data = this.getSetupData(forUserEmail);

    let url = [''];
    switch (privateInbox) {
      case SetupType.PERSONAL_INBOX:
        url = ['/user-settings/my-inboxes'];
        break;
      case SetupType.PERSONAL_SHARED_INBOX:
        url = ['/user-settings/personal-inboxes/create'];
        break;
      case SetupType.SHARED_INBOX:
        url = ['/user-settings/shared-inboxes/create'];
        break;
    }

    this._userManagerService.ensureCurrentUser(forUserEmail);
    this._router.navigate(url, { queryParams: { permissionDeniedReason, sharedInboxState: data.state } });

    return of(undefined);
  }

  private handleOauthUnauthorized(forUserEmail: string, privateInbox?: boolean): Observable<any> {
    Logger.customLog(
      'Got unauthorized when doing oAauth',
      LogLevel.ERROR,
      privateInbox ? LogTag.PRIVATE_INBOX_SETUP : LogTag.SHARED_INBOX_SETUP,
    );

    if (forUserEmail) {
      this.cleanupSetupData(forUserEmail);
      this._userManagerService.ensureCurrentUser(forUserEmail);
    }

    this._router.navigate(privateInbox ? ['/user-settings/my-inboxes'] : ['/user-settings/shared-inboxes']);

    return of(undefined);
  }
}

export enum SetupType {
  SHARED_INBOX = 'shared-inbox',
  PERSONAL_SHARED_INBOX = 'personal-shared-inbox',
  PERSONAL_INBOX = 'personal-inbox',
  TEAM = 'team',
}
