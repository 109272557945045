import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class MainMenuService {
  menu: any;
  sidebarActive: boolean = true;

  openAccounts$: Subject<any> = new Subject();
  sidebarToggle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  sideMenuToggle$: Subject<string> = new Subject();

  constructor() {
    this.menu = {
      open: false,
    };
  }

  get sidebarToggleObs() {
    return this.sidebarToggle$.asObservable();
  }

  openMenuAndAccounts() {
    this.toggleMenu();
    setTimeout(() => {
      this.openAccounts$.next(false);
    }, 0);
  }

  toggleMenu = () => {
    this.menu.open ? (this.menu.open = false) : (this.menu.open = true);
  };

  openMenu = () => {
    this.menu.open = true;
  };

  closeMenu = () => {
    this.menu.open = false;
  };

  toggleSidebar(open?: boolean) {
    if (open !== undefined) {
      open ? this.openSidebar() : this.closeSidebar();
      return;
    }

    !this.sidebarActive ? this.openSidebar() : this.closeSidebar();
  }

  openSidebar() {
    if (this.sidebarActive) {
      return;
    }

    this.sidebarActive = true;
    this.sidebarToggle$.next(true);
  }

  closeSidebar() {
    if (!this.sidebarActive) {
      return;
    }

    this.sidebarActive = false;
    this.sidebarToggle$.next(false);
  }

  sideMenuToggle = state => {
    this.sideMenuToggle$.next(state);
  };
}
