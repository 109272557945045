import { inject, Pipe, PipeTransform } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Pipe({
  name: 'chatConversationName',
})
export class ChatConversationNamePipe implements PipeTransform {
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  transform(conversation: ConversationModel): string {
    const shareList = conversation.getShareList();
    return shareList.find(user => user.id !== this.userManagerService.getCurrentUserId()).name;
  }
}
