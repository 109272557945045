import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from '@dta/ui/components/common/tooltip/tooltip.directive';
import { TimeAgoModule } from '@dta/ui/components/common/time-ago/time-ago.module';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  imports: [CommonModule, TimeAgoModule],
  exports: [TooltipDirective, TooltipComponent],
})
export class TooltipModule {}
