<div class="shared-tags-comp-wrapper" (window:resize)="onResize()">
  <div
    class="shared-tags-container middle-view"
    #sharedTagsContainer
    [class.on-header]="isOnHeader"
    [class.middle-view]="!isOnHeader"
    [class.disabled]="offline"
    [class.delegating]="delegating"
    [class.show-hidden-tags]="showHiddenTags"
    [class.is-clickable]="isClickable && !isOnHeader"
  >
    <!-- Recipient tag -->
    <label-tag
      *ngIf="shouldShowRecipientTag"
      [content]="recipient"
      [displayWideContent]="true"
      [isClickable]="isClickable"
      (click)="onChannelTagClick($event)"
    >
    </label-tag>

    <!-- Snooze tag -->
    <label-tag
      *ngIf="snoozeDueDate"
      [extraIcon]="snoozeSvg"
      [content]="snoozeDueDate"
      [color]="'#f5a623'"
      [displayWideContent]="true"
      [isClickable]="false"
    >
    </label-tag>

    <!-- Assignee tag -->
    <label-tag
      [content]="name"
      [color]="color"
      [assigneeTag]="assigneeTag"
      [delegating]="delegating"
      [class.hide]="!isOnHeader && !assigneeTag && (!statusTag || statusTag.name === 'Unassigned')"
      [isClickable]="isClickable"
      (click)="onTagClick($event, assigneeTag || statusTag)"
    >
    </label-tag>

    <ng-container *ngIf="!!classificationTag">
      <loop-classification-tag
        [classificationTag]="classificationTag"
        [isClassificationAssignVisible]="false"
        (click)="onTagClick($event, classificationTag)"
      ></loop-classification-tag>
    </ng-container>

    <!-- System tags -->
    <ng-container *ngFor="let systemTag of systemTags;">
      <label-tag
        *ngIf="systemTag.name === SharedTagSystemNames.SPAM"
        [content]="systemTag.name"
        [color]="systemTag.color"
        [isClickable]="isClickable"
        (click)="onTagClick($event, systemTag)"
      >
      </label-tag>
    </ng-container>

    <!-- Shared label tags -->
    <ng-container *ngFor="let labelTag of sharedLabelTags;">
      <label-tag
        *ngIf="labelTag.name"
        [content]="labelTag.name"
        [color]="labelTag.color"
        [isClickable]="isClickable"
        (click)="onTagClick($event, labelTag)"
      >
      </label-tag>
    </ng-container>

    <!-- Label tags -->
    <ng-container *ngFor="let labelTag of labelTags;">
      <label-tag
        *ngIf="labelTag.name"
        [content]="labelTag.name"
        [isPrivate]="true"
        [color]="labelTag.color"
        [isClickable]="isClickable"
        (click)="onTagClick($event, labelTag)"
      >
      </label-tag>
    </ng-container>
  </div>

  <div
    *ngIf="hasOverflow"
    class="toggle-hidden-tags-button"
    [class.hide-button]="showHiddenTags"
    (click)="toggleHiddenTags($event)"
  >
    <svg-comp [src]="dropdownSvg"> </svg-comp>
  </div>
</div>
