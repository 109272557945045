import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FromSelectorComponent } from '@dta/ui/components/common/from-selector/from-selector.component';
import { OutsideClickModule } from '@dta/ui/directives/outside-click/outside-click.module';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';

@NgModule({
  declarations: [FromSelectorComponent],
  imports: [CommonModule, OutsideClickModule, TooltipModule, GraphicsModule],
  exports: [FromSelectorComponent],
})
export class FromSelectorModule {}
