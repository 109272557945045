import * as _ from 'lodash';
import {
  EventBase,
  EventCardCreated,
  EventCommentCreated,
  EventContactCreated,
  EventSharedTagCreated,
  EventSharedTagDeleted,
  EventSharedTagsUpdated,
  EventSharedTagUpdated,
  EventSignatureCreated,
  EventTagCreated,
  EventTagsUpdated,
  EventUserStatus,
  ListOfResourcesOfTag,
  ResourceBase,
  SharedTagFolder,
  Tag,
} from '@shared/api/api-loop/models';
import { EventType } from '../../event-synchronization/event-synchronization.service';
import {
  CardAppointmentModel,
  CardChatModel,
  CardDraftModel,
  CardMailModel,
  CardSharedModel,
  CardTemplateModel,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import {
  CommentChatModel,
  CommentDraftModel,
  CommentMailModel,
  CommentTemplateModel,
  QuoteCommentModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import {
  AttendeeModel,
  GroupModel,
  LoginUserModel,
  UserModel,
} from '@dta/shared/models-api-loop/contact/contact.model';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import {
  SharedTagAssignedByModel,
  SharedTagAssigneeModel,
  SharedTagCustomModel,
  SharedTagFolderModel,
  SharedTagLabelModel,
  SharedTagMentionModel,
  SharedTagPriorityModel,
  SharedTagReactionModel,
  SharedTagStatusModel,
  SharedTagSystemModel,
} from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { SignatureModel } from '@dta/shared/models-api-loop/signature.model';
import { ListOfTagsModel, TagLabelModel, TagModel, TimedTagModel } from '@dta/shared/models-api-loop/tag.model';

export class EventModel implements EventBase {
  id: string;
  created: string;
  recipient: any;
  $type: string;

  _ex: EventExtraData;

  constructor(data: EventBase) {
    _.assign(this, data);
    this.decorate();
  }

  static createList(events: EventBase[]): EventModel[] {
    return _.map(events, event => new EventModel(event));
  }

  private decorate() {
    let ex: EventExtraData = {};

    switch (this.$type) {
      case EventType.CommentCreated:
      case EventType.CommentUpdated:
        ex.resource = (<EventCommentCreated>this).comment;
        break;
      case EventType.CardCreated:
      case EventType.CardUpdated:
        ex.resource = (<EventCardCreated>this).card;
        break;
      case EventType.ContactCreated:
      case EventType.ContactDeleted:
      case EventType.ContactUpdated:
        ex.resource = (<EventContactCreated>this).contact;
        break;
      case EventType.SignatureCreated:
      case EventType.SignatureDeleted:
      case EventType.SignatureUpdated:
        ex.resource = (<EventSignatureCreated>this).signature;
        break;
      case EventType.TagCreated:
      case EventType.TagDeleted:
      case EventType.TagUpdated:
        ex.resource = (<EventTagCreated>this).tag;
        break;
      case EventType.FolderCreated:
      case EventType.FolderUpdated:
      case EventType.FolderDeleted:
        ex.resource = (<EventSharedTagCreated>this).tag;
        break;
      case EventType.TagsUpdated:
        let tagsUpdated = <EventTagsUpdated>this;
        ex.resource = tagsUpdated.tags.parent;
        ex.revision = parseInt(tagsUpdated.tags.revision);
        ex.tags = tagsUpdated?.tags?.tags;
        ex.type = tagsUpdated.$type;
        break;
      case EventType.SharedTagsUpdated:
        let sharedTagsUpdated = <EventSharedTagsUpdated>this;
        ex.resource = sharedTagsUpdated.tags.parent;
        ex.revision = parseInt(sharedTagsUpdated.tags.revision);
        ex.tags = sharedTagsUpdated?.tags?.tags;
        ex.type = sharedTagsUpdated.$type;
        break;
      case EventType.UserStatus:
        ex.resource = (<EventUserStatus>this).user;
        break;
      default:
        break;
    }

    if (ex.resource) {
      ex.revision = ex.revision || parseInt(ex.resource.revision);
      ex.type = ex.type || ex.resource.$type;
    }

    this._ex = ex;
  }
  static areEventResourceTypesSupported(model: EventModel): boolean {
    return (
      _.isEmpty(model._ex) ||
      (this.isResourceTypeSupported(model) &&
        this.isResourceParentTypeSupported(model) &&
        this.isTagListSupported(model))
    );
  }

  private static isResourceTypeSupported(model: EventModel): boolean {
    return !model._ex.resource?.['$type'] || supportedModelTypes.includes(model._ex.resource?.['$type']);
  }

  private static isResourceParentTypeSupported(model: EventModel): boolean {
    return !model._ex.resource?.['parent'] || supportedModelTypes.includes(model._ex.resource?.['parent'].$type);
  }

  private static isTagListSupported(model: EventModel): boolean {
    return !model._ex.tags || !_.some(model._ex.tags.resources, (tag: Tag) => !supportedModelTypes.includes(tag.$type));
  }
}

const supportedModelTypes: string[] = [
  // CARDS
  CardMailModel.type,
  CardSharedModel.type,
  CardAppointmentModel.type,
  CardChatModel.type,
  CardTemplateModel.type,
  CardDraftModel.type,

  // COMMENTS
  CommentMailModel.type,
  CommentChatModel.type,
  CommentTemplateModel.type,
  QuoteCommentModel.type,
  CommentDraftModel.type,

  // CONTACTS
  UserModel.type,
  LoginUserModel.type,
  GroupModel.type,
  AttendeeModel.type,

  // SHARED TAGS
  SharedTagAssigneeModel.type,
  SharedTagStatusModel.type,
  SharedTagCustomModel.type,
  SharedTagAssignedByModel.type,
  SharedTagPriorityModel.type,
  SharedTagSystemModel.type,
  SharedTagReactionModel.type,
  SharedTagMentionModel.type,
  SharedTagLabelModel.type,

  // TAGS
  TimedTagModel.type,
  ListOfTagsModel.type,
  TagModel.type,
  TagLabelModel.type,

  // SHARED TAGS
  SharedTagFolderModel.type,

  // OTHER
  FileModel.type,
  SignatureModel.type,
];

export interface EventExtraData {
  resource?: ResourceBase;
  tags?: ListOfResourcesOfTag;
  revision?: number;
  type?: string;
}
