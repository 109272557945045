import { ChangeDetectorRef, Component, ElementRef, inject, ViewChild } from '@angular/core';
import {
  BaseFormControlComponent,
  provideControlValueAccessor
} from '@shared/modules/form-controls/base-form-control.component';
import { getHours, getMinutes, isDate, isValid, setHours, setMinutes, startOfDay, startOfMinute } from 'date-fns';

@Component({
  selector: 'loop-date-time-control',
  templateUrl: './date-time-control.component.html',
  styleUrls: ['./date-time-control.component.scss'],
  providers: [provideControlValueAccessor(DateTimeControlComponent)]
})
export class DateTimeControlComponent extends BaseFormControlComponent<Date> {
  private readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);

  _datePart: Date | null = null;
  _timePart: Date | null = null;

  @ViewChild('timeInput') timeInput?: ElementRef<HTMLInputElement>;

  override handleModelChange(val: Date | null): void {
    super.handleModelChange(val);
    this.updateParts();
    this.handleBlur();
  }

  override writeValue(value: Date): void {
    super.writeValue(value);
    this.updateParts();
  }

  updateValue(): void {
    if (this._datePart) {
      this._datePart = startOfDay(this._datePart);

      if (this._timePart && isDate(this._timePart) && isValid(this._timePart)) {
        this._datePart = setHours(this._datePart, getHours(this._timePart));
        this._datePart = setMinutes(this._datePart, getMinutes(this._timePart));
      }
    }

    this.handleModelChange(this._datePart ? startOfMinute(this._datePart) : null);
    this.changeDetector.detectChanges();
  }

  handleDateOpenChange(): void {
    setTimeout(() => {
      this.timeInput?.nativeElement.focus();
    });
  }

  handleInputBlur(): void {
    this.updateValue();
    this.handleBlur();
  }

  protected focusHandler(): void {
    //noop
  }

  private updateParts(): void {
    const currentValue = this.value$.getValue();
    this._datePart = currentValue ? startOfDay(currentValue) : null;

    // Change time part only if it really changed
    if (!(this._timePart && currentValue && startOfMinute(currentValue).getTime() === this._timePart.getTime())) {
      this._timePart = currentValue ? startOfMinute(currentValue) : null;
    }
    this.changeDetector.detectChanges();
  }
}
