import { Directive } from '@angular/core';
import { SidebarContacts } from 'dta/shared/models/contact.model';
import { forkJoin, merge, Observable, of, tap, timer } from 'rxjs';
import { debounceTime, filter, map, mergeMap, startWith } from 'rxjs/operators';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { UnreadStateChange } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { CONSTANTS } from '@shared/models/constants/constants';

@Directive()
export class ContactHelperService {
  constructor(private _contactService: ContactService) {}

  get constructorName(): string {
    return 'ContactHelperService';
  }

  subscribeToTeamsAndSharedInboxesCount(
    forUserEmail: string,
  ): Observable<{ teamChannels: number; sharedInboxChannels: number }> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }

    return this.subscribeForSidebarChanges(forUserEmail).pipe(
      startWith(null),
      mergeMap(() => this._contactService.getTeamsAndSharedInboxesCount(forUserEmail)),
    );
  }

  subscribeToSidebarContacts(forUserEmail: string): Observable<SidebarContacts> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }

    return merge(this.subscribeForSidebarChanges(forUserEmail), this.subscribeForUnreadChanges(forUserEmail)).pipe(
      startWith(null),
      mergeMap((observableIndex: number) => {
        // Get all accessible contacts
        return this._contactService.getSidebarContacts(forUserEmail);
      }),
      map((contacts: SidebarContacts) => {
        return contacts;
      }),
    );
  }

  subscribeForSidebarChanges(forUserEmail: string): Observable<any> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }

    return SharedSubjects._contactSidebarChange$.forUserEmail(forUserEmail).pipe(debounceTime(100));
  }

  private subscribeForUnreadChanges(forUserEmail: string): Observable<any> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }

    return SharedSubjects._unreadStateChange$.pipe(
      filter((data: UnreadStateChange) => {
        return data.forUserEmail === forUserEmail;
      }),
      debounceTime(100),
    );
  }
}
