import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { BasePushSynchronizationService } from '../base-push-synchronization/base-push-synchronization.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { CardShared } from '@shared/api/api-loop/models';
import { HttpErrorResponse } from '@angular/common/http';
import { CardModel, CardSharedModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { Logger } from '@shared/services/logger/logger';

@Injectable()
export class CardPushSynchronizationService extends BasePushSynchronizationService<CardModel> {
  constructor(private _api: ApiService) {
    super();
  }

  get constructorName(): string {
    return 'CardPushSynchronizationService';
  }

  protected synchronize(forUserEmail: string, card: CardModel): Observable<any> {
    return this._api.CardApiService.Card_UpdateCard(
      {
        cardBase: <CardShared>{
          $type: CardSharedModel.type,
          id: card.id,
          isLive: false,
        },
      },
      forUserEmail,
    );
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    card: CardModel,
  ): Observable<any> {
    if (err.status === 0) {
      // we lost connection and will retry
      return EMPTY;
    }

    Logger.error(err, `PushSync [${forUserEmail}]: Could not sync card update, will not retry cardId: ${card._id}`);
    return of(card);
  }
}
