import { Injectable } from '@angular/core';
import { HttpResponseEvent, HttpResponseEventType } from '@dta/shared/models/http-events.model';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { HttpResponseEventData } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { Observable } from 'rxjs';

@Injectable()
export class HttpEventService {
  // State variables
  private lastEventType: HttpResponseEventType = undefined;

  constructor() {}

  get events$(): Observable<HttpResponseEventData> {
    return SharedSubjects._httpResponseEvent$.asObservable();
  }

  emit(event: HttpResponseEvent) {
    // Do not throw when event is undefined,
    // but don't emit either
    if (!event) {
      return;
    }

    let httpResponseEventData = new HttpResponseEventData();
    httpResponseEventData.httpResponseEvent = event;

    // Broadcast change only
    let broadcast = !this.lastEventType || event.type !== this.lastEventType;
    this.lastEventType = event.type;

    SharedSubjects._httpResponseEvent$.next(httpResponseEventData, broadcast);
  }
}
