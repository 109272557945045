/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { OptionsSearch } from '../models/options-search';
import { ListOfResourcesOfResourceBase } from '../models/list-of-resources-of-resource-base';
import { Response } from '../models/response';
import { SearchQueryBase } from '../models/search-query-base';

@Injectable()
export class SearchApiService extends BaseService {
  Search_GetSearchOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsSearch>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/search/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Search_GetSearchOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsSearch;

          body = <OptionsSearch>response.body;

          return <HttpResponse<OptionsSearch>>response.clone({ body: body });
        })
      );
  }

  Search_GetSearchOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsSearch> {
    const observable$ = this.Search_GetSearchOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/search/list`;
      const url = this.rootUrl + `/api/v1/search/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SearchApiService.Search_GetListParams` containing the following parameters:
   *
   * - `query`: Search query.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Search_GetListResponse(
    params: SearchApiService.Search_GetListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfResourceBase>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/search/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Search_GetList');

    // body, headers and query params
    __body = params.query;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfResourceBase;

          body = <ListOfResourcesOfResourceBase>response.body;

          return <HttpResponse<ListOfResourcesOfResourceBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SearchApiService.Search_GetListParams` containing the following parameters:
   *
   * - `query`: Search query.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Search_GetList(
    params: SearchApiService.Search_GetListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfResourceBase> {
    const observable$ = this.Search_GetListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/search/list`;
      const url = this.rootUrl + `/api/v1/search/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module SearchApiService {
  /**
   * Parameters for Search_GetList
   */
  export type Search_GetListParams = {
    /**
     * Search query.
     */
    query?: SearchQueryBase;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };
}
