import { Injectable } from '@angular/core';
import { IFrameRequestHandlerI } from '@shared/services/integrations/iframe-request-handler.interface';
import { IFrameAppRequest, IFrameMethod } from '@intheloop/loop-integration';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { SubscriptionLicense } from '@shared/api/api-loop/models/subscription-license';
import { MainAppRoutesNames } from '@dta/ui/components/mainapp/resources/routes';
import { CONVERSATIONS_QUERY_PARAM_IDENTIFIER } from '@shared/modules/conversations/common/resources/conversation-query-param-identifier';

@Injectable()
export class AppHelper implements IFrameRequestHandlerI {
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _webOnBoardingService: WebOnboardingService,
    private _userManagerService: UserManagerService,
    private _storageService: StorageService
  ) {}

  handleRequest(forUserEmail: string, request: IFrameAppRequest, integrationId: string): Observable<any> {
    switch (request.method) {
      case IFrameMethod.FILTER_BY_IDS_IN_CONTEXT:
        let param = Array.isArray(request.conversationIds) ? request.conversationIds.join(';') : [];
        if (this._router.url.includes('myloopinbox') || this._router.url.includes('conversation')) {
          this._router.navigate([MainAppRoutesNames.conversations], {
            queryParams: {
              [CONVERSATIONS_QUERY_PARAM_IDENTIFIER]: param
            }
          });
        } else {
          this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {
              showCardsInChannel: param,
              navigatedWithClick: true
            },
            queryParamsHandling: 'merge'
          });
        }
        return of(undefined);
      case IFrameMethod.OPEN_WEB_ON_BOARDING:
        this._webOnBoardingService.openCustom(
          this._userManagerService.getCurrentUserEmail(),
          new URLSearchParams(),
          request.path,
          window.open()
        );
        return of(undefined);
      case IFrameMethod.GET_WORKSPACE_ID:
        let key = this._storageService.getKey(
          this._userManagerService.getCurrentUserEmail(),
          StorageKey.userTopPriorityLicense
        );
        let topPriorityLicence: SubscriptionLicense = this._storageService.getParsedItem(key);
        return of(topPriorityLicence.id);
    }
  }
}
