import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ListOfTagsModel, TagLabelModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import {
  EventWorkspaceUpdated,
  ListOfResourcesOfListOfResourcesOfSharedTagLabel,
  ListOfResourcesOfSharedTagLabel,
  ListOfResourcesOfTagLabel,
  ListOfTags,
  SharedTagLabel,
  TagRights
} from '@shared/api/api-loop/models';
import { SharedTagApiService, TagApiService } from '@shared/api/api-loop/services';
import { Logger } from '@shared/services/logger/logger';
import {
  SharedTagBaseModel,
  SharedTagLabelModel,
  SharedTagModel,
  SharedTagStatusModel,
  StaticSharedTagIds
} from 'dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { SharedTagService } from '../shared-tags/shared-tags.service';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { TagService } from '../tag/tag.service';
import { BaseService } from '../base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { SharedTagDaoService } from '@shared/database/dao/shared-tag/shared-tag-dao.service';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { ConversationChangeService } from '@shared/services/data/conversation-change/conversation-change.service';
import { ConversationChangeModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-change.model';

export interface LabelServiceI {
  getAssignableLabelTags(
    forUserEmail: string,
    contextId?: string,
    omitSharedTags?: boolean
  ): Observable<(SharedTagLabelModel | TagLabelModel)[]>;

  addOrRemoveLabelForCards(
    forUserEmail: string,
    cardIds: string[],
    labels: (SharedTagLabelModel | TagLabelModel)[],
    action: 'add' | 'remove'
  ): Observable<ConversationModel[]>;

  createOrUpdateSharedTagLabel(forUserEmail: string, sharedTagLabel: SharedTagLabelModel): Observable<SharedTagModel>;

  fetchAllSharedLabels(forUserEmail: string, includeDeleted?: boolean): Observable<SharedTagLabelModel[]>;

  removeSharedTagLabel(forUserEmail: string, sharedTagLabel: SharedTagLabelModel): Observable<any>;
}

@Injectable()
export class LabelService extends BaseService implements LabelServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    private _sharedTagApiService: SharedTagApiService,
    private _sharedTagDaoService: SharedTagDaoService,
    private _sharedTagService: SharedTagService,
    private _conversationService: ConversationService,
    private _conversationChangeService: ConversationChangeService,
    private _tagApiService: TagApiService,
    private _tagService: TagService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'LabelService';
  }

  saveOnly(forUserEmail: string, sharedTagLabel: SharedTagLabelModel): Observable<SharedTagModel> {
    return this._sharedTagDaoService.saveAll(forUserEmail, [sharedTagLabel]).pipe(map(_.first));
  }

  save(forUserEmail: string, sharedTagLabel: SharedTagLabelModel): Observable<SharedTagModel> {
    return this.saveAll(forUserEmail, [sharedTagLabel]).pipe(map(_.first));
  }

  saveAll(forUserEmail: string, sharedTagLabels: SharedTagLabelModel[]): Observable<SharedTagModel[]> {
    if (_.isEmpty(sharedTagLabels)) {
      return of(sharedTagLabels);
    }
    return this._sharedTagDaoService.saveAll(forUserEmail, sharedTagLabels);
  }

  createOrUpdateSharedTagLabel(forUserEmail: string, sharedTagLabel: SharedTagLabelModel): Observable<SharedTagModel> {
    return of(sharedTagLabel).pipe(
      tap((_sharedTagLabel: SharedTagLabelModel) => {
        this.enqueuePushSynchronization(forUserEmail, _sharedTagLabel);
        return _sharedTagLabel;
      })
    );
  }

  removeSharedTagLabel(forUserEmail: string, sharedTagLabel: SharedTagLabelModel): Observable<any> {
    return of(sharedTagLabel).pipe(
      tap((_sharedTagLabel: SharedTagLabelModel) => {
        this.enqueuePushSynchronization(forUserEmail, _sharedTagLabel);
        PublisherService.publishEvent(forUserEmail, new SharedTagLabelModel(_sharedTagLabel), PublishEventType.Remove);
      })
    );
  }

  ////////////////
  // SYNC METHODS
  ////////////////
  syncLabels(forUserEmail: string): Observable<(TagModel | SharedTagBaseModel)[]> {
    return this.fetchAllLabels(forUserEmail);
  }

  fetchAllLabels(forUserEmail: string): Observable<(SharedTagBaseModel | TagModel)[]> {
    if (!forUserEmail) {
      return throwError(new Error('forUserEmail cannot be nil'));
    }

    return combineLatest([
      this.fetchAllSharedLabels(forUserEmail),
      this.fetchPersonalLabels(forUserEmail),
      this.createDefaultStatusTags(forUserEmail)
    ]).pipe(
      /**
       * Combine results (for count. only fetched)
       */
      map(([sharedLabels, personalLabels]: [SharedTagBaseModel[], TagModel[], SharedTagStatusModel[]]) => {
        return [...sharedLabels, ...personalLabels];
      }),
      /**
       * Catch any error
       */
      catchError(err => {
        Logger.error(err, 'Error in syncLabels() for ' + forUserEmail);
        return of(undefined);
      })
    );
  }

  fetchPersonalLabels(forUserEmail: string): Observable<TagModel[]> {
    return this._tagApiService
      .Tag_GetUserLabels({}, forUserEmail)
      .pipe(
        mergeMap((result: ListOfResourcesOfTagLabel) =>
          this._tagService.saveAllAndPublish(forUserEmail, TagModel.createList(result.resources))
        )
      );
  }

  addOrRemoveLabelForCards(
    forUserEmail: string,
    cardIds: string[],
    labels: (SharedTagLabelModel | TagLabelModel)[],
    action: 'add' | 'remove'
  ): Observable<ConversationModel[]> {
    return this._conversationService
      .findOrFetchByCardIds(forUserEmail, cardIds)
      .pipe(
        mergeMap((_conversations: ConversationModel[]) =>
          this.applyLabelToCards(forUserEmail, _conversations, labels, action)
        )
      );
  }

  fetchAllSharedLabels(forUserEmail: string, includeDeleted?: boolean): Observable<SharedTagLabelModel[]> {
    return of(undefined).pipe(
      /**
       * Get workspaces for user
       */
      mergeMap(() => this._sharedTagApiService.SharedTag_GetWorkspacesLabels({}, forUserEmail)),
      /**
       * Combine all sharedTags
       */
      map((resources: ListOfResourcesOfListOfResourcesOfSharedTagLabel) =>
        _.map(resources.resources, (resource: ListOfResourcesOfSharedTagLabel) => resource.resources).flat()
      ),
      /**
       * Filter deleted
       */
      mergeMap((sharedTags: SharedTagLabel[]) => {
        return from(sharedTags);
      }),
      filter((sharedTag: SharedTagLabel) => {
        return includeDeleted ? true : !sharedTag.deleted;
      }),
      toArray(),
      /**
       * Save and publish
       */
      mergeMap((sharedTags: SharedTagLabel[]) =>
        this._sharedTagService.saveAllAndPublish(forUserEmail, SharedTagBaseModel.createList(sharedTags))
      ),
      defaultIfEmpty([])
    ) as Observable<SharedTagLabelModel[]>;
  }

  /**
   * Create default status tags to make them available in filters
   */
  private createDefaultStatusTags(forUserEmail: string): Observable<SharedTagStatusModel[]> {
    return of([
      SharedTagStatusModel.createDefaultStatusSharedTag(StaticSharedTagIds.UNASSIGNED_ID),
      SharedTagStatusModel.createDefaultStatusSharedTag(StaticSharedTagIds.RESOLVED_ID)
    ]).pipe(
      /**
       * Save and publish
       */
      mergeMap((sharedTags: SharedTagLabel[]) =>
        this._sharedTagService.saveAllAndPublish(forUserEmail, SharedTagBaseModel.createList(sharedTags))
      )
    );
  }

  getAssignableLabelTags(
    forUserEmail: string,
    contextId?: string,
    omitSharedTags?: boolean
  ): Observable<(SharedTagLabelModel | TagLabelModel)[]> {
    let obs: Observable<any>[] = [this._tagService.findAllLabelTags(forUserEmail)];
    if (!omitSharedTags) {
      obs.push(this._sharedTagService.findAvailableLabels(forUserEmail, contextId, TagRights.ASSIGN));
    }
    return combineLatest(obs).pipe(map(labels => labels.flat()));
  }

  updateSharedLabelsByWorkspace(forUserEmail: string, event: EventWorkspaceUpdated): Observable<SharedTagBaseModel[]> {
    let parameters: SharedTagApiService.SharedTag_GetWorkspaceLabelsParams = {
      id: event.workspaceId
    };

    return this._sharedTagApiService.SharedTag_GetWorkspaceLabels(parameters, forUserEmail).pipe(
      mergeMap((result: ListOfResourcesOfSharedTagLabel) => {
        // No shared tags indicates that this user no longer
        // has rights to shared tags from this workspace
        if (result.totalSize === 0) {
          return this._sharedTagService.updateRightsForWorkspaceLabels(forUserEmail, event.workspaceId, TagRights.VIEW);
        }

        // Otherwise update and publish non deleted
        return this._sharedTagService.saveAllAndPublish(
          forUserEmail,
          SharedTagBaseModel.createList(_.filter(result.resources, label => !label.deleted))
        );
      })
    );
  }

  private applyLabelToCards(
    forUserEmail: string,
    conversations: ConversationModel[],
    labels: (SharedTagLabelModel | TagLabelModel)[],
    action: 'add' | 'remove'
  ): Observable<ConversationModel[]> {
    if (_.isEmpty(conversations)) {
      return of([]);
    }

    let changes = [];

    return from(conversations).pipe(
      tap((conversation: ConversationModel) => {
        let conversationChange = ConversationChangeModel.createLabelChangeMode({
          cardId: conversation.cardId,
          action: action,
          labels: labels
        });
        changes.push(conversationChange);
      }),
      /**
       * Decorate sharedTags list
       */
      tap((conversation: ConversationModel) => {
        _.forEach(labels, label => {
          conversation.addOrRemoveLabel(label, action);
        });
      }),
      toArray(),
      /**
       * Save and publish cards
       */
      mergeMap((_conversations: ConversationModel[]) => {
        return this._conversationChangeService.saveAll(forUserEmail, changes).pipe(
          mergeMap(() => this._conversationService.saveAllAndPublish(forUserEmail, _conversations)),
          map(() => _conversations)
        );
      }),
      /**
       * Enqueue for push sync
       */
      mergeMap((_conversations: ConversationModel[]) => {
        let hasSharedTagLabelChange = _.some(labels, label => label.$type === SharedTagLabelModel.type);
        let hasPrivateLabelChange = _.some(labels, label => label.$type === TagLabelModel.type);

        if (hasSharedTagLabelChange) {
          let arrayOfListOfSharedTags: ListOfTags[] = _conversations.map(c => c.sharedTags);
          this._sharedTagService.enqueueSharedTagsSynchronization(forUserEmail, arrayOfListOfSharedTags);
        }

        if (hasPrivateLabelChange) {
          let arrayOfListOfTags: ListOfTagsModel[] = _conversations.map(c => ListOfTagsModel.create(c.tags));
          this._tagService.enqueuePushSynchronization(forUserEmail, arrayOfListOfTags);
        }

        return of(_conversations);
      })
    );
  }
}
