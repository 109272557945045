/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { CommentBase } from '../models/comment-base';
import { Response } from '../models/response';
import { CommentPreview } from '../models/comment-preview';
import { OptionsCommentList } from '../models/options-comment-list';
import { ListOfResourcesOfCommentBase } from '../models/list-of-resources-of-comment-base';
import { CardType } from '../models/card-type';
import { CommentList } from '../models/comment-list';
import { CommentMail } from '../models/comment-mail';
import { OptionsCommentChat } from '../models/options-comment-chat';
import { CommentChat } from '../models/comment-chat';
import { CommentTemplate } from '../models/comment-template';
import { CommentDraft } from '../models/comment-draft';
import { CommentOutOfOffice } from '../models/comment-out-of-office';

@Injectable()
export class CommentApiService extends BaseService {
  /**
   * @param params The `CommentApiService.Comment_GetParams` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `htmlFormat`: Html format of comment body
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Comment on mail card.
   * or
   * Comment on chat card
   * or
   * Template
   * or
   * Draft
   */
  Comment_GetResponse(
    params: CommentApiService.Comment_GetParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/comment/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_Get');

    // body, headers and query params
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentBase;

          body = <CommentBase>response.body;

          return <HttpResponse<CommentBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_GetParams` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `htmlFormat`: Html format of comment body
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Comment on mail card.
   * or
   * Comment on chat card
   * or
   * Template
   * or
   * Draft
   */
  Comment_Get(
    params: CommentApiService.Comment_GetParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentBase> {
    const observable$ = this.Comment_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/${params.id}`;
      const url = this.rootUrl + `/api/v1/comment/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_GetCommentPreviewParams` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `useRawHtml`: Use raw html or use sanitized html. Default is to use sanitized html in preview
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Comment_GetCommentPreviewResponse(
    params: CommentApiService.Comment_GetCommentPreviewParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentPreview>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/comment/${params.id}/preview`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetCommentPreview');

    // body, headers and query params
    if (params.useRawHtml != null) __params = __params.set('useRawHtml', params.useRawHtml.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentPreview;

          body = <CommentPreview>response.body;

          return <HttpResponse<CommentPreview>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_GetCommentPreviewParams` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `useRawHtml`: Use raw html or use sanitized html. Default is to use sanitized html in preview
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Comment_GetCommentPreview(
    params: CommentApiService.Comment_GetCommentPreviewParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentPreview> {
    const observable$ = this.Comment_GetCommentPreviewResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/${params.id}/preview`;
      const url = this.rootUrl + `/api/v1/comment/${params.id}/preview`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_GetCommentEmlParams` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Comment_GetCommentEmlResponse(
    params: CommentApiService.Comment_GetCommentEmlParams,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/comment/${params.id}/eml`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetCommentEml');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `CommentApiService.Comment_GetCommentEmlParams` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Comment_GetCommentEml(
    params: CommentApiService.Comment_GetCommentEmlParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.Comment_GetCommentEmlResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/${params.id}/eml`;
      const url = this.rootUrl + `/api/v1/comment/${params.id}/eml`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Comment_GetCommentListOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsCommentList>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/comment/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetCommentListOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsCommentList;

          body = <OptionsCommentList>response.body;

          return <HttpResponse<OptionsCommentList>>response.clone({ body: body });
        })
      );
  }

  Comment_GetCommentListOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsCommentList> {
    const observable$ = this.Comment_GetCommentListOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/list`;
      const url = this.rootUrl + `/api/v1/comment/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_GetListParams` containing the following parameters:
   *
   * - `tags`: List of tags to filter card list by.
   *
   * - `tagFilterRelation`: Query relation for tag/sharedTag filters. Default is 'and'.
   *
   * - `sortOrder`: Order of comments by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `shouldIncludeMutedIds`: List focused/muted comment list
   *
   * - `sharedTags`: List of shared tags to filter card list by.
   *
   * - `senderIds`: List of user ids as senders (From) to filter comment list by.
   *
   * - `searchQuery`: Plaintext search query to filter comment list by. Please use prefix 'To:' or 'From:' in order to filter by recipient oy by sender.
   *
   * - `returnFormat`: Return format temporary - small is return only id
   *
   * - `recipientIds`: List of recipient ids (either a user or a group) to filter comment list by.
   *
   * - `offsetModified`: Include comments from/since (as per requested sort order) its modified date.
   *
   * - `offsetId`: Id of last recieved comment in the previous batch when using offsetModified
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offsetDateType`: Offset date type.
   *
   * - `offsetDateEnd`: Include cards to/until (as per requested sort order) its offset date type.
   *
   * - `offsetDate`: Include cards from/since (as per requested sort order) its offset date type.
   *
   * - `offset`: Requested page offset.
   *
   * - `mutedIds`: List of muted user/group ids for muted logic comment list by.
   *
   * - `includeSignedLinks`: Include signed links for attachments
   *
   * - `htmlFormat`: Html format of comment body.
   *
   * - `groupComment`: comment is in group
   *
   * - `excludeTags`: List of exclude tags to filter card list by.
   *
   * - `excludeTagFilterRelation`: Query relation for excludeTag/excludeSharedTag filters. Default is 'and'.
   *
   * - `excludeSharedTags`: List of exclude shared tags to filter card list by.
   *
   * - `exactShareContactIds`: List of contact ids (user or group) to filter result list by exact matching of comments's share list.
   *
   * - `contactFilterRelation`: Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
   *
   * - `commentIds`: List of comment ids to filter comment list by.
   *
   * - `cardTypes`: List of card types to filter card list by.
   *
   * - `cardIds`: List of card ids to filter comment list by.
   *
   * - `authorizeCardIdsBeforeSearch`: Do authorize card ids before search.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Comment_GetListResponse(
    params: CommentApiService.Comment_GetListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfCommentBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/comment/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetList');

    // body, headers and query params
    (params.tags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('tags', val.toString());
    });
    if (params.tagFilterRelation != null)
      __params = __params.set('tagFilterRelation', params.tagFilterRelation.toString());
    if (params.sortOrder != null) __params = __params.set('sortOrder', params.sortOrder.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.shouldIncludeMutedIds != null)
      __params = __params.set('shouldIncludeMutedIds', params.shouldIncludeMutedIds.toString());
    (params.sharedTags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('sharedTags', val.toString());
    });
    (params.senderIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('senderIds', val.toString());
    });
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.returnFormat != null) __params = __params.set('returnFormat', params.returnFormat.toString());
    (params.recipientIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('recipientIds', val.toString());
    });
    if (params.offsetModified != null) __params = __params.set('offsetModified', params.offsetModified.toString());
    if (params.offsetId != null) __params = __params.set('offsetId', params.offsetId.toString());
    if (params.offsetHistoryId != null) __params = __params.set('offsetHistoryId', params.offsetHistoryId.toString());
    if (params.offsetDateType != null) __params = __params.set('offsetDateType', params.offsetDateType.toString());
    if (params.offsetDateEnd != null) __params = __params.set('offsetDateEnd', params.offsetDateEnd.toString());
    if (params.offsetDate != null) __params = __params.set('offsetDate', params.offsetDate.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    (params.mutedIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('mutedIds', val.toString());
    });
    if (params.includeSignedLinks != null)
      __params = __params.set('includeSignedLinks', params.includeSignedLinks.toString());
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.groupComment != null) __params = __params.set('groupComment', params.groupComment.toString());
    (params.excludeTags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('excludeTags', val.toString());
    });
    if (params.excludeTagFilterRelation != null)
      __params = __params.set('excludeTagFilterRelation', params.excludeTagFilterRelation.toString());
    (params.excludeSharedTags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('excludeSharedTags', val.toString());
    });
    (params.exactShareContactIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('exactShareContactIds', val.toString());
    });
    if (params.contactFilterRelation != null)
      __params = __params.set('contactFilterRelation', params.contactFilterRelation.toString());
    (params.commentIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('commentIds', val.toString());
    });
    (params.cardTypes || []).forEach((val, index) => {
      if (val != null) __params = __params.append('cardTypes', val.toString());
    });
    (params.cardIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('cardIds', val.toString());
    });
    if (params.authorizeCardIdsBeforeSearch != null)
      __params = __params.set('authorizeCardIdsBeforeSearch', params.authorizeCardIdsBeforeSearch.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfCommentBase;

          body = <ListOfResourcesOfCommentBase>response.body;

          return <HttpResponse<ListOfResourcesOfCommentBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_GetListParams` containing the following parameters:
   *
   * - `tags`: List of tags to filter card list by.
   *
   * - `tagFilterRelation`: Query relation for tag/sharedTag filters. Default is 'and'.
   *
   * - `sortOrder`: Order of comments by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `shouldIncludeMutedIds`: List focused/muted comment list
   *
   * - `sharedTags`: List of shared tags to filter card list by.
   *
   * - `senderIds`: List of user ids as senders (From) to filter comment list by.
   *
   * - `searchQuery`: Plaintext search query to filter comment list by. Please use prefix 'To:' or 'From:' in order to filter by recipient oy by sender.
   *
   * - `returnFormat`: Return format temporary - small is return only id
   *
   * - `recipientIds`: List of recipient ids (either a user or a group) to filter comment list by.
   *
   * - `offsetModified`: Include comments from/since (as per requested sort order) its modified date.
   *
   * - `offsetId`: Id of last recieved comment in the previous batch when using offsetModified
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offsetDateType`: Offset date type.
   *
   * - `offsetDateEnd`: Include cards to/until (as per requested sort order) its offset date type.
   *
   * - `offsetDate`: Include cards from/since (as per requested sort order) its offset date type.
   *
   * - `offset`: Requested page offset.
   *
   * - `mutedIds`: List of muted user/group ids for muted logic comment list by.
   *
   * - `includeSignedLinks`: Include signed links for attachments
   *
   * - `htmlFormat`: Html format of comment body.
   *
   * - `groupComment`: comment is in group
   *
   * - `excludeTags`: List of exclude tags to filter card list by.
   *
   * - `excludeTagFilterRelation`: Query relation for excludeTag/excludeSharedTag filters. Default is 'and'.
   *
   * - `excludeSharedTags`: List of exclude shared tags to filter card list by.
   *
   * - `exactShareContactIds`: List of contact ids (user or group) to filter result list by exact matching of comments's share list.
   *
   * - `contactFilterRelation`: Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
   *
   * - `commentIds`: List of comment ids to filter comment list by.
   *
   * - `cardTypes`: List of card types to filter card list by.
   *
   * - `cardIds`: List of card ids to filter comment list by.
   *
   * - `authorizeCardIdsBeforeSearch`: Do authorize card ids before search.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Comment_GetList(
    params: CommentApiService.Comment_GetListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfCommentBase> {
    const observable$ = this.Comment_GetListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/list`;
      const url = this.rootUrl + `/api/v1/comment/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_GetCardCommentsParams` containing the following parameters:
   *
   * - `sortOrder`: Order of comments by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `returnFormat`: Return format temporary - small is return only id
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offset`: Requested page offset.
   *
   * - `htmlFormat`: Html format of comment body.
   *
   * - `cardId`: comment for card id.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Comment_GetCardCommentsResponse(
    params: CommentApiService.Comment_GetCardCommentsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfCommentBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/comment/card`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetCardComments');

    // body, headers and query params
    if (params.sortOrder != null) __params = __params.set('sortOrder', params.sortOrder.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.returnFormat != null) __params = __params.set('returnFormat', params.returnFormat.toString());
    if (params.offsetHistoryId != null) __params = __params.set('offsetHistoryId', params.offsetHistoryId.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.cardId != null) __params = __params.set('cardId', params.cardId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfCommentBase;

          body = <ListOfResourcesOfCommentBase>response.body;

          return <HttpResponse<ListOfResourcesOfCommentBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_GetCardCommentsParams` containing the following parameters:
   *
   * - `sortOrder`: Order of comments by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `returnFormat`: Return format temporary - small is return only id
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offset`: Requested page offset.
   *
   * - `htmlFormat`: Html format of comment body.
   *
   * - `cardId`: comment for card id.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Comment_GetCardComments(
    params: CommentApiService.Comment_GetCardCommentsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfCommentBase> {
    const observable$ = this.Comment_GetCardCommentsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/card`;
      const url = this.rootUrl + `/api/v1/comment/card`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Comment_GetCommentConversationOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsCommentList>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/comment/conversation`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetCommentConversationOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsCommentList;

          body = <OptionsCommentList>response.body;

          return <HttpResponse<OptionsCommentList>>response.clone({ body: body });
        })
      );
  }

  Comment_GetCommentConversationOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsCommentList> {
    const observable$ = this.Comment_GetCommentConversationOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/conversation`;
      const url = this.rootUrl + `/api/v1/comment/conversation`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_GetConversationCommentsParams` containing the following parameters:
   *
   * - `sortOrder`: Order of comments by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offset`: Requested page offset.
   *
   * - `draftCardIds`: Get draft comments in additional list
   *
   * - `conversationId`: comment for conversation id.
   *
   * - `appointmentCardId`: Get additiona appointment card
   *
   * - `appendQuoteComment`: Get quoted comment in additional list
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Comment_GetConversationCommentsResponse(
    params: CommentApiService.Comment_GetConversationCommentsParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentList>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/comment/conversation`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetConversationComments');

    // body, headers and query params
    if (params.sortOrder != null) __params = __params.set('sortOrder', params.sortOrder.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.offsetHistoryId != null) __params = __params.set('offsetHistoryId', params.offsetHistoryId.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    (params.draftCardIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('draftCardIds', val.toString());
    });
    if (params.conversationId != null) __params = __params.set('conversationId', params.conversationId.toString());
    if (params.appointmentCardId != null)
      __params = __params.set('appointmentCardId', params.appointmentCardId.toString());
    if (params.appendQuoteComment != null)
      __params = __params.set('appendQuoteComment', params.appendQuoteComment.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentList;

          body = <CommentList>response.body;

          return <HttpResponse<CommentList>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_GetConversationCommentsParams` containing the following parameters:
   *
   * - `sortOrder`: Order of comments by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offset`: Requested page offset.
   *
   * - `draftCardIds`: Get draft comments in additional list
   *
   * - `conversationId`: comment for conversation id.
   *
   * - `appointmentCardId`: Get additiona appointment card
   *
   * - `appendQuoteComment`: Get quoted comment in additional list
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Comment_GetConversationComments(
    params: CommentApiService.Comment_GetConversationCommentsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentList> {
    const observable$ = this.Comment_GetConversationCommentsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/conversation`;
      const url = this.rootUrl + `/api/v1/comment/conversation`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_CreateCommentMailParams` containing the following parameters:
   *
   * - `commentMail`: Comment mail object
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `sendAs`: Email address of the user you want to send as
   *
   * - `ignoreEmailHistory`: Flag indicating whether or not server should append email history when sending email or not. Default is false.
   *
   * - `htmlFormat`: Requested html format of comment body for create response.
   *
   * - `groupId`: Id of the group where mail will be looped into when successfully send. SendAs user must be member of that group.
   *
   * - `draftId`: Comment mail created from draft id
   *
   * - `doNotUseInternalSend`: Do not use internal send
   *
   * - `doNotSendEmail`: Do not send email, only valid for users with elevated permissions
   *
   * - `doNotModifyDeleteArchiveFlag`: Do not modify delete archive flag on card
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `closeConversation`: Close mail conversation
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentMail
   */
  Comment_CreateCommentMailResponse(
    params: CommentApiService.Comment_CreateCommentMailParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentMail>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/comment/mail`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_CreateCommentMail');

    // body, headers and query params
    __body = params.commentMail;
    if (params.skipBbCodeEscaping != null)
      __params = __params.set('skipBbCodeEscaping', params.skipBbCodeEscaping.toString());
    if (params.sendAs != null) __params = __params.set('sendAs', params.sendAs.toString());
    if (params.ignoreEmailHistory != null)
      __params = __params.set('ignoreEmailHistory', params.ignoreEmailHistory.toString());
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.draftId != null) __params = __params.set('draftId', params.draftId.toString());
    if (params.doNotUseInternalSend != null)
      __params = __params.set('doNotUseInternalSend', params.doNotUseInternalSend.toString());
    if (params.doNotSendEmail != null) __params = __params.set('doNotSendEmail', params.doNotSendEmail.toString());
    if (params.doNotModifyDeleteArchiveFlag != null)
      __params = __params.set('doNotModifyDeleteArchiveFlag', params.doNotModifyDeleteArchiveFlag.toString());
    if (params.customCreateDateTime != null)
      __params = __params.set('customCreateDateTime', params.customCreateDateTime.toString());
    if (params.closeConversation != null)
      __params = __params.set('closeConversation', params.closeConversation.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentMail;

          body = <CommentMail>response.body;

          return <HttpResponse<CommentMail>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_CreateCommentMailParams` containing the following parameters:
   *
   * - `commentMail`: Comment mail object
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `sendAs`: Email address of the user you want to send as
   *
   * - `ignoreEmailHistory`: Flag indicating whether or not server should append email history when sending email or not. Default is false.
   *
   * - `htmlFormat`: Requested html format of comment body for create response.
   *
   * - `groupId`: Id of the group where mail will be looped into when successfully send. SendAs user must be member of that group.
   *
   * - `draftId`: Comment mail created from draft id
   *
   * - `doNotUseInternalSend`: Do not use internal send
   *
   * - `doNotSendEmail`: Do not send email, only valid for users with elevated permissions
   *
   * - `doNotModifyDeleteArchiveFlag`: Do not modify delete archive flag on card
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `closeConversation`: Close mail conversation
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentMail
   */
  Comment_CreateCommentMail(
    params: CommentApiService.Comment_CreateCommentMailParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentMail> {
    const observable$ = this.Comment_CreateCommentMailResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/mail`;
      const url = this.rootUrl + `/api/v1/comment/mail`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Comment_GetCommentChatOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsCommentChat>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/comment/chat`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetCommentChatOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsCommentChat;

          body = <OptionsCommentChat>response.body;

          return <HttpResponse<OptionsCommentChat>>response.clone({ body: body });
        })
      );
  }

  Comment_GetCommentChatOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsCommentChat> {
    const observable$ = this.Comment_GetCommentChatOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/chat`;
      const url = this.rootUrl + `/api/v1/comment/chat`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_CreateCommentChatParams` containing the following parameters:
   *
   * - `commentChat`: Comment chat object
   *
   * - `sourceResourceId`: Id of mail comment or mail card to create/copy shared card from.
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `setUnreadOnlyFor`: List of user ids for which unreads should be set. All other users will not have unread set.
   *                 If null, all eligible users have unread set.
   *
   * - `setUnreadExceptFor`: List of user ids for which unreads should not be set.
   *
   * - `includeSenderInUnreadList`: Add creator in logic that set unread flag.
   *
   * - `doNotSendEmail`: Do not send email, only valid for users with elevated permissions
   *
   * - `doNotModifyDeleteArchiveFlag`: Do not modify delete archive flag on card
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentChat
   */
  Comment_CreateCommentChatResponse(
    params: CommentApiService.Comment_CreateCommentChatParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentChat>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/comment/chat`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_CreateCommentChat');

    // body, headers and query params
    __body = params.commentChat;
    if (params.sourceResourceId != null)
      __params = __params.set('sourceResourceId', params.sourceResourceId.toString());
    if (params.skipBbCodeEscaping != null)
      __params = __params.set('skipBbCodeEscaping', params.skipBbCodeEscaping.toString());
    (params.setUnreadOnlyFor || []).forEach((val, index) => {
      if (val != null) __params = __params.append('setUnreadOnlyFor', val.toString());
    });
    (params.setUnreadExceptFor || []).forEach((val, index) => {
      if (val != null) __params = __params.append('setUnreadExceptFor', val.toString());
    });
    if (params.includeSenderInUnreadList != null)
      __params = __params.set('includeSenderInUnreadList', params.includeSenderInUnreadList.toString());
    if (params.doNotSendEmail != null) __params = __params.set('doNotSendEmail', params.doNotSendEmail.toString());
    if (params.doNotModifyDeleteArchiveFlag != null)
      __params = __params.set('doNotModifyDeleteArchiveFlag', params.doNotModifyDeleteArchiveFlag.toString());
    if (params.customCreateDateTime != null)
      __params = __params.set('customCreateDateTime', params.customCreateDateTime.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentChat;

          body = <CommentChat>response.body;

          return <HttpResponse<CommentChat>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_CreateCommentChatParams` containing the following parameters:
   *
   * - `commentChat`: Comment chat object
   *
   * - `sourceResourceId`: Id of mail comment or mail card to create/copy shared card from.
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `setUnreadOnlyFor`: List of user ids for which unreads should be set. All other users will not have unread set.
   *                 If null, all eligible users have unread set.
   *
   * - `setUnreadExceptFor`: List of user ids for which unreads should not be set.
   *
   * - `includeSenderInUnreadList`: Add creator in logic that set unread flag.
   *
   * - `doNotSendEmail`: Do not send email, only valid for users with elevated permissions
   *
   * - `doNotModifyDeleteArchiveFlag`: Do not modify delete archive flag on card
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentChat
   */
  Comment_CreateCommentChat(
    params: CommentApiService.Comment_CreateCommentChatParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentChat> {
    const observable$ = this.Comment_CreateCommentChatResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/chat`;
      const url = this.rootUrl + `/api/v1/comment/chat`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_UpdateChatParams` containing the following parameters:
   *
   * - `commentChat`: Comment chat object
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentChat
   */
  Comment_UpdateChatResponse(
    params: CommentApiService.Comment_UpdateChatParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentChat>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/comment/chat`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_UpdateChat');

    // body, headers and query params
    __body = params.commentChat;
    if (params.skipBbCodeEscaping != null)
      __params = __params.set('skipBbCodeEscaping', params.skipBbCodeEscaping.toString());
    if (params.customCreateDateTime != null)
      __params = __params.set('customCreateDateTime', params.customCreateDateTime.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentChat;

          body = <CommentChat>response.body;

          return <HttpResponse<CommentChat>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_UpdateChatParams` containing the following parameters:
   *
   * - `commentChat`: Comment chat object
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentChat
   */
  Comment_UpdateChat(
    params: CommentApiService.Comment_UpdateChatParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentChat> {
    const observable$ = this.Comment_UpdateChatResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/chat`;
      const url = this.rootUrl + `/api/v1/comment/chat`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_CreateTemplateResponseParams` containing the following parameters:
   *
   * - `commentTemplate`: Comment chat object
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentTemplate
   */
  Comment_CreateTemplateResponseResponse(
    params: CommentApiService.Comment_CreateTemplateResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentTemplate>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/comment/template`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_CreateTemplateResponse');

    // body, headers and query params
    __body = params.commentTemplate;
    if (params.skipBbCodeEscaping != null)
      __params = __params.set('skipBbCodeEscaping', params.skipBbCodeEscaping.toString());
    if (params.customCreateDateTime != null)
      __params = __params.set('customCreateDateTime', params.customCreateDateTime.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentTemplate;

          body = <CommentTemplate>response.body;

          return <HttpResponse<CommentTemplate>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_CreateTemplateResponseParams` containing the following parameters:
   *
   * - `commentTemplate`: Comment chat object
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentTemplate
   */
  Comment_CreateTemplateResponse(
    params: CommentApiService.Comment_CreateTemplateResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentTemplate> {
    const observable$ = this.Comment_CreateTemplateResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/template`;
      const url = this.rootUrl + `/api/v1/comment/template`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_UpdateTemplateParams` containing the following parameters:
   *
   * - `commentTemplate`: Comment chat object
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentTemplate
   */
  Comment_UpdateTemplateResponse(
    params: CommentApiService.Comment_UpdateTemplateParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentTemplate>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/comment/template`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_UpdateTemplate');

    // body, headers and query params
    __body = params.commentTemplate;
    if (params.skipBbCodeEscaping != null)
      __params = __params.set('skipBbCodeEscaping', params.skipBbCodeEscaping.toString());
    if (params.customCreateDateTime != null)
      __params = __params.set('customCreateDateTime', params.customCreateDateTime.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentTemplate;

          body = <CommentTemplate>response.body;

          return <HttpResponse<CommentTemplate>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_UpdateTemplateParams` containing the following parameters:
   *
   * - `commentTemplate`: Comment chat object
   *
   * - `skipBbCodeEscaping`: Indicator whether or not to skip BBCode escaping. Use it wisely.
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentTemplate
   */
  Comment_UpdateTemplate(
    params: CommentApiService.Comment_UpdateTemplateParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentTemplate> {
    const observable$ = this.Comment_UpdateTemplateResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/template`;
      const url = this.rootUrl + `/api/v1/comment/template`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_CreateDraftResponseParams` containing the following parameters:
   *
   * - `commentDraft`: Comment draft object
   *
   * - `isShared`: Comment draft object
   *
   * - `includeClientId`: include client id for attachments
   *
   * - `draftOnCardId`: Comment draft reply on card object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentDraft
   */
  Comment_CreateDraftResponseResponse(
    params: CommentApiService.Comment_CreateDraftResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentDraft>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/comment/draft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_CreateDraftResponse');

    // body, headers and query params
    __body = params.commentDraft;
    if (params.isShared != null) __params = __params.set('isShared', params.isShared.toString());
    if (params.includeClientId != null) __params = __params.set('includeClientId', params.includeClientId.toString());
    if (params.draftOnCardId != null) __params = __params.set('draftOnCardId', params.draftOnCardId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentDraft;

          body = <CommentDraft>response.body;

          return <HttpResponse<CommentDraft>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_CreateDraftResponseParams` containing the following parameters:
   *
   * - `commentDraft`: Comment draft object
   *
   * - `isShared`: Comment draft object
   *
   * - `includeClientId`: include client id for attachments
   *
   * - `draftOnCardId`: Comment draft reply on card object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentDraft
   */
  Comment_CreateDraftResponse(
    params: CommentApiService.Comment_CreateDraftResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentDraft> {
    const observable$ = this.Comment_CreateDraftResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/draft`;
      const url = this.rootUrl + `/api/v1/comment/draft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_UpdateDraftResponseParams` containing the following parameters:
   *
   * - `commentDraft`: Comment draft object
   *
   * - `includeClientId`: include client id for attachments
   *
   * - `forceUpdateCommentSnippet`: force update comment snippet
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentDraft
   */
  Comment_UpdateDraftResponseResponse(
    params: CommentApiService.Comment_UpdateDraftResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentDraft>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/comment/draft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_UpdateDraftResponse');

    // body, headers and query params
    __body = params.commentDraft;
    if (params.includeClientId != null) __params = __params.set('includeClientId', params.includeClientId.toString());
    if (params.forceUpdateCommentSnippet != null)
      __params = __params.set('forceUpdateCommentSnippet', params.forceUpdateCommentSnippet.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentDraft;

          body = <CommentDraft>response.body;

          return <HttpResponse<CommentDraft>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_UpdateDraftResponseParams` containing the following parameters:
   *
   * - `commentDraft`: Comment draft object
   *
   * - `includeClientId`: include client id for attachments
   *
   * - `forceUpdateCommentSnippet`: force update comment snippet
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentDraft
   */
  Comment_UpdateDraftResponse(
    params: CommentApiService.Comment_UpdateDraftResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentDraft> {
    const observable$ = this.Comment_UpdateDraftResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/draft`;
      const url = this.rootUrl + `/api/v1/comment/draft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_UpdateOutOfOfficeCommentResponseParams` containing the following parameters:
   *
   * - `commentOutOfOffice`: Comment draft object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentOutOfOffice
   */
  Comment_UpdateOutOfOfficeCommentResponseResponse(
    params: CommentApiService.Comment_UpdateOutOfOfficeCommentResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentOutOfOffice>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/comment/outofoffice`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_UpdateOutOfOfficeCommentResponse');

    // body, headers and query params
    __body = params.commentOutOfOffice;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentOutOfOffice;

          body = <CommentOutOfOffice>response.body;

          return <HttpResponse<CommentOutOfOffice>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_UpdateOutOfOfficeCommentResponseParams` containing the following parameters:
   *
   * - `commentOutOfOffice`: Comment draft object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentOutOfOffice
   */
  Comment_UpdateOutOfOfficeCommentResponse(
    params: CommentApiService.Comment_UpdateOutOfOfficeCommentResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentOutOfOffice> {
    const observable$ = this.Comment_UpdateOutOfOfficeCommentResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/outofoffice`;
      const url = this.rootUrl + `/api/v1/comment/outofoffice`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_GetOutOfOfficeCommentResponseParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentOutOfOffice
   */
  Comment_GetOutOfOfficeCommentResponseResponse(
    params: CommentApiService.Comment_GetOutOfOfficeCommentResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentOutOfOffice>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/comment/outofoffice`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetOutOfOfficeCommentResponse');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentOutOfOffice;

          body = <CommentOutOfOffice>response.body;

          return <HttpResponse<CommentOutOfOffice>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_GetOutOfOfficeCommentResponseParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentOutOfOffice
   */
  Comment_GetOutOfOfficeCommentResponse(
    params: CommentApiService.Comment_GetOutOfOfficeCommentResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentOutOfOffice> {
    const observable$ = this.Comment_GetOutOfOfficeCommentResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/comment/outofoffice`;
      const url = this.rootUrl + `/api/v1/comment/outofoffice`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_GetCommentEml2Params` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Comment_GetCommentEml2Response(
    params: CommentApiService.Comment_GetCommentEml2Params,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.2/comment/${params.id}/eml`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetCommentEml2');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `CommentApiService.Comment_GetCommentEml2Params` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Comment_GetCommentEml2(
    params: CommentApiService.Comment_GetCommentEml2Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.Comment_GetCommentEml2Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/comment/${params.id}/eml`;
      const url = this.rootUrl + `/api/v1.2/comment/${params.id}/eml`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_GetCommentMsgParams` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Comment_GetCommentMsgResponse(
    params: CommentApiService.Comment_GetCommentMsgParams,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.2/comment/${params.id}/msg`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_GetCommentMsg');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `CommentApiService.Comment_GetCommentMsgParams` containing the following parameters:
   *
   * - `id`: Comment id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Comment_GetCommentMsg(
    params: CommentApiService.Comment_GetCommentMsgParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.Comment_GetCommentMsgResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/comment/${params.id}/msg`;
      const url = this.rootUrl + `/api/v1.2/comment/${params.id}/msg`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CommentApiService.Comment_CreateCommentChannelParams` containing the following parameters:
   *
   * - `commentMail`: Comment mail object
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentMail
   */
  Comment_CreateCommentChannelResponse(
    params: CommentApiService.Comment_CreateCommentChannelParams,
    forUserEmail: string
  ): Observable<HttpResponse<CommentMail>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1.2/comment/channel`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Comment_CreateCommentChannel');

    // body, headers and query params
    __body = params.commentMail;
    if (params.customCreateDateTime != null)
      __params = __params.set('customCreateDateTime', params.customCreateDateTime.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CommentMail;

          body = <CommentMail>response.body;

          return <HttpResponse<CommentMail>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CommentApiService.Comment_CreateCommentChannelParams` containing the following parameters:
   *
   * - `commentMail`: Comment mail object
   *
   * - `customCreateDateTime`: Custom created datetime
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return CommentMail
   */
  Comment_CreateCommentChannel(
    params: CommentApiService.Comment_CreateCommentChannelParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CommentMail> {
    const observable$ = this.Comment_CreateCommentChannelResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/comment/channel`;
      const url = this.rootUrl + `/api/v1.2/comment/channel`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module CommentApiService {
  /**
   * Parameters for Comment_Get
   */
  export type Comment_GetParams = {
    /**
     * Comment id
     */
    id: string;

    /**
     * Html format of comment body
     */
    htmlFormat?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_GetCommentPreview
   */
  export type Comment_GetCommentPreviewParams = {
    /**
     * Comment id
     */
    id: string;

    /**
     * Use raw html or use sanitized html. Default is to use sanitized html in preview
     */
    useRawHtml?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_GetCommentEml
   */
  export type Comment_GetCommentEmlParams = {
    /**
     * Comment id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_GetList
   */
  export type Comment_GetListParams = {
    /**
     * List of tags to filter card list by.
     */
    tags?: string[];

    /**
     * Query relation for tag/sharedTag filters. Default is 'and'.
     */
    tagFilterRelation?: string;

    /**
     * Order of comments by modified date time (default is descending).
     */
    sortOrder?: string;

    /**
     * Requested page size.
     */
    size?: number;

    /**
     * List focused/muted comment list
     */
    shouldIncludeMutedIds?: boolean;

    /**
     * List of shared tags to filter card list by.
     */
    sharedTags?: string[];

    /**
     * List of user ids as senders (From) to filter comment list by.
     */
    senderIds?: string[];

    /**
     * Plaintext search query to filter comment list by. Please use prefix 'To:' or 'From:' in order to filter by recipient oy by sender.
     */
    searchQuery?: string;

    /**
     * Return format temporary - small is return only id
     */
    returnFormat?: string;

    /**
     * List of recipient ids (either a user or a group) to filter comment list by.
     */
    recipientIds?: string[];

    /**
     * Include comments from/since (as per requested sort order) its modified date.
     */
    offsetModified?: string;

    /**
     * Id of last recieved comment in the previous batch when using offsetModified
     */
    offsetId?: string;

    /**
     * Next page of resources when offsetModified set
     */
    offsetHistoryId?: string;

    /**
     * Offset date type.
     */
    offsetDateType?: string;

    /**
     * Include cards to/until (as per requested sort order) its offset date type.
     */
    offsetDateEnd?: string;

    /**
     * Include cards from/since (as per requested sort order) its offset date type.
     */
    offsetDate?: string;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * List of muted user/group ids for muted logic comment list by.
     */
    mutedIds?: string[];

    /**
     * Include signed links for attachments
     */
    includeSignedLinks?: boolean;

    /**
     * Html format of comment body.
     */
    htmlFormat?: string;

    /**
     * comment is in group
     */
    groupComment?: boolean;

    /**
     * List of exclude tags to filter card list by.
     */
    excludeTags?: string[];

    /**
     * Query relation for excludeTag/excludeSharedTag filters. Default is 'and'.
     */
    excludeTagFilterRelation?: string;

    /**
     * List of exclude shared tags to filter card list by.
     */
    excludeSharedTags?: string[];

    /**
     * List of contact ids (user or group) to filter result list by exact matching of comments's share list.
     */
    exactShareContactIds?: string[];

    /**
     * Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
     */
    contactFilterRelation?: string;

    /**
     * List of comment ids to filter comment list by.
     */
    commentIds?: string[];

    /**
     * List of card types to filter card list by.
     */
    cardTypes?: CardType[];

    /**
     * List of card ids to filter comment list by.
     */
    cardIds?: string[];

    /**
     * Do authorize card ids before search.
     */
    authorizeCardIdsBeforeSearch?: boolean;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_GetCardComments
   */
  export type Comment_GetCardCommentsParams = {
    /**
     * Order of comments by modified date time (default is descending).
     */
    sortOrder?: string;

    /**
     * Requested page size.
     */
    size?: number;

    /**
     * Return format temporary - small is return only id
     */
    returnFormat?: string;

    /**
     * Next page of resources when offsetModified set
     */
    offsetHistoryId?: string;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * Html format of comment body.
     */
    htmlFormat?: string;

    /**
     * comment for card id.
     */
    cardId?: string;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_GetConversationComments
   */
  export type Comment_GetConversationCommentsParams = {
    /**
     * Order of comments by modified date time (default is descending).
     */
    sortOrder?: string;

    /**
     * Requested page size.
     */
    size?: number;

    /**
     * Next page of resources when offsetModified set
     */
    offsetHistoryId?: string;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * Get draft comments in additional list
     */
    draftCardIds?: string[];

    /**
     * comment for conversation id.
     */
    conversationId?: string;

    /**
     * Get additiona appointment card
     */
    appointmentCardId?: string;

    /**
     * Get quoted comment in additional list
     */
    appendQuoteComment?: boolean;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_CreateCommentMail
   */
  export type Comment_CreateCommentMailParams = {
    /**
     * Comment mail object
     */
    commentMail: CommentMail;

    /**
     * Indicator whether or not to skip BBCode escaping. Use it wisely.
     */
    skipBbCodeEscaping?: boolean;

    /**
     * Email address of the user you want to send as
     */
    sendAs?: string;

    /**
     * Flag indicating whether or not server should append email history when sending email or not. Default is false.
     */
    ignoreEmailHistory?: boolean;

    /**
     * Requested html format of comment body for create response.
     */
    htmlFormat?: string;

    /**
     * Id of the group where mail will be looped into when successfully send. SendAs user must be member of that group.
     */
    groupId?: string;

    /**
     * Comment mail created from draft id
     */
    draftId?: string;

    /**
     * Do not use internal send
     */
    doNotUseInternalSend?: boolean;

    /**
     * Do not send email, only valid for users with elevated permissions
     */
    doNotSendEmail?: boolean;

    /**
     * Do not modify delete archive flag on card
     */
    doNotModifyDeleteArchiveFlag?: boolean;

    /**
     * Custom created datetime
     */
    customCreateDateTime?: string;

    /**
     * Close mail conversation
     */
    closeConversation?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_CreateCommentChat
   */
  export type Comment_CreateCommentChatParams = {
    /**
     * Comment chat object
     */
    commentChat: CommentChat;

    /**
     * Id of mail comment or mail card to create/copy shared card from.
     */
    sourceResourceId?: string;

    /**
     * Indicator whether or not to skip BBCode escaping. Use it wisely.
     */
    skipBbCodeEscaping?: boolean;

    /**
     * List of user ids for which unreads should be set. All other users will not have unread set.
     *             If null, all eligible users have unread set.
     */
    setUnreadOnlyFor?: string[];

    /**
     * List of user ids for which unreads should not be set.
     */
    setUnreadExceptFor?: string[];

    /**
     * Add creator in logic that set unread flag.
     */
    includeSenderInUnreadList?: boolean;

    /**
     * Do not send email, only valid for users with elevated permissions
     */
    doNotSendEmail?: boolean;

    /**
     * Do not modify delete archive flag on card
     */
    doNotModifyDeleteArchiveFlag?: boolean;

    /**
     * Custom created datetime
     */
    customCreateDateTime?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_UpdateChat
   */
  export type Comment_UpdateChatParams = {
    /**
     * Comment chat object
     */
    commentChat: CommentChat;

    /**
     * Indicator whether or not to skip BBCode escaping. Use it wisely.
     */
    skipBbCodeEscaping?: boolean;

    /**
     * Custom created datetime
     */
    customCreateDateTime?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_CreateTemplateResponse
   */
  export type Comment_CreateTemplateResponseParams = {
    /**
     * Comment chat object
     */
    commentTemplate: CommentTemplate;

    /**
     * Indicator whether or not to skip BBCode escaping. Use it wisely.
     */
    skipBbCodeEscaping?: boolean;

    /**
     * Custom created datetime
     */
    customCreateDateTime?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_UpdateTemplate
   */
  export type Comment_UpdateTemplateParams = {
    /**
     * Comment chat object
     */
    commentTemplate: CommentTemplate;

    /**
     * Indicator whether or not to skip BBCode escaping. Use it wisely.
     */
    skipBbCodeEscaping?: boolean;

    /**
     * Custom created datetime
     */
    customCreateDateTime?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_CreateDraftResponse
   */
  export type Comment_CreateDraftResponseParams = {
    /**
     * Comment draft object
     */
    commentDraft: CommentDraft;

    /**
     * Comment draft object
     */
    isShared?: boolean;

    /**
     * include client id for attachments
     */
    includeClientId?: boolean;

    /**
     * Comment draft reply on card object
     */
    draftOnCardId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_UpdateDraftResponse
   */
  export type Comment_UpdateDraftResponseParams = {
    /**
     * Comment draft object
     */
    commentDraft: CommentDraft;

    /**
     * include client id for attachments
     */
    includeClientId?: boolean;

    /**
     * force update comment snippet
     */
    forceUpdateCommentSnippet?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_UpdateOutOfOfficeCommentResponse
   */
  export type Comment_UpdateOutOfOfficeCommentResponseParams = {
    /**
     * Comment draft object
     */
    commentOutOfOffice: CommentOutOfOffice;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_GetOutOfOfficeCommentResponse
   */
  export type Comment_GetOutOfOfficeCommentResponseParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_GetCommentEml2
   */
  export type Comment_GetCommentEml2Params = {
    /**
     * Comment id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_GetCommentMsg
   */
  export type Comment_GetCommentMsgParams = {
    /**
     * Comment id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Comment_CreateCommentChannel
   */
  export type Comment_CreateCommentChannelParams = {
    /**
     * Comment mail object
     */
    commentMail: CommentMail;

    /**
     * Custom created datetime
     */
    customCreateDateTime?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
