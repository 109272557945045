import { DataServiceShared } from '@shared/services/data/data.service';
import { PullSynchronizationService } from '@shared/synchronization/pull-synchronization/pull-synchronization.service';
import { WatchdogService } from '@shared/services/watchdog/watchdog.service';
import { RetrySynchronizationService } from '@shared/synchronization/retry-synchronization/retry-synchronization.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { StateUpdates } from '@dta/shared/models/state-updates';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { ApiService } from '@shared/api/api-loop/api.module';
import { SynchronizableModel } from '@shared/models/sync/synchronizable.model';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { ElectronService } from '@shared/services/electron/electron';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { forkJoin, interval, Observable, of, combineLatest } from 'rxjs';
import { filter, first, map, mergeMap, publishReplay, refCount, tap } from 'rxjs/operators';
import { SynchronizationManagerService, UserSyncStatus } from './synchronization-manager.service';
import { SynchronizationStatusService } from './synchronization-status.service';
import { PushSynchronizationModuleService } from './push-synchronization/push-synchronization.module';
import { SynchronizationMiddlewareService } from './synchronization-middleware/synchronization-middleware.service';
import { PushSynchronizationService } from './push-synchronization/push-synchronization.service';
import { RetryModel } from '@dta/shared/models-api-loop/retry.model';
import { FlushDatabaseService } from '@shared/services/flush-db/flush-db.service';
import { TriggerSubscriberService } from './trigger-subscriber/trigger-subscriber.service';
import { EventHubService, IEventHub } from './websockets/event-hub.service';
import { DatabaseService } from '@shared/database/database.service';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { Time } from '@dta/shared/utils/common-utils';

export abstract class SynchronizationService {
  private _initialized = false;

  protected _syncManagerService: SynchronizationManagerService;
  protected _eventHubService: EventHubService;
  protected _triggerSubscriberService: TriggerSubscriberService;
  protected _db: DatabaseService;

  constructor(
    protected _userEmail: string,
    protected _watchdogService: WatchdogService,
    protected _storageService: StorageService,
    protected _fileStorageService: FileStorageService,
    protected _syncStatusService: SynchronizationStatusService,
    protected _notificationsService: NotificationsService,
    protected _trackingService: TrackingService,
    protected _dataService: DataServiceShared,
    protected _apiService: ApiService,
    protected _electronService: ElectronService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _settingsService: SettingsService,
    protected _pushSynchronizationModuleService: PushSynchronizationModuleService,
    protected _synchronizationMiddlewareService: SynchronizationMiddlewareService,
    protected _flushDatabaseService: FlushDatabaseService,
    protected _databaseFactory: DatabaseFactory,
    protected _time: Time
  ) {
    if (!this._userEmail) {
      throw new Error(this.constructorName + ': _userEmail cannot be empty');
    }
  }

  protected abstract initSynchronizationManagerService(): void;

  get constructorName(): string {
    return 'SynchronizationService';
  }

  get init$(): Observable<boolean> {
    if (this._initialized) {
      return of(true);
    }

    return interval(100).pipe(
      map(() => {
        return this._initialized;
      }),
      filter((initialized: boolean) => {
        return initialized;
      }),
      first(),
      publishReplay(1),
      refCount()
    );
  }

  get userEmail(): string {
    return this._userEmail;
  }

  get eventHub(): IEventHub {
    return this._eventHubService;
  }

  get pushSynchronization(): PushSynchronizationService {
    return this._syncManagerService.pushSynchronization;
  }

  get pullSynchronization(): PullSynchronizationService {
    return this._syncManagerService.pullSynchronization;
  }

  get retrySynchronization(): RetrySynchronizationService {
    return this._syncManagerService.retrySynchronization;
  }

  getSyncServicesStatus(): UserSyncStatus {
    return this._syncManagerService.getSyncServicesStatus();
  }

  init() {
    if (this._initialized) {
      return;
    }

    let watch = new StopWatch(this.constructorName + '.init', ProcessType.SERVICE, this._userEmail);
    watch.log('initializing');

    of(undefined)
      .pipe(
        /**
         * DB initialization
         */
        mergeMap(() => {
          return this.initDB();
        }),
        /**
         * Before init synchronization services
         */
        mergeMap(() => {
          return combineLatest([
            this._dataService.ConversationService.beforeSyncServiceInit(this.userEmail),
            this._dataService.FileService.beforeSyncServiceInit(this.userEmail),
            this._dataService.CommentService.beforeSyncServiceInit(this.userEmail)
          ]);
        }),
        /**
         * Init synchronization services
         */
        tap(() => {
          this.initServices();
        }),
        /**
         * After init synchronization services
         */
        mergeMap(() => {
          // Implemented only on Web
          return this._syncManagerService.clearPushSyncQueue(this.userEmail);
        }),
        /**
         * Run migration
         */
        mergeMap(() => {
          return this.initMigration();
        }),
        tap(() => {
          this._initialized = true;
          watch.log('done');
        })
      )
      .subscribe();
  }

  private initDB(): Observable<any> {
    /**
     * Get DB instance
     */
    return this._databaseFactory.open(this._userEmail).pipe(
      tap((db: DatabaseService) => {
        this._db = db;
      })
    );
  }

  protected initMigration(): Observable<any> {
    return of(undefined);
  }

  private initServices() {
    this._eventHubService = new EventHubService(this._userEmail, this._sharedUserManagerService);

    this._triggerSubscriberService = new TriggerSubscriberService(this._userEmail, this._dataService, this);

    this.initSynchronizationManagerService();
  }

  /**
   * Kicks off the synchronization via the watchdog.
   */
  start() {
    this._watchdogService.registerServices(this._syncManagerService);
    if (this._watchdogService.isConnectionActive) {
      this._syncManagerService.startSync();
    }
  }

  /**
   * Stops synchronization
   */
  stop() {
    this._watchdogService.unregisterServices(this._syncManagerService);
    this._syncManagerService?.stopSync();
  }

  /**
   * Stops the synchronization and deletes all data
   */
  destroy(): Observable<any> {
    return of(this.stop()).pipe(mergeMap(() => this._databaseFactory.destroy(this._userEmail)));
  }

  publishStateUpdates(forUserEmail: string, stateUpdates: StateUpdates) {
    PublisherService.publishEvent(this._userEmail, stateUpdates.all);
    PublisherService.publishEvent(this._userEmail, stateUpdates.remove, PublishEventType.Remove);
  }

  enqueuePushSynchronization(data: SynchronizableModel | SynchronizableModel[]) {
    this.pushSynchronization.enqueueSynchronization(data).subscribe();
  }

  dequeuePushSynchronization(data: BaseModel | BaseModel[]) {
    this.pushSynchronization.dequeueSynchronization(data).subscribe();
  }

  enqueueRetrySynchronization(data: RetryModel | RetryModel[]) {
    this.retrySynchronization.enqueueSynchronization(data);
  }

  dequeueRetrySynchronization(data: BaseModel | BaseModel[]) {
    this.retrySynchronization.dequeueSynchronization(data);
  }
}
