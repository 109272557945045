/* tslint:disable */

/**
 * Possible View Extra Data Type
 */
export type ViewExtraDataType = 'Sent' | 'Draft';

export module ViewExtraDataType {
  export const SENT: ViewExtraDataType = 'Sent';
  export const DRAFT: ViewExtraDataType = 'Draft';

  export function values(): ViewExtraDataType[] {
    return [SENT, DRAFT];
  }
}
