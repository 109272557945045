import { environment } from '@shared/environments/environment';

let isProduction = ENV === 'production';

// Set apiEnv. localStorage is not available in main process
let apiEnvironment = 'PRODUCTION';
try {
  apiEnvironment = localStorage.getItem('api_environment') || environment.apiEnvironment || 'PRODUCTION';
} catch (err) {}

// force production API when in stable/production release branch
if (isProduction) {
  try {
    localStorage.removeItem('api_environment');
  } catch (err) {}
  apiEnvironment = 'PRODUCTION';
}

export let API_ENVIRONMENTS = ['PRODUCTION', 'SPRINT', 'STAGE', 'LOOP', 'LOCALHOST'];

let clientId: string;
let microsoftClientId: string;
let loopApiUri: string;
let logApiUri: string;
let loopWebSocketUri: string;
let webOnboardingUrl: string = '';
let clientSecret: string = '';
let signUpUrl: string = '';
let domain: string = '';
let commentCacheMinutes: number = 30;

switch (apiEnvironment) {
  default:
  case 'PRODUCTION':
    clientId = '641033709772-go9onjsta8f7mraokjrblcijs3394apg.apps.googleusercontent.com';
    loopApiUri = 'https://api.intheloop.io';
    logApiUri = 'https://log.intheloop.io';
    loopWebSocketUri = 'https://socket.intheloop.io';
    microsoftClientId = '0c49d50e-04d5-479d-acd9-640a4d6b3321';
    webOnboardingUrl = 'https://register.intheloop.io';
    signUpUrl = 'https://signup.intheloop.io';
    domain = '.intheloop.io';
    clientSecret = 'ca32eec4-c030-4fe2-929c-22c18be45dd2';
    break;
  case 'SPRINT':
    clientId = '661500301384.apps.googleusercontent.com';
    loopApiUri = 'https://sprint.snapp.email';
    logApiUri = 'https://log-sprint.snapp.email';
    loopWebSocketUri = 'https://socket-sprint.snapp.email';
    microsoftClientId = 'f30d45f7-c3cb-4d43-ae53-a4d64ab92bb3';
    signUpUrl = 'https://signup.snapp.email';
    webOnboardingUrl = 'https://register.snapp.email';
    domain = '.snapp.email';
    clientSecret = '50e1ab79-d039-45f5-8297-4485ba47421c';
    commentCacheMinutes = 5;
    break;
  case 'LOOP':
    clientId = '661500301384.apps.googleusercontent.com';
    loopApiUri = 'https://loop.snapp.email';
    logApiUri = 'https://log-loop.snapp.email';
    loopWebSocketUri = 'https://socket-loop.snapp.email';
    microsoftClientId = 'f30d45f7-c3cb-4d43-ae53-a4d64ab92bb3';
    signUpUrl = 'https://signup-loop.snapp.email';
    webOnboardingUrl = 'https://register-loop.snapp.email';
    domain = '.snapp.email';
    clientSecret = '50e1ab79-d039-45f5-8297-4485ba47421c';
    commentCacheMinutes = 5;
    break;
  case 'STAGE':
    clientId = '661500301384.apps.googleusercontent.com';
    loopApiUri = 'https://stage.intheloop.io';
    logApiUri = 'https://log-stage.intheloop.io';
    loopWebSocketUri = 'https://socket-stage.intheloop.io';
    microsoftClientId = '4c605c1c-aeb4-45e2-ab15-5e25f2a787ce';
    signUpUrl = 'https://signup-stage.intheloop.io';
    webOnboardingUrl = 'https://register-stage.intheloop.io';
    domain = '.intheloop.io';
    clientSecret = 'e3cbd22d-058e-4144-8a18-b0c55bd2227d';
    commentCacheMinutes = 5;
    break;
  case 'LOCALHOST':
    clientId = '661500301384.apps.googleusercontent.com';
    loopApiUri = 'http://localhost:7094';
    loopWebSocketUri = 'http://localhost:7091';
    logApiUri = 'http://localhost:7089';
    microsoftClientId = 'f30d45f7-c3cb-4d43-ae53-a4d64ab92bb3';
    commentCacheMinutes = 5;
    break;
}

let loopWebSocketsPath = '/hubs/v1/events';
let BODY_SAVED_ON_DISC_TEXT = 'BODY_SAVED_ON_DISC_TEXT';

export const CONSTANTS = {
  ///////////////////////
  // GENERAL
  ///////////////////////
  PRODUCTION: isProduction,
  PLATFORM: 'electron',
  DOMAIN: domain,

  ///////////////////////
  // API
  ///////////////////////
  LOOP_API_ROOT_URI: loopApiUri,
  LOOP_WEB_SOCKET_URI_PATH: loopWebSocketsPath,
  LOOP_WEB_SOCKET_URI: loopWebSocketUri + loopWebSocketsPath,
  LOOP_LOG_ROOT_URI: logApiUri,
  LOOP_COMMENTS_CACHE_DURATION_MINUTES: commentCacheMinutes,

  ///////////////////////
  // AUTH
  ///////////////////////
  TOKEN_URL: 'https://www.googleapis.com/oauth2/v4/token',
  CLIENT_ID: clientId,
  MICROSOFT_CLIENT_ID: microsoftClientId,

  GOOGLE_OAUTH:
    'https://accounts.google.com/o/oauth2/v2/auth?' +
    parameterBuilder({
      client_id: clientId,
      redirect_uri: signUpUrl + '/loading-second-google',
      scope:
        'https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      login_hint: ''
    }),

  GOOGLE_OAUTH_LIMITED:
    'https://accounts.google.com/o/oauth2/v2/auth?' +
    parameterBuilder({
      client_id: clientId,
      redirect_uri: signUpUrl + '/loading-second-google',
      scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      login_hint: ''
    }),

  MICROSOFT_OAUTH:
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
    parameterBuilder({
      client_id: microsoftClientId,
      response_type: 'code',
      redirect_uri: signUpUrl + '/loading-second-microsoft',
      response_mode: 'query',
      scope:
        'calendars.readwrite contacts.read mail.readwrite mail.send mailboxsettings.readwrite user.read offline_access',
      prompt: 'select_account',
      login_hint: ''
    }),

  MICROSOFT_OAUTH_LIMITED:
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
    parameterBuilder({
      client_id: microsoftClientId,
      response_type: 'code',
      redirect_uri: signUpUrl + '/loading-second-microsoft',
      response_mode: 'query',
      scope: 'user.read',
      prompt: 'select_account',
      login_hint: ''
    }),

  MICROSOFT_OAUTH_EXTENDED:
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
    parameterBuilder({
      client_id: microsoftClientId,
      tenant: 'common',
      response_type: 'code',
      redirect_uri: signUpUrl + '/loading-second-microsoft',
      response_mode: 'query',
      scope:
        'calendars.readwrite contacts.read mail.readwrite mail.send mailboxsettings.readwrite user.read offline_access mail.send.shared mail.readwrite.shared contacts.read.shared calendars.readwrite.shared user.readbasic.all',
      prompt: 'select_account',
      login_hint: ''
    }),

  GOOGLE_MAPS_API: 'AIzaSyAbi-jSBCzmaYC61KhpX2R4STGzBNL9E98',

  ///////////////////////
  // STORAGE
  ///////////////////////
  LOCAL_FILE_ROOT_URI: 'http://localhost:8080',
  MAX_ATTACHMENTS_SIZE: 25 * 1024 * 1024,

  ///////////////////////
  // SYNC
  ///////////////////////
  SYNC_CUTOFF_DAYS: 2, // days to look into past when synchronizing data
  FILE_SYNC_CUTOFF_DAYS: 3, // days to look into past when synchronizing files
  TAG_UPDATE_PARENT_CUTOFF_DAYS: 14,
  CONVERSATION_MAX_CHANGES_DTA: 20000,
  CONVERSATION_MAX_CHANGES_WEB: 100,

  ACTIVE_SYNC_PAGE_SIZE: 1024,
  ACTIVE_SYNC_PULL_INTERVAL: 1000,

  PAST_SYNC_START_PAGE_SIZE: 100,
  PAST_SYNC_MAX_PAGE_SIZE: 1024,
  PAST_SYNC_PULL_INTERVAL: 1000,

  // How often sync loop waits between each query for any prev. sync to complete
  SYNC_WAIT_START_PERIOD: 10 * 1000,

  // How often retry sync polls DB for retry entries
  // Cases with "retryCount < 0" are covered with db conditions
  RETRY_SYNC_DB_POLL_PERIOD: 5 * 1000,

  // How often does contact sync pull for new changes
  CONTACT_SYNC_POLL_PERIOD: 5 * 60 * 1000,

  // How often are reports generated
  REPORT_SERVICE_PERIOD: 10 * 60 * 1000,

  // How often updates for currently observed list of conversations are fetched
  CONVERSATION_PULL_PERIOD: 10 * 1000,

  // How often badgeCount list are fetched
  BADGE_COUNT_PULL_PERIOD: 10 * 1000,

  // How often logs are removed from cache and persisted on disk
  LOGS_PERSIST_INTERVAL: 60 * 1000,
  LOGS_MAX_CACHE_SIZE: 500,

  ///////////////////////
  // DATABASE
  ///////////////////////
  DB_PERSIST_INTERVAL: 60 * 1000,
  DB_PURGE_EMAIL_CUTOFF_DAYS: 30,
  DB_PURGE_CHAT_CUTOFF_DAYS: 7,
  DB_PURGE_CHAT_MIN_COUNT: 100,

  ///////////////////////
  // INTEGRATIONS
  ///////////////////////
  LOOP_BOT_ID: 'user_1552217',
  LOOP_BOT_EMAIL: 'loopbot@intheloop.io',

  ///////////////////////
  // OTHERS
  ///////////////////////
  BODY_SAVED_ON_DISC_TEXT: BODY_SAVED_ON_DISC_TEXT,
  BODY_SAVED_ON_DISC_HTML: `<div style="${isProduction ? 'display:none' : 'background:red'}">${BODY_SAVED_ON_DISC_TEXT}</div>`,
  WATCHDOG_CONNECTION_RETRY: 1000,
  WATCHDOG_DETECT_FAILED_SYNCS_INTERVAL: 5000,
  REPORTING_USAGE_METRICS_INTERVAL_MINUTES: 20,
  DEEPLINK_PREFIX: 'intheloop://',
  IN_APP_LINK_PREFIX: 'https://loop-messenger.app.link/',
  WEB_ONBOARDING_URL: webOnboardingUrl,
  SIGN_UP_URL: signUpUrl,
  CLIENT_SECRET: clientSecret,
  TENOR_API_KEY: 'AIzaSyAvhOjakmzZaRgaywEc0i3kzPZnEuHvy3U',
  TENOR_CONTENT_FILTER_OPTIONS: 'medium',
  TENOR_API: 'https://tenor.googleapis.com/v2',
  TENOR_IMAGES: 'https://media.tenor.com/',
  FILES_API: 'https://files.intheloop.io/',
  USER_ACTIVE_STATE_LIFETIME_MS: 10 * 60 * 1000, // How long is active state valid (should be updated every 5 second via WS)
  BODY_COMPLEXITY_LIMIT: 365,
  BODY_CLIPPING_LIMIT: 800 * 1000,
  UNDO_MILISECONDS: 3 * 1000,
  BIG_INT: 999_999_999,
  INVALIDATE_LOCAL_CHANGES_AFTER: 30,

  AVAILABLE_LANGUAGES: [
    'English (US)',
    'English (UK)',
    'German',
    'French',
    'Spanish',
    'Slovenian',
    'Croatian',
    'Hungarian',
    'Portuguese (Brazil)',
    'Portuguese',
    'Spanish',
    'Dutch',
    'Italian',
    'Turkish',
    'Polish',
    'Russian'
  ]
};

function parameterBuilder(params): string {
  return Object.keys(params)
    .map(key => key + '=' + (params[key] || ''))
    .join('&')
    .split(' ')
    .join('%20');
}
