import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsPdfPipe } from '@shared/modules/files/components/pipes/is-pdf/is-pdf.pipe';

@NgModule({
  declarations: [IsPdfPipe],
  imports: [CommonModule],
  exports: [IsPdfPipe],
})
export class IsPdfModule {}
