/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { SharedTagBase } from '../models/shared-tag-base';
import { Response } from '../models/response';
import { ListOfTags } from '../models/list-of-tags';
import { ResponseConflict } from '../models/response-conflict';
import { ListOfResourcesOfSharedTagFolder } from '../models/list-of-resources-of-shared-tag-folder';
import { ListOfResourcesOfListOfGroupSharedFolderTags } from '../models/list-of-resources-of-list-of-group-shared-folder-tags';
import { ListOfResourcesOfLabelsWorkspace } from '../models/list-of-resources-of-labels-workspace';
import { ListOfResourcesOfSharedTagLabel } from '../models/list-of-resources-of-shared-tag-label';
import { ListOfResourcesOfListOfResourcesOfSharedTagLabel } from '../models/list-of-resources-of-list-of-resources-of-shared-tag-label';
import { ListOfResourcesOfClassificationsWorkspace } from '../models/list-of-resources-of-classifications-workspace';
import { ListOfResourcesOfSharedTagClassification } from '../models/list-of-resources-of-shared-tag-classification';
import { ListOfResourcesOfListOfResourcesOfSharedTagClassification } from '../models/list-of-resources-of-list-of-resources-of-shared-tag-classification';
import { ListOfGroupSharedFolderTags } from '../models/list-of-group-shared-folder-tags';

@Injectable()
export class SharedTagApiService extends BaseService {
  /**
   * @param params The `SharedTagApiService.SharedTag_GetParams` containing the following parameters:
   *
   * - `id`: Tag id
   *
   * - `parentId`: Parent of tag
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetResponse(
    params: SharedTagApiService.SharedTag_GetParams,
    forUserEmail: string
  ): Observable<HttpResponse<SharedTagBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_Get');

    // body, headers and query params
    if (params.parentId != null) __params = __params.set('parentId', params.parentId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SharedTagBase;

          body = <SharedTagBase>response.body;

          return <HttpResponse<SharedTagBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetParams` containing the following parameters:
   *
   * - `id`: Tag id
   *
   * - `parentId`: Parent of tag
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_Get(
    params: SharedTagApiService.SharedTag_GetParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SharedTagBase> {
    const observable$ = this.SharedTag_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/${params.id}`;
      const url = this.rootUrl + `/api/v1/sharedtag/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_CreateSharedTagParams` containing the following parameters:
   *
   * - `sharedTag`: Shared tag object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_CreateSharedTagResponse(
    params: SharedTagApiService.SharedTag_CreateSharedTagParams,
    forUserEmail: string
  ): Observable<HttpResponse<SharedTagBase>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/sharedtag`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_CreateSharedTag');

    // body, headers and query params
    __body = params.sharedTag;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SharedTagBase;

          body = <SharedTagBase>response.body;

          return <HttpResponse<SharedTagBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_CreateSharedTagParams` containing the following parameters:
   *
   * - `sharedTag`: Shared tag object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_CreateSharedTag(
    params: SharedTagApiService.SharedTag_CreateSharedTagParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SharedTagBase> {
    const observable$ = this.SharedTag_CreateSharedTagResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag`;
      const url = this.rootUrl + `/api/v1/sharedtag`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_UpdateSharedTagParams` containing the following parameters:
   *
   * - `sharedTag`: Shared tag object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_UpdateSharedTagResponse(
    params: SharedTagApiService.SharedTag_UpdateSharedTagParams,
    forUserEmail: string
  ): Observable<HttpResponse<SharedTagBase>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/sharedtag`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_UpdateSharedTag');

    // body, headers and query params
    __body = params.sharedTag;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SharedTagBase;

          body = <SharedTagBase>response.body;

          return <HttpResponse<SharedTagBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_UpdateSharedTagParams` containing the following parameters:
   *
   * - `sharedTag`: Shared tag object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_UpdateSharedTag(
    params: SharedTagApiService.SharedTag_UpdateSharedTagParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SharedTagBase> {
    const observable$ = this.SharedTag_UpdateSharedTagResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag`;
      const url = this.rootUrl + `/api/v1/sharedtag`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_DeleteSharedTagParams` containing the following parameters:
   *
   * - `sharedTag`: Shared tag object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_DeleteSharedTagResponse(
    params: SharedTagApiService.SharedTag_DeleteSharedTagParams,
    forUserEmail: string
  ): Observable<HttpResponse<SharedTagBase>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/sharedtag`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_DeleteSharedTag');

    // body, headers and query params
    __body = params.sharedTag;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SharedTagBase;

          body = <SharedTagBase>response.body;

          return <HttpResponse<SharedTagBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_DeleteSharedTagParams` containing the following parameters:
   *
   * - `sharedTag`: Shared tag object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_DeleteSharedTag(
    params: SharedTagApiService.SharedTag_DeleteSharedTagParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SharedTagBase> {
    const observable$ = this.SharedTag_DeleteSharedTagResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag`;
      const url = this.rootUrl + `/api/v1/sharedtag`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_UpdateTagsParams` containing the following parameters:
   *
   * - `tags`: List of resources that need to be modified
   *
   * - `processAsync`: If true, backend will process the request asynchrounosly and return HTTP accepted if everything is OK with request
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_UpdateTagsResponse(
    params: SharedTagApiService.SharedTag_UpdateTagsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfTags[]>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/sharedtag/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_UpdateTags');

    // body, headers and query params
    __body = params.tags;
    if (params.processAsync != null) __params = __params.set('processAsync', params.processAsync.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfTags[];

          body = <ListOfTags[]>response.body;

          return <HttpResponse<ListOfTags[]>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_UpdateTagsParams` containing the following parameters:
   *
   * - `tags`: List of resources that need to be modified
   *
   * - `processAsync`: If true, backend will process the request asynchrounosly and return HTTP accepted if everything is OK with request
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_UpdateTags(
    params: SharedTagApiService.SharedTag_UpdateTagsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfTags[]> {
    const observable$ = this.SharedTag_UpdateTagsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/list`;
      const url = this.rootUrl + `/api/v1/sharedtag/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetListOfSharedFolderTagsParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `groupId`: get shared folder tag for group
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetListOfSharedFolderTagsResponse(
    params: SharedTagApiService.SharedTag_GetListOfSharedFolderTagsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfSharedTagFolder>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/groupfolderlist`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetListOfSharedFolderTags');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfSharedTagFolder;

          body = <ListOfResourcesOfSharedTagFolder>response.body;

          return <HttpResponse<ListOfResourcesOfSharedTagFolder>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetListOfSharedFolderTagsParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `groupId`: get shared folder tag for group
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetListOfSharedFolderTags(
    params: SharedTagApiService.SharedTag_GetListOfSharedFolderTagsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfSharedTagFolder> {
    const observable$ = this.SharedTag_GetListOfSharedFolderTagsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/groupfolderlist`;
      const url = this.rootUrl + `/api/v1/sharedtag/groupfolderlist`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetListOfGroupsSharedFolderTagsParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetListOfGroupsSharedFolderTagsResponse(
    params: SharedTagApiService.SharedTag_GetListOfGroupsSharedFolderTagsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfListOfGroupSharedFolderTags>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/groupsfolderlist`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetListOfGroupsSharedFolderTags');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfListOfGroupSharedFolderTags;

          body = <ListOfResourcesOfListOfGroupSharedFolderTags>response.body;

          return <HttpResponse<ListOfResourcesOfListOfGroupSharedFolderTags>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetListOfGroupsSharedFolderTagsParams` containing the following parameters:
   *
   * - `size`: Paging size
   *
   * - `offset`: Paging offset
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetListOfGroupsSharedFolderTags(
    params: SharedTagApiService.SharedTag_GetListOfGroupsSharedFolderTagsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfListOfGroupSharedFolderTags> {
    const observable$ = this.SharedTag_GetListOfGroupsSharedFolderTagsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/groupsfolderlist`;
      const url = this.rootUrl + `/api/v1/sharedtag/groupsfolderlist`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_UpdateTagParams` containing the following parameters:
   *
   * - `tags`: Updated listoftags object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_UpdateTagResponse(
    params: SharedTagApiService.SharedTag_UpdateTagParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfTags>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/sharedtag/list/singleton`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_UpdateTag');

    // body, headers and query params
    __body = params.tags;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfTags;

          body = <ListOfTags>response.body;

          return <HttpResponse<ListOfTags>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_UpdateTagParams` containing the following parameters:
   *
   * - `tags`: Updated listoftags object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_UpdateTag(
    params: SharedTagApiService.SharedTag_UpdateTagParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfTags> {
    const observable$ = this.SharedTag_UpdateTagResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/list/singleton`;
      const url = this.rootUrl + `/api/v1/sharedtag/list/singleton`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetLabelWorkspacesParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetLabelWorkspacesResponse(
    params: SharedTagApiService.SharedTag_GetLabelWorkspacesParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfLabelsWorkspace>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/labels/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetLabelWorkspaces');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfLabelsWorkspace;

          body = <ListOfResourcesOfLabelsWorkspace>response.body;

          return <HttpResponse<ListOfResourcesOfLabelsWorkspace>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetLabelWorkspacesParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetLabelWorkspaces(
    params: SharedTagApiService.SharedTag_GetLabelWorkspacesParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfLabelsWorkspace> {
    const observable$ = this.SharedTag_GetLabelWorkspacesResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/labels/list`;
      const url = this.rootUrl + `/api/v1/sharedtag/labels/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetWorkspaceLabelsParams` containing the following parameters:
   *
   * - `id`: workspace id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetWorkspaceLabelsResponse(
    params: SharedTagApiService.SharedTag_GetWorkspaceLabelsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfSharedTagLabel>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/labels/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetWorkspaceLabels');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfSharedTagLabel;

          body = <ListOfResourcesOfSharedTagLabel>response.body;

          return <HttpResponse<ListOfResourcesOfSharedTagLabel>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetWorkspaceLabelsParams` containing the following parameters:
   *
   * - `id`: workspace id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetWorkspaceLabels(
    params: SharedTagApiService.SharedTag_GetWorkspaceLabelsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfSharedTagLabel> {
    const observable$ = this.SharedTag_GetWorkspaceLabelsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/labels/${params.id}`;
      const url = this.rootUrl + `/api/v1/sharedtag/labels/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetWorkspacesLabelsParams` containing the following parameters:
   *
   * - `workspaceIds`: workspace ids (all if empty)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetWorkspacesLabelsResponse(
    params: SharedTagApiService.SharedTag_GetWorkspacesLabelsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfListOfResourcesOfSharedTagLabel>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/workspaces/labels/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetWorkspacesLabels');

    // body, headers and query params
    (params.workspaceIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('workspaceIds', val.toString());
    });
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfListOfResourcesOfSharedTagLabel;

          body = <ListOfResourcesOfListOfResourcesOfSharedTagLabel>response.body;

          return <HttpResponse<ListOfResourcesOfListOfResourcesOfSharedTagLabel>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetWorkspacesLabelsParams` containing the following parameters:
   *
   * - `workspaceIds`: workspace ids (all if empty)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetWorkspacesLabels(
    params: SharedTagApiService.SharedTag_GetWorkspacesLabelsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfListOfResourcesOfSharedTagLabel> {
    const observable$ = this.SharedTag_GetWorkspacesLabelsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/workspaces/labels/list`;
      const url = this.rootUrl + `/api/v1/sharedtag/workspaces/labels/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetClassificationWorkspacesParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetClassificationWorkspacesResponse(
    params: SharedTagApiService.SharedTag_GetClassificationWorkspacesParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfClassificationsWorkspace>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/classification/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetClassificationWorkspaces');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfClassificationsWorkspace;

          body = <ListOfResourcesOfClassificationsWorkspace>response.body;

          return <HttpResponse<ListOfResourcesOfClassificationsWorkspace>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetClassificationWorkspacesParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetClassificationWorkspaces(
    params: SharedTagApiService.SharedTag_GetClassificationWorkspacesParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfClassificationsWorkspace> {
    const observable$ = this.SharedTag_GetClassificationWorkspacesResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/classification/list`;
      const url = this.rootUrl + `/api/v1/sharedtag/classification/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetWorkspaceClassificationsParams` containing the following parameters:
   *
   * - `id`: workspace id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetWorkspaceClassificationsResponse(
    params: SharedTagApiService.SharedTag_GetWorkspaceClassificationsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfSharedTagClassification>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/classification/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetWorkspaceClassifications');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfSharedTagClassification;

          body = <ListOfResourcesOfSharedTagClassification>response.body;

          return <HttpResponse<ListOfResourcesOfSharedTagClassification>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetWorkspaceClassificationsParams` containing the following parameters:
   *
   * - `id`: workspace id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetWorkspaceClassifications(
    params: SharedTagApiService.SharedTag_GetWorkspaceClassificationsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfSharedTagClassification> {
    const observable$ = this.SharedTag_GetWorkspaceClassificationsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/classification/${params.id}`;
      const url = this.rootUrl + `/api/v1/sharedtag/classification/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetWorkspacesClassificationParams` containing the following parameters:
   *
   * - `workspaceIds`: workspace ids (all if empty)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetWorkspacesClassificationResponse(
    params: SharedTagApiService.SharedTag_GetWorkspacesClassificationParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfListOfResourcesOfSharedTagClassification>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/sharedtag/workspaces/classification/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetWorkspacesClassification');

    // body, headers and query params
    (params.workspaceIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('workspaceIds', val.toString());
    });
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfListOfResourcesOfSharedTagClassification;

          body = <ListOfResourcesOfListOfResourcesOfSharedTagClassification>response.body;

          return <HttpResponse<ListOfResourcesOfListOfResourcesOfSharedTagClassification>>(
            response.clone({ body: body })
          );
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetWorkspacesClassificationParams` containing the following parameters:
   *
   * - `workspaceIds`: workspace ids (all if empty)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetWorkspacesClassification(
    params: SharedTagApiService.SharedTag_GetWorkspacesClassificationParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfListOfResourcesOfSharedTagClassification> {
    const observable$ = this.SharedTag_GetWorkspacesClassificationResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/sharedtag/workspaces/classification/list`;
      const url = this.rootUrl + `/api/v1/sharedtag/workspaces/classification/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetListOfSharedFolderTags2Params` containing the following parameters:
   *
   * - `groupId`: get shared folder tag for group
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetListOfSharedFolderTags2Response(
    params: SharedTagApiService.SharedTag_GetListOfSharedFolderTags2Params,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfGroupSharedFolderTags>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.2/sharedtag/groupfolderlist`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SharedTag_GetListOfSharedFolderTags2');

    // body, headers and query params
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfGroupSharedFolderTags;

          body = <ListOfGroupSharedFolderTags>response.body;

          return <HttpResponse<ListOfGroupSharedFolderTags>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SharedTagApiService.SharedTag_GetListOfSharedFolderTags2Params` containing the following parameters:
   *
   * - `groupId`: get shared folder tag for group
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SharedTag_GetListOfSharedFolderTags2(
    params: SharedTagApiService.SharedTag_GetListOfSharedFolderTags2Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfGroupSharedFolderTags> {
    const observable$ = this.SharedTag_GetListOfSharedFolderTags2Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/sharedtag/groupfolderlist`;
      const url = this.rootUrl + `/api/v1.2/sharedtag/groupfolderlist`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module SharedTagApiService {
  /**
   * Parameters for SharedTag_Get
   */
  export type SharedTag_GetParams = {
    /**
     * Tag id
     */
    id: string;

    /**
     * Parent of tag
     */
    parentId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_CreateSharedTag
   */
  export type SharedTag_CreateSharedTagParams = {
    /**
     * Shared tag object
     */
    sharedTag: SharedTagBase;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_UpdateSharedTag
   */
  export type SharedTag_UpdateSharedTagParams = {
    /**
     * Shared tag object
     */
    sharedTag: SharedTagBase;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_DeleteSharedTag
   */
  export type SharedTag_DeleteSharedTagParams = {
    /**
     * Shared tag object
     */
    sharedTag: SharedTagBase;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_UpdateTags
   */
  export type SharedTag_UpdateTagsParams = {
    /**
     * List of resources that need to be modified
     */
    tags: ListOfTags[];

    /**
     * If true, backend will process the request asynchrounosly and return HTTP accepted if everything is OK with request
     */
    processAsync?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetListOfSharedFolderTags
   */
  export type SharedTag_GetListOfSharedFolderTagsParams = {
    /**
     * Paging size
     */
    size?: number;

    /**
     * Paging offset
     */
    offset?: number;

    /**
     * get shared folder tag for group
     */
    groupId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetListOfGroupsSharedFolderTags
   */
  export type SharedTag_GetListOfGroupsSharedFolderTagsParams = {
    /**
     * Paging size
     */
    size?: number;

    /**
     * Paging offset
     */
    offset?: number;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_UpdateTag
   */
  export type SharedTag_UpdateTagParams = {
    /**
     * Updated listoftags object
     */
    tags: ListOfTags;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetLabelWorkspaces
   */
  export type SharedTag_GetLabelWorkspacesParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetWorkspaceLabels
   */
  export type SharedTag_GetWorkspaceLabelsParams = {
    /**
     * workspace id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetWorkspacesLabels
   */
  export type SharedTag_GetWorkspacesLabelsParams = {
    /**
     * workspace ids (all if empty)
     */
    workspaceIds?: string[];

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetClassificationWorkspaces
   */
  export type SharedTag_GetClassificationWorkspacesParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetWorkspaceClassifications
   */
  export type SharedTag_GetWorkspaceClassificationsParams = {
    /**
     * workspace id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetWorkspacesClassification
   */
  export type SharedTag_GetWorkspacesClassificationParams = {
    /**
     * workspace ids (all if empty)
     */
    workspaceIds?: string[];

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SharedTag_GetListOfSharedFolderTags2
   */
  export type SharedTag_GetListOfSharedFolderTags2Params = {
    /**
     * get shared folder tag for group
     */
    groupId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
