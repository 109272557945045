// All events should be well documented in this list:
// https://github.com/4thOffice/Product-and-staff/wiki/Userflow-events

export enum UserflowEventName {
  // Add account
  GoogleAccountAdded = 'google_account_added',
  ExchangeAccountAdded = 'exchange-account-added',
  MicrosoftAccountAdded = 'microsoft-account-added',
  RemoteImapAccountAdded = 'remote-imap-account-added',
  ImapAccountAdded = 'imap-account-added',
  OtherAccountAdded = 'other-account-added',

  // View open
  LoopInboxOpened = 'loop_inbox_opened',
  ChatsOpened = 'chats_opened',
  AssignedOpened = 'assigned_opened',
  StarredOpened = 'starred_opened',
  FolderOpened = 'folder_opened',
  UnifiedSharedInboxViewOpened = 'unified_shared_inbox_view_opened',

  // Delegation
  EmailAssigned = 'email_assigned',
  EmailResolved = 'email_resolved',
  EmailUnassigned = 'email_unassigned',
  EmailMuted = 'email_muted',
  EmailSubscribed = 'email_subscribed',

  // New/Create
  FolderCreated = 'folder_created',
  EmailSent = 'email_sent',
  TeamCreated = 'team_created',

  // Other
  UserInvited = 'user_invited',
  TeamSubscribed = 'team_subscribed',
  TeamUnsubscribed = 'team_unsubscribed',
}
