import * as _ from 'lodash';
import { Signature } from '@shared/api/api-loop/models/signature';
import { BaseModel } from './base/base.model';

export class SignatureModel extends BaseModel implements Signature {
  static type: string = 'Signature';

  readonly $type: string = SignatureModel.type;

  name: string;
  text: string;
  html: string;
  isDefault: boolean;
  _ex: SignatureExtraData;

  constructor(data?: Signature) {
    super(data);
  }

  static create(data: Signature): SignatureModel {
    if (!data || !data.$type) {
      throw new Error(`Invalid $type given ${JSON.stringify(data)}`);
    }
    if (data instanceof SignatureModel) {
      return data;
    }
    return new SignatureModel(data);
  }

  static createList(docs: Signature[]): SignatureModel[] {
    return _.map(docs, (doc: Signature) => new SignatureModel(doc));
  }
}

export interface SignatureExtraData {
  deleted: boolean;
}
