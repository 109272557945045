import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmojiPickerComponent } from '@dta/ui/components/common/emoji-picker/emoji-picker.component';
import { IconModule } from '@shared/modules/common/icon/icon.module';

@NgModule({
  declarations: [EmojiPickerComponent],
  imports: [CommonModule, IconModule],
  exports: [EmojiPickerComponent],
})
export class EmojiPickerModule {}
