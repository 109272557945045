import { ToolbarOption } from '@shared/modules/form-controls/wysiwyg-control/internal/toolbar-option';

export const defaultToolbarOptions: ToolbarOption[] = [
  ToolbarOption.bold,
  ToolbarOption.italic,
  ToolbarOption.underline,
  ToolbarOption.fontFamily,
  ToolbarOption.fontSize,
  ToolbarOption.textColor,
  ToolbarOption.backgroundColor,
  ToolbarOption.insertLink,
  ToolbarOption.formatUL,
  ToolbarOption.formatOL,
  ToolbarOption.outdent,
  ToolbarOption.indent,
  ToolbarOption.clearFormatting
];
