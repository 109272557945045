import { Component, Input } from '@angular/core';
import { EmptyStateSpecialBaseComponent } from '../empty-state-special-base.component';

@Component({
  selector: 'shared-inboxes-empty-state',
  templateUrl: './shared-inboxes-empty-state.html',
  styleUrls: ['../empty-state.scss'],
})
export class SharedInboxesEmptyStateComponent extends EmptyStateSpecialBaseComponent {
  illustrationSvg = require('@shared/assets/img/svg/illustration-shared-inbox.svg');

  @Input() sharedInboxes: boolean;

  ctaClick() {}

  get createLink() {
    return this.sharedInboxes ? '/user-settings/shared-inboxes/create' : '/user-settings/personal-inboxes/create';
  }

  get title() {
    return this.sharedInboxes ? 'shared inbox' : 'personal inbox';
  }
}
