import { v1 } from 'uuid';
import { Time } from './common-utils';

export class StopWatch {
  private uuid: string = '';
  private start: Date;
  private stopped: boolean = false;
  private init: boolean = false;

  private static enabled: boolean = false;

  private timeDiffThresholdWarning = 200; // Log tasks that take longer than threshold in red (in ms)
  private timeDiffThresholdError = 500; // Log tasks that take longer than threshold in red (in ms)
  private prevTime = 0; // For keeping track how long did the new step take

  private static ELAPSED_TIME_COLOR: string = 'color: #54C8E8';
  private static ELAPSED_TIME_COLOR_WARNING: string = 'color: #cf6713';
  private static ELAPSED_TIME_COLOR_ERROR: string = 'color: #cc1b1b';
  private static UUID_COLOR: string = 'color: #bdbdbd';
  private static USER_COLOR: string = 'color: #988a99';
  private static PROCESS_COLOR: string = 'color: #988a99';
  private static TIMESTAMP_COLOR: string = 'color: #54C8E8';

  constructor(
    private type: string,
    private processType: ProcessType = ProcessType.OTHER,
    private forUser: string = 'all',
  ) {
    if (StopWatch.enabled && !this.stopped) {
      this.start = new Date();
      this.uuid = v1().substr(0, 8);

      this.log();
      this.init = true;
    }
  }

  static enable() {
    this.enabled = true;
  }

  static disable() {
    this.enabled = false;
  }

  log(...args: any[]) {
    if (!StopWatch.enabled || this.stopped || !this.start) {
      return;
    }

    // Get time diff
    let end = new Date();
    let timeDiff = end.getTime() - this.start.getTime();

    // Set color by diff time
    let textColor = void 0;
    if (timeDiff - this.prevTime >= this.timeDiffThresholdError) {
      textColor = StopWatch.ELAPSED_TIME_COLOR_ERROR;
    } else if (timeDiff - this.prevTime >= this.timeDiffThresholdWarning) {
      textColor = StopWatch.ELAPSED_TIME_COLOR_WARNING;
    }
    this.prevTime = timeDiff;

    // Elapsed time
    let elapsedTimeMsg = '%c%s ms ~%c ';
    let elapseTimeData = [StopWatch.ELAPSED_TIME_COLOR, this.padLeft(6, timeDiff + ''), void 0];

    // TimeStamp
    let timeStamp = '%c[%s]%c ';
    let timeStampData = [StopWatch.TIMESTAMP_COLOR, Time.getTimestamp(true), void 0];

    // UUID
    let uuidMsg = ' StopWatch %c[uuid: %s]%c ';
    let uuidData = [StopWatch.UUID_COLOR, this.uuid, void 0];

    // Process
    let processMsg = '%c[process: %s]%c ';
    let processData = [StopWatch.PROCESS_COLOR, this.padRight(11, this.processType), void 0];

    // User (optional)
    let userMsg = '%c[user: %s]%c ';
    let userData = [StopWatch.USER_COLOR, this.padRight(20, this.forUser), void 0];

    // Log data
    let stopwatchMsg = '';
    let stopwatchData = [];
    if (!this.init) {
      stopwatchMsg = '%cInit: [%s]%c';
      stopwatchData = [textColor + ';font-weight:600;', this.type, void 0];
    } else {
      stopwatchMsg = '----> %c%s%c';
      stopwatchData = [textColor, args.join(' '), void 0];
    }

    //////////////
    // MSG STRING
    //////////////
    let logMsg = '';
    logMsg += elapsedTimeMsg;
    logMsg += timeStamp;
    logMsg += uuidMsg;
    logMsg += processMsg;
    logMsg += userMsg;
    logMsg += stopwatchMsg;

    ////////////
    // LOG DATA
    ////////////
    let logData = [logMsg];
    logData.push(...elapseTimeData);
    logData.push(...timeStampData);
    logData.push(...uuidData);
    logData.push(...processData);
    logData.push(...userData);
    logData.push(...stopwatchData);

    //////////////////
    // LOG TO CONSOLE
    //////////////////
    console.log(...logData);
  }

  stop() {
    this.stopped = true;
  }

  private padLeft(maxLength: number, str: string) {
    return str && str.length < maxLength ? ' '.repeat(maxLength - str.length) + str : str;
  }
  private padRight(maxLength: number, str: string) {
    return str && str.length < maxLength ? str + ' '.repeat(maxLength - str.length) : str;
  }
}

export enum ProcessType {
  COMPONENT = 'component',
  SERVICE = 'service',
  SERVICE_IPC = 'service-ipc',
  STORE = 'store',
  COLLECTION = 'collection',
  OTHER = 'other',
}
