export function isLinkExpired(timestamp: number): boolean {
  return timestamp > -1 && timestamp * 1000 < new Date().getTime();
}

export function getLinkExpirationDate(url: string): number {
  /**
   * Note: lookbehind not supported on safari (<=)
   * https://stackoverflow.com/a/58460583/3650404
   */
  let expTimestamps = url?.match(/Expires=(.*?)&/);
  return parseInt(expTimestamps ? expTimestamps[1] : '-1', 10);
}
