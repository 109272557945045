import * as _ from 'lodash';
import { BaseModel } from '../../base/base.model';
import {
  AppointmentResponse,
  Attendee,
  CardAppointment,
  CardBase,
  CardChat,
  CardDraft,
  CardMail,
  CardShared,
  CardTemplate,
  CommentBase,
  Group,
  ListOfResourcesOfAttendee,
  ListOfResourcesOfCommentChat,
  ListOfResourcesOfCommentMail,
  ListOfResourcesOfCommentTemplate,
  ListOfResourcesOfContactBase,
  ListOfResourcesOfInteger,
  ListOfResourcesOfString,
  ListOfResourcesOfTag,
  ListOfTags,
  OnlineMeeting,
  ResourceBase,
  SharedTagBase,
  TagType,
  ViewEnum,
} from '@shared/api/api-loop/models';
import { CollectionGroupName } from '@shared/models/constants/collection.names';
import { CommentBaseModel, CommentMailModel, CommentModel, CommentSnippet } from '../../comment/comment.model';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { Tag } from '@shared/api/api-loop/models/tag';
import { ListOfTagsModel, TagLabelModel } from '../../tag.model';
import { ContactBaseModel, ContactModel, GroupModel, UserModel } from '../../contact/contact.model';
import { FileModel } from '../../file.model';
import { SharedTagAssigneeModel, SharedTagLabelModel, StaticSharedTagIds } from '../../shared-tag/shared-tag.model';
import { ViewsFilter } from '../../../models/collection.model';
import { ConversationCardBaseModel } from '../conversation-card.model';
import { endOfDay, isSameDay, startOfDay, subDays } from 'date-fns';

export type CardModel =
  | CardMailModel
  | CardSharedModel
  | CardAppointmentModel
  | CardChatModel
  | CardTemplateModel
  | CardDraftModel;

export abstract class CardBaseModel extends ConversationCardBaseModel implements CardBase {
  static collectionName: CollectionGroupName = CollectionGroupName.Card;

  created: string;
  // Only for shared cards and not fully supported from BE. DON'T USE
  author: UserModel;
  shareList?: ListOfResourcesOfContactBase;
  tags?: ListOfTags;
  sharedTags?: ListOfTags;

  _ex: CardExtraData;
  _ui?: CardViewData;

  static create(doc: CardBase): CardModel {
    if (!doc || !doc.$type) {
      throw new Error(`Invalid $type given ${JSON.stringify(doc)}`);
    }
    if (doc instanceof CardBaseModel) {
      return doc;
    }

    switch (doc.$type) {
      case CardMailModel.type:
        return new CardMailModel(doc);
      case CardSharedModel.type:
        return new CardSharedModel(doc);
      case CardChatModel.type:
        return new CardChatModel(doc);
      case CardAppointmentModel.type:
        return new CardAppointmentModel(doc);
      case CardTemplateModel.type:
        return new CardTemplateModel(doc);
      case CardDraftModel.type:
        return new CardDraftModel(doc);
      default:
        break;
    }
  }

  static createList(docs: CardBase[]): CardModel[] {
    return _.map(docs, doc => CardBaseModel.create(doc));
  }

  static isSupported(card: CardBase): boolean {
    if (!card) {
      return false;
    }

    let model = CardBaseModel.create(card);

    if (_.isNil(model)) {
      return false;
    }
    /**
     * FOURTH-4265
     * Filter out multi-contact cards
     */
    if (model instanceof CardChatModel && _.size(model.shareList.resources) > 2) {
      return false;
    }

    return true;
  }

  static buildFromComments(comments: CommentModel[]): CardModel {
    if (_.isEmpty(comments)) {
      throw new Error('Comments cannot be empty');
    }

    let comment = _.first(comments);

    // Build shareList
    let contacts;
    if (comment instanceof CommentMailModel) {
      let group = _.find(comment.to.resources, { $type: GroupModel.type });
      if (group) {
        // Group
        contacts = [group];
      } else {
        // Senders
        let authors = _.map(comments, comment => comment.author);
        contacts = _.uniqBy(authors, 'id');
      }
    } else {
      let group = _.find(comment.shareList.resources, { $type: GroupModel.type });
      if (group) {
        // Group
        contacts = [group];
      } else {
        // ShareList
        contacts = comment.shareList.resources;
      }
    }

    let card = CardBaseModel.create(comment.parent);
    card.shareList = BaseModel.createListOfResources(contacts);
    card.comments = BaseModel.createListOfResources(comments);

    return card;
  }

  static isEqual(a: CardModel, b: CardModel): boolean {
    if (!a || !b) {
      return false;
    }

    // id
    if (BaseModel.isEqual(a, b)) {
      return true;
    }

    // OLD ID
    if (CardBaseModel.isEqualByOldId(a, b)) {
      return true;
    }

    // LAST COMMENT
    if (CardBaseModel.isEqualByLastCommentId(a, b)) {
      return true;
    }

    return false;
  }

  private static isEqualByOldId(a: CardModel, b: CardModel): boolean {
    if (a._ex.oldId || b._ex.oldId) {
      return (a._id && a._id === b._ex.oldId) || (b._id && b._id === a._ex.oldId);
    }

    return false;
  }

  private static isEqualByLastCommentId(a: CardModel, b: CardModel): boolean {
    // only on ChatCard as they are the only one left that don't preserve clientId when synced
    if (a._ex.lastCommentSnippet && b._ex.lastCommentSnippet) {
      return (
        a._ex.lastCommentSnippet._id === b._ex.lastCommentSnippet._id &&
        a instanceof CardChatModel &&
        b instanceof CardChatModel
      );
    }

    return false;
  }

  static isEqualByFirstComment(a: CardModel, b: CardModel): boolean {
    if (!a || !b) {
      return false;
    }

    let commentA = a.getComments()[0];
    let commentB = b.getComments()[0];

    // Comment clientId
    if (commentA.clientId && commentB.clientId && commentA.clientId === commentB.clientId) {
      return true;
    }

    // Comment id
    return CommentBaseModel.isEqual(CommentBaseModel.create(commentA), CommentBaseModel.create(commentB));
  }

  static mergeCardListByFirstComment(cardListA: CardModel[], cardListB: CardModel[]): CardModel[] {
    return _.unionWith(cardListA, cardListB, CardBaseModel.isEqualByFirstComment);
  }

  static getCommentsFromCards(cards: CardModel[]): CommentModel[] {
    let comments: CommentBase[] = _.flatten(_.map(cards, card => card.getComments()));
    return CommentBaseModel.createList(comments);
  }

  static getBackendIds(cards: CardBase[]): string[] {
    return _.map(cards, card => card.id);
  }

  static getSnapshotOrSourceCardIds(cards: CardBase[]): string[] {
    return cards
      .filter((card: CardBase) => card?.$type === CardSharedModel.type)
      .filter((card: CardShared) => {
        if (card.snapshotResource?.id) {
          return true;
        }

        return card.sourceResource?.id && card.isLive;
      })
      .map((card: CardShared) => {
        let linkedCard: ResourceBase;
        if (card.snapshotResource) {
          linkedCard = card.snapshotResource;
        } else {
          linkedCard =
            card.sourceResource && card.sourceResource.id && card.isLive ? card.sourceResource : card.snapshotResource;
        }

        return linkedCard.id || linkedCard['_id'];
      });
  }

  isDraftOnLocalCard(): boolean {
    return this._ex.hasDraft && !this.id;
  }

  setDraftState(hasDraft: boolean) {
    return (this._ex.hasDraft = hasDraft);
  }

  isMeToMeCard(currentUserId: string): boolean {
    let shareListIds = _.map(this.getShareList(), user => user.id);
    return shareListIds.length === 1 && shareListIds[0] === currentUserId;
  }

  isPrimaryViewCard() {
    return (
      this.isMyLoopInboxCard() &&
      this.showCardForView(ViewsFilter.INBOX, ViewEnum.LOOP_INBOX, true, false, undefined, true)
    );
  }

  /**
   * [!] NOTE [!]
   * Criteria in this method MUST follow specifications
   * Docs: https://github.com/4thOffice/Product-and-staff/wiki/MyLoopInbox
   * Any changes must be discussed with the team/product first
   */
  isMyLoopInboxCard(sentView: boolean = false): boolean {
    // Is not snapshot or copy
    if (this instanceof CardMailModel && this.isSnapshot) {
      return false;
    }

    // Is followed
    if ([SubscriptionState.UNSUBSCRIBED].includes(this._ex.subscriptionState)) {
      return false;
    }

    // Has live loop
    if (this._ex.hasLiveLoop && !sentView) {
      return false;
    }

    return true;
  }

  /**
   * [!] NOTE [!]
   * Criteria in this method MUST follow specifications
   * Docs: https://github.com/4thOffice/Product-and-staff/wiki/Channels
   * Any changes must be discussed with the team/product first
   */
  isChannelCard(channelContactId: string, isCurrentUserChannel: boolean): boolean {
    // Only CardShared and CardMail
    if (!(this instanceof CardSharedModel || this instanceof CardMailModel)) {
      return false;
    }

    // Is not snapshot or copy
    if (this instanceof CardMailModel && this.isSnapshot) {
      return false;
    }

    // Should belong to channel
    if (!this._ex.channelIds.includes(channelContactId)) {
      return false;
    }

    // Filter out card with live loops in this channel
    if (this._ex.liveLoopChannelIds.includes(channelContactId)) {
      return false;
    }

    // Show me to me in personal channel
    if (isCurrentUserChannel && !this.isMeToMeCard(channelContactId)) {
      return false;
    }

    return true;
  }

  /**
   * [!] NOTE [!]
   * Criteria in this method MUST follow specifications
   * Docs: https://github.com/4thOffice/Product-and-staff/wiki/Personal-Inbox-view
   * Any changes must be discussed with the team/product first
   */
  isPersonalInboxCard(currentUserId: string): boolean {
    // Must be CardMail or CardAppointmentModel
    if (!(this instanceof CardMailModel)) {
      return false;
    }

    // Is not snapshot or copy
    if ((<CardMailModel>this).isSnapshot) {
      return false;
    }

    // Must not be group card
    if (this._ex.isGroupCard) {
      return false;
    }

    // Always show me to me
    if (this.isMeToMeCard(currentUserId)) {
      return true;
    }

    return this.hasCommentTagId(TagType.INBOX);
  }

  isAllSharedInboxesCard(sharedInboxIds: string[]): boolean {
    // Is not snapshot or copy
    if (!(this instanceof CardSharedModel)) {
      return false;
    }

    // Should belong to channel
    if (_.isEmpty(_.intersection(this._ex.channelIds, sharedInboxIds))) {
      return false;
    }

    return true;
  }

  /**
   * FOLLOW/UNFOLLOW logic should match the one in specifications defined at:
   * https://github.com/4thOffice/Product-and-staff/wiki/Followed
   */
  isFollowed() {
    return (
      this.hasTagId(TagType.FOLLOW) ||
      (!this.hasSharedTagId(StaticSharedTagIds.UNFOLLOW_ID) && !this.hasTagId(TagType.UNFOLLOW))
    );
  }

  isSubscribed() {
    return this.isFollowed() && !this.hasTagId(TagType.MUTED);
  }

  isMuted() {
    return this.isFollowed() && this.hasTagId(TagType.MUTED);
  }

  static calculateSubscriptionState(card: CardModel): SubscriptionState {
    return !card.isFollowed()
      ? SubscriptionState.UNSUBSCRIBED
      : !card.isMuted()
        ? SubscriptionState.SUBSCRIBED
        : SubscriptionState.MUTED;
  }

  fitsAssignedView(isPrivateTriageDisabledInMyLoopInbox: boolean): boolean {
    if (!this.hasSharedTagAssignee()) {
      return false;
    }
    if (this.hasPendingSnooze()) {
      return false;
    }

    let passDeletedFilter;
    let passArchivedFilter;

    if (isPrivateTriageDisabledInMyLoopInbox) {
      passDeletedFilter = !this._ex.isDeleted && !this._ex.isSharedDeleted;
      passArchivedFilter = !this._ex.isArchived && !this._ex.isSharedArchived;
    } else {
      passDeletedFilter = !this._ex.isDeleted;
      passArchivedFilter = !this._ex.isArchived;
    }

    return passDeletedFilter && passArchivedFilter;
  }

  fitsInboxView(
    includeChannelCards: boolean,
    isSharedInboxChannel: boolean,
    excludeMuted: boolean,
    showSent: boolean,
    isPrivateTriageDisabledInMyLoopInbox?: boolean,
    currentUserId?: string,
  ): boolean {
    let passDeletedFilter;
    let passArchivedFilter;

    let inboxTagCondition = this.hasCommentTagId(TagType.INBOX) || (currentUserId && this.isMeToMeCard(currentUserId));

    if (showSent) {
      inboxTagCondition = inboxTagCondition || this.hasCommentTagId(TagType.SENT);
    }

    if (isPrivateTriageDisabledInMyLoopInbox) {
      passDeletedFilter = !this._ex.isDeleted && !this._ex.isSharedDeleted;
      passArchivedFilter = !this._ex.isArchived && !this._ex.isSharedArchived;
    } else {
      passDeletedFilter = isSharedInboxChannel ? !this._ex.isSharedDeleted : !this._ex.isDeleted;

      passArchivedFilter = isSharedInboxChannel ? !this._ex.isSharedArchived : !this._ex.isArchived;
    }

    let passDraftFilter = !(this instanceof CardDraftModel);

    let passesMutedFilter = excludeMuted ? !this.hasTagId(TagType.MUTED) : true;

    let passesSnoozeFilter = isSharedInboxChannel || !this.hasPendingSnooze();

    if (includeChannelCards) {
      return (
        (inboxTagCondition || (this instanceof CardSharedModel && this._ex.isGroupCard)) &&
        passDeletedFilter &&
        passArchivedFilter &&
        passesMutedFilter &&
        passesSnoozeFilter &&
        passDraftFilter
      );
    }

    return (
      inboxTagCondition &&
      passDeletedFilter &&
      passArchivedFilter &&
      passesMutedFilter &&
      passesSnoozeFilter &&
      passDraftFilter
    );
  }

  showCardForView(
    viewFilter: ViewsFilter,
    primaryView: ViewEnum,
    includeChannelCards?: boolean,
    isSharedInboxChannel?: boolean,
    boardId?: string,
    excludeMuted?: boolean,
    isPrivateTriageDisabledInMyLoopInbox?: boolean,
    showSent: boolean = true,
    currentUserId?: string, // Only for myLoopInbox and personal inbox, to check if isMeToMeCard
  ): boolean {
    // Cards with pending snooze are shown only in snooze, all and sharedInbox
    if (
      !isSharedInboxChannel &&
      this.hasPendingSnooze() &&
      ![ViewsFilter.SNOOZE, ViewsFilter.ALL].includes(viewFilter)
    ) {
      return false;
    }

    switch (viewFilter) {
      case ViewsFilter.ALL:
        return this.fitsAllMessagesView(isPrivateTriageDisabledInMyLoopInbox);
      case ViewsFilter.ARCHIVED:
        if (isPrivateTriageDisabledInMyLoopInbox) {
          return this._ex.isArchived || this._ex.isSharedArchived;
        }
        return this._ex.isArchived || (isSharedInboxChannel && this._ex.isSharedArchived);
      case ViewsFilter.DELETED:
        if (isPrivateTriageDisabledInMyLoopInbox) {
          return this._ex.isDeleted || this._ex.isSharedDeleted;
        }
        return this._ex.isDeleted || (isSharedInboxChannel && this._ex.isSharedDeleted);
      case ViewsFilter.DRAFTS:
        return this.fitsDraftsView(isPrivateTriageDisabledInMyLoopInbox);
      case ViewsFilter.INBOX:
        return this.fitsInboxView(
          includeChannelCards,
          isSharedInboxChannel,
          excludeMuted,
          showSent,
          isPrivateTriageDisabledInMyLoopInbox,
          currentUserId,
        );
      case ViewsFilter.SENT:
        return isSharedInboxChannel ? this._ex.isSharedSent : this.hasCommentTagId(TagType.SENT);
      case ViewsFilter.ASSIGNED:
        return this.fitsAssignedView(isPrivateTriageDisabledInMyLoopInbox);
      case ViewsFilter.SPAM:
        return this.hasSpamSharedTag();
      case ViewsFilter.STARRED:
        return this._ex.isStarred;
      case ViewsFilter.SNOOZE:
        return this.hasPendingSnooze();
      case ViewsFilter.BOARD:
        // Gmail has "All Email" folder where boardId is undefined
        return !boardId || this.hasCommentTagId(boardId);
      case ViewsFilter.MUTED:
        return this.isMuted();
      default:
        return false;
    }
  }

  private fitsAllMessagesView(isPrivateTriageDisabledInMyLoopInbox: boolean): boolean {
    let isDeleted = (isPrivateTriageDisabledInMyLoopInbox && this._ex.isSharedDeleted) || this._ex.isDeleted;
    let isDraftCardWithParentCard = this instanceof CardDraftModel && this.parentCard;

    if (isDeleted || isDraftCardWithParentCard) {
      return false;
    }

    return true;
  }

  private fitsDraftsView(isPrivateTriageDisabledInMyLoopInbox: boolean): boolean {
    let isDeleted = (isPrivateTriageDisabledInMyLoopInbox && this._ex.isSharedDeleted) || this._ex.isDeleted;
    let isDraftCardWithoutParent = this instanceof CardDraftModel && !this.parentCard;
    let nonDraftCardWithDraft = !(this instanceof CardDraftModel) && this._ex.hasDraft;

    return !isDeleted && (isDraftCardWithoutParent || nonDraftCardWithDraft);
  }

  clone<T extends BaseModel>(): T {
    // if this is not enough, then rather call super.cloneDeep
    let clone: CardModel = super.clone();
    clone.comments = _.clone(clone.comments);
    return <any>clone;
  }

  toObject(): CardBase {
    this.removeComments();

    return super.toObject();
  }

  removeComments() {
    let card: CardModel = this;

    if (card.comments) {
      delete card.comments.resources;
    }
  }

  hasComments(): boolean {
    let card: CardModel = this;

    return card.comments && !_.isEmpty(card.comments.resources);
  }

  hasAllComments(): boolean {
    let card: CardModel = this;

    return this.hasComments() && card.comments.resources.length >= card.comments.totalSize;
  }

  getComments(): CommentBase[] {
    let card: CardModel = this;
    return this.getResources(card.comments);
  }

  getCommentsForFoldering(): CommentBase[] {
    return CardBaseModel.filterCommentsForFoldering(CommentBaseModel.createList(this.getComments()));
  }

  static filterCommentsForFoldering(comments: CommentModel[]): CommentModel[] {
    return _.filter(comments, comment => {
      return !comment.isFolderingActionUpdate;
    });
  }

  getSortedComments(): CommentBase[] {
    return BaseModel.sortListByCreated(this.getComments());
  }

  getLastComment(): CommentBase {
    let comments = this.getComments();

    if (_.isEmpty(comments)) {
      return undefined;
    }

    return comments[comments.length - 1];
  }

  getCommentsTotalSize(): number {
    let card: CardModel = this;
    return card.comments?.totalSize || 0;
  }

  getShareList(): ContactBase[] {
    if (this instanceof CardDraftModel) {
      return this.getResources((<CardDraftModel>this).shareList);
    } else {
      return this.getResources(this.shareList);
    }
  }

  getAllContacts(): ContactBase[] {
    let shareList = this.getShareList();
    let author = <ContactModel>this.author;

    let lastCommentSnippetAuthor =
      this._ex && this._ex.lastCommentSnippet ? this._ex.lastCommentSnippet.author : undefined;

    let emailSharelistUsers = this._ex && this._ex.emailSharelistUsers ? this._ex.emailSharelistUsers : undefined;

    let emailAuthorUsers = this._ex && this._ex.emailAuthorUsers ? this._ex.emailAuthorUsers : undefined;

    let joined = _.unionBy(
      shareList,
      [author],
      [lastCommentSnippetAuthor],
      emailSharelistUsers,
      emailAuthorUsers,
      'id',
    );

    // Remove undefined entries
    return _.filter(joined, contact => contact !== undefined);
  }

  hasCommentTagId(id: string): boolean {
    if (!this._ex) {
      return false;
    }

    return _.some(this._ex.tags, { id: id });
  }

  hasCommentTags(): boolean {
    if (!this._ex) {
      return false;
    }

    return !_.isEmpty(this._ex.tags);
  }

  hasSpamSharedTag(): boolean {
    return this.hasSharedTags() && _.some(this.sharedTags.tags.resources, { id: StaticSharedTagIds.SPAM_ID });
  }

  hasSharedTagAssignee(): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, {
      $type: SharedTagAssigneeModel.type,
    });
  }

  hasSharedTagName(name: string): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, { name: name });
  }

  getSharedDraftModifiedDate(): string {
    if (this instanceof CardMailModel) {
      return this.draftResource?.modifiedDate;
    }

    if (this instanceof CardSharedModel) {
      return this.sourceResource?.draftResource?.modifiedDate || this.snapshotResource?.draftResource?.modifiedDate;
    }

    if (this instanceof CardDraftModel) {
      return this.modifiedDate;
    }

    return undefined;
  }

  isAppointment(): boolean {
    return this instanceof CardAppointmentModel || this.hasAppointmentLink();
  }

  hasAppointmentLink(): boolean {
    return (
      (this instanceof CardMailModel || this instanceof CardSharedModel) &&
      this.appointmentLink &&
      !_.isNil(this.appointmentLink.id)
    );
  }

  getAppointmentLinkId(): string {
    return (this instanceof CardMailModel || this instanceof CardSharedModel) && this.appointmentLink
      ? this.appointmentLink.id
      : undefined;
  }

  findGroupInShareList(): Group {
    if (this._ex && !this._ex.isGroupCard) {
      return undefined;
    }
    return this.getShareList().find(contact => contact.$type === GroupModel.type);
  }

  isInternalCard(): boolean {
    return (
      this.$type === CardChatModel.type || // is chat card
      this.$type === CardSharedModel.type || // is loopin
      (this.$type === CardMailModel.type && this._ex && this._ex.isGroupCard)
    ); // is team mail
  }

  isExternalCard(): boolean {
    return !this.isInternalCard();
  }

  sharedTagsToReducedForm() {
    let reducedList = _.map(this.getSharedTags(), (sharedTag: SharedTagBase) => {
      ContactBaseModel.toReducedForm(sharedTag);
      return sharedTag;
    });

    if (!_.isEmpty(reducedList)) {
      this.sharedTags.tags.resources = reducedList;
    }
  }

  contactsToReducedForm() {
    for (let prop in this) {
      switch (prop) {
        case 'attendees':
          _.forEach(this[prop]['resources'], contact => {
            CardAppointmentModel.toReducedForm(contact);
          });
          break;
        case 'shareList':
          _.forEach(this[prop]['resources'], contact => {
            ContactBaseModel.toReducedForm(contact);
          });
          break;
        case 'author':
          ContactBaseModel.toReducedForm(this.author);
          break;
        default:
          break;
      }
    }
  }

  populateWithContacts(contacts: ContactModel[]) {
    for (let prop in this) {
      switch (prop) {
        case 'attendees':
        case 'shareList':
          let populatedList = _.map(this[prop]['resources'], contact => {
            // If contact is not synced, return current
            if (!contact.id) {
              return contact;
            }
            let fullContact = Object.assign(
              {},
              _.find(contacts, c => c.id === contact.id),
            );

            // Merge preserved fields to full contact (needed for CardAppointmentModel)
            return Object.assign({}, fullContact, contact);
          });
          this[prop]['resources'] = populatedList;
          break;
        case 'author':
          // If contact is not synced, return current
          if (!this.author.id) {
            break;
          }
          this.author = Object.assign({}, <UserModel>_.find(contacts, c => c.id === this.author.id));
          break;
        default:
          break;
      }
    }

    this.populateExtraDataWithContacts(contacts);
  }

  populateExtraDataWithContacts(contacts: ContactModel[]) {
    for (let prop in this._ex) {
      switch (prop) {
        case 'lastCommentSnippet':
          if (this._ex[prop]) {
            let author = this._ex[prop]['author'];
            let authorId = author?.id;
            if (author && author.id) {
              this._ex[prop]['author'] = _.find(contacts, c => c.id === authorId);
            }
          }
          break;
        case 'emailAuthorUsers':
        case 'emailSharelistUsers':
          if (this._ex[prop]) {
            let populatedList = _.map(this._ex[prop], contact => {
              // If contact is not synced, return current
              if (!contact?.id) {
                return contact;
              }
              let fullContact = _.find(contacts, c => c.id === contact.id);

              // Merge preserved fields to full contact (needed for CardAppointmentModel)
              return Object.assign({}, fullContact, contact);
            });
            this._ex[prop] = populatedList;
          }
          break;
        default:
          break;
      }
    }
  }

  getSearchableContent(): string[] {
    ////////////////////
    // Card properties
    ////////////////////
    let contactsAsModels = ContactBaseModel.createList(this.getAllContacts());
    let cardContacts = _.flatMap(contactsAsModels, (contact: ContactBaseModel) => {
      return contact.getSearchableContent();
    });

    let cardName = this.name;

    ///////////////////////
    // Comment properties
    ///////////////////////
    let commentsAsModels = CommentBaseModel.createList(this.getComments());
    let commentContents = _.flatMap(commentsAsModels, (comment: CommentBaseModel) => {
      return comment.getSearchableContent();
    });

    // Combine and return
    return _.filter([cardName, ...cardContacts, ...commentContents], item => !_.isEmpty(item));
  }

  getContactForChannelTag(): ContactModel {
    let shareList =
      this instanceof CardDraftModel ? (<CardDraftModel>this).shareList?.resources || [] : this.shareList.resources;

    return shareList.find((contact: ContactBase) => contact.$type === GroupModel.type) as GroupModel;
  }
}

export class CardMailModel extends CardBaseModel implements CardMail {
  static type: string = 'CardMail';
  static mutableProperties: string[] = ['privateDraftResource', 'draftResource'];

  readonly $type: string = CardMailModel.type;

  comments?: ListOfResourcesOfCommentMail;
  copiedCardIds?: ListOfResourcesOfString;
  appointmentLink?: CardAppointment;
  isSnapshot?: boolean;
  loopInResource?: CardShared;
  draftResource?: CardDraft;
  privateDraftResource?: CardDraft;

  // TODO: remove this flag as it's no longer supported by BE
  isForward: boolean;

  constructor(data?: CardMail) {
    super(data);
  }

  hasVirtualUnreads(): boolean {
    let comments = CommentBaseModel.createList(this.getComments());
    return _.some(comments, (comment: CommentBaseModel) => comment.hasTagId(TagType.UNREAD_VIRTUAL));
  }

  toObject(): CardBase {
    if (this.appointmentLink) {
      this.appointmentLink = CardBaseModel.buildAsReference(<CardAppointmentModel>this.appointmentLink);
    }

    if (this.draftResource) {
      this.draftResource = CardBaseModel.buildAsReference(<CardDraftModel>this.draftResource);
    }

    if (this.privateDraftResource) {
      this.privateDraftResource = CardBaseModel.buildAsReference(<CardDraftModel>this.privateDraftResource);
    }

    return super.toObject();
  }

  hasCopiedCardIds(): boolean {
    return this.copiedCardIds && !_.isEmpty(this.copiedCardIds.resources);
  }

  getDraftCardIds(fallbackToClientIds?: boolean): string[] {
    let draftIds = [];

    if (this.draftResource) {
      let id = this.draftResource.id || (fallbackToClientIds && this.draftResource.clientId);
      if (id) {
        draftIds.push(id);
      }
    }

    if (this.privateDraftResource) {
      let id = this.privateDraftResource.id || (fallbackToClientIds && this.privateDraftResource.clientId);
      if (id) {
        draftIds.push(id);
      }
    }

    return draftIds;
  }

  removeLinkToDraftCard(draftCardId: string) {
    if ([this.draftResource?.id, this.draftResource?.clientId].includes(draftCardId)) {
      this.removeLinkToDraftCardByType('shared');
    }

    if ([this.privateDraftResource?.id, this.privateDraftResource?.clientId].includes(draftCardId)) {
      this.removeLinkToDraftCardByType('private');
    }
  }

  removeLinkToDraftCardByType(draftType: 'private' | 'shared') {
    if (draftType === 'shared') {
      delete this.draftResource;
    }

    if (draftType === 'private') {
      delete this.privateDraftResource;
    }
  }

  hasPrivateDraft(): boolean {
    return !_.isEmpty(this.privateDraftResource);
  }

  hasSharedDraft(): boolean {
    return !_.isEmpty(this.draftResource);
  }
}

export class CardSharedModel extends CardBaseModel implements CardShared {
  static type: string = 'CardShared';

  readonly $type: string = CardSharedModel.type;

  comments?: ListOfResourcesOfCommentChat;
  appointmentLink?: CardAppointment;
  sourceResource?: CardMail;
  snapshotResource?: CardMail;
  isLive?: boolean;

  constructor(data?: CardShared) {
    super(data);

    this._synced = this.id && (!!this.snapshotResource || !!this.sourceResource);
    this.setLinkedCardId(this.sourceResource);
    this.setLinkedCardId(this.snapshotResource);
  }

  static buildAsReference<T>(model: CardSharedModel): T {
    let ref = super.buildAsReference<CardShared>(model);
    ref.isLive = model.isLive;
    return <any>ref;
  }

  toObject(): CardBase {
    if (this.sourceResource) {
      this.sourceResource = CardBaseModel.buildFromBaseAsReference(this.sourceResource);
    }

    if (this.snapshotResource) {
      this.snapshotResource = CardBaseModel.buildFromBaseAsReference(this.snapshotResource);
    }

    return super.toObject();
  }

  private setLinkedCardId(linkedCard: CardMail) {
    if (!linkedCard) {
      return;
    }
    (<CardMailModel>linkedCard)._id = this._getId(linkedCard);
  }

  hasSnapshotComments(): boolean {
    if (!this.snapshotResource) {
      return false;
    }

    return this.snapshotResource.comments && !_.isEmpty(this.snapshotResource.comments.resources);
  }

  hasSourceComments(): boolean {
    if (!this.sourceResource) {
      return false;
    }

    return this.sourceResource.comments && !_.isEmpty(this.sourceResource.comments.resources);
  }

  hasExtraComments() {
    return this.hasSourceComments() || this.hasSnapshotComments();
  }

  getSnapshotAndSourceResourceId(): string[] {
    let ids = [];

    if (this.snapshotResource) {
      ids.push(this.snapshotResource.id);
    }

    if (this.sourceResource) {
      ids.push(this.sourceResource.id);
    }

    return ids;
  }

  getSnapshotId(): string {
    if (!this.snapshotResource) {
      return undefined;
    }

    return this.snapshotResource.id;
  }

  getSortedSnapshotComments(): CommentBase[] {
    if (!this.hasSnapshotComments()) {
      return [];
    }

    return BaseModel.sortListByCreated(this.getResources(this.snapshotResource.comments));
  }

  getSortedSourceComments(): CommentBase[] {
    if (!this.hasSourceComments()) {
      return [];
    }

    return BaseModel.sortListByCreated(this.getResources(this.sourceResource.comments));
  }

  extraCommentsCardId(): string {
    if (this.isLive && this.sourceResource && this.sourceResource.id) {
      return this.sourceResource.id;
    } else {
      return this.snapshotResource ? this.snapshotResource.id : undefined;
    }
  }

  getSortedExtraComments(): CommentBase[] {
    if (this.hasSnapshotComments()) {
      return this.getSortedSnapshotComments();
    }

    return this.getSortedSourceComments();
  }

  getSearchableContent(): string[] {
    let common = super.getSearchableContent();
    let sourceAndSnapshotResourceContent = [];

    /////////////////////////////
    // SourceResource properties
    /////////////////////////////
    if (this.sourceResource) {
      let sourceResourceAsModel = CardBaseModel.create(this.sourceResource);
      sourceAndSnapshotResourceContent.push(...sourceResourceAsModel.getSearchableContent());
    }

    ///////////////////////////////
    // snapshotResource properties
    ///////////////////////////////
    if (this.snapshotResource) {
      let snapshotResourceAsModel = CardBaseModel.create(this.snapshotResource);
      sourceAndSnapshotResourceContent.push(...snapshotResourceAsModel.getSearchableContent());
    }

    // Combine and return
    return _.filter([...common, ...sourceAndSnapshotResourceContent], item => !_.isEmpty(item));
  }

  isSharedWithOthers(forUserId: string): boolean {
    return _.filter(this.getShareList(), (contact: ContactBase) => contact.id !== forUserId)?.length > 0;
  }
}

export class CardTemplateModel extends CardBaseModel implements CardTemplate {
  static type: string = 'CardTemplate';

  readonly $type: string = CardTemplateModel.type;

  templates?: ListOfResourcesOfCommentTemplate;
  comments?: ListOfResourcesOfCommentMail;
}

export class CardAppointmentModel extends CardMailModel implements CardAppointment {
  static type: string = 'CardAppointment';

  readonly $type: string = CardAppointmentModel.type;

  attendees: ListOfResourcesOfAttendee;
  location: string;
  startTime: string;
  endTime: string;
  isAllDay: boolean;
  isCancelled: boolean;
  isOccurrence: boolean;
  hasRecurrence: boolean;
  response: AppointmentResponse; // NOTE: revision od response should match the one on server (when we make updates)
  recurringMasterResponse: AppointmentResponse;
  sharedContext: Group;
  remindersInMinutes: ListOfResourcesOfInteger;
  onlineMeeting: OnlineMeeting;

  startTimeDate?: Date;
  endTimeDate?: Date;
  colour?: string;

  contextId: string; // used to send to API

  _ui?: CardAppointmentViewData;

  constructor(data?: CardAppointment) {
    super(data);
    if (data?.startTime) {
      this.startTimeDate = new Date(data.startTime);
      if (this.isAllDay) {
        this.startTimeDate = startOfDay(this.startTimeDate);
      }
    }
    if (data?.endTime) {
      this.endTimeDate = new Date(data.endTime);
      if (this.isAllDay) {
        this.endTimeDate = endOfDay(subDays(this.endTimeDate, 1));
      }
    }
  }

  static createList(docs: CardAppointment[]): CardAppointmentModel[] {
    return _.map(docs, doc => new CardAppointmentModel(doc));
  }

  get is_single_day_event(): boolean {
    return isSameDay(this.startTimeDate, this.endTimeDate);
  }

  getAllContacts(): ContactBase[] {
    let shareList = super.getAllContacts();
    let attendees = this.getResources(this.attendees);

    let joined = _.unionBy(shareList, attendees, 'id');

    // Remove undefined entries
    return _.filter(joined, contact => contact !== undefined);
  }

  // Delete all properties except 'id' and '$type'.
  // This is used for striping object's child elements
  // to reduced form and using join when getting from DB.
  static toReducedForm(model: any) {
    // Don't reduce models that are not synced
    if (!model.id) {
      return;
    }
    for (let prop in model) {
      if (
        prop !== 'id' &&
        prop !== '$type' &&
        prop !== 'statusUpdatedAt' && // Preserve Attendee specific
        prop !== 'isOptional' && // fields and merge on populate
        prop !== 'status'
      ) {
        delete model[prop];
      }
    }
  }
}

export class CardChatModel extends CardBaseModel implements CardChat {
  static type: string = 'CardChat';

  readonly $type: string = CardChatModel.type;

  comments?: ListOfResourcesOfCommentChat;

  constructor(data?: CardChat) {
    super(data);
  }
}

export class CardDraftModel extends CardBaseModel implements CardDraft {
  static type: string = 'CardDraft';

  readonly $type: string = CardDraftModel.type;

  parentCard?: ResourceBase;
  parentComment?: ResourceBase;
  additionalShareList?: ListOfResourcesOfContactBase;
  commentDraft?: ResourceBase;
  comments?: ListOfResourcesOfCommentChat;
  modifiedDate: string;

  constructor(data?: any) {
    super(data);
  }

  setSharedTag(sharedTag: SharedTagBase) {
    if (this.hasSharedTagId(sharedTag.id)) {
      return;
    }

    // Add shared tag
    if (this.sharedTags && this.sharedTags.tags) {
      this.sharedTags.tags.resources.push(sharedTag);
      this.sharedTags.tags.size += 1;
      this.sharedTags.revision = (parseInt(this.sharedTags.revision, 10) + 1).toString();
    } else {
      let parent: ResourceBase = this.toResourceBase();

      let resources: Tag[] = [sharedTag];

      let tags: ListOfResourcesOfTag = {
        resources: resources,
        offset: 0,
        size: 1,
        totalSize: 1,
      };

      let sharedTags: ListOfTags = {
        $type: ListOfTagsModel.type,
        revision: '1',
        tags: tags,
        parent: parent,
      };

      this.sharedTags = sharedTags;
    }
  }

  getDraftComment(): ResourceBase {
    return this.commentDraft;
  }

  toObject(): CardBase {
    if (this.commentDraft) {
      this.commentDraft = CommentBaseModel.buildFromBaseAsReference(this.commentDraft);
    }

    return super.toObject();
  }

  hasFullyPopulatedCommentDraft(): boolean {
    return !_.isEmpty(this.commentDraft?.['author']);
  }

  isDiscarded(): boolean {
    return this.hasSharedTagId(StaticSharedTagIds.DELETED_ID);
  }

  isSent(): boolean {
    return !_.isEmpty(this.parentComment);
  }
}

export interface CardExtraData {
  // Flags
  isArchived: boolean;
  isSharedArchived: boolean;
  isDeleted: boolean;
  isSharedDeleted: boolean;
  isStarred: boolean;
  isGroupCard: boolean;
  isGroupUnaccessible: boolean;
  isUnread: boolean;
  isSent: boolean;
  isSharedSent: boolean;
  isSourceUnread: boolean;
  isLastCommentChat: boolean;
  hasInaccessibleGroup: boolean;
  hasUnsentComments: boolean;
  hasFailedToSendComments: boolean;
  hasDraft: boolean;
  hasPendingSnooze: boolean;
  commentsSynchronized: boolean;
  newDomainUserOnly: boolean;

  // Last snippets
  lastSentCommentSnippet: CommentSnippet;
  lastCommentSnippet: CommentSnippet;

  // Last comments created dates
  lastSentCommentCreated: string;
  lastCommentCreated: string;

  // Date for sort order (combines snooze due date, last comment and sharedDraft modified date)
  sortDate: string;
  snoozeDueDate: string;

  // Counts
  visibleAttachmentCount: number;
  sourceUnreadCount: number;
  countOfComments: number;
  unreadCount: number;

  // For show/hide in channels
  hasLiveLoop: boolean;
  subscriptionState: SubscriptionState;

  // For channel unread count.
  // If you are signed in with account that is also connected as SI and you send email from A
  // to that SI email, you will not see unread from A in A channel
  // More about: https://github.com/4thOffice/brisket/issues/7330
  hasLiveLoopInSameChannel: boolean;

  // Other
  shareList: ContactBase[];
  oldId?: string;
  senderIds: string[];
  replyToIds: string[];
  channelIds: string[];
  tags: Tag[];
  liveLoopChannelIds: string[];
  chatCardContactId: string;
  emailAuthorUsers: ContactBase[];
  emailSharelistUsers: ContactBase[];
  channelTag: ChannelTagData;
}

export enum SubscriptionState {
  UNSUBSCRIBED = 'unsubscribed',
  SUBSCRIBED = 'subscribed',
  MUTED = 'muted',
}

export interface CardViewData {
  attachments: FileModel[];
}

export interface CardAppointmentViewData extends CardViewData {
  month: string;
  day: string;
  multiday: boolean;
  duration: string;
  organiser: Attendee;
  invitees: Attendee[];
  currentUserStatus: string;
  localStartTime: string; // Do NOT use moment. When send via IPC, it cannot be serialized
  localEndTime: string; // Do NOT use moment. When send via IPC, it cannot be serialized
  declinedByCurrentUser: boolean;
  acceptResponses: string;
  tentativeResponses: string;
  declineResponses: string;
}

export interface ChannelTagData {
  displayName: string;
  contactId: string;
}

export enum ActionType {
  PERSONAL = 'PERSONAL',
  SHARED = 'SHARED',
}
