import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';

@Injectable()
export class TimerService {
  timer: Observable<number> = timer(2000, 10000);
  minuteTimer: Observable<number> = timer(60000, 5000);
  thirtyMinuteTimer: Observable<number> = timer(1800000, 600000);

  constructor() {}
}
