import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem, DropdownRouterLinkItem } from '@shared/ui/dropdown/interfaces/dropdown-item';

@Pipe({
  name: 'asDropdownRouterLinkItem',
})
export class AsDropdownRouterLinkItemPipe implements PipeTransform {
  transform(item: DropdownItem): DropdownRouterLinkItem {
    return item as DropdownRouterLinkItem;
  }
}
