import * as _ from 'lodash';
import { EMPTY, from, of, Subscription } from 'rxjs';
import { bufferTime, catchError, concatMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { EventUserStatus } from '@shared/api/api-loop/models';
import { OnDestroy } from '@angular/core';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { DataServiceShared } from '@shared/services/data/data.service';
import { SynchronizationService } from '@shared/synchronization/synchronization.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CommentMailModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { HubEventType } from '@dta/shared/models/event.models';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import {
  OpenDraftForEditData,
  PublishEventType
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';

@AutoUnsubscribe()
export class TriggerSubscriberService implements OnDestroy {
  //////////////////
  // Subscriptions
  //////////////////
  private contactOnlineStatusChangedSub: Subscription;
  private groupContactAfterSaveHookSub: Subscription;

  constructor(
    private _userEmail: string,
    private _dataService: DataServiceShared,
    private _syncService: SynchronizationService
  ) {
    this.subscribeToAll();
  }

  ngOnDestroy() {}

  private subscribeToAll() {
    this.subscribeToContactOnlineStatusChanges();
    this.subscribeToGroupContactAfterSave();
  }

  ////////////////////////
  // GROUP CONTACT CHANGE
  ////////////////////////
  private subscribeToGroupContactAfterSave() {
    this.groupContactAfterSaveHookSub?.unsubscribe();
    this.groupContactAfterSaveHookSub = this._dataService.ContactService.groupContactsAfterSaveHook$
      .pipe(
        mergeMap((groups: GroupModel[]) => {
          let tags = _.map(groups, (group: GroupModel) => group.tags);
          return this._dataService.CardService.updateCardsTagsAndPublish(this._userEmail, tags);
        }),
        catchError(err => {
          Logger.error(
            err,
            `Error in trigger service for group contact after save subscriber for user: ${this._userEmail}`,
            LogTag.SYNC
          );

          return of(undefined);
        })
      )
      .subscribe();
  }

  ///////////////////////////
  // CONTACT ACTIVITY STATUS
  ///////////////////////////
  private subscribeToContactOnlineStatusChanges() {
    this.contactOnlineStatusChangedSub?.unsubscribe();
    this.contactOnlineStatusChangedSub = this._syncService.eventHub
      .event([HubEventType.EventUserStatus])
      .pipe(
        bufferTime(5000), // Collect updates for 5 seconds
        filter((events: EventUserStatus[]) => {
          return !_.isEmpty(events);
        }),
        /**
         * Process events
         */
        concatMap((events: EventUserStatus[]) => {
          let users = _.map(events, (event: EventUserStatus) => event.user);
          return this._dataService.ContactService.updateUsersActivityAndPublish(this._userEmail, users);
        }),
        catchError(err => {
          Logger.customLog(
            `Error in trigger service for contact online status subscriber for user: ${this._userEmail}`,
            LogLevel.ERROR,
            LogTag.SYNC
          );
          return EMPTY;
        })
      )
      .subscribe();
  }
}
