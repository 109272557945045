export enum MarkAllReadView {
  AllSharedInboxes = 'All Shared Inboxes',
  MyLoopInbox = 'My Loop Inbox',
  PersonalInbox = 'Personal Inbox',
  Assigned = 'Assigned',
  Chats = 'Chats',
  Folder = 'Folder',
  Channel = 'Channel',
  Inbox = 'Inbox',
  Starred = 'Starred',
}
