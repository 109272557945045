import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { BaseModel } from '../../shared/models-api-loop/base/base.model';
import { DatabaseCollectionService, DatabasePagingOptions, DatabaseQuery } from '../database/database.service';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { from, Observable, of, throwError } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { ProcessType, StopWatch } from '../../shared/utils/stop-watch';
import { SearchFilterType } from '../../shared/models/search.model';
import { BaseDaoService, PagingParams } from '@shared/database/dao/base/base-dao.service';
import { DatabaseServiceWeb } from '../../../web/app/database/database.service.web';

@Injectable()
export abstract class BaseDaoServiceDta<T extends BaseModel, B extends ResourceBase> extends BaseDaoService<T, B> {
  protected abstract collection(forUserEmail: string): Observable<DatabaseCollectionService>;

  saveAll(forUserEmail: string, models: T[]): Observable<T[]> {
    if (_.isEmpty(models)) {
      return of(models);
    }

    let watch = new StopWatch(this.constructorName + '.saveAll: ' + models.length, ProcessType.SERVICE, forUserEmail);

    watch.log('get collection');
    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        watch.log('doBeforeSave');
        return this.doBeforeSave(forUserEmail, models).pipe(
          mergeMap((models: T[]) => {
            watch.log('each date to ISO string');
            return from(models);
          }),
          map((model: T) => {
            if (!model.created) {
              model.created = new Date().toISOString();
            }

            return model.toObject();
          }),
          toArray(),
          mergeMap((models: T[]) => {
            watch.log('upsert');
            return collection.upsert(models);
          }),
          map(() => {
            watch.log('done');
            return models;
          }),
        );
      }),
    );
  }

  removeAll(forUserEmail: string, models: T[]): Observable<any> {
    if (_.isEmpty(models)) {
      return of([]);
    }

    let ids = _.map(models, '_id');
    return this.removeByIds(forUserEmail, ids);
  }

  removeById(forUserEmail: string, id: string): Observable<any> {
    if (!id) {
      throw new Error(`Id cannot be nil`);
    }

    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        return collection.removeById(id);
      }),
    );
  }

  removeByIds(forUserEmail: string, ids: string[]): Observable<any> {
    if (_.isEmpty(ids)) {
      return of([]);
    }

    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        return collection.remove({ _id: { $fastIn: ids } });
      }),
    );
  }

  findById(forUserEmail: string, id: string): Observable<T> {
    if (!id) {
      return throwError(new Error(`Id cannot be nil`));
    }

    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        let query = {
          $or: [
            {
              _id: id,
            },
            {
              id: id,
            },
          ],
        };
        let options = this.getCustomFindBaseOptions();

        return collection.findOne(query, options).pipe(
          map(doc => {
            return this.toModel(doc);
          }),
          mergeMap(doc => {
            return this.populate(forUserEmail, [doc]);
          }),
          map(array => array[0]), // Take one/first
        ) as Observable<T>;
      }),
    );
  }

  findByIds(forUserEmail: string, ids: string[]): Observable<T[]> {
    if (_.isEmpty(ids)) {
      return of([]);
    }

    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        let query = {
          $or: [
            {
              _id: { $fastIn: ids },
            },
            {
              id: { $fastIn: ids },
            },
          ],
        };
        let options = this.getCustomFindBaseOptions();

        return collection.find(query, options).pipe(
          map(docs => {
            return this.toModels(docs);
          }),
          mergeMap(docs => {
            return this.populate(forUserEmail, docs);
          }),
        );
      }),
    );
  }

  findBaseByIds(forUserEmail: string, entities: B[]): Observable<B[]> {
    if (_.isEmpty(entities)) {
      return of([]);
    }

    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        let ids = this.getIdsFromEntities(entities);
        let query = {
          id: { $fastIn: ids },
        };
        let options = {
          _project: BaseModel.resourceBaseFieldNames,
          ...this.getCustomFindBaseOptions(),
        };

        return collection.find(query, options);
      }),
    );
  }

  findSyncedByIds(forUserEmail: string, entities: B[]): Observable<T[]> {
    if (_.isEmpty(entities)) {
      return of([]);
    }

    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        let ids = this.getIdsFromEntities(entities);
        let query = {
          id: { $fastIn: ids },
        };
        let options = this.getCustomFindBaseOptions();

        return collection.find(query, options).pipe(
          map(docs => {
            return this.toModels(docs);
          }),
          mergeMap(docs => this.populate(forUserEmail, docs)),
        );
      }),
    );
  }

  findSyncedBaseByIds(forUserEmail: string, entities: B[]): Observable<B[]> {
    if (_.isEmpty(entities)) {
      return of([]);
    }

    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        let ids = this.getIdsFromEntities(entities);
        let query = {
          _synced: true,
          id: { $fastIn: ids },
        };
        let options = {
          _project: BaseModel.resourceBaseFieldNames,
          ...this.getCustomFindBaseOptions(),
        };

        return collection.find(query, options);
      }),
    );
  }

  removeCollection(forUserEmail: string): Observable<any> {
    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        return collection.remove({});
      }),
    );
  }

  getCustomFindBaseOptions() {
    return {};
  }

  addPagingToOptions(pagingParams: PagingParams, options: Object = {}): Object {
    let pagingOptions: DatabasePagingOptions = {};

    if (!_.isNil(pagingParams.offset)) {
      pagingOptions.$skip = pagingParams.offset;
    }
    if (!_.isNil(pagingParams.size)) {
      pagingOptions.$limit = pagingParams.size;
    }

    return _.assign(options, pagingOptions);
  }

  condition(forUserEmail: string, query: Object): Observable<any> {
    return this.collection(forUserEmail).pipe(
      mergeMap((collection: DatabaseCollectionService) => {
        return collection.conditionStart(query);
      }),
    );
  }

  /**
   * Builds a DB query from filters
   * - filters should be implemented in a OR manner
   * - currently we have 2 types of filters 'FROM' and 'TO'
   * - FROM should look into 'author' field
   * - TO should look into 'shareList' field
   */
  protected getFiltersQuery(filter: any, currentUserId: string): DatabaseQuery {
    let queryBuilder: Object = {};

    switch (filter.Type) {
      case SearchFilterType.TO:
        if (filter.IsGroup) {
          queryBuilder = {
            to: {
              resources: {
                id: {
                  $fastIn: filter.Ids,
                },
              },
            },
          };
        } else {
          queryBuilder = {
            author: {
              resources: {
                id: currentUserId,
              },
            },
            $or: [
              {
                to: {
                  resources: {
                    id: {
                      $fastIn: filter.Ids,
                    },
                  },
                },
              },
              {
                cc: {
                  resources: {
                    id: {
                      $fastIn: filter.Ids,
                    },
                  },
                },
              },
            ],
          };
        }
        break;
      case SearchFilterType.ANY:
        if (filter.IsGroup) {
          // If searching in a shared inbox group, we need to search for emails that are sent to group, or
          // emails that were sent by allowed impersonated sender of such group
          queryBuilder = {
            $or: [
              {
                to: {
                  resources: {
                    id: {
                      $fastIn: filter.Ids,
                    },
                  },
                },
              },
              {
                author: {
                  id: {
                    $fastIn: filter.Ids,
                  },
                },
              },
            ],
          };
        } else {
          queryBuilder = {
            $or: [
              {
                to: {
                  resources: {
                    id: {
                      $fastIn: filter.Ids,
                    },
                  },
                },
              },
              {
                author: {
                  id: {
                    $fastIn: filter.Ids,
                  },
                },
              },
              {
                cc: {
                  resources: {
                    id: {
                      $fastIn: filter.Ids,
                    },
                  },
                },
              },
            ],
          };
        }
        break;
      case SearchFilterType.FROM:
        queryBuilder = {
          author: {
            id: {
              $fastIn: filter.Ids,
            },
          },
        };
        break;
      default:
        break;
    }

    return <DatabaseQuery>{
      query: queryBuilder,
    };
  }

  protected getClientIdsFromEntities(resources: B[]): string[] {
    return _.compact(_.map(resources, resource => resource['_id'] || resource.clientId || resource.id));
  }

  protected getAllIdsFromEntities(resources: B[]): string[] {
    return _.compact(_.flatMap(resources, resource => [resource.clientId, resource.id]));
  }
}
