import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsVideoPipe } from './is-video.pipe';

@NgModule({
  declarations: [IsVideoPipe],
  imports: [CommonModule],
  exports: [IsVideoPipe],
})
export class IsVideoModule {}
