import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ContactModel, ContactViewData, GroupModel } from 'dta/shared/models-api-loop/contact/contact.model';
import { BaseViewDecorateService } from '../base-view-decorate.service';
import { from, Observable, of } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { ContactUnreadCount } from '@shared/services/data/card-unread/card-unread.service.interface';

@Injectable()
export class ContactViewDecorateService extends BaseViewDecorateService<ContactModel> {
  constructor() {
    super();
  }

  decorateViewData(forUserId: string, contact: ContactModel, force?: boolean): Observable<ContactModel> {
    if (contact._ui && !force) {
      return of(contact);
    }

    /**
     * Defaults
     */
    let ui: ContactViewData = {
      unreadChats: 0,
      favoriteName: '',
      isTagDisabled: false
    };

    ui.favoriteName = this.getFavoriteName(contact);

    contact._ui = ui;
    return of(contact);
  }

  getFavoriteName(contact: ContactModel): string {
    if (contact instanceof GroupModel) {
      return contact.name;
    } else {
      return contact.name ? contact.name.split(' ')[0] : '';
    }
  }

  decorateWithUnreadCounts(
    contacts: ContactModel[],
    unreadDictionary: { [groupKey: string]: ContactUnreadCount }
  ): Observable<ContactModel[]> {
    if (_.isEmpty(contacts)) {
      return of([]);
    }

    if (!unreadDictionary) {
      unreadDictionary = {};
    }

    return from(contacts).pipe(
      map((contact: ContactModel) => {
        contact._ui.unreadChats = unreadDictionary[contact.id]?.chatUnreadCount || 0;
        contact._ui.unreadEmails = unreadDictionary[contact.id]?.emailUnreadCount || 0;
        contact._ui.unreadBadge = unreadDictionary[contact.id]?.totalUnreadCount || 0;
        contact._ui.totalCount = unreadDictionary[contact.id]?.totalCount || 0;
        return contact;
      }),
      toArray()
    );
  }

  decorateWithUnreadCount(
    contact: ContactModel,
    unreadDictionary: ContactUnreadCount = new ContactUnreadCount()
  ): Observable<ContactModel> {
    if (!contact || !contact._ui) {
      return of(contact);
    }

    contact._ui.unreadChats = unreadDictionary.chatUnreadCount || 0;
    contact._ui.unreadEmails = unreadDictionary.emailUnreadCount || 0;
    contact._ui.unreadBadge = unreadDictionary.totalUnreadCount || 0;
    contact._ui.totalCount = unreadDictionary.totalCount || 0;

    return of(contact);
  }
}
