import { LoginUserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { NotificationEvent } from '@dta/shared/models/notifications.model';
import {
  EventBadgeCountChanged,
  EventCommentCreated,
  EventUserActiveReply,
  EventUserTyping,
  ListOfTags,
  User
} from '@shared/api/api-loop/models';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ContactChanges } from '@dta/shared/models/event.models';
import { HttpResponseEvent } from '@dta/shared/models/http-events.model';
import { Subject } from 'rxjs';
import { RESOURCE_BASE_TYPE_TO_ACTION } from '@shared/services/communication/shared-subjects/common/interfaces/resource-base-type-to-action';
import { ResourceActionToData } from '@shared/services/communication/shared-subjects/common/interfaces/resource-action-to-data';
import { EnumObjectValue } from '@shared/utils/common/types';
import { ResourceBaseType } from '@shared/api/common/contatns/ResourceBaseType';

///////////////
// BASE MODELS
///////////////
export enum SharedSubjectScope {
  USER,
  APP
}

export abstract class SharedSubjectData {
  static scope: SharedSubjectScope;
  readonly $scope: SharedSubjectScope;
}

export class AppScopeData {
  static scope = SharedSubjectScope.APP;
  readonly $scope: SharedSubjectScope = AppScopeData.scope;
}

export class UserScopeData {
  static scope = SharedSubjectScope.USER;
  readonly $scope: SharedSubjectScope = UserScopeData.scope;

  constructor(public forUserEmail?: string) {}
}

//////////////////////////////
// APPLICATION SCOPED MODELS
//////////////////////////////
export class Trigger extends AppScopeData {}

export class SystemInfoPopupData extends AppScopeData {
  popupType: SystemInfoPopupType;
}

export class ConfirmationPopupData extends AppScopeData {
  type: ConfirmationPopupDataType;
}

export class ConfirmationPopupOpenData extends ConfirmationPopupData {
  type = ConfirmationPopupDataType.Open;
  shared: boolean;
  callback: Subject<boolean>;
  popupType: ConfirmationPopupType;
  additionalData?: any;
  text?: string;
}

export class ConfirmationPopupConfirmData extends ConfirmationPopupData {
  type = ConfirmationPopupDataType.Confirm;
  confirmed: boolean;
  additionalData?: any;
}

export interface StorageName {
  storageName: string;
  keyToDelete?: string;
  child?: StorageName;
}
export class ClearStorageCache extends AppScopeData {
  storageName: StorageName;
}

export class UserSwitch extends AppScopeData {
  reason: UserSwitchReason;
  newUserEmail: string;
}

export class UserRemove extends AppScopeData {
  userEmail: string;
}

export class UserSet extends AppScopeData {
  userEmail: string;
}

export class UserUpdate extends AppScopeData {
  updatedUser: LoginUserModel;
}

export class UserAuthUpdate extends AppScopeData {
  updatedUserEmail: string;
}

export class ConnectionStatus extends AppScopeData {
  connectionActive: boolean;
}

export class PowerStateStatus extends AppScopeData {
  powerState: PowerState;
}

export class AppStatusNotification extends AppScopeData {
  type: AppStatusType;
  status: ConnectionStatus | AppSyncStatus | AppEventApiStatus;
}

export class NotificationEventWrapper extends AppScopeData {
  notificationEvent: NotificationEvent;
}

export class OpenDraftForEditData extends AppScopeData {
  forUserEmail: string;
  cardDraftId: string;
}

export class HttpResponseEventData extends AppScopeData {
  httpResponseEvent: HttpResponseEvent;
}

export class DesktopNotificationEventWrapper extends AppScopeData {
  desktopNotificationEvent: EventCommentCreated[];
}

export class WrittenFileNames extends AppScopeData {
  fileName: string;
}

export class FileUploadProgress extends AppScopeData {
  fileName: string;
  progress: number;
}

export class UserTokenInvalid extends AppScopeData {
  userEmail: string;
  showDialog: boolean;
  close: boolean;
}

export class RedoInitSyncRequired extends AppScopeData {
  open: boolean;
  title: string;
  text: string;
}

export class NoticeBarData extends AppScopeData {
  noticeType: NoticeType;
}

export class UpdateModalData extends AppScopeData {
  open: boolean;
  currentVersion: string;
}

export class CardResolvedData extends AppScopeData {
  cardId: string;
}

export class UnreadStateChange extends AppScopeData {
  constructor(
    // Filter for user based on application.
    // On sidebar badge we need it for all user, on other we may not
    public forUserEmail?: string,
    public optimisticResponse?: boolean
  ) {
    super();
  }
}

export class CountersChangedTrigger extends AppScopeData {
  // Filter for user based on application.
  forUserEmail: string;
  events: EventBadgeCountChanged[];
}

//////////////////////
// USER SCOPED MODELS
//////////////////////
export class UserTrigger extends UserScopeData {}

export class DraftDeleted extends UserScopeData {
  draftCardId: string;
}

export class DraftError extends UserScopeData {
  draftCardId: string;
}

export class UserTypingData extends UserScopeData {
  cardId: string;
}

export class ActiveReplyData extends UserScopeData {
  cardId: string;
}

export class EventActiveUsersData extends UserScopeData {
  users: User[];
}

export class HubEventUserTypingData extends UserScopeData {
  event: EventUserTyping;
}

export class HubEventActiveReplyData extends UserScopeData {
  event: EventUserActiveReply;
}

export class PublishEvent extends UserScopeData {
  static type: string = 'PublishEvent';
  readonly $type: string = PublishEvent.type;

  models: BaseModel[];
  eventType: PublishEventType;
}

// Store events
export class ContactStoreChange extends UserScopeData {
  contactChanges: ContactChanges;
}

export class ContactSidebarChange extends UserScopeData {}

// Other
export class FollowUpSyncFor extends UserScopeData {
  id: string;
  followUpSyncType: FollowUpSyncForType;
  conversationIds?: string[];
}

export class PrefetchStatus extends UserScopeData {
  totalJobs: number;
  finishedJobs: number;
}

export class PrefetchError extends PrefetchStatus {
  error?: string;
}

export class ModelRemoved extends UserScopeData {
  // Backend id of a model that was removed
  modelId: string;
}

export class OptimisticResponseData extends UserScopeData {
  conversationIdsToModify: string[] | 'all-in-view';
  stateToApply: OptimisticResponseState;
  listOfSharedTags?: ListOfTags;
  listOfTags?: ListOfTags;
  snippet?: string;
  fromFolderId: string;
  toFolderId: string;
}

export class ModelActionData<
  RESOURCE extends EnumObjectValue<typeof ResourceBaseType>,
  ACTION extends RESOURCE_BASE_TYPE_TO_ACTION<RESOURCE>
> extends AppScopeData {
  resource: RESOURCE;
  action: ACTION;
  data: ResourceActionToData<ACTION>;
}

/////////
// ENUMS
/////////
export enum OptimisticResponseState {
  READ,
  UNREAD,

  DELETE,
  UNDELETE,
  SHARED_DELETE,
  SHARED_UNDELETE,

  ARCHIVE,
  UNARCHIVE,
  SHARED_ARCHIVE,
  SHARED_UNARCHIVE,

  STAR,
  UNSTAR,

  MOVE_TO_FOLDER,
  MOVE_TO_INBOX,

  SHARED_TAGS_UPDATE,
  TAGS_UPDATE,

  SNIPPET_UPDATE,
  DRAFT_DELETE
}

export enum PublishEventType {
  Remove = 'Remove',
  Upsert = 'Upsert',
  Purge = 'Purge'
}

export enum PowerState {
  Suspend,
  Resume,
  OnAc,
  OnBattery,
  Shutdown,
  LockScreen,
  UnlockScreen
}

export enum SynchronizationEventType {
  Remove = 'Remove'
}

export enum AppStatusType {
  connection = 'connection',
  sync = 'sync',
  eventApi = 'eventApi'
}

export enum AppSyncStatus {
  onboarding = 'onboarding',
  syncing = 'syncing',
  done = 'done'
}

export enum AppEventApiStatus {
  ok = 'ok',
  error = 'error',
  timeout = 'timeout'
}

export enum UserSwitchReason {
  automatic = 'automatic',
  manual = 'manual',
  addAccount = 'addAccount',
  removeAccount = 'removeAccount',
  refreshAccount = 'refreshAccount',
  notification = 'notification'
}

export enum FollowUpSyncForType {
  SharedCardHeader, // Will sync all data needed to calculate header of source card
  Conversation
}

export enum SystemInfoPopupType {
  DBQuotaExceeded
}

export enum ConfirmationPopupDataType {
  Open,
  Confirm
}

export enum ConfirmationPopupType {
  CannedResponseDelete,
  SharedAction,
  SharedInboxAction,
  DeleteTeam,
  DeleteSharedDraft,
  AuthError,
  CollisionDetection
}

export enum ConfirmationPopupAction {
  Open,
  Confirm
}

export enum NoticeType {
  trialNotice = 'trialNotice',
  suspendNotice = 'suspendNotice'
}
