import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { Tag, TagType } from '@shared/api/api-loop/models';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { CollectionNameWeb, TagTypeIndex, TypeIndex } from '../../database-schema';
import { TagDaoServiceI } from '@shared/database/dao/tag/tag-dao.service';
import { TagLabelModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DatabaseServiceWeb } from '../../database.service.web';

@Injectable()
export class TagDaoServiceWeb extends BaseDaoServiceWeb<TagModel, Tag> implements TagDaoServiceI {
  constructor(protected _databaseFactory: DatabaseFactory) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'TagDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.Tag;
  }

  protected toModel(doc: Tag): TagModel {
    return TagModel.create(doc);
  }

  findAllLabelTags(forUserEmail: string): Observable<TagLabelModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) =>
        db.findAllByIndex(TypeIndex.indexName, TagLabelModel.type, this.collectionName),
      ),
      map(docs => this.toModels(docs)),
      map(docs => docs.filter(doc => !(<TagLabelModel>doc).deleted)),
    );
  }

  findExclusiveTag(forUserEmail: string, tags: Tag[]): Observable<TagModel> {
    let tagIds = tags.map(tag => tag.id);
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIds(tagIds, this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      map(_.first),
    );
  }
}
