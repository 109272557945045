import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsNormalTeamPipe } from '@shared/modules/contacts/components/pipes/is-normal-team.pipe';
import { IsGroupPipe } from '@shared/modules/contacts/components/pipes/is-group.pipe';
import { IsUserPipe } from '@shared/modules/contacts/components/pipes/is-user.pipe';
import { IsAvailabilityStatusValidPipe } from '@shared/modules/contacts/components/pipes/is-availability-status-valid.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsNormalTeamPipe, IsGroupPipe, IsUserPipe, IsAvailabilityStatusValidPipe],
  exports: [IsNormalTeamPipe, IsGroupPipe, IsUserPipe, IsAvailabilityStatusValidPipe],
})
export class ContactsPipesModule {}
