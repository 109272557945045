import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContactModel, GroupModel, UserModel } from '../../../../shared/models-api-loop/contact/contact.model';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { ContactFilter, FilterByMethod } from '../../../../shared/models/contact.model';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { UserManagerService } from '../../../../../shared/services/user-manager/user-manager.service';

@AutoUnsubscribe()
@Component({
  selector: 'suggested-contacts',
  template: `
    <div class="suggested-contacts" [class.on-composer]="isOnComposer" [class.has-title]="showTitle">
      <div *ngIf="isOnComposer" class="triangle"></div>

      <div class="suggested-contacts_content">
        <span *ngIf="showTitle" class="title">Suggested:</span>

        <div class="suggested-contacts_list" [class.justify-end]="isForMention">
          <div
            *ngFor="let token of tokens; let i = index"
            class="token"
            [attr.draggable]="isOnComposer"
            [title]="token.email || token.name"
            (dragstart)="dragStart($event, token)"
            (click)="onContactClick($event, token)"
          >
            <div class="info">
              <span class="name">{{ isForMention ? '@' : '' }}{{ token.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./suggested-contacts.scss'],
})
export class SuggestedContactsComponent implements OnInit, OnDestroy, OnChanges {
  ////////
  // SVGs
  ////////
  smallDropdown = require('shared/assets/img/svg/small-dropdown.svg');

  //////////////////
  // Input / Output
  //////////////////
  @Input() currentRecipients: ContactModel[];
  @Input() isForMention: boolean;
  @Input() showTitle: boolean;
  @Input() ignoreGroups: boolean;
  @Input() excludeCurrentUser: boolean;
  @Input() isOnComposer: boolean;
  @Output() onContactAdd: EventEmitter<any> = new EventEmitter();

  /////////////
  // Variables
  /////////////
  tokens: ContactModel[] = [];
  private initialTokens: ContactModel[] = [];

  /////////////////
  // Subscriptions
  ////////////////
  private contactSub: Subscription;

  constructor(
    private _contactService: ContactService,
    private _userManagerService: UserManagerService,
  ) {}

  ngOnInit() {
    this.getTopContacts();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.currentRecipients && !changes.currentRecipients.firstChange) ||
      (changes.disableGroups && !changes.disableGroups.firstChange)
    ) {
      this.filterTokens();
    }
  }

  ////////////////
  // View methods
  ////////////////
  dragStart($event: DragEvent, token: ContactModel) {
    $event.dataTransfer.setData('chip', JSON.stringify(token));
    $event.dataTransfer.setData('isGroup', JSON.stringify(token.$type === GroupModel.type));
  }

  onContactClick($event: MouseEvent, contact: ContactModel) {
    $event.stopPropagation();
    this.onContactAdd.emit(contact);
    this.tokens = this.tokens.filter(token => token.id !== contact.id);

    if (this.tokens.length < 8) {
      this.getTopContacts();
    }
  }

  ///////////////////
  // Private methods
  ///////////////////
  private get shouldIgnoreGroups(): boolean {
    return this.ignoreGroups || this.groupInCurrentRecipients() || this.UserContactInCurrentRecipients();
  }

  private getTopContacts() {
    let contactFilter = new ContactFilter();
    contactFilter.excludeMe = true;
    contactFilter.filterMethod = FilterByMethod.SHOW_TOP;
    contactFilter.size = 20;
    contactFilter.ignoreGroups = this.shouldIgnoreGroups;
    contactFilter.excludeContactIds = _.map(this.currentRecipients, c => c.id);

    this.contactSub?.unsubscribe();
    this.contactSub = this._contactService
      .getFilteredContacts(this._userManagerService.getCurrentUserEmail(), contactFilter)
      .pipe(
        tap((contacts: ContactModel[]) => {
          this.initialTokens = contacts;
          this.filterTokens();
        }),
      )
      .subscribe();
  }

  private filterTokens() {
    let tokens = this.initialTokens;
    if (this.groupInCurrentRecipients()) {
      tokens = [];
    } else if (this.shouldIgnoreGroups) {
      tokens = tokens.filter(token => token.$type !== GroupModel.type);
    }

    this.tokens = _.differenceBy(tokens, this.currentRecipients, 'id');
  }

  private groupInCurrentRecipients(): boolean {
    return _.some(this.currentRecipients, r => r.$type === GroupModel.type);
  }

  private UserContactInCurrentRecipients(): boolean {
    return _.some(this.currentRecipients, r => r.$type === UserModel.type);
  }
}
