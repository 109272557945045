/* tslint:disable */

/**
 * Possible Snippet Types
 */
export type SnippetType = 'Chat' | 'Email';

export module SnippetType {
  export const CHAT: SnippetType = 'Chat';
  export const EMAIL: SnippetType = 'Email';

  export function values(): SnippetType[] {
    return [CHAT, EMAIL];
  }
}
