/* tslint:disable */

/**
 * Shared Inbox types
 */
export type SharedInboxType = 'SharedInbox' | 'PersonalInbox';

export module SharedInboxType {
  export const SHARED_INBOX: SharedInboxType = 'SharedInbox';
  export const PERSONAL_INBOX: SharedInboxType = 'PersonalInbox';

  export function values(): SharedInboxType[] {
    return [SHARED_INBOX, PERSONAL_INBOX];
  }
}
