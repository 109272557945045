import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  displayCategories,
  KeyboardAction,
  KeyboardActionCategory,
  KeyboardActionModeType,
  KeyboardActionType,
  KeyboardShortcut,
  KeyboardShortcutData,
  loopKeyboardShortcuts,
  ShortcutsData,
  ShortcutTrigger,
} from '../../../shared/models/keyboard-shortcut.model';
import { filter, tap } from 'rxjs/operators';
import { KeyboardShortcutModeStack } from './keyboard-shortcut-modes';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '../../../shared/models/logger.model';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';

@Injectable()
export class KeyboardShortcutsListenerService {
  ///////////////////
  // State variables
  ///////////////////
  private availableShortCutsByEvent: { [event: string]: KeyboardShortcut } = {};
  private modeStack: KeyboardShortcutModeStack;
  private hotKeyG: boolean;
  private hotKeyGClearTimeout = undefined;
  private loggingEnabled: boolean = false;
  private modeSwitchSub: Subscription;

  /////////////
  // Subjects
  /////////////
  shortcutEmitted$: Subject<KeyboardShortcutData> = new Subject();
  private shortcutSubject$: Subject<KeyboardShortcutData> = new Subject();

  constructor() {
    this.init();
  }

  get allAvailableShortcuts(): KeyboardShortcut[] {
    return Object.values(this.availableShortCutsByEvent);
  }

  get displayCategories(): KeyboardActionCategory[] {
    return displayCategories;
  }

  getAvailableShortcutsByCategories(categories: KeyboardActionCategory[]): KeyboardShortcut[] {
    return _.filter(this.allAvailableShortcuts, (shortcut: KeyboardShortcut) => {
      return categories.includes(shortcut.action.category);
    });
  }

  init() {
    this.onKeyDown = this.onKeyDown.bind(this);
    this.registerKeyboardEventListeners();
    this.createShortcutsList();
    this.listenToModeSwitch();
    this.initLogging();
  }

  ngOnDestroy() {
    this.unregisterKeyboardEventListeners();
  }

  getActionsByTypes(types: KeyboardActionType[]): Observable<KeyboardShortcutData> {
    return this.shortcutSubject$.asObservable().pipe(
      filter((action: KeyboardShortcutData) => {
        return types.includes(action.action.type);
      }),
      filter((action: KeyboardShortcutData) => {
        // Return true if meant for all
        if (action.forAllListeners) {
          return true;
        }

        let currentMode = this.modeStack.getActiveMode().type;

        let shouldEmit = true;

        // Check if on white list
        if (!_.isEmpty(action.action.whitelistedModes)) {
          shouldEmit = shouldEmit && action.action.whitelistedModes.includes(currentMode);
        }

        // Check if not on black list
        if (!_.isEmpty(action.action.blacklistedModes)) {
          shouldEmit = shouldEmit && !action.action.blacklistedModes.includes(currentMode);
        }

        // Check if baseMode is not on baseModeBlacklistedModes
        if (!_.isEmpty(action.action.baseModeBlacklisted)) {
          shouldEmit = shouldEmit && !action.action.baseModeBlacklisted.includes(this.modeStack.getBaseMode()?.type);
        }

        return shouldEmit;
      }),
      filter((action: KeyboardShortcutData) => {
        return action !== undefined;
      }),
      tap((action: KeyboardShortcutData) => {
        this.shortcutEmitted$.next(action);

        // Log
        this.logKeyboardShortcuts('Keyboard shortcut: ' + action.action.type);
      }),
    );
  }

  setMode(mode: KeyboardActionModeType) {
    this.logModeStack(true);
    this.logKeyboardShortcuts('Set mode: ' + mode, true);

    this.modeStack.setNewMode(mode);

    this.logModeStack(false);
  }

  createNewModeStack(mode: KeyboardActionModeType) {
    this.modeStack.createNewModeStack(mode);
  }

  goToPreviousMode(
    fromMode?: KeyboardActionModeType,
    triggeredWithKeyboardAction: boolean = false,
    event?: FocusEvent,
  ) {
    /**
     * Some set mode type when they are focused,
     * so we don't call go to previous mode in this case.
     */
    if (this.checkIfClickedInsideOf(['.white-left', '.white-right', '.select-dropdown'], event)) {
      return;
    }

    let _action: KeyboardAction;

    if (fromMode) {
      setTimeout(() => {
        _action = this.modeStack.previousModeInRelationTo(fromMode);

        if (_action) {
          this.logModeStack(true);
          this.logKeyboardShortcuts('Go to prev. mode from: ' + fromMode, true);

          this.dispatchAction({ action: _action } as KeyboardShortcutData);

          this.logModeStack(false);
        }
      }, 0);
    } else {
      _action = this.modeStack.navigateToPreviousMode();

      if (_action) {
        this.logModeStack(true);
        this.logKeyboardShortcuts('Go to prev. mode.', true);

        _action = { ..._action, triggeredWithKeyboardAction };
        this.dispatchAction({ action: _action } as KeyboardShortcutData);

        this.logModeStack(false);
      }
    }
  }

  dispatchAction(shortcut: KeyboardShortcutData, forAllListeners: boolean = false) {
    let keyShortcutEvent = {
      ...shortcut,
      mode: this.modeStack.getActiveMode(),
      forAllListeners: forAllListeners,
    } as KeyboardShortcutData;

    if (shortcut.action.useNativeSupport) {
      // Emit native
      this.emitNative(shortcut.action);

      // Emit for suggested/tracking
      this.shortcutEmitted$.next(shortcut);

      // Log
      this.logKeyboardShortcuts('Keyboard shortcut: ' + shortcut.action.type);
    } else {
      this.shortcutSubject$.next(keyShortcutEvent);
    }
  }

  private checkIfClickedInsideOf(selectors: string[], event: FocusEvent): boolean {
    if (!event || !selectors) {
      return false;
    }

    return selectors.some(selector => !!(<HTMLElement>event?.relatedTarget)?.closest(selector));
  }

  private createShortcutsList() {
    _.forEach(loopKeyboardShortcuts, (shortcut: ShortcutsData) => {
      // Identify by type by default
      let strEvent: string = shortcut.action.type;

      // Change to key event if key or code specified
      if (shortcut.key || shortcut.code) {
        strEvent = this.stringifyKeyEvent(shortcut);
      }

      this.availableShortCutsByEvent[strEvent] = new KeyboardShortcut(shortcut);
    });
  }

  private registerKeyboardEventListeners() {
    document.addEventListener('keydown', this.onKeyDown, { capture: true });
  }

  private unregisterKeyboardEventListeners() {
    document.removeEventListener('keydown', this.onKeyDown, { capture: true });
  }

  private onKeyDown(event: KeyboardEvent) {
    /**
     * Ignore repeated events - when holding key down
     * Except for arrowUp and arrowDown
     */
    if (event.repeat && !['ArrowUp', 'ArrowDown'].includes(event.key)) {
      return;
    }

    let isModifierKeyPressed: boolean = event.altKey || event.shiftKey || event.metaKey || event.ctrlKey;

    if (event.key === 'g' && !isModifierKeyPressed) {
      this.setHotKeyFlagAndClearWithTimeout();
    }
    if (this.hotKeyG && isModifierKeyPressed) {
      this.hotKeyG = false;
    }

    this.checkForShortcutAndDispatch(event);

    // Clear hotKey flag if any other key is pressed
    if (this.hotKeyG && event.key !== 'g') {
      this.hotKeyG = false;
    }
  }

  private checkForShortcutAndDispatch(event: KeyboardEvent) {
    if (!event.key) {
      return;
    }

    let isDarwin = checkIfOS(OperatingSystem.DARWIN);
    let mappedKey = event.key;

    // Apparently some mac users perceive delete to mean backspace, so we support that as well
    if (isDarwin && event.key.toUpperCase() === 'BACKSPACE') {
      mappedKey = 'DELETE';
    }

    let shortcutData: ShortcutsData = {
      hotKeyG: this.hotKeyG,
      altKey: event.altKey,
      shiftKey: event.shiftKey,
      ctrlKey: isDarwin ? event.metaKey : event.ctrlKey,
      key: mappedKey,
    };

    // Get stringified key combination
    let triggeredShortcut = this.stringifyKeyEvent(shortcutData);
    let triggeredAction = this.availableShortCutsByEvent[triggeredShortcut];

    // Fallback to code
    if (!triggeredAction) {
      shortcutData.code = event.code;
      triggeredShortcut = this.stringifyKeyEvent(shortcutData);
      triggeredAction = this.availableShortCutsByEvent[triggeredShortcut];
    }

    if (triggeredAction) {
      const shortcut = {
        action: triggeredAction.action,
        triggerType: ShortcutTrigger.KEYBOARD_SHORTCUT,
      } as KeyboardShortcutData;

      // If native support will be used no action is needed
      if (triggeredAction.action.useNativeSupport) {
        // Emit for suggested/tracking
        this.shortcutEmitted$.next(shortcut);

        // Log
        this.logKeyboardShortcuts('Keyboard shortcut: ' + shortcut.action.type);
        return;
      }

      // In some cases we only need to prevent default but still propagate
      if (
        triggeredAction.preventDefaultInModes &&
        triggeredAction.preventDefaultInModes.includes(this.modeStack.getActiveMode().type)
      ) {
        event.preventDefault();
      }

      // Stop any propagation and prevent native support if needed
      if (triggeredAction.preventNativeSupport) {
        event.preventDefault();
        event.stopPropagation();
      }

      // Dispatch and add keyboard action trigger type for tracking
      this.dispatchAction(shortcut);
    }
  }

  private setHotKeyFlagAndClearWithTimeout() {
    this.hotKeyG = true;

    if (this.hotKeyGClearTimeout) {
      clearTimeout(this.hotKeyGClearTimeout);
    }
    this.hotKeyGClearTimeout = setTimeout(() => {
      this.hotKeyG = false;
    }, 1000);
  }

  /**
   * Will stringify key combinations, if both code and key are set, it will
   * prefer code combo
   * @param keyPressData Shortcut data (can be predefined or from event)
   * @returns KeyboardKeyEvent as string
   */
  private stringifyKeyEvent(keyPressData: ShortcutsData): string {
    return [
      ...(keyPressData.ctrlKey ? ['ctrl'] : []),
      ...(keyPressData.altKey ? ['alt'] : []),
      ...(keyPressData.shiftKey ? ['shift'] : []),
      ...(keyPressData.hotKeyG ? ['hotKeyG'] : []),
      keyPressData.code ? keyPressData.code : keyPressData.key.toUpperCase(),
    ].join(' + ');
  }

  private listenToModeSwitch() {
    this.modeStack = new KeyboardShortcutModeStack();

    this.modeSwitchSub = this.getActionsByTypes([KeyboardActionType.PREVIOUS_MODE])
      .pipe(
        tap((action: KeyboardShortcutData) => {
          this.goToPreviousMode(undefined, true);
        }),
      )
      .subscribe();
  }

  private emitNative(action: KeyboardAction) {
    switch (action.type) {
      case KeyboardActionType.COPY:
        // Use timeout otherwise command triggered from shortcuts window is not detected
        setTimeout(() => {
          document.execCommand('copy', false, undefined);
        }, 50);
        break;
      case KeyboardActionType.PASTE:
        // Use timeout otherwise command triggered from shortcuts window is not detected
        setTimeout(() => {
          document.execCommand('paste', false, undefined);
        }, 50);
        break;
      case KeyboardActionType.PASTE_WITHOUT_FORMATTING:
        let _event = new CustomEvent('paste-text');
        document.dispatchEvent(_event);
        break;
      default:
        Logger.customLog(
          'No native support implemented for action type: ' + action.type,
          LogLevel.ERROR,
          LogTag.INTERESTING_ERROR,
          true,
        );
    }
  }

  ///////////
  // Logging
  ///////////
  /**
   * To enable logging call KeyboardShortcutsLogging.enable() from console
   */
  private initLogging() {
    if (CONSTANTS.PRODUCTION) {
      return;
    }

    window['KeyboardShortcutsLogging'] = {
      disable: this.disableLogging.bind(this),
      enable: this.enableLogging.bind(this),
    };
  }

  private logKeyboardShortcuts(action: string, modeRelated?: boolean) {
    if (!this.loggingEnabled) {
      return;
    }

    console.log('%c[KeyboardShortcuts]', modeRelated ? 'color: #be7efe' : 'color: #bada55', action);
  }

  private logModeStack(beforeAction: boolean = false) {
    if (!this.loggingEnabled) {
      return;
    }

    console.log(
      '%c[KeyboardShortcuts]',
      'color: #d7afff',
      beforeAction ? 'Mode stack before: ' : 'Mode stack after: ',
      this.modeStack.getStringifiedModeStack(),
    );
  }

  private disableLogging(): string {
    this.loggingEnabled = false;

    return 'Keyboard shortcuts Logging disabled';
  }

  private enableLogging(): string {
    this.loggingEnabled = true;

    return 'Keyboard shortcuts Logging enabled';
  }
}
