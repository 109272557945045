import { ChangeDetectorRef, Directive, ElementRef, OnDestroy } from '@angular/core';
import { KeyboardActionModeType } from '../../../../shared/models/keyboard-shortcut.model';
import { KeyboardShortcutsListenerService } from '../../../services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { ListKeyboardSelection } from '../list-keyboard-selection/list-keyboard-selection.component';
import { TooltipPlacement } from '../tooltip/tooltip.component';

@Directive()
export abstract class DropdownComponent extends ListKeyboardSelection implements OnDestroy {
  TooltipPlacement = TooltipPlacement;
  ////////////////////
  // State variables
  ////////////////////
  open: boolean = false;
  additionalDropdownOpenTarget;
  startSelectionIndex: number = -1;

  constructor(
    protected _elementRef: ElementRef,
    protected _changeDetection: ChangeDetectorRef,
    protected _keyboardShortcutListenerService: KeyboardShortcutsListenerService,
  ) {
    super();
    this.checkOutsideClick = this.checkOutsideClick.bind(this);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.removeClickEventListener();
  }

  toggleDropdown(event?: MouseEvent) {
    this.open ? this.closeDropdown() : this.openDropdown();
  }

  openDropdown() {
    if (this.open) {
      return;
    }

    this.open = true;
    this._keyboardShortcutListenerService.setMode(KeyboardActionModeType.DROPDOWN);

    this.onDropdownOpen();
    this.addClickEventListener();
    this.addKeyboardListener();

    this.triggerChangeDetection();
  }

  closeDropdown(goToPreviousMode: boolean = true) {
    if (!this.open) {
      return;
    }

    this.open = false;

    if (goToPreviousMode) {
      this._keyboardShortcutListenerService.goToPreviousMode(KeyboardActionModeType.DROPDOWN);
    }

    this.onDropdownClosed();
    this.removeClickEventListener();
    this.removeKeyboardListener();

    this.triggerChangeDetection();
  }

  onDropdownOpen() {
    // does nothing
  }

  onDropdownClosed() {
    // does nothing
  }

  private removeClickEventListener() {
    document.removeEventListener('click', this.checkOutsideClick, { capture: true });
  }

  protected addClickEventListener() {
    document.addEventListener('click', this.checkOutsideClick, { capture: true });
  }

  private checkOutsideClick($event) {
    if (this._elementRef.nativeElement.contains($event.target) || this.isClickOnAdditionalDropdownTarget($event)) {
      return;
    }

    this.closeDropdown();
  }

  private isClickOnAdditionalDropdownTarget($event): boolean {
    if (!this.additionalDropdownOpenTarget) {
      return;
    }
    if (this.additionalDropdownOpenTarget.nativeElement) {
      // additional target has native element
      return this.additionalDropdownOpenTarget.nativeElement.contains($event.target);
    } else {
      // additional target does not have native element
      return this.additionalDropdownOpenTarget.contains($event.target);
    }
  }

  protected triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }

  protected closeList() {
    this.closeDropdown();
  }
}
