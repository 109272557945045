import { Pipe, PipeTransform, TemplateRef } from '@angular/core';

@Pipe({
  name: 'isTemplateRef',
})
export class IsTemplateRefPipe implements PipeTransform {
  transform(value: string | null | TemplateRef<any>): value is TemplateRef<any> {
    return value instanceof TemplateRef;
  }
}
