<div class="title">ASSIGN TO</div>
<div [formGroup]="form" class="flex flex-col gap-quarter">
  <loop-text-control #queryTextControl formControlName="query" size="small" placeholder="Search"></loop-text-control>
  <div>
    <loader *ngIf="isLoading" [style]="'button-circle'" [customClass]="'blue-loader'"></loader>

    <ng-container *ngIf="conversation$ | async; let conversation">
      <ng-container *ngFor="let contact of contacts$ | async; let index = index">
        <loop-contact
          class="padding-vertical-quarter padding-horizontal-half"
          [contact]="contact"
          [class.bg-light-ui-element]="index === currentIndex"
          [showAvailabilityStatus]="true"
          [suffixTpl]="contact.id === (currentAssignId$ | async) ? suffixTpl : undefined"
          [isEmailVisible]="false"
          [tooltip]="{ content: contact?.email, placement: TooltipPlacement.LEFT }"
          (click)="handleContactClick(contact, conversation)"
        ></loop-contact>
        <ng-template #suffixTpl>
          <loop-icon name="check" width="14px" height="14px"></loop-icon>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>
<div *ngIf="conversation$ | async; let conversation">
  <div class="padding-vertical-half unassign-button-container" (click)="sendStatusCommand('/unassign', conversation)">
    <div class="unassign-button padding-vertical-quarter padding-horizontal-half flex gap-half font-new-default">
      <div class="unassigned-badge"></div>
      Unassigned
    </div>
  </div>
  <div
    class="resolve-button-container padding-vertical-half flex justify-center"
    (click)="sendStatusCommand('/resolve', conversation)"
  >
    <div class="dropdown-button green">Mark as resolved</div>
  </div>
</div>
