import { NgModule } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { DaoModule } from './dao/dao.module.web';
import { DatabaseFactoryWeb } from './database-factory.service.web';

@NgModule({
  imports: [DaoModule],
  exports: [],
  declarations: [],
  providers: [{ provide: DatabaseFactory, useClass: DatabaseFactoryWeb }],
})
export class DatabaseModule {}
