import * as _ from 'lodash';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { fromEvent, Subscription } from 'rxjs';
import { TooltipService } from './tooltip.service';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { tap, throttleTime } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.html',
  styleUrls: ['./tooltip.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('void => horizontal', [
        style({ opacity: 0.2, transform: 'translateX(-16px)' }),
        animate(
          '0.1s ease-in-out',
          style({
            opacity: 1,
            transform: 'translateX(0px)',
          }),
        ),
      ]),
      transition('void => vertical', [
        style({ opacity: 0.2, transform: 'translateY(-16px)' }),
        animate(
          '0.1s ease-in-out',
          style({
            opacity: 1,
            transform: 'translateY(0px)',
          }),
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnInit, OnDestroy {
  tooltip = {
    content: '',
    top: '',
    right: '',
    left: '',
    onRight: false,
    onTop: false,
    onBottom: false,
    time: false,
    shiftLeft: false,
    shiftRight: false,
  };

  tooltipHeight = 32;
  show = false;
  animationState: string;

  private tooltipSub: Subscription;
  private scrollSub: Subscription;

  constructor(
    private _changeDetection: ChangeDetectorRef,
    private _tooltipService: TooltipService,
  ) {}

  ngOnInit() {
    this.subscribeToTooltip();
    this.subscribeToScrollEvents();
  }

  ngOnDestroy() {}

  private subscribeToTooltip() {
    this.tooltipSub && this.tooltipSub.unsubscribe();
    this.tooltipSub = this._tooltipService
      .getTooltip()
      .pipe(
        tap(data => {
          if (data) {
            this.handleTooltipState(data);
          } else {
            this.show = false;
          }
          if (!this._changeDetection['destroyed']) {
            this._changeDetection.detectChanges();
          }
        }),
      )
      .subscribe();
  }

  private subscribeToScrollEvents() {
    this.scrollSub && this.scrollSub.unsubscribe();
    this.scrollSub = fromEvent(window, 'scroll')
      .pipe(
        throttleTime(100),
        tap(() => {
          this.show = false;
          if (!this._changeDetection['destroyed']) {
            this._changeDetection.detectChanges();
          }
        }),
      )
      .subscribe();
  }

  private handleTooltipState(data) {
    if (_.isEmpty(data.content)) {
      return;
    }

    switch (data.placement) {
      case TooltipPlacement.RIGHT:
        data.onRight = true;
        data.position = 'onRight';
        break;
      case TooltipPlacement.TOP:
        data.onTop = true;
        data.position = 'onTop';
        break;
      case TooltipPlacement.BOTTOM:
        data.onBottom = true;
        data.position = 'onBottom';
        break;
      default:
        data.onLeft = true;
        data.position = 'onLeft';
        break;
    }

    switch (data.alignment) {
      case TooltipAlignment.CENTER:
        data.alignCenter = true;
        break;
      case TooltipAlignment.END:
        data.shiftRight = true;
        break;
      default:
        break;
    }

    let minTop;
    let maxTop;
    let top;

    if (data.containerSelector) {
      let container = document.querySelector(data.containerSelector);
      if (container) {
        let containerBoundingRect = container.getBoundingClientRect();
        minTop = containerBoundingRect.top;
        maxTop = containerBoundingRect.top + containerBoundingRect.height - this.tooltipHeight;
      }
    }

    let boundingRect = data.element.getBoundingClientRect();
    // If alignCenter flag is set then align center
    if (data.alignCenter) {
      top = boundingRect.top + boundingRect.height / 2 - this.tooltipHeight / 2;
    }
    // If alignCenter is not set and element height is smaller than tooltip height align center
    else if (boundingRect.height < this.tooltipHeight) {
      top = boundingRect.top + boundingRect.height / 2 - this.tooltipHeight / 2;
    }
    // If alignCenter is not set and element height is greater than tooltip height align top
    else {
      top = boundingRect.top;
    }

    if (_.isUndefined(minTop) || _.isUndefined(maxTop) || (top > minTop && top < maxTop)) {
      this.show = true;
      this.animationState = 'horizontal';

      let commonProperties = {
        content: data.content,
        titleContent: data.titleContent,
        contentSuffix: data.contentSuffix,
        extraClass: data.extraClass,
        position: data.position,
        onLeft: data.onLeft,
        onRight: data.onRight,
        onTop: data.onTop,
        onBottom: data.onBottom,
        time: data.isTime,
        shiftLeft: data.shiftLeft,
        shiftRight: data.shiftRight,
      };

      if (data.onRight) {
        let left = boundingRect.right + 10;

        this.tooltip = {
          top: top + 'px',
          left: left + 'px',
          right: '',
          ...commonProperties,
        };
      } else if (data.onTop) {
        this.animationState = 'vertical';
        let top = boundingRect.top - boundingRect.height - 15;
        let left = boundingRect.left + boundingRect.width / 2;

        this.tooltip = {
          top: top + 'px',
          left: left + 'px',
          right: '',
          ...commonProperties,
        };

        this.setTooltipShift(boundingRect, data.content);
      } else if (data.onBottom) {
        this.animationState = 'vertical';
        let top = boundingRect.bottom + 10;
        let left = boundingRect.left + boundingRect.width / 2;

        this.tooltip = {
          top: top + 'px',
          left: left + 'px',
          right: '',
          ...commonProperties,
        };

        this.setTooltipShift(boundingRect, data.content);
      } else {
        let right = window.innerWidth - boundingRect.left + 10;

        this.tooltip = {
          top: top + 'px',
          left: '',
          right: right + 'px',
          ...commonProperties,
        };
      }
    }
  }

  private setTooltipShift(boundingRect: any, contentText: string) {
    // shift tooltip to left/right if there is not enough space
    let right = window.innerWidth - boundingRect.right;
    if (boundingRect.left < 100 && contentText.length > 30) {
      this.tooltip['shiftLeft'] = true;
    } else if (right < 140 && contentText.length > 30 && !this.tooltip['shiftLeft']) {
      this.tooltip['shiftRight'] = true;
    }
  }
}

export interface TooltipOptions {
  element: any;
  content: string;
  titleContent?: string;
  contentSuffix?: string;
  extraClass?: string;
  containerSelector: string;
  placement: TooltipPlacement;
  alignment: TooltipAlignment;
  isTime: boolean;
  isDisabled: boolean;
}

export enum TooltipPlacement {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
}

export enum TooltipAlignment {
  CENTER,
  START,
  END,
}
