import { AfterViewInit, Directive, ElementRef, Inject, Input, NgModule } from '@angular/core';

@Directive({
  selector: '[myAutoFocus]'
})
export class AutofocusDirective implements AfterViewInit {
  private _autofocus;

  constructor(@Inject(ElementRef) private _el) {}

  ngAfterViewInit() {
    if (this._autofocus || typeof this._autofocus === 'undefined') {
      this._el.nativeElement.focus();
    }
  }

  ngOnInit() {
    if (this._autofocus || typeof this._autofocus === 'undefined') {
      setTimeout(() => {
        this._el.nativeElement.focus();
      }, 50);
    }
  }

  @Input() set myAutoFocus(condition: boolean) {
    this._autofocus = condition !== false;
  }
}

@NgModule({
  declarations: [AutofocusDirective],
  exports: [AutofocusDirective]
})
export class AutofocusModule {}
