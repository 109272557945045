import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { RedirectService } from '@shared/services/redirect/redirect.service';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { writeTextToClipboard } from '@dta/shared/clipboard/write-text-to-clipboard';

@Injectable()
export class ContactDetailHelperService {
  readonly toggleDetails$: ReplaySubject<ContactBase | null> = new ReplaySubject<ContactBase | null>(1);

  constructor(private readonly _redirectService: RedirectService) {}

  emailHistory(contact: ContactBase) {
    this._redirectService.quickJump(<UserModel>contact, 'threads', false);
  }

  copyEmailAddress(contact: ContactBase) {
    writeTextToClipboard((<UserModel>contact).email);
  }

  openDetails(contact: ContactBase) {
    this.toggleDetails$.next(contact);
  }
}
