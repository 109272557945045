import { Observable } from 'rxjs';
import { BaseDaoServiceDta } from '@dta/backend/dao/base-dao.service.dta';
import { ResourceBase } from '@shared/api/api-loop/models';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';

export interface UserAvailabilityStatusDaoServiceI {
  findAllUserAvailabilityStatuses(forUserEmail: string): Observable<UserAvailabilityStatusModel[]>;
  findByUserId(forUserEmail: string, userId: string): Observable<UserAvailabilityStatusModel>;
}

export abstract class UserAvailabilityStatusDaoService
  extends BaseDaoServiceDta<UserAvailabilityStatusModel, ResourceBase>
  implements UserAvailabilityStatusDaoServiceI
{
  abstract findAllUserAvailabilityStatuses(forUserEmail: string): Observable<UserAvailabilityStatusModel[]>;
  abstract findByUserId(forUserEmail: string, userId: string): Observable<UserAvailabilityStatusModel>;
}
