<!-- Tag container -->
<div
  class="tag-container"
  [style.border-color]="borderColor"
  [style.background-color]="backgroundColor"
  [class.sharelist-button]="displaySharelistIcon"
  [class.is-clickable]="isClickable"
  [class.is-wide]="displayWideContent"
  [class.disabled]="isDisabled"
  [title]="titleContent"
>
  <!-- Avatar image -->
  <avatar-image
    *ngIf="assigneeTag?._ex?.user && !assigneeTag?._ex?.hideAvatar"
    class="shared-tag-image center-avatar"
    [hidden]="delegating"
    [disablePopup]="true"
    [contact]="assigneeTag._ex?.user"
    [extraClass]="isOnHeader ? 'avatar-20': 'avatar-16'"
    [sharedTag]="true"
  >
  </avatar-image>

  <!-- SVG comp -->
  <svg-comp *ngIf="displayAssignIcon" class="assign-icon" [hidden]="delegating" [src]="assignSvg"> </svg-comp>

  <!-- Extra icon -->
  <svg-comp *ngIf="extraIcon" class="extra-icon" [style.color]="borderColor" [hidden]="delegating" [src]="extraIcon">
  </svg-comp>

  <!-- Tag content -->
  <div class="tag-content" [style.color]="delegating ? 'transparent' : textColor">
    <span>{{ content }}</span>
    <span class="spinner" *ngIf="delegating"></span>
  </div>

  <!-- Lock (private tags only) -->
  <svg-comp *ngIf="isPrivate" class="private-lock" [style.color]="borderColor" [src]="lockSvg"> </svg-comp>

  <!-- Sharelist icon -->
  <svg-comp *ngIf="displaySharelistIcon" [src]="userSelectedSvg" [hidden]="delegating"> </svg-comp>

  <!-- Dropdown icon -->
  <svg-comp *ngIf="displayDropdownIcon" class="dropdown-icon" [style.color]="textColor" [src]="dropdownSvg"> </svg-comp>

  <!-- Remove filter icon -->
  <svg-comp
    *ngIf="displayRemoveIcon"
    class="remove-icon"
    [hidden]="delegating"
    [style.color]="textColor"
    [src]="removeSvg"
    (click)="onRemoveIconClick(assigneeTag)"
  >
  </svg-comp>
</div>
