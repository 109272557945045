import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { DropdownItem } from '../interfaces/dropdown-item';

/**
 * Creates Observable which resolves to array of only available items.
 */
export const createAvailableDropdownItemsObservable: (
  dropdownItems: readonly DropdownItem[],
) => Observable<readonly DropdownItem[]> = dropdownItems =>
  combineLatest(
    dropdownItems.map(dropdownItem =>
      (dropdownItem.isAvailable$ instanceof Observable ? dropdownItem.isAvailable$ : of(true)).pipe(
        map(isAvailable => (isAvailable ? dropdownItem : null)),
      ),
    ),
  ).pipe(
    debounceTime(0),
    map(items => items.filter((dropdownItem): dropdownItem is DropdownItem => dropdownItem !== null)),
  );
