import { Injectable } from '@angular/core';
import { ConversationApiService } from '@shared/api/api-loop/services';
import {
  ActionEnum,
  ConversationSearchUpdateRequest,
  SearchQueryConversationAction,
  TagType,
  ViewEnum
} from '@shared/api/api-loop/models';
import { from, map, mergeMap, Observable, of, tap, toArray } from 'rxjs';
import { ConversationCollectionParams } from '@dta/shared/models/collection.model';
import { ConversationHelper } from '../collection/conversation-collection/conversation-helper';
import { CollectionHelper } from '../collection/collection.helper';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { OptimisticResponseHelper } from '@dta/ui/components/common/conversation-list/optimistic-response.helper';
import { OptimisticResponseState } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { BaseService } from '@shared/services/data/base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { ConversationActionModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-action.model';
import { v1 } from 'uuid';
import { ConversationChangeService } from '@shared/services/data/conversation-change/conversation-change.service';
import { ConversationChangeModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-change.model';
import { CommentService } from '@shared/services/data/comment/comment.service';
import * as _ from 'lodash';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';
import { ConversationActionParams } from '@shared/services/data/conversation-action/conversation-action.service.interface';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { TrackingConstants } from '@dta/shared/services/tracking/tracking.constants';
import { addSeconds } from 'date-fns';

@Injectable()
export class ConversationActionService extends BaseService {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _conversationApiService: ConversationApiService,
    private _notificationsService: NotificationsService,
    private _conversationService: ConversationService,
    private _conversationChangeService: ConversationChangeService,
    private _commentService: CommentService,
    private trackingService: TrackingService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'ConversationActionService';
  }

  markAllInViewAsRead(forUserEmail: string, params: ConversationCollectionParams): Observable<void> {
    let tagConditions = CollectionHelper.getTagsAndSharedTagsForFetch(params);

    let query: SearchQueryConversationAction = {
      showInView: params.showInView
    };

    query = ConversationHelper.addConversationConditions(
      query,
      tagConditions.includeCardSharedTagIds,
      tagConditions.excludeCardSharedTagIds
    );

    let updateRequest: ConversationSearchUpdateRequest = {
      $type: 'ConversationSearchUpdateRequest',
      action: ActionEnum.MARK_AS_READ,
      sharedActionContext: false,
      query
    };

    return this._conversationApiService.Conversation_UpdateConversationActionList({ updateRequest }, forUserEmail);
  }

  conversationAction(
    forUserEmail: string,
    {
      action,
      conversationIds,
      folder,
      context,
      isSharedAction,
      addTags,
      removeTags,
      actionDate,
      dueDate
    }: ConversationActionParams
  ): Observable<ConversationModel[]> {
    this.trackingService.track(forUserEmail, TrackingConstants.conversationAction, {
      conversationIds: conversationIds,
      action: action,
      folder: folder,
      context: context,
      isSharedAction: isSharedAction,
      addTags: addTags,
      removeTags: removeTags,
      actionDate: actionDate,
      dueDate: dueDate
    });

    let optimisticStateAndNotificationType = this.getOptimisticStateAndNotificationType(action, forUserEmail);
    this.showAppNotification(forUserEmail, optimisticStateAndNotificationType.type);
    let changes = [];

    return this._conversationService.findByCardIds(forUserEmail, conversationIds).pipe(
      mergeMap((conversations: ConversationModel[]) => {
        return from(conversations);
      }),
      map((conversation: ConversationModel) => {
        conversation.updateConversationFromAction({
          action: action,
          folder: folder,
          context: context,
          addTags: addTags,
          removeTags: removeTags
        });

        if (action !== ActionEnum.MARK_AS_READ && action !== ActionEnum.MARK_AS_UNREAD) {
          changes.push(
            ConversationChangeModel.createConversationActionModel({
              cardId: conversation.cardId,
              action: action,
              folder: folder,
              context: context,
              addTags: addTags,
              removeTags: removeTags
            })
          );
        }

        return conversation;
      }),
      toArray(),
      mergeMap((conversations: ConversationModel[]) => {
        return this._conversationChangeService
          .saveAll(forUserEmail, changes)
          .pipe(mergeMap(() => this._conversationService.saveAllAndPublish(forUserEmail, conversations)));
      }),
      tap(() => {
        let conversationAction = new ConversationActionModel({
          id: v1(),
          $type: ConversationActionModel.type,
          actionToApply: action,
          actionDate: actionDate ? addSeconds(new Date(actionDate), 1).toISOString() : undefined,
          conversationIds: conversationIds,
          folderId: folder?.id,
          sharedActionContext: isSharedAction,
          context: context,
          addTags: addTags?.map(tag => tag.id),
          removeTags: removeTags?.map(tag => tag.id),
          dueDate: dueDate
        });
        this.enqueuePushSynchronization(forUserEmail, conversationAction);
      }),
      mergeMap((conversations: ConversationModel[]) => {
        return this.afterConversationAction(forUserEmail, conversations, action);
      })
    );
  }

  private afterConversationAction(
    forUserEmail: string,
    conversations: ConversationModel[],
    action: ActionEnum
  ): Observable<ConversationModel[]> {
    return of(conversations).pipe(
      mergeMap((conversations: ConversationModel[]) => {
        switch (action) {
          case ActionEnum.MARK_AS_READ:
            return this.markAsRead(forUserEmail, conversations);
        }
        return of(conversations);
      })
    );
  }

  private markAsRead(forUserEmail: string, conversations: ConversationModel[]): Observable<ConversationModel[]> {
    let cardIds = [];
    _.forEach(conversations, conversation => {
      if (conversation.snapshotCard) {
        cardIds.push({ id: conversation.snapshotCard.id });
      }
      cardIds.push({ id: conversation.cardId });
    });

    return this._commentService.findUnreadCommentsByCards(forUserEmail, cardIds).pipe(
      mergeMap((comments: CommentModel[]) => {
        return this._commentService.removeTagsByComments(
          forUserEmail,
          comments,
          [TagModel.buildSystemTag(TagType.UNREAD), TagModel.buildSystemTag(TagType.UNREAD_VIRTUAL)],
          false
        );
      }),
      map(() => conversations)
    );
  }

  private getOptimisticStateAndNotificationType(
    action: ActionEnum,
    forUserEmail: string
  ): OptimisticStateAndNotificationType {
    let type: NotificationEventType;
    let state: OptimisticResponseState;
    switch (action) {
      case ActionEnum.MARK_AS_READ:
        state = OptimisticResponseState.READ;
        break;
      case ActionEnum.MARK_AS_UNREAD:
        state = OptimisticResponseState.UNREAD;
        break;
      case ActionEnum.MARK_AS_STARRED:
        type = NotificationEventType.CardStarred;
        state = OptimisticResponseState.STAR;
        break;
      case ActionEnum.MARK_AS_UNSTARRED:
        type = NotificationEventType.CardUnstarred;
        state = OptimisticResponseState.UNSTAR;
        break;
      case ActionEnum.DELETE:
        type = NotificationEventType.CardDeleted;
        state = OptimisticResponseState.SHARED_DELETE;
        break;
      case ActionEnum.ARCHIVE:
        type = NotificationEventType.CardArchived;
        state = OptimisticResponseState.SHARED_ARCHIVE;
        break;
      case ActionEnum.MOVE_TO_INBOX:
        type = NotificationEventType.CardMovedToInbox;
        state = OptimisticResponseState.MOVE_TO_INBOX;
        break;
      case ActionEnum.MOVE_TO_FOLDER:
        type = NotificationEventType.CardMovedToFolder;
        state = OptimisticResponseState.MOVE_TO_FOLDER;
        break;
      case ActionEnum.FOLLOW:
        type = NotificationEventType.FollowConversation;
        break;
      case ActionEnum.UN_FOLLOW:
        type = NotificationEventType.UnFollowConversation;
        break;
      case ActionEnum.CHANGE_CLASSIFICATION:
        this.showBasicNotification(forUserEmail, 'Classification changed');
        break;
    }
    return { type, state };
  }

  private applyOptimisticResponse(forUserEmail: string, state: OptimisticResponseState, conversationIds: string[]) {
    OptimisticResponseHelper.triggerApplyOptimisticResponse(forUserEmail, conversationIds, state);
  }

  private showAppNotification(forUserEmail: string, type: NotificationEventType) {
    if (type) {
      this._notificationsService.setInAppNotification(forUserEmail, { type: type });
    }
  }

  private showBasicNotification(forUserEmail: string, message: string): void {
    this._notificationsService.setInAppNotification(forUserEmail, {
      type: NotificationEventType.BasicNotification,
      msg: message
    });
  }
}

interface OptimisticStateAndNotificationType {
  state: OptimisticResponseState;
  type: NotificationEventType;
}
