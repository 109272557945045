import { Observable } from 'rxjs';
import { BaseDaoService } from '../base/base-dao.service';
import {
  AppointmentResponse,
  CardAppointment,
  CardBase,
  CardDraft,
  ListOfResourcesOfAttendee,
} from '@shared/api/api-loop/models';
import {
  CardAppointmentModel,
  CardChatModel,
  CardDraftModel,
  CardMailModel,
  CardModel,
  CardSharedModel,
  SubscriptionState,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { DatabaseQuery } from '@dta/backend/database/database.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';
import {
  AllSharedInboxesCollectionParams,
  ChannelCardsCollectionParams,
  ChatCardsCollectionParams,
  MyLoopInboxCollectionParams,
  PersonalInboxCollectionParams,
  SearchableViewCollectionParams,
} from '@dta/shared/models/collection.model';

export interface CardDaoServiceI {
  removeTag(forUserEmail: string, card: CardModel, tag: TagModel): Observable<CardModel>;
  unsetId(forUserEmail: string, card: CardModel): Observable<CardModel>;
  updateAppointmentAttendees(
    forUserEmail: string,
    card: CardAppointmentModel,
    attendees: ListOfResourcesOfAttendee,
  ): Observable<CardAppointmentModel>;
  updatedAppointmentResponse(
    forUserEmail: string,
    cardId: string,
    response: AppointmentResponse,
  ): Observable<CardAppointmentModel>;
  checkIfCardHasLiveLoops(forUserEmail: string, cardId: string): Observable<boolean>;
  findChatCardSubscriptionStatus(
    forUserEmail: string,
    cardId: string,
  ): Observable<{ _ex: { subscriptionState: SubscriptionState } }>;
  findNonStubCardBaseByIds(forUserEmail: string, cards: CardBase[]): Observable<BaseModel[]>;
  findCardIds(forUserEmail: string, cardIds: string[]): Observable<string[]>;
  findCardBySourceResourceId(forUserEmail: string, sourceCardId: string): Observable<CardModel>;
  findCardsBySourceResourceId(forUserEmail: string, sourceCardId: string): Observable<CardModel[]>;
  findLoopsBySourceAndSnapshotIds(
    forUserEmail: string,
    sourceCards: CardMailModel[],
    excludedCards: CardModel[],
  ): Observable<CardModel[]>;
  findLiveLoopsBySourceResourceIds(
    forUserEmail: string,
    sourceResourceCardIds: string[],
  ): Observable<CardSharedModel[]>;
  findCardMailByIds(forUserEmail: string, cardIds: string[], excludedCards: CardModel[]): Observable<CardModel[]>;
  findCardSharedBySnapshotResource(forUserEmail: string, card: CardMailModel): Observable<CardModel>;
  findPersonalInboxCards(forUserEmail: string, params: PersonalInboxCollectionParams): Observable<CardModel[]>;
  findChatCards(forUserEmail: string, params: ChatCardsCollectionParams): Observable<CardChatModel[]>;
  findLocalChatCardsBySharelist(forUserEmail: string, emailsFromChatCards: string[]): Observable<CardChatModel[]>;
  findCardsForAssignedView(forUserEmail: string, params: any): Observable<CardSharedModel[]>;
  findFolderCards(forUserEmail: string, params: SearchableViewCollectionParams): Observable<CardModel[]>;
  findCardsByChannel(forUserEmail: string, params: ChannelCardsCollectionParams): Observable<CardModel[]>;
  findDraftsOrCardsWithDrafts(forUserEmail: string, params: SearchableViewCollectionParams): Observable<CardModel[]>;
  findAllSharedInboxCards(forUserEmail: string, params: AllSharedInboxesCollectionParams): Observable<CardModel[]>;
  findMyLoopInboxCards(forUserEmail: string, params: MyLoopInboxCollectionParams): Observable<CardModel[]>;
  findCardIdsToPurge(forUserEmail: string, createdCutoffTime: string, accessedCutoffTime: string): Observable<string[]>;
  findAppointments(forUserEmail: string, params: any): Observable<CardModel[]>;
  findCards(forUserEmail: string, cards: CardBase[]): Observable<CardModel[]>;
  findCardsWithoutSynchronizedComments(forUserEmail: string): Observable<CardModel[]>;
  findAppointmentsByCards(
    forUserEmail: string,
    cardsWithAppointmentsLink: CardMailModel[],
  ): Observable<CardAppointmentModel[]>;
  findCardByAppointmentLink(forUserEmail: string, appointment: CardAppointment): Observable<CardModel>;
  findChatCardByContactId(forUserEmail: string, contactId: string): Observable<CardChatModel>;
  findCardSharedWithMissingSourceCards(forUserEmail: string): Observable<CardSharedModel[]>;
  findTemplateCardIds(forUserEmail: string): Observable<string[]>;
  getParentLoop(forUserEmail: string, card: CardDraftModel): Observable<CardModel>;
  buildAllSharedInboxesCardsFromParams(params: AllSharedInboxesCollectionParams): DatabaseQuery;
  unlinkDraftCard(forUserEmail: string, cardDraft: CardDraft): Observable<any>;
}

export abstract class CardDaoService extends BaseDaoService<CardModel, CardBase> implements CardDaoServiceI {
  abstract removeTag(forUserEmail: string, card: CardModel, tag: TagModel): Observable<CardModel>;
  abstract unsetId(forUserEmail: string, card: CardModel): Observable<CardModel>;
  abstract updateAppointmentAttendees(
    forUserEmail: string,
    card: CardAppointmentModel,
    attendees: ListOfResourcesOfAttendee,
  ): Observable<CardAppointmentModel>;
  abstract updatedAppointmentResponse(
    forUserEmail: string,
    cardId: string,
    response: AppointmentResponse,
  ): Observable<CardAppointmentModel>;
  abstract checkIfCardHasLiveLoops(forUserEmail: string, cardId: string): Observable<boolean>;
  abstract findChatCardSubscriptionStatus(
    forUserEmail: string,
    cardId: string,
  ): Observable<{ _ex: { subscriptionState: SubscriptionState } }>;
  abstract findNonStubCardBaseByIds(forUserEmail: string, cards: CardBase[]): Observable<BaseModel[]>;
  abstract findCardIds(forUserEmail: string, cardIds: string[]): Observable<string[]>;
  abstract findCardBySourceResourceId(forUserEmail: string, sourceCardId: string): Observable<CardModel>;
  abstract findCardsBySourceResourceId(forUserEmail: string, sourceCardId: string): Observable<CardModel[]>;
  abstract findLoopsBySourceAndSnapshotIds(
    forUserEmail: string,
    sourceCards: CardMailModel[],
    excludedCards: CardModel[],
  ): Observable<CardModel[]>;
  abstract findLiveLoopsBySourceResourceIds(
    forUserEmail: string,
    sourceResourceCardIds: string[],
  ): Observable<CardSharedModel[]>;
  abstract findCardMailByIds(
    forUserEmail: string,
    cardIds: string[],
    excludedCards: CardModel[],
  ): Observable<CardModel[]>;
  abstract findCardSharedBySnapshotResource(forUserEmail: string, card: CardMailModel): Observable<CardModel>;
  abstract findPersonalInboxCards(forUserEmail: string, params: PersonalInboxCollectionParams): Observable<CardModel[]>;
  abstract findChatCards(forUserEmail: string, params: ChatCardsCollectionParams): Observable<CardChatModel[]>;
  abstract findLocalChatCardsBySharelist(
    forUserEmail: string,
    emailsFromChatCards: string[],
  ): Observable<CardChatModel[]>;
  abstract findCardsForAssignedView(forUserEmail: string, params: any): Observable<CardSharedModel[]>;
  abstract findFolderCards(forUserEmail: string, params: SearchableViewCollectionParams): Observable<CardModel[]>;
  abstract findCardsByChannel(forUserEmail: string, params: ChannelCardsCollectionParams): Observable<CardModel[]>;
  abstract findDraftsOrCardsWithDrafts(
    forUserEmail: string,
    params: SearchableViewCollectionParams,
  ): Observable<CardModel[]>;
  abstract findAllSharedInboxCards(
    forUserEmail: string,
    params: AllSharedInboxesCollectionParams,
  ): Observable<CardModel[]>;
  abstract findMyLoopInboxCards(forUserEmail: string, params: MyLoopInboxCollectionParams): Observable<CardModel[]>;
  abstract findCardIdsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string,
  ): Observable<string[]>;
  abstract findAppointments(forUserEmail: string, params: any): Observable<CardModel[]>;
  abstract findCards(forUserEmail: string, cards: CardBase[]): Observable<CardModel[]>;
  abstract findCardsWithoutSynchronizedComments(forUserEmail: string): Observable<CardModel[]>;
  abstract findAppointmentsByCards(
    forUserEmail: string,
    cardsWithAppointmentsLink: CardMailModel[],
  ): Observable<CardAppointmentModel[]>;
  abstract findCardByAppointmentLink(forUserEmail: string, appointment: CardAppointment): Observable<CardModel>;
  abstract findChatCardByContactId(forUserEmail: string, contactId: string): Observable<CardChatModel>;
  abstract findCardSharedWithMissingSourceCards(forUserEmail: string): Observable<CardSharedModel[]>;
  abstract findTemplateCardIds(forUserEmail: string): Observable<string[]>;
  abstract getParentLoop(forUserEmail: string, card: CardDraftModel): Observable<CardModel>;
  abstract buildAllSharedInboxesCardsFromParams(params: AllSharedInboxesCollectionParams): DatabaseQuery;
  abstract unlinkDraftCard(forUserEmail: string, cardDraft: CardDraft): Observable<any>;
}
