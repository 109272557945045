import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderNode } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';
import { ContextType } from '@shared/api/api-loop/models/context-type';
import { ContextEnum } from '@shared/api/api-loop/models/context-enum';
import { FilterEnum } from '@shared/api/api-loop/models';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable()
export class NavigationBarService {
  folders: FolderNode[];
  foldersChanged$: Subject<FolderNode[]> = new Subject();
  foldersPinned$: Subject<FolderNode> = new Subject();

  folderSelected: FolderNode;
  selectedFolderChanged$: Subject<FolderNode> = new Subject();

  showFolderView: boolean = false;
  showFolderViewChanged$: Subject<boolean> = new Subject();

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  setFolders(views: FolderNode[]): void {
    this.folders = views;
    this.foldersChanged$.next(this.folders);
  }

  viewChanged(folder: FolderNode): void {
    if (!folder) {
      return;
    }

    this.folderSelected = folder;

    this.selectedFolderChanged$.next(this.folderSelected);
  }

  folderPinned(folder: FolderNode): void {
    folder.pinned = !folder.pinned;
    this.foldersPinned$.next(folder);
  }

  deleteFolder(folder: FolderNode): void {
    this.folders = _.filter(this.folders, _folder => _folder.id !== folder.id);
    this.setFolders(this.folders);
  }

  toggleFolderView(): void {
    this.showFolderView = !this.showFolderView;
    this.showFolderViewChanged$.next(this.showFolderView);
  }

  closeFolderView(): void {
    if (this.showFolderView) {
      this.showFolderView = false;
      this.showFolderViewChanged$.next(this.showFolderView);
    }
  }

  getContext(): ContextType {
    return getContextByFolder(this.folderSelected);
  }
}

export function getContextByFolder(folder: FolderNode): ContextType {
  if (!folder) {
    return { context: ContextEnum.INBOX };
  }

  let context = ContextEnum.FOLDER;
  if (!!folder?.filterEnum) {
    switch (folder.filterEnum) {
      case FilterEnum.INBOX:
        context = ContextEnum.INBOX;
        break;
      case FilterEnum.DELETED:
        context = ContextEnum.DELETE;
        break;
      case FilterEnum.ARCHIVED:
        context = ContextEnum.ARCHIVE;
        break;
    }
  }

  return context === ContextEnum.FOLDER ? { context: context, folderId: folder.id } : { context: context };
}
