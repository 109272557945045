import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  BehaviorSubject,
  debounce,
  EMPTY,
  exhaustMap,
  merge,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscription,
  timer
} from 'rxjs';
import { IPC } from '../../../../shared/communication/ipc-constants';
import { LogTag } from '../../../../shared/models/logger.model';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { StorageKey } from '@dta/shared/services/storage/storage.service';
import {
  ContactBaseModel,
  ContactModel,
  GroupModel,
  UserModel
} from '@dta/shared/models-api-loop/contact/contact.model';
import { TooltipPlacement } from '../../common/tooltip/tooltip.component';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { catchError, debounceTime, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { ClickTrackingLocation } from '@dta/shared/services/tracking/tracking.constants';
import { SidebarContacts } from '@dta/shared/models/contact.model';
import { KeyboardAction, KeyboardActionType, KeyboardShortcutData } from '@dta/shared/models/keyboard-shortcut.model';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { MenuChannelsListService } from './menu-channels-list.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { Logger } from '@shared/services/logger/logger';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { ElectronService } from '@shared/services/electron/electron';
import { UserPreferencesService } from '@shared/services/user-preferences/user-preferences.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { RedirectService } from '@shared/services/redirect/redirect.service';
import { NewMessageModalService } from '@shared/services/new-message-modal/new-message-modal.service';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';
import { Event, NavigationStart, Router } from '@angular/router';
import { RouterService, SupportedRouteParts } from '@dta/ui/services/router/router.service';
import { AppStateService } from '@shared/services/data/app-state/app-state.service';
import {
  ConnectionStatus,
  UnreadStateChange
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { GroupType } from '@shared/api/api-loop/models';
import { CONSTANTS } from '@shared/models/constants/constants';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { UserAvailabilityStatusService } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service';
import { UserAvailabilityStatusSubscriberEvent } from '@shared/services/availability-status-subscriber/user-availability-status-subscriber/user-availability-status-subscriber.interface';
import { UserAvailabilityStatusSubscriberService } from '@shared/services/availability-status-subscriber/user-availability-status-subscriber/user-availability-status-subscriber.service';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { HeaderDropdownComponent } from '@shared/modules/main/header/header-dropdown/header-dropdown.component';
import { isGroupModel } from '@shared/modules/contacts/common/helpers/is-group-contact';
import { CommentApiService } from '@shared/api/api-loop/services/comment-api.service';
import { CommentFormComponent } from '@shared/modules/comments/components/comment-form/comment-form.component';
import { LOOP_DYNAMIC_COMPONENT_NAME } from '@shared/modules/loop/components/loop-dynamic-components';
import { v1 } from 'uuid';
import { ModalFormService } from '@shared/forms/shell/modal-form/modal-form.service';
import { MailCommentOperationsService } from '@shared/modules/comments/components/mail-comment/mail-comment-operations/mail-comment-operations.service';
import { UserAuthService } from '@shared/modules/auth/auth-data-access/user-auth.service';
import { SettingsScope } from '@shared/modules/auth/settings/common/settings-scope';
import { ConversationSettingsScopeProperty } from '@shared/modules/auth/settings/common/conversation-settings-scope';
import { InboxSettingsScopeProperty } from '@shared/modules/auth/settings/common/inbox-settings-scope';

@AutoUnsubscribe()
@Component({
  selector: 'menu-channels-list',
  templateUrl: './menu-channels-list.html',
  styleUrls: ['./menu-channels-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuChannelsListComponent implements OnInit, OnDestroy {
  /////////
  // SVGs
  /////////
  chevronSvg = require('shared/assets/img/svg/chevron-icon.svg');
  favoriteDragSvg = require('shared/assets/img/svg/favorite-drag.svg');
  favoriteRemoveSvg = require('shared/assets/img/svg/favorite-remove.svg');
  ellipsisVertical = require('shared/assets/img/svg/ellipsis-vertical-solid.svg');
  teamIconSvg = require('shared/assets/img/svg/team.svg');
  addTeamIconSvg = require('shared/assets/img/svg/small-add.svg');
  favoritesIconSvg = require('shared/assets/img/svg/favorite-medium-empty.svg');
  teamsIconSvg = require('shared/assets/img/svg/team-sidebar-title.svg');
  peopleIconSvg = require('shared/assets/img/svg/user-selected.svg');
  deleteChateeSvg = require('shared/assets/img/svg/small-close.svg');
  sharedInboxIconSvg = require('shared/assets/img/svg/shared_inbox_icon.svg');

  ///////////////////
  // Input / Output
  ///////////////////
  @Output() updateScroll = new EventEmitter(false);
  @Input() inboxOpened: boolean;

  /////////////
  // UI vars
  /////////////
  channelsListPreferences: IMenuChannelsListPreferences = {
    favoritesOpened: false,
    peopleOpened: false,
    teamsOpened: false,
    sharedInboxesOpened: false,
    personalInboxesOpened: false
  };
  hasUserPreferences: boolean = false;

  // Data
  hoveredChannel: ContactModel;
  additionalDropdownOpenTarget: any;

  favoriteChannels: ContactModel[] = [];
  peopleChannels: ContactModel[] = [];
  teamChannels: ContactModel[] = [];
  sharedInboxChannels: ContactModel[] = [];
  personalInboxChannels: ContactModel[] = [];
  userAvailabilitiesByUserId: Dictionary<UserAvailabilityStatusModel> = {};
  currentFeedId: string = '';
  dragulaFavoriteListGroup = 'favorite-contacts';
  hasUnreadPeopleChannels: boolean = false;
  hasUnreadTeamsChannels: boolean = false;
  hasUnreadFavoriteChannels: boolean = false;
  hasUnreadSharedInboxChannels: boolean = false;
  hasUnreadPersonalInboxChannels: boolean = false;
  initialLoad: boolean = true;
  currentUserEmail: string = '';
  showMorePeople: boolean = false;
  showMoreTeams: boolean = false;
  showMoreSharedInboxes: boolean = false;
  showMorePersonalInboxes: boolean = false;
  peopleChannelsShowLimit: number = 10;
  teamChannelsShowLimit: number = 10;
  sharedInboxChannelsShowLimit: number = 10;
  personalInboxChannelsShowLimit: number = 10;
  allSharedInboxesSupported: boolean = false;
  allPersonalInboxesSupported: boolean = false;
  offline: boolean;

  // Other
  TooltipPlacement = TooltipPlacement;
  trackingLocation = ClickTrackingLocation.Sidebar;
  isDarwin: boolean = undefined;

  private currentUserIdsSub = new BehaviorSubject<string[]>([]);
  private currentUserIds: string[] = [];
  private lastRouteChannelId: string;
  private wasTabWithChannelOpened: boolean;
  private handledKeyboardActions: KeyboardActionType[] = [
    KeyboardActionType.CREATE_SHARED_INBOX,
    KeyboardActionType.CREATE_TEAM
  ];
  private optimisticResponseInProgress: boolean;
  private debounceUnreadChangeSubject: Subject<any> = new Subject<any>();

  @ViewChild('dropdown', { static: false }) dropdown: HeaderDropdownComponent;

  /////////////////
  // Subscriptions
  /////////////////
  private routerEventsSub: Subscription;
  private contactsSub: Subscription;
  private unreadSub: Subscription;
  private anonymousSubscriptions: Subscription[] = [];
  private keyboardShortcutsSub: Subscription;
  private connectionStatusSub: Subscription;
  private currentUserAvailabilitySub: Subscription;
  private userAvailabilitySub: Subscription;
  private sideBarChangesSub: Subscription;

  constructor(
    private ngZone: NgZone,
    private _userPreferencesService: UserPreferencesService,
    private _userManagerService: UserManagerService,
    private _trackingService: TrackingService,
    private _menuChannelsListService: MenuChannelsListService,
    private _changeDetection: ChangeDetectorRef,
    private _contactService: ContactService,
    private _userAvailabilityStatusService: UserAvailabilityStatusService,
    private _userAvailabilityStatusSubscribedService: UserAvailabilityStatusSubscriberService,
    private _notificationsService: NotificationsService,
    private _newMessageModalService: NewMessageModalService,
    private _redirectService: RedirectService,
    private _electronService: ElectronService,
    private _keyboardShortcutsListenerService: KeyboardShortcutsListenerService,
    private _router: Router,
    private _routerService: RouterService,
    private _appStateService: AppStateService,
    private readonly modalFormService: ModalFormService,
    private readonly mailCommentOperationsService: MailCommentOperationsService
  ) {}

  ngOnInit() {
    this.isDarwin = checkIfOS(OperatingSystem.DARWIN);

    this.subscribeToContacts();
    this.getCurrentUsersAvailabilityStatuses();
    this.subscribeToUnreads();
    this.subscribeToOptimisticResponseChanges();
    this.subscribeToConnectionStatus();
    this.subscribeToOpenFavorite();
    this.subscribeAndRegisterKeyboardShortcuts();
    this.subscribeToRouterEvents();
    this.subscribeToSideBarChanges();
  }

  ngOnDestroy() {
    this._electronService.ipcRenderer.removeListener(IPC.OPEN_FAVORITE, this.handleOpenFavorite);
  }

  get componentName(): string {
    return 'MenuChannelsListComponent';
  }

  ////////////////
  // View methods
  ////////////////
  trackByFn(index, item) {
    return item.id;
  }

  handleDoubleClick($event, channel: ContactModel) {
    const initialFromIds: string[] = [channel.id];

    this.modalFormService.create<CommentApiService.Comment_CreateCommentMailParams, CommentFormComponent, any>({
      modalTitle$: of('New mail'),
      componentId: LOOP_DYNAMIC_COMPONENT_NAME.CommentFormComponent,
      windowIdentifier: v1(),
      useCustomLayout: true,
      componentParams: {
        initialFromIds: initialFromIds
      },
      modalWidth: '1000px',
      afterSubmit$: (value: CommentApiService.Comment_CreateCommentMailParams) => {
        return this.mailCommentOperationsService.createCommentMail$(value);
      },
      observer: () => {
        //noop
      }
    });

    $event.preventDefault();
    $event.stopPropagation();
  }

  navigate($event, channel: ContactModel) {
    $event.preventDefault();
    $event.stopPropagation();

    this.navigateToChannel(channel);
  }

  onChannelHover(channel: ContactModel, target) {
    if (!this.dropdown?.channelDropdown?.open) {
      this.hoveredChannel = ContactBaseModel.create(channel);
      this.additionalDropdownOpenTarget = target;
    }
  }

  addTeam() {
    this._trackingService.trackCreateResources(this._userManagerService.getCurrentUserEmail(), 'team');
    this._redirectService.navigateTo('/user-settings/teams/create');
  }

  getUserPreference() {
    let userPreferences = this._userPreferencesService.getCurrentUserPreference(StorageKey.menuChannelsList);

    if (!_.isEmpty(userPreferences)) {
      this.channelsListPreferences = _.assign({}, this.channelsListPreferences, userPreferences);
      this.hasUserPreferences = true;
      this._changeDetection.detectChanges();
    } else {
      this.hasUserPreferences = false;
    }
  }

  toggleListOpened(event: MouseEvent, list: string) {
    event?.stopPropagation();

    this.channelsListPreferences[list] = !this.channelsListPreferences[list];
    this.persistUserPreferences();

    this.hasUserPreferences = true;
    this.updateScroll.emit(false);

    if (list === 'teamsOpened') {
      this.showMoreTeams = false;
      this.teamChannelsShowLimit = 10;
    } else if (list === 'sharedInboxesOpened') {
      this.showMoreSharedInboxes = false;
      this.sharedInboxChannelsShowLimit = 10;
    } else if (list === 'personalInboxesOpened') {
      this.showMorePersonalInboxes = false;
      this.personalInboxChannelsShowLimit = 10;
    } else {
      this.showMorePeople = false;
      this.peopleChannelsShowLimit = 10;
    }

    this._changeDetection.detectChanges();
  }

  toggleDropdown(event, channel) {
    event.preventDefault();
    event.stopPropagation();

    this.hoveredChannel = ContactBaseModel.create(channel);
    let currentTarget: DOMRect = event.currentTarget.getBoundingClientRect();

    this.dropdown?.toggleDropdownFromParent(currentTarget.y + currentTarget.height);
  }

  handleShowMorePeople() {
    this.showMorePeople = true;
    this.peopleChannelsShowLimit = ExpandedChannelListLimit.PEOPLE;
    this._changeDetection.detectChanges();
  }

  handleShowMoreTeams() {
    this.showMoreTeams = true;
    this.teamChannelsShowLimit = ExpandedChannelListLimit.TEAMS;
    this._changeDetection.detectChanges();
  }

  handleShowMoreSharedInboxes() {
    this.showMoreSharedInboxes = true;
    this.sharedInboxChannelsShowLimit = ExpandedChannelListLimit.SHARED_INBOXES;
    this._changeDetection.detectChanges();
  }

  handleShowMorePersonalInboxes() {
    this.showMorePersonalInboxes = true;
    this.personalInboxChannelsShowLimit = ExpandedChannelListLimit.PERSONAL_INBOXES;
    this._changeDetection.detectChanges();
  }

  removeFavorite(favoriteToRemove: ContactModel, $event) {
    // Prevent propagation of click event
    $event.preventDefault();
    $event.stopPropagation();

    // Change state on UI - remove user from list
    let i = this.favoriteChannels.findIndex(favorite => {
      return favorite.id === favoriteToRemove.id;
    });
    this.favoriteChannels.splice(i, 1);

    this.anonymousSubscriptions.push(
      this._contactService
        .unfavoriteContact(this._userManagerService.getCurrentUserEmail(), favoriteToRemove.id)
        .pipe(
          tap(() => {
            this._trackingService.trackFavorite(
              this._userManagerService.getCurrentUserEmail(),
              false,
              'Favorites Sidebar'
            );

            this._notificationsService.setInAppNotification(this._userManagerService.getCurrentUserEmail(), {
              type: NotificationEventType.ContactRemovedFromFavorites,
              msg: `;$;{favoriteToRemove.name;} removed; from; favorites.`
            });
          })
        )
        .subscribe()
    );
  }

  newChat($event) {
    $event.stopPropagation();
    this._trackingService.trackCreateResources(this._userManagerService.getCurrentUserEmail(), 'people');
    this._newMessageModalService.open();
  }

  createNewSharedInbox() {
    this._trackingService.trackCreateResources(this._userManagerService.getCurrentUserEmail(), 'shared inbox');
    this._redirectService.navigateTo('/user-settings/shared-inboxes/create');
  }

  createNewPersonalInbox() {
    this._trackingService.trackCreateResources(this._userManagerService.getCurrentUserEmail(), 'personal inbox');
    this._redirectService.navigateTo('/user-settings/personal-inboxes/create');
  }

  deleteChatee($event, chatee: ContactModel) {
    $event.preventDefault();
    $event.stopPropagation();

    // Change state on UI - remove user from list
    let i = this.peopleChannels.findIndex(contact => {
      return contact.id === chatee.id;
    });
    this.peopleChannels.splice(i, 1);

    let currentUserEmail = this._userManagerService.getCurrentUserEmail();

    this.anonymousSubscriptions.push(
      this._contactService
        .removeContactFromPeople(currentUserEmail, chatee.id)
        .pipe(
          tap(() => {
            this._trackingService.trackFavorite(currentUserEmail, false, 'People Sidebar');

            this._notificationsService.setInAppNotification(currentUserEmail, {
              type: NotificationEventType.ContactRemovedFromPeople,
              msg: `;$;{chatee.name;} removed; from; people; section.`
            });
          })
        )
        .subscribe()
    );
  }

  hasValidAvailabilityStatus(contact: ContactModel): boolean {
    if (!contact || !(contact instanceof ContactBaseModel)) {
      return false;
    }

    return contact.hasValidAvailabilityStatus();
  }

  ///////////////////
  // Private methods
  ///////////////////
  private resetData() {
    this.favoriteChannels = [];
    this.peopleChannels = [];
    this.teamChannels = [];
    this.sharedInboxChannels = [];
    this.personalInboxChannels = [];
    this.initialLoad = true;
    this.currentUserEmail = '';
  }

  private navigateToChannel(channel: ContactModel) {
    if (isGroupModel(channel)) {
      this._redirectService.quickJump(channel, undefined, channel.groupType === GroupType.NORMAL);
    } else {
      this._redirectService.quickJump(channel, undefined, true);
    }
  }

  private subscribeToOpenFavorite() {
    this._electronService.ipcRenderer.on(IPC.OPEN_FAVORITE, this.handleOpenFavorite);
  }

  private handleOpenFavorite = (event, favorite: ContactBase) => {
    this.ngZone.run(() => {
      this.navigateToChannel(ContactBaseModel.create(favorite));
    });
  };

  private setExpansionStates() {
    if (this.hasUserPreferences) {
      return;
    }

    // User has favorites (not new user)
    if (!_.isEmpty(this.favoriteChannels)) {
      this.channelsListPreferences.favoritesOpened = true;
      this.channelsListPreferences.peopleOpened = false;
      this.channelsListPreferences.sharedInboxesOpened = false;
      this.channelsListPreferences.teamsOpened = false;
    } else {
      this.channelsListPreferences.favoritesOpened = false;
      this.channelsListPreferences.peopleOpened = !_.isEmpty(this.peopleChannels);
      this.channelsListPreferences.sharedInboxesOpened = !_.isEmpty(this.sharedInboxChannels);
      this.channelsListPreferences.personalInboxesOpened = !_.isEmpty(this.personalInboxChannels);
      this.channelsListPreferences.teamsOpened = !_.isEmpty(this.teamChannels);
    }
  }

  private ensureSelectedChannelHasExpandedList() {
    let { routeParts } = this._routerService.getCurrentRouteData();

    if (routeParts.at(0) !== SupportedRouteParts.Channel) {
      return;
    }

    let channelId = routeParts.at(1);

    if (this.lastRouteChannelId === channelId && this.wasTabWithChannelOpened) {
      return;
    }

    this.lastRouteChannelId = channelId;

    if (!channelId) {
      return;
    }

    this.wasTabWithChannelOpened = true;
    let { sharedInboxesOpened, teamsOpened, peopleOpened, favoritesOpened, personalInboxesOpened } =
      this.channelsListPreferences;

    if (!sharedInboxesOpened && this.sharedInboxChannels.some(c => c._id === channelId)) {
      this.channelsListPreferences.sharedInboxesOpened = true;
      this.persistUserPreferences();
      this.triggerChangeDetection();
    } else if (!personalInboxesOpened && this.personalInboxChannels.some(c => c._id === channelId)) {
      this.channelsListPreferences.personalInboxesOpened = true;
      this.persistUserPreferences();
      this.triggerChangeDetection();
    } else if (!teamsOpened && this.teamChannels.some(c => c._id === channelId)) {
      this.channelsListPreferences.teamsOpened = true;
      this.persistUserPreferences();
      this.triggerChangeDetection();
    } else if (!peopleOpened && this.peopleChannels.some(c => c._id === channelId)) {
      this.channelsListPreferences.peopleOpened = true;
      this.persistUserPreferences();
      this.triggerChangeDetection();
    } else if (!favoritesOpened && this.favoriteChannels.some(c => c._id === channelId)) {
      this.channelsListPreferences.favoritesOpened = true;
      this.persistUserPreferences();
      this.triggerChangeDetection();
    } else {
      this.wasTabWithChannelOpened = false;
    }
  }

  private persistUserPreferences() {
    this._userPreferencesService.setCurrentUserPreference(StorageKey.menuChannelsList, this.channelsListPreferences);
  }

  private getSideBarUserModelsIds(): string[] {
    let userIds = [];

    _.forEach(this.favoriteChannels, contact => {
      if (contact.$type === UserModel.type) {
        userIds.push(contact.id);
      }
    });

    _.forEach(this.peopleChannels, contact => {
      if (contact.$type === UserModel.type) {
        userIds.push(contact.id);
      }
    });

    return userIds;
  }

  /////////////////
  // Subscriptions
  /////////////////
  private subscribeAndRegisterKeyboardShortcuts() {
    this.keyboardShortcutsSub?.unsubscribe();
    this.keyboardShortcutsSub = this._keyboardShortcutsListenerService
      .getActionsByTypes(this.handledKeyboardActions)
      .pipe(
        tap((action: KeyboardShortcutData) => {
          switch (action.action.type) {
            case KeyboardActionType.CREATE_TEAM:
              this.addTeam();
              break;
            case KeyboardActionType.CREATE_SHARED_INBOX:
              this.createNewSharedInbox();
              break;
            default:
              break;
          }
        })
      )
      .subscribe();
  }

  private subscribeToSideBarChanges() {
    this.sideBarChangesSub?.unsubscribe();
    this.sideBarChangesSub = this.currentUserIdsSub
      .asObservable()
      .pipe(
        tap((ids: string[]) => {
          if (!_.isEmpty(_.xor(ids, this.currentUserIds))) {
            this.subscribeToUserAvailabilities(ids);
          }
          this.currentUserIds = ids;
        })
      )
      .subscribe();
  }

  private subscribeToRouterEvents() {
    this.routerEventsSub?.unsubscribe();
    this.routerEventsSub = this._router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        debounceTime(200),
        tap(() => this.ensureSelectedChannelHasExpandedList())
      )
      .subscribe();
  }

  private subscribeToOptimisticResponseChanges() {
    this.anonymousSubscriptions.push(
      SharedSubjects._unreadStateChange$
        .pipe(
          filter((state: UnreadStateChange) => state.optimisticResponse),
          tap(() => {
            this.optimisticResponseInProgress = true;
          }),
          debounceTime(2000),
          tap(() => {
            this.optimisticResponseInProgress = false;
            this.debounceUnreadChangeSubject.next(true);
          })
        )
        .subscribe()
    );
  }

  private subscribeToUnreads() {
    this.unreadSub?.unsubscribe();
    this.unreadSub = this._userManagerService
      .onAllBlockingSyncDone(this.currentUserEmail)
      .pipe(
        switchMap(() => {
          return merge(this._userManagerService.userSwitch$, timer(1 * 1000, CONSTANTS.BADGE_COUNT_PULL_PERIOD));
        }),
        debounce(() => (this.optimisticResponseInProgress ? this.debounceUnreadChangeSubject : of({}))),
        filter(() => !this.offline),
        exhaustMap(() => {
          return of(undefined).pipe(
            mergeMap(() => {
              return this._contactService.getSidebarContacts(this.currentUserEmail);
            }),
            mergeMap((sidebarContacts: SidebarContacts) => {
              return this._menuChannelsListService.decorateContactsWithUnreadCount(
                this._userManagerService.getCurrentUserEmail(),
                sidebarContacts,
                true
              );
            })
          );
        }),
        /**
         * Trigger unread change
         */
        tap(() => {
          SharedSubjects._unreadStateChange$.next(new UnreadStateChange(this.currentUserEmail));
        })
      )
      .subscribe();
  }

  private getCurrentUsersAvailabilityStatuses() {
    this.currentUserAvailabilitySub?.unsubscribe();
    this.currentUserAvailabilitySub = this._userAvailabilityStatusService
      .findAllUserAvailabilityStatuses(this._userManagerService.getCurrentUserEmail())
      .pipe(
        tap((userAvailabilities: UserAvailabilityStatusModel[]) => {
          this.userAvailabilitiesByUserId = _.keyBy(userAvailabilities, 'userId');
        })
      )
      .subscribe();
  }

  private subscribeToUserAvailabilities(userIds: string[]) {
    this.userAvailabilitySub?.unsubscribe();
    this.userAvailabilitySub = this._userAvailabilityStatusSubscribedService
      .subscribeToUserAvailabilityStatuses(this.currentUserEmail, userIds, true)
      .pipe(
        tap((event: UserAvailabilityStatusSubscriberEvent) => {
          let userAvailabilities = UserAvailabilityStatusSubscriberService.processUserAvailabilityStatusSubscriberEvent(
            event,
            Object.values(this.userAvailabilitiesByUserId)
          );
          this.userAvailabilitiesByUserId = _.keyBy(userAvailabilities, 'userId');

          this.triggerChangeDetection();
        }),
        retry({ count: 3, delay: () => timer(2000) }),
        catchError(err => {
          // Don't let error break anything

          Logger.error(err, 'Error in Account Dropdown component');

          return EMPTY;
        })
      )
      .subscribe();
  }

  private subscribeToContacts() {
    this.contactsSub?.unsubscribe();
    this.contactsSub = this._userManagerService.userSwitch$
      .pipe(
        tap(() => {
          this.resetData();
          this.currentUserEmail = this._userManagerService.getCurrentUserEmail();
          this.getUserPreference();
          this.setExpansionStates();
        }),
        switchMap(() => {
          return this._menuChannelsListService.getSidebarContacts();
        }),
        tap((contacts: SidebarContacts) => {
          this.favoriteChannels = contacts.favoriteChannels.sort((a, b) => {
            if (a.$type === UserModel.type && b.$type === GroupModel.type) {
              return -1;
            }

            if (a.$type === GroupModel.type && b.$type === UserModel.type) {
              return 1;
            }

            if (a.$type === GroupModel.type && b.$type === GroupModel.type) {
              if (
                (<GroupModel>a).groupType === GroupType.NORMAL &&
                (<GroupModel>b).groupType === GroupType.SHARED_INBOX
              ) {
                return 1;
              }

              if (
                (<GroupModel>a).groupType === GroupType.SHARED_INBOX &&
                (<GroupModel>b).groupType === GroupType.NORMAL
              ) {
                return -1;
              }
            }
          });
          this.peopleChannels = contacts.peopleChannels;
          this.teamChannels = contacts.teamChannels;
          this.sharedInboxChannels = contacts.sharedInboxChannels;
          this.personalInboxChannels = contacts.personalInboxChannels;

          this.ensureSelectedChannelHasExpandedList();
          this.currentUserIdsSub.next(this.getSideBarUserModelsIds());

          this.updateScroll.emit(false);
          this._changeDetection.detectChanges();
        }),
        catchError(err => {
          Logger.error(err, `;Error in $;{this.componentName;}.subscribeToContacts()`, LogTag.INTERESTING_ERROR, true);
          return of(undefined);
        })
      )
      .subscribe();
  }

  private subscribeToConnectionStatus() {
    this.connectionStatusSub?.unsubscribe();
    this.connectionStatusSub = this._appStateService.connectionStatus$
      .pipe(
        tap((connectionStatus: ConnectionStatus) => {
          this.offline = !connectionStatus.connectionActive;
          this.triggerChangeDetection();
        })
      )
      .subscribe();
  }

  protected triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }
}

interface IMenuChannelsListPreferences {
  favoritesOpened: boolean;
  peopleOpened: boolean;
  teamsOpened: boolean;
  sharedInboxesOpened: boolean;
  personalInboxesOpened: boolean;
}

export enum ExpandedChannelListLimit {
  FAVORITES = 1024,
  SHARED_INBOXES = 1024,
  PERSONAL_INBOXES = 1024,
  TEAMS = 1024,
  PEOPLE = 20
}
