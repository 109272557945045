import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAuthService } from '@shared/modules/auth/auth-data-access/user-auth.service';

@NgModule({
  imports: [CommonModule]
})
export class AuthDataAccessModule {
  static forRoot(): ModuleWithProviders<AuthDataAccessModule> {
    return {
      ngModule: AuthDataAccessModule,
      providers: [UserAuthService]
    };
  }
}
