import * as _ from 'lodash';
import * as moment from 'moment';
import { catchError, EMPTY, forkJoin, from, Observable, of, switchMap, throwError } from 'rxjs';
import { ApiService } from '@shared/api/api-loop/api.module';
import { EventList } from '@shared/api/api-loop/models/event-list';
import { EventTagsUpdated } from '@shared/api/api-loop/models/event-tags-updated';
import { EventBase } from '@shared/api/api-loop/models/event-base';
import { EventCommentCreated } from '@shared/api/api-loop/models/event-comment-created';
import { EventCardCreated } from '@shared/api/api-loop/models/event-card-created';
import { CardBase } from '@shared/api/api-loop/models/card-base';
import { Logger } from '@shared/services/logger/logger';
import { EventUserOnboardingCompleted } from '@shared/api/api-loop/models/event-user-onboarding-completed';
import { CommentBase } from '@shared/api/api-loop/models/comment-base';
import { EventSignatureCreated } from '@shared/api/api-loop/models/event-signature-created';
import { EventTagCreated } from '@shared/api/api-loop/models/event-tag-created';
import {
  AvailabilityUserStatus,
  CardType,
  ContactBase,
  EventCardAuthorizationUpdated,
  EventCardUpdated,
  EventCommentUpdated,
  EventContactBase,
  EventContactCreated,
  EventFolderUpdated,
  EventSharedTagCreated,
  EventSharedTagDeleted,
  EventSharedTagsUpdated,
  EventSharedTagUpdated,
  EventSignatureDeleted,
  EventSignatureUpdated,
  EventTagDeleted,
  EventTagUpdated,
  EventUserAvailabilityStatusChanged,
  EventUserSettingsUpdated,
  ListOfTags,
  TagType,
  User
} from '@shared/api/api-loop/models';
import { EventAccountTokenInvalid } from '@shared/api/api-loop/models/event-account-token-invalid';
import { EventModel } from './event-model/event.model';
import {
  EventGroupsModel,
  OtherEventGroups,
  SharedTagsUpdatedEventGroups,
  TagsUpdatedEventGroups
} from './event-model/event-groups.model';
import { EventSyncSettingsUpdated } from '@shared/api/api-loop/models/event-sync-settings-updated';
import {
  bufferCount,
  concatMap,
  defaultIfEmpty,
  distinct,
  filter,
  groupBy,
  map,
  mergeMap,
  tap,
  toArray
} from 'rxjs/operators';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { EventWorkspaceUpdated } from '@shared/api/api-loop/models/event-workspace-updated';
import { EventCache, SupportedCacheTypes } from './event-sync-cache';
import { DataServiceShared } from '@shared/services/data/data.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CardBaseModel, CardChatModel, CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CommentBaseModel, CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import {
  ContactBaseModel,
  ContactModel,
  GroupModel,
  UserModel
} from '@dta/shared/models-api-loop/contact/contact.model';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { SignatureModel } from '@dta/shared/models-api-loop/signature.model';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { StateUpdates } from '@dta/shared/models/state-updates';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import {
  ClearStorageCache,
  DesktopNotificationEventWrapper,
  ModelRemoved,
  PublishEventType
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SynchronizationStatusType } from '../synchronization-status.service';
import { DatabaseService } from '@shared/database/database.service';
import { isWebApp, Time } from '@dta/shared/utils/common-utils';
import { CONSTANTS } from '@shared/models/constants/constants';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { SharedTagFolderModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { CLASSIFICATION_SHARED_TAG_CACHE_NAME } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/smart-classification-shared-tag-api';

export class EventSynchronizationService {
  constructor(
    private _userEmail: string,
    private _data: DataServiceShared,
    private _api: ApiService,
    private _notificationsService: NotificationsService,
    private _sharedUserManagerService: SharedUserManagerService,
    private _settingsService: SettingsService,
    private _db: DatabaseService,
    private _time: Time,
    private _storageService: StorageService
  ) {}

  get constructorName(): string {
    return 'EventSynchronizationService';
  }

  get userEmail(): string {
    return this._userEmail;
  }

  processEventList(eventList: EventList, syncType: SynchronizationStatusType): Observable<EventList> {
    this.removeUnsupportedEvents(eventList);
    if (!eventList || _.isEmpty(eventList.resources)) {
      return of(eventList);
    }

    let watch = new StopWatch(this.constructorName + '.processEventList', ProcessType.SERVICE, this._userEmail);
    watch.log('processing ' + eventList.resources.length + ' events');

    let stateUpdates = new StateUpdates();
    let eventGroups: EventGroupsModel;
    let sessionCache = new EventCache();

    return this.buildEventGroups(eventList.resources, watch).pipe(
      tap((_eventGroups: EventGroupsModel) => {
        eventGroups = _eventGroups;
      }),
      /**
       * CREATE CACHE FOR CURRENT BATCH
       */
      mergeMap(() => {
        return this.cacheEvents(eventGroups, sessionCache);
      }),
      /**
       * CARD CREATED
       */
      mergeMap(() => {
        eventGroups.cardCreated.length && watch.log('processCardCreatedEvents');
        return this.processCardCreatedEvents(eventGroups.cardCreated, sessionCache);
      }),
      /**
       * COMMENT CREATED
       */
      mergeMap(() => {
        eventGroups.commentCreated.length && watch.log('processCommentCreatedEvents');
        return this.processCommentCreatedEvents(eventGroups.commentCreated, syncType, sessionCache);
      }),
      /**
       * TAGS UPDATED
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.tagsUpdated.all.length && watch.log('processTagsUpdatedEvents');
        return this.processTagsUpdatedEvents(eventGroups.tagsUpdated, _stateUpdates.comments, syncType);
      }),
      /**
       * SHARED-TAGS UPDATED
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.sharedTagsUpdated.all.length && watch.log('processSharedTagsUpdatedEvents');
        return this.processSharedTagsUpdatedEvents(eventGroups.sharedTagsUpdated);
      }),
      /**
       * OTHER
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.others.all.length && watch.log('processOtherEventList');
        return this.processOtherEventList(eventGroups.others, syncType, sessionCache);
      }),
      tap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);
      }),
      /**
       * PUBLISH ALL SYNCED DATA
       */
      tap(() => {
        watch.log('Publishing data');
        this.publishStateUpdates(stateUpdates);
      }),
      /**
       * PERSIST DB TO DISK
       */
      mergeMap(() => {
        return this._db.saveThrottled();
      }),
      tap((persisted: boolean) => {
        eventList['persisted'] = persisted;

        persisted && watch.log('DB persisted');
      }),
      map(() => {
        watch.log('finished processing ' + eventList.resources.length + ' events');
        return eventList;
      }),
      /**
       * CLEAR CACHE FOR CURRENT BATCH
       */
      tap(() => {
        this.clearCache(sessionCache);
      })
    ) as Observable<EventList>;
  }

  private removeUnsupportedEvents(eventList: EventList) {
    if (!eventList || !eventList.resources) {
      return;
    }

    _.remove(eventList.resources, (event: EventBase) => {
      switch (event.$type) {
        case EventType.CardCreated:
        case EventType.CardUpdated:
          let cardCreated = <EventCardCreated>event;
          return !CardBaseModel.isSupported(cardCreated.card);
        case EventType.ContactCreated:
        case EventType.ContactUpdated:
          let contactCreated = <EventContactCreated>event;
          return !ContactBaseModel.isSupported(contactCreated.contact);
        default:
          return false;
      }
    });
  }

  private buildEventGroups(events: EventBase[], watch: StopWatch): Observable<EventGroupsModel> {
    watch.log('normalizing events', events.length);
    return this.normalizeEvents(events).pipe(
      map((_events: EventBase[]) => {
        let eventGroups = EventGroupsModel.buildEventGroups(_events);

        let counts = {
          cardCreated: _.size(eventGroups.cardCreated),
          commentCreated: _.size(eventGroups.commentCreated),
          tagsUpdated: eventGroups.tagsUpdated && {
            comments: _.size(eventGroups.tagsUpdated.comments),
            cards: _.size(eventGroups.tagsUpdated.cards),
            contacts: _.size(eventGroups.tagsUpdated.contacts)
          },
          sharedTagsUpdated: eventGroups.sharedTagsUpdated && {
            cards: _.size(eventGroups.sharedTagsUpdated.cards),
            comments: _.size(eventGroups.sharedTagsUpdated.comments)
          },
          others: eventGroups.others && {
            cards: _.size(eventGroups.others.cards),
            contacts: _.size(eventGroups.others.contacts),
            signatures: _.size(eventGroups.others.signatures),
            tags: _.size(eventGroups.others.tags),
            others: _.size(eventGroups.others.others)
          }
        };
        watch.log('processing events', _events.length, JSON.stringify(counts, null, 2));

        return eventGroups;
      })
    );
  }

  private publishStateUpdates(stateUpdates: StateUpdates) {
    if (_.isEmpty(stateUpdates)) {
      return;
    }

    PublisherService.publishEvent(this._userEmail, stateUpdates.all);
    PublisherService.publishEvent(this._userEmail, stateUpdates.remove, PublishEventType.Remove);
  }

  private processCardCreatedEvents(
    cardCreatedEvents: EventCardCreated[],
    sessionCache: EventCache
  ): Observable<StateUpdates> {
    if (_.isEmpty(cardCreatedEvents)) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(this.constructorName + '.processCardCreatedEvents', ProcessType.SERVICE, this._userEmail);

    return from(cardCreatedEvents).pipe(
      map((event: EventCardCreated) => {
        return event.card;
      }),
      tap((card: CardBase) => {
        this.updateWithCachedSharedTags(card, sessionCache);
      }),
      distinct((card: CardBase) => {
        return card.id;
      }),
      toArray(),
      mergeMap((cards: CardBase[]) => {
        watch.log('filterAndUpdateNewChatCards');

        let { chatCardsWithoutClientId, otherCards } = _.groupBy(cards, c =>
          c.$type === CardChatModel.type && !c.clientId ? 'chatCardsWithoutClientId' : 'otherCards'
        );

        return forkJoin([
          this._data.CardService.filterNewCards(this._userEmail, otherCards || []),
          this.updateChatCardsWithClientId(chatCardsWithoutClientId || [])
        ]);
      }),
      mergeMap(([newCards, updatedCards]: CardBase[][]) => {
        watch.log('saveOnly/saveAll');
        return forkJoin([
          this._data.CardService.saveOnly(this._userEmail, CardBaseModel.createList(newCards)),
          this._data.CardService.saveAll(this._userEmail, CardBaseModel.createList(updatedCards))
        ]);
      }),
      map(([newCards, stateUpdates]: [CardModel[], StateUpdates]) => {
        watch.log('done');
        stateUpdates.add(newCards);
        return stateUpdates;
      })
    ) as Observable<StateUpdates>;
  }

  private processCommentCreatedEvents(
    commentCreatedEvents: EventCommentCreated[],
    syncType: SynchronizationStatusType,
    sessionCache: EventCache
  ): Observable<StateUpdates> {
    if (_.isEmpty(commentCreatedEvents)) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(
      this.constructorName + '.processCommentCreatedEvents',
      ProcessType.SERVICE,
      this._userEmail
    );

    return from(commentCreatedEvents).pipe(
      map((event: EventCommentCreated) => {
        return event.comment;
      }),
      /**
       * TODO: temporary until BE fix
       */
      filter((comment: CommentBase) => {
        return comment && comment.$type !== 'CommentBase';
      }),
      distinct((comment: CommentBase) => {
        return comment.id;
      }),
      tap((comment: CommentBase) => {
        if (comment.parent?.id) {
          const cacheClearRequest = new ClearStorageCache();
          cacheClearRequest.storageName = {
            storageName: 'smartCommentsApi',
            keyToDelete: comment.parent?.id
          };
          SharedSubjects._clearStorageCache.next(cacheClearRequest);
        }
      }),
      toArray(),
      /**
       * Workaround for the workaround in push-sync service when sending comments
       * Update tags if they have higher revision than already synced comments
       */
      mergeMap((comments: CommentBase[]) => {
        watch.log('filterNewComments');
        return this._data.CommentService.filterNewCommentsAndUpdateTagsWithHigherRevision(this._userEmail, comments);
      }),
      mergeMap((comments: CommentBase[]) => {
        watch.log('updateCommentsWithTags');
        return this.updateCommentsWithTags(comments, sessionCache);
      }),
      mergeMap((comments: CommentBase[]) => {
        watch.log('addComments');
        return this.addComments(comments, syncType, watch);
      }),
      tap((stateUpdates: StateUpdates) => {
        watch.log('publishDesktopNotifications');
        this.publishDesktopNotifications(commentCreatedEvents, stateUpdates.comments, syncType);
      }),
      defaultIfEmpty(new StateUpdates()),
      tap(() => {
        watch.log('Finished processCommentCreatedEvents');
      })
    ) as Observable<StateUpdates>;
  }

  private updateChatCardsWithClientId(chatCards: CardBase[]): Observable<CardBase[]> {
    let chatCardsByEmail: { [email: string]: CardChatModel } = chatCards.reduce((acc, card: CardChatModel) => {
      let user = <UserModel>card.shareList.resources.find(u => (<UserModel>u).email !== this._userEmail);
      let userEmail = user?.email;

      if (userEmail) {
        acc[userEmail] = card;
      }

      return acc;
    }, {});

    if (_.isEmpty(chatCardsByEmail)) {
      return of(chatCards);
    }

    return this._data.CardService.findLocalChatCardsByShareList(this._userEmail, Object.keys(chatCardsByEmail)).pipe(
      mergeMap((dbCards: CardChatModel[]) => {
        return from(dbCards);
      }),
      tap((dbCard: CardChatModel) => {
        if (!dbCard?.clientId) {
          return;
        }

        let user = <UserModel>dbCard.shareList.resources.find(u => (<UserModel>u).email !== this._userEmail);

        let cardWithUserOnSharelist = chatCardsByEmail[user?.email];

        if (cardWithUserOnSharelist) {
          // We mutate chatCard
          cardWithUserOnSharelist['clientId'] = dbCard.clientId;
        }
      }),
      toArray(),
      /**
       * Return mutated chatCards
       */
      map(() => chatCards)
    );
  }

  private updateCommentsWithTags(comments: CommentBase[], sessionCache: EventCache): Observable<CommentBase[]> {
    if (sessionCache.isCacheEmpty(SupportedCacheTypes.TAGS)) {
      return of(comments);
    }

    return from(comments).pipe(
      map((comment: CommentBase) => {
        this.updateWithCachedTags(comment, sessionCache);

        return comment;
      }),
      toArray()
    );
  }

  private updateWithCachedTags(resource: CommentBase | CardBase | ContactBase, sessionCache: EventCache) {
    let cachedEvent = sessionCache.getEvent(SupportedCacheTypes.TAGS, resource.id);

    let cardTagRevision = resource.tags ? parseInt(resource.tags.revision) : 0;

    if (cachedEvent && parseInt(cachedEvent.tags.revision) > cardTagRevision) {
      resource.tags = cachedEvent.tags;
    }
  }

  private updateWithCachedSharedTags(resource: CardBase, sessionCache: EventCache) {
    let cachedEvent = sessionCache.getEvent(SupportedCacheTypes.SHARED_TAGS, resource.id);

    let cardTagRevision = resource.sharedTags ? parseInt(resource.sharedTags.revision) : 0;

    if (cachedEvent && parseInt(cachedEvent.tags.revision) > cardTagRevision) {
      resource.sharedTags = cachedEvent.tags;
    }
  }

  private cacheEvents(eventGroups: EventGroupsModel, sessionCache: EventCache): Observable<any> {
    return of(undefined).pipe(
      /**
       * Tags
       */
      mergeMap(() => {
        return this.cacheTagsUpdatedEvents(eventGroups.tagsUpdated.all, sessionCache, SupportedCacheTypes.TAGS);
      }),
      /**
       * Shared tags
       */
      mergeMap(() => {
        return this.cacheTagsUpdatedEvents(
          eventGroups.sharedTagsUpdated.all,
          sessionCache,
          SupportedCacheTypes.SHARED_TAGS
        );
      })
    );
  }

  private clearCache(sessionCache: EventCache) {
    sessionCache.clearAll();
  }

  private cacheTagsUpdatedEvents(
    tagsUpdatedEvents: EventTagsUpdated[],
    sessionCache: EventCache,
    cacheType: SupportedCacheTypes
  ): Observable<EventTagsUpdated[]> {
    if (_.isEmpty(tagsUpdatedEvents)) {
      return of(tagsUpdatedEvents);
    }

    /**
     * Update cached event only if it doesn't exist or has higher revision
     */
    return from(tagsUpdatedEvents).pipe(
      tap((event: EventTagsUpdated) => {
        let key = event.tags.parent.id;
        let cachedEvent = sessionCache.getEvent(cacheType, key);

        if (!cachedEvent || BaseModel.isRevisionGreaterThan(event.tags, cachedEvent.tags)) {
          sessionCache.cacheEvent(cacheType, key, event);
        }
      }),
      toArray()
    );
  }

  private addComments(
    comments: CommentBase[],
    syncType: SynchronizationStatusType,
    watch: StopWatch
  ): Observable<StateUpdates> {
    if (_.isEmpty(comments)) {
      return of(new StateUpdates());
    }

    let stateUpdates: StateUpdates;

    watch.log('prepareComments');
    return this.prepareComments(comments, syncType, watch).pipe(
      tap((_stateUpdates: StateUpdates) => {
        stateUpdates = _stateUpdates;

        let msg = _.template('Synced <%= comments %> comments and <%= cards %> cards')({
          comments: stateUpdates.comments.length,
          cards: stateUpdates.cards.length,
          contacts: stateUpdates.contacts.length
        });
        watch.log(msg);
      }),
      mergeMap(() => {
        watch.log('save contacts');
        return this._data.ContactService.saveAllAndPublish(this._userEmail, stateUpdates.contacts);
      }),
      mergeMap(() => {
        watch.log('save comments');
        return this._data.CommentService.saveAllAndPublish(this._userEmail, stateUpdates.comments).pipe(
          // State updates for comments need to be override with the result of
          // saveAll. They will have correctly populated contacts
          tap((updatedComments: CommentModel[]) => {
            stateUpdates.add(updatedComments);
          })
        );
      }),
      mergeMap(() => {
        watch.log('save cards');
        return this._data.CardService.saveAll(this._userEmail, stateUpdates.cards);
      }),
      map((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        watch.log('finished addComments');
        return stateUpdates;
      })
    );
  }

  private prepareComments(
    comments: CommentBase[],
    syncType: SynchronizationStatusType,
    watch: StopWatch
  ): Observable<StateUpdates> {
    let stateUpdates = new StateUpdates();
    let _commentsModel: CommentModel[] = [];

    /**
     * RESOLVE-CARDS
     */
    watch.log('findOrFetchCommentCards');
    return this._data.CardService.findCommentCards(this._userEmail, comments, true).pipe(
      tap((cards: CardModel[]) => {
        stateUpdates.add(cards);
      }),
      /**
       * UPDATE-COMMENTS
       */
      mergeMap((cards: CardModel[]) => {
        watch.log('updateComments');

        let _comments = CommentBaseModel.createList(comments);
        return this.updateComments(_comments, cards, syncType, watch);
      }),
      /**
       * FETCH CONTACTS
       */
      mergeMap((comments: CommentModel[]) => {
        stateUpdates.add(comments);
        _commentsModel = comments;

        watch.log('fetchNewContacts');
        return this._data.ContactService.fetchNewContactsByCommentShareLists(this._userEmail, comments);
      }),
      map(() => {
        return stateUpdates;
      })
    );
  }

  private updateComments(
    comments: CommentModel[],
    cards: CardModel[],
    syncType: SynchronizationStatusType,
    watch: StopWatch
  ): Observable<CommentModel[]> {
    if (syncType === SynchronizationStatusType.PULL_ACTIVE) {
      watch.log('updateCommentsBody');
      return this._data.CommentService.updateCommentsBody(this._userEmail, comments);
    }

    let commentsByUpdateType = _.groupBy(comments, comment => (comment.hasTags() ? 'updateBody' : 'fetch'));
    let updateBodyComments = commentsByUpdateType['updateBody'];
    let fetchComments = commentsByUpdateType['fetch'];
    let result: CommentModel[] = [];

    fetchComments && watch.log('fetchComments');
    return this._data.CommentService.fetchComments(this._userEmail, fetchComments).pipe(
      tap((comments: CommentModel[]) => {
        result.push(...comments);
      }),
      mergeMap(() => {
        updateBodyComments && watch.log('updateCommentsBody');
        return this._data.CommentService.updateCommentsBody(this._userEmail, updateBodyComments);
      }),
      tap((comments: CommentModel[]) => {
        if (comments) {
          result.push(...comments);
        }
      }),
      // TODO: remove when BE fixes comment.parent.clientId references
      /**
       * UPDATE PARENT REFERENCES
       */
      mergeMap(() => {
        watch.log('updateCommentParentReferences');
        return this.updateCommentParentReferences(result, cards);
      })
    );
  }

  private updateCommentParentReferences(comments: CommentModel[], cards: CardModel[]): Observable<CommentModel[]> {
    let cardRefs = _.map(cards, card => CardBaseModel.buildAsReference(card));
    let cardsRefsMap = _.keyBy(cardRefs, 'id');

    // filter-out comments whose parent card could not be resolved (when fetching a card fails with HTTP/403)
    return from(comments).pipe(
      filter((comment: CommentModel) => {
        if (!comment.parent) {
          return false;
        }

        comment.parent = cardsRefsMap[comment.parent.id];

        if (!comment.parent) {
          Logger.customLog(
            `EventSync [${this._userEmail}]: Could not resolve comment parent reference commentId:${comment.id} parentId:${comment.parent}`,
            LogLevel.WARN,
            LogTag.SYNC
          );
          return false;
        }

        return true;
      }),
      toArray()
    );
  }

  private publishDesktopNotifications(
    commentCreatedEvents: EventCommentCreated[],
    comments: CommentModel[],
    syncType: SynchronizationStatusType
  ) {
    if (!syncType || syncType !== SynchronizationStatusType.PULL_ACTIVE) {
      return;
    }

    let commentsByIdMap = _.keyBy(comments, 'id');

    // populate notifications with resolved Comments
    let filteredCommentCreatedEvents = _.filter(commentCreatedEvents, event => {
      if (_.isNil(event.comment)) {
        return false;
      }

      if (event.comment.parent.$type === CardType.CARD_MAIL && !event.comment.parent.id.includes('copy1T')) {
        return false;
      }

      let commentId = event.comment.id;
      let resolvedComment = commentsByIdMap[commentId];

      if (!resolvedComment) {
        return false;
      }

      event.comment = resolvedComment;

      return _.some(resolvedComment.tags.tags.resources, tag => {
        return tag.id === TagType.UNREAD || tag.id === TagType.UNREAD_VIRTUAL;
      });
    });

    // Send comments to desktop notifications service
    let event = new DesktopNotificationEventWrapper();
    event.desktopNotificationEvent = filteredCommentCreatedEvents;
    SharedSubjects._desktopNotifications$.next(event);
  }

  private normalizeEvents(events: EventBase[]): Observable<EventBase[]> {
    if (_.isEmpty(events)) {
      return of(events);
    }

    return from(EventModel.createList(events)).pipe(
      filter((event: EventModel) => {
        let isSupported = EventModel.areEventResourceTypesSupported(event);

        if (!isSupported) {
          console.log(`Event ${event.$type} for ${this._userEmail} is not supported and will not be processed.`);
        }

        return isSupported;
      }),
      groupBy((event: EventModel) => {
        return event._ex.resource ? event._ex.type + ':' + event._ex.resource.id : event.$type;
      }),
      mergeMap(group => {
        return group.pipe(toArray());
      }),
      /**
       * We're only interested in last revision of an event
       */
      map((events: EventModel[]) => {
        return _.sortBy(events, event => {
          return event._ex.resource ? event._ex.revision : event.created;
        });
      }),
      map((events: EventBase[]) => {
        return _.last(events);
      }),
      toArray(),
      defaultIfEmpty([])
    );
  }

  private processTagsUpdatedEvents(
    eventGroups: TagsUpdatedEventGroups,
    comments: CommentModel[],
    syncType: SynchronizationStatusType
  ): Observable<StateUpdates> {
    if (_.isEmpty(eventGroups.all)) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(this.constructorName + '.processTagsUpdatedEvents', ProcessType.SERVICE, this._userEmail);
    let stateUpdates = new StateUpdates();

    eventGroups.comments.length && watch.log('processCommentTagsUpdatedEvents');
    return this.processCommentTagsUpdatedEvents(eventGroups.comments, comments, syncType).pipe(
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.cards.length && watch.log('processCardTagsUpdatedEvents');
        return this.processCardTagsUpdatedEvents(eventGroups.cards);
      }),
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.contacts.length && watch.log('processContactTagsUpdatedEvents');
        return this.processContactTagsUpdatedEvents(eventGroups.contacts);
      }),
      map((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        watch.log('done');
        return stateUpdates;
      })
    );
  }

  /**
   * filters-out TagsUpdated events for matching CommentCreated events
   */
  private filterCommentTagsUpdatedEvents(
    tagsUpdatedEvents: EventTagsUpdated[],
    comments: CommentModel[]
  ): EventTagsUpdated[] {
    return _.differenceWith(tagsUpdatedEvents, comments, (tagsUpdated: EventTagsUpdated, comment: CommentModel) => {
      return (
        tagsUpdated.tags.parent.id === comment.id && BaseModel.isRevisionGreaterThan(comment.tags, tagsUpdated.tags)
      );
    });
  }

  private processCommentTagsUpdatedEvents(
    tagsUpdatedEvents: EventTagsUpdated[],
    comments: CommentModel[],
    syncType: SynchronizationStatusType
  ): Observable<StateUpdates> {
    tagsUpdatedEvents = this.filterCommentTagsUpdatedEvents(tagsUpdatedEvents, comments);
    if (_.isEmpty(tagsUpdatedEvents)) {
      return of(new StateUpdates());
    }

    let stateUpdates = new StateUpdates();
    let tags = _.map(tagsUpdatedEvents, event => event.tags);
    let tagUpdatesForNonLocalModels: EventTagsUpdated[];

    return of(undefined).pipe(
      /**
       * UPDATE LOCAL COMMENTS TAGS
       */
      mergeMap(() => this._data.CommentService.updateCommentsTags(this._userEmail, tags)),
      // Filter out comments that are not locally saved
      tap((localUpdatedComments: CommentModel[]) => {
        tagUpdatesForNonLocalModels = _.differenceWith(
          tagsUpdatedEvents,
          localUpdatedComments,
          (updateEvent, comment) => updateEvent.tags.parent.id === comment.id
        );
      }),
      // Find or fetch cards for newly updated local comments
      mergeMap((localUpdatedComments: CommentModel[]) => {
        stateUpdates.add(localUpdatedComments);
        return this._data.CardService.findCommentCards(this._userEmail, localUpdatedComments);
      }),
      mergeMap((cards: CardModel[]) => this._data.CardService.saveAll(this._userEmail, cards)),
      map((_stateUpdates: StateUpdates) => stateUpdates.mergeWith(_stateUpdates)),
      /**
       * PROCESS MISSING COMMENTS FOR TAGS
       */
      mergeMap(() => this.processMissingCommentsForTags(tagUpdatesForNonLocalModels, syncType)),
      map((_stateUpdates: StateUpdates) => stateUpdates.mergeWith(_stateUpdates))
    );
  }

  private processMissingCommentsForTags(
    tagsUpdatedEvents: EventTagsUpdated[],
    syncType: SynchronizationStatusType
  ): Observable<StateUpdates> {
    if (_.isEmpty(tagsUpdatedEvents)) {
      return of(new StateUpdates());
    }

    // Process only updates for non-local models on WEB
    if (isWebApp()) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(
      this.constructorName + '.processMissingCommentsForTags',
      ProcessType.SERVICE,
      this._userEmail
    );

    return this._time.getServerTime().pipe(
      mergeMap((now: moment.Moment) => {
        let updateParentCreatedCutOffDate = moment(now)
          .subtract(CONSTANTS.TAG_UPDATE_PARENT_CUTOFF_DAYS, 'days')
          .toDate()
          .toISOString();

        // Filter out updates on old models
        let missingComments = tagsUpdatedEvents
          .filter(
            event => !event.parentCreated || new Date(event.parentCreated) >= new Date(updateParentCreatedCutOffDate)
          )
          .map(event => event.tags)
          .map((listOfTags: ListOfTags) => <CommentBase>{ id: listOfTags.parent.id });

        return this._data.CommentService.fetchComments(this._userEmail, missingComments).pipe(
          mergeMap((comments: CommentModel[]) => {
            return this.addComments(comments, syncType, watch);
          })
        );
      })
    );
  }

  private processCardTagsUpdatedEvents(tagsUpdatedEvents: EventTagsUpdated[]): Observable<StateUpdates> {
    if (_.isEmpty(tagsUpdatedEvents)) {
      return of(new StateUpdates());
    }

    let stateUpdates = new StateUpdates();
    let tags = _.map(tagsUpdatedEvents, event => event.tags);

    return this._data.CardService.updateCardsTags(this._userEmail, tags).pipe(
      map((_stateUpdates: StateUpdates) => {
        return stateUpdates.mergeWith(_stateUpdates);
      })
    );
  }

  private processContactTagsUpdatedEvents(tagsUpdatedEvents: EventTagsUpdated[]): Observable<StateUpdates> {
    if (_.isEmpty(tagsUpdatedEvents)) {
      return of(new StateUpdates());
    }

    let stateUpdates = new StateUpdates();
    let tags = _.map(tagsUpdatedEvents, event => event.tags);

    return this._data.ContactService.updateContactsTags(this._userEmail, tags).pipe(
      map((contacts: ContactModel[]) => {
        return stateUpdates.add(contacts);
      })
    );
  }

  private processSharedTagsUpdatedEvents(eventGroups: SharedTagsUpdatedEventGroups): Observable<StateUpdates> {
    if (_.isEmpty(eventGroups.all)) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(
      this.constructorName + '.processSharedTagsUpdatedEvents',
      ProcessType.SERVICE,
      this._userEmail
    );
    let stateUpdates = new StateUpdates();

    eventGroups.cards.length && watch.log('processCardSharedTagsUpdatedEvents');
    return this.processCardSharedTagsUpdatedEvents(eventGroups.cards).pipe(
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);
        eventGroups.comments.length && watch.log('processCommentSharedTagsUpdatedEvents');
        return this.processCommentSharedTagsUpdatedEvents(eventGroups.comments);
      }),
      map((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);
        watch.log('done');
        return stateUpdates;
      })
    );
  }

  private processCardSharedTagsUpdatedEvents(
    sharedTagsUpdatedEvents: EventSharedTagsUpdated[]
  ): Observable<StateUpdates> {
    if (_.isEmpty(sharedTagsUpdatedEvents)) {
      return of(new StateUpdates());
    }

    let tags = _.map(sharedTagsUpdatedEvents, event => event.tags);

    return this._data.CardService.updateCardsSharedTags(this._userEmail, tags);
  }

  private processCommentSharedTagsUpdatedEvents(
    sharedTagsUpdatedEvents: EventSharedTagsUpdated[]
  ): Observable<StateUpdates> {
    if (_.isEmpty(sharedTagsUpdatedEvents)) {
      return of(new StateUpdates());
    }

    let tags = _.map(sharedTagsUpdatedEvents, event => event.tags);
    let stateUpdates = new StateUpdates();

    return this._data.CommentService.updateCommentsSharedTags(this._userEmail, tags).pipe(
      mergeMap((_comments: CommentModel[]) => {
        stateUpdates.add(_comments);
        let parentCardIds = _.map(_comments, comment => comment.parent.id);
        return this._data.CardService.findByIds(this._userEmail, parentCardIds);
      }),
      mergeMap((cards: CardModel[]) => {
        return this._data.CardService.saveAll(this._userEmail, cards);
      }),
      map((updates: StateUpdates) => {
        stateUpdates.add(updates.cards);
        return stateUpdates;
      })
    );
  }

  private processOtherEventList(
    eventGroups: OtherEventGroups,
    syncType: SynchronizationStatusType,
    sessionCache: EventCache
  ): Observable<StateUpdates> {
    if (_.isEmpty(eventGroups.all)) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(this.constructorName + '.processOtherEventList', ProcessType.SERVICE, this._userEmail);

    let stateUpdates = new StateUpdates();

    /**
     * OTHERS
     */
    eventGroups.others.length && watch.log('processOtherEvents');
    return this.processOtherEvents(eventGroups.others, syncType).pipe(
      /**
       * CARDS
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.cards.length && watch.log('processCardUpdatedEvents');
        return this.processCardUpdatedEvents(eventGroups.cards, sessionCache);
      }),
      /**
       * COMMENTS
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.cards.length && watch.log('processCommentUpdatedEvents');
        return this.processCommentUpdatedEvents(eventGroups.comments, syncType, sessionCache);
      }),
      /**
       * CONTACTS
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.contacts.length && watch.log('processContactEvents');
        return this.processContactEvents(eventGroups.contacts, sessionCache);
      }),
      /**
       * SIGNATURES
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.signatures.length && watch.log('processSignatureEvents');
        return this.processSignatureEvents(eventGroups.signatures);
      }),
      /**
       * TAGS (folders)
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.tags && watch.log('processTagEvents');
        return this.processTagEvents(eventGroups.tags);
      }),
      /**
       * TAGS SHARED (folders)
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.folders && watch.log('processFoldersEvents');
        return this.processFoldersEvents(eventGroups.folders);
      }),
      /**
       * USER SETTINGS
       */
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        eventGroups.userSettings && watch.log('processUserStatusUpdate');
        return this.processUserSettingsUpdate(eventGroups.userSettings);
      }),
      map((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        return stateUpdates;
      })
    );
  }

  private processOtherEvents(events: EventBase[], syncType: SynchronizationStatusType): Observable<StateUpdates> {
    if (_.isEmpty(events)) {
      return of(new StateUpdates());
    }

    return from(events).pipe(
      bufferCount(10),
      concatMap((chunk: EventBase[]) => {
        return from(chunk).pipe(
          mergeMap((event: EventBase) => {
            return this.processEvent(event, syncType);
          })
        );
      }),
      toArray(),
      map((models: BaseModel[][]) => {
        return _.compact(_.flatten(models));
      }),
      filter((models: BaseModel[]) => {
        return !_.isEmpty(models);
      }),
      map((models: BaseModel[]) => {
        return new StateUpdates(models);
      }),
      defaultIfEmpty(new StateUpdates())
    );
  }

  private processEvent(event: EventBase, syncType: SynchronizationStatusType): Observable<BaseModel[]> {
    return of(event).pipe(
      mergeMap((event: EventBase) => {
        switch (event.$type) {
          case EventType.UserOnboardingCompleted:
            return this.onUserOnboardingCompleted(event);
          case EventType.AccountTokenInvalid:
            return this.onAccountTokenInvalid(event);
          case EventType.SyncSettingsUpdated:
            return this.onSyncSettingsUpdated(event);
          case EventType.WorkspaceUpdated:
            return this.onWorkspaceUpdated(event);
          case EventType.UserAvailabilityStatusUpdated:
            return this.processUserAvailabilityStatusChanged(event);
          case EventType.UserAvailabilityStatusDeleted:
            return this.processUserAvailabilityStatusDeleted(event);
          case EventType.IntegrationsUpdated:
            return this.processIntegrationsUpdated();
          // Folders
          case EventType.FolderCreated:
          case EventType.FolderUpdated:
            return this.createOrUpdateFolder(event);
          case EventType.FolderDeleted:
            return this.folderDeleted(event);
          case EventType.EventFolderUpdated:
            return this.eventFolderUpdated(event);
          case EventType.EventCardAuthorizationUpdated:
            return this.eventCardAuthorizationUpdated(event);
          default:
            Logger.customLog(
              `EventSync [${this._userEmail}]: unsupported event: ${event.$type}`,
              LogLevel.WARN,
              LogTag.SYNC
            );
            return EMPTY;
        }
      }),
      map((model: BaseModel | BaseModel[]) => {
        return _.castArray(model);
      })
    );
  }

  private processCardUpdatedEvents(events: EventBase[], sessionCache: EventCache): Observable<any> {
    if (_.isEmpty(events)) {
      return of(new StateUpdates());
    }

    return from(events).pipe(
      /**
       * Get all cards from events
       */
      mergeMap((event: EventCardUpdated) => {
        return of(event.card);
      }),
      toArray(),
      /**
       * Filter for passing through cards with greater revision
       */
      mergeMap((cards: CardBase[]) => {
        return this._data.CardService.filterOutNewCardUpdates(this._userEmail, cards);
      }),
      mergeMap((newCardUpdates: CardBase[]) => {
        return from(newCardUpdates);
      }),
      /**
       * Fetch cards with missing comments stub
       */
      mergeMap((card: CardBase) => {
        if (_.has(card, 'comments')) {
          return of(card);
        }

        // only when comments stub is missing from card
        return this._api.CardApiService.Card_Get({ id: card.id }, this._userEmail);
      }),
      /**
       * Update card with cached shared tags from this event list batch
       */
      tap((card: CardBase) => {
        this.updateWithCachedSharedTags(card, sessionCache);
      }),
      map((card: CardBase) => {
        return CardBaseModel.create(card);
      }),
      toArray(),
      mergeMap((cards: CardModel[]) => {
        return this._data.CardService.saveAll(this._userEmail, cards);
      })
    );
  }

  private processCommentUpdatedEvents(
    commentUpdatedEvent: EventCommentUpdated[],
    syncType: SynchronizationStatusType,
    sessionCache: EventCache
  ): Observable<StateUpdates> {
    if (_.isEmpty(commentUpdatedEvent)) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(
      this.constructorName + '.processCommentUpdatedEvents',
      ProcessType.SERVICE,
      this._userEmail
    );

    return from(commentUpdatedEvent).pipe(
      /**
       * Get all comments from events
       */
      mergeMap((event: EventCommentUpdated) => {
        return of(event.comment);
      }),
      toArray(),
      /**
       * Filter for passing through comments with greater revision
       */
      mergeMap((comments: CommentBase[]) => {
        watch.log('filterNewCommentUpdates');
        return this._data.CommentService.filterOutNewCommentUpdates(this._userEmail, comments);
      }),
      /**
       * Update comments with cached tags from this event list batch
       */
      mergeMap((comments: CommentBase[]) => {
        watch.log('updateCommentsWithTags');
        return this.updateCommentsWithTags(comments, sessionCache);
      }),
      mergeMap((comments: CommentBase[]) => {
        watch.log('addComments');
        return this.addComments(comments, syncType, watch);
      }),
      defaultIfEmpty(new StateUpdates()),
      tap(() => {
        watch.log('Finished processCommentUpdatedEvents');
      })
    ) as Observable<StateUpdates>;
  }

  private processContactEvents(events: EventBase[], sessionCache: EventCache): Observable<StateUpdates> {
    if (_.isEmpty(events)) {
      return of(new StateUpdates());
    }

    let add: ContactModel[] = [];
    let remove: ContactModel[] = [];

    return from(events).pipe(
      tap((event: EventContactBase) => {
        let contact = ContactBaseModel.create(event.contact);
        if (!contact) {
          return;
        }

        if (event.$type === EventType.ContactDeleted) {
          Logger.customLog(
            `Got remove event for contact id: ${contact.id} for user: ${this._userEmail}`,
            LogLevel.INFO,
            LogTag.SYNC
          );
          remove.push(contact);
        } else {
          this.updateWithCachedTags(contact, sessionCache);
          if (contact.$type === GroupModel.type) {
            let piIds = this._storageService.getParsedItem(StorageKey.allowedImpersonatedPIIds) || [];
            _.forEach((<GroupModel>contact).allowedImpersonatedSenders?.resources, user => {
              piIds.push(user.id);
            });

            this._storageService.setStringifiedItem(StorageKey.allowedImpersonatedPIIds, _.uniq(piIds));
          }
          add.push(contact);
        }
      }),
      toArray(),
      // Save all contacts. The deleted ones will be marked as such
      mergeMap(() => {
        return this._data.ContactService.filterContactUpdates(this._userEmail, [...add, ...remove]).pipe(
          tap((contactUpdates: ContactModel[]) => {
            this._data.ContactService.removeCachedAvatarForContact(this._userEmail, contactUpdates);
          }),
          mergeMap((contactUpdates: ContactModel[]) => {
            let removedIds = _.intersectionBy(
              _.map(contactUpdates, contact => contact.id),
              _.map(remove, contact => contact.id)
            );
            this._data.ContactService.removeByIds(this._userEmail, removedIds);
            return this._data.ContactService.saveAll(this._userEmail, contactUpdates);
          })
        );
      }),
      map((savedContacts: ContactModel[]) => {
        return new StateUpdates(savedContacts);
      })
    );
  }

  private processSignatureEvents(events: EventBase[]): Observable<StateUpdates> {
    // TODO signature updates
    return of(new StateUpdates());
  }

  private createUserAvailabilityFromEvent(event: EventUserAvailabilityStatusChanged): UserAvailabilityStatusModel {
    let status: AvailabilityUserStatus = {
      status: event.status,
      enabledAssignments: event.enabledAssignments,
      clearAfter: event.clearAfter,
      emoji: event.emoji,
      outOfOffice: event.outOfOffice
    };

    return new UserAvailabilityStatusModel(
      UserAvailabilityStatusModel.createUserAvailabilityFromStatus(event.userId, status)
    );
  }

  private eventFolderUpdated(event: EventFolderUpdated): Observable<any> {
    return this._data.FolderService.fetchAndSaveFoldersForGroup(this._userEmail, event.groupId);
  }

  private createOrUpdateFolder(event: EventSharedTagCreated | EventSharedTagUpdated): Observable<any> {
    return this._data.FolderService.saveAllAndPublish(this._userEmail, [new SharedTagFolderModel(event.tag)]);
  }

  private folderDeleted(event: EventSharedTagDeleted): Observable<any> {
    return this._data.FolderService.removeAllAndPublish(this._userEmail, [new SharedTagFolderModel(event.tag)]);
  }

  private eventCardAuthorizationUpdated(event: EventCardAuthorizationUpdated): Observable<any> {
    return this._data.ConversationService.findByCardId(this._userEmail, event.cardId).pipe(
      mergeMap((conversation: ConversationModel) => {
        return this._data.CardService.fetchCard(this._userEmail, event.cardId).pipe(
          catchError(err => {
            conversation.showInViews = [];
            return this._data.ConversationService.saveAllAndPublish(this._userEmail, [conversation]);
          })
        );
      })
    );
  }

  private processIntegrationsUpdated(): Observable<any> {
    return this._data.IntegrationService.syncIntegrations(this._userEmail);
  }

  private processUserAvailabilityStatusDeleted(event: EventUserAvailabilityStatusChanged): Observable<any> {
    return this._data.UserAvailabilityStatusService.deleteUserAvailabilityStatusLocal(
      this._userEmail,
      this.createUserAvailabilityFromEvent(event)
    );
  }

  private processUserAvailabilityStatusChanged(event: EventUserAvailabilityStatusChanged): Observable<any> {
    return this._data.UserAvailabilityStatusService.saveAllAndPublish(this._userEmail, [
      this.createUserAvailabilityFromEvent(event)
    ]);
  }

  private processUserSettingsUpdate(events: EventBase[]): Observable<StateUpdates> {
    if (_.isEmpty(events)) {
      return of(new StateUpdates());
    }

    // We get user Settings only on active sync. Normalization will take only last update.
    // If there is more than one update, something is wrong
    if (events.length > 1) {
      Logger.customLog('More than one userSettingsUpdate in event page', LogLevel.ERROR, LogTag.SYNC);
    }

    let lastUpdate = <EventUserSettingsUpdated>_.last(events);
    this._settingsService.saveUserSettings(lastUpdate.userSettings, this._userEmail);

    // Fetch user to get latest user profile (i.e. when user name is updated)
    return this._data.UserService.updateSelf(this._userEmail).pipe(map(() => new StateUpdates()));
  }

  private processFoldersEvents(events: EventBase[]): Observable<StateUpdates> {
    if (_.isEmpty(events)) {
      return of(new StateUpdates());
    }

    let add: SharedTagFolderModel[] = [];
    let remove: SharedTagFolderModel[] = [];

    return from(events).pipe(
      tap((event: EventFolderBase) => {
        let folder = new SharedTagFolderModel(event.tag);

        if (event.$type === EventType.FolderDeleted) {
          remove.push(folder);
        } else {
          add.push(folder);
        }
      }),
      toArray(),
      mergeMap(() => {
        return this._data.FolderService.saveAll(this._userEmail, add);
      }),
      mergeMap(() => {
        return this._data.FolderService.removeAll(this._userEmail, remove);
      }),
      map(() => {
        return new StateUpdates(add, remove);
      })
    );
  }

  private processTagEvents(events: EventBase[]): Observable<StateUpdates> {
    if (_.isEmpty(events)) {
      return of(new StateUpdates());
    }

    let add: TagModel[] = [];
    let remove: TagModel[] = [];

    return from(events).pipe(
      tap((event: EventTagBase) => {
        let tag = new TagModel(event.tag);

        if (event.$type === EventType.TagDeleted) {
          remove.push(tag);
        } else {
          add.push(tag);
        }
      }),
      toArray(),
      mergeMap(() => {
        return this._data.TagService.saveAll(this._userEmail, add);
      }),
      mergeMap(() => {
        return this._data.TagService.removeAll(this._userEmail, remove);
      }),
      map(() => {
        return new StateUpdates(add, remove);
      })
    );
  }

  eventsFollowUpSync(): Observable<any> {
    let stateUpdates = new StateUpdates();

    return this._data.CardService.fetchAllMissingCommentsForCards(this._userEmail).pipe(
      tap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);
        this.publishStateUpdates(stateUpdates);
      })
    );
  }

  private onUserOnboardingCompleted(event: EventUserOnboardingCompleted): Observable<any> {
    this._notificationsService.removeInAppNotification(this._userEmail);
    this._sharedUserManagerService.setOnboardingCompleteOnServerByEmail(
      this._userEmail,
      'EventSynchronizationService.onUserOnboardingCompleted'
    );

    return EMPTY;
  }

  private onAccountTokenInvalid(event: EventAccountTokenInvalid): Observable<any> {
    let userEmail = event.recipient.email;
    return this._data.UserService.handleTokenRevokeEvent(userEmail);
  }

  private onSyncSettingsUpdated(event: EventSyncSettingsUpdated): Observable<any> {
    return this._data.UserService.updateLocalSyncSettings(this._userEmail).pipe(mergeMap(() => EMPTY));
  }

  private onWorkspaceUpdated(event: EventWorkspaceUpdated): Observable<any> {
    const cacheClearRequest = new ClearStorageCache();
    cacheClearRequest.storageName = {
      storageName: 'apiCache',
      keyToDelete: '/api/v1/tag/labels'
    };
    SharedSubjects._clearStorageCache.next(cacheClearRequest);

    const sharedTagsCacheClearRequest = new ClearStorageCache();
    sharedTagsCacheClearRequest.storageName = {
      storageName: 'apiCache',
      keyToDelete: '/api/v1/sharedtag/workspaces/labels/list'
    };
    SharedSubjects._clearStorageCache.next(sharedTagsCacheClearRequest);

    const classificationTagsClearRequest = new ClearStorageCache();
    classificationTagsClearRequest.storageName = {
      storageName: 'apiCache',
      keyToDelete: '/api/v1/sharedtag/workspaces/classification/list'
    };
    SharedSubjects._clearStorageCache.next(classificationTagsClearRequest);

    const classificationCacheClearRequest = new ClearStorageCache();
    classificationTagsClearRequest.storageName = {
      storageName: CLASSIFICATION_SHARED_TAG_CACHE_NAME
    };
    SharedSubjects._clearStorageCache.next(classificationCacheClearRequest);

    return this._data.UserService.fetchTopPriorityLicense(this.userEmail).pipe(
      /**
       * Fetch labels
       */
      mergeMap(() =>
        event.isPersonal
          ? this._data.LabelService.fetchPersonalLabels(this._userEmail)
          : this._data.LabelService.updateSharedLabelsByWorkspace(this._userEmail, event)
      ),
      filter(() => {
        return !event.isPersonal;
      }),
      /**
       * Fetch statuses
       */
      mergeMap(() => this._data.AvailabilityStatusService.syncAvailabilityStatuses(this._userEmail))
    );
  }
}

export type EventFolderBase = EventSharedTagUpdated | EventSharedTagCreated | EventSharedTagDeleted;
export type EventTagBase = EventTagCreated | EventTagUpdated | EventTagDeleted;
export type EventSignatureBase = EventSignatureCreated | EventSignatureUpdated | EventSignatureDeleted;

export enum EventType {
  // Base
  Base = 'EventBase',

  ///////////////////
  // COMMENT EVENTS
  ///////////////////
  CommentCreated = 'EventCommentCreated',
  CommentUpdated = 'EventCommentUpdated',

  ///////////////
  // CARD EVENTS
  ///////////////
  CardCreated = 'EventCardCreated',
  CardUpdated = 'EventCardUpdated',

  ///////////////////
  // CONTACTS EVENTS
  ///////////////////
  ContactCreated = 'EventContactCreated',
  ContactUpdated = 'EventContactUpdated',
  ContactDeleted = 'EventContactDeleted',

  ////////////////////
  // SIGNATURE EVENTS
  ////////////////////
  SignatureCreated = 'EventSignatureCreated',
  SignatureUpdated = 'EventSignatureUpdated',
  SignatureDeleted = 'EventSignatureDeleted',

  //////////////
  // TAG EVENTS
  //////////////
  TagCreated = 'EventTagCreated',
  TagUpdated = 'EventTagUpdated',
  TagDeleted = 'EventTagDeleted',
  TagsUpdated = 'EventTagsUpdated',

  /////////////////////
  // SHARED TAG EVENTS
  /////////////////////
  SharedTagsUpdated = 'EventSharedTagsUpdated',

  ////////////////
  // STATE EVENTS
  ////////////////
  UserOnboardingCompleted = 'EventUserOnboardingCompleted',
  UserRegistered = 'EventUserRegistered',
  AccountTokenInvalid = 'EventAccountTokenInvalid',
  UserStatus = 'EventUserStatus',
  SyncSettingsUpdated = 'EventSyncSettingsUpdated',
  UserSettingsUpdated = 'EventUserSettingsUpdated',
  WorkspaceUpdated = 'EventWorkspaceUpdated',

  /////////////////////
  // AVAILABILITY EVENTS
  /////////////////////
  UserAvailabilityStatusUpdated = 'EventUserAvailabilityStatusChanged',
  UserAvailabilityStatusDeleted = 'EventUserAvailabilityStatusDeleted',

  /////////////////////
  // INTEGRATIONS
  /////////////////////
  IntegrationsUpdated = 'EventIntegrationsUpdated',

  /////////////////////
  // Folders
  /////////////////////
  FolderUpdated = 'EventSharedTagUpdated',
  FolderCreated = 'EventSharedTagCreated',
  FolderDeleted = 'EventSharedTagDeleted',
  EventFolderUpdated = 'EventFolderUpdated',

  /////////////////////
  // EventCardAuthorizationUpdated
  /////////////////////
  EventCardAuthorizationUpdated = 'EventCardAuthorizationUpdated'
}
