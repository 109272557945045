/* tslint:disable */

/**
 * Device type enum
 */
export type DeviceType = 'iPhone' | 'iPad' | 'Android' | 'Test';

export module DeviceType {
  export const I_PHONE: DeviceType = 'iPhone';
  export const I_PAD: DeviceType = 'iPad';
  export const ANDROID: DeviceType = 'Android';
  export const TEST: DeviceType = 'Test';

  export function values(): DeviceType[] {
    return [I_PHONE, I_PAD, ANDROID, TEST];
  }
}
