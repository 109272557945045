import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Encryption } from '../../utils/common-utils';

@Injectable()
export class StorageService {
  constructor() {}

  getItem(key: string): any {
    let value = localStorage[key];
    if (value === undefined) {
      value = this.getConstant(key);
      if (value === undefined) {
        this.setItem(key, value);
      }
    }
    return value;
  }

  getParsedItem(key: string, decrypt?: boolean): any {
    let value = this.getItem(key);

    if (decrypt && value) {
      try {
        value = Encryption.decrypt(value);
      } catch (e) {
        console.log("Can't decipher. Got error:  ", e);
        return undefined;
      }
    }

    try {
      return JSON.parse(value);
    } catch (err) {
      return undefined;
    }
  }

  setItem(key: string, value?): any {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage[key] = value;
    }
    return value;
  }

  setStringifiedItem(key: string, value?: any, encrypt?: boolean) {
    let string = value;

    try {
      string = JSON.stringify(value);
    } catch (err) {}

    if (encrypt) {
      string = Encryption.encrypt(string);
    }

    this.setItem(key, string);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  getKey(prefix: string, suffix: StorageKey): string {
    return prefix + '_' + suffix;
  }

  findKeysByPrefix(prefix: string): string[] {
    let keys = Object.keys(localStorage);
    return _.filter(keys, key => key.startsWith(prefix));
  }

  getConstant(name: string): any {
    return CONSTANTS[name];
  }

  getPlatform(): string {
    return CONSTANTS.PLATFORM || 'electron';
  }

  isKeyInStorage(key: string): boolean {
    return key in localStorage;
  }
}

export enum StorageKey {
  ///////////////////////
  // APP STATE
  ///////////////////////
  apiEnvironment = 'api_environment',
  users = 'users',
  userRegistered = 'registered',
  lastUser = 'last_user',
  lastRoute = 'last_route',
  previousRoute = 'previous_route',
  usersInitiallySynced = 'users_initially_synced',
  collectionHistoryIds = 'collection_history_ids', // Legacy storage key
  cutOffTimeStorageKey = 'cut_off_time_storage_key',
  redoInitSyncRequired = 'redo_init_sync_required',
  closeOldTabsRequired = 'close_old_tabs_required',
  appPreferences = 'app_preferences',
  spellcheckerLanguage = 'spellchecker-language',
  skipVersion = 'skip-version',
  setUnreadFilterOnChannel = 'set_unread_filter_on_channel',
  defaultColorThemeId = 'default_theme_id',
  customThemeColors = 'custom_theme_colors',
  dbRev = 'db_rev',
  fileRev = 'file_rev',
  scheduledMigrations = 'scheduled_migrations',
  appVersion = 'app_rev',
  lastActivityAt = 'last_activity_at',
  activated = 'activated',
  lastCompletedPurgeAt = 'last_completed_purge_at',
  recentlyUsedShortcuts = 'recently_used_shortcuts',
  skipDefaultFilters = 'skip_default_filters',
  lightBackground = 'light_background',

  ///////////////////////
  // USER RELATED
  ///////////////////////
  userUnreadCount = 'user-unread-count',
  defaultSignatureId = 'default_signature_id',
  defaultSenderEmail = 'default_sender_email',
  settings = 'settings',
  preferences = 'preferences',
  userNotificationSettings = 'notification_settings',
  userClientSettings = 'client_settings',
  userEmailSettings = 'email_settings',
  userAliasSettings = 'alias_settings',
  defaultAction = 'default_action',
  recentSearches = '_recent_searches',
  avatarIdSync = 'avatar_id_sync',
  avatarUrls = 'avatar_urls',
  quickJumpRecent = 'quick-jump-recent-results',
  userPaymentSettings = 'user-payment-settings',
  emailDrafts = 'email-drafts',
  userTopPriorityLicense = 'user-top-priority-licenses',
  sharedInboxSetup = 'shared-inbox-setup',
  privateInboxSetup = 'private-inbox-setup',
  internalSyncData = 'internal-sync-data',
  outOfOffice = 'out-of-office',
  allowedImpersonatedPIIds = 'allowed-impersonated-pi-ids',
  ///////////////////////
  userAuth = 'auth',
  emailAuthType = 'email_auth_type',
  lastGmailRefreshToken = 'last_gmail_refresh_token',
  lastMicrosoftRefreshToken = 'last_microsoft_refresh_token',
  getUserForCodeExchange = 'get-user-for-code-exchange',
  conversationChatDraft = 'conversation-chat-draft',
  conversationMentionsDraft = 'conversation-mentions-draft',

  ///////////////////////
  // SYNC
  ///////////////////////
  activeSyncHistoryId = 'active_sync_history_id',
  activeConversationSyncHistoryId = 'active_conversation_sync_history_id',
  pastSyncHistoryId = 'past_sync_history_id',
  pastSyncCutOffDate = 'past_sync_cut_off_date',
  pastSyncComplete = 'past_sync_complete',
  historySyncComplete = 'history_sync_complete',
  syncRestartRequired = 'sync_restart_required',
  contactSyncHistoryId = 'contact_sync_history_id',
  initContactSyncComplete = 'init_contact_sync_complete',

  ///////////////////////
  // FROALA
  ///////////////////////
  froalaShowFormatting = 'froala_show_formatting',
  userFont = 'user-font',
  userFontSize = 'user-font-size',

  ///////////////////////
  // VIEW AND
  // COMPONENT STATES
  ///////////////////////
  threadedFilter = 'threaded_filter',
  spamFilter = 'spam_filter',
  unreadFilter = 'unread_filter',
  starredFilter = 'starred_filter',
  threadsPanelFilter = 'threads_panel_filter',
  personalInboxFilter = 'personal_inbox_filter',
  messagesFilter = 'messages_new_filter',
  myLoopInboxOpened = 'my-loop-inbox-opened',
  middleViewFilters = 'middle-view-filters',
  stickyFromSelection = 'sticky-from-selection',
  menuChannelsList = 'menu-channels-list',
  quickJumpRecentResults = 'quick-jump-recent-results',
  manuallyMarkAsRead = 'manually_mark_as_read',
  noticeBarClosedTime = 'notice-bar-closed-time',
  sharedTagsFilterByChannel = 'shared_tags_filter_by_channel',
  lastSelectedSnooze = 'last_selected_snooze',
  collaborationChatToggled = 'collaboration_chat_toggled',
  pinnedViews = 'pinned_views_v1',
  integrationPaneDisplayStyle = 'integration_pane_display_style',

  ///////////////////////
  // ONBOARDING
  ///////////////////////
  webOnboardingSecret = 'web_onboarding_secret',
  currentWebOnboardingWindowId = 'current-web-onboarding-window-id',

  ///////////////////////
  // DEBUGGING
  ///////////////////////
  metricsEnabled = 'metrics-enabled',
  isTestingMode = 'is-testing-mode',

  //////////
  // FILES
  //////////
  fileUrlStorage = 'file_url_storage',
  fileUrlStorage2 = 'file_url_storage_2',

  /////////////////////////
  // LOCAL UNREAD COUNTERS
  /////////////////////////
  unreadCountersByEmail = 'local_unread_counters_by_email',
  contactsTotalUnreadCountersByEmail = 'local_contacts_total_unread_counters_by_email',
  assignedCountersByEmail = 'local_assigned_counters_by_email',

  ///////////////////////
  // OTHER
  ///////////////////////
  logBundle = 'logBundle',
  deviceId = 'device_id'
}
