import * as _ from 'lodash';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ResourceBase } from '@shared/api/api-loop/models';

export class LoopIDBStoreCacheItem {
  static type: string = 'LoopIDBStoreCacheItem';
  readonly $type: string = LoopIDBStoreCacheItem.type;

  insertTimestamp: string;
  model: ResourceBase;

  constructor(model: ResourceBase) {
    this.model = model;
    this.insertTimestamp = new Date().getTime().toString();
  }

  static create(model: ResourceBase): LoopIDBStoreCacheItem {
    return new LoopIDBStoreCacheItem(model);
  }

  static createList(models: BaseModel[]): LoopIDBStoreCacheItem[] {
    return _.map(models, model => new LoopIDBStoreCacheItem(model));
  }

  static getModels(items: LoopIDBStoreCacheItem[]): ResourceBase[] {
    return _.map(items, item => item.model);
  }
}
