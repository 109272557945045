import * as _ from 'lodash';
import { Directive, OnDestroy } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { LoginService } from '@shared/services/login/login.service';
import { Logger } from '@shared/services/logger/logger';
import { catchError, tap } from 'rxjs/operators';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { WebOnboardingService } from '../web-onboarding/web-onboarding.service';
import { Router } from '@angular/router';
import { UserManagerService } from '../user-manager/user-manager.service';
import {
  CardAppointmentModel,
  CardChatModel,
  CardMailModel,
  CardSharedModel
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { ContactModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { RedirectService } from '../redirect/redirect.service';
import { deeplinkRoute } from 'web/app/web-routing.module';
import { ChannelSetupService, SetupType } from '../data/channel-inbox-setup/channel-setup.service';
import {
  microsoftExtendedPrefixWithSeparator,
  personalInboxStateOriginPrefix,
  personalSharedInboxStateOriginPrefix,
  sharedInboxSetupStateOriginPrefix
} from '../data/channel-inbox-setup/channel-setup.service.interface';
import { CONVERSATION_DEEP_LINK } from '@shared/modules/conversations/common/resources/conversation-deeplink';
import { MainAppRoutesNames } from '@dta/ui/components/mainapp/resources/routes';
import { CONVERSATIONS_QUERY_PARAM_IDENTIFIER } from '@shared/modules/conversations/common/resources/conversation-query-param-identifier';
import { CONVERSATIONS_DEEP_LINK } from '@shared/modules/conversations/common/resources/conversations-deeplink';
import { CONVERSATION_QUERY_PARAM_SEPARATOR } from '@shared/modules/conversations/common/resources/conversation-query-param-separator';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import * as URI from 'uri-js';
import { isSendCommandType } from '@shared/modules/deeplinks/common/constants/send-command-type';
import { SendCommand } from '@shared/modules/deeplinks/common/interfaces/send-command';

export interface DeepLinksServiceI {
  get sendChat$(): Observable<string>;
  get deepLinks$(): Subject<string>;
}

@AutoUnsubscribe()
@Directive()
export abstract class DeepLinksService implements DeepLinksServiceI, OnDestroy {
  //////////////////
  // Subscriptions
  //////////////////
  protected anonymousSubscriptions: Subscription[] = [];

  ////////////
  // Subjects
  ////////////
  protected _sendChat$: Subject<string> = new Subject();
  readonly sendCommand$: Subject<SendCommand> = new Subject();
  protected _deepLinks$: Subject<string> = new Subject();

  constructor(
    protected _router: Router,
    protected _loginService: LoginService,
    protected _webOnboardingService: WebOnboardingService,
    protected _userManagerService: UserManagerService,
    protected _redirectService: RedirectService,
    protected _channelSetupService: ChannelSetupService
  ) {
    this.exposeDeeplinkTrigger();
  }

  ngOnDestroy(): void {
    this.sendCommand$.complete();
    this._deepLinks$.complete();
    this._sendChat$.complete();
  }

  get sendChat$(): Observable<string> {
    return this._sendChat$.asObservable();
  }

  get deepLinks$(): Subject<string> {
    return this._deepLinks$;
  }

  static shouldInterceptUrl(url: string): boolean {
    // Intercept if url includes deeplink, allow open-web-onboarding to open settings in new tab
    return url.includes(`${window.location.host}/${deeplinkRoute}/`) && !url.includes('open-web-onboarding');
  }

  private openConversationView(initialConversationId: string | undefined, conversationIds: string[]): void {
    const conversationId = initialConversationId ?? '';
    this._router.navigate([`${MainAppRoutesNames.conversations}/${conversationId}`], {
      queryParams: {
        [CONVERSATIONS_QUERY_PARAM_IDENTIFIER]: conversationIds.join(CONVERSATION_QUERY_PARAM_SEPARATOR)
      },
      queryParamsHandling: 'merge'
    });
  }

  protected onCodeLogin(searchParams: URLSearchParams) {
    let code = searchParams.get('code') || undefined;
    let authType = searchParams.get('auth-type') || undefined;
    let state = searchParams.get('state') || undefined;
    let oAuthRefreshToken = searchParams.get('oauthRefreshToken') || undefined;
    let oAuthEmail = searchParams.get('oauthEmail') || undefined;
    let permissionDenied = !_.isEmpty(searchParams.get('permission-denied'));

    // Strip the extra prefix (needed only on registration site)
    if (state && state.includes(microsoftExtendedPrefixWithSeparator)) {
      state = state.substring(
        state.indexOf(microsoftExtendedPrefixWithSeparator) + microsoftExtendedPrefixWithSeparator.length
      );
    }

    if (!code) {
      Logger.customLog('LoginWithCode deeplink called with no code', LogLevel.ERROR, LogTag.AUTH_AND_LOGIN, true);

      return;
    }

    let handler$: Observable<any>;
    let onError: Function;
    if (state?.includes(personalInboxStateOriginPrefix)) {
      handler$ = this._channelSetupService.handlePersonalInboxDeeplink(
        state,
        code,
        oAuthRefreshToken,
        oAuthEmail,
        permissionDenied
      );
      onError = () => {
        this._router.navigate(['/user-settings/my-inboxes']);
      };
    } else if (state?.startsWith(personalSharedInboxStateOriginPrefix)) {
      handler$ = this._channelSetupService.handlePersonalSharedInboxDeeplink(
        state,
        code,
        oAuthRefreshToken,
        oAuthEmail,
        permissionDenied
      );
      onError = () => {
        this._router.navigate(['/user-settings/personal-inboxes']);
      };
    } else if (state?.includes(sharedInboxSetupStateOriginPrefix)) {
      handler$ = this._channelSetupService.handleSharedInboxOauthDeeplink(
        state,
        code,
        oAuthRefreshToken,
        oAuthEmail,
        SetupType.SHARED_INBOX,
        permissionDenied
      );
      onError = () => {
        this._router.navigate(['/user-settings/personal-inboxes']);
      };
    } else {
      this._loginService.toggleOauthLoading$.next(true);
      handler$ = this._loginService.loginWithActivationCode(code, authType);
      onError = () => {
        this._loginService.toggleOauthLoading$.next(false);
        this._router.navigate(['/login']);
      };
    }

    handler$
      .pipe(
        catchError(err => {
          // Show generic error for security
          Logger.error(err, 'Code login failed using code', LogTag.AUTH_AND_LOGIN);

          if (onError) {
            onError();
          }

          return of(false);
        })
      )
      .subscribe();
  }

  protected onCloseWebOnboarding(searchParams: URLSearchParams) {
    let viewName = searchParams.get('view-name');
    let channelId = searchParams.get('channel-id');
    let channelTab = searchParams.get('channel-tab');
    let email = searchParams.get('email-address');

    if (email) {
      this.onChangeAccount(searchParams);
    }

    if (viewName) {
      this.onNavigateToView(searchParams);
    } else if (channelId) {
      let params = new URLSearchParams({ id: channelId });

      if (channelTab) {
        params.append('tab', channelTab);
      }

      this.onOpenChannel(params);
    } else {
      this.redirectToDefaultView();
    }
  }

  checkForDeepLink(url: string = undefined) {
    let parsedURI = URI.parse(url || this._router.url);

    let searchParams = new URLSearchParams(decodeURI(parsedURI.query));
    let deepLink = parsedURI.host;
    if (parsedURI.path.includes(deeplinkRoute)) {
      searchParams = new URLSearchParams(decodeURI(parsedURI.query));
      deepLink = parsedURI.path.split(deeplinkRoute + '/')?.[1]?.split('/')?.[0] ?? parsedURI.host;
    }

    this.processDeepLink(deepLink, searchParams, url);
  }

  protected processDeepLink(deepLink: string, searchParams: URLSearchParams, deeplinkUrl: string) {
    if (deepLink?.split('/').includes(CONVERSATION_DEEP_LINK)) {
      deepLink = deeplinkUrl || deepLink;
      if (deepLink.includes(CONVERSATION_DEEP_LINK)) {
        const realDeeplinkUrl = deepLink.substring(deepLink.indexOf(CONVERSATION_DEEP_LINK));
        const conversationIds = realDeeplinkUrl.split('/')[1];
        this.openConversationView(undefined, [conversationIds]);
      }
      return;
    }

    switch (deepLink) {
      case 'send-chat':
        this.onSendChat(searchParams);
        break;
      case 'send-command':
        this.onSendCommand(searchParams);
        break;
      case 'open-channel':
        this.onOpenChannel(searchParams);
        break;
      case 'show-cards-in-channel':
        this.showCardsInChannel(searchParams);
        break;
      case 'open-view':
        this.onNavigateToView(searchParams);
        break;
      case 'create-chat-reply':
        this.onCreateChatReply(searchParams);
        break;
      case 'change-account':
        this.onChangeAccount(searchParams);
        break;
      case 'close-web-onboarding':
        this.onCloseWebOnboarding(searchParams);
        break;
      case 'invite-into-shared-inbox':
        this.onInviteToSharedInbox(searchParams);
        break;
      case 'add-new-shared-inbox':
        this.onAddNewSharedInbox();
        break;
      case 'setup-shared-inbox-sla':
        this.onSetupSISla(searchParams);
        break;
      case 'open-web-onboarding':
        this.onOpenWebOnboarding(searchParams);
        break;
      case 'check-for-updates':
        this.onCheckForUpdates(searchParams);
        break;
      case 'code-login':
        this.onCodeLogin(searchParams);
        break;
      case CONVERSATIONS_DEEP_LINK:
        const conversationIds = searchParams.get(CONVERSATIONS_QUERY_PARAM_IDENTIFIER);
        this.openConversationView(undefined, conversationIds.split(CONVERSATION_QUERY_PARAM_SEPARATOR));
        break;
      default:
        this.redirectToDefaultView();
        break;
    }
  }

  protected redirectToDefaultView() {
    // No rerouting on DTA, Web app needs to redirect from '/deeplink' to '/'
  }

  protected onAddNewSharedInbox() {
    this._redirectService.navigateTo('/user-settings/shared-inboxes/create');
  }

  protected onNavigateToView(searchParams: URLSearchParams) {
    let viewName = searchParams.get('view-name');
    let validNames = [
      'account-emails',
      'messages',
      'assigned',
      'all-messages',
      'subscription-settings',
      'settings',
      'starred'
    ];

    if (validNames.includes(viewName)) {
      switch (viewName) {
        case 'account-emails':
          this._router.navigate(['/account/inbox']);
          break;
        case 'messages':
          this._router.navigate(['/inbox/' + viewName]);
          break;
        case 'assigned':
          this._router.navigate(['/' + viewName]);
          break;
        case 'all-messages':
          this._router.navigate(['/']);
          break;
        case 'settings':
          this._router.navigate(['/user-settings']);
          break;
        case 'starred':
          this._router.navigate(['/starred']);
          break;
        default:
          break;
      }
    }
  }

  protected onOpenWebOnboarding(searchParams: URLSearchParams) {
    let path = searchParams.get('path') || undefined;

    if (path) {
      searchParams.delete('path');

      this._webOnboardingService.openCustom(
        this._userManagerService.getCurrentUserEmail(),
        searchParams,
        path,
        undefined // Open in the same tab
      );
    } else {
      this._webOnboardingService.openCustom(
        this._userManagerService.getCurrentUserEmail(),
        searchParams,
        undefined,
        undefined // Open in the same tab
      );
    }
  }

  protected showCardsInChannel(searchParams: URLSearchParams) {
    let channelId = searchParams.get('channel-id');
    let showCardsInChannel = searchParams.get('card-ids');

    if (!channelId || !showCardsInChannel) {
      this.redirectToDefaultView();
      return;
    }

    this.getContactAndNavigateToChannel(channelId, undefined, 'email', { showCardsInChannel });
  }

  protected onOpenChannel(searchParams: URLSearchParams) {
    let channelId = searchParams.get('id');
    let tab = searchParams.get('tab');

    if (!channelId) {
      return;
    }
    this.getContactAndNavigateToChannel(channelId, undefined, tab);
  }

  protected getContactAndNavigateToChannel(
    id: string,
    conversation?: ConversationModel,
    tab?: string,
    queryParams?: object
  ) {
    let currentUserEmail = this._userManagerService.getCurrentUserEmail();

    this.anonymousSubscriptions.push(
      this.findOrFetchContactById(currentUserEmail, id)
        .pipe(
          tap((contact: ContactModel) => {
            if (contact) {
              this.navigateToChannel(contact, conversation, tab, queryParams);
            } else {
              this.fallbackNavigateToChannel(conversation);
            }
          })
        )
        .subscribe()
    );
  }

  protected navigateToChannel(
    contact: ContactModel,
    conversation?: ConversationModel,
    tab?: string,
    queryParams?: object
  ) {
    // Navigate to channel card
    if (conversation && conversation.cardType !== CardChatModel.type) {
      this._redirectService.quickJump(contact, 'threads', false, conversation.id || conversation._id);
      return;
    }

    // Navigate to channel tab
    if (tab) {
      if (tab === 'email') {
        this.routerNavigateToChannel(contact._id, 'firstCard', 'threads', queryParams);
      } else if (tab === 'files') {
        this.routerNavigateToChannel(contact._id, 'empty', 'files');
      } else if (tab === 'info') {
        this.routerNavigateToChannel(contact._id, 'empty', 'info');
      } else {
        this.routerNavigateToChannel(contact._id, 'chat', null);
      }
      return;
    }

    // Navigate to channel using default channel opening logic
    this._redirectService.quickJump(contact);
  }

  private routerNavigateToChannel(contactId: string, primary: string, panel: string, queryParams: object = {}) {
    queryParams['t'] = new Date().getTime();
    this._router.navigate(
      [
        '/channel/' + contactId,
        { fragment: 'redirected' },
        {
          outlets: {
            primary: primary === 'firstCard' ? ['thread', primary] : primary,
            panel: panel
          }
        }
      ],
      { queryParams }
    );
  }

  private fallbackNavigateToChannel(conversation: ConversationModel) {
    if (!conversation) {
      return;
    }
    switch (conversation.cardType) {
      case CardMailModel.type:
      case CardSharedModel.type:
        this._router.navigate(['/myloopinbox/' + (conversation.id || conversation._id)]);
        break;
      case CardChatModel.type:
        this._router.navigate(['/inbox/messages/' + (conversation.id || conversation._id)]);
        break;
      case CardAppointmentModel.type:
        this._router.navigate(['/agenda/' + (conversation.id || conversation._id)]);
        break;
      default:
        break;
    }
  }

  private onSendChat(searchParams: URLSearchParams) {
    let msg = searchParams.get('msg');
    this._sendChat$.next(msg);
  }

  private onSendCommand(searchParams: URLSearchParams) {
    const command = searchParams.get('command');
    const type = searchParams.get('type');

    if (!isSendCommandType(type)) {
      return;
    }

    this.sendCommand$.next({
      type: type,
      command: command
    });
  }

  private onSetupSISla(searchParams) {
    let teamId = searchParams.get('team-id') || undefined;

    if (!teamId) {
      console.warn('Setup SI SLA deeplink: team-id missing');
      return;
    }

    this._webOnboardingService.setupSISla(teamId);
  }

  protected stringToArray(str: string): string[] {
    if (!str) {
      return [];
    }
    return str.replace(/[\'\[\] ]/g, '').split(',');
  }

  protected mapEmailsToUserModels(emails: string[]): UserModel[] {
    if (_.isEmpty(emails)) {
      return [];
    }

    emails = _.castArray(emails);

    return emails.map((email: string) => {
      return new UserModel({
        $type: UserModel.type,
        email: email,
        name: email
      });
    });
  }

  protected abstract findOrFetchContactById(forUserEmail: string, contactId: string): Observable<ContactModel>;
  protected onOpenCard(searchParams: URLSearchParams) {}
  protected onCheckForUpdates(searchParams: URLSearchParams) {}
  protected onCreateChatReply(searchParams: URLSearchParams) {}
  protected onChangeAccount(searchParams: URLSearchParams) {}
  protected onInviteToSharedInbox(searchParams: URLSearchParams) {}
  protected exposeDeeplinkTrigger() {}
}
