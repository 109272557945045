<loop-form-control-wrapper [baseFormControl]="this">
  <div class="checkbox" [class.disabled]="(isDisabled$ | async) === true" [ngClass]="{ checked: value$ | async }">
    <input
      [id]="_id"
      type="checkbox"
      [attr.disabled]="(isDisabled$ | async) ? '' : null"
      [disabled]="(isDisabled$ | async) === true"
      [ngModel]="value$ | async"
      (ngModelChange)="handleModelChange($event)"
    />
    <label [for]="_id" class="cursor-pointer"><span></span></label>
  </div>
</loop-form-control-wrapper>
