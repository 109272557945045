<!-- Contact avatar -->
<img
  *ngIf="!multipleRecipients && file"
  [class]="extraClass + ' prevent-copy'"
  [class.bordered-avatar]="hasBorder"
  [style.width]="width + 'px'"
  [style.height]="height + 'px'"
  [src]="file"
  (error)="onImageError()"
/>

<!-- Multiple avatars -->
<div
  *ngIf="multipleRecipients"
  [class]="extraClass"
  [style.width]="width + 'px'"
  [style.height]="height + 'px'"
  [class.bordered-avatar]="hasBorder"
  [class.multiple]="multipleRecipients"
>
  <svg-comp [src]="multiple"></svg-comp>
</div>

<!-- Default team avatar -->
<img
  *ngIf="!multipleRecipients && (!contact || (!file && contact && contact.$type === 'Group'))"
  [class]="extraClass + ' prevent-copy'"
  [style.width]="width + 'px'"
  [style.height]="height + 'px'"
  [class.bordered-avatar]="hasBorder"
  [src]="'shared/assets/img/svg/default-team.svg'"
/>

<!-- Default user avatar -->
<div
  *ngIf="!file && contact && contact.$type === 'User'"
  [class]="extraClass"
  [class.placeholder]="!multipleRecipients"
  [style.background]="backgroundColor"
  [class.shared-tag]="sharedTag"
  [style.width]="width + 'px'"
  [style.height]="height + 'px'"
  [class.bordered-avatar]="hasBorder"
>
  {{initials}}
</div>
