import { Injectable, OnDestroy } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { AutoUnsubscribe } from '../../../shared/utils/subscriptions/auto-unsubscribe';
import {
  WebsocketCardEventsAggregationService,
  WebsocketCardEventType,
} from '../websocket-card-events-aggregation/websocket-card-events-aggregation.service';

@AutoUnsubscribe()
@Injectable()
export class SomeoneIsTypingService extends WebsocketCardEventsAggregationService implements OnDestroy {
  constructor(
    protected _userManagerService: UserManagerService,
    protected _contactService: ContactService,
  ) {
    super(WebsocketCardEventType.Typing, _userManagerService, _contactService);
  }

  ngOnDestroy() {}
}
