import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LibrarySearchResults } from '@dta/shared/models/library.model';
import { FileApiService } from '@shared/api/api-loop/services/file-api.service';
import { map, mergeMap } from 'rxjs/operators';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { FileExDecorateService } from '@shared/decorators/extra-data-decorators/file-ex-decorator/file-ex-decorate.service';
import { FileService } from '@shared/services/data/file/file.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { LibraryService } from '@shared/services/data/library/library.service';
import { FileViewDecorateService } from '@shared/decorators/view-data-decorators/file-view-decorator/file-view-decorate.service';
import { FileSynchronizationService } from '@shared/services/data/file-synchronization/file-synchronization.service';

@Injectable()
export class LibraryServiceWeb extends LibraryService {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _fileApiService: FileApiService,
    protected _fileExDecorateService: FileExDecorateService,
    protected _fileService: FileService,
    protected _fileViewDecorateService: FileViewDecorateService,
    protected fileSynchronizationService: FileSynchronizationService,
  ) {
    super(_syncMiddleware, _fileApiService, _fileExDecorateService, _fileService, fileSynchronizationService);
  }

  get constructorName(): string {
    return 'LibraryServiceWeb';
  }

  protected findFilesFromApi(
    forUserEmail: string,
    searchQuery: string,
    offset: number,
    size: number,
  ): Observable<LibrarySearchResults> {
    return super.findFilesFromApi(forUserEmail, searchQuery, offset, size).pipe(
      mergeMap((results: LibrarySearchResults) => {
        return this._fileViewDecorateService.decorateListViewData(forUserEmail, results.result).pipe(
          map((decoratedFiles: FileModel[]) => {
            results.result = decoratedFiles;
            return results;
          }),
        );
      }),
    );
  }
}
