import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { ConversationHeaderDropdownModule } from '@shared/modules/conversations/components/conversation-header/dropdowns/thread-header-dropdown/conversation-header-dropdown.module';
import { ConversationFoldersModule } from '@shared/modules/conversations/components/conversation-header/conversation-folders/conversation-folders.module';
import { AssignDropdownModule } from '@shared/modules/conversations/components/conversation-header/dropdowns/assign-dropdown/assign-dropdown.module';
import { CustomDropdownModule } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.module';
import { ConversationTagModule } from '@shared/ui/conversation-tag/conversation-tag.module';
import { AssigneeTagModule } from '@shared/modules/conversations/components/conversation-header/assignee-tag/assignee-tag.module';
import { DropdownModule } from '@shared/ui/dropdown/dropdown.module';
import { GetLabelDropdownItemsPipe } from '@shared/modules/conversations/components/conversation-header/internal/get-label-dropdown-items.pipe';
import { AsPureArgumentForModule } from '@shared/ui/pipes/as-pure-argument-for/as-pure-argument-for.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { SnoozeDropdownModule } from '@shared/modules/conversations/components/conversation-header/dropdowns/snooze-dropdown/snooze-dropdown.module';
import { SnoozeTagModule } from '@shared/modules/conversations/components/conversation-header/snooze-tag/snooze-tag.module';
import { AddToFolderDropdownModule } from '@shared/modules/folder/component/add-to-folder-dropdown/add-to-folder-dropdown.module';
import { LabelsDropdownModule } from '@shared/modules/shared-tag/components/labels-dropdown/labels-dropdown.module';
import { MoveToTeamDropdownModule } from '@shared/modules/teams/components/move-to-team-dropdown/move-to-team-dropdown.module';
import { ConversationHeaderActionService } from '@shared/modules/conversations/components/conversation-header/conversation-header-action.service';
import { ConversationChannelIconModule } from '@shared/modules/conversations/components/conversation-channel-icon/conversation-channel-icon.module';
import { ClassificationTagModule } from '@shared/modules/shared-tag/components/classification-tag/classification-tag.module';
import { GetClassificationSharedTagPipeModule } from '@shared/modules/shared-tag/pipes/get-classification-shared-tag.pipe/get-classification-shared-tag.pipe.module';
import { ClassificationDropdownModule } from '@shared/modules/shared-tag/components/classification-dropdown/classification-dropdown.module';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';
import { SubjectPipeModule } from '@dta/ui/pipes/subject/subject-pipe.module';
import { UserNameModule } from '@dta/ui/pipes/user-name/user-name.module';
import { AvatarModule } from '@dta/ui/components/common/avatar/avatar.module';
import { ConversationHeaderComponent } from './conversation-header.component';
import { ChatConversationNamePipe } from './internal/chat-conversation-name.pipe';

@NgModule({
  declarations: [ConversationHeaderComponent, GetLabelDropdownItemsPipe, ChatConversationNamePipe],
  imports: [
    CommonModule,
    GraphicsModule,
    TooltipModule,
    SubjectPipeModule,
    ConversationHeaderDropdownModule,
    ConversationFoldersModule,
    AssignDropdownModule,
    CustomDropdownModule,
    ConversationTagModule,
    UserNameModule,
    AvatarModule,
    AssigneeTagModule,
    DropdownModule,
    AsPureArgumentForModule,
    IconModule,
    SnoozeDropdownModule,
    SnoozeTagModule,
    AddToFolderDropdownModule,
    LabelsDropdownModule,
    MoveToTeamDropdownModule,
    ConversationChannelIconModule,
    ClassificationTagModule,
    GetClassificationSharedTagPipeModule,
    ClassificationDropdownModule
  ],
  exports: [ConversationHeaderComponent],
  providers: [ConversationHeaderActionService]
})
export class ConversationHeaderModule {}
