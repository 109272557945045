import { Injectable } from '@angular/core';
import { BaseModel } from '../../../shared/models-api-loop/base/base.model';
import { Observable, of } from 'rxjs';
import { FetchResult, MultiSessionCollectionParams } from '../../../shared/models/collection.model';
import { CollectionOptions, CollectionSubscriberService, LoadMoreType } from '../collection-subscriber.service';
import { BaseCollectionService } from '../base.collection';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { IntegrationService } from '@shared/services/data/integration/integration.service';

@Injectable()
export class IntegrationsCollectionService extends BaseCollectionService<IntegrationModel> {
  protected supportedTypes: (typeof BaseModel)[] = [IntegrationModel];
  readonly loadMoreType: LoadMoreType = LoadMoreType.ONLY_QUERY;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _collectionSubscriberService: CollectionSubscriberService,
    private _integrationService: IntegrationService,
  ) {
    super(_userManagerService, _collectionSubscriberService);
  }

  get constructorName(): string {
    return 'IntegrationsCollection';
  }

  registerCollection(
    params: MultiSessionCollectionParams,
    options?: CollectionOptions,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<string> {
    return super.registerCollection(params, options, forUserEmail);
  }

  protected reduce(
    models: IntegrationModel[],
    params: MultiSessionCollectionParams,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<IntegrationModel[]> {
    return of(models);
  }

  protected doBeforePublish(
    models: IntegrationModel[],
    params: MultiSessionCollectionParams,
    forUserEmail: string,
  ): Observable<IntegrationModel[]> {
    return of(models);
  }

  protected doBeforeReduce(
    models: IntegrationModel[],
    params: MultiSessionCollectionParams,
    forUserEmail: string,
  ): Observable<IntegrationModel[]> {
    return of(models);
  }

  count(params: MultiSessionCollectionParams, forUserEmail: string): Observable<number> {
    throw new Error('Method not implemented.');
  }

  query(params: MultiSessionCollectionParams, forUserEmail: string): Observable<IntegrationModel[]> {
    return this._integrationService.findAllIntegrations(forUserEmail);
  }

  fetch(params: MultiSessionCollectionParams, forUserEmail: string): Observable<FetchResult> {
    throw new Error('Method not implemented.');
  }
}
