import { Pipe, PipeTransform } from '@angular/core';
import { DropdownRouterLinkItem } from '@shared/ui/dropdown/interfaces/dropdown-item';

@Pipe({
  name: 'dropdownMenuItemRouterLink',
})
export class DropdownItemRouterLinkPipe implements PipeTransform {
  transform(menuItem: DropdownRouterLinkItem): string {
    return menuItem.routerLink();
  }
}
