import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RsvpInfoComponent } from '@dta/ui/components/email-card/rsvp/rsvp-info/rsvp-info.component';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { AgendaPipesModule } from '@shared/modules/agenda/pipes/agenda-pipes/agenda-pipes.module';
import { UserNameModule } from '@dta/ui/pipes/user-name/user-name.module';
import { ButtonModule } from '@shared/ui/button/button.module';
import { StartWithPipeModule } from '@shared/ui/pipes/start-with-pipe/start-with-pipe.module';

@NgModule({
  declarations: [RsvpInfoComponent],
  imports: [CommonModule, GraphicsModule, AgendaPipesModule, UserNameModule, ButtonModule, StartWithPipeModule],
  exports: [RsvpInfoComponent],
})
export class RsvpInfoModule {}
