import { Observable } from 'rxjs';
import { RetryModel } from '@dta/shared/models-api-loop/retry.model';
import { BaseDaoServiceDta } from '@dta/backend/dao/base-dao.service.dta';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ResourceBase } from '@shared/api/api-loop/models';

export interface RetryQueueDaoServiceI {
  saveAllToQueue(forUserEmail: string, models: RetryModel[]): Observable<RetryModel[]>;
  countWaiting(forUserEmail: string): Observable<Number>;
  findNextBatch(forUserEmail: string, dbPageSize: number): Observable<RetryModel[]>;
  findByDataId(forUserEmail: string, dataIds: string[]): Observable<RetryModel[]>;
  removeByDataId(forUserEmail: string, dataIds: string[]): Observable<RetryModel[]>;
  removeById(forUserEmail: string, id: string): Observable<any>;
}

export abstract class RetryQueueDaoService
  extends BaseDaoServiceDta<BaseModel, ResourceBase>
  implements RetryQueueDaoServiceI
{
  abstract saveAllToQueue(forUserEmail: string, models: RetryModel[]): Observable<RetryModel[]>;
  abstract countWaiting(forUserEmail: string): Observable<Number>;
  abstract findNextBatch(forUserEmail: string, dbPageSize: number): Observable<RetryModel[]>;
  abstract findByDataId(forUserEmail: string, dataIds: string[]): Observable<RetryModel[]>;
  abstract removeByDataId(forUserEmail: string, dataIds: string[]): Observable<RetryModel[]>;
  abstract removeById(forUserEmail: string, id: string): Observable<any>;
}
