import { Directive } from '@angular/core';
import { RedirectService } from '@shared/services/redirect/redirect.service';

@Directive()
export abstract class EmptyStateSpecialBaseComponent {
  checkmarkSvg = require('shared/assets/img/svg/checkmark.svg');
  forwardSvg = require('shared/assets/img/svg/forward-filled.svg');
  replySvg = require('shared/assets/img/svg/reply-filled.svg');
  lockSvg = require('shared/assets/img/svg/lock-private.svg');
  secureSvg = require('shared/assets/img/svg/security.svg');

  constructor(protected _redirectService: RedirectService) {}

  abstract ctaClick(): void;
}
