import { SynchronizableModel } from '@shared/models/sync/synchronizable.model';
import * as _ from 'lodash';
import { BaseModel } from './base/base.model';
import { ModelMappers } from './model-mappers/model-mappers';
import { ActionEnum } from '@shared/api/api-loop/models/action-enum';

export class PushSyncModelBase {
  $type: string;
  _id: string;
  created: string;
  retryCount: number;
  retryAfterTimestamp: number;
  internalRetryCount: number;
  conversationAction?: ActionEnum;
  data: SynchronizableModel;
}

export class PushSyncModel implements PushSyncModelBase {
  static type: string = 'PushSyncModel';

  readonly $type: string = PushSyncModel.type;

  _id: string;
  created: string;

  // Retry properties
  internalRetryCount: number; // Used for retries that don't show notification to user
  retryCount: number;
  retryAfterTimestamp: number;

  // Conversation Action
  conversationAction?: ActionEnum;

  // Data to sync
  data: SynchronizableModel;

  constructor(model: PushSyncModelBase, conversationAction?: ActionEnum) {
    this._id = model._id || model.data._id;
    this.data = ModelMappers.castToEndModel(model.data);

    let now = new Date();
    this.created = model.created || now.toISOString();
    this.retryCount = model.retryCount || 0;
    this.internalRetryCount = model.internalRetryCount || 0;
    this.retryAfterTimestamp = model.retryAfterTimestamp || now.getTime() - 10;
    this.conversationAction = conversationAction;
  }

  static createPushSyncModel(data: BaseModel, conversationAction?: ActionEnum): PushSyncModel {
    return new PushSyncModel({ data } as PushSyncModelBase, conversationAction);
  }

  static createPushSyncModelList(dataList: BaseModel[], conversationAction?: ActionEnum): PushSyncModel[] {
    return _.map(dataList, data => PushSyncModel.createPushSyncModel(data, conversationAction));
  }

  static createList(models: PushSyncModelBase[]) {
    return _.map(models, model => new PushSyncModel(model));
  }

  toObject(): PushSyncModel {
    this.data = this.data.toObject() as SynchronizableModel;
    return BaseModel.toObject(this) as PushSyncModel;
  }

  bumpRetry() {
    // Increment retry count
    this.retryCount += 1;

    // Exponential backoff
    this.retryAfterTimestamp = new Date().getTime() + 2 ** this.retryCount * 1000;
  }

  bumpInternalRetry() {
    // Increment retry count
    this.internalRetryCount += 1;

    // Exponential backoff
    this.retryAfterTimestamp = new Date().getTime() + 2 ** this.internalRetryCount * 1000;
  }
}
