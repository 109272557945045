import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDecorateService } from './base-decorate.service';
import { CardViewDecorateService } from './view-data-decorators/card-view-decorator/card-view-decorate.service';
import { CardExDecorateService } from './extra-data-decorators/card-decorator/card-ex-decorate.service';
import { CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';

@Injectable()
export class CardDecorateService extends BaseDecorateService<CardModel> {
  constructor(
    private _cardViewDecorateService: CardViewDecorateService,
    private _cardExDecorateService: CardExDecorateService,
  ) {
    super();
  }

  decorateExtraData(forUserEmail: string, card: CardModel): Observable<CardModel> {
    return this._cardExDecorateService.decorate(forUserEmail, card);
  }

  decorateViewData(forUserEmail: string, card: CardModel, force?: boolean): Observable<CardModel> {
    return this._cardViewDecorateService.decorate(forUserEmail, card, force);
  }
}
