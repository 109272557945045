import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { catchError, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { Observable, of, Subscription, throwError, timer } from 'rxjs';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { AvailabilityStatusSubscriberService } from '@shared/services/availability-status-subscriber/availability-status-subscriber.service';
import { AvailabilityStatusSubscriberEvent } from '@shared/services/availability-status-subscriber/availability-status-subscriber.interface';
import { AvailabilityStatusService } from '@shared/services/data/availability-status/availability-status.service';

@Directive()
@AutoUnsubscribe()
export class AvailabilityStatusListComponent implements OnInit, OnDestroy {
  availabilityStatuses: AvailabilityStatusModel[] = [];
  showLoader: boolean = false;

  //////////////////
  // Subscriptions
  //////////////////
  private userSwitchSub: Subscription;
  private statusSub: Subscription;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _availabilityStatusSubscribedService: AvailabilityStatusSubscriberService,
    protected _availabilityStatusService: AvailabilityStatusService,
    protected _changeDetection: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscribeToUserSwitch();
  }

  ngOnDestroy(): void {}

  getAvailableStatuses() {
    this.showLoader = true;
    this.statusSub?.unsubscribe();
    this.statusSub = this._userManagerService
      .onAllBlockingSyncDone(this.currentUserEmail)
      .pipe(
        switchMap(() => this._availabilityStatusService.findAllAvailabilityStatuses(this.currentUserEmail)),
        mergeMap((statuses: AvailabilityStatusModel[]) => {
          this.availabilityStatuses = statuses;
          return this.registerObserver();
        }),
      )
      .subscribe();
  }

  registerObserver(): Observable<any> {
    return this._availabilityStatusSubscribedService.subscribeToAvailabilityStatuses(this.currentUserEmail, true).pipe(
      tap((event: AvailabilityStatusSubscriberEvent) => {
        this.showLoader = true;
        this.availabilityStatuses = AvailabilityStatusSubscriberService.processAvailabilityStatusSubscriberEvent(
          event,
          this.availabilityStatuses,
        );

        this.showLoader = false;
        this.triggerChangeDetection();
      }),
      retry({ count: 3, delay: () => timer(2000) }),
      catchError((err, obs) => {
        // Don't let error break anything
        this.availabilityStatuses = [];
        this.showLoader = false;

        Logger.error(err, 'Error in StatusDialogCompoennt');
        return of(undefined);
      }),
    );
  }

  protected triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }

  private get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  private subscribeToUserSwitch() {
    this.userSwitchSub?.unsubscribe();
    this.userSwitchSub = this._userManagerService.userSwitch$
      .pipe(
        tap(() => {
          this.availabilityStatuses = [];
          this.getAvailableStatuses();
        }),
      )
      .subscribe();
  }
}
