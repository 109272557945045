import { Injectable, OnDestroy } from '@angular/core';
import { IFrameRequestHandlerI } from '../iframe-request-handler.interface';
import { Observable, of, Subject } from 'rxjs';
import { IFrameMethod } from '@intheloop/loop-integration';
import { IFrameWindowRequest } from '@intheloop/loop-integration/dist/methods/iframe';

@Injectable()
export class IframeWindowHelper implements IFrameRequestHandlerI, OnDestroy {
  readonly iFrameHeight$: Subject<{ height: number; integrationId: string }> = new Subject<{
    height: number;
    integrationId: string;
  }>();
  readonly expandIframe$: Subject<{ integrationId: string }> = new Subject<{ integrationId: string }>();
  readonly iframeState$: Subject<{ state: Record<string, string | number>; integrationId: string }> = new Subject();

  handleRequest(forUserEmail: string, request: IFrameWindowRequest, integrationId: string): Observable<any> {
    switch (request.method) {
      case IFrameMethod.SEND_IFRAME_STATE:
        this.iframeState$.next({ state: request.state, integrationId: integrationId });
        return of(request.state);
      case IFrameMethod.SEND_IFRAME_HEIGHT:
        this.iFrameHeight$.next({ height: request.height, integrationId: integrationId });
        return of(request.height);
      case IFrameMethod.EXPAND_IFRAME:
        this.expandIframe$.next({ integrationId: integrationId });
        return of(undefined);
    }
  }

  ngOnDestroy(): void {
    this.iFrameHeight$.complete();
    this.expandIframe$.complete();
    this.iframeState$.complete();
  }
}
