/* tslint:disable */

/**
 * Possible Result Format Types
 */
export type ResultFormatEnum = 'Json' | 'Xlsx';

export module ResultFormatEnum {
  export const JSON: ResultFormatEnum = 'Json';
  export const XLSX: ResultFormatEnum = 'Xlsx';

  export function values(): ResultFormatEnum[] {
    return [JSON, XLSX];
  }
}
