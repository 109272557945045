import { ContactModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ContactBase, ListOfTags, User, UserStatus } from '@shared/api/api-loop/models';
import { Observable } from 'rxjs';
import { BaseDaoService } from '../base/base-dao.service';

export interface ContactDaoServiceI {
  getAllContacts(forUserEmail: string): Observable<ContactModel[]>;
  updateUserName(forUserEmail: string, user: UserModel): Observable<any>;
  updateContactTagsById(forUserEmail: string, id: string, tags: ListOfTags);
  getActiveUserCount(forUserEmail: string, users: User[]): Observable<Number>;
  updateUsersStatusById(forUserEmail: string, userIds: string[], onlineStatus: UserStatus): Observable<ContactModel[]>;
  findAllAccessibleGroups(forUserEmail: string): Observable<ContactModel[]>;
  findById(forUserEmail: string, id: string): Observable<ContactModel>;
  findByEmail(forUserEmail: string, email: string): Observable<ContactModel>;
  removeAccountsByEmails(forUserEmail: string, contactEmails: string[]): Observable<ContactModel[]>;
  removeAvailabilityStatus(forUserEmail: string, contact: ContactModel): Observable<ContactModel>;
}

export abstract class ContactDaoService
  extends BaseDaoService<ContactModel, ContactBase>
  implements ContactDaoServiceI
{
  abstract getAllContacts(forUserEmail: string): Observable<ContactModel[]>;
  abstract updateUserName(forUserEmail: string, user: UserModel): Observable<any>;
  abstract updateContactTagsById(forUserEmail: string, id: string, tags: ListOfTags);
  abstract getActiveUserCount(forUserEmail: string, users: User[]): Observable<Number>;
  abstract updateUsersStatusById(
    forUserEmail: string,
    userIds: string[],
    onlineStatus: UserStatus,
  ): Observable<ContactModel[]>;
  abstract findAllAccessibleGroups(forUserEmail: string): Observable<ContactModel[]>;
  abstract findByEmail(forUserEmail: string, email: string): Observable<ContactModel>;
  abstract removeAccountsByEmails(forUserEmail: string, contactEmails: string[]): Observable<ContactModel[]>;
  abstract removeAvailabilityStatus(forUserEmail: string, contact: ContactModel): Observable<ContactModel>;
}
