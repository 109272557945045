<select-dropdown
  #channelDropdown
  [iconForToggle]="true"
  [extraClass]="'header-title'"
  [hasToggleContent]="true"
  [options]="dropdownOptions"
  [additionalDropdownOpenTarget]="additionalDropdownOpenTarget"
  (selectionChange$)="onDropdownSelect($event)"
>
</select-dropdown>
