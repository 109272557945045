import { NgModule, Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { HomeApiService } from './services/home-api.service';
import { AppointmentApiService } from './services/appointment-api.service';
import { AuthApiService } from './services/auth-api.service';
import { AvailabilityStatusApiService } from './services/availability-status-api.service';
import { BadgeCountApiService } from './services/badge-count-api.service';
import { CardApiService } from './services/card-api.service';
import { CommandApiService } from './services/command-api.service';
import { CommentApiService } from './services/comment-api.service';
import { ContactApiService } from './services/contact-api.service';
import { ConversationApiService } from './services/conversation-api.service';
import { DeviceApiService } from './services/device-api.service';
import { EventApiService } from './services/event-api.service';
import { FileApiService } from './services/file-api.service';
import { GroupApiService } from './services/group-api.service';
import { HelperApiService } from './services/helper-api.service';
import { IntegrationsApiService } from './services/integrations-api.service';
import { NotificationApiService } from './services/notification-api.service';
import { ReportApiService } from './services/report-api.service';
import { SearchApiService } from './services/search-api.service';
import { SettingsApiService } from './services/settings-api.service';
import { SharedTagApiService } from './services/shared-tag-api.service';
import { SignatureApiService } from './services/signature-api.service';
import { SpamApiService } from './services/spam-api.service';
import { SubscriptionApiService } from './services/subscription-api.service';
import { SubscriptionLicenseApiService } from './services/subscription-license-api.service';
import { TagApiService } from './services/tag-api.service';
import { TimedAuthApiService } from './services/timed-auth-api.service';
import { TokenApiService } from './services/token-api.service';
import { UserApiService } from './services/user-api.service';

@Injectable()
export class ApiService {
  constructor(
    public HomeApiService: HomeApiService,
    public AppointmentApiService: AppointmentApiService,
    public AuthApiService: AuthApiService,
    public AvailabilityStatusApiService: AvailabilityStatusApiService,
    public BadgeCountApiService: BadgeCountApiService,
    public CardApiService: CardApiService,
    public CommandApiService: CommandApiService,
    public CommentApiService: CommentApiService,
    public ContactApiService: ContactApiService,
    public ConversationApiService: ConversationApiService,
    public DeviceApiService: DeviceApiService,
    public EventApiService: EventApiService,
    public FileApiService: FileApiService,
    public GroupApiService: GroupApiService,
    public HelperApiService: HelperApiService,
    public IntegrationsApiService: IntegrationsApiService,
    public NotificationApiService: NotificationApiService,
    public ReportApiService: ReportApiService,
    public SearchApiService: SearchApiService,
    public SettingsApiService: SettingsApiService,
    public SharedTagApiService: SharedTagApiService,
    public SignatureApiService: SignatureApiService,
    public SpamApiService: SpamApiService,
    public SubscriptionApiService: SubscriptionApiService,
    public SubscriptionLicenseApiService: SubscriptionLicenseApiService,
    public TagApiService: TagApiService,
    public TimedAuthApiService: TimedAuthApiService,
    public TokenApiService: TokenApiService,
    public UserApiService: UserApiService
  ) {}
}

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    ApiConfiguration,
    ApiService,
    HomeApiService,
    AppointmentApiService,
    AuthApiService,
    AvailabilityStatusApiService,
    BadgeCountApiService,
    CardApiService,
    CommandApiService,
    CommentApiService,
    ContactApiService,
    ConversationApiService,
    DeviceApiService,
    EventApiService,
    FileApiService,
    GroupApiService,
    HelperApiService,
    IntegrationsApiService,
    NotificationApiService,
    ReportApiService,
    SearchApiService,
    SettingsApiService,
    SharedTagApiService,
    SignatureApiService,
    SpamApiService,
    SubscriptionApiService,
    SubscriptionLicenseApiService,
    TagApiService,
    TimedAuthApiService,
    TokenApiService,
    UserApiService
  ]
})
export class ApiModule {}
