import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { ContactBaseModel } from '@dta/shared/models-api-loop/contact/contact.model';

@Component({
  selector: 'loop-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent {
  @Input() contact: ContactBaseModel;
  @Input() showAvailabilityStatus: boolean = false;
  @Input() suffixTpl?: TemplateRef<void>;
  @Input() isEmailVisible: boolean = true;
}
