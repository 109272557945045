<div
  [class]="class + ' input-text'"
  (click)="focusToInput()"
  [class.invalid]="!control.valid"
  [class.padding-left-0]="hasElements"
  [class.full-width]="fullWidth"
>
  <div class="input-wrap" [class.wrap]="class !== 'assign-dropdown'">
    <ng-content></ng-content>

    <input
      #input
      [(ngModel)]="value"
      [type]="type"
      (focus)="handleFocus($event)"
      (blur)="handleBlur($event)"
      (keydown)="keydown($event)"
      (keyup)="keyup($event)"
      (ngModelChange)="onChangeInput($event)"
      [type]="type"
      [name]="name"
      [placeholder]="placeholder"
      [disabled]="disabled"
      (ngModelChange)="onChangeInput($event)"
      (paste)="onPaste($event)"
      [class]="class"
      [class.allow-paste]="true"
    />
  </div>
  <label
    for=""
    *ngIf="label && !placeholder"
    [class.focus]="focused"
    [class.has-elements]="!focused && (hasElements || !empty)"
    >{{label}}</label
  >
</div>
