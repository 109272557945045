import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { SharedTagLabelModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { TagLabelModel } from '@dta/shared/models-api-loop/tag.model';

@Pipe({
  name: 'getLabelDropdownItems',
})
export class GetLabelDropdownItemsPipe implements PipeTransform {
  transform(label: SharedTagLabelModel | TagLabelModel, action: () => void): DropdownItem[] {
    return [
      {
        action: action,
        isDanger: true,
        title: 'Remove',
      },
    ];
  }
}
