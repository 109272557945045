import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '../../shared/models/logger.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { LoginUserModel } from '@dta/shared/models-api-loop/contact/contact.model';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _userManager: UserManagerService,
    private _router: Router,
  ) {}

  canActivate(): boolean {
    try {
      let token = undefined;
      let currentUserEmail = undefined;
      let user: LoginUserModel;

      try {
        // Get current user and its token
        currentUserEmail = this._userManager.getCurrentUserEmail();
        user = this._userManager.getUserLoginByEmail(currentUserEmail);
        token = this._userManager.getCurrentUserToken();
      } catch (err) {
        Logger.error(err, `Error in AuthGuard for ${currentUserEmail}:`, LogTag.AUTH_AND_LOGIN);

        // Will be handled in userManager
        return true;
      }

      if (user && token) {
        return true;
      } else {
        // Log missing token
        Logger.customLog(
          `AuthGuard: no token for current user ${currentUserEmail}`,
          LogLevel.ERROR,
          LogTag.AUTH_AND_LOGIN,
        );

        // Redirect to login as last resort
        this._router.navigate(['/login']);

        return false;
      }
    } catch (ignore) {
      return false;
    }
  }
}
