import { Injectable } from '@angular/core';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { CollectionNameWeb } from '../../database-schema';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DatabaseServiceWeb } from '../../database.service.web';
import { AvailabilityStatusDaoServiceI } from '@shared/database/dao/availability-status/availability-status-dao.service';
import { AvailabilityStatus } from '@shared/api/api-loop/models/availability-status';

@Injectable()
export class AvailabilityStatusDaoServiceWeb
  extends BaseDaoServiceWeb<AvailabilityStatusModel, AvailabilityStatus>
  implements AvailabilityStatusDaoServiceI
{
  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.AvailabilityStatus;
  }

  get constructorName(): string {
    return 'AvailabilityStatusDaoServiceWeb';
  }

  protected toModel(doc: ResourceBase): AvailabilityStatusModel {
    return AvailabilityStatusModel.create(doc);
  }

  findAllAvailabilityStatuses(forUserEmail: string): Observable<AvailabilityStatusModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.getAll(this.collectionName);
      }),
      map(docs => this.toModels(docs)),
    );
  }

  removeAllAvailabilityStatuses(forUserEmail: string): Observable<any> {
    return this.removeAllInCollection(forUserEmail);
  }
}
