import { ContactModel } from 'dta/shared/models-api-loop/contact/contact.model';
import { UnreadCounters, UnreadOptimisticResponse } from 'dta/shared/models/unread.model';
import { Observable } from 'rxjs';

export interface CardUnreadServiceI {
  applyOptimisticResponseToViewUnreadCount(forUserEmail: string, data: UnreadOptimisticResponse);
  getUnreadCountForMainViews(forUserEmail: string): Observable<UnreadCounters>;
  getContactUnreadCount(forUserEmail: string, contact: ContactModel): Observable<ContactUnreadCount>;
  getContactsTotalUnreadCount(
    forUserEmail: string,
    contacts: ContactModel[],
    forceFetch?: boolean
  ): Observable<{ [groupKey: string]: ContactUnreadCount }>;
  getAssignedItemsCount(forUserEmail: string): Observable<number>;
}

export class ContactUnreadCount {
  totalUnreadCount: number = 0;
  emailUnreadCount?: number = 0;
  chatUnreadCount?: number = 0;
  totalCount?: number = 0;
}
