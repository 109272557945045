import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { Injectable, OnDestroy } from '@angular/core';
import { from, map, mergeMap, Observable, of, Subject, tap, toArray } from 'rxjs';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { IntegrationType } from '@shared/api/api-loop/models';
import { IntegrationSubscriberService } from '@shared/services/integration-subscriber/integration-subscriber.service';
import { IntegrationSubscriberEvent } from '@shared/services/integration-subscriber/integration-subscriber.interface';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel } from '@dta/shared/models/logger.model';
import * as _ from 'lodash';

@AutoUnsubscribe()
@Injectable()
export class IntegrationsProviderService implements OnDestroy {
  integrations: IntegrationModel[] = [];
  initDone$: Subject<IntegrationModel[]> = new Subject();

  constructor(
    protected _integrationSubscriberService: IntegrationSubscriberService,
    protected _userManagerService: UserManagerService
  ) {}

  ngOnDestroy(): void {}

  initEnabledServices() {
    if (!this._userManagerService.getCurrentUserEmail()) {
      return;
    }

    this._integrationSubscriberService
      .subscribeToIntegrations(this._userManagerService.getCurrentUserEmail())
      .pipe(
        map((event: IntegrationSubscriberEvent) => {
          let newIntegrations = IntegrationSubscriberService.processIntegrationSubscriberEvent(
            event,
            this.integrations
          );
          let integrationsToInit = _.differenceBy(newIntegrations, this.integrations, 'id');
          this.integrations = newIntegrations;
          return integrationsToInit;
        }),
        mergeMap((integrations: IntegrationModel[]) => {
          return from(integrations);
        }),
        mergeMap((integration: IntegrationModel) => {
          Logger.customLog(
            `Integration ${integration.integrationType} was initialized for ${this._userManagerService.getCurrentUserEmail()}`,
            LogLevel.INFO
          );
          return this.initServiceByType(integration.integrationType);
        }),
        toArray(),
        tap(() => {
          this.initDone$.next(this.integrations);
        })
      )
      .subscribe();
  }

  initServiceByType(integrationType: IntegrationType): Observable<any> {
    switch (integrationType) {
      case IntegrationType.CUSTOM:
      default:
        return of(undefined);
    }
  }

  removeServiceByType(integrationType: IntegrationType) {
    switch (integrationType) {
      case IntegrationType.CUSTOM:
      default:
        break;
    }
  }
}
