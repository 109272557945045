import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { CommentMailModel } from '../../models-api-loop/comment/comment.model';
import { ContactBase, User } from '@shared/api/api-loop/models';
import { UserModel } from '../../models-api-loop/contact/contact.model';
import { BBCodeService } from '../../services/bbcode/bbcode.service';
import { FileModel } from '../../models-api-loop/file.model';
import { BodyClippingService } from '@dta/shared/services/body-clipping/body-clipping.service';
import { checkIfOS, OperatingSystem } from '../common-utils';
import { COLLAPSE_REGEX, COLLAPSE_TAG_CLASSES } from '@shared/modules/comments/constants/history-tag-elements';

@Injectable()
export class HistoryAndSignatureHelper {
  /////////////////////
  // Static variables
  /////////////////////
  static editableHistoryWrapperClassName = 'loop-editable-history-wrapper';
  static signatureWrapperClassName = 'loop-signature-to-post';
  static historyInDraftWrapper = 'loop-draft-history-wrapper';
  static signatureInDraftWrapper = 'loop-draft-signature-wrapper';
  static leaveThisHerePlaceholder: string = '<div style="display:none;">&nbsp;</div>';

  //////////////////////
  // Private variables
  //////////////////////
  private collapseRegex: RegExp = COLLAPSE_REGEX;

  constructor(
    private _bbCodeService: BBCodeService,
    private _bodyClippingService: BodyClippingService
  ) {}

  // Make sure comment has body
  getHistory(previousComment: CommentMailModel, conversationName: string, clipLongBody: boolean = false): string {
    if (!previousComment) {
      return '';
    }

    return (
      '<div class="original-content" style="border-left: #e9e9e9 1px solid; padding-left: 10px">' +
      this.getHistoryHeader(previousComment, conversationName) +
      '<br><br>' +
      '<div style="margin-left: 10px;">' +
      this.getHistoryBody(previousComment, clipLongBody) +
      '</div>' +
      '</div>'
    );
  }

  prepareCommentForPrint(html: string, previousComment: CommentMailModel, conversationName: string): string {
    if (!previousComment) {
      return '';
    }

    // We need to append WordSection1 for emails from outlook, as they set the print width, orientation directly...
    return (
      '<div class="original-content WordSection1">' +
      this.getHistoryHeader(previousComment, conversationName) +
      '<br><br>' +
      '<div style="margin-left: 10px;">' +
      html +
      '</div>' +
      '</div>'
    );
  }

  getHistoryAttachments(previousComment: CommentMailModel, currentAttachments: FileModel[] = []): FileModel[] {
    // History attachments: only inline (hidden) attachments

    if (!previousComment) {
      return currentAttachments || [];
    }

    // Combine all
    let combinedAttachments = [...previousComment.getInlineAttachments(), ...currentAttachments];

    // Remove duplicates
    return _.uniqBy(combinedAttachments, file => file._id);
  }

  getForwardAttachments(previousComment: CommentMailModel, currentAttachments: FileModel[] = []): FileModel[] {
    // Forward attachments: all from previous comment (inline and attachments)

    if (!previousComment) {
      return currentAttachments || [];
    }

    // Combine all
    let combinedAttachments = [...previousComment.getAttachments(), ...currentAttachments];

    // Remove duplicates
    return _.uniqBy(combinedAttachments, file => file._id);
  }

  getHistoryBody(previousComment: CommentMailModel, clipLongBody: boolean = false): string {
    let html = '';

    if (previousComment && previousComment instanceof CommentMailModel && previousComment.hasBody()) {
      let bodyAsHTML = previousComment.body.content;

      if (clipLongBody) {
        bodyAsHTML = this._bodyClippingService.getClippedBody(bodyAsHTML, previousComment.id, 'reply-history').body;
      }

      if (!previousComment.hasHtmlBody()) {
        // Transform body from bbCode to html
        bodyAsHTML = this._bbCodeService.process({
          text: bodyAsHTML,
          removeMisalignedTags: false,
          addInLineBreaks: false
        });
      }

      html = `<div>${bodyAsHTML}</div>`;

      // Remove file:// from paths because Froala will not render them correctly
      // (Froala will just remove whole <img> element)
      html = html.replace(/file:(\/|\\)+/g, checkIfOS(OperatingSystem.DARWIN) ? '/' : '');
    }

    return this.removeAllOtherCollapseTags(html);
  }

  prepareDraftBody(content: string, history: string, signatureHtml: string): string {
    content = this.removeSignatureFromBody(content);

    if (_.isEmpty(content)) {
      content = '<div><br></div>';
    }

    if (signatureHtml) {
      content += `<div class="${HistoryAndSignatureHelper.signatureInDraftWrapper}">` + signatureHtml + `</div>`;
    }

    if (history) {
      content += `<div class="${HistoryAndSignatureHelper.historyInDraftWrapper}">` + history + `</div>`;
    }

    return content;
  }

  parseDraftBody(body: string): { content: string; history: string; signatureHtml: string } {
    let content = '',
      history = '',
      signatureHtml = '';
    // Parse string to html
    let wrapper = document.createElement('div');
    wrapper.innerHTML = body;

    // Select history section
    let historyContainer =
      wrapper.querySelector(`div[fr-original-class="${HistoryAndSignatureHelper.historyInDraftWrapper}"]`) ||
      wrapper.querySelector(`div[class="${HistoryAndSignatureHelper.historyInDraftWrapper}"]`);

    if (historyContainer) {
      // Get and remove history from content
      history = historyContainer.innerHTML;
      historyContainer.remove();
    }

    // Select signature section
    let signatureContainer =
      wrapper.querySelector(`div[fr-original-class="${HistoryAndSignatureHelper.signatureInDraftWrapper}"]`) ||
      wrapper.querySelector(`div[class="${HistoryAndSignatureHelper.signatureInDraftWrapper}"]`);

    if (signatureContainer) {
      // Get and remove signature from content
      signatureHtml = signatureContainer.innerHTML;
      signatureContainer.remove();
    }

    // Get content
    content = wrapper.innerHTML;

    // Cleanup
    wrapper.remove();

    // Return two parts
    return { content, history, signatureHtml };
  }

  appendEditableHistoryAndSignaturePlaceholder(
    content: string,
    history: string,
    skipAppendingSignaturePlaceholder?: boolean
  ): string {
    if (_.isEmpty(history)) {
      return content;
    }

    if (_.isEmpty(content)) {
      content = '<div><br></div>';
    }

    // Append placeholder for signatures
    if (!skipAppendingSignaturePlaceholder) {
      content += `<div class="${HistoryAndSignatureHelper.signatureWrapperClassName}" contenteditable="false">${HistoryAndSignatureHelper.leaveThisHerePlaceholder}</div>`;
    }

    // Append history
    return content + `<div class="${HistoryAndSignatureHelper.editableHistoryWrapperClassName}">` + history + '</div>';
  }

  splitAtEditableHistory(content: string): { content: string; history: string } {
    // Parse string to html
    let wrapper = document.createElement('div');
    wrapper.innerHTML = content;

    // Select history section
    let historyContainer =
      wrapper.querySelector(`div[fr-original-class="${HistoryAndSignatureHelper.editableHistoryWrapperClassName}"]`) ||
      wrapper.querySelector(`div[class="${HistoryAndSignatureHelper.editableHistoryWrapperClassName}"]`);

    if (!historyContainer) {
      return { content: content, history: '' };
    }

    // Remove history from content
    let history = historyContainer.innerHTML;
    historyContainer.remove();

    // Get content
    let innerHTML = wrapper.innerHTML;

    // Cleanup
    wrapper.remove();

    // Return two parts
    return { content: innerHTML, history: history };
  }

  removeSignatureFromBody(html: string): string {
    // Parse string to html
    let wrapper = document.createElement('div');
    wrapper.innerHTML = html;

    // Remove all signatures
    _.forEach(
      <any>wrapper.querySelectorAll(`div[fr-original-class="${HistoryAndSignatureHelper.signatureWrapperClassName}"]`),
      signature => {
        signature.remove();
      }
    );

    // Save content and cleanup
    let innerHTML = wrapper.innerHTML;
    wrapper.remove();

    return innerHTML;
  }

  appendHistoryAndSignatureToBody(content: string, history: string, signatureHtml: string, isForward: boolean) {
    if (signatureHtml || !_.isEmpty(history)) {
      let headerClass = isForward ? '' : ` class="${COLLAPSE_TAG_CLASSES.loop_quote}"`; // Collapse tag (if NOT forward)

      content +=
        `<div${headerClass}>` + // Header
        '<div style="margin-top: 40px">' + // Margin
        (signatureHtml ? `<div>${signatureHtml}</div>` : '') + // Signature
        (signatureHtml && history ? `<br><br>` : '') + // Spacing
        (history ? `<div>${this.removeAllOtherCollapseTags(history)}</div>` : '') + // History
        '</div></div>';
    }

    return content;
  }

  ////////////////////
  // Private helpers
  ////////////////////
  private removeAllOtherCollapseTags(html: string): string {
    return html.replace(this.collapseRegex, '');
  }

  private getHistoryHeader(previousComment: CommentMailModel, conversationName: string): string {
    // From
    let from = `<b>From:</b> ${previousComment.author.name} ${this.getUserEmailInChevrons(previousComment.author.email)}`;

    // To
    let to = `<br><b>To:</b> ${this.getListOfContactsInForHistoryHeader(previousComment.to.resources)}`;

    // Cc
    let cc = previousComment.hasCc()
      ? `<br><b>Cc:</b> ${this.getListOfContactsInForHistoryHeader(previousComment.cc.resources)}`
      : '';

    // Cc
    let bcc = previousComment.hasBcc()
      ? `<br><b>Bcc:</b> ${this.getListOfContactsInForHistoryHeader(previousComment.bcc.resources)}`
      : '';

    // Date
    let date = `<br><b>Date:</b> ${moment(previousComment.created).format('dddd, MMMM DD, YYYY hh:mm A')}`;

    // Subject
    let subject = `<br><b>Subject:</b> ${conversationName?.replace('<', '&lt;').replace('>', '&gt;')}`;

    return from + to + cc + bcc + date + subject;
  }

  private getUserEmailInChevrons(email: string): string {
    return `&lt;${email}&gt;`;
  }

  private getListOfContactsInForHistoryHeader(contacts: ContactBase[]): string {
    if (!contacts) {
      return '';
    }

    let transformedListOfToRecipients = _.map(
      contacts,
      (contact: ContactBase) =>
        contact.name +
        (contact.$type === UserModel.type ? ` ${this.getUserEmailInChevrons((<User>contact).email)}` : '')
    );

    return transformedListOfToRecipients.join('; ');
  }
}
