import * as _ from 'lodash';
import { Directive } from '@angular/core';
import { BaseService } from '../base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { FileServiceI } from './file.service.interface';
import { CommentBaseModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { RecentSearchFilter, SearchFilterType } from '@dta/shared/models/search.model';
import { ContactBase, ConvertedAttachments, ConvertedTempAttachment, ThumbnailSize } from '@shared/api/api-loop/models';
import { from, Observable, of, switchMap } from 'rxjs';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { catchError, defaultIfEmpty, filter, flatMap, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { FileExDecorateService } from '@shared/decorators/extra-data-decorators/file-ex-decorator/file-ex-decorate.service';
import { FileApiService } from '@shared/api/api-loop/services';
import { FileHelper } from '@dta/shared/utils/file.helper';
import { Environment, EnvironmentType, getBuffer } from '@dta/shared/utils/common-utils';
import { LibrarySearchResults, SearchResultType } from '@dta/shared/models/library.model';
import { FileDaoService } from '@shared/database/dao/file/file-dao.service';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel } from '@dta/shared/models/logger.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { FileDownloadService } from '@shared/modules/files/shell/services/file-download.service';

@Directive()
export abstract class FileService extends BaseService implements FileServiceI {
  static tempFileIdPrefix = 'TF_';
  abstract showLoaderWhenUploadingFile: boolean;

  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _fileExDecorateService: FileExDecorateService,
    protected _fileApiService: FileApiService,
    protected _fileDaoService: FileDaoService,
    protected _fileStorageService: FileStorageService,
    protected readonly fileDownloadService: FileDownloadService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'FileService';
  }

  abstract storeLocalFile(
    forUserEmail: string,
    file: File,
    hidden?: boolean,
    base64?: any,
    shouldUpload?: boolean
  ): Observable<FileModel>;

  downloadThumbnail(
    forUserEmail: string,
    file: FileModel,
    size: ThumbnailSize = ThumbnailSize.MEDIUM
  ): Observable<FileModel> {
    if (!file.thumbnailInfo.supported) {
      return of(file);
    }

    let filename = this._fileStorageService.imageThumbnailFilename(file, size);
    return this._fileStorageService.fileExistsInUrlStorage(filename).pipe(
      switchMap(exists => {
        if (exists) {
          return of(file);
        }

        return this._fileApiService
          .File_GetThumbnailSignedLink(
            {
              id: file.id,
              thumbnailSize: size
            },
            forUserEmail
          )
          .pipe(switchMap(url => this._fileStorageService.writeFileUrl(filename, url)));
      }),
      map(() => {
        file._ex.syncStatus.thumbnail = true;
        return file;
      }),
      defaultIfEmpty(file),
      catchError(err => {
        Logger.error(err, 'Could not download thumbnail at ' + this.constructorName);
        return of(file);
      })
    );
  }

  abstract downloadFile(
    forUserEmail: string,
    file: FileModel,
    saveToDownloads?: boolean,
    openInNativeAfterDownload?: boolean
  ): Observable<string>;

  saveAll(forUserEmail: string, files: FileModel[]): Observable<FileModel[]> {
    if (_.isEmpty(files)) {
      return of(files);
    }

    const filesIds = files.map(file => file.id);

    return this._fileDaoService.findByIds(forUserEmail, filesIds).pipe(
      map(localFiles => {
        const fileById = localFiles.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});

        const localFileIds = Object.keys(fileById);

        return files.map(file => {
          if (localFileIds.includes(file.id)) {
            return fileById[file.id];
          }
          return file;
        });
      }),
      switchMap(files => {
        return this._fileExDecorateService.decorateListExtraData(forUserEmail, files).pipe(
          mergeMap((_files: FileModel[]) => {
            return this._fileDaoService.saveAll(forUserEmail, _files);
          })
        );
      })
    );
  }

  save(forUserEmail: string, file: FileModel): Observable<FileModel> {
    return this.saveAll(forUserEmail, [file]).pipe(map((files: FileModel[]) => _.first(files)));
  }

  saveAllAndPublish(forUserEmail: string, files: FileModel[]): Observable<FileModel[]> {
    if (_.isEmpty(files)) {
      return of(files);
    }

    return this._fileExDecorateService.decorateListExtraData(forUserEmail, files).pipe(
      mergeMap((_files: FileModel[]) => {
        return this._fileDaoService.saveAll(forUserEmail, _files);
      }),
      tap((_files: FileModel[]) => {
        PublisherService.publishEvent(forUserEmail, _files);
      })
    );
  }

  filterNewFiles(forUserEmail: string, files: FileModel[]): Observable<FileModel[]> {
    return this._fileDaoService.findBaseByIds(forUserEmail, files).pipe(
      map((dbFiles: IFile[]) => {
        return _.differenceBy(files, dbFiles as FileModel[], 'id');
      })
    );
  }

  findNonChannelFilesByIds(forUserEmail: string, files: IFile[], contact: ContactBase): Observable<FileModel[]> {
    return this._fileDaoService.findNonChannelFilesByIds(forUserEmail, files, contact);
  }

  updateChannelIds(forUserEmail: string, files: FileModel[], contactId: string): Observable<FileModel[]> {
    if (_.isEmpty(files) || !contactId) {
      return of([]);
    }

    return this._fileDaoService.updateChannelIdsByFiles(forUserEmail, files, contactId);
  }

  convertNonTempAttachmentsToTemp(
    forUserEmail: string,
    attachments: FileModel[]
  ): Observable<ConvertedTempAttachment[]> {
    // Filter non-temp attachments
    let attachmentsByType = _.groupBy(attachments, (attachment: FileModel) =>
      attachment.id.startsWith(FileService.tempFileIdPrefix) ? 'temp' : 'other'
    );

    let nonTempAttachmentIds = _.map(attachmentsByType['other'], (file: FileModel) => file.id);

    if (_.isEmpty(nonTempAttachmentIds)) {
      return of([]);
    }

    // Convert non-temp attachments
    return this._fileApiService
      .File_ConvertFileAttachmentToTempAttachmentPOST(
        { bodyFileAttachmentIds: BaseModel.createListOfResources(nonTempAttachmentIds) },
        forUserEmail
      )
      .pipe(map((convertedAttachments: ConvertedAttachments) => convertedAttachments.tempAttachment));
  }

  uploadLocalFile(
    forUserEmail: string,
    file: File,
    hidden: boolean = false,
    path: string = '',
    fileModel?: FileModel
  ): Observable<FileModel> {
    let _file: FileModel = fileModel ? fileModel : FileModel.buildFromLocalFile(file, undefined, undefined, hidden);

    return this._fileStorageService.readFile(path).pipe(
      filter((data: Uint8Array) => {
        // If file has already been uploaded - then there is nothing else to do
        if (_.isNil(data)) {
          Logger.customLog('File could not be read from tmp folder: ' + path, LogLevel.ERROR);

          return false;
        }
        return true;
      }),
      /**
       * Upload file
       */
      mergeMap((data: Uint8Array) => {
        return this.uploadFile(forUserEmail, _file, data, hidden);
      })
    );
  }

  updateFileContent(forUserEmail: string, fileId: string, file: Blob): Observable<IFile> {
    let params: FileApiService.File_UpdateFileProperParams = {
      id: fileId,
      file: file
    };
    return this._fileApiService.File_UpdateFileProper(params, forUserEmail);
  }

  downloadFileWhenMissing(
    forUserEmail: string,
    file: FileModel,
    isForSignature: boolean = false
  ): Observable<FileModel> {
    if (!file || !file.id) {
      throw new Error('File cannot be nil');
    }
    if (!file.name) {
      throw new Error('Filename cannot be nil');
    }

    let fileName =
      isForSignature || file._ex.isSignature
        ? this._fileStorageService.signatureFilename(file)
        : this._fileStorageService.getFileName(file, false);

    return this._fileStorageService.fileExists(fileName).pipe(
      filter((exists: boolean) => {
        return !exists;
      }),
      mergeMap(() => {
        return this.getFileContent(forUserEmail, file);
      }),
      mergeMap((buffer: ArrayBuffer) => {
        return this._fileStorageService.writeFile(fileName, getBuffer(buffer));
      }),
      map(() => {
        return file;
      }),
      defaultIfEmpty(file)
    );
  }

  downloadThumbnails(forUserEmail: string, files: FileModel[]): Observable<FileModel[]> {
    return from(files).pipe(
      mergeMap((file: FileModel) => {
        return this.downloadThumbnail(forUserEmail, file);
      }, 5),
      toArray()
    );
  }

  findFilesForSearch(
    forUserEmail: string,
    query: string,
    offset: number,
    size: number,
    filter: RecentSearchFilter
  ): Observable<FileModel[]> {
    return this._fileDaoService.findFilesBySearchQueryAndRecentFilter(forUserEmail, query, filter, offset, size).pipe(
      mergeMap((files: FileModel[]) => this._fileExDecorateService.decorateListExtraData(forUserEmail, files)),
      /**
       * Force download thumbnails
       */
      mergeMap((files: FileModel[]) => this.downloadThumbnails(forUserEmail, files))
    );
  }

  findFileById(forUserEmail: string, id: string): Observable<FileModel> {
    return this._fileDaoService.findById(forUserEmail, id);
  }

  findByIds(forUserEmail: string, ids: string[]): Observable<FileModel[]> {
    return this._fileDaoService.findByIds(forUserEmail, ids);
  }

  downloadThumbnailById(forUserEmail: string, id: string): Observable<ArrayBuffer> {
    return this._fileApiService.File_GetFileThumbnail({ id: id, thumbnailSize: 'Small' }, forUserEmail);
  }

  getFilePathWithHash(
    forUserEmail: string,
    fileId: string,
    includeFilePrefix: boolean = true,
    isForSignature: boolean = false
  ): Observable<FilePathWithHash> {
    if (!fileId) {
      Logger.warn('Got null fileId in getFilePathWithHash()');

      let nullFile: FilePathWithHash = {
        fetchStatus: undefined,
        filePath: ''
      };

      return of(nullFile);
    }

    let fetchErrorStatus: number;
    return this.findFileById(forUserEmail, fileId).pipe(
      tap((file: FileModel) => {
        if (!file.hash && file.id) {
          Logger.warn('File with id ' + file.id + ' is missing hash. Extradata: ' + JSON.stringify(file._ex));
        }
      }),
      catchError(err => {
        return this._fileApiService.File_GetFile({ id: fileId }, forUserEmail).pipe(
          map(file => new FileModel(file)),
          mergeMap(file => this.save(forUserEmail, file)),
          catchError(_err => {
            if (_.includes([403], _err.status)) {
              fetchErrorStatus = 403;
            }

            if (_.includes([400], _err.status)) {
              fetchErrorStatus = 400;
            }

            return of(undefined);
          })
        );
      }),
      mergeMap((fileData: FileModel) => {
        let fileName = fileId;

        if (fileData) {
          fileName =
            isForSignature || fileData._ex.isSignature
              ? this._fileStorageService.signatureFilename(fileData)
              : this._fileStorageService.getFileName(fileData, false);
        }

        return of(<FilePathWithHash>{
          filePath: this._fileStorageService.getFilePath(fileName, includeFilePrefix),
          fetchStatus: fetchErrorStatus
        });
      })
    );
  }

  fetchFileMetadataById(forUserEmail: string, fileId: string): Observable<IFile> {
    return this._fileApiService.File_GetFile({ id: fileId }, forUserEmail);
  }

  ////////////////
  // DAO WRAPPERS
  ////////////////
  findChannelFiles(forUserEmail: string, params: any): Observable<FileModel[]> {
    return this._fileDaoService.findChannelFiles(forUserEmail, params);
  }

  findFiles(forUserEmail: string, params: any): Observable<FileModel[]> {
    return this._fileDaoService.findFiles(forUserEmail, params);
  }

  findFilesForComments(forUserEmail: string, comments: CommentBaseModel[]): Observable<FileModel[]> {
    return this._fileDaoService.findFilesForComments(forUserEmail, comments);
  }

  removeAll(forUserEmail: string, files: FileModel[]): Observable<any> {
    return this._fileDaoService.removeAll(forUserEmail, files);
  }

  findCommentIdsForFileHashes(forUserEmail: string, fileHashes: string[]): Observable<{ [hash: string]: string[] }> {
    return this._fileDaoService.findCommentIdsForFileHashes(forUserEmail, fileHashes);
  }

  findFilesBySearchQuery(
    forUserEmail: string,
    query: string,
    offset: number,
    size: number
  ): Observable<LibrarySearchResults> {
    return this._fileDaoService.findFilesBySearchQuery(forUserEmail, query, offset, size).pipe(
      flatMap((fileModels: FileModel[]) => {
        return this._fileExDecorateService.decorateListExtraData(forUserEmail, fileModels);
      }),
      map((decoratedFileModels: FileModel[]) => {
        return {
          $type: SearchResultType.Offline,
          result: decoratedFileModels,
          length: decoratedFileModels.length
        };
      })
    );
  }

  fetchFilesForSearch(
    forUserEmail: string,
    query: string,
    offset: number,
    size: number,
    currentUserId: string,
    filter: RecentSearchFilter
  ): Observable<FileModel[]> {
    let queryParams: FileApiService.File_GetListParams = {
      size: size,
      offset: offset,
      searchQuery: query
    };
    if (filter) {
      if (filter.Type === SearchFilterType.TO || filter.IsGroup) {
        _.assign(queryParams, {
          senderIds: filter.IsGroup ? [] : [currentUserId],
          recipientIds: filter.Ids,
          contactFilterRelation: 'and'
        });
      }
      if (filter.Type === SearchFilterType.FROM && !filter.IsGroup) {
        _.assign(queryParams, {
          senderIds: filter.Ids
        });
      }
      if (filter.Type === SearchFilterType.ANY && !filter.IsGroup) {
        _.assign(queryParams, {
          senderIds: filter.Ids,
          recipientIds: filter.Ids
        });
      }
    }
    return this._fileApiService.File_GetList(queryParams, forUserEmail).pipe(
      map(result => result.resources),
      map(FileModel.createList),
      mergeMap(files => this._fileExDecorateService.decorateListExtraData(forUserEmail, files)),
      /**
       * Force download thumbnails
       */
      mergeMap(files => this.downloadThumbnails(forUserEmail, files))
    );
  }

  getFilenameFromPath(forUserEmail: string, filePath: string): Observable<string> {
    return of(filePath.split('/').slice(-1)[0]);
  }

  findOrFetchFileById(forUserEmail: string, id: string): Observable<FileModel> {
    if (!id) {
      return of(undefined);
    }

    return this.findFileById(forUserEmail, id).pipe(
      catchError(err =>
        id.includes(BaseModel.idPrefix)
          ? of(undefined)
          : this._fileApiService.File_GetFile({ id: id }, forUserEmail).pipe(
              map(file => new FileModel(file)),
              mergeMap(file => this.save(forUserEmail, file))
            )
      ),
      mergeMap((file: FileModel) => (file ? this._fileExDecorateService.decorate(forUserEmail, file) : of(undefined)))
    );
  }

  // BE ONLY
  getFileContent(forUserEmail: string, file: FileModel): Observable<ArrayBuffer> {
    return this._fileApiService.File_GetFileContent({ id: file.id }, forUserEmail);
  }

  uploadFile(
    forUserEmail: string,
    file: FileModel,
    fileData: any,
    includeSignedLink: boolean = false
  ): Observable<FileModel> {
    // no need to upload file when attaching from library
    if (!fileData) {
      return of(file);
    }

    if (file.id) {
      return of(FileModel.buildAsReference(file) as FileModel);
    }

    let blob: Blob = new Blob([fileData]);
    blob['name'] = file.name;

    let params = {
      file: blob,
      includeSignedLink: includeSignedLink
    } as FileApiService.File_CreateFileParams;

    return this._fileApiService.File_CreateFile(params, forUserEmail).pipe(
      map((response: IFile) => {
        // preserve fields
        response.clientId = file.clientId;
        response.hidden = file.hidden;

        return new FileModel(response);
      }),
      mergeMap((file: FileModel) => {
        return this._fileExDecorateService.decorateExtraData(forUserEmail, file);
      })
    );
  }

  createFile(forUserEmail: string, params: FileApiService.File_CreateFileParams) {
    return this._fileApiService.File_CreateFile(params, forUserEmail).pipe(
      map((response: IFile) => {
        return new FileModel(response);
      })
    );
  }

  uploadAndDecorateFile(
    forUserEmail: string,
    file: File,
    hidden?: boolean,
    includeSignedLink?: boolean
  ): Observable<FileModel> {
    return this._fileApiService.File_CreateFile({ file, includeSignedLink: true }, forUserEmail).pipe(
      mergeMap((fileResponse: IFile) => {
        let fileObject = FileHelper.getLocalFileProperties(file);
        let fileModel = new FileModel(fileResponse);
        fileModel.hidden = hidden;

        return this._fileExDecorateService.decorateExtraDataFromLocalFile(fileModel, fileObject);
      }),
      mergeMap((_file: FileModel) => {
        _file._ex.path = _file._ex.originalPath || _file._ex.path;
        _file._ex.syncStatus.binary = true;
        _file._ex.syncStatus.preview = _file._ex.isImage || _file._ex.isPdf || _file._ex.isVideo;
        _file._ex.syncStatus.failed = false;
        _file._ui = {
          thumbnailPath: URL.createObjectURL(file)
        };

        return of(_file);
      })
    );
  }

  abstract beforeSyncServiceInit(forUserEmail: string): Observable<any>;
}

export class FilePathWithHash {
  filePath: string;
  fetchStatus: number; // undefined == did not fetch
}
