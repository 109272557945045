/* tslint:disable */

export type AuthType =
  | 'Test'
  | 'Google'
  | 'Exchange'
  | 'Office365'
  | 'Integration'
  | 'MicrosoftGraph'
  | 'ImapSmtp'
  | 'RemoteImap'
  | 'Password';

export module AuthType {
  export const TEST: AuthType = 'Test';
  export const GOOGLE: AuthType = 'Google';
  export const EXCHANGE: AuthType = 'Exchange';
  export const OFFICE_365: AuthType = 'Office365';
  export const INTEGRATION: AuthType = 'Integration';
  export const MICROSOFT_GRAPH: AuthType = 'MicrosoftGraph';
  export const IMAP_SMTP: AuthType = 'ImapSmtp';
  export const REMOTE_IMAP: AuthType = 'RemoteImap';
  export const PASSWORD: AuthType = 'Password';

  export function values(): AuthType[] {
    return [TEST, GOOGLE, EXCHANGE, OFFICE_365, INTEGRATION, MICROSOFT_GRAPH, IMAP_SMTP, REMOTE_IMAP, PASSWORD];
  }
}
