import * as moment from 'moment';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CardModel } from '../../../shared/models-api-loop/conversation-card/card/card.model';
import { AutoUnsubscribe } from '../../../shared/utils/subscriptions/auto-unsubscribe';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { KeyboardShortcutsListenerService } from '../../services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { DropdownComponent } from '../common/dropdown/dropdown.component';
import { tap } from 'rxjs/operators';
import { NotificationEventType } from '../../../shared/models/notifications.model';
import { TooltipPlacement } from '../common/tooltip/tooltip.component';
import { TrackingService } from '../../../shared/services/tracking/tracking.service';
import { ClickTrackingLocation } from '../../../shared/services/tracking/tracking.constants';
import { StorageKey, StorageService } from '../../../shared/services/storage/storage.service';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { SnoozeService } from '@shared/services/data/snooze/snooze.service';
import { OptimisticResponseHelper } from '../common/conversation-list/optimistic-response.helper';
import { OptimisticResponseState } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { RangeDate } from '@dta/ui/components/date-picker/date-picker.component';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Component({
  selector: 'snooze-dropdown',
  styleUrls: ['./snooze-dropdown.scss'],
  templateUrl: './snooze-dropdown.html',
})
@AutoUnsubscribe()
export class SnoozeDropdownComponent extends DropdownComponent implements OnDestroy {
  TooltipPlacement = TooltipPlacement;

  ////////////////
  // View queries
  ////////////////
  @ViewChild('itemsWrapper', { static: false }) itemsWrapper: ElementRef;

  /////////
  // SVGs
  /////////
  laterSvg = require('shared/assets/img/svg/time-later.svg');
  calendarSvg = require('shared/assets/img/svg/calendar2.svg');
  cupSvg = require('shared/assets/img/svg/medium-cup.svg');
  briefcaseSvg = require('shared/assets/img/svg/briefcase.svg');
  dropdownToggleSvg = require('shared/assets/img/svg/open-dropdown.svg');
  sunSvg = require('shared/assets/img/svg/medium-sun.svg');
  replySvg = require('shared/assets/img/svg/replay-circle.svg');

  ////////////////////
  // Inputs, Outputs
  ////////////////////
  @Input() conversation: ConversationModel;
  @Input() openRight: boolean = false;
  @Input() time: string;

  @Output() closeCard$: EventEmitter<boolean> = new EventEmitter();

  ///////////////////
  // State variables
  ///////////////////
  datePickerTab: boolean = false;
  defaultOptions: SnoozeDropdownOptions[] = [
    {
      name: 'Tomorrow',
      displayTime: moment(this.getDateAt(1, 9, 0)).format('ddd HH:mm'),
      callback: () => {
        this.snooze(this.getDateAt(1, 9, 0) as string);
      },
      svg: this.cupSvg,
    },
    {
      name: 'Next week',
      displayTime: moment(this.getNextMondayDateAt(9, 0)).format('ddd HH:mm'),
      callback: () => {
        this.snooze(this.getNextMondayDateAt(9, 0));
      },
      svg: this.briefcaseSvg,
    },
    {
      name: 'Someday',
      svg: this.sunSvg,
      callback: () => {
        this.someday();
      },
    },
  ];

  options: SnoozeDropdownOptions[] = [];

  //////////////////
  // Subscriptions
  //////////////////
  private setSnoozeSub: Subscription;
  private dismissSnoozeSub: Subscription;

  constructor(
    protected _elementRef: ElementRef,
    protected _changeDetection: ChangeDetectorRef,
    protected _keyboardShortcutListenerService: KeyboardShortcutsListenerService,
    private _snoozeService: SnoozeService,
    private _userManagerService: UserManagerService,
    private _notificationsService: NotificationsService,
    private _trackingService: TrackingService,
    private _storageService: StorageService,
  ) {
    super(_elementRef, _changeDetection, _keyboardShortcutListenerService);
  }

  get upperLimitDate(): Date {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    return date;
  }

  get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  get selectedSnoozeTime(): string {
    if (this.conversation.hasPendingSnooze()) {
      return this.snoozeDueDate;
    }

    return undefined;
  }

  get hasPendingSnooze(): boolean {
    return this.conversation.hasPendingSnooze();
  }

  get snoozeDueDate(): string {
    return this.conversation.getSnoozeDueDate();
  }

  get selectedSnoozeTimeFormatted(): string {
    if (this.conversation.hasSnoozeSomeday()) {
      return 'Someday';
    }

    return moment(this.snoozeDueDate).format('ddd HH:mm');
  }

  get selectedSnoozeTimeTooltip(): string {
    if (!this.snoozeDueDate || this.conversation.hasSnoozeSomeday()) {
      return undefined;
    }

    return moment(this.snoozeDueDate).format('MMMM Do YYYY, h:mm a');
  }

  get storageKey(): string {
    return this._storageService.getKey(this.currentUserEmail, StorageKey.lastSelectedSnooze);
  }

  ////////////////
  // View methods
  ////////////////
  snooze(time: string, someday: boolean = false, fromDatePicker = false) {
    this.closeDropdown();

    if (fromDatePicker) {
      this.persistLastSelectedToLocalStorage(time);
    }

    this._trackingService.trackUserClick(this.currentUserEmail, ClickTrackingLocation.SnoozeDropdown, 'Snooze set', {
      someday,
      fromDatePicker,
    });

    this.setSnoozeSub?.unsubscribe();
    this.setSnoozeSub = this._snoozeService
      .setOrUpdateSnooze(this.currentUserEmail, this.conversation.id, time)
      .pipe(
        tap((conversation: ConversationModel) => {
          this._notificationsService.setInAppNotification(this.currentUserEmail, {
            type: NotificationEventType.Snooze,
            msg: 'Snoozed till ' + moment(time).format('D MMM HH:mm'),
          });

          this.closeCard$.emit(true);

          OptimisticResponseHelper.triggerApplyOptimisticResponse(
            this.currentUserEmail,
            [conversation.id],
            OptimisticResponseState.TAGS_UPDATE,
            { listOfTags: conversation.tags },
          );
        }),
      )
      .subscribe();
  }

  dismissSnooze() {
    this.dismissSnoozeSub?.unsubscribe();
    this.dismissSnoozeSub = this._snoozeService
      .removeAllSnoozeTags(this._userManagerService.getCurrentUserEmail(), this.conversation.id)
      .pipe(
        tap((conversation: ConversationModel) => {
          this._notificationsService.setInAppNotification(this.currentUserEmail, {
            type: NotificationEventType.Snooze,
            msg: 'Snooze discarded',
          });

          this.closeCard$.emit(true);

          OptimisticResponseHelper.triggerApplyOptimisticResponse(
            this.currentUserEmail,
            [conversation.id],
            OptimisticResponseState.TAGS_UPDATE,
            { listOfTags: conversation.tags },
          );
        }),
      )
      .subscribe();

    this.closeDropdown();
  }

  selectOption(option) {
    option.callback();
  }

  hideDatePicker() {
    this.datePickerTab = false;
    this.triggerChangeDetection();
  }

  showDatePicker() {
    this.datePickerTab = true;
    this.triggerChangeDetection();
  }

  someday() {
    this.snooze(new Date(9999, 0).toISOString(), true);
  }

  onDropdownOpen() {
    this.setDDOptions();

    this._trackingService.trackUserClick(
      this.currentUserEmail,
      ClickTrackingLocation.SnoozeDropdown,
      'Snooze dropdown opened',
    );
  }

  // Date picker
  dateSelected(date: RangeDate) {
    this.snooze(date.from.toISOString(), false, true);
  }

  ///////////////////
  // Private methods
  ///////////////////
  private getNextMondayDateAt(hour: number, minutes: number, date = new Date()): string {
    date.setHours(hour);
    date.setMinutes(minutes);

    do {
      date.setDate(date.getDate() + 1);
    } while (date.getDay() !== 1);

    return date.toISOString();
  }

  private getDateAt(
    daysFromNow: number,
    hour: number,
    minutes: number,
    returnDateObject: boolean = false,
  ): string | Date {
    const date = new Date();

    date.setDate(date.getDate() + daysFromNow);
    date.setHours(hour);
    date.setMinutes(minutes);

    if (returnDateObject) {
      return date;
    }

    return date.toISOString();
  }

  private setDDOptions() {
    const lastSelected: string = this.getLastSelectedFromLocalStorage();
    const showLastSelected: boolean =
      lastSelected && !moment(lastSelected).isSame(this.snoozeDueDate) && moment(new Date()).isBefore(lastSelected);

    this.options = [
      // We don't show this option after a certain time of the day
      ...(this.isTimeTodayBeforeHour(16)
        ? [
            {
              name: 'Later today',
              displayTime: moment(this.getDateAt(0, 16, 30)).format('HH:mm'),
              callback: () => {
                this.snooze(this.getDateAt(0, 16, 30) as string);
              },
              svg: this.laterSvg,
            },
          ]
        : []),

      // Default
      ...this.defaultOptions,

      // Last selected
      ...(showLastSelected
        ? [
            {
              name: 'Last selected',
              displayTime: moment(lastSelected).format('ddd HH:mm'),
              callback: () => {
                this.snooze(lastSelected);
              },
              svg: this.replySvg,
              extraClass: 'last-selected border-top',
            },
          ]
        : []),

      // Date picker
      {
        name: 'Pick a date',
        callback: () => {
          this.showDatePicker();
        },
        svg: this.calendarSvg,
        subsection: true,
        extraClass: showLastSelected ? undefined : 'border-top',
      },

      // If snooze due
      ...(this.conversation?.getSnoozeDueDate()
        ? [
            {
              name: 'Discard snooze',
              uiDiscard: true,
              callback: () => {
                this.dismissSnooze();
              },
            },
          ]
        : []),
    ];

    this.datePickerTab = false;
    this.maxSelectionIndex = this.options.length - 1;
  }

  private isTimeTodayBeforeHour(hour: number) {
    return new Date() < this.getDateAt(0, hour, 0, true);
  }

  protected confirmListItemSelection() {
    if (this.datePickerTab) {
      return;
    }

    this.options[this.selectionIndex].callback();
  }

  private persistLastSelectedToLocalStorage(time: string) {
    this._storageService.setItem(this.storageKey, time);
  }

  private getLastSelectedFromLocalStorage(): string {
    return this._storageService.getItem(this.storageKey);
  }
}

interface SnoozeDropdownOptions {
  name: string;
  callback: () => void;
  svg?: NodeRequire;
  displayTime?: string;
  subsection?: boolean;
  uiDiscard?: boolean;
  extraClass?: string;
}
