import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsPureArgumentForModule } from '@shared/ui/pipes/as-pure-argument-for/as-pure-argument-for.module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { IsImageModule } from '@shared/modules/files/components/pipes/is-image/is-image.module';
import { IsPdfModule } from '@shared/modules/files/components/pipes/is-pdf/is-pdf.module';
import { IsVideoModule } from '@shared/modules/files/components/pipes/is-video/is-video.module';
import { HasPreviewPipe } from '@shared/modules/files/components/files-preview-dialog-content/has-preview.pipe';
import { FilePreviewModule } from '@shared/modules/files/components/file-preview/file-preview.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { PdfjsModule } from '@dta/ui/components/common/pdfjs/pdfjs.module';
import { FilesPreviewDialogContentComponent } from './files-preview-dialog-content.component';

@NgModule({
  declarations: [FilesPreviewDialogContentComponent],
  imports: [
    CommonModule,
    HasPreviewPipe,
    AsPureArgumentForModule,
    GraphicsModule,
    PdfjsModule,
    IsImageModule,
    IsPdfModule,
    IsVideoModule,
    HasPreviewPipe,
    FilePreviewModule,
    IconModule
  ],
  exports: [FilesPreviewDialogContentComponent]
})
export class FilesPreviewDialogContentModule {}
