import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDecorateService } from './base-decorate.service';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { CommentViewDecorateService } from './view-data-decorators/comment-view-decorator/comment-view-decorate.service';
import { CommentExDecorateService } from './extra-data-decorators/comment-ex-decorator/comment-ex-decorate.service';

@Injectable()
export class CommentDecorateService extends BaseDecorateService<CommentModel> {
  constructor(
    private _commentViewDecorateService: CommentViewDecorateService,
    private _commentExDecorateService: CommentExDecorateService,
  ) {
    super();
  }

  decorateExtraData(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    return this._commentExDecorateService.decorate(forUserEmail, comment);
  }

  decorateViewData(forUserEmail: string, comment: CommentModel, force?: boolean): Observable<CommentModel> {
    return this._commentViewDecorateService.decorate(forUserEmail, comment, force);
  }
}
