/* tslint:disable */

/**
 * Subscription types
 */
export type SubscriptionMemberType = 'User' | 'SharedInbox' | 'Personal' | 'SharedInboxAccount';

export module SubscriptionMemberType {
  export const USER: SubscriptionMemberType = 'User';
  export const SHARED_INBOX: SubscriptionMemberType = 'SharedInbox';
  export const PERSONAL: SubscriptionMemberType = 'Personal';
  export const SHARED_INBOX_ACCOUNT: SubscriptionMemberType = 'SharedInboxAccount';

  export function values(): SubscriptionMemberType[] {
    return [USER, SHARED_INBOX, PERSONAL, SHARED_INBOX_ACCOUNT];
  }
}
