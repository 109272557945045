import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormControlWrapperComponent } from '@shared/modules/form-controls/base/form-control-wrapper/form-control-wrapper.component';

@NgModule({
  declarations: [FormControlWrapperComponent],
  imports: [CommonModule, FormsModule],
  exports: [FormControlWrapperComponent],
})
export class FormControlWrapperModule {}
