import { Color, ColorType } from '@shared/modules/color-scheme/common/color-types-array';
import * as _ from 'lodash';

export type EssentialColorPaletteData = Record<ColorType, Color>;
export class ColorPalette implements Record<ColorType, Color> {
  success!: Color;
  warning!: Color;
  danger!: Color;
  dark!: Color;

  constructor(data: EssentialColorPaletteData) {
    _.assign(this, data);
  }
}
