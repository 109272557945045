<loop-form-control-wrapper [baseFormControl]="this">
  <div cdkOverlayOrigin #origin="cdkOverlayOrigin" class="select-wrapper">
    <ng-container *ngIf="customValueTpl; else defaultInputRendererTpl">
      <div (click)="showDropdown = true" class="w-100 flex" style="height: 32px">
        <ng-container *ngTemplateOutlet="customValueTpl; context: { $implicit: selectedValue$ | async }"></ng-container>
      </div>
    </ng-container>

    <ng-template #defaultInputRendererTpl>
      <div
        class="fake-input-wrapper"
        [class.focus]="showDropdown"
        [class.disabled]="isDisabled$ | async"
        (click)="showDropdown = true"
      >
        <ng-container *ngIf="!(queryString$ | async); else emptyDivTpl">
          <span *ngIf="(value$ | async | asArray).length === 0" class="select-placeholder">
            {{ placeholder }}
          </span>

          <ng-container *ngIf="mode === 'multiple'; else singleModeTpl">
            <ng-container *ngFor="let value of selectedValue$ | async">
              <loop-tag [value]="value.label" [canRemove]="true" (remove)="removeClick(value.value, $event)"></loop-tag>
            </ng-container>
          </ng-container>
          <ng-template #singleModeTpl>
            <ng-container *ngFor="let value of selectedValue$ | async">
              {{ value.label }}
            </ng-container>
          </ng-template>
        </ng-container>

        <ng-template #emptyDivTpl><div></div></ng-template>

        <input
          [ngModel]="queryString$ | async"
          (ngModelChange)="queryString$.next($event)"
          (focus)="onFocus()"
          class="custom-select-input"
        />
        <div class="flex items-center caret-down">
          <loop-icon name="angle-up" width="16px"></loop-icon>
        </div>
      </div>
    </ng-template>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayMinWidth]="origin.elementRef.nativeElement.clientWidth"
      [cdkConnectedOverlayWidth]="origin.elementRef.nativeElement.clientWidth"
      [cdkConnectedOverlayOrigin]="origin"
      [cdkConnectedOverlayOpen]="showDropdown"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      (backdropClick)="handleOutsideClick()"
      (overlayOutsideClick)="showDropdown = false"
      (detach)="showDropdown = false"
    >
      <div class="options">
        <ng-container *ngFor="let option of filteredOptions$ | async; let index = index">
          <loop-select-item
            [customOptionTemplate]="customOptionTemplate"
            [option]="option"
            [isSelected]="value$ | async | contains: option.value"
            (itemClick)="handleModelChange(option.value)"
          >
          </loop-select-item>
        </ng-container>
      </div>
    </ng-template>
  </div>
</loop-form-control-wrapper>
