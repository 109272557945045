import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { isDropdownRouterLinkItem } from '@shared/ui/dropdown/helpers/dropdown-type-guards';

@Pipe({
  name: 'isDropdownRouterLinkItem',
})
export class IsDropdownRouterLinkItemPipe implements PipeTransform {
  transform(item: DropdownItem): boolean {
    return isDropdownRouterLinkItem(item);
  }
}
