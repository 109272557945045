<div class="flex gap">
  <loop-date-control
    [(ngModel)]="_datePart"
    (ngModelChange)="updateValue(); handleDateOpenChange()"
    [size]="size"
    [placeholder]="placeholder"
    [disabled]="(isDisabled$ | async) === true"
    (controlBlur)="handleBlur()"
    (controlFocus)="onFocus()"
  ></loop-date-control>
  <input
    #timeInput
    loopTimeMask
    [(ngModel)]="_timePart"
    [ngClass]="size"
    [disabled]="!_datePart || (isDisabled$ | async) === true"
    [class.soft-disabled]="!_datePart && (isDisabled$ | async) === false"
    (blur)="handleInputBlur()"
    (focus)="onFocus()"
  />
</div>
