import { ThreadingEnum, User } from '@shared/api/api-loop/models';

export class SendAsOption {
  type: SendAsType;
  user: User;
  groupId?: string;
  personalInbox?: boolean;
  threadingMode?: ThreadingEnum;
}

export enum SendAsType {
  ImpersonateUser,
  Alias,
  Original,
}

export class SendAsProperties {
  sendAsOptions: SendAsOption[] = [];
  sendAsOptionsForNewEmail: SendAsOption[] = [];
  sharedInboxId: string;
  userIdsBySharedInboxId: { [id: string]: string };
}
