import { ChangeDetectionStrategy, Component, inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FolderNode } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';
import { CachedFolderSharedTagApiService } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/cached-folder-shared-tag-api.service';
import { Tag } from '@shared/api/api-loop/models/tag';
import { FolderHelper } from '@shared/services/data/folder/folder-helper';
import { FolderDialogService, FolderTitleEnum } from '@shared/services/folder-dialog/folder-dialog.service';
import { ConversationActionService } from '@shared/services/data/conversation-action/conversation-action.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ActionEnum } from '@shared/api/api-loop/models';
import { NavigationBarService } from '@shared/modules/main/navigation-bar/navigation-bar.service';
import { CustomDropdownBase } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown-base';
import { moveToNextCard } from '@shared/modules/middle-view/helpers/move-to-next-card';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, publishReplay, refCount, switchMap, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { SharedTagFolderModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';

@UntilDestroy()
@Component({
  selector: 'loop-add-to-folder-dropdown',
  templateUrl: './add-to-folder-dropdown.component.html',
  styleUrls: ['./add-to-folder-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddToFolderDropdownComponent extends CustomDropdownBase<FolderNode> implements OnInit, OnDestroy {
  private readonly cachedSharedTagApiService: CachedFolderSharedTagApiService = inject(CachedFolderSharedTagApiService);
  private readonly folderHelper: FolderHelper = inject(FolderHelper);
  private readonly folderDialogService: FolderDialogService = inject(FolderDialogService);
  private readonly conversationActionService: ConversationActionService = inject(ConversationActionService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly navigationBarService: NavigationBarService = inject(NavigationBarService);
  private readonly injector: Injector = inject(Injector);
  private readonly router: Router = inject(Router);
  private readonly trackingService: TrackingService = inject(TrackingService);

  protected readonly groupId$: ReplaySubject<string> = new ReplaySubject<string>(1);
  @Input() set groupId(groupId: string) {
    this.groupId$.next(groupId);
  }
  @Input() conversationIds: string[] = [];

  protected readonly folderSvg: NodeRequire = require('shared/assets/img/svg/folder.svg');
  protected readonly folderSearchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  protected visibleDataLength: number = 0;

  private readonly folders$: Observable<SharedTagFolderModel[]> = this.groupId$.pipe(
    switchMap(groupId =>
      this.cachedSharedTagApiService.getFoldersForGroup$(groupId, this.userManagerService.getCurrentUserEmail())
    ),
    map(folders => folders.filter(folder => !!folder.actionEnum)),
    publishReplay(1),
    refCount()
  );

  protected readonly filteredFolders$: Observable<FolderNode[]> = combineLatest([
    this.folderSearchQuery$,
    this.folders$
  ]).pipe(
    map(([searchQuery, folders]) => {
      const filteredTags = _.sortBy(this.transform(folders, searchQuery), 'name');
      return this.folderHelper.createFolderStructure(filteredTags, []);
    }),
    publishReplay(1),
    refCount()
  );

  ngOnInit(): void {
    this.filteredFolders$.pipe(untilDestroyed(this)).subscribe(folders => {
      this.visibleDataLength = folders.length;
      this.currentItems = folders;
    });
  }

  protected currentItemClick(): void {
    this.folderClicked(this.currentItems[this.currentIndex]);
  }

  folderClicked(folder: FolderNode): void {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'AddToFolderDropdown',
      'Add to folder',
      {
        conversationIds: this.conversationIds,
        folder: folder?.folder?.id ?? folder.actionEnum
      }
    );

    moveToNextCard(this.conversationIds[0], this.injector);
    this.conversationActionService
      .conversationAction(this.userManagerService.getCurrentUserEmail(), {
        action: folder.actionEnum ?? ActionEnum.MOVE_TO_FOLDER,
        conversationIds: this.conversationIds,
        folder: folder.actionEnum !== ActionEnum.MOVE_TO_FOLDER ? undefined : folder.folder,
        context: this.navigationBarService.getContext(),
        isSharedAction: !this.router.url.includes('myloopinbox')
      })
      .pipe(take(1))
      .subscribe(() => {
        this.closeDropdown.next();
      });
  }

  createFolder(groupId: string): void {
    this.closeDropdown.next();
    this.folders$.pipe(take(1), untilDestroyed(this)).subscribe(folders => {
      this.folderDialogService.toggleDialog$.next({
        title: FolderTitleEnum.CREATE,
        folders: this.folderHelper.createFolderStructure(folders, []),
        groupId: groupId
      });
    });
  }

  private transform(boards: SharedTagFolderModel[], query: string): SharedTagFolderModel[] {
    if (!boards) {
      return [];
    }
    if (!query) {
      return boards;
    }

    query = query.toLowerCase();
    return boards.filter((board: SharedTagFolderModel) => {
      return this.getDisplayName(board).toLowerCase().includes(query);
    });
  }

  private getDisplayName(board: Tag): string {
    let splitBoardNames = board.name.split('/');
    if (!splitBoardNames.length || board['_indentationLevel'] === 0) {
      return board.name;
    }
    return _.last(splitBoardNames);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.groupId$.complete();
    this.folderSearchQuery$.complete();
  }
}
