import * as _ from 'lodash';
import { combineLatest, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CardBase, CardType, GroupType } from '@shared/api/api-loop/models';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { KeyboardShortcutsListenerService } from 'dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { ContactModel, GroupModel, UserModel } from 'dta/shared/models-api-loop/contact/contact.model';
import {
  KeyboardActionCategory,
  KeyboardActionType,
  KeyboardShortcutData,
  NavigateKeyboardAction
} from 'dta/shared/models/keyboard-shortcut.model';
import { ContactService } from '../data/contact/contact.service';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { CardApiService } from '@shared/api/api-loop/services/card-api.service';
import { CardBaseModel, CardChatModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { createChatCardId } from '@shared/modules/conversations/common/helpers/create-chat-card-id';
import { isGroupModel } from '@shared/modules/contacts/common/helpers/is-group-contact';

export interface RedirectServiceI {
  // Navigate to app's default view (root)
  navigateToDefaultView(): void;

  // Will navigate to emails tab (emails or inbox, based on user settings)
  navigateToInbox(extraPath: string): void;

  // Use this method for channel redirects. You can specify which panel and tab to open
  // If you don't provide panel and showChat parameters, channel will open via default logic.
  // You can provide cardId, to open a specified card within a channel
  quickJump(contact: UserModel | GroupModel, panel?: string, showChat?: boolean, cardId?: string): void;
}

@AutoUnsubscribe()
@Injectable()
export class RedirectService implements OnDestroy, RedirectServiceI {
  //////////////////
  // Subscriptions
  //////////////////
  private quickJumpSub: Subscription;
  private keyboardShortcutsSub: Subscription;

  constructor(
    private _router: Router,
    private _contactService: ContactService,
    private _cardApiService: CardApiService,
    private _userManagerService: UserManagerService,
    private _keyboardShortcutListenerService: KeyboardShortcutsListenerService
  ) {
    this.registerKeyboardNavigation();
  }

  ngOnDestroy(): void {
    this.unregisterKeyboardNavigation();
  }

  navigateToDefaultView() {
    this._userManagerService.isCurrentUserInOneTrialWorkspaceAndAdmin()
      ? this._router.navigate(['/getting-started'])
      : this._router.navigate(['/']);
  }

  navigateToInbox(extraPath: string = '') {
    let emailPath = '/account/inbox';

    this._router.navigate([emailPath + extraPath]);
  }

  navigateTo(path: string = '') {
    this._router.navigate([path]);
  }

  quickJump(contact: UserModel | GroupModel, panel?: string, showChat?: boolean, cardId?: string) {
    let path;
    let obs;

    if (!contact.id) {
      obs = this._contactService.findContactByEmail(this._userManagerService.getCurrentUserEmail(), contact.email);
    } else {
      obs = this._contactService.findContactById(this._userManagerService.getCurrentUserEmail(), contact.id);
    }

    this.quickJumpSub?.unsubscribe();
    this.quickJumpSub = obs
      .pipe(
        filter((_contact: ContactModel) => {
          return _contact && !_contact._ex.isNotAccessible;
        }),
        switchMap((_contact: ContactModel) => {
          return combineLatest([
            this._contactService.setUnreadCountForContact(this._userManagerService.getCurrentUserEmail(), _contact),
            (_contact.$type === UserModel.type && showChat
              ? this._cardApiService.Card_Get(
                  {
                    id: createChatCardId(this._userManagerService.getCurrentUserId(), _contact.id)
                  },
                  this._userManagerService.getCurrentUserEmail(),
                  true
                )
              : of(undefined)
            ).pipe(
              catchError(() => {
                return this._cardApiService.Card_CreateCard(
                  {
                    cardChat: CardChatModel.create({
                      $type: CardChatModel.type,
                      shareList: BaseModel.createListOfResources([this._userManagerService.getCurrentUser(), _contact]),
                      id: createChatCardId(this._userManagerService.getCurrentUserId(), _contact.id)
                    })
                  },
                  this._userManagerService.getCurrentUserEmail()
                );
              })
            )
          ]);
        }),
        tap(([_contact, card]) => {
          // Separate channel opening logic for shared inboxes and other channels
          if (
            _contact instanceof GroupModel &&
            (_contact.groupType === GroupType.SHARED_INBOX || _contact.groupType === GroupType.PERSONAL_INBOX)
          ) {
            path = this.getChannelPathForSharedInbox(<GroupModel>_contact, cardId, panel, showChat);
          } else {
            path = this.getChannelPath(_contact, card, showChat);
          }
          this._router.navigate(path);
        })
      )
      .subscribe();
  }

  private getChannelPath(contact: UserModel | GroupModel, card: CardBase, showChat: boolean): any[] {
    let contactId = contact.id || contact._id || contact.clientId;

    if (showChat) {
      return [`/channel/${contactId}/${card?.id ?? contactId}`];
    }

    return [
      '/channel/' + contactId,
      { fragment: 'redirected' },
      {
        outlets: {
          primary: 'empty',
          panel: 'threads'
        }
      }
    ];
  }

  private getChannelPathForSharedInbox(contact: GroupModel, cardId: string, panel?: string, showChat?: boolean): any[] {
    let primaryPath, panelPath;
    let contactId = contact._id || contact.clientId || contact.id;

    if (!panel && !showChat) {
      primaryPath = 'empty';
      panelPath = 'threads';
    } else if (panel) {
      primaryPath = panel === 'chat' || showChat ? 'chat' : 'empty';
      panelPath = panel === 'chat' || showChat ? undefined : panel;
    }

    return [
      '/channel/' + contactId,
      { fragment: 'redirected' },
      {
        outlets: {
          primary: cardId ? ['thread', cardId] : [primaryPath],
          panel: panelPath === undefined ? null : panelPath
        }
      }
    ];
  }

  private registerKeyboardNavigation() {
    let jumpToActions = this._keyboardShortcutListenerService.getAvailableShortcutsByCategories([
      KeyboardActionCategory.JUMP_TO
    ]);

    let handledKeyboardActions = [
      ..._.map(jumpToActions, shortcut => shortcut.action.type),
      KeyboardActionType.CREATE_CANNED_RESPONSE,
      KeyboardActionType.CREATE_SIGNATURE,
      KeyboardActionType.JUMP_TO_CHANNEL,
      KeyboardActionType.CHAT_WITH_LOOPBOT
    ];

    this.keyboardShortcutsSub?.unsubscribe();
    this.keyboardShortcutsSub = this._keyboardShortcutListenerService
      .getActionsByTypes(handledKeyboardActions)
      .pipe(
        tap((action: KeyboardShortcutData) => {
          let _keyAction = <NavigateKeyboardAction>action.action;

          if (_keyAction.contact) {
            this._router.navigate(['/channel/' + _keyAction.contact._id]);
            this.quickJump(
              _keyAction.contact,
              undefined,
              isGroupModel(_keyAction.contact) ? _keyAction.contact.groupType === GroupType.NORMAL : true
            );
          } else {
            this._router.navigate(_keyAction.navigation.commands, _keyAction.navigation.extras);
          }
        })
      )
      .subscribe();
  }

  private unregisterKeyboardNavigation() {
    this.keyboardShortcutsSub?.unsubscribe();
  }
}
