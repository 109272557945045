<div *ngIf="isVisible" class="alert">
  <div class="flex justify-between padding-half flex-1">
    <div class="flex items-center gap flex-1">
      <loop-icon *ngIf="icon$ | async; let icon" [name]="icon" class="icon" width="18px"></loop-icon>
      <div class="text flex-1">
        <div *ngIf="heading" class="heading">{{ heading }}</div>

        <div *ngIf="content" class="contents hide-empty alert-text-content">
          <ng-container *loopStringTemplateOutlet="content">
            {{ content }}
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isDismissible">
      <div>
        <loop-icon (click)="closeAlert()" class="close-button" name="xmark" width="8px"></loop-icon>
      </div>
    </ng-container>
  </div>
</div>
