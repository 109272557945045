import { v1 } from 'uuid';
import { BaseModel } from './base/base.model';

export class RetryModel {
  static type: string = 'RetryObject';

  readonly $type: string = RetryModel.type;

  _id: string;
  id: string;
  created: string;

  // Retry properties
  retryCount: number;
  retryAfter: string;
  retryType: RetryType;
  retryPriority: RetryPriority;

  // Data to sync
  data: BaseModel;

  static createRetryObject(
    data: BaseModel,
    retryType: RetryType,
    retryPriority: RetryPriority = RetryPriority.NORMAL, // default priority
  ): RetryModel {
    let id = 'retry_entry_' + v1();
    let retryObject: RetryModel = {
      _id: id,
      id: id, // For online model DB consistency
      $type: RetryModel.type,
      created: new Date().toISOString(),
      retryCount: 0,
      retryAfter: new Date().toISOString(),
      retryPriority: retryPriority,
      retryType: retryType,

      data: data,
    };

    return retryObject;
  }
}

export enum RetryType {
  GET_COMMENT_503 = 'GET_COMMENT_503',
  GET_COMMENT_HTML_BODY = 'GET_COMMENT_HTML_BODY',
  GET_FILE = 'GET_FILE',
  GET_FILE_PREVIEW = 'GET_FILE_PREVIEW',
}

export enum RetryPriority {
  HIGH, // Must have
  NORMAL, // Should have
  LOW, // Could have
}
