import { Injectable } from '@angular/core';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CollectionNameWeb } from '../../database-schema';
import { from, Observable } from 'rxjs';
import { ModelMappers } from '@dta/shared/models-api-loop/model-mappers/model-mappers';
import { defaultIfEmpty, map, mergeMap, toArray } from 'rxjs/operators';
import { DatabaseServiceWeb } from '../../database.service.web';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { UserAvailabilityStatusDaoServiceI } from '@shared/database/dao/user-availability/user-availability-dao.service';
import * as _ from 'lodash';

@Injectable()
export class UserAvailabilityStatusDaoServiceWeb
  extends BaseDaoServiceWeb<BaseModel, ResourceBase>
  implements UserAvailabilityStatusDaoServiceI
{
  constructor(protected _databaseFactory: DatabaseFactory) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'UserAvailabilityDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.UserAvailabilityStatus;
  }

  protected toModel(doc: ResourceBase): BaseModel {
    return ModelMappers.castToEndModel(doc);
  }

  findAllUserAvailabilityStatuses(forUserEmail: string): Observable<UserAvailabilityStatusModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.getAll(this.collectionName);
      }),
      mergeMap(docs => from(this.toModels(docs))),
      toArray(),
      defaultIfEmpty([]),
    ) as Observable<UserAvailabilityStatusModel[]>;
  }

  findByUserId(forUserEmail: string, userId: string): Observable<UserAvailabilityStatusModel> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findByIds([userId], this.collectionName);
      }),
      map(docs => this.toModels(docs)),
      mergeMap(docs => this.populate(forUserEmail, docs)),
      map(_.first),
    ) as Observable<UserAvailabilityStatusModel>;
  }
}
