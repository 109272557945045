/* tslint:disable */

/**
 * Possible Clear After enums
 */
export type AvailabilityClearAfterEnum =
  | 'None'
  | 'Minutes_30'
  | 'Hours_1'
  | 'Hours_2'
  | 'Hours_4'
  | 'Hours_8'
  | 'Hours_12'
  | 'Days_1'
  | 'Days_2'
  | 'Days_3'
  | 'Days_7'
  | 'Days_14'
  | 'Today'
  | 'Tomorrow'
  | 'ThisWeek'
  | 'NextWeek'
  | 'NextBusinessDay';

export module AvailabilityClearAfterEnum {
  export const NONE: AvailabilityClearAfterEnum = 'None';
  export const MINUTES__30: AvailabilityClearAfterEnum = 'Minutes_30';
  export const HOURS__1: AvailabilityClearAfterEnum = 'Hours_1';
  export const HOURS__2: AvailabilityClearAfterEnum = 'Hours_2';
  export const HOURS__4: AvailabilityClearAfterEnum = 'Hours_4';
  export const HOURS__8: AvailabilityClearAfterEnum = 'Hours_8';
  export const HOURS__12: AvailabilityClearAfterEnum = 'Hours_12';
  export const DAYS__1: AvailabilityClearAfterEnum = 'Days_1';
  export const DAYS__2: AvailabilityClearAfterEnum = 'Days_2';
  export const DAYS__3: AvailabilityClearAfterEnum = 'Days_3';
  export const DAYS__7: AvailabilityClearAfterEnum = 'Days_7';
  export const DAYS__14: AvailabilityClearAfterEnum = 'Days_14';
  export const TODAY: AvailabilityClearAfterEnum = 'Today';
  export const TOMORROW: AvailabilityClearAfterEnum = 'Tomorrow';
  export const THIS_WEEK: AvailabilityClearAfterEnum = 'ThisWeek';
  export const NEXT_WEEK: AvailabilityClearAfterEnum = 'NextWeek';
  export const NEXT_BUSINESS_DAY: AvailabilityClearAfterEnum = 'NextBusinessDay';

  export function values(): AvailabilityClearAfterEnum[] {
    return [
      NONE,
      MINUTES__30,
      HOURS__1,
      HOURS__2,
      HOURS__4,
      HOURS__8,
      HOURS__12,
      DAYS__1,
      DAYS__2,
      DAYS__3,
      DAYS__7,
      DAYS__14,
      TODAY,
      TOMORROW,
      THIS_WEEK,
      NEXT_WEEK,
      NEXT_BUSINESS_DAY
    ];
  }
}
