import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { BrowserDialogContentDirective } from './internal/browser-dialog-content.directive';
import { BrowserDialogContentComponent } from './internal/browser-dialog-content.component';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { ButtonModule } from '@shared/ui/button/button.module';

@NgModule({
  declarations: [BrowserDialogContentDirective, BrowserDialogContentComponent],
  imports: [CommonModule, DialogModule, IconModule, ButtonModule],
  exports: [BrowserDialogContentDirective]
})
export class BrowserDialogModule {}
