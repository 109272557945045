import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { LogEntry } from '@shared/api/log-loop/models/log-entry';
import { LogService } from '@shared/api/log-loop/api.module';
import { LogSynchronizationService } from '@shared/synchronization/log-synchronization/log-synchronization.service';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { WatchdogService } from '@shared/services/watchdog/watchdog.service';

export class LogSynchronizationServiceWeb extends LogSynchronizationService {
  protected syncPeriodSeconds: number = 4;

  constructor(
    protected _watchdogService: WatchdogService,
    protected _logService: LogService,
    private _storageService: StorageService,
  ) {
    super(_watchdogService, _logService);
  }

  get constructorName(): string {
    return 'LogSynchronizationServiceWeb';
  }

  get inflightKey(): string {
    return StorageKey.logBundle + '_inflight';
  }

  protected getNextLogBundle(): Observable<{ logBundle: LogEntry[]; inflightLogPaths: string[] }> {
    // Get logs
    let logBundle = this._storageService.getParsedItem(StorageKey.logBundle);

    if (_.isEmpty(logBundle)) {
      return of(undefined);
    }

    // Persist them marked as inflight
    this._storageService.setStringifiedItem(this.inflightKey, logBundle);

    // Remove array in favour of inflight copy
    this._storageService.removeItem(StorageKey.logBundle);

    logBundle = _.map(logBundle, log => JSON.parse(log) as LogEntry);

    return of({ logBundle, inflightLogPaths: [this.inflightKey] });
  }

  protected removeInflightLogBundle(inflightLogPaths: string[]): Observable<any> {
    if (_.isEmpty(inflightLogPaths)) {
      // Get inflight logs
      let logBundleInflight = this._storageService.getParsedItem(this.inflightKey);

      if (_.isEmpty(logBundleInflight)) {
        return of(undefined);
      }

      // Persist joined logs
      let logBundle = this._storageService.getParsedItem(StorageKey.logBundle) || [];
      this._storageService.setStringifiedItem(StorageKey.logBundle, [...logBundle, ...logBundleInflight]);
    }

    // Remove inflight array
    this._storageService.removeItem(this.inflightKey);
    return of(undefined);
  }
}
