import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { DIALOG_CONTENT, DialogContent } from '@shared/ui/kit/dialog/modal-content-element-ref';
import { FileDownloadService } from '@shared/modules/files/shell/services/file-download.service';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'loop-files-preview-dialog-content',
  templateUrl: './files-preview-dialog-content.component.html',
  styleUrls: ['./files-preview-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesPreviewDialogContentComponent {
  private readonly fileDownloadService: FileDownloadService = inject(FileDownloadService);
  private readonly dialogContent: DialogContent = inject(DIALOG_CONTENT);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Input() files: IFile[] = [];
  @Input() currentFileIndex: number = 0;

  protected close(): void {
    this.dialogContent?.close();
  }

  protected downloadFile(file: IFile): void {
    this.fileDownloadService.downloadFromLink(file.urlLink).pipe(take(1), untilDestroyed(this));
  }

  protected previousFile(): void {
    this.currentFileIndex -= 1;
    this.changeDetectorRef.detectChanges();
  }

  protected nextFile(): void {
    this.currentFileIndex += 1;
    this.changeDetectorRef.detectChanges();
  }
}
