import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { BasePushSynchronizationService } from '../base-push-synchronization/base-push-synchronization.service';
import { ListOfTagsModel } from '@dta/shared/models-api-loop/tag.model';
import { catchError, defaultIfEmpty, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { CardType, ListOfTags } from '@shared/api/api-loop/models';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '@shared/api/api-loop/api.module';
import { LogLevel } from '@dta/shared/models/logger.model';
import { Encryption } from '@dta/shared/utils/common-utils';
import { Logger } from '@shared/services/logger/logger';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { CardBaseModel, CardSharedModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CommentChatModel, CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { StateUpdates } from '@dta/shared/models/state-updates';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Injectable()
export class SharedTagPushSynchronizationService extends BasePushSynchronizationService<ListOfTagsModel> {
  constructor(
    protected _api: ApiService,
    protected _conversationService: ConversationService,
    protected _commentService: CommentService,
  ) {
    super();
  }

  get constructorName(): string {
    return 'SharedTagPushSynchronizationService';
  }

  protected synchronize(forUserEmail: string, listOfSharedTags: ListOfTagsModel[]): Observable<ListOfTagsModel[]> {
    return this.updateListOfTagsWithLatestRevision(forUserEmail, listOfSharedTags).pipe(
      mergeMap((updatedListOfSharedTags: ListOfTagsModel[]) =>
        this._api.SharedTagApiService.SharedTag_UpdateTags({ tags: updatedListOfSharedTags }, forUserEmail),
      ),
      catchError(err => this.handleSharedTagsUpdateError(forUserEmail, listOfSharedTags, err)),
    );
  }

  protected afterSynchronize(forUserEmail: string, tags: ListOfTagsModel[]): Observable<any> {
    let sharedTagsByParentType = _.groupBy(tags, 'parent.$type');

    return this.updateCardSharedTags(forUserEmail, sharedTagsByParentType[CardSharedModel.type]).pipe(
      mergeMap(() => {
        return this.updateCommentSharedTags(forUserEmail, sharedTagsByParentType[CommentChatModel.type]);
      }),
    );
  }

  private updateCardSharedTags(forUserEmail: string, tags: ListOfTags[]) {
    let watch = new StopWatch(this.constructorName + '.updateSharedTags', ProcessType.SERVICE, forUserEmail);

    watch.log('updateCardsSharedTags');
    return this._conversationService.updateCardsSharedTags(forUserEmail, tags).pipe(
      mergeMap((conversations: ConversationModel[]) => {
        watch.log('saveAllCards');
        return this._conversationService.saveAllAndPublish(forUserEmail, conversations);
      }),
      tap(() => {
        watch.log('done');
      }),
    );
  }

  private updateCommentSharedTags(forUserEmail: string, tags: ListOfTags[]) {
    let watch = new StopWatch(this.constructorName + '.updateSharedTags', ProcessType.SERVICE, forUserEmail);

    watch.log('updateCommentSharedTags');
    return this._commentService.updateCommentsSharedTags(forUserEmail, tags).pipe(
      mergeMap((comments: CommentModel[]) => {
        return this.updateLoopCardsForComments(forUserEmail, comments);
      }),
      tap(() => {
        watch.log('done');
      }),
    );
  }

  private updateLoopCardsForComments(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    return from(comments).pipe(
      filter((comment: CommentModel) => {
        return comment instanceof CommentChatModel && comment.parent.$type === CardSharedModel.type;
      }),
      map((comment: CommentChatModel) => {
        let card = CardBaseModel.create(comment.parent);
        return card._id;
      }),
      toArray(),
      mergeMap((loopIds: string[]) => {
        return this._conversationService.findByIds(forUserEmail, loopIds);
      }),
      tap((conversations: ConversationModel[]) => {
        let stateUpdates = new StateUpdates();
        stateUpdates.add(conversations);
        PublisherService.publishStateUpdates(forUserEmail, stateUpdates);
      }),
      map(() => {
        return comments;
      }),
      defaultIfEmpty(comments),
    );
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    tags: ListOfTagsModel[],
  ): Observable<any> {
    if ([0, 503, 504].includes(err.status)) {
      // we lost connection and will retry
      return EMPTY;
    } else if (err.status === 401) {
      // unauthorized - token should be refreshed and we'll retry
      return throwError(err);
    }

    // we will not be able to sync those tags as we will always get the same error.
    // We remove them from the queue.
    return of(tags);
  }

  private handleSharedTagsUpdateError(
    forUserEmail: string,
    listOfSharedTags: ListOfTagsModel[],
    err: HttpErrorResponse,
  ): Observable<any> {
    if ([409, 403].includes(err.status)) {
      Logger.customLog(
        `ShareTagDebugTrace: got ${err.status} for shareTag update. Will fetch parent and persist`,
        LogLevel.INFO,
      );

      // Indicates we have wrong state locally. We should fetch and persist latest version
      return this.updateSharedTagsToLatestBackendVersion(forUserEmail, listOfSharedTags);
    }

    return throwError(err);
  }

  private updateSharedTagsToLatestBackendVersion(forUserEmail: string, listOfTags: ListOfTagsModel[]): Observable<any> {
    if (_.isEmpty(listOfTags)) {
      return of([]);
    }

    return from(listOfTags).pipe(
      mergeMap((tags: ListOfTagsModel) => {
        Logger.customLog(
          `ShareTagDebugTrace: will try to handle error with fetch of model with id: ` +
            `${tags?.parent?.id}, listOfTags: ${Encryption.encrypt(JSON.stringify(listOfTags))}`,
          LogLevel.INFO,
        );

        if ((CardType.values() as string[]).includes(tags.parent?.$type)) {
          return this._conversationService.fetchConversationsByCardIds(forUserEmail, [tags.parent.id]);
        } else if (tags.parent?.$type?.toLocaleLowerCase().includes('comment')) {
          return this._commentService.fetchAndSaveComment(forUserEmail, tags.parent.id);
        }

        return EMPTY;
      }, 5),
      toArray(),
      defaultIfEmpty([]),
    );
  }

  private updateListOfTagsWithLatestRevision(
    forUserEmail: string,
    listOfSharedTags: ListOfTagsModel[],
  ): Observable<ListOfTagsModel[]> {
    if (_.isEmpty(listOfSharedTags)) {
      return of([]);
    }

    let parentCardIds = _.map(
      listOfSharedTags,
      (tag: ListOfTagsModel) => tag.parent?.$type.includes('Card') && tag.parent?.id,
    );
    let parentCommentIds = _.map(
      listOfSharedTags,
      (tag: ListOfTagsModel) => tag.parent?.$type.includes('Comment') && tag.parent?.id,
    );

    return forkJoin([
      this._conversationService.findByIds(forUserEmail, _.compact(parentCardIds)),
      this._commentService.findByIds(forUserEmail, _.compact(parentCommentIds)),
    ]).pipe(
      map(([latestConversation, latestComment]: [ConversationModel[], CommentModel[]]) => {
        let cardsById = _.keyBy(latestConversation, 'id');
        let commentById = _.keyBy(latestComment, 'id');

        return _.map(listOfSharedTags, (sharedTags: ListOfTagsModel) => {
          let parentId = sharedTags.parent?.id;
          let parent = cardsById[parentId] || commentById[parentId];
          sharedTags.revision = parent?.sharedTags?.revision || sharedTags.revision;

          return sharedTags;
        });
      }),
    );
  }
}
