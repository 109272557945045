import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { HttpEventService } from './http-event.service';
import { CONSTANTS } from '@shared/models/constants/constants';

import { catchError, filter, tap, timeout } from 'rxjs/operators';
import { HttpResponseEventType } from '../../dta/shared/models/http-events.model';

@Injectable()
export class HttpEventApiInterceptor implements HttpInterceptor {
  private readonly eventApiUri: string = '/event/list';
  private readonly timeoutMs: number = 70 * 1000;

  constructor(private _httpResponseEventService: HttpEventService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isEventApiUrl(request.url)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      timeout(this.timeoutMs),
      catchError(err => {
        return this.handleError(err);
      }),
      /**
       * filter-out strange 'sent event' with response.type = 0
       */
      filter((response: HttpResponse<any>) => {
        return response && response.type > 0;
      }),
      tap(data => {
        this._httpResponseEventService.emit({
          type: HttpResponseEventType.success,
        });
      }),
    );
  }

  private isApiUrl(url: string): boolean {
    if (!url) {
      return false;
    }

    return url.startsWith(CONSTANTS.LOOP_API_ROOT_URI);
  }

  private isEventApiUrl(url: string): boolean {
    return this.isApiUrl(url) && url.endsWith(this.eventApiUri);
  }

  private handleError(err): Observable<any> {
    // timeout
    if (err instanceof TimeoutError) {
      this._httpResponseEventService.emit({
        type: HttpResponseEventType.timeout,
      });
    }

    // error
    if (err instanceof HttpErrorResponse && err.status && (err.status < 200 || err.status >= 300)) {
      this._httpResponseEventService.emit({
        type: HttpResponseEventType.error,
      });
    }

    return throwError(err);
  }
}
