import { Directive } from '@angular/core';
import { CardBase, CommentBase } from '@shared/api/api-loop/models';
import { CardService } from '@shared/services/data/card/card.service';
import {
  CardChatModel,
  CardMailModel,
  CardModel,
  CardSharedModel,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { AllSharedInboxesCollectionParams } from 'dta/shared/models/collection.model';
import { StateUpdates } from 'dta/shared/models/state-updates';
import { Observable, of } from 'rxjs';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';

@Directive()
export class CardServiceWeb extends CardService {
  get constructorName(): string {
    return 'CardServiceWeb';
  }

  protected findUnreadAndLastCommentsForChatCards(
    forUserEmail: string,
    cards: CardChatModel[],
    includeSnapshotComments?: boolean,
  ): Observable<CommentModel[]> {
    return this._commentService.findLastCommentsByCardsForFoldering(forUserEmail, cards) as Observable<CommentModel[]>;
  }

  protected findSyncedByIds(forUserEmail: string, comments: CommentBase[]): Observable<CardModel[]> {
    return this._cardDaoService.findSyncedByIds(forUserEmail, comments);
  }

  findCardSharedBySnapshotResource(forUserEmail: string, card: CardMailModel): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  findLoopsBySourceId(forUserEmail: string, id: string): Observable<CardModel[]> {
    return of([]);
  }
  findDecoratedCardById(forUserEmail: string, card: CardModel): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  findMissingCommentsForCard(forUserEmail: string, card: CardModel): Observable<CardModel> {
    return of(card);
  }
  findAllSharedInboxCards(forUserEmail: string, params: AllSharedInboxesCollectionParams): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findAppointments(forUserEmail: string, params: any): Observable<CardModel[]> {
    return of([]);
  }
  findCardBySourceResourceId(forUserEmail: string, id: string): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  republishCard(forUserEmail: string, cardId: string): Observable<CardModel> {
    throw new Error('Method not implemented.');
  }
  filterNewCards(forUserEmail: string, cards: CardBase[]): Observable<CardBase[]> {
    return of(cards);
  }
  findLocalChatCardsByShareList(forUserEmail: string, emailsFromChatCards: string[]): Observable<CardBase[]> {
    return of([]);
  }
  fetchMissingSourceCards(forUserEmail: string, sharedCards: CardSharedModel[]): Observable<StateUpdates> {
    return of(new StateUpdates());
  }
  decorateCardsExtraData(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    throw new Error('Method not implemented.');
  }
  findCardIdsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string,
  ): Observable<string[]> {
    throw new Error('Method not implemented.');
  }
  fetchOrCreateMeToMeChatCard(forUserEmail: string): Observable<CardChatModel> {
    return of(undefined);
  }
}
