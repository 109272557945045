import { Pipe, PipeTransform } from '@angular/core';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { SharedTagClassificationModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

@Pipe({
  name: 'getClassificationSharedTag'
})
export class GetClassificationSharedTagPipe implements PipeTransform {
  transform(conversation: ConversationModel): SharedTagClassificationModel | undefined {
    return conversation?.sharedTags?.tags?.resources?.find(
      tag => tag.$type === SharedTagClassificationModel.type
    ) as SharedTagClassificationModel;
  }
}
