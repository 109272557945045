export class FileHelper {
  static getFilesFolderName(): string {
    return 'files_v' + FILE_REV;
  }

  static getLogDirName(): string {
    return 'applogs';
  }

  static getPersistentFilesFolderName(): string {
    return 'persistent_storage';
  }

  static getDraftsDirName(): string {
    return 'drafts';
  }

  static getLocalFileProperties(file: File) {
    let properties = ['name', 'path', 'lastModified', 'lastModifiedDate', 'size', 'type'];
    let fileObject = {};

    properties.forEach(prop => {
      if (file[prop]) {
        fileObject[prop] = file[prop];
      }
    });

    return fileObject;
  }

  static createFileReader(obs): FileReader {
    let reader = new FileReader();

    reader.onerror = err => obs.error(err);
    reader.onabort = err => obs.error(err);
    reader.onload = () => obs.next(reader.result);
    reader.onloadend = () => obs.complete();

    return reader;
  }
}
