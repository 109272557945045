import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import {
  CommentBaseModel,
  CommentDraftModel,
  CommentMailModel,
  CommentModel,
} from '../../../../dta/shared/models-api-loop/comment/comment.model';
import { Tag } from '@shared/api/api-loop/models/tag';
import { TagType } from '@shared/api/api-loop/models/tag-type';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { ContactBaseModel, GroupModel } from '../../../../dta/shared/models-api-loop/contact/contact.model';
import { TagModel } from '../../../../dta/shared/models-api-loop/tag.model';
import { File, User } from '@shared/api/api-loop/models';
import { BaseModel } from '../../../../dta/shared/models-api-loop/base/base.model';
import { forkJoin, from, Observable, of } from 'rxjs';
import { filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { SharedUserManagerService } from '../../../../dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StaticSharedTagIds } from '../../../../dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '../../../../dta/shared/models/logger.model';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { BaseExDecorateService } from '../base-ex-decorate.service';
import {
  CardBaseModel,
  CardChatModel,
  CardDraftModel,
  CardExtraData,
  CardMailModel,
  CardModel,
  CardSharedModel,
  ChannelTagData,
  SubscriptionState,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';

@Injectable()
export class CardExDecorateService extends BaseExDecorateService<CardModel> {
  // Fields that are set outside this decorator and should be passed on
  private static passThroughFields = ['syncedVia'];

  constructor(
    protected _contactStoreFactory: ContactStoreFactory,
    protected _sharedUserManagerService: SharedUserManagerService,
  ) {
    super();
  }

  /////////////////
  // DTA methods:
  /////////////////
  protected findLiveLoopsBySourceResourceIds(forUserEmail: string, cards: CardModel[]): Observable<CardSharedModel[]> {
    return of([]);
  }

  protected decorateDraftChannelIds(
    forUserEmail: string,
    ex: CardExtraData,
    card: CardDraftModel,
  ): Observable<CardExtraData> {
    return of(ex);
  }

  ///////////////////
  // COMMON methods:
  ///////////////////
  decorateListExtraData(forUserEmail: string, cards: CardModel[], force?: boolean): Observable<CardModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    return this.findAllAccessibleGroupIds(forUserEmail).pipe(
      /**
       * Decorate base extra data
       */
      mergeMap((accessibleGroupIds: string[]) => {
        return super.decorateListExtraData(forUserEmail, cards, force, accessibleGroupIds);
      }),
      /**
       * Decorate live loop status
       */
      mergeMap((_cards: CardModel[]) => {
        return this.decorateLiveLoopStatus(forUserEmail, _cards);
      }),
    );
  }

  decorateExtraData(
    forUserEmail: string,
    card: CardModel,
    force: boolean,
    accessibleGroupIds: string[],
  ): Observable<CardModel> {
    // Get comments
    let comments = CommentBaseModel.createList(card.getSortedComments());

    return of(undefined).pipe(
      /**
       * Decorate base data
       */
      mergeMap(() => {
        return this.decorateBaseExtraData(forUserEmail, card, comments, accessibleGroupIds);
      }),
      /**
       * Decorate separate for chat/shared and email
       */
      mergeMap((ex: CardExtraData) => {
        if (card instanceof CardChatModel || card instanceof CardSharedModel) {
          return this.decorateChatExtraData(forUserEmail, ex, card, comments);
        } else if (card instanceof CardDraftModel) {
          return this.decorateDraftExtraData(forUserEmail, ex, card, card.commentDraft as CommentDraftModel);
        }

        return this.decorateMailExtraData(forUserEmail, ex, card, <CommentMailModel[]>comments);
      }),
      /**
       * Update extra data
       */
      map((ex: CardExtraData) => {
        card._ex = ex;
        return card;
      }),
    );
  }

  private decorateBaseExtraData(
    forUserEmail: string,
    card: CardModel,
    comments: CommentModel[],
    accessibleGroupIds: string[],
  ): Observable<CardExtraData> {
    /**
     * Defaults
     */
    let ex: CardExtraData = {
      senderIds: [],
      replyToIds: [],
      channelIds: [],
      tags: [],
      isUnread: false,
      isSourceUnread: false,
      isStarred: false,
      isDeleted: false,
      isSent: false,
      isSharedSent: false,
      isSharedDeleted: false,
      isArchived: false,
      isSharedArchived: false,
      isGroupCard: false,
      subscriptionState: SubscriptionState.UNSUBSCRIBED,
      isGroupUnaccessible: false,
      commentsSynchronized: false,
      lastSentCommentCreated: undefined,
      lastCommentCreated: undefined,
      sortDate: undefined,
      unreadCount: 0,
      sourceUnreadCount: 0,
      hasInaccessibleGroup: false,
      hasLiveLoop: false,
      hasLiveLoopInSameChannel: false,
      liveLoopChannelIds: [],
      lastCommentSnippet: undefined,
      lastSentCommentSnippet: undefined,
      shareList: [],
      countOfComments: 0,
      hasUnsentComments: false,
      hasPendingSnooze: false,
      snoozeDueDate: undefined,
      hasFailedToSendComments: false,
      hasDraft: false,
      visibleAttachmentCount: 0,
      isLastCommentChat: false,
      emailAuthorUsers: [],
      emailSharelistUsers: [],
      newDomainUserOnly: false,
      chatCardContactId: undefined,
      channelTag: undefined,
    };

    // We don't need comments to determine if card is in group context
    ex.isGroupCard = CardBaseModel.isGroupCard(card.getShareList());
    ex.isGroupUnaccessible = this.isGroupUnaccessible(card.getShareList(), accessibleGroupIds);

    // We don't need comments to determine subscription state
    ex.subscriptionState = CardBaseModel.calculateSubscriptionState(card);

    // Set snooze property
    ex.hasPendingSnooze = card.hasPendingSnooze();
    ex.snoozeDueDate = card.getSnoozeDueDate();

    if (card instanceof CardSharedModel) {
      ex.hasDraft = !!card.snapshotResource?.privateDraftResource || !!card.snapshotResource?.draftResource;
    }
    if (card instanceof CardMailModel) {
      ex.hasDraft = !!card.privateDraftResource || !!card.draftResource;
    }

    // Preserve passThroughFields
    ex = this.preservePassThroughFields(ex, card._ex);

    // When there are no comments:
    // for chat card return default extra data
    // Preserve old extra data or set to default for other CardModels
    if (_.isEmpty(CardBaseModel.filterCommentsForFoldering(comments))) {
      if (card instanceof CardChatModel) {
        ex.chatCardContactId = this.getContactIdForChatCard(forUserEmail, card);
        return of(ex);
      } else {
        return card._ex
          ? of({ ...ex, ...card._ex }) // Merge with default
          : of(ex);
      }
    }

    ex.countOfComments = card.comments ? card.comments.totalSize : 0;
    ex.commentsSynchronized = !_.isNil(card.comments?.totalSize) && comments.length >= card.comments.totalSize;

    ex.senderIds = this.getSenderIdsFromComments(comments);
    ex.replyToIds = this.getReplyToIdsFromComments(comments);
    ex.channelTag = this.constructChannelTag(forUserEmail, card);

    return of(ex);
  }

  private preservePassThroughFields(exNew: CardExtraData, exOld: CardExtraData) {
    if (!exOld) {
      return exNew;
    }
    _.forEach(CardExDecorateService.passThroughFields, field => {
      if (!_.isUndefined(exOld[field]) && !_.isNull(exOld[field])) {
        exNew[field] = exOld[field];
      }
    });

    return exNew;
  }

  private decorateDraftExtraData(
    forUserEmail: string,
    ex: CardExtraData,
    card: CardDraftModel,
    comment: CommentDraftModel,
  ): Observable<CardExtraData> {
    if (_.isEmpty(comment)) {
      return of(ex);
    }

    let _comment = CommentBaseModel.create(comment);

    ex.lastCommentSnippet = CommentBaseModel.buildSnippet(_comment);
    ex.channelTag = this.constructChannelTag(forUserEmail, card);
    ex.lastCommentCreated = _comment.created;
    ex.sortDate = this.getSortDate(card, ex.lastCommentCreated);
    ex.countOfComments = 1;
    ex.isSharedDeleted = ex.isDeleted = card.hasSharedTagId(StaticSharedTagIds.DELETED_ID);
    ex.channelIds = this.buildChannelIdsFromSendersAndShareList(forUserEmail, card, ex);
    ex.hasDraft = true;
    ex.isUnread = ex.unreadCount > 0;
    ex.subscriptionState = SubscriptionState.SUBSCRIBED;

    let emailSharelist = _.cloneDeep(_comment.getShareList());
    _.forEach(emailSharelist, contact => ContactBaseModel.toReducedForm(contact));
    ex.emailSharelistUsers = ex.shareList = _comment.getShareList();

    return of(ex);
  }

  private decorateMailExtraData(
    forUserEmail: string,
    ex: CardExtraData,
    card: CardModel,
    comments: CommentMailModel[],
  ): Observable<CardExtraData> {
    if (_.isEmpty(comments)) {
      return of(ex);
    }

    return this.updateExtraDataWithComments(forUserEmail, ex, comments, card).pipe(
      mergeMap((_ex: CardExtraData) => {
        return this.updateExtraDataWithCommentsTags(_ex, comments);
      }),
      tap((_ex: CardExtraData) => {
        _ex.channelIds = this.buildChannelIdsFromSendersAndShareList(forUserEmail, card, _ex);
      }),
    );
  }

  private decorateChatExtraData(
    forUserEmail: string,
    ex: CardExtraData,
    card: CardModel,
    comments: CommentModel[],
  ): Observable<CardExtraData> {
    if (_.isEmpty(CardBaseModel.filterCommentsForFoldering(comments))) {
      return of(ex);
    }

    let lastComment: CommentModel = _.last(comments);
    let lastCommentSnippet = CommentBaseModel.buildSnippet(lastComment);
    let lastFolderingComment: CommentModel = _.last(CardBaseModel.filterCommentsForFoldering(comments));
    let allComments = comments;

    ex.lastCommentCreated = lastComment.created;
    ex.sortDate = this.getSortDate(card, ex.lastCommentCreated);
    ex.lastCommentSnippet = lastCommentSnippet;

    ex.isDeleted = lastFolderingComment.hasTagId(TagType.DELETED);
    ex.isSharedDeleted = card.hasSharedTagId(StaticSharedTagIds.DELETED_ID);

    ex.isArchived = lastFolderingComment.hasTagId(TagType.ARCHIVE);
    ex.isSharedArchived = card.hasSharedTagId(StaticSharedTagIds.ARCHIVED_ID);

    ex.unreadCount = this.countUnreadComments(comments);
    ex.shareList = this.updateShareList(card, lastComment);
    ex.isLastCommentChat = true;
    ex.channelIds = this.buildChannelIdsFromSendersAndShareList(forUserEmail, card, ex);

    if (card instanceof CardSharedModel) {
      let extraComments = <CommentMailModel[]>CommentBaseModel.createList(card.getSortedExtraComments());
      allComments = _.concat(allComments, extraComments);

      if (card.hasExtraComments()) {
        this.updateExtraDataWithExtraComments(forUserEmail, ex, card, comments, extraComments);
      } else {
        ex.tags = this.getTagsFromLoopinComments(comments);
      }
    } else {
      ex.tags = this.getTagsFromChatComments(forUserEmail, comments);
      ex.newDomainUserOnly = this.shouldHideFromSmartInbox(forUserEmail, comments);
      ex.chatCardContactId = this.getContactIdForChatCard(forUserEmail, card);
    }

    ex.isUnread = ex.unreadCount > 0;
    ex.isStarred = ex.tags.some(tag => tag.tagType === TagType.STARRED);

    // TODO: consolidate this with .updateExtraDataWithComments
    _.some(allComments.reverse(), comment => {
      if (!comment.id) {
        ex.hasUnsentComments = true;
      }
      if (comment._ex && comment._ex.errorSending) {
        ex.hasFailedToSendComments = true;
      }

      return ex.hasUnsentComments && ex.hasFailedToSendComments;
    });

    return of(ex);
  }

  private updateExtraDataWithExtraComments(
    forUserEmail: string,
    ex: CardExtraData,
    card: CardSharedModel,
    comments: CommentModel[],
    extraComments: CommentMailModel[],
  ) {
    let lastComment: CommentModel = _.last(comments);
    let unreadCount = this.countUnreadComments(extraComments);

    // Combine all tags
    ex.tags = [...this.getTagsFromLoopinComments(comments), ...this.getTagsFromSnapshotComments(extraComments)];

    ex.visibleAttachmentCount = this.countVisibleAttachments(extraComments);
    ex.sourceUnreadCount = unreadCount;
    ex.isSourceUnread = unreadCount > 0;

    // Save only users id and $type and populate it later
    let emailAuthorNames = _.cloneDeep(this.getEmailAuthors(extraComments));
    _.forEach(emailAuthorNames, (contact: User) => ContactBaseModel.toReducedForm(contact));
    ex.emailAuthorUsers = emailAuthorNames;

    // Save only users id and $type and populate it later
    let emailSharelist = _.cloneDeep(_.last(extraComments).getShareList());
    _.forEach(emailSharelist, contact => ContactBaseModel.toReducedForm(contact));
    ex.emailSharelistUsers = emailSharelist;

    // If last snapshot comment is after last loop comment, we need to override sort date.
    let lastExtraComment = _.last(extraComments);
    if (moment(lastExtraComment.created).isAfter(moment(lastComment.created))) {
      let lastExtraCommentSnippet = CommentBaseModel.buildSnippet(lastExtraComment);
      ex.lastCommentCreated = lastExtraComment.created;
      ex.sortDate = this.getSortDate(card, ex.lastCommentCreated);
      ex.lastCommentSnippet = lastExtraCommentSnippet;
      ex.isLastCommentChat = false;
    }

    // Decorate sentProperties from SharedSent
    if (_.some(ex.tags, (tag: Tag) => tag.id === TagType.SENT)) {
      let lastSentComment = _.findLast(extraComments, comment => comment.hasTagId(TagType.SENT));
      ex.isSent = true;
      ex.lastSentCommentCreated = lastSentComment.created;
      ex.lastSentCommentSnippet = CommentBaseModel.buildSnippet(lastSentComment);
    }

    if (
      _.some(
        extraComments,
        (comment: CommentMailModel) =>
          comment.hasSharedTagId(StaticSharedTagIds.SENT_ID) || comment.hasTagId(TagType.SENT),
      )
    ) {
      let lastSentMailComment = _.findLast(
        extraComments,
        comment => comment.hasSharedTagId(StaticSharedTagIds.SENT_ID) || comment.hasTagId(TagType.SENT),
      );
      ex.isSharedSent = true;
      ex.lastSentCommentCreated = lastSentMailComment.created;
      ex.lastSentCommentSnippet = CommentBaseModel.buildSnippet(lastSentMailComment);
    }
  }

  private countUnreadComments(comments: CommentModel[]): number {
    return _.filter(comments, comment => comment.hasTagId(TagType.UNREAD) || comment.hasTagId(TagType.UNREAD_VIRTUAL))
      .length;
  }

  private countVisibleAttachments(comments: CommentModel[]): number {
    let attachments = _.reduce(
      comments,
      (total: File[], comment: CommentModel) => {
        if (_.isNull(comment.attachments.resources)) {
          return total;
        } else {
          return total.concat(comment.attachments.resources);
        }
      },
      [],
    );

    return _.reduce(
      attachments,
      (total: number, file: File) => {
        return total + (file.hidden ? 0 : 1);
      },
      0,
    );
  }

  private getTagsFromComments(comments: CommentModel[]): Tag[] {
    let tagsByComments = _.map(comments, comment => {
      return comment.getTags();
    });
    let tags: Tag[] = _.flatten(tagsByComments);
    return _.uniqBy(tags, 'id');
  }

  private getTagsFromChatComments(forUserEmail: string, comments: CommentModel[]): Tag[] {
    if (!comments || comments.length === 0) {
      return [];
    }

    let lastComment = _.last(CardBaseModel.filterCommentsForFoldering(comments));
    let hasUnreadTag: boolean = comments.some(
      comment =>
        comment.hasTagId(TagType.UNREAD) &&
        !(comment.hasTagId(TagType.NEWDOMAINUSER) && comment.author.email === forUserEmail),
    );

    let tags = lastComment.hasTagId(TagType.ARCHIVE)
      ? [TagModel.buildSystemTag(TagType.ARCHIVE)]
      : [TagModel.buildSystemTag(TagType.INBOX)];

    if (hasUnreadTag) {
      tags = [...tags, TagModel.buildSystemTag(TagType.UNREAD)];
    }

    return tags;
  }

  private getTagsFromLoopinComments(comments: CommentModel[]): Tag[] {
    // For loopins we have the same logic as chat. We only look at the last comment to determine
    // if loop is archived or deleted. For starred and unread we look at all the comments.
    let commentsWithFolderTags = CardBaseModel.filterCommentsForFoldering(comments);
    let lastComment = _.last(commentsWithFolderTags);

    let hasUnreadTag: boolean = comments.some(comment => comment.hasTagId(TagType.UNREAD));
    let hasStarredTag: boolean = comments.some(comment => comment.hasTagId(TagType.STARRED));
    let tags = [];

    if (lastComment.hasTagId(TagType.ARCHIVE)) {
      tags = [TagModel.buildSystemTag(TagType.ARCHIVE)];
    } else if (lastComment.hasTagId(TagType.DELETED)) {
      tags = [TagModel.buildSystemTag(TagType.DELETED)];
    } else if (lastComment.hasTagId(TagType.INBOX) || lastComment.hasTagId(TagType.SENT)) {
      tags = [TagModel.buildSystemTag(TagType.INBOX)];
    } else if (lastComment.getTags().length === 1 && lastComment.hasTagId(TagType.SYSTEMMESSAGE)) {
      // Workaround for BE bug of lacking INBOX tag. FE ticket #4525
      tags = [TagModel.buildSystemTag(TagType.INBOX)];
    } else {
      tags = [...lastComment.getTags()];
    }

    if (hasUnreadTag) {
      tags = [...tags, TagModel.buildSystemTag(TagType.UNREAD)];
    }
    if (hasStarredTag) {
      tags = [...tags, TagModel.buildSystemTag(TagType.STARRED)];
    }

    return tags;
  }

  private getTagsFromSnapshotComments(snapshotComments: CommentModel[]): Tag[] {
    // With loopins, we are only interested in unread and sent tags.
    let allTags = _.flatMap(snapshotComments, comment => comment.getTags());
    let hasSentTag = _.some(allTags, (tag: Tag) => tag.id === TagType.SENT);
    let hasUnread = _.some(allTags, (tag: Tag) => tag.id === TagType.UNREAD);
    return [
      ...(hasSentTag ? [TagModel.buildSystemTag(TagType.SENT)] : []),
      ...(hasUnread ? [TagModel.buildSystemTag(TagType.UNREAD)] : []),
    ];
  }

  private updateExtraDataWithComments(
    forUserEmail: string,
    ex: CardExtraData,
    comments: CommentMailModel[],
    card: CardModel,
  ): Observable<CardExtraData> {
    let lastComment = _.last(comments);
    ex.lastCommentCreated = lastComment.created;
    ex.sortDate = this.getSortDate(card, ex.lastCommentCreated);
    ex.lastCommentSnippet = CommentBaseModel.buildSnippet(lastComment);
    ex.shareList = this.updateShareList(card, lastComment);

    // Save only users id and $type and populate it later
    let emailSharelist = _.cloneDeep(lastComment.getShareList(true));
    _.forEach(emailSharelist, contact => ContactBaseModel.toReducedForm(contact));
    ex.emailSharelistUsers = emailSharelist;

    // Save only users id and $type and populate it later
    let emailAuthorNames = _.cloneDeep(this.getEmailAuthors(comments));

    _.forEach(emailAuthorNames, (contact: User) => {
      return ContactBaseModel.toReducedForm(contact);
    });
    ex.emailAuthorUsers = emailAuthorNames;

    return from(comments).pipe(
      tap((comment: CommentModel) => {
        this.updateExtraDataWithComment(forUserEmail, ex, comment);
      }),
      toArray(),
      map(() => {
        return ex;
      }),
    );
  }

  private updateExtraDataWithComment(forUserEmail: string, ex: CardExtraData, comment: CommentModel) {
    let createdMoment = moment(comment.created);

    // SNIPPET & SORT-DATE
    if (comment.hasTagId(TagType.SENT) || comment.author.email === forUserEmail) {
      // lastSentCommentCreated
      if (!ex.lastSentCommentCreated || createdMoment.isAfter(ex.lastSentCommentCreated)) {
        ex.lastSentCommentCreated = comment.created;
        ex.lastSentCommentSnippet = CommentBaseModel.buildSnippet(comment);

        // Save only users id and $type and populate it later
        let emailSharelist = _.cloneDeep(comment.getShareList(true));
        _.forEach(emailSharelist, contact => ContactBaseModel.toReducedForm(contact));
        ex.emailSharelistUsers = emailSharelist;
      }
    }

    // ATTACHMENTS
    if (comment.hasAttachments()) {
      ex.visibleAttachmentCount += comment.attachments.resources.filter(
        (attachment: File) => !attachment.hidden,
      ).length;
    }

    // DRAFT & UNSENT
    if (!comment.id) {
      ex.hasUnsentComments = true;
    }

    if (comment._ex && comment._ex.errorSending) {
      ex.hasFailedToSendComments = true;
    }
  }

  private updateExtraDataWithCommentsTags(ex: CardExtraData, comments: CommentModel[]): Observable<CardExtraData> {
    let tags: Tag[] = this.getTagsFromComments(comments);
    if (_.isEmpty(tags)) {
      return of(ex);
    }

    ex.tags = tags;
    ex.unreadCount = this.countUnreadComments(comments);
    ex.isUnread = ex.unreadCount > 0;
    ex.isDeleted = this.everyCommentHasTagId(comments, TagType.DELETED);
    ex.isArchived = this.everyCommentHasTagId(comments, TagType.ARCHIVE);
    ex.isStarred = this.someCommentHasTagId(comments, TagType.STARRED);

    return of(ex);
  }

  private getReplyToIdsFromComments(comments: CommentModel[]): string[] {
    if (_.isEmpty(comments)) {
      return [];
    }

    let allReplyTos = _.flatMap(comments, (comment: CommentModel) => {
      if (comment.$type === CommentMailModel.type) {
        return (<CommentMailModel>comment).getReplyTo();
      }

      return [];
    });

    let replyToIds = _.map(allReplyTos, (contact: ContactBase) => contact.id);

    return _.uniq(replyToIds);
  }

  private getSenderIdsFromComments(comments: CommentModel[]): string[] {
    let senderIds = _.map(comments, (comment: CommentModel) => comment.author.id);
    return _.uniq(senderIds);
  }

  private everyCommentHasTagId(comments: CommentModel[], tagId: TagType): boolean {
    return _.every(comments, (comment: CommentModel) => {
      return comment.hasTagId(tagId);
    });
  }

  private someCommentHasTagId(comments: CommentModel[], tagId: TagType): boolean {
    return _.some(comments, (comment: CommentModel) => {
      return comment.hasTagId(tagId);
    });
  }

  private updateShareList(card: CardModel, comment: CommentModel): ContactBase[] {
    let cardShareList = card.getShareList();
    let commentShareList = comment.getShareList(true);
    let updatedShareList = _.unionBy(cardShareList, commentShareList, 'id');

    // needs to be updated for offline-first experience
    card.shareList = BaseModel.createListOfResources(updatedShareList);

    // if card shareList contains group then return
    if (CardBaseModel.findGroupInShareList(cardShareList)) {
      return cardShareList;
    }

    // if comment shareList contains group then return only the group
    let group = CardBaseModel.findGroupInShareList(commentShareList);
    if (group) {
      return [group];
    }

    return updatedShareList;
  }

  /**
   * True if group in unaccessible or not on share list
   */
  private isGroupUnaccessible(shareList: ContactBase[], accessibleGroupIds: string[]): boolean {
    if (_.isEmpty(shareList)) {
      return true;
    }

    if (_.isEmpty(accessibleGroupIds)) {
      accessibleGroupIds = [];
    }

    let group = CardBaseModel.findGroupInShareList(shareList);
    return _.isEmpty(group) || !accessibleGroupIds.includes(group.id);
  }

  /**
   * For each CardMail that has copiedCardIds set,
   * we need to check if we currently have any live loops associated with it.
   */
  private decorateLiveLoopStatus(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return forkJoin([this.filterLiveLoops(cards), this.findLiveLoopsBySourceResourceIds(forUserEmail, cards)]).pipe(
      map((_cards: CardSharedModel[][]) => {
        let liveLoops = _cards[0];
        let dbLiveLoops = _cards[1];
        return _.unionBy(liveLoops, dbLiveLoops, '_id');
      }),
      mergeMap((liveLoops: CardSharedModel[]) => {
        return this.decorateCardsWithLiveLoops(cards, liveLoops);
      }),
    );
  }

  decorateCardsWithLiveLoops(cards: CardModel[], liveLoopCards: CardSharedModel[]): Observable<CardModel[]> {
    let liveLoopsBySourceId = _.groupBy(liveLoopCards, card => {
      let sourceResource = <CardMailModel>card.sourceResource;
      return sourceResource._id || sourceResource.id;
    });

    return from(cards).pipe(
      map((card: CardModel) => {
        let liveLoops = liveLoopsBySourceId[card._id] || liveLoopsBySourceId[card.id];

        card._ex.hasLiveLoop = !_.isEmpty(liveLoops);
        card._ex.hasLiveLoopInSameChannel = this.hasLiveLoopInSameChannel(liveLoops, card._ex.channelIds);
        card._ex.liveLoopChannelIds = _.flatten(_.map(liveLoops, _card => _card._ex.channelIds));

        return card;
      }),
      toArray(),
    );
  }

  private hasLiveLoopInSameChannel(liveLoopCards: CardSharedModel[], channelIds: string[]): boolean {
    return _.some(liveLoopCards, (loopCards: CardSharedModel) => {
      return !_.isEmpty(_.intersection(loopCards._ex.channelIds, channelIds));
    });
  }

  private filterLiveLoops(cards: CardModel[]): Observable<CardSharedModel[]> {
    return from(cards).pipe(
      filter((card: CardModel) => {
        return card instanceof CardSharedModel && card.sourceResource && card.isLive;
      }),
      map((card: CardModel) => {
        return <CardSharedModel>card;
      }),
      toArray(),
    );
  }

  private getEmailAuthors(comments: CommentModel[]): ContactBase[] {
    let authors = _.map(comments, (comment: CommentModel) => comment.author);

    // Reverse because unique must take the latest occurrence
    authors = authors.reverse();

    return _.uniqBy(authors, 'id');
  }

  protected buildChannelIdsFromSendersAndShareList(forUserEmail: string, card: CardModel, ex: CardExtraData): string[] {
    // Not shown in any channel
    if ((<CardMailModel>card).isSnapshot) {
      return [];
    }

    let currentUserId = this._sharedUserManagerService.getUserIdByEmail(forUserEmail);
    let shareList = card.getShareList();
    let senderIds = ex.senderIds || [];
    let replyToIds = ex.replyToIds || [];
    let channelIds = [];

    // Group is recipient and show group cards only in group channels
    let group = CardBaseModel.findGroupInShareList(shareList);
    if (group) {
      channelIds.push(group.id);
    } else {
      // Card belong to every sender channel
      channelIds.push(...senderIds);

      // Card also belong to every reply-to channel
      channelIds.push(...replyToIds);

      // I am sender and contact is recipient
      if (senderIds.includes(currentUserId)) {
        let shareListIds = _.map(shareList, contact => contact.id);
        channelIds.push(...shareListIds);
      }
    }

    return _.uniq(channelIds);
  }

  private shouldHideFromSmartInbox(forUserEmail: string, comments: CommentModel[]): boolean {
    if (comments.length > 1) {
      return false;
    }
    return (
      _.filter(comments, comment => {
        return comment.hasTagId(TagType.NEWDOMAINUSER) && comment.author.email === forUserEmail;
      }).length === 1
    );
  }

  private getContactIdForChatCard(forUserEmail: string, card: CardChatModel): string {
    let forUserId = this._sharedUserManagerService.getUserIdByEmail(forUserEmail);
    let sharelist = card.getShareList();

    if (sharelist.length === 1 && sharelist[0].id === forUserId) {
      return forUserId;
    }

    let filteredSharelist = _.filter(card.getShareList(), (contact: ContactBase) => {
      return contact.id !== forUserId;
    });

    if (filteredSharelist.length !== 1) {
      Logger.customLog(
        `Chat card has unexpected sharelist. Ids: ${card.getShareList().map(c => c.id)}`,
        LogLevel.INFO,
        LogTag.INTERESTING_ERROR,
      );
    }

    return filteredSharelist[0].id;
  }

  private constructChannelTag(forUserEmail: string, card: CardModel): ChannelTagData {
    let contact = card.getContactForChannelTag();

    if (!contact) {
      contact = this._sharedUserManagerService.getUserByEmail(forUserEmail);
    }

    let tagData = {
      displayName: contact.$type === GroupModel.type ? contact.name : contact.email,
      contactId: contact.id,
    } as ChannelTagData;

    return tagData;
  }

  ////////////////////
  // Private helpers
  ////////////////////
  private getSortDate(card: CardModel, lastCommentCreated: string): string {
    // Comments
    let possibleSortDates = [lastCommentCreated];

    // Snooze
    let snoozeDueDate = card.getSnoozeDueDate();
    if (snoozeDueDate) {
      possibleSortDates.push(snoozeDueDate);
    }

    // Draft modified
    let draftModifiedDate = card.getSharedDraftModifiedDate();
    if (draftModifiedDate) {
      possibleSortDates.push(draftModifiedDate);
    }

    let sortedDates = possibleSortDates.sort((aDate: string, bDate: string) =>
      aDate === bDate || Date.parse(aDate) < Date.parse(bDate) ? 1 : -1,
    );

    return sortedDates[0];
  }

  private findAllAccessibleGroupIds(forUserEmail: string): Observable<string[]> {
    // There is cyclic dependency that breaks app. So we inject
    let contactStore = this._contactStoreFactory.forUser(forUserEmail);
    return contactStore.findAllAccessibleGroupIds();
  }
}
