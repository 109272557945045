import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import { StorageKey, StorageService } from '../../../shared/services/storage/storage.service';
import { availableLoopThemes, ColorTheme, LoopColorTheme } from './themes';
import { ElectronService } from '@shared/services/electron/electron';
import { memoryLeakGuard } from '@dta/shared/utils/subscriptions/actionable-subscription';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThemesService implements OnDestroy {
  ///////////////////
  // State variables
  ///////////////////
  private colorThemesById: { [id: string]: LoopColorTheme } = {};
  private currentActiveTheme: LoopColorTheme;
  currentActiveTheme$: BehaviorSubject<LoopColorTheme | null> = new BehaviorSubject<LoopColorTheme | null>(null);

  constructor(
    private _storageService: StorageService,
    private _electronService: ElectronService,
  ) {
    this.init();
  }

  get currentSelectedTheme(): LoopColorTheme {
    return this.currentActiveTheme;
  }

  get isDarkModeActive(): boolean {
    return this.currentActiveTheme.isDarkTheme;
  }

  private init() {
    this.initializeThemes();
    this.readCustomThemes();
    this.setDefaultTheme();
  }

  private initializeThemes() {
    _.forEach(availableLoopThemes, (_theme: ColorTheme) => {
      this.colorThemesById[_theme.id] = new LoopColorTheme(_theme);
    });
  }

  private readCustomThemes() {
    let hexes = this._storageService.getItem(StorageKey.customThemeColors);

    if (hexes) {
      let _hexes = hexes.split(';');
      this.colorThemesById['custom_light'].updatePaletteWithCustomHexes(_hexes);
      this.colorThemesById['custom_dark'].updatePaletteWithCustomHexes(_hexes);
    }
  }

  setThemeById(fullId: string, hexes?: string) {
    if (!fullId) {
      return;
    }

    this._storageService.setItem(StorageKey.defaultColorThemeId, fullId);
    this.currentActiveTheme = this.colorThemesById[fullId];
    this.currentActiveTheme$.next(this.currentActiveTheme);

    if (this.currentActiveTheme.isCustom && hexes) {
      this._storageService.setItem(StorageKey.customThemeColors, hexes);
      this.currentActiveTheme.updatePaletteWithCustomHexes(hexes.split(';'));
    }

    // Todo move electron deps to dta only. The rest should go to shared
    this._electronService
      .setNativeTheme(this.currentActiveTheme.isDarkTheme)
      .pipe(memoryLeakGuard(), take(1))
      .subscribe();

    this.currentActiveTheme.applyTheme();
  }

  switchThemeByName(name: string) {
    let newFullId = name + (this.currentActiveTheme.isDarkTheme ? '_dark' : '_light');
    this.currentActiveTheme = this.colorThemesById[newFullId];
    this.currentActiveTheme$.next(this.currentActiveTheme);
    this._storageService.setItem(StorageKey.defaultColorThemeId, newFullId);
    this.currentActiveTheme.applyTheme();
  }

  toggleDark() {
    let newFullId = this.currentActiveTheme.themeName + (this.currentActiveTheme.isDarkTheme ? '_light' : '_dark');
    this.currentActiveTheme = this.colorThemesById[newFullId];
    this.currentActiveTheme$.next(this.currentActiveTheme);
    this._storageService.setItem(StorageKey.defaultColorThemeId, newFullId);
    this.currentActiveTheme.applyTheme();
  }

  // This method is called when app is initialized and sets default theme
  setDefaultTheme() {
    let defaultThemeId = this._storageService.getItem(StorageKey.defaultColorThemeId) || 'violet_light';

    this.setThemeById(defaultThemeId);
  }

  getAvailableThemeNames(): string[] {
    return _.uniq(_.filter(availableLoopThemes, theme => !theme.isCustom).map((theme: ColorTheme) => theme.themeName));
  }

  ngOnDestroy(): void {
    this.currentActiveTheme$.complete();
  }
}
