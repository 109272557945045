import { Component, Input, OnInit } from '@angular/core';
import { FileModel } from '../../../../shared/models-api-loop/file.model';

@Component({
  selector: 'thumbnail',
  styleUrls: ['./thumbnail.scss'],
  templateUrl: './thumbnail.html',
})
export class ThumbnailComponent implements OnInit {
  @Input() file: FileModel;
  @Input() search: boolean;
  @Input() full: boolean;

  ////////////////////
  // State variables
  ////////////////////
  uri: string;

  ngOnInit() {
    this.setFileThumbnailUri();
  }

  onImageError() {
    this.uri = undefined;
  }

  private setFileThumbnailUri() {
    // Show file with synced thumbnail
    if (this.file._ex && (this.file._ex.syncStatus.thumbnail || this.file._ui.thumbnailPath)) {
      this.uri = this.file._ui.thumbnailPath;
      return;
    }
  }
}
