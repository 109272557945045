/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { File } from '../models/file';
import { Response } from '../models/response';
import { ContentSignedLink } from '../models/content-signed-link';
import { ConvertedAttachments } from '../models/converted-attachments';
import { ListOfResourcesOfString } from '../models/list-of-resources-of-string';
import { ListOfResourcesOfFile } from '../models/list-of-resources-of-file';
import { CardType } from '../models/card-type';

@Injectable()
export class FileApiService extends BaseService {
  /**
   * @param params The `FileApiService.File_CreateFileParams` containing the following parameters:
   *
   * - `includeSignedLink`: Set url signed link
   *
   * - `file`: File object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_CreateFileResponse(
    params: FileApiService.File_CreateFileParams,
    forUserEmail: string
  ): Observable<HttpResponse<File>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/file`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_CreateFile');

    // body, headers and query params
    if (params.includeSignedLink != null)
      __params = __params.set('includeSignedLink', params.includeSignedLink.toString());
    let formData: FormData = new FormData();
    formData.append('file', params.file, params.file['name']);
    __body = formData;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: File;

          body = <File>response.body;

          return <HttpResponse<File>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_CreateFileParams` containing the following parameters:
   *
   * - `includeSignedLink`: Set url signed link
   *
   * - `file`: File object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_CreateFile(
    params: FileApiService.File_CreateFileParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<File> {
    const observable$ = this.File_CreateFileResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file`;
      const url = this.rootUrl + `/api/v1/file`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_UpdateFileProperParams` containing the following parameters:
   *
   * - `id`: id of file
   *
   * - `includeSignedLink`: Set url signed link
   *
   * - `file`: File object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_UpdateFileProperResponse(
    params: FileApiService.File_UpdateFileProperParams,
    forUserEmail: string
  ): Observable<HttpResponse<File>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/file/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_UpdateFileProper');

    // body, headers and query params
    if (params.includeSignedLink != null)
      __params = __params.set('includeSignedLink', params.includeSignedLink.toString());
    let formData: FormData = new FormData();
    formData.append('file', params.file, params.file['name']);
    __body = formData;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: File;

          body = <File>response.body;

          return <HttpResponse<File>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_UpdateFileProperParams` containing the following parameters:
   *
   * - `id`: id of file
   *
   * - `includeSignedLink`: Set url signed link
   *
   * - `file`: File object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_UpdateFileProper(
    params: FileApiService.File_UpdateFileProperParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<File> {
    const observable$ = this.File_UpdateFileProperResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/${params.id}`;
      const url = this.rootUrl + `/api/v1/file/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetFileParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `includeSignedLink`: Set url signed link
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFileResponse(
    params: FileApiService.File_GetFileParams,
    forUserEmail: string
  ): Observable<HttpResponse<File>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetFile');

    // body, headers and query params
    if (params.includeSignedLink != null)
      __params = __params.set('includeSignedLink', params.includeSignedLink.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: File;

          body = <File>response.body;

          return <HttpResponse<File>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_GetFileParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `includeSignedLink`: Set url signed link
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFile(
    params: FileApiService.File_GetFileParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<File> {
    const observable$ = this.File_GetFileResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/${params.id}`;
      const url = this.rootUrl + `/api/v1/file/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetFileContentParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFileContentResponse(
    params: FileApiService.File_GetFileContentParams,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/${params.id}/content`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetFileContent');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `FileApiService.File_GetFileContentParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFileContent(
    params: FileApiService.File_GetFileContentParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.File_GetFileContentResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/${params.id}/content`;
      const url = this.rootUrl + `/api/v1/file/${params.id}/content`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetSignedLinkContentParams` containing the following parameters:
   *
   * - `contentSignedLink`: Content signed link
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetSignedLinkContentResponse(
    params: FileApiService.File_GetSignedLinkContentParams,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/file/signedlinkcontent`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetSignedLinkContent');

    // body, headers and query params
    __body = params.contentSignedLink;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `FileApiService.File_GetSignedLinkContentParams` containing the following parameters:
   *
   * - `contentSignedLink`: Content signed link
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetSignedLinkContent(
    params: FileApiService.File_GetSignedLinkContentParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.File_GetSignedLinkContentResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/signedlinkcontent`;
      const url = this.rootUrl + `/api/v1/file/signedlinkcontent`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetFileSignedLinkParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFileSignedLinkResponse(
    params: FileApiService.File_GetFileSignedLinkParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/${params.id}/signedlink`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetFileSignedLink');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_GetFileSignedLinkParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFileSignedLink(
    params: FileApiService.File_GetFileSignedLinkParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.File_GetFileSignedLinkResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/${params.id}/signedlink`;
      const url = this.rootUrl + `/api/v1/file/${params.id}/signedlink`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_ConvertFileAttachmentToTempAttachmentGETParams` containing the following parameters:
   *
   * - `fileAttachmentIds`: list attachment to convert - it start with AF_
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_ConvertFileAttachmentToTempAttachmentGETResponse(
    params: FileApiService.File_ConvertFileAttachmentToTempAttachmentGETParams,
    forUserEmail: string
  ): Observable<HttpResponse<ConvertedAttachments>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/convertattachmentstotemp`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_ConvertFileAttachmentToTempAttachmentGET');

    // body, headers and query params
    (params.fileAttachmentIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('fileAttachmentIds', val.toString());
    });
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ConvertedAttachments;

          body = <ConvertedAttachments>response.body;

          return <HttpResponse<ConvertedAttachments>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_ConvertFileAttachmentToTempAttachmentGETParams` containing the following parameters:
   *
   * - `fileAttachmentIds`: list attachment to convert - it start with AF_
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_ConvertFileAttachmentToTempAttachmentGET(
    params: FileApiService.File_ConvertFileAttachmentToTempAttachmentGETParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ConvertedAttachments> {
    const observable$ = this.File_ConvertFileAttachmentToTempAttachmentGETResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/convertattachmentstotemp`;
      const url = this.rootUrl + `/api/v1/file/convertattachmentstotemp`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_ConvertFileAttachmentToTempAttachmentPOSTParams` containing the following parameters:
   *
   * - `bodyFileAttachmentIds`: list attachment to convert - it start with AF_
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_ConvertFileAttachmentToTempAttachmentPOSTResponse(
    params: FileApiService.File_ConvertFileAttachmentToTempAttachmentPOSTParams,
    forUserEmail: string
  ): Observable<HttpResponse<ConvertedAttachments>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/file/convertattachmentstotemp`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_ConvertFileAttachmentToTempAttachmentPOST');

    // body, headers and query params
    __body = params.bodyFileAttachmentIds;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ConvertedAttachments;

          body = <ConvertedAttachments>response.body;

          return <HttpResponse<ConvertedAttachments>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_ConvertFileAttachmentToTempAttachmentPOSTParams` containing the following parameters:
   *
   * - `bodyFileAttachmentIds`: list attachment to convert - it start with AF_
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  File_ConvertFileAttachmentToTempAttachmentPOST(
    params: FileApiService.File_ConvertFileAttachmentToTempAttachmentPOSTParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ConvertedAttachments> {
    const observable$ = this.File_ConvertFileAttachmentToTempAttachmentPOSTResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/convertattachmentstotemp`;
      const url = this.rootUrl + `/api/v1/file/convertattachmentstotemp`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetFilePdfParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFilePdfResponse(
    params: FileApiService.File_GetFilePdfParams,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/${params.id}/pdf`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetFilePdf');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `FileApiService.File_GetFilePdfParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFilePdf(
    params: FileApiService.File_GetFilePdfParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.File_GetFilePdfResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/${params.id}/pdf`;
      const url = this.rootUrl + `/api/v1/file/${params.id}/pdf`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetFileThumbnailParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `thumbnailSize`: Thumbnail size, values: Small, and Medium (default).
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFileThumbnailResponse(
    params: FileApiService.File_GetFileThumbnailParams,
    forUserEmail: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/${params.id}/thumbnail`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetFileThumbnail');

    // body, headers and query params
    if (params.thumbnailSize != null) __params = __params.set('thumbnailSize', params.thumbnailSize.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  /**
   * @param params The `FileApiService.File_GetFileThumbnailParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `thumbnailSize`: Thumbnail size, values: Small, and Medium (default).
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetFileThumbnail(
    params: FileApiService.File_GetFileThumbnailParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ArrayBuffer> {
    const observable$ = this.File_GetFileThumbnailResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/${params.id}/thumbnail`;
      const url = this.rootUrl + `/api/v1/file/${params.id}/thumbnail`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetThumbnailSignedLinkParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `thumbnailSize`: Thumbnail size, values: Small, and Medium (default).
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetThumbnailSignedLinkResponse(
    params: FileApiService.File_GetThumbnailSignedLinkParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/${params.id}/thumbnailsignedlink`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetThumbnailSignedLink');

    // body, headers and query params
    if (params.thumbnailSize != null) __params = __params.set('thumbnailSize', params.thumbnailSize.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_GetThumbnailSignedLinkParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `thumbnailSize`: Thumbnail size, values: Small, and Medium (default).
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetThumbnailSignedLink(
    params: FileApiService.File_GetThumbnailSignedLinkParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.File_GetThumbnailSignedLinkResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/${params.id}/thumbnailsignedlink`;
      const url = this.rootUrl + `/api/v1/file/${params.id}/thumbnailsignedlink`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetPdfThumbnailSignedLinkParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetPdfThumbnailSignedLinkResponse(
    params: FileApiService.File_GetPdfThumbnailSignedLinkParams,
    forUserEmail: string
  ): Observable<HttpResponse<string>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/${params.id}/pdfsignedlink`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetPdfThumbnailSignedLink');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string;

          body = <string>response.body;

          return <HttpResponse<string>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_GetPdfThumbnailSignedLinkParams` containing the following parameters:
   *
   * - `id`: File id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `ETag`: EntityTag header
   *
   * - `Authorization`: Authorization header
   */
  File_GetPdfThumbnailSignedLink(
    params: FileApiService.File_GetPdfThumbnailSignedLinkParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string> {
    const observable$ = this.File_GetPdfThumbnailSignedLinkResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/${params.id}/pdfsignedlink`;
      const url = this.rootUrl + `/api/v1/file/${params.id}/pdfsignedlink`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `FileApiService.File_GetListParams` containing the following parameters:
   *
   * - `sortOrder`: Order of comments by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `senderIds`: List of user ids as senders (From) to filter comment list by.
   *
   * - `searchQuery`: Plaintext search query to filter file list by. Please use prefix 'To:' or 'From:' in order to filter by recipient oy by sender.
   *
   * - `recipientIds`: List of recipient ids (either a user or a group) to filter file list by.
   *
   * - `offsetModified`: Include files from/sice (as per requested sort order) its modified date.
   *
   * - `offsetId`: Id of last recieved file in the previous batch when using offsetModified
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offsetDateType`: Offset date type.
   *
   * - `offsetDateEnd`: Include cards to/until (as per requested sort order) its offset date type.
   *
   * - `offsetDate`: Include cards from/since (as per requested sort order) its offset date type.
   *
   * - `offset`: Requested page offset.
   *
   * - `includeSignedLinks`: Includes signed links for file/thumbnail
   *
   * - `groupFile`: file is in group
   *
   * - `exactShareContactIds`: List of contact ids (user or group) to filter result list by exact matching of comments's share list.
   *
   * - `contactFilterRelation`: Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
   *
   * - `cardTypes`: List of card types to filter file list by.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   *
   * @return List of files.
   */
  File_GetListResponse(
    params: FileApiService.File_GetListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfFile>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/file/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('File_GetList');

    // body, headers and query params
    if (params.sortOrder != null) __params = __params.set('sortOrder', params.sortOrder.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    (params.senderIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('senderIds', val.toString());
    });
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.recipientIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('recipientIds', val.toString());
    });
    if (params.offsetModified != null) __params = __params.set('offsetModified', params.offsetModified.toString());
    if (params.offsetId != null) __params = __params.set('offsetId', params.offsetId.toString());
    if (params.offsetHistoryId != null) __params = __params.set('offsetHistoryId', params.offsetHistoryId.toString());
    if (params.offsetDateType != null) __params = __params.set('offsetDateType', params.offsetDateType.toString());
    if (params.offsetDateEnd != null) __params = __params.set('offsetDateEnd', params.offsetDateEnd.toString());
    if (params.offsetDate != null) __params = __params.set('offsetDate', params.offsetDate.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeSignedLinks != null)
      __params = __params.set('includeSignedLinks', params.includeSignedLinks.toString());
    if (params.groupFile != null) __params = __params.set('groupFile', params.groupFile.toString());
    (params.exactShareContactIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('exactShareContactIds', val.toString());
    });
    if (params.contactFilterRelation != null)
      __params = __params.set('contactFilterRelation', params.contactFilterRelation.toString());
    (params.cardTypes || []).forEach((val, index) => {
      if (val != null) __params = __params.append('cardTypes', val.toString());
    });
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfFile;

          body = <ListOfResourcesOfFile>response.body;

          return <HttpResponse<ListOfResourcesOfFile>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `FileApiService.File_GetListParams` containing the following parameters:
   *
   * - `sortOrder`: Order of comments by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `senderIds`: List of user ids as senders (From) to filter comment list by.
   *
   * - `searchQuery`: Plaintext search query to filter file list by. Please use prefix 'To:' or 'From:' in order to filter by recipient oy by sender.
   *
   * - `recipientIds`: List of recipient ids (either a user or a group) to filter file list by.
   *
   * - `offsetModified`: Include files from/sice (as per requested sort order) its modified date.
   *
   * - `offsetId`: Id of last recieved file in the previous batch when using offsetModified
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offsetDateType`: Offset date type.
   *
   * - `offsetDateEnd`: Include cards to/until (as per requested sort order) its offset date type.
   *
   * - `offsetDate`: Include cards from/since (as per requested sort order) its offset date type.
   *
   * - `offset`: Requested page offset.
   *
   * - `includeSignedLinks`: Includes signed links for file/thumbnail
   *
   * - `groupFile`: file is in group
   *
   * - `exactShareContactIds`: List of contact ids (user or group) to filter result list by exact matching of comments's share list.
   *
   * - `contactFilterRelation`: Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
   *
   * - `cardTypes`: List of card types to filter file list by.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   *
   * @return List of files.
   */
  File_GetList(
    params: FileApiService.File_GetListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfFile> {
    const observable$ = this.File_GetListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/file/list`;
      const url = this.rootUrl + `/api/v1/file/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module FileApiService {
  /**
   * Parameters for File_CreateFile
   */
  export type File_CreateFileParams = {
    /**
     * Set url signed link
     */
    includeSignedLink?: boolean;

    /**
     * File object
     */
    file?: Blob;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_UpdateFileProper
   */
  export type File_UpdateFileProperParams = {
    /**
     * id of file
     */
    id: string;

    /**
     * Set url signed link
     */
    includeSignedLink?: boolean;

    /**
     * File object
     */
    file?: Blob;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetFile
   */
  export type File_GetFileParams = {
    /**
     * File id
     */
    id: string;

    /**
     * Set url signed link
     */
    includeSignedLink?: boolean;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetFileContent
   */
  export type File_GetFileContentParams = {
    /**
     * File id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * EntityTag header
     */
    ETag?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetSignedLinkContent
   */
  export type File_GetSignedLinkContentParams = {
    /**
     * Content signed link
     */
    contentSignedLink: ContentSignedLink;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * EntityTag header
     */
    ETag?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetFileSignedLink
   */
  export type File_GetFileSignedLinkParams = {
    /**
     * File id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_ConvertFileAttachmentToTempAttachmentGET
   */
  export type File_ConvertFileAttachmentToTempAttachmentGETParams = {
    /**
     * list attachment to convert - it start with AF_
     */
    fileAttachmentIds?: string[];

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_ConvertFileAttachmentToTempAttachmentPOST
   */
  export type File_ConvertFileAttachmentToTempAttachmentPOSTParams = {
    /**
     * list attachment to convert - it start with AF_
     */
    bodyFileAttachmentIds?: ListOfResourcesOfString;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetFilePdf
   */
  export type File_GetFilePdfParams = {
    /**
     * File id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * EntityTag header
     */
    ETag?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetFileThumbnail
   */
  export type File_GetFileThumbnailParams = {
    /**
     * File id
     */
    id: string;

    /**
     * Thumbnail size, values: Small, and Medium (default).
     */
    thumbnailSize?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * EntityTag header
     */
    ETag?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetThumbnailSignedLink
   */
  export type File_GetThumbnailSignedLinkParams = {
    /**
     * File id
     */
    id: string;

    /**
     * Thumbnail size, values: Small, and Medium (default).
     */
    thumbnailSize?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * EntityTag header
     */
    ETag?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetPdfThumbnailSignedLink
   */
  export type File_GetPdfThumbnailSignedLinkParams = {
    /**
     * File id
     */
    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * EntityTag header
     */
    ETag?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for File_GetList
   */
  export type File_GetListParams = {
    /**
     * Order of comments by modified date time (default is descending).
     */
    sortOrder?: string;

    /**
     * Requested page size.
     */
    size?: number;

    /**
     * List of user ids as senders (From) to filter comment list by.
     */
    senderIds?: string[];

    /**
     * Plaintext search query to filter file list by. Please use prefix 'To:' or 'From:' in order to filter by recipient oy by sender.
     */
    searchQuery?: string;

    /**
     * List of recipient ids (either a user or a group) to filter file list by.
     */
    recipientIds?: string[];

    /**
     * Include files from/sice (as per requested sort order) its modified date.
     */
    offsetModified?: string;

    /**
     * Id of last recieved file in the previous batch when using offsetModified
     */
    offsetId?: string;

    /**
     * Next page of resources when offsetModified set
     */
    offsetHistoryId?: string;

    /**
     * Offset date type.
     */
    offsetDateType?: string;

    /**
     * Include cards to/until (as per requested sort order) its offset date type.
     */
    offsetDateEnd?: string;

    /**
     * Include cards from/since (as per requested sort order) its offset date type.
     */
    offsetDate?: string;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * Includes signed links for file/thumbnail
     */
    includeSignedLinks?: boolean;

    /**
     * file is in group
     */
    groupFile?: boolean;

    /**
     * List of contact ids (user or group) to filter result list by exact matching of comments's share list.
     */
    exactShareContactIds?: string[];

    /**
     * Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
     */
    contactFilterRelation?: string;

    /**
     * List of card types to filter file list by.
     */
    cardTypes?: CardType[];

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };
}
