import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isWebApp } from '@dta/shared/utils/common-utils';
import { Observable } from 'rxjs';
import { CONSTANTS } from '@shared/models/constants/constants';

@Injectable()
export class HttpApiAppDataInterceptor implements HttpInterceptor {
  private readonly typeHeaderName: string = 'LoopApp-Type';
  private readonly versionHeaderName: string = 'LoopApp-Version';
  private externalLinks = [CONSTANTS.TENOR_API, CONSTANTS.TENOR_IMAGES];
  private isWebApp = isWebApp();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.externalLinks.some(externalLink => request.url.includes(externalLink))) {
      return next.handle(request);
    }

    let headers = request.headers;
    headers = headers.set(this.typeHeaderName, this.isWebApp ? 'Loop-WEB' : 'Loop-DTA');
    headers = headers.set(this.versionHeaderName, APP_VERSION);

    request = request.clone({
      headers: headers,
    });

    return next.handle(request);
  }
}
