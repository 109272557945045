import { Injectable, OnDestroy } from '@angular/core';
import { LRUCache } from 'lru-cache';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';

@Injectable()
export class StorageProviderService implements OnDestroy {
  private readonly storage: Map<string, LRUCache<any, any>> = new Map<string, LRUCache<any, any>>();

  constructor() {
    this.observeClearStorageRequests();
  }

  private observeClearStorageRequests(): void {
    SharedSubjects._clearStorageCache.subscribe(request => {
      const storageNames: string[] = [];
      let requestedStorageName = request.storageName;
      while (requestedStorageName) {
        storageNames.push(requestedStorageName.storageName);
        if (!requestedStorageName.child) {
          break;
        }
        requestedStorageName = requestedStorageName.child;
      }

      if (!requestedStorageName) {
        return;
      }

      let storageToClear: LRUCache<any, any>;
      storageNames.forEach(storageName => {
        storageToClear = this.storage.get(storageName);

        if (requestedStorageName.keyToDelete) {
          storageToClear?.delete(requestedStorageName.keyToDelete);
        } else {
          storageToClear?.clear();
        }
      });
    });
  }

  /**
   * @param name - storage UUID
   * @param maxsize - max stored items count
   * @param ttl - stored items validity - default 15 minutes
   */
  provideStorage<K, V>(name: string, maxsize: number = 50, ttl: number | null = 900000): LRUCache<K, V> {
    if (!this.storage.has(name)) {
      this.storage.set(
        name,
        new LRUCache<K, V>({
          ttl: ttl,
          max: maxsize,
        }),
      );
    }
    return this.storage.get(name)!;
  }

  releaseStorage(name: string): void {
    if (this.storage.has(name)) {
      this.storage.get(name)?.clear();
    }
  }

  clearAll(): void {
    this.storage.forEach(storage => storage.clear());
  }

  ngOnDestroy(): void {
    this.storage.clear();
  }
}
