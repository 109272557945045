/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Response } from '../models/response';
import { EmailType } from '../models/email-type';

@Injectable()
export class HelperApiService extends BaseService {
  Helper_GetListOfEnumsResponse(forUserEmail: string): Observable<HttpResponse<string[]>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/enums`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Helper_GetListOfEnums');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: string[];

          body = <string[]>response.body;

          return <HttpResponse<string[]>>response.clone({ body: body });
        })
      );
  }

  Helper_GetListOfEnums(forUserEmail: string, cacheEnabled?: boolean): Observable<string[]> {
    const observable$ = this.Helper_GetListOfEnumsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/enums`;
      const url = this.rootUrl + `/enums`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `HelperApiService.Helper_GetEnumParams` containing the following parameters:
   *
   * - `id`: Enum id
   *
   * @return
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   */
  Helper_GetEnumResponse(
    params: HelperApiService.Helper_GetEnumParams,
    forUserEmail: string
  ): Observable<HttpResponse<any>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/enums/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Helper_GetEnum');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: any;

          return <HttpResponse<any>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `HelperApiService.Helper_GetEnumParams` containing the following parameters:
   *
   * - `id`: Enum id
   *
   * @return
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   *
   * or
   */
  Helper_GetEnum(
    params: HelperApiService.Helper_GetEnumParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<any> {
    const observable$ = this.Helper_GetEnumResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/enums/${params.id}`;
      const url = this.rootUrl + `/enums/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `HelperApiService.Helper_GetEmailTypeParams` containing the following parameters:
   *
   * - `emailAddress`:
   */
  Helper_GetEmailTypeResponse(
    params: HelperApiService.Helper_GetEmailTypeParams,
    forUserEmail: string
  ): Observable<HttpResponse<EmailType>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/emailType/${params.emailAddress}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Helper_GetEmailType');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: EmailType;

          body = <EmailType>response.body;

          return <HttpResponse<EmailType>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `HelperApiService.Helper_GetEmailTypeParams` containing the following parameters:
   *
   * - `emailAddress`:
   */
  Helper_GetEmailType(
    params: HelperApiService.Helper_GetEmailTypeParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<EmailType> {
    const observable$ = this.Helper_GetEmailTypeResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/emailType/${params.emailAddress}`;
      const url = this.rootUrl + `/emailType/${params.emailAddress}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module HelperApiService {
  /**
   * Parameters for Helper_GetEnum
   */
  export type Helper_GetEnumParams = {
    /**
     * Enum id
     */
    id: string;
  };

  /**
   * Parameters for Helper_GetEmailType
   */
  export type Helper_GetEmailTypeParams = {
    emailAddress: string;
  };
}
