import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventLengthPipe } from '@shared/modules/agenda/pipes/event-length.pipe';
import { GetAppointmentResponsePipe } from '@shared/modules/agenda/pipes/get-appointment-response.pipe';
import { InviteesPipe } from '@shared/modules/agenda/pipes/invitees.pipe';
import { IsInSelectedMonthPipe } from '@shared/modules/agenda/pipes/is-in-selected-month.pipe';
import { IsLastDayOfWeekPipe } from '@shared/modules/agenda/pipes/is-last-day-of-week.pipe';
import { IsOrganizerPipe } from '@shared/modules/agenda/pipes/is-organizer.pipe';
import { IsSameMonthPipe } from '@shared/modules/agenda/pipes/is-same-month.pipe';
import { IsSameDayPipe } from '@shared/modules/agenda/pipes/is-same-day.pipe';
import { IsTodayPipe } from '@shared/modules/agenda/pipes/is-today.pipe';
import { IsWeekendDayPipe } from '@shared/modules/agenda/pipes/is-weekend-day.pipe';
import { OrganizerPipe } from '@shared/modules/agenda/pipes/organizer.pipe';
import { PxPipe } from '@shared/modules/agenda/pipes/px.pipe';
import { TimesPipe } from '@shared/modules/agenda/pipes/times.pipe';

@NgModule({
  declarations: [
    EventLengthPipe,
    GetAppointmentResponsePipe,
    InviteesPipe,
    IsInSelectedMonthPipe,
    IsLastDayOfWeekPipe,
    IsOrganizerPipe,
    IsSameMonthPipe,
    IsSameDayPipe,
    IsTodayPipe,
    IsWeekendDayPipe,
    OrganizerPipe,
    PxPipe,
    TimesPipe,
  ],
  imports: [CommonModule],
  exports: [
    EventLengthPipe,
    GetAppointmentResponsePipe,
    InviteesPipe,
    IsInSelectedMonthPipe,
    IsLastDayOfWeekPipe,
    IsOrganizerPipe,
    IsSameMonthPipe,
    IsSameDayPipe,
    IsTodayPipe,
    IsWeekendDayPipe,
    OrganizerPipe,
    PxPipe,
    TimesPipe,
  ],
})
export class AgendaPipesModule {}
