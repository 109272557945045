<ng-container *ngIf="isClassificationTagEnabled">
  <ng-container *ngIf="classificationTag; else emptyClassificationTagTpl">
    <ng-container *ngIf="isClassificationAssignVisible; else simpleClassificationTagTpl">
      <loop-conversation-tag
        loopCustomDropdown
        [dropdownConfiguration]="classificationDropdownComponent"
        [componentParams]="{ selectedClassificationTag: classificationTag, conversationIds: conversationIds }"
        [content]="classificationTag.name"
        [color]="classificationTag.color"
        [loopHoverTooltip]="classificationTag.name"
        [suffixTpl]="suffixTpl"
      >
      </loop-conversation-tag>

      <ng-template #suffixTpl>
        <div class="flex items-center">
          <svg-comp class="dropdown-icon" [style.color]="classificationTag.color" [src]="dropdownSvg"> </svg-comp>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #simpleClassificationTagTpl>
      <loop-conversation-tag
        [content]="classificationTag.name"
        [color]="classificationTag.color"
        [loopHoverTooltip]="classificationTag.name"
      >
      </loop-conversation-tag>
    </ng-template>
  </ng-container>

  <ng-template #emptyClassificationTagTpl>
    <ng-container *ngIf="isClassificationAssignVisible">
      <loop-conversation-tag
        loopCustomDropdown
        [dropdownConfiguration]="classificationDropdownComponent"
        [componentParams]="{ conversationIds: conversationIds }"
        [content]="'Classify'"
        [color]="'--medium-ui-element'"
        [suffixTpl]="suffixTpl"
      >
      </loop-conversation-tag>

      <ng-template #suffixTpl>
        <div class="flex items-center">
          <svg-comp class="dropdown-icon" [style.color]="unClassifiedColor" [src]="dropdownSvg"> </svg-comp>
        </div>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>
