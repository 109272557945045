import { Observable } from 'rxjs';
import { BaseDaoService } from '../base/base-dao.service';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { AvailabilityStatus } from '@shared/api/api-loop/models/availability-status';

export interface AvailabilityStatusDaoServiceI {
  findAllAvailabilityStatuses(forUserEmail: string): Observable<AvailabilityStatusModel[]>;
  removeAllAvailabilityStatuses(forUserEmail: string): Observable<any>;
}

export abstract class AvailabilityStatusDaoService
  extends BaseDaoService<AvailabilityStatusModel, AvailabilityStatus>
  implements AvailabilityStatusDaoServiceI
{
  abstract findAllAvailabilityStatuses(forUserEmail: string): Observable<AvailabilityStatusModel[]>;
  abstract removeAllAvailabilityStatuses(forUserEmail: string): Observable<any>;
}
