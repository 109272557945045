import { css } from '@shared/modules/comments/helpers/lit-css';
import { Color, COLOR_TYPES, ColorType } from '@shared/modules/color-scheme/common/color-types-array';
import { ColorPalette } from '@shared/modules/color-scheme/common/color-palette';

export const createThemeColorPaletteCssVariablesFactory =
  (prefix: string) =>
  (colorPalette: ColorPalette): string =>
    Object.entries(colorPalette)
      .map(([colorType, color]: [string, any]): [ColorType, Color] => [colorType as ColorType, color])
      .map(
        ([colorType, color]) =>
          css`
          --${prefix}-${colorType}: ${color.baseHex};
          --${prefix}-${colorType}-rgb: ${color.baseRgbValues};
          --${prefix}-${colorType}-contrast: ${color.contrastHex};
          --${prefix}-${colorType}-contrast-rgb: ${color.contrastRgbValues};
          --${prefix}-${colorType}-shade: ${color.shade};
          --${prefix}-${colorType}-tint: ${color.tint};
          --${prefix}-${colorType}-highlight: ${color.highlight};
          --${prefix}-${colorType}-translucent-base: ${color.translucentBase};
    `
      )
      .reduce((completeStyles, colorStyles) => `${completeStyles}${colorStyles}`, '')
      // remove all white space characters (excluding spaces)
      .replace(/[\r\n\t\f\v]/g, '')
      // replace multiple spaces with single one
      .replace(/\s+/g, ' ');

export const createAtomColorPaletteCssVariablesAliasesFactory =
  (cssClassPrefix: string, variablePrefix: string) => (): string =>
    COLOR_TYPES.map(
      colorType =>
        css`
          .${cssClassPrefix}-${colorType} {
            --${cssClassPrefix}: var(--${variablePrefix}-${colorType});
            --${cssClassPrefix}-rgb: var(--${variablePrefix}-${colorType}-rgb);
            --${cssClassPrefix}-contrast: var(--${variablePrefix}-${colorType}-contrast);
            --${cssClassPrefix}-contrast-rgb: var(--${variablePrefix}-${colorType}-contrast-rgb);
            --${cssClassPrefix}-shade: var(--${variablePrefix}-${colorType}-shade);
            --${cssClassPrefix}-tint: var(--${variablePrefix}-${colorType}-tint);
            --${cssClassPrefix}-highlight: var(--${variablePrefix}-${colorType}-highlight);
            --${cssClassPrefix}-translucent-base: var(--${variablePrefix}-${colorType}-translucent-base);
          }
    `
    )
      .concat([
        css`
          .${cssClassPrefix}-text {
            --${cssClassPrefix}: var(--${variablePrefix}-basic-contrast);
          }
    `
      ])
      .reduce((completeStyles, colorStyles) => `${completeStyles}${colorStyles}`, '')
      // remove all white space characters (excluding spaces)
      .replace(/[\r\n\t\f\v]/g, '')
      // replace multiple spaces with single one
      .replace(/\s+/g, ' ');
