export interface ColorTheme {
  id: string;
  themeName: string;
  themeDisplayName: string;
  isDarkTheme: boolean;
  isCustom?: boolean;
  colorDictionary: { [id: string]: string };
  additionalProperties?: { [id: string]: string };
}

export enum ColorReturnType {
  HEX,
  RGB,
  RGBA,
}

export class LoopColorTheme {
  id: string;
  themeName: string;
  themeDisplayName: string;
  isDarkTheme: boolean;
  isCustom?: boolean;
  colorPalette: LoopColorPalette;
  additionalProperties: { [id: string]: string };
  customColorsNames: string[] = [
    'primary-color-dark',
    'primary-color-light',
    'unread-color',
    'highlight-color',
    'chat-bubble-me',
  ];

  constructor(data: ColorTheme) {
    this.id = data.id;
    this.themeName = data.themeName;
    this.themeDisplayName = data.themeDisplayName;
    this.isDarkTheme = data.isDarkTheme;
    this.isCustom = data.isCustom;
    this.additionalProperties = data.additionalProperties;
    this.colorPalette = new LoopColorPalette(data.colorDictionary);
  }

  updatePaletteWithCustomHexes(hexes: string[]) {
    if (hexes.length !== this.customColorsNames.length) {
      throw new Error(`Array length mismatch when setting custom colors.`);
    }

    this.customColorsNames.forEach((name: string, i: number) => {
      this.colorPalette.updateCustomColorByName(name, hexes[i]);
    });
  }

  mapToLocalColor(hexOrRgb: string, returnType: ColorReturnType, skipCheck?: boolean): string {
    if (!this.isDarkTheme && !skipCheck) {
      return hexOrRgb;
    }
    return this.colorPalette.mapToLocalColor(hexOrRgb, returnType);
  }

  // Use this method to apply css rules to document
  applyTheme() {
    this.colorPalette.applyPalette();
    Object.keys(this.additionalProperties).forEach(property => {
      document.documentElement.style.setProperty(`--${property}`, this.additionalProperties[property]);
    });

    document.documentElement.classList[this.isDarkTheme ? 'add' : 'remove']('is-dark-theme');
    document.documentElement.style.setProperty('color-scheme', this.isDarkTheme ? 'dark' : 'light');
  }
}

export class LoopColorPalette {
  palette: { [name: string]: LoopColor } = {};
  localWhite: LoopColor;
  localBlack: LoopColor;

  constructor(initDict: { [name: string]: string }) {
    this.parseInitDict(initDict);
    this.localWhite = this.palette['background-light'];
    this.localBlack = this.palette['strong-ui-element'];
  }

  private parseInitDict(_initDict: { [name: string]: string }) {
    Object.keys(_initDict).forEach((name: string) => {
      this.palette[name] = new LoopColor(_initDict[name], name);
    });
  }

  mapToLocalColor(hexOrRgb: string, returnType: ColorReturnType): string {
    let colorToMap = new LoopColor(hexOrRgb);

    // If we encounter a weird color we cannot parse, return the original.
    if (!colorToMap) {
      return hexOrRgb;
    }

    if (!colorToMap.isGrey) {
      return hexOrRgb;
    }

    let rDelta = this.localWhite.r - this.localBlack.r;
    let gDelta = this.localWhite.g - this.localBlack.g;
    let bDelta = this.localWhite.b - this.localBlack.b;

    let newR = Math.floor(this.localBlack.r + (colorToMap.r / 255) * rDelta);
    let newG = Math.floor(this.localBlack.g + (colorToMap.g / 255) * gDelta);
    let newB = Math.floor(this.localBlack.b + (colorToMap.b / 255) * bDelta);

    switch (returnType) {
      case ColorReturnType.HEX:
        return `#${('00' + newR.toString(16)).substr(-2)}${('00' + newG.toString(16)).substr(-2)}${('00' + newB.toString(16)).substr(-2)}`;
      case ColorReturnType.RGB:
        return `rgb(${newR}, ${newG}, ${newB})`;
      case ColorReturnType.RGBA:
        return `rgba(${newR}, ${newG}, ${newB}, ${colorToMap.a})`;
    }
  }

  updateCustomColorByName(name: string, hex: string) {
    this.palette[name].updateWithCustomHex(hex);
  }

  applyPalette() {
    Object.keys(this.palette).forEach(property => {
      document.documentElement.style.setProperty(`--${property}`, this.palette[property].hexValue);
    });
  }

  getHexDictionary(): { [name: string]: string } {
    let result = {};

    Object.keys(this.palette).forEach((name: string) => {
      result[name] = this.palette[name].hexValue;
    });

    return result;
  }
}

export class LoopColor {
  name?: string;
  hexValue: string;
  rgbaValue: string;
  isGrey: boolean;
  r: number;
  g: number;
  b: number;
  a: number;

  constructor(hex: string, name?: string) {
    this.name = name;
    this.parseDataAndSetValues(hex);
    this.markAsGrey();
  }

  private parseDataAndSetValues(data: string) {
    if (data.startsWith('#') && (data.length === 4 || data.length === 7 || data.length === 9)) {
      this.hexValue = data;
      this.setRgbValuesFromHex(data);
    } else if (data.startsWith('rgb')) {
      this.rgbaValue = data;
      this.setHexValuesFromRgb(data);
    } else {
      this.parseByNameOrReturnOriginal(data);
    }
  }

  private setRgbValuesFromHex(hex: string) {
    let _hex = hex.substr(1);
    let _r: string, _g: string, _b: string, _a: string;
    switch (_hex.length) {
      case 3:
        _r = _hex[0] + _hex[0];
        _g = _hex[1] + _hex[1];
        _b = _hex[2] + _hex[2];
        _a = 'ff';
        this.hexValue = `#${_r}${_g}${_b}`;
        break;
      case 6:
        _r = _hex[0] + _hex[1];
        _g = _hex[2] + _hex[3];
        _b = _hex[4] + _hex[5];
        _a = 'ff';
        break;
      case 8:
        _r = _hex[0] + _hex[1];
        _g = _hex[2] + _hex[3];
        _b = _hex[4] + _hex[5];
        _a = _hex[6] + _hex[7];
        break;
      default:
        throw new Error(`Error when parsing rgb values from hex. Trying to parse: ${hex}.`);
    }

    this.r = parseInt(_r, 16);
    this.g = parseInt(_g, 16);
    this.b = parseInt(_b, 16);
    this.a = parseInt(_a, 16) / 255;

    this.rgbaValue = `rgba(${this.r},${this.g},${this.b},${this.a})`;
  }

  private setHexValuesFromRgb(rgb: string) {
    let _rgb: string[];

    if (rgb.startsWith('rgba')) {
      _rgb = rgb.replace('rgba', '').replace('(', '').replace(')', '').split(',');
      this.r = parseInt(_rgb[0]);
      this.g = parseInt(_rgb[1]);
      this.b = parseInt(_rgb[2]);
      this.a = parseFloat(_rgb[3]);
      this.hexValue = `#${('00' + this.r.toString(16)).substr(-2)}${('00' + this.g.toString(16)).substr(-2)}${('00' + this.b.toString(16)).substr(-2)}${('00' + Math.floor(this.a * 256).toString(16)).substr(-2)}`;
    } else if (rgb.startsWith('rgb')) {
      _rgb = rgb.replace('rgb', '').replace('(', '').replace(')', '').split(',');
      this.r = parseInt(_rgb[0]);
      this.g = parseInt(_rgb[1]);
      this.b = parseInt(_rgb[2]);
      this.a = 1;
      this.hexValue = `#${('00' + this.r.toString(16)).substr(-2)}${('00' + this.g.toString(16)).substr(-2)}${('00' + this.b.toString(16)).substr(-2)}${('00' + Math.floor(this.a * 256).toString(16)).substr(-2)}`;
    } else {
      throw new Error(`Error when parsing hex values from rgb(a). Trying to parse: ${rgb}.`);
    }
  }

  private parseByNameOrReturnOriginal(data: string) {
    let _result = colorNameDictionary[data];

    if (_result) {
      this.hexValue = _result;
      this.setRgbValuesFromHex(_result);
    }
  }

  private markAsGrey() {
    let deltaRG = Math.abs(this.r - this.g);
    let deltaRB = Math.abs(this.r - this.b);
    let deltaBG = Math.abs(this.b - this.g);

    let diff = deltaRG + deltaRB + deltaBG;
    this.isGrey = diff < 25;
  }

  updateWithCustomHex(hex: string) {
    this.parseDataAndSetValues(hex);
  }
}

const sharedLightColors = {
  'background-dark': '#faf9fb',
  'light-overlay': '#ffffff80',
  'menu-text-color-highlight': '#ffffff',
  'background-light': '#ffffff',
  'light-ui-element': '#efeef1',
  'medium-ui-element': '#9d9ba0',
  'strong-ui-element': '#313032',
  'danger': '#d4343c',
  'danger-80': '#d4343cbb',
};

const sharedLightAdditional = {
  'box-shadow-light': '0 1px 4px 0 #00000033',
  'box-shadow': '0 2px 10px 0 #00000033',
  'box-shadow-search': '0 4px 6px #00000033',
};

const sharedDarkColors = {
  'light-overlay': '#ffffff0f',
  'danger': '#d4343c',
  'danger-80': '#d4343cbb',
};

const sharedDarkAdditional = {
  'box-shadow-light': '0 1px 4px 0 #00000080',
  'box-shadow': '0 2px 10px 0 #00000080',
  'box-shadow-search': '0 4px 6px #00000080',
};

export const availableLoopThemes: ColorTheme[] = [
  ///////////
  // Violet
  ///////////
  {
    id: 'violet_light',
    themeName: 'violet',
    themeDisplayName: 'Violet Loop',
    isDarkTheme: false,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#291d47',
      'primary-color-light': '#3a295c',
      'menu-text-color': '#c1b4f8',
      'unread-color': '#2ec0e8',
      'unread-color-background': '#2ec0e845',
      'highlight-color': '#41247c',
      'chat-bubble-light': '#e2e2e266',
      'chat-bubble': '#e2e2e2',
      'chat-bubble-me': '#8164bb',
      // additional hexes, computed from above
      'unread-color-80': '#2ec0e8bb',
      'background-dark-overlay': '#faf9fb90',
      ...sharedLightColors,
    },
    additionalProperties: {
      ...sharedLightAdditional,
    },
  },

  {
    id: 'violet_dark',
    themeName: 'violet',
    themeDisplayName: 'Violet Loop',
    isDarkTheme: true,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#18161b',
      'primary-color-light': '#221e2a',
      'menu-text-color': '#a7a5aa',
      'menu-text-color-highlight': '#e2e2e3',
      'background-light': '#1b181f',
      'background-dark': '#221e2a',
      'light-ui-element': '#2d2838',
      'medium-ui-element': '#a4a3a5',
      'strong-ui-element': '#e1e1e2',
      'unread-color': '#65c6e0',
      'unread-color-background': '#65c6e045',
      'highlight-color': '#f3edff',
      'chat-bubble-light': '#352d4266',
      'chat-bubble': '#352d42',
      'chat-bubble-me': '#584f66',
      // additional hexes, computed from above
      'unread-color-80': '#65c6e0bb',
      'background-dark-overlay': '#221e2a90',
      ...sharedDarkColors,
    },
    additionalProperties: {
      ...sharedDarkAdditional,
    },
  },

  ////////
  // Aqua
  ////////
  {
    id: 'aqua_light',
    themeName: 'aqua',
    themeDisplayName: 'Aqua Loop',
    isDarkTheme: false,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#00143c',
      'primary-color-light': '#0f2c5f',
      'menu-text-color': '#cbd5e8',
      'unread-color': '#51c8fc',
      'unread-color-background': '#51c8fc45',
      'highlight-color': '#072353',
      'chat-bubble': '#e2e2e2',
      'chat-bubble-light': '#e2e2e266',
      'chat-bubble-me': '#3b6cad',
      // additional hexes, computed from above
      'unread-color-80': '#51c8fcbb',
      'background-dark-overlay': '#faf9fb90',
      ...sharedLightColors,
    },
    additionalProperties: {
      ...sharedLightAdditional,
    },
  },

  {
    id: 'aqua_dark',
    themeName: 'aqua',
    themeDisplayName: 'Aqua Loop',
    isDarkTheme: true,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#15181e',
      'primary-color-light': '#1a2233',
      'menu-text-color': '#a3a7ad',
      'menu-text-color-highlight': '#e1e2e5',
      'background-light': '#181a1f',
      'background-dark': '#1d2129',
      'light-ui-element': '#282d38',
      'medium-ui-element': '#a2a4a8',
      'strong-ui-element': '#e1e1e3',
      'unread-color': '#70E0B7',
      'unread-color-background': '#70E0B745',
      'highlight-color': '#eef2ff',
      'chat-bubble': '#2d3342',
      'chat-bubble-light': '#2d334266',
      'chat-bubble-me': '#445170',
      // additional hexes, computed from above
      'unread-color-80': '#70E0B7bb',
      'background-dark-overlay': '#1d212990',
      ...sharedDarkColors,
    },
    additionalProperties: {
      ...sharedDarkAdditional,
    },
  },

  ////////////
  // Woodland
  ////////////
  {
    id: 'woodland_light',
    themeName: 'woodland',
    themeDisplayName: 'Woodland Loop',
    isDarkTheme: false,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#003328',
      'primary-color-light': '#0f5e4d',
      'menu-text-color': '#f4fffd',
      'unread-color': '#24b3b3',
      'unread-color-background': '#24b3b345',
      'highlight-color': '#065a59',
      'chat-bubble': '#e2e2e2',
      'chat-bubble-light': '#e2e2e266',
      'chat-bubble-me': '#005f59',
      // additional hexes, computed from above
      'unread-color-80': '#42b3b3bb',
      'background-dark-overlay': '#faf9fb90',
      ...sharedLightColors,
    },
    additionalProperties: {
      ...sharedLightAdditional,
    },
  },

  {
    id: 'woodland_dark',
    themeName: 'woodland',
    themeDisplayName: 'Woodland Loop',
    isDarkTheme: true,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#171b1b',
      'primary-color-light': '#1f2c2b',
      'menu-text-color': '#a5abaa',
      'menu-text-color-highlight': '#e2e4e4',
      'background-light': '#181f1f',
      'background-dark': '#1d2929',
      'light-ui-element': '#283838',
      'medium-ui-element': '#a3a6a6',
      'strong-ui-element': '#e1e2e2',
      'unread-color': '#65e0a5',
      'unread-color-background': '#65e0a545',
      'highlight-color': '#edfffe',
      'chat-bubble': '#2d4242',
      'chat-bubble-light': '#2d424266',
      'chat-bubble-me': '#4f6666',
      // additional hexes, computed from above
      'unread-color-80': '#65e0a5bb',
      'background-dark-overlay': '#1d292990',
      ...sharedDarkColors,
    },
    additionalProperties: {
      ...sharedDarkAdditional,
    },
  },

  //////////
  // Popsy
  //////////
  {
    id: 'popsy_light',
    themeName: 'popsy',
    themeDisplayName: 'Popsy Loop',
    isDarkTheme: false,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#48001e',
      'primary-color-light': '#6d1238',
      'menu-text-color': '#f5c7da',
      'unread-color': '#00ccbb',
      'unread-color-background': '#00ccbb45',
      'highlight-color': '#6d1138',
      'chat-bubble': '#e2e2e2',
      'chat-bubble-light': '#e2e2e266',
      'chat-bubble-me': '#b8617e',
      // additional hexes, computed from above
      'unread-color-80': '#00ccbbbb',
      'background-dark-overlay': '#faf9fb90',
      ...sharedLightColors,
    },
    additionalProperties: {
      ...sharedLightAdditional,
    },
  },

  {
    id: 'popsy_dark',
    themeName: 'popsy',
    themeDisplayName: 'Popsy Loop',
    isDarkTheme: true,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#1f1519',
      'primary-color-light': '#331a24',
      'menu-text-color': '#ada3a7',
      'menu-text-color-highlight': '#e5e1e3',
      'background-light': '#26181e',
      'background-dark': '#291d22',
      'light-ui-element': '#38282f',
      'medium-ui-element': '#a8a3a5',
      'strong-ui-element': '#e3e1e2',
      'unread-color': '#71dbcf',
      'unread-color-background': '#71dbcf45',
      'highlight-color': '#ffedf5',
      'chat-bubble': '#422d36',
      'chat-bubble-light': '#422d3666',
      'chat-bubble-me': '#704457',
      // additional hexes, computed from above
      'unread-color-80': '#71dbcfbb',
      'background-dark-overlay': '#291d2290',
      ...sharedDarkColors,
    },
    additionalProperties: {
      ...sharedDarkAdditional,
    },
  },

  ////////////
  // Reynald
  ////////////
  {
    id: 'reynald_light',
    themeName: 'reynald',
    themeDisplayName: 'Reynald Loop',
    isDarkTheme: false,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#89240d',
      'primary-color-light': '#2f1800',
      'menu-text-color': '#ffecec',
      'unread-color': '#36b36a',
      'unread-color-background': '#36b36a45',
      'highlight-color': '#551705',
      'chat-bubble': '#e2e2e2',
      'chat-bubble-light': '#e2e2e266',
      'chat-bubble-me': '#923f15',
      // additional hexes, computed from above
      'unread-color-80': '#36b36abb',
      'background-dark-overlay': '#faf9fb90',
      ...sharedLightColors,
    },
    additionalProperties: {
      ...sharedLightAdditional,
    },
  },

  {
    id: 'reynald_dark',
    themeName: 'reynald',
    themeDisplayName: 'Reynald Loop',
    isDarkTheme: true,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#1f1715',
      'primary-color-light': '#331f1a',
      'menu-text-color': '#ada5a3',
      'menu-text-color-highlight': '#e5e2e1',
      'background-light': '#1f1919',
      'background-dark': '#291f1d',
      'light-ui-element': '#382b28',
      'medium-ui-element': '#a7a3a3',
      'strong-ui-element': '#e2e1e1',
      'unread-color': '#65e092',
      'unread-color-background': '#65e09245',
      'highlight-color': '#fff0ed',
      'chat-bubble': '#42322d',
      'chat-bubble-light': '#42322d66',
      'chat-bubble-me': '#665b4f',
      // additional hexes, computed from above
      'unread-color-80': '#65e092bb',
      'background-dark-overlay': '#291f1d90',
      ...sharedDarkColors,
    },
    additionalProperties: {
      ...sharedDarkAdditional,
    },
  },

  ////////////
  // Menthol
  ////////////
  {
    id: 'menthol_light',
    themeName: 'menthol',
    themeDisplayName: 'Menthol Loop',
    isDarkTheme: false,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#339070',
      'primary-color-light': '#252525',
      'menu-text-color': '#d9fff1',
      'unread-color': '#da6b8f',
      'unread-color-background': '#da6b8f45',
      'highlight-color': '#3c3a3a',
      'chat-bubble': '#e2e2e2',
      'chat-bubble-light': '#e2e2e266',
      'chat-bubble-me': '#464646',
      // additional hexes, computed from above
      'unread-color-80': '#da6b8fbb',
      'background-dark-overlay': '#faf9fb90',
      ...sharedLightColors,
    },
    additionalProperties: {
      ...sharedLightAdditional,
    },
  },

  {
    id: 'menthol_dark',
    themeName: 'menthol',
    themeDisplayName: 'Menthol Loop',
    isDarkTheme: true,
    colorDictionary: {
      'primary-color-dark': '#339070',
      'primary-color-light': '#111111',
      'menu-text-color': '#e1e1e1',
      'menu-text-color-highlight': '#ffffff',
      'background-light': '#141414',
      'background-dark': '#1c1c1c',
      'light-ui-element': '#383838',
      'medium-ui-element': '#a1a1a1',
      'strong-ui-element': '#e1e1e1',
      'unread-color': '#e88fae',
      'unread-color-background': '#e88fae45',
      'highlight-color': '#e2f3ee',
      'chat-bubble': '#2e2e2e',
      'chat-bubble-light': '#2e2e2e66',
      'chat-bubble-me': '#505050',
      // additional hexes, computed from above
      'unread-color-80': '#e88faebb',
      'background-dark-overlay': '#1c1c1c90',
      ...sharedDarkColors,
    },
    additionalProperties: {
      ...sharedDarkAdditional,
    },
  },

  ////////////
  // Custom
  ////////////
  {
    id: 'custom_light',
    themeName: 'custom',
    themeDisplayName: 'Custom Loop',
    isDarkTheme: false,
    isCustom: true,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#291d47',
      'primary-color-light': '#3a295c',
      'menu-text-color': '#c1b4f8',
      'light-ui-element': '#efeef1',
      'unread-color': '#54c8e8',
      'unread-color-background': '#54c8e850',
      'highlight-color': '#41247c',
      'chat-bubble-me': '#8164bb',
      'chat-bubble': '#e2e2e2',
      ...sharedLightColors,
    },
    additionalProperties: {
      ...sharedLightAdditional,
    },
  },

  {
    id: 'custom_dark',
    themeName: 'custom',
    themeDisplayName: 'Custom Loop',
    isDarkTheme: true,
    isCustom: true,
    colorDictionary: {
      // color hex table
      'primary-color-dark': '#18161b',
      'primary-color-light': '#221e2a',
      'menu-text-color': '#e2e2e3',
      'menu-text-color-highlight': '#e2e2e2',
      'background-light': '#121212',
      'background-dark': '#222222',
      'medium-ui-element': '#a0a0a0',
      'strong-ui-element': '#e0e0e0',
      'light-ui-element': '#272727',
      'unread-color': '#8edff6',
      'unread-color-background': '#8edff650',
      'highlight-color': '#ffffff',
      'chat-bubble-me': '#4d3796',
      'chat-bubble': '#3b3939',
      ...sharedDarkColors,
    },
    additionalProperties: {
      ...sharedDarkAdditional,
    },
  },
];

export const colorNameDictionary = {
  'aliceblue': '#f0f8ff',
  'antiquewhite': '#faebd7',
  'aqua': '#00ffff',
  'aquamarine': '#7fffd4',
  'azure': '#f0ffff',
  'beige': '#f5f5dc',
  'bisque': '#ffe4c4',
  'black': '#000000',
  'blanchedalmond': '#ffebcd',
  'blue': '#0000ff',
  'blueviolet': '#8a2be2',
  'brown': '#a52a2a',
  'burlywood': '#deb887',
  'cadetblue': '#5f9ea0',
  'chartreuse': '#7fff00',
  'chocolate': '#d2691e',
  'coral': '#ff7f50',
  'cornflowerblue': '#6495ed',
  'cornsilk': '#fff8dc',
  'crimson': '#dc143c',
  'cyan': '#00ffff',
  'darkblue': '#00008b',
  'darkcyan': '#008b8b',
  'darkgoldenrod': '#b8860b',
  'darkgray': '#a9a9a9',
  'darkgreen': '#006400',
  'darkkhaki': '#bdb76b',
  'darkmagenta': '#8b008b',
  'darkolivegreen': '#556b2f',
  'darkorange': '#ff8c00',
  'darkorchid': '#9932cc',
  'darkred': '#8b0000',
  'darksalmon': '#e9967a',
  'darkseagreen': '#8fbc8f',
  'darkslateblue': '#483d8b',
  'darkslategray': '#2f4f4f',
  'darkturquoise': '#00ced1',
  'darkviolet': '#9400d3',
  'deeppink': '#ff1493',
  'deepskyblue': '#00bfff',
  'dimgray': '#696969',
  'dodgerblue': '#1e90ff',
  'firebrick': '#b22222',
  'floralwhite': '#fffaf0',
  'forestgreen': '#228b22',
  'fuchsia': '#ff00ff',
  'gainsboro': '#dcdcdc',
  'ghostwhite': '#f8f8ff',
  'gold': '#ffd700',
  'goldenrod': '#daa520',
  'gray': '#808080',
  'green': '#008000',
  'greenyellow': '#adff2f',
  'honeydew': '#f0fff0',
  'hotpink': '#ff69b4',
  'indianred ': '#cd5c5c',
  'indigo': '#4b0082',
  'ivory': '#fffff0',
  'khaki': '#f0e68c',
  'lavender': '#e6e6fa',
  'lavenderblush': '#fff0f5',
  'lawngreen': '#7cfc00',
  'lemonchiffon': '#fffacd',
  'lightblue': '#add8e6',
  'lightcoral': '#f08080',
  'lightcyan': '#e0ffff',
  'lightgoldenrodyellow': '#fafad2',
  'lightgrey': '#d3d3d3',
  'lightgreen': '#90ee90',
  'lightpink': '#ffb6c1',
  'lightsalmon': '#ffa07a',
  'lightseagreen': '#20b2aa',
  'lightskyblue': '#87cefa',
  'lightslategray': '#778899',
  'lightsteelblue': '#b0c4de',
  'lightyellow': '#ffffe0',
  'lime': '#00ff00',
  'limegreen': '#32cd32',
  'linen': '#faf0e6',
  'magenta': '#ff00ff',
  'maroon': '#800000',
  'mediumaquamarine': '#66cdaa',
  'mediumblue': '#0000cd',
  'mediumorchid': '#ba55d3',
  'mediumpurple': '#9370d8',
  'mediumseagreen': '#3cb371',
  'mediumslateblue': '#7b68ee',
  'mediumspringgreen': '#00fa9a',
  'mediumturquoise': '#48d1cc',
  'mediumvioletred': '#c71585',
  'midnightblue': '#191970',
  'mintcream': '#f5fffa',
  'mistyrose': '#ffe4e1',
  'moccasin': '#ffe4b5',
  'navajowhite': '#ffdead',
  'navy': '#000080',
  'oldlace': '#fdf5e6',
  'olive': '#808000',
  'olivedrab': '#6b8e23',
  'orange': '#ffa500',
  'orangered': '#ff4500',
  'orchid': '#da70d6',
  'palegoldenrod': '#eee8aa',
  'palegreen': '#98fb98',
  'paleturquoise': '#afeeee',
  'palevioletred': '#d87093',
  'papayawhip': '#ffefd5',
  'peachpuff': '#ffdab9',
  'peru': '#cd853f',
  'pink': '#ffc0cb',
  'plum': '#dda0dd',
  'powderblue': '#b0e0e6',
  'purple': '#800080',
  'rebeccapurple': '#663399',
  'red': '#ff0000',
  'rosybrown': '#bc8f8f',
  'royalblue': '#4169e1',
  'saddlebrown': '#8b4513',
  'salmon': '#fa8072',
  'sandybrown': '#f4a460',
  'seagreen': '#2e8b57',
  'seashell': '#fff5ee',
  'sienna': '#a0522d',
  'silver': '#c0c0c0',
  'skyblue': '#87ceeb',
  'slateblue': '#6a5acd',
  'slategray': '#708090',
  'snow': '#fffafa',
  'springgreen': '#00ff7f',
  'steelblue': '#4682b4',
  'tan': '#d2b48c',
  'teal': '#008080',
  'thistle': '#d8bfd8',
  'tomato': '#ff6347',
  'turquoise': '#40e0d0',
  'violet': '#ee82ee',
  'wheat': '#f5deb3',
  'white': '#ffffff',
  'whitesmoke': '#f5f5f5',
  'yellow': '#ffff00',
  'yellowgreen': '#9acd32',
};
