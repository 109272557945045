import { isObservable, Observable, take, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OpenDialogOptions } from './open-dialog-options';
import { ReturnDialogData } from './return-dialog-data';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { inject } from '@angular/core';

interface TriggerDialogCloseParams {
  closeFn: () => void;
  isCloseAllowed$: Observable<boolean> | undefined;
  dialogClosed$: Observable<any>;
}

export abstract class DialogService {
  abstract openDialog<C>(options: OpenDialogOptions<C>): Observable<ReturnDialogData<C>>;

  protected triggerDialogClose({ closeFn, isCloseAllowed$, dialogClosed$ }: TriggerDialogCloseParams): void {
    if (!isObservable(isCloseAllowed$)) {
      closeFn();
      return;
    }

    isCloseAllowed$
      .pipe(
        take(1),
        filter(isConfirmed => !!isConfirmed),
        takeUntil(dialogClosed$)
      )
      .subscribe(() => {
        closeFn();
      });
  }
}
