import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'folder' })
export class FolderPipe implements PipeTransform {
  constructor() {}

  transform(folderName: string) {
    let splitName = folderName.split('/');
    return splitName.length > 1 ? `.../${splitName[splitName.length - 1]}` : folderName;
  }
}
