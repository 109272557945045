import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssigneeTagComponent } from '@shared/modules/conversations/components/conversation-header/assignee-tag/assignee-tag.component';
import { ConversationTagModule } from '@shared/ui/conversation-tag/conversation-tag.module';
import { CustomDropdownModule } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.module';
import { AssignDropdownModule } from '@shared/modules/conversations/components/conversation-header/dropdowns/assign-dropdown/assign-dropdown.module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { UserNameModule } from '@dta/ui/pipes/user-name/user-name.module';
import { AvatarModule } from '@dta/ui/components/common/avatar/avatar.module';

@NgModule({
  declarations: [AssigneeTagComponent],
  imports: [
    CommonModule,
    AvatarModule,
    ConversationTagModule,
    CustomDropdownModule,
    UserNameModule,
    AssignDropdownModule,
    GraphicsModule,
  ],
  exports: [AssigneeTagComponent],
})
export class AssigneeTagModule {}
