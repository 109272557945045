import * as _ from 'lodash';
import { BaseModel } from './base/base.model';
import { CollectionName } from '@shared/models/constants/collection.names';
import { Integration } from '@shared/api/api-loop/models/integration';
import { IntegrationType } from '@shared/api/api-loop/models/integration-type';

export class IntegrationModel extends BaseModel implements Integration {
  static collectionName: CollectionName = CollectionName.Integration;
  static type: string = 'Integration';

  readonly $type: string = IntegrationModel.type;

  integrationType?: IntegrationType;
  iFrameURL?: string;
  pinned?: boolean;
  apiKey?: string;
  disconnected?: boolean;

  _ex?: IntegrationExtraData;

  constructor(data?: Integration) {
    super(data);
  }

  static create(doc: Integration): IntegrationModel {
    if (!doc || !doc.$type) {
      throw new Error(`Invalid $type given ${JSON.stringify(doc)}`);
    }
    if (doc instanceof IntegrationModel) {
      return doc;
    }
    return new IntegrationModel(doc);
  }

  static createList(docs: Integration[]): IntegrationModel[] {
    return _.map(docs, (doc: Integration) => new IntegrationModel(doc));
  }
}

export interface IntegrationExtraData {
  serverType: IntegrationType;
  serverId?: string;

  name: string;
  description: string;
  detailedDescription: string;
  inAppUrl: string;
  privacyPolicy: string;
  webSiteUrl: string;
  logo: string;
  type: Type[];
  disconnected?: boolean;
  detailImages?: string[];
  detailsRequired?: boolean;
  comingSoon?: boolean;

  iframeUrl?: string;
}

export enum Type {
  PLUGIN = 'Plugin',
  OTHER = 'Other',
}
