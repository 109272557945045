import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LabelsDropdownComponent } from '@shared/modules/shared-tag/components/labels-dropdown/labels-dropdown.component';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { IsLabelSelectedPipeModule } from '@shared/modules/shared-tag/pipes/is-label-selected.pipe/is-label-selected.pipe.module';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';

@NgModule({
  declarations: [LabelsDropdownComponent],
  imports: [CommonModule, TextControlModule, IconModule, FormsModule, IsLabelSelectedPipeModule, ContactModule],
  exports: [LabelsDropdownComponent],
})
export class LabelsDropdownModule {}
