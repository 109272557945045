import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FileApiService } from '@shared/api/api-loop/services/file-api.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { Observable, of } from 'rxjs';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'loop-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePreviewComponent {
  private readonly fileApiService: FileApiService = inject(FileApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  @Input() file: IFile;

  protected readonly getFileMetadataFn$: (fileId: string) => Observable<FileModel> = fileId => {
    return this.fileApiService
      .File_GetFile(
        {
          id: fileId
        },
        this.userManagerService.getCurrentUserEmail(),
        true
      )
      .pipe(
        take(1),
        untilDestroyed(this),
        map(file => new FileModel(file))
      );
  };

  protected readonly populateFileWithUrlLinkFn$: (file: IFile) => Observable<IFile> = file => {
    if (file.urlLink) {
      return of(file);
    }

    return this.fileApiService
      .File_GetFile({ id: file.id }, this.userManagerService.getCurrentUserEmail(), true)
      .pipe();
  };

  protected readonly getThumbnailFn$: (fileId: string) => Observable<string> = fileId => {
    return this.fileApiService
      .File_GetPdfThumbnailSignedLink({ id: fileId }, this.userManagerService.getCurrentUserEmail(), true)
      .pipe(take(1), untilDestroyed(this));
  };
}
