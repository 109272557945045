import { inject, Injectable } from '@angular/core';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { defer, exhaustMap, from, isObservable, Observable, of, take, takeUntil, timer } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BrowserDialogContentComponent } from './internal/browser-dialog-content.component';
import { DialogService } from '../../dialog.service';
import {
  isNewWindowDialogOptions,
  OpenDialogOptions,
  OverlayDialogOptions
} from '@shared/ui/kit/dialog/open-dialog-options';
import { ReturnDialogData } from '@shared/ui/kit/dialog/return-dialog-data';
import { isAfterDialogOpen, isBeforeDialogClose } from '@shared/ui/kit/dialog/after-dialog-open';
import { LOOP_DYNAMIC_COMPONENTS } from '@shared/modules/loop/components/loop-dynamic-components';
import { everyTrue } from '@shared/utils/rxjs/every-true';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { KeyboardActionModeType, KeyboardActionType } from '@dta/shared/models/keyboard-shortcut.model';

@Injectable()
export class BrowserDialogService extends DialogService {
  private readonly dialogModuleApi: Dialog = inject(Dialog);
  private readonly overlay: Overlay = inject(Overlay);
  protected readonly keyboardShortcutsListenerService: KeyboardShortcutsListenerService = inject(
    KeyboardShortcutsListenerService
  );

  openDialog<C>(options: OpenDialogOptions<C>): Observable<ReturnDialogData<C>> {
    return defer(() => {
      return this.getOverlayOptions(options).pipe(
        switchMap(options => {
          const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
          options.isClosable = options.isClosable === undefined ? true : options.isClosable;
          // We must use defer so the dialog isn't presented until the returned observable is subscribed
          const dialogRef = this.dialogModuleApi.open(BrowserDialogContentComponent, {
            width: options.isFullScreen ? '100%' : (options.width ?? '520px'),
            panelClass: 'loop-dialog-panel',
            backdropClass: 'loop-dialog-backdrop',
            disableClose: options.isCloseAllowed$ !== undefined ? true : !options.isClosable,
            height: options.isFullScreen ? '100%' : undefined,
            positionStrategy
          });

          const closeFn = (): void =>
            this.triggerDialogClose({
              closeFn: () => {
                dialogRef.close();
                this.keyboardShortcutsListenerService.setMode(KeyboardActionModeType.NAVIGATION);
              },
              isCloseAllowed$: options.isCloseAllowed$ ?? of(true),
              dialogClosed$: dialogRef.closed
            });

          if (dialogRef.componentInstance) {
            (dialogRef.componentInstance.options as OpenDialogOptions<C>) = options;
            dialogRef.componentInstance.dialogRef = dialogRef;
            dialogRef.componentInstance.close = () => closeFn();
          }

          return dialogRef.componentInstance!.componentRef.pipe(
            map(componentRef => {
              const callAfterDialogOpen = isAfterDialogOpen(componentRef.instance);
              if (callAfterDialogOpen) {
                // We have to wait for animation in browser-dialog-content.scss to complete after executing the function
                timer(300)
                  .pipe(take(1))
                  .subscribe(() => {
                    if (callAfterDialogOpen && componentRef.instance) {
                      componentRef.instance.afterDialogOpen();
                    }
                  });
              }

              const callBeforeDialogClose = isBeforeDialogClose(componentRef.instance);
              if (callBeforeDialogClose) {
                const isCloseAllowed$ = everyTrue(
                  options.isCloseAllowed$ ?? of(true),
                  componentRef.instance.beforeDialogClose$()
                );

                this.observeShortcutCloseEvents(dialogRef);
                this.observeNativeDialogCloseEvents(dialogRef, isCloseAllowed$);

                const closeFn = (): void =>
                  this.triggerDialogClose({
                    closeFn: () => dialogRef.close(),
                    isCloseAllowed$: isCloseAllowed$,
                    dialogClosed$: dialogRef.closed
                  });

                if (dialogRef.componentInstance) {
                  dialogRef.componentInstance.close = () => closeFn();
                }
              } else {
                this.observeShortcutCloseEvents(dialogRef);
                this.observeNativeDialogCloseEvents(dialogRef, options.isCloseAllowed$);
              }

              const returnData: ReturnDialogData<C> = {
                afterClose$: dialogRef.closed,
                componentInstance: componentRef.instance as C,
                close: () => closeFn()
              };
              return returnData;
            })
          );
        })
      );
    });
  }

  private observeShortcutCloseEvents(dialogRef: DialogRef<any, any>): void {
    this.keyboardShortcutsListenerService
      .getActionsByTypes([KeyboardActionType.TOGGLE_SHORTCUTS_DIALOG])
      .pipe(takeUntil(dialogRef.closed))
      .subscribe(() => {
        this.keyboardShortcutsListenerService.setMode(KeyboardActionModeType.NAVIGATION);
        dialogRef.close();
      });
  }

  private observeNativeDialogCloseEvents(
    dialogRef: DialogRef<any, any>,
    isCloseAllowed$: Observable<boolean> | undefined
  ): void {
    if (!isObservable(isCloseAllowed$)) {
      return;
    }

    const observeDialogCloseTriggerToClose = (closeTrigger$: Observable<any>): void => {
      closeTrigger$
        .pipe(
          exhaustMap(() => isCloseAllowed$.pipe(take(1))),
          filter(isConfirmed => !!isConfirmed),
          takeUntil(dialogRef.closed)
        )
        .subscribe(() => {
          dialogRef.close();
        });
    };

    observeDialogCloseTriggerToClose(dialogRef.backdropClick.pipe(tap(a => console.log(a))));

    observeDialogCloseTriggerToClose(dialogRef.keydownEvents.pipe(filter(event => event.code === 'Escape')));
  }

  private getOverlayOptions<C>(options: OpenDialogOptions<C>): Observable<OverlayDialogOptions<C>> {
    if (!isNewWindowDialogOptions(options)) {
      return of(options);
    }

    const component = LOOP_DYNAMIC_COMPONENTS[options.componentId];
    if (!component.component) {
      return of(null);
    }

    return from(component.component()).pipe(
      map(resolvedComponent => {
        return {
          ...options,
          isClosable: (options as OverlayDialogOptions<any>).isClosable,
          isCloseAllowed$: of(true),
          componentData: {
            component: resolvedComponent.component,
            module: resolvedComponent.module
          },
          componentParams: options.componentParams as any
        } as OverlayDialogOptions<C>;
      })
    );
  }
}
