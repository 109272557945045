import { Injectable } from '@angular/core';
import { BaseModel } from '../../../shared/models-api-loop/base/base.model';
import { Observable, of } from 'rxjs';
import { FetchResult, MultiSessionCollectionParams } from '../../../shared/models/collection.model';
import { CollectionOptions, CollectionSubscriberService, LoadMoreType } from '../collection-subscriber.service';
import { BaseCollectionService } from '../base.collection';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { AvailabilityStatusService } from '@shared/services/data/availability-status/availability-status.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AvailabilityStatusCollectionService extends BaseCollectionService<AvailabilityStatusModel> {
  protected supportedTypes: (typeof BaseModel)[] = [AvailabilityStatusModel];
  readonly loadMoreType: LoadMoreType = LoadMoreType.ONLY_QUERY;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _collectionSubscriberService: CollectionSubscriberService,
    protected _availabilityStatusService: AvailabilityStatusService,
  ) {
    super(_userManagerService, _collectionSubscriberService);
  }

  get constructorName(): string {
    return 'AvailabilityStatusCollectionService';
  }

  registerCollection(
    params: MultiSessionCollectionParams,
    options?: CollectionOptions,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<string> {
    return super.registerCollection(params, options, forUserEmail);
  }

  protected reduce(
    models: AvailabilityStatusModel[],
    params: MultiSessionCollectionParams,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<AvailabilityStatusModel[]> {
    return of(models);
  }

  protected doBeforePublish(
    models: AvailabilityStatusModel[],
    params: MultiSessionCollectionParams,
    forUserEmail: string,
  ): Observable<AvailabilityStatusModel[]> {
    return of(models);
  }

  protected doBeforeReduce(
    models: AvailabilityStatusModel[],
    params: MultiSessionCollectionParams,
    forUserEmail: string,
  ): Observable<AvailabilityStatusModel[]> {
    return of(models);
  }

  count(params: MultiSessionCollectionParams, forUserEmail: string): Observable<number> {
    throw new Error('Method not implemented.');
  }

  query(params: MultiSessionCollectionParams, forUserEmail: string): Observable<AvailabilityStatusModel[]> {
    return this._availabilityStatusService.findAllAvailabilityStatuses(forUserEmail);
  }

  fetch(params: MultiSessionCollectionParams, forUserEmail: string): Observable<FetchResult> {
    return this._availabilityStatusService.syncAvailabilityStatuses(forUserEmail).pipe(
      map((statuses: AvailabilityStatusModel[]) => {
        let fetchResult: FetchResult = {
          offsetHistoryId: undefined,
          dataLength: statuses?.length || 0,
          hasData: !!statuses,
        };

        return fetchResult;
      }),
    );
  }
}
