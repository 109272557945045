import { Injectable } from '@angular/core';
import { WatchdogService } from '@shared/services/watchdog/watchdog.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { LogService } from '@shared/api/log-loop/api.module';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { HttpEventService } from '@shared/interceptors/http-event.service';
import { DataServiceShared } from '@shared/services/data/data.service';
import { ElectronService } from '@shared/services/electron/electron';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { FlushDatabaseService } from '@shared/services/flush-db/flush-db.service';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { LogSynchronizationService } from '@shared/synchronization/log-synchronization/log-synchronization.service';
import { PushSynchronizationModuleService } from '@shared/synchronization/push-synchronization/push-synchronization.module';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { SynchronizationStatusService } from '@shared/synchronization/synchronization-status.service';
import { SynchronizationFactory } from '@shared/synchronization/synchronization.factory';
import { SynchronizationService } from '@shared/synchronization/synchronization.service';
import { LogSynchronizationServiceWeb } from './log-synchronization/log-synchronization.service.web';
import { SynchronizationServiceWeb } from './synchronization.service.web';
import { PushSyncDaoService } from '@shared/database/dao/push-sync/push-sync-dao.service';
import { Time } from '@dta/shared/utils/common-utils';
import { filter, Subscription, tap } from 'rxjs';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { UserSwitch } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

@Injectable()
export class SynchronizationFactoryWeb extends SynchronizationFactory {
  private userSwitchSub: Subscription;

  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _watchdogService: WatchdogService,
    protected _storageService: StorageService,
    protected _fileStorageService: FileStorageService,
    protected _status: SynchronizationStatusService,
    protected _notificationsService: NotificationsService,
    protected _trackingService: TrackingService,
    protected _dataService: DataServiceShared,
    protected _apiService: ApiService,
    protected _electronService: ElectronService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _settingsService: SettingsService,
    protected _contactStoreFactory: ContactStoreFactory,
    protected _httpResponseEventService: HttpEventService,
    protected _pushSynchronizationModuleService: PushSynchronizationModuleService,
    protected _flushDatabaseService: FlushDatabaseService,
    protected _logService: LogService,
    protected _databaseFactoryWeb: DatabaseFactory,
    protected _pushSyncDaoService: PushSyncDaoService,
    protected _time: Time,
    private _userManagerService: UserManagerService,
  ) {
    super(
      _syncMiddleware,
      _watchdogService,
      _storageService,
      _fileStorageService,
      _status,
      _notificationsService,
      _trackingService,
      _dataService,
      _apiService,
      _electronService,
      _sharedUserManagerService,
      _settingsService,
      _contactStoreFactory,
      _httpResponseEventService,
      _pushSynchronizationModuleService,
      _flushDatabaseService,
      _logService,
      _databaseFactoryWeb,
      _time,
    );
    this.subscribeToUserSwitch();
  }

  get constructorName(): string {
    return 'SynchronizationFactoryWeb';
  }

  protected initSynchronizationService(userEmail: string): SynchronizationService {
    return new SynchronizationServiceWeb(
      userEmail,
      this._watchdogService,
      this._storageService,
      this._fileStorageService,
      this._status,
      this._notificationsService,
      this._trackingService,
      this._dataService,
      this._apiService,
      this._electronService,
      this._sharedUserManagerService,
      this._settingsService,
      this._pushSynchronizationModuleService,
      this._syncMiddleware,
      this._flushDatabaseService,
      this._databaseFactory,
      this._time,
      this._pushSyncDaoService,
    );
  }

  protected initLogSynchronizationService(): LogSynchronizationService {
    return new LogSynchronizationServiceWeb(this._watchdogService, this._logService, this._storageService);
  }

  private subscribeToUserSwitch() {
    this.userSwitchSub?.unsubscribe();
    this.userSwitchSub = this._userManagerService.userSwitch$
      .pipe(
        // Initial data can be null
        filter((userSwitch: UserSwitch) => !!userSwitch.newUserEmail),
        tap((userSwitch: UserSwitch) => {
          this.initUserSwitchHandler(userSwitch.newUserEmail);
        }),
      )
      .subscribe();
  }
}
