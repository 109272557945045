import {
  ComponentRef,
  createNgModule,
  Directive,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Type,
  ViewContainerRef
} from '@angular/core';
import { writeComponentRefChanges } from '@shared/utils/write-component-ref-changes';

@Directive({
  selector: '[loopModalFormContent]'
})
export class ModalFormContentDirective<T> implements OnInit {
  @Input() loopModalFormContent!: Type<T>;
  @Input() loopModalFormContentModule?: Type<any>;
  @Input() loopModalFormContentParams?: Partial<T>;
  @Input() loopModalFormContentInjector?: Injector;
  @Output() componentRef: EventEmitter<ComponentRef<T>> = new EventEmitter<ComponentRef<T>>();

  constructor(
    public viewContainerRef: ViewContainerRef,
    private readonly injector: Injector
  ) {}

  ngOnInit(): void {
    const componentRef = this.viewContainerRef.createComponent(this.loopModalFormContent, {
      ngModuleRef: this.loopModalFormContentModule
        ? createNgModule(this.loopModalFormContentModule, this.loopModalFormContentInjector ?? this.injector)
        : undefined,
      injector: this.loopModalFormContentInjector ?? this.injector
    });
    if (this.loopModalFormContentParams) {
      writeComponentRefChanges(componentRef, this.loopModalFormContentParams as Partial<T>);
    }
    this.componentRef.emit(componentRef);
  }
}
