import { Component, Input } from '@angular/core';
import { RedirectService } from '@shared/services/redirect/redirect.service';
import { GroupModel } from '../../../../../../../dta/shared/models-api-loop/contact/contact.model';

@Component({
  selector: 'create-successful',
  styleUrls: ['./create-successful.scss'],
  template: `
    <div class="content">
      <span> {{ hasInvitees ? 'Success, your invites have been sent' : successTitle }}</span>

      <svg-comp [src]="createSuccess"></svg-comp>

      <button
        class="btn button primary-green"
        *ngIf="ctaCopy"
        id="take-me-to-si"
        (keyup.enter)="openSharedInbox()"
        (click)="openSharedInbox()"
      >
        {{ ctaCopy }}
      </button>

      <ng-content select="extra-content"></ng-content>

      <div></div>
    </div>
  `,
  host: { class: 'main' },
})
export class CreateSuccessfulComponent {
  createSuccess = require('shared/assets/img/svg/create-success.svg');

  @Input() channel: GroupModel;
  @Input() hasInvitees: boolean;
  @Input() successTitle: string;
  @Input() ctaCopy: string;

  constructor(private _redirectService: RedirectService) {}

  openSharedInbox() {
    this._redirectService.quickJump(this.channel);
  }
}
