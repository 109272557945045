import { Component } from '@angular/core';
import { EmptyStateSpecialBaseComponent } from '../empty-state-special-base.component';

@Component({
  selector: 'teams-empty-state',
  templateUrl: './teams-empty-state.html',
  styleUrls: ['../empty-state.scss'],
})
export class TeamsEmptyStateComponent extends EmptyStateSpecialBaseComponent {
  illustrationSvg = require('@shared/assets/img/svg/illustration-teams.svg');

  ctaClick() {
    this._redirectService.navigateTo('/user-settings/teams/create');
  }
}
