import { Directive, OnDestroy } from '@angular/core';

@Directive()
export abstract class KeyboardActions implements OnDestroy {
  ////////////////////
  // State properties
  ////////////////////
  protected keyboardActions = {};

  constructor() {
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  //////////////////////
  // Life cycle methods
  //////////////////////
  ngOnDestroy() {
    this.removeKeyboardListener();
  }

  ///////////////////
  // Protected methods
  ///////////////////
  protected handleKeyDown(event: KeyboardEvent) {
    if (this.keyboardActions[event.key]) {
      event.preventDefault();
      event.stopPropagation();

      this.keyboardActions[event.key]();
    }
  }

  protected addKeyboardListener() {
    window.addEventListener('keydown', this.handleKeyDown, { capture: true });
  }

  protected removeKeyboardListener() {
    window.removeEventListener('keydown', this.handleKeyDown, { capture: true });
  }
}
