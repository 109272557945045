import * as _ from 'lodash';
import { v1 } from 'uuid';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { CollectionGroupName, CollectionName } from '@shared/models/constants/collection.names';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

export abstract class BaseModel implements ResourceBase {
  static collectionName: CollectionName | CollectionGroupName;
  static type: string;
  static resourceBaseFieldNames: string[] = ['_id', 'id', 'clientId', 'name', 'revision', '$type', '_synced'];
  static idPrefix: string = '_brisket_';

  _synced: boolean; // true when BE id is set
  _id: string; // local ID

  // ResourceBase
  id?: string; // api id
  clientId?: string;
  name?: string;
  revision?: string;
  $type: string;

  // all models should have created date, most interfaces have it
  created: string;

  // optional extra fields
  _ex?: any;
  _ui?: any;

  constructor(data?: ResourceBase) {
    if (data) {
      this.validateTypeOrThrow(data);
      _.assign(this, data);
    }

    this._synced = !!this.id;
    this._setId();
  }

  static isEqual(a: BaseModel, b: BaseModel): boolean {
    if (!a || !b) {
      return false;
    }

    // _id
    if (a._id && a._id === b._id) {
      return true;
    }

    // Id
    return a.id && a.id === b.id;
  }

  static mergeList<T extends BaseModel>(aList: T[], bList: T[]): T[] {
    return _.unionWith(aList, bList, this.isEqual) as T[];
  }

  static createListOfResources<T>(resources: Array<ResourceBase | string | number> = []): T {
    return <any>{
      resources: resources,
      offset: 0,
      size: resources.length,
      totalSize: resources.length,
    };
  }

  static buildAsReference<T extends ResourceBase>(model: BaseModel): T {
    if (!model) {
      return undefined;
    }

    return <any>{
      $type: model.$type === 'Conversation' ? (model as ConversationModel).cardType : model.$type,
      _id: model._id,
      id: model.id,
      clientId: model.clientId,
      name: model.name,
      revision: model.revision,
    };
  }

  static buildFromBaseAsReference<T extends ResourceBase>(resource: ResourceBase): T {
    let _id = resource['_id'] || resource.clientId || resource.id;
    if (!_id) {
      throw new Error('_id could not be determined from ResourceBase');
    }

    return <any>{
      $type: resource.$type,
      _id: _id,
      id: resource.id,
      clientId: resource.clientId,
      name: resource.name,
      revision: resource.revision,
    };
  }

  static sortListByCreated(resources: ResourceBase[]): ResourceBase[] {
    return _.sortBy(resources, resource => new Date(resource['created']).getTime());
  }

  toResourceBase(): ResourceBase {
    return <ResourceBase>_.pick(this.cloneDeep(), BaseModel.resourceBaseFieldNames);
  }

  clone<T extends BaseModel>(): T {
    return _.clone(<any>this);
  }

  // expose it when needed
  cloneDeep<T extends BaseModel>(): T {
    return _.cloneDeep(<any>this);
  }

  toObject(): ResourceBase {
    this.validateTypeOrThrow();

    return BaseModel.toObject(this, true) as ResourceBase;
  }

  static toObject(inputObject: Object, removeUndefinedValues: boolean = false): Object {
    // Process only complex objects (not strings, numbers, ...)
    if (!_.isObject(inputObject) && !_.isArray(inputObject)) {
      return inputObject;
    }

    // Reduce first level
    let object = BaseModel.omitFunctionsOnObject(inputObject, removeUndefinedValues);

    // Remove UI data
    delete object['_ui'];

    // Perform toObject in depth
    for (let prop in object) {
      let propName = prop.toString();

      // Handle array
      if (_.isArray(object[propName])) {
        object[propName] = _.map(object[propName], val => BaseModel.toObject(val) as ResourceBase);
      }

      // Handle object
      if (_.isObject(object[propName]) && !_.isArray(object[propName])) {
        object[propName] = BaseModel.toObject(object[propName]) as ResourceBase;
      }
    }

    return object as Object;
  }

  private static omitFunctionsOnObject(inputObject: Object, removeUndefinedValues: boolean = false): Object {
    return _.omitBy(inputObject, value => (removeUndefinedValues && _.isNil(value)) || _.isFunction(value)) as Object;
  }

  private _setId() {
    // this._id = this._id || this.clientId || this.id || this._generateId();
    this._id = this._getId() || this._generateId();

    if (!this.id && !this.clientId) {
      this.clientId = this._id;
    }
  }

  protected _getId(resource: ResourceBase = this): string {
    return (<BaseModel>resource)._id || resource.clientId || resource.id;
  }

  protected _generateId(): string {
    // prefix id so we can identify all resources that originated from our app
    return BaseModel.idPrefix + v1();
  }

  static isRevisionGreaterThan(a: ResourceBase, b: ResourceBase): boolean {
    return BaseModel.compareRevisions(a, b) > 0;
  }

  static isRevisionGreaterOrEqualThan(a: ResourceBase, b: ResourceBase): boolean {
    return BaseModel.compareRevisions(a, b) >= 0;
  }

  static compareRevisions(a: ResourceBase, b: ResourceBase): number {
    if (!a || !b) {
      throw new Error('Cannot compare revisions, input is nil ' + !a ? 'a' : 'b');
    }

    let aRevision = a.revision ? parseInt(a.revision, 10) : 0;
    let bRevision = b.revision ? parseInt(b.revision, 10) : 0;

    if (aRevision > bRevision) {
      return 1;
    }
    if (aRevision < bRevision) {
      return -1;
    }
    return 0;
  }

  bumpRevision() {
    this.revision = BaseModel.bumpSomeRevision(this.revision);
  }

  static bumpSomeRevision(revision) {
    return revision ? (parseInt(revision, 10) + 1).toString() : '0';
  }

  private validateTypeOrThrow(data?: ResourceBase) {
    let type = this.constructor['type'];
    let $type = data ? data.$type : this.$type;

    if ($type !== type) {
      throw new Error(`Invalid $type for ${this.constructor.name} (BaseModel), should be ${type} but is ${$type}`);
    }
  }

  getResources(listOfResources: ListOfResourcesOfResourceBase): ResourceBase[] {
    if (!listOfResources || _.isEmpty(listOfResources.resources)) {
      return [];
    }

    return listOfResources.resources;
  }

  // Delete all properties except 'id' and '$type'.
  // This is used for striping object's child elements
  // to reduced form and using join when getting from DB.
  static toReducedForm(model: any) {
    // Don't reduce models that are not synced
    if (!model.id) {
      return;
    }
    for (let prop in model) {
      if (prop !== 'id' && prop !== '$type') {
        delete model[prop];
      }
    }
  }

  getViewDate(): string {
    throw Error('View date not supported');
  }
}

export interface ListOfResourcesOfResourceBase {
  resources?: ResourceBase[];
  size: number;
  offset: number;
  totalSize: number;
}
