import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DropdownComponent } from '@dta/ui/components/common/dropdown/dropdown.component';
import { TooltipAlignment, TooltipPlacement } from '@dta/ui/components/common/tooltip/tooltip.component';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { ContactType, GroupSubType, GroupType } from '@shared/api/api-loop/models';
import { mergeMap, Subject, take } from 'rxjs';
import { GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { KeyboardActionType, KeyboardShortcutData } from '@dta/shared/models/keyboard-shortcut.model';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'move-to-team',
  templateUrl: './move-to-team.component.html',
  styleUrls: ['./move-to-team.component.scss'],
})
export class MoveToTeamComponent extends DropdownComponent implements OnInit, OnDestroy {
  TooltipPlacement = TooltipPlacement;
  TooltipAlignment = TooltipAlignment;
  GroupType = GroupType;
  GroupSubType = GroupSubType;
  ContactType = ContactType;

  /////////
  // SVGs
  /////////
  searchSvg = require('shared/assets/img/svg/search.svg');

  folderSearchQuery: string = '';
  searchQuery$: Subject<string> = new Subject();
  handledKeyboardActions: KeyboardActionType[] = [KeyboardActionType.MOVE_TO_TEAM];
  destroy$: Subject<void> = new Subject<void>();
  currentIndexEvent$: Subject<string> = new Subject();

  @Input() conversation: ConversationModel;

  constructor(
    protected _elementRef: ElementRef,
    protected _changeDetection: ChangeDetectorRef,
    protected _keyboardShortcutsListenerService: KeyboardShortcutsListenerService,
    private _conversationService: ConversationService,
    private _userManagerService: UserManagerService,
  ) {
    super(_elementRef, _changeDetection, _keyboardShortcutsListenerService);
  }

  ngOnInit(): void {
    this.registerKeyboardShortcuts();
  }

  handleInputChange(): void {
    this.searchQuery$.next(this.folderSearchQuery);
  }

  private registerKeyboardShortcuts() {
    this._keyboardShortcutsListenerService
      .getActionsByTypes(this.handledKeyboardActions)
      .pipe(
        tap((action: KeyboardShortcutData) => {
          if (action.action.type === KeyboardActionType.MOVE_TO_TEAM) {
            this.openDropdown();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected increaseSelectionIndex() {
    this.currentIndexEvent$.next('DOWN');
  }

  protected decreaseSelectionIndex() {
    this.currentIndexEvent$.next('UP');
  }

  moveToTeam(contact: GroupModel) {
    this._conversationService
      .findOrFetchByCardId(this._userManagerService.getCurrentUserEmail(), this.conversation.id)
      .pipe(
        mergeMap((conversation: ConversationModel) => {
          return this._conversationService.moveToTeam(
            this._userManagerService.getCurrentUserEmail(),
            conversation,
            contact,
          );
        }),
      )
      .pipe(take(1))
      .subscribe();

    this.closeDropdown();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
    this.currentIndexEvent$.complete();
  }
}
