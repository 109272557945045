import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'getting-started-card',
  styleUrls: ['./getting-started-card.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ transform: 'scale(1.15)', opacity: 0 }),
        animate('220ms', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
    ]),
  ],
  template: `
    <div class="getting-started-card">
      <!-- Title -->
      <div class="title">{{ title }}</div>

      <!-- Done -->
      <div *ngIf="done; else plus_cta" @inOutAnimation class="done">
        <svg-comp [src]="checkMarkSvg"></svg-comp>
      </div>

      <!-- Cta -->
      <ng-template #plus_cta>
        <div class="plus-cta" (click)="onCtaClick()">
          <svg-comp [src]="plusSvg"></svg-comp>
        </div>
      </ng-template>

      <!-- Description -->
      <div *ngIf="!done" class="description">{{ description }}</div>

      <!-- Footer CTA -->
      <div *ngIf="done; else learn_more" @inOutAnimation class="footer-cta" (click)="onCtaClick()">
        {{ addMore }}
      </div>

      <ng-template #learn_more>
        <div *ngIf="learnMore" [id]="learnMoreId" class="footer-cta" (click)="onLearnMoreClick()">Learn more</div>
      </ng-template>
    </div>
  `,
})
export class GettingStartedCardComponent {
  plusSvg = require('shared/assets/img/svg/plus-icon.svg');
  checkMarkSvg = require('shared/assets/img/svg/checkmark.svg');

  @Input() title: string;
  @Input() description: string;
  @Input() addMore: string = 'Add more';
  @Input() learnMore: boolean;
  @Input() done: boolean;

  @Output() onLearnMoreClick$ = new EventEmitter<void>();
  @Output() onCtaClick$ = new EventEmitter<void>();

  get learnMoreId(): string {
    return `learn-more-${this.title.toLowerCase().replace(/\s/g, '-')}`;
  }

  onLearnMoreClick() {
    this.onLearnMoreClick$.emit();
  }

  onCtaClick() {
    this.onCtaClick$.emit();
  }
}
