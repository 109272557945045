import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { Logger } from '@shared/services/logger/logger';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { RedirectService } from '@shared/services/redirect/redirect.service';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { CardService } from '@shared/services/data/card/card.service';
import { SubscriptionStatusUpdate } from '@shared/enums/enums';
import { GroupType, TagType } from '@shared/api/api-loop/models';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { SubscriptionHelperService } from '@shared/services/subscription-helper/subscription-helper.service';
import { ConversationActionService } from '@shared/services/data/conversation-action/conversation-action.service';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { ClickTrackingLocation } from '@dta/shared/services/tracking/tracking.constants';
import { SubscriptionState } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { ContactModel, GroupModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import {
  SelectDropdownComponent,
  SelectDropdownOptions
} from '@dta/ui/components/common/select-dropdown/select-dropdown.component';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogService } from '../../../common/confirm-dialog/confirm-dialog.service';

@UntilDestroy()
@Component({
  selector: 'header-dropdown',
  styleUrls: ['./header-dropdown.scss'],
  templateUrl: './header-dropdown.html'
})
export class HeaderDropdownComponent implements OnInit, OnChanges, OnDestroy {
  ////////
  // SVGs
  ////////
  private readonly settingsSvg: string = require('@shared/assets/img/svg/header-settings.svg');
  private readonly favoriteFullSvg: string = require('@shared/assets/img/svg/favorite-medium-full.svg');
  private readonly favoriteEmptySvg: string = require('@shared/assets/img/svg/favorite-medium-empty.svg');
  private readonly unmuteSvg: string = require('@shared/assets/img/svg/follow.svg');
  private readonly muteSvg: string = require('@shared/assets/img/svg/unfollow.svg');
  private readonly userSvg: string = require('@shared/assets/img/svg/user-header-icon.svg');
  private readonly editTeamSvg: string = require('@shared/assets/img/svg/icons-people.svg');
  private readonly deleteSvg: string = require('@shared/assets/img/svg/header-leave-team.svg');
  private readonly checkmarkSvg: string = require('@shared/assets/img/svg/shortcuts/mark-read.svg');

  ///////////////////
  // INPUT / OUTPUT
  ///////////////////
  @Input() channel: ContactModel;
  @Input() title: string;
  @Input() isOnChannel: boolean;
  @Input() trackingLocation: string;
  @Input() additionalDropdownOpenTarget: any;

  ////////////////////
  // State variables
  ////////////////////
  dropdownOptions: SelectDropdownOptions = { options: [] };
  private channelChatSubscriptionStatus: SubscriptionState;
  private currentUser: UserModel;
  private isFavorite: boolean;

  private readonly leaveTeamResponseSubject: Subject<boolean> = new Subject<boolean>();

  ////////////////
  // View queries
  ////////////////
  @ViewChild('channelDropdown', { static: false }) channelDropdown: SelectDropdownComponent;

  constructor(
    private _router: Router,
    private _userManagerService: UserManagerService,
    private _redirectService: RedirectService,
    private _webOnboardingService: WebOnboardingService,
    private _contactService: ContactService,
    private _notificationsService: NotificationsService,
    private _trackingService: TrackingService,
    private _cardService: CardService,
    private _subscriptionHelperService: SubscriptionHelperService,
    private _confirmDialogService: ConfirmDialogService,
    private _elementRef: ElementRef,
    private _changeDetection: ChangeDetectorRef,
    private readonly conversationActionService: ConversationActionService
  ) {}

  //////////////////////
  // Life cycle methods
  //////////////////////
  ngOnInit(): void {
    this.subscribeToUserSwitch();
    this.subscribeToRouterEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.channel) {
      // For some reason sometimes 'this.channel' is set to []
      // Add logging to debug and handle to not break for user
      if (Array.isArray(this.channel)) {
        Logger.customLog(
          `[SHOW THIS TO BLAZKA!]` +
            `${this.constructorName}:ngOnChanges this.channel is array: ${JSON.stringify(this.channel)} ` +
            `Other parameters: title:${this.title}, isOnChannel:${this.isOnChannel}, trackingLocation:${this.trackingLocation}` +
            `Will try to recover.`,
          LogLevel.WARN,
          LogTag.INTERESTING_ERROR,
          true
        );
      }

      if (this.channel && !Array.isArray(this.channel)) {
        this.isFavorite = this.channel?.hasTagType(TagType.FAVORITE);
      }
    }
  }

  ///////////
  // Getters
  ///////////
  get constructorName(): string {
    return 'HeaderDropdownComponent';
  }

  get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  private get isGroup(): boolean {
    return this.channel?.$type === 'Group';
  }

  private get isNormalGroup(): boolean {
    if (!(this.channel instanceof GroupModel)) {
      return false;
    }

    return this.isGroup && this.channel.groupType === GroupType.NORMAL;
  }

  private get isPersonalInbox(): boolean {
    if (!(this.channel instanceof GroupModel)) {
      return false;
    }

    return this.isGroup && this.channel.groupType === GroupType.PERSONAL_INBOX;
  }

  private get isOnlyAdmin(): boolean {
    if (!(this.channel instanceof GroupModel)) {
      return false;
    }

    const admins = this.channel.admins?.resources;
    const admin = _.find(<ContactModel[]>admins, {
      id: this.currentUser.id
    });

    return admin && admins.length === 1;
  }

  private get isCurrentChannelSubscribed(): boolean {
    return this.channel && this.channel.$type === GroupModel.type && (<GroupModel>this.channel).subscribed;
  }

  private get isCurrentChannelsChatSubscribed(): boolean {
    return this.channelChatSubscriptionStatus === SubscriptionState.SUBSCRIBED;
  }

  ////////////////
  // View methods
  ////////////////
  onDropdownSelect(option: string): void {
    switch (option) {
      case DropdownOption.FOLLOW:
      case DropdownOption.UNFOLLOW:
        this.updateSubscriptionStatus(option);
        break;
      case DropdownOption.REMOVE_FROM_FAVORITES:
        this.setFavorite(false);
        break;
      case DropdownOption.ADD_TO_FAVORITES:
        this.setFavorite(true);
        break;
      case DropdownOption.MARK_ALL_AS_READ:
        this.conversationActionService
          .markAllInViewAsRead(this.currentUserEmail, {
            offset: 0,
            size: 0,
            showInView: {
              view: 'Channel',
              filter: 'Inbox',
              channelId: this.channel?.id
            }
          })
          .pipe(take(1), untilDestroyed(this))
          .subscribe();
        break;
      case DropdownOption.EDIT_MEMBERS:
        this.viewTeamMembers();
        break;
      case DropdownOption.SETTINGS:
        this.navigateSettings();
        break;
      case DropdownOption.LEAVE_TEAM:
        this.openConfirmLeaveTeamDialog();
        break;
      case this.channel?.email:
        this._redirectService.quickJump(<UserModel>this.channel, 'info', false);
        break;
      default:
        break;
    }
  }

  ///////////////////
  // Private helpers
  ///////////////////
  toggleDropdownFromParent(yPosition): void {
    if (this.channelDropdown.open) {
      this.channelDropdown.closeDropdown();
      return;
    }

    this.setChannelChatSubscriptionStatus()
      .pipe(
        untilDestroyed(this),
        take(1),
        tap(() => {
          this.setDropdownOptions();
          this.channelDropdown.openDropdown();

          let wrapper = this.channelDropdown.optionsWrapper.nativeElement;
          // Height is not initialized on first open
          let clientHeight = wrapper.clientHeight < 60 ? 170 : wrapper.clientHeight;

          if (document.body.scrollHeight - 50 < yPosition + clientHeight) {
            wrapper.style.setProperty('transform', 'translate(0, -100%)', 'important');
            yPosition -= 30;
          } else {
            wrapper.style.setProperty('transform', 'unset', 'important');
          }
          this._elementRef.nativeElement.style.position = `absolute`;
          this._elementRef.nativeElement.style.top = `${yPosition}px`;
          this._changeDetection.detectChanges();
        })
      )
      .subscribe();
  }

  private navigateSettings(): void {
    if (this.isOnChannel) {
      this.editTeamWebSettings();
      return;
    }

    if (this._router.url.startsWith('/user-settings')) {
      this._redirectService.navigateToInbox();
    } else {
      this._router.navigateByUrl('/user-settings');
    }
  }

  private viewTeamMembers(): void {
    this._webOnboardingService.openWebSettings(
      new URLSearchParams({ tab: 'members' }),
      `/teams/${this.channel.id}`,
      window.open()
    );

    this.trackUserClick('Edit members');
  }

  // tslint:disable-next-line:member-ordering
  private setFavorite: any = _.throttle((value: boolean) => {
    // Change state on UI
    this.isFavorite = value;

    let obs = value
      ? this._contactService.favoriteContact(this.currentUserEmail, this.channel.id)
      : this._contactService.unfavoriteContact(this.currentUserEmail, this.channel.id);

    obs
      .pipe(
        take(1),
        untilDestroyed(this),
        tap(contact => {
          this.channel = contact;

          this._trackingService.trackFavorite(this.currentUserEmail, value);

          if (value) {
            this._notificationsService.setInAppNotification(this.currentUserEmail, {
              type: NotificationEventType.ContactAddedToFavorites,
              msg: `${this.trimTextForNotification(this.channel.name)} added to favorites.`
            });
          } else {
            this._notificationsService.setInAppNotification(this.currentUserEmail, {
              type: NotificationEventType.ContactRemovedFromFavorites,
              msg: `${this.trimTextForNotification(this.channel.name)} removed from favorites.`
            });
          }
        })
      )
      .subscribe();
  }, 200);

  private updateSubscriptionStatus(option: DropdownOption): void {
    let subscriptionStatusUpdate: SubscriptionStatusUpdate;
    switch (option) {
      case DropdownOption.FOLLOW:
        subscriptionStatusUpdate = SubscriptionStatusUpdate.INBOX;
        break;
      case DropdownOption.UNFOLLOW:
        subscriptionStatusUpdate = SubscriptionStatusUpdate.UNSUBSCRIBE;
        break;
    }
    this._subscriptionHelperService
      .ensureSubscriptionStateOfChatCardAndTeam(
        this.currentUserEmail,
        this.trackingLocation,
        this.channel as GroupModel,
        subscriptionStatusUpdate
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private openConfirmLeaveTeamDialog(): void {
    let data = {
      open: true,
      noCancelButton: false,
      closeButton: false,
      subject: this.leaveTeamResponseSubject,
      completeResponse: false,
      title: `Leave ${this.channel.name} team?`,
      text: 'You will stop receiving messages from this team and people will be notified that you left.',
      confirmButtonText: 'Leave',
      confirmButtonDanger: true
    };

    this.subscribeToDialogConfirm();
    this._confirmDialogService.toggle.next(data);
  }

  private editTeamWebSettings(): void {
    this._webOnboardingService.openWebSettings(undefined, `/teams/${this.channel.id}`, window.open());

    this.trackUserClick('Team Settings');
  }

  private leaveGroup(): void {
    if (!(this.channel instanceof GroupModel)) {
      return;
    }

    this._contactService
      .leaveGroup(this._userManagerService.getCurrentUserEmail(), this.channel)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._redirectService.navigateToDefaultView();
      });
  }

  private setDropdownOptions(): void {
    this.dropdownOptions = { options: [] };

    if (this.title === 'Loop inbox' || this.title === 'Inbox') {
      this.dropdownOptions.options = [
        {
          option: DropdownOption.SETTINGS,
          svgIcon: this.settingsSvg
        }
      ];
      return;
    }

    if (this.isOnChannel) {
      this.dropdownOptions.options = [
        ...(this.isGroup
          ? [
              ...(this.isPersonalInbox
                ? []
                : [
                    {
                      option: DropdownOption.EDIT_MEMBERS,
                      svgIcon: this.editTeamSvg
                    }
                  ]),
              ...[...(!this.isNormalGroup ? [...this.getSubscriptionOptions()] : [])]
            ]
          : []),

        {
          option: DropdownOption[this.isFavorite ? 'REMOVE_FROM_FAVORITES' : 'ADD_TO_FAVORITES'],
          svgIcon: this.isFavorite ? this.favoriteEmptySvg : this.favoriteFullSvg
        },

        {
          option: DropdownOption.MARK_ALL_AS_READ,
          svgIcon: this.checkmarkSvg
        },

        ...(this.channel?.email
          ? [
              {
                option: this.channel.email,
                svgIcon: this.userSvg
              }
            ]
          : []),

        ...(!this.isOnlyAdmin && this.isGroup && !this.isPersonalInbox
          ? [
              {
                option: DropdownOption.LEAVE_TEAM,
                svgIcon: this.deleteSvg
              }
            ]
          : []),

        ...(this.isGroup
          ? [
              {
                option: DropdownOption.SETTINGS,
                svgIcon: this.settingsSvg
              }
            ]
          : [])
      ];
    }
  }

  private getSubscriptionOptions(): { option: DropdownOption; svgIcon: string }[] {
    if (!(this.channel instanceof GroupModel)) {
      return [];
    }
    if (this.isCurrentChannelSubscribed) {
      return [
        {
          option: DropdownOption.UNFOLLOW,
          svgIcon: this.muteSvg
        }
      ];
    }

    return [
      {
        option: DropdownOption.FOLLOW,
        svgIcon: this.unmuteSvg
      }
    ];
  }

  private trimTextForNotification(text: string, length: number = 43): string {
    return text.length > length ? `${text.substring(0, length - 3)}...` : text;
  }

  private trackUserClick(action: string): void {
    this._trackingService.trackUserClick(
      this._userManagerService.getCurrentUserEmail(),
      ClickTrackingLocation.ChannelHeader,
      action
    );
  }

  private setChannelChatSubscriptionStatus(): Observable<any> {
    if (!this.isGroup) {
      this.channelChatSubscriptionStatus = SubscriptionState.UNSUBSCRIBED;
      return of(undefined);
    }

    return this._cardService.getChatCardSubscriptionStatus(this.currentUserEmail, this.channel.id).pipe(
      tap((chatCardSubscribeStatus: SubscriptionState) => {
        this.channelChatSubscriptionStatus = chatCardSubscribeStatus;
      })
    );
  }

  ///////////////
  // Subscribers
  ///////////////
  private subscribeToDialogConfirm(): void {
    this.leaveTeamResponseSubject.pipe(untilDestroyed(this)).subscribe(response => {
      if (response) {
        this.leaveGroup();
      }
    });
  }

  private subscribeToRouterEvents(): void {
    this._router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        untilDestroyed(this),
        catchError(err => {
          Logger.customLog(`Error in ${this.constructorName}:subscribeToRouterEvents(): ${err}`, LogLevel.ERROR);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.channelDropdown?.closeDropdown();
      });
  }

  private subscribeToUserSwitch(): void {
    this._userManagerService.userSwitch$.pipe(untilDestroyed(this)).subscribe(() => {
      this.currentUser = this._userManagerService.getCurrentUser();
    });
  }

  ngOnDestroy(): void {
    this.leaveTeamResponseSubject.complete();
  }
}

enum DropdownOption {
  REMOVE_FROM_FAVORITES = 'Remove from favorites',
  ADD_TO_FAVORITES = 'Add to favorites',
  MARK_ALL_AS_READ = 'Mark all as read',
  EDIT_MEMBERS = 'Edit members',
  SETTINGS = 'Settings',
  LEAVE_TEAM = 'Leave team',
  FOLLOW = 'Follow',
  UNFOLLOW = 'Unfollow'
}
