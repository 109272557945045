import { forkJoin, map, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { mergeMap, tap } from 'rxjs/operators';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { UserflowEventName } from '../userflow/userflow.constants';
import { SubscriptionState } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { SubscriptionStatusUpdate } from '@shared/enums/enums';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { NotificationsService } from '../notification/notification.service';
import { UserflowService } from '../userflow/userflow.service';
import { TagService } from '../data/tag/tag.service';
import { SubscriptionService } from '../data/subscription/subscription.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import * as _ from 'lodash';

@Injectable()
export class SubscriptionHelperService {
  constructor(
    private _conversationService: ConversationService,
    private _trackingService: TrackingService,
    private _notificationsService: NotificationsService,
    private _userflowService: UserflowService,
    private _tagService: TagService,
    private _subscriptionService: SubscriptionService,
  ) {}

  get constructorName(): string {
    return 'SubscriptionHelperService';
  }

  toggleSubscribeOfTeam(
    forUserEmail: string,
    contact: ContactModel,
    showNotification: boolean = true,
    forceSubscribeOption?: boolean,
  ): Observable<ContactModel> {
    if (!contact || contact.$type !== GroupModel.type) {
      return of(undefined);
    }

    // Toggle state for UI
    let subscribe = !(<GroupModel>contact).subscribed;
    (<GroupModel>contact).subscribed = !(<GroupModel>contact).subscribed;
    subscribe = forceSubscribeOption ?? subscribe;

    // Sync to BE
    return this._subscriptionService
      .changeSubscribeStatusOfTeam(forUserEmail, subscribe ? 'subscribe' : 'unsubscribe', contact.id)
      .pipe(
        tap((_contact: ContactModel) => {
          // Track action
          this._trackingService.trackSubscribeStatusChange(forUserEmail, subscribe ? 'subscribe' : 'unsubscribe');

          // Show toast message
          if (showNotification) {
            this._notificationsService.setInAppNotification(forUserEmail, {
              type: subscribe ? NotificationEventType.ContactUnmuted : NotificationEventType.ContactMuted,
              msg: subscribe ? 'Channel Subscribed' : 'Channel Unsubscribed',
            });
          }

          // Trigger Userflow event
          this._userflowService.triggerEventWithNoAttributes(
            forUserEmail,
            subscribe ? UserflowEventName.TeamSubscribed : UserflowEventName.TeamUnsubscribed,
          );
        }),
      );
  }

  toggleSubscribeOfCard(
    forUserEmail: string,
    cardId: string,
    trackingLocation: string,
    currentSubscription: SubscriptionState,
    showNotification: boolean = true,
  ): Observable<ConversationModel> {
    if (!cardId) {
      return of(undefined);
    }

    return this._tagService.toggleSubscribeOfCard(forUserEmail, cardId).pipe(
      tap((conversation: ConversationModel) => {
        const subscriptionState = conversation.calculateSubscriptionState();
        // Track
        this._trackingService.trackMuteCard(
          forUserEmail,
          trackingLocation,
          subscriptionState !== SubscriptionState.SUBSCRIBED,
        );

        // Set notification
        if (showNotification) {
          this._notificationsService.setInAppNotification(forUserEmail, {
            type:
              subscriptionState === SubscriptionState.MUTED
                ? NotificationEventType.MuteOne
                : currentSubscription === SubscriptionState.MUTED
                  ? NotificationEventType.UnmuteOne
                  : NotificationEventType.SubscribeToOne,
          });
        }
      }),
    );
  }

  ensureSubscriptionStateOfChatCardAndTeam(
    forUserEmail: string,
    trackingLocation: string,
    group: GroupModel,
    subscriptionStatusUpdate: SubscriptionStatusUpdate,
  ): Observable<any> {
    if (group.$type !== GroupModel.type) {
      return of(undefined);
    }

    return forkJoin([
      this.ensureGroupSubscriptionState(forUserEmail, group, subscriptionStatusUpdate),
      this.ensureChatCardSubscriptionState(forUserEmail, trackingLocation, group, subscriptionStatusUpdate),
    ]).pipe(
      tap(() => {
        this._notificationsService.setInAppNotification(forUserEmail, {
          type: NotificationEventType.SubscribeToOne,
          msg: 'Subscription updated',
        });
      }),
    );
  }

  ////////////////////
  // Private helpers
  ////////////////////
  private ensureGroupSubscriptionState(
    forUserEmail: string,
    group: GroupModel,
    subscriptionStatusUpdate: SubscriptionStatusUpdate,
  ): Observable<any> {
    let shouldGroupBeSubscribed = [SubscriptionStatusUpdate.INBOX_AND_CHAT, SubscriptionStatusUpdate.INBOX].includes(
      subscriptionStatusUpdate,
    );

    return this.toggleSubscribeOfTeam(forUserEmail, group, false, shouldGroupBeSubscribed);
  }

  private ensureChatCardSubscriptionState(
    forUserEmail: string,
    trackingLocation: string,
    group: GroupModel,
    subscriptionStatusUpdate: SubscriptionStatusUpdate,
  ): Observable<any> {
    return of(undefined).pipe(
      /**
       * Get current group chat card subscription state
       */
      mergeMap(() => {
        return this._conversationService.findOrFetchByCardIds(forUserEmail, [group.id]).pipe(
          map((conversations: ConversationModel[]) => _.first(conversations)),
          map((conversation: ConversationModel) => {
            return conversation.calculateSubscriptionState();
          }),
        );
      }),
      /**
       * Toggle status if mismatch
       */
      mergeMap((chatCardSubscribeStatus: SubscriptionState) => {
        let shouldBeSubscribed = [SubscriptionStatusUpdate.INBOX_AND_CHAT, SubscriptionStatusUpdate.CHAT].includes(
          subscriptionStatusUpdate,
        );

        let isSubscribed = chatCardSubscribeStatus === SubscriptionState.SUBSCRIBED;

        if (shouldBeSubscribed !== isSubscribed) {
          return this.toggleSubscribeOfCard(forUserEmail, group.id, trackingLocation, chatCardSubscribeStatus, false);
        }

        return of(undefined);
      }),
    );
  }
}
