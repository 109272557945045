import { SettingsService } from '@shared/services/settings/settings.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { PushSyncDaoService } from '@shared/database/dao/push-sync/push-sync-dao.service';
import { DatabaseService } from '@shared/database/database.service';
import { DataServiceShared } from '@shared/services/data/data.service';
import { ElectronService } from '@shared/services/electron/electron';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { FlushDatabaseService } from '@shared/services/flush-db/flush-db.service';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { PushSynchronizationModuleService } from '@shared/synchronization/push-synchronization/push-synchronization.module';
import { SynchronizationManagerService } from '@shared/synchronization/synchronization-manager.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { SynchronizationStatusService } from '@shared/synchronization/synchronization-status.service';
import { EventHubService } from '@shared/synchronization/websockets/event-hub.service';
import { PullSynchronizationServiceWeb } from './pull-synchronization/pull-synchronization.service.web';
import { PushSynchronizationServiceWeb } from './push-synchronization/push-synchronization.service.web';
import { Time } from '@dta/shared/utils/common-utils';
import { Observable } from 'rxjs';

export class SynchronizationManagerServiceWeb extends SynchronizationManagerService {
  constructor(
    protected _userEmail: string,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _storageService: StorageService,
    protected _eventHubService: EventHubService,
    protected _syncStatusService: SynchronizationStatusService,
    protected _trackingService: TrackingService,
    protected _notificationsService: NotificationsService,
    protected _dataService: DataServiceShared,
    protected _apiService: ApiService,
    protected _fileStorageService: FileStorageService,
    protected _pushSynchronizationModuleService: PushSynchronizationModuleService,
    protected _electronService: ElectronService,
    protected _settingsService: SettingsService,
    protected _synchronizationMiddlewareService: SynchronizationMiddlewareService,
    protected _flushDatabaseService: FlushDatabaseService,
    protected _databaseService: DatabaseService,
    protected _time: Time,
    private _pushSyncDaoService: PushSyncDaoService
  ) {
    super(
      _userEmail,
      _sharedUserManagerService,
      _storageService,
      _eventHubService,
      _syncStatusService,
      _trackingService,
      _notificationsService,
      _dataService,
      _apiService,
      _fileStorageService,
      _pushSynchronizationModuleService,
      _electronService,
      _settingsService,
      _synchronizationMiddlewareService,
      _flushDatabaseService,
      _databaseService,
      _time
    );
  }

  protected initPullSynchronizationService() {
    this._pullSynchronization = new PullSynchronizationServiceWeb(
      this._userEmail,
      this._apiService,
      this._storageService,
      this._eventSynchronizationService,
      this._conversationSynchronizationService,
      this._syncStatusService,
      this._dataService,
      this._sharedUserManagerService,
      this._synchronizationMiddlewareService,
      this._flushDatabaseService
    );

    this._pullSynchronization.init();
  }

  protected initPushSynchronizationService() {
    this._pushSynchronization = new PushSynchronizationServiceWeb(
      this._userEmail,
      this._syncStatusService,
      this._synchronizationMiddlewareService,
      this._pushSynchronizationModuleService.CommentPushSynchronizationService,
      this._pushSynchronizationModuleService.TemplatePushSynchronizationService,
      this._pushSynchronizationModuleService.TagPushSynchronizationService,
      this._pushSynchronizationModuleService.SharedTagPushSynchronizationService,
      this._pushSynchronizationModuleService.AgendaPushSynchronizationService,
      this._pushSynchronizationModuleService.CardPushSynchronizationService,
      this._pushSynchronizationModuleService.AvailabilityStatusPushSynchronizationService,
      this._pushSynchronizationModuleService.SharedTagLabelPushSynchronizationService,
      this._pushSynchronizationModuleService.ConversationActionPushSynchronizationService,
      this._pushSynchronizationModuleService.FolderPushSynchronizationService,
      this._pushSyncDaoService,
      this._notificationsService
    );
  }

  clearPushSyncQueue(forUserEmail: string): Observable<any> {
    return this._pushSynchronization.clearPushSyncQueue(forUserEmail);
  }
}
