<div class="white-right">
  <div class="flex-container">
    <div *ngIf="currentUserId">
      <div class="section">
        <div class="options-container">
          <div class="option">
            <!-- Notification denied warning -->
            <div *ngIf="!notificationPermission" class="notification warn">
              We see you have disabled notifications in your browser. You will need to open your
              <b>browser preferences</b> to change that.
            </div>

            <div *ngIf="notificationPermission">
              <div class="option-inputs">
                <!-- Notifications in general -->
                <div class="option-row" id="allow-notifications">
                  <span class="notification-option-title">Notifications</span>
                  <span class="notification-option-subtitle">Enable to receive notifications</span>
                  <div class="checkbox-toggler" [ngClass]="{checked: allowNotifications}">
                    <svg-comp [src]="checkSvg"></svg-comp>
                    <svg-comp [src]="closeSvg"></svg-comp>
                    <input
                      id="notifications-checkbox-0"
                      type="checkbox"
                      [checked]="allowNotifications"
                      name="allowNotifications"
                    />
                    <label (click)="onAllowNotificationToggle($event)" for="notifications-checkbox-0"
                      ><span></span
                    ></label>
                  </div>
                </div>

                <div *ngIf="requestingPermission" class="notification">
                  Click <b>Allow</b> in your browser's pop-up to enable notifications. If the pop-up is not shown check
                  your <b>browser preferences.</b>
                </div>

                <!-- Notification sound -->
                <div *ngIf="notificationSettings.allowNotifications" class="option-row" id="general-notification-sound">
                  <span class="notification-option-title">Notification Sound</span>
                  <span class="notification-option-subtitle">Play a sound when a notification is received.</span>
                  <div class="checkbox-toggler" [ngClass]="{checked: notificationSettings.notificationSound === true}">
                    <svg-comp [src]="checkSvg"></svg-comp>
                    <svg-comp [src]="closeSvg"></svg-comp>
                    <input
                      id="notifications-checkbox-4"
                      type="checkbox"
                      [(ngModel)]="notificationSettings.notificationSound"
                      name="notificationSound"
                      (ngModelChange)="onNotificationChange($event)"
                    />
                    <label for="notifications-checkbox-4"><span></span></label>
                  </div>
                </div>

                <div *ngIf="notificationSettings.allowNotifications" class="option-row" id="focused-notification">
                  <span class="notification-option-title">Receive Notifications in the App</span>
                  <span class="notification-option-subtitle">Notify me also while I am using the app</span>
                  <div
                    class="checkbox-toggler"
                    [ngClass]="{checked: notificationSettings.isNotifyWhenFocusedEnabled === true}"
                  >
                    <svg-comp [src]="checkSvg"></svg-comp>
                    <svg-comp [src]="closeSvg"></svg-comp>
                    <input
                      id="notifications-checkbox-5"
                      type="checkbox"
                      [(ngModel)]="notificationSettings.isNotifyWhenFocusedEnabled"
                      name="isNotifyWhenFocusedEnabled"
                      (ngModelChange)="onNotificationChange($event)"
                    />
                    <label for="notifications-checkbox-5"><span></span></label>
                  </div>
                </div>

                <div *ngIf="allowNotifications">
                  <div class="option-title">Loop can notify of the following:</div>

                  <!-- Messages -->
                  <div class="option-row" id="general-messages">
                    <span class="notification-option-title">Private conversation</span>
                    <span class="notification-option-subtitle">Notify me when a new direct message is received</span>
                    <div
                      class="checkbox-toggler"
                      [ngClass]="{checked: notificationSettings.notifications.chat === true}"
                    >
                      <svg-comp [src]="checkSvg"></svg-comp>
                      <svg-comp [src]="closeSvg"></svg-comp>
                      <input
                        id="notifications-checkbox-1"
                        type="checkbox"
                        [(ngModel)]="notificationSettings.notifications.chat"
                        name="chat"
                        (ngModelChange)="onNotificationChange($event)"
                      />
                      <label for="notifications-checkbox-1"><span></span></label>
                    </div>
                  </div>

                  <!-- Emails -->
                  <div class="option-row" id="general-email">
                    <span class="notification-option-title">Email conversation</span>
                    <span class="notification-option-subtitle">Notify me when a new email is received</span>
                    <div
                      class="checkbox-toggler"
                      [ngClass]="{checked: notificationSettings.notifications.email === true}"
                    >
                      <svg-comp [src]="checkSvg"></svg-comp>
                      <svg-comp [src]="closeSvg"></svg-comp>
                      <input
                        id="notifications-checkbox-2"
                        type="checkbox"
                        [(ngModel)]="notificationSettings.notifications.email"
                        name="email"
                        (ngModelChange)="onNotificationChange($event)"
                      />
                      <label for="notifications-checkbox-2"><span></span></label>
                    </div>
                  </div>

                  <!-- Team emails -->
                  <div class="option-row" id="general-team-emails">
                    <span class="notification-option-title">Team conversation</span>
                    <span class="notification-option-subtitle">Notify me when a new team message is received</span>
                    <div
                      class="checkbox-toggler"
                      [ngClass]="{checked: notificationSettings.notifications.teamEmail === true}"
                    >
                      <svg-comp [src]="checkSvg"></svg-comp>
                      <svg-comp [src]="closeSvg"></svg-comp>
                      <input
                        id="notifications-checkbox-3"
                        type="checkbox"
                        [(ngModel)]="notificationSettings.notifications.teamEmail"
                        name="teamEmail"
                        (ngModelChange)="onNotificationChange($event)"
                      />
                      <label for="notifications-checkbox-3"><span></span></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
