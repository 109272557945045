import * as _ from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import {
  InboxProvider,
  PermissionDeniedReason,
  PersonalSharedInboxSetupData,
} from '@shared/services/data/channel-inbox-setup/channel-setup.service.interface';
import { Observable } from 'rxjs';
import { ClickTrackingLocation } from '@dta/shared/services/tracking/tracking.constants';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { CreateInboxComponent } from '@shared/modules/main/user-settings/channels/create/create-inbox/create-inbox.component';
import { SetupStep, Step } from '@shared/modules/main/user-settings/channels/create/create-channel.base.component';
import { CREATE_PERSONAL_COPY } from '@shared/modules/main/user-settings/channels/create/create-inbox/create-personal-inbox/create-personal-inbox-copy';
import { SetupType } from '@shared/services/data/channel-inbox-setup/channel-setup.service';

@AutoUnsubscribe()
@Component({
  selector: 'create-personal-inbox',
  styleUrls: ['./../../../../create-view.scss'],
  templateUrl: './../../create-channel.html',
  host: { class: 'main' },
})
export class CreatePersonalInboxComponent extends CreateInboxComponent implements OnInit, OnDestroy {
  //////////////
  // Properties
  //////////////
  data: PersonalSharedInboxSetupData;
  step: 0 | 1 = 0;
  steps: Step[] = [
    { id: SetupStep.Channels, title: 'Connect a personal inbox', hidden: true },
    { id: SetupStep.CreatingInbox, title: '', hidden: true },
  ];
  textBank = CREATE_PERSONAL_COPY;
  trackingLocation: string = ClickTrackingLocation.PersonalInbox;
  setupType: SetupType = SetupType.PERSONAL_SHARED_INBOX;

  get backButtonText(): string {
    return {
      0: 'Back to My inboxes',
      1: 'Back to Overview',
    }[this.step];
  }

  get nextButtonText(): string {
    return {
      0: 'Next',
      1: this.data?.members?.length > 0 ? 'Send invites' : 'Create',
    }[this.step];
  }

  get additionalTrackingProperties(): Object {
    return {
      workflow: 'PersonalInboxSetup',
      step: 'PersonalInboxSetup' + (this.step + 1),
    };
  }

  ngOnInit() {
    super.ngOnInit();
    // Remove Gmail alias from options
    this.channelOptions = this.channelOptions.filter(option => option.id !== 'channel-card-gmail-alias');
    this.persistData();
  }

  ////////////////
  // View methods
  ////////////////
  nextStep() {
    if (this.step === 1) {
      this.create();
    }

    this.trackView();
  }

  ///////////
  // Helpers
  ///////////
  protected navigateToSettings() {
    this._router.navigate(['/user-settings/personal-inboxes']);
  }

  protected getNewDataModel(): PersonalSharedInboxSetupData {
    return new PersonalSharedInboxSetupData();
  }

  protected persistData() {
    this._channelSetupService.persistSetupData(this.currentUserEmail, this.data);
  }

  protected setStepFromData(permissionDeniedReason?: PermissionDeniedReason) {
    let step: 0 | 1 = 0;

    if (this.data.inboxAuth || this.data.provider === InboxProvider.GOOGLE_ALIAS) {
      this.create(0);
      step = 1;
    }

    this.stepStatusToIdle();

    if (permissionDeniedReason) {
      step = 0;
      this.data = _.pick(this.data, ['name', 'description', 'state']) as PersonalSharedInboxSetupData;
      this.persistData();

      switch (permissionDeniedReason) {
        case 'permissionDenied':
          this.openErrorDialog('Required permissions were not granted');
          break;
        case 'emailSameAsLoggedInUser':
          this.openErrorDialog(
            'You have already connected this inbox as a personal account. Please sign-in with a different personal account to connect this shared inbox.',
          );
          break;
        case 'unknown':
        default:
          this.openErrorDialog(
            'Something went wrong. Please try again, if the issue persists please contact our support.',
          );
          break;
      }
    }

    this.step = step;
    this.trackView();
  }

  protected setupServiceCreate(): Observable<ContactModel> {
    return this._channelSetupService.createInbox(this.currentUserEmail, true);
  }
}
