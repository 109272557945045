export enum SyncSettingsType {
  SYNC_SETTINGS_GMAIL = 'SyncSettingsGmail',
  SYNC_SETTINGS_MICROSOFT = 'SyncSettingsMicrosoft',
  SYNC_SETTINGS_EXCHANGE = 'SyncSettingsExchange',
  SYNC_SETTINGS_REMOTE_IMAP = 'SyncSettingsRemoteImap',
  SYNC_SETTINGS_IMAP_SMTP = 'SyncSettingsImapSmtp',
}

export enum EmailTypeEnum {
  AUTH_IMAP_SMTP = 'AuthImapSmtp',
  AUTH_GOOGLE = 'AuthGoogle',
  AUTH_PASSWORD = 'AuthPassword',
  AUTH_EXCHANGE = 'AuthExchange',
  AUTH_MICROSOFT = 'AuthMicrosoft',
  AUTH_REMOTE_IMAP = 'AuthRemoteImap',
  AUTH_CODE = 'AuthCode',
}
