export interface LoopIDBTransformer<T> {
  serialize: (obj: T) => unknown;
  deserialize: (value: unknown) => T;
}

export interface LoopIDBIndexSchema {
  name: string;
  keyPath?: string | string[];
  options?: IDBIndexParameters;
}

export enum StoreTypes {
  PERSISTENT = 'persistent',
  CACHE = 'cache',
}

export interface LoopIDBObjectStoreParameters extends IDBObjectStoreParameters {
  alternativePrimaryKey?: string;
  storageType?: StoreTypes;
  maxCacheSize?: number;
}

export interface LoopIDBStoreSchema {
  name: string;
  options?: LoopIDBObjectStoreParameters;
  indexes?: (LoopIDBIndexSchema | string)[];
}

export interface LoopIDBDatabaseSchema {
  version: number;
  stores: LoopIDBStoreSchema[];
}

/**
 * Options to pass to the LoopIDBDatabase constructor
 */
export interface LoopIDBDatabaseOptions {
  /**
   * Name of this database
   */
  name: string;

  schema?: LoopIDBDatabaseSchema;

  /**
   * IDBFactory to use to create the database if different from `window.indexeddb`
   *
   * @default `window.indexeddb`
   */
  factory?: IDBFactory;

  /**
   * Custom onUpgrade method to execute when database version has changed
   *
   * @param database
   * @param versionChangeEvent
   */
  onUpgrade?: (
    database: IDBDatabase,
    oldVersion: number,
    newVersion: number | null,
    transaction: IDBTransaction,
  ) => void;

  onBlocked?: (event: Event) => void;

  /**
   * Should the database be closed on version changed
   */
  autoCloseOnVersionChange?: boolean;
}
