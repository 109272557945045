import * as _ from 'lodash';
import { inject, Injectable } from '@angular/core';
import { CommentBaseModel, CommentMailModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { ThumbnailSize } from '@shared/api/api-loop/models/thumbnail-size';
import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@shared/models/constants/constants';
import { map } from 'rxjs/operators';
import { FileHelper } from '@dta/shared/utils/file.helper';
import { FileStorageServiceI, WriteFileOptions } from './file-storage.interface';
import { FileNameConstants } from '@shared/enums/enums';
import { readFileData } from '@shared/modules/files/common/helpers/read-file-data';
import {
  FileUrlEntryI,
  FileUrlStorageService
} from '../../../web/app/services/file-storage/file-url-storage/file-url-storage.service';

/**
 * [!] IMPORTANT NOTICE: [!]
 * Using 'fs' API from renderer process doesn't work.
 * Related: https://github.com/electron/electron/issues/19554
 * Use 'fs' API in main process and call it via IPC.
 */
@Injectable()
export abstract class FileStorageService implements FileStorageServiceI {
  protected _fileUrlStorageService: FileUrlStorageService = inject(FileUrlStorageService);

  // Matches files(_XX)/cid or files(_XX)/tmp/cid
  private filePathCidRegex: RegExp = /.*[\/\\]files(?:_v[0-9]*)[\/\\](?:tmp[\/\\])?([^?]+).*/i;

  constructor() {}

  get constructorName(): string {
    return 'FileStorageService';
  }

  getFilePath(fileHash: string, includeFilePrefix: boolean = true, urlLink: string = ''): string {
    if (urlLink && !FileUrlStorageService.isLinkExpired(FileUrlStorageService.getLinkExpirationDate(urlLink))) {
      return urlLink;
    }

    return this._fileUrlStorageService.getFileUrlByFileName(fileHash);
  }

  getFileThumbnailPath(file: FileModel): string {
    let thumbnailFileName = this.imageThumbnailFilename(file, ThumbnailSize.MEDIUM);
    return this._fileUrlStorageService.getFileUrlByFileName(thumbnailFileName);
  }

  getFilePreviewPath(file: FileModel): string {
    let previewFileName = this.previewPDFFilename(file);
    return this._fileUrlStorageService.getFileUrlByFileName(previewFileName);
  }

  writeFileUrl(fileName: string, fileUrl: string, options?: WriteFileOptions): Observable<any> {
    return of(this._fileUrlStorageService.writeToStorage([{ fileUrl: fileUrl, fileName: fileName }]));
  }

  writeFileUrls(data: FileUrlEntryI[]): Observable<any> {
    return of(this._fileUrlStorageService.writeToStorage(data));
  }

  fileExists(fileName: string): Observable<boolean> {
    return of(this._fileUrlStorageService.existsInStorage(fileName));
  }

  fileExistsInUrlStorage(fileName: string): Observable<boolean> {
    return of(this._fileUrlStorageService.existsInStorage(fileName));
  }

  abstract getFilesUri(): string;

  //////////////////////
  // Write file methods
  //////////////////////
  writeFile(fileName: string, data: Buffer, options?: WriteFileOptions): Observable<any> {
    throw new Error('Method not implemented.');
  }

  //////////////
  // Filenames
  //////////////
  imageThumbnailFilename(file: FileModel, size: ThumbnailSize): string {
    return size === ThumbnailSize.MEDIUM ? this.thumbnailFilename(file) : this.previewImgFilename(file);
  }

  thumbnailFilename(file: FileModel): string {
    // changed from _small to _preview to get nicer images
    return file.hash + FileNameConstants.PREVIEW;
  }

  previewImgFilename(file: FileModel): string {
    return file.hash + FileNameConstants.LARGE;
  }

  signatureFilename(file: FileModel): string {
    return file.hash + FileNameConstants.SIGNATURE;
  }

  previewPDFFilename(file: FileModel): string {
    return file.hash + FileNameConstants.PREVIEW_PDF;
  }

  /////////
  // Other
  /////////
  readFiles(files: string[]): Observable<Buffer[]> {
    throw new Error('Method not implemented.');
  }
  readFile(filePath: string, relativeToCurrentDir: boolean = false): Observable<Buffer> {
    throw new Error('Method not implemented.');
  }
  moveFromTmp(file: FileModel): Observable<string> {
    throw new Error('Method not implemented.');
  }
  renameFile(source: string, target: string, logError: boolean = true): Observable<string> {
    throw new Error('Method not implemented.');
  }
  createPath(path: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  deleteFiles(files: string[]): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getDirFiles(path: string): Observable<string[]> {
    throw new Error('Method not implemented.');
  }
  getFileName(file: FileModel, temp: boolean): string {
    throw new Error('Method not implemented.');
  }
  getFullPath(filePathFromFilesFolder: string): string {
    throw new Error('Method not implemented.');
  }
  getCommentBodyFileName(comment: CommentBaseModel): string {
    throw new Error('Method not implemented.');
  }
  getFilesPath(): string {
    throw new Error('Method not implemented.');
  }
  download(
    fileUri: string,
    fileName: string,
    saveToDownloads?: boolean,
    openDestinationFolderAfter?: boolean,
    pathToSave?: string
  ): Observable<string> {
    throw new Error('Method not implemented.');
  }
  downloadPublic(fileUri: string, fileName: string) {
    throw new Error('Method not implemented.');
  }
  downloadAllFiles(fileNamesWithFileUris: { fileName: string; fileUri: string }[], dirName: string) {
    throw new Error('Method not implemented.');
  }
  updateResourceGroup(group: string) {
    throw new Error('Method not implemented.');
  }

  static validateTotalFilesSize(files: FileList | (FileModel | File)[] = [], existingFiles: FileModel[] = []): boolean {
    let maxsize = CONSTANTS.MAX_ATTACHMENTS_SIZE;
    let allFiles = [..._.values(files), ..._.values(existingFiles)];
    let totalFilesSize = this.sumFilesSize(allFiles);
    return totalFilesSize < maxsize;
  }

  static sumFilesSize(files: (FileModel | File)[]): number {
    return _.reduce(
      files,
      (sum, file) => {
        return sum + this.getFileSize(file);
      },
      0
    );
  }

  static getFileSize(file: FileModel | File): number {
    if (!file) {
      return 0;
    }

    return file.size || 0;
  }

  readFileData(file: File, defaultFilename: string): Observable<File> {
    return readFileData(file, defaultFilename).pipe(
      map(base64 => {
        file['base64data'] = base64;
        return file;
      })
    );
  }

  readGifFile(buffer: Buffer, file: File): Observable<File> {
    return Observable.create(obs => {
      const reader = FileHelper.createFileReader(obs);

      let blob = new Blob([buffer], { type: 'image/gif' });
      return reader.readAsDataURL(blob);
    }).pipe(
      map((data: string) => {
        let base64data = _.split(data, ',');
        if (!_.isEmpty(base64data)) {
          file['base64data'] = base64data[1];
        }

        return file;
      })
    );
  }

  readImageFile(uri: string): Observable<HTMLImageElement> {
    if (!uri) {
      throw new Error('Uri must not be empty');
    }

    return Observable.create(obs => {
      let img = new Image();

      img.onerror = err => {
        return obs.error(err);
      };
      img.onabort = err => {
        return obs.error(err);
      };
      img.onload = () => {
        obs.next(img);
        obs.complete();
      };

      img.src = uri;

      return img;
    });
  }

  writeCommentBody(comment: CommentMailModel) {
    throw new Error('Method not implemented.');
  }

  loadCommentBodyFromDisc(comment: CommentMailModel): Observable<CommentMailModel> {
    throw new Error('Method not implemented.');
  }

  storeAttachment(file: FileModel, toTmp: boolean = false, base64Data?: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  getFilePathFromDialog(fileName: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  appendFile(fileName: string, data: Buffer): Observable<string> {
    throw new Error('Method not implemented.');
  }

  getCidFromFilePath(uri: string = ''): string {
    if (uri.includes('cid:')) {
      return uri.replace('cid:', '');
    }

    return uri.replace(this.filePathCidRegex, '$1');
  }
}
