import { ApiService } from '@shared/api/api-loop/api.module';
import { PullSynchronizationService } from '@shared/synchronization/pull-synchronization/pull-synchronization.service';
import { DataServiceShared } from '@shared/services/data/data.service';
import { ConversationSynchronizationService } from '@shared/synchronization/conversation-synchronization/conversation-synchronization.service';
import { CONSTANTS } from '@shared/models/constants/constants';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { Observable, of } from 'rxjs';

export class ConversationSynchronizationServiceWeb extends ConversationSynchronizationService {
  constructor(
    protected _userEmail: string,
    protected _data: DataServiceShared,
    protected _apiService: ApiService,
    protected _storageService: StorageService,
    protected _pullSynchronizationService: PullSynchronizationService,
  ) {
    super(_userEmail, _data, _apiService, _storageService, _pullSynchronizationService);
  }

  protected getMaxChanges(): number {
    return CONSTANTS.CONVERSATION_MAX_CHANGES_WEB;
  }

  protected isInvalidState(): boolean {
    return true;
  }

  /**
   * Not used ATM -> we always have invalid state
   * @protected
   */
  protected isHistoryIdValid(): Observable<any> {
    return of(undefined);
  }
}
