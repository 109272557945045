/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Response } from '../models/response';
import { NotificationBase } from '../models/notification-base';

@Injectable()
export class NotificationApiService extends BaseService {
  /**
   * @param params The `NotificationApiService.Notification_CreateNotificationParams` containing the following parameters:
   *
   * - `notification`: Notification resource
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Notification_CreateNotificationResponse(
    params: NotificationApiService.Notification_CreateNotificationParams,
    forUserEmail: string
  ): Observable<HttpResponse<Response>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/notification`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Notification_CreateNotification');

    // body, headers and query params
    __body = params.notification;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: Response;

          body = <Response>response.body;

          return <HttpResponse<Response>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `NotificationApiService.Notification_CreateNotificationParams` containing the following parameters:
   *
   * - `notification`: Notification resource
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Notification_CreateNotification(
    params: NotificationApiService.Notification_CreateNotificationParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<Response> {
    const observable$ = this.Notification_CreateNotificationResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/notification`;
      const url = this.rootUrl + `/api/v1/notification`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module NotificationApiService {
  /**
   * Parameters for Notification_CreateNotification
   */
  export type Notification_CreateNotificationParams = {
    /**
     * Notification resource
     */
    notification: NotificationBase;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
