import { Injectable } from '@angular/core';
import { BaseObservableCacheService } from '@shared/cache/base-observable-cache.service';
import { LRUCache } from 'lru-cache';
import { Observable, of } from 'rxjs';
import * as hash from 'object-hash';
import { ApiCacheKey } from '@shared/api/api-cache/api-cache-key';

interface KeyFromRequest {
  endpoint: string;
  url: string;
  forUserEmail?: string;
  params?: Record<string, unknown>;
}

export function createKeyFromRequest({ endpoint, url, params, forUserEmail }: KeyFromRequest): ApiCacheKey {
  return {
    endpoint: endpoint,
    key: hash({ endpoint, url, params, forUserEmail })
  };
}

@Injectable()
export class BaseApiCacheService extends BaseObservableCacheService<ApiCacheKey> {
  protected readonly cacheStorage: LRUCache<string, LRUCache<string, Observable<any>>> =
    this.storageProviderService.provideStorage('apiCache', 300, 1000 * 60 * 30);

  getOrStoreObservable<T>(
    key: ApiCacheKey,
    observable$: Observable<T>,
    expiration: number = 1000 * 60 * 30
  ): Observable<T> {
    if (!this.cacheStorage.has(key.endpoint)) {
      this.cacheStorage.set(
        key.endpoint,
        new LRUCache<string, Observable<any>>({
          ttl: 1000 * 60 * 30,
          ttlAutopurge: true
        })
      );
    }

    const endpointStorage = this.cacheStorage.get(key.endpoint);
    if (!endpointStorage.has(key.key)) {
      return this.storeObservable(endpointStorage, key.key, observable$, expiration);
    }

    return endpointStorage.get(key.key) as Observable<T>;
  }
}
