import * as _ from 'lodash';
import { Observable, of, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CommentService } from '../../comment/comment.service';
import {
  CardType,
  FilterEnum,
  ListOfResourcesOfConversation,
  ListOfResourcesOfTag,
  ListOfTags,
  ViewEnum
} from '@shared/api/api-loop/models';
import { ContactService } from '../../contact/contact.service';
import { CardService } from '../../card/card.service';
import { ConversationFetchRequest, FetchResult } from '@dta/shared/models/collection.model';
import { BaseCollectionService } from '../base-collection/base-collection.service';
import { ConversationApiService, SpamApiService } from '@shared/api/api-loop/services';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { ConversationHelper } from './conversation-helper';
import { ConversationService } from '../../conversation/conversation.service';
import { Logger } from '@shared/services/logger/logger';
import { LogTag } from '@dta/shared/models/logger.model';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ListOfTagsModel } from '@dta/shared/models-api-loop/tag.model';
import { ContactBaseModel } from '@dta/shared/models-api-loop/contact/contact.model';

@Injectable()
export class ConversationCollectionService extends BaseCollectionService {
  constructor(
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
    private _conversationApiService: ConversationApiService,
    private readonly spamApiService: SpamApiService,
    private _conversationService: ConversationService,
    private _storageService: StorageService
  ) {
    super(_commentService, _cardService, _contactService);
  }

  get constructorName(): string {
    return 'ConversationCollectionService';
  }

  ///////////////
  // FETCH CARDS
  ///////////////
  fetchConversations(forUserEmail: string, fetchRequest: ConversationFetchRequest): Observable<FetchResult> {
    let watch = new StopWatch(this.constructorName + '.fetchConversations', ProcessType.SERVICE, forUserEmail);

    const listOfConversations$ =
      fetchRequest.showInViewData?.filter === FilterEnum.SPAM
        ? this.spamApiService
            .Spam_GetSpamMessages(
              {
                pageSize: fetchRequest.size,
                historyId: fetchRequest.offsetHistoryId,
                groupId: fetchRequest.showInViewData?.channelId
              },
              forUserEmail
            )
            .pipe(
              map((response: ListOfResourcesOfConversation) => {
                let tags: ListOfResourcesOfTag = {
                  resources: [],
                  offset: 0,
                  size: 0,
                  totalSize: 0
                };

                let sharedTags: ListOfTags = {
                  $type: ListOfTagsModel.type,
                  revision: '0',
                  tags: tags
                };

                response.resources = response.resources.map(resource => {
                  resource.sharedTags = sharedTags;
                  resource.tags = sharedTags;
                  resource.shareList = ContactBaseModel.createListOfResources([]);
                  resource.showInViews = [
                    {
                      view: ViewEnum.CHANNEL,
                      channelId: fetchRequest.showInViewData.channelId,
                      filter: FilterEnum.SPAM
                    }
                  ];
                  resource.unreadCount = {
                    totalUnreadCount: 0,
                    chatUnreadCount: 0,
                    emailUnreadCount: 0
                  };
                  return resource;
                });
                return response;
              })
            )
        : this._conversationApiService.Conversation_GetList(
            { query: ConversationHelper.getFetchRequestToSearchQueryConversation(fetchRequest) },
            forUserEmail
          );

    return listOfConversations$.pipe(
      /**
       * Publish conversations from API
       */
      mergeMap((response: ListOfResourcesOfConversation) =>
        this.processConversationResponse(forUserEmail, fetchRequest, response, watch)
      ),
      /**
       * Return
       */
      map((response: ListOfResourcesOfConversation) => {
        watch.log('done');

        let fetchResult: FetchResult = {
          offsetHistoryId: response.offsetHistoryId,
          dataLength: response.size,
          hasData: response.totalSize > fetchRequest.size
        };

        return fetchResult;
      }),
      catchError(err => {
        return of({
          offsetHistoryId: fetchRequest.offsetHistoryId,
          dataLength: 0,
          hasData: false
        });
      })
    );
  }

  private processConversationResponse(
    forUserEmail: string,
    fetchRequest: ConversationFetchRequest,
    response: ListOfResourcesOfConversation,
    watch: StopWatch
  ) {
    return of(response).pipe(
      /**
       * Remove CardMail from ConversationSync
       */
      map((response: ListOfResourcesOfConversation) => {
        return _.filter(response.resources, conversation => {
          return conversation.cardType !== CardType.CARD_MAIL || conversation.channelType === 'loop:spam';
        });
      }),
      mergeMap((conversations: ConversationModel[]) => {
        return this._conversationService
          .findByCardIds(
            forUserEmail,
            conversations.map(conversation => conversation.cardId)
          )
          .pipe(
            map(localCards => {
              let conversationsByCardId = _.keyBy(localCards, 'cardId');
              const conversationToSave = conversations.map(conversation => {
                const localCard = conversationsByCardId[conversation.cardId];

                if (!localCard) {
                  return conversation;
                }
                return BaseModel.isRevisionGreaterOrEqualThan(conversation, localCard) ? conversation : localCard;
              });
              return conversationToSave;
            })
          );
      }),
      /**
       * Process and save
       */
      mergeMap((conversations: ConversationModel[]) => {
        watch.log('will saveAllAndPublish');
        return this._conversationService.saveAll(forUserEmail, ConversationModel.createList(conversations)).pipe(
          /**
           * Publish correctly marked models
           */
          tap((_conversations: ConversationModel[]) => {
            // Mark query that conversations fits to
            if (fetchRequest.textQuery) {
              _conversations = _conversations.map(conversation => {
                conversation.fitsSearchQuery = fetchRequest.textQuery;
                return conversation;
              });
            }
            PublisherService.publishEvent(forUserEmail, _conversations);
          }),
          /**
           * Remove _syncedComments property before triggering follow-up sync
           */
          mergeMap((_conversations: ConversationModel[]) => {
            if (fetchRequest.forceFollowUpFetch) {
              return this._conversationService
                .removeSyncedCommentAttribute(forUserEmail, fetchRequest.showInViewData)
                .pipe(map(() => _conversations));
            }

            return of(_conversations);
          }),
          /**
           * Proceed with response
           */
          map(() => response)
        );
      })
    );
  }

  public fetchUpdates(forUserEmail: string, historyId: string, cutOffTimeStorageKey: string): Observable<string> {
    let cutOffTime = this._storageService.getItem(cutOffTimeStorageKey);
    let query = ConversationHelper.getSearchQueryConversationForUpdates(historyId, cutOffTime);

    return this._conversationApiService.Conversation_GetList({ query }, forUserEmail).pipe(
      /**
       * Process and save updates
       */
      mergeMap((response: ListOfResourcesOfConversation) => {
        let _conversations = ConversationModel.createList(response.resources);

        return this._conversationService.saveAllAndPublish(forUserEmail, _conversations).pipe(map(() => response));
      }),
      /**
       * Catch any error
       */
      catchError(err => {
        // Handle upgrades errors by not unsubscribing but delaying
        if (!_.isNil(err.status) && ((err.status >= 500 && err.status < 600) || err.status === 0)) {
          return timer(2_000);
        }

        // Log other errors
        Logger.error(
          err,
          `Error in ${this.constructorName}:periodicPull() for ${forUserEmail}`,
          LogTag.INTERESTING_ERROR
        );

        // Retry others by larger delay
        return timer(20_000);
      }),
      map((response: ListOfResourcesOfConversation) => response.offsetHistoryId)
    );
  }
}
