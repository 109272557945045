/* tslint:disable */
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { LogConfiguration } from './api-configuration';
import { Encryption } from '@dta/shared/utils/common-utils';

/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

/**
 * Base class for API services
 */
export class BaseService {
  constructor(
    protected _config: LogConfiguration,
    protected _http: HttpClient
  ) {}

  private _rootUrl: string;

  private readonly authHeaderName: string = 'Authorization';
  private readonly authLogTokenType: string = 'LogAuth';

  private readonly aesKey: string = '(H+MbQeThWmZq4t7w!z%C*F-J@NcRfUj';
  private readonly aesIv: string = '8x/A?D*G-KaPdSgV';

  /**
   * Returns the root url for API operations. If not set directly in this
   * service, will fallback to ApiConfiguration.rootUrl.
   */
  get rootUrl(): string {
    return this._rootUrl || this._config.rootUrl;
  }

  /**
   * Sets the root URL for API operations in this service.
   */
  set rootUrl(rootUrl: string) {
    this._rootUrl = rootUrl;
  }

  /**
   * Creates a new `HttpParams` with the correct codec
   */
  protected newParams(): HttpParams {
    return new HttpParams({
      encoder: new ParameterCodec()
    });
  }

  private getCryptedLogBearer(logBearer: string): string {
    return Encryption.getCryptedLogBearer(logBearer, this.aesKey, this.aesIv);
  }

  protected newHeaders(bearer: string): HttpHeaders {
    var headers = new HttpHeaders();
    headers = headers.append(this.authHeaderName, `${this.authLogTokenType} ${this.getCryptedLogBearer(bearer)}`);
    return headers;
  }
}
