export function htmlDecode(input: string): string {
  let elem = document.createElement('div');
  elem.innerHTML = input;

  return elem.childNodes.length === 0 ? '' : elem.childNodes[0].nodeValue ? elem.childNodes[0].nodeValue : '';
}

export function insertTextAtCursor(text: string): void {
  if (window.getSelection) {
    // Get selection (only one focus one whole window)
    let sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      // Get range of selection
      let range = sel.getRangeAt(0);

      // Remove selection in range
      range.deleteContents();

      // Replace with new text
      let newNode = document.createTextNode(text);
      range.insertNode(newNode);

      // Move caret at the end of insertion
      range.setStart(newNode, htmlDecode(text).length);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }
}

export function removeCharactersBeforeCursor(count: number): void {
  if (window.getSelection) {
    let selection = window.getSelection();
    if (selection.getRangeAt && selection.rangeCount) {
      let editorRange = selection.getRangeAt(0);
      const node = editorRange.commonAncestorContainer;
      const range = document.createRange();
      range.selectNodeContents(node);
      range.setStart(node, editorRange.endOffset - count); // current caret pos - 3
      range.setEnd(node, editorRange.endOffset); // current caret pos
      range.deleteContents();
    }
  }
}

export function insertElementAtCursor(element: Element): void {
  if (window.getSelection) {
    // Get selection (only one focus one whole window)
    let sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      // Get range of selection
      let range = sel.getRangeAt(0);

      // Remove selection in range
      range.deleteContents();

      // Replace with new text
      range.insertNode(element);

      range = document.createRange();
      range.setStartAfter(element);
      range.collapse(true);

      // Remove all ranges and add the new one
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }
}
