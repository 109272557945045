import { Injectable } from '@angular/core';
import { IFrameRequestHandlerI } from '@shared/services/integrations/iframe-request-handler.interface';
import { IFrameMethod } from '@intheloop/loop-integration';
import { Observable } from 'rxjs';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { IFrameRequest } from '@intheloop/loop-integration/dist/models/model';

@Injectable()
export class AuthHelper implements IFrameRequestHandlerI {
  constructor(private _webOnBoardingService: WebOnboardingService) {}

  handleRequest(forUserEmail: string, request: IFrameRequest, integrationId: string): Observable<any> {
    switch (request.method) {
      case IFrameMethod.GET_SHORT_LIVED_TOKEN:
        return this._webOnBoardingService.getShortLivedToken(forUserEmail);
    }
  }
}
