import * as _ from 'lodash';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import {
  ContactBaseModel,
  ContactModel,
  GroupModel,
  UserModel,
} from '../../../../shared/models-api-loop/contact/contact.model';
import { ContactBase, GroupSubType, GroupType, User } from '@shared/api/api-loop/models';
import { TooltipService } from '../tooltip/tooltip.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { map, tap } from 'rxjs/operators';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { CONSTANTS } from '@shared/models/constants/constants';
import { EmailUtils } from '@dta/shared/utils/common-utils';
import { InputComponent } from '../custom-input/custom-input.component';

@AutoUnsubscribe()
@Component({
  selector: 'chips',
  templateUrl: './chips.html',
  styleUrls: ['./chips.scss'],
})
export class ChipsComponent implements OnInit, OnDestroy {
  @Input() disableGroups: boolean = false;
  @Input() label;
  @Input() hideCurrentUser = false;
  @Input() value;
  @Input() preview = false;
  @Input() ignoreGroups: boolean = false;
  @Input() recipients: ContactBase[];
  @Input() previousRecipients: ContactBase[] = [];
  @Input() lockedRecipients: ContactBase[] = [];
  @Input() autofocus: boolean = false;
  @Input() class = '';
  @Input() addChipOnDemand$: EventEmitter<ContactBase>;
  @Input() passiveDisplay: boolean = true;
  @Input() unremovable: boolean = false;
  @Input() alwaysShowAutoSuggest: boolean = false;
  @Input() showAutosuggestIfEmpty: boolean = false;
  @Input() isFocused: boolean = true;
  @Input() placeholder: string;
  @Input() autosuggestLimit: number = 10;
  @Input() preventSubmitOnFocus: boolean = false;
  @Input() showAutosuggester: boolean = true;
  @Input() draggable: boolean;

  // View info
  @Input() isOnLoopInPopup: boolean = false;
  @Input() isOnAddToLoop: boolean = false;
  @Input() isOnNewMessage: boolean = false;
  @Input() isOnReplyTo: boolean = false;
  @Input() isOnComposer: boolean = false;

  @Input() groupSubTypes: GroupSubType[] = [
    GroupSubType.NORMAL,
    GroupSubType.MANAGED_TEAM,
    GroupSubType.PERSONAL_INBOX,
    GroupSubType.SHARED_INBOX,
  ];

  @Output() onInputChange = new EventEmitter();
  @Output() onChipsChange = new EventEmitter<ContactBase[]>();
  @Output() onChipAdded = new EventEmitter();
  @Output() onChipRemoved = new EventEmitter();
  @Output() removeChip$ = new EventEmitter();
  @Output() onBlur$ = new EventEmitter();
  @Output() chipsFocusEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeRecipients: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('inputElement', { static: false }) inputElement: InputComponent;
  @ViewChild('chipsWrapper', { static: false }) protected chipsWrapper;

  // Current index of dropdown
  currentIndexEvent = new Subject();
  currentQuery = new Subject();
  currentAutosuggestItem: ContactBase;

  // current user
  currentUserId = '';
  chipToRemoveId: string;

  chipsWrapperHeight = 0;

  // subs
  private addChipSub: Subscription;
  private anonymousSubscriptions: Subscription[] = [];

  constructor(
    @Inject(ElementRef) public _elementRef,
    private _userManagerService: UserManagerService,
    private _changeDetection: ChangeDetectorRef,
    private _contactService: ContactService,
    private _tooltipService: TooltipService,
  ) {}

  ngOnInit() {
    this.currentUserId = this._userManagerService.getCurrentUserEmail();

    this.showActiveDisplay();
    this.setIgnoreProperties();
    this.subscribeToAddChip();

    if (this.isOnLoopInPopup) {
      if (window.innerHeight > 800) {
        this.chipsWrapperHeight = window.innerHeight - 600;
      } else {
        this.chipsWrapperHeight = window.innerHeight - 529;
      }
    }

    if (this.recipients) {
      this.recipients.forEach(chip => {
        this.addChip(chip, false);
      });
    }

    setTimeout(() => {
      this.onValueChange('');
    }, 700);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableGroups && !changes.disableGroups.firstChange) {
      this.setIgnoreProperties();
    }
  }

  ngOnDestroy() {}

  private triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }

  private subscribeToAddChip() {
    if (!this.addChipOnDemand$) {
      return;
    }

    this.addChipSub?.unsubscribe();
    this.addChipSub = this.addChipOnDemand$
      .pipe(
        tap(contact => {
          this.addChip(contact);
        }),
      )
      .subscribe();
  }

  // Remove last chip - when user uses backspace to delete
  lastChip = () => {
    return this.recipients[this.recipients.length - 1];
  };

  // Remove 1 chip from chips array and return new array
  removeChip = (chip: ContactBase, backspace?: boolean) => {
    this.currentAutosuggestItem = undefined;

    if (!backspace || this.chipToRemoveId === chip.id) {
      let isChipLocked =
        this.lockedRecipients &&
        this.lockedRecipients.find(
          lockedRecipient => lockedRecipient.id === chip.id || lockedRecipient.clientId === chip.clientId,
        );
      if (isChipLocked) {
        this.removeChip$.emit(chip.id || chip.clientId);
        return;
      }

      if (chip.id) {
        this.recipients = this.recipients.filter(recipient => recipient.id !== chip.id);
      } else {
        this.recipients = this.recipients.filter(recipient => recipient.clientId !== chip.clientId);
      }

      // notify of removed chip and updated recipients
      this.removeChip$.emit(chip.id || chip.clientId);
      this.onChipsChange.emit(this.recipients);
      this.onChipRemoved.emit(chip);

      this.setIgnoreProperties();

      // Hide token tooltip when removing chip
      this._tooltipService.removeTooltip();

      // return focus to input
      this.focusInput();
    } else {
      // Store id of chip to remove
      this.chipToRemoveId = chip.id;
      this.triggerChangeDetection();
    }
  };

  // Get in current selected item from autosuggest list
  selectedItemEvent = (item: ContactBase) => {
    this.currentAutosuggestItem = item;
  };

  focusInput() {
    this._elementRef.nativeElement.querySelector('input').focus();
  }

  onFocus() {
    this.isFocused = true;
    this.chipsFocusEvent.emit();
  }

  onPaste() {
    if (this.inputElement && this.inputElement.value) {
      let remainder = '';

      for (let email of this.inputElement.value.split(/[\n\r\t,; ]/)) {
        email = email.trim();

        if (EmailUtils.validateEmail(email)) {
          // Add new item to array
          let user = new UserModel({
            $type: UserModel.type,
            email: email,
            name: email,
          });

          this.addChip(user);
        } else if (email) {
          remainder += email + ' ';
        }
      }
      this.resetInput();
      this.inputElement.value = remainder;
    }
  }

  resetInput = () => {
    this.value = '';
    this.currentQuery.next('');
    this.currentAutosuggestItem = undefined;
  };

  addCurrentItem() {
    // Validate email
    if (
      this.currentAutosuggestItem &&
      this.currentAutosuggestItem.$type === UserModel.type &&
      (<User>this.currentAutosuggestItem).email === this.value
    ) {
      // Check if there is some suggestion selected in Autosugest list
      // if there is, find out which one is selected and
      // create new chip
      this.addChip(this.currentAutosuggestItem);
      this.resetInput();
    } else if (EmailUtils.validateEmail(this.value)) {
      if (this.isOnNewMessage) {
        return;
      }

      // Add new item to array
      let user = new UserModel({
        $type: UserModel.type,
        email: this.value,
        name: this.value,
      });

      this.addChip(user);
      this.resetInput();
    } else if (this.currentAutosuggestItem) {
      // Add new item to array
      this.addChip(this.currentAutosuggestItem);
      this.resetInput();
    }
  }

  handleBlur() {
    if (!document.hasFocus()) {
      return;
    }

    this.isFocused = false;
    this.currentQuery.next('');
  }

  showActiveDisplay() {
    if (!this.passiveDisplay) {
      return;
    }

    this.passiveDisplay = false;
  }

  keydown($event: KeyboardEvent) {
    // Show active display on keydown
    this.showActiveDisplay();
    // Check if event is backspace
    let backspace: boolean = false;

    switch ($event.code) {
      // ADD ITEM
      case 'Enter':
      case 'Tab':
        if (
          (this.value || this.alwaysShowAutoSuggest || this.preventSubmitOnFocus) &&
          !(this.isOnNewMessage && this.recipients.length > 0)
        ) {
          $event.stopPropagation();
          $event.preventDefault();
          this.addCurrentItem();
        }
        break;
      case 'Space':
        if (EmailUtils.validateEmail(this.value)) {
          $event.preventDefault();
          this.addCurrentItem();
        }
        break;
      // REMOVE LAST ITEM
      case 'Backspace':
        backspace = true;
        if (this.value === '' && this.recipients.length >= 1) {
          this.removeChip(this.lastChip(), true);
        }
        break;
      // EMIT TO AUTO SUGGEST
      case 'ArrowUp':
        $event.stopPropagation();
        $event.preventDefault();
        this.currentIndexEvent.next('UP');
        break;
      case 'ArrowDown':
        $event.stopPropagation();
        $event.preventDefault();
        this.currentIndexEvent.next('DOWN');
        break;
      default:
        break;
    }

    // Remove id of chip to remove if backspace was not pressed
    if (!_.isEmpty(this.chipToRemoveId) && !backspace) {
      this.chipToRemoveId = '';
      this.triggerChangeDetection();
    }
  }

  setCurrentIndex(index) {
    this.currentIndexEvent.next(index);
  }

  chipIsExistingContact(chip: ContactBase): Observable<ContactBase> {
    let currentUserEmail = this._userManagerService.getCurrentUserEmail();

    // If chip is not UserModel and does not have id, return it
    if (chip.$type !== UserModel.type && !chip.id) {
      return of(chip);
    }

    // Get by id if the chip is synced, or by email if the chip is local
    if (chip.id) {
      return this._contactService.findContactById(currentUserEmail, chip.id).pipe(
        map((contact: ContactModel) => {
          if (!_.isUndefined(contact)) {
            return contact;
          }

          return chip;
        }),
      );
    } else if (chip.$type === UserModel.type && (<User>chip).email) {
      return this._contactService.findContactByEmail(currentUserEmail, (<User>chip).email.toLowerCase()).pipe(
        map((contact: ContactModel) => {
          if (!_.isUndefined(contact) && (<User>contact).email.toLowerCase() === (<User>chip).email.toLowerCase()) {
            return contact;
          }

          return chip;
        }),
      );
    }

    // Always fallback to chip
    return of(chip);
  }

  addChip(chip: ContactBase, emitAddRecipient: boolean = true, refocus: boolean = false): Observable<ContactBase> {
    if (this.disableGroups && chip.$type === GroupModel.type) {
      this.value = '';
      this.currentQuery.next('');
      return;
    }

    let sub = this.chipIsExistingContact(chip)
      .pipe(
        tap((_chip: ContactBase) => {
          // Check if email is already in the chips list
          this.recipients = this.recipients.filter(r => {
            // if chip is selected from auto suggest (is already a User model)
            if (
              (r.$type === UserModel.type &&
                _chip.$type === UserModel.type &&
                (<User>r).email &&
                (<User>_chip).email &&
                (<User>r).email.toLowerCase() === (<User>_chip).email.toLowerCase()) ||
              (_chip.id && r.id === _chip.id)
            ) {
              return false;
            }

            return true;
          });

          let recipient = this.createRecipient(_chip);
          this.recipients = [...this.recipients, recipient];
          this.value = '';
          this.currentQuery.next('');

          this.setIgnoreProperties();
          if (emitAddRecipient) {
            this.onChipAdded.emit(recipient);
          }
          this.onChipsChange.emit(this.recipients);

          if (refocus) {
            this.focusInput();
          }
          if (this.isOnLoopInPopup) {
            this.scrollToBottomOfChips();
          }
        }),
      )
      .subscribe();

    this.anonymousSubscriptions.push(sub);
  }

  scrollToBottomOfChips() {
    if (this.chipsWrapper && this.chipsWrapper.nativeElement) {
      setTimeout(() => {
        this.chipsWrapper.nativeElement.scrollTop = CONSTANTS.BIG_INT;
      }, 0);
    }
  }

  createRecipient(chip: ContactBase): ContactBase {
    let recipient;
    if (chip.id) {
      recipient = ContactBaseModel.create(chip);
    } else {
      recipient = chip;
    }

    return recipient;
  }

  onValueChange = $event => {
    this.value = $event;
    this.currentQuery.next($event);
    this.onInputChange.next($event);
    this.triggerChangeDetection();
  };

  setIgnoreProperties() {
    this.setIgnoreGroups();
    this.setIgnoreAutoSuggest();
  }

  setIgnoreGroups() {
    if (!this.disableGroups) {
      this.ignoreGroups = [...this.recipients, ...this.previousRecipients].some(
        recipient => recipient.$type === UserModel.type,
      );
    }
  }

  setIgnoreAutoSuggest() {
    this.showAutosuggester = ![...this.recipients, ...this.previousRecipients].some(
      recipient => recipient.$type === GroupModel.type,
    );
    this.triggerChangeDetection();
  }

  trackById(index, chip) {
    return chip.id || chip._id;
  }

  contactClicked(chip: ContactBase) {
    this.addChip(chip, true, true);
  }
}
