/* tslint:disable */

/**
 * Enum of all possible tag types. All except FOLDER are given by the API gods
 */
export type TagType =
  | 'MUTED'
  | 'UNREAD'
  | 'DELETED'
  | 'HIDDEN'
  | 'SENT'
  | 'FOLDER'
  | 'MENTION'
  | 'RESPONSEPENDING'
  | 'STARRED'
  | 'FAVORITE'
  | 'FOCUSED'
  | 'INBOX'
  | 'FORUMS_G'
  | 'PERSONAL_G'
  | 'SOCIAL_G'
  | 'UPDATES_G'
  | 'PROMOTIONS_G'
  | 'IMPORTANT'
  | 'ARCHIVE'
  | 'PURGE'
  | 'SYSTEMMESSAGE'
  | 'OUTBOX'
  | 'SPAM'
  | 'SNOOZE'
  | 'SNOOZE_INTERRUPT'
  | 'SNOOZE_DUE'
  | 'CHATEE'
  | 'NEWDOMAINUSER'
  | 'UNREAD_VIRTUAL'
  | 'FOLLOW'
  | 'UNFOLLOW';

export module TagType {
  export const MUTED: TagType = 'MUTED';
  export const UNREAD: TagType = 'UNREAD';
  export const DELETED: TagType = 'DELETED';
  export const HIDDEN: TagType = 'HIDDEN';
  export const SENT: TagType = 'SENT';
  export const FOLDER: TagType = 'FOLDER';
  export const MENTION: TagType = 'MENTION';
  export const RESPONSEPENDING: TagType = 'RESPONSEPENDING';
  export const STARRED: TagType = 'STARRED';
  export const FAVORITE: TagType = 'FAVORITE';
  export const FOCUSED: TagType = 'FOCUSED';
  export const INBOX: TagType = 'INBOX';
  export const FORUMS_G: TagType = 'FORUMS_G';
  export const PERSONAL_G: TagType = 'PERSONAL_G';
  export const SOCIAL_G: TagType = 'SOCIAL_G';
  export const UPDATES_G: TagType = 'UPDATES_G';
  export const PROMOTIONS_G: TagType = 'PROMOTIONS_G';
  export const IMPORTANT: TagType = 'IMPORTANT';
  export const ARCHIVE: TagType = 'ARCHIVE';
  export const PURGE: TagType = 'PURGE';
  export const SYSTEMMESSAGE: TagType = 'SYSTEMMESSAGE';
  export const OUTBOX: TagType = 'OUTBOX';
  export const SPAM: TagType = 'SPAM';
  export const SNOOZE: TagType = 'SNOOZE';
  export const SNOOZE_INTERRUPT: TagType = 'SNOOZE_INTERRUPT';
  export const SNOOZE_DUE: TagType = 'SNOOZE_DUE';
  export const CHATEE: TagType = 'CHATEE';
  export const NEWDOMAINUSER: TagType = 'NEWDOMAINUSER';
  export const UNREAD_VIRTUAL: TagType = 'UNREAD_VIRTUAL';
  export const FOLLOW: TagType = 'FOLLOW';
  export const UNFOLLOW: TagType = 'UNFOLLOW';

  export function values(): TagType[] {
    return [
      MUTED,
      UNREAD,
      DELETED,
      HIDDEN,
      SENT,
      FOLDER,
      MENTION,
      RESPONSEPENDING,
      STARRED,
      FAVORITE,
      FOCUSED,
      INBOX,
      FORUMS_G,
      PERSONAL_G,
      SOCIAL_G,
      UPDATES_G,
      PROMOTIONS_G,
      IMPORTANT,
      ARCHIVE,
      PURGE,
      SYSTEMMESSAGE,
      OUTBOX,
      SPAM,
      SNOOZE,
      SNOOZE_INTERRUPT,
      SNOOZE_DUE,
      CHATEE,
      NEWDOMAINUSER,
      UNREAD_VIRTUAL,
      FOLLOW,
      UNFOLLOW
    ];
  }
}
