import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LocationControlComponent } from '@shared/modules/form-controls/location-control/location-control.component';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { OutsideClickModule } from '@dta/ui/directives/outside-click/outside-click.module';

@NgModule({
  declarations: [LocationControlComponent],
  imports: [CommonModule, FormsModule, TextControlModule, OutsideClickModule],
  exports: [LocationControlComponent]
})
export class LocationControlModule {}
