import { IntegrationType } from '@shared/api/api-loop/models';
import { IntegrationExtraData, Type } from '@dta/shared/models-api-loop/integration.model';
import { Environment, EnvironmentType, isWebApp } from '@dta/shared/utils/common-utils';
import { CONSTANTS } from '@shared/models/constants/constants';

export let Integrations: IntegrationExtraData[] = [
  {
    serverType: IntegrationType.OPEN_AI,
    name: 'OpenAI',
    description: 'The OpenAI integration allows you to use the power of artificial intelligence (AI)',
    detailedDescription:
      'The OpenAI integration allows you to use the power of artificial intelligence (AI) directly in Loop, making it easier for you to generate emails, replies, and translate messages.',
    inAppUrl: 'openai',
    privacyPolicy: '',
    webSiteUrl: '',
    logo: 'shared/assets/img/integrations/open-ai/logo.svg',
    detailsRequired: true,
    detailImages: [
      'shared/assets/img/integrations/open-ai/open-ai-fix-grammar.png',
      'shared/assets/img/integrations/open-ai/open-ai-reply-positively.png',
      'shared/assets/img/integrations/open-ai/open-ai-reply-negatively.png',
      'shared/assets/img/integrations/open-ai/open-ai-translate.png',
    ],
    iframeUrl: getIframeUrl(IntegrationType.OPEN_AI),
    type: [Type.PLUGIN],
  },
  {
    serverType: IntegrationType.CUSTOM,
    name: 'Custom',
    description: 'Create your own integration using iframe.',
    detailedDescription:
      'With your own integration you will be able to bridge the gap between your team inboxes and your business data. The integration will let you take over a part of the user interface with your business logic!',
    inAppUrl: 'custom',
    privacyPolicy: '',
    webSiteUrl: '',
    logo: 'shared/assets/img/integrations/custom/logo.png',
    detailsRequired: true,
    type: [Type.PLUGIN],
  },
  {
    serverType: IntegrationType.HUB_SPOT,
    name: 'HubSpot',
    description:
      'The HubSpot Sales Hub integration will let you create and manage contacts, companies and deals right from your inbox.',
    detailedDescription: '',
    inAppUrl: 'hubspot',
    privacyPolicy: '',
    webSiteUrl: '',
    logo: 'shared/assets/img/integrations/hubspot/logo.png',
    comingSoon: true,
    type: [Type.PLUGIN],
  },
];

export function getIntegrationDataByType(type: IntegrationType) {
  return Integrations.find(integration => integration.serverType === type);
}

export function getIframeUrl(type: IntegrationType): string {
  let env = isWebApp() ? Environment.getWebEnvironment() : Environment.getEnvironment();

  let environment;

  switch (env) {
    case EnvironmentType.ALPHA:
    case EnvironmentType.DEVELOPMENT:
      environment = 'alpha';
      break;
    case EnvironmentType.BETA:
      environment = 'beta';
      break;
    case EnvironmentType.PRODUCTION:
    case EnvironmentType.PRODUCTION_STORE:
    default:
      environment = 'app';
      break;
  }

  switch (type) {
    case IntegrationType.OPEN_AI:
      return `https://openai-${environment}${CONSTANTS.DOMAIN}/`;
    case IntegrationType.INSIGHTS:
      return `${CONSTANTS.WEB_ONBOARDING_URL}/reporting/iframe`;
  }
}
