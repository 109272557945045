<div class="container">
  <div class="content-container">
    <!-- SVG header-->
    <svg-comp [src]="batchSelectBgSvg" class="batch-select-background"></svg-comp>

    <!-- Counters -->
    <span class="title" *ngIf="markedCardsNumber === 1">{{ markedCardsNumber }} conversation selected</span>
    <span class="title" *ngIf="markedCardsNumber > 1">{{ markedCardsNumber }} conversations selected</span>

    <div class="actions-container">
      <ng-container *ngIf="areMainActionsVisible$ | async">
        <span #assignTpl class="action" (click)="openAssign()">
          <svg-comp [src]="assignSvg"></svg-comp>
          Assign...
        </span>

        <!-- Resolve -->
        <span (click)="batchResolve()" class="action">
          <svg-comp [src]="resolveSvg"></svg-comp>
          Resolve
        </span>

        <div #moveToFolderTpl *ngIf="sharedInboxId" (click)="openAddToFolder()" class="action position-relative">
          <svg-comp [src]="folder22Svg"></svg-comp>
          Move to folder
        </div>

        <div class="flex flex-col position-relative">
          <div #labelTagsTpl class="action" (click)="openAddTag()">
            <svg-comp [src]="folder22Svg"></svg-comp>
            Add/Remove tag
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let action of actions$ | async">
        <span
          *ngIf="action.isAvailable$ ? (action.isAvailable$ | async) : true"
          (click)="action.action()"
          class="action star"
        >
          <svg-comp [src]="action.icon"></svg-comp>
          {{ action.name }}
        </span>
      </ng-container>
    </div>

    <!--      <span class="border"></span>-->

    <!--      &lt;!&ndash; Cancel &ndash;&gt;-->
    <!--      <span (click)="batchCancel()" class="action cancel">-->
    <!--        <svg-comp [src]="closeSvg"></svg-comp>-->
    <!--        Cancel-->
    <!--      </span>-->
    <!--    </div>-->
  </div>
</div>
