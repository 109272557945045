import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api/api-loop/api.module';
import { EMPTY, Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, tap } from 'rxjs/operators';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Logger } from '@shared/services/logger/logger';
import { BasePushSynchronizationService } from '@shared/synchronization/push-synchronization/base-push-synchronization/base-push-synchronization.service';
import {
  SharedTagLabelModel,
  SharedTagModel,
  StaticSharedTagPrefix,
} from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { LabelService } from '@shared/services/data/label/label.service';
import * as uuid from 'uuid';

@Injectable()
export class SharedTagLabelPushSynchronizationService extends BasePushSynchronizationService<SharedTagModel> {
  constructor(
    protected _api: ApiService,
    protected _labelService: LabelService,
  ) {
    super();
  }

  get constructorName(): string {
    return 'AvailabilityStatusPushSynchronizationService';
  }

  protected synchronize(forUserEmail: string, sharedTagLabel: SharedTagLabelModel): Observable<any> {
    if (!sharedTagLabel.id) {
      return this.synchronizeCreateLabel(forUserEmail, sharedTagLabel);
    } else if (sharedTagLabel.id) {
      return this.synchronizeUpdateLabel(forUserEmail, sharedTagLabel);
    }
  }

  protected afterSynchronize(forUserEmail: string, sharedTagLabel: SharedTagLabelModel): Observable<SharedTagModel> {
    let obs$ = _.isEmpty(sharedTagLabel) ? of(sharedTagLabel) : this._labelService.save(forUserEmail, sharedTagLabel);

    return obs$.pipe(
      tap((_sharedTagLabel: SharedTagLabelModel) => {
        PublisherService.publishEvent(forUserEmail, _sharedTagLabel);
      }),
    );
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    sharedTagLabel: SharedTagLabelModel,
  ): Observable<any> {
    // We lost connection and will retry
    if (err.status === 0) {
      return EMPTY;
    }

    Logger.error(
      err,
      `PushSync [${forUserEmail}]: Could not sync status, will not retry statusId: ${sharedTagLabel._id}`,
    );
    return of(sharedTagLabel);
  }

  private synchronizeCreateLabel(
    forUserEmail: string,
    sharedTagLabel: SharedTagLabelModel,
  ): Observable<SharedTagModel> {
    sharedTagLabel.id = `${StaticSharedTagPrefix.LABEL_PREFIX}${sharedTagLabel.workspaceId}:${uuid.v4()}`;
    return this._api.SharedTagApiService.SharedTag_CreateSharedTag({ sharedTag: sharedTagLabel }, forUserEmail).pipe(
      map(SharedTagLabelModel.create),
    );
  }

  private synchronizeUpdateLabel(
    forUserEmail: string,
    sharedTagLabel: SharedTagLabelModel,
  ): Observable<SharedTagModel> {
    return this._api.SharedTagApiService.SharedTag_UpdateSharedTag({ sharedTag: sharedTagLabel }, forUserEmail).pipe(
      map(SharedTagLabelModel.create),
    );
  }
}
