import * as _ from 'lodash';
import { EMPTY, forkJoin, from, merge, Observable, of, throwError, zip } from 'rxjs';
import { Directive } from '@angular/core';
import { AppointmentResponse } from '@shared/api/api-loop/models/appointment-response';
import {
  ActionType,
  CardAppointmentModel,
  CardBaseModel,
  CardChatModel,
  CardDraftModel,
  CardMailModel,
  CardModel,
  CardSharedModel,
  CardTemplateModel,
  SubscriptionState
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { StateUpdates } from 'dta/shared/models/state-updates';
import {
  AllSharedInboxesCollectionParams,
  ChatCardsCollectionParams,
  MyLoopInboxCollectionParams,
  PersonalInboxCollectionParams,
  SearchableViewCollectionParams
} from 'dta/shared/models/collection.model';
import {
  CardAppointment,
  CardBase,
  CardDraft,
  CardType,
  CommentBase,
  ListOfResourcesOfCardBase,
  ListOfTags,
  QueryRelation,
  SortOrder,
  TagType
} from '@shared/api/api-loop/models';
import { CardServiceI } from './card.service.interface';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { BaseService } from '../base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import {
  bufferCount,
  catchError,
  defaultIfEmpty,
  distinct,
  filter,
  map,
  mergeMap,
  pluck,
  tap,
  toArray
} from 'rxjs/operators';
import {
  CommentBaseModel,
  CommentDraftModel,
  CommentModel,
  MimeType
} from '@dta/shared/models-api-loop/comment/comment.model';
import { CommentService } from '../comment/comment.service';
import { CardApiService, CommentApiService } from '@shared/api/api-loop/services';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { TagModel } from '@dta/shared/models-api-loop/tag.model';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { UserScopeData } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SharedTagService } from '../shared-tags/shared-tags.service';
import { CardExDecorateService } from '@shared/decorators/extra-data-decorators/card-decorator/card-ex-decorate.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { Encryption, Environment, EnvironmentType, isWebApp } from '@dta/shared/utils/common-utils';
import { ContactService } from '../contact/contact.service';
import { CardPopulateService } from '@shared/populators/conversation-card-populate/card-populate/card-populate.service';
import { CardDaoService } from '@shared/database/dao/card/card-dao.service';
import { TagLabelService } from '../tag-label/tag-label.service';

@Directive()
export abstract class CardService extends BaseService implements CardServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _commentService: CommentService,
    protected _cardApiService: CardApiService,
    protected _sharedTagService: SharedTagService,
    protected _cardExDecorateService: CardExDecorateService,
    protected _contactService: ContactService,
    protected _cardPopulateService: CardPopulateService,
    protected _cardDaoService: CardDaoService,
    protected _tagLabelService: TagLabelService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'CardService';
  }

  /**
   * Save without any processing by skipping doBeforeSave and doAfterSave hooks.
   * Should never be used, with CardCreated events as an exception.
   */
  saveOnly(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    let watch = new StopWatch(this.constructorName + '.saveOnly: ' + cards.length, ProcessType.SERVICE, forUserEmail);

    // Shared tags should always be decorated
    watch.log('fetchMissingCardSharedTags');
    return this._sharedTagService.fetchMissingCardSharedTags(forUserEmail, cards).pipe(
      mergeMap((_cards: CardModel[]) => {
        watch.log('decorateListExtraData');
        return this._cardExDecorateService.decorateListExtraData(forUserEmail, _cards);
      }),
      mergeMap((_cards: CardModel[]) => {
        watch.log('saveAllCommon');
        return this.saveAllCommon(forUserEmail, _cards, watch);
      })
    );
  }

  saveAll(forUserEmail: string, cards: CardModel[] = []): Observable<StateUpdates> {
    let stateUpdates = new StateUpdates();

    if (_.isEmpty(cards)) {
      return of(stateUpdates);
    }

    let watch = new StopWatch(this.constructorName + '.saveAll: ' + cards.length, ProcessType.SERVICE, forUserEmail);

    watch.log('doBeforeSave');
    return this.doBeforeSave(forUserEmail, cards).pipe(
      mergeMap((_cards: CardModel[]) => {
        watch.log('saveAllCommon');
        return this.saveAllCommon(forUserEmail, _cards, watch);
      }),
      mergeMap((_cards: CardModel[]) => {
        stateUpdates.add(_cards);

        watch.log('doAfterSave');
        return this.doAfterSave(forUserEmail, _cards);
      }),
      map((_stateUpdates: StateUpdates) => {
        return stateUpdates.mergeWith(_stateUpdates);
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  saveAndPublish(forUserEmail: string, card: CardModel): Observable<CardModel> {
    return this.saveAllAndPublish(forUserEmail, [card]).pipe(map((cards: CardModel[]) => _.first(cards)));
  }

  saveAllAndPublish(forUserEmail: string, cards: CardModel[] = []): Observable<CardModel[]> {
    let stopWatch = new StopWatch(this.constructorName + '.saveAllAndPublish', ProcessType.SERVICE, forUserEmail);

    stopWatch.log('saveAll');
    return this.saveAll(forUserEmail, cards).pipe(
      map((stateUpdates: StateUpdates) => {
        stopWatch.log('publishStateUpdates');
        PublisherService.publishStateUpdates(forUserEmail, stateUpdates);

        // return only input cards
        return _.intersectionBy(stateUpdates.cards, cards, '_id');
      })
    );
  }

  protected saveAllCommon(forUserEmail: string, cards: CardModel[], watch: StopWatch): Observable<CardModel[]> {
    return of(cards).pipe(
      /**
       * Reduce
       */
      mergeMap((_cards: CardModel[]) => {
        watch.log('contactsToReducedForm');
        return this._cardPopulateService.reduce(forUserEmail, _cards);
      }),
      /**
       * Save to db
       */
      mergeMap((_cards: CardModel[]) => {
        watch.log('saveAll');
        return this.saveToDb(forUserEmail, _cards);
      }),
      /**
       * Populate contacts
       */
      mergeMap((_cards: CardModel[]) => {
        watch.log('populateWithContacts');
        return this._cardPopulateService.populate(forUserEmail, _cards);
      })
    );
  }

  protected saveToDb(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return this._cardDaoService.saveAll(forUserEmail, cards);
  }

  protected doBeforeSave(forUserEmail: string, cards: CardModel[]): Observable<any> {
    let watch = new StopWatch(this.constructorName + '.doBeforeSave', ProcessType.SERVICE, forUserEmail);

    return of(undefined).pipe(
      mergeMap(() => {
        watch.log('findOrFetchCommentsForCards');
        return this.findOrFetchCommentsForCards(forUserEmail, cards, true);
      }),
      mergeMap((_cards: CardModel[]) => {
        watch.log('fetchMissingCardSharedTags');
        return this._sharedTagService.fetchMissingCardSharedTags(forUserEmail, _cards);
      }),
      mergeMap((_cards: CardModel[]) => {
        watch.log('fetchMissingCardTags');
        return this._tagLabelService.fetchMissingCardTags(forUserEmail, _cards);
      }),
      mergeMap((_cards: CardModel[]) => {
        watch.log('decorateListExtraData');
        return this._cardExDecorateService.decorateListExtraData(forUserEmail, _cards);
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  protected doAfterSave(forUserEmail: string, cards: CardModel[]): Observable<StateUpdates> {
    let watch = new StopWatch(this.constructorName + '.doAfterSave', ProcessType.SERVICE, forUserEmail);
    let stateUpdates = new StateUpdates();

    return this.updateLinkedLoopinCards(forUserEmail, cards).pipe(
      tap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        watch.log('recalculateOutboxBadge');
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  removeAllAndPublish(forUserEmail: string, cards: CardModel[]): Observable<any> {
    return this._cardDaoService.removeAll(forUserEmail, cards).pipe(
      tap((_cards: CardModel[]) => {
        let stateUpdates = new StateUpdates([], [], cards);
        PublisherService.publishStateUpdates(forUserEmail, stateUpdates);
      })
    );
  }

  saveCardsAndComments(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return of(undefined).pipe(
      mergeMap(() => {
        let allComments = _.flatMap(cards, (card: CardModel) => {
          let parentRef = CardBaseModel.buildAsReference(card);

          return _.map(card.getComments(), comment => {
            comment.parent = parentRef;
            return comment;
          });
        });

        return this._commentService.saveAllAndPublish(forUserEmail, CommentBaseModel.createList(allComments));
      }),
      mergeMap(() => {
        return this.saveAll(forUserEmail, cards);
      }),
      map((stateUpdates: StateUpdates) => stateUpdates.cards)
    );
  }

  updatedAppointmentResponse(
    forUserEmail: string,
    cardId: string,
    response: AppointmentResponse
  ): Observable<CardAppointmentModel> {
    if (!cardId) {
      throw new Error('cardId cannot be nil');
    }
    if (!response) {
      throw new Error('AppointmentResponse cannot be nil');
    }

    return this.findOrFetchCardById(forUserEmail, cardId).pipe(
      mergeMap((card: CardAppointmentModel) => {
        card.response = response;
        return this.saveAndPublish(forUserEmail, card as CardModel);
      }),
      map((card: CardModel) => {
        return <CardAppointmentModel>card;
      }),
      tap((appointment: CardAppointmentModel) => {
        this.enqueuePushSynchronization(forUserEmail, appointment);
      })
    );
  }

  abstract findCardSharedBySnapshotResource(forUserEmail: string, card: CardMailModel): Observable<CardModel>;

  abstract findLoopsBySourceId(forUserEmail: string, id: string): Observable<CardModel[]>;

  abstract findDecoratedCardById(forUserEmail: string, card: CardModel): Observable<CardModel>;

  abstract findMissingCommentsForCard(forUserEmail: string, card: CardModel): Observable<CardModel>;

  abstract findAllSharedInboxCards(
    forUserEmail: string,
    params: AllSharedInboxesCollectionParams
  ): Observable<CardModel[]>;

  abstract findAppointments(forUserEmail: string, params: any): Observable<CardModel[]>;

  abstract findCardBySourceResourceId(forUserEmail: string, id: string): Observable<CardModel>;

  abstract republishCard(forUserEmail: string, cardId: string): Observable<CardModel>;

  abstract filterNewCards(forUserEmail: string, cards: CardBase[]): Observable<CardBase[]>;

  abstract findLocalChatCardsByShareList(forUserEmail: string, emailsFromChatCards: string[]): Observable<CardBase[]>;

  abstract fetchMissingSourceCards(forUserEmail: string, sharedCards: CardSharedModel[]): Observable<StateUpdates>;

  abstract decorateCardsExtraData(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]>;

  abstract findCardIdsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string
  ): Observable<string[]>;

  protected linkSnapshotResourceToCardShared(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    let { cardsShared, otherCards } = _.groupBy(cards, card =>
      card instanceof CardSharedModel ? 'cardsShared' : 'otherCards'
    );

    if (_.isEmpty(cardsShared)) {
      return of(cards);
    }

    return of(undefined).pipe(
      mergeMap(() => {
        let snapshotResourceIds = cardsShared
          .map((card: CardSharedModel) => card.snapshotResource?.id)
          .filter(id => id !== undefined);

        return this.findOrFetchCardsById(forUserEmail, snapshotResourceIds);
      }),
      mergeMap((_cards: CardModel[]) => {
        let cardsById: _.Dictionary<CardModel> = _.keyBy(_cards, 'id');

        let cardsWithLinkedSnapshot = cardsShared.map((card: CardSharedModel) => {
          if (card.snapshotResource?.id) {
            card.snapshotResource = cardsById[card.snapshotResource.id] || card.snapshotResource;
          }
          return card;
        });

        return of([...cardsWithLinkedSnapshot, ...(otherCards || [])]);
      }),
      defaultIfEmpty(cards)
    );
  }

  moveToTeam(forUserEmail: string, cardId: string, groupId: string) {
    let params: CardApiService.Card_CardMoveToTeamResponseParams = {
      id: cardId,
      groupId: groupId
    };

    return this._cardApiService.Card_CardMoveToTeamResponse(params, forUserEmail);
  }

  deleteDraftCard(forUserEmail: string, card: CardDraftModel): Observable<any> {
    let params: CardApiService.Card_DeleteCardDraftParams = {
      cardDraft: card
    };

    return this._cardApiService.Card_DeleteCardDraft(params, forUserEmail);
  }

  findAllGroupsForCards(forUserEmail: string, cards: CardModel[]): Observable<ContactModel[]> {
    let groups = _.map(cards, card => card.findGroupInShareList());
    let groupIds = _.map(groups, group => group && group.id);

    return this._contactService.findOrFetchContactsById(forUserEmail, _.compact(groupIds)).pipe(defaultIfEmpty([]));
  }

  /* true: all cards that have ARCHIVE and DELETE action in shared context */
  /* false: else */
  groupCardsByActionContext(
    forUserEmail: string,
    cards: CardModel[],
    sharedActionCardIds: Object
  ): Observable<_.Dictionary<CardModel[]>> {
    return of(undefined).pipe(
      map(() => {
        let cardsByActionContext = _.groupBy(cards, card => {
          return sharedActionCardIds[card.id] ? ActionType.SHARED : ActionType.PERSONAL;
        });

        return cardsByActionContext;
      })
    );
  }

  fetchAllMissingCommentsForCards(forUserEmail: string): Observable<StateUpdates> {
    return this.findCardsWithoutSynchronizedComments(forUserEmail).pipe(
      mergeMap((cards: CardModel[]) => {
        return this.fetchMissingCommentsForCards(cards, forUserEmail);
      })
    );
  }

  private fetchMissingCommentsForCards(cards: CardModel[], forUserEmail: string): Observable<StateUpdates> {
    if (_.isEmpty(cards)) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(
      this.constructorName + '.fetchMissingCommentsForCards',
      ProcessType.SERVICE,
      forUserEmail
    );

    watch.log('Found', cards.length, 'cards without synchronized comments');
    /**
     * Fetch missing comments
     */
    return this._commentService.fetchAndSaveCommentsForCards(forUserEmail, cards, false, false).pipe(
      /**
       * Save cards
       */
      mergeMap((_cards: CardModel[]) => {
        return this.findCards(forUserEmail, cards);
      }),
      mergeMap((_cards: CardModel[]) => {
        return this.saveAll(forUserEmail, _cards);
      }),
      tap(() => {
        watch.log('done');
      }),
      defaultIfEmpty(new StateUpdates()),
      catchError(err => {
        Logger.log(err, 'Could not fetchMissingCommentsForCards');
        return of(new StateUpdates());
      })
    ) as Observable<StateUpdates>;
  }

  abstract fetchOrCreateMeToMeChatCard(forUserEmail: string): Observable<CardChatModel>;

  fetchMissingSourceCardsByIds(forUserEmail: string, sharedCardsIds: string[]): Observable<StateUpdates> {
    if (_.isEmpty(sharedCardsIds)) {
      return of(new StateUpdates());
    }

    let watch = new StopWatch(
      this.constructorName + '.fetchMissingSourceCardsByIds: ' + sharedCardsIds.length,
      ProcessType.SERVICE,
      forUserEmail
    );

    watch.log('findByIds');
    return this.findByIds(forUserEmail, sharedCardsIds).pipe(
      // Filter out all non sharedCards
      mergeMap((cards: CardModel[]) => {
        return from(cards);
      }),
      filter((card: CardModel) => {
        return card instanceof CardSharedModel;
      }),
      toArray(),
      mergeMap((cards: CardModel[]) => {
        watch.log('fetchMissingSourceCards: ' + cards.length);
        return this.fetchMissingSourceCards(forUserEmail, cards as CardSharedModel[]);
      })
    );
  }

  filterNewNonStubCards(forUserEmail: string, cards: CardBase[]): Observable<CardBase[]> {
    return of(cards);
  }

  stopSharingLoop(forUserEmail: string, card: CardModel): Observable<CardModel> {
    let _card: CardSharedModel = <CardSharedModel>CardSharedModel.create(_.cloneDeep(card));
    _card.isLive = false;
    return this.saveAndPublish(forUserEmail, _card).pipe(
      tap((savedCard: CardModel) => {
        this.enqueuePushSynchronization(forUserEmail, <CardSharedModel>savedCard);
      })
    );
  }

  protected _findCommentsForCards(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeAllLoopinComments: boolean
  ): Observable<CommentModel[]> {
    let commentDraftIds = _.compact(
      _.map(cards, (card: CardModel) => (card instanceof CardDraftModel ? card.commentDraft.id : undefined))
    );

    let cardsByType = _.partition(cards, (card: CardModel) =>
      includeAllLoopinComments
        ? card instanceof CardChatModel
        : card instanceof CardChatModel || card instanceof CardSharedModel
    );

    let chatCards = cardsByType[0];
    let otherCards = cardsByType[1];

    let chatCommentsObs = this.findUnreadAndLastCommentsForChatCards(forUserEmail, chatCards, true);

    let otherCommentsObs = this._commentService.findCommentsByCards(forUserEmail, otherCards, baseResourceOnly, true);

    return forkJoin([chatCommentsObs, otherCommentsObs]).pipe(
      map((comments: CommentModel[][]) => {
        let chatComments = comments[0];
        let otherComments = comments[1];
        return _.unionBy(chatComments, otherComments, '_id');
      }),
      mergeMap((comments: CommentModel[]) => {
        return this.fetchMissingDraftComments(forUserEmail, comments, commentDraftIds);
      })
    );
  }

  getChatCardSubscriptionStatus(forUserEmail: string, cardId: string): Observable<SubscriptionState> {
    return this.findChatCardSubscriptionStatus(forUserEmail, cardId).pipe(
      catchError(err => {
        return this.fetchAndSaveCard(forUserEmail, cardId);
      }),
      mergeMap((card: CardModel) => {
        return of(card._ex.subscriptionState);
      })
    );
  }

  private fetchMissingDraftComments(
    forUserEmail: string,
    comments: CommentModel[],
    commentDraftIds: string[]
  ): Observable<CommentModel[]> {
    if (_.isEmpty(commentDraftIds)) {
      return of(comments);
    }

    let localCommentDraftIds = _.compact(
      _.map(comments, (comment: CommentModel) => {
        return comment instanceof CommentDraftModel ? comment.id : undefined;
      })
    );

    let missingCommentIds = _.difference(commentDraftIds, localCommentDraftIds);

    if (!missingCommentIds || missingCommentIds.length === 0) {
      return of(comments);
    }

    let missingCommentDrafts = _.map(missingCommentIds, (_id: string) => {
      return CommentDraftModel.create({
        $type: CommentDraftModel.type,
        id: _id
      });
    });

    return this._commentService.fetchComments(forUserEmail, missingCommentDrafts).pipe(
      mergeMap((_comments: CommentModel[]) => {
        return this._commentService.saveAll(forUserEmail, _comments);
      }),
      map((_comments: CommentModel[]) => {
        return [...comments, ..._comments];
      })
    );
  }

  findOrFetchCardById(forUserEmail: string, cardId: string): Observable<CardModel> {
    return this.findById(forUserEmail, cardId).pipe(
      catchError(err => {
        return of(undefined);
      }),
      mergeMap((card: CardModel) => {
        if (_.isEmpty(card) && !cardId.startsWith(BaseModel.idPrefix)) {
          return this.fetchAndSaveCard(forUserEmail, cardId);
        }

        return of(card);
      })
    );
  }

  fetchCardsAndCommentsById(forUserEmail: string, cardIds: string[]): Observable<any> {
    return this.fetchListOfCardsFromConversations(forUserEmail, cardIds).pipe(
      mergeMap((cards: CardModel[]) => {
        let partitionedCards = _.partition(cards, card => card.$type === CardChatModel.type);

        let chatCards = partitionedCards[0];
        let otherCards = partitionedCards[1];

        return forkJoin([
          /**
           * Fetch chat comments and publish them
           */
          this.fetchCommentsForCards(forUserEmail, chatCards).pipe(
            tap((stateUpdates: StateUpdates) => {
              PublisherService.publishStateUpdates(forUserEmail, stateUpdates);
            })
          ),
          /**
           * Fetch comments and map them to cards
           */
          this._commentService
            .fetchCommentsAndSaveForCardIds(
              forUserEmail,
              _.map(otherCards, card => card.id),
              false
            )
            .pipe(
              map((comments: CommentModel[][]) => {
                return _.flatten(comments);
              }),
              mergeMap((comments: CommentModel[]) => {
                return this.mapCommentsToCards(otherCards, comments);
              }),
              mergeMap((_cards: CardModel[]) => {
                return this.saveAllAndPublish(forUserEmail, _cards);
              })
            )
        ]);
      })
    );
  }

  findOrFetchCardsById(forUserEmail: string, cardIds: string[]): Observable<CardModel[]> {
    if (_.isEmpty(cardIds)) {
      return of([]);
    }

    return this.findByIds(forUserEmail, cardIds).pipe(
      catchError(err => {
        return of([]);
      }),
      mergeMap((localCards: CardModel[]) => {
        let localCardIds = _.map(localCards, (card: CardModel) => card.id);
        let missingCardIds = _.difference(cardIds, localCardIds);

        return this.fetchListOfCards(forUserEmail, missingCardIds).pipe(
          catchError(err => {
            // When offline, return empty
            if (err.status === 0) {
              return of([]);
            }

            Logger.customLog('Error when fetching comments for cards in CommentService. Err: ' + err, LogLevel.ERROR);

            return throwError(() => err);
          }),
          map((apiCards: CardModel[]) => {
            return [...localCards, ...apiCards];
          })
        );
      })
    );
  }

  findOrFetchChatCardByContactId(forUserEmail: string, contactId: string): Observable<CardChatModel> {
    return this.findChatCardByContactId(forUserEmail, contactId).pipe(
      mergeMap((card: CardChatModel) => {
        if (!card) {
          return this.fetchAndSaveChatCardByContactId(forUserEmail, contactId);
        } else {
          return of(card);
        }
      })
    );
  }

  private fetchAndSaveChatCardByContactId(forUserEmail: string, contactId: string): Observable<CardChatModel> {
    if (contactId.startsWith(BaseModel.idPrefix)) {
      return of(undefined);
    }

    return this._cardApiService
      .Card_GetList(
        {
          offset: 0,
          size: 1024,
          cardTypes: [CardType.CARD_CHAT],
          recipientIds: [contactId]
        },
        forUserEmail
      )
      .pipe(
        mergeMap((response: ListOfResourcesOfCardBase) => {
          let models = CardBaseModel.createList(response.resources);
          return from(models);
        }),
        filter((card: CardModel) => {
          return CardBaseModel.isSupported(card);
        }),
        toArray(),
        mergeMap((_cards: CardModel[]) => {
          if (_.isEmpty(_cards)) {
            return of(undefined);
          }

          if (_cards.length > 1) {
            Logger.customLog('Got more than one chat card for contactId: ' + contactId, LogLevel.ERROR);
          }

          let [chatCard] = _cards;
          chatCard.removeComments();

          return this.saveChatCardAndComments(forUserEmail, chatCard);
        }),
        defaultIfEmpty(undefined)
      );
  }

  private saveChatCardAndComments(forUserEmail: string, card: CardModel): Observable<CardChatModel> {
    if (!card) {
      return of(undefined);
    }

    return this.saveCardsComments(forUserEmail, [card]).pipe(
      mergeMap(() => {
        return this.saveOnly(forUserEmail, [card]);
      }),
      map((cards: CardModel[]) => {
        return <CardChatModel>_.first(cards);
      })
    );
  }

  protected saveCardsComments(forUserEmail: string, cards: CardModel[]): Observable<CommentModel[]> {
    return from(cards).pipe(
      mergeMap((card: CardModel) => {
        let cardRef = CardBaseModel.buildAsReference(card);
        return from(card.getComments()).pipe(
          map((comment: CommentBase) => {
            comment.parent = cardRef;
            return CommentBaseModel.create(comment);
          })
        );
      }),
      toArray(),
      mergeMap((comments: CommentModel[]) => {
        return this._commentService.saveAllAndPublish(forUserEmail, comments);
      })
    );
  }

  protected findUnreadAndLastCommentsForChatCards(
    forUserEmail: string,
    cards: CardChatModel[],
    includeSnapshotComments?: boolean
  ): Observable<CommentModel[]> {
    let watch = new StopWatch(
      this.constructorName + '.findUnreadAndLastCommentsForChatCards',
      ProcessType.SERVICE,
      forUserEmail
    );

    return this._commentService.findGroupedCommentIdsByCards(forUserEmail, cards, includeSnapshotComments).pipe(
      mergeMap((commentIdsByParentId: _.Dictionary<CommentBase[]>) => {
        watch.log('findGroupedCommentIdsByCards');
        return from(_.values(commentIdsByParentId));
      }),
      /**
       * Find last comment and any unread comments
       */
      mergeMap((comments: CommentBase[]) => {
        let lastComment = _.last(comments);
        let unreadComments = this.findUnreadCommentsBase(comments);

        comments = _.concat(unreadComments, lastComment);
        comments = _.uniqBy(_.compact(comments), '_id');

        return from(comments);
      }),
      toArray(),
      mergeMap((comments: CommentBase[]) => {
        return this._commentService.findComments(forUserEmail, comments);
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  private findUnreadCommentsBase(comments: CommentBase[]): CommentBase[] {
    return comments.filter((comment: CommentBase) => {
      if (!_.has(comment, 'tags.tags.resources')) {
        return false;
      }

      return _.some(comment.tags.tags.resources, { id: TagType.UNREAD });
    });
  }

  protected findChatCardSubscriptionStatus(
    forUserEmail: string,
    cardId: string
  ): Observable<{ _ex: { subscriptionState: SubscriptionState } }> {
    return this._cardDaoService.findChatCardSubscriptionStatus(forUserEmail, cardId);
  }

  protected findOrFetchCommentsForCards(
    forUserEmail: string,
    cards: CardModel[],
    includeAllLoopinComments: boolean,
    getBodyFromDisc?: boolean
  ): Observable<CardModel[]> {
    return of(undefined).pipe(
      /**
       * Find comments for cards
       */
      mergeMap(() => this.findCommentsForCards(forUserEmail, cards, includeAllLoopinComments, getBodyFromDisc)),
      /**
       * Fetch comments for cards with no comments
       */
      mergeMap((_cards: CardModel[]) => {
        let needCommentFetch = _.groupBy(
          _cards,
          (card: CardModel) =>
            !!card.id && // Is not local
            !card.hasComments() && // Has no comments
            card.$type !== CardTemplateModel.type && // Needs comments to be synced
            card.$type !== CardDraftModel.type &&
            card.$type !== CardAppointmentModel.type
        );

        let cardsInNeedOfCommentFetch = needCommentFetch['true'] || [];
        let cardsWithComments = needCommentFetch['false'] || [];

        if (_.isEmpty(cardsInNeedOfCommentFetch)) {
          return of(cardsWithComments);
        }

        return this.fetchCommentsForCards(forUserEmail, cardsInNeedOfCommentFetch, true).pipe(
          map((stateUpdates: StateUpdates) => stateUpdates.cards),
          // Return local resource in offline mode
          catchError(err => (err.status === 0 ? of(cardsInNeedOfCommentFetch) : throwError(() => err))),
          /**
           * Merge fetched cards with given ones
           */
          map((fetchedCards: CardModel[]) => {
            let cardsInNeedOfCommentFetchById = _.keyBy(cardsInNeedOfCommentFetch, 'id');
            let updatedCards = _.map(fetchedCards, card => _.merge(card, cardsInNeedOfCommentFetchById[card.id] || {}));

            return [...updatedCards, ...cardsWithComments];
          })
        );
      })
    );
  }

  findCommentsForCards(
    forUserEmail: string,
    cards: CardModel[],
    includeAllLoopinComments: boolean,
    getBodyFromDisc?: boolean
  ): Observable<CardModel[]> {
    if (_.isEmpty(cards)) {
      return of(cards);
    }

    let watch = new StopWatch(this.constructorName + '.findCommentsForCards', ProcessType.SERVICE, forUserEmail);
    return of(undefined).pipe(
      /**
       * Link snapshotResource to cards
       */
      mergeMap(() => this.linkSnapshotResourceToCardShared(forUserEmail, cards)),
      /**
       * Find comments for cards
       */
      mergeMap((_cards: CardModel[]) =>
        this._findCommentsForCards(forUserEmail, _cards, false, includeAllLoopinComments)
      ),
      mergeMap((comments: CommentModel[]) => {
        if (getBodyFromDisc) {
          return this._commentService.loadMissingBodiesFromDisc(forUserEmail, comments);
        }

        return of(comments);
      }),
      /**
       * Append bodies from disc if necessary
       */
      mergeMap((comments: CommentModel[]) => {
        watch.log(`Found ${comments.length} comments for ${cards.length} cards`);
        return this.mapCommentsToCards(cards, comments);
      }),
      /**
       * Log as done
       */
      tap(() => watch.log(`Done`))
    );
  }

  findCommentsForCard(
    forUserEmail: string,
    card: CardModel,
    shouldFindAppointmentComments: boolean = true
  ): Observable<CardModel> {
    card = CardBaseModel.create(card);
    if (card.isAppointment() && shouldFindAppointmentComments) {
      return this.findAppointmentComments(forUserEmail, card);
    }

    let watch = new StopWatch(this.constructorName + '.findCommentsForCard', ProcessType.SERVICE, forUserEmail);
    let commentCount = 0;

    return this._commentService.findCommentsByCard(forUserEmail, card).pipe(
      mergeMap((comments: CommentModel[]) => {
        commentCount = comments.length;
        return this.mapCommentsToCards([card], comments);
      }),
      map((cards: CardModel[]) => {
        return _.first(cards);
      }),
      tap(() => {
        watch.log(`Found ${commentCount} comments for card`);
      })
    );
  }

  protected mapCommentsToCards(cards: CardModel[], comments: CommentModel[]): Observable<CardModel[]> {
    let commentsByCardId = _.groupBy(comments, comment => {
      return comment.parent['_id'] ? comment.parent['_id'] : comment.parent.id;
    });
    return from(cards).pipe(
      map((card: CardModel) => {
        let commentsById = (commentsByCardId[card.id] as CommentModel[]) || [];
        let commentsBy_id = (commentsByCardId[card._id] as CommentModel[]) || [];

        let _comments: CommentModel[] = _.uniqBy([...commentsById, ...commentsBy_id], '_id');

        if (_.isEmpty(_comments)) {
          return card;
        }

        if (card instanceof CardDraftModel) {
          this.mapCommentsToDraftCard(card, _comments);
        }

        // Update Card
        if (!_.isEmpty(_comments)) {
          if (card.comments) {
            card.comments.resources = _comments;
            card.comments.size = _.filter(_comments, comment => comment.$type !== CommentDraftModel.type).length;
            // Update totalSize
            card.comments.totalSize =
              card.comments.size > card.comments.totalSize ? card.comments.size : card.comments.totalSize;
          } else {
            card.comments = BaseModel.createListOfResources(_comments);
          }
        }

        // Update sourceResource or. SnapshotResource
        if (card instanceof CardSharedModel) {
          this.mapCommentsToCardShared(card as CardSharedModel, commentsByCardId as _.Dictionary<CommentModel[]>);
        }

        // Comments must be sorted
        if (card.comments) {
          card.comments.resources = BaseModel.sortListByCreated(card.comments.resources);
        }
        return card;
      }),
      toArray()
    );
  }

  private mapCommentsToCardShared(card: CardSharedModel, commentsByCardId: _.Dictionary<CommentModel[]>) {
    // Prefer source resource if liveLoop
    if (card.sourceResource && card.isLive) {
      let [_id, id] = [card.sourceResource['_id'], card.sourceResource.id];
      let comments = commentsByCardId[_id] || commentsByCardId[id];

      if (!_.isEmpty(comments)) {
        card.sourceResource.comments = BaseModel.createListOfResources(_.sortBy(comments, 'created'));
        return;
      }
    }

    // Use source resource in when no access to original email or fallback
    if (card.snapshotResource && card.snapshotResource.id) {
      let [_id, id] = [card.snapshotResource['_id'], card.snapshotResource.id];
      let comments = commentsByCardId[_id] || card.snapshotResource[id];

      if (!_.isEmpty(comments)) {
        card.snapshotResource.comments = BaseModel.createListOfResources(_.sortBy(comments, 'created'));
      }
    }
  }

  private mapCommentsToDraftCard(card: CardDraftModel, comments: CommentModel[]) {
    let draftComment = _.find(comments, (comment: CommentModel) => comment instanceof CommentDraftModel);

    card.commentDraft = draftComment;
    card.name = draftComment?.name;
    _.remove(comments, (comment: CommentModel) => comment instanceof CommentDraftModel);
  }

  private findAppointmentComments(forUserEmail: string, card: CardModel): Observable<CardModel> {
    let card$: Observable<CardModel>;
    let appointment$: Observable<CardModel>;

    if (card instanceof CardMailModel && card.appointmentLink) {
      card$ = of(card);
      appointment$ = this.findOrFullyFetchCardById(forUserEmail, card.appointmentLink.id);
    } else {
      card$ = this.findCardByAppointmentLink(forUserEmail, card);
      appointment$ = of(card);
    }

    return forkJoin([card$, appointment$]).pipe(
      map((cards: CardModel[]) => {
        // Remove cards that were not found
        return _.compact(cards);
      }),
      mergeMap((cards: CardModel[]) => {
        return this.findCommentsForCards(forUserEmail, cards, false);
      }),
      map((cards: CardModel[]) => {
        let _card = <CardMailModel>cards[0];
        let appointment = <CardAppointmentModel>cards[1];

        // handle missing card or appointment
        if (_card && !appointment) {
          return _card;
        }
        if (!_card && appointment) {
          return appointment;
        }

        _card.name = appointment.name;
        _card.appointmentLink = appointment;

        return _card;
      })
    );
  }

  /**
   * Fully fetch means that all comments will be fetched as well
   */
  private findOrFullyFetchCardById(forUserEmail: string, cardId: string): Observable<CardModel> {
    return this.findById(forUserEmail, cardId).pipe(
      catchError(err => {
        if (err.status === 404) {
          // Fetch missing card
          let cardStub = {
            id: cardId
          } as CardBaseModel;

          return of(undefined).pipe(
            /**
             * Fetch card with comments
             */
            mergeMap(() => {
              return this._commentService.fetchAndSaveCommentsForCards(forUserEmail, [cardStub], false, true);
            }),
            /**
             * Save card so it gets decorated
             */
            mergeMap((cards: CardModel[]) => {
              return this.saveAllAndPublish(forUserEmail, cards);
            }),
            map((cards: CardModel[]) => {
              return cards[0];
            })
          );
        }
        return throwError(err);
      })
    );
  }

  protected updateSourceCardsLiveLoopStatus(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return of([]);
  }

  private updateLinkedLoopinCards(forUserEmail: string, cards: CardModel[]): Observable<StateUpdates> {
    let watch = new StopWatch(this.constructorName + '.updateLinkedLoopinCards', ProcessType.SERVICE, forUserEmail);
    let stateUpdates = new StateUpdates();

    return forkJoin([
      this.updateLoopsBySourceAndSnapshots(forUserEmail, cards),
      this.updateSourceCardsLiveLoopStatus(forUserEmail, cards)
    ]).pipe(
      map((listOfCards: Array<CardModel[]>) => {
        return _.flatten(listOfCards);
      }),
      mergeMap((_cards: CardModel[]) => {
        watch.log('saveOnly');
        return this.saveOnly(forUserEmail, _cards);
      }),
      mergeMap((_cards: CardModel[]) => {
        stateUpdates.add(_cards);

        // we need to publish remove event, so some inboxes can hide the card with Live loop
        watch.log('filterCardsWithLiveLoops');
        return this.filterCardsWithLiveLoops(stateUpdates.cards);
      }),
      tap((_cards: CardModel[]) => {
        stateUpdates.addToRemove(_cards);
      }),
      map(() => {
        return stateUpdates;
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  private filterCardsWithLiveLoops(cards: CardModel[]): Observable<CardModel[]> {
    return from(cards).pipe(
      filter((card: CardModel) => {
        return card instanceof CardMailModel && card._ex.hasLiveLoop;
      }),
      toArray()
    );
  }

  fetchCommentsForCards(
    forUserEmail: string,
    cards: CardBase[],
    includeMailCommentsForCardShared: boolean = false
  ): Observable<StateUpdates> {
    if (_.isEmpty(cards)) {
      return of(new StateUpdates());
    }

    return from(cards).pipe(
      mergeMap((card: CardBase) => {
        return this.getCardComments(forUserEmail, card, includeMailCommentsForCardShared);
      }, 5),
      toArray(),
      mergeMap((cardsWithComments: CardModel[]) => {
        let stateUpdates = new StateUpdates();
        stateUpdates.add(cardsWithComments);

        let allComments = this.getCommentsFromCards(cardsWithComments);
        return this._commentService
          .saveAll(forUserEmail, allComments)
          .pipe(map((comments: CommentModel[]) => stateUpdates.add(comments)));
      })
    );
  }

  private getCommentsFromCards(cards: CardModel[]): CommentModel[] {
    let comments: CommentBase[] = _.flatten(
      _.map(cards, card => {
        if (card instanceof CardDraftModel) {
          return [...card.getComments(), card.getDraftComment()];
        } else {
          return card.getComments();
        }
      })
    );
    return CommentBaseModel.createList(comments);
  }

  /**
   * When we save card email that has loops associated with it, we need to update those loops
   * as their decoration might have changed because of new comments on source card.
   */
  private updateLoopsBySourceAndSnapshots(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    let watch = new StopWatch(
      this.constructorName + '.updateLoopsBySourceAndSnapshots',
      ProcessType.SERVICE,
      forUserEmail
    );
    return this.findLoopsBySourceAndSnapshots(forUserEmail, cards).pipe(
      mergeMap((_cards: CardModel[]) => {
        watch.log('findCommentsForCards');
        return this.findCommentsForCards(forUserEmail, _cards, true);
      }),
      mergeMap((_cards: CardModel[]) => {
        watch.log('decorateListExtraData');
        return this._cardExDecorateService.decorateListExtraData(forUserEmail, _cards);
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  findLoopsBySourceAndSnapshots(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return from(cards).pipe(
      filter((card: CardModel) => {
        return card instanceof CardMailModel;
      }),
      map((card: CardModel) => {
        return <CardMailModel>card;
      }),
      filter((card: CardMailModel) => {
        return card.isSnapshot || card.hasCopiedCardIds();
      }),
      toArray(),
      mergeMap((sourceCards: CardMailModel[]) => {
        return this.findLoopsBySourceAndSnapshotIds(forUserEmail, sourceCards, cards);
      }),
      defaultIfEmpty([])
    );
  }

  fetchAndSaveCard(forUserEmail: string, cardId: string): Observable<CardModel> {
    return this.fetchCard(forUserEmail, cardId).pipe(
      mergeMap((card: CardModel) => this.saveAndPublish(forUserEmail, card))
    );
  }

  fetchCard(forUserEmail: string, cardId: string): Observable<CardModel> {
    return this._cardApiService
      .Card_Get(
        {
          id: cardId,
          sizeComments: 0
        },
        forUserEmail
      )
      .pipe(map(CardBaseModel.create));
  }

  getCardCommentsById(
    forUserEmail: string,
    id: string,
    includeMailCommentsForCardShared: boolean = false
  ): Observable<CardModel> {
    let card = new CardMailModel();
    card.id = id;
    return this.getCardComments(forUserEmail, card, includeMailCommentsForCardShared);
  }

  getCardComments(
    forUserEmail: string,
    card: CardBase,
    includeMailCommentsForCardShared: boolean = false
  ): Observable<CardModel> {
    if (!card || !card.id) {
      throw new Error('Card.id cannot be empty');
    }

    if (card instanceof CardDraftModel) {
      return this.getCommentsForDraftCards(forUserEmail, card);
    }

    return this._getCardComments(forUserEmail, card, includeMailCommentsForCardShared).pipe(
      mergeMap((_card: CardModel) => {
        let comments = CommentBaseModel.createList(_card.getComments());
        return this._commentService.updateCommentsBody(forUserEmail, comments).pipe(
          map((_comments: CommentModel[]) => {
            _card.comments.resources = _comments;
            return _card;
          })
        );
      })
    );
  }

  private getCommentsForDraftCards(forUserEmail: string, card: CardDraftModel): Observable<CardModel> {
    let draftComment = card.commentDraft;
    return this._commentService.fetchComments(forUserEmail, [draftComment]).pipe(
      mergeMap((_draftComment: CommentModel[]) => {
        return zip(of(_draftComment[0]), this._getCardComments(forUserEmail, card));
      }),
      map((result: [CommentModel, CardModel]) => {
        let _card = <CardDraftModel>result[1];
        _card.commentDraft = result[0];
        return _card;
      })
    );
  }

  protected _getCardComments(
    forUserEmail: string,
    card: CardBase,
    includeMailCommentsForCardShared: boolean = false
  ): Observable<CardModel> {
    let size = card.$type === CardChatModel.type ? 30 : 1024;
    let params: CardApiService.Card_GetParams = {
      id: card.id,
      // Currently we don't need copied comments on SharedCard,
      // since we are fetching them separately for SnapshotCard
      // offsetCopiedComments: 0,
      // sizeCopiedComments: size,
      offsetComments: 0,
      sizeComments: size,
      orderComments: SortOrder.DESCENDING,
      htmlFormat: MimeType.html
    };

    // Fetch all loopin data in one API call
    if (includeMailCommentsForCardShared) {
      params.offsetCopiedComments = 0;
      params.sizeCopiedComments = size;
      params.orderCopiedComments = SortOrder.DESCENDING;
      params.includeFullCopiedCard = true;
    }

    if (Environment.getEnvironment() === EnvironmentType.WEB_APP) {
      params.includeSignedLinks = true;
    }

    return this._cardApiService.Card_Get(params, forUserEmail).pipe(
      map(CardBaseModel.create),
      /**
       * Save card
       */
      mergeMap((_card: CardModel) => {
        let newCards = [_card];

        // Build Mail card from copied comments and save it
        if (_card.$type === CardSharedModel.type && includeMailCommentsForCardShared) {
          let snapshotResource = (<CardSharedModel>_card).snapshotResource;
          newCards.push(CardBaseModel.create(snapshotResource));
        }

        // 1-on-1 chat cards and newly created loopinCards can be without comments
        return forkJoin([
          this.saveCardsAndComments(
            forUserEmail,
            newCards.filter(c => c.hasComments())
          ),
          of(newCards.filter(c => !c.hasComments()))
        ]);
      }),
      map(_.flatten),
      /**
       * Return card that was requested
       */
      map((cards: CardModel[]) => _.find(cards, c => c.id === card.id))
    );
  }

  protected setCommentsReadStatus(
    forUserEmail: string,
    comments: CommentModel[],
    status: 'read' | 'unread'
  ): Observable<CommentModel[]> {
    let watch = new StopWatch(this.constructorName + '.setCommentsReadStatus', ProcessType.SERVICE, forUserEmail);

    watch.log(`will set status to: ${status}`);
    return status === 'read'
      ? this._commentService.removeTagsByComments(forUserEmail, comments, [
          TagModel.buildSystemTag(TagType.UNREAD),
          TagModel.buildSystemTag(TagType.UNREAD_VIRTUAL)
        ])
      : this._commentService.addTagByComments(forUserEmail, comments, TagModel.buildSystemTag(TagType.UNREAD));
  }

  protected findSyncedByIds(forUserEmail: string, comments: CommentBase[]): Observable<CardModel[]> {
    return of([]);
  }

  protected fetchCards(
    forUserEmail: string,
    cards: CardBase[],
    validTotalCommentSize?: boolean
  ): Observable<CardModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    return from(cards).pipe(
      filter((card: CardBase) => {
        /**
         * #5991 appointment cards have missing fields on list get, so this is a workaround
         * that forces appointment cards to be fetched via card get.
         */
        return !_.isEmpty(card.id) && card.$type !== CardAppointmentModel.type;
      }),
      pluck('id'),
      toArray(),
      mergeMap((cardIds: string[]) => {
        return this.fetchCardsAndSharedCards(forUserEmail, cardIds, validTotalCommentSize);
      })
    ) as Observable<CardModel[]>;
  }

  findCommentCards(
    forUserEmail: string,
    comments: CommentBase[],
    validTotalCommentSize?: boolean
  ): Observable<CardModel[]> {
    let uniqCommentsByCard = _.uniqBy(comments, 'parent.id');
    let uniqCards: CardBase[] = _.map(uniqCommentsByCard, 'parent');

    return this.findSyncedByIds(forUserEmail, uniqCards);
  }

  findOrFetchCommentCards(
    forUserEmail: string,
    comments: CommentBase[],
    validTotalCommentSize?: boolean
  ): Observable<CardModel[]> {
    let uniqCommentsByCard = _.uniqBy(comments, 'parent.id');
    let uniqCards: CardBase[] = _.map(uniqCommentsByCard, 'parent');

    return this.findSyncedByIds(forUserEmail, uniqCards).pipe(
      mergeMap((dbCards: CardModel[]) => {
        let missingCards = _.differenceBy(uniqCards, dbCards, 'id');

        return this.fetchCards(forUserEmail, missingCards, validTotalCommentSize).pipe(
          map((apiCards: CardModel[]) => {
            return [...dbCards, ...apiCards];
          })
        );
      })
    );
  }

  fetchCardsAndSharedCards(
    forUserEmail: string,
    cardIds: string[],
    validTotalCommentSize?: boolean
  ): Observable<CardModel[]> {
    return this.fetchListOfCards(forUserEmail, cardIds, undefined, validTotalCommentSize).pipe(
      mergeMap((cards: CardModel[]) => {
        return this.fetchMissingSharedCards(forUserEmail, cards);
      })
    );
  }

  /**
   * For a list of cards find the ones that are snapshot and fetch missing SharedCards
   */
  fetchMissingSharedCards(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    let parentCardSharedIds: string[] = [];
    let watch = new StopWatch(this.constructorName + '.fetchMissingSharedCards', ProcessType.SERVICE, forUserEmail);

    return from(cards).pipe(
      /**
       * Filter for snapshots
       */
      filter((card: CardModel) => {
        return (
          card.$type === CardMailModel.type &&
          (<CardMailModel>card).isSnapshot &&
          !_.isEmpty((<CardMailModel>card).loopInResource)
        );
      }),
      map((card: CardModel) => {
        return <CardMailModel>card;
      }),
      toArray(),
      mergeMap((_cards: CardMailModel[]) => {
        parentCardSharedIds = _.map(_cards, card => card.loopInResource.id);

        watch.log('parentCardSharedIds.count = ' + parentCardSharedIds.length);
        return this.findByIds(forUserEmail, parentCardSharedIds);
      }),
      mergeMap((dbCards: CardModel[]) => {
        let dbCardSharedIds = _.map(dbCards, card => card.id);
        let missingCardSharedIds = _.difference(parentCardSharedIds, dbCardSharedIds);

        watch.log('missingCardSharedIds.count = ' + missingCardSharedIds.length);
        return this.fetchAndSaveSingleCardsForIds(forUserEmail, missingCardSharedIds, 1024);
      }),
      mergeMap((fetchedCards: CardModel[]) => {
        watch.log('fetchedCards.count = ' + fetchedCards.length);

        cards.push(...fetchedCards);
        return of(cards);
      })
    );
  }

  /**
   * Fetch individual cards only by id
   */
  fetchAndSaveSingleCardsForIds(
    forUserEmail: string,
    cardIds: string[],
    sizeOfLastComments: 0 | 1024 | 1 = 0
  ): Observable<CardModel[]> {
    return from(cardIds).pipe(
      mergeMap((cardId: string) => {
        let params: CardApiService.Card_GetParams = {
          id: cardId,
          offsetComments: 0,
          orderComments: SortOrder.DESCENDING,
          // Note: hard limit for max number of comments,
          // ok for current need, change if needed
          sizeComments: sizeOfLastComments
        };
        return this._cardApiService.Card_Get(params, forUserEmail);
      }, 5),
      catchError(err => {
        if (![403, 404].includes(err.status)) {
          return throwError(err);
        }

        return EMPTY;
      }),
      map((card: CardBase) => {
        return CardBaseModel.create(card);
      }),
      toArray(),
      mergeMap((cards: CardModel[]) => this.saveCardsAndComments(forUserEmail, cards))
    );
  }

  filterOutNewCardUpdates(forUserEmail: string, cards: CardBase[]): Observable<CardBase[]> {
    let localCardsByCardId: _.Dictionary<CardBase> = {};

    return this.findCards(forUserEmail, cards).pipe(
      mergeMap((localCards: CardBase[]) => {
        localCardsByCardId = _.keyBy(localCards, 'id');
        return from(cards);
      }),
      /**
       * Filter out old updates
       */
      filter((cardUpdate: CardBase) => {
        let localCard = localCardsByCardId[cardUpdate.id];

        // All updates without local card are passed through
        if (!localCard) {
          return true;
        }

        return BaseModel.isRevisionGreaterThan(cardUpdate, localCard);
      }),
      /**
       * Take 'children' with greater revision
       */
      mergeMap((newCardUpdate: CardBase) => {
        let localCard = localCardsByCardId[newCardUpdate.id];

        if (localCard) {
          // Compare sharedTags revision and take greater
          if (
            localCard.sharedTags &&
            newCardUpdate.sharedTags &&
            BaseModel.isRevisionGreaterThan(localCard.sharedTags, newCardUpdate.sharedTags)
          ) {
            newCardUpdate.sharedTags = localCard.sharedTags;
          }
        }

        return of(newCardUpdate);
      }),
      toArray(),
      mergeMap((cardUpdates: CardBase[]) => {
        return this.removeCardsWithDeletedFields(forUserEmail, cardUpdates);
      })
    );
  }

  removeCardsWithDeletedFields(forUserEmail: string, cards: CardBase[]): Observable<CardBase[]> {
    let mailCards = _.filter(cards, (card: CardBase) => card.$type === CardMailModel.type);
    let mailCardIds = _.map(mailCards, (card: CardBase) => card.id);
    let idsToDelete = [];

    if (!mailCards || mailCards.length === 0) {
      return of(cards);
    }

    return this.findByIds(forUserEmail, mailCardIds).pipe(
      mergeMap((localCards: CardModel[]) => {
        let localCardsById = _.keyBy(localCards, 'id');
        _.forEach(mailCards, (card: CardBase) => {
          let localCard = <CardMailModel>localCardsById[card.id];
          if (localCard && localCard.draftResource && !(<CardMailModel>card).draftResource) {
            idsToDelete.push(card.id);
          }
        });
        return this.removeByIds(forUserEmail, idsToDelete);
      }),
      map(() => cards)
    );
  }

  protected findLoopsBySourceAndSnapshotIds(
    forUserEmail: string,
    sourceCards: CardMailModel[],
    excludedCards: CardModel[]
  ): Observable<CardModel[]> {
    return of(sourceCards);
  }

  updateCardsTagsAndPublish(forUserEmail: string, tags: ListOfTags[]): Observable<StateUpdates> {
    return this.updateCardsTags(forUserEmail, tags).pipe(
      tap((stateUpdates: StateUpdates) => {
        PublisherService.publishStateUpdates(forUserEmail, stateUpdates);
      })
    );
  }

  updateCardsTags(forUserEmail: string, tags: ListOfTags[]): Observable<StateUpdates> {
    if (_.isEmpty(tags)) {
      return of(new StateUpdates());
    }

    let tagsByParentId = _.keyBy(tags, 'parent.id');
    let ids: string[] = _.keys(tagsByParentId);

    return this.findByIds(forUserEmail, ids).pipe(
      mergeMap((cards: CardModel[]) => {
        return from(cards);
      }),
      filter((card: CardModel) => {
        let _tags = tagsByParentId[card.id];
        return _tags && (!card.tags || BaseModel.isRevisionGreaterThan(_tags, card.tags));
      }),
      map((card: CardModel) => {
        card.tags = tagsByParentId[card.id];
        return card;
      }),
      toArray(),
      mergeMap((cards: CardModel[]) => {
        return this.saveAll(forUserEmail, cards);
      })
    );
  }

  updateCardsSharedTags(forUserEmail: string, sharedTags: ListOfTags[]): Observable<StateUpdates> {
    if (_.isEmpty(sharedTags)) {
      return of(new StateUpdates());
    }

    let cards: CardModel[] = [];
    let sharedTagsByParentId = _.keyBy(sharedTags, 'parent.id');
    let ids: string[] = _.keys(sharedTagsByParentId);

    /**
     * Find local cards
     */
    return this.findByIds(forUserEmail, ids).pipe(
      /**
       * Combine local and fetched cards
       */
      mergeMap((cards: CardModel[]) => from(cards)),
      /**
       * Filter cards that should be updated
       */
      filter((card: CardModel) => {
        let tags = sharedTagsByParentId[card.id];
        let shouldUpdate = tags && (!card.sharedTags || BaseModel.isRevisionGreaterOrEqualThan(tags, card.sharedTags));

        if (!shouldUpdate) {
          Logger.customLog(
            `ShareTagDebugTrace: will not update card with id: ${card.id} ` +
              `with sharedTags with revision: ${tags?.revision}. Details` +
              `[tagsUndefined: ${_.isEmpty(tags)}; current revision: ${card?.sharedTags?.revision}]` +
              `update: ${Encryption.encrypt(JSON.stringify(tags))} ` +
              `current: ${Encryption.encrypt(JSON.stringify(card.sharedTags))}`,
            LogLevel.INFO
          );
        }

        return shouldUpdate;
      }),
      /**
       * Append shared tags to cards
       */
      map((card: CardModel) => {
        card.sharedTags = sharedTagsByParentId[card.id];
        return card;
      }),
      toArray(),
      defaultIfEmpty([]),
      /**
       * Save all cards
       */
      mergeMap((_cards: CardModel[]) => {
        return this.saveAll(forUserEmail, _cards);
      })
    );
  }

  markCardsAsUnread(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    cards = _.cloneDeep(cards);

    return this._markCardsAsUnread(forUserEmail, cards);
  }

  markCardAsUnread(forUserEmail: string, cardId: string): Observable<CardModel> {
    return this.findOrFetchCardById(forUserEmail, cardId).pipe(
      mergeMap((card: CardModel) => this._markCardsAsUnread(forUserEmail, [card])),
      map(_.first)
    );
  }

  markCardAsRead(forUserEmail: string, cardId: string): Observable<CardModel> {
    return this.findOrFetchCardById(forUserEmail, cardId).pipe(
      mergeMap((card: CardModel) => {
        return this._markCardsAsRead(forUserEmail, [card]);
      }),
      map((cards: CardModel[]) => {
        return _.first(cards);
      })
    );
  }

  markCardsAsRead(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    cards = _.cloneDeep(cards);

    return this._markCardsAsRead(forUserEmail, cards);
  }

  private _markCardsAsRead(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    let watch = new StopWatch(this.constructorName + '.markCardsAsRead', ProcessType.SERVICE, forUserEmail);

    watch.log('findOrFetchUnreadCommentsByCards');
    return this.findOrFetchUnreadCommentsByCards(forUserEmail, cards, isWebApp()).pipe(
      mergeMap((comments: CommentModel[]) => {
        watch.log('markCardsAndCommentsAsReadOrUnread');
        return this.markCardsAndCommentsAsReadOrUnread(forUserEmail, cards, comments, true);
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  private findOrFetchUnreadCommentsByCards(
    forUserEmail: string,
    cards: CardModel[],
    doFindAndFetch: boolean = false
  ): Observable<CommentBase[]> {
    return this._commentService.findUnreadCommentsByCards(forUserEmail, cards).pipe(
      mergeMap((dbComments: CommentBase[]) => {
        let parentCardIds = _.map(dbComments, (comment: CommentBase) => comment.parent.id);
        let requestedCardIds = [
          ...CardBaseModel.getBackendIds(cards),
          ...CardBaseModel.getSnapshotOrSourceCardIds(cards)
        ];
        let missingCardIds = _.difference(requestedCardIds, parentCardIds);

        if (doFindAndFetch) {
          missingCardIds = requestedCardIds;
        }

        return forkJoin([this.fetchUnreadCommentsForCards(forUserEmail, missingCardIds), of(dbComments)]);
      }),
      map((comments: CommentBase[][]) => comments.flat()),
      map((comments: CommentBase[]) => _.uniqBy(comments, 'id'))
    );
  }

  private fetchUnreadCommentsForCards(forUserEmail: string, cardIds: string[]): Observable<CommentModel[]> {
    if (_.isEmpty(cardIds)) {
      return of([]);
    }

    let params: CommentApiService.Comment_GetListParams = {
      cardIds: cardIds,
      tagFilterRelation: QueryRelation.OR,
      tags: [TagType.UNREAD, TagType.UNREAD_VIRTUAL],
      offset: 0,
      size: 1024
    };

    return this._commentService.fetchListOfComments(forUserEmail, params).pipe(
      mergeMap((comments: CommentModel[]) => {
        return this._commentService.saveAll(forUserEmail, comments);
      })
    );
  }

  protected _markCardsAsUnread(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    if (_.isEmpty(cards)) {
      return of([]);
    }

    let watch = new StopWatch(this.constructorName + '.markCardsAsUnread', ProcessType.SERVICE, forUserEmail);

    /**
     * Find last comments
     */
    watch.log('findGroupedCommentIdsByCards');
    return this.findOrFetchCardsLastComment(forUserEmail, cards).pipe(
      /**
       * MarkAsUnread
       */
      mergeMap((comments: CommentModel[]) => {
        watch.log('markCardsAndCommentsAsReadOrUnread');
        return this.markCardsAndCommentsAsReadOrUnread(forUserEmail, cards, comments, false);
      }),
      tap(() => {
        watch.log('done');
      })
    );
  }

  private findOrFetchCardsLastComment(forUserEmail: string, cards: CardModel[]): Observable<CommentBase[]> {
    return this.findCardsLastComment(forUserEmail, cards).pipe(
      mergeMap((dbComments: CommentBase[]) => {
        let parentCardIds = _.map(dbComments, (comment: CommentBase) => comment.parent.id);
        let requestedCardIds = _.map(cards, (card: CardModel) => card.id);
        let missingCardIds = _.difference(requestedCardIds, parentCardIds);
        let missingCards = _.filter(cards, (card: CardModel) => missingCardIds.includes(card.id));

        return forkJoin([this.fetchCardsLastComment(forUserEmail, missingCards), of(dbComments)]);
      }),
      map((comments: CommentBase[][]) => {
        return comments.flat();
      })
    );
  }

  private findCardsLastComment(forUserEmail: string, cards: CardModel[]): Observable<CommentBase[]> {
    return this._commentService.findGroupedCommentIdsByCards(forUserEmail, cards, false).pipe(
      mergeMap((commentIdsByParentId: _.Dictionary<CommentBase[]>) => {
        return from(_.values(commentIdsByParentId));
      }),
      map((comments: CommentBase[]) => {
        return _.last(comments);
      }),
      toArray(),
      mergeMap((comments: CommentBase[]) => {
        return this._commentService.findComments(forUserEmail, comments);
      })
    );
  }

  private fetchCardsLastComment(forUserEmail: string, cards: CardModel[]): Observable<CommentBase[]> {
    let cardIds = _.map(cards, (card: CardModel) => card.id);

    return this.fetchListOfCards(forUserEmail, cardIds, true).pipe(
      map((apiCards: CardModel[]) =>
        _.map(apiCards, (card: CardModel) => CommentBaseModel.create(_.last(card.getComments())))
      ),
      mergeMap((comments: CommentModel[]) => {
        return this._commentService.saveAll(forUserEmail, comments);
      })
    );
  }

  private fetchListOfCardsFromConversations(forUserEmail: string, cardIds: string[]): Observable<CardBaseModel[]> {
    return from(cardIds).pipe(
      distinct(),
      bufferCount(100),
      mergeMap((_cardIds: string[]) => {
        let params: CardApiService.Card_GetListParams = {
          offset: 0,
          size: 1024,
          cardIds: _cardIds
        };
        return this._cardApiService.Card_GetList(params, forUserEmail);
      }, 5),
      mergeMap((response: ListOfResourcesOfCardBase) => from(CardBaseModel.createList(response.resources))),
      map((card: CardModel) => {
        card.removeComments();
        return card;
      }),
      toArray(),
      /**
       * FOURTH-4265
       * Filter out multi-contact cards
       */
      map((cards: CardModel[]) => {
        return _.filter(cards, card => CardBaseModel.isSupported(card));
      })
    );
  }

  private fetchListOfCards(
    forUserEmail: string,
    cardIds: string[],
    keepLastComment: boolean = false,
    validTotalCommentSize: boolean = false
  ): Observable<CardBaseModel[]> {
    if (_.isEmpty(cardIds)) {
      return of([]);
    }

    return from(cardIds).pipe(
      distinct(),
      bufferCount(100),
      mergeMap((_cardIds: string[]) => {
        let params: CardApiService.Card_GetListParams = {
          offset: 0,
          size: 1024,
          cardIds: _cardIds,
          validTotalCommentSize
        };
        return this._cardApiService.Card_GetList(params, forUserEmail);
      }, 5),
      mergeMap((response: ListOfResourcesOfCardBase) => from(CardBaseModel.createList(response.resources))),
      map((card: CardModel) => {
        if (!keepLastComment) {
          card.removeComments();
        }

        return card;
      }),
      toArray(),
      /**
       * Fallback fetchById
       */
      mergeMap((fetchedCards: CardModel[]) => {
        let fetchedCardIds = _.map(fetchedCards, card => card.id);
        let missingCardIds = _.difference(cardIds, fetchedCardIds);

        return forkJoin([
          this.saveAll(forUserEmail, fetchedCards).pipe(map(stateUpdates => stateUpdates.cards)),
          this.fetchAndSaveSingleCardsForIds(forUserEmail, missingCardIds, keepLastComment ? 1 : 0)
        ]);
      }),
      map((_cards: CardModel[][]) => _cards.flat()),
      /**
       * FOURTH-4265
       * Filter out multi-contact cards
       */
      map((cards: CardModel[]) => {
        return _.filter(cards, card => CardBaseModel.isSupported(card));
      })
    );
  }

  validateForDrafts(forUserEmail: string, card: CardModel): Observable<CardModel> {
    if (!card.id) {
      Logger.customLog(
        `Tried to validate card with id: ${card._id} for drafts but is does not have BE id`,
        LogLevel.WARN,
        LogTag.DRAFT
      );
      return of(card);
    }

    Logger.customLog(`Will validate card with id: ${card.id} for drafts`, LogLevel.INFO, LogTag.DRAFT);

    return this.fetchAndSaveCard(forUserEmail, card.id);
  }

  private markCardsAndCommentsAsReadOrUnread(
    forUserEmail: string,
    cards: CardModel[],
    comments: CommentModel[],
    readOrUnread: boolean
  ): Observable<CardModel[]> {
    let stateUpdates = new StateUpdates();
    let watch = new StopWatch(
      this.constructorName + '.markCardsAndCommentsAsReadOrUnread',
      ProcessType.SERVICE,
      forUserEmail
    );

    return this.setCommentsReadStatus(forUserEmail, comments, readOrUnread ? 'read' : 'unread').pipe(
      mergeMap((_comments: CommentModel[]) => {
        stateUpdates.add(_comments);

        watch.log('saveAllCards');
        return this.saveAll(forUserEmail, cards);
      }),
      mergeMap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);

        watch.log('publish cards and comments');
        PublisherService.publishStateUpdates(forUserEmail, stateUpdates);

        watch.log('publishContactsFromComments');
        return this._contactService.publishContactsFromComments(forUserEmail, comments).pipe(
          map(() => {
            return cards;
          })
        );
      }),
      defaultIfEmpty([]),
      tap((_cards: CardModel[]) => {
        watch.log('done, marked', _cards.length, 'cards and', comments.length, 'comments as readOrUnread');
      }),
      catchError(err => {
        Logger.error(err, 'Could not mark cards as readOrUnread');
        return throwError(err);
      })
    );
  }

  ////////////////
  // DAO WRAPPERS
  ////////////////
  findById(forUserEmail: string, cardId: string): Observable<CardModel> {
    return this._cardDaoService.findById(forUserEmail, cardId);
  }

  findByIds(forUserEmail: string, ids: string[]): Observable<CardModel[]> {
    return this._cardDaoService.findByIds(forUserEmail, ids);
  }

  findChatCardByContactId(forUserEmail: string, contactId: string): Observable<CardChatModel> {
    return this._cardDaoService.findChatCardByContactId(forUserEmail, contactId);
  }

  unsetId(forUserEmail: string, card: CardModel): Observable<CardModel> {
    return of(card);
  }

  unlinkDraftCard(forUserEmail: string, cardDraft: CardDraft): Observable<any> {
    if (!cardDraft.parentCard) {
      return of(undefined);
    }

    return this._cardDaoService.unlinkDraftCard(forUserEmail, cardDraft);
  }

  protected findCardsWithoutSynchronizedComments(forUserEmail: string): Observable<CardModel[]> {
    return of([]);
  }

  protected findCardByAppointmentLink(forUserEmail: string, appointment: CardAppointment): Observable<CardModel> {
    return of(undefined);
  }

  protected findCards(forUserEmail: string, cards: CardBase[]): Observable<CardModel[]> {
    return of([]);
  }

  protected removeByIds(forUserEmail: string, ids: string[]): Observable<any> {
    return of(undefined);
  }

  ///////////////////////////////
  // DAO WRAPPERS: VIEW FINDERS
  ///////////////////////////////
  findMyLoopInboxCards(forUserEmail: string, params: MyLoopInboxCollectionParams): Observable<CardModel[]> {
    return this._cardDaoService.findMyLoopInboxCards(forUserEmail, params);
  }

  findCardsForAssignedView(forUserEmail: string, params: any): Observable<CardSharedModel[]> {
    return this._cardDaoService.findCardsForAssignedView(forUserEmail, params);
  }

  findPersonalInboxCards(forUserEmail: string, params: PersonalInboxCollectionParams): Observable<CardModel[]> {
    return this._cardDaoService.findPersonalInboxCards(forUserEmail, params);
  }

  findChatCards(forUserEmail: string, params: ChatCardsCollectionParams): Observable<CardChatModel[]> {
    return this._cardDaoService.findChatCards(forUserEmail, params);
  }

  findCardsByChannel(forUserEmail: string, params: any): Observable<CardModel[]> {
    return this._cardDaoService.findCardsByChannel(forUserEmail, params);
  }

  findDraftsOrCardsWithDrafts(forUserEmail: string, params: PersonalInboxCollectionParams): Observable<CardModel[]> {
    return this._cardDaoService.findDraftsOrCardsWithDrafts(forUserEmail, params);
  }

  findFolderCards(forUserEmail: string, params: SearchableViewCollectionParams): Observable<CardModel[]> {
    return this._cardDaoService.findFolderCards(forUserEmail, params);
  }
}
