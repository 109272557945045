<div class="date-picker" [ngClass]="extraClass" (click)="$event.stopPropagation()">
  <!-- Month selector -->
  <div class="month">
    <!-- Button -->
    <div (click)="incrementCalendarMonth(false)">
      <svg-comp class="left" [src]="dropdownToggleSvg"> </svg-comp>
    </div>

    <span>{{monthAndYear}}</span>

    <!-- Button -->
    <div (click)="incrementCalendarMonth(true)">
      <svg-comp class="right" [src]="dropdownToggleSvg"> </svg-comp>
    </div>
  </div>

  <!-- Days in month -->
  <div class="month-wrapper" [class.submit-on-date]="submitOnDatePick">
    <!-- Days of the week -->
    <ng-template ngFor let-day [ngForOf]="days">
      <div class="day header">{{day}}</div>
    </ng-template>

    <ng-template ngFor let-month [ngForOf]="threeMonths" let-monthIndex="index">
      <ng-template ngFor let-date [ngForOf]="month" let-i="index">
        <!-- Day -->
        <div
          class="day"
          [class.current-day]="isSameDay(now, date)"
          [class.selected-day]="isSameDay(selectedDate, date)"
          [class.disabled]="isDateDisabled(date)"
          [class.selected-range-day]="isInRange(date)"
          [class.current-month]="monthIndex === 1"
          [attr.data-date]="date.toString()"
          (click)="selectDate($event, date)"
        >
          {{date.getDate()}}
        </div>
      </ng-template>
    </ng-template>
  </div>

  <!-- Time selector -->
  <div *ngIf="showTimeInput" class="time-wrapper">
    <!-- Selected date -->
    <div class="selected-date">{{selectedDateForUi}}</div>

    <div class="time-input-wrapper">
      <!-- Hours input -->
      <input
        #inputTimeRef0
        [(ngModel)]="inputHours"
        maxlength="2"
        (keydown)="inputKeyDown($event, 0)"
        (focus)="onInputFocus(0)"
        (blur)="onInputBlur(0)"
        type="text"
      />

      <!-- Separator -->
      <span>:</span>

      <!-- Minutes input -->
      <input
        #inputTimeRef1
        [(ngModel)]="inputMinutes"
        maxlength="2"
        (keydown)="inputKeyDown($event, 1)"
        (focus)="onInputFocus(1)"
        (blur)="onInputBlur(1)"
        type="text"
      />
    </div>
  </div>

  <!-- Submit -->
  <div *ngIf="!submitOnDatePick" [ngClass]="{'center-flex' : backButtonIncluded}" class="submit-wrapper">
    <!-- Cancel button -->
    <div *ngIf="backButtonIncluded" tabindex="0" (click)="back()" class="button button-back">
      <span>{{ backButtonText }}</span>
    </div>

    <!-- Submit button -->
    <div tabindex="1" class="button button-submit" (click)="submit()">
      <span>{{ submitButtonText }}</span>
    </div>
  </div>

  <!-- Submit Error -->
  <div class="submit-error">{{submitError}}</div>
</div>
