import * as _ from 'lodash';
import { BaseModel } from '../base/base.model';
import {
  Attendee,
  AvailabilityUserStatus,
  ContactBase,
  Group,
  GroupSubType,
  GroupType,
  ListOfResourcesOfSharedTagBase,
  ListOfResourcesOfUser,
  ListOfTags,
  SharedTagAssignee,
  SharedTagBase,
  SharedTagStatus,
  SharedTagSystemNames,
  Tag,
  ThreadingEnum,
  User,
  UserSettings,
  UserStatus
} from '@shared/api/api-loop/models';
import { CollectionName } from '@shared/models/constants/collection.names';
import { TagModel } from '../tag.model';
import { TagType } from '@shared/api/api-loop/models/tag-type';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { SharedTagAssigneeModel, SharedTagStatusModel } from '../shared-tag/shared-tag.model';
import { SyncSettings } from '../../models/settings.model';

export type ContactModel = UserModel | GroupModel;

export abstract class ContactBaseModel extends BaseModel implements ContactBase {
  static collectionName: CollectionName = CollectionName.Contact;

  tags?: ListOfTags;
  weight?: number;

  _ex: ContactExtraData;
  _ui?: ContactViewData;

  static create(data: ContactBase): ContactModel {
    if (!data || !data.$type) {
      throw new Error(`Invalid $type given ${JSON.stringify(data)}`);
    }
    if (data instanceof ContactBaseModel) {
      return <ContactModel>data;
    }

    switch (data.$type) {
      case UserModel.type:
        return new UserModel(data);
      case GroupModel.type:
        return new GroupModel(data);
      case AttendeeModel.type:
        return new AttendeeModel(data);
      default:
        break;
    }
  }

  // Lets work primarily with ids (user_<id>) and not with emails
  protected _getId(resource: ResourceBase = this): string {
    return resource.id || (<BaseModel>resource)._id || resource.clientId;
  }

  static createList(docs: ContactBase[]): ContactModel[] {
    return docs.map(doc => ContactBaseModel.create(doc));
  }

  static getUniqueContactsWithHighestRevision(contacts: ContactBase[]): ContactBase[] {
    let sortedByRevision = _.orderBy(contacts, 'revision', 'desc');
    let uniqContacts = _.uniqBy(sortedByRevision, 'id');

    // Remove undefined entries
    return _.filter(uniqContacts, c => c !== undefined);
  }

  /**
   * Overridden as it uses _.unionBy instead of _.unionWith as it is 10x faster by average
   */
  static mergeList<T extends BaseModel>(aList: T[], bList: T[]): T[] {
    return _.unionBy(aList, bList, contact => contact._id);
  }

  static isSupported(contact: ContactBase): boolean {
    let model = ContactBaseModel.create(contact);
    return !_.isNil(model);
  }

  static buildFromBaseAsReference<T extends ResourceBase>(user: User): T {
    return <any>{
      $type: user.$type,
      _id: user['_id'],
      id: user.id,
      clientId: user.clientId,
      name: user.name,
      displayName: user.displayName,
      revision: user.revision,
      email: user.email
    };
  }

  static toReducedForm(model: any) {
    // Don't reduce models that are not synced
    if (!model.id) {
      return;
    }
    if (model.$type === UserModel.type) {
      for (let prop in model) {
        if (!['id', '$type', 'displayName', 'email'].includes(prop)) {
          delete model[prop];
        }
      }
    } else if (model.$type === GroupModel.type) {
      for (let prop in model) {
        if (!['id', '$type'].includes(prop)) {
          delete model[prop];
        }
      }
    }
  }

  getSearchableContent(): string[] {
    return [this.name];
  }

  addTag(tag: TagModel) {
    if (!tag) {
      throw new Error('Invalid tag given');
    }

    if (!this.hasTags()) {
      this.tags = {
        $type: 'ListOfTags',
        tags: BaseModel.createListOfResources(),
        parent: {
          $type: this.$type,
          id: this.id
        },
        revision: '1'
      };
    }

    // REMOVE MUTUALLY EXCLUSIVE TAGS
    if (_.includes([TagType.MUTED, TagType.FOCUSED], tag.id)) {
      this.tags.tags.resources = _.filter(this.tags.tags.resources, tag => {
        return !_.includes([TagType.MUTED, TagType.FOCUSED], tag.id);
      });
    }

    // add only if tag doesn't exist
    if (!_.some(this.tags.tags.resources, { id: tag.id })) {
      this.tags.tags.resources = [...this.tags.tags.resources, tag.toObject()];
    }
  }

  removeTag(inputTag: Tag) {
    if (!inputTag || !this.hasTags()) {
      return;
    }

    this.tags.tags.resources = _.filter(this.tags.tags.resources, tag => tag.id !== inputTag.id);

    if (inputTag.id === TagType.MUTED) {
      let focusedTag = TagModel.buildSystemTag(TagType.FOCUSED);
      this.tags.tags.resources = [...this.tags.tags.resources, focusedTag.toObject()];
    }
    if (inputTag.id === TagType.FOCUSED) {
      let mutedTag = TagModel.buildSystemTag(TagType.MUTED);
      this.tags.tags.resources = [...this.tags.tags.resources, mutedTag.toObject()];
    }
  }

  hasTags(): boolean {
    return this.tags && this.tags.tags && !_.isEmpty(this.tags.tags.resources);
  }

  getTags(): Tag[] {
    return this.hasTags() ? this.tags.tags.resources : [];
  }

  hasTagType(tagType: TagType): boolean {
    if (!this.hasTags()) {
      return false;
    }

    return _.some(this.tags.tags.resources, { id: tagType });
  }

  isAccessible(): boolean {
    return !this._ex || !this._ex.isNotAccessible;
  }

  getWeight(): number {
    return this.weight || 0;
  }

  hasValidAvailabilityStatus(): boolean {
    if (!(this instanceof UserModel)) {
      return false;
    }

    if (!this.availabilityStatus) {
      return false;
    }

    if (this.availabilityStatus.clearAfter) {
      let now = new Date();
      let clearAfterDate = new Date(this.availabilityStatus.clearAfter);

      return clearAfterDate > now;
    }

    // Don't clear option
    return true;
  }
}

export class UserModel extends ContactBaseModel implements User {
  static collectionName: CollectionName = CollectionName.Contact;
  static type: string = 'User';

  readonly $type: string = UserModel.type;

  email?: string;
  firstName?: string;
  lastName?: string;
  onlineStatus: string;
  availabilityStatus?: AvailabilityUserStatus;

  constructor(data?: User) {
    super(data);
  }

  protected _generateId(): string {
    if (this.email) {
      return this.email;
    }
    return super._generateId();
  }

  getOnlineStatus(): string {
    return this.onlineStatus || UserStatus.NOT_REGISTERED;
  }

  isRegistered(): boolean {
    return this.getOnlineStatus() !== UserStatus.NOT_REGISTERED;
  }

  getSearchableContent(): string[] {
    return _.filter([...super.getSearchableContent(), this.email], item => !_.isEmpty(item));
  }

  get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }
}

export class LoginUserModel extends UserModel {
  userSettings: UserSettings;
  syncSettings: SyncSettings;
  throwVerifiedError?: boolean;

  constructor(data?: UserModel) {
    super(data);
  }

  toUserModel(): UserModel {
    let user = _.clone(this);

    delete user.userSettings;
    delete user.syncSettings;

    return user as UserModel;
  }

  static haveSyncSettingsChanged(updatedUser: LoginUserModel, user: LoginUserModel): boolean {
    // Thees are the only values we care about
    let valuesToCompare = ['threadingMode', 'isEnabled'];

    return valuesToCompare.some(value => user.syncSettings?.[value] !== updatedUser.syncSettings?.[value]);
  }
}

export class GroupModel extends ContactBaseModel implements Group {
  static collectionName: CollectionName = CollectionName.Contact;
  static type: string = 'Group';

  readonly $type: string = GroupModel.type;

  admins?: ListOfResourcesOfUser;
  members?: ListOfResourcesOfUser;
  description?: string;
  availableSharedTags?: ListOfResourcesOfSharedTagBase;
  email?: string; // will be deprecated soon / is always null
  allowedImpersonatedSenders?: ListOfResourcesOfUser;
  groupType?: GroupType;
  groupSubType?: GroupSubType;
  syncingComplete?: boolean;
  subscribed: boolean;
  threadingMode?: ThreadingEnum;
  syncingAccount?: User;
  syncingAliasAccount?: User;
  showTotalCountBadge?: boolean;
  colour?: string;

  constructor(data?: Group) {
    super(data);
  }

  getAllMembers(): User[] {
    return _.unionBy(this.getResources(this.admins), this.getResources(this.members), 'id');
  }

  getMembers(): User[] {
    return this.getResources(this.members);
  }

  getAdmins(): User[] {
    return this.getResources(this.admins);
  }

  hasAvailableSharedTags(): boolean {
    return this.availableSharedTags && !_.isEmpty(this.availableSharedTags.resources);
  }

  getAvailableSharedTags(): SharedTagBase[] {
    return this.getResources(this.availableSharedTags);
  }

  getStatusSharedTags(): SharedTagStatus[] {
    if (!this.hasAvailableSharedTags()) {
      return [];
    }

    let sharedTags = this.getAvailableSharedTags();
    return _.filter(sharedTags, (tag: SharedTagBase) => {
      return tag.$type === SharedTagStatusModel.type;
    });
  }

  getAssigneeSharedTags(): SharedTagAssignee[] {
    if (!this.hasAvailableSharedTags()) {
      return [];
    }

    let sharedTags = this.getAvailableSharedTags();
    return _.filter(sharedTags, (tag: SharedTagBase) => {
      return tag.$type === SharedTagAssigneeModel.type;
    });
  }

  findAvailableSharedTagId(sharedTagName: SharedTagSystemNames): string {
    let availableSharedTags = this.getAvailableSharedTags();
    if (!availableSharedTags) {
      return undefined;
    }

    let sharedTagId = _.find(availableSharedTags, tag => tag.name === sharedTagName);
    return sharedTagId ? sharedTagId.id : undefined;
  }

  isRegistered(): boolean {
    return true;
  }
}

export class AttendeeModel extends UserModel implements Attendee {
  static collectionName: CollectionName = CollectionName.Contact;
  static type: string = 'Attendee';

  readonly $type: string = AttendeeModel.type;

  statusUpdatedAt?: string;
  isOptional?: boolean;
  status?: string;

  constructor(data?: User) {
    super(data);
  }

  static castToUserModel(attendeeModel: AttendeeModel): UserModel {
    // Clone because we will be removing and don't
    // want to change original
    let attendeeAsUser = _.cloneDeep(attendeeModel);

    // Remove attendee properties
    delete attendeeAsUser.statusUpdatedAt;
    delete attendeeAsUser.isOptional;
    delete attendeeAsUser.status;

    // Change type
    let noType = JSON.parse(JSON.stringify(attendeeAsUser));
    noType['$type'] = UserModel.type;

    // Cast to user
    return new UserModel(noType);
  }
}

export interface ContactViewData {
  unreadChats?: number;
  totalCount?: number;
  unreadEmails?: number;
  unreadBadge?: number;
  favoriteName: string;
  isTagDisabled?: boolean;
}

export interface ContactExtraData {
  favorite: boolean;
  sendAsOptions?: SendAsOptions;
  isNotAccessible: boolean;
  lastUpdateTime?: number; // Use milliseconds and not timestamp to be able to compare on DB query level
  localName: string;
}

export interface SendAsOptions {
  impersonatedUser: User;
  groupId: string;
}

export enum NoAccessContact {
  name = 'noaccess'
}
