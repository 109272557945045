import { EMPTY, from, Observable, throwError } from 'rxjs';
import {
  AuthCode,
  AuthExchange,
  AuthImapSmtp,
  AuthMicrosoft,
  AuthRemoteImap,
  EmailType,
  Response,
  Token,
} from '@shared/api/api-loop/models';
import { AuthUser } from '../../../../dta/shared/models/auth.model';
import { AuthApiService, HelperApiService, TokenApiService } from '@shared/api/api-loop/services';
import { catchError, delay, map, mergeMap, toArray } from 'rxjs/operators';
import { LoginUserModel } from '../../../../dta/shared/models-api-loop/contact/contact.model';
import { SharedUserManagerService } from '../../../../dta/shared/services/shared-user-manager/shared-user-manager.service';
import { Logger } from '@shared/services/logger/logger';
import { UserSwitchReason, UserTokenInvalid } from '../../communication/shared-subjects/shared-subjects-models';
import { Injectable } from '@angular/core';
import { SharedSubjects } from '../../communication/shared-subjects/shared-subjects';
import { TrackingService } from '../../../../dta/shared/services/tracking/tracking.service';
import { AuthActionType } from '../../../../dta/shared/services/tracking/tracking.constants';
import { LogLevel, LogTag } from '../../../../dta/shared/models/logger.model';
import { UserService } from '@shared/services/data/user/user.service';
import { EmailTypeEnum } from '@dta/shared/models/sync-settings-type.model';
import { AuthServiceI, ExchangeAuthData, ImapAuthData } from './auth.service.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthErrorHelper } from './auth-error.helper';

@Injectable()
export class AuthService implements AuthServiceI {
  constructor(
    private _sharedUserManagerService: SharedUserManagerService,
    private _tokenApiService: TokenApiService,
    private _userService: UserService,
    private _trackingService: TrackingService,
    private _authApiService: AuthApiService,
    private _helperApiService: HelperApiService,
  ) {}

  get constructorName(): string {
    return 'AuthService';
  }

  refreshAccessTokenForAllUsers(): Observable<Token[]> {
    Logger.customLog('User auth action: refreshAccessTokenForAllUsers', LogLevel.INFO, LogTag.AUTH_AND_LOGIN);

    return from(this._sharedUserManagerService.getUsers()).pipe(
      mergeMap((user: LoginUserModel) =>
        this.refreshTokenForUser(this._sharedUserManagerService.getUserAuthByEmail(user.email), user.email),
      ),
      toArray(),
    );
  }

  createExchangeAuth(authData: ExchangeAuthData, isAddAccount: boolean = false): Observable<AuthExchange> {
    let authExchange: AuthExchange = {
      $type: EmailTypeEnum.AUTH_EXCHANGE,
      email: authData.email,
      secret: authData.password,
      username: authData.username || '',
      serverUri: authData.serverUri || '',
      domain: authData.domain || '',
    };

    return this._authApiService
      .Auth_CreateExchangeAuth({ authExchange, isAddAccount }, undefined)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => AuthErrorHelper.handleAuthError(error))));
  }

  createImapAuth(authData: ImapAuthData, isAddAccount?: boolean): Observable<AuthRemoteImap | AuthImapSmtp> {
    return this._helperApiService.Helper_GetEmailType({ emailAddress: authData.email }, undefined).pipe(
      mergeMap((emailType: EmailType) => {
        let isRemote = emailType.emailServiceType === 'RemoteImap';

        let authImap: AuthImapSmtp | AuthRemoteImap = {
          $type: isRemote ? EmailTypeEnum.AUTH_REMOTE_IMAP : EmailTypeEnum.AUTH_IMAP_SMTP,
          email: authData.email,
          imapPassword: authData.password || authData.incomingServerPassword,
          imapHost: authData.incomingServerHost,
          imapPort: authData.incomingServerPort,
          smtpHost: authData.outgoingServer,
          smtpPort: authData.outgoingServerPort,
          imapUsername: authData.incomingServerUsername,
          smtpUsername: authData.outgoingServerUsername || authData.email,
          smtpPassword: authData.outgoingServerPassword || authData.password,
        };

        return isRemote
          ? this._authApiService.Auth_CreateRemoteImapAuth({ authRemoteImap: authImap, isAddAccount }, undefined)
          : this._authApiService.Auth_CreateImapAuth({ authImapSmtp: authImap, isAddAccount }, undefined);
      }),
      catchError((error: HttpErrorResponse) => throwError(() => AuthErrorHelper.handleAuthError(error))),
    );
  }

  createTokenCodeAuth(code: string, isAddAccount?: boolean): Observable<AuthCode> {
    let authCode: AuthCode = {
      $type: EmailTypeEnum.AUTH_CODE,
      code: code,
    };

    return this._authApiService
      .Auth_CreateTokenCodeAuth({ authCode, isAddAccount }, undefined)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => AuthErrorHelper.handleAuthError(error))));
  }

  createMicrosoftAuth(
    oAuthRefreshToken: string,
    email: string,
    sharedInboxEmail?: string,
    isAddAccount?: boolean,
  ): Observable<AuthMicrosoft> {
    let authMicrosoft: AuthMicrosoft = {
      $type: EmailTypeEnum.AUTH_MICROSOFT,
      accessToken: oAuthRefreshToken,
      email,
      sharedInboxEmail,
    };

    return this._authApiService
      .Auth_CreateMicrosoftAuth({ authMicrosoft, isAddAccount }, undefined)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => AuthErrorHelper.handleAuthError(error))));
  }

  ///////////////////
  // Private helpers
  ///////////////////
  private refreshTokenForUser(auth: AuthUser, email: string): Observable<Token> {
    let params: TokenApiService.Token_RefreshTokenParams = {
      Authorization: 'Bearer ' + auth.token.accessToken,
      token: auth.token,
    };

    Logger.customLog(`User auth action: will refresh token for (${email})`, LogLevel.INFO, LogTag.AUTH_AND_LOGIN);

    // ONLY TMP TO DEBUG "ACCOUNT DISCONNECTED" ISSUE
    let tokenBeforeRefresh = this._sharedUserManagerService.getTokenByEmail(email);
    return this._tokenApiService.Token_RefreshToken(params, email).pipe(
      map((data: Token) => {
        auth.token.accessToken = data.accessToken;
        auth.token.refreshToken = data.refreshToken;
        this._sharedUserManagerService.setUserAuth(auth, UserSwitchReason.refreshAccount, email);

        // ONLY TMP TO DEBUG "ACCOUNT DISCONNECTED" ISSUE
        let tokenAfterRefresh = this._sharedUserManagerService.getTokenByEmail(email);
        Logger.customLog(
          `User auth action: have refreshed token for (${email}), ` +
            `comparing tokens (before 10ms): [tokenBeforeRefresh === tokenAfterRefresh -> ${tokenBeforeRefresh === tokenAfterRefresh}]`,
          LogLevel.INFO,
          LogTag.AUTH_AND_LOGIN,
        );

        return data;
      }),
      /**
       * Wait for user to be correctly set and all of the changes to be propagated
       * before using new authorization
       */
      delay(10),
      mergeMap((token: Token) => {
        // ONLY TMP TO DEBUG "ACCOUNT DISCONNECTED" ISSUE
        let tokenAfterRefresh = this._sharedUserManagerService.getTokenByEmail(email);
        Logger.customLog(
          `User auth action: will fetch or update user (${email})` +
            `comparing tokens (after 10ms): [tokenBeforeRefresh === tokenAfterRefresh -> ${tokenBeforeRefresh === tokenAfterRefresh}]`,
          LogLevel.INFO,
          LogTag.AUTH_AND_LOGIN,
        );

        return this._userService.fetchOrUpdateUser(email).pipe(map(() => token));
      }),
      catchError((err: Response) => {
        // Log
        Logger.customLog(
          `User auth action: could not refresh token for user (${email}). Will remove account. Err: ${err.message}`,
          LogLevel.ERROR,
          LogTag.AUTH_AND_LOGIN,
        );

        // Track
        this._trackingService.trackAuthAction(email, AuthActionType.COULD_NOT_REFRESH_TOKEN);

        // Remove account and open re-login popup
        this._sharedUserManagerService.removeAccountByEmail(email, 'AuthService.refreshTokenForUser');
        this.triggerReloginNavigation(email);

        return <Observable<any>>EMPTY;
      }),
    );
  }

  private triggerReloginNavigation(forUserEmail: string) {
    let data = new UserTokenInvalid();
    data.userEmail = forUserEmail;
    data.showDialog = true;

    SharedSubjects._userTokenInvalid$.next(data);
  }
}
