<div
  class="tag-container"
  [style.border-color]="borderColor$ | async"
  [style.background-color]="backgroundColor$ | async"
>
  <div *ngIf="prefixTpl" class="flex items-center padding-left-quarter">
    <ng-container *ngTemplateOutlet="prefixTpl"></ng-container>
  </div>

  <div class="tag-content" [style.color]="isLoading ? 'transparent' : (textColor$ | async)">
    <span>{{ content }}</span>
    <span class="spinner" *ngIf="isLoading"></span>
  </div>

  <div *ngIf="suffixTpl" class="padding-right-quarter flex items-center">
    <ng-container *ngTemplateOutlet="suffixTpl"></ng-container>
  </div>
</div>
