import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { SharedTagLabelModel } from '../../../../shared/models-api-loop/shared-tag/shared-tag.model';
import { KeyboardShortcutsListenerService } from '../../../services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { SharedTagBase } from '@shared/api/api-loop/models/shared-tag-base';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { TooltipPlacement } from '../tooltip/tooltip.component';
import { TrackingService } from '../../../../shared/services/tracking/tracking.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ClickTrackingLocation } from '../../../../shared/services/tracking/tracking.constants';
import { LabelService } from '@shared/services/data/label/label.service';
import { tap } from 'rxjs/operators';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { mergeMap, of, Subscription } from 'rxjs';
import { TagLabelModel } from '@dta/shared/models-api-loop/tag.model';
import { Tag } from '@shared/api/api-loop/models';
import { KeyboardActionType, KeyboardShortcutData } from '@dta/shared/models/keyboard-shortcut.model';

@AutoUnsubscribe()
@Component({
  selector: 'labels-dropdown',
  templateUrl: './labels-dropdown.html',
  styleUrls: ['./labels-dropdown.scss'],
})
export class LabelsDropdownComponent extends DropdownComponent implements OnChanges, OnInit {
  ////////
  // SVGs
  ////////
  lockSvg = require('shared/assets/img/svg/lock.svg');

  ////////////////
  // View queries
  ////////////////
  @ViewChild('itemsWrapper', { static: false }) itemsWrapper: ElementRef;

  ////////////////////
  // Inputs / Outputs
  ////////////////////
  @Input() labelTags: Tag[] = [];
  @Input() sharedTags: SharedTagBase[] = [];
  @Input() showOnlyLabelIds: string[] = [];
  @Input() sharedInboxId: string;
  @Input() threadType: 'shared' | 'email' = 'shared';

  @Output() addLabel = new EventEmitter<SharedTagLabelModel | TagLabelModel>();
  @Output() removeLabel = new EventEmitter<SharedTagLabelModel | TagLabelModel>();

  //////////////
  // Properties
  //////////////
  TooltipPlacement = TooltipPlacement;
  filterQuery: string = '';
  filteredLabels: (SharedTagLabelModel | TagLabelModel)[] = [];
  availableLabels: (SharedTagLabelModel | TagLabelModel)[] = [];
  protected maxSelectionIndex = undefined;
  private selectedLabels: (SharedTagLabelModel | TagLabelModel)[] = [];

  //////////
  // SVGs
  //////////
  hashtagSvg = require('shared/assets/img/svg/newheader-tag-dropdown-toggle.svg');
  searchSvg = require('shared/assets/img/svg/search.svg');
  addSvg = require('shared/assets/img/svg/small-add.svg');
  checkMarkSvg = require('shared/assets/img/svg/checkmark.svg');

  //////////////////
  // Subscriptions
  //////////////////
  private getAvailableLabelsSub: Subscription;
  private keyboardShortcutsSub: Subscription;

  constructor(
    protected _elementRef: ElementRef,
    protected _changeDetection: ChangeDetectorRef,
    protected _keyboardShortcutListenerService: KeyboardShortcutsListenerService,
    private _webOnboardingService: WebOnboardingService,
    private _trackingService: TrackingService,
    private _userManagerService: UserManagerService,
    private _labelService: LabelService,
  ) {
    super(_elementRef, _changeDetection, _keyboardShortcutListenerService);
  }

  private get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  ngOnInit() {
    this.registerKeyboardShortcuts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sharedTags || changes.labelTags) {
      this.setSelectedLabels();
      this.triggerChangeDetection();
    }
  }

  private registerKeyboardShortcuts() {
    this.keyboardShortcutsSub?.unsubscribe();
    this.keyboardShortcutsSub = this._keyboardShortcutListenerService
      .getActionsByTypes([KeyboardActionType.TAG_CONVERSATION])
      .pipe(
        tap((action: KeyboardShortcutData) => {
          if (action.action.type === KeyboardActionType.TAG_CONVERSATION) {
            this.openDropdown();
          }
        }),
      )
      .subscribe();
  }

  selectLabel(label: SharedTagLabelModel | TagLabelModel) {
    let tagType = label.$type === SharedTagLabelModel.type ? 'shared' : 'private';

    // Track user click action
    this._trackingService.trackUserClick(
      this.currentUserEmail,
      ClickTrackingLocation.TagsDropdown,
      'Tag ' + (this.alreadyAdded(label) ? 'removed' : 'added'),
      { tagType, threadType: this.threadType },
    );

    this.alreadyAdded(label) ? this.removeLabel.emit(label) : this.addLabel.emit(label);
  }

  alreadyAdded(label: SharedTagLabelModel | TagLabelModel): boolean {
    return this.selectedLabels.some(_label => _label.id === label.id);
  }

  onInputChange() {
    this.filterLabelsByQuery();
  }

  onDropdownClosed() {
    this.filterQuery = '';
  }

  onDropdownOpen() {
    this.setAvailableLabels();
  }

  openTagsSettings() {
    // Track user click action
    this._trackingService.trackUserClick(this.currentUserEmail, ClickTrackingLocation.TagsDropdown, 'Create tag');

    // Open tag settings
    this._webOnboardingService.openWebSettings(undefined, '/personal/tags/', window.open());

    // Close dropdown
    // List of available labels will be reloaded upon re-opening
    setTimeout(() => {
      this.closeDropdown();
    }, 100);
  }

  private setSelectedLabels() {
    this.sharedTags = this.sharedTags ?? [];
    this.labelTags = this.labelTags ?? [];

    this.selectedLabels = [...this.sharedTags, ...this.labelTags].filter(
      tag => tag.$type === SharedTagLabelModel.type || tag.$type === TagLabelModel.type,
    ) as (SharedTagLabelModel | TagLabelModel)[];
  }

  private filterLabelsByQuery() {
    this.availableLabels = this.availableLabels ?? [];

    this.filteredLabels = this.availableLabels.filter(
      label =>
        label.name?.toLowerCase().includes(this.filterQuery.toLowerCase()) &&
        (this.showOnlyLabelIds.length === 0 || this.showOnlyLabelIds.includes(label.id)),
    );

    this.maxSelectionIndex = this.filteredLabels.length - 1;
    this.triggerChangeDetection();
  }

  protected confirmListItemSelection() {
    const selection: SharedTagLabelModel | TagLabelModel = this.filteredLabels[this.selectionIndex];

    if (selection) {
      this.selectLabel(selection);
    }
  }

  private setAvailableLabels() {
    this.getAvailableLabelsSub?.unsubscribe();
    this.getAvailableLabelsSub = of(undefined)
      .pipe(
        mergeMap(() => this._userManagerService.onAllBlockingSyncDone(this.currentUserEmail)),
        mergeMap(() =>
          this._labelService.getAssignableLabelTags(
            this.currentUserEmail,
            this.sharedInboxId,
            this.threadType === 'email',
          ),
        ),
        tap((labels: (SharedTagLabelModel | TagLabelModel)[]) => {
          this.availableLabels = _.sortBy(labels, label => label.name.toLowerCase());
          this.setSelectedLabels();
          this.filterLabelsByQuery();
          this.listWrapper = this.itemsWrapper;
        }),
      )
      .subscribe();
  }
}
