import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { Attendee } from '@shared/api/api-loop/models/attendee';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { BaseViewDecorateService } from '../base-view-decorate.service';
import { ResponseTypeModel } from '@shared/api/api-loop/models/response-type-model';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import {
  CardAppointmentModel,
  CardDraftModel,
  CardMailModel,
  CardModel,
  CardSharedModel,
  CardViewData,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import {
  CommentBaseModel,
  CommentChatModel,
  CommentDraftModel,
  CommentModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import { UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { CommentViewDecorateService } from '../comment-view-decorator/comment-view-decorate.service';

@Injectable()
export class CardViewDecorateService extends BaseViewDecorateService<CardModel> {
  constructor(
    private _commentViewDecorateService: CommentViewDecorateService,
    private _userManagerService: UserManagerService,
  ) {
    super();
  }

  decorateViewData(forUserEmail: string, card: CardModel, force?: boolean): Observable<CardModel> {
    if (card._ui && !force) {
      return of(card);
    }

    return this.decorateBaseViewData(forUserEmail, card).pipe(
      mergeMap((_card: CardModel) => {
        return this.decorateCardDraftViewData(forUserEmail, _card);
      }),
    );
  }

  private decorateBaseViewData(forUserEmail: string, card: CardModel): Observable<CardModel> {
    /**
     * Defaults
     */
    let ui: CardViewData = {
      attachments: [],
    };
    let comments = CommentBaseModel.createList(card.getComments());
    return this._commentViewDecorateService.decorateListViewData(forUserEmail, comments).pipe(
      mergeMap((_comments: CommentModel[]) => {
        // Some agenda cards don't have comments. This is not a fix, but it
        // prevents error. Fix this at some point
        if (card.comments) {
          card.comments.resources = _comments;
        }
        return this.decorateViewDataFromComments(ui, _comments);
      }),
      map((_ui: CardViewData) => {
        card._ui = _ui;
        return card;
      }),
    );
  }

  private decorateViewDataFromComments(ui: CardViewData, comments: CommentModel[]): Observable<CardViewData> {
    return from(comments).pipe(
      tap((comment: CommentModel) => {
        if (comment.hasAttachments()) {
          ui.attachments = [...ui.attachments, ...comment.getAttachments()];
        }
      }),
      toArray(),
      map(() => {
        return ui;
      }),
    );
  }

  private decorateCardDraftViewData(forUserEmail: string, card: CardModel): Observable<CardModel> {
    if (!(card instanceof CardDraftModel)) {
      return of(card);
    }

    let draftComment = card.commentDraft as CommentDraftModel;
    return this._commentViewDecorateService.decorateViewData(forUserEmail, draftComment).pipe(
      map((_draftComment: CommentModel) => {
        card.commentDraft = _draftComment;
        return card;
      }),
    );
  }

  private decorateAppointmentViewData(forUserEmail: string, card: CardModel): Observable<CardModel> {
    // Only decorate appointment view data
    if (card.$type === CardAppointmentModel.type) {
      return this.decorateCardAppointmentViewData(<CardAppointmentModel>card);
    }

    // Decorate base + appointment view data
    // Remove the last check once agenda logic is thoroughly revisited
    if (
      card.$type === CardMailModel.type &&
      (<CardMailModel>card).appointmentLink &&
      (<CardMailModel>card).appointmentLink.$type === CardAppointmentModel.type &&
      (<CardAppointmentModel>(<CardMailModel>card).appointmentLink)._ex
    ) {
      return this.decorateViewData(forUserEmail, <CardAppointmentModel>(<CardMailModel>card).appointmentLink).pipe(
        map((appointment: CardModel) => {
          (<CardMailModel>card).appointmentLink = appointment;
          return card;
        }),
      );
    }

    return of(card);
  }

  private decorateCardAppointmentViewData(card: CardAppointmentModel): Observable<CardAppointmentModel> {
    let currentUserId = this._userManagerService.getCurrentUserId();

    // In case appointment lasts all day, we dont parse timezone
    let localStartTimeMoment = card.isAllDay ? moment.parseZone(card.startTime) : moment(card.startTime);
    card._ui.localStartTime = localStartTimeMoment.toISOString();

    let localEndTime = card.isAllDay ? moment.parseZone(card.endTime).add(-1, 'day') : moment(card.endTime);
    card._ui.localEndTime = localEndTime.toISOString();

    card._ui.day = localStartTimeMoment.format('DD');
    card._ui.month = localStartTimeMoment.format('MMM');
    card._ui.multiday = !localStartTimeMoment.isSame(card._ui.localEndTime, 'day');

    // duration
    if (card._ui.multiday) {
      if (card.isAllDay) {
        card._ui.duration =
          moment.parseZone(card.startTime).format('D MMM YYYY') +
          ' - ' +
          moment.parseZone(card.endTime).add(-1, 'day').format('D MMM YYYY');
      } else {
        card._ui.duration =
          moment(card.startTime).format('D MMM YYYY H:mm') + ' - ' + moment(card.endTime).format('D MMM YYYY H:mm');
      }
    } else {
      if (card.isAllDay) {
        card._ui.duration = 'All day';
      } else {
        card._ui.duration = moment(card.startTime).format('H:mm') + ' - ' + moment(card.endTime).format('H:mm');
      }
    }

    // BE does not return card.attendees on card list GET, therefore
    // we'll check for card.attendees and set default values if it is missing
    // !! this is a TEMP "fix" !!
    // https://github.com/4thOffice/4O_Backend/issues/1808

    // attendees
    if (card.attendees) {
      card._ui.declinedByCurrentUser = _.some(card.attendees.resources, (attendee: Attendee) => {
        return currentUserId === attendee.id && attendee.status === 'Declined';
      });
      card._ui.organiser = _.find(card.attendees.resources, (attendee: Attendee) => attendee.status === 'Organizer');
      card._ui.invitees = _.filter(card.attendees.resources, (attendee: Attendee) => attendee.id !== currentUserId);
      card._ui.currentUserStatus = card.response ? card.response.status : ResponseTypeModel.NEEDS_ACTION;

      // Generate list of usernames for each response type
      let acceptPeople = [];
      let tentativePeople = [];
      let declinePeople = [];
      card._ui.invitees.map(response => {
        if (response.status === ResponseTypeModel.ACCEPT) {
          acceptPeople.push(response.firstName);
        } else if (response.status === ResponseTypeModel.TENTATIVE) {
          tentativePeople.push(response.firstName);
        } else if (response.status === ResponseTypeModel.DECLINE) {
          declinePeople.push(response.firstName);
        }
      });
      card._ui.acceptResponses = this.getGroupStatusString(acceptPeople, 'accepted the invitation.');
      card._ui.tentativeResponses = this.getGroupStatusString(tentativePeople, 'tentatively accepted the invitation.');
      card._ui.declineResponses = this.getGroupStatusString(declinePeople, 'declined the invitation.');
    } else {
      card._ui.declinedByCurrentUser = false;
      card._ui.organiser = undefined;
      card._ui.invitees = [];
      card._ui.currentUserStatus = undefined;
      card._ui.acceptResponses = '';
      card._ui.tentativeResponses = '';
      card._ui.declineResponses = '';
    }

    return of(card);
  }

  private getGroupStatusString(group: string[], statusText) {
    if (group.length > 0) {
      if (group.length < 2) {
        return group[0] + ' has ' + statusText;
      }
      return (
        [group.slice(0, -1).join(', '), group.slice(-1)[0]].join(group.length < 2 ? ', ' : ' and ') +
        ' have ' +
        statusText
      );
    }
  }

  decorateCardsCommentsWithPreviousShareList(forUserEmail: string, cards: CardModel[]): Observable<CardModel[]> {
    return from(cards).pipe(
      mergeMap((card: CardModel) => {
        if (card instanceof CardSharedModel) {
          return this.decorateCardCommentsWithPreviousSharelist(forUserEmail, card);
        }

        return of(card);
      }),
      toArray(),
    );
  }

  private decorateCardCommentsWithPreviousSharelist(
    forUserEmail: string,
    card: CardSharedModel,
  ): Observable<CardSharedModel> {
    let comments = <CommentChatModel[]>card.getComments();
    let previousShareList: ContactBase[] = [];
    let firstComment = _.first(comments);

    return this._commentViewDecorateService.decorateListViewData(forUserEmail, comments).pipe(
      mergeMap((_comments: CommentModel[]) => {
        return from(_comments as CommentChatModel[]);
      }),
      tap((comment: CommentChatModel) => {
        if (_.isEmpty(previousShareList)) {
          previousShareList = comment.getShareList();
          return;
        }

        let shareList = comment.getShareList();
        comment._ui.previousSharelist = _.differenceBy(shareList, previousShareList, 'id');

        previousShareList = comment.getShareList();
      }),
      toArray(),
      map(() => {
        return card;
      }),
    );
  }
}
