import * as _ from 'lodash';
import { Directive } from '@angular/core';
import { PurgeStatistics } from '@dta/shared/models/purge.model';
import { ElectronProcessType, LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { LoggerHelperService } from './logger-helper.service';
import { Report } from '@shared/services/reporter/reporting.service';

export interface LoggerI {
  init(_userPath?: string, _electronProcessType?: ElectronProcessType): void;

  customLog(
    message: string,
    logLevel: LogLevel,
    _tags: LogTag | LogTag[],
    messageAsArgument: boolean,
    customLogMessage: string,
    customStrArgs: { [key: string]: string },
  ): void;

  error(
    error: Error,
    message: string,
    _tags: LogTag | LogTag[],
    messageAsArgument: boolean,
    customLogMessage: string,
  ): void;

  log(...args: any[]): void;

  debug(...args: any[]): void;

  warn(...args: any[]): void;

  logViewTiming(view: string, timeSec: number): void;

  logPurgeStatistics(forUserEmail: string, statistics: PurgeStatistics, instanceId: string): void;

  durationStart(forUserEmail: string, metricName: string): void;

  durationStopAndLog(forUserEmail: string, metricName: string, count?: number): void;

  logReport(forUserEmail: string, report: Report): void;

  handled_error(error: Error, errorId: string, ...args: string[]): void;

  remoteLog(msg: string, level: number, tags: string[]): void;

  logComponentTiming(view: string, description: string, timeMs: number, forAccount: string): void;
}
@Directive()
export abstract class Logger {
  //////////////////////
  // Static variables
  //////////////////////
  static _test: string[] = ['test'];
  static _mute: boolean;
  static _localLogsOnly: boolean;
  static _machineIP: any;
  static _machineId: string;
  static _version: string;
  static _logToFile: boolean = false; // Only for main process
  static _userEmail: string;
  static _turnOffForLocalDevelopment: boolean = false;
  static _env: string;
  static _localDevelopment: string[] = ['electron-development', 'development'];
  static _durationLogStartByName: { [name: string]: Date } = {};
  static _wasInit: boolean = false;
  static _loggingDisabled: boolean = false;
  static _development: string[] = Logger._test.concat([
    'electron-development',
    'development',
    'electron-beta',
    'beta',
    'electron-alpha',
  ]);
  static _apiEnv: string;
  static _turnOffWebsocketLogging: boolean = true;

  // Implement as service so we can change provider
  static _loggerHelperService: LoggerHelperService;

  /**
   * Is muted when not in development environment.
   * Can be unmuted via window.Logger.
   */
  static get isMuted(): boolean {
    if (Logger._mute === undefined && Logger._wasInit) {
      Logger._mute = !Logger.validateEnvironment(Logger._development);
      if (this._loggerHelperService.shouldExposeLogger) {
        window['Logger'] = this;
      }
    }

    return Logger._mute === true;
  }

  static set logToFile(value: boolean) {
    this._logToFile = value;
  }

  static get logToFile(): boolean {
    return this._logToFile;
  }

  static get machineIP(): string {
    return this._machineIP;
  }

  static validateEnvironment(supportedEnvironments: string[]) {
    return supportedEnvironments.includes(Logger._env);
  }

  static get localLogOnly(): boolean {
    if (Logger._localLogsOnly === undefined) {
      Logger._localLogsOnly =
        (Logger._turnOffForLocalDevelopment && Logger.validateEnvironment(Logger._localDevelopment)) ||
        Logger._loggingDisabled;
    }

    return Logger._localLogsOnly;
  }

  static setMachineId(machineId: string) {
    this._machineId = machineId;
  }

  static getMachineId(): string {
    return this._machineId;
  }

  static setVersion(version: string) {
    this._version = version;
  }

  static getVersion(): string {
    return this._version;
  }

  static setUserEmail(userEmail: string) {
    this._userEmail = userEmail;
  }

  static setLoggingDisabled(loggingDisabled: boolean) {
    if (loggingDisabled === Logger._loggingDisabled) {
      return;
    }

    if (Logger._loggingDisabled) {
      setTimeout(() => {
        Logger.customLog(`Logger: set loggingDisabled: ${loggingDisabled}`, LogLevel.ERROR);
      }, 0);
    } else {
      Logger.customLog(`Logger: set loggingDisabled: ${loggingDisabled}`, LogLevel.ERROR);
    }

    Logger._loggingDisabled = loggingDisabled;
    Logger._localLogsOnly = undefined;
  }

  static getUserEmail(): string {
    return this._userEmail;
  }

  static init(
    _env: string,
    _apiEnv: string,
    _loggerHelperService: LoggerHelperService,
    _userPath?: string,
    _electronProcessType?: ElectronProcessType,
  ) {
    this._env = _env;
    if (!_.isNil(_apiEnv)) {
      this._apiEnv = _apiEnv.toLowerCase();
    }

    // Might not be defined (in main service)
    if (_loggerHelperService) {
      this._loggerHelperService = _loggerHelperService;

      this._loggerHelperService.init(_userPath, _electronProcessType);
    }
  }

  static customLog(
    message: string,
    logLevel: LogLevel,
    _tags?: LogTag | LogTag[],
    messageAsArgument: boolean = false,
    customLogMessage: string = '',
    customStrArgs: { [key: string]: string } = {},
    customNumArgs: { [key: string]: number } = {},
  ) {
    this._loggerHelperService?.customLog(message, logLevel, _tags, messageAsArgument, customLogMessage, customStrArgs);
  }

  static log(...args: any[]) {
    this._loggerHelperService?.log();
  }

  static debug(...args: any[]) {
    this._loggerHelperService.debug();
  }

  /**
   * Log error to console and report via Sentry
   */
  static error(
    error: Error,
    message?: string,
    _tags?: LogTag | LogTag[],
    messageAsArgument: boolean = false,
    customLogMessage: string = '',
  ) {
    this._loggerHelperService?.error(error, message, _tags, messageAsArgument, customLogMessage);
  }

  /**
   * Always report via Sentry but to console only when not muted
   */
  static warn(...args: any[]) {
    this._loggerHelperService.warn(args);
  }

  static logViewTiming(view: string, timeSec: number) {
    this._loggerHelperService.logViewTiming(view, timeSec);
  }

  static logCommentClipping(message: string, commentId: string, commentLength: number, clippedCommentLength: number) {
    this._loggerHelperService.logCommentClipping(message, commentId, commentLength, clippedCommentLength);
  }

  static logPurgeStatistics(forUserEmail: string, statistics: PurgeStatistics, instanceId: string) {
    this._loggerHelperService.logPurgeStatistics(forUserEmail, statistics, instanceId);
  }

  static durationStart(forUserEmail: string, metricName: string) {
    this._loggerHelperService.durationStart(forUserEmail, metricName);
  }

  static durationStopAndLog(forUserEmail: string, metricName: string, count?: number) {
    this._loggerHelperService.durationStopAndLog(forUserEmail, metricName, count);
  }

  static logReport(forUserEmail: string, report: Report) {
    this._loggerHelperService.logReport(forUserEmail, report);
  }

  static handled_error(error: Error, errorId: string, ...args: string[]) {
    this._loggerHelperService.handled_error(error, errorId, ...args);
  }

  static remoteLog(msg: string, level: number, tags: string[]) {
    this._loggerHelperService.remoteLog(msg, level, tags);
  }

  static logComponentTiming(view: string, description: string, timeMs: number, forAccount: string) {
    this._loggerHelperService.logComponentTiming(view, description, timeMs, forAccount);
  }
}
