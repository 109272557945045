import { NgModule } from '@angular/core';
import { AppStateService } from '@shared/services/data/app-state/app-state.service';
import { CardUnreadService } from '@shared/services/data/card-unread/card-unread.service';
import { CardService } from '@shared/services/data/card/card.service';
import { CollectionService } from '@shared/services/data/collection/collection.service';
import { CommandService } from '@shared/services/data/command/command.service';
import { CommentSenderService } from '@shared/services/data/comment-sender/comment-sender.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { FolderService } from '@shared/services/data/folder/folder.service';
import { HtmlImageService } from '@shared/services/data/html-image/html-image.service';
import { LabelService } from '@shared/services/data/label/label.service';
import { SharedTagService } from '@shared/services/data/shared-tags/shared-tags.service';
import { TagService } from '@shared/services/data/tag/tag.service';
import { UserService } from '@shared/services/data/user/user.service';
import { CardServiceWeb } from './card/card.service.web';
import { CommentServiceWeb } from './comment/comment.service.web';
import { ContactServiceWeb } from './contact/contact.service.web';
import { FileService } from '@shared/services/data/file/file.service';
import { FileServiceWeb } from './file/file.service.web';
import { SnoozeService } from '@shared/services/data/snooze/snooze.service';
import { DraftService } from '@shared/services/data/draft/draft.service';
import { DraftServiceWeb } from './draft/draft.service.web';
import { CommentBodyHelperService } from '@shared/services/data/comment/comment-body-helper/comment-body-helper.service';
import { DataServiceShared } from '@shared/services/data/data.service';
import { AuthService } from '@shared/services/data/auth/auth.service';
import { RetryQueueService } from '@shared/services/data/retry-queue/retry-queue.service';
import { LibraryService } from '@shared/services/data/library/library.service';
import { CardCollectionService } from '@shared/services/data/collection/card-collection/card-collection.service';
import { FileCollectionService } from '@shared/services/data/collection/file-collection/file-collection.service';
import { SingleCardCollectionService } from '@shared/services/data/collection/single-card-collection/single-card-collection.service';
import { AgendaCollectionService } from '@shared/services/data/collection/agenda-collection/agenda-collection.service';
import { FileSynchronizationServiceWeb } from '../file-synchronization/file-synchronization.service.web';
import { FileSynchronizationService } from '@shared/services/data/file-synchronization/file-synchronization.service';
import { ConversationCollectionService } from '@shared/services/data/collection/conversation-collection/conversation-collection.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationCollectionCacheService } from '@shared/services/data/collection/conversation-collection/conversation-collection-cache/conversation-collection-cache.service';
import { DraftCollectionService } from '@shared/services/data/collection/draft-collection/draft-collection.service';
import { ConversationActionService } from '@shared/services/data/conversation-action/conversation-action.service';
import { LibraryServiceWeb } from './library/library.service.web';
import { TagLabelService } from '@shared/services/data/tag-label/tag-label.service';
import { CardsCollectionService } from '@shared/services/data/collection/cards-collection/cards-collection.service';
import { AvailabilityStatusService } from '@shared/services/data/availability-status/availability-status.service';
import { UserAvailabilityStatusService } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service';
import { SingleConversationCollection } from '@dta/ui/collections/conversations/single-conversation.collection';
import { ConversationServiceWeb } from '../conversation/conversation.service.web';
import { IntegrationService } from '@shared/services/data/integration/integration.service';
import { ConversationChangeService } from '@shared/services/data/conversation-change/conversation-change.service';
import { AppointmentService } from '@shared/services/data/appointment/appointment.service';
import { ConversationCommentsCollectionModule } from '@dta/ui/collections/conversation-comments/conversation-comments.collection.module';
import { SharedTagDataAccessModule } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/shared-tag-data-access.module';

@NgModule({
  imports: [ConversationCommentsCollectionModule, SharedTagDataAccessModule],
  exports: [],
  declarations: [],
  providers: [
    DataServiceShared,
    CommentBodyHelperService,
    FolderService,
    CommentSenderService,
    UserService,
    LabelService,
    TagLabelService,
    AuthService,
    RetryQueueService,
    CollectionService,
    CardsCollectionService,
    CardCollectionService,
    FileCollectionService,
    DraftCollectionService,
    SingleCardCollectionService,
    SingleConversationCollection,
    AgendaCollectionService,
    ConversationCollectionService,
    ConversationCollectionCacheService,
    SnoozeService,
    TagService,
    SharedTagService,
    AvailabilityStatusService,
    UserAvailabilityStatusService,
    IntegrationService,
    CommandService,
    AppStateService,
    CardUnreadService,
    ConversationActionService,
    ConversationChangeService,
    AppointmentService,
    HtmlImageService,
    { provide: ConversationService, useClass: ConversationServiceWeb },
    { provide: LibraryService, useClass: LibraryServiceWeb },
    { provide: FileSynchronizationService, useClass: FileSynchronizationServiceWeb },
    { provide: CardService, useClass: CardServiceWeb },
    { provide: CommentService, useClass: CommentServiceWeb },
    { provide: ContactService, useClass: ContactServiceWeb },
    { provide: FileService, useClass: FileServiceWeb },
    { provide: DraftService, useClass: DraftServiceWeb }
  ]
})
export class DataModuleWeb {}
