<div (click)="openCalendar = true">
  <loop-text-control
    (click)="textClick()"
    [size]="size"
    [disabled]="isDisabled"
    [placeholder]="placeholder"
    [ngModel]="latestUsedUserValue"
  ></loop-text-control>
</div>
<!-- Date picker -->
<date-picker
  *ngIf="openCalendar"
  [submitButtonText]="'Select'"
  [showTimeInput]="false"
  [initialSelectedTime]="selectedDate | toDateString"
  [submitOnDatePick]="true"
  (onOutsideClick)="openCalendar = false"
  outsideClick
  (dateSelected)="onDateSelected($event)"
>
</date-picker>
