import { Injectable } from '@angular/core';
import { SharedTagModel } from 'dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { Observable, of } from 'rxjs';
import { BaseViewDecorateService } from '../base-view-decorate.service';

@Injectable()
export class SharedTagViewDecorateService extends BaseViewDecorateService<SharedTagModel> {
  constructor() {
    super();
  }

  decorateViewData(forUserId: string, sharedTag: SharedTagModel, force?: boolean): Observable<SharedTagModel> {
    if (sharedTag._ui && !force) {
      return of(sharedTag);
    }

    let ui = {};
    sharedTag._ui = ui;

    return of(sharedTag);
  }
}
