import * as _ from 'lodash';
import { catchError, defaultIfEmpty, filter, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';
import { combineLatest, EMPTY, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { Logger } from '@shared/services/logger/logger';
import { CommentModel } from '../../../../dta/shared/models-api-loop/comment/comment.model';
import { FileModel, FileSynchronizationStatus } from '../../../../dta/shared/models-api-loop/file.model';
import { ApiService } from '@shared/api/api-loop/api.module';
import { ThumbnailSize } from '@shared/api/api-loop/models/thumbnail-size';
import { File } from '@shared/api/api-loop/models';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { SharedUserManagerService } from '../../../../dta/shared/services/shared-user-manager/shared-user-manager.service';
import { LogLevel, LogTag } from '../../../../dta/shared/models/logger.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { FileSynchronizationServiceI } from './file-synchronization.service.interface';
import { FileService } from '../file/file.service';
import { FileExDecorateService } from '@shared/decorators/extra-data-decorators/file-ex-decorator/file-ex-decorate.service';
import { FileViewDecorateService } from '../../../decorators/view-data-decorators/file-view-decorator/file-view-decorate.service';
import { getBuffer } from '@dta/shared/utils/common-utils';
import { FileApiCacheService } from '@shared/modules/files/shell/file-api-cache/file-api-cache.service';
import { FileUrlEntryI } from '../../../../web/app/services/file-storage/file-url-storage/file-url-storage.service';

export abstract class FileSynchronizationService implements FileSynchronizationServiceI {
  static COMMENT_SYNC_PAGE_SIZE = 1;

  constructor(
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _fileStorageService: FileStorageService,
    protected _api: ApiService,
    protected _fileService: FileService,
    protected _fileExDecorateService: FileExDecorateService,
    protected _fileViewDecorateService: FileViewDecorateService,
    protected readonly fileApiCacheService: FileApiCacheService,
  ) {}

  synchronizeFilesForComment(
    forUserEmail: string,
    comment: CommentModel,
    thumbnailsOnly: boolean = true,
    shouldSkipCache: boolean = false,
  ): Observable<CommentModel> {
    if (_.isEmpty(comment.getAttachments())) {
      return of(comment);
    }

    return of(comment).pipe(
      /**
       * Synchronize files
       */
      mergeMap((_comment: CommentModel) => {
        return this.synchronizeFiles(forUserEmail, comment.getAttachments(), thumbnailsOnly, shouldSkipCache);
      }),
      filter((files: FileModel[]) => {
        return !_.isEmpty(files);
      }),
      /**
       * Append them to comment and save it
       */
      mergeMap((files: FileModel[]) => {
        comment.attachments.resources = files;
        return of(comment);
      }),
      /**
       * Catch error
       */
      catchError(err => {
        Logger.error(
          err,
          `Error in synchronizeFilesForComment() for user: ${forUserEmail}]: comment.id: ${comment.id}`,
          LogTag.ERROR,
          true,
        );

        return EMPTY;
      }),
      defaultIfEmpty(comment),
    );
  }

  synchronizeFiles(
    forUserEmail: string,
    files: FileModel[] = [],
    thumbnailsOnly: boolean = false,
    shouldSkipCache: boolean = false,
  ): Observable<FileModel[]> {
    if (_.isEmpty(files)) {
      return of([]);
    }

    return from(files).pipe(
      /**
       * Filter out files without id (gifs and un-synced attachments)
       */
      filter((file: FileModel) => {
        return file.id !== undefined;
      }),
      toArray(),
      /**
       * Get metadata of files
       */
      mergeMap((_files: FileModel[]) => {
        return this.getFilesMetadata(forUserEmail, _files, shouldSkipCache).pipe(
          switchMap((_files: FileModel[]) => {
            const fileUrls: FileUrlEntryI[] = [];
            files.forEach(file => {
              if (file.urlLink) {
                fileUrls.push({
                  fileName: file.hash,
                  fileUrl: file.urlLink,
                });
              }
            });
            if (fileUrls.length) {
              this._fileStorageService.writeFileUrls(fileUrls).pipe(map(() => _files));
            }
            return of(_files);
          }),
        );
      }),
      /**
       * Download binaries
       */
      mergeMap((_files: FileModel[]) => {
        return this.downloadFiles(forUserEmail, _files, thumbnailsOnly);
      }),
      /**
       * Save all updated files
       */
      mergeMap((_files: FileModel[]) => {
        return this._fileService.saveAllAndPublish(forUserEmail, _files).pipe(
          switchMap(_downloadedFiles => {
            return this._fileViewDecorateService.decorateListViewData(forUserEmail, _downloadedFiles, true);
          }),
        );
      }),
      /**
       * Publish files
       */
      tap((_files: FileModel[]) => {
        PublisherService.publishEvent(forUserEmail, _files);
      }),
      /**
       * Return all files, not just updated ones
       */
      map((updatedFiles: FileModel[]) => {
        let updatedFilesByIds = _.keyBy(updatedFiles, (file: FileModel) => file._id);

        return _.map(files, (file: FileModel) => (file._id in updatedFilesByIds ? updatedFilesByIds[file._id] : file));
      }),
      defaultIfEmpty(files),
    );
  }

  private getFilesMetadata(
    forUserEmail: string,
    files: FileModel[],
    shouldSkipCache?: boolean,
  ): Observable<FileModel[]> {
    return from(files).pipe(
      mergeMap((file: FileModel) => {
        return this.getFileMetadata(forUserEmail, file, shouldSkipCache);
      }),
      toArray(),
    );
  }

  private getFileMetadata(forUserEmail: string, file: FileModel, shouldSkipCache?: boolean): Observable<FileModel> {
    if (file._ex.syncStatus.meta && !file._ex.syncStatus.failed && !shouldSkipCache) {
      return of(file);
    }

    // Don't fetch local file
    if (!file.id) {
      return of(file);
    }

    return of(file).pipe(
      /**
       * Fetch metadata
       */
      mergeMap((_file: FileModel) => {
        if (_file.hash && !shouldSkipCache) {
          return this.fileApiCacheService
            .getOrStoreObservable(_file.hash, this.fetchFileMetadata(forUserEmail, _file.id))
            .pipe(
              map((response: File) => {
                return {
                  ...response,
                  id: _file.id ?? response.id,
                  clientId: _file.clientId ?? response.clientId,
                  name: _file.name ?? response.name,
                  urlLink: _file.urlLink ?? response.urlLink,
                };
              }),
            );
        }
        return this.fetchFileMetadata(forUserEmail, _file.id);
      }),
      /**
       * Process response
       */
      map((response: File) => {
        let responseModel = new FileModel(response);

        // Fix for BE bug
        this.fixThumbnailDimensions(responseModel, file);

        // Preserve hidden property
        return _.assign(file, responseModel, { hidden: file.hidden });
      }),
      /**
       * Decorate _ex properties
       */
      map((mergedFile: FileModel) => {
        let isMetaIncomplete =
          mergedFile.isThumbnailSupported() && !mergedFile.isThumbnailReady() && !mergedFile.hasThumbnailFailed();

        mergedFile._ex.syncStatus.failed = false;
        mergedFile._ex.syncStatus.meta = !isMetaIncomplete;

        return mergedFile;
      }),
      /**
       * Handle API errors
       */
      catchError(err => {
        // Only retry for 500 and 503
        if ([500, 503].includes(err.status)) {
          Logger.customLog(
            `[SYNC] - FileSync [${forUserEmail}]: file.id:${file.id} will be retried. Response was ${err.status}.`,
            LogLevel.INFO,
            LogTag.SYNC,
          );

          file._ex.syncStatus.meta = false;
          file._ex.syncStatus.failed = false;
          return of(file);
        }

        // For other status codes: finish
        Logger.customLog(
          `[SYNC] - FileSync [${forUserEmail}]: file.id:${file.id} will not be retried. Response was ${err.status}.`,
          LogLevel.INFO,
          LogTag.SYNC,
        );

        file._ex.syncStatus.meta = false;
        file._ex.syncStatus.failed = true;
        return of(file);
      }),
    );
  }

  private fixThumbnailDimensions(response: FileModel, file: FileModel) {
    // fix not needed
    if (!file.hasValidThumbnailDimensions()) {
      return;
    }

    if (response.thumbnailInfo && !response.hasThumbnailPages()) {
      response.thumbnailInfo.pages = file.thumbnailInfo.pages;
    }

    if (!response.hasValidThumbnailDimensions() && file.hasValidThumbnailDimensions()) {
      let existingPage = file.getFirstThumbnailPage();
      let page = response.getFirstThumbnailPage();
      page.width = existingPage.width;
      page.height = existingPage.height;
    }
  }

  private downloadFiles(
    forUserEmail: string,
    files: FileModel[],
    thumbnailsOnly: boolean = false,
  ): Observable<FileModel[]> {
    const files$ = files
      .filter(file => file.id)
      .map(file => {
        return this.downloadFile(forUserEmail, file, thumbnailsOnly);
      });

    return combineLatest(files$).pipe(take(1));
  }

  protected downloadFile(forUserEmail: string, file: FileModel, thumbnailsOnly: boolean): Observable<FileModel> {
    return this.downloadBinaries(forUserEmail, file, thumbnailsOnly).pipe(
      catchError(err => {
        // Don't retry on this errors. They indicate something is wrong with request
        // and there is no use in us retrying the same request.
        if (_.includes([400, 403, 404], err.status)) {
          Logger.error(
            err,
            `[SYNC] - FileSync [${forUserEmail}]: could not download file fileId:${file.id}. Status is: ${err.status}.`,
            LogTag.SYNC,
          );

          file._ex.syncStatus.failed = true;

          return of(file);
        }

        if (_.includes([500, 503], err.status)) {
          Logger.error(
            err,
            `[SYNC] - FileSync [${forUserEmail}]: could not download file fileId:${file.id}. Status is: ${err.status}.`,
            LogTag.SYNC,
          );

          file._ex.syncStatus.failed = false;

          return of(file);
        }

        return throwError(err);
      }),
      mergeMap((file: FileModel) => this._fileExDecorateService.redecorateFilePath(file)),
      mergeMap((file: FileModel) => this.verifyFileExists(forUserEmail, file)),
      map((syncStatus: FileSynchronizationStatus) => {
        file._ex.syncStatus.attempt++;

        file._ex.syncStatus.failed = file._ex.syncStatus.failed || !syncStatus.binary;
        file._ex.syncStatus.meta = syncStatus.binary;
        file._ex.syncStatus.binary = syncStatus.binary;
        file._ex.syncStatus.thumbnail = syncStatus.thumbnail;
        file._ex.syncStatus.preview = syncStatus.preview;

        if (file._ui) {
          file._ui.isSyncing = !syncStatus.binary;
        }

        return file;
      }),
    );
  }

  private downloadBinaries(forUserEmail: string, file: FileModel, thumbnailsOnly: boolean): Observable<FileModel> {
    let obs: Observable<FileModel>[] = [];
    let thumbnailSupported = file.isThumbnailSupported();
    let thumbnailReady = file.isThumbnailReady();
    let thumbnailFailed = file.hasThumbnailFailed();
    let pdfPreviewSupported = file.isPdfPreviewSupported();

    // File binary
    if (!thumbnailsOnly) {
      obs.push(this.downloadContent(forUserEmail, file, 'binary'));
    }

    if (thumbnailSupported && thumbnailReady && !thumbnailFailed) {
      // File thumbnails
      obs.push(this._fileService.downloadThumbnail(forUserEmail, file));

      // File PDF preview (for documents like word, excel, ...)
      if (pdfPreviewSupported) {
        obs.push(this.downloadContent(forUserEmail, file, 'PDF_preview'));
      }
    }

    return forkJoin(obs).pipe(
      map((files: FileModel[]) => {
        // verify that all binary was downloaded
        if (pdfPreviewSupported && thumbnailSupported && files.length === 3) {
          // If preview is supported - we need 3 files - 1xFile and 1xPDF for preview and 1xSmall thumbnail
        } else if (pdfPreviewSupported && files.length === 2) {
          // If no IMG is supported - but PDF is - we expect one PDF and the file itself
        } else if (thumbnailSupported && files.length === 2) {
          // If no PDF is supported - but IMG is - we expect one small thumb and file itself
        } else if (!pdfPreviewSupported && files.length === 1) {
          // If preview is not supported then only 1xFile should get downloaded
        } else {
          Logger.customLog(
            `[SYNC] - FileSync [${forUserEmail}]: A potential issue in file download detected. fileId:${file.id}`,
            LogLevel.WARN,
            LogTag.SYNC,
          );
          throw new Error('File download error. File: ' + file.id);
        }

        return file;
      }),
    );
  }

  private verifyFileExists(forUserEmail: string, file: FileModel): Observable<FileSynchronizationStatus> {
    let syncStatus: FileSynchronizationStatus = {
      attempt: undefined,
      failed: false,
      meta: false,
      binary: false,
      thumbnail: false,
      preview: false,
    };

    if (_.isNil(file.id)) {
      return of(syncStatus);
    }

    return of(undefined).pipe(
      /**
       * Validate binary
       */
      mergeMap(() => this._fileStorageService.fileExists(file.hash)),
      tap((exists: boolean) => {
        syncStatus.binary = exists;
      }),
      mergeMap(() => {
        if (!file._ex) {
          Logger.customLog(
            `[SYNC] - FileSync [${forUserEmail}]: File with id:${file.id} is missing _ex`,
            LogLevel.ERROR,
            LogTag.SYNC,
          );
        }

        // Preview is the file itself
        if (file._ex.isImage || file._ex.isVideo || file._ex.isPdf) {
          return of(syncStatus.binary);
        }

        // PDF preview - is ready
        if (file.hasPdfPreview()) {
          let filename = this._fileStorageService.previewPDFFilename(file);
          return this._fileStorageService.fileExists(filename);
        }

        // Preview NOT supported
        return of(false);
      }),
      tap((exists: boolean) => {
        syncStatus.preview = exists;
      }),
      mergeMap(() => {
        if (!file.isThumbnailSupported()) {
          return of(false);
        }

        let filename = this._fileStorageService.imageThumbnailFilename(file, ThumbnailSize.MEDIUM);
        return this._fileStorageService.fileExists(filename);
      }),
      tap((exists: boolean) => {
        syncStatus.thumbnail = exists;
      }),
      map(() => {
        return syncStatus;
      }),
    );
  }

  protected fetchFileMetadata(forUserEmail: string, fileId: string): Observable<File> {
    return this._api.FileApiService.File_GetFile({ id: fileId, includeSignedLink: true }, forUserEmail);
  }

  protected downloadContent(
    forUserEmail: string,
    file: FileModel,
    contentType: 'binary' | 'PDF_preview',
  ): Observable<FileModel> {
    let filename = contentType === 'binary' ? file.hash : this._fileStorageService.previewPDFFilename(file);

    return of(undefined).pipe(
      /**
       * Check if file exists
       */
      mergeMap(() => this._fileStorageService.fileExists(filename)),
      filter((exists: boolean) => !exists),
      /**
       * Fetch file content when non existing
       */
      mergeMap(() =>
        contentType === 'binary'
          ? this._api.FileApiService.File_GetFileSignedLink({ id: file.id }, forUserEmail)
          : this._api.FileApiService.File_GetPdfThumbnailSignedLink({ id: file.id }, forUserEmail),
      ),
      /**
       * Write fetched file
       */
      mergeMap((url: string) => {
        return this._fileStorageService.writeFileUrl(filename, url).pipe(map(() => url));
      }),
      /**
       * Return file metadata
       */
      map(url => {
        file.urlLink = url;
        return file;
      }),
      defaultIfEmpty(file),
    );
  }
}
