/* tslint:disable */

/**
 * Possible Context Types
 */
export type ContextEnum = 'Archive' | 'Delete' | 'Inbox' | 'Folder';

export module ContextEnum {
  export const ARCHIVE: ContextEnum = 'Archive';
  export const DELETE: ContextEnum = 'Delete';
  export const INBOX: ContextEnum = 'Inbox';
  export const FOLDER: ContextEnum = 'Folder';

  export function values(): ContextEnum[] {
    return [ARCHIVE, DELETE, INBOX, FOLDER];
  }
}
