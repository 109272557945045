import * as _ from 'lodash';
import { Directive } from '@angular/core';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { from, Observable, of, throwError, toArray } from 'rxjs';
import { BaseService } from '../base/base.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { IntegrationsApiService } from '@shared/api/api-loop/services/integrations-api.service';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { ListOfResourcesOfIntegration } from '@shared/api/api-loop/models/list-of-resources-of-integration';
import { IntegrationServiceI } from '@shared/services/data/integration/integration.interface';
import { IntegrationDaoService } from '@shared/database/dao/integrations/integrations-dao.service';
import { IntegrationType } from '@shared/api/api-loop/models/integration-type';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { HttpErrorResponse } from '@angular/common/http';
import { Prompt } from '@shared/services/integrations/open-ai/prompts';
import { OpenAIResponse } from '@shared/api/api-loop/models/open-airesponse';
import { Integrations } from '@shared/modules/main/user-settings/integrations/integrations';

@Directive()
export class IntegrationService extends BaseService implements IntegrationServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _integrationDaoService: IntegrationDaoService,
    protected _integrationsApiService: IntegrationsApiService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'IntegrationsService';
  }

  saveOnly(forUserEmail: string, integration: IntegrationModel): Observable<IntegrationModel> {
    return this._integrationDaoService.saveAll(forUserEmail, [integration]).pipe(map(_.first));
  }

  save(forUserEmail: string, integration: IntegrationModel): Observable<IntegrationModel> {
    return this.saveAll(forUserEmail, [integration]).pipe(map(_.first));
  }

  saveAll(forUserEmail: string, integrations: IntegrationModel[]): Observable<IntegrationModel[]> {
    if (_.isEmpty(integrations)) {
      return of(integrations);
    }
    return this.doBeforeSave(integrations).pipe(mergeMap(() => this.saveToDb(forUserEmail, integrations)));
  }

  findAllIntegrations(forUserEmail: string): Observable<IntegrationModel[]> {
    return this._integrationDaoService.findAllIntegrations(forUserEmail);
  }

  saveAllAndPublish(forUserEmail: string, integrations: IntegrationModel[]): Observable<IntegrationModel[]> {
    return this.saveAll(forUserEmail, integrations).pipe(
      tap((_integrations: IntegrationModel[]) => {
        PublisherService.publishEvent(forUserEmail, _integrations);
      }),
    );
  }

  removeIntegration(forUserEmail: string, integration: IntegrationModel): Observable<any> {
    return this._integrationDaoService.remove(forUserEmail, integration).pipe(
      mergeMap(() => {
        return this._integrationsApiService.Integrations_DeleteIntegration(
          { integrationId: integration.id },
          forUserEmail,
        );
      }),
      tap(() => {
        PublisherService.publishEvent(forUserEmail, new IntegrationModel(integration), PublishEventType.Remove);
      }),
    );
  }

  syncIntegrations(forUserEmail: string): Observable<IntegrationModel[]> {
    let watch = new StopWatch(this.constructorName + '.syncIntegrations', ProcessType.SERVICE, forUserEmail);

    watch.log('Starting integrations sync');
    return this._integrationsApiService.Integrations_GetIntegrationList({}, forUserEmail).pipe(
      mergeMap((response: ListOfResourcesOfIntegration) => {
        watch.log('Processing response of size: ' + response.resources.length);

        return of(IntegrationModel.createList(response.resources));
      }),
      mergeMap((integrations: IntegrationModel[]) => {
        return this.removeCollection(forUserEmail).pipe(
          mergeMap(() => {
            return this.saveAllAndPublish(forUserEmail, integrations);
          }),
        );
      }),
    );
  }

  upsertIntegration(forUserEmail: string, integration: IntegrationModel): Observable<IntegrationModel> {
    return this._integrationsApiService.Integrations_UpsertIntegration({ integration: integration }, forUserEmail).pipe(
      map(IntegrationModel.create),
      mergeMap((_integration: IntegrationModel) => {
        return this.saveAllAndPublish(forUserEmail, [_integration]);
      }),
      map(_.first),
      // stringify error if you want to send it to FE
      catchError((error: HttpErrorResponse) => throwError(() => JSON.parse(JSON.stringify(error)))),
    );
  }

  findByType(forUserEmail: string, integrationType: IntegrationType): Observable<IntegrationModel[]> {
    return this._integrationDaoService.findByType(forUserEmail, integrationType);
  }

  askOpenAIBot(forUserEmail: string, prompt: Prompt, text: string): Observable<OpenAIResponse> {
    let request: IntegrationsApiService.Integrations_GetOpenAIResponseParams = {
      openAIRequest: { prompt: `${prompt} ${text}` },
    };

    return this._integrationsApiService.Integrations_GetOpenAIResponse(request, forUserEmail);
  }

  private doBeforeSave(integrations: IntegrationModel[]): Observable<IntegrationModel[]> {
    return from(integrations).pipe(
      map((integration: IntegrationModel) => {
        integration._ex = Integrations.find(_integration => _integration.serverType === integration.integrationType);
        return integration;
      }),
      toArray(),
    );
  }

  private saveToDb(forUserEmail: string, integrations: IntegrationModel[]): Observable<IntegrationModel[]> {
    return this._integrationDaoService.saveAll(forUserEmail, integrations);
  }

  ////////////////
  // DAO WRAPPERS
  ////////////////
  findById(forUserEmail: string, integrationId: string): Observable<IntegrationModel> {
    return this._integrationDaoService.findById(forUserEmail, integrationId);
  }

  removeCollection(forUserEmail): Observable<any> {
    return this._integrationDaoService.removeCollection(forUserEmail);
  }

  removeAll(forUserEmail: string): Observable<any> {
    return this._integrationDaoService.removeAllIntegrations(forUserEmail);
  }
}
