import { inject, Injectable } from '@angular/core';
import { ContactsApiCacheModule } from '@shared/modules/contacts/shell/contacts-api-cache/contacts-api-cache.module';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { StorageProviderService } from '@shared/cache/storage-provider/storage-provider';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { LRUCache } from 'lru-cache';
import { combineLatest, Observable, of } from 'rxjs';
import { ContactBaseModel, ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: ContactsApiCacheModule
})
export class SmartContactsApiService {
  private readonly contactApiService: ContactApiService = inject(ContactApiService);
  private readonly storageProviderService: StorageProviderService = inject(StorageProviderService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  protected readonly contactsApiCacheStorage: LRUCache<string, ContactModel | null | Observable<ContactModel | null>> =
    this.storageProviderService.provideStorage('smartContactCache', 300, 1000 * 60 * 15);

  getByIds$(ids: string[]): Observable<ContactModel[]> {
    const cachedEntities: ContactModel[] = [];
    const missingIds: string[] = [];
    const onGoingHierarchyObservables$: Observable<ContactModel | null>[] = [];

    ids.forEach(id => {
      if (this.contactsApiCacheStorage.has(id)) {
        const entity: Observable<ContactModel | null> | ContactModel | null = this.contactsApiCacheStorage.get(id);
        if (entity === null) {
          return;
        }
        if (entity instanceof Observable) {
          onGoingHierarchyObservables$.push(entity);
        } else {
          cachedEntities.push(entity);
        }
      } else {
        missingIds.push(id);
      }
    });

    if (!missingIds.length && !onGoingHierarchyObservables$.length) {
      return of(cachedEntities);
    }

    const missingEntities$ = missingIds.length
      ? this.contactApiService
          .Contact_GetList(
            {
              contactIds: missingIds,
              size: missingIds.length
            },
            this.userManagerService.getCurrentUserEmail()
          )
          .pipe(
            map(response => {
              const missingEntities: ContactModel[] = [];
              missingIds.forEach(missingId => {
                const missingEntity = response.resources.find(entity => entity.id === missingId);
                const missingEntityModel = missingEntity ? ContactBaseModel.create(missingEntity) : null;
                this.contactsApiCacheStorage.set(missingId, missingEntityModel);
                if (missingEntityModel) {
                  missingEntities.push(missingEntityModel);
                }
              });
              return missingEntities;
            }),
            publishReplay(1),
            refCount()
          )
      : of([]);

    missingIds.forEach(missingId => {
      this.contactsApiCacheStorage.set(
        missingId,
        missingEntities$.pipe(
          map(missingEntities => missingEntities.find(missingEntity => missingEntity.id === missingId) ?? null)
        )
      );
    });

    return combineLatest([missingEntities$, ...onGoingHierarchyObservables$]).pipe(
      map(([requestedResponse, ...onGoingObservables]) => {
        return [...requestedResponse, ...onGoingObservables, ...cachedEntities].filter(
          (entity: ContactModel | null): entity is ContactModel => {
            return !!entity && ids.includes(entity.id);
          }
        );
      })
    );
  }

  getSingle(id: string): Observable<ContactModel> {
    return this.getByIds$([id]).pipe(
      map(response => {
        return response?.[0];
      })
    );
  }

  removeContactFromStorage(id: string): void {
    this.contactsApiCacheStorage.delete(id);
  }
}
