<ng-container *ngIf="conversation$ | async; let conversation">
  <div class="header">
    <!-- Actions -->
    <div class="action-bar">
      <div class="left-section">
        <div class="top">
          <div class="star-title-wrapper">
            <ng-container *ngIf="conversation.channelType; else starActionTpl">
              <loop-conversation-channel-icon
                [width]="24"
                [channelType]="conversation.channelType"
              ></loop-conversation-channel-icon>
            </ng-container>

            <ng-template #starActionTpl>
              <!-- Star -->
              <ng-container *ngIf="conversation.cardType !== CardType_Chat">
                <div
                  *ngIf="!conversation.isStarred"
                  class="action star"
                  (click)="applyConversationAction(ActionEnum.MARK_AS_STARRED)"
                >
                  <svg-comp
                    [src]="star22Svg"
                    [tooltip]="{ content: 'Star (S)', placement: TooltipPlacement.BOTTOM }"
                  ></svg-comp>
                </div>

                <!-- Unstar -->
                <div
                  *ngIf="conversation.isStarred"
                  class="action unstar"
                  (click)="applyConversationAction(ActionEnum.MARK_AS_UNSTARRED)"
                >
                  <svg-comp
                    [src]="fullstar22Svg"
                    [tooltip]="{ content: 'Unstar (S)', placement: TooltipPlacement.BOTTOM }"
                  ></svg-comp>
                </div>
              </ng-container>
            </ng-template>

            <!-- Subject -->
            <div
              class="title"
              [class.cursor-pointer]="conversation.cardType !== 'CardChat'"
              (click)="copyTitleToClipboard(conversation)"
              [tooltip]="
                conversation.cardType === 'CardChat'
                  ? undefined
                  : { content: conversation.name, placement: TooltipPlacement.BOTTOM }
              "
            >
              <ng-container *ngIf="conversation.cardType === CardType_Chat; else mailConversationTpl">
                {{ conversation | chatConversationName }}
              </ng-container>
              <ng-template #mailConversationTpl>
                {{ conversation.name | subject }}
              </ng-template>
            </div>
          </div>

          <loop-conversation-folders [conversation]="conversation"></loop-conversation-folders>
        </div>

        <div *ngIf="canAddSharedTags$ | async" class="bottom-row">
          <loop-assignee-tag [conversation]="conversation"></loop-assignee-tag>
          <loop-classification-tag
            [classificationTag]="conversation | getClassificationSharedTag"
            [conversationIds]="[conversation.cardId]"
          ></loop-classification-tag>

          <ng-container *ngIf="sharedLabelTags$ | async; let sharedLabelTags">
            <ng-container *ngFor="let sharedLabelTag of sharedLabelTags">
              <div>
                <loop-conversation-tag
                  [loopClickDropdown]="
                    sharedLabelTag | getLabelDropdownItems: (sharedLabelTag | asPureArgumentFor: removeLabel)
                  "
                  [color]="sharedLabelTag.color"
                  [content]="sharedLabelTag.name"
                ></loop-conversation-tag>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="privateLabelTags$ | async; let privateLabelTags">
            <ng-container *ngFor="let privateLabelTag of privateLabelTags">
              <div>
                <loop-conversation-tag
                  [loopClickDropdown]="
                    privateLabelTag | getLabelDropdownItems: (privateLabelTag | asPureArgumentFor: removeLabel)
                  "
                  [color]="privateLabelTag.color"
                  [content]="privateLabelTag.name"
                  [suffixTpl]="suffixTpl"
                ></loop-conversation-tag>
              </div>

              <ng-template #suffixTpl>
                <loop-icon name="lock" width="12px" height="12px" [style.color]="privateLabelTag.color"></loop-icon>
              </ng-template>
            </ng-container>
          </ng-container>

          <loop-snooze-tag [conversation]="conversation"></loop-snooze-tag>
        </div>
      </div>

      <div class="right-section">
        <loop-conversation-header-dropdown
          #dropdownContainerTpl
          [conversation]="conversation"
          [contact]="contact$ | async"
          (selectionChange$)="onDropdownSelect($event)"
        >
        </loop-conversation-header-dropdown>
      </div>
    </div>
  </div>
</ng-container>
