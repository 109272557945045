import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmOrDeclineDialogContentComponent } from './confirm-or-decline-dialog-content.component';
import { ButtonModule } from '@shared/ui/button/button.module';
import { AutofocusModule } from '@dta/ui/directives/autofocus.directive';

@NgModule({
  declarations: [ConfirmOrDeclineDialogContentComponent],
  imports: [CommonModule, ButtonModule]
})
export class ConfirmOrDeclineDialogContentModule {}
