export function getWrapper(html: string): HTMLElement {
  let wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  return wrapper;
}

export function getHtmlFromWrapper(wrapper: HTMLElement): string {
  const imgDataSrcRegex: RegExp = new RegExp(` data-src=`, 'g');
  let html = wrapper.innerHTML.replace(imgDataSrcRegex, ' src=');
  wrapper.remove();
  return html;
}
