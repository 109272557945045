import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversationTagComponent } from '@shared/ui/conversation-tag/conversation-tag.component';

@NgModule({
  declarations: [ConversationTagComponent],
  imports: [CommonModule],
  exports: [ConversationTagComponent],
})
export class ConversationTagModule {}
