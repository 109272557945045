import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable, OnDestroy } from '@angular/core';
import { delay, from, fromEvent, Observable, of, Subscription, zip } from 'rxjs';
import { Router } from '@angular/router';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { EventCommentCreated, FilterEnum, ViewEnum } from '@shared/api/api-loop/models';
import {
  CommentBaseModel,
  CommentChatModel,
  CommentMailModel
} from '../../../shared/models-api-loop/comment/comment.model';
import { GroupModel } from '../../../shared/models-api-loop/contact/contact.model';
import { INotificationSettings } from '../../../../shared/modules/main/user-settings/general/general.component';
import { CardChatModel, CardSharedModel } from '../../../shared/models-api-loop/conversation-card/card/card.model';
import { bufferTime, concatMap, defaultIfEmpty, filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { ElectronService } from '@shared/services/electron/electron';
import { IPC } from '../../../shared/communication/ipc-constants';
import { StorageKey, StorageService } from '../../../shared/services/storage/storage.service';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import {
  DesktopNotificationEventWrapper,
  UserSwitchReason
} from '../../../../shared/services/communication/shared-subjects/shared-subjects-models';
import { SharedSubjects } from '../../../../shared/services/communication/shared-subjects/shared-subjects';
import { SharedAvatarService } from '../../../../shared/services/shared-avatar/shared-avatar.service';
import { AutoUnsubscribe } from '../../../shared/utils/subscriptions/auto-unsubscribe';
import { checkIfOS, isFirefox, OperatingSystem } from '../../../shared/utils/common-utils';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@AutoUnsubscribe()
@Injectable()
export class DesktopNotificationsService implements OnDestroy {
  /////////////////
  // Subscriptions
  /////////////////
  private notificationSub: Subscription;
  private notificationClickSub: Subscription;

  constructor(
    private _router: Router,
    private _userManager: UserManagerService,
    private _fileStorageService: FileStorageService,
    private _storageService: StorageService,
    private _conversationService: ConversationService,
    private _electronService: ElectronService,
    private _sharedAvatarService: SharedAvatarService
  ) {}

  ngOnDestroy(): void {}

  subscribeToDesktopNotifications() {
    this.notificationSub?.unsubscribe();
    this.notificationSub = SharedSubjects._desktopNotifications$
      .pipe(
        map((event: DesktopNotificationEventWrapper) => {
          console.log(event);
          let usersData = this._userManager.getAllUserEmails();
          return _.filter(event.desktopNotificationEvent, (notification: EventCommentCreated) => {
            // If post from notification is older than 30 minutes don't show the notification
            let diff = moment().diff(moment(notification.comment.created), 'minutes');
            if (diff > 30) {
              return false;
            }

            // If sender of the post from notification is currently signed in don't show the notification
            return usersData.some(
              (user: any) => notification.recipient.email === user && notification.comment.author.email !== user
            );
          });
        }),
        /**
         * Filter comments for notifications
         */
        concatMap((notifications: EventCommentCreated[]) => {
          return this.filterCommentsForNotifications(notifications);
        }),
        mergeMap((notifications: EventCommentCreated[]) => {
          return from(notifications);
        }),
        /**
         * Build notification
         */
        map((notification: EventCommentCreated) => {
          let comment = CommentBaseModel.create(notification.comment);
          let content = comment.snippet;
          let isCommentChat = false;
          let isLoopin = false;
          let groupRecipient;

          if (comment instanceof CommentChatModel) {
            groupRecipient = comment.shareList.resources.find(contact => contact.$type === 'Group');
            isCommentChat = true;
          } else if (comment instanceof CommentMailModel) {
            groupRecipient = comment.to.resources.find(contact => contact.$type === 'Group');
          }

          if (comment.parent.$type === CardSharedModel.type) {
            isLoopin = true;
          }

          let iconUri =
            this._sharedAvatarService.getAvatarFile(
              notification.recipient.email,
              this._fileStorageService.getFilesUri(),
              SharedAvatarService.getAvatarFileName(notification.recipient.email, comment.author),
              comment.author.id,
              comment.author
            ) || '/shared/assets/icon/icon.ico';

          return <IDesktopNotificationOptions>{
            Title: comment.author.name,
            Body: _.truncate(content, { length: 80 }),
            Subject: comment.parent.name,
            Uri: this.generateUri(notification.recipient.email, comment, notification.recipient.id),
            UserId: notification.recipient.id,
            UserEmail: notification.recipient.email,
            IconUri: iconUri,
            IsChat: isCommentChat,
            IsGroup: !_.isNil(groupRecipient),
            IsLoopin: isLoopin,
            GroupName: _.isNil(groupRecipient) ? undefined : groupRecipient.name,
            HasAttachments: comment.hasAttachments(),
            HasMention: comment.hasMention()
          };
        }),
        /**
         * Buffer notifications in time span of 4 seconds
         */
        bufferTime(4 * 1000),
        filter(notifications => {
          return !_.isEmpty(notifications);
        }),
        /**
         * Show one notification
         */
        tap((notifications: IDesktopNotificationOptions[]) => {
          let [firstNotification, ...otherNotifications] = notifications;

          this.newNotification({
            ...firstNotification,
            ...{
              Title: otherNotifications.length ? notifications.length + ' new notifications' : firstNotification.Title
            }
          });
        })
      )
      .subscribe();
  }

  private filterCommentsForNotifications(notifications: EventCommentCreated[]): Observable<EventCommentCreated[]> {
    return from(notifications).pipe(
      /**
       * Get parent
       */
      mergeMap((notification: EventCommentCreated) => {
        return zip(of(notification), this.findParent(notification));
      }),
      mergeMap((data: [EventCommentCreated, ConversationModel]) => {
        if (!data[1]) {
          return zip(
            of(data[0]),
            of(undefined).pipe(
              delay(2000),
              mergeMap(() => this.findParent(data[0]))
            )
          );
        }
        return of(data);
      }),
      /**
       * Filter out cards that don't match MyLoopInbox view criteria
       * or are not chat cards
       */
      filter((data: [EventCommentCreated, ConversationModel]) => {
        return data[1] && data[1].shouldShowInSubView(ViewEnum.LOOP_INBOX, FilterEnum.INBOX);
      }),
      /**
       * Return only filtered commentCreated events
       */
      map((data: [EventCommentCreated, ConversationModel]) => {
        return data[0];
      }),
      toArray(),
      defaultIfEmpty([])
    );
  }

  private findParent(notification: EventCommentCreated): Observable<ConversationModel> {
    let cardId = notification.comment.parent.id.replace('-copy1T', '');
    return this._conversationService
      .findOrFetchByCardIds(notification.recipient.email, [cardId])
      .pipe(map(conversations => _.first(conversations)));
  }

  private generateUri(userEmail: string, comment: CommentBaseModel, userId: string): string {
    if (comment.$type === CommentChatModel.type && comment.parent.$type === CardChatModel.type) {
      // Chats are opened in chats
      let castComment = <CommentChatModel>comment;

      // Find group in shareList
      let group = castComment.shareList.resources.find(contact => contact.$type === GroupModel.type);

      if (_.isEmpty(group)) {
        // Find other contact from 1on1 conversation if group is not in shareList
        group = castComment.shareList.resources.find(contact => contact.id !== userId);
      }

      return '/inbox/messages/chat/' + group.id;
    } else {
      // Loops and Emails are opened in My loop inbox
      return '/myloopinbox/' + comment.parent.id;
    }
  }

  private navigateToUri(uri: string, userEmail: string, userId: string) {
    let currentUserId = this._userManager.getCurrentUserId();

    // If switch right away if correct current user
    if (userId === currentUserId) {
      setTimeout(() => {
        this._router.navigate([uri]);
      }, 100);
      return;
    }

    // Switch user and navigate
    let user = this._userManager.getUserLoginByEmail(userEmail);
    this._userManager.switchUser(user, UserSwitchReason.notification);

    setTimeout(() => {
      this._router.navigate([uri]);
    }, 2 * 1000);
  }

  private newNotification(options: IDesktopNotificationOptions) {
    // Get notification settings or use default
    let key = this._storageService.getKey(options.UserEmail, StorageKey.userNotificationSettings);
    let notificationSetting: INotificationSettings =
      this._storageService.getParsedItem(key) || new INotificationSettings();

    // Don't show notification when app is in focus
    if (!notificationSetting.isNotifyWhenFocusedEnabled && document.hasFocus()) {
      return;
    }

    // Not Allowed
    if (!notificationSetting.allowNotifications) {
      return;
    }
    // Chat
    if (options.IsChat && !notificationSetting.notifications.chat) {
      return;
    }
    // Email
    if (!options.IsChat && !options.IsGroup && !notificationSetting.notifications.email) {
      return;
    }
    // Team email
    if (options.IsGroup && !notificationSetting.notifications.teamEmail) {
      return;
    }

    // Build chat notification body
    if (options.IsChat) {
      if (options.HasAttachments) {
        options.Body = 'Message 📎\n' + options.Body;
      } else {
        options.Body = 'Message\n' + options.Body;
      }

      // Handle mention
      if (options.HasMention) {
        options.Body = '📢 ' + options.Body;
      }
    }

    // Build mail
    if (!options.IsChat) {
      if (options.HasAttachments) {
        options.Subject = options.Subject + ' 📎';
      }

      // For all emails we also show subject line
      options.Body = 'Subject: ' + options.Subject + '\n' + options.Body;
    }

    // If post is sent to team, there should be a name of the team in parentheses.
    if (options.IsGroup) {
      options.Title = options.Title + ' (' + options.GroupName + ')';
    }

    // Set silent option
    options.Silent = !notificationSetting?.notificationSound;

    // Build notification
    let notification = new Notification(options.Title, {
      body: options.Body,
      icon: options.IconUri,
      silent: true, // This does not work on Mac Firefox so we disable the custom sound
      requireInteraction: false
    });

    if (!options.Silent && !this.isFirefoxOnMac()) {
      let audio = new Audio('./shared/assets/sounds/pop.mp3');
      audio.play();
    }

    // Close notification automatically - prevents stacking many notifications
    setTimeout(() => {
      notification?.close();
    }, 5_000);

    this.notificationClickSub?.unsubscribe();
    this.notificationClickSub = fromEvent(notification, 'click')
      .pipe(take(1))
      .subscribe(() => {
        if (options.Uri) {
          this.navigateToUri(options.Uri, options.UserEmail, options.UserId);
        }

        this._electronService.ipcRenderer.send(IPC.FOCUS_ON_APP);
        notification.close();
      });
  }

  private isFirefoxOnMac(): boolean {
    return checkIfOS(OperatingSystem.DARWIN) && isFirefox();
  }
}

export interface IDesktopNotificationOptions {
  Title: string;
  Body: string;
  Uri: string;
  Silent?: boolean;
  UserId: string;
  UserEmail: string;
  IconUri: string;
  IsChat?: boolean;
  IsGroup?: boolean;
  IsLoopin?: boolean;
  Subject?: string;
  GroupName?: string;
  HasAttachments?: boolean;
  HasMention?: boolean;
}
