import { Directive } from '@angular/core';
import { CommentDraftModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { DraftService, SharedDraftData } from '@shared/services/data/draft/draft.service';
import { Observable } from 'rxjs';

@Directive()
export class DraftServiceWeb extends DraftService {
  get constructorName(): string {
    return 'DraftServiceWeb';
  }

  updateSharedDraftContent(forUserEmail: string, contentFileId: string, content: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  updateSharedDraftComment(forUserEmail: string, sharedDraftComment: CommentDraftModel): Observable<CommentDraftModel> {
    throw new Error('Method not implemented.');
  }

  getSharedDraftData(forUserEmail: string, sharedDraft_id: string): Observable<SharedDraftData> {
    throw new Error('Method not implemented.');
  }
}
