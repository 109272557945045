import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextControlComponent } from '@shared/modules/form-controls/text-control/text-control.component';
import { FormControlWrapperModule } from '@shared/modules/form-controls/base/form-control-wrapper/form-control-wrapper.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { StringTemplateOutletModule } from '@shared/ui/kit/string-template-outlet/string-template-outlet.module';

@NgModule({
  declarations: [TextControlComponent],
  imports: [CommonModule, FormsModule, FormControlWrapperModule, IconModule, StringTemplateOutletModule],
  exports: [TextControlComponent]
})
export class TextControlModule {}
