import * as _ from 'lodash';
import {
  combineLatest,
  concat,
  EMPTY,
  forkJoin,
  from,
  interval,
  Observable,
  of,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  defaultIfEmpty,
  distinct,
  filter,
  first,
  map,
  mergeMap,
  publishReplay,
  refCount,
  startWith,
  tap,
  toArray,
} from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import {
  ContactBase,
  ContactType,
  Group,
  GroupSubType,
  GroupType,
  TagType,
  User,
  UserStatus,
} from '@shared/api/api-loop/models';
import { Injector } from '@angular/core';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import {
  ContactModel,
  GroupModel,
  NoAccessContact,
  UserModel,
} from '@dta/shared/models-api-loop/contact/contact.model';
import { ContactFilter, FilterByMethod, SidebarContacts } from '@dta/shared/models/contact.model';
import { ContactChanges } from '@dta/shared/models/event.models';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StringUtils } from '@dta/shared/utils/common-utils';
import { StopWatch } from '@dta/shared/utils/stop-watch';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import {
  ContactSidebarChange,
  ContactStoreChange,
  PublishEventType,
} from '@shared/services/communication/shared-subjects/shared-subjects-models';

export class ContactStore {
  // Parameters
  topSidebarCountPerSectionDefault: number = 20; // Default value for number of items per section in sidebar
  topSidebarCountPerSectionExtended: number = 1024; // Extended value for number of items per section in sidebar

  // State variables
  private _contactsById: _.Dictionary<ContactModel> = {};
  private _initialized = false;

  // Indexes
  private _contactIdsByEmail: _.Dictionary<string> = {}; // Use Dictionary for constant access time
  private _accessibleGroupIds: _.Dictionary<any> = {}; // Use Dictionary for constant access time
  private _groupIds: _.Dictionary<any> = {}; // Use Dictionary for constant access time

  // Subjects
  private contactsChanges$: Subject<ContactChanges> = new Subject();

  // Subscriptions
  private syncEventsSub: Subscription;
  private contactFeedSub: Subscription;

  // Injected services
  private _contactService: ContactService;
  private _sharedUserManagerService: SharedUserManagerService;

  // Online autosuggest
  private fetchContactDebounceFunc: Function;
  private fetchContactDebounceSub: Subscription;

  /////////////////////
  // SIDEBAR VARIABLES
  /////////////////////
  // Sidebar sorted limited dictionaries
  // Dictionary structure:
  //  o key: contact.id
  //  o values: array of values to sort by, ordered by importance
  private _favourites_limited: _.Dictionary<any[]> = {}; // Unordered ordered dictionary od top N values
  private _peoples_limited: _.Dictionary<any[]> = {}; // Unordered ordered dictionary od top N values
  private _teams_limited: _.Dictionary<any[]> = {}; // Unordered ordered dictionary od top N values
  private _sharedInbox_limited: _.Dictionary<any[]> = {}; // Unordered ordered dictionary od top N values
  private _personalInbox_limited: _.Dictionary<any[]> = {}; // Unordered ordered dictionary od top N values

  private _favourites_threshold_contactId: string = undefined; // Value that is the min and is threshold
  private _peoples_threshold_contactId: string = undefined; // Value that is the min and is threshold
  private _teams_threshold_contactId: string = undefined; // Value that is the min and is threshold
  private _sharedInbox_threshold_contactId: string = undefined; // Value that is the min and is threshold
  private _personalInbox_threshold_contactId: string = undefined; // Value that is the min and is threshold

  constructor(
    protected _userEmail: string,
    protected _injector: Injector,
  ) {
    if (!this._userEmail) {
      throw new Error(this.constructorName + ': _userEmail cannot be empty');
    }

    // Inject services here so other services can inject with
    // no circular dependency issues
    this._contactService = this._injector.get(ContactService);
    this._sharedUserManagerService = this._injector.get(SharedUserManagerService);

    this.subscribeToContactFeed();
    this.subscribeToRemoveEvents();
  }

  get constructorName(): string {
    return 'ContactStore';
  }

  get init$(): Observable<boolean> {
    if (this._initialized) {
      return of(true);
    }

    return interval(100).pipe(
      map(() => {
        return this._initialized;
      }),
      filter((initialized: boolean) => {
        return initialized;
      }),
      first(),
      publishReplay(1),
      refCount(),
    );
  }

  destroy() {
    // Remove all subscriptions
    this.syncEventsSub?.unsubscribe();
    this.contactFeedSub?.unsubscribe();
    this.fetchContactDebounceSub?.unsubscribe();

    // Close all subjects
    this.contactsChanges$.complete();
  }

  ///////////////////
  // Contact Getters
  ///////////////////
  getContactsByIds(ids: string[], undefinedIfMissing?: boolean): Observable<ContactModel[]> {
    if (_.isEmpty(ids)) {
      return of([]);
    }

    return this.init$.pipe(
      mergeMap(() => {
        const contacts = ids.reduce((acc, id) => {
          if (id in this._contactsById) {
            acc.push(this.getCloneOfContact(id));
          }
          return acc;
        }, []);

        if (undefinedIfMissing) {
          return forkJoin([of([]), of(contacts)]);
        }
        let missingIds = _.difference(
          ids,
          _.map(contacts, contact => contact.id),
        );

        return this.handleMissingContacts(missingIds).pipe(
          map((_contacts: ContactModel[]) => {
            return [...contacts, ..._contacts];
          }),
        );
      }),
    );
  }

  getContactById(id: string, undefinedIfMissing?: boolean): Observable<ContactModel> {
    if (!id) {
      return throwError('Id cannot be nil');
    }

    return this.init$.pipe(
      map(() => {
        // Get contact by id
        return this.getCloneOfContact(id);
      }),
      mergeMap((contact: ContactModel) => {
        // Find or fetch contact if missing
        if (!contact && !undefinedIfMissing) {
          Logger.customLog('Could not find contact with id: ' + id + ' for user: ' + this._userEmail, LogLevel.ERROR);
          return this.handleMissingContact(id);
        }

        // Clone so that saved reference stays unchanged
        return of(contact);
      }),
      /**
       * Handle group you don't have access to (remove when BE supports getGroup by parent)
       */
      catchError(err => {
        if (err.status === 403) {
          if (!id.startsWith('user_')) {
            let group: Group = {
              $type: GroupModel.type,
              id: id,
              name: NoAccessContact.name,
            };
            return of(new GroupModel(group));
          }
        }
      }),
    );
  }

  getContactByEmail(email: string): Observable<ContactModel> {
    if (!email) {
      throw new Error('Email cannot be nil');
    }

    return this.init$.pipe(
      map(() => {
        // Get id by email
        return this._contactIdsByEmail[email];
      }),
      mergeMap((id: string) => {
        if (!id) {
          return this.handleMissingId(email);
        }

        return this.getContactById(id);
      }),
    );
  }

  getUserEmailsBySharedInboxId(): Observable<{ [id: string]: string }> {
    return this.init$.pipe(
      mergeMap(() => {
        return this.getInboxesWithAllowedImpersonatedSenders();
      }),
      mergeMap((sharedInboxes: GroupModel[]) => {
        let userEmailsBySharedInboxId = {};
        _.forEach(sharedInboxes, sharedInbox => {
          userEmailsBySharedInboxId[sharedInbox.id] =
            sharedInbox.syncingAliasAccount?.email || sharedInbox.syncingAccount?.email;
        });
        return of(userEmailsBySharedInboxId);
      }),
    );
  }

  getContactByIdOrThrow(id: string): Observable<ContactModel> {
    if (!id) {
      return throwError('Id cannot be nil');
    }

    let contact = this.getCloneOfContact(id);

    if (!contact) {
      return throwError('Contact with id: ' + id + ' not found for user: ' + this._userEmail);
    }

    return of(contact);
  }

  getSidebarContacts(): Observable<SidebarContacts> {
    return this.init$.pipe(
      mergeMap(() => {
        return of(this.limitedDictionariesToSidebarContacts());
      }),
    );
  }

  getTeamChannels(): Observable<ContactModel[]> {
    return this.init$.pipe(
      mergeMap(() => {
        let keys = Object.keys(this._accessibleGroupIds).filter(key => {
          let group = this._contactsById[key] as GroupModel;
          return group?.groupSubType === GroupSubType.NORMAL && !group?._ex?.isNotAccessible;
        });

        return of(this.contactIdsToContacts(keys));
      }),
    );
  }

  getContactsForChannelTags(): Observable<ContactModel[]> {
    return this.init$.pipe(
      map(() => {
        let forUserId = this._sharedUserManagerService.getUserIdByEmail(this._userEmail);
        return [..._.keys(this._groupIds), forUserId];
      }),
      mergeMap((contactIds: string[]) => {
        return this.getContactsByIds(contactIds);
      }),
    );
  }

  getMergedWithExisting(partialContacts: ContactModel[]): Observable<ContactModel[]> {
    return this.init$.pipe(
      mergeMap(() => {
        return from(partialContacts);
      }),
      map((partialContact: ContactModel) => {
        let existingContact = this.getCloneOfContact(partialContact.id);

        if (existingContact) {
          partialContact = _.merge(existingContact, partialContact);
        }

        return partialContact;
      }),
      toArray(),
    );
  }

  existsWithHigherOrEqualRevision(contactToCompare: ContactModel): Observable<boolean> {
    if (!contactToCompare) {
      return throwError('Contact to compare cannot be nil.');
    }

    return this.init$.pipe(
      mergeMap(() => {
        if (contactToCompare.id) {
          return this.compareById(contactToCompare);
        } else {
          return this.compareByEmail(contactToCompare);
        }
      }),
    );
  }

  private compareById(contactToCompare: ContactModel): Observable<boolean> {
    let existingContact = this.getCloneOfContact(contactToCompare.id);
    let result =
      existingContact !== undefined && BaseModel.isRevisionGreaterOrEqualThan(existingContact, contactToCompare);

    return of(result);
  }

  private compareByEmail(contactToCompare: ContactModel): Observable<boolean> {
    let idForEmail = this._contactIdsByEmail[contactToCompare.email];
    return of(idForEmail !== undefined);
  }

  ////////////
  // COUNTERS
  ////////////

  // Get counters that represent user state. For reporter
  getCounters(): Observable<Counters> {
    return this.init$.pipe(
      map(() => {
        let counters = new Counters();

        counters.allContactsCount = Object.keys(this._contactsById).length;
        counters.groupContactsCount = Object.keys(this._accessibleGroupIds).length;

        let mutedContacts = _.filter(
          Object.values(this._contactsById),
          (contact: ContactModel) => contact.$type === GroupModel.type && !(<GroupModel>contact).subscribed,
        );
        counters.mutedContactsCount = mutedContacts.length;

        return counters;
      }),
    );
  }

  /////////////////
  // Subscriptions
  /////////////////
  subscribeToContactChanges(): Observable<ContactChanges> {
    return this.init$.pipe(
      mergeMap(() => {
        return this.contactsChanges$.asObservable();
      }),
    );
  }

  subscribeToContact(contact: ContactModel): Observable<ContactModel> {
    if (!contact) {
      throw new Error('Contact cannot be nil');
    }

    // Hook on this.contactsChanges$ observable and
    // emit contact every time
    return this.subscribeToContactById(contact.id);
  }

  subscribeToContactById(id: string): Observable<ContactModel> {
    if (!id) {
      throw new Error('Contact id cannot be nil');
    }

    // Hook on this.contactsChanges$ observable and
    // emit contact every time
    return this.contactsChanges$.pipe(
      filter((contactsChanges: ContactChanges) => {
        return contactsChanges.includesUpdateOf(id);
      }),
      debounceTime(100),
      // Make sure we get initial emission
      startWith(null),
      mergeMap(() => this.getContactById(id)),
      distinct(),
    );
  }

  //////////////////
  // Get by indexes
  //////////////////
  findAllAccessibleGroupIds(): Observable<string[]> {
    return this.init$.pipe(
      map(() => {
        let contactIds = _.keys(this._accessibleGroupIds);
        return contactIds.slice(0); // Don't use Deepclone
      }),
    );
  }

  ///////////////////////
  // Shared inbox helper
  ///////////////////////
  private getAllInboxesWithAllowedImpersonatedSenders(): Observable<GroupModel[]> {
    return this.init$.pipe(
      mergeMap(() => {
        let contacts = _.values(this._contactsById);

        let result = <GroupModel[]>_.filter(contacts, (contact: ContactModel) => {
          return contact instanceof GroupModel && !contact._ex.isNotAccessible;
        });

        return of(result);
      }),
    );
  }

  getInboxesWithAllowedImpersonatedSenders(): Observable<GroupModel[]> {
    return this.getAllInboxesWithAllowedImpersonatedSenders().pipe(
      mergeMap((inboxes: GroupModel[]) => {
        let result = <GroupModel[]>_.filter(inboxes, (contact: ContactModel) => {
          return (
            contact instanceof GroupModel &&
            (contact.groupType === GroupType.PERSONAL_INBOX || contact.groupType === GroupType.SHARED_INBOX) &&
            contact.groupSubType !== GroupSubType.MANAGED_TEAM
          );
        });
        return of(result);
      }),
    );
  }

  getPersonalInboxesWithAllowedImpersonatedSenders(): Observable<GroupModel[]> {
    return this.getAllInboxesWithAllowedImpersonatedSenders().pipe(
      mergeMap((inboxes: GroupModel[]) => {
        let result = <GroupModel[]>_.filter(inboxes, (contact: ContactModel) => {
          return contact instanceof GroupModel && contact.groupType === GroupType.PERSONAL_INBOX;
        });
        return of(result);
      }),
    );
  }

  getSharedInboxesWithAllowedImpersonatedSenders(): Observable<GroupModel[]> {
    return this.getAllInboxesWithAllowedImpersonatedSenders().pipe(
      mergeMap((inboxes: GroupModel[]) => {
        let result = <GroupModel[]>_.filter(inboxes, (contact: ContactModel) => {
          return (
            contact instanceof GroupModel &&
            contact.groupType === GroupType.SHARED_INBOX &&
            contact.groupSubType !== GroupSubType.MANAGED_TEAM
          );
        });
        return of(result);
      }),
    );
  }

  getAllAllowedImpersonatedSenders(): Observable<User[]> {
    return this.init$.pipe(
      mergeMap(() => {
        return this.getSharedInboxesWithAllowedImpersonatedSenders();
      }),
      mergeMap((sharedInboxes: GroupModel[]) => {
        let allowedImpersonations = _.map(
          sharedInboxes,
          sharedInbox => sharedInbox.syncingAliasAccount || sharedInbox.syncingAccount,
        );
        _.remove(allowedImpersonations, impersonation => impersonation.email === this._userEmail);
        return of(allowedImpersonations);
      }),
    );
  }

  getAllowedImpersonatedUsersFromSharelist(shareList: ContactBase[]): Observable<User[]> {
    return this.init$.pipe(
      mergeMap(() => {
        return this.getAllAllowedImpersonatedSenders();
      }),
      mergeMap((allSenders: User[]) => {
        return of(_.intersectionBy(allSenders, shareList, 'id'));
      }),
    );
  }

  getAllPersonalInboxes(): Observable<GroupModel[]> {
    return this.init$.pipe(
      mergeMap(() => {
        let contacts = _.values(this._contactsById);

        let result = <GroupModel[]>_.filter(contacts, (contact: ContactModel) => {
          return (
            contact instanceof GroupModel &&
            contact.groupType === GroupType.PERSONAL_INBOX &&
            !contact?._ex?.isNotAccessible
          );
        });

        return of(result);
      }),
    );
  }

  getAllSharedInboxes(): Observable<GroupModel[]> {
    return this.init$.pipe(
      mergeMap(() => {
        let contacts = _.values(this._contactsById);

        let result = <GroupModel[]>_.filter(contacts, (contact: ContactModel) => {
          return (
            contact instanceof GroupModel &&
            contact.groupType === GroupType.SHARED_INBOX &&
            !contact?._ex?.isNotAccessible
          );
        });

        return of(result);
      }),
    );
  }

  getAllPersonalAndSharedInboxes(): Observable<GroupModel[]> {
    return this.init$.pipe(
      mergeMap(() => {
        let contacts = _.values(this._contactsById);

        let result = <GroupModel[]>_.filter(contacts, (contact: ContactModel) => {
          return (
            contact instanceof GroupModel &&
            (contact.groupSubType === GroupSubType.SHARED_INBOX ||
              contact.groupSubType === GroupSubType.PERSONAL_INBOX) &&
            !contact?._ex?.isNotAccessible
          );
        });

        return of(result);
      }),
    );
  }

  ///////////
  // Helpers
  ///////////
  private handleMissingContacts(ids: string[]): Observable<ContactModel[]> {
    if (_.isEmpty(ids)) {
      return of([]);
    }

    return this._contactService.findOrFetchContactsById(this._userEmail, ids).pipe(
      tap((contacts: ContactModel[]) => {
        this.saveToStore(contacts);
      }),
    );
  }

  private handleMissingContact(id: string): Observable<ContactModel> {
    return this._contactService.findOrFetchContactById(this._userEmail, id).pipe(
      tap((contact: ContactModel) => {
        if (contact) {
          this.saveToStore([contact]);
        } else {
          Logger.customLog(
            'Did try to findOrFetch user: ' + id + ' for user: ' + this._userEmail + ' but got no result',
            LogLevel.ERROR,
            LogTag.INTERESTING_ERROR,
            true,
            'Did try to findOrFetch user with no success',
          );
        }
      }),
    );
  }

  private handleMissingId(email: string): Observable<ContactModel> {
    return this._contactService.findOrFetchContactByEmail(this._userEmail, email).pipe(
      tap((contact: ContactModel) => {
        if (contact) {
          this.saveToStore([contact]);
        } else {
          Logger.customLog(
            'Did try to findOrFetch user: ' + email + ' for user: ' + this._userEmail + ' but got no result',
            LogLevel.ERROR,
            LogTag.INTERESTING_ERROR,
            true,
            'Did try to findOrFetch user with no success',
          );
        }
      }),
    );
  }

  private getCurrentContactList(deepClone: boolean = true): ContactModel[] {
    let contacts = _.values(this._contactsById);

    // Bypass cloning ONLY when contacts will remain inside contact store
    // NEVER return original references outside this service.
    if (!deepClone) {
      return contacts;
    }

    return _.cloneDeep(contacts);
  }

  private emitChanges(contactChanges: ContactChanges) {
    // Emit for store
    this.contactsChanges$.next(contactChanges);

    // Trigger sidebar recalculation
    this.insertContactsIntoSidebarLimitedDictionaries(contactChanges.updated);

    // Broadcast change
    let data = new ContactStoreChange();
    data.forUserEmail = this._userEmail;
    SharedSubjects._contactStoreChange$.next(data);
  }

  public saveToStore(contacts: ContactModel[]): ContactChanges {
    let contactChanges: ContactChanges = new ContactChanges();

    _.forEach(contacts, (contact: ContactModel) => {
      // Make sure contact is not undefined. We don't want to
      // store undefined values
      if (!_.isUndefined(contact)) {
        this.updateContactDictionaries(contact);

        contactChanges.updated.push(contact);
      }
    });

    return contactChanges;
  }

  private updateContactDictionaries(contact: ContactModel) {
    // To allow local contacts e.g. send email to new contact while offline,
    // we want to save contacts to dictionaries by id if synced and by email if not
    let isContactSynced = contact.id !== undefined;
    let key = isContactSynced ? contact.id : contact.email;

    // Clean up potential unsynced contacts when we get the real contact
    if (isContactSynced) {
      delete this._contactsById[contact.email];
      delete this._contactIdsByEmail[contact.email];
      delete this._accessibleGroupIds[contact.email];
      delete this._groupIds[contact.email];
    }

    // Index by id (main)
    this._contactsById[key] = contact;

    // Email index
    this._contactIdsByEmail[contact.email] = key;

    // Group indices
    if (contact.$type === GroupModel.type) {
      this._groupIds[key] = 1;

      !contact._ex.isNotAccessible
        ? (this._accessibleGroupIds[key] = 1) // Add to index
        : delete this._accessibleGroupIds[key]; // Remove from index
    }
  }

  private filterForAccessibleContacts(contacts: ContactModel[]): Observable<ContactModel[]> {
    // Filter out non accessible contacts
    return from(contacts).pipe(
      filter((contact: ContactModel) => {
        return contact.isAccessible();
      }),
      toArray(),
      defaultIfEmpty([]),
    );
  }

  private _getAccessibleContacts(deepClone: boolean = true): Observable<ContactModel[]> {
    return this.init$.pipe(
      map(() => {
        return this.getCurrentContactList(deepClone);
      }),
      mergeMap((contacts: ContactModel[]) => {
        return this.filterForAccessibleContacts(contacts);
      }),
    );
  }

  /////////////////
  // Subscriptions
  /////////////////
  private subscribeToRemoveEvents() {
    this.syncEventsSub?.unsubscribe();
    this.syncEventsSub = PublisherService.getModelsForUserAndModelType(
      this._userEmail,
      [UserModel, GroupModel],
      PublishEventType.Remove,
    )
      .pipe(
        mergeMap((models: BaseModel[]) => {
          return this.processRemoveEvents(models as ContactModel[]);
        }),
        tap((contactChanges: ContactChanges) => {
          this.emitChanges(contactChanges);
        }),
      )
      .subscribe();
  }

  private processRemoveEvents(models: ContactModel[]): Observable<ContactChanges> {
    let contactChanges: ContactChanges = new ContactChanges();

    return from(models).pipe(
      tap((model: ContactModel) => {
        // Remove from indexes but never from main dictionary
        delete this._accessibleGroupIds[model.id];

        // Add contact to dictionary. Contact must have isDeleted value set
        if (model instanceof UserModel || model instanceof GroupModel) {
          if (!model._ex.isNotAccessible) {
            Logger.customLog(
              'Got remove event for contact id: ' + model.id + ' with isDeleted set to false.',
              LogLevel.WARN,
            );
            model._ex.isNotAccessible = true;
          }

          this._contactsById[model.id] = model;

          contactChanges.updated.push(model);
        }
      }),
      map(() => {
        return contactChanges;
      }),
    );
  }

  private subscribeToContactFeed() {
    this.contactFeedSub?.unsubscribe();
    this.contactFeedSub = this._contactService
      .getAllContacts(this._userEmail)
      .pipe(
        // Save initial batch
        tap((contacts: ContactModel[]) => {
          // Add to store
          let contactChanges = this.saveToStore(contacts);

          // Emit initial batch
          this.emitChanges(contactChanges);

          // We got initial batch and hav registered observer
          this._initialized = true;
        }),
        /**
         * Process published events
         */
        mergeMap(() => {
          return PublisherService.getModelsForUserAndModelType(this._userEmail, [UserModel, GroupModel]);
        }),
        tap((contacts: BaseModel[]) => {
          // Add to store
          let contactChanges = this.saveToStore(contacts as ContactModel[]);

          // Emit changes
          this.emitChanges(contactChanges);
        }),
      )
      .subscribe();
  }

  // [!] ALWAYS CLONE [!]
  // This way, contacts in this store can't be mutated from outside
  private getCloneOfContact(id: string): ContactModel {
    return _.cloneDeep(this._contactsById[id]);
  }

  //////////////////
  // SIDEBAR LOGIC
  //////////////////
  private limitedDictionariesToSidebarContacts(): SidebarContacts {
    let sidebarContacts = new SidebarContacts();

    let topFavouritesKeys = this.getSortedKeys(this._favourites_limited, true);
    sidebarContacts.favoriteChannels = this.contactIdsToContacts(topFavouritesKeys);

    let topPeoplesKeys = this.getSortedKeys(this._peoples_limited);
    sidebarContacts.peopleChannels = this.contactIdsToContacts(topPeoplesKeys);

    let topTeamsKeys = this.getSortedKeys(this._teams_limited);
    sidebarContacts.teamChannels = this.contactIdsToContacts(topTeamsKeys);

    let topSharedInboxKeys = this.getSortedKeys(this._sharedInbox_limited);
    sidebarContacts.sharedInboxChannels = this.contactIdsToContacts(topSharedInboxKeys);

    let topPersonalInboxKeys = this.getSortedKeys(this._personalInbox_limited);
    sidebarContacts.personalInboxChannels = this.contactIdsToContacts(topPersonalInboxKeys);

    return sidebarContacts;
  }

  private contactIdsToContacts(contactIds: string[]): ContactModel[] {
    return _.map(contactIds, contactId => this.getCloneOfContact(contactId));
  }

  private getSortedKeys(dict: _.Dictionary<any>, sortByNameOnly: boolean = false): string[] {
    let sortable = [];
    for (let contactId in dict) {
      sortable.push([contactId, dict[contactId]]);
    }

    sortable.sort((pairA, pairB) => this.compare(pairB[1], pairA[1], sortByNameOnly));

    return _.map(sortable, pair => pair[0]);
  }

  private insertContactsIntoSidebarLimitedDictionaries(contacts: ContactModel[]) {
    let changes: boolean = false;

    for (let i = 0; i < contacts.length; i++) {
      changes = this.insertContactIntoSidebarLimitedDictionaries(contacts[i]) || changes;
    }

    if (changes) {
      let triggerData = new ContactSidebarChange();
      triggerData.forUserEmail = this._userEmail;
      SharedSubjects._contactSidebarChange$.next(triggerData);
    }
  }

  private insertContactIntoSidebarLimitedDictionaries(contact: ContactModel): boolean {
    let section: SidebarSection;
    let selectedDict;
    let thresholdContactId;
    let limit: number = this.topSidebarCountPerSectionDefault;

    // Don't include deleted and inaccessible contacts
    if (!contact.isAccessible() || !contact.isRegistered()) {
      return this.removeFromOtherSections(contact.id, section);
    }

    // Select section that contact belongs to and corresponding threshold
    if (contact._ex && contact._ex.favorite) {
      section = SidebarSection.FAVORITES;
      selectedDict = this._favourites_limited;
      thresholdContactId = this._favourites_threshold_contactId;
      limit = this.topSidebarCountPerSectionExtended;
    } else if (contact instanceof UserModel && contact.hasTagType(TagType.CHATEE)) {
      if (contact.email === this._userEmail) {
        return false;
      }

      section = SidebarSection.PEOPLES;
      selectedDict = this._peoples_limited;
      thresholdContactId = this._peoples_threshold_contactId;
    } else if (contact instanceof GroupModel && contact.groupType === GroupType.SHARED_INBOX) {
      section = SidebarSection.SHARED_INBOXES;
      selectedDict = this._sharedInbox_limited;
      thresholdContactId = this._sharedInbox_threshold_contactId;
      limit = this.topSidebarCountPerSectionExtended;
    } else if (contact instanceof GroupModel && contact.groupType === GroupType.PERSONAL_INBOX) {
      section = SidebarSection.PERSONAL_INBOXES;
      selectedDict = this._personalInbox_limited;
      thresholdContactId = this._personalInbox_threshold_contactId;
      limit = this.topSidebarCountPerSectionExtended;
    } else if (contact instanceof GroupModel && contact.groupType !== GroupType.SHARED_INBOX) {
      section = SidebarSection.TEAMS;
      selectedDict = this._teams_limited;
      thresholdContactId = this._teams_threshold_contactId;
      limit = this.topSidebarCountPerSectionExtended;
    }

    let removed = this.removeFromOtherSections(contact.id, section);

    if (!selectedDict) {
      return removed;
    }

    let key = contact.id;
    let value = [contact.weight, contact.name];

    // If contact already exists and have same wight/name -> return false
    if (key in selectedDict && selectedDict[key][0] === value[0] && selectedDict[key][1] === value[1]) {
      return false;
    }

    // If dictionary below limit: freely insert
    if (Object.keys(selectedDict).length < limit) {
      selectedDict[key] = value;

      // Save min contactId
      if (!thresholdContactId || this.compare(value, selectedDict[thresholdContactId]) === -1) {
        this.setThresholdContactId(key, section);
      }

      return true;
    }

    // Dictionary full: replace if higher value
    if (this.compare(value, selectedDict[thresholdContactId]) === 1) {
      delete selectedDict[thresholdContactId];
      selectedDict[key] = value;

      let minValueKey = this.findMinValueKey(selectedDict);
      this.setThresholdContactId(minValueKey, section);

      return true;
    }

    return removed;
  }

  // Compare two arrays. Earlier values have are more important
  //  o -1: a < b
  //  o  0: a = b
  //  o  1: a > b
  private compare(a: any[], b: any[], sortByNameOnly: boolean = false) {
    // Handle input cases
    if (!a && b!) {
      throw new Error('Both values are undefined. Something must be wrong.');
    }
    if (!a) {
      return -1;
    }
    if (!b) {
      return 1;
    }
    if (a.length !== b.length) {
      throw new Error('Length of value arrays do not match.');
    }

    for (let i = 0; i < a.length; i++) {
      // Skip first value (weight) if sorting
      // only by name is required
      if (sortByNameOnly && i === 0) {
        continue;
      }

      let valueA = a[i];
      let valueB = b[i];

      // For weight: bigger is better
      // For name: 'smaller' is better
      if (i === 0) {
        valueA *= -1;
        valueB *= -1;
      } else if (i === 1) {
        // Because A, B, C, ..., a, b, c, ...
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA > valueB) {
        return -1;
      }

      if (valueA < valueB) {
        return 1;
      }
    }

    return 0;
  }

  private findMinValueKey(dict: _.Dictionary<any>): string {
    return Object.keys(dict).reduce((keyA, keyB) => (this.compare(dict[keyA], dict[keyB]) === -1 ? keyA : keyB));
  }

  private setThresholdContactId(value: string, section: SidebarSection) {
    switch (section) {
      case SidebarSection.FAVORITES:
        this._favourites_threshold_contactId = value;
        break;
      case SidebarSection.PEOPLES:
        this._peoples_threshold_contactId = value;
        break;
      case SidebarSection.TEAMS:
        this._teams_threshold_contactId = value;
        break;
      case SidebarSection.SHARED_INBOXES:
        this._sharedInbox_threshold_contactId = value;
        break;
      case SidebarSection.PERSONAL_INBOXES:
        this._personalInbox_threshold_contactId = value;
        break;
      default:
        throw new Error('Unsupported sidebar section. Will not set threshold contact id');
    }
  }

  private removeFromOtherSections(contactId: string, section: SidebarSection): boolean {
    let removed: boolean = false;

    if (section !== SidebarSection.FAVORITES) {
      removed = !_.isUndefined(this._favourites_limited[contactId]) || removed;
      delete this._favourites_limited[contactId];
    }
    if (section !== SidebarSection.PEOPLES) {
      removed = !_.isUndefined(this._peoples_limited[contactId]) || removed;
      delete this._peoples_limited[contactId];
    }
    if (section !== SidebarSection.TEAMS) {
      removed = !_.isUndefined(this._teams_limited[contactId]) || removed;
      delete this._teams_limited[contactId];
    }
    if (section !== SidebarSection.SHARED_INBOXES) {
      removed = !_.isUndefined(this._sharedInbox_limited[contactId]) || removed;
      delete this._sharedInbox_limited[contactId];
    }
    if (section !== SidebarSection.PERSONAL_INBOXES) {
      removed = !_.isUndefined(this._personalInbox_limited[contactId]) || removed;
      delete this._personalInbox_limited[contactId];
    }

    return removed;
  }

  /////////////////////
  // AUTOSUGGEST LOGIC
  /////////////////////
  getFilteredContacts(contactFilter: ContactFilter, watch: StopWatch): Observable<ContactModel[]> {
    let sessionSubject: Subject<ContactModel[]> = new Subject();
    let sessionContactList: ContactModel[] = [];

    return this.findContacts(contactFilter, watch).pipe(
      /**
       * Fetch
       */
      mergeMap((localContacts: ContactModel[]) => {
        return concat(of(localContacts), this.fetchContactsMoreDebounce(contactFilter, localContacts, sessionSubject));
      }),
      filter((localOrApiContacts: ContactModel[]) => !_.isEmpty(localOrApiContacts)),
      /**
       * Sort and limit
       */
      map((localOrApiContacts: ContactModel[]) => {
        // Sort
        watch.log('filterTopContacts: sort');

        sessionContactList = [...sessionContactList, ...this.sortContacts(localOrApiContacts, contactFilter)];

        sessionContactList = _.uniqBy(sessionContactList, 'id');

        // Limit
        if (contactFilter.size) {
          watch.log('filterTopContacts: limit');
          sessionContactList = sessionContactList.splice(0, contactFilter.size);
        }

        return sessionContactList;
      }),
      map((contacts: ContactModel[]) => {
        watch.log('done: ' + contacts.length);

        // [!] Make sure you never return reference to models in store [!]
        return _.cloneDeep(contacts);
      }),
      defaultIfEmpty([]),
    );
  }

  private findContacts(contactFilter: ContactFilter, watch: StopWatch): Observable<ContactModel[]> {
    let contactObservable: Observable<ContactModel[]>;

    if (!_.isEmpty(contactFilter.availableContactsIds)) {
      // Get selected contacts
      contactObservable = this.getContactsByIds(contactFilter.availableContactsIds).pipe(
        map((contacts: ContactModel[]) => _.uniqBy(contacts, 'id')),
      );
    } else {
      // Get all contacts
      contactObservable = this._getAccessibleContacts(false);
    }

    watch.log('getting contacts');
    return contactObservable.pipe(
      /**
       * Filter contacts
       */
      map((contacts: ContactModel[]) => {
        if (contactFilter.filterMethod === FilterByMethod.SHOW_TOP) {
          watch.log('filterTopContacts');
          return this.filterTopContacts(contacts, contactFilter, watch);
        }

        if (contactFilter.filterMethod === FilterByMethod.QUERY) {
          watch.log('filterByQuery');
          return this.filterByQuery(contacts, contactFilter, watch);
        }

        watch.log('return not filtered');
        return contacts;
      }),
    );
  }

  fetchContactsMoreDebounce(
    contactFilter: ContactFilter,
    currentLocalResults: ContactModel[],
    sessionSubject: Subject<ContactModel[]>,
  ): Observable<ContactModel[]> {
    if (!this.fetchContactDebounceFunc) {
      this.fetchContactDebounceFunc = _.debounce(this.fetchContacts, 500);
    }

    this.fetchContactDebounceFunc(contactFilter, currentLocalResults, sessionSubject);
    return sessionSubject.asObservable();
  }

  private fetchContacts(
    contactFilter: ContactFilter,
    currentLocalResults: ContactModel[],
    sessionSubject: Subject<ContactModel[]>,
  ) {
    this.fetchContactDebounceSub?.unsubscribe();
    this.fetchContactDebounceSub = this._contactService
      .fetchForQuery(this._userEmail, contactFilter, currentLocalResults)
      .pipe(
        tap((searchResult: ContactModel[]) => {
          sessionSubject.next(searchResult);
          sessionSubject.complete();
        }),
      )
      .subscribe();
  }

  private filterByQuery(contacts: ContactModel[], contactFilter: ContactFilter, watch: StopWatch): ContactModel[] {
    let query = StringUtils.toLocale(contactFilter.searchQuery);
    let queryTokens = query.match(/[a-zA-Z]+|[0-9]+/g);

    if (_.isEmpty(queryTokens)) {
      return [];
    }

    watch.log('filterByQuery: filter');
    let filteredContacts = _.filter(contacts, item => {
      // Ignore/exclude already added contacts - this.filter[]
      let filtered = contactFilter.filter.filter((_filter: any) => _filter.id === item.id);

      if (filtered.length) {
        return false;
      }

      if (contactFilter.contactTypes && !contactFilter.contactTypes.includes(item.$type as ContactType)) {
        return false;
      }

      if (item instanceof GroupModel && contactFilter.groupSubTypes) {
        if (!contactFilter.groupSubTypes.includes(item.groupSubType)) {
          return false;
        }
      }

      if (contactFilter.ignoreGroups && item.$type === GroupModel.type) {
        return false;
      }
      if (!item.name || (!item.email && item.$type !== GroupModel.type)) {
        return false;
      }
      if (contactFilter.excludeMe && item.id === contactFilter.currentUserId) {
        return false;
      }
      if (contactFilter.ignoreNotRegistered && !item.isRegistered()) {
        return false;
      }

      // resolve name & email
      let name = item._ex.localName.toUpperCase();
      let email = item.email ? item.email.toUpperCase() : '';

      // find match
      let emailTokens: RegExpMatchArray = email.match(/[a-zA-Z]+|[0-9]+/g);
      let nameTokens: RegExpMatchArray = name.match(/[a-zA-Z]+|[0-9]+/g);
      let matchingTokens = queryTokens.filter(token => {
        token = token.toUpperCase();

        if (_.some(emailTokens, emailToken => emailToken.startsWith(token))) {
          return true;
        }
        if (_.some(nameTokens, nameToken => nameToken.startsWith(token))) {
          return true;
        }

        return false;
      });

      return matchingTokens && queryTokens.length === matchingTokens.length;
    });

    watch.log('filterByQuery: done');
    return filteredContacts;
  }

  private filterTopContacts(contacts: ContactModel[], contactFilter: ContactFilter, watch: StopWatch): ContactModel[] {
    if (_.isEmpty(contacts)) {
      return contacts;
    }

    // Filter
    watch.log('filterTopContacts: filter');
    let filteredContacts = contacts
      .filter((contact: ContactModel) => {
        // Filter groups
        if (contactFilter.ignoreGroups && contact instanceof GroupModel) {
          return false;
        }

        if (contactFilter.contactTypes && !contactFilter.contactTypes.includes(contact.$type as ContactType)) {
          return false;
        }

        if (contact instanceof GroupModel && contactFilter.groupSubTypes) {
          if (!contactFilter.groupSubTypes.includes(contact.groupSubType)) {
            return false;
          }
        }

        // Filter out contact without name/email/id
        if (!contact.name || (contact instanceof UserModel && !contact.email) || !contact.id) {
          return false;
        }

        // Exclude current user
        if (contactFilter.excludeMe && contact.id === contactFilter.currentUserId) {
          return false;
        }

        // Exclude not registered
        if (contactFilter.ignoreNotRegistered && !contact.isRegistered()) {
          return false;
        }

        // Exclude contacts in excludeContactIds
        if (contactFilter.excludeContactIds && contactFilter.excludeContactIds.includes(contact.id)) {
          return false;
        }

        // Filter by assign suggestions
        if (
          contactFilter.assignSuggestionIds &&
          !contactFilter.assignSuggestionIds.includes(contact.id) &&
          contact.id !== contactFilter.currentAssigneeId
        ) {
          return false;
        }

        // We want to skip checks below for team members and self if applicable
        if (contact.id === contactFilter.currentAssigneeId) {
          return true;
        }
        if (!contactFilter.excludeMe && contact.id === contactFilter.currentUserId) {
          return true;
        }
        if (contactFilter.assignSuggestionIds && contactFilter.assignSuggestionIds.includes(contact.id)) {
          return true;
        }
        if (
          (contactFilter.isOnLoopInPopupMentions ||
            contactFilter.isAssigneesDropdown ||
            contactFilter.isOnLoopInPopup) &&
          contact instanceof UserModel &&
          (contact.weight === 0 || contact.onlineStatus === UserStatus.NOT_REGISTERED)
        ) {
          return false;
        }
        return true;
      })
      .filter((contact: ContactModel) => {
        return !_.some(contactFilter.filter, filtered => filtered.id === contact.id);
      });

    watch.log('filterTopContacts: done');
    return filteredContacts;
  }

  private sortContacts(contacts: ContactModel[] = [], contactFilter: ContactFilter): ContactModel[] {
    return contacts.sort((a, b) => {
      ///////////////////////
      // Special sort cases

      // Put self on first place
      if (contactFilter.selfOnTop && a.id === contactFilter.currentUserId) {
        return -1;
      }

      if (contactFilter.selfOnTop && b.id === contactFilter.currentUserId) {
        return 1;
      }

      // Put given item on first place
      if (contactFilter.firstItemId && a.id === contactFilter.firstItemId) {
        return -1;
      }

      if (contactFilter.firstItemId && b.id === contactFilter.firstItemId) {
        return 1;
      }

      // Put current assignee on top
      if (contactFilter.currentAssigneeId && a.id === contactFilter.currentAssigneeId) {
        return -1;
      }

      if (contactFilter.currentAssigneeId && b.id === contactFilter.currentAssigneeId) {
        return 1;
      }

      // Bump assign suggestions on top
      if (
        contactFilter.assignSuggestionIds &&
        contactFilter.assignSuggestionIds.includes(a.id) &&
        !contactFilter.assignSuggestionIds.includes(b.id)
      ) {
        return -1;
      }

      if (
        contactFilter.assignSuggestionIds &&
        contactFilter.assignSuggestionIds.includes(b.id) &&
        !contactFilter.assignSuggestionIds.includes(a.id)
      ) {
        return 1;
      }

      ////////////////////
      // Default sorting
      ////////////////////
      let aWeight = a.getWeight();
      let bWeight = b.getWeight();
      let aName = a.name;
      let bName = b.name;
      let aRegistered = a.isRegistered();
      let bRegistered = b.isRegistered();

      // By status (registered/notRegistered)
      if (!contactFilter.ignoreOnlineStatus && aRegistered && !bRegistered) {
        return -1;
      }
      if (!contactFilter.ignoreOnlineStatus && !aRegistered && bRegistered) {
        return 1;
      }

      // By weight
      if (aWeight < bWeight) {
        return 1;
      }
      if (aWeight > bWeight) {
        return -1;
      }

      // By name
      if (aName < bName) {
        return -1;
      } else {
        return 1;
      }
    });
  }
}

enum SidebarSection {
  FAVORITES = 'FAVORITES',
  PEOPLES = 'PEOPLES',
  TEAMS = 'TEAMS',
  SHARED_INBOXES = 'SHARED_INBOXES',
  PERSONAL_INBOXES = 'PERSONAL_INBOXES',
}

export class Counters {
  allContactsCount: number;
  mutedContactsCount: number;
  groupContactsCount: number;
}
