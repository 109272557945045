import { Directive } from '@angular/core';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { ContactModel, UserModel } from 'dta/shared/models-api-loop/contact/contact.model';
import { Observable, of, throwError } from 'rxjs';
import { ContactApiService, GroupApiService, UserApiService } from '@shared/api/api-loop/services';
import { debounceTime, filter, mergeMap, startWith, tap } from 'rxjs/operators';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { ContactExDecorateService } from '@shared/decorators/extra-data-decorators/contact-ex-decorator/contact-ex-decorate.service';
import { SharedAvatarService } from '@shared/services/shared-avatar/shared-avatar.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { ContactDaoService } from '@shared/database/dao/contact/contact-dao.service';
import { ContactFilter } from '@dta/shared/models/contact.model';
import { ContactViewDecorateService } from '@shared/decorators/view-data-decorators/contact-view-decorator/contact-view-decorate.service';
import { CardUnreadService } from '@shared/services/data/card-unread/card-unread.service';
import { ContactUnreadCount } from '@shared/services/data/card-unread/card-unread.service.interface';
import { AvatarService } from '@shared/services/data/avatar/avatar.service';
import { UserAvailabilityStatusService } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { SmartGroupApiService } from '@shared/modules/contacts/shell/contacts-api-cache/smart-group-api.service';

@Directive()
export class ContactServiceWeb extends ContactService {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _contactApiService: ContactApiService,
    protected _contactStoreFactory: ContactStoreFactory,
    protected _contactExDecorateService: ContactExDecorateService,
    protected _groupApiService: GroupApiService,
    protected _userApiService: UserApiService,
    protected _sharedAvatarService: SharedAvatarService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _contactDaoService: ContactDaoService,
    protected _avatarService: AvatarService,
    protected _userAvailabilityStatusService: UserAvailabilityStatusService,
    protected _storageService: StorageService,
    private _contactViewDecorateService: ContactViewDecorateService,
    private _cardUnreadService: CardUnreadService,
    protected smartGroupApiService: SmartGroupApiService
  ) {
    super(
      _syncMiddleware,
      _contactApiService,
      _contactStoreFactory,
      _groupApiService,
      _contactExDecorateService,
      _userApiService,
      _sharedAvatarService,
      _sharedUserManagerService,
      _contactDaoService,
      _avatarService,
      _userAvailabilityStatusService,
      _storageService,
      smartGroupApiService
    );
  }

  get constructorName(): string {
    return 'ContactServiceWeb';
  }

  getActiveUserCount(forUserEmail: string, contact: ContactModel): Observable<Number> {
    return of(0);
  }

  subscribeToContactByIdOrEmail(forUserEmail: string, idOrEmail: string): Observable<ContactModel> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }
    if (!idOrEmail) {
      throw new Error('id or email cannot be nil.');
    }

    if (idOrEmail.includes('@')) {
      return this.subscribeToContactByEmail(forUserEmail, idOrEmail);
    } else {
      return this.subscribeToContactById(forUserEmail, idOrEmail);
    }
  }

  private subscribeForContactStoreChanges(forUserEmail: string): Observable<any> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }

    return SharedSubjects._contactStoreChange$.forUserEmail(forUserEmail).pipe(debounceTime(100), startWith(null));
  }

  // ONLY TMP
  subscribeToContactById(forUserEmail: string, id: string) {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }
    if (!id) {
      throw new Error('id cannot be nil.');
    }

    return this.subscribeForContactStoreChanges(forUserEmail).pipe(
      mergeMap(() => {
        // Get contact by id
        return this.getContactById(forUserEmail, id);
      })
    );
  }

  subscribeToContactByEmail(forUserEmail: string, email: string) {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }
    if (!email) {
      throw new Error('email cannot be nil.');
    }

    return this.subscribeForContactStoreChanges(forUserEmail).pipe(
      mergeMap(() => {
        // Get contact by email
        return this.getContactByEmail(forUserEmail, email);
      })
    );
  }

  subscribeToContact(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    if (!forUserEmail) {
      return throwError('forUserEmail cannot be nil.');
    }
    if (!contact) {
      return throwError('contact cannot be nil.');
    }

    if (contact.id) {
      return this.subscribeToContactById(forUserEmail, contact.id);
    } else {
      return this.subscribeToContactByEmail(forUserEmail, contact.email);
    }
  }

  setUnreadCountForContact(forUserEmail: string, contact: ContactModel): Observable<ContactModel> {
    return this._cardUnreadService
      .getContactUnreadCount(forUserEmail, contact)
      .pipe(
        mergeMap((unreadDictionary: ContactUnreadCount) =>
          this.decorateContactWithUnreadCount(forUserEmail, contact, unreadDictionary)
        )
      );
  }

  private decorateContactWithUnreadCount(
    forUserEmail: string,
    contact: ContactModel,
    unreadDictionary: ContactUnreadCount
  ): Observable<ContactModel> {
    return this._contactViewDecorateService
      .decorateListViewData(forUserEmail, [contact])
      .pipe(
        mergeMap((decoratedContacts: ContactModel[]) =>
          this._contactViewDecorateService.decorateWithUnreadCount(decoratedContacts[0], unreadDictionary)
        )
      );
  }

  protected updateUserName(forUserEmail: string, user: UserModel): Observable<any> {
    return of(undefined);
  }

  ////////////////
  // STORE PROXY
  ////////////////
  getContactsForChannelTags(forUserEmail: string): Observable<ContactModel[]> {
    return super.getContactsForChannelTags(forUserEmail).pipe(
      /**
       * Decorate list view data. Is done as part of IPC on DTA
       */
      mergeMap((contacts: ContactModel[]) =>
        this._contactViewDecorateService.decorateListViewData(forUserEmail, contacts)
      )
    );
  }

  ///////////////////////
  // AUTOSUGGEST METHODS
  ///////////////////////
  getFilteredContacts(forUserEmail: string, contactFilter: ContactFilter): Observable<ContactModel[]> {
    return super.getFilteredContacts(forUserEmail, contactFilter).pipe(
      /**
       * Decorate list view data. Is done as part of IPC on DTA
       */
      filter((contacts: ContactModel[]) => !!contacts),
      mergeMap((contacts: ContactModel[]) => {
        return this._contactViewDecorateService.decorateListViewData(forUserEmail, contacts);
      })
    );
  }

  removeCollection(forUserEmail: string): Observable<any> {
    return this._contactDaoService.removeCollection(forUserEmail).pipe(
      tap(() => {
        this._contactStoreFactory.removeForUser(forUserEmail);
      })
    );
  }
}
