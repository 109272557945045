import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AssignDropdownComponent } from '@shared/modules/conversations/components/conversation-header/dropdowns/assign-dropdown/assign-dropdown.component';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';
import { ContactsPipesModule } from '@shared/modules/contacts/components/pipes/contacts-pipes.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';
import { LoaderModule } from '@dta/ui/components/common/loader/loader.module';
import { IsAnyAssignedPipe } from './internal/is-any-assigned.pipe';

@NgModule({
  declarations: [AssignDropdownComponent, IsAnyAssignedPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TextControlModule,
    ContactModule,
    TooltipModule,
    ContactsPipesModule,
    LoaderModule,
    IconModule
  ],
  exports: [AssignDropdownComponent, IsAnyAssignedPipe]
})
export class AssignDropdownModule {}
