import { File as IFile } from '@shared/api/api-loop/models/file';
import { getWrapper } from '@shared/modules/comments/common/helpers/get-wrapper';
import { hexToString } from '@shared/utils/convert-hex-to-string';

export function replaceContentLinksWithTempFiles(html: string, files: IFile[]): string {
  const wrapper = getWrapper(html);
  const imgList = wrapper.querySelectorAll('img');

  const fileByHash = files.reduce((acc, file) => {
    acc[hexToString(file.hash)] = file;
    return acc;
  }, {});

  const hashes = Object.keys(fileByHash);

  imgList.forEach(img => {
    if (img?.src?.startsWith('https') || img?.getAttribute('data-src')?.startsWith('http')) {
      const fileHash = hashes.find(hash => img?.src?.includes(hash) || img?.getAttribute('data-src')?.includes(hash));

      if (fileHash) {
        img.setAttribute('src', `cid:${fileByHash[fileHash].id}`);
      }
    }
  });

  const modifiedHtml = wrapper.innerHTML;
  wrapper.remove();
  return modifiedHtml;
}
