import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { BasePushSynchronizationService } from '../base-push-synchronization/base-push-synchronization.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CardAppointmentModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CardApiService } from '@shared/api/api-loop/services';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { CardService } from '@shared/services/data/card/card.service';
import { Logger } from '@shared/services/logger/logger';
import { AppointmentResponse } from '@shared/api/api-loop/models';

@Injectable()
export class AgendaPushSynchronizationService extends BasePushSynchronizationService<CardAppointmentModel> {
  constructor(
    protected _api: ApiService,
    protected _cardService: CardService,
  ) {
    super();
  }

  get constructorName(): string {
    return 'AgendaPushSynchronizationService';
  }

  protected synchronize(forUserEmail: string, appointment: CardAppointmentModel): Observable<CardAppointmentModel> {
    let params: CardApiService.Card_UpdateAppointmentResponseParams = {
      id: appointment.id,
      appointmentResponse: appointment.response,
    };

    return this._api.CardApiService.Card_UpdateAppointmentResponse(params, forUserEmail).pipe(
      map((response: AppointmentResponse) => {
        appointment.response = response;
        return appointment;
      }),
      /**
       * Handle error codes
       */
      catchError(err => {
        return this.handleSyncError(forUserEmail, err, appointment);
      }),
    );
  }

  protected afterSynchronize(
    forUserEmail: string,
    appointment: CardAppointmentModel,
  ): Observable<CardAppointmentModel> {
    return of(appointment).pipe(
      tap(() => {
        PublisherService.publishEvent(forUserEmail, appointment);
      }),
    );
  }

  protected handleSyncError(
    forUserEmail: string,
    err: HttpErrorResponse,
    appointment: CardAppointmentModel,
  ): Observable<any> {
    if (err.status === 0) {
      // we lost connection and will retry
      return EMPTY;
    }

    // If we get 409 (conflict), we fetch appointment card, set correct revision and retry
    if (err.status === 409) {
      return this.retryAppointmentResponseWithCorrectRevision(forUserEmail, appointment);
    }

    return throwError(err);
  }

  // Fetch appointment card, set correct revision and retry
  private retryAppointmentResponseWithCorrectRevision(
    forUserEmail: string,
    appointment: CardAppointmentModel,
  ): Observable<any> {
    return of(undefined).pipe(
      /**
       * Fetch card for correct revision
       */
      mergeMap(() => {
        return this._cardService.fetchAndSaveCard(forUserEmail, appointment.id);
      }),
      /**
       * Update response with correct revision
       */
      mergeMap((updatedAppointment: CardAppointmentModel) => {
        // Update revision
        appointment.response.revision = updatedAppointment.response.revision;

        // Save. Will also publish to push sync queue
        return this._cardService.updatedAppointmentResponse(forUserEmail, appointment.id, appointment.response);
      }),
      /**
       * Retry
       */
      mergeMap(() => {
        return EMPTY;
      }),
    );
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    appointment: CardAppointmentModel,
  ): Observable<any> {
    Logger.error(
      err,
      `PushSync [${forUserEmail}]: Could not sync appointmentResponse, will not retry appointmentId: ${appointment._id}`,
    );
    return of(appointment);
  }
}
