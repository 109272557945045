import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@shared/api/api-loop/models';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

@Pipe({ name: 'userName' })
export class UserNamePipe implements PipeTransform {
  constructor(private _userManagerService: UserManagerService) {}

  transform(
    user: User,
    upperCase?: boolean,
    firstNameOnly?: boolean,
    yourself: boolean = false,
    you: boolean = false,
    isMeText: boolean = false
  ) {
    if (!user) {
      return '';
    }

    let currentUser = this._userManagerService.getCurrentUser();
    if (currentUser && user.id === currentUser.id) {
      let snippet: string = '';

      if (yourself) {
        snippet = 'yourself';
      } else if (you) {
        snippet = 'you';
      } else if (isMeText) {
        snippet = 'me';
      } else {
        snippet = firstNameOnly ? user.name.split(' ')[0].trim() : user.name;
      }

      return upperCase ? snippet[0].toUpperCase() + snippet.slice(1) : snippet;
    }

    if (firstNameOnly && user.$type === 'User' && user.name) {
      return user.name.split(' ')[0].trim();
    }
    return user.name.trim();
  }
}
