import { ChannelType } from '@shared/modules/comments/common/constants/channel-type';
import { EnumObjectValue } from '@shared/utils/common/types';

export const CHANNEL_ICON_MAPPER: Record<EnumObjectValue<typeof ChannelType>, string> = {
  [ChannelType.whatsApp]: 'whatsapp',
  [ChannelType.sms]: 'comment-sms',
  [ChannelType.viber]: 'viber',
  [ChannelType.messenger]: 'message-bot',
  [ChannelType.loopSpam]: 'octagon-exclamation'
};

export const CHANNEL_ICON_COLOR_MAPPER: Record<EnumObjectValue<typeof ChannelType>, string> = {
  [ChannelType.whatsApp]: '#25d366',
  [ChannelType.sms]: 'var(--strong-ui-element)',
  [ChannelType.viber]: '#7360f2',
  [ChannelType.messenger]: 'var(--strong-ui-element)',
  [ChannelType.loopSpam]: 'var(--strong-ui-element)'
};
