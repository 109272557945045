import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { TagLabelModel } from '@dta/shared/models-api-loop/tag.model';
import { SharedTagLabelModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { CONSTANTS } from '@shared/models/constants/constants';
import { ActionEnum } from '@shared/api/api-loop/models/action-enum';
import { SharedTagFolder } from '@shared/api/api-loop/models/shared-tag-folder';
import { ContextType } from '@shared/api/api-loop/models/context-type';
import { v1 } from 'uuid';
import { SharedTagLabel } from '@shared/api/api-loop/models/shared-tag-label';
import { TagLabel } from '@shared/api/api-loop/models/tag-label';
import { SharedTagClassification } from '@shared/api/api-loop/models/shared-tag-classification';

export interface ConversationChangeBaseModel extends ResourceBase {
  id: string;
  createdAt: string;
  labelChanges?: LabelChange[];
  conversationActions?: ConversationActionChange[];
}

interface ConversationActionModel {
  cardId: string;
  action: ActionEnum;
  folder?: SharedTagFolder;
  context?: ContextType;
  addTags?: (SharedTagLabel | TagLabel)[];
  removeTags?: (SharedTagLabel | TagLabel)[];
}

interface LabelChangeMode {
  cardId: string;
  action: 'add' | 'remove';
  labels: (SharedTagLabel | TagLabel)[];
}

export const REMOVE_CLASSIFICATION: 'remove_classification' = 'remove_classification' as const;

export class ConversationChangeModel extends BaseModel implements ConversationChangeBaseModel {
  static type: string = 'ConversationChange';
  readonly $type: string = ConversationChangeModel.type;

  constructor(model?: ConversationChangeBaseModel) {
    super(model);
  }

  id: string;
  cardId: string;
  createdAt: string;
  labelChanges?: LabelChange[];
  conversationActions?: ConversationActionChange[];
  classificationChange?: SharedTagClassification | typeof REMOVE_CLASSIFICATION;

  isValid(date: Date) {
    let diffSeconds = (date.getTime() - new Date(this.createdAt).getTime()) / 1000;
    return diffSeconds <= CONSTANTS.INVALIDATE_LOCAL_CHANGES_AFTER;
  }

  static createConversationActionModel({
    cardId,
    action,
    folder,
    context,
    addTags,
    removeTags
  }: ConversationActionModel) {
    if (action === ActionEnum.CHANGE_CLASSIFICATION) {
      return ConversationChangeModel.createClassificationChange({
        cardId: cardId,
        classificationTag: addTags?.[0]
      });
    }

    if (addTags?.length || removeTags?.length) {
      return ConversationChangeModel.createLabelChangeMode({
        cardId: cardId,
        action: addTags?.length ? 'add' : 'remove',
        labels: addTags ?? removeTags
      });
    }

    let change = new ConversationChangeModel();
    change.cardId = cardId;
    change.id = v1();
    change.createdAt = new Date().toISOString();
    change.conversationActions = [
      {
        action: action,
        context: context,
        folder: folder
      }
    ];
    return change;
  }

  static createClassificationChange({
    cardId,
    classificationTag
  }: {
    cardId: string;
    classificationTag: SharedTagClassification;
  }) {
    let conversationChange = new ConversationChangeModel();
    conversationChange.id = v1();
    conversationChange.cardId = cardId;
    conversationChange.createdAt = new Date().toISOString();
    conversationChange.classificationChange = classificationTag ? classificationTag : REMOVE_CLASSIFICATION;
    return conversationChange;
  }

  static createLabelChangeMode({ cardId, action, labels }: LabelChangeMode) {
    let conversationChange = new ConversationChangeModel();
    conversationChange.id = v1();
    conversationChange.cardId = cardId;
    conversationChange.createdAt = new Date().toISOString();

    conversationChange.labelChanges = [
      {
        action: action,
        tags: labels
      }
    ];
    return conversationChange;
  }
}

export interface LabelChange {
  action: 'add' | 'remove';
  tags: (SharedTagLabel | TagLabel)[];
}

export interface ConversationActionChange {
  action: ActionEnum;
  folder?: SharedTagFolder;
  context?: ContextType;
}
