import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClassificationNode } from '@shared/modules/shared-tag/common/interfaces/classification-node';
import { ClassificationTagType } from '@shared/api/api-loop/models/classification-tag-type';
import { TextControlComponent } from '@shared/modules/form-controls/text-control/text-control.component';
import { buildClassificationTree } from '@shared/modules/shared-tag/common/helpers/build-classification-tree';
import { filterClassificationTree } from '@shared/modules/shared-tag/common/helpers/has-classification-node-query';
import { SmartClassificationSharedTagApi } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/smart-classification-shared-tag-api';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { debounceTime, finalize, map, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, ReplaySubject, startWith } from 'rxjs';
import { SharedTagClassificationModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

@Component({
  selector: 'loop-classification-tree',
  templateUrl: './classification-tree.component.html',
  styleUrls: ['./classification-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassificationTreeComponent implements AfterViewInit, OnDestroy {
  private readonly formBuilder: UntypedFormBuilder = inject(UntypedFormBuilder);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly smartClassificationSharedTagApi: SmartClassificationSharedTagApi = inject(
    SmartClassificationSharedTagApi
  );
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  private readonly selectedClassificationTag$: ReplaySubject<SharedTagClassificationModel> = new ReplaySubject(1);
  @Input() set selectedClassificationTag(selectedClassificationTag: SharedTagClassificationModel) {
    this.selectedClassificationTag$.next(selectedClassificationTag);
  }
  @Input() classificationTagsListTpl?: TemplateRef<void>;
  @Input() classificationCategoryButtonTpl?: TemplateRef<{ $implicit: ClassificationNode }>;

  protected readonly ClassificationTagType_Classification: typeof ClassificationTagType.CLASSIFICATION =
    ClassificationTagType.CLASSIFICATION;
  protected readonly ClassificationTagType_Category: typeof ClassificationTagType.CATEGORY =
    ClassificationTagType.CATEGORY;

  @Output() nodeClick: EventEmitter<ClassificationNode> = new EventEmitter();

  @ViewChild(TextControlComponent) textControlElement?: TextControlComponent;

  protected isLoading: boolean = false;
  protected readonly form: UntypedFormGroup = this.formBuilder.group({
    query: []
  });

  protected readonly classificationNodeTags$: Observable<ClassificationNode[]> = combineLatest([
    this.selectedClassificationTag$.pipe(startWith(undefined)),
    this.form.controls['query'].valueChanges.pipe(debounceTime(200), startWith(''))
  ]).pipe(
    switchMap(([selectedClassificationTag, query]) => {
      this.isLoading = true;
      this.changeDetectorRef.detectChanges();

      return this.smartClassificationSharedTagApi.getAll$(this.userManagerService.getCurrentUserEmail()).pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
        map(classificationTags => classificationTags.filter(classificationTag => !classificationTag.deleted)),
        map(classificationTags => buildClassificationTree(classificationTags, selectedClassificationTag)),
        map(classificationTree => {
          if (!query) {
            return classificationTree;
          }

          return classificationTree
            .map(classificationTag => filterClassificationTree(classificationTag, query))
            .filter(Boolean);
        })
      );
    })
  );

  ngAfterViewInit(): void {
    this.textControlElement?.focus();
  }

  protected trackByFn(index: number, item: ClassificationNode): string {
    return item.classificationTag.id;
  }

  protected handleNodeClick(classificationNode: ClassificationNode): void {
    if (classificationNode.classificationTag.classificationType === ClassificationTagType.CATEGORY) {
      classificationNode.isNodeExpanded = !classificationNode.isNodeExpanded;
      this.changeDetectorRef.detectChanges();
    } else {
      this.nodeClick.next(classificationNode);
    }
  }

  ngOnDestroy(): void {
    this.selectedClassificationTag$.complete();
  }
}
