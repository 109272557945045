import * as _ from 'lodash';
import { Directive, OnDestroy } from '@angular/core';
import { SynchronizableModel } from '@shared/models/sync/synchronizable.model';
import {
  CommentChatModel,
  CommentDraftModel,
  CommentMailModel,
  CommentModel,
  CommentTemplateModel
} from '@dta/shared/models-api-loop/comment/comment.model';
import {
  CommentPushSynchronizationService,
  DraftPushError
} from './comment-push-synchronization/comment-push-synchronization.service';
import { concat, forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, filter, finalize, mergeMap, tap, toArray } from 'rxjs/operators';
import { SynchronizationStatusService, SynchronizationStatusType } from '../synchronization-status.service';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { SyncServiceStatus } from '@shared/models/sync/synchronization-service-status.model';
import { SignatureModel } from '@dta/shared/models-api-loop/signature.model';
import { TemplatePushSynchronizationService } from './template-push-synchronization/template-push-synchronization.service';
import { ListOfTagsModel } from '@dta/shared/models-api-loop/tag.model';
import { TagPushSynchronizationService } from './tag-push-synchronization/tag-push-synchronization.service';
import { SharedTagPushSynchronizationService } from './shared-tag-push-synchronization/shared-tag-push-synchronization.service';
import { AgendaPushSynchronizationService } from './agenda-push-synchronization/agenda-push-synchronization.service';
import {
  CardAppointmentModel,
  CardChatModel,
  CardMailModel,
  CardModel,
  CardSharedModel
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CardPushSynchronizationService } from './card-push-synchronization/card-push-synchronization.service';
import { PushSyncDaoService } from '@shared/database/dao/push-sync/push-sync-dao.service';
import { PushSyncModel } from '@dta/shared/models-api-loop/push-sync.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { AvailabilityStatusPushSynchronizationService } from '@shared/synchronization/push-synchronization/availability-status-push-synchronization/availability-status-push-synchronization.service';
import { AvailabilityStatusModel } from '@dta/shared/models-api-loop/availability-status.model';
import { SharedTagFolderModel, SharedTagLabelModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { SharedTagLabelPushSynchronizationService } from '@shared/synchronization/push-synchronization/shared-tag-label-push-synchronization/shared-tag-label-push-synchronization.service';
import { ConversationActionPushSynchronizationService } from '@shared/synchronization/push-synchronization/conversation-push-synchronization/conversation-action-push-synchronization.service';
import { ConversationActionModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-action.model';
import { FolderPushSynchronizationService } from '@shared/synchronization/push-synchronization/folder-push-synchronization/folder-push-synchronization.service';

const MAX_PUSH_SYNC_RETRY_COUNT = 18; // Retry for aprox. 3 days (exponential back-off)

@Directive()
export abstract class PushSynchronizationService implements OnDestroy {
  active: boolean = false;

  /////////////////
  // Subscriptions
  /////////////////
  protected pushSub: Subscription;

  constructor(
    protected _userEmail: string,
    protected _status: SynchronizationStatusService,
    protected _commentPushSynchronizationService: CommentPushSynchronizationService,
    protected _templatePushSynchronizationService: TemplatePushSynchronizationService,
    protected _tagPushSynchronizationService: TagPushSynchronizationService,
    protected _sharedTagPushSynchronizationService: SharedTagPushSynchronizationService,
    protected _agendaPushSynchronizationService: AgendaPushSynchronizationService,
    protected _cardPushSynchronizationService: CardPushSynchronizationService,
    protected _availabilityStatusPushSynchronizationService: AvailabilityStatusPushSynchronizationService,
    protected _sharedTagLabelPushSynchronizationService: SharedTagLabelPushSynchronizationService,
    protected _conversationActionPushSynchronizationService: ConversationActionPushSynchronizationService,
    protected _folderPushSynchronizationService: FolderPushSynchronizationService,
    protected _pushSyncDaoService: PushSyncDaoService,
    protected _notificationsService: NotificationsService
  ) {}

  get constructorName(): string {
    return 'PushSynchronizationService';
  }

  ngOnDestroy() {}

  abstract start();

  /**
   * Stop the push notification pipeline
   */
  stop() {
    this.active = false;
    this.pushSub?.unsubscribe();

    Logger.log(`[SYNC] - PushSync [${this._userEmail}]: stopped`);
  }

  enqueueSynchronization(data: SynchronizableModel | SynchronizableModel[]): Observable<any> {
    let models = PushSyncModel.createPushSyncModelList(_.castArray(data));

    return this.enqueueSynchronizationInternal(models);
  }

  private enqueueSynchronizationInternal(models: PushSyncModel[]): Observable<any> {
    return this.removeDuplicatesFromSyncQueue(models).pipe(
      mergeMap(() => {
        return this._pushSyncDaoService.saveAllToQueue(this._userEmail, models);
      })
    );
  }

  dequeueSynchronization(data: BaseModel | BaseModel[]): Observable<any> {
    return this.dequeueSynchronizationInternal(PushSyncModel.createPushSyncModelList(_.castArray(data)));
  }

  private dequeueSynchronizationInternal(data: PushSyncModel | PushSyncModel[]): Observable<any> {
    return this._pushSyncDaoService.removeAllFromQueue(this._userEmail, _.castArray(data));
  }

  getStatus(): SyncServiceStatus {
    return this.active ? SyncServiceStatus.ACTIVE : SyncServiceStatus.INACTIVE;
  }

  private removeDuplicatesFromSyncQueue(models: PushSyncModel[]): Observable<any> {
    // currently we only need to remove listOfTags and signatures
    let modelsByType = _.groupBy(models, model => model.data.$type);
    let listOfTags = modelsByType[ListOfTagsModel.type] || [];

    return forkJoin([this._pushSyncDaoService.removeListOfTags(this._userEmail, listOfTags)]);
  }

  protected synchronizeModels(models: PushSyncModel[] | PushSyncModel[][]): Observable<any> {
    return from(models).pipe(
      filter((model: PushSyncModel | PushSyncModel[]) => !_.isEmpty(model)),
      concatMap((model: PushSyncModel | PushSyncModel[]) => {
        return of(model).pipe(
          tap(() => this.notifyEnqueue(model)),
          mergeMap(() => this.synchronizeModel(model)),
          mergeMap(() => this.dequeueSynchronizationInternal(model)),
          finalize(() => this.notifyDequeue(model)),
          catchError(err => this.handleSyncFailed(model, err))
        );
      }),
      toArray(),
      defaultIfEmpty(models)
    );
  }

  private synchronizeModel(model: PushSyncModel | PushSyncModel[]): Observable<any> {
    let models = _.castArray(model);
    switch (_.first(models).data.$type) {
      case CommentMailModel.type:
      case CommentChatModel.type:
      case CommentDraftModel.type:
        return this.synchronizeComment(_.first(models).data as CommentModel);
      case CardSharedModel.type:
      case CardMailModel.type:
      case CardChatModel.type:
        return this.synchronizeCardUpdate(_.first(models).data as CardModel);
      case CommentTemplateModel.type:
        return this.synchronizeTemplate(_.first(models).data as CommentTemplateModel);
      case CardAppointmentModel.type:
        return this.synchronizeAgenda(_.first(models).data as CardAppointmentModel);
      case AvailabilityStatusModel.type:
        return this.synchronizeAvailabilityStatus(_.first(models).data as AvailabilityStatusModel);
      case SharedTagLabelModel.type:
        return this.synchronizeSharedTagLabel(_.first(models).data as SharedTagLabelModel);
      case ConversationActionModel.type:
        return this.synchronizeConversationAction(_.first(models).data as ConversationActionModel);
      case ListOfTagsModel.type:
        return this.synchronizeListOfTags(_.castArray(models.map(m => m.data)) as ListOfTagsModel[]);
      case SharedTagFolderModel.type:
        return this.synchronizeFolder(_.first(models).data as SharedTagFolderModel);
      default:
        break;
    }
  }

  /////////////////////////////////
  // SYNC METHODS IMPLEMENTATIONS
  /////////////////////////////////
  private synchronizeComment(comment: CommentModel) {
    return this._commentPushSynchronizationService.synchronizeModel(this._userEmail, comment);
  }

  private synchronizeTemplate(comment: CommentTemplateModel) {
    return this._templatePushSynchronizationService.synchronizeModel(this._userEmail, comment);
  }

  private synchronizeAgenda(appointment: CardAppointmentModel) {
    return this._agendaPushSynchronizationService.synchronizeModel(this._userEmail, appointment);
  }

  private synchronizeCardUpdate(card: CardModel) {
    return this._cardPushSynchronizationService.synchronizeModel(this._userEmail, card);
  }

  private synchronizeAvailabilityStatus(availabilityStatus: AvailabilityStatusModel) {
    return this._availabilityStatusPushSynchronizationService.synchronizeModel(this._userEmail, availabilityStatus);
  }

  private synchronizeFolder(folder: SharedTagFolderModel) {
    return this._folderPushSynchronizationService.synchronizeModel(this._userEmail, folder);
  }

  private synchronizeSharedTagLabel(sharedTagLabel: SharedTagLabelModel) {
    return this._sharedTagLabelPushSynchronizationService.synchronizeModel(this._userEmail, sharedTagLabel);
  }

  private synchronizeConversationAction(conversationAction: ConversationActionModel) {
    return this._conversationActionPushSynchronizationService.synchronizeModel(this._userEmail, conversationAction);
  }

  private synchronizeListOfTags(tags: ListOfTagsModel[]) {
    // We want to prevent synchronizing ListOfTags for which parents are not yet synced
    let validUpdates = _.filter(tags, listOfTags => {
      if (listOfTags.parent && listOfTags.parent.id) {
        return true;
      }

      Logger.warn(`PushSync [${this._userEmail}]: could not sync ListOfTags, parent is invalid listOfTags`);
      return false;
    });

    /* Post and update tags */
    let personalTags = _.filter(validUpdates, (tag: ListOfTagsModel) => !tag._ex || !tag._ex.areSharedTags);
    let updateTags =
      personalTags && personalTags.length > 0
        ? this._tagPushSynchronizationService.synchronizeModel(this._userEmail, personalTags)
        : of([]);

    /* Post and update shared tags */
    let sharedTags = _.filter(validUpdates, (tag: ListOfTagsModel) => tag._ex && tag._ex.areSharedTags);
    let updateSharedTags =
      sharedTags && sharedTags.length > 0
        ? this._sharedTagPushSynchronizationService.synchronizeModel(this._userEmail, sharedTags)
        : of([]);

    return concat(updateTags, updateSharedTags);
  }

  /////////////
  // HELPERS
  /////////////
  protected notifyEnqueue(model) {
    this._status.enqueue({
      id: model._id,
      entity: model,
      type: SynchronizationStatusType.PUSH
    });
  }

  protected notifyDequeue(model) {
    this._status.dequeue({
      id: model._id,
      entity: model,
      type: SynchronizationStatusType.PUSH
    });
  }

  private handleSyncFailed(model: PushSyncModel | PushSyncModel[], err: any): Observable<any> {
    let models = _.castArray(model);

    let topFailedModel = _.first(_.orderBy(models, 'retryCount', 'desc'));

    // Handle retry count exceeded
    if (
      topFailedModel.retryCount >= MAX_PUSH_SYNC_RETRY_COUNT ||
      topFailedModel.internalRetryCount >= MAX_PUSH_SYNC_RETRY_COUNT
    ) {
      Logger.error(
        err,
        `[SYNC] - PushSync [${this._userEmail}]: could not sync ${models[0].data.$type}
                and will not retry as retryCount is ${topFailedModel.retryCount}`,
        LogTag.SYNC
      );

      return this.dequeueSynchronizationInternal(models);
    }

    if (err instanceof DraftPushError) {
      return this.enqueueSynchronizationInternal(_.map(models, (m: PushSyncModel) => (m.bumpInternalRetry(), m)));
    }

    // Retry
    Logger.customLog(
      `[SYNC] - PushSync [${this._userEmail}]: will retry ${models[0].data.$type}`,
      LogLevel.ERROR,
      LogTag.SYNC
    );

    // Notify user on 2th and 5th retry
    if ([2, 5].includes(topFailedModel.retryCount)) {
      this._notificationsService.setInAppNotification(this._userEmail, {
        type: NotificationEventType.PushSyncIssue,
        value: topFailedModel.retryCount
      });
    }

    return this.enqueueSynchronizationInternal(_.map(models, (m: PushSyncModel) => (m.bumpRetry(), m)));
  }

  clearPushSyncQueue(forUserEmail: string) {
    return of(undefined);
  }
}
