/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { CardBase } from '../models/card-base';
import { Response } from '../models/response';
import { OptionsCardChat } from '../models/options-card-chat';
import { CardChat } from '../models/card-chat';
import { ResponseConflict } from '../models/response-conflict';
import { CardShared } from '../models/card-shared';
import { CardDraft } from '../models/card-draft';
import { OptionsCardList } from '../models/options-card-list';
import { ListOfResourcesOfCardBase } from '../models/list-of-resources-of-card-base';
import { CardType } from '../models/card-type';
import { SearchQueryConversation } from '../models/search-query-conversation';
import { CardAppointment } from '../models/card-appointment';
import { ListOfResourcesOfCardAppointment } from '../models/list-of-resources-of-card-appointment';
import { AppointmentResponse } from '../models/appointment-response';

@Injectable()
export class CardApiService extends BaseService {
  /**
   * @param params The `CardApiService.Card_GetParams` containing the following parameters:
   *
   * - `id`: Card id
   *
   * - `sizeCopiedComments`: Requested page size of CopiedComments resource
   *
   * - `sizeComments`: Requested page size of Comments resource
   *
   * - `orderCopiedComments`: Order of copied comments by created date time (default is ascending).
   *
   * - `orderComments`: Order of comments by created date time (default is ascending).
   *
   * - `offsetCopiedComments`: Requested page offset on CopiedComments resource
   *
   * - `offsetComments`: Requested page offset on Comments resource
   *
   * - `includeSignedLinks`: Attachment includes signed links
   *
   * - `includeFullCopiedCard`: Attachment includes signed links
   *
   * - `htmlFormat`: HTML format of comment body
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Chat card
   * or
   * Mail card
   * or
   * Shared card
   * or
   * Appointment card
   * or
   * Draft card
   */
  Card_GetResponse(params: CardApiService.Card_GetParams, forUserEmail: string): Observable<HttpResponse<CardBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/card/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_Get');

    // body, headers and query params
    if (params.sizeCopiedComments != null)
      __params = __params.set('sizeCopiedComments', params.sizeCopiedComments.toString());
    if (params.sizeComments != null) __params = __params.set('sizeComments', params.sizeComments.toString());
    if (params.orderCopiedComments != null)
      __params = __params.set('orderCopiedComments', params.orderCopiedComments.toString());
    if (params.orderComments != null) __params = __params.set('orderComments', params.orderComments.toString());
    if (params.offsetCopiedComments != null)
      __params = __params.set('offsetCopiedComments', params.offsetCopiedComments.toString());
    if (params.offsetComments != null) __params = __params.set('offsetComments', params.offsetComments.toString());
    if (params.includeSignedLinks != null)
      __params = __params.set('includeSignedLinks', params.includeSignedLinks.toString());
    if (params.includeFullCopiedCard != null)
      __params = __params.set('includeFullCopiedCard', params.includeFullCopiedCard.toString());
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardBase;

          body = <CardBase>response.body;

          return <HttpResponse<CardBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_GetParams` containing the following parameters:
   *
   * - `id`: Card id
   *
   * - `sizeCopiedComments`: Requested page size of CopiedComments resource
   *
   * - `sizeComments`: Requested page size of Comments resource
   *
   * - `orderCopiedComments`: Order of copied comments by created date time (default is ascending).
   *
   * - `orderComments`: Order of comments by created date time (default is ascending).
   *
   * - `offsetCopiedComments`: Requested page offset on CopiedComments resource
   *
   * - `offsetComments`: Requested page offset on Comments resource
   *
   * - `includeSignedLinks`: Attachment includes signed links
   *
   * - `includeFullCopiedCard`: Attachment includes signed links
   *
   * - `htmlFormat`: HTML format of comment body
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Chat card
   * or
   * Mail card
   * or
   * Shared card
   * or
   * Appointment card
   * or
   * Draft card
   */
  Card_Get(params: CardApiService.Card_GetParams, forUserEmail: string, cacheEnabled?: boolean): Observable<CardBase> {
    const observable$ = this.Card_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/${params.id}`;
      const url = this.rootUrl + `/api/v1/card/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Card_GetCardChatOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsCardChat>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/card/chat`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_GetCardChatOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsCardChat;

          body = <OptionsCardChat>response.body;

          return <HttpResponse<OptionsCardChat>>response.clone({ body: body });
        })
      );
  }

  Card_GetCardChatOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsCardChat> {
    const observable$ = this.Card_GetCardChatOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/chat`;
      const url = this.rootUrl + `/api/v1/card/chat`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_CreateCardParams` containing the following parameters:
   *
   * - `cardChat`: Card chat object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_CreateCardResponse(
    params: CardApiService.Card_CreateCardParams,
    forUserEmail: string
  ): Observable<HttpResponse<CardChat>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/card/chat`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_CreateCard');

    // body, headers and query params
    __body = params.cardChat;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardChat;

          body = <CardChat>response.body;

          return <HttpResponse<CardChat>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_CreateCardParams` containing the following parameters:
   *
   * - `cardChat`: Card chat object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_CreateCard(
    params: CardApiService.Card_CreateCardParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardChat> {
    const observable$ = this.Card_CreateCardResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/chat`;
      const url = this.rootUrl + `/api/v1/card/chat`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Card_GetCardSharedOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsCardChat>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/card/shared`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_GetCardSharedOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsCardChat;

          body = <OptionsCardChat>response.body;

          return <HttpResponse<OptionsCardChat>>response.clone({ body: body });
        })
      );
  }

  Card_GetCardSharedOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsCardChat> {
    const observable$ = this.Card_GetCardSharedOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/shared`;
      const url = this.rootUrl + `/api/v1/card/shared`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_CreateCardSharedParams` containing the following parameters:
   *
   * - `cardShared`: Card shared object
   *
   * - `sourceResourceId`: Id of mail comment or mail card to create/copy shared card from.
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_CreateCardSharedResponse(
    params: CardApiService.Card_CreateCardSharedParams,
    forUserEmail: string
  ): Observable<HttpResponse<CardShared>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/card/shared`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_CreateCardShared');

    // body, headers and query params
    __body = params.cardShared;
    if (params.sourceResourceId != null)
      __params = __params.set('sourceResourceId', params.sourceResourceId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardShared;

          body = <CardShared>response.body;

          return <HttpResponse<CardShared>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_CreateCardSharedParams` containing the following parameters:
   *
   * - `cardShared`: Card shared object
   *
   * - `sourceResourceId`: Id of mail comment or mail card to create/copy shared card from.
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_CreateCardShared(
    params: CardApiService.Card_CreateCardSharedParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardShared> {
    const observable$ = this.Card_CreateCardSharedResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/shared`;
      const url = this.rootUrl + `/api/v1/card/shared`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_DeleteCardDraftParams` containing the following parameters:
   *
   * - `cardDraft`: Card draft object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_DeleteCardDraftResponse(
    params: CardApiService.Card_DeleteCardDraftParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/card/draft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_DeleteCardDraft');

    // body, headers and query params
    __body = params.cardDraft;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_DeleteCardDraftParams` containing the following parameters:
   *
   * - `cardDraft`: Card draft object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_DeleteCardDraft(
    params: CardApiService.Card_DeleteCardDraftParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Card_DeleteCardDraftResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/draft`;
      const url = this.rootUrl + `/api/v1/card/draft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_SendLaterCardDraftParams` containing the following parameters:
   *
   * - `sendDateTime`: DateTime when message will be send (or set offserInSeconds)
   *
   * - `offsetInSeconds`: send after offset seconds (or set sendDateTime)
   *
   * - `cardDraftId`: Card draft id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_SendLaterCardDraftResponse(
    params: CardApiService.Card_SendLaterCardDraftParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/card/draft/sendlater`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_SendLaterCardDraft');

    // body, headers and query params
    if (params.sendDateTime != null) __params = __params.set('sendDateTime', params.sendDateTime.toString());
    if (params.offsetInSeconds != null) __params = __params.set('offsetInSeconds', params.offsetInSeconds.toString());
    if (params.cardDraftId != null) __params = __params.set('cardDraftId', params.cardDraftId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_SendLaterCardDraftParams` containing the following parameters:
   *
   * - `sendDateTime`: DateTime when message will be send (or set offserInSeconds)
   *
   * - `offsetInSeconds`: send after offset seconds (or set sendDateTime)
   *
   * - `cardDraftId`: Card draft id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_SendLaterCardDraft(
    params: CardApiService.Card_SendLaterCardDraftParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Card_SendLaterCardDraftResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/draft/sendlater`;
      const url = this.rootUrl + `/api/v1/card/draft/sendlater`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_CancelSendLaterCardDraftParams` containing the following parameters:
   *
   * - `cardDraftId`: Card draft id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_CancelSendLaterCardDraftResponse(
    params: CardApiService.Card_CancelSendLaterCardDraftParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/card/draft/cancelsendlater`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_CancelSendLaterCardDraft');

    // body, headers and query params
    if (params.cardDraftId != null) __params = __params.set('cardDraftId', params.cardDraftId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_CancelSendLaterCardDraftParams` containing the following parameters:
   *
   * - `cardDraftId`: Card draft id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_CancelSendLaterCardDraft(
    params: CardApiService.Card_CancelSendLaterCardDraftParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Card_CancelSendLaterCardDraftResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/draft/cancelsendlater`;
      const url = this.rootUrl + `/api/v1/card/draft/cancelsendlater`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_UpdateCardParams` containing the following parameters:
   *
   * - `cardBase`: Card chat object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Card shared.
   */
  Card_UpdateCardResponse(
    params: CardApiService.Card_UpdateCardParams,
    forUserEmail: string
  ): Observable<HttpResponse<CardShared>> {
    let __method = 'PATCH';
    let __url = this.rootUrl + `/api/v1/card`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_UpdateCard');

    // body, headers and query params
    __body = params.cardBase;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardShared;

          body = <CardShared>response.body;

          return <HttpResponse<CardShared>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_UpdateCardParams` containing the following parameters:
   *
   * - `cardBase`: Card chat object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Card shared.
   */
  Card_UpdateCard(
    params: CardApiService.Card_UpdateCardParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardShared> {
    const observable$ = this.Card_UpdateCardResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card`;
      const url = this.rootUrl + `/api/v1/card`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Card_GetCardListOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsCardList>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/card/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_GetCardListOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsCardList;

          body = <OptionsCardList>response.body;

          return <HttpResponse<OptionsCardList>>response.clone({ body: body });
        })
      );
  }

  Card_GetCardListOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsCardList> {
    const observable$ = this.Card_GetCardListOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/list`;
      const url = this.rootUrl + `/api/v1/card/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_GetListParams` containing the following parameters:
   *
   * - `validTotalCommentSize`: Comment total size is calculated on get.This setting is only used when cardIds are set.
   *
   * - `tags`: List of tags to filter card list by.
   *
   * - `tagFilterRelation`: Query relation for tag/sharedTag filters. Default is 'and'.
   *
   * - `sortOrder`: Order of cards by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `sharedTags`: List of shared tags to filter card list by.
   *
   * - `searchQuery`: Plain text search query to filter comment list by cards title.
   *
   * - `recipientIds`: List of recipient ids (either a user or a group) to filter comment list by.
   *
   * - `offsetModified`: Include cards from/since (as per requested sort order) its modified date.
   *
   * - `offsetId`: Id of last recieved card in the previous batch when using offsetModified
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offsetDateType`: Offset date type.
   *
   * - `offsetDateEnd`: Include cards to/until (as per requested sort order) its offset date type.
   *
   * - `offsetDate`: Include cards from/since (as per requested sort order) its offset date type.
   *
   * - `offset`: Requested page offset.
   *
   * - `htmlFormat`: HTML format of comment body.
   *
   * - `excludeTags`: List of exclude tags to filter card list by.
   *
   * - `excludeTagFilterRelation`: Query relation for excludeTag/excludeSharedTag filters. Default is 'and'.
   *
   * - `excludeSharedTags`: List of exclude shared tags to filter card list by.
   *
   * - `exactShareContactIds`: List of contact ids (user or group) to filter result list by exact matching of comments's share list.
   *
   * - `contactFilterRelation`: Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
   *
   * - `cardTypes`: List of card types to filter card list by.
   *
   * - `cardIds`: List of card ids to filter card list by. Also returns chat cards without comments and/or card snapshots which are hidden otherwise.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Card_GetListResponse(
    params: CardApiService.Card_GetListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfCardBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/card/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_GetList');

    // body, headers and query params
    if (params.validTotalCommentSize != null)
      __params = __params.set('validTotalCommentSize', params.validTotalCommentSize.toString());
    (params.tags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('tags', val.toString());
    });
    if (params.tagFilterRelation != null)
      __params = __params.set('tagFilterRelation', params.tagFilterRelation.toString());
    if (params.sortOrder != null) __params = __params.set('sortOrder', params.sortOrder.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    (params.sharedTags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('sharedTags', val.toString());
    });
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.recipientIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('recipientIds', val.toString());
    });
    if (params.offsetModified != null) __params = __params.set('offsetModified', params.offsetModified.toString());
    if (params.offsetId != null) __params = __params.set('offsetId', params.offsetId.toString());
    if (params.offsetHistoryId != null) __params = __params.set('offsetHistoryId', params.offsetHistoryId.toString());
    if (params.offsetDateType != null) __params = __params.set('offsetDateType', params.offsetDateType.toString());
    if (params.offsetDateEnd != null) __params = __params.set('offsetDateEnd', params.offsetDateEnd.toString());
    if (params.offsetDate != null) __params = __params.set('offsetDate', params.offsetDate.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    (params.excludeTags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('excludeTags', val.toString());
    });
    if (params.excludeTagFilterRelation != null)
      __params = __params.set('excludeTagFilterRelation', params.excludeTagFilterRelation.toString());
    (params.excludeSharedTags || []).forEach((val, index) => {
      if (val != null) __params = __params.append('excludeSharedTags', val.toString());
    });
    (params.exactShareContactIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('exactShareContactIds', val.toString());
    });
    if (params.contactFilterRelation != null)
      __params = __params.set('contactFilterRelation', params.contactFilterRelation.toString());
    (params.cardTypes || []).forEach((val, index) => {
      if (val != null) __params = __params.append('cardTypes', val.toString());
    });
    (params.cardIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append('cardIds', val.toString());
    });
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfCardBase;

          body = <ListOfResourcesOfCardBase>response.body;

          return <HttpResponse<ListOfResourcesOfCardBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_GetListParams` containing the following parameters:
   *
   * - `validTotalCommentSize`: Comment total size is calculated on get.This setting is only used when cardIds are set.
   *
   * - `tags`: List of tags to filter card list by.
   *
   * - `tagFilterRelation`: Query relation for tag/sharedTag filters. Default is 'and'.
   *
   * - `sortOrder`: Order of cards by modified date time (default is descending).
   *
   * - `size`: Requested page size.
   *
   * - `sharedTags`: List of shared tags to filter card list by.
   *
   * - `searchQuery`: Plain text search query to filter comment list by cards title.
   *
   * - `recipientIds`: List of recipient ids (either a user or a group) to filter comment list by.
   *
   * - `offsetModified`: Include cards from/since (as per requested sort order) its modified date.
   *
   * - `offsetId`: Id of last recieved card in the previous batch when using offsetModified
   *
   * - `offsetHistoryId`: Next page of resources when offsetModified set
   *
   * - `offsetDateType`: Offset date type.
   *
   * - `offsetDateEnd`: Include cards to/until (as per requested sort order) its offset date type.
   *
   * - `offsetDate`: Include cards from/since (as per requested sort order) its offset date type.
   *
   * - `offset`: Requested page offset.
   *
   * - `htmlFormat`: HTML format of comment body.
   *
   * - `excludeTags`: List of exclude tags to filter card list by.
   *
   * - `excludeTagFilterRelation`: Query relation for excludeTag/excludeSharedTag filters. Default is 'and'.
   *
   * - `excludeSharedTags`: List of exclude shared tags to filter card list by.
   *
   * - `exactShareContactIds`: List of contact ids (user or group) to filter result list by exact matching of comments's share list.
   *
   * - `contactFilterRelation`: Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
   *
   * - `cardTypes`: List of card types to filter card list by.
   *
   * - `cardIds`: List of card ids to filter card list by. Also returns chat cards without comments and/or card snapshots which are hidden otherwise.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Card_GetList(
    params: CardApiService.Card_GetListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfCardBase> {
    const observable$ = this.Card_GetListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/list`;
      const url = this.rootUrl + `/api/v1/card/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_GetListByQueryParams` containing the following parameters:
   *
   * - `query`: Search query.
   *
   * - `htmlFormat`: HTML format of comment body.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Card_GetListByQueryResponse(
    params: CardApiService.Card_GetListByQueryParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfCardBase>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/card/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_GetListByQuery');

    // body, headers and query params
    __body = params.query;
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfCardBase;

          body = <ListOfResourcesOfCardBase>response.body;

          return <HttpResponse<ListOfResourcesOfCardBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_GetListByQueryParams` containing the following parameters:
   *
   * - `query`: Search query.
   *
   * - `htmlFormat`: HTML format of comment body.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Card_GetListByQuery(
    params: CardApiService.Card_GetListByQueryParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfCardBase> {
    const observable$ = this.Card_GetListByQueryResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/list`;
      const url = this.rootUrl + `/api/v1/card/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_CreateCard2Params` containing the following parameters:
   *
   * - `cardAppointment`: Card appointment object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_CreateCard2Response(
    params: CardApiService.Card_CreateCard2Params,
    forUserEmail: string
  ): Observable<HttpResponse<CardAppointment>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/card/appointment`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_CreateCard2');

    // body, headers and query params
    __body = params.cardAppointment;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardAppointment;

          body = <CardAppointment>response.body;

          return <HttpResponse<CardAppointment>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_CreateCard2Params` containing the following parameters:
   *
   * - `cardAppointment`: Card appointment object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_CreateCard2(
    params: CardApiService.Card_CreateCard2Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardAppointment> {
    const observable$ = this.Card_CreateCard2Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/appointment`;
      const url = this.rootUrl + `/api/v1/card/appointment`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_GetAgendaListParams` containing the following parameters:
   *
   * - `size`: Requested page size.
   *
   * - `offset`: Requested page offset.
   *
   * - `dateTimeStart`: Minimum start date time of appointment
   *
   * - `dateTimeEnd`: Maximum end time of appointment (if not defined you get all appointments from dateTimeStart onwards)
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Card_GetAgendaListResponse(
    params: CardApiService.Card_GetAgendaListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfCardAppointment>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/card/appointment/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_GetAgendaList');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.dateTimeStart != null) __params = __params.set('dateTimeStart', params.dateTimeStart.toString());
    if (params.dateTimeEnd != null) __params = __params.set('dateTimeEnd', params.dateTimeEnd.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfCardAppointment;

          body = <ListOfResourcesOfCardAppointment>response.body;

          return <HttpResponse<ListOfResourcesOfCardAppointment>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_GetAgendaListParams` containing the following parameters:
   *
   * - `size`: Requested page size.
   *
   * - `offset`: Requested page offset.
   *
   * - `dateTimeStart`: Minimum start date time of appointment
   *
   * - `dateTimeEnd`: Maximum end time of appointment (if not defined you get all appointments from dateTimeStart onwards)
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Card_GetAgendaList(
    params: CardApiService.Card_GetAgendaListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfCardAppointment> {
    const observable$ = this.Card_GetAgendaListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/appointment/list`;
      const url = this.rootUrl + `/api/v1/card/appointment/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_UpdateAppointmentResponseParams` containing the following parameters:
   *
   * - `id`: Card id
   *
   * - `appointmentResponse`: Appointment response object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_UpdateAppointmentResponseResponse(
    params: CardApiService.Card_UpdateAppointmentResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<AppointmentResponse>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/card/appointment/${params.id}/response`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_UpdateAppointmentResponse');

    // body, headers and query params
    __body = params.appointmentResponse;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AppointmentResponse;

          body = <AppointmentResponse>response.body;

          return <HttpResponse<AppointmentResponse>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_UpdateAppointmentResponseParams` containing the following parameters:
   *
   * - `id`: Card id
   *
   * - `appointmentResponse`: Appointment response object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Card_UpdateAppointmentResponse(
    params: CardApiService.Card_UpdateAppointmentResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AppointmentResponse> {
    const observable$ = this.Card_UpdateAppointmentResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/appointment/${params.id}/response`;
      const url = this.rootUrl + `/api/v1/card/appointment/${params.id}/response`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `CardApiService.Card_CardMoveToTeamResponseParams` containing the following parameters:
   *
   * - `id`: Card id
   *
   * - `groupId`: move to groupId
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Card shared.
   */
  Card_CardMoveToTeamResponseResponse(
    params: CardApiService.Card_CardMoveToTeamResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<CardShared>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/card/movetoteam/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Card_CardMoveToTeamResponse');

    // body, headers and query params
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardShared;

          body = <CardShared>response.body;

          return <HttpResponse<CardShared>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `CardApiService.Card_CardMoveToTeamResponseParams` containing the following parameters:
   *
   * - `id`: Card id
   *
   * - `groupId`: move to groupId
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Card shared.
   */
  Card_CardMoveToTeamResponse(
    params: CardApiService.Card_CardMoveToTeamResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardShared> {
    const observable$ = this.Card_CardMoveToTeamResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/card/movetoteam/${params.id}`;
      const url = this.rootUrl + `/api/v1/card/movetoteam/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module CardApiService {
  /**
   * Parameters for Card_Get
   */
  export type Card_GetParams = {
    /**
     * Card id
     */
    id: string;

    /**
     * Requested page size of CopiedComments resource
     */
    sizeCopiedComments?: number;

    /**
     * Requested page size of Comments resource
     */
    sizeComments?: number;

    /**
     * Order of copied comments by created date time (default is ascending).
     */
    orderCopiedComments?: string;

    /**
     * Order of comments by created date time (default is ascending).
     */
    orderComments?: string;

    /**
     * Requested page offset on CopiedComments resource
     */
    offsetCopiedComments?: number;

    /**
     * Requested page offset on Comments resource
     */
    offsetComments?: number;

    /**
     * Attachment includes signed links
     */
    includeSignedLinks?: boolean;

    /**
     * Attachment includes signed links
     */
    includeFullCopiedCard?: boolean;

    /**
     * HTML format of comment body
     */
    htmlFormat?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_CreateCard
   */
  export type Card_CreateCardParams = {
    /**
     * Card chat object
     */
    cardChat: CardChat;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_CreateCardShared
   */
  export type Card_CreateCardSharedParams = {
    /**
     * Card shared object
     */
    cardShared: CardShared;

    /**
     * Id of mail comment or mail card to create/copy shared card from.
     */
    sourceResourceId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_DeleteCardDraft
   */
  export type Card_DeleteCardDraftParams = {
    /**
     * Card draft object
     */
    cardDraft: CardDraft;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_SendLaterCardDraft
   */
  export type Card_SendLaterCardDraftParams = {
    /**
     * DateTime when message will be send (or set offserInSeconds)
     */
    sendDateTime?: string;

    /**
     * send after offset seconds (or set sendDateTime)
     */
    offsetInSeconds?: number;

    /**
     * Card draft id
     */
    cardDraftId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_CancelSendLaterCardDraft
   */
  export type Card_CancelSendLaterCardDraftParams = {
    /**
     * Card draft id
     */
    cardDraftId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_UpdateCard
   */
  export type Card_UpdateCardParams = {
    /**
     * Card chat object
     */
    cardBase: CardBase;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_GetList
   */
  export type Card_GetListParams = {
    /**
     * Comment total size is calculated on get.This setting is only used when cardIds are set.
     */
    validTotalCommentSize?: boolean;

    /**
     * List of tags to filter card list by.
     */
    tags?: string[];

    /**
     * Query relation for tag/sharedTag filters. Default is 'and'.
     */
    tagFilterRelation?: string;

    /**
     * Order of cards by modified date time (default is descending).
     */
    sortOrder?: string;

    /**
     * Requested page size.
     */
    size?: number;

    /**
     * List of shared tags to filter card list by.
     */
    sharedTags?: string[];

    /**
     * Plain text search query to filter comment list by cards title.
     */
    searchQuery?: string;

    /**
     * List of recipient ids (either a user or a group) to filter comment list by.
     */
    recipientIds?: string[];

    /**
     * Include cards from/since (as per requested sort order) its modified date.
     */
    offsetModified?: string;

    /**
     * Id of last recieved card in the previous batch when using offsetModified
     */
    offsetId?: string;

    /**
     * Next page of resources when offsetModified set
     */
    offsetHistoryId?: string;

    /**
     * Offset date type.
     */
    offsetDateType?: string;

    /**
     * Include cards to/until (as per requested sort order) its offset date type.
     */
    offsetDateEnd?: string;

    /**
     * Include cards from/since (as per requested sort order) its offset date type.
     */
    offsetDate?: string;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * HTML format of comment body.
     */
    htmlFormat?: string;

    /**
     * List of exclude tags to filter card list by.
     */
    excludeTags?: string[];

    /**
     * Query relation for excludeTag/excludeSharedTag filters. Default is 'and'.
     */
    excludeTagFilterRelation?: string;

    /**
     * List of exclude shared tags to filter card list by.
     */
    excludeSharedTags?: string[];

    /**
     * List of contact ids (user or group) to filter result list by exact matching of comments's share list.
     */
    exactShareContactIds?: string[];

    /**
     * Query relation for contact filters (userIds, groupIds and senderIds). Default is 'or'.
     */
    contactFilterRelation?: string;

    /**
     * List of card types to filter card list by.
     */
    cardTypes?: CardType[];

    /**
     * List of card ids to filter card list by. Also returns chat cards without comments and/or card snapshots which are hidden otherwise.
     */
    cardIds?: string[];

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_GetListByQuery
   */
  export type Card_GetListByQueryParams = {
    /**
     * Search query.
     */
    query?: SearchQueryConversation;

    /**
     * HTML format of comment body.
     */
    htmlFormat?: string;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_CreateCard2
   */
  export type Card_CreateCard2Params = {
    /**
     * Card appointment object
     */
    cardAppointment: CardAppointment;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_GetAgendaList
   */
  export type Card_GetAgendaListParams = {
    /**
     * Requested page size.
     */
    size?: number;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * Minimum start date time of appointment
     */
    dateTimeStart?: string;

    /**
     * Maximum end time of appointment (if not defined you get all appointments from dateTimeStart onwards)
     */
    dateTimeEnd?: string;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_UpdateAppointmentResponse
   */
  export type Card_UpdateAppointmentResponseParams = {
    /**
     * Card id
     */
    id: string;

    /**
     * Appointment response object
     */
    appointmentResponse: AppointmentResponse;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Card_CardMoveToTeamResponse
   */
  export type Card_CardMoveToTeamResponseParams = {
    /**
     * Card id
     */
    id: string;

    /**
     * move to groupId
     */
    groupId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
