import { Directive, OnDestroy } from '@angular/core';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { RecentSearch, RecentSearchFilter, SearchResultType } from '@dta/shared/models/search.model';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { flatMap, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StringUtils } from '@dta/shared/utils/common-utils';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import * as _ from 'lodash';
import { CardBaseModel, CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { AppStateService } from '@shared/services/data/app-state/app-state.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { CardService } from '@shared/services/data/card/card.service';
import { FileService } from '@shared/services/data/file/file.service';

export interface SearchServiceI {
  focusOnInput: Subject<boolean>;
  openRecent: Subject<RecentSearch>;
  setFilter: Subject<RecentSearchFilter>;
  resetSearch: Subject<boolean>;
  closeSearch: Subject<boolean>;
  toggle: Subject<boolean>;
  saveRecent: Subject<RecentSearch>;

  search(
    query: string,
    searchGroupIdList: string,
    offset: number,
    size: number,
    searchFilter: RecentSearchFilter,
    isLoadMore?: boolean, // needed to distinguish between load more and regular search
  ): Observable<SearchResultType>;
}

@AutoUnsubscribe()
@Directive()
export abstract class SearchService implements SearchServiceI, OnDestroy {
  ///////////////////
  // State variables
  ///////////////////
  protected routeBeforeSearch: string;

  ////////////
  // Subjects
  ////////////
  focusOnInput: Subject<boolean> = new Subject();
  openRecent: Subject<RecentSearch> = new Subject();
  setFilter: Subject<RecentSearchFilter> = new Subject();
  resetSearch: Subject<boolean> = new Subject();
  closeSearch: Subject<boolean> = new Subject();
  toggle: Subject<boolean> = new Subject();
  saveRecent: Subject<RecentSearch> = new Subject();

  /////////////////
  // Subscriptions
  /////////////////
  private toggleSub: Subscription;

  constructor(
    protected _router: Router,
    protected _userManager: UserManagerService,
    protected _appStateService: AppStateService,
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _fileService: FileService,
  ) {
    this.subscribeToToggle();
  }

  get constructorName(): string {
    return 'SearchService';
  }

  ngOnDestroy(): void {}

  search(
    query: string,
    searchGroupIdList: string = 'Card',
    offset: number = 0,
    size: number,
    searchFilter: RecentSearchFilter,
    isLoadMore?: boolean, // needed to distinguish between load more and regular search
  ): Observable<SearchResultType> {
    return of(this._appStateService.isConnectionActive).pipe(
      switchMap((connectionActive: boolean) => {
        // Offline search
        if (!connectionActive) {
          return this.searchDb(query, searchGroupIdList, offset, size, searchFilter);
        }

        // Online search / load-more
        if (isLoadMore) {
          return this.searchAPI(query, searchGroupIdList, offset, size, searchFilter);
        }

        // Combined search
        return merge(
          this.searchDb(query, searchGroupIdList, offset, size, searchFilter),
          this.searchAPI(query, searchGroupIdList, offset, size, searchFilter),
        );
      }),
    );
  }

  private searchDb(
    query: string,
    searchGroupIdList: string,
    offset?: number,
    size?: number,
    searchFilter?: RecentSearchFilter,
  ): Observable<SearchResultType> {
    return of(undefined).pipe(
      flatMap(() => {
        switch (searchGroupIdList) {
          case 'Card':
            return this.searchCards(query, searchFilter, offset, size);
          case 'File':
            return this.searchFiles(query, searchFilter, offset, size);
          default:
            return this.searchCards(query, searchFilter, offset, size);
        }
      }),
    );
  }

  protected abstract searchCards(
    query: string,
    searchFilter?: RecentSearchFilter,
    offset?: number,
    limit?: number,
  ): Observable<SearchResultType>;

  protected abstract searchFiles(
    query: string,
    searchFilter?: RecentSearchFilter,
    offset?: number,
    size?: number,
  ): Observable<SearchResultType>;

  private searchAPI(
    query: string,
    searchGroupId: string,
    offset: number,
    size: number,
    searchFilter?: RecentSearchFilter,
  ): Observable<SearchResultType> {
    switch (searchGroupId) {
      case 'Card':
        return this.searchApiCards(query, searchFilter, offset, size);
      case 'File':
        return this.searchApiFiles(query, searchFilter, offset, size);
      default:
        return this.searchApiCards(query, searchFilter, offset, size);
    }
  }

  private searchApiCards(query: string, searchFilter: RecentSearchFilter, offset, size): Observable<SearchResultType> {
    return this._commentService
      .fetchCommentsForSearch(this._userManager.getCurrentUserEmail(), query, offset, size, searchFilter)
      .pipe(
        /**
         * Group comments by card.id
         */
        map((comments: CommentModel[]) => {
          return _.toArray(_.groupBy(comments, 'parent.id'));
        }),
        /**
         * Create cards from comments
         */
        map((commentsByCard: CommentModel[][]) => {
          return commentsByCard.map(comments => {
            let lastComment = _.last(comments);
            let firstComment = _.first(comments);

            let card = CardBaseModel.create(firstComment.parent);
            card.comments = BaseModel.createListOfResources(comments);
            card.created = this.getLatestCommentCreatedDate(comments);

            return card;
          });
        }),
        map((cards: CardModel[]) => {
          return <SearchResultType>{
            type: 'online',
            data: {
              Card: cards,
            },
          };
        }),
      );
  }

  protected searchApiFiles(
    query: string,
    searchFilter: RecentSearchFilter,
    offset?: number,
    size?: number,
  ): Observable<SearchResultType> {
    return this._fileService
      .fetchFilesForSearch(
        this._userManager.getCurrentUserEmail(),
        query,
        offset,
        size,
        this._userManager.getCurrentUserId(),
        searchFilter,
      )
      .pipe(
        map((files: FileModel[]) => {
          return <SearchResultType>{
            type: 'online',
            data: {
              File: files,
            },
          };
        }),
      );
  }

  protected getLatestCommentCreatedDate(comments: CommentModel[]): string {
    let date = Math.max.apply(
      undefined,
      comments.map(comment => {
        return new Date(comment.created);
      }),
    );

    return new Date(date).toISOString();
  }

  getRouteBeforeSearch(): string {
    return this.routeBeforeSearch || null;
  }

  private subscribeToToggle() {
    this.toggleSub?.unsubscribe();
    this.toggleSub = this.toggle
      .pipe(
        tap((data: boolean) => {
          if (data === true) {
            this.saveRouteAndNavigate();
          }
        }),
      )
      .subscribe();
  }

  private saveRouteAndNavigate() {
    // Since 3.0.0 we use routes instead of dialogs for search
    // We need to store the last known route before Search was initialized
    // so we can navigate back to the same route once user is done searching
    if (!this._router.routerState.snapshot.url.includes('search')) {
      this.routeBeforeSearch = this._router.routerState.snapshot.url;
    }
    this._router.navigate(['/search']);
  }
}
