import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { FileModel, FileViewData, ImageDimensions } from 'dta/shared/models-api-loop/file.model';
import { Observable, of } from 'rxjs';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { FileNameConstants } from '@shared/enums/enums';
import { BaseViewDecorateService } from '../base-view-decorate.service';
import { getFileExtension } from '@shared/utils/get-file-extension';
import { File as IFile } from '@shared/api/api-loop/models/file';

@Injectable()
export class FileViewDecorateService extends BaseViewDecorateService<FileModel> {
  private readonly imageExtensions: string[] = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
  private readonly videoExtensions: string[] = ['quicktime', 'mov', 'mpg', 'mp4', 'ogv', 'webm'];

  private readonly defaultThumbnailSize: number = 280;
  private readonly maxThumbnailWidth: number = this.defaultThumbnailSize * 2;
  private readonly maxThumbnailHeight: number = this.defaultThumbnailSize;

  constructor(private _fileStorageService: FileStorageService) {
    super();
  }

  decorateViewData(forUserEmail: string, file: FileModel, force?: boolean): Observable<FileModel> {
    if (file._ui && !force) {
      return of(file);
    }

    let ui: FileViewData = file._ui || {
      synchronized: false,
      isSyncing: false,
      thumbnailPath: '',
      iconPath: '',
      previewPath: '',
      category: '',
      isPdf: false,
      hasBinary: false,
      hasThumbnail: false,
      hasPreview: false,
      hasNativePreview: false,
      thumbnailDimensions: undefined,
      base64data: '',
      hideFooter: false,
    };

    if (!file._ex) {
      file._ui = ui;
      return of(file);
    }

    ui.synchronized = this.isSynchronized(file);
    ui.isSyncing = !file._ex.syncStatus.binary && !file._ex.syncStatus.failed;
    ui.iconPath = this.getIconPath(file);
    ui.category = FileViewDecorateService.getCategoryFromExtension(file);
    ui.isPdf = this.isPdf(file);

    ui.hasBinary = this.hasBinary(file);

    ui.thumbnailPath = this.getThumbnailPath(file);
    ui.hasThumbnail = this.hasThumbnail(file);

    ui.previewPath = this.getPreviewPath(file);
    ui.hasPreview = this.hasPreview(file);
    ui.hasNativePreview = this.hasNativePreview(file);

    // only set dimensions when we know the size or thumbnail can be shown
    let dimensions = this.calculateThumbnailDimensions(file);
    if (dimensions.width || dimensions.height || file._ex.syncStatus.thumbnail) {
      ui.thumbnailDimensions = dimensions;
    }

    file._ui = ui;

    return of(file);
  }

  private calculateThumbnailDimensions(file: FileModel): ImageDimensions {
    let result: ImageDimensions = {};
    let thumbInfo = file.getFirstThumbnailPage();

    // default: auto-size
    if (!thumbInfo || !file.hasValidThumbnailDimensions()) {
      return result;
    }

    if (thumbInfo.width >= this.maxThumbnailWidth || thumbInfo.height >= this.maxThumbnailHeight) {
      // thumbnail is larger then max size
      let widthRatio = this.maxThumbnailWidth / thumbInfo.width;
      let heightRatio = this.maxThumbnailHeight / thumbInfo.height;
      let ratio = widthRatio < heightRatio ? widthRatio : heightRatio;

      result.width = ratio * thumbInfo.width;
      result.height = ratio * thumbInfo.height;
    } else {
      // thumbnail is smaller then max size
      result.width = thumbInfo.width;
      result.height = thumbInfo.height;
    }

    return result;
  }

  private getThumbnailPath(file: FileModel): string {
    if (file._ex.isImage && file._ex.originalPath) {
      return file._ex.originalPath;
    }
    if (file.isThumbnailReady()) {
      return this._fileStorageService.getFileThumbnailPath(file) || (file._ex.isImage && file.urlLink);
    }

    return file._ex.path || file._ex.originalPath || file.urlLink;
  }

  private getIconPath(file: FileModel): string {
    let path = file._ex.path;
    let extension = file._ex.extension;

    if (extension === 'psd') {
      return path + FileNameConstants.PREVIEW;
    }
    if (extension === 'pdf') {
      return path + FileNameConstants.PREVIEW_PDF;
    }

    return path;
  }

  private getPreviewPath(file: FileModel): string {
    if (this.isPdf(file)) {
      return this.getPdfPreviewPath(file);
    }

    if (this.isVideo(file)) {
      return this.getVideoPreviewPath(file);
    }

    if (!file.id && file._ex.originalPath) {
      return file._ex.originalPath;
    }

    return file._ex.path || file._ex.originalPath;
  }

  private isSynchronized(file: FileModel): boolean {
    let syncStatus = file._ex.syncStatus;

    return syncStatus.meta && syncStatus.binary && syncStatus.thumbnail && syncStatus.preview;
  }

  private hasBinary(file: FileModel): boolean {
    let syncStatus = file._ex.syncStatus;

    // potentially available for local file
    return syncStatus.binary || !_.isEmpty(file._ex.originalPath);
  }

  private hasThumbnail(file: FileModel): boolean {
    let syncStatus = file._ex.syncStatus;

    // potentially available for local file
    return syncStatus.thumbnail || ((file._ex.path || file._ex.originalPath || file.urlLink) && this.isImage(file));
  }

  private hasPreview(file: FileModel): boolean {
    let syncStatus = file._ex.syncStatus;

    // potentially available for local file
    return syncStatus.preview || !_.isEmpty(file._ex.originalPath);
  }

  private hasNativePreview(file: FileModel): boolean {
    if (file._ex.syncStatus.binary || _.isEmpty(file._ex.originalPath)) {
      return false;
    }

    let filesPath = this._fileStorageService.getFilesPath();

    // Native preview is only potentially available when originalPath doesn't point to file storage
    return !file._ex.originalPath.includes(filesPath);
  }

  private isPdf(file: FileModel): boolean {
    return file._ex.extension === 'pdf' || file.isPdfPreviewSupported();
  }

  private getPdfPreviewPath(file: FileModel): string {
    return file.isPdfPreviewSupported()
      ? this._fileStorageService.getFilePreviewPath(file)
      : file._ex.path || file.urlLink;
  }

  private getVideoPreviewPath(file: FileModel): string {
    if (file.urlLink) {
      return file.urlLink;
    }
    return file._ex.path;
  }

  private isImage(file: FileModel): boolean {
    return this.imageExtensions.includes(file._ex.extension);
  }

  private isVideo(file: FileModel): boolean {
    return this.videoExtensions.includes(file._ex.extension);
  }

  static getCategoryFromExtension(file: FileModel | IFile): string {
    let extension = getFileExtension(file.name);
    if (!extension) {
      return undefined;
    }

    switch (extension) {
      case 'doc':
      case 'docx':
        return 'doc';
      case 'xls':
      case 'xlsx':
      case 'ods':
        return 'xls';
      case 'ppt':
      case 'pptx':
      case 'odp':
        return 'ppt';
      case 'odt':
        return 'oo';
      case 'pdf':
        return 'pdf';
      case 'txt':
        return 'txt';
      case 'html':
      case 'htm':
        return 'html';
      case 'jpeg':
      case 'jpg':
      case 'gif':
      case 'tiff':
      case 'png':
      case 'bmp':
        return 'img';
      case 'mp3':
      case 'ogg':
      case 'flac':
      case 'wma':
      case 'wav':
      case 'mid':
        return 'sound';
      case 'avi':
      case 'asf':
      case 'mov':
      case 'qt':
      case 'flv':
      case 'mpg':
      case 'mpeg':
      case 'mp4':
      case 'mvw':
      case 'divx':
      // case 'ogg':
      case 'ogv':
      case 'webm':
        return 'video';
      case 'ical':
      case 'ics':
        return 'cal';
      case 'cs':
      case 'c':
      case 'js':
      case 'php':
      case 'css':
      case 'svg':
        return 'code';
      case 'zip':
      case 'gz':
      case 'tar':
      case 'rar':
      case '7z':
      case 'jar':
        return 'zip';
      default:
        return 'file';
    }
  }
}
