import { Injectable } from '@angular/core';
import { NotificationEvent, NotificationEventType } from 'dta/shared/models/notifications.model';
import { SharedSubjects } from '../communication/shared-subjects/shared-subjects';
import { NotificationEventWrapper } from '../communication/shared-subjects/shared-subjects-models';

@Injectable()
export class NotificationsService {
  get constructorName(): string {
    return 'NotificationsService';
  }

  setInAppNotification(forUserEmail: string, notification: NotificationEvent) {
    let wrappedEvent = new NotificationEventWrapper();
    wrappedEvent.notificationEvent = notification;

    SharedSubjects._inAppNotifications$.next(wrappedEvent);
  }

  removeInAppNotification(forUserEmail: string) {
    let wrappedEvent = new NotificationEventWrapper();
    wrappedEvent.notificationEvent = { type: NotificationEventType.Remove, msg: '', icon: '' };

    SharedSubjects._inAppNotifications$.next(wrappedEvent);
  }
}
