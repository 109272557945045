/**
 * Created by janez on 9.8.2016.
 */
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable()
export class SynchronizationStatusService {
  private ongoing: ISynchronizationStatusEvent[] = [];
  private events$: Subject<ISynchronizationStatusEvent[]> = new Subject();

  /**
   * Add a synchronization event to the list of currently ongoing events
   * @param event
   */
  enqueue(event: ISynchronizationStatusEvent) {
    if (this.isEnqueued(event)) {
      return;
    }

    this.resolveType(event);
    this.ongoing.push(event);
    this.events$.next(this.ongoing);
  }

  /**
   * Mark a synchronization event as complete
   * @param event
   */
  dequeue(event: ISynchronizationStatusEvent) {
    let removedEvents: ISynchronizationStatusEvent[] = _.remove(this.ongoing, e => {
      return event.id === e.id;
    });

    if (_.isEmpty(removedEvents)) {
      return;
    }

    this.events$.next(this.ongoing);
  }

  get enqueued$() {
    return this.events$.pipe(startWith(this.ongoing));
  }

  private isEnqueued(event: ISynchronizationStatusEvent): boolean {
    return _.some(this.ongoing, { id: event.id });
  }

  private resolveType(event: ISynchronizationStatusEvent) {
    if (!_.isNil(event.type)) {
      event['typeDescription'] = SynchronizationStatusType[event.type];
    }
  }
}

export interface ISynchronizationStatusEvent {
  id: string;
  entity?: any;
  type: SynchronizationStatusType;
}

export enum SynchronizationStatusType {
  PULL_ONBOARDING,
  PULL_CHECKING,
  PULL_UPDATES,
  PULL_PAST,
  PULL_ACTIVE,
  PUSH,
}
