import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[outsideClick]',
})
export class OutsideClickDirective implements OnInit, OnDestroy {
  @Output() onOutsideClick = new EventEmitter<any>();

  constructor(private _elementRef: ElementRef) {
    this.onClick = this.onClick.bind(this);
  }

  ngOnInit() {
    document.addEventListener('click', this.onClick, { capture: true });
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.onClick, { capture: true });
  }

  public onClick(event: MouseEvent) {
    let clickedInside = this._elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.onOutsideClick.emit({ clickOutside: true, targetElement: event.target });
    }
  }
}
