import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDateString',
})
export class ToDateStringPipe implements PipeTransform {
  transform(date: Date | null | undefined): string {
    if (!date) {
      date = new Date();
    }

    return date.toISOString();
  }
}
