<div>
  <div *ngIf="!isDatePickerTabSelected">
    <div class="dropdown-header">
      <div class="title">SNOOZE</div>
    </div>

    <div #itemsWrapper class="dropdown-items-wrapper">
      <!-- Snooze due time -->
      <div
        *ngIf="snoozeDueDate$ | async"
        class="dropdown-item snooze-due top-row"
        [tooltip]="{ content: selectedSnoozeTimeTooltip$ | async, placement: TooltipPlacement.LEFT }"
      >
        <!-- Icon -->
        <svg-comp [src]="laterSvg"> </svg-comp>

        <span>{{ (hasPendingSnooze$ | async) ? 'Snoozed' : 'Snooze due' }}</span>

        <!-- Display Time -->
        <div class="option-time">{{ selectedSnoozeTimeFormatted$ | async }}</div>
      </div>

      <ng-container *ngFor="let option of snoozeDropdownOptions$ | async; index as i">
        <div
          class="dropdown-item {{ option.extraClass }}"
          [class.bg-light-ui-element]="i === currentIndex"
          [class.action-button]="option.uiDiscard"
          (click)="option.callback()"
        >
          <!-- Icon -->
          <svg-comp *ngIf="option.svg" [src]="option.svg"> </svg-comp>

          <!-- Name -->
          <span>{{ option.name }}</span>

          <!-- Display Time -->
          <div *ngIf="option.displayTime" class="option-time">{{ option.displayTime }}</div>

          <!-- Subsection indicator -->
          <svg-comp *ngIf="option.subsection" class="subsection" [src]="dropdownToggleSvg"> </svg-comp>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="isDatePickerTabSelected">
    <!-- Back -->
    <div class="subsection-back" (click)="hideDatePicker()">Back</div>

    <!-- Header -->
    <div class="dropdown-header">
      <div class="title">Select date and time</div>
    </div>

    <!-- Date picker -->
    <date-picker
      [submitButtonText]="'Snooze'"
      [upperLimitDate]="upperLimitDate"
      [initialSelectedTime]="selectedSnoozeTime$ | async"
      (dateSelected)="dateSelected($event)"
    >
    </date-picker>
  </div>
</div>
