import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  BaseFormControlComponent,
  provideControlValueAccessor
} from '@shared/modules/form-controls/base-form-control.component';
import { debounceTime, Observable, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'loop-location-control',
  templateUrl: './location-control.component.html',
  styleUrls: ['./location-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideControlValueAccessor(LocationControlComponent)]
})
export class LocationControlComponent extends BaseFormControlComponent<string> implements OnDestroy, OnInit {
  focused: boolean = false;

  readonly searchChanged$: Subject<string> = new Subject<string>();
  private placesService!: google.maps.places.PlacesService;

  protected readonly locations$: Observable<any> = this.searchChanged$.pipe(
    debounceTime(300),
    switchMap(searchQuery => {
      return this.findPlacesFromQueryObservable({ query: searchQuery }, this.placesService);
    })
  );

  ngOnInit(): void {
    super.ngOnInit();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  override onFocus(): void {
    super.onFocus();
    this.focused = true;
  }

  onModelChange(event): void {
    this.searchChanged$.next(event);
    this.handleModelChange(event);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.searchChanged$.complete();
  }

  protected focusHandler(): void {
    //noop
  }

  findPlacesFromQueryObservable(
    request: google.maps.places.TextSearchRequest,
    placesService: google.maps.places.PlacesService
  ): Observable<any> {
    return new Observable(observer => {
      placesService.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          observer.next(results);
          observer.complete();
        } else {
          observer.error(status);
        }
      });
    });
  }
}
