import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api/api-loop/api.module';
import { EMPTY, Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, tap } from 'rxjs/operators';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Logger } from '@shared/services/logger/logger';
import { BasePushSynchronizationService } from '@shared/synchronization/push-synchronization/base-push-synchronization/base-push-synchronization.service';
import {
  SharedTagFolderModel,
  SharedTagLabelModel,
  SharedTagModel,
  StaticSharedTagPrefix,
} from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { LabelService } from '@shared/services/data/label/label.service';
import * as uuid from 'uuid';
import { FolderService } from '@shared/services/data/folder/folder.service';

@Injectable()
export class FolderPushSynchronizationService extends BasePushSynchronizationService<SharedTagModel> {
  constructor(
    protected _api: ApiService,
    protected _folderService: FolderService,
  ) {
    super();
  }

  get constructorName(): string {
    return 'FolderPushSynchronizationService';
  }

  protected synchronize(forUserEmail: string, folder: SharedTagFolderModel): Observable<any> {
    if (folder.id && folder._ex && folder._ex.deleted) {
      return this.synchronizeDeleteFolder(forUserEmail, folder);
    } else if (!folder.id) {
      return this.synchronizeCreateFolder(forUserEmail, folder);
    } else {
      return this.synchronizeUpdateFolder(forUserEmail, folder);
    }
  }

  protected afterSynchronize(forUserEmail: string, folder: SharedTagFolderModel): Observable<SharedTagFolderModel> {
    return _.isEmpty(folder) ? of(folder) : this._folderService.saveAllAndPublish(forUserEmail, [folder]);
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    folder: SharedTagFolderModel,
  ): Observable<any> {
    // We lost connection and will retry
    if (err.status === 0) {
      return EMPTY;
    }

    Logger.error(err, `PushSync [${forUserEmail}]: Could not sync folder, will not retry statusId: ${folder._id}`);
    return of(folder);
  }

  private synchronizeDeleteFolder(forUserEmail: string, folder: SharedTagFolderModel): Observable<void> {
    return this._api.SharedTagApiService.SharedTag_DeleteSharedTag({ sharedTag: folder }, forUserEmail).pipe(
      map(() => void 0),
    );
  }

  private synchronizeCreateFolder(forUserEmail: string, folder: SharedTagFolderModel): Observable<SharedTagModel> {
    return this._api.SharedTagApiService.SharedTag_CreateSharedTag({ sharedTag: folder }, forUserEmail).pipe(
      map(SharedTagLabelModel.create),
    );
  }

  private synchronizeUpdateFolder(forUserEmail: string, folder: SharedTagFolderModel): Observable<SharedTagModel> {
    return this._api.SharedTagApiService.SharedTag_UpdateSharedTag({ sharedTag: folder }, forUserEmail).pipe(
      map(SharedTagLabelModel.create),
    );
  }
}
