import * as _ from 'lodash';
import { TinyColor } from '@ctrl/tinycolor';

export type ColorType = 'success' | 'warning' | 'danger' | 'dark';

export const COLOR_TYPES: readonly ColorType[] = ['success', 'warning', 'danger', 'dark'] as const;

export const isColorDark = (color: string | TinyColor): boolean =>
  (color instanceof TinyColor ? color : new TinyColor(color)).getLuminance() < 0.15;

export type RgbColorValues = `${number}, ${number}, ${number}`;

export const getRgbValues = (tinyColor: TinyColor): RgbColorValues => {
  const rgbValues = tinyColor.toRgb();
  return `${rgbValues.r}, ${rgbValues.g}, ${rgbValues.b}`;
};

export interface EssentialColorData {
  base: string;
  contrast: string;
}

export class Color implements EssentialColorData {
  base!: string;
  contrast!: string;

  baseTinyColor: TinyColor;
  contrastTinyColor: TinyColor;

  constructor(data: EssentialColorData) {
    _.assign(this, data);
    this.baseTinyColor = new TinyColor(this.base);
    this.contrastTinyColor = new TinyColor(this.contrast);
  }

  get isDark(): boolean {
    return isColorDark(this.baseTinyColor);
  }

  get shade(): string {
    return this.baseTinyColor.shade(10).toHexString();
  }

  get tint(): string {
    return this.baseTinyColor.tint(12).toHexString();
  }

  get lightBase(): string {
    return this.baseTinyColor.tint(80).toHexString();
  }

  get translucentBase(): string {
    return this.baseTinyColor.tint(90).toHexString();
  }

  get lightContrast(): string {
    return this.baseTinyColor.shade(90).toHexString();
  }

  get highlight(): string {
    return (this.isDark ? this.baseTinyColor.tint(20) : this.baseTinyColor.shade(20)).toHexString();
  }

  get baseHex(): string {
    return this.baseTinyColor.toHexString();
  }

  get baseRgb(): string {
    return this.baseTinyColor.toRgbString();
  }

  get baseRgbValues(): RgbColorValues {
    return getRgbValues(this.baseTinyColor);
  }

  get contrastHex(): string {
    return this.contrastTinyColor.toHexString();
  }

  get contrastRgb(): string {
    return this.contrastTinyColor.toRgbString();
  }

  get contrastRgbValues(): RgbColorValues {
    return getRgbValues(this.contrastTinyColor);
  }
}
