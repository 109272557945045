// Collection names
export enum CollectionName {
  // Sync queues
  PushSyncQueue = 'PushSyncQueue',
  RetrySyncQueue = 'RetrySyncQueue',

  // App data collections
  File = 'File',
  Tag = 'Tag',
  SharedTag = 'SharedTag',
  Contact = 'Contact',
  Favorite = 'Favorite',
  AvailabilityStatus = 'AvailabilityStatus',
  Integration = 'Integration',
  ConversationChange = 'ConversationChange',
  UserAvailabilityStatus = 'UserAvailabilityStatus',
  Log = 'Log'
}

// CollectionGroup names
export enum CollectionGroupName {
  Comment,
  Card,
  Conversation
}
