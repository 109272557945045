/* tslint:disable */

/**
 * Possible event types
 */
export type EventType =
  | 'UserRegistered'
  | 'OnboardingComplete'
  | 'CommentCreated'
  | 'CommentUpdated'
  | 'CardCreated'
  | 'CardUpdated'
  | 'CardAuthorizationUpdated'
  | 'TagsCreated'
  | 'TagsUpdated'
  | 'SharedTagsUpdated'
  | 'TokenInvalid'
  | 'UserOnlineStatusChange'
  | 'UserActiveReply'
  | 'UserTyping'
  | 'ContactUpdated'
  | 'ContactCreated'
  | 'ContactDeleted'
  | 'AvatarUpdated'
  | 'UserDeleted'
  | 'GroupDeleted'
  | 'SyncSettingsUpdated'
  | 'OnboardingStepComplete'
  | 'UserSettingsUpdated'
  | 'UserPaymentStatusUpdated'
  | 'HistorySyncStarted'
  | 'HistorySyncEnded'
  | 'WorkspaceUpdated'
  | 'BadgeCountUpdated'
  | 'ConversationUpdated'
  | 'UserAvailabilityStatusUpdated'
  | 'UserAvailabilityStatusDeleted'
  | 'IntegrationsUpdated'
  | 'FolderTags'
  | 'SharedFolderTags';

export module EventType {
  export const USER_REGISTERED: EventType = 'UserRegistered';
  export const ONBOARDING_COMPLETE: EventType = 'OnboardingComplete';
  export const COMMENT_CREATED: EventType = 'CommentCreated';
  export const COMMENT_UPDATED: EventType = 'CommentUpdated';
  export const CARD_CREATED: EventType = 'CardCreated';
  export const CARD_UPDATED: EventType = 'CardUpdated';
  export const CARD_AUTHORIZATION_UPDATED: EventType = 'CardAuthorizationUpdated';
  export const TAGS_CREATED: EventType = 'TagsCreated';
  export const TAGS_UPDATED: EventType = 'TagsUpdated';
  export const SHARED_TAGS_UPDATED: EventType = 'SharedTagsUpdated';
  export const TOKEN_INVALID: EventType = 'TokenInvalid';
  export const USER_ONLINE_STATUS_CHANGE: EventType = 'UserOnlineStatusChange';
  export const USER_ACTIVE_REPLY: EventType = 'UserActiveReply';
  export const USER_TYPING: EventType = 'UserTyping';
  export const CONTACT_UPDATED: EventType = 'ContactUpdated';
  export const CONTACT_CREATED: EventType = 'ContactCreated';
  export const CONTACT_DELETED: EventType = 'ContactDeleted';
  export const AVATAR_UPDATED: EventType = 'AvatarUpdated';
  export const USER_DELETED: EventType = 'UserDeleted';
  export const GROUP_DELETED: EventType = 'GroupDeleted';
  export const SYNC_SETTINGS_UPDATED: EventType = 'SyncSettingsUpdated';
  export const ONBOARDING_STEP_COMPLETE: EventType = 'OnboardingStepComplete';
  export const USER_SETTINGS_UPDATED: EventType = 'UserSettingsUpdated';
  export const USER_PAYMENT_STATUS_UPDATED: EventType = 'UserPaymentStatusUpdated';
  export const HISTORY_SYNC_STARTED: EventType = 'HistorySyncStarted';
  export const HISTORY_SYNC_ENDED: EventType = 'HistorySyncEnded';
  export const WORKSPACE_UPDATED: EventType = 'WorkspaceUpdated';
  export const BADGE_COUNT_UPDATED: EventType = 'BadgeCountUpdated';
  export const CONVERSATION_UPDATED: EventType = 'ConversationUpdated';
  export const USER_AVAILABILITY_STATUS_UPDATED: EventType = 'UserAvailabilityStatusUpdated';
  export const USER_AVAILABILITY_STATUS_DELETED: EventType = 'UserAvailabilityStatusDeleted';
  export const INTEGRATIONS_UPDATED: EventType = 'IntegrationsUpdated';
  export const FOLDER_TAGS: EventType = 'FolderTags';
  export const SHARED_FOLDER_TAGS: EventType = 'SharedFolderTags';

  export function values(): EventType[] {
    return [
      USER_REGISTERED,
      ONBOARDING_COMPLETE,
      COMMENT_CREATED,
      COMMENT_UPDATED,
      CARD_CREATED,
      CARD_UPDATED,
      CARD_AUTHORIZATION_UPDATED,
      TAGS_CREATED,
      TAGS_UPDATED,
      SHARED_TAGS_UPDATED,
      TOKEN_INVALID,
      USER_ONLINE_STATUS_CHANGE,
      USER_ACTIVE_REPLY,
      USER_TYPING,
      CONTACT_UPDATED,
      CONTACT_CREATED,
      CONTACT_DELETED,
      AVATAR_UPDATED,
      USER_DELETED,
      GROUP_DELETED,
      SYNC_SETTINGS_UPDATED,
      ONBOARDING_STEP_COMPLETE,
      USER_SETTINGS_UPDATED,
      USER_PAYMENT_STATUS_UPDATED,
      HISTORY_SYNC_STARTED,
      HISTORY_SYNC_ENDED,
      WORKSPACE_UPDATED,
      BADGE_COUNT_UPDATED,
      CONVERSATION_UPDATED,
      USER_AVAILABILITY_STATUS_UPDATED,
      USER_AVAILABILITY_STATUS_DELETED,
      INTEGRATIONS_UPDATED,
      FOLDER_TAGS,
      SHARED_FOLDER_TAGS
    ];
  }
}
