import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CHANNEL_ICON_COLOR_MAPPER, CHANNEL_ICON_MAPPER } from '@shared/modules/comments/common/constants/channel-icon';

@Component({
  selector: 'loop-conversation-channel-icon',
  templateUrl: './conversation-channel-icon.component.html',
  styleUrls: ['./conversation-channel-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationChannelIconComponent {
  @Input() channelType?: string;
  @Input() width?: number;

  protected readonly channelTypeIconFn: (channelType: string) => string | null = channelType => {
    return CHANNEL_ICON_MAPPER[channelType] ?? null;
  };
  protected readonly channelColorFn: (channelType: string) => string | null = channelType => {
    return CHANNEL_ICON_COLOR_MAPPER[channelType] ?? null;
  };
}
