import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { FileIconComponent } from '@dta/ui/components/common/file-icon/file-icon.component';

@NgModule({
  declarations: [FileIconComponent],
  imports: [CommonModule, GraphicsModule],
  exports: [FileIconComponent],
})
export class FileIconModule {}
