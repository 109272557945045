import { Injectable } from '@angular/core';
import { IPC } from '@dta/shared/communication/ipc-constants';
import { BaseDialogOptions, DialogData, DialogOptions } from '@dta/shared/models/dialog.model';
import {
  BrowserWindow,
  ElectronService,
  ElectronWindowOptions,
  ProcessData,
  Statistics
} from '@shared/services/electron/electron';
import { Observable, of, Subject } from 'rxjs';

@Injectable()
export class ElectronServiceWeb extends ElectronService {
  ipcRenderer = {
    on: () => {},
    removeListener: () => {},
    once: () => {},
    removeAllListeners: (channel: string) => {},
    send: (event: String) => {
      switch (event) {
        case IPC.FOCUS_ON_APP:
          window.focus();
          break;
        default:
          break;
      }
    }
  };

  screen: {
    getDisplayNearestPoint: (point: Electron.Point) => Electron.Display;
    getCursorScreenPoint: () => Electron.Point;
  };

  get isElectronApp(): boolean {
    return false;
  }

  openExternal(url: string, sameWindow?: boolean): Observable<void> {
    window.open(url, sameWindow ? '_self' : '_blank');
    return of(undefined);
  }

  // Methods that depend on this and are included in web should be isolated to DTA only!
  setNativeTheme(isDark: boolean): Observable<void> {
    return of(undefined);
  }
  getBrowserReference(windowId: number): BrowserWindow {
    return {
      loadURL: (..._) => of(undefined),
      focus: (..._) => of(undefined),
      center: (..._) => of(undefined),
      show: (..._) => of(undefined),
      close: (..._) => of(undefined),
      setTitle: (..._) => of(undefined),
      previewFile: (..._) => of(undefined),
      setMaximizable: (..._) => of(undefined),
      setMenu: (..._) => of(undefined),
      setSize: (..._) => of(undefined),
      setResizable: (..._) => of(undefined)
    } as BrowserWindow;
  }

  ///////////////
  // Not needed
  ///////////////
  openDialogWindow(options: BaseDialogOptions, data?: Record<string, any>): Observable<any> {
    throw new Error('Method not implemented.');
  }
  remoteQuitThisWindow(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  replaceMisspelling(text: string): Observable<void> {
    throw new Error('Method not implemented.');
  }
  setSpellchecker(languageIndex: number): Observable<void> {
    throw new Error('Method not implemented.');
  }
  readMailFile(filePath: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  createNewBrowserWindow(
    windowOptions: ElectronWindowOptions,
    eventListeners?: { 'closed'?: Subject<any>; 'page-title-updated'?: Subject<any> }
  ): Observable<BrowserWindow> {
    throw new Error('Method not implemented.');
  }
  getMachineId(): Observable<string> {
    throw new Error('Method not implemented.');
  }
  getMachineIp(): Observable<string> {
    throw new Error('Method not implemented.');
  }
  openHotWindow(options: DialogOptions, data: DialogData): Observable<BrowserWindow> {
    throw new Error('Method not implemented.');
  }
  setWindowIdentifier(identifier: string, windowId: number): Observable<boolean> {
    return of(true);
  }
  tryFocusToReplyPopOut(cardId: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  handleDoubleClick(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  previewFileOnUi(path: string): Observable<void> {
    throw new Error('Method not implemented.');
  }
  showItemInFolder(path: string): Observable<void> {
    throw new Error('Method not implemented.');
  }
  shrinkLoginWindow(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  expandLoginWindow(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  isForE2E(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  getProcessData(): Observable<ProcessData> {
    throw new Error('Method not implemented.');
  }
  openFileInNativeApp(path: string): Observable<void> {
    throw new Error('Method not implemented.');
  }
  getStatistics(): Observable<Statistics> {
    throw new Error('Method not implemented.');
  }
  checkForUpdates({ isDeprecatedVersion }: { isDeprecatedVersion: boolean }): Observable<void> {
    throw new Error('Method not implemented.');
  }
  setSpellCheckerLanguage(languages: string[]): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getSpellCheckerLanguages(): Observable<string[]> {
    throw new Error('Method not implemented.');
  }

  disableSpellChecker(): Observable<void> {
    throw new Error('Method not implemented.');
  }
}
