import { Recipients } from '@dta/shared/communication/recipients.ipc';
import {
  DataCapsuleSharedSubjectScoutRequest,
  DataCapsuleSharedSubjectScoutResponse
} from '@dta/shared/communication/data-capsule.ipc';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SharedSubjectData } from '@shared/services/communication/shared-subjects/shared-subjects-models';

export class Broadcaster {
  private static _currentEndpoint: Recipients | string;
  private static _broadcastService: BroadcastService;

  static get currentEndpoint() {
    if (!Broadcaster._currentEndpoint) {
      throw new Error('Set current endpoint before using broadcast');
    }

    return Broadcaster._currentEndpoint;
  }

  static init(endpoint: Recipients | string, broadcastService: BroadcastService) {
    Broadcaster._currentEndpoint = endpoint;
    Broadcaster._broadcastService = broadcastService;
  }

  static broadcast(subjectName: string, data: SharedSubjectData) {
    if (!this._broadcastService) {
      return;
    }
    this._broadcastService.broadcast(subjectName, data, this._currentEndpoint);
  }

  static handleScoutRequest(request: DataCapsuleSharedSubjectScoutRequest): boolean {
    if (!this._broadcastService) {
      return;
    }
    return this._broadcastService.handleScoutRequest(request);
  }

  static handleScoutResponse(response: DataCapsuleSharedSubjectScoutResponse) {
    if (!this._broadcastService) {
      return;
    }
    this._broadcastService.handleScoutResponse(response);
  }
}

@Injectable()
export abstract class BroadcastService {
  protected static pendingScoutRequests: _.Dictionary<Subject<boolean>> = {};

  abstract broadcast(subjectName: string, data: SharedSubjectData, currentEndpoint: Recipients | string): void;

  abstract handleScoutRequest(request: DataCapsuleSharedSubjectScoutRequest): boolean;

  abstract handleScoutResponse(response: DataCapsuleSharedSubjectScoutResponse): void;
}
