import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtomDirective } from './atom.directive';

@NgModule({
  declarations: [AtomDirective],
  imports: [CommonModule],
  exports: [AtomDirective]
})
export class AtomModule {}
