import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { FileHelper } from '@dta/shared/utils/file.helper';
import { map } from 'rxjs/operators';

export function readFileData(file: File, defaultFilename?: string): Observable<string | undefined> {
  if (!file.name) {
    _.set(file, 'name', defaultFilename);
  }

  return new Observable(obs => {
    return FileHelper.createFileReader(obs).readAsDataURL(file);
  }).pipe(
    map((data: string) => {
      let base64data = _.split(data, ',');
      return base64data[1];
    }),
  );
}
