import * as _ from 'lodash';
import { EMPTY, interval, merge, Observable, Subject, timer } from 'rxjs';
import { catchError, defaultIfEmpty, exhaustMap, filter, mergeMap, tap, throttle } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { SynchronizableModel } from '@shared/models/sync/synchronizable.model';
import { PushSynchronizationService } from '@shared/synchronization/push-synchronization/push-synchronization.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { CommentPushSynchronizationService } from '@shared/synchronization/push-synchronization/comment-push-synchronization/comment-push-synchronization.service';
import { SynchronizationStatusService } from '@shared/synchronization/synchronization-status.service';
import { TemplatePushSynchronizationService } from '@shared/synchronization/push-synchronization/template-push-synchronization/template-push-synchronization.service';
import { TagPushSynchronizationService } from '@shared/synchronization/push-synchronization/tag-push-synchronization/tag-push-synchronization.service';
import { SharedTagPushSynchronizationService } from '@shared/synchronization/push-synchronization/shared-tag-push-synchronization/shared-tag-push-synchronization.service';
import { AgendaPushSynchronizationService } from '@shared/synchronization/push-synchronization/agenda-push-synchronization/agenda-push-synchronization.service';
import { CardPushSynchronizationService } from '@shared/synchronization/push-synchronization/card-push-synchronization/card-push-synchronization.service';
import { PushSyncDaoService } from '@shared/database/dao/push-sync/push-sync-dao.service';
import { Logger } from '@shared/services/logger/logger';
import { LogTag } from '@dta/shared/models/logger.model';
import { PushSyncModel } from '@dta/shared/models-api-loop/push-sync.model';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { AvailabilityStatusPushSynchronizationService } from '@shared/synchronization/push-synchronization/availability-status-push-synchronization/availability-status-push-synchronization.service';
import { SharedTagLabelPushSynchronizationService } from '@shared/synchronization/push-synchronization/shared-tag-label-push-synchronization/shared-tag-label-push-synchronization.service';
import { ConversationActionPushSynchronizationService } from '@shared/synchronization/push-synchronization/conversation-push-synchronization/conversation-action-push-synchronization.service';
import { FolderPushSynchronizationService } from '@shared/synchronization/push-synchronization/folder-push-synchronization/folder-push-synchronization.service';

@Injectable()
export class PushSynchronizationServiceWeb extends PushSynchronizationService implements OnDestroy {
  private sync$: Subject<any> = new Subject();

  constructor(
    protected _userEmail: string,
    protected _status: SynchronizationStatusService,
    protected _synchronizationMiddlewareService: SynchronizationMiddlewareService,
    protected _commentPushSynchronizationService: CommentPushSynchronizationService,
    protected _templatePushSynchronizationService: TemplatePushSynchronizationService,
    protected _tagPushSynchronizationService: TagPushSynchronizationService,
    protected _sharedTagPushSynchronizationService: SharedTagPushSynchronizationService,
    protected _agendaPushSynchronizationService: AgendaPushSynchronizationService,
    protected _cardPushSynchronizationService: CardPushSynchronizationService,
    protected _availabilityStatusSynchronizationService: AvailabilityStatusPushSynchronizationService,
    protected _sharedTagLabelPushSynchronizationService: SharedTagLabelPushSynchronizationService,
    protected _conversationActionPushSynchronizationService: ConversationActionPushSynchronizationService,
    protected _folderPushSynchronizationService: FolderPushSynchronizationService,
    protected _pushSyncDaoService: PushSyncDaoService,
    protected _notificationsService: NotificationsService
  ) {
    super(
      _userEmail,
      _status,
      _commentPushSynchronizationService,
      _templatePushSynchronizationService,
      _tagPushSynchronizationService,
      _sharedTagPushSynchronizationService,
      _agendaPushSynchronizationService,
      _cardPushSynchronizationService,
      _availabilityStatusSynchronizationService,
      _sharedTagLabelPushSynchronizationService,
      _conversationActionPushSynchronizationService,
      _folderPushSynchronizationService,
      _pushSyncDaoService,
      _notificationsService
    );
  }

  get constructorName(): string {
    return 'PushSynchronizationServiceWeb';
  }

  ngOnDestroy() {}

  start() {
    this.active = true;

    this.pushSub?.unsubscribe();
    this.pushSub = merge(this.sync$, timer(5_000, 5_000))
      .pipe(
        /**
         * Emits, then ignores subsequent source values for a duration of interval
         */
        throttle(() => interval(500)),
        /**
         * Sync all models
         */
        exhaustMap(() => this.synchronizeAll()),
        catchError(err => {
          Logger.error(err, `[SYNC] - PushSync [${this._userEmail}]: failed`, LogTag.SYNC);
          this.stop();

          return EMPTY;
        })
      )
      .subscribe();
  }

  private synchronizeAll(): Observable<any> {
    return this._pushSyncDaoService.getAllInQueue(this._userEmail).pipe(
      filter((data: PushSyncModel[]) => !_.isEmpty(data)),
      mergeMap((data: PushSyncModel[]) => this.synchronizeModels(_.castArray(data))),
      defaultIfEmpty([])
    );
  }

  enqueueSynchronization(data: SynchronizableModel | SynchronizableModel[]): Observable<any> {
    return super.enqueueSynchronization(data).pipe(tap(() => this.sync$.next(undefined)));
  }

  clearPushSyncQueue(forUserEmail: string) {
    return this._pushSyncDaoService.clearCollection(forUserEmail);
  }
}
