export enum ToolbarOption {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  formatPainter = 'formatPainter',
  fontFamily = 'fontFamily',
  fontSize = 'fontSize',
  textColor = 'textColor',
  backgroundColor = 'backgroundColor',
  insertLink = 'insertLink',
  formatUL = 'formatUL',
  formatOL = 'formatOL',
  outdent = 'outdent',
  indent = 'indent',
  clearFormatting = 'clearFormatting',
  alignLeft = 'alignLeft',
  alignCenter = 'alignCenter',
  alignRight = 'alignRight',
  alignJustify = 'alignJustify',
  paragraphFormat = 'paragraphFormat',
  paragraphStyle = 'paragraphStyle',
  lineHeight = 'lineHeight',
  quote = 'quote',
  strikeThrough = 'strikeThrough',
  subscript = 'subscript',
  superscript = 'superscript',
  inlineClass = 'inlineClass',
  inlineStyle = 'inlineStyle',
  insertTable = 'insertTable',
  specialCharacters = 'specialCharacters',
  html = 'html'
}
