import * as _ from 'lodash';
import { StaticSharedTagIds, StaticSharedTagPrefix } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import {
  ConversationCollectionParams,
  SearchableViewCollectionParams,
  ViewsFilter
} from '@dta/shared/models/collection.model';
import { FilterEnum, SharedTagBase, TagLabel, TagType } from '@shared/api/api-loop/models';

export class CollectionHelper {
  static getTagsAndSharedTagsForFetch(params: ConversationCollectionParams): {
    includeCardSharedTagIds: string[];
    excludeCardSharedTagIds: string[];
    includeCardTags: string[];
    excludeCardTags: string[];
    includeCommentTags: string[];
    excludeCommentTags: string[];
  } {
    let includeCardSharedTagIds: string[] = [];
    let excludeCardSharedTagIds: string[] = [];
    let includeCardTags: string[] = [];
    let excludeCardTags: string[] = [];
    let includeCommentTags: string[] = [];
    let excludeCommentTags: string[] = [];

    // Unread filter
    if (params.unreadOnly) {
      includeCommentTags.push(TagType.UNREAD);
    }

    // Include tags from filters
    if (params.sharedTags) {
      const sharedTags = params.sharedTags.filter(
        sharedTag => !sharedTag.id.includes(StaticSharedTagPrefix.CLASSIFICATION_PREFIX)
      );
      includeCardSharedTagIds.push(..._.map(sharedTags, (tag: SharedTagBase) => tag.id));
    }

    // Include tags from filters
    if (params.privateLabels) {
      includeCardTags.push(...params.privateLabels.map(label => label.id));
    }

    return {
      includeCardSharedTagIds: includeCardSharedTagIds,
      excludeCardSharedTagIds: excludeCardSharedTagIds,
      includeCardTags: includeCardTags,
      excludeCardTags: excludeCardTags,
      includeCommentTags: includeCommentTags,
      excludeCommentTags: excludeCommentTags
    };
  }
}
