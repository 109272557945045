import { Injectable, NgModule } from '@angular/core';
import { AgendaPushSynchronizationService } from './agenda-push-synchronization/agenda-push-synchronization.service';
import { CardPushSynchronizationService } from './card-push-synchronization/card-push-synchronization.service';
import { CommentPushSynchronizationService } from './comment-push-synchronization/comment-push-synchronization.service';
import { SharedTagPushSynchronizationService } from './shared-tag-push-synchronization/shared-tag-push-synchronization.service';
import { TagPushSynchronizationService } from './tag-push-synchronization/tag-push-synchronization.service';
import { TemplatePushSynchronizationService } from './template-push-synchronization/template-push-synchronization.service';
import { AvailabilityStatusPushSynchronizationService } from '@shared/synchronization/push-synchronization/availability-status-push-synchronization/availability-status-push-synchronization.service';
import { SharedTagLabelPushSynchronizationService } from '@shared/synchronization/push-synchronization/shared-tag-label-push-synchronization/shared-tag-label-push-synchronization.service';
import { ConversationActionPushSynchronizationService } from '@shared/synchronization/push-synchronization/conversation-push-synchronization/conversation-action-push-synchronization.service';
import { FolderPushSynchronizationService } from '@shared/synchronization/push-synchronization/folder-push-synchronization/folder-push-synchronization.service';

@Injectable()
export class PushSynchronizationModuleService {
  constructor(
    public CommentPushSynchronizationService: CommentPushSynchronizationService,
    public TemplatePushSynchronizationService: TemplatePushSynchronizationService,
    public TagPushSynchronizationService: TagPushSynchronizationService,
    public SharedTagPushSynchronizationService: SharedTagPushSynchronizationService,
    public AgendaPushSynchronizationService: AgendaPushSynchronizationService,
    public CardPushSynchronizationService: CardPushSynchronizationService,
    public AvailabilityStatusPushSynchronizationService: AvailabilityStatusPushSynchronizationService,
    public SharedTagLabelPushSynchronizationService: SharedTagLabelPushSynchronizationService,
    public ConversationActionPushSynchronizationService: ConversationActionPushSynchronizationService,
    public FolderPushSynchronizationService: FolderPushSynchronizationService
  ) {}
}

@NgModule({
  providers: [
    PushSynchronizationModuleService,
    CommentPushSynchronizationService,
    TemplatePushSynchronizationService,
    TagPushSynchronizationService,
    SharedTagPushSynchronizationService,
    AgendaPushSynchronizationService,
    CardPushSynchronizationService,
    AvailabilityStatusPushSynchronizationService,
    SharedTagLabelPushSynchronizationService,
    ConversationActionPushSynchronizationService,
    FolderPushSynchronizationService
  ]
})
export class PushSynchronizationModule {}
