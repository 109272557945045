import * as _ from 'lodash';
import { Directive } from '@angular/core';
import { TagModel, TagViewData } from '@dta/shared/models-api-loop/tag.model';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import {
  ListOfGroupSharedFolderTags,
  ListOfResourcesOfListOfGroupSharedFolderTags,
  ListOfResourcesOfSharedTagFolder,
  SharedTagFolder,
  Tag
} from '@shared/api/api-loop/models';
import { SharedTagApiService } from '@shared/api/api-loop/services';
import { concat, EMPTY, from, Observable, of, switchMap, tap } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { FolderServiceI } from './folder.service.interface';
import { SharedTagFolderModel, SharedTagModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { SharedTagService } from '@shared/services/data/shared-tags/shared-tags.service';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { BaseService } from '@shared/services/data/base/base.service';

@Directive()
export class FolderService extends BaseService implements FolderServiceI {
  constructor(
    protected _sharedTagApiService: SharedTagApiService,
    protected _sharedTagService: SharedTagService,
    protected _syncMiddleware: SynchronizationMiddlewareService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'FolderService';
  }

  syncSharedFolders(forUserEmail: string): Observable<SharedTagFolderModel[]> {
    return this.fetchAllFolders(forUserEmail, 1024);
  }

  fetchAndSaveFoldersForGroup(forUserEmail: string, groupId: string): Observable<SharedTagModel[]> {
    return this.fetchSharedFoldersByGroup(forUserEmail, groupId).pipe(
      switchMap((folders: SharedTagFolderModel[]) => {
        return this._sharedTagService.saveAllAndPublish(forUserEmail, folders);
      })
    );
  }

  findAllFolders(forUserEmail: string): Observable<SharedTagFolderModel[]> {
    return this._sharedTagService.findAllFolders(forUserEmail);
  }

  findFoldersForContext(forUserEmail: string, contextId: string): Observable<SharedTagFolderModel[]> {
    return this._sharedTagService.findFoldersForContext(forUserEmail, contextId);
  }

  createOrUpdateFolder(forUserEmail: string, folder: SharedTagFolderModel): Observable<SharedTagFolderModel[]> {
    return of(undefined).pipe(
      tap(() => {
        this.enqueuePushSynchronization(forUserEmail, folder);
      }),
      mergeMap(() => {
        return this._sharedTagService.saveAllAndPublish(forUserEmail, [folder]) as Observable<SharedTagFolderModel[]>;
      })
    );
  }

  deleteFolders(forUserEmail: string, folders: SharedTagFolderModel[]): Observable<SharedTagFolderModel[]> {
    return this._sharedTagService.removeAll(forUserEmail, folders).pipe(
      tap(() => {
        folders = folders.map(folder => {
          folder._ex = { deleted: true };
          return folder;
        });

        this.enqueuePushSynchronization(forUserEmail, folders);
        PublisherService.publishEvent(forUserEmail, folders, PublishEventType.Remove);
      })
    );
  }

  private fetchAllFolders(forUserEmail: string, size: number = 1024): Observable<SharedTagFolderModel[]> {
    let watch = new StopWatch(this.constructorName + '.fetchFolders', ProcessType.SERVICE, forUserEmail);

    watch.log('Starting folder sync');
    return this._sharedTagApiService
      .SharedTag_GetListOfGroupsSharedFolderTags(
        {
          size: size
        },
        forUserEmail
      )
      .pipe(
        mergeMap((response: ListOfResourcesOfListOfGroupSharedFolderTags) => {
          watch.log('Processing response of size: ' + response.size);
          return this.processListOfGroupsSharedFolderTags(forUserEmail, response, size);
        })
      );
  }

  private processListOfGroupsSharedFolderTags(
    forUserEmail: string,
    response: ListOfResourcesOfListOfGroupSharedFolderTags,
    fetchedSize: number
  ): Observable<SharedTagFolderModel[]> {
    let fetchByGroup: string[] = [];

    return from(response.resources).pipe(
      mergeMap((groupFolderTags: ListOfGroupSharedFolderTags) => {
        // Fetch by group id size of folders is greater than fetched size
        if (groupFolderTags.tags.totalSize > fetchedSize) {
          fetchByGroup.push(groupFolderTags.parent.id);
          return of([]);
        }

        return this.prepareSharedFolderModels(groupFolderTags.tags.resources, groupFolderTags.systemTags.resources);
      }),
      toArray(),
      map((folders: SharedTagFolderModel[][]) => {
        return _.flatten(folders);
      }),
      /**
       * Fetch by Folder and Save/Publish
       */
      mergeMap((tags: SharedTagFolderModel[]) => {
        if (fetchByGroup.length > 0) {
          return from(fetchByGroup).pipe(
            mergeMap((groupId: string) => {
              return this.fetchSharedFoldersByGroup(forUserEmail, groupId);
            }),
            toArray(),
            map((folders: SharedTagFolderModel[][]) => {
              return _.flatten(folders);
            }),
            mergeMap((_tags: SharedTagFolderModel[]) => {
              return this._sharedTagService.saveAll(forUserEmail, [..._tags, ...tags]);
            })
          );
        }

        return this._sharedTagService.saveAllAndPublish(forUserEmail, tags);
      })
    ) as Observable<SharedTagFolderModel[]>;
  }

  private fetchSharedFoldersByGroup(forUserEmail: string, groupId: string, size: number = 1024, offset: number = 0) {
    return this._sharedTagApiService
      .SharedTag_GetListOfSharedFolderTags2(
        {
          groupId: groupId
        },
        forUserEmail
      )
      .pipe(
        mergeMap((response: ListOfGroupSharedFolderTags) => {
          return this.prepareSharedFolderModels(response.tags?.resources, response.systemTags?.resources);
        })
      );
  }

  private prepareSharedFolderModels(
    folders: SharedTagFolder[],
    systemFolders?: SharedTagFolder[]
  ): Observable<SharedTagFolderModel[]> {
    return of(undefined).pipe(
      map(() => {
        return _.map((systemFolders || []).concat(folders), folder => {
          return new SharedTagFolderModel(folder);
        });
      })
    );
  }

  private static getDisplayName(name: string = '', indentationLevel = 0) {
    let splitBoardNames = name.split('/');
    if (!splitBoardNames.length || indentationLevel === 0) {
      return name;
    }
    return _.last(splitBoardNames);
  }

  static decorateFolders(folders: TagModel[]): TagModel[] {
    for (let folder of folders) {
      let folderName = folder.name;
      folder._ui = <TagViewData>{
        indentationLevel: 0
      };

      while (folderName.indexOf('/') > -1) {
        folderName = folderName.substr(0, folderName.lastIndexOf('/'));

        // checking for parent (if true -> adding one indentation level)
        if (_.find(folders, (folderItem: Tag) => folderItem.name === folderName)) {
          folder._ui.indentationLevel++;
        }
      }
      folder._ui.displayName = this.getDisplayName(folder.name, folder._ui.indentationLevel);
    }
    return folders;
  }

  // Dao wrappers
  removeAllAndPublish(forUserEmail: string, models: SharedTagModel[]): Observable<any> {
    return this._sharedTagService.removeAll(forUserEmail, models).pipe(
      tap(() => {
        PublisherService.publishEvent(forUserEmail, models, PublishEventType.Remove);
      })
    );
  }

  removeCollection(forUserEmail: string): Observable<any> {
    return this._sharedTagService.removeCollection(forUserEmail);
  }

  removeAll(forUserEmail: string, models: SharedTagModel[]): Observable<any> {
    return this._sharedTagService.removeAll(forUserEmail, models);
  }

  saveAll(forUserEmail: string, models: SharedTagModel[]): Observable<any> {
    return this._sharedTagService.saveAll(forUserEmail, models);
  }

  saveAllAndPublish(forUserEmail: string, models: SharedTagModel[]): Observable<any> {
    return this._sharedTagService.saveAllAndPublish(forUserEmail, models);
  }
}
