import * as _ from 'lodash';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, merge, Observable, of, Subscription, throwError, timer } from 'rxjs';
import { CONSTANTS } from '@shared/models/constants/constants';
import { ProcessType, StopWatch } from '../../../dta/shared/utils/stop-watch';
import { Logger } from '@shared/services/logger/logger';
import {
  catchError,
  concatMap,
  debounceTime,
  defaultIfEmpty,
  delay,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  repeat,
  retryWhen,
  startWith,
  switchMap,
  tap,
  timeout,
} from 'rxjs/operators';
import { ElectronService } from '@shared/services/electron/electron';
import { IPC } from '../../../dta/shared/communication/ipc-constants';
import { HttpResponseEventType } from '../../../dta/shared/models/http-events.model';
import { SharedSubjects } from '../communication/shared-subjects/shared-subjects';
import {
  ConnectionStatus,
  HttpResponseEventData,
  PowerState,
  PowerStateStatus,
} from '../communication/shared-subjects/shared-subjects-models';
import { AutoUnsubscribe } from '../../../dta/shared/utils/subscriptions/auto-unsubscribe';
import { LogLevel, LogTag } from '../../../dta/shared/models/logger.model';
import { SynchronizationManagerService } from '@shared/synchronization/synchronization-manager.service';
import { HttpEventService } from '@shared/interceptors/http-event.service';

/**
 * Meet Fido. Fido is a good dog.
 *
 * Fido is making sure that after connection drops the synchronization services are turned off.
 * And after connection to server resumes, Fido restarts the synchronization services.
 */

@AutoUnsubscribe()
@Injectable()
export class WatchdogService implements OnDestroy {
  private _isConnectionActive: boolean = true;
  private _connectionCheckTimeoutMs: number = 1000;
  private _connectionStopWatch: StopWatch;

  private _syncActive: boolean = false;
  private _syncManagerServices: Set<SynchronizationManagerService> = new Set();

  //////////////////
  // Subscriptions
  //////////////////
  private _watchdogSub: Subscription;
  private _powerStateChangeSub: Subscription;

  constructor(
    private _http: HttpClient,
    private _httpResponseEventService: HttpEventService,
    private _electronService: ElectronService,
  ) {
    if (localStorage.getItem('app_disabled') === 'true') {
      return;
    }

    this.subscribeToPowerStateChange();
    this.start();
  }

  get connectionStatus$(): Observable<ConnectionStatus> {
    let connectionStatus = new ConnectionStatus();
    connectionStatus.connectionActive = this.isConnectionActive;

    return SharedSubjects._connectionStatus$.asObservable().pipe(startWith(connectionStatus));
  }

  getIsConnectionActive(): Observable<boolean> {
    return of(this.isConnectionActive);
  }

  get isConnectionActive(): boolean {
    return this._isConnectionActive;
  }

  get constructorName(): string {
    return 'WatchdogService';
  }

  ngOnDestroy() {}

  /**
   * Starts watching for connection status changes
   * Starts sync services when we're online
   * Stops sync services when we're offline
   * Restarts sync services when any of them fail
   */
  private start() {
    // CONNECTION STATUS
    let connectionStatus$ = this.watchConnectionStatus().pipe(
      tap((connectionStatus: boolean) => {
        Logger.customLog(
          'WatchdogService start() connectionStatus$: connectionStatus ' +
            connectionStatus +
            ' _syncActive: ' +
            this._syncActive,
          LogLevel.INFO,
        );

        // start/stop sync-services when connection status changes
        if (connectionStatus === true && this._syncActive === false) {
          this.startAll();
        } else if (connectionStatus === false && this._syncActive === true) {
          this.stopAll();
        }
      }),
      catchError(err => {
        Logger.error(err, 'WatchdogService: A fatal error has occurred in WatchdogService.watchConnectionStatus');
        return throwError(err);
      }),
    );

    // SYNC-SERVICES STATUS
    let servicesStatus$ = of(undefined).pipe(
      // Only check for services status when sync services are started
      filter(() => this._syncActive),
      tap(() => this.restartFailedServices()),
      defaultIfEmpty(undefined),
      delay(CONSTANTS.WATCHDOG_DETECT_FAILED_SYNCS_INTERVAL),
      repeat(),
    );

    this._watchdogSub && this._watchdogSub.unsubscribe();
    this._watchdogSub = merge(connectionStatus$, servicesStatus$).subscribe();

    Logger.customLog('WatchdogService: started', LogLevel.INFO);
  }

  /**
   * Stops sync services and stops listening for connection status changes
   */
  private stop() {
    this._watchdogSub && this._watchdogSub.unsubscribe();
    this.stopAll();

    Logger.customLog('WatchdogService: stopped', LogLevel.INFO);
  }

  /**
   * Restarts sync services when any of them fail (per user/sync-service)
   */
  private restartFailedServices() {
    this._syncManagerServices.forEach(syncManagerService => {
      let isSyncActive: boolean = syncManagerService.statusSync();

      if (isSyncActive === false) {
        syncManagerService.startSync();
      }
    });
  }

  /**
   * Register synchronization manager service
   */
  registerServices(syncManagerService: SynchronizationManagerService) {
    this._syncManagerServices.add(syncManagerService);
  }

  unregisterServices(syncManagerService: SynchronizationManagerService) {
    this._syncManagerServices.delete(syncManagerService);
  }

  private startAll() {
    this._syncManagerServices.forEach(syncManagerService => {
      syncManagerService.startSync();
    });
    this._syncActive = true;
    Logger.customLog('WatchdogService: Sync services started', LogLevel.INFO);
  }

  private stopAll() {
    this._syncActive = false;
    this._isConnectionActive = false;
    this.publishConnectionStatus(this._isConnectionActive);

    this._syncManagerServices.forEach(syncManagerService => {
      syncManagerService.stopSync();
    });
    Logger.customLog('WatchdogService: Sync services stopped', LogLevel.INFO);
  }

  private publishConnectionStatus(connectionStatus: boolean) {
    Logger.customLog('WatchdogService [Connection status]: ' + connectionStatus ? 'ONLINE' : 'OFFLINE', LogLevel.INFO);

    // Send to dialog window via IPC
    this._electronService.ipcRenderer.send(IPC.DIALOG_PROXY, {
      event: 'watchdogConnectionStatus',
      data: { connectionStatus: connectionStatus },
    });

    // Create data package
    let connectionStatusEvent = new ConnectionStatus();
    connectionStatusEvent.connectionActive = connectionStatus;

    // Send event
    SharedSubjects._connectionStatus$.next(connectionStatusEvent);

    // Log
    if (connectionStatus) {
      this._connectionStopWatch?.log('Connection status: Back ONLINE', true);
    } else {
      this._connectionStopWatch = new StopWatch(
        this.constructorName + '.publishConnectionStatus',
        ProcessType.SERVICE,
        'all',
      );
    }
  }

  /**
   * Verifies whether API is accessible
   * If retry set to true it retries to connect with API indefinitely with exponential backoff
   */
  private isApiAlive(retry: boolean = false): Observable<boolean> {
    Logger.customLog('WatchdogService isApiAlive() called with retry: ' + retry, LogLevel.INFO);

    let start = moment();
    let query = '/api?t=' + new Date().getTime();
    return this._http.get(CONSTANTS.LOOP_API_ROOT_URI + query).pipe(
      timeout(this._connectionCheckTimeoutMs),
      map(() => true),
      catchError(err => {
        Logger.customLog('WatchdogService isApiAlive() got error status: ' + err.status, LogLevel.INFO);

        if (err.status === 503 && localStorage.getItem('app_disabled') === 'true') {
          this.stop();
          return of(false);
        }

        return retry ? throwError(err) : of(false);
      }),
      retryWhen((handler: Observable<any>) => {
        return handler.pipe(
          flatMap(() => {
            let elapsed = moment().diff(start, 'minutes');
            let multiplier = 1;

            if (elapsed > 1 && elapsed < 5) {
              multiplier = 2;
            } else if (elapsed > 5 && elapsed < 30) {
              multiplier = 5;
            } else if (elapsed > 30) {
              multiplier = 10;
            }

            Logger.customLog('WatchdogService isApiAlive() retry multiplier: ' + multiplier, LogLevel.INFO);

            return timer(multiplier * CONSTANTS.WATCHDOG_CONNECTION_RETRY);
          }),
        );
      }),
      tap((connection: boolean) => {
        Logger.customLog('WatchdogService [Connection status - isApiAlive]: ' + connection, LogLevel.INFO);
      }),
    );
  }

  /**
   * Watch for connection status changes and handle false positives by doing a request to the API
   * @returns {Observable<boolean>}
   */
  private watchConnectionStatus(): Observable<boolean> {
    // observe whether an API request fails with no-connection error (successful requests are not observed)
    let httpStatus$ = this._httpResponseEventService.events$.pipe(
      filter((event: HttpResponseEventData) => {
        return event.httpResponseEvent.type === HttpResponseEventType.offline;
      }),
      map(() => {
        // connectionsStatus is false <=> offline
        return false;
      }),
      tap((connection: boolean) => {
        Logger.customLog('WatchdogService [Connection status - httpStatus$]: ' + connection, LogLevel.LOG);
      }),
    );

    // navigator.offline status and events work just fine in Chrome (especially when it is set to false)
    // but when status of onLine is true, it needs to be verified as it might return false positives
    let navigatorOnLine$ = merge(fromEvent(window, 'offline'), fromEvent(window, 'online')).pipe(
      map(() => {
        return navigator.onLine;
      }),
      startWith(navigator.onLine),
      tap((connection: boolean) => {
        Logger.customLog('WatchdogService [Connection status - navigatorOnLine$]: ' + connection, LogLevel.LOG);
      }),
    );

    return merge(navigatorOnLine$, httpStatus$).pipe(
      debounceTime(1500),
      concatMap((connectionStatus: boolean) => {
        // ConnectionStatus = true is verified by doing an API request
        return connectionStatus ? this.isApiAlive() : of(false);
      }),
      //
      // Cancel previous isApiAlive requests so they don't pile up (especially those that keep retrying)
      //
      switchMap(connectionStatus => {
        Logger.customLog(
          'WatchdogService watchConnectionStatus() connectionStatus: ' + connectionStatus,
          LogLevel.INFO,
        );

        // Always propagate connection status through the system
        let obs = [of(connectionStatus)];

        // Always retry requests for API connection
        if (connectionStatus === false) {
          obs.push(this.isApiAlive(true));
        }

        return merge(...obs);
      }),
      debounceTime(500),
      distinctUntilChanged(),
      tap((connectionStatus: boolean) => {
        Logger.customLog(
          'WatchdogService watchConnectionStatus() tap->connectionStatus: ' + connectionStatus,
          LogLevel.INFO,
        );
        this._isConnectionActive = connectionStatus;
        this.publishConnectionStatus(connectionStatus);
      }),
    );
  }

  private initPowerState$() {
    this._electronService.ipcRenderer.on('power-state-change', (event, data) => {
      let state: PowerStateStatus = new PowerStateStatus();

      switch (data.state) {
        case 'suspend':
          state.powerState = PowerState.Suspend;
          Logger.customLog('WatchdogService power change to: suspend', LogLevel.INFO, LogTag.POWER_STATE_CHANGE);
          break;
        case 'resume':
          state.powerState = PowerState.Resume;
          Logger.customLog('WatchdogService power change to: resume', LogLevel.INFO, LogTag.POWER_STATE_CHANGE);
          break;
        case 'on-ac':
          state.powerState = PowerState.OnAc;
          Logger.customLog('WatchdogService power change to: on-ac', LogLevel.INFO, LogTag.POWER_STATE_CHANGE);
          break;
        case 'on-battery':
          state.powerState = PowerState.OnBattery;
          Logger.customLog('WatchdogService power change to: on-battery', LogLevel.INFO, LogTag.POWER_STATE_CHANGE);
          break;
        case 'shutdown':
          state.powerState = PowerState.Shutdown;
          Logger.customLog('WatchdogService power change to: shutdown', LogLevel.INFO, LogTag.POWER_STATE_CHANGE);
          break;
        case 'lock-screen':
          state.powerState = PowerState.LockScreen;
          Logger.customLog('WatchdogService power change to: lock-screen', LogLevel.INFO, LogTag.POWER_STATE_CHANGE);
          break;
        case 'unlock-screen':
          state.powerState = PowerState.UnlockScreen;
          Logger.customLog('WatchdogService power change to: unlock-screen', LogLevel.INFO, LogTag.POWER_STATE_CHANGE);
          break;
        default:
          Logger.customLog(
            'WatchdogService [powerState$]: Unsupported power state: ' + data.state,
            LogLevel.ERROR,
            LogTag.POWER_STATE_CHANGE,
          );
      }

      SharedSubjects._powerState$.next(state);
    });
  }

  /**
   * When system is going to sleep we stop watchdog service
   * When system wakes up we start the watchdog service again
   */
  private subscribeToPowerStateChange() {
    this.initPowerState$();

    this._powerStateChangeSub && this._powerStateChangeSub.unsubscribe();
    this._powerStateChangeSub = SharedSubjects._powerState$
      .pipe(
        filter((state: PowerStateStatus) => {
          return _.includes([PowerState.Suspend, PowerState.Shutdown, PowerState.Resume], state.powerState);
        }),
        map((state: PowerStateStatus) => {
          return state.powerState === PowerState.Resume;
        }),
        tap((isAwake: boolean) => {
          Logger.customLog('WatchdogService isAwake: ' + isAwake, LogLevel.INFO);

          isAwake ? this.start() : this.stop();
        }),
      )
      .subscribe();
  }
}
