<div class="confirm-modal">
  <modal
    class="confirm-modal-inner in-front"
    [footer]="true"
    (onClose)="onModalClose()"
    [isFullscreen]="true"
    #confirmModal
  >
    <!-- Content -->
    <modal-content *ngIf="confirmModal">
      <!-- Close button -->
      <div *ngIf="closeButton" class="close-button">
        <svg-comp [src]="closeMediumSvg" (click)="cancel()"> </svg-comp>
      </div>

      <h1 class="title">{{ title }}</h1>
      <div class="text">{{ text }}</div>
    </modal-content>

    <!-- Fotter -->
    <modal-footer *ngIf="confirmModal">
      <footer>
        <div class="buttons">
          <button class="cancel-button" (click)="cancel()" *ngIf="!noCancelButton">Cancel</button>
          <button class="confirm-button" [class.red]="confirmButtonDanger" (click)="confirm()">
            {{ confirmButtonText }}
          </button>
        </div>
      </footer>
    </modal-footer>
  </modal>
</div>
