/* tslint:disable */

/**
 * Sort by enum.
 */
export type LoginPurpose = 'Login' | 'SetSecret';

export module LoginPurpose {
  export const LOGIN: LoginPurpose = 'Login';
  export const SET_SECRET: LoginPurpose = 'SetSecret';

  export function values(): LoginPurpose[] {
    return [LOGIN, SET_SECRET];
  }
}
