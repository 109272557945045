export enum BatchActions {
  STAR = 'STARRED',
  UNSTAR = 'STARRED',

  TRASH = 'DELETED',
  UNTRASH = 'DELETED',

  ARCHIVE = 'ARCHIVE',
  UNARCHIVE = 'ARCHIVE',

  MARKREAD = 'UNREAD',
  MARKUNREAD = 'UNREAD',

  FOLLOW = 'FOLLOW',
  UNFOLLOW = 'UNFOLLOW',

  FOLDER = 'FOLDER',
  RESOLVE = 'RESOLVE',
}
