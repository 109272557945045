import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { DatabaseService } from '@shared/database/database.service';

@Injectable()
export abstract class BaseDaoService<T extends BaseModel, B extends ResourceBase> {
  constructor(protected _databaseFactory: DatabaseFactory) {}

  abstract get constructorName(): string;

  abstract saveAll(forUserEmail: string, models: T[]): Observable<T[]>;
  abstract removeCollection(forUserEmail: string): Observable<any>;
  abstract removeAll(forUserEmail: string, models: T[]): Observable<any>;
  abstract removeById(forUserEmail: string, id: string): Observable<any>;
  abstract removeByIds(forUserEmail: string, ids: string[]): Observable<any>;
  abstract findById(forUserEmail: string, id: string): Observable<T>;
  abstract findByIds(forUserEmail: string, ids: string[]): Observable<T[]>;
  abstract findBaseByIds(forUserEmail: string, entities: B[]): Observable<B[]>;
  abstract findSyncedByIds(forUserEmail: string, entities: B[]): Observable<T[]>;

  protected abstract toModel(doc: B): T;

  protected toModels(docs: B[]): T[] {
    return _.map(docs, doc => this.toModel(doc));
  }

  protected populate(forUserId: string, docs: T[]): Observable<T[]> {
    return of(docs);
  }

  protected doBeforeSave(forUserEmail: string, models: T[]): Observable<T[]> {
    return of(models);
  }

  protected db(forUserEmail: string): Observable<DatabaseService> {
    // Make sure forUserEmail is set
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil.');
    }

    return this._databaseFactory.whenDbInit(forUserEmail).pipe(
      mergeMap(() => {
        // Get database
        let db: DatabaseService = this._databaseFactory.forUser(forUserEmail);

        if (!db) {
          throw new Error(`No database instance found for forUserEmail=${forUserEmail}`);
        }

        return of(db);
      }),
    );
  }

  save(forUserEmail: string, model: T): Observable<T> {
    if (_.isEmpty(model)) {
      return of(model);
    }

    return this.saveAll(forUserEmail, [model]).pipe(map((models: T[]) => _.first(models)));
  }

  remove(forUserEmail: string, model: T): Observable<any> {
    return this.removeById(forUserEmail, model._id);
  }

  protected getIdsFromEntities(resources: B[]): string[] {
    return _.compact(_.map(resources, resource => resource.id));
  }
}

export interface PagingParams {
  offset: number;
  size: number;
}
