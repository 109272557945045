<div class="special-empty">
  <!-- Title -->
  <div class="title">A single app for all your communications</div>

  <!-- Illustration -->
  <svg-comp [src]="illustrationSvg"> </svg-comp>

  <!-- Features -->
  <div class="check-marks">
    <!-- Left column -->
    <div>
      <ng-template
        ngFor
        let-item
        [ngForOf]="[
                'Chat with multiple teammates at the same time', 
                '@mention the team name and to share an email collaborate with the whole team'
            ]"
      >
        <div class="item">
          <svg-comp class="check-mark-round" [src]="checkmarkSvg"></svg-comp>
          <span>{{item}}</span>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Cta button -->
  <div class="cta" sleepClickEvent (click)="ctaClick()">Create a team</div>

  <a
    href="https://help.intheloop.io/hc/en-gb/articles/360038720154-How-to-create-a-team"
    target="_blank"
    rel="noopener noreferrer"
  >
    Learn more
  </a>
</div>
