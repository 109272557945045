import { Pipe, PipeTransform } from '@angular/core';
import { Attendee } from '@shared/api/api-loop/models/attendee';

@Pipe({
  name: 'organizer',
})
export class OrganizerPipe implements PipeTransform {
  transform(attendees: Attendee[]): Attendee | null {
    return attendees.find(attendee => attendee.status === 'Organizer');
  }
}
