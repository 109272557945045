import { Injectable } from '@angular/core';
import { ProcessType, StopWatch } from '../../../../../dta/shared/utils/stop-watch';
import { FetchRequestAgendaList, FetchResult } from '../../../../../dta/shared/models/collection.model';
import { from, Observable, of } from 'rxjs';
import { filter, map, mergeMap, toArray } from 'rxjs/operators';
import {
  CardAppointmentModel,
  CardBaseModel,
  CardModel,
} from '../../../../../dta/shared/models-api-loop/conversation-card/card/card.model';
import { CardApiService } from '@shared/api/api-loop/services';
import { ListOfResourcesOfCardAppointment } from '@shared/api/api-loop/models';
import { CardService } from '@shared/services/data/card/card.service';
import { StateUpdates } from '../../../../../dta/shared/models/state-updates';
import { BaseCollectionService } from '@shared/services/data/collection/base-collection/base-collection.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { ContactService } from '@shared/services/data/contact/contact.service';

@Injectable()
export class AgendaCollectionService extends BaseCollectionService {
  constructor(
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
    private _cardApiService: CardApiService,
  ) {
    super(_commentService, _cardService, _contactService);
  }

  get constructorName(): string {
    return 'AgendaCollectionService';
  }

  ///////////////
  // FETCH CARDS
  ///////////////
  fetchAgendaItems(forUserEmail: string, fetchRequest: FetchRequestAgendaList): Observable<FetchResult> {
    let watch = new StopWatch(this.constructorName + '.fetchCards', ProcessType.SERVICE, forUserEmail);
    let stateUpdates = new StateUpdates();

    let dataLength: number = 0;

    return of(undefined).pipe(
      /**
       * Fetch agenda items
       */
      mergeMap(() => {
        watch.log('Card_GetAgendaList');
        return this._cardApiService.Card_GetAgendaList(fetchRequest.apiParams, forUserEmail);
      }),
      mergeMap((response: ListOfResourcesOfCardAppointment) => {
        return from(response.resources);
      }),
      map(CardAppointmentModel.create),
      toArray(),
      /**
       * Save and publish all card and comment updates
       */
      mergeMap(cards => {
        stateUpdates.add(cards);
        watch.log('saveAndPublishCardsAndComments');
        return this.saveAndPublishCardsAndComments(forUserEmail, stateUpdates, watch);
      }),
      /**
       * Return
       */
      map(() => {
        watch.log('done');

        let fetchResult: FetchResult = {
          offsetHistoryId: '',
          dataLength: dataLength,
          hasData: dataLength > 0,
        };

        return fetchResult;
      }),
    );
  }
}
