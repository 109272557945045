import { Injectable } from '@angular/core';
import { BaseObservableCacheService } from '@shared/cache/base-observable-cache.service';
import { LRUCache } from 'lru-cache';
import { Observable } from 'rxjs';

@Injectable()
export class FileApiCacheService extends BaseObservableCacheService<string> {
  protected readonly cacheStorage: LRUCache<string, Observable<any>> = this.storageProviderService.provideStorage(
    'fileApiCache',
    300,
    null,
  );

  getOrStoreObservable(hash: string, observable$: Observable<any>, expiration: number = 600000): Observable<any> {
    if (!this.cacheStorage.has(hash)) {
      this.storeObservable(this.cacheStorage, hash, observable$, expiration);
    }

    return this.cacheStorage.get(hash);
  }
}
