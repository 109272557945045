/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { CardAppointment } from '../models/card-appointment';
import { Response } from '../models/response';
import { ListOfResourcesOfCardAppointment } from '../models/list-of-resources-of-card-appointment';
import { ListOfResourcesOfAgendaSettings } from '../models/list-of-resources-of-agenda-settings';
import { AppointmentResponse } from '../models/appointment-response';

@Injectable()
export class AppointmentApiService extends BaseService {
  /**
   * @param params The `AppointmentApiService.Appointment_GetParams` containing the following parameters:
   *
   * - `id`: Card appointment id
   *
   * - `includeSignedLinks`: Attachment includes signed links
   *
   * - `htmlFormat`: HTML format of comment body
   *
   * - `authorizationCardId`: Authorization card id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Appointment card
   */
  Appointment_GetResponse(
    params: AppointmentApiService.Appointment_GetParams,
    forUserEmail: string
  ): Observable<HttpResponse<CardAppointment>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/appointment/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Appointment_Get');

    // body, headers and query params
    if (params.includeSignedLinks != null)
      __params = __params.set('includeSignedLinks', params.includeSignedLinks.toString());
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.authorizationCardId != null)
      __params = __params.set('authorizationCardId', params.authorizationCardId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardAppointment;

          body = <CardAppointment>response.body;

          return <HttpResponse<CardAppointment>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AppointmentApiService.Appointment_GetParams` containing the following parameters:
   *
   * - `id`: Card appointment id
   *
   * - `includeSignedLinks`: Attachment includes signed links
   *
   * - `htmlFormat`: HTML format of comment body
   *
   * - `authorizationCardId`: Authorization card id
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   *
   * @return Appointment card
   */
  Appointment_Get(
    params: AppointmentApiService.Appointment_GetParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardAppointment> {
    const observable$ = this.Appointment_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/appointment/${params.id}`;
      const url = this.rootUrl + `/api/v1/appointment/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AppointmentApiService.Appointment_DeleteCardAppointmentParams` containing the following parameters:
   *
   * - `id`: Appointment id.
   *
   * - `contextId`: Team ID is used to verify access and select Inbox account as creator.
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Appointment_DeleteCardAppointmentResponse(
    params: AppointmentApiService.Appointment_DeleteCardAppointmentParams,
    forUserEmail: string
  ): Observable<HttpResponse<CardAppointment>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/appointment/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Appointment_DeleteCardAppointment');

    // body, headers and query params
    if (params.contextId != null) __params = __params.set('contextId', params.contextId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardAppointment;

          body = <CardAppointment>response.body;

          return <HttpResponse<CardAppointment>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AppointmentApiService.Appointment_DeleteCardAppointmentParams` containing the following parameters:
   *
   * - `id`: Appointment id.
   *
   * - `contextId`: Team ID is used to verify access and select Inbox account as creator.
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Appointment_DeleteCardAppointment(
    params: AppointmentApiService.Appointment_DeleteCardAppointmentParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardAppointment> {
    const observable$ = this.Appointment_DeleteCardAppointmentResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/appointment/${params.id}`;
      const url = this.rootUrl + `/api/v1/appointment/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AppointmentApiService.Appointment_CreateAppointmentParams` containing the following parameters:
   *
   * - `cardAppointment`: Card appointment object
   *
   * - `contextId`: Team ID is used to verify access and select Inbox account as creator.
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Appointment_CreateAppointmentResponse(
    params: AppointmentApiService.Appointment_CreateAppointmentParams,
    forUserEmail: string
  ): Observable<HttpResponse<CardAppointment>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/appointment`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Appointment_CreateAppointment');

    // body, headers and query params
    __body = params.cardAppointment;
    if (params.contextId != null) __params = __params.set('contextId', params.contextId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardAppointment;

          body = <CardAppointment>response.body;

          return <HttpResponse<CardAppointment>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AppointmentApiService.Appointment_CreateAppointmentParams` containing the following parameters:
   *
   * - `cardAppointment`: Card appointment object
   *
   * - `contextId`: Team ID is used to verify access and select Inbox account as creator.
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Appointment_CreateAppointment(
    params: AppointmentApiService.Appointment_CreateAppointmentParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardAppointment> {
    const observable$ = this.Appointment_CreateAppointmentResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/appointment`;
      const url = this.rootUrl + `/api/v1/appointment`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AppointmentApiService.Appointment_UpdateCardAppointmentParams` containing the following parameters:
   *
   * - `cardAppointment`: Card appointment object
   *
   * - `contextId`: Team ID is used to verify access and select Inbox account as creator.
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Appointment_UpdateCardAppointmentResponse(
    params: AppointmentApiService.Appointment_UpdateCardAppointmentParams,
    forUserEmail: string
  ): Observable<HttpResponse<CardAppointment>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/appointment`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Appointment_UpdateCardAppointment');

    // body, headers and query params
    __body = params.cardAppointment;
    if (params.contextId != null) __params = __params.set('contextId', params.contextId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: CardAppointment;

          body = <CardAppointment>response.body;

          return <HttpResponse<CardAppointment>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AppointmentApiService.Appointment_UpdateCardAppointmentParams` containing the following parameters:
   *
   * - `cardAppointment`: Card appointment object
   *
   * - `contextId`: Team ID is used to verify access and select Inbox account as creator.
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Appointment_UpdateCardAppointment(
    params: AppointmentApiService.Appointment_UpdateCardAppointmentParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<CardAppointment> {
    const observable$ = this.Appointment_UpdateCardAppointmentResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/appointment`;
      const url = this.rootUrl + `/api/v1/appointment`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AppointmentApiService.Appointment_GetAgendaListParams` containing the following parameters:
   *
   * - `size`: Requested page size.
   *
   * - `offset`: Requested page offset.
   *
   * - `htmlFormat`: HTML format of comment body.
   *
   * - `dateTimeStart`: Minimum start date time of appointment
   *
   * - `dateTimeEnd`: Maximum end time of appointment (if not defined you get all appointments from dateTimeStart onwards)
   *
   * - `contextList`: Get appointments for specified contexts.
   *
   * - `allTeamContexts`: Get appointments for all team contexts.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Appointment_GetAgendaListResponse(
    params: AppointmentApiService.Appointment_GetAgendaListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfCardAppointment>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/appointment/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Appointment_GetAgendaList');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.dateTimeStart != null) __params = __params.set('dateTimeStart', params.dateTimeStart.toString());
    if (params.dateTimeEnd != null) __params = __params.set('dateTimeEnd', params.dateTimeEnd.toString());
    (params.contextList || []).forEach((val, index) => {
      if (val != null) __params = __params.append('contextList', val.toString());
    });
    if (params.allTeamContexts != null) __params = __params.set('allTeamContexts', params.allTeamContexts.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfCardAppointment;

          body = <ListOfResourcesOfCardAppointment>response.body;

          return <HttpResponse<ListOfResourcesOfCardAppointment>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AppointmentApiService.Appointment_GetAgendaListParams` containing the following parameters:
   *
   * - `size`: Requested page size.
   *
   * - `offset`: Requested page offset.
   *
   * - `htmlFormat`: HTML format of comment body.
   *
   * - `dateTimeStart`: Minimum start date time of appointment
   *
   * - `dateTimeEnd`: Maximum end time of appointment (if not defined you get all appointments from dateTimeStart onwards)
   *
   * - `contextList`: Get appointments for specified contexts.
   *
   * - `allTeamContexts`: Get appointments for all team contexts.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Appointment_GetAgendaList(
    params: AppointmentApiService.Appointment_GetAgendaListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfCardAppointment> {
    const observable$ = this.Appointment_GetAgendaListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/appointment/list`;
      const url = this.rootUrl + `/api/v1/appointment/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AppointmentApiService.Appointment_GetAgendaSettingsListParams` containing the following parameters:
   *
   * - `contextList`: Get appointments for specified contexts.
   *
   * - `allTeamContexts`: Get appointments for all team contexts.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Appointment_GetAgendaSettingsListResponse(
    params: AppointmentApiService.Appointment_GetAgendaSettingsListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfAgendaSettings>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/appointment/list/settings`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Appointment_GetAgendaSettingsList');

    // body, headers and query params
    (params.contextList || []).forEach((val, index) => {
      if (val != null) __params = __params.append('contextList', val.toString());
    });
    if (params.allTeamContexts != null) __params = __params.set('allTeamContexts', params.allTeamContexts.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfAgendaSettings;

          body = <ListOfResourcesOfAgendaSettings>response.body;

          return <HttpResponse<ListOfResourcesOfAgendaSettings>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AppointmentApiService.Appointment_GetAgendaSettingsListParams` containing the following parameters:
   *
   * - `contextList`: Get appointments for specified contexts.
   *
   * - `allTeamContexts`: Get appointments for all team contexts.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Appointment_GetAgendaSettingsList(
    params: AppointmentApiService.Appointment_GetAgendaSettingsListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfAgendaSettings> {
    const observable$ = this.Appointment_GetAgendaSettingsListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/appointment/list/settings`;
      const url = this.rootUrl + `/api/v1/appointment/list/settings`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `AppointmentApiService.Appointment_UpdateAppointmentResponseParams` containing the following parameters:
   *
   * - `id`: Card id
   *
   * - `appointmentResponse`: Appointment response object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Appointment_UpdateAppointmentResponseResponse(
    params: AppointmentApiService.Appointment_UpdateAppointmentResponseParams,
    forUserEmail: string
  ): Observable<HttpResponse<AppointmentResponse>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/appointment/${params.id}/response`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Appointment_UpdateAppointmentResponse');

    // body, headers and query params
    __body = params.appointmentResponse;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: AppointmentResponse;

          body = <AppointmentResponse>response.body;

          return <HttpResponse<AppointmentResponse>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `AppointmentApiService.Appointment_UpdateAppointmentResponseParams` containing the following parameters:
   *
   * - `id`: Card id
   *
   * - `appointmentResponse`: Appointment response object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Appointment_UpdateAppointmentResponse(
    params: AppointmentApiService.Appointment_UpdateAppointmentResponseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<AppointmentResponse> {
    const observable$ = this.Appointment_UpdateAppointmentResponseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/appointment/${params.id}/response`;
      const url = this.rootUrl + `/api/v1/appointment/${params.id}/response`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module AppointmentApiService {
  /**
   * Parameters for Appointment_Get
   */
  export type Appointment_GetParams = {
    /**
     * Card appointment id
     */
    id: string;

    /**
     * Attachment includes signed links
     */
    includeSignedLinks?: boolean;

    /**
     * HTML format of comment body
     */
    htmlFormat?: string;

    /**
     * Authorization card id
     */
    authorizationCardId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Appointment_DeleteCardAppointment
   */
  export type Appointment_DeleteCardAppointmentParams = {
    /**
     * Appointment id.
     */
    id: string;

    /**
     * Team ID is used to verify access and select Inbox account as creator.
     */
    contextId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Appointment_CreateAppointment
   */
  export type Appointment_CreateAppointmentParams = {
    /**
     * Card appointment object
     */
    cardAppointment: CardAppointment;

    /**
     * Team ID is used to verify access and select Inbox account as creator.
     */
    contextId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Appointment_UpdateCardAppointment
   */
  export type Appointment_UpdateCardAppointmentParams = {
    /**
     * Card appointment object
     */
    cardAppointment: CardAppointment;

    /**
     * Team ID is used to verify access and select Inbox account as creator.
     */
    contextId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Appointment_GetAgendaList
   */
  export type Appointment_GetAgendaListParams = {
    /**
     * Requested page size.
     */
    size?: number;

    /**
     * Requested page offset.
     */
    offset?: number;

    /**
     * HTML format of comment body.
     */
    htmlFormat?: string;

    /**
     * Minimum start date time of appointment
     */
    dateTimeStart?: string;

    /**
     * Maximum end time of appointment (if not defined you get all appointments from dateTimeStart onwards)
     */
    dateTimeEnd?: string;

    /**
     * Get appointments for specified contexts.
     */
    contextList?: string[];

    /**
     * Get appointments for all team contexts.
     */
    allTeamContexts?: boolean;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Appointment_GetAgendaSettingsList
   */
  export type Appointment_GetAgendaSettingsListParams = {
    /**
     * Get appointments for specified contexts.
     */
    contextList?: string[];

    /**
     * Get appointments for all team contexts.
     */
    allTeamContexts?: boolean;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Appointment_UpdateAppointmentResponse
   */
  export type Appointment_UpdateAppointmentResponseParams = {
    /**
     * Card id
     */
    id: string;

    /**
     * Appointment response object
     */
    appointmentResponse: AppointmentResponse;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
