import * as _ from 'lodash';
import {
  KeyboardAction,
  KeyboardActionModeType,
  KeyboardActionType,
  loopModes,
} from '../../../shared/models/keyboard-shortcut.model';

/**
 * Class for mode handling. Handles switching to modes as well as
 * creating events when we want to get to the previous mode.
 *
 * modeStack will hold the modes in the order they were visited.
 * Last mode on the stack is active mode.
 */
export class KeyboardShortcutModeStack {
  private modeStack: KeyboardActionMode[] = [
    {
      type: KeyboardActionModeType.NAVIGATION,
      level: 0,
      isSkippable: false,
    },
  ];

  constructor() {}

  setNewMode(newModeType: KeyboardActionModeType) {
    let newMode = _.find(loopModes, (mode: KeyboardActionMode) => mode.type === newModeType);

    if (this.modeStack.length === 0) {
      this.modeStack.push(newMode);
    } else {
      this.insertNewMode(newMode);
    }
  }

  createNewModeStack(newModeType: KeyboardActionModeType) {
    let newMode = _.find(loopModes, (mode: KeyboardActionMode) => mode.type === newModeType);
    this.modeStack = [newMode];
  }

  navigateToPreviousMode(): KeyboardAction {
    let currentMode = this.getActiveMode();
    let nextMode = this.getPreviousMode();

    this.insertNewMode(nextMode);

    let action = {
      type: KeyboardActionType.MODE_SWITCH,
      fromMode: currentMode,
      toMode: nextMode,
    } as KeyboardAction;

    return action;
  }

  previousModeInRelationTo(fromMode: KeyboardActionModeType): KeyboardAction {
    let oldMode = _.find(loopModes, (mode: KeyboardActionMode) => mode.type === fromMode);
    let spliceIndex = _.findIndex(this.modeStack, mode => mode.type === oldMode.type);

    if (this.isValidTransition(spliceIndex)) {
      let newMode = this.getPreviousMode(spliceIndex);
      let action = {
        type: KeyboardActionType.MODE_SWITCH,
        fromMode: oldMode,
        toMode: newMode,
      } as KeyboardAction;

      this.insertNewMode(newMode);

      return action;
    }
  }

  /**
   *
   * @param newMode     New mode to insert onto the stack
   *
   * New mode will be inserted in place of the mode on the stack with the same level and
   * will remove all entries with higher level. If all entries on the stack are of lower level,
   * insert on top of the stack.
   * Example:
   *
   * [Navigation(lv.0) -> Text(lv.1) -> Dropdown(lv.2)]
   *                         |
   *                 INSERT Thread(lv.1)
   *                         |
   *          [Navigation(lv.0) -> Thread(lv.1)]
   */
  private insertNewMode(newMode: KeyboardActionMode) {
    let spliceIndex = _.findIndex(this.modeStack, mode => mode.level >= newMode.level);

    if (spliceIndex < 0) {
      this.modeStack.push(newMode);
    } else if (spliceIndex < 1) {
      this.modeStack = [newMode];
    } else {
      this.modeStack = this.modeStack.slice(0, spliceIndex);
      this.modeStack.push(newMode);
    }
  }

  getBaseMode(): KeyboardActionMode {
    return _.first(this.modeStack);
  }

  getActiveMode(): KeyboardActionMode {
    return _.last(this.modeStack);
  }

  getStringifiedModeStack(): string {
    return this.modeStack.reduce((acc, mode, index) => acc + (index === 0 ? '' : ' > ') + mode.type, '');
  }

  private getPreviousMode(index?: number): KeyboardActionMode {
    let previousMode: KeyboardActionMode;
    let returnIndex = index || this.modeStack.length - 1;
    let validMode = false;

    if (this.modeStack.length === 1) {
      previousMode = this.modeStack[0];
      validMode = true;
    }

    while (!validMode) {
      previousMode = this.modeStack[returnIndex - 1];
      if (!previousMode.isSkippable) {
        validMode = true;
      } else {
        returnIndex -= 1;
      }
    }

    return previousMode;
  }

  private isValidTransition(transitionFrom: number): boolean {
    if (transitionFrom < 1) {
      return false;
    }

    if (transitionFrom === this.modeStack.length - 1) {
      return true;
    }

    return !_.some(this.modeStack.slice(transitionFrom + 1), mode => {
      return !mode.isSkippable;
    });
  }
}

export interface KeyboardActionMode {
  type: KeyboardActionModeType;
  level: number;
  context?: string;
  isSkippable: boolean;
}
