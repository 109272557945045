<div
  [style.width]="width + 'px'"
  [style.height]="height + 'px'"
  (click)="openContactPopup($event)"
  class="avatar-wrapper"
  draggable="false"
>
  <!-- Spinner -->
  <span *ngIf="spinner" [class]="extraClass + ' circle-spinner'"></span>

  <!-- Avatar image -->
  <avatar-file
    [file]="file"
    [forUserEmail]="forUserEmail"
    [extraClass]="extraClass"
    [contact]="contact"
    [sharedTag]="sharedTag"
    [multipleRecipients]="multipleRecipients"
    [width]="width"
    [height]="height"
    [hasBorder]="hasBorder"
    (requestAvatar)="resolveFile(true)"
  >
  </avatar-file>
</div>
