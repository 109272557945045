import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { ContactModel, GroupModel, UserModel } from 'dta/shared/models-api-loop/contact/contact.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { Router } from '@angular/router';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { filter, tap } from 'rxjs/operators';
import { RedirectService } from '@shared/services/redirect/redirect.service';

@AutoUnsubscribe()
@Injectable()
export class NewMessageModalService implements OnDestroy {
  openModal$: Subject<void> = new Subject();
  currentUser: UserModel;

  ////////////////////
  // Subscriptions
  ////////////////////
  private findContactSub: Subscription;

  constructor(
    protected _router: Router,
    protected _userManagerService: UserManagerService,
    protected _contactService: ContactService,
    private readonly _redirectService: RedirectService
  ) {}

  ngOnDestroy(): void {}

  get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  open() {
    this.openModal$.next();
    this.currentUser = this._userManagerService.getCurrentUser();
  }

  openChat(contact: ContactModel) {
    if (contact.$type === GroupModel.type) {
      this.navigateToChannel(contact._id);
    } else {
      this._redirectService.quickJump(contact, undefined, true);
      this.findContactSub?.unsubscribe();
      // this.findContactSub = this._contactService
      //   .findContactByEmail(this.currentUserEmail, contact.email)
      //   .pipe(
      //     filter((fullContact: ContactModel) => !_.isEmpty(fullContact)),
      //     tap((fullContact: ContactModel) => {
      //       this.navigateToChannel(fullContact._id);
      //     }),
      //   )
      //   .subscribe();
    }
  }

  navigateToChannel(channelId: string) {
    this._router.navigate([
      '/channel/' + channelId,
      { fragment: 'redirected' },
      { outlets: { primary: ['chat'], panel: null } }
    ]);
  }
}
