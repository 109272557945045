import * as _ from 'lodash';
import { EMPTY, forkJoin, from, Observable, of, switchMap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CommentBaseModel,
  CommentChatModel,
  CommentDraftModel,
  CommentMailExtraData,
  CommentMailModel,
  CommentModel,
  MimeType
} from '@dta/shared/models-api-loop/comment/comment.model';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { BasePushSynchronizationService } from '../base-push-synchronization/base-push-synchronization.service';
import { CardBase, CommentBase, CommentChat, CommentDraft, CommentMail, GroupType } from '@shared/api/api-loop/models';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { DraftError, PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { CommentApiService } from '@shared/api/api-loop/services';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { ApiService } from '@shared/api/api-loop/api.module';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { FileService } from '@shared/services/data/file/file.service';
import {
  CardBaseModel,
  CardDraftModel,
  CardMailModel,
  CardModel,
  CardSharedModel
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CardDaoService } from '@shared/database/dao/card/card-dao.service';
import { CardService } from '@shared/services/data/card/card.service';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { HtmlImageService } from '@shared/services/data/html-image/html-image.service';
import { DraftService } from '@shared/services/data/draft/draft.service';
import { LabelService } from '@shared/services/data/label/label.service';
import { CommentDaoService } from '@shared/database/dao/comment/comment-dao.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';

@Injectable()
export class CommentPushSynchronizationService extends BasePushSynchronizationService<CommentModel> {
  constructor(
    protected _api: ApiService,
    protected _commentService: CommentService,
    protected _notificationsService: NotificationsService,
    protected _fileService: FileService,
    protected _cardService: CardService,
    protected _cardDaoService: CardDaoService,
    protected _commentDaoService: CommentDaoService,
    protected _htmlImageService: HtmlImageService,
    protected _draftService: DraftService,
    protected _labelService: LabelService,
    protected _conversationService: ConversationService
  ) {
    super();
  }

  get constructorName(): string {
    return 'CommentPushSynchronizationService';
  }

  protected beforeSynchronize(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    return of(comment).pipe(
      /**
       * Signatures have no attachments. Local path has to be replaced with cid
       */
      mergeMap((comment: CommentModel) => this.processSignaturesInBodyForSend(comment)),
      /**
       * Upload attachments and replace paths in html
       */
      mergeMap((comment: CommentModel) => this.prepareCommentAttachments(forUserEmail, comment))
    );
  }

  protected synchronize(forUserEmail: string, comment: CommentModel): Observable<CommentModel | DraftPushError> {
    if (comment.id && comment instanceof CommentDraftModel) {
      return this.updateCommentDraft(forUserEmail, comment);
    }

    return this._sendComment(forUserEmail, comment);
  }

  protected afterSynchronize(forUserEmail: string, model: CommentModel): Observable<CommentModel> {
    return of(model).pipe(
      /**
       * Save comment
       */
      mergeMap((comment: CommentModel) => {
        return this._commentService.save(forUserEmail, comment);
      }),
      /**
       * Publish updates
       */
      tap((comment: CommentModel) => {
        this.publishSyncUpdates(forUserEmail, comment);
      }),
      /**
       * Save card
       */
      mergeMap((comment: CommentModel) => {
        return this.updateCommentCard(forUserEmail, comment).pipe(map(() => comment));
      })
    );
  }

  private _sendComment(forUserEmail: string, comment: CommentModel): Observable<CommentModel | DraftPushError> {
    // Preserves SENT/OUTBOX tags on sent comments
    let send$: Observable<CommentBase | DraftPushError>;

    if (comment instanceof CommentDraftModel) {
      send$ = this.createDraft(forUserEmail, comment);
    } else if (comment instanceof CommentChatModel) {
      if (comment.id) {
        // Edit chat - uncomment when BE supports
        send$ = this.updateChatComment(forUserEmail, comment);
      } else {
        send$ = this.createChatComment(forUserEmail, comment);
      }
    } else if (comment instanceof CommentMailModel) {
      send$ = this.createCommentMail(forUserEmail, comment);
    }

    return send$.pipe(
      catchError(err => {
        // Handle conflict
        if (err.status === 409) {
          return this.handleCommentConflictError(forUserEmail, err, comment);
        }

        return throwError(err);
      }),
      /**
       * Show notification
       */
      tap(() => {
        if (![CommentChatModel.type, CommentDraftModel.type].includes(comment.$type)) {
          this._notificationsService.setInAppNotification(forUserEmail, { type: NotificationEventType.EmailSent });
        }
      }),
      /**
       * Create model
       */
      map((response: CommentBase) => {
        // We get empty response on send as for first comment
        // We should still remove it from queue, move files from tmp, ...
        if (_.isEmpty(response)) {
          return comment;
        }

        return CommentBaseModel.create(response);
      }),
      /**
       * Update comment body
       */
      mergeMap((comment: CommentModel) => {
        return this._commentService.updateCommentBody(forUserEmail, comment);
      })
    );
  }

  private updateCommentDraft(forUserEmail: string, comment: CommentDraftModel): Observable<CommentModel> {
    // Update comment model when needed
    let updateCommentDraftModelObs$ = of(comment);
    if (comment._ex.unSyncedBodyChanges || comment._ex.saveAndClose || !comment.contentFileId) {
      comment.bumpRevision();
      let parameters: CommentApiService.Comment_UpdateDraftResponseParams = {
        commentDraft: comment,
        forceUpdateCommentSnippet: comment._ex.saveAndClose
      };

      updateCommentDraftModelObs$ = this._api.CommentApiService.Comment_UpdateDraftResponse(
        parameters,
        forUserEmail
      ).pipe(
        catchError(err => {
          if (err.status === 429) {
            return this.handleCommentUpdateRateLimit(forUserEmail, err, comment);
          }

          Logger.error(err, 'Got error as response for updateCommentDraft', LogTag.INTERESTING_ERROR, true);

          return throwError(err);
        }),
        tap(() => (comment._ex.unSyncedBodyChanges = false)),
        map((response: CommentDraft) => CommentBaseModel.create(response) as CommentDraftModel)
      );
    }

    comment._ex.saveAndClose = false;

    return updateCommentDraftModelObs$.pipe(
      mergeMap((draftComment: CommentDraftModel) => {
        let contentAsArray: Uint8Array = new TextEncoder().encode(comment.body.content);
        let blob: Blob = new Blob([contentAsArray]);

        return this._fileService
          .updateFileContent(forUserEmail, draftComment.contentFileId, blob)
          .pipe(map(() => draftComment));
      })
    );
  }

  private handleCommentConflictError(
    forUserEmail: string,
    err: HttpErrorResponse,
    comment: CommentModel
  ): Observable<CommentBase> {
    if (err.status !== 409 || (!comment.id && !_.has(err, 'error.conflictedResource.id'))) {
      return throwError(err);
    }

    // Resource conflict for drafts: get resource id and update
    if (comment.$type === CommentDraftModel.type) {
      comment.id = err.error.conflictedResource.id;

      let comment$ = of(comment);

      // Get conflict resource type info from error message (until BE provides type)
      let isDraftCardConflict = comment.parent?.clientId && err.error.message.includes(comment.parent?.clientId);
      if (isDraftCardConflict) {
        comment$ = this._api.CardApiService.Card_Get({ id: err.error.conflictedResource.id }, forUserEmail).pipe(
          map(CardBaseModel.create),
          map((card: CardDraftModel) => {
            comment.parent = card;
            comment.id = card.commentDraft?.id;
            return comment;
          })
        );
      }

      return comment$.pipe(
        mergeMap((comment: CommentModel) => this._api.CommentApiService.Comment_Get({ id: comment.id }, forUserEmail)),
        map(CommentBaseModel.create),
        mergeMap((remoteComment: CommentDraftModel) => {
          let updatedComment = { ...comment, ...remoteComment };
          return this.updateCommentDraft(forUserEmail, CommentBaseModel.create(updatedComment) as CommentDraftModel);
        })
      );
    }

    // When editing comment right after sending it we don't have comment id
    if (!comment.id && (<CommentChatModel>comment)._ex?.editedLocally && _.has(err, 'error.conflictedResource.id')) {
      comment.id = err.error.conflictedResource.id;
      return this.updateChatComment(forUserEmail, comment as CommentChatModel);
    }

    // comment already exists - we need to GET commentById
    let id = comment.id || err.error.conflictedResource.id;
    return this._api.CommentApiService.Comment_Get({ id: id }, forUserEmail);
  }

  private handleCommentUpdateRateLimit(
    forUserEmail: string,
    err: HttpErrorResponse,
    comment: CommentModel
  ): Observable<CommentBase> {
    if (err.status !== 429) {
      return throwError(err);
    }

    Logger.customLog(
      `Got rate limit on comment update for ${comment.id}, user: ${forUserEmail}. Revision is: ${comment.revision}`,
      LogLevel.ERROR,
      [LogTag.SYNC, LogTag.INTERESTING_ERROR],
      false,
      'Got rate limit on comment update'
    );

    // Don't update, just pass through
    return of(comment);
  }

  protected publishSyncUpdates(forUserEmail: string, comment: CommentModel): void {
    // Remove 'sending' toast notification and publish sent comment
    if (comment instanceof CommentMailModel) {
      PublisherService.publishEvent(forUserEmail, comment, PublishEventType.Remove);
    }
    PublisherService.publishEvent(forUserEmail, comment);
  }

  protected prepareCommentAttachments(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    return of(comment).pipe(
      /**
       * Convert draft attachments to temp
       */
      mergeMap((_comment: CommentModel) => this.convertDraftAttachmentsToTemp(forUserEmail, _comment)),
      /**
       * Fetch missing inline image file models
       */
      mergeMap((_comment: CommentModel) => this.fetchMissingInlineImageFileModels(forUserEmail, _comment)),
      /**
       * Replace img path with cids
       */
      map((_comment: CommentModel) => this.replaceImgFilePathsWithCid(_comment)),
      /**
       * Replace img path with links (drafts)
       */
      map((_comment: CommentModel) => this.replaceImgFilePathsWitLinks(_comment)),
      /**
       * Remove unused inline image files from attachments
       */
      mergeMap((_comment: CommentModel) => this.removeUnusedInlineImages(_comment))
    );
  }

  private convertDraftAttachmentsToTemp(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    if (comment.$type !== CommentDraftModel.type) {
      return of(comment);
    }

    return this._draftService.convertAttachmentsToTemp(forUserEmail, comment as CommentDraftModel);
  }

  private fetchMissingInlineImageFileModels(forUserEmail: string, comment: CommentModel): Observable<CommentModel> {
    /**
     * For mail comments created from drafts, we do not have correct fileModels attached.
     * BE will changed all TF_ files in attachment list to AF_ but will not change draft body.
     * As a workaround we fetch all missing fileModels by TF_ and add it to attachment list.
     * Related ticket: https://github.com/4thOffice/brisket/issues/9645
     */
    return comment instanceof CommentMailModel && !(comment instanceof CommentDraftModel)
      ? this._htmlImageService.fetchMissingInlineImageFileModels(forUserEmail, comment as CommentDraftModel)
      : of(comment);
  }

  private removeUnusedInlineImages(comment: CommentModel): Observable<CommentModel> {
    return !(comment instanceof CommentMailModel) || !comment.hasBody()
      ? of(comment)
      : this._htmlImageService.removeUnusedInlineImages(comment);
  }

  private replaceImgFilePathsWithCid(comment: CommentModel): CommentModel {
    if (
      comment instanceof CommentDraftModel ||
      !(comment instanceof CommentMailModel) ||
      !comment.hasBody() ||
      !comment.hasAttachments()
    ) {
      return comment;
    }

    let html = comment.body.content;
    let files = comment.getInlineAttachments();

    comment.body.content = this._htmlImageService.replaceImgFilePathsAndLinksWithCid(html, files);
    return comment;
  }

  private replaceImgFilePathsWitLinks(comment: CommentModel): CommentModel {
    if (!(comment instanceof CommentDraftModel) || !comment.hasBody() || !comment.hasAttachments()) {
      return comment;
    }

    let html = comment.body.content;
    let files = comment.getInlineAttachments();

    comment.body.content = this._htmlImageService.replaceImgFilePathsAndCidsWithLinks(html, files);
    return comment;
  }

  protected processSignaturesInBodyForSend(comment: CommentModel): Observable<CommentModel> {
    if (!(comment instanceof CommentMailModel) || !comment.hasBody()) {
      return of(comment);
    }

    let html = comment.body.content;
    comment.body.content = this._htmlImageService.processSignaturesInBodyForSend(html);
    return of(comment);
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse | DraftPushError,
    comment: CommentModel
  ): Observable<any> {
    /**
     * Syncing is retried, draft was not yet returned from BE
     */
    if (err instanceof DraftPushError) {
      return throwError(err);
    }
    /**
     * Syncing is retried only on offline event or unauthorized/401 response
     */
    if ((<HttpErrorResponse>err).status === 0) {
      // We lost connection and will retry
      return EMPTY;
    } else if ((<HttpErrorResponse>err).status === 401) {
      // Unauthorized - token should be refreshed and we'll retry
      return throwError(err);
    } else if ((<HttpErrorResponse>err).status === 403) {
      return throwError(err);
    }

    /**
     * We cannot sync the comment, remove it from push-queue and publish
     */
    Logger.error(
      err,
      `[SYNC] - PushSync [${forUserEmail}]: could not sync comment commentId:${comment._id}`,
      LogTag.SYNC
    );
  }

  private createDraft(forUserEmail: string, comment: CommentDraftModel): Observable<CommentDraft> {
    let parameters = {
      commentDraft: comment,
      draftOnCardId: comment._ex.linkedMailCard,
      isShared: false
    } as CommentApiService.Comment_CreateDraftResponseParams;

    if (
      !comment.id &&
      comment.author?.email !== forUserEmail &&
      !_.some(comment.to.resources, contact => contact instanceof GroupModel && contact.groupType === GroupType.NORMAL)
    ) {
      parameters.commentDraft.sendAs = comment.author.email;

      if (comment._ex.sendAsGroupId) {
        parameters.commentDraft.sendAsGroupId = comment._ex.sendAsGroupId;
      }
    }

    let contentAsArray: Uint8Array = new TextEncoder().encode(comment.body.content);
    let blob: Blob = new Blob([contentAsArray]);

    return this._api.CommentApiService.Comment_CreateDraftResponse(parameters, forUserEmail).pipe(
      mergeMap((response: CommentDraft) =>
        this._fileService.updateFileContent(forUserEmail, response.contentFileId, blob).pipe(map(() => response))
      )
    );
  }

  private updateChatComment(forUserEmail: string, comment: CommentChatModel): Observable<CommentChat> {
    let parameters = {
      commentChat: comment.toCommentBase(),
      skipBbCodeEscaping: comment._ex.hasMentions
    } as CommentApiService.Comment_UpdateChatParams;

    return this._api.CommentApiService.Comment_UpdateChat(parameters, forUserEmail);
  }

  private createChatComment(forUserEmail: string, comment: CommentChatModel): Observable<CommentChat> {
    let parameters = {
      commentChat: comment.toCommentBase(),
      sourceResourceId: comment._ex?.sourceResourceId,
      skipBbCodeEscaping: comment._ex?.hasMentions
    } as CommentApiService.Comment_CreateCommentChatParams;

    return this._api.CommentApiService.Comment_CreateCommentChat(parameters, forUserEmail).pipe(
      /**
       * Follow-up updates (add label)
       */
      mergeMap((createdComment: CommentChat) => {
        let $followUpUpdate = of([]);
        if (comment._ex.labels) {
          $followUpUpdate = this._labelService.addOrRemoveLabelForCards(
            forUserEmail,
            [createdComment.parent.id],
            comment._ex.labels,
            'add'
          );
        }

        return $followUpUpdate.pipe(map(() => createdComment));
      })
    );
  }

  private createCommentMail(forUserEmail: string, comment: CommentMailModel): Observable<CommentMail | DraftPushError> {
    let parameters = {
      commentMail: comment.toCommentBase(),
      htmlFormat: MimeType.html,
      ignoreEmailHistory: true
    } as CommentApiService.Comment_CreateCommentMailParams;

    if (comment._ex?.shouldSplitOnSend) {
      parameters.closeConversation = true;
    }

    if (
      !comment.id &&
      comment.author?.email !== forUserEmail &&
      !_.some(comment.to.resources, contact => contact instanceof GroupModel && contact.groupType === GroupType.NORMAL)
    ) {
      parameters.sendAs = comment.author.email;

      if (comment._ex.sendAsGroupId) {
        parameters.groupId = comment._ex.sendAsGroupId;
      }
    }

    // Add link to draft on BE that this email was created from
    return of(undefined).pipe(
      /**
       * Get draft card from comment id
       */
      mergeMap(() => {
        if (comment._ex.createdFromDraftCommentId) {
          return this.getDraftCard(forUserEmail, comment);
        }

        Logger.customLog(
          `[Comment Push] - ${forUserEmail}, ${comment._ex.createdFromDraftCommentId} does not have draft`,
          LogLevel.ERROR
        );

        return of(undefined);
      }),
      /**
       * Does comment contain BE link to draft card
       */
      map((draftCard: CardBase) => {
        if (!draftCard || !draftCard.id) {
          throw new DraftPushError();
        } else {
          parameters.draftId = draftCard.id;
        }
        return draftCard;
      }),
      /**
       * Send request to BE
       */
      mergeMap((conversation: ConversationModel) => {
        return this._api.CommentApiService.Comment_CreateCommentMail(parameters, forUserEmail).pipe(
          /**
           * Remove link to draft if mail comment created from draft
           */
          mergeMap((_comment: CommentMail) => {
            /**
             * Find Card id that draft is linked to
             */
            return this._conversationService
              .findOrFetchByCardId(
                forUserEmail,
                comment.parent?.id?.replace('-copy1T', '') || comment.parent?.clientId?.replace('-copy1T', '')
              )
              .pipe(
                switchMap(_converastion => {
                  if (_converastion) {
                    return this._conversationService.unlinkPrivateDraft(forUserEmail, _converastion.cardId).pipe(
                      tap(() => {
                        _converastion.privateDraft = undefined;
                        PublisherService.publishEvent(forUserEmail, [_converastion]);
                      }),
                      map(() => _comment)
                    );
                  }

                  return of(_comment);
                })
              );
          })
        );
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private getDraftCard(forUserEmail: string, comment: CommentMailModel): Observable<CardBase> {
    return of(undefined).pipe(
      mergeMap(() => {
        return this._commentDaoService.findCommentsByIdsOrClientIds(forUserEmail, [
          comment._ex.createdFromDraftCommentId
        ]);
      }),
      map((comments: CommentDraftModel[]) => {
        let draftCard = _.first(comments)?.parent;
        if (draftCard) {
          comment.setExPassthroughData('createdFromDraftCardId', draftCard.id || draftCard.clientId);
          return draftCard;
        }

        return undefined;
      })
    );
  }

  protected updateCommentCard(forUserEmail: string, comment: CommentModel): Observable<CardModel> {
    let card = CardBaseModel.create(comment.parent);

    // Freshly send comment with missing parent BE id indicates, that
    // we don't have rights for that comment/card. We don't update, it
    // will come via eventSync
    if (!card.id) {
      return of(undefined);
    }

    return of(undefined).pipe(
      /**
       * Find card by BE id
       */
      mergeMap(() => {
        return this._cardDaoService.findById(forUserEmail, card.id);
      }),
      /**
       * If card does not exists, fetch it
       */
      catchError(err => {
        if (err.status !== 404) {
          return throwError(err);
        }

        return this._api.CardApiService.Card_Get({ id: card.id }, forUserEmail).pipe(map(CardBaseModel.create));
      }),
      /**
       * Save card and publish updates
       */
      mergeMap((card: CardModel) => {
        return this._cardService.saveAndPublish(forUserEmail, card);
      })
    );
  }
}

export class DraftPushError extends Error {
  type: string;

  constructor() {
    super();
    Object.setPrototypeOf(this, DraftPushError.prototype);
  }
}
