import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import {
  CommentBaseModel,
  CommentChatModel,
  CommentDraftModel,
  CommentMailModel,
  CommentModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import { RecentSearchFilter } from '@dta/shared/models/search.model';
import { CardBase } from '@shared/api/api-loop/models';
import { UnfurlData } from '@shared/models/unfurl/unfurl.model';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { ChannelChatsCollectionParams } from '@dta/ui/collections/comments/channel-chats.collection';
import { tap } from 'rxjs/operators';
import { SyncSettingsType } from '@dta/shared/models/sync-settings-type.model';
import { CommentApiService } from '@shared/api/api-loop/services/comment-api.service';

@Injectable()
export class CommentServiceWeb extends CommentService {
  get constructorName(): string {
    return 'CommentServiceWeb';
  }

  protected shouldWriteBodyToDisk(comment: CommentMailModel): boolean {
    // Never write comment body to disc
    return false;
  }

  removeComment(forUserEmail: string, comment: CommentModel, undoSent?: boolean): Observable<any> {
    throw new Error('Method not implemented.');
  }
  saveUnfurlDataById(forUserEmail: string, id: string, unfurlData: UnfurlData): Observable<CommentChatModel> {
    throw new Error('Method not implemented.');
  }
  findCommentsForSearch(
    forUserEmail: string,
    query: string,
    offset: number,
    size: number,
    filter?: RecentSearchFilter,
  ): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  loadMissingBodiesFromDisc(forUserEmail: string, comments: CommentModel[]): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }
  protected loadLocalCommentBody(comment: CommentMailModel): Observable<CommentMailModel> {
    return of(comment);
  }
  openCommentInBrowser(forUserEmail: string, comment: CommentMailModel) {
    throw new Error('Method not implemented.');
  }
  undoEmailByClientId(forUserEmail: string, commentClientId: string): Observable<CommentModel> {
    throw new Error('Method not implemented.');
  }
  downloadCommentContent(forUserEmail: string, commentId: string, commentSubject: string): Observable<any> {
    if (_.isEmpty(forUserEmail) || _.isEmpty(commentId) || _.isEmpty(commentSubject)) {
      return throwError(new Error('Missing parameter for downloadCommentContent()'));
    }

    // Get account type to determine the format of downloaded content
    let accountType = this._sharedUserManagerService.getAccountSyncSettingsTypeByEmail(forUserEmail);
    let isMsgFormat =
      accountType === SyncSettingsType.SYNC_SETTINGS_EXCHANGE ||
      accountType === SyncSettingsType.SYNC_SETTINGS_MICROSOFT;

    return this.getCommentArrayBuffer(forUserEmail, commentId).pipe(
      switchMap((res: ArrayBuffer) => {
        return this.fileDownloadService.downloadFromArrayBuffer(res, {
          fileName: commentSubject + (isMsgFormat ? '.msg' : '.eml'),
        });
      }),
    );
  }

  beforeSyncServiceInit(forUserEmail: string): Observable<any> {
    return this._commentDaoService.removeCollection(forUserEmail);
  }

  countOutboxComments(forUserEmail: string) {
    throw new Error('Method not implemented.');
  }
  countChatsByChatCard(forUserEmail: string, params: ChannelChatsCollectionParams): Observable<Number> {
    return of(params.offset);
  }
  findCommentsForCards(forUserEmail: string, cards: CardBase[]): Observable<CommentBaseModel[]> {
    throw new Error('Method not implemented.');
  }
  findCommentsForOutbox(forUserEmail: string): Observable<CommentBaseModel[]> {
    throw new Error('Method not implemented.');
  }
  findOutboxComments(forUserEmail: string): Observable<CommentBaseModel[]> {
    throw new Error('Method not implemented.');
  }
  findChatCommentsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string,
  ): Observable<CommentModel[]> {
    throw new Error('Method not implemented.');
  }

  // DRAFTS
  findSharedDraftBySourceCardOrEmpty(forUserEmail: string, card_id: any): Observable<CommentDraftModel> {
    throw new Error('Method not implemented.');
  }
}
