<div class="dialog-container">
  <div class="content-container">
    <p class="description">{{ params?.description }}</p>
    <p class="hint">{{ params?.hint }}</p>
  </div>
  <div class="buttons-container">
    <div>
      <loop-button
        (click)="abort()"
        [fill]="params.declineButton?.fill ?? 'transparent'"
        [color]="params.declineButton?.color ?? 'systemContrast'"
      >
        {{ params?.declineMessage }}
      </loop-button>
    </div>
    <div>
      <loop-button
        (click)="confirm()"
        (keydown.enter)="confirm()"
        [fill]="params.confirmButton?.fill ?? 'primary'"
        [color]="params.confirmButton?.color ?? 'color'"
      >
        {{ params?.confirmMessage }}
      </loop-button>
    </div>
  </div>
</div>
