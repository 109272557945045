import * as _ from 'lodash';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, range, throwError, timer } from 'rxjs';
import { HttpEventService } from './http-event.service';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Logger } from '@shared/services/logger/logger';
import { catchError, mergeMap, retryWhen, zip } from 'rxjs/operators';
import { HttpResponseEventType } from '@dta/shared/models/http-events.model';
import { environment } from '@shared/environments/environment';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  private apiKillSwitchTriggered: boolean = false;

  private serviceUnavailableStatusCodes: number[] = [0, 502, 503, 504];
  private offlineStatusCodes: number[] = [0, 502, 503, 504];
  private retryStatusCodes: number[] = [408, 503, 504];
  private maxErrorRetries: number = 3;

  constructor(private _httpResponseEventService: HttpEventService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ignore API requests after API kill switch is triggered
    if (this.apiKillSwitchTriggered && this.isApiUrl(request.url)) {
      return EMPTY;
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleFalsePositiveHttpError(err);
      }),
      catchError((err: HttpErrorResponse) => {
        return this.handleApiHttpError(request, err);
      }),
    );
  }

  private isApiUrl(url: string): boolean {
    if (!url) {
      return false;
    }

    if (environment.usingProxy) {
      return url.startsWith('http://localhost:4200');
    }

    return url.startsWith(CONSTANTS.LOOP_API_ROOT_URI);
  }

  private handleFalsePositiveHttpError(err: HttpErrorResponse): Observable<HttpResponse<any>> {
    // TODO: remove when fixed in httpClient
    // false positive (bug from httpClient where it expects to parse body as string)
    // https://github.com/angular/angular/issues/18396
    if (err.status >= 200 && err.status < 300) {
      let response = new HttpResponse({
        body: null,
        headers: err.headers,
        status: err.status,
        statusText: err.statusText,
        url: err.url,
      });

      return of(response);
    }

    // rethrow by default
    return throwError(err);
  }

  private handleApiHttpError(request: HttpRequest<any>, err: HttpErrorResponse): Observable<any> {
    if (!this.isApiUrl(err.url)) {
      return throwError(err);
    }

    // responses from HEAD requests are not intercepted
    // also causes a loop for connection-watchdog.service/isApiAlive
    if (request.method === 'HEAD') {
      return throwError(err);
    }

    // publish errors
    if (err.status === 401) {
      this.handleUnauthorizedError(err);
    }
    if (this.serviceUnavailableStatusCodes.includes(err.status)) {
      this.handleServiceUnavailableError(err);
    }

    return throwError(err).pipe(retryWhen((handler: Observable<any>) => this.retryOnHttpError(handler)));
  }

  private retryOnHttpError(handler: Observable<any>): Observable<any> {
    return handler.pipe(
      /**
       * Pass-through/throw errors that we should never retry on
       */
      mergeMap((err: HttpErrorResponse) => {
        //
        if (!this.retryStatusCodes.includes(err.status)) {
          return throwError(err);
        }

        return of(err);
      }),
      /**
       * Retry with exponential backoff
       */
      zip(range(1, this.maxErrorRetries), (err, i) => {
        return [err, i];
      }),
      mergeMap(input => {
        let err: HttpErrorResponse = input[0] as HttpErrorResponse;
        let retryCount: number = input[1] as number;

        // ensure max number of attempts
        if (retryCount >= this.maxErrorRetries) {
          return throwError(err);
        }

        // use retry-after or 1s by default
        let retryAfterSeconds = this.getRetryAfter(err);

        // apply exponential backoff based on the retry attempt
        if (retryAfterSeconds <= 1) {
          retryAfterSeconds = Math.pow(2, retryCount);
        }

        Logger.debug('Failed API request will be retried after %d second(s)', retryAfterSeconds, err);
        return timer(retryAfterSeconds * 1000);
      }),
    );
  }

  private getRetryAfter(err: HttpErrorResponse): number {
    let retryAfter;

    try {
      retryAfter = Number(err.headers.get('Retry-After'));
    } catch (e) {
      retryAfter = 1;
    }

    return retryAfter || 1;
  }

  private handleUnauthorizedError(err: HttpErrorResponse) {
    // UNAUTHORIZED - token needs to be refreshed or user needs to re-login when NOT on the routes below
    let uriWhitelist = ['login', 'logon', 'token', 'composer'];
    let path = err.url.split('/').pop();

    if (!uriWhitelist.includes(path)) {
      this._httpResponseEventService.emit({ type: HttpResponseEventType.unauthorized });
    }
  }

  private handleServiceUnavailableError(err: HttpErrorResponse) {
    // KILL-SWITCH - disables app
    if (err.status === 503 && this.isApiKillSwitch(err)) {
      this.apiKillSwitchTriggered = true;

      let location = !_.isEmpty(err.headers) && err.headers.get('Location');
      this._httpResponseEventService.emit({
        type: HttpResponseEventType.deprecated,
        data: {
          location: location,
        },
      });
      return;
    }

    // OFFLINE-DETECTION - API is unavailable
    if (this.offlineStatusCodes.includes(err.status)) {
      // emit error status when there is no connection or API is not available
      this._httpResponseEventService.emit({ type: HttpResponseEventType.offline });
      return;
    }
  }

  private isApiKillSwitch(err: HttpErrorResponse): boolean {
    if (err.status !== 503 || _.isEmpty(err.error) || err.headers.get('content-type') !== 'application/json') {
      return false;
    }

    let body = err.error;

    if (_.isString(body)) {
      body = JSON.parse(body);
    }

    return body.ErrorCode.trim().toUpperCase() === 'DEPRECATED';
  }
}
