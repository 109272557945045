import * as _ from 'lodash';
import { Directive } from '@angular/core';
import { BaseService } from '../base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { SnoozeServiceI } from './snooze.service.interface';
import {
  CardBaseModel,
  CardModel,
  CardSharedModel,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { merge, Observable, of, throwError } from 'rxjs';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { StateUpdates } from '@dta/shared/models/state-updates';
import {
  CardBase,
  CardType,
  ListOfResourcesOfCardBase,
  QueryRelation,
  SortOrder,
  Tag,
  TagType,
} from '@shared/api/api-loop/models';
import { CardApiService } from '@shared/api/api-loop/services';
import { TimedTagModel } from '@dta/shared/models-api-loop/tag.model';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { CardService } from '../card/card.service';
import { CommentService } from '../comment/comment.service';
import { ContactService } from '../contact/contact.service';
import { TagService } from '../tag/tag.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

const snoozeTags: string[] = [TagType.SNOOZE, TagType.SNOOZE_DUE, TagType.SNOOZE_INTERRUPT];

@Directive()
export class SnoozeService extends BaseService implements SnoozeServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _conversationService: ConversationService,
    protected _contactService: ContactService,
    protected _tagService: TagService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'SnoozeService';
  }

  ////////////////
  // SYNC METHODS
  ////////////////

  protected fetchSaveAndPublishNewContacts(forUserEmail: string, stateUpdates: StateUpdates): Observable<StateUpdates> {
    let comments = CardBaseModel.getCommentsFromCards(stateUpdates.cards);

    return this._contactService.fetchNewContactsByCommentShareLists(forUserEmail, comments).pipe(
      map(() => {
        return stateUpdates;
      }),
    );
  }

  removeAllSnoozeTags(forUserEmail: string, cardId: string): Observable<ConversationModel> {
    return of(undefined).pipe(
      /**
       * Find card by id
       */
      mergeMap(() => {
        return this._conversationService.findOrFetchByCardId(forUserEmail, cardId);
      }),
      /**
       * Remove all snooze tags
       */
      map((conversation: ConversationModel) => {
        return this.removeAllSnoozeTagsOnCard(conversation);
      }),
      /**
       * Sync to BE, Save to DB and publish tag updates
       */
      mergeMap((conversation: ConversationModel) => {
        return this._tagService.syncSaveAndPublishCardTagUpdates(forUserEmail, conversation);
      }),
    );
  }

  setOrUpdateSnooze(forUserEmail: string, cardId: string, dueDateUTC: string): Observable<ConversationModel> {
    return of(undefined).pipe(
      /**
       * Find card by id
       */
      mergeMap(() => {
        return this._conversationService
          .findOrFetchByCardIds(forUserEmail, [cardId])
          .pipe(map((conversations: ConversationModel[]) => _.first(conversations)));
      }),
      /**
       * Remove all snooze tags and add new snooze tag
       */
      map((conversation: ConversationModel) => {
        conversation = this.removeAllSnoozeTagsOnCard(conversation);
        conversation = this.setSnoozeTag(conversation, dueDateUTC);

        return conversation;
      }),
      /**
       * Sync to BE, Save to DB and publish tag updates
       */
      mergeMap((conversation: ConversationModel) => {
        return this._tagService.syncSaveAndPublishCardTagUpdates(forUserEmail, conversation);
      }),
    );
  }

  private removeAllSnoozeTagsOnCard(conversation: ConversationModel): ConversationModel {
    conversation.setTags(_.filter(conversation.getTags(), (tag: Tag) => !snoozeTags.includes(tag.id)));
    return conversation;
  }

  private setSnoozeTag(conversation: ConversationModel, dueDateUTC: string): ConversationModel {
    conversation.setTags([...conversation.getTags(), TimedTagModel.buildSnoozeTag(dueDateUTC)]);
    return conversation;
  }
}
