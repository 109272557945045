import * as _ from 'lodash';
import { EMPTY, from, Observable, of, Subject, Subscription } from 'rxjs';
import { WatchdogService } from '../services/watchdog/watchdog.service';
import { SynchronizationStatusService } from './synchronization-status.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import {
  PushSyncData,
  RetrySyncData,
  SynchronizationMiddlewareService,
} from './synchronization-middleware/synchronization-middleware.service';
import { CONSTANTS } from '@shared/models/constants/constants';
import { catchError, concatMap, delay, exhaustMap, filter, map, mergeMap, repeat, tap } from 'rxjs/operators';
import { ElectronService } from '@shared/services/electron/electron';
import { SettingsService } from '../services/settings/settings.service';
import { StorageService } from '../../dta/shared/services/storage/storage.service';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { TrackingService } from '../../dta/shared/services/tracking/tracking.service';
import { SharedUserManagerService } from '../../dta/shared/services/shared-user-manager/shared-user-manager.service';
import { Logger } from '@shared/services/logger/logger';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { DataServiceShared } from '@shared/services/data/data.service';
import { SynchronizationService } from './synchronization.service';
import { PushSynchronizationModuleService } from '@shared/synchronization/push-synchronization/push-synchronization.module';
import { FlushDatabaseService } from '@shared/services/flush-db/flush-db.service';
import {
  HttpResponseEventData,
  UserRemove,
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { HttpResponseEventType } from '@dta/shared/models/http-events.model';
import { LogSynchronizationService } from './log-synchronization/log-synchronization.service';
import { LogService } from '@shared/api/log-loop/api.module';
import { HttpEventService } from '@shared/interceptors/http-event.service';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { Time } from '@dta/shared/utils/common-utils';
import { IPC } from '@dta/shared/communication/ipc-constants';

export abstract class SynchronizationFactory {
  protected _instances: { [userEmail: string]: SynchronizationService } = {};
  private logSyncService: LogSynchronizationService;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private currentUserEmailActive: string;

  private currentUserSub: Subscription;

  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _watchdogService: WatchdogService,
    protected _storageService: StorageService,
    protected _fileStorageService: FileStorageService,
    protected _status: SynchronizationStatusService,
    protected _notificationsService: NotificationsService,
    protected _trackingService: TrackingService,
    protected _dataService: DataServiceShared,
    protected _apiService: ApiService,
    protected _electronService: ElectronService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _settingsService: SettingsService,
    protected _contactStoreFactory: ContactStoreFactory,
    protected _httpResponseEventService: HttpEventService,
    protected _pushSynchronizationModuleService: PushSynchronizationModuleService,
    protected _flushDatabaseService: FlushDatabaseService,
    protected _logService: LogService,
    protected _databaseFactory: DatabaseFactory,
    protected _time: Time,
  ) {
    if (!CONSTANTS.PRODUCTION) {
      window['SyncFactory'] = this;
    }
  }

  get constructorName(): string {
    return 'SynchronizationFactory';
  }

  protected abstract initSynchronizationService(userEmail: string): SynchronizationService;

  protected abstract initLogSynchronizationService(): LogSynchronizationService;

  init() {
    this.initUserRemoveHandler();
    this.initTokenRefreshHandler();
    this.initSyncMiddleware();

    this.startLogSync();
  }

  ///////////////
  // GET METHODS
  ///////////////
  forUser(userEmail: string): SynchronizationService {
    if (_.isNil(this._instances[userEmail])) {
      this._instances[userEmail] = this.createSyncServiceForUser(userEmail);
    }

    return this._instances[userEmail];
  }

  forAllActive(): SynchronizationService[] {
    return _.values(this._instances);
  }

  ///////////////////
  // PRIVATE HELPERS
  ///////////////////

  stopSyncForAllUsers() {
    from(this._sharedUserManagerService.getAllUserEmails())
      .pipe(
        map((userEmail: string) => {
          return this.forUser(userEmail);
        }),
        tap((syncService: SynchronizationService) => {
          syncService.stop();
        }),
      )
      .subscribe();
  }

  /**
   * Stop and destroy syncService and stores for user being removed
   */
  private initUserRemoveHandler() {
    this._sharedUserManagerService.userRemove$
      .pipe(
        filter((removeEvent: UserRemove) => {
          return !_.isNil(this._instances[removeEvent.userEmail]);
        }),
        mergeMap((removeEvent: UserRemove) => {
          return this.stopAndRemoveForUser(removeEvent.userEmail);
        }),
      )
      .subscribe();
  }

  private stopInstanceForUser(userEmail) {
    this._instances[userEmail].stop();
  }

  private stopAndRemoveForUser(userEmail: string): Observable<any> {
    // Destroy database
    return this._instances[userEmail].destroy().pipe(
      tap(() => {
        // Stop and remove sync service
        delete this._instances[userEmail];

        this._contactStoreFactory.removeForUser(userEmail);
      }),
    );
  }

  /**
   * Start syncService for newly added user
   */
  public initUserSwitchHandler(email: string) {
    this.currentUserSub?.unsubscribe();
    this.currentUserSub = of(email)
      .pipe(
        filter(_email => !!_email),
        map((newUserEmail: string) => {
          if (email === this.currentUserEmailActive) {
            return this.forUser(email);
          }

          if (this.currentUserEmailActive) {
            this.stopInstanceForUser(this.currentUserEmailActive);
          }

          if (!this._sharedUserManagerService.getUserByEmail(email)) {
            this._sharedUserManagerService.handleTokenRevokedForUser(
              email,
              'SynchronizationFactory.handleTokenRevokeEvent',
            );
            this._electronService.ipcRenderer.send(IPC.REDO_INIT_SYNC);
          }

          // Init store
          this.initStoreForUser(newUserEmail);

          // Save current user
          this.currentUserEmailActive = newUserEmail;

          // Init sync
          return this.forUser(newUserEmail);
        }),
        concatMap((syncService: SynchronizationService) => {
          Logger.customLog(
            `${this.constructorName}.initUserSetHandler: got syncService for ${syncService.userEmail}`,
            LogLevel.LOG,
            LogTag.SYNC,
          );

          // Start sync
          return syncService.init$.pipe(
            tap(() => {
              Logger.customLog(
                `${this.constructorName}.syncService.init$ done. Will start sync ${syncService.userEmail}`,
                LogLevel.LOG,
                LogTag.SYNC,
              );

              syncService.start();
            }),
          );
        }),
      )
      .subscribe();
  }

  private initTokenRefreshHandler() {
    this._httpResponseEventService.events$
      .pipe(
        filter((event: HttpResponseEventData) => event.httpResponseEvent.type === HttpResponseEventType.unauthorized),
        exhaustMap(() => this._dataService.AuthService.refreshAccessTokenForAllUsers()),
        /**
         * Catch any error. This way subscription does not complete.
         */
        catchError(err => {
          Logger.customLog(
            `Error in ${this.constructorName}:initTokenRefreshHandler. Err: ${err}`,
            LogLevel.ERROR,
            LogTag.SYNC,
          );
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private getSyncService(userEmail: string): SynchronizationService {
    if (!userEmail) {
      throw new Error('userEmail cannot be nil');
    }

    return this.forUser(userEmail);
  }

  private initStoreForUser(forUserEmail: string) {
    this._contactStoreFactory.forUser(forUserEmail);
  }

  private initSyncMiddleware() {
    this._syncMiddleware.enqueuePushSynchronization$
      .pipe(
        tap((data: PushSyncData) => {
          let syncService = this.getSyncService(data.forUserEmail);
          syncService.enqueuePushSynchronization(data.models);
        }),
      )
      .subscribe();

    this._syncMiddleware.dequeuePushSynchronization$
      .pipe(
        tap((data: PushSyncData) => {
          let syncService = this.getSyncService(data.forUserEmail);
          syncService.dequeuePushSynchronization(data.models);
        }),
      )
      .subscribe();

    this._syncMiddleware.enqueueRetrySynchronization$
      .pipe(
        tap((data: RetrySyncData) => {
          let syncService = this.getSyncService(data.forUserEmail);
          syncService.enqueueRetrySynchronization(data.models);
        }),
      )
      .subscribe();

    this._syncMiddleware.dequeueRetrySynchronization$
      .pipe(
        tap((data: RetrySyncData) => {
          let syncService = this.getSyncService(data.forUserEmail);
          syncService.dequeueRetrySynchronization(data.data);
        }),
      )
      .subscribe();
  }

  protected createSyncServiceForUser(userEmail: string): SynchronizationService {
    Logger.customLog(
      `${this.constructorName}.createSyncServiceForUser: will create sync for user: ${userEmail}`,
      LogLevel.LOG,
      LogTag.SYNC,
    );

    let service = this.initSynchronizationService(userEmail);
    service.init();
    return service;
  }

  private startLogSync() {
    if (!this.logSyncService) {
      this.logSyncService = this.initLogSynchronizationService();
    }

    if (this.unsubscribe$) {
      this.unsubscribe$.next(void 0);
      this.unsubscribe$.complete();
    }

    this.unsubscribe$ = new Subject<void>();
    this.logSyncService.startLogSync(this.unsubscribe$);

    of(undefined)
      .pipe(
        filter(() => {
          // Restart when not active
          return !this.logSyncService.active;
        }),
        tap(() => {
          this.startLogSync();
        }),
        delay(10 * 1000),
        repeat(),
        catchError(err => {
          Logger.customLog(this.constructorName + ':startLogSync error: ' + err, LogLevel.ERROR, LogTag.SYNC);
          return of(undefined);
        }),
      )
      .subscribe();
  }
}
