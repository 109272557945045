import { Pipe, PipeTransform } from '@angular/core';
import { isUserContact } from '@shared/modules/contacts/common/helpers/is-user-contact';
import { ContactBaseModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';

@Pipe({
  name: 'isAvailabilityStatusValid',
})
export class IsAvailabilityStatusValidPipe implements PipeTransform {
  transform(contact: ContactBaseModel): contact is UserModel {
    if (!isUserContact(contact)) {
      return false;
    }

    return contact.hasValidAvailabilityStatus();
  }
}
