import { Pipe, PipeTransform } from '@angular/core';
import { ContactType } from '@shared/api/api-loop/models';
import { ContactBaseModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';

@Pipe({
  name: 'isUser',
})
export class IsUserPipe implements PipeTransform {
  transform(contact: ContactBaseModel): contact is UserModel {
    return contact.$type === ContactType.USER || contact instanceof UserModel;
  }
}
