import * as _ from 'lodash';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Observable } from 'rxjs';
import { environment } from '@shared/environments/environment';

@Injectable()
export class HttpProxyHelperInterceptor implements HttpInterceptor {
  private externalLinks = [CONSTANTS.TENOR_API, CONSTANTS.TENOR_IMAGES, CONSTANTS.FILES_API];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Replace domain so proxy can catch the request (solved CORS issues in development)
    if (environment.usingProxy && !_.some(this.externalLinks, link => request.url.includes(link))) {
      request = request.clone({
        url: request.url.replace(/^.*\/\/[^\/\?]+/, ''),
      });
    }

    return next.handle(request);
  }
}
