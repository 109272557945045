import { Component } from '@angular/core';
import {
  BaseFormControlComponent,
  provideControlValueAccessor
} from '@shared/modules/form-controls/base-form-control.component';

@Component({
  selector: 'loop-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss'],
  providers: [provideControlValueAccessor(CheckboxControlComponent)]
})
export class CheckboxControlComponent extends BaseFormControlComponent<boolean> {
  protected focusHandler(): void {
    //noop
  }
}
