import { Pipe, PipeTransform } from '@angular/core';
import { TagLabel } from '@shared/api/api-loop/models/tag-label';
import { SharedTagLabel } from '@shared/api/api-loop/models/shared-tag-label';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Pipe({
  name: 'isLabelSelected',
})
export class IsLabelSelectedPipe implements PipeTransform {
  transform(conversation: ConversationModel, tag: TagLabel | SharedTagLabel): boolean {
    return (
      !!conversation?.sharedTags?.tags?.resources?.some(_tag => _tag.id === tag.id) ||
      !!conversation?.tags?.tags?.resources?.some(_tag => _tag.id === tag.id)
    );
  }
}
