import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'loop-modal-form-footer',
  template: `
    <div class="browser-modal-form-footer-content">
      <ng-container *ngTemplateOutlet="ngContentTpl"></ng-container>
    </div>

    <ng-template #ngContentTpl><ng-content></ng-content></ng-template>
  `,
  styleUrls: ['./modal-form-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFormFooterComponent {
  @HostBinding('class.single-button') @Input() useSingleButtonLayout: boolean = false;
}
