<div class="settings-container">
  <ng-template [ngIf]="!createdChannel" [ngIfElse]="createSuccessful">
    <div class="main-container">
      <!-- Back button -->
      <div *ngIf="!steps[step].hidden" class="back-button" (click)="goBack()">
        <svg-comp [src]="backSvg"></svg-comp>
        {{ backButtonText }}
      </div>

      <!-- Step 1 Overview -->
      <ng-template [ngIf]="steps[step].id === SetupStep.Overview">
        <div class="title">{{ steps[step].id }}</div>

        <div class="sub-title">{{ textBank.overviewSubtitle }}</div>

        <simple-input
          [autoFocus]="true"
          [placeholder]="textBank.namePlaceholder"
          [label]="textBank.nameLabel"
          [(value)]="data.name"
        >
        </simple-input>

        <simple-input
          [placeholder]="textBank.descriptionPlaceholder"
          [label]="'Description'"
          [(value)]="data.description"
        >
        </simple-input>
      </ng-template>

      <!-- Step 2 Channels -->
      <ng-template [ngIf]="steps[step].id === SetupStep.Channels">
        <div class="title">{{ steps[step].title }}</div>

        <div class="sub-title">{{ textBank.channelSubtitle }}</div>

        <notification-card [title]="textBank.channelNotificationTitle" [content]="textBank.channelNotificationContent">
        </notification-card>

        <div class="channel-cards">
          <ng-template ngFor [ngForOf]="channelOptions" let-channel>
            <div
              (click)="!(channel.disabled || offline) && channel.onClick()"
              [trackUserClick]="{
                                forUserEmail: currentUserEmail,
                                location: trackingLocation,
                                action: channel.description,
                                additionalProperties: additionalTrackingProperties
                            }"
              class="channel-card"
              [attr.id]="channel.id"
              [class.disabled]="channel.disabled"
              [tooltip]="{
                                content: channel.disabled 
                                    ? 'Only available if your Loop account email is from a Google service' 
                                    : (offline ? 'You need to be online to continue' : undefined), 
                                placement: TooltipPlacement.BOTTOM
                            }"
              [class.offline]="offline"
            >
              <svg-comp [src]="channel.svg"></svg-comp>
              <div>{{ channel.description }}</div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="steps[step].hidden" class="error">{{ currentActionErrorMessage}}</div>
      </ng-template>

      <!-- Step 3 Creating inbox -->
      <ng-template [ngIf]="steps[step].id === SetupStep.CreatingInbox">
        <div *ngIf="currentActionErrorMessage" class="action-error">{{ currentActionErrorMessage }}</div>
        <loader *ngIf="!currentActionErrorMessage" [text]="'Creating personal inbox'"></loader>
      </ng-template>

      <!-- Step 3 Members -->
      <ng-template [ngIf]="steps[step].id === SetupStep.Members">
        <div class="title">{{ steps[step].id }}</div>

        <div class="sub-title">
          Start typing names of people you’d like to invite to <strong>{{ data.name }}</strong> or select suggestions
          from your contact list.
        </div>

        <notification-card
          [title]="textBank.membersNotificationTitle"
          [content]="textBank.membersNotificationContent"
          [ctaUrl]="textBank.membersNotificationCtaUrl"
          [ctaCopy]="textBank.membersNotificationCtaCopy"
        >
        </notification-card>

        <div class="autosuggest-wrapper">
          <svg-comp [src]="addMorePeopleSvg"></svg-comp>
          <input
            type="text"
            class="allow-paste"
            (keyup)="onInputKeyEvent($event)"
            [placeholder]="'Enter teammate name or email'"
            [(ngModel)]="userQuery"
            [focusStateNotifier]="'textSecondary'"
            (input)="query$.next(userQuery)"
          />

          <autosuggest
            *ngIf="userQuery"
            [startQuery]="userQuery"
            [limit]="5"
            [excludeMe]="true"
            [filter]="data?.members"
            [isOnCreateSharedInbox]="true"
            [ignoreGroups]="true"
            [ignoreNotRegistered]="false"
            [createContactIfEmpty]="true"
            [currentIndexEvent]="currentIndexEvent"
            (selectedItemEvent)="selectedItemEvent($event)"
            (clicked)="addMember($event)"
            [query$]="query$"
          >
          </autosuggest>
        </div>

        <suggested-contacts
          [showTitle]="true"
          [ignoreGroups]="true"
          [excludeCurrentUser]="true"
          [currentRecipients]="data?.members"
          (onContactAdd)="addMember($event)"
        >
        </suggested-contacts>

        <div *ngIf="data?.members?.length" class="loop-users-list loop-users-list-table">
          <ng-template ngFor [ngForOf]="data?.members" let-user>
            <div class="loop-user-item">
              <avatar-image [contact]="user" [extraClass]="'email-avatar'"></avatar-image>

              <div class="name-description">
                <div class="name">{{ user.name }}</div>
                <div class="description">{{ user.email }}</div>
              </div>

              <div class="remove" (click)="removeMember(user)">
                <svg-comp [src]="trashSvg"></svg-comp>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>

    <create-channel-sidebar
      *ngIf="!steps[step].hidden"
      [step]="step"
      [steps]="steps"
      [channelName]="data?.name"
      [currentActionErrorMessage]="currentActionErrorMessage"
      [nextButtonText]="nextButtonText"
      [isActionInProgress]="isActionInProgress"
      [currentUserEmail]="currentUserEmail"
      [trackingLocation]="trackingLocation"
      [additionalTrackingProperties]="additionalTrackingProperties"
      (nextStep$)="nextStep()"
    >
    </create-channel-sidebar>
  </ng-template>

  <ng-template #createSuccessful>
    <create-successful
      [successTitle]="textBank.successTitle"
      [channel]="createdChannel"
      [ctaCopy]="textBank.successCtaCopy"
      [hasInvitees]="data?.members?.length > 0"
    >
    </create-successful>
  </ng-template>
</div>
