import { Pipe, PipeTransform } from '@angular/core';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { getFileExtension } from '@shared/utils/get-file-extension';
import { FileModel } from '@dta/shared/models-api-loop/file.model';

@Pipe({
  name: 'isPdf',
})
export class IsPdfPipe implements PipeTransform {
  transform(file: FileModel | IFile): boolean {
    return getFileExtension(file.name).toLowerCase() === 'pdf';
  }
}
