<div class="status-dialog">
  <modal
    #statusPopup
    [isFullscreen]="true"
    [header]="true"
    [footer]="true"
    [modalClass]="'error-popup-modal'"
    [hideCloseButton]="true"
    [zindex]="5000"
    (onClose)="onClose()"
    (onModalClick)="closeDatePicker()"
  >
    <!-- Modal header -->
    <modal-header class="status-header"> Set your status </modal-header>

    <modal-content *ngIf="statusPopup && statusPopup.isOpened">
      <div class="body-wrapper">
        <!-- Input -->
        <div class="input-wrapper">
          <emoji-picker
            [extraClass]="'prepend-input'"
            [positionX]="emojiPosition.RIGHT"
            [positionY]="emojiPosition.BOTTOM"
            [emojiSelected]="status.emoji"
            (sendEmojiEvent)="status.emoji = $event"
          >
          </emoji-picker>

          <input
            class="input"
            [type]="'text'"
            [placeholder]="'What\'s your status'"
            (focus)="onInputFocus()"
            (input)="onValueChange(status)"
            [(ngModel)]="status.name"
          />

          <svg-comp
            *ngIf="status.name || status.emoji"
            class="svg-icon"
            [src]="closeMediumSvg"
            (click)="initStatus()"
            [tooltip]="{content: 'Clear all', placement: TooltipPlacement.BOTTOM}"
          >
          </svg-comp>
          <div *ngIf="status && status.name && status.name.length > 128" class="error-message">
            Status name is too long
          </div>
        </div>

        <loader
          *ngIf="showLoader && availabilityStatuses.length == 0 && !status.name && !status.emoji"
          [text]="'Loading predefined statuses'"
        >
        </loader>

        <div class="predefined-statuses-title" *ngIf="!status.name && !status.emoji && !showLoader">
          Available statuses
        </div>

        <!-- Predefined statuses -->
        <div class="predefined-statuses" *ngIf="!status.name && !status.emoji">
          <ng-template ngFor let-avaStatus [ngForOf]="availabilityStatuses" let-i="index">
            <div class="status-wrapper" (click)="onPredefinedStatusClick(avaStatus)">
              <div>{{ avaStatus.emoji }}</div>
              <div>{{ avaStatus.name }}</div>
            </div>
          </ng-template>
        </div>

        <div *ngIf="status.name || status.emoji" class="option-wrapper">
          <!-- Status options -->
          <div class="option-row">
            <div class="clear-after-wrapper">
              <div class="description-title">Clear after</div>
              <select-dropdown
                [capitalize]="true"
                [options]="dropdownOptions"
                (selectionChange$)="onDropdownSelect($event)"
              >
              </select-dropdown>
            </div>
            <ng-template [ngIf]="datePickerOpen">
              <date-picker
                class="center-flex date-picker"
                [extraClass]="'dropdown-picker'"
                [backButtonIncluded]="true"
                [selectFutureDate]="true"
                [showTimeInput]="true"
                [initialSelectedTime]="dateSelected"
                (backClicked)="closeDatePicker()"
                (dateSelected)="onDateSelected($event)"
              >
              </date-picker>
            </ng-template>
          </div>

          <!-- Available for Assignment -->
          <div class="option-row">
            <div>
              <div class="description-title">Not available for assignment</div>
            </div>

            <div class="checkbox-toggler" [ngClass]="{checked: !status.enabledAssignments}">
              <svg-comp [src]="checkSvg"></svg-comp>
              <svg-comp [src]="closeSvg"></svg-comp>
              <input
                id="status-checkbox"
                type="checkbox"
                [(ngModel)]="status.enabledAssignments"
                name="triage"
                (ngModelChange)="status.enabledAssignments != status.enabledAssignments"
              />
              <label for="status-checkbox"><span></span></label>
            </div>
          </div>

          <!-- Out of office -->
          <div class="option-row">
            <div>
              <div class="description-title">Send out of office reply</div>
              <div class="description-subtitle" *ngIf="!hasOutOfOffice">
                You have not setup your out of office responder.
                <a (click)="openOutOfOffice()"> Click here </a>
                to finish your setup.
              </div>
            </div>

            <div class="checkbox-toggler" [ngClass]="{ checked: status.outOfOffice }">
              <svg-comp [src]="checkSvg"></svg-comp>
              <svg-comp [src]="closeSvg"></svg-comp>
              <input
                id="out-of-office-checkbox"
                type="checkbox"
                [disabled]="!hasOutOfOffice"
                [(ngModel)]="status.outOfOffice"
                name="triage"
                (ngModelChange)="status.outOfOffice != status.outOfOffice"
              />
              <label for="out-of-office-checkbox"><span></span></label>
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="button-wrappers">
          <!-- Cancel -->
          <button class="button cancel" id="confirmation-shared-cancel" (click)="onConfirm(false)">Cancel</button>

          <!-- Save -->
          <button
            class="button primary-green"
            [class.disabled]="!status.name || !status.emoji || status.name.length > 128"
            (click)="onConfirm(true)"
          >
            Save
          </button>
        </div>
      </div>
    </modal-content>
  </modal>
</div>
