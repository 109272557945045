/* tslint:disable */
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';
import { BaseApiCacheService } from '@shared/api/api-cache/base-api-cache.service';
import { inject } from '@angular/core';

/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

/**
 * Base class for API services
 */
export class BaseService {
  protected readonly _config: ApiConfiguration = inject(ApiConfiguration);
  protected readonly _http: HttpClient = inject(HttpClient);
  protected readonly _baseApiCacheService: BaseApiCacheService = inject(BaseApiCacheService);

  private _rootUrl: string;

  /**
   * Returns the root url for API operations. If not set directly in this
   * service, will fallback to ApiConfiguration.rootUrl.
   */
  get rootUrl(): string {
    return this._rootUrl || this._config.rootUrl;
  }

  /**
   * Sets the root URL for API operations in this service.
   */
  set rootUrl(rootUrl: string) {
    this._rootUrl = rootUrl;
  }

  /**
   * Creates a new `HttpParams` with the correct codec
   */
  protected newParams(): HttpParams {
    return new HttpParams({
      encoder: new ParameterCodec()
    });
  }

  protected newHeaders(forUserEmail: string): HttpHeaders {
    return forUserEmail ? new HttpHeaders({ __forUserEmail: forUserEmail }) : new HttpHeaders();
  }
}
