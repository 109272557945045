import { Subscription } from 'rxjs';

const isFunction = obj => typeof obj === 'function';
const isSubscription = obj => obj && isFunction(obj.unsubscribe) && obj instanceof Subscription;
const isSubscriptionArray = obj => isArray(obj) && obj.every(prop => isSubscription(prop));
const isArray = obj => typeof obj === 'object' && Array.isArray(obj);

function doUnsubscribe(target: any, propName: string) {
  let property = target[propName];

  // Handle subscription
  if (isSubscription(property)) {
    !property.closed && property.unsubscribe();
  }

  // Handle array of subscriptions
  if (isSubscriptionArray(property) && property.length > 0) {
    property.forEach(prop => {
      !prop.closed && prop.unsubscribe();
    });
  }
}

export function AutoUnsubscribe({ eventName = 'ngOnDestroy' } = {}): Function {
  return function (constructor: Function) {
    let original = constructor.prototype[eventName];

    // Throw error if ngOnDestroy not implemented
    if (!isFunction(original)) {
      let msg = `${constructor.name} is using @AutoUnsubscribe but does not implement OnDestroy`;
      throw new Error(msg);
    }

    constructor.prototype[eventName] = function () {
      // Unsubscribe if function or reset if array/dictionary

      for (let propName in this) {
        doUnsubscribe(this, propName);
      }

      // Call super.ngOnDestroy / event
      if (isFunction(original)) {
        original.apply(this, arguments);
      }
    };
  };
}
