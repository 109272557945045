import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  BaseFormControlComponent,
  provideControlValueAccessor
} from '@shared/modules/form-controls/base-form-control.component';

export type TextControlType = 'text' | 'search' | 'email' | 'tel' | 'url' | 'number';

@Component({
  selector: 'loop-text-control',
  templateUrl: './text-control.component.html',
  styleUrls: ['./text-control.component.scss'],
  providers: [provideControlValueAccessor(TextControlComponent)]
})
export class TextControlComponent extends BaseFormControlComponent<string> {
  @Input() controlType: TextControlType = 'text';
  @Input() isReadonly: boolean = false;
  @Input() endIconName?: string | TemplateRef<void>;
  @Input() startIconName?: string | TemplateRef<void>;

  @ViewChild('inputElement') private readonly inputElement?: ElementRef<HTMLInputElement>;

  @Output() endIconClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() startIconClick: EventEmitter<void> = new EventEmitter<void>();

  protected focusHandler(): void {
    this.inputElement?.nativeElement?.focus();
  }
}
