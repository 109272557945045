import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CollectionNameWeb, PushSyncDataTypeIndex, RetryAfterTimestampIndex } from '../../database-schema';
import { PushSyncDaoServiceI } from '@shared/database/dao/push-sync/push-sync-dao.service';
import { ListOfTagsModel } from '@dta/shared/models-api-loop/tag.model';
import { Observable, of } from 'rxjs';
import { ModelMappers } from '@dta/shared/models-api-loop/model-mappers/model-mappers';
import { map, mergeMap } from 'rxjs/operators';
import { CommentDraftModel, CommentMailModel, CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { DatabaseServiceWeb } from '../../database.service.web';
import { PushSyncModel, PushSyncModelBase } from '@dta/shared/models-api-loop/push-sync.model';
import { SharedTagFolderModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

@Injectable()
export class PushSyncDaoServiceWeb extends BaseDaoServiceWeb<BaseModel, ResourceBase> implements PushSyncDaoServiceI {
  constructor(protected _databaseFactory: DatabaseFactory) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'PushSyncDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.PushSync;
  }

  protected toModel(doc: ResourceBase): BaseModel {
    return ModelMappers.castToEndModel(doc);
  }

  removeListOfTags(forUserEmail: string, pushSyncModels: PushSyncModel[]): Observable<any> {
    let parentIds = _.map(pushSyncModels, pushSyncModel => pushSyncModel.data['parent'].id);

    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndex(PushSyncDataTypeIndex.indexName, ListOfTagsModel.type, this.collectionName);
      }),
      // Filter tags to be removed
      mergeMap((data: PushSyncModel[]) => {
        if (_.isEmpty(data)) {
          return of([]);
        }

        let dataToSave = _.filter(data, tag => !parentIds.includes(tag.data['parent'].id));

        return this.removeAllByIndex(
          forUserEmail,
          PushSyncDataTypeIndex.indexName,
          ListOfTagsModel.type,
          this.collectionName
        ).pipe(mergeMap(() => this.saveAllToQueue(forUserEmail, PushSyncModel.createList(dataToSave))));
      })
    );
  }

  removeDraftsForCommentMail(forUserEmail: string, comments: PushSyncModel[]): Observable<any> {
    if (_.isEmpty(comments)) {
      return of(undefined);
    }

    return this.db(forUserEmail).pipe(
      // Get all drafts
      mergeMap((db: DatabaseServiceWeb) =>
        db.findAllByIndex(PushSyncDataTypeIndex.indexName, CommentDraftModel.type, this.collectionName)
      ),
      // Filter drafts to be removed
      mergeMap((data: PushSyncModel[]) => {
        if (_.isEmpty(data)) {
          return of([]);
        }

        let draftCommentIdsToRemove = comments
          .map((model: PushSyncModel) => (<CommentMailModel>model.data)._ex.createdFromDraftCommentId)
          .filter(Boolean);

        let dataToSave = _.filter(
          data,
          model =>
            !draftCommentIdsToRemove.includes(model.data.id) && !draftCommentIdsToRemove.includes(model.data.clientId)
        );

        return this.removeAllByIndex(
          forUserEmail,
          PushSyncDataTypeIndex.indexName,
          CommentDraftModel.type,
          this.collectionName
        ).pipe(mergeMap(() => this.saveAllToQueue(forUserEmail, PushSyncModel.createList(dataToSave))));
      })
    );
  }

  removeAll(forUserEmail: string, models: BaseModel[]): Observable<BaseModel[]> {
    let ids = _.flatMap(models, model => [model.id, model._id]);
    ids = _.filter(ids, id => !_.isUndefined(id));

    return this.removeByIds(forUserEmail, ids).pipe(map(() => models));
  }

  getAllInQueue(forUserEmail: string): Observable<PushSyncModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return db.findAllByIndexBounded(
          RetryAfterTimestampIndex.indexName,
          undefined,
          new Date().getTime(),
          this.collectionName
        );
      }),
      map((dbDocs: PushSyncModelBase[]) => PushSyncModel.createList(dbDocs))
    );
  }

  saveAllToQueue(forUserEmail: string, models: PushSyncModel[]): Observable<PushSyncModel[]> {
    return this.db(forUserEmail).pipe(
      mergeMap((db: DatabaseServiceWeb) => {
        return of(models).pipe(
          mergeMap((_models: PushSyncModel[]) => {
            let dbObjects = _.map(_.cloneDeep(_models), model => model.toObject());
            return db.insertAll(dbObjects as PushSyncModel[], this.collectionName);
          }),
          map(() => models)
        );
      })
    );
  }

  removeAllFromQueue(forUserEmail: string, models: PushSyncModel[]): Observable<any> {
    let ids = _.flatMap(models, model => [model.data.id, model.data._id]);
    ids = _.filter(ids, id => !_.isUndefined(id));

    return this.removeByIds(forUserEmail, ids).pipe(map(() => models));
  }

  clearCollection(forUserEmail: string): Observable<any> {
    return this.removeAllInCollection(forUserEmail);
  }

  // DTA methods - not needed on web
  findConversationActions(forUserEmail: string): Observable<PushSyncModel[]> {
    throw new Error('Method not implemented.');
  }
  findFolders(forUserEmail: string): Observable<PushSyncModel[]> {
    throw new Error('Method not implemented.');
  }
  count(forUserEmail: string) {
    throw new Error('Method not implemented.');
  }
  forceSave(forUserEmail: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  findComments(forUserEmail: string): Observable<PushSyncModel[]> {
    throw new Error('Method not implemented.');
  }
  findTemplates(forUserEmail: string): Observable<PushSyncModel[]> {
    throw new Error('Method not implemented.');
  }
  findListOfTags(forUserEmail: string): Observable<PushSyncModel[]> {
    throw new Error('Method not implemented.');
  }
  findCardUpdates(forUserEmail: string): Observable<PushSyncModel[]> {
    throw new Error('Method not implemented.');
  }
  findAppointments(forUserEmail: string): Observable<PushSyncModel[]> {
    throw new Error('Method not implemented.');
  }
  updateCommentsParentByOldCardId(forUserEmail: string, oldCardId: string, comment: CommentModel) {
    throw new Error('Method not implemented.');
  }
  findAvailabilityStatuses(forUserEmail: string): Observable<PushSyncModel[]> {
    throw new Error('Method not implemented.');
  }
}
