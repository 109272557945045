<div *ngIf="attachment | isLoadingAttachment" class="loading-wrapper">
  <loop-loader-new></loop-loader-new>
</div>

<div
  class="flex common-attachment"
  (click)="onAttachmentClick($event, attachment)"
  (mouseenter)="isSingleActionVisible = true"
  (mouseleave)="isSingleActionVisible = false"
  [loopContextDropdown]="contextDropdownItems"
  [fileDragDrop]="attachment"
  [draggable]="true"
>
  <file-icon [file]="attachment" style="margin-top: 1px"></file-icon>
  <div class="flex items-center justify-between gap-half min-width-0 flex-1">
    <div class="flex flex-col min-width-0 flex-1">
      <loop-file-name class="file-name" [fileName]="attachment.name"></loop-file-name>
      <div class="file-size">{{ attachment.size | fileSize }}&nbsp;</div>
    </div>
    <ng-container *ngIf="dropdownItems.length === 1; else dropdownTpl">
      <div
        [class.d-none]="!isSingleActionVisible"
        class="download-file"
        alt="Download file"
        (click)="onDropdownItemClick($event, dropdownItems[0])"
      >
        <loop-icon [name]="dropdownItems[0].icon" width="16px" height="16px"></loop-icon>
      </div>
    </ng-container>
    <ng-template #dropdownTpl>
      <div>
        <loop-icon
          sleepClickEvent
          [loopClickDropdown]="dropdownItems"
          class="cursor-pointer clickable-icon"
          name="ellipsis-vertical"
          width="16px"
        >
        </loop-icon>
      </div>
    </ng-template>
  </div>

  <div *ngIf="isDeleteAvailable" class="delete-file" (click)="deleteAttachment(attachment)">
    <loop-icon name="circle-xmark" width="16px" height="16px"></loop-icon>
  </div>
</div>
