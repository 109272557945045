<div class="dropdown">
  <!-- Dropdown -->
  <div *ngIf="open" class="dropdown-menu">
    <!-- Header -->
    <div class="dropdown-header">
      <div class="title">TAGS</div>
      <div class="add-button" (click)="openTagsSettings()">
        <svg-comp [src]="addSvg"></svg-comp>
        <span>Add</span>
      </div>
    </div>

    <!-- Input -->
    <div class="input-wrapper">
      <svg-comp class="svg-icon" [src]="searchSvg"></svg-comp>
      <input
        type="text"
        class="allow-paste"
        autocomplete="off"
        placeholder="Search tags"
        [(ngModel)]="filterQuery"
        (input)="onInputChange()"
        [myAutoFocus]="true"
        [focusStateNotifier]="'textSecondary'"
      />
    </div>

    <div #itemsWrapper class="dropdown-items-wrapper">
      <ng-template ngFor let-label [ngForOf]="filteredLabels" let-i="index">
        <div
          class="dropdown-item"
          [class.selected]="i === selectionIndex"
          (click)="selectLabel(label)"
          (mouseenter)="setSelectionIndex(i, true)"
        >
          <!-- Hashtag -->
          <svg-comp [src]="hashtagSvg" [style.color]="label.color"> </svg-comp>

          <!-- Label name -->
          <span>{{label.name}}</span>

          <svg-comp *ngIf="label.$type === 'TagLabel'" class="private-lock" [src]="lockSvg"> </svg-comp>

          <!-- Check -->
          <svg-comp *ngIf="alreadyAdded(label)" class="check-mark" [src]="checkMarkSvg"> </svg-comp>
        </div>
      </ng-template>

      <div *ngIf="!availableLabels?.length" class="no-tags">Create tags by clicking Add above.</div>
    </div>
  </div>
</div>
