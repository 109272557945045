import { Observable } from 'rxjs';
import { BaseDaoService } from '../base/base-dao.service';
import { CardBase, CommentBase, ListOfTags } from '@shared/api/api-loop/models';
import {
  CommentBaseModel,
  CommentDraftModel,
  CommentModel,
  CommentTemplateModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import { CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ListOfTagsModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { ChannelChatsCollectionParams } from '@dta/ui/collections/comments/channel-chats.collection';

export interface CommentDaoServiceI {
  removeUndoData(forUserEmail: string, comment: CommentModel): Observable<any>;
  findLastCommentsByCardsForFoldering(forUserEmail: string, cards: CardModel[]): Observable<CommentBaseModel[]>;
  findCommentsByCard(forUserEmail: string, card: CardModel): Observable<CommentModel[]>;
  findCommentsBaseByCard(forUserEmail: string, card: CardModel): Observable<CommentModel[]>;
  findCommentsByTag(forUserEmail: string, tagId: string): Observable<CommentBaseModel[]>;
  findCommentsForOutbox(forUserEmail: string): Observable<CommentBaseModel[]>;
  findCommentsForCards(forUserEmail: string, cards: CardBase[]): Observable<CommentBaseModel[]>;
  updateCommentById(forUserEmail: string, id: string, update: Object): Observable<any>;
  updateCommentTagsById(forUserEmail: string, id: string, tags: ListOfTags);
  deleteCommentById(forUserEmail: string, id: string);
  findCommentsByCards(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
  ): Observable<CommentModel[]>;
  findCommentsByCardsAndTags(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
    tag: TagModel,
    includeTag: boolean,
  ): Observable<CommentModel[]>;
  findGroupedCommentIdsByCards(
    forUserEmail: string,
    cards: CardModel[],
    includeSnapshotComments: boolean,
  ): Observable<_.Dictionary<CommentBase[]>>;
  findComments(forUserEmail: string, comments: CommentBase[]): Observable<CommentModel[]>;
  findTagsByCard(forUserEmail: string, card: CardModel): Observable<ListOfTags[]>;
  findTagsByComment(forUserEmail: string, comment: CommentModel): Observable<ListOfTagsModel>;
  countAllTemplates(forUserEmail: string): Observable<Number>;
  findAllTemplates(forUserEmail: string): Observable<CommentTemplateModel[]>;
  findSharedDraftBySourceCardOrThrow(forUserEmail: string, card_id: string): Observable<CommentDraftModel>;
  addTagByComments(forUserEmail: string, comments: CommentModel[], tag: TagModel): Observable<any>;
  removeTagsByComments(forUserEmail: string, comments: CommentModel[], tags: TagModel[]): Observable<any>;
  updateTagsForComments(
    forUserEmail: string,
    comments: CommentModel[],
    addTag: TagModel,
    removeTags: TagModel[],
  ): Observable<any>;
  findChatCommentsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string,
  ): Observable<CommentModel[]>;
  findChatsByChatCard(forUserEmail: string, params: ChannelChatsCollectionParams): Observable<CommentModel[]>;
  countChatsByChatCard(forUserEmail: string, params: any): Observable<Number>;
  countOutboxComments(forUserEmail: string): Observable<Number>;
  findOutboxComments(forUserEmail: string): Observable<CommentBaseModel[]>;
  findCommentsBySearchQuery(
    forUserEmail: string,
    query: string,
    filter: any,
    offset: number,
    limit: number,
  ): Observable<CommentModel[]>;
  contactHasUnreadChat(forUserEmail: string, contact: ContactModel): Observable<boolean>;
  contactHasUnreadMail(forUserEmail: string, contact: ContactModel): Observable<boolean>;
  findCommentsWithUnsyncedAttachments(
    forUserEmail: string,
    size: number,
    commentBlacklist: CommentModel[],
    failedAttachmentsOnly: boolean,
    previewsOnly: boolean,
    parentCard?: CardModel,
    lastComment?: CommentModel,
  ): Observable<CommentModel[]>;
  findUnreadCommentsByCards(forUserEmail: string, cards: CardModel[]): Observable<CommentModel[]>;
  findCommentsWithTagIdByCards(forUserEmail: string, cards: CardBase[], tagId: string): Observable<CommentModel[]>;
  getLastCommentByCardForFoldering(forUserEmail: string, card: CardModel): Observable<CommentModel>;
  updateCommentsParentByOldCardId(forUserEmail: string, oldCardId: string, comment: CommentModel): Observable<any>;
  updateCommentWithCorrectTags(forUserEmail: string, comment: CommentModel): Observable<any>;
  findCommentsByQuoteIds(forUserEmail: string, commentIds: string[]): Observable<CommentModel[]>;
  findCommentsByIdsOrClientIds(forUserEmail: string, idOrClientIds: string[]): Observable<CommentModel[]>;
  findCommentsByParentIdsOrClientIds(forUserEmail: string, idOrClientIds: string[]): Observable<CommentModel[]>;
  removeAllTemplates(forUserEmail: string): Observable<any>;
}

export abstract class CommentDaoService
  extends BaseDaoService<CommentModel, CommentBase>
  implements CommentDaoServiceI
{
  abstract removeUndoData(forUserEmail: string, comment: CommentModel): Observable<any>;
  abstract findLastCommentsByCardsForFoldering(
    forUserEmail: string,
    cards: CardModel[],
  ): Observable<CommentBaseModel[]>;
  abstract findCommentsByCard(forUserEmail: string, card: CardModel): Observable<CommentModel[]>;
  abstract findCommentsBaseByCard(forUserEmail: string, card: CardModel): Observable<CommentModel[]>;
  abstract findCommentsByTag(forUserEmail: string, tagId: string): Observable<CommentBaseModel[]>;
  abstract findCommentsForOutbox(forUserEmail: string): Observable<CommentBaseModel[]>;
  abstract findCommentsForCards(forUserEmail: string, cards: CardBase[]): Observable<CommentBaseModel[]>;
  abstract updateCommentById(forUserEmail: string, id: string, update: Object): Observable<any>;
  abstract updateCommentTagsById(forUserEmail: string, id: string, tags: ListOfTags);
  abstract deleteCommentById(forUserEmail: string, id: string);
  abstract findCommentsByCards(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
  ): Observable<CommentModel[]>;
  abstract findCommentsByCardsAndTags(
    forUserEmail: string,
    cards: CardModel[],
    baseResourceOnly: boolean,
    includeSnapshotComments: boolean,
    tag: TagModel,
    includeTag: boolean,
  ): Observable<CommentModel[]>;
  abstract findGroupedCommentIdsByCards(
    forUserEmail: string,
    cards: CardModel[],
    includeSnapshotComments: boolean,
  ): Observable<_.Dictionary<CommentBase[]>>;
  abstract findComments(forUserEmail: string, comments: CommentBase[]): Observable<CommentModel[]>;
  abstract findTagsByCard(forUserEmail: string, card: CardModel): Observable<ListOfTags[]>;
  abstract findTagsByComment(forUserEmail: string, comment: CommentModel): Observable<ListOfTagsModel>;
  abstract countAllTemplates(forUserEmail: string): Observable<Number>;
  abstract findAllTemplates(forUserEmail: string): Observable<CommentTemplateModel[]>;
  abstract findSharedDraftBySourceCardOrThrow(forUserEmail: string, card_id: string): Observable<CommentDraftModel>;
  abstract addTagByComments(forUserEmail: string, comments: CommentModel[], tag: TagModel): Observable<any>;
  abstract removeTagsByComments(forUserEmail: string, comments: CommentModel[], tags: TagModel[]): Observable<any>;
  abstract updateTagsForComments(
    forUserEmail: string,
    comments: CommentModel[],
    addTag: TagModel,
    removeTags: TagModel[],
  ): Observable<any>;
  abstract findChatCommentsToPurge(
    forUserEmail: string,
    createdCutoffTime: string,
    accessedCutoffTime: string,
  ): Observable<CommentModel[]>;
  abstract findChatsByChatCard(forUserEmail: string, params: ChannelChatsCollectionParams): Observable<CommentModel[]>;
  abstract countChatsByChatCard(forUserEmail: string, params: any): Observable<Number>;
  abstract countOutboxComments(forUserEmail: string): Observable<Number>;
  abstract findOutboxComments(forUserEmail: string): Observable<CommentBaseModel[]>;
  abstract findCommentsBySearchQuery(
    forUserEmail: string,
    query: string,
    filter: any,
    offset: number,
    limit: number,
  ): Observable<CommentModel[]>;
  abstract contactHasUnreadChat(forUserEmail: string, contact: ContactModel): Observable<boolean>;
  abstract contactHasUnreadMail(forUserEmail: string, contact: ContactModel): Observable<boolean>;
  abstract findCommentsWithUnsyncedAttachments(
    forUserEmail: string,
    size: number,
    commentBlacklist: CommentModel[],
    failedAttachmentsOnly: boolean,
    previewsOnly: boolean,
    parentCard?: CardModel,
    lastComment?: CommentModel,
  ): Observable<CommentModel[]>;
  abstract findUnreadCommentsByCards(forUserEmail: string, cards: CardModel[]): Observable<CommentModel[]>;
  abstract findCommentsWithTagIdByCards(
    forUserEmail: string,
    cards: CardBase[],
    tagId: string,
  ): Observable<CommentModel[]>;
  abstract getLastCommentByCardForFoldering(forUserEmail: string, card: CardModel): Observable<CommentModel>;
  abstract updateCommentsParentByOldCardId(
    forUserEmail: string,
    oldCardId: string,
    comment: CommentModel,
  ): Observable<any>;
  abstract updateCommentWithCorrectTags(forUserEmail: string, comment: CommentModel): Observable<any>;
  abstract findCommentsByQuoteIds(forUserEmail: string, commentIds: string[]): Observable<CommentModel[]>;
  abstract findCommentsByIdsOrClientIds(forUserEmail: string, idOrClientIds: string[]): Observable<CommentModel[]>;
  abstract findCommentsByParentIdsOrClientIds(
    forUserEmail: string,
    idOrClientIds: string[],
  ): Observable<CommentModel[]>;
  abstract removeAllTemplates(forUserEmail: string): Observable<any>;
}
