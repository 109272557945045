<ng-container *ngIf="conversation$ | async; let conversation">
  <ng-container *ngIf="assigneeTagData$ | async; let assigneeTagData">
    <ng-container *ngIf="assigneeTagData.tag; else unassignedResolvedTpl">
      <ng-container *ngIf="assigneeTagData.contact; let contact">
        <ng-template #prefixTpl>
          <avatar-image
            class="shared-tag-image center-avatar"
            [disablePopup]="true"
            [contact]="contact"
            [extraClass]="'avatar-16'"
            [sharedTag]="true"
          >
          </avatar-image>
        </ng-template>
        <loop-conversation-tag
          loopCustomDropdown
          [content]="contact | userName: true : true : false : false : true"
          [color]="assigneeTagData.tag.color"
          [dropdownConfiguration]="assignDropdownComponentConfiguration"
          [componentParams]="{ conversation: conversation }"
          [prefixTpl]="prefixTpl"
          [suffixTpl]="suffixTpl"
        >
        </loop-conversation-tag>
      </ng-container>

      <ng-template #suffixTpl>
        <div class="flex items-center">
          <svg-comp class="dropdown-icon" [style.color]="assigneeTagData.tag.color" [src]="dropdownSvg"> </svg-comp>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #unassignedResolvedTpl>
    <loop-conversation-tag
      loopCustomDropdown
      [content]="(isResolved$ | async) ? 'Resolved' : 'Assign'"
      [color]="(isResolved$ | async) ? '#5ABA62' : '--medium-ui-element'"
      [dropdownConfiguration]="assignDropdownComponentConfiguration"
      [componentParams]="{ conversation: conversation }"
      [suffixTpl]="suffixTpl"
    >
    </loop-conversation-tag>

    <ng-template #suffixTpl>
      <div class="flex items-center">
        <svg-comp class="dropdown-icon" [style.color]="resolveUnassignColor$ | async" [src]="dropdownSvg"> </svg-comp>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
