/* tslint:disable */

/**
 * Possible View Types
 */
export type ViewEnum = 'LoopInbox' | 'PersonalInbox' | 'Channel';

export module ViewEnum {
  export const LOOP_INBOX: ViewEnum = 'LoopInbox';
  export const PERSONAL_INBOX: ViewEnum = 'PersonalInbox';
  export const CHANNEL: ViewEnum = 'Channel';

  export function values(): ViewEnum[] {
    return [LOOP_INBOX, PERSONAL_INBOX, CHANNEL];
  }
}
