import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IgnorableInterceptor } from '@shared/interceptors/ignorable-interceptor';

@Injectable()
export abstract class BaseErrorInterceptor extends IgnorableInterceptor implements HttpInterceptor {
  abstract readonly httpStatusCode: HttpStatusCode;

  // Ability to match only specific errorCode. When null, error type is not validated (and only status code is).
  abstract readonly errorCode: string | null;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldBeIgnored(req)) {
      return this.ignoreInterceptor(req, next);
    }
    return next.handle(req).pipe(catchError(err => this.catchError(err)));
  }

  protected abstract handleError(err: HttpErrorResponse): Observable<any>;

  protected emptyResponse(): Observable<any> {
    return EMPTY;
  }

  protected shouldCatchError(err: Error): err is HttpErrorResponse {
    return (
      err instanceof HttpErrorResponse &&
      err.status === this.httpStatusCode &&
      (!this.errorCode || (!!this.errorCode && this.errorCode === err.error?.errorCode))
    );
  }

  protected catchError(err: Error, _config?: any): Observable<any> {
    if (!this.shouldCatchError(err)) {
      return throwError(err);
    }
    return this.handleError(err);
  }
}
