import { ResourceBase } from '@shared/api/api-loop/models';
import { Observable } from 'rxjs';
import { BaseDaoService } from '../base/base-dao.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { ConversationChangeModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-change.model';

export interface ConversationChangeDaoServiceI {
  findAllConversationsChanges(forUserEmail: string): Observable<ConversationChangeModel[]>;
}

export abstract class ConversationChangeDaoService
  extends BaseDaoService<BaseModel, ResourceBase>
  implements ConversationChangeDaoServiceI
{
  abstract findAllConversationsChanges(forUserEmail: string): Observable<ConversationChangeModel[]>;
}
