import { CardModel } from '../models-api-loop/conversation-card/card/card.model';
import { FileModel } from '../models-api-loop/file.model';

export interface RecentSearchFilter {
  Type: SearchFilterType;
  Name: string;
  Email: string;
  IsGroup: boolean;
  Ids: string[];
}

export interface RecentSearch {
  Query: string;
  Filter: RecentSearchFilter;
  Date?: Date;
}

export interface SearchResultType {
  type: 'offline' | 'online';
  data: SearchResult;
  onlyLocal?: boolean;
}

export enum SearchFilterType {
  ANY = 'Any',
  FROM = 'From',
  TO = 'To',
}

export class SearchResult {
  Card?: CardModel[];
  File?: FileModel[];
}
