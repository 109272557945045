<loop-form-control-wrapper [loopContextDropdown]="wysiwygOptions" [baseFormControl]="this" class="flex flex-col flex-1">
  <div
    loopMention
    [overrideMentionOptions]="overriddenContactOptions"
    [isLoopMentionDisabled]="isLoopMentionDisabled"
    [loopMentionPreferredPosition]="MentionPlacement_Bottom"
    (loopMentionContactClick)="handleMentionContactClick($event)"
    class="froala-wrapper"
    [class.paint-roller]="!!copiedStyle"
  >
    <div id="froala-toolbar-container-base" class="toolbar"></div>
    <div
      *ngIf="showFroalaToolbar"
      #froala
      class="froala-new"
      [class.disabled]="isDisabled$ | async"
      [froalaEditor]="options"
      [froalaModel]="value$ | async"
      (click)="onClick($event)"
      (froalaModelChange)="handleModelChange($event)"
    ></div>
  </div>

  <ng-container *ngFor="let appendedContentTpl of appendedContentTpls">
    <ng-container *ngTemplateOutlet="appendedContentTpl"></ng-container>
  </ng-container>
</loop-form-control-wrapper>
