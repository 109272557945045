import { Injectable } from '@angular/core';
import { catchError, concatMap, Observable, of } from 'rxjs';
import { BasePushSynchronizationService } from '../base-push-synchronization/base-push-synchronization.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { map } from 'rxjs/operators';
import { ConversationListUpdateRequest } from '@shared/api/api-loop/models/conversation-list-update-request';
import { ConversationActionModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-action.model';

@Injectable()
export class ConversationActionPushSynchronizationService extends BasePushSynchronizationService<ConversationActionModel> {
  constructor(protected _api: ApiService) {
    super();
  }

  get constructorName(): string {
    return 'ConversationActionPushSynchronizationService';
  }

  protected synchronize(
    forUserEmail: string,
    conversationAction: ConversationActionModel
  ): Observable<ConversationActionModel> {
    return of(conversationAction).pipe(
      concatMap((_conversation: ConversationActionModel) => {
        let updateRequest: ConversationListUpdateRequest = {
          $type: 'ConversationListUpdateRequest',
          action: conversationAction.actionToApply,
          actionDate: conversationAction.actionDate,
          conversationIds: conversationAction.conversationIds,
          folderId: conversationAction.folderId,
          sharedActionContext: conversationAction.sharedActionContext,
          fromContext: _conversation.context,
          addTags: conversationAction.addTags,
          removeTags: conversationAction.removeTags,
          dueDate: conversationAction.dueDate
        };

        return this._api.ConversationApiService.Conversation_UpdateConversationActionList(
          { updateRequest },
          forUserEmail
        );
      }),
      map(() => conversationAction),
      catchError(() => {
        // Don't let push sync stuck if any http errors
        return of(conversationAction);
      })
    );
  }
}
