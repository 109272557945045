/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { LogConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HomeApiService extends BaseService {
  constructor(
    protected _config: LogConfiguration,
    protected _http: HttpClient
  ) {
    super(_config, _http);
  }

  Home_HeadResponse(bearer: string): Observable<HttpResponse<string[]>> {
    let __method = 'HEAD';
    let __url = this.rootUrl + `/api`;
    let __headers = this.newHeaders(bearer);
    let __params = this.newParams();
    let __body: any;

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: string[];

          body = <string[]>response.body;

          return <HttpResponse<string[]>>response.clone({ body: body });
        })
      );
  }

  Home_Head(bearer: string): Observable<string[]> {
    return this.Home_HeadResponse(bearer).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );
  }

  Home_GetResponse(bearer: string): Observable<HttpResponse<string[]>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api`;
    let __headers = this.newHeaders(bearer);
    let __params = this.newParams();
    let __body: any;

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: string[];

          body = <string[]>response.body;

          return <HttpResponse<string[]>>response.clone({ body: body });
        })
      );
  }

  Home_Get(bearer: string): Observable<string[]> {
    return this.Home_GetResponse(bearer).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );
  }
}

export module HomeApiService {}
