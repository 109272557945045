/* tslint:disable */

/**
 * Account types.
 */
export type AccountType = 'Gmail' | 'MicrosoftExchange' | 'MicrosoftGraph' | 'RemoteImap';

export module AccountType {
  export const GMAIL: AccountType = 'Gmail';
  export const MICROSOFT_EXCHANGE: AccountType = 'MicrosoftExchange';
  export const MICROSOFT_GRAPH: AccountType = 'MicrosoftGraph';
  export const REMOTE_IMAP: AccountType = 'RemoteImap';

  export function values(): AccountType[] {
    return [GMAIL, MICROSOFT_EXCHANGE, MICROSOFT_GRAPH, REMOTE_IMAP];
  }
}
