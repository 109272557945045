import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { KeyboardActionModeType } from '../../shared/models/keyboard-shortcut.model';
import { KeyboardShortcutsListenerService } from '../services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';

@Directive({
  selector: '[focusStateNotifier]',
})
export class FocusStateNotifier {
  inputMode: KeyboardActionModeType;

  constructor(
    @Inject(ElementRef) private _el,
    private _keyboardShortcutsListenerService: KeyboardShortcutsListenerService,
  ) {}

  notifyService() {
    if (!this.inputMode) {
      return;
    }

    this._keyboardShortcutsListenerService.setMode(this.inputMode);
  }

  ngOnInit() {
    this.notifyService = this.notifyService.bind(this);
    this._el.nativeElement.addEventListener('focus', this.notifyService);
  }

  ngOnDestroy() {
    this._el.nativeElement.removeEventListener('focus', this.notifyService);
  }

  @Input() set focusStateNotifier(inputMode: KeyboardActionModeType) {
    this.inputMode = inputMode;
    if (document.activeElement === this._el.nativeElement) {
      this.notifyService();
    }
  }
}
