import * as _ from 'lodash';
import { Component } from '@angular/core';
import { StorageKey } from '@dta/shared/services/storage/storage.service';
import { Logger } from '@shared/services/logger/logger';
import { LoggerHelperService } from '@shared/services/logger/logger-helper.service';
import { SynchronizationFactory } from '@shared/synchronization/synchronization.factory';
import { StopWatch } from '@dta/shared/utils/stop-watch';
import { Event, NavigationStart, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { deeplinkRoute } from './web-routing.module';
import { NotifierService } from '@shared/services/notifier/notifier.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { DesktopNotificationsService } from '@dta/ui/services/desktop-notifications/desktop-notifications.service';
import { ReloginDialogService } from '@dta/ui/components/relogin-dialog/relogin-dialog.service';
import { UserSwitchReason } from '@shared/services/communication/shared-subjects/shared-subjects-models';

@Component({
  selector: 'web-root',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.scss'],
})
export class WebComponent {
  constructor(
    private _loggerHelperService: LoggerHelperService,
    private _synchronizationFactory: SynchronizationFactory,
    private _router: Router,
    private _notifierService: NotifierService,
    private _userManagerService: UserManagerService,
    private _desktopNotificationService: DesktopNotificationsService,
    private _reloginDialogService: ReloginDialogService,
  ) {
    this.subscribeToRouterEvents();

    // Reroute to last route and start route listener for last route
    this._userManagerService.rerouteToLast(UserSwitchReason.automatic);
    this._userManagerService.startRouteListener();
  }

  private startSyncAndLogger() {
    //////////////////
    // SYNC AND DATA
    //////////////////

    // Init sync activity for logged in users
    this.initSynchronizationFactory();

    // Init notifier service
    this.initNotifierService();

    // Init token revoked handling
    this._reloginDialogService.init();

    /////////////////////////
    // LOGGING AND DEBUGGING
    /////////////////////////

    // Init logging
    this.initLogger();

    // Init stopwatch
    this.initStopWatch();

    // Init Desktop notifications
    this._desktopNotificationService.subscribeToDesktopNotifications();
  }

  private initSynchronizationFactory() {
    this._synchronizationFactory.init();
  }

  private initNotifierService() {
    this._notifierService.init();
  }

  private initLogger() {
    Logger.init(ENV, this.getEnvironment(), this._loggerHelperService);

    // Set user email for current process and main thread
    Logger.setUserEmail(this._userManagerService.getCurrentUserEmail());
  }

  private getEnvironment(): string {
    let environment = localStorage.getItem(StorageKey.apiEnvironment);
    if (_.isNil(environment)) {
      environment = 'PRODUCTION';
    }

    return environment;
  }

  private initStopWatch() {
    window['StopWatch'] = StopWatch;
    StopWatch.disable();
  }

  private subscribeToRouterEvents() {
    this._router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        filter((event: NavigationStart) => !event.url.includes('/' + deeplinkRoute)),
        take(1),
        tap(() => {
          this.startSyncAndLogger();
        }),
      )
      .subscribe();
  }
}
