import * as _ from 'lodash';
import { Directive } from '@angular/core';
import { filter, Observable, of, Subject, throwError, zip } from 'rxjs';
import { LoginUserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { HelperApiService } from '@shared/api/api-loop/services/helper-api.service';
import {
  AuthCode,
  AuthExchange,
  AuthGoogle,
  AuthImapSmtp,
  AuthMicrosoft,
  AuthPassword,
  AuthRemoteImap,
  EmailServiceAuthType,
  EmailType,
  User,
  ErrorCode
} from '@shared/api/api-loop/models';
import { AuthApiService } from '@shared/api/api-loop/services/auth-api.service';
import { mergeMap, tap } from 'rxjs/operators';
import { UserApiService } from '@shared/api/api-loop/services/user-api.service';
import { AuthUser, EmailServiceType } from '@dta/shared/models/auth.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { LoginDialogService } from '@dta/ui/components/common/login-dialog/login-dialog.service';
import { RedirectService } from '@shared/services/redirect/redirect.service';
import { UserService } from '@shared/services/data/user/user.service';
import {
  UserSwitchReason,
  UserTokenInvalid
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { AccountActionType } from 'dta/shared/services/tracking/tracking.constants';
import { Logger } from '../logger/logger';
import { LogLevel, LogTag } from 'dta/shared/models/logger.model';
import { StorageKey } from 'dta/shared/services/storage/storage.service';
import { INotificationSettings } from 'shared/modules/main/user-settings/general/general.component';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { UserflowEventName } from '@shared/services/userflow/userflow.constants';
import { UserflowService } from '@shared/services/userflow/userflow.service';
import { EmailTypeEnum } from '@dta/shared/models/sync-settings-type.model';
import { OAuthService } from '../oauth/oauth.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface LoginServiceI {
  //////////////
  // Properties
  //////////////
  clientParameter: string;

  ///////////
  // Helpers
  ///////////

  // Get type of email based on provider
  getEmailType(email: string): Observable<EmailType>;

  //////////////////////////////////////////
  // Login/register based on provider/way
  //////////////////////////////////////////

  // Login/register IMAP user
  // (remote and native, based on parameter)
  imapLogin(
    email: string,
    imapHost: string,
    imapPassword: string,
    imapPort: number,
    imapUsername: string,
    name: string,
    smtpHost: string,
    smtpPassword: string,
    smtpPort: number,
    smtpUsername: string,
    sslRequired: boolean,
    isAddAccount: boolean,
    isNativeImap: boolean
  ): Observable<AuthRemoteImap | AuthImapSmtp>;

  // Exchange login
  exchangeLogin(
    email: string,
    password: string,
    username: string,
    serverUri: string,
    domain: string,
    isAddAccount: boolean
  ): Observable<AuthExchange>;

  // Google login (open in browser)
  googleLogin(email: string, fullScope: boolean): void;

  // Microsoft login (open within electron)
  microsoftLogin(email: string, fullScope: boolean): void;

  // For login via activation code (6 digit)
  loginWithActivationCode(code: string): Observable<LoginUserModel>;

  // For login with email and password
  loginWithPassword(email: string, password: string): Observable<any>;
}

@Directive()
export abstract class LoginService implements LoginServiceI {
  codeExchangeError$: Subject<any> = new Subject();
  asyncError$: Subject<any> = new Subject();
  toggleOauthLoading$: Subject<boolean> = new Subject();
  abstract readonly clientParameter: string;

  constructor(
    protected _helperApiService: HelperApiService,
    protected _authApiService: AuthApiService,
    protected _userApiService: UserApiService,
    protected _userManagerService: UserManagerService,
    protected _userService: UserService,
    protected _trackingService: TrackingService,
    protected _settingsService: SettingsService,
    protected _storageService: StorageService,
    protected _loginDialogService: LoginDialogService,
    protected _redirectService: RedirectService,
    protected _userflowService: UserflowService,
    protected _oAuthService: OAuthService
  ) {}

  ///////////////
  // IMAP Login
  ///////////////
  imapLogin(
    email: string,
    imapHost: string = undefined,
    imapPassword: string,
    imapPort: number = undefined,
    imapUsername: string,
    name: string,
    smtpHost: string = undefined,
    smtpPassword: string,
    smtpPort: number = undefined,
    smtpUsername: string,
    sslRequired: boolean = true,
    isAddAccount: boolean = false,
    isNativeImap: boolean = false
  ): Observable<AuthRemoteImap | AuthImapSmtp> {
    if (isNativeImap) {
      let body: AuthImapSmtp = {
        $type: EmailTypeEnum.AUTH_IMAP_SMTP,
        email: email,
        imapHost: imapHost,
        imapPassword: imapPassword,
        imapPort: imapPort,
        imapUsername: imapUsername,
        name: name,
        smtpHost: smtpHost,
        smtpPassword: smtpPassword,
        smtpPort: smtpPort,
        smtpUsername: smtpUsername,
        sslRequired: sslRequired
      };

      let params: AuthApiService.Auth_CreateImapAuthParams = {
        authImapSmtp: body,
        isAddAccount: isAddAccount
      };

      return this._authApiService.Auth_CreateImapAuth(params, undefined).pipe(
        mergeMap((auth: AuthImapSmtp) => {
          return this.afterImapLogin(auth, body, EmailServiceType.imap, email);
        })
      );
    } else {
      let body: AuthRemoteImap = {
        $type: EmailTypeEnum.AUTH_REMOTE_IMAP,
        email: email,
        imapHost: imapHost,
        imapPassword: imapPassword,
        imapPort: imapPort,
        imapUsername: imapUsername,
        name: name,
        smtpHost: smtpHost,
        smtpPassword: smtpPassword,
        smtpPort: smtpPort,
        smtpUsername: smtpUsername,
        sslRequired: sslRequired
      };

      let params: AuthApiService.Auth_CreateRemoteImapAuthParams = {
        authRemoteImap: body,
        isAddAccount: isAddAccount
      };

      return this._authApiService.Auth_CreateRemoteImapAuth(params, undefined).pipe(
        mergeMap((auth: AuthRemoteImap) => {
          return this.afterImapLogin(auth, body, EmailServiceType.remoteImap, email);
        })
      );
    }
  }

  protected afterImapLogin(
    auth: AuthImapSmtp | AuthRemoteImap,
    loginData: AuthImapSmtp | AuthRemoteImap,
    emailType: EmailServiceType,
    email: string
  ): Observable<LoginUserModel> {
    /**
     * BE returns auth with some empty fields, but we need to send all the fields when creating sync settings.
     * So we use login data and append token that we got from BE
     */
    let fullInfoAuth: AuthImapSmtp = loginData;
    fullInfoAuth.token = auth.token;

    return this.afterLogin(fullInfoAuth, emailType, email);
  }

  ///////////////////
  // Exchange Login
  ///////////////////
  exchangeLogin(
    email: string,
    password: string,
    username: string = '',
    serverUri: string = '',
    domain: string = '',
    isAddAccount: boolean = false
  ): Observable<AuthExchange> {
    let body: AuthExchange = {
      $type: EmailTypeEnum.AUTH_EXCHANGE,
      email: email,
      secret: password,
      username: username,
      serverUri: serverUri,
      domain: domain
    };

    let params: AuthApiService.Auth_CreateExchangeAuthParams = {
      authExchange: body,
      isAddAccount: isAddAccount
    };

    return this._authApiService.Auth_CreateExchangeAuth(params, undefined).pipe(
      mergeMap((auth: AuthExchange) => {
        auth.secret = password || '';
        return this.afterLogin(auth, EmailServiceType.exchange, email);
      })
    );
  }

  ///////////
  // oAuth
  ///////////
  googleLogin(email: string, fullScope?: boolean) {
    this._oAuthService.openExternalOAuth(email, 'google', { fullScope, sameWindow: true });
  }

  microsoftLogin(email: string, fullScope?: boolean) {
    this._oAuthService.openExternalOAuth(email, 'microsoft', { fullScope, sameWindow: true });
  }

  ////////////
  // HELPERS
  ////////////
  get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  getEmailType(email: string): Observable<EmailType> {
    return this._helperApiService.Helper_GetEmailType({ emailAddress: email }, undefined, false);
  }

  /////////////////////
  // EMAIL & PASSWORD
  /////////////////////
  loginWithPassword(email: string, password: string): Observable<any> {
    let body: AuthPassword = {
      $type: 'AuthPassword',
      identificator: email,
      password: password
    };

    let params: AuthApiService.Auth_CreatePasswordAuthParams = {
      authPassword: body,
      isAddAccount: false
    };

    return of(undefined).pipe(
      /**
       * Get auth from code
       */
      mergeMap(() => {
        return this._authApiService.Auth_CreatePasswordAuth(params, undefined);
      }),
      /**
       * Get self
       */
      mergeMap((auth: AuthCode) => {
        let params: UserApiService.User_GetSelfParams = {
          Authorization: 'Bearer ' + auth.token.accessToken
        };

        return zip(this._userApiService.User_GetSelf(params, undefined), of(auth));
      }),
      /**
       * Get email type for logging
       */
      mergeMap((data: [User, AuthCode]) => {
        return zip(this.getEmailType(data[0].email), of(data[0]), of(data[1]));
      }),
      /**
       * Complete authorization
       */
      mergeMap((data: [EmailType, User, AuthCode]) => {
        let fullInfoAuth: AuthPassword = body;
        fullInfoAuth.token = data[2].token;

        return this.afterLogin(fullInfoAuth, data[0].emailServiceType as EmailServiceType, data[1].email);
      }),
      mergeMap((user: LoginUserModel) => {
        if (user.throwVerifiedError) {
          user.throwVerifiedError = false;
          return throwError(new HttpErrorResponse({ error: { errorCode: ErrorCode.EMAIL_NOT_VERIFIED }, status: 451 }));
        }
        return of(user);
      })
    );
  }

  setUserEmailAuthType(email: string, emailAuthType: EmailServiceAuthType) {
    this._userManagerService.setUserEmailAuthType(email, emailAuthType);
  }

  ///////////////
  // Activation
  ///////////////
  loginWithActivationCode(code: string, authType: string = 'AuthCode'): Observable<LoginUserModel> {
    if (_.isEmpty(code)) {
      return throwError(() => 'Empty code');
    }

    this.toggleOauthLoading$.next(true);

    let body: AuthCode = {
      $type: 'AuthCode',
      code: code
    };

    let params: AuthApiService.Auth_CreateTokenCodeAuthParams = {
      authCode: body,
      isAddAccount: false
    };

    return of(undefined).pipe(
      /**
       * Get auth from code
       */
      mergeMap(() => {
        return this._authApiService.Auth_CreateTokenCodeAuth(params, undefined);
      }),
      /**
       * Get self
       */
      mergeMap((auth: AuthCode) => {
        let params: UserApiService.User_GetSelfParams = {
          Authorization: 'Bearer ' + auth.token.accessToken
        };

        return zip(this._userApiService.User_GetSelf(params, undefined), of(auth));
      }),
      /**
       * Get email type for logging
       */
      mergeMap(([user, authCode]: [User, AuthCode]) => {
        return zip(this.getEmailType(user.email), of(user), of(authCode));
      }),
      /**
       * Complete authorization
       */
      mergeMap(([emailType, user, authCode]: [EmailType, User, AuthCode]) => {
        this.setUserEmailAuthType(user.email, emailType.emailAuthType);

        let fullInfoAuth: AuthUser = body;
        fullInfoAuth.token = authCode.token;

        // Set auth type given via deeplink or default
        fullInfoAuth.$type = authType;

        return this.afterLogin(fullInfoAuth, emailType.emailServiceType as EmailServiceType, user.email);
      })
    );
  }

  ////////////////////
  // PRIVATE HELPERS
  ////////////////////
  protected afterLogin(
    auth: AuthImapSmtp | AuthRemoteImap | AuthExchange | AuthGoogle | AuthMicrosoft | AuthPassword,
    emailType: EmailServiceType,
    email: string,
    refreshToken: string = undefined
  ): Observable<LoginUserModel> {
    this._userManagerService.setUserAuth(auth, UserSwitchReason.addAccount, email);

    return this._userService.fetchOrUpdateUser(email, refreshToken).pipe(
      tap((loginUser: LoginUserModel) => {
        // Check for 451 response
        if (auth.$type === EmailTypeEnum.AUTH_PASSWORD && loginUser.throwVerifiedError) {
          return loginUser;
        }

        // Make sure we are working with latest version of logged in users
        this._userManagerService.reloadUsersCache();

        // Make sure logged in user is set
        this._userManagerService.switchUser(loginUser);

        this._trackingService.trackAccountAction(email, AccountActionType.LOGIN);
        this._settingsService.saveUserSettings(loginUser.userSettings, loginUser.email);

        // Do not store passwords in local storage
        if (emailType === EmailServiceType.imap || emailType === EmailServiceType.remoteImap) {
          // Imap specific
          (<AuthImapSmtp | AuthRemoteImap>auth).imapPassword = '';
          (<AuthImapSmtp | AuthRemoteImap>auth).smtpPassword = '';
        } else if (emailType === EmailServiceType.exchange) {
          // Exchange specific
          (<AuthExchange>auth).secret = '';
        }

        this._userManagerService.setUserAuth(auth, UserSwitchReason.addAccount, email);

        this.setUserNotificationSettings();
        this.requestOpenRelogin();
        this._loginDialogService.toggle.next(false);
        this._redirectService.navigateToDefaultView();

        // Trigger Userflow event
        this._userflowService.triggerEventWithNoAttributes(email, this.getUserflowEventBasedOnEmailType(emailType));

        // Track successful login
        this._trackingService.trackLogin(email, 'Login successful');

        // Log new registration/login
        Logger.customLog(
          `Successful ${emailType} login: ${auth.$type} for ${email}`,
          LogLevel.INFO,
          LogTag.AUTH_AND_LOGIN,
          true,
          'Successful login'
        );
      })
    );
  }

  private setUserNotificationSettings() {
    let key = this._storageService.getKey(this.currentUserEmail, StorageKey.userNotificationSettings);
    return this._storageService.setStringifiedItem(key, new INotificationSettings());
  }

  private requestOpenRelogin() {
    let data = new UserTokenInvalid();
    data.close = true;

    SharedSubjects._userTokenInvalid$.next(data);
  }

  private getUserflowEventBasedOnEmailType(emailType: EmailServiceType): UserflowEventName {
    switch (emailType) {
      case EmailServiceType.imap:
        return UserflowEventName.ImapAccountAdded;
      case EmailServiceType.remoteImap:
        return UserflowEventName.RemoteImapAccountAdded;
      case EmailServiceType.exchange:
        return UserflowEventName.ExchangeAccountAdded;
      case EmailServiceType.google:
        return UserflowEventName.GoogleAccountAdded;
      case EmailServiceType.microsoft:
        return UserflowEventName.MicrosoftAccountAdded;
      default:
        return UserflowEventName.OtherAccountAdded;
    }
  }

  ///////////
  // HELPERS
  ///////////
  protected parseCodeOrThrow(json: string): string {
    try {
      let code = JSON.parse(json);
      return code['Code'];
    } catch (err) {
      Logger.customLog(
        'Could not parse code because: ' + err.message + 'Title: ' + json,
        LogLevel.ERROR,
        LogTag.AUTH_AND_LOGIN
      );
      throw new Error('Could not parse code because: ' + err.message);
    }
  }
}
