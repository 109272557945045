<button
  #buttonElement
  [class.color--primary]="color === 'primary'"
  [class.color--system-contrast]="color === 'systemContrast'"
  [class.color--warning]="color === 'warning'"
  [class.color--success]="color === 'success'"
  [class.color--danger]="color === 'danger'"
  [class.color--light]="color === 'light'"
  [class.fill--color]="fill === 'color'"
  [class.fill--outline]="fill === 'outline'"
  [class.fill--transparent]="fill === 'transparent'"
  [class.fill--link]="fill === 'link'"
  [class.size--small]="size === 'small'"
  [class.size--normal]="size === 'normal'"
  [class.size--large]="size === 'large'"
  [class.normal-border-radius]="hasSmallBorder"
  [disabled]="isDisabled || isLoading"
  [type]="type"
  [tabIndex]="allowTabIndex ? 0 : -1"
>
  <span class="container">
    <ng-content></ng-content>
    <loop-loader-new [class.outline]="fill === 'outline'" *ngIf="isLoading"></loop-loader-new>
  </span>
</button>
