/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { OptionsSearch } from '../models/options-search';
import { ListOfResourcesOfConversation } from '../models/list-of-resources-of-conversation';
import { Response } from '../models/response';
import { SearchQueryConversation } from '../models/search-query-conversation';
import { ListOfResourcesOfString } from '../models/list-of-resources-of-string';
import { UpdateRequestBase } from '../models/update-request-base';

@Injectable()
export class ConversationApiService extends BaseService {
  Conversation_GetSearchOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsSearch>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/conversation/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Conversation_GetSearchOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsSearch;

          body = <OptionsSearch>response.body;

          return <HttpResponse<OptionsSearch>>response.clone({ body: body });
        })
      );
  }

  Conversation_GetSearchOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsSearch> {
    const observable$ = this.Conversation_GetSearchOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/conversation/list`;
      const url = this.rootUrl + `/api/v1/conversation/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `ConversationApiService.Conversation_GetListParams` containing the following parameters:
   *
   * - `query`: Search query.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Conversation_GetListResponse(
    params: ConversationApiService.Conversation_GetListParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfConversation>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/conversation/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Conversation_GetList');

    // body, headers and query params
    __body = params.query;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfConversation;

          body = <ListOfResourcesOfConversation>response.body;

          return <HttpResponse<ListOfResourcesOfConversation>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `ConversationApiService.Conversation_GetListParams` containing the following parameters:
   *
   * - `query`: Search query.
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Conversation_GetList(
    params: ConversationApiService.Conversation_GetListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfConversation> {
    const observable$ = this.Conversation_GetListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/conversation/list`;
      const url = this.rootUrl + `/api/v1/conversation/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `ConversationApiService.Conversation_GetList2Params` containing the following parameters:
   *
   * - `conversationIds`: Get converstation ids.
   *
   * - `includeAttachmentSignedLinks`: Include signed links
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Conversation_GetList2Response(
    params: ConversationApiService.Conversation_GetList2Params,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfConversation>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/conversation/get`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Conversation_GetList2');

    // body, headers and query params
    __body = params.conversationIds;
    if (params.includeAttachmentSignedLinks != null)
      __params = __params.set('includeAttachmentSignedLinks', params.includeAttachmentSignedLinks.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfConversation;

          body = <ListOfResourcesOfConversation>response.body;

          return <HttpResponse<ListOfResourcesOfConversation>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `ConversationApiService.Conversation_GetList2Params` containing the following parameters:
   *
   * - `conversationIds`: Get converstation ids.
   *
   * - `includeAttachmentSignedLinks`: Include signed links
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Conversation_GetList2(
    params: ConversationApiService.Conversation_GetList2Params,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfConversation> {
    const observable$ = this.Conversation_GetList2Response(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/conversation/get`;
      const url = this.rootUrl + `/api/v1/conversation/get`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `ConversationApiService.Conversation_UpdateConversationActionListParams` containing the following parameters:
   *
   * - `updateRequest`: Conversation list update request.
   *
   * - `forceAction`: Only super user can force action (for all user)
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Conversation_UpdateConversationActionListResponse(
    params: ConversationApiService.Conversation_UpdateConversationActionListParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/conversation/action/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Conversation_UpdateConversationActionList');

    // body, headers and query params
    __body = params.updateRequest;
    if (params.forceAction != null) __params = __params.set('forceAction', params.forceAction.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `ConversationApiService.Conversation_UpdateConversationActionListParams` containing the following parameters:
   *
   * - `updateRequest`: Conversation list update request.
   *
   * - `forceAction`: Only super user can force action (for all user)
   *
   * - `X-Impersonate-User`: Impersonation header.
   *
   * - `Authorization`: Authorization header.
   */
  Conversation_UpdateConversationActionList(
    params: ConversationApiService.Conversation_UpdateConversationActionListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Conversation_UpdateConversationActionListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/conversation/action/list`;
      const url = this.rootUrl + `/api/v1/conversation/action/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module ConversationApiService {
  /**
   * Parameters for Conversation_GetList
   */
  export type Conversation_GetListParams = {
    /**
     * Search query.
     */
    query?: SearchQueryConversation;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Conversation_GetList2
   */
  export type Conversation_GetList2Params = {
    /**
     * Get converstation ids.
     */
    conversationIds: ListOfResourcesOfString;

    /**
     * Include signed links
     */
    includeAttachmentSignedLinks?: boolean;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };

  /**
   * Parameters for Conversation_UpdateConversationActionList
   */
  export type Conversation_UpdateConversationActionListParams = {
    /**
     * Conversation list update request.
     */
    updateRequest?: UpdateRequestBase;

    /**
     * Only super user can force action (for all user)
     */
    forceAction?: boolean;

    /**
     * Impersonation header.
     */
    XImpersonateUser?: string;

    /**
     * Authorization header.
     */
    Authorization?: string;
  };
}
