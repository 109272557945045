import { Directive, ElementRef, inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ATOM_COLOR_PREFIX } from '@shared/modules/color-scheme/common/css-color-variable-prefix';
import { COLOR_TYPES } from '@shared/modules/color-scheme/common/color-types-array';
import { AtomType, isAtomType } from '@shared/modules/color-scheme/common/atom-type';
import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[loopAtom]'
})
export class AtomDirective implements OnDestroy {
  private readonly renderer: Renderer2 = inject(Renderer2);
  private readonly elementRef: ElementRef = inject(ElementRef);

  readonly atom$: ReplaySubject<AtomType> = new ReplaySubject<AtomType>(1);

  @Input() set loopAtom(atomType: AtomType | undefined) {
    if (!atomType) {
      return;
    }

    if (!isAtomType(atomType)) {
      throw new Error(`unknown atom type (${atomType})`);
    }
    this.atom$.next(atomType);
  }

  constructor() {
    this.atom$.pipe(untilDestroyed(this)).subscribe(atomColor => this.createAtomClass(atomColor));
  }

  private createAtomClass(atomColor: AtomType): void {
    const element: HTMLElement = this.elementRef.nativeElement;
    COLOR_TYPES.forEach(colorType => this.renderer.removeClass(element, `${ATOM_COLOR_PREFIX}-${colorType}`));
    const customClass: string = `${ATOM_COLOR_PREFIX}-${atomColor}`;
    this.renderer.addClass(element, customClass);
  }

  ngOnDestroy(): void {
    this.atom$.complete();
  }
}
