import { Observable } from 'rxjs';
import { BaseDaoService } from '../base/base-dao.service';
import { IntegrationModel } from '@dta/shared/models-api-loop/integration.model';
import { Integration } from '@shared/api/api-loop/models/integration';
import { IntegrationType } from '@shared/api/api-loop/models/integration-type';

export interface IntegrationDaoServiceI {
  findAllIntegrations(forUserEmail: string): Observable<IntegrationModel[]>;
  removeAllIntegrations(forUserEmail: string): Observable<any>;
  findByType(forUserEmail: string, integrationType: IntegrationType): Observable<IntegrationModel[]>;
}

export abstract class IntegrationDaoService
  extends BaseDaoService<IntegrationModel, Integration>
  implements IntegrationDaoServiceI
{
  abstract findAllIntegrations(forUserEmail: string): Observable<IntegrationModel[]>;
  abstract removeAllIntegrations(forUserEmail: string): Observable<any>;
  abstract findByType(forUserEmail: string, integrationType: IntegrationType): Observable<IntegrationModel[]>;
}
