import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration, intervalToDuration } from 'date-fns';

@Pipe({
  name: 'eventLength',
})
export class EventLengthPipe implements PipeTransform {
  transform(startDate: Date, endDate: Date): string {
    const duration = intervalToDuration({
      start: startDate,
      end: endDate,
    });
    return formatDuration(duration, { format: ['hours', 'minutes'] });
  }
}
