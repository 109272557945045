import { Injectable } from '@angular/core';
import { IFrameRequestHandlerI } from '@shared/services/integrations/iframe-request-handler.interface';
import { IFrameMethod, IFrameOpenAIRequest } from '@intheloop/loop-integration';
import { Observable, of } from 'rxjs';

@Injectable()
export class OpenAiHelper implements IFrameRequestHandlerI {
  handleRequest(forUserEmail: string, request: IFrameOpenAIRequest, integrationId: string): Observable<any> {
    switch (request.method) {
      case IFrameMethod.ASK_OPEN_AI:
        return of(undefined);
    }
  }
}
