import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';

export const MAX_CACHE_SIZE: number = 400;
export const ON_CACHE_LIMIT_REACHED_DELETE_COUNT: number = 300;
export interface FileUrlEntryI {
  fileName: string;
  fileUrl: string;
}

@Injectable()
export class FileUrlStorageService {
  constructor(private _storageService: StorageService) {}

  get constructorName(): string {
    return 'FileUrlStorageService';
  }

  private getStorage(): FileUrlStorage {
    // TODO move to indexdb
    return this._storageService.getParsedItem(StorageKey.fileUrlStorage) ?? new FileUrlStorage();
  }

  private persistStorage(storage: FileUrlStorage): void {
    return this._storageService.setStringifiedItem(StorageKey.fileUrlStorage, storage);
  }

  existsInStorage(fileName: string): boolean {
    let storage = this.getStorage();
    let entry = storage.entries[fileName];

    return !_.isUndefined(entry) && !FileUrlStorageService.isLinkExpired(entry.expires);
  }

  getFileUrlByFileName(fileName: string): string {
    if (_.isEmpty(fileName)) {
      return undefined;
    }

    let storage = this.getStorage();
    let entry = storage.entries[fileName];

    if (!entry || FileUrlStorageService.isLinkExpired(entry.expires)) {
      //this.removeFromStorage(fileName);
      return undefined;
    }

    return entry.url;
  }

  writeToStorage(data: FileUrlEntryI[]): void {
    if (!data || !data.length) {
      return;
    }

    let storage = this.getStorage();
    if (Object.keys(storage.entries).length >= MAX_CACHE_SIZE) {
      storage = this.garbageCollectCache(storage);
    }

    data.forEach(({ fileName, fileUrl }) => {
      let entry: FileUrlEntry = {
        expires: FileUrlStorageService.getLinkExpirationDate(fileUrl),
        url: fileUrl
      };
      storage.entries[fileName] = entry;
    });

    this.persistStorage(storage);
  }

  private garbageCollectCache(storage: FileUrlStorage): FileUrlStorage {
    let lastToExpire = _.orderBy(Object.keys(storage.entries), key => storage.entries[key].expires, ['desc']);
    let keysToRemove = lastToExpire.slice(
      MAX_CACHE_SIZE - ON_CACHE_LIMIT_REACHED_DELETE_COUNT,
      Object.keys(storage.entries).length
    );
    _.forEach(keysToRemove, key => {
      delete storage.entries[key];
    });
    return storage;
  }

  removeFromStorage(fileName: string): void {
    if (_.isEmpty(fileName)) {
      return;
    }

    let storage = this.getStorage();
    delete storage.entries[fileName];
    this.persistStorage(storage);
  }

  private removeFromStorageByKeys(keys: string[]): void {
    let storage = this.getStorage();

    _.forEach(keys, key => {
      delete storage.entries[key];
    });

    this.persistStorage(storage);
  }

  static isLinkExpired(timestamp: number): boolean {
    return timestamp > -1 && timestamp * 1000 < new Date().getTime();
  }

  static getLinkExpirationDate(url: string): number {
    /**
     * Note: lookbehind not supported on safari (<=)
     * https://stackoverflow.com/a/58460583/3650404
     */
    let expTimestamps = url.match(/Expires=(.*?)&/);
    return parseInt(expTimestamps ? expTimestamps[1] : '-1', 10);
  }
}

class FileUrlStorage {
  entries: { [fileName: string]: FileUrlEntry } = {};
}

interface FileUrlEntry {
  expires: number; // Expiration timestamp or -1 if never expires or no data
  url: string;
}
