import { Injectable } from '@angular/core';
import { DatabaseFactory } from '@shared/database/database-factory.service';
import { ContactBase, Conversation, File } from '@shared/api/api-loop/models';
import { BaseDaoServiceWeb } from '../base/base-dao.service.web';
import { CollectionNameWeb } from '../../database-schema';
import { Observable, of } from 'rxjs';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { FileDaoServiceI } from '@shared/database/dao/file/file-dao.service';
import { CommentBaseModel } from '@dta/shared/models-api-loop/comment/comment.model';

@Injectable()
export class FileDaoServiceWeb extends BaseDaoServiceWeb<FileModel, File> implements FileDaoServiceI {
  constructor(protected _databaseFactory: DatabaseFactory) {
    super(_databaseFactory);
  }

  get constructorName(): string {
    return 'ConversationDaoServiceWeb';
  }

  get collectionName(): CollectionNameWeb {
    return CollectionNameWeb.File;
  }

  protected toModel(doc: Conversation): FileModel {
    return new FileModel(doc);
  }

  findFilesBySearchQuery(forUserEmail: string, query: string, offset: number, size: number): Observable<FileModel[]> {
    return of([]);
  }

  findFiles(forUserEmail: string, params: any): Observable<FileModel[]> {
    return of([]);
  }

  findChannelFiles(forUserEmail: string, params: any): Observable<FileModel[]> {
    return of([]);
  }

  findFilesBySearchQueryAndRecentFilter(
    forUserEmail: string,
    query: string,
    filter: any,
    offset: number,
    size: number,
  ): Observable<FileModel[]> {
    return of([]);
  }

  updateChannelIdsByFiles(forUserEmail: string, files: FileModel[], contactId: string): Observable<FileModel[]> {
    return of(files);
  }

  // todo
  findFilesForComments(forUserEmail: string, comments: CommentBaseModel[]): Observable<FileModel[]> {
    throw new Error('Method not implemented.');
  }
  findCommentIdsForFileHashes(forUserEmail: string, fileHashes: string[]): Observable<{ [hash: string]: string[] }> {
    throw new Error('Method not implemented.');
  }
  findNonChannelFilesByIds(forUserEmail: string, files: File[], contact: ContactBase): Observable<FileModel[]> {
    throw new Error('Method not implemented.');
  }
}
