import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { Injectable } from '@angular/core';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import {
  ConnectionStatus,
  CountersChangedTrigger,
  UnreadStateChange
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { ContactModel } from 'dta/shared/models-api-loop/contact/contact.model';
import { UnreadCounters, UnreadOptimisticResponse } from 'dta/shared/models/unread.model';
import {
  buffer,
  bufferWhen,
  debounceTime,
  exhaustMap,
  filter,
  from,
  interval,
  Observable,
  of,
  Subject,
  Subscription
} from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';
import {
  BadgeCount,
  FilterEnum,
  ListOfResourcesOfBadgeCount,
  ShowInViewObject,
  ViewEnum
} from '@shared/api/api-loop/models';
import { Logger } from '@shared/services/logger/logger';
import { LogTag } from '@dta/shared/models/logger.model';
import { BadgeCountApiService } from '@shared/api/api-loop/services';
import { AppStateService } from '../app-state/app-state.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { CardUnreadServiceI, ContactUnreadCount } from './card-unread.service.interface';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';

@Injectable()
export class CardUnreadService implements CardUnreadServiceI {
  ////////////////////
  // Counters by user
  ////////////////////
  private unreadCountersByEmail: { [email: string]: UnreadCounters } = {};
  private contactsTotalUnreadCountersByEmail: { [email: string]: { [groupKey: string]: ContactUnreadCount } } = {};
  private assignedCountersByEmail: { [email: string]: number } = {};

  private initialLocalLoadDone: boolean;

  //////////////////
  // Subscriptions
  //////////////////
  private countersSubscriber: Subscription;
  private connectionStatusSubscriber: Subscription;

  constructor(
    private _badgeCountApiService: BadgeCountApiService,
    private _appState: AppStateService,
    private _sharedUserManagerService: SharedUserManagerService,
    private _storageService: StorageService
  ) {
    this.subscribeToTriggers();
    this.subscribeToConnectionState();
  }

  get constructorName(): string {
    return 'CardUnreadService';
  }

  applyOptimisticResponseToViewUnreadCount(forUserEmail: string, data: UnreadOptimisticResponse) {
    if (['myLoopInbox', 'chat', 'personalInbox'].includes(data.viewDefiners.view)) {
      // Main views
      this.initLocalStateIfEmpty(forUserEmail);
      this.unreadCountersByEmail[forUserEmail][data.viewDefiners.view] += data.diffToAdd;
      if (this.unreadCountersByEmail[forUserEmail][data.viewDefiners.view] < 0) {
        this.unreadCountersByEmail[forUserEmail][data.viewDefiners.view] = 0;
      }
    } else {
      // Channels
      if (
        !this.contactsTotalUnreadCountersByEmail.hasOwnProperty(forUserEmail) ||
        !this.contactsTotalUnreadCountersByEmail[forUserEmail].hasOwnProperty(data.viewDefiners.channelId)
      ) {
        return;
      }

      let unreadsForChannel = this.contactsTotalUnreadCountersByEmail[forUserEmail][data.viewDefiners.channelId];
      if (data.viewDefiners.type === 'chat') {
        unreadsForChannel.chatUnreadCount += data.diffToAdd;
        if (unreadsForChannel.chatUnreadCount < 0) {
          unreadsForChannel.chatUnreadCount = 0;
        }
      } else {
        unreadsForChannel.emailUnreadCount += data.diffToAdd;
        if (unreadsForChannel.emailUnreadCount < 0) {
          unreadsForChannel.emailUnreadCount = 0;
        }
      }

      // Total unread count
      unreadsForChannel.totalUnreadCount = unreadsForChannel.emailUnreadCount + unreadsForChannel.chatUnreadCount;
      this.contactsTotalUnreadCountersByEmail[forUserEmail][data.viewDefiners.channelId] = unreadsForChannel;
    }

    SharedSubjects._unreadStateChange$.next(new UnreadStateChange(forUserEmail, true));
    this.persistLocalState();
  }

  getUnreadCountForMainViews(forUserEmail: string): Observable<UnreadCounters> {
    let counters = this.unreadCountersByEmail[forUserEmail];

    if (!_.isEmpty(counters)) {
      return of(counters);
    }

    return this.fetchUnreadCountsViews(forUserEmail).pipe(map(() => this.unreadCountersByEmail[forUserEmail]));
  }

  getContactUnreadCount(forUserEmail: string, contact: ContactModel): Observable<ContactUnreadCount> {
    if (_.isEmpty(contact)) {
      return of(new ContactUnreadCount());
    }

    return this.getContactsTotalUnreadCount(forUserEmail, [contact]).pipe(map(u => u[contact.id]));
  }

  getContactsTotalUnreadCount(
    forUserEmail: string,
    contacts: ContactModel[],
    forceFetch?: boolean
  ): Observable<{ [groupKey: string]: ContactUnreadCount }> {
    return of(undefined).pipe(
      /**
       * Find unread counts in local cache
       */
      map(() => this.findContactsTotalUnreadCount(forUserEmail, contacts)),
      /**
       * Return from cache if all contacts
       * Fetch otherwise
       */
      mergeMap((contactsTotalUnreadCount: { [groupKey: string]: ContactUnreadCount }) => {
        if (Object.keys(contactsTotalUnreadCount).length === contacts.length && !forceFetch) {
          return of(contactsTotalUnreadCount);
        }

        let contexts: ShowInViewObject[] = [];
        _.forEach(contacts, contact => {
          contexts.push({
            view: ViewEnum.CHANNEL,
            channelId: contact.id
          });
        });

        return this.fetchUnreadCountsViews(forUserEmail, contexts).pipe(
          map(() => this.findContactsTotalUnreadCount(forUserEmail, contacts))
        );
      })
    );
  }

  getAssignedItemsCount(forUserEmail: string): Observable<number> {
    if (this.assignedCountersByEmail[forUserEmail]) {
      return of(this.assignedCountersByEmail[forUserEmail]);
    }

    let contexts = [
      {
        view: ViewEnum.LOOP_INBOX,
        filter: FilterEnum.ASSIGNED
      }
    ];

    return this.fetchUnreadCountsViews(forUserEmail, contexts).pipe(
      map(() => this.assignedCountersByEmail[forUserEmail])
    );
  }

  private findContactsTotalUnreadCount(
    forUserEmail: string,
    contacts: ContactModel[]
  ): { [groupKey: string]: ContactUnreadCount } {
    let contactsTotalUnreadCount: { [groupKey: string]: ContactUnreadCount } = {};
    let userCounters = this.contactsTotalUnreadCountersByEmail[forUserEmail];

    if (!userCounters) {
      return contactsTotalUnreadCount;
    }

    _.forEach(contacts, (contact: ContactModel) => {
      if (!_.isUndefined(userCounters[contact.id])) {
        contactsTotalUnreadCount[contact.id] = userCounters[contact.id];
      }
    });

    return contactsTotalUnreadCount;
  }

  ///////////////////////////////////
  // Private helpers and subscribers
  ///////////////////////////////////
  private subscribeToConnectionState() {
    this.connectionStatusSubscriber?.unsubscribe();
    this.connectionStatusSubscriber = this._appState.connectionStatus$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.connectionActive === curr.connectionActive),
        tap((connectionStatus: ConnectionStatus) => {
          if (connectionStatus.connectionActive) {
            // Invalidate when back online
            this.invalidateCache();

            // Trigger recalculation for all
            _.forEach(this._sharedUserManagerService.getAllUserEmails(), (userEmail: string) =>
              SharedSubjects._unreadStateChange$.next(new UnreadStateChange(userEmail))
            );
          } else {
            if (!this.initialLocalLoadDone) {
              this.loadLocalState();
            }
          }

          if (this.initialLocalLoadDone) {
            this.persistLocalState();
          }
        })
      )
      .subscribe();
  }

  private invalidateCache() {
    this.unreadCountersByEmail = {};
    this.contactsTotalUnreadCountersByEmail = {};
    this.assignedCountersByEmail = {};
  }

  private subscribeToTriggers() {
    this.countersSubscriber?.unsubscribe();
    this.countersSubscriber = SharedSubjects._countersChangedTrigger$
      .pipe(
        /**
         * Wait for 2 seconds after localChange
         */
        buffer(
          SharedSubjects._unreadStateChange$.pipe(
            filter((state: UnreadStateChange) => state.optimisticResponse),
            debounceTime(2000)
          )
        ),
        /**
         * Fetch for changed contexts
         */
        concatMap((data: CountersChangedTrigger[]) => {
          if (_.isEmpty(data)) {
            return of(undefined);
          }
          let contextsByEmail: Dictionary<ShowInViewObject[]> = {};

          let countersByEmail = _.groupBy(data, countersChangedTrigger => countersChangedTrigger.forUserEmail);

          _.forEach(Object.keys(countersByEmail), (email: string) => {
            if (!(email in contextsByEmail)) {
              contextsByEmail[email] = [];
            }
            _.forEach(countersByEmail[email], counter => {
              if (!_.isEmpty(counter.events)) {
                _.forEach(counter.events, event => {
                  contextsByEmail[email].push({
                    view: event.view as ViewEnum,
                    channelId: event.channelId || undefined,
                    filter: (event.filter as FilterEnum) || undefined
                  });
                });
              }
            });
          });

          return from(Object.keys(contextsByEmail)).pipe(
            mergeMap((forUserEmail: string) => {
              return this.fetchUnreadCountsViews(
                forUserEmail,
                _.uniqBy(contextsByEmail[forUserEmail], context =>
                  [context.filter, context.view, context.channelId].join()
                )
              ).pipe(map(() => forUserEmail));
            })
          );
        }),
        /**
         * Trigger unread change
         */
        tap((forUserEmail: string) => {
          SharedSubjects._unreadStateChange$.next(new UnreadStateChange(forUserEmail));
          this.persistLocalState();
        }),
        /**
         * Handle error and resubscribe
         */
        catchError(err => {
          Logger.error(
            err,
            `Error in ${this.constructorName}:subscribeToTriggers(). Will resubscribe`,
            LogTag.INTERESTING_ERROR
          );

          this.subscribeToTriggers();
          return of(undefined);
        })
      )
      .subscribe();
  }

  private fetchUnreadCountsViews(forUserEmail: string, contexts: ShowInViewObject[] = []): Observable<any> {
    // Always fetch main views
    contexts.push(
      ...[
        {
          view: ViewEnum.LOOP_INBOX,
          filter: FilterEnum.INBOX
        },
        {
          view: ViewEnum.LOOP_INBOX,
          filter: FilterEnum.CHATS
        },
        {
          view: ViewEnum.LOOP_INBOX,
          filter: FilterEnum.ASSIGNED
        },
        {
          view: ViewEnum.PERSONAL_INBOX
        }
      ]
    );

    this.initLocalStateIfEmpty(forUserEmail);

    return this._badgeCountApiService.BadgeCount_GetList({ contexts }, forUserEmail).pipe(
      map((response: ListOfResourcesOfBadgeCount) => {
        if (!response || !response.resources) {
          return;
        }

        _.forEach(response.resources, (viewCount: BadgeCount) => {
          if (_.isEmpty(viewCount)) {
            return;
          }

          switch (viewCount.view) {
            case ViewEnum.LOOP_INBOX:
              this.handleLoopInboxCounterUpdate(forUserEmail, viewCount);
              break;
            case ViewEnum.PERSONAL_INBOX:
              this.handlePersonalInboxCounterUpdate(forUserEmail, viewCount);
              break;
            case ViewEnum.CHANNEL:
              this.handleChannelCountersUpdate(forUserEmail, viewCount);
              break;
            default:
              break;
          }
        });

        return this.unreadCountersByEmail[forUserEmail];
      }),
      catchError(err => {
        Logger.error(
          err,
          `Error in ${this.constructorName}:getUnreadCountForMainViews() for ${forUserEmail}`,
          LogTag.INTERESTING_ERROR
        );

        return of(this.unreadCountersByEmail[forUserEmail]);
      })
    );
  }

  private initLocalStateIfEmpty(forUserEmail: string): void {
    if (!_.has(this.unreadCountersByEmail, forUserEmail)) {
      this.unreadCountersByEmail[forUserEmail] = {
        myLoopInbox: 0,
        chat: 0,
        personalInbox: 0,
        totalUnreadCount: 0,
        totalItemCount: 0
      };
    }

    if (_.has(this.unreadCountersByEmail, forUserEmail)) {
      if (!('myLoopInbox' in this.unreadCountersByEmail[forUserEmail])) {
        this.unreadCountersByEmail[forUserEmail].myLoopInbox = 0;
      }
      if (!('chat' in this.unreadCountersByEmail[forUserEmail])) {
        this.unreadCountersByEmail[forUserEmail].chat = 0;
      }
      if (!('personalInbox' in this.unreadCountersByEmail[forUserEmail])) {
        this.unreadCountersByEmail[forUserEmail].personalInbox = 0;
      }
      if (!('total' in this.unreadCountersByEmail[forUserEmail])) {
        this.unreadCountersByEmail[forUserEmail].totalUnreadCount = 0;
      }
      if (!('totalItemCount' in this.unreadCountersByEmail[forUserEmail])) {
        this.unreadCountersByEmail[forUserEmail].totalItemCount = 0;
      }
    }
  }

  private handleLoopInboxCounterUpdate(forUserEmail: string, viewCount: BadgeCount) {
    switch (viewCount.filter) {
      case FilterEnum.CHATS:
        this.unreadCountersByEmail[forUserEmail].chat = viewCount.unreadCount;
        break;
      case FilterEnum.ASSIGNED:
        this.assignedCountersByEmail[forUserEmail] = viewCount.assignedCount;
        break;
      default:
        this.unreadCountersByEmail[forUserEmail].myLoopInbox = viewCount.unreadCount;
        this.unreadCountersByEmail[forUserEmail].totalUnreadCount = viewCount.unreadCount;
        this.unreadCountersByEmail[forUserEmail].totalItemCount = viewCount.totalCount;
        break;
    }
  }

  private handlePersonalInboxCounterUpdate(forUserEmail: string, viewCount: BadgeCount) {
    this.unreadCountersByEmail[forUserEmail].personalInbox = viewCount.unreadCount;
  }

  private handleChannelCountersUpdate(forUserEmail: string, viewCount: BadgeCount) {
    if (!this.contactsTotalUnreadCountersByEmail[forUserEmail]) {
      this.contactsTotalUnreadCountersByEmail[forUserEmail] = {};
    }

    this.contactsTotalUnreadCountersByEmail[forUserEmail][viewCount.channelId] = {
      ...viewCount.counts,
      totalCount: viewCount.totalCount
    };
  }

  private persistLocalState() {
    this._storageService.setStringifiedItem(StorageKey.unreadCountersByEmail, this.unreadCountersByEmail);
    this._storageService.setStringifiedItem(
      StorageKey.contactsTotalUnreadCountersByEmail,
      this.contactsTotalUnreadCountersByEmail
    );
    this._storageService.setStringifiedItem(StorageKey.assignedCountersByEmail, this.assignedCountersByEmail);
  }

  private loadLocalState() {
    this.unreadCountersByEmail = this._storageService.getParsedItem(StorageKey.unreadCountersByEmail) || {};
    this.assignedCountersByEmail = this._storageService.getParsedItem(StorageKey.assignedCountersByEmail) || {};
    this.contactsTotalUnreadCountersByEmail =
      this._storageService.getParsedItem(StorageKey.contactsTotalUnreadCountersByEmail) || {};

    this.initialLocalLoadDone = true;
  }
}
