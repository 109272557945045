import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BasePushSynchronizationService } from '../base-push-synchronization/base-push-synchronization.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import {
  CommentBaseModel,
  CommentModel,
  CommentTemplateModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import { CommentBase, CommentTemplate } from '@shared/api/api-loop/models';
import { CommentApiService } from '@shared/api/api-loop/services';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { CommentService } from '@shared/services/data/comment/comment.service';

@Injectable()
export class TemplatePushSynchronizationService extends BasePushSynchronizationService<CommentTemplateModel> {
  constructor(
    protected _api: ApiService,
    protected _commentService: CommentService,
  ) {
    super();
  }

  get constructorName(): string {
    return 'TemplatePushSynchronizationService';
  }

  protected synchronize(forUserEmail: string, localComment: CommentTemplateModel): Observable<any> {
    return of(undefined).pipe(
      /**
       * Sync with server
       */
      mergeMap(() => this.sendTemplate(forUserEmail, localComment)),
      map(CommentBaseModel.create),
      /**
       * Update body
       */
      mergeMap((syncedComment: CommentTemplateModel) =>
        forkJoin([this._commentService.updateCommentBody(forUserEmail, syncedComment), of(syncedComment)]),
      ),
      /**
       * Save template
       */
      mergeMap(([updatedComment, syncedComment]: [CommentTemplateModel, CommentTemplateModel]) => {
        // If there is no body on BE resources, use local (in case of 503)
        if (
          !updatedComment.body.mimeType &&
          !updatedComment.body.content &&
          !syncedComment.body.mimeType &&
          !syncedComment.body.content
        ) {
          updatedComment.body.mimeType = localComment.body.mimeType;
          updatedComment.body.content = localComment.body.content;
        }

        if (updatedComment.body.mimeType && updatedComment.body.content) {
          return this._commentService.saveAndPublish(forUserEmail, updatedComment);
        }

        return this._commentService.saveAndPublish(forUserEmail, syncedComment);
      }),
    );
  }

  private sendTemplate(
    forUserEmail: string,
    comment: CommentModel,
    isRetry: boolean = false,
  ): Observable<CommentTemplate> {
    let templateRequest: CommentApiService.Comment_CreateTemplateResponseParams = {
      commentTemplate: comment,
    };

    // If template has id we update comment
    let obs = this._api.CommentApiService.Comment_CreateTemplateResponse(templateRequest, forUserEmail);

    if (comment.id) {
      obs = this._api.CommentApiService.Comment_UpdateTemplate(templateRequest, forUserEmail);
    }

    return obs.pipe(
      /**
       * Catch conflict error
       */
      catchError(err => {
        // Handle conflict
        if (err.status === 409) {
          return this.handleTemplateConflictError(forUserEmail, err, comment, isRetry);
        }

        return throwError(err);
      }),
    );
  }

  private handleTemplateConflictError(
    forUserEmail: string,
    err: HttpErrorResponse,
    comment: CommentModel,
    isRetry: boolean,
  ): Observable<CommentBase> {
    // Retry only once
    if (isRetry) {
      Logger.customLog(
        `[SYNC] - PushSync [${forUserEmail}]: handleTemplateConflictError called after retry. Will no longer retry. Template id: ${comment.id}, clientId: ${comment.clientId}`,
        LogLevel.ERROR,
        [LogTag.INTERESTING_ERROR, LogTag.SYNC],
        true,
        'Template conflict error',
      );

      return throwError(err);
    }

    if (err.status !== 409 || (!comment.id && !_.has(err, 'error.conflictedResource.id'))) {
      return throwError(err);
    }

    // Comment already exists - we need to GET commentById
    let id = comment.id || err.error.conflictedResource.id;
    return this._api.CommentApiService.Comment_Get({ id: id }, forUserEmail).pipe(
      mergeMap((serverComment: CommentBase) => {
        // Update comment to server parameters and retry
        comment.id = id;
        comment.revision = serverComment.revision;

        return this.sendTemplate(forUserEmail, comment, true);
      }),
    );
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    comment: CommentModel,
  ): Observable<any> {
    if (err.status === 0) {
      // We lost connection and will retry
      return EMPTY;
    } else if (err.status === 401) {
      // Unauthorized - token should be refreshed and we'll retry
      return throwError(err);
    }

    /**
     * We cannot sync the comment, remove it from push-queue and publish
     */
    Logger.error(
      err,
      `[SYNC] - PushSync [${forUserEmail}]: could not sync template with id :${comment.id || comment._id}`,
      LogTag.SYNC,
    );
  }
}
