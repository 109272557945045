import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Renderer2,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, Validator } from '@angular/forms';

@Directive({
  selector: '[loopTimeMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeMaskDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeMaskDirective),
      multi: true,
    },
  ],
})
export class TimeMaskDirective implements ControlValueAccessor, Validator {
  _onChange!: (_: Date) => void;

  _touched!: () => void;

  private _dateValue!: Date;

  private _fieldJustGotFocus: boolean = false;

  private readonly _h: string;
  private readonly _m: string;

  private readonly timeInputNativeSupport: boolean;

  @HostBinding('type') type: string = 'text';

  constructor(
    @Self() private readonly _el: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly domDocument: Document,
  ) {
    this._h = 'hh';
    this._m = 'mm';
    this.timeInputNativeSupport = this.resolveNativeTimeInputSupport();
    if (this.timeInputNativeSupport) {
      this.type = 'time';
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent): void {
    if (this.timeInputNativeSupport) {
      return;
    }

    if (evt.key >= '0' && evt.key <= '9') {
      this._setInputText(evt.key);
    }
    evt.preventDefault();
  }

  @HostListener('click', ['$event'])
  onClick(evt: MouseEvent): void {
    if (this.timeInputNativeSupport) {
      return;
    }
    this._fieldJustGotFocus = true;
    const caretPosition = this._doGetCaretPosition();
    if (caretPosition < 3) {
      this._el.nativeElement.setSelectionRange(0, 2);
    } else {
      this._el.nativeElement.setSelectionRange(3, 6);
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(evt: any): void {
    if (this.timeInputNativeSupport) {
      return;
    }
    this._fieldJustGotFocus = true;
    const caretPosition = this._doGetCaretPosition();
    if (caretPosition < 3) {
      this._el.nativeElement.setSelectionRange(0, 2);
    } else {
      this._el.nativeElement.setSelectionRange(3, 6);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(evt: any): void {
    if (this.timeInputNativeSupport) {
      return;
    }
    this._touched();
    this._el.nativeElement.setSelectionRange(0, 0);
  }

  @HostListener('change')
  onChange(): void {
    if (this.timeInputNativeSupport) {
      this._controlValueChanged();
    }
  }

  private _setInputText(key: string): void {
    const input: string[] = this._el.nativeElement.value.split(':');

    const hours: string = input[0];
    const minutes: string = input[1];

    const caretPosition = this._doGetCaretPosition();
    if (caretPosition < 3) {
      this._setHours(hours, minutes, key);
    } else {
      this._setMinutes(hours, minutes, key);
    }

    this._fieldJustGotFocus = false;
  }

  private _setHours(hours: string, minutes: string, key: any): void {
    const hoursArray: string[] = hours.split('');
    const firstDigit: string = hoursArray[0];
    const secondDigit: string = hoursArray[1];

    let newHour = '';

    let completeTime = '';
    let sendCaretToMinutes = false;

    if (firstDigit === this._h || this._fieldJustGotFocus) {
      newHour = `0${key}`;
      sendCaretToMinutes = Number(key) > 2;
    } else {
      newHour = `${secondDigit}${key}`;
      if (Number(newHour) > 23) {
        newHour = '23';
      }
      sendCaretToMinutes = true;
    }

    completeTime = `${newHour}:${minutes}`;

    this._renderer.setProperty(this._el.nativeElement, 'value', completeTime);
    this._controlValueChanged();
    if (!sendCaretToMinutes) {
      this._el.nativeElement.setSelectionRange(0, 2);
    } else {
      this._el.nativeElement.setSelectionRange(3, 6);
      this._fieldJustGotFocus = true;
    }
  }

  private _setMinutes(hours: string, minutes: string, key: any): void {
    const minutesArray: string[] = minutes.split('');
    const firstDigit: string = minutesArray[0];
    const secondDigit: string = minutesArray[1];

    let newMinutes = '';

    let completeTime = '';

    if (firstDigit === this._m || this._fieldJustGotFocus) {
      newMinutes = `0${key}`;
    } else if (Number(minutes) === 59) {
      newMinutes = `0${key}`;
    } else {
      newMinutes = `${secondDigit}${key}`;
      if (Number(newMinutes) > 59) {
        newMinutes = '59';
      }
    }

    completeTime = `${hours}:${newMinutes}`;

    this._renderer.setProperty(this._el.nativeElement, 'value', completeTime);
    this._controlValueChanged();
    this._el.nativeElement.setSelectionRange(3, 6);
  }

  _clearHoursOrMinutes(): void {
    const caretPosition = this._doGetCaretPosition();
    const input: string[] = this._el.nativeElement.value.split(':');

    const hours: string = input[0];
    const minutes: string = input[1];

    let newTime = '';
    let sendCaretToMinutes = false;

    if (caretPosition > 2) {
      newTime = `${hours}:${this._m.repeat(2)}`;
      sendCaretToMinutes = true;
    } else {
      newTime = `${this._h.repeat(2)}:${minutes}`;
      sendCaretToMinutes = false;
    }

    this._fieldJustGotFocus = true;

    this._renderer.setProperty(this._el.nativeElement, 'value', newTime);
    this._controlValueChanged();
    if (!sendCaretToMinutes) {
      this._el.nativeElement.setSelectionRange(0, 2);
    } else {
      this._el.nativeElement.setSelectionRange(3, 6);
    }
  }

  writeValue(value: Date): void {
    if (value && !(value instanceof Date)) {
      throw new Error('TimeMask: invalid Date');
    }

    this._dateValue = new Date(value);

    const defaultValue = !this.timeInputNativeSupport ? `${this._h.repeat(2)}:${this._m.repeat(2)}` : null;
    const v = value ? this._dateToStringTime(value) : defaultValue;

    this._renderer.setProperty(this._el.nativeElement, 'value', v);
  }

  registerOnChange(fn: (_: Date) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._touched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
  }

  validate(c: UntypedFormControl): { [key: string]: any } | null {
    return this._el.nativeElement.value.indexOf(this._h) === -1 && this._el.nativeElement.value.indexOf(this._m) === -1
      ? null
      : { validTime: false };
  }

  private _doGetCaretPosition(): number {
    let iCaretPos = 0;

    const nativeElement = this._el.nativeElement;

    // IE Support
    // eslint-disable-next-line no-prototype-builtins
    if (this.domDocument.hasOwnProperty('selection')) {
      nativeElement.focus();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - because of old browsers support
      const oSel = this.domDocument.selection.createRange();

      oSel.moveStart('character', -nativeElement.value.length);

      iCaretPos = oSel.text.length;
    } else if (nativeElement.selectionStart || nativeElement.selectionStart === '0') {
      // Firefox support
      iCaretPos = nativeElement.selectionStart;
    }

    return iCaretPos;
  }

  private _zeroFill(value: number): string {
    return (value > 9 ? '' : '0') + value;
  }

  private _dateToStringTime(value: Date): string {
    return `${this._zeroFill(value.getHours())}:${this._zeroFill(value.getMinutes())}`;
  }

  private _stringToNumber(str: string): number {
    if (str.indexOf(this._h) === -1 && str.indexOf(this._m) === -1) {
      return Number(str);
    }

    const regExp = new RegExp(`${this._h}|${this._m}`, 'g');
    const finalStr = str.replace(regExp, '0');

    return Number(finalStr);
  }

  private _controlValueChanged(): void {
    const value = this._el.nativeElement.value;
    if (value) {
      const timeArray: string[] = value.split(':');
      this._dateValue = new Date(this._dateValue.setHours(this._stringToNumber(timeArray[0])));
      this._dateValue = new Date(this._dateValue.setMinutes(this._stringToNumber(timeArray[1])));
      this._onChange(this._dateValue);
    }
  }

  private resolveNativeTimeInputSupport(): boolean {
    const input = this.domDocument.createElement('input');
    const value = 'a';
    input.setAttribute('value', value);
    return input.value !== value;
  }
}
