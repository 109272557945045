import { Injectable, NgModule } from '@angular/core';
import { CardPopulateService } from './conversation-card-populate/card-populate/card-populate.service';
import { CommentPopulateService } from './comment-populate/comment-populate.service';
import { ConversationPopulateService } from './conversation-card-populate/conversation-populate/conversation-populate.service';
import { SharedTagPopulateService } from './shared-tags-populate/shared-tags-populate.service';
import { SharedTagDataAccessModule } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/shared-tag-data-access.module';

@Injectable()
export class PopulationService {
  constructor(
    public CardPopulateService: CardPopulateService,
    public CommentPopulateService: CommentPopulateService,
    public SharedTagPopulateService: SharedTagPopulateService
  ) {}
}

@NgModule({
  imports: [SharedTagDataAccessModule],
  exports: [],
  declarations: [],
  providers: [
    PopulationService,
    CardPopulateService,
    CommentPopulateService,
    SharedTagPopulateService,
    ConversationPopulateService
  ]
})
export class PopulationModule {}
