/* tslint:disable */

/**
 * Group sub types
 */
export type GroupSubType = 'Normal' | 'SharedInbox' | 'ManagedTeam' | 'PersonalInbox';

export module GroupSubType {
  export const NORMAL: GroupSubType = 'Normal';
  export const SHARED_INBOX: GroupSubType = 'SharedInbox';
  export const MANAGED_TEAM: GroupSubType = 'ManagedTeam';
  export const PERSONAL_INBOX: GroupSubType = 'PersonalInbox';

  export function values(): GroupSubType[] {
    return [NORMAL, SHARED_INBOX, MANAGED_TEAM, PERSONAL_INBOX];
  }
}
