/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { SyncSettingsBase } from '../models/sync-settings-base';
import { Response } from '../models/response';
import { SyncSettingsExchange } from '../models/sync-settings-exchange';
import { ResponseConflict } from '../models/response-conflict';
import { SyncSettingsGmail } from '../models/sync-settings-gmail';
import { SyncSettingsMicrosoft } from '../models/sync-settings-microsoft';
import { SyncSettingsImapSmtp } from '../models/sync-settings-imap-smtp';
import { SyncSettingsRemoteImap } from '../models/sync-settings-remote-imap';
import { UserSettings } from '../models/user-settings';
import { ScopeSettings } from '../models/scope-settings';

@Injectable()
export class SettingsApiService extends BaseService {
  /**
   * @param params The `SettingsApiService.Settings_GetSyncSettingsParams` containing the following parameters:
   *
   * - `Authorization`: Authorization header
   *
   * @return Exchange sync settings
   * or
   * Gmail sync settings
   * or
   * Microsoft sync settings
   * or
   * Remote imap sync settings
   * or
   * Native imap sync settings
   */
  Settings_GetSyncSettingsResponse(
    params: SettingsApiService.Settings_GetSyncSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsBase>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/settings/sync`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_GetSyncSettings');

    // body, headers and query params
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsBase;

          body = <SyncSettingsBase>response.body;

          return <HttpResponse<SyncSettingsBase>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_GetSyncSettingsParams` containing the following parameters:
   *
   * - `Authorization`: Authorization header
   *
   * @return Exchange sync settings
   * or
   * Gmail sync settings
   * or
   * Microsoft sync settings
   * or
   * Remote imap sync settings
   * or
   * Native imap sync settings
   */
  Settings_GetSyncSettings(
    params: SettingsApiService.Settings_GetSyncSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsBase> {
    const observable$ = this.Settings_GetSyncSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/sync`;
      const url = this.rootUrl + `/api/v1/settings/sync`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateExchangeSettingsParams` containing the following parameters:
   *
   * - `settingsExchange`: Exchange sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_CreateExchangeSettingsResponse(
    params: SettingsApiService.Settings_CreateExchangeSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsExchange>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/settings/exchange`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_CreateExchangeSettings');

    // body, headers and query params
    __body = params.settingsExchange;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsExchange;

          body = <SyncSettingsExchange>response.body;

          return <HttpResponse<SyncSettingsExchange>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateExchangeSettingsParams` containing the following parameters:
   *
   * - `settingsExchange`: Exchange sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_CreateExchangeSettings(
    params: SettingsApiService.Settings_CreateExchangeSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsExchange> {
    const observable$ = this.Settings_CreateExchangeSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/exchange`;
      const url = this.rootUrl + `/api/v1/settings/exchange`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateExchangeSettingsParams` containing the following parameters:
   *
   * - `settingsExchange`: Exchange sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateExchangeSettingsResponse(
    params: SettingsApiService.Settings_UpdateExchangeSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsExchange>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/settings/exchange`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_UpdateExchangeSettings');

    // body, headers and query params
    __body = params.settingsExchange;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsExchange;

          body = <SyncSettingsExchange>response.body;

          return <HttpResponse<SyncSettingsExchange>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateExchangeSettingsParams` containing the following parameters:
   *
   * - `settingsExchange`: Exchange sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateExchangeSettings(
    params: SettingsApiService.Settings_UpdateExchangeSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsExchange> {
    const observable$ = this.Settings_UpdateExchangeSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/exchange`;
      const url = this.rootUrl + `/api/v1/settings/exchange`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateGmailSettingsParams` containing the following parameters:
   *
   * - `settingsGmail`: Gmail sync settings
   *
   * - `Authorization`:
   */
  Settings_CreateGmailSettingsResponse(
    params: SettingsApiService.Settings_CreateGmailSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsGmail>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/settings/gmail`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_CreateGmailSettings');

    // body, headers and query params
    __body = params.settingsGmail;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsGmail;

          body = <SyncSettingsGmail>response.body;

          return <HttpResponse<SyncSettingsGmail>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateGmailSettingsParams` containing the following parameters:
   *
   * - `settingsGmail`: Gmail sync settings
   *
   * - `Authorization`:
   */
  Settings_CreateGmailSettings(
    params: SettingsApiService.Settings_CreateGmailSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsGmail> {
    const observable$ = this.Settings_CreateGmailSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/gmail`;
      const url = this.rootUrl + `/api/v1/settings/gmail`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateGmailSettingsParams` containing the following parameters:
   *
   * - `settingsGmail`: Gmail sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateGmailSettingsResponse(
    params: SettingsApiService.Settings_UpdateGmailSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsGmail>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/settings/gmail`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_UpdateGmailSettings');

    // body, headers and query params
    __body = params.settingsGmail;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsGmail;

          body = <SyncSettingsGmail>response.body;

          return <HttpResponse<SyncSettingsGmail>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateGmailSettingsParams` containing the following parameters:
   *
   * - `settingsGmail`: Gmail sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateGmailSettings(
    params: SettingsApiService.Settings_UpdateGmailSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsGmail> {
    const observable$ = this.Settings_UpdateGmailSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/gmail`;
      const url = this.rootUrl + `/api/v1/settings/gmail`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateMicrosoftSettingsParams` containing the following parameters:
   *
   * - `settingsMicrosoft`: Microsoft graph sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_CreateMicrosoftSettingsResponse(
    params: SettingsApiService.Settings_CreateMicrosoftSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsMicrosoft>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/settings/microsoft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_CreateMicrosoftSettings');

    // body, headers and query params
    __body = params.settingsMicrosoft;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsMicrosoft;

          body = <SyncSettingsMicrosoft>response.body;

          return <HttpResponse<SyncSettingsMicrosoft>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateMicrosoftSettingsParams` containing the following parameters:
   *
   * - `settingsMicrosoft`: Microsoft graph sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_CreateMicrosoftSettings(
    params: SettingsApiService.Settings_CreateMicrosoftSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsMicrosoft> {
    const observable$ = this.Settings_CreateMicrosoftSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/microsoft`;
      const url = this.rootUrl + `/api/v1/settings/microsoft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateMicrosoftSettingsParams` containing the following parameters:
   *
   * - `settingsMicrosoft`: Microsoft graph sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateMicrosoftSettingsResponse(
    params: SettingsApiService.Settings_UpdateMicrosoftSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsMicrosoft>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/settings/microsoft`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_UpdateMicrosoftSettings');

    // body, headers and query params
    __body = params.settingsMicrosoft;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsMicrosoft;

          body = <SyncSettingsMicrosoft>response.body;

          return <HttpResponse<SyncSettingsMicrosoft>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateMicrosoftSettingsParams` containing the following parameters:
   *
   * - `settingsMicrosoft`: Microsoft graph sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateMicrosoftSettings(
    params: SettingsApiService.Settings_UpdateMicrosoftSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsMicrosoft> {
    const observable$ = this.Settings_UpdateMicrosoftSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/microsoft`;
      const url = this.rootUrl + `/api/v1/settings/microsoft`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateImapSettingsParams` containing the following parameters:
   *
   * - `settingsImap`: Imap sync settings
   *
   * - `Authorization`:
   */
  Settings_CreateImapSettingsResponse(
    params: SettingsApiService.Settings_CreateImapSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsImapSmtp>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/settings/imap`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_CreateImapSettings');

    // body, headers and query params
    __body = params.settingsImap;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsImapSmtp;

          body = <SyncSettingsImapSmtp>response.body;

          return <HttpResponse<SyncSettingsImapSmtp>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateImapSettingsParams` containing the following parameters:
   *
   * - `settingsImap`: Imap sync settings
   *
   * - `Authorization`:
   */
  Settings_CreateImapSettings(
    params: SettingsApiService.Settings_CreateImapSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsImapSmtp> {
    const observable$ = this.Settings_CreateImapSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/imap`;
      const url = this.rootUrl + `/api/v1/settings/imap`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateImapSettingsParams` containing the following parameters:
   *
   * - `settingsImap`: Gmail sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateImapSettingsResponse(
    params: SettingsApiService.Settings_UpdateImapSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsImapSmtp>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/settings/imap`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_UpdateImapSettings');

    // body, headers and query params
    __body = params.settingsImap;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsImapSmtp;

          body = <SyncSettingsImapSmtp>response.body;

          return <HttpResponse<SyncSettingsImapSmtp>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateImapSettingsParams` containing the following parameters:
   *
   * - `settingsImap`: Gmail sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateImapSettings(
    params: SettingsApiService.Settings_UpdateImapSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsImapSmtp> {
    const observable$ = this.Settings_UpdateImapSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/imap`;
      const url = this.rootUrl + `/api/v1/settings/imap`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateRemoteImapSettingsParams` containing the following parameters:
   *
   * - `settingsRemoteImap`: Remote IMAP sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_CreateRemoteImapSettingsResponse(
    params: SettingsApiService.Settings_CreateRemoteImapSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsRemoteImap>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/settings/remoteimap`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_CreateRemoteImapSettings');

    // body, headers and query params
    __body = params.settingsRemoteImap;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsRemoteImap;

          body = <SyncSettingsRemoteImap>response.body;

          return <HttpResponse<SyncSettingsRemoteImap>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_CreateRemoteImapSettingsParams` containing the following parameters:
   *
   * - `settingsRemoteImap`: Remote IMAP sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_CreateRemoteImapSettings(
    params: SettingsApiService.Settings_CreateRemoteImapSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsRemoteImap> {
    const observable$ = this.Settings_CreateRemoteImapSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/remoteimap`;
      const url = this.rootUrl + `/api/v1/settings/remoteimap`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateRemoteImapSettingsParams` containing the following parameters:
   *
   * - `settingsRemoteImap`: Remote imap sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateRemoteImapSettingsResponse(
    params: SettingsApiService.Settings_UpdateRemoteImapSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<SyncSettingsRemoteImap>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/settings/remoteimap`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_UpdateRemoteImapSettings');

    // body, headers and query params
    __body = params.settingsRemoteImap;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SyncSettingsRemoteImap;

          body = <SyncSettingsRemoteImap>response.body;

          return <HttpResponse<SyncSettingsRemoteImap>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateRemoteImapSettingsParams` containing the following parameters:
   *
   * - `settingsRemoteImap`: Remote imap sync settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateRemoteImapSettings(
    params: SettingsApiService.Settings_UpdateRemoteImapSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SyncSettingsRemoteImap> {
    const observable$ = this.Settings_UpdateRemoteImapSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/remoteimap`;
      const url = this.rootUrl + `/api/v1/settings/remoteimap`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_GetUserSettingsParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`:
   */
  Settings_GetUserSettingsResponse(
    params: SettingsApiService.Settings_GetUserSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<UserSettings>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/settings/user`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_GetUserSettings');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: UserSettings;

          body = <UserSettings>response.body;

          return <HttpResponse<UserSettings>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_GetUserSettingsParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`:
   */
  Settings_GetUserSettings(
    params: SettingsApiService.Settings_GetUserSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<UserSettings> {
    const observable$ = this.Settings_GetUserSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/user`;
      const url = this.rootUrl + `/api/v1/settings/user`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateUserSettingsParams` containing the following parameters:
   *
   * - `settingsUser`: User settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateUserSettingsResponse(
    params: SettingsApiService.Settings_UpdateUserSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<UserSettings>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/settings/user`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_UpdateUserSettings');

    // body, headers and query params
    __body = params.settingsUser;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: UserSettings;

          body = <UserSettings>response.body;

          return <HttpResponse<UserSettings>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateUserSettingsParams` containing the following parameters:
   *
   * - `settingsUser`: User settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateUserSettings(
    params: SettingsApiService.Settings_UpdateUserSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<UserSettings> {
    const observable$ = this.Settings_UpdateUserSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/user`;
      const url = this.rootUrl + `/api/v1/settings/user`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateUserAliasParams` containing the following parameters:
   *
   * - `aliasEmail`: User alias
   *
   * - `enableForSend`: User can send email from alias
   *
   * - `defaultAlias`: User can set default alias for sending
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateUserAliasResponse(
    params: SettingsApiService.Settings_UpdateUserAliasParams,
    forUserEmail: string
  ): Observable<HttpResponse<UserSettings>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/alias/${params.aliasEmail}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_UpdateUserAlias');

    // body, headers and query params
    if (params.enableForSend != null) __params = __params.set('enableForSend', params.enableForSend.toString());
    if (params.defaultAlias != null) __params = __params.set('defaultAlias', params.defaultAlias.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: UserSettings;

          body = <UserSettings>response.body;

          return <HttpResponse<UserSettings>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_UpdateUserAliasParams` containing the following parameters:
   *
   * - `aliasEmail`: User alias
   *
   * - `enableForSend`: User can send email from alias
   *
   * - `defaultAlias`: User can set default alias for sending
   *
   * - `Authorization`: Authorization header
   */
  Settings_UpdateUserAlias(
    params: SettingsApiService.Settings_UpdateUserAliasParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<UserSettings> {
    const observable$ = this.Settings_UpdateUserAliasResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/alias/${params.aliasEmail}`;
      const url = this.rootUrl + `/alias/${params.aliasEmail}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_DeleteUserAliasParams` containing the following parameters:
   *
   * - `aliasEmail`: User alias
   *
   * - `Authorization`: Authorization header
   */
  Settings_DeleteUserAliasResponse(
    params: SettingsApiService.Settings_DeleteUserAliasParams,
    forUserEmail: string
  ): Observable<HttpResponse<UserSettings>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/alias/${params.aliasEmail}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_DeleteUserAlias');

    // body, headers and query params
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: UserSettings;

          body = <UserSettings>response.body;

          return <HttpResponse<UserSettings>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_DeleteUserAliasParams` containing the following parameters:
   *
   * - `aliasEmail`: User alias
   *
   * - `Authorization`: Authorization header
   */
  Settings_DeleteUserAlias(
    params: SettingsApiService.Settings_DeleteUserAliasParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<UserSettings> {
    const observable$ = this.Settings_DeleteUserAliasResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/alias/${params.aliasEmail}`;
      const url = this.rootUrl + `/alias/${params.aliasEmail}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_ListScopesSettingsParams` containing the following parameters:
   *
   * - `Authorization`: Authorization header
   */
  Settings_ListScopesSettingsResponse(
    params: SettingsApiService.Settings_ListScopesSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<string[]>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/settings/scopeslist`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_ListScopesSettings');

    // body, headers and query params
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: string[];

          body = <string[]>response.body;

          return <HttpResponse<string[]>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_ListScopesSettingsParams` containing the following parameters:
   *
   * - `Authorization`: Authorization header
   */
  Settings_ListScopesSettings(
    params: SettingsApiService.Settings_ListScopesSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<string[]> {
    const observable$ = this.Settings_ListScopesSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/scopeslist`;
      const url = this.rootUrl + `/api/v1/settings/scopeslist`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_GetScopeSettingsParams` containing the following parameters:
   *
   * - `scope`: Scope name
   *
   * - `Authorization`: Authorization header
   */
  Settings_GetScopeSettingsResponse(
    params: SettingsApiService.Settings_GetScopeSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ScopeSettings>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/settings/scope/${params.scope}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_GetScopeSettings');

    // body, headers and query params
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ScopeSettings;

          body = <ScopeSettings>response.body;

          return <HttpResponse<ScopeSettings>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_GetScopeSettingsParams` containing the following parameters:
   *
   * - `scope`: Scope name
   *
   * - `Authorization`: Authorization header
   */
  Settings_GetScopeSettings(
    params: SettingsApiService.Settings_GetScopeSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ScopeSettings> {
    const observable$ = this.Settings_GetScopeSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/scope/${params.scope}`;
      const url = this.rootUrl + `/api/v1/settings/scope/${params.scope}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_DeleteScopeSettingsParams` containing the following parameters:
   *
   * - `scope`: Scope name
   *
   * - `Authorization`: Authorization header
   */
  Settings_DeleteScopeSettingsResponse(
    params: SettingsApiService.Settings_DeleteScopeSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<void>> {
    let __method = 'DELETE';
    let __url = this.rootUrl + `/api/v1/settings/scope/${params.scope}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_DeleteScopeSettings');

    // body, headers and query params
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: void;

          return <HttpResponse<void>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_DeleteScopeSettingsParams` containing the following parameters:
   *
   * - `scope`: Scope name
   *
   * - `Authorization`: Authorization header
   */
  Settings_DeleteScopeSettings(
    params: SettingsApiService.Settings_DeleteScopeSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<void> {
    const observable$ = this.Settings_DeleteScopeSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/scope/${params.scope}`;
      const url = this.rootUrl + `/api/v1/settings/scope/${params.scope}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_PutScopeSettingsParams` containing the following parameters:
   *
   * - `scopeSettings`: scope settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_PutScopeSettingsResponse(
    params: SettingsApiService.Settings_PutScopeSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ScopeSettings>> {
    let __method = 'PUT';
    let __url = this.rootUrl + `/api/v1/settings/scope`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_PutScopeSettings');

    // body, headers and query params
    __body = params.scopeSettings;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ScopeSettings;

          body = <ScopeSettings>response.body;

          return <HttpResponse<ScopeSettings>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_PutScopeSettingsParams` containing the following parameters:
   *
   * - `scopeSettings`: scope settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_PutScopeSettings(
    params: SettingsApiService.Settings_PutScopeSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ScopeSettings> {
    const observable$ = this.Settings_PutScopeSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/scope`;
      const url = this.rootUrl + `/api/v1/settings/scope`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SettingsApiService.Settings_PatchScopeSettingsParams` containing the following parameters:
   *
   * - `scopeSettings`: scope settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_PatchScopeSettingsResponse(
    params: SettingsApiService.Settings_PatchScopeSettingsParams,
    forUserEmail: string
  ): Observable<HttpResponse<ScopeSettings>> {
    let __method = 'PATCH';
    let __url = this.rootUrl + `/api/v1/settings/scope`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Settings_PatchScopeSettings');

    // body, headers and query params
    __body = params.scopeSettings;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ScopeSettings;

          body = <ScopeSettings>response.body;

          return <HttpResponse<ScopeSettings>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SettingsApiService.Settings_PatchScopeSettingsParams` containing the following parameters:
   *
   * - `scopeSettings`: scope settings
   *
   * - `Authorization`: Authorization header
   */
  Settings_PatchScopeSettings(
    params: SettingsApiService.Settings_PatchScopeSettingsParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ScopeSettings> {
    const observable$ = this.Settings_PatchScopeSettingsResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/settings/scope`;
      const url = this.rootUrl + `/api/v1/settings/scope`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module SettingsApiService {
  /**
   * Parameters for Settings_GetSyncSettings
   */
  export type Settings_GetSyncSettingsParams = {
    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_CreateExchangeSettings
   */
  export type Settings_CreateExchangeSettingsParams = {
    /**
     * Exchange sync settings
     */
    settingsExchange: SyncSettingsExchange;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_UpdateExchangeSettings
   */
  export type Settings_UpdateExchangeSettingsParams = {
    /**
     * Exchange sync settings
     */
    settingsExchange: SyncSettingsExchange;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_CreateGmailSettings
   */
  export type Settings_CreateGmailSettingsParams = {
    /**
     * Gmail sync settings
     */
    settingsGmail: SyncSettingsGmail;

    Authorization?: string;
  };

  /**
   * Parameters for Settings_UpdateGmailSettings
   */
  export type Settings_UpdateGmailSettingsParams = {
    /**
     * Gmail sync settings
     */
    settingsGmail: SyncSettingsGmail;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_CreateMicrosoftSettings
   */
  export type Settings_CreateMicrosoftSettingsParams = {
    /**
     * Microsoft graph sync settings
     */
    settingsMicrosoft: SyncSettingsMicrosoft;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_UpdateMicrosoftSettings
   */
  export type Settings_UpdateMicrosoftSettingsParams = {
    /**
     * Microsoft graph sync settings
     */
    settingsMicrosoft: SyncSettingsMicrosoft;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_CreateImapSettings
   */
  export type Settings_CreateImapSettingsParams = {
    /**
     * Imap sync settings
     */
    settingsImap: SyncSettingsImapSmtp;

    Authorization?: string;
  };

  /**
   * Parameters for Settings_UpdateImapSettings
   */
  export type Settings_UpdateImapSettingsParams = {
    /**
     * Gmail sync settings
     */
    settingsImap: SyncSettingsImapSmtp;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_CreateRemoteImapSettings
   */
  export type Settings_CreateRemoteImapSettingsParams = {
    /**
     * Remote IMAP sync settings
     */
    settingsRemoteImap: SyncSettingsRemoteImap;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_UpdateRemoteImapSettings
   */
  export type Settings_UpdateRemoteImapSettingsParams = {
    /**
     * Remote imap sync settings
     */
    settingsRemoteImap: SyncSettingsRemoteImap;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_GetUserSettings
   */
  export type Settings_GetUserSettingsParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    Authorization?: string;
  };

  /**
   * Parameters for Settings_UpdateUserSettings
   */
  export type Settings_UpdateUserSettingsParams = {
    /**
     * User settings
     */
    settingsUser: UserSettings;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_UpdateUserAlias
   */
  export type Settings_UpdateUserAliasParams = {
    /**
     * User alias
     */
    aliasEmail: string;

    /**
     * User can send email from alias
     */
    enableForSend?: boolean;

    /**
     * User can set default alias for sending
     */
    defaultAlias?: boolean;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_DeleteUserAlias
   */
  export type Settings_DeleteUserAliasParams = {
    /**
     * User alias
     */
    aliasEmail: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_ListScopesSettings
   */
  export type Settings_ListScopesSettingsParams = {
    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_GetScopeSettings
   */
  export type Settings_GetScopeSettingsParams = {
    /**
     * Scope name
     */
    scope: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_DeleteScopeSettings
   */
  export type Settings_DeleteScopeSettingsParams = {
    /**
     * Scope name
     */
    scope: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_PutScopeSettings
   */
  export type Settings_PutScopeSettingsParams = {
    /**
     * scope settings
     */
    scopeSettings: ScopeSettings;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Settings_PatchScopeSettings
   */
  export type Settings_PatchScopeSettingsParams = {
    /**
     * scope settings
     */
    scopeSettings: ScopeSettings;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
