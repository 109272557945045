import * as _ from 'lodash';
import { CardBaseModel, CardModel } from '../models-api-loop/conversation-card/card/card.model';
import { CommentBaseModel, CommentModel } from '../models-api-loop/comment/comment.model';
import { ContactBaseModel, ContactModel } from '../models-api-loop/contact/contact.model';
import { BaseModel } from '../models-api-loop/base/base.model';

export class StateUpdates {
  static type: string = 'StateUpdates';
  readonly $type: string = StateUpdates.type;

  private _cards: CardModel[] = [];
  private _comments: CommentModel[] = [];
  private _contacts: ContactModel[] = [];
  private _others: BaseModel[] = [];

  private _remove: BaseModel[] = [];
  private _purge: BaseModel[] = [];

  constructor(models?: BaseModel[], remove?: BaseModel[], purge?: BaseModel[]) {
    this.add(models);
    this.addToRemove(remove);
    this.addToPurge(purge);
  }

  get all(): BaseModel[] {
    return _.concat(this._cards, this._comments, this._contacts, this._others);
  }

  get cards(): CardModel[] {
    return this._cards;
  }

  get comments(): CommentModel[] {
    return this._comments;
  }

  get contacts(): ContactModel[] {
    return this._contacts;
  }

  get others(): BaseModel[] {
    return this._others;
  }

  get remove(): BaseModel[] {
    return this._remove;
  }

  get purge(): BaseModel[] {
    return this._purge;
  }

  add(models: BaseModel[]): StateUpdates {
    if (_.isEmpty(models)) {
      return this;
    }

    _.forEach(models, (model: BaseModel) => {
      if (model instanceof CardBaseModel) {
        this._cards.push(model);
      } else if (model instanceof CommentBaseModel) {
        this._comments.push(<CommentModel>model);
      } else if (model instanceof ContactBaseModel) {
        this._contacts.push(<ContactModel>model);
      } else {
        this._others.push(model);
      }
    });

    return this.normalize();
  }

  addToRemove(models: BaseModel[]): StateUpdates {
    if (_.isEmpty(models)) {
      return this;
    }

    this._remove = _.unionBy(this.remove, models, model => model._id);
    return this;
  }

  addToPurge(models: BaseModel[]): StateUpdates {
    if (_.isEmpty(models)) {
      return this;
    }

    this._purge = _.unionBy(this.purge, models, model => model._id);
    return this;
  }

  mergeWith(stateUpdates: StateUpdates): StateUpdates {
    if (_.isEmpty(stateUpdates)) {
      return this;
    }

    this.add(stateUpdates.all);
    this.addToRemove(stateUpdates.remove);
    this.addToPurge(stateUpdates.purge);

    return this;
  }

  private normalize(): StateUpdates {
    // keep only last instance of unique entity
    this._cards = _.uniqBy(this._cards.reverse(), '_id');
    this._comments = _.uniqBy(this._comments.reverse(), '_id');
    this._contacts = _.uniqBy(this._contacts.reverse(), '_id');
    this._others = _.uniqBy(this._others.reverse(), '_id');
    this._remove = _.uniqBy(this._remove.reverse(), '_id');

    return this;
  }
}
