import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalResizeComponent } from '@dta/shared/components/vertical-resize/vertical-resize.component';

@NgModule({
  declarations: [VerticalResizeComponent],
  imports: [CommonModule],
  exports: [VerticalResizeComponent],
})
export class VerticalResizeModule {}
