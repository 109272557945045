import { Injectable } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

@Injectable()
export class UserManagerServiceWeb extends UserManagerService {
  switchUserByIndex(index: number): void {
    throw new Error('Method not implemented.');
  }

  updateLoggerInMainProcessUser(): void {}
}
