import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnoozeTagComponent } from '@shared/modules/conversations/components/conversation-header/snooze-tag/snooze-tag.component';
import { ConversationTagModule } from '@shared/ui/conversation-tag/conversation-tag.module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { CustomDropdownModule } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { SnoozeApiActionsModule } from '@shared/modules/tags/services/snooze-api-actions/snooze-api-actions.module';

@NgModule({
  declarations: [SnoozeTagComponent],
  imports: [
    CommonModule,
    ConversationTagModule,
    GraphicsModule,
    CustomDropdownModule,
    IconModule,
    SnoozeApiActionsModule,
  ],
  exports: [SnoozeTagComponent],
})
export class SnoozeTagModule {}
