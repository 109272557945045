import { FroalaOptions } from 'froala-editor';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';

interface Options {
  placeholder: string;
  toolbarContainerId?: string;
  isBase64ImageUpload?: boolean;
}

export function createOptions(options: Options): Partial<FroalaOptions> {
  return {
    imageAddNewLine: true,
    colorsText: [
      '#000000',
      '#343434',
      '#535353',
      '#878787',
      '#c1c1c1',
      '#eaeaea',
      '#f0f0f0',
      '#ffffff',
      '#fb0008',
      '#fd8708',
      '#ffff0b',
      '#22ff07',
      '#21ffff',
      '#0000ff',
      '#8500ff',
      '#fb00ff',
      '#f0c0c1',
      '#fbdfc2',
      '#fff0c1',
      '#d1e6c9',
      '#c6d9dc',
      '#c5dbf0',
      '#d0c7e3',
      '#e4c5d4',
      '#e38587',
      '#f6c18b',
      '#fee188',
      '#a8d098',
      '#92b8bd',
      '#8fb9e2',
      '#a494cc',
      '#ca93af',
      '#d64f53',
      '#f2a359',
      '#fed254',
      '#82ba6a',
      '#64959f',
      '#5d96d3',
      '#7b65b6',
      '#b3648f',
      '#be0006',
      '#de7e2b',
      '#ecb727',
      '#589b3e',
      '#376f7b',
      '#316eb9',
      '#533896',
      '#943766',
      '#850004',
      '#a34a0a',
      '#b07f06',
      '#2c6516',
      '#123e4a',
      '#0d3f81',
      '#270e62',
      '#5f0f37',
      '#520001',
      '#642f07',
      '#6b4e04',
      '#1f3f10',
      '#0d272e',
      '#0a2850',
      '#180b3c',
      '#3a0b24'
    ],
    colorsBackground: [
      '#000000',
      '#343434',
      '#535353',
      '#878787',
      '#c1c1c1',
      '#eaeaea',
      '#f0f0f0',
      '#ffffff',
      '#fb0008',
      '#fd8708',
      '#ffff0b',
      '#22ff07',
      '#21ffff',
      '#0000ff',
      '#8500ff',
      '#fb00ff',
      '#f0c0c1',
      '#fbdfc2',
      '#fff0c1',
      '#d1e6c9',
      '#c6d9dc',
      '#c5dbf0',
      '#d0c7e3',
      '#e4c5d4',
      '#e38587',
      '#f6c18b',
      '#fee188',
      '#a8d098',
      '#92b8bd',
      '#8fb9e2',
      '#a494cc',
      '#ca93af',
      '#d64f53',
      '#f2a359',
      '#fed254',
      '#82ba6a',
      '#64959f',
      '#5d96d3',
      '#7b65b6',
      '#b3648f',
      '#be0006',
      '#de7e2b',
      '#ecb727',
      '#589b3e',
      '#376f7b',
      '#316eb9',
      '#533896',
      '#943766',
      '#850004',
      '#a34a0a',
      '#b07f06',
      '#2c6516',
      '#123e4a',
      '#0d3f81',
      '#270e62',
      '#5f0f37',
      '#520001',
      '#642f07',
      '#6b4e04',
      '#1f3f10',
      '#0d272e',
      '#0a2850',
      '#180b3c',
      '#3a0b24'
    ],
    colorsStep: 8,
    imageDefaultAlign: 'left',
    imageDefaultWidth: 0,
    imageEditButtons: [],
    spellcheck: true,
    key: '7M/abvnujfhjiw&`ewX_GN\\==',
    placeholderText: options.placeholder,
    tabSpaces: 4,
    toolbarSticky: true,
    pluginsEnabled: [
      'align',
      'codeBeautifier',
      'codeView',
      'colors',
      'draggable',
      'embedly',
      'entities',
      'file',
      'fontAwesome',
      'fontFamily',
      'fontSize',
      'image',
      'imageTUI',
      'inlineStyle',
      'inlineClass',
      'lineHeight',
      'link',
      'lists',
      'paragraphFormat',
      'paragraphStyle',
      'quote',
      'save',
      'specialCharacters',
      'table',
      'url',
      'wordPaste'
    ],
    listAdvancedTypes: false,
    htmlIgnoreCssProperties: [
      'color',
      'border-left',
      'user-select',
      '-webkit-user-modify',
      'pointer-events',
      'box-sizing'
    ],
    pasteDeniedAttrs: ['class', 'id'],
    htmlAllowedEmptyTags: ['signature'],
    useClasses: false,
    tooltips: false,
    height: '100%',
    wordPasteModal: false,
    tableResizer: true,
    pastePlain: false,
    tableStyles: {
      'no-borders': 'Hide borders',
      'show-borders': 'Show borders'
    },
    tableCellStyles: {
      'no-borders': 'Hide borders',
      'show-borders': 'Show borders'
    },
    tableMultipleStyles: false,
    tableCellMultipleStyles: false,
    linkAlwaysBlank: true,
    fontFamily: {
      'Arial,Helvetica,sans-serif': 'Arial',
      'Calibri,sans-serif': 'Calibri',
      'Georgia,serif': 'Georgia',
      'Impact,Charcoal,sans-serif': 'Impact',
      'Open Sans,sans-serif': 'Open Sans',
      'Roboto,sans-serif': 'Roboto',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      'Times New Roman,Times,serif': 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana',
      ...osSpecificFonts()
    },
    fontFamilySelection: true,
    fontSizeSelection: true,
    fontSizeUnit: 'pt',
    fontSize: [
      '8',
      '9',
      '10',
      '11',
      '12',
      '14',
      '16',
      '18',
      '20',
      '22',
      '24',
      '26',
      '28',
      '30',
      '36',
      '48',
      '60',
      '72',
      '96'
    ],
    attribution: false,
    enter: 1, // Default HTML tag that is inserted on enter: 0 -> p, 1 -> div, 2 -> br,
    toolbarContainer: options.toolbarContainerId ? `#${options.toolbarContainerId}` : undefined
  };
}

function osSpecificFonts(): Record<string, string> {
  if (checkIfOS(OperatingSystem.WINDOWS)) {
    return {
      'Segoe UI': 'System (Segoe UI)'
    };
  }

  if (checkIfOS(OperatingSystem.DARWIN)) {
    return {
      '-apple-system,BlinkMacSystemFont,sans-serif': 'System (San Francisco)'
    };
  }

  return {};
}
