import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startWith',
})
export class StartWithPipe implements PipeTransform {
  transform(content: string, startWith: string): boolean {
    return content.startsWith(startWith);
  }
}
