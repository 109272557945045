/* tslint:disable */

/**
 * Possible Filter Types
 */
export type FilterEnum =
  | 'Inbox'
  | 'Chats'
  | 'Drafts'
  | 'Archived'
  | 'Assigned'
  | 'Sent'
  | 'Starred'
  | 'Muted'
  | 'Deleted'
  | 'AllMessages'
  | 'Snoozed'
  | 'SnoozedDue'
  | 'Unread'
  | 'Spam';

export module FilterEnum {
  export const INBOX: FilterEnum = 'Inbox';
  export const CHATS: FilterEnum = 'Chats';
  export const DRAFTS: FilterEnum = 'Drafts';
  export const ARCHIVED: FilterEnum = 'Archived';
  export const ASSIGNED: FilterEnum = 'Assigned';
  export const SENT: FilterEnum = 'Sent';
  export const STARRED: FilterEnum = 'Starred';
  export const MUTED: FilterEnum = 'Muted';
  export const DELETED: FilterEnum = 'Deleted';
  export const ALL_MESSAGES: FilterEnum = 'AllMessages';
  export const SNOOZED: FilterEnum = 'Snoozed';
  export const SNOOZED_DUE: FilterEnum = 'SnoozedDue';
  export const UNREAD: FilterEnum = 'Unread';
  export const SPAM: FilterEnum = 'Spam';

  export function values(): FilterEnum[] {
    return [
      INBOX,
      CHATS,
      DRAFTS,
      ARCHIVED,
      ASSIGNED,
      SENT,
      STARRED,
      MUTED,
      DELETED,
      ALL_MESSAGES,
      SNOOZED,
      SNOOZED_DUE,
      UNREAD,
      SPAM
    ];
  }
}
