import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseFormControlComponent } from '@shared/modules/form-controls/base-form-control.component';

@Component({
  selector: 'loop-form-control-wrapper',
  templateUrl: './form-control-wrapper.component.html',
  styleUrls: ['./form-control-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlWrapperComponent<T> {
  @Input() baseFormControl!: BaseFormControlComponent<T>;

  @Input() isColumnOriented: boolean = false;
}
