import * as _ from 'lodash';
import * as moment from 'moment';
import { LogLevel } from '@dta/shared/models/logger.model';
import { ContactBase } from '@shared/api/api-loop/models';
import { Logger } from '@shared/services/logger/logger';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { Injectable } from '@angular/core';
import { SharedAvatarServiceI } from './shared-avatar.interface';

@Injectable()
export abstract class SharedAvatarService implements SharedAvatarServiceI {
  constructor(protected _storageService: StorageService) {}

  abstract get constructorName(): string;

  abstract getAvatarFile(
    forUserEmail: string,
    filesUri: string,
    avatarFileName: string,
    contactId: string,
    contact?: ContactBase,
  ): string;

  abstract isFileNameEmptyPlaceholder(fileName: string): boolean;

  static getAvatarFileName(forUserEmail: string, contact: ContactBase): string {
    if (contact.$type === UserModel.type) {
      return forUserEmail + '_' + contact.id;
    }

    return contact.id;
  }

  getAvatarTimestamp(forUserEmail: string, contactId: string) {
    let timestamps = this.getTimestamps(forUserEmail);
    return moment(timestamps[contactId]).format('x');
  }

  addIdToLocalStore(forUserEmail: string, contactId: string) {
    let timestamps = this.getTimestamps(forUserEmail);

    timestamps[contactId] = moment().format();

    this.setTimestampsFromLocalStore(forUserEmail, timestamps);
  }

  getTimestamps(forUserEmail: string): { [key: string]: string } {
    let key = this._storageService.getKey(forUserEmail, StorageKey.avatarIdSync);
    return this._storageService.getParsedItem(key) || {};
  }

  removeTimestampsFromLocalStore(forUserEmail: string, contactIds: string[]) {
    if (!contactIds || contactIds.length === 0) {
      return;
    }

    if (!forUserEmail) {
      Logger.customLog(`${this.constructorName}:removeTimestampsFromLocalStore forUserEmail is missing`, LogLevel.WARN);
      return;
    }

    let timestamps = this.getTimestamps(forUserEmail);

    for (let i = 0; i < contactIds.length; i++) {
      delete timestamps[contactIds[i]];
    }

    this.setTimestampsFromLocalStore(forUserEmail, timestamps);
  }

  protected setTimestampsFromLocalStore(forUserEmail: string, timestamps: { [key: string]: string }) {
    let key = this._storageService.getKey(forUserEmail, StorageKey.avatarIdSync);
    this._storageService.setStringifiedItem(key, timestamps);
  }
}
