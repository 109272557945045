<ng-template #cdkMenuTpl>
  <div @slideMotion class="dropdown-menu" cdkMenu>
    <ng-container *ngIf="availableItems$ | async; let items">
      <span cdkMenuItem></span>
      <div class="flex flex-col" tabindex="0">
        <ng-container
          *ngFor="let item of items"
          [ngTemplateOutlet]="
            (item | isDropdownHelpTextItem)
              ? buttonItemTpl
              : (item | isDropdownRouterLinkItem)
                ? anchorItemTpl
                : buttonItemTpl
          "
        >
          <ng-template #buttonItemTpl>
            <ng-template [ngIf]="(item | asDropdownDelimiterItemPipe).isDelimiter" [ngIfElse]="buttonTpl">
              <hr class="m-0" />
            </ng-template>

            <ng-template #buttonTpl>
              <a
                [cdkMenuItemDisabled]="(item | asDropdownHelpTextItem).isHelpText"
                [class.dropdown-menu__item--danger]="item.isDanger"
                [class.dropdown-menu__item--selected]="item.isSelected"
                [class.dropdown-menu__item--helptext]="(item | asDropdownHelpTextItem).isHelpText"
                (cdkMenuItemTriggered)="handleItemClick(item)"
                class="dropdown-menu__item"
                cdkMenuItem
              >
                <div class="flex gap-x-half items-center">
                  <div>
                    <loop-icon *ngIf="item.icon" [name]="item.icon" height="14px" width="14px"></loop-icon>
                  </div>
                  <div class="flex items-center justify-between gap-x">
                    <span>{{ item.title }}</span>
                    <span class="shortcut" *ngIf="item.shortcut"> {{ item.shortcut }} </span>
                  </div>
                </div>
              </a>
            </ng-template>
          </ng-template>
          <ng-template #anchorItemTpl>
            <a
              [routerLink]="item | asDropdownRouterLinkItem | dropdownMenuItemRouterLink"
              [queryParams]="(item | asDropdownRouterLinkItem).queryParams ?? undefined"
              [fragment]="(item | asDropdownRouterLinkItem).routerLinkFragment ?? undefined"
              [class.dropdown-menu__item--danger]="item.isDanger"
              [class.dropdown-menu__item--selected]="item.isSelected"
              class="dropdown-menu__item"
              cdkMenuItem
            >
              <div class="flex items-center">
                <span>{{ item.title }}</span>
                <svg-comp *ngIf="item.icon" [src]="item.icon"></svg-comp>
              </div>
            </a>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
