/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { SubscriptionLicense } from '../models/subscription-license';
import { Response } from '../models/response';
import { SubscriptionLicensePatchRequest } from '../models/subscription-license-patch-request';
import { ListOfResourcesOfSubscriptionLicense } from '../models/list-of-resources-of-subscription-license';

@Injectable()
export class SubscriptionLicenseApiService extends BaseService {
  /**
   * @param params The `SubscriptionLicenseApiService.SubscriptionLicense_GetParams` containing the following parameters:
   *
   * - `id`: Subscription id
   *
   * - `Authorization`: Authorization header
   */
  SubscriptionLicense_GetResponse(
    params: SubscriptionLicenseApiService.SubscriptionLicense_GetParams,
    forUserEmail: string
  ): Observable<HttpResponse<SubscriptionLicense>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/subscription/license/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SubscriptionLicense_Get');

    // body, headers and query params
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SubscriptionLicense;

          body = <SubscriptionLicense>response.body;

          return <HttpResponse<SubscriptionLicense>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionLicenseApiService.SubscriptionLicense_GetParams` containing the following parameters:
   *
   * - `id`: Subscription id
   *
   * - `Authorization`: Authorization header
   */
  SubscriptionLicense_Get(
    params: SubscriptionLicenseApiService.SubscriptionLicense_GetParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SubscriptionLicense> {
    const observable$ = this.SubscriptionLicense_GetResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription/license/${params.id}`;
      const url = this.rootUrl + `/api/v1/subscription/license/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SubscriptionLicenseApiService.SubscriptionLicense_PatchSubscriptionLicenseParams` containing the following parameters:
   *
   * - `subscription`:
   *
   * - `id`:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SubscriptionLicense_PatchSubscriptionLicenseResponse(
    params: SubscriptionLicenseApiService.SubscriptionLicense_PatchSubscriptionLicenseParams,
    forUserEmail: string
  ): Observable<HttpResponse<SubscriptionLicense>> {
    let __method = 'PATCH';
    let __url = this.rootUrl + `/api/v1/subscription/license/${params.id}`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SubscriptionLicense_PatchSubscriptionLicense');

    // body, headers and query params
    __body = params.subscription;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SubscriptionLicense;

          body = <SubscriptionLicense>response.body;

          return <HttpResponse<SubscriptionLicense>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionLicenseApiService.SubscriptionLicense_PatchSubscriptionLicenseParams` containing the following parameters:
   *
   * - `subscription`:
   *
   * - `id`:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SubscriptionLicense_PatchSubscriptionLicense(
    params: SubscriptionLicenseApiService.SubscriptionLicense_PatchSubscriptionLicenseParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SubscriptionLicense> {
    const observable$ = this.SubscriptionLicense_PatchSubscriptionLicenseResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription/license/${params.id}`;
      const url = this.rootUrl + `/api/v1/subscription/license/${params.id}`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SubscriptionLicenseApiService.SubscriptionLicense_GetListOfSubscriptionLicensesParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SubscriptionLicense_GetListOfSubscriptionLicensesResponse(
    params: SubscriptionLicenseApiService.SubscriptionLicense_GetListOfSubscriptionLicensesParams,
    forUserEmail: string
  ): Observable<HttpResponse<ListOfResourcesOfSubscriptionLicense>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/subscription/license/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SubscriptionLicense_GetListOfSubscriptionLicenses');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ListOfResourcesOfSubscriptionLicense;

          body = <ListOfResourcesOfSubscriptionLicense>response.body;

          return <HttpResponse<ListOfResourcesOfSubscriptionLicense>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionLicenseApiService.SubscriptionLicense_GetListOfSubscriptionLicensesParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SubscriptionLicense_GetListOfSubscriptionLicenses(
    params: SubscriptionLicenseApiService.SubscriptionLicense_GetListOfSubscriptionLicensesParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ListOfResourcesOfSubscriptionLicense> {
    const observable$ = this.SubscriptionLicense_GetListOfSubscriptionLicensesResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription/license/list`;
      const url = this.rootUrl + `/api/v1/subscription/license/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `SubscriptionLicenseApiService.SubscriptionLicense_GetTopPrioritySubscriptionParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SubscriptionLicense_GetTopPrioritySubscriptionResponse(
    params: SubscriptionLicenseApiService.SubscriptionLicense_GetTopPrioritySubscriptionParams,
    forUserEmail: string
  ): Observable<HttpResponse<SubscriptionLicense>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/subscription/license`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('SubscriptionLicense_GetTopPrioritySubscription');

    // body, headers and query params
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: SubscriptionLicense;

          body = <SubscriptionLicense>response.body;

          return <HttpResponse<SubscriptionLicense>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `SubscriptionLicenseApiService.SubscriptionLicense_GetTopPrioritySubscriptionParams` containing the following parameters:
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  SubscriptionLicense_GetTopPrioritySubscription(
    params: SubscriptionLicenseApiService.SubscriptionLicense_GetTopPrioritySubscriptionParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<SubscriptionLicense> {
    const observable$ = this.SubscriptionLicense_GetTopPrioritySubscriptionResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/subscription/license`;
      const url = this.rootUrl + `/api/v1/subscription/license`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module SubscriptionLicenseApiService {
  /**
   * Parameters for SubscriptionLicense_Get
   */
  export type SubscriptionLicense_GetParams = {
    /**
     * Subscription id
     */
    id: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SubscriptionLicense_PatchSubscriptionLicense
   */
  export type SubscriptionLicense_PatchSubscriptionLicenseParams = {
    subscription: SubscriptionLicensePatchRequest;

    id: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SubscriptionLicense_GetListOfSubscriptionLicenses
   */
  export type SubscriptionLicense_GetListOfSubscriptionLicensesParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for SubscriptionLicense_GetTopPrioritySubscription
   */
  export type SubscriptionLicense_GetTopPrioritySubscriptionParams = {
    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
