import { Pipe, PipeTransform } from '@angular/core';
import { DropdownHelpTextItem, DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';

@Pipe({
  name: 'asDropdownHelpTextItem',
})
export class AsDropdownHelpTextItemPipe implements PipeTransform {
  transform(item: DropdownItem): DropdownHelpTextItem {
    return item as DropdownHelpTextItem;
  }
}
