import { ChangeDetectionStrategy, Component, inject, Injector, Input, OnDestroy } from '@angular/core';
import { CustomDropdownComponentConfiguration } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.directive';
import { SnoozeDropdownComponent } from '@shared/modules/conversations/components/conversation-header/dropdowns/snooze-dropdown/snooze-dropdown.component';
import { SnoozeApiActionsService } from '@shared/modules/tags/services/snooze-api-actions/snooze-api-actions.service';
import { Observable, ReplaySubject, switchMap } from 'rxjs';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'loop-snooze-tag',
  templateUrl: './snooze-tag.component.html',
  styleUrls: ['./snooze-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnoozeTagComponent implements OnDestroy {
  private readonly injector: Injector = inject(Injector);
  private readonly snoozeApiActionsService: SnoozeApiActionsService = inject(SnoozeApiActionsService);

  protected readonly snoozeSvg: NodeRequire = require('shared/assets/img/svg/snooze.svg');
  protected readonly removeSvg: NodeRequire = require('shared/assets/img/svg/close-icon.svg');

  protected readonly conversation$: ReplaySubject<ConversationModel> = new ReplaySubject<ConversationModel>(1);
  @Input() set conversation(conversation: ConversationModel) {
    this.conversation$.next(conversation);
  }

  protected readonly hasSnoozeDue$: Observable<boolean> = this.conversation$.pipe(
    map(conversation => {
      return conversation.hasSnoozeDue();
    }),
    distinctUntilChanged(),
  );

  protected readonly snoozeDueDate$: Observable<string> = this.conversation$.pipe(
    filter(conversation => {
      return !conversation.hasSnoozeDue();
    }),
    map(conversation => {
      return conversation.getSnoozeDisplayDate();
    }),
    distinctUntilChanged(),
  );

  protected readonly snoozeDropdownComponentConfiguration: CustomDropdownComponentConfiguration<any> = {
    component: {
      component: SnoozeDropdownComponent,
      parentInjector: this.injector,
    },
  };

  discardSnooze(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.conversation$
      .pipe(
        switchMap(conversation => {
          return this.snoozeApiActionsService.discardSnooze$(conversation);
        }),
        take(1),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.conversation$.complete();
  }
}
