<div class="flex flex-col gap-half">
  <div class="dropdown-header">
    <div class="flex justify-between flex-1">
      FOLDERS
      <ng-container *ngIf="groupId$ | async; let groupId">
        <div class="flex gap-quarter" (click)="createFolder(groupId)">
          <loop-icon name="circle-plus" width="16px" height="16px"></loop-icon>
          <span>Add</span>
        </div>
      </ng-container>
    </div>
  </div>

  <loop-text-control
    class="padding-horizontal"
    [ngModel]="folderSearchQuery$ | async"
    (ngModelChange)="folderSearchQuery$.next($event)"
    size="small"
    placeholder="Search"
  ></loop-text-control>

  <ng-container *ngIf="filteredFolders$ | async; let folders">
    <div *ngIf="folders && folders.length; else noFoldersTpl" class="folders-wrapper">
      <ng-container *ngTemplateOutlet="treeNodeTpl; context: { $implicit: folders }"></ng-container>
    </div>

    <ng-template #noFoldersTpl>
      <div>
        <span>No folders</span>
      </div>
    </ng-template>
  </ng-container>
</div>

<ng-template let-folders #treeNodeTpl>
  <ng-container *ngFor="let folder of folders; let index = index">
    <div
      class="folder"
      [style.padding-left]="16 + folder.indent * 12 + 'px'"
      [class.bg-light-ui-element]="folder.index === currentIndex"
      (click)="folderClicked(folder)"
    >
      <svg-comp class="svg-icon" [src]="folderSvg"> </svg-comp>

      <div class="folder-name">{{ folder.name }}</div>
    </div>

    <div *ngIf="folder?.children?.length" class="node__children">
      <ng-container *ngTemplateOutlet="treeNodeTpl; context: { $implicit: folder.children }"></ng-container>
    </div>
  </ng-container>
</ng-template>
