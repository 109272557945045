import { FileModel } from '../models-api-loop/file.model';

export enum SearchResultType {
  Online = 'SearchResultTypeOnline',
  Offline = 'SearchResultTypeOffline',
}

export interface LibrarySearchResults {
  $type: SearchResultType;
  result: FileModel[];
  length: number;
}
