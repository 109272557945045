import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, of, throttleTime } from 'rxjs';
import { ContactModel, UserModel } from '../../../../shared/models-api-loop/contact/contact.model';
import { debounceTime, map, mergeMap, tap } from 'rxjs/operators';
import { IPC } from '../../../../shared/communication/ipc-constants';
import { ProcessType, StopWatch } from '../../../../shared/utils/stop-watch';
import { SidebarContacts } from '../../../../shared/models/contact.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ElectronService } from '@shared/services/electron/electron';
import { ContactHelperService } from 'web/app/services/contact-helper/contact-helper.service';
import { ContactViewDecorateService } from '@shared/decorators/view-data-decorators/contact-view-decorator/contact-view-decorate.service';
import { CardUnreadService } from '@shared/services/data/card-unread/card-unread.service';
import { ContactUnreadCount } from '@shared/services/data/card-unread/card-unread.service.interface';
import { ContactService } from '@shared/services/data/contact/contact.service';

@Injectable()
export class MenuChannelsListService {
  private channelsToShow: Dictionary<any> = {};

  constructor(
    private _userManagerService: UserManagerService,
    private _cardUnreadService: CardUnreadService,
    private _contactHelperService: ContactHelperService,
    private _contactViewDecorateService: ContactViewDecorateService,
    private _electronService: ElectronService,
    private _contactService: ContactService,
  ) {}

  get constructorName(): string {
    return 'MenuChannelsListService';
  }

  /**
   * Adds channel id to list of channels that need to be shown in menu
   */
  addChannelToMenu(channelId: string): void {
    this.channelsToShow[channelId] = true;
  }

  getSidebarContacts(): Observable<SidebarContacts> {
    let watch = new StopWatch(this.constructorName + '.getSidebarContacts', ProcessType.SERVICE);

    return this._contactHelperService.subscribeToSidebarContacts(this._userManagerService.getCurrentUserEmail()).pipe(
      mergeMap((sidebarContacts: SidebarContacts) => {
        watch.log('setUnreadCountForContacts');
        return this.decorateContactsWithUnreadCount(this._userManagerService.getCurrentUserEmail(), sidebarContacts);
      }),
      tap((sidebarContacts: SidebarContacts) => {
        watch.log('setTouchBarFavorites');
        this.setTouchBarFavorites(sidebarContacts);
      }),
    );
  }

  public decorateContactsWithUnreadCount(
    forUserEmail: string,
    sidebarContacts: SidebarContacts,
    forceFetch?: boolean,
  ): Observable<SidebarContacts> {
    let allContacts = [
      ...sidebarContacts.favoriteChannels,
      ...sidebarContacts.peopleChannels,
      ...sidebarContacts.teamChannels,
      ...sidebarContacts.sharedInboxChannels,
      ...sidebarContacts.personalInboxChannels,
    ];

    return of(undefined).pipe(
      /**
       * Decorate with view data
       */
      mergeMap(() => {
        return this._contactViewDecorateService.decorateListViewData(forUserEmail, allContacts);
      }),
      /**
       * Decorate with unreads
       */
      mergeMap((decoratedContacts: ContactModel[]) => {
        return this.setTotalUnreadCountForContacts(forUserEmail, decoratedContacts, forceFetch);
      }),
      /**
       * Break by middle
       */
      map((decoratedContacts: ContactModel[]) => {
        let decoratedContactsByIds = _.keyBy(decoratedContacts, contact => contact.id);

        // Map decorated contacts to section
        sidebarContacts.favoriteChannels = _.map(
          sidebarContacts.favoriteChannels,
          contact => decoratedContactsByIds[contact.id],
        );
        sidebarContacts.peopleChannels = _.map(
          sidebarContacts.peopleChannels,
          contact => decoratedContactsByIds[contact.id],
        );
        sidebarContacts.teamChannels = _.map(
          sidebarContacts.teamChannels,
          contact => decoratedContactsByIds[contact.id],
        );
        sidebarContacts.sharedInboxChannels = _.map(
          sidebarContacts.sharedInboxChannels,
          contact => decoratedContactsByIds[contact.id],
        );

        return sidebarContacts;
      }),
    );
  }

  private setTotalUnreadCountForContacts(
    forUserEmail: string,
    contacts: ContactModel[],
    forceFetch: boolean,
  ): Observable<ContactModel[]> {
    let watch = new StopWatch(
      this.constructorName + '.setUnreadCountForContacts: ' + contacts.length,
      ProcessType.SERVICE,
      forUserEmail,
    );

    watch.log('getContactsUnreadCount: ' + contacts.length);
    return this._cardUnreadService.getContactsTotalUnreadCount(forUserEmail, contacts, forceFetch).pipe(
      mergeMap((unreadDictionary: { [groupKey: string]: ContactUnreadCount }) => {
        watch.log('decorateViewData: ' + contacts.length);
        return this.decorateContactsWithTotalUnreads(forUserEmail, contacts, unreadDictionary);
      }),
    );
  }

  private decorateContactsWithTotalUnreads(
    forUserEmail: string,
    contacts: ContactModel[],
    unreadDictionary: { [groupKey: string]: ContactUnreadCount },
  ): Observable<ContactModel[]> {
    return this._contactViewDecorateService.decorateListViewData(forUserEmail, contacts).pipe(
      mergeMap((decoratedContacts: ContactModel[]) => {
        return this._contactViewDecorateService.decorateWithUnreadCounts(decoratedContacts, unreadDictionary);
      }),
    );
  }

  private setTouchBarFavorites(sidebarContacts: SidebarContacts) {
    // Set touchBar name for favorite users
    let favorites = _.map(sidebarContacts.favoriteChannels, (favorite: ContactModel) => {
      if (favorite instanceof UserModel) {
        favorite['touchbarName'] = favorite.name.replace(/\s.*/, '');
      } else {
        favorite['touchbarName'] = favorite.name;
      }

      return favorite;
    });

    // Set favorites for touchbar
    this._electronService.ipcRenderer.send(IPC.SET_FAVORITES, favorites);
  }
}
