import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import {
  ConversationFetchRequest,
  DraftCardsCollectionParams,
  DraftCommentCollectionParams,
  FetchRequestAgendaList,
  FetchRequestCard,
  FetchRequestFileList,
  FetchResult,
} from '@dta/shared/models/collection.model';
import { CardCollectionService } from './card-collection/card-collection.service';
import { FileCollectionService } from './file-collection/file-collection.service';
import { AgendaCollectionService } from './agenda-collection/agenda-collection.service';
import { CollectionServiceI } from '@shared/services/data/collection/collection.service.interface';
import { SingleCardCollectionService } from '@shared/services/data/collection/single-card-collection/single-card-collection.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { BaseService } from '@shared/services/data/base/base.service';
import { ConversationCollectionService } from './conversation-collection/conversation-collection.service';
import { DraftCollectionService } from './draft-collection/draft-collection.service';
import { CardsCollectionService } from './cards-collection/cards-collection.service';

@Injectable()
export class CollectionService extends BaseService implements CollectionServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    private _cardCollectionService: CardCollectionService,
    private _cardsCollectionService: CardsCollectionService,
    private _fileCollectionService: FileCollectionService,
    private _singleCardCollectionService: SingleCardCollectionService,
    private _agendaCollectionService: AgendaCollectionService,
    private _conversationCollectionService: ConversationCollectionService,
    private _draftCollectionService: DraftCollectionService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'CollectionService';
  }

  fetchFiles(forUserEmail: string, fetchRequest: FetchRequestFileList): Observable<FetchResult> {
    return this._fileCollectionService.fetchFiles(forUserEmail, fetchRequest);
  }

  fetchAgendaItems(forUserEmail: string, fetchRequest: FetchRequestAgendaList): Observable<FetchResult> {
    return this._agendaCollectionService.fetchAgendaItems(forUserEmail, fetchRequest);
  }

  fetchCommentsByCard(forUserEmail: string, fetchRequest: FetchRequestCard): Observable<FetchResult> {
    return this._cardCollectionService.fetchCommentsByCard(forUserEmail, fetchRequest);
  }

  fetchCard(forUserEmail: string, cardId: string): Observable<CardModel> {
    return this._singleCardCollectionService.fetchCard(forUserEmail, cardId);
  }

  fetchConversations(forUserEmail: string, fetchRequest: ConversationFetchRequest): Observable<FetchResult> {
    return this._conversationCollectionService.fetchConversations(forUserEmail, fetchRequest);
  }

  fetchCards(forUserEmail: string, fetchRequest: ConversationFetchRequest): Observable<FetchResult> {
    return this._cardsCollectionService.fetchCards(forUserEmail, fetchRequest);
  }

  fetchDraftsForCard(forUserEmail: string, params: DraftCardsCollectionParams): Observable<FetchResult> {
    return this._draftCollectionService.fetchDraftsForCard(forUserEmail, params);
  }

  fetchDraftComment(forUserEmail: string, params: DraftCommentCollectionParams): Observable<FetchResult> {
    return this._draftCollectionService.fetchDraftComment(forUserEmail, params);
  }

  fetchUpdates(forUserEmail: string, historyId: string, cutOffTimeStorageKey: string): Observable<string> {
    return this._conversationCollectionService.fetchUpdates(forUserEmail, historyId, cutOffTimeStorageKey);
  }
}
