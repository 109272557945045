import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LogConfiguration } from './api-configuration';
import { HomeApiService } from './services/home-api.service';
import { LogApiService } from './services/log-api.service';

@Injectable()
export class LogService {
  constructor(
    public HomeApiService: HomeApiService,
    public LogApiService: LogApiService
  ) {}
}

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [LogConfiguration, LogService, HomeApiService, LogApiService]
})
export class LogModule {}
