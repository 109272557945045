import { BaseDaoService } from '../base/base-dao.service';
import { File } from '@shared/api/api-loop/models/file';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { Observable } from 'rxjs';
import { CommentBaseModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { ContactBase } from '@shared/api/api-loop/models';

export interface FileDaoServiceI {
  findFilesBySearchQuery(forUserEmail: string, query: string, offset: number, size: number): Observable<FileModel[]>;

  findFilesBySearchQueryAndRecentFilter(forUserEmail: string, query: string, filter: any, offset: number, size: number);

  findFiles(forUserEmail: string, params: any): Observable<FileModel[]>;

  findChannelFiles(forUserEmail: string, params: any): Observable<FileModel[]>;

  findFilesForComments(forUserEmail: string, comments: CommentBaseModel[]): Observable<FileModel[]>;

  findCommentIdsForFileHashes(forUserEmail: string, fileHashes: string[]): Observable<{ [hash: string]: string[] }>;

  findNonChannelFilesByIds(forUserEmail: string, files: File[], contact: ContactBase): Observable<FileModel[]>;

  updateChannelIdsByFiles(forUserEmail: string, files: FileModel[], contactId: string): Observable<FileModel[]>;
}

export abstract class FileDaoService extends BaseDaoService<FileModel, File> implements FileDaoServiceI {
  abstract findFilesBySearchQuery(
    forUserEmail: string,
    query: string,
    offset: number,
    size: number,
  ): Observable<FileModel[]>;

  abstract findFilesBySearchQueryAndRecentFilter(
    forUserEmail: string,
    query: string,
    filter: any,
    offset: number,
    size: number,
  );

  abstract findFiles(forUserEmail: string, params: any): Observable<FileModel[]>;

  abstract findChannelFiles(forUserEmail: string, params: any): Observable<FileModel[]>;

  abstract findFilesForComments(forUserEmail: string, comments: CommentBaseModel[]): Observable<FileModel[]>;

  abstract findCommentIdsForFileHashes(
    forUserEmail: string,
    fileHashes: string[],
  ): Observable<{
    [hash: string]: string[];
  }>;

  abstract findNonChannelFilesByIds(forUserEmail: string, files: File[], contact: ContactBase): Observable<FileModel[]>;

  abstract updateChannelIdsByFiles(
    forUserEmail: string,
    files: FileModel[],
    contactId: string,
  ): Observable<FileModel[]>;
}
