import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ErrorCode } from '@shared/api/api-loop/models/error-code';
import { FormResponseType } from '@shared/modules/main/user-settings/channels/create/create-inbox/create-inbox.component';

@Injectable()
export class CreateChannelFormDialogService {
  dialogEvents$: Subject<CreateSIFormDialogData> = new Subject();

  get dialogEventsObs(): Observable<CreateSIFormDialogData> {
    return this.dialogEvents$.asObservable();
  }
}

export interface CreateSIFormDialogData {
  type: 'open' | 'close' | 'update';
  data?: CreateSIFormDialogExtraData;
}

export interface CreateSIFormDialogExtraData {
  subject?: Subject<FormResponseType>;
  formType?: CreateChannelFormType;
  aliases?: string[];
  email?: string;
  forbiddenEmail?: string;
  disableEmailInput?: boolean;
  error?: string;
  errorCode?: ErrorCode | 'OTHER_LOGIN_ERROR' | 'UNAUTHORIZED';
}

export type CreateChannelFormType = 'microsoft-exchange' | 'imap' | 'microsoft-shared-inbox' | 'gmail-alias';
