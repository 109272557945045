import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { createAvailableDropdownItemsObservable } from '@shared/ui/dropdown/helpers/create-available-dropdown-items';
import { isDropdownActionItem, isDropdownHelpTextItem } from '@shared/ui/dropdown/helpers/dropdown-type-guards';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

const ANIMATION_TRANSITION_IN = `0.2s cubic-bezier(0.23, 1, 0.32, 1)`;
const ANIMATION_TRANSITION_OUT = `0.2s cubic-bezier(0.755, 0.05, 0.855, 0.06)`;

@Component({
  selector: 'loop-dropdown-menu-renderer',
  templateUrl: './browser-menu-renderer.component.html',
  styleUrls: ['./browser-menu-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideMotion', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'scaleY(0.8)'
        })
      ),
      state(
        'enter',
        style({
          opacity: 1,
          transform: 'scaleY(1)'
        })
      ),
      transition('void => *', [animate(ANIMATION_TRANSITION_IN)]),
      transition('* => void', [animate(ANIMATION_TRANSITION_OUT)])
    ])
  ]
})
export class BrowserDropdownRendererComponent {
  private readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  @ViewChild('cdkMenuTpl') readonly templateRef!: TemplateRef<void>;

  availableItems$?: Observable<readonly DropdownItem[]>;
  waitingElements$?: Observable<readonly DropdownItem[]>;

  private initialAvailableElements$?: Observable<readonly DropdownItem[]>;

  onOpen(): void {
    if (this.waitingElements$) {
      this.availableItems$ = this.waitingElements$;
      this.waitingElements$ = undefined;
    }
  }

  setItems(dropdownItems: readonly DropdownItem[], options?: { isDropdownOpened: boolean }): void {
    if (!options.isDropdownOpened) {
      this.availableItems$ = createAvailableDropdownItemsObservable(dropdownItems);
      this.initialAvailableElements$ = this.availableItems$;
    } else {
      this.waitingElements$ = createAvailableDropdownItemsObservable(dropdownItems);
    }
    this.cdr.detectChanges();
  }

  appendItems(dropdownItems: readonly DropdownItem[]): void {
    this.availableItems$ = this.initialAvailableElements$.pipe(
      take(1),
      map(availableItems => {
        return [...availableItems, ...dropdownItems];
      })
    );
  }

  handleItemClick(item: DropdownItem): void {
    if (isDropdownActionItem(item) && !isDropdownHelpTextItem(item) && item.action) {
      item.action();
    }
  }
}
