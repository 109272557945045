import * as _ from 'lodash';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedUserManagerService } from 'dta/shared/services/shared-user-manager/shared-user-manager.service';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';

@Injectable()
export class HttpLoopApiAuthInterceptor implements HttpInterceptor {
  private readonly authHeaderName: string = 'Authorization';
  private readonly authTokenType: string = 'Bearer';

  private readonly userEmailHeaderName: string = '__forUserEmail';

  constructor(
    private _sharedUserManagerService: SharedUserManagerService,
    private storageService: StorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = this.setAuthHeader(request.headers);

    request = request.clone({
      headers: headers
    });

    return next.handle(request);
  }

  private setAuthHeader(headers: HttpHeaders): HttpHeaders {
    let userEmail = headers.get(this.userEmailHeaderName);
    if (_.isNil(userEmail)) {
      return headers;
    }

    let token = this._sharedUserManagerService.getTokenByEmail(userEmail);

    headers = headers.delete(this.userEmailHeaderName);
    headers = headers.set(this.authHeaderName, `${this.authTokenType} ${token}`);

    return headers;
  }
}
