import * as _ from 'lodash';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SharedTagLabelModel } from '../../../../shared/models-api-loop/shared-tag/shared-tag.model';
import { SharedInboxTagComponent } from '../shared-inbox-tag/shared-inbox-tag.component';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { TrackingService } from '../../../../shared/services/tracking/tracking.service';
import { ClickTrackingLocation } from '../../../../shared/services/tracking/tracking.constants';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Component({
  selector: 'labels-display-header',
  templateUrl: 'labels-display-header.html',
  styleUrls: ['labels-display-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelsDisplayHeaderComponent extends SharedInboxTagComponent implements OnChanges {
  ////////////////////
  // Inputs, Outputs
  ////////////////////
  @Input() conversation: ConversationModel;
  @Input() showSharedTags: boolean = false;
  @Input() showSystemAssigneeTags: boolean = false;
  @Input() hasPendingSnooze: boolean = false;
  @Input() hasSnoozeDue: boolean = false;

  @Output() removeLabel = new EventEmitter<SharedTagLabelModel>();
  @Output() closeCard$ = new EventEmitter<never>();
  @Output() removeSnoozeDue$ = new EventEmitter<never>();

  /////////////////////
  // State variables
  /////////////////////
  isOnHeader: boolean = true;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _changeDetection: ChangeDetectorRef,
    protected _trackingService: TrackingService,
  ) {
    super(_userManagerService, _changeDetection, _trackingService);
    this.debounceTagsOverflow = this.checkTagsOverflow;
  }

  onRemoveLabel(label: SharedTagLabelModel) {
    // Track user click action
    this._trackingService.trackUserClick(
      this._userManagerService.getCurrentUserEmail(),
      ClickTrackingLocation.ThreadHeader,
      'Tag removed',
    );

    // Emit action to parent component
    this.removeLabel.emit(label);

    // Remove from view
    this.sharedLabelTags = _.filter(this.sharedLabelTags, t => t.id !== label.id);

    // Recalculate overflow
    this.checkTagsOverflow();
    this.triggerChangeDetection();
  }

  closeCard() {
    this.closeCard$.emit();
  }

  toggleHiddenTags(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.showHiddenTags = !this.showHiddenTags;

    this.checkTagsOverflow();
    this.triggerChangeDetection();
  }

  removeSnoozeDueTag() {
    this.removeSnoozeDue$.emit();
  }

  protected checkTagsOverflow() {
    if (_.isEmpty(this.sharedTagsContainer)) {
      return;
    }

    const sharedTagsContainerEl = this.sharedTagsContainer.nativeElement;
    const paddingThreshold: number = 10;

    this.hasOverflow = false;
    let needsOverflow: boolean = false;

    this.traverseSharedTagsLabels((label: HTMLElement) => {
      label.classList.remove('overflow');
      needsOverflow = needsOverflow || label.offsetTop > paddingThreshold;
    });

    if (!needsOverflow) {
      return;
    }

    sharedTagsContainerEl.classList.add('toggle-button-calculation-padding');
    this.traverseSharedTagsLabels((label: HTMLElement) => {
      const overflows: boolean = label.offsetTop > paddingThreshold;
      label.classList.toggle('overflow', !this.showHiddenTags && overflows);

      this.hasOverflow = this.hasOverflow || overflows;
    });
    sharedTagsContainerEl.classList.remove('toggle-button-calculation-padding');

    this.triggerChangeDetection();
  }

  private traverseSharedTagsLabels(callback: Function) {
    const sharedTagsContainerEl = this.sharedTagsContainer.nativeElement;
    for (const label of sharedTagsContainerEl.childNodes) {
      if (label.classList?.contains('traversable-do-not-remove')) {
        callback(<HTMLElement>label);
      }
    }
  }
}
