import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ListOfResourcesOfConversation, SearchQueryConversation } from '@shared/api/api-loop/models';

export const MAX_CACHE_SIZE: number = 50;

@Injectable()
export class ConversationCollectionCacheService {
  ///////////////
  // Cache state
  ///////////////
  private requestResponseAndParameters: CachedResponse[] = [];

  constructor() {}

  get constructorName(): string {
    return 'ConversationCollectionCacheService';
  }

  cacheResponse(
    forUserEmail: string,
    queryParameters: SearchQueryConversation,
    response: ListOfResourcesOfConversation,
  ) {
    if (!queryParameters || !response || !_.isEmpty(queryParameters.historyId)) {
      return;
    }

    let key = this.getKeyFromQuery(forUserEmail, queryParameters);
    let value = JSON.stringify(response);

    return this.addToCache(key, value);
  }

  getResponseFromCache(forUserEmail: string, queryParameters: SearchQueryConversation): ListOfResourcesOfConversation {
    if (!queryParameters || !_.isEmpty(queryParameters.historyId)) {
      return undefined;
    }

    let key = this.getKeyFromQuery(forUserEmail, queryParameters);
    let cachedResponse = this.getFromCache(key);

    if (_.isEmpty(cachedResponse)) {
      return undefined;
    }

    return JSON.parse(cachedResponse);
  }

  private addToCache(key: string, value: string) {
    if (this.requestResponseAndParameters.length >= MAX_CACHE_SIZE) {
      this.garbageCollectCache();
    }

    this.removeFromCache(key);

    let item: CachedResponse = {
      key: key,
      response: value,
    };

    this.requestResponseAndParameters.push(item);
  }

  private getFromCache(key: string): string {
    return _.find(this.requestResponseAndParameters, (item: CachedResponse) => item.key === key)?.response;
  }

  private removeFromCache(key: string) {
    this.requestResponseAndParameters = _.filter(
      this.requestResponseAndParameters,
      (item: CachedResponse) => item.key !== key,
    );
  }

  private garbageCollectCache() {
    this.requestResponseAndParameters.splice(0, 10);
  }

  private getKeyFromQuery(forUserEmail: string, queryParameters: SearchQueryConversation): string {
    let stringifiedParameters = JSON.stringify(queryParameters);
    return forUserEmail + '::' + stringifiedParameters;
  }
}

class CachedResponse {
  key: string;
  response: string;
}
