import * as _ from 'lodash';
import { forkJoin, of, Subscription } from 'rxjs';
import { bufferTime, catchError, concatMap, filter, mergeMap, tap } from 'rxjs/operators';
import { EventSynchronizationService } from '../../event-synchronization/event-synchronization.service';
import { OnDestroy } from '@angular/core';
import { SyncServiceStatus } from '@shared/models/sync/synchronization-service-status.model';
import { PullSynchronizationService } from '@shared/synchronization/pull-synchronization/pull-synchronization.service';
import { DataServiceShared } from '@shared/services/data/data.service';
import { Logger } from '@shared/services/logger/logger';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import {
  FollowUpSyncFor,
  FollowUpSyncForType,
} from '@shared/services/communication/shared-subjects/shared-subjects-models';

@AutoUnsubscribe()
export class FollowUpSynchronizationService implements OnDestroy {
  // State variables
  active: boolean = false;

  //////////////////
  // Subscriptions
  //////////////////
  private followUpSyncForSub: Subscription;

  constructor(
    private _userEmail: string,
    private _data: DataServiceShared,
    private _eventSynchronizationService: EventSynchronizationService,
    private _pullSynchronizationService: PullSynchronizationService,
  ) {}

  get constructorName(): string {
    return 'FollowUpSynchronizationService';
  }

  ngOnDestroy() {}

  startFollowUpSync(): Subscription {
    if (this.active) {
      return;
    }

    let watch = new StopWatch(this.constructorName + '.startFollowUpSync', ProcessType.SERVICE, this._userEmail);
    this.active = true;

    // Handle explicit requests
    this.handleFollowUpSyncFor();
  }

  private handleFollowUpSyncFor() {
    this.followUpSyncForSub?.unsubscribe();
    this.followUpSyncForSub = SharedSubjects._followUpSyncFor$
      .forUserEmail(this._userEmail)
      .pipe(
        bufferTime(500),
        filter((requests: FollowUpSyncFor[]) => {
          return !_.isEmpty(requests);
        }),
        // Do follow up sync
        concatMap((requests: FollowUpSyncFor[]) => {
          let allFollowUpSyncs = [];
          let requestsByType = _.groupBy(requests, (request: FollowUpSyncFor) => request.followUpSyncType);

          // Shared Card Header requests
          if (!_.isEmpty(requestsByType[FollowUpSyncForType.SharedCardHeader])) {
            let sharedCardHeaderRequests = requestsByType[FollowUpSyncForType.SharedCardHeader];
            let sharedCardIds = _.map(sharedCardHeaderRequests, (request: FollowUpSyncFor) => request.id);
            sharedCardIds = _.uniq(sharedCardIds);

            let fetchMissingSourceCards = this._data.CardService.fetchMissingSourceCardsByIds(
              this._userEmail,
              sharedCardIds,
            );
            allFollowUpSyncs.push(fetchMissingSourceCards);
          }

          // NOTE: add other follow up sync handlers here

          return forkJoin(allFollowUpSyncs);
        }),
      )
      .subscribe();
  }

  getStatus(): SyncServiceStatus {
    return this.active ? SyncServiceStatus.ACTIVE : SyncServiceStatus.INACTIVE;
  }
}
