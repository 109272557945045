import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SafeExternalUrl } from '@dta/ui/pipes/safe/safe-external-url.pipe';

@NgModule({
  declarations: [SafePipe, SafeUrlPipe, SafeExternalUrl],
  imports: [CommonModule],
  exports: [SafePipe, SafeUrlPipe, SafeExternalUrl]
})
export class SafeModule {}
