import { inject, Injectable } from '@angular/core';
import { BaseService } from '@shared/services/data/base/base.service';
import { AppointmentServiceInterface } from '@shared/services/data/appointment/appointment.service.interface';
import { CardAppointmentModel, CardBaseModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { map, Observable, of, switchMap } from 'rxjs';
import { AppointmentApiService } from '@shared/api/api-loop/services';
import { ListOfResourcesOfCardAppointment } from '@shared/api/api-loop/models/list-of-resources-of-card-appointment';
import { AgendaCollectionParams } from '@dta/ui/collections/agendas/agendas.collection';
import { CardService } from '@shared/services/data/card/card.service';
import { AppointmentResponse } from '@shared/api/api-loop/models/appointment-response';
import { MimeType } from '@dta/shared/models-api-loop/comment/comment.model';
import { ListOfResourcesOfAgendaSettings } from '@shared/api/api-loop/models/list-of-resources-of-agenda-settings';

@Injectable()
export class AppointmentService extends BaseService implements AppointmentServiceInterface {
  private readonly appointmentApiService: AppointmentApiService = inject(AppointmentApiService);
  private readonly cardService: CardService = inject(CardService);

  fetchAppointment(forUserEmail: string, appointmentId: string): Observable<CardAppointmentModel> {
    return this.appointmentApiService
      .Appointment_Get(
        {
          id: appointmentId,
          htmlFormat: MimeType.html,
        },
        forUserEmail,
      )
      .pipe(
        map(CardBaseModel.create),
        switchMap((_appointment: CardAppointmentModel) => {
          return this.cardService.saveAllAndPublish(forUserEmail, [_appointment]).pipe(map(() => _appointment));
        }),
      );
  }

  createAppointment(
    forUserEmail: string,
    appointment: CardAppointmentModel,
    contextId: string,
  ): Observable<CardAppointmentModel> {
    return this.appointmentApiService
      .Appointment_CreateAppointment(
        {
          cardAppointment: appointment,
          contextId: contextId,
        },
        forUserEmail,
      )
      .pipe(
        map(CardBaseModel.create),
        switchMap((_appointment: CardAppointmentModel) => {
          return this.cardService.saveAllAndPublish(forUserEmail, [_appointment]).pipe(map(() => _appointment));
        }),
      );
  }

  updateAppointment(
    forUserEmail: string,
    appointment: CardAppointmentModel,
    contextId?: string,
  ): Observable<CardAppointmentModel> {
    return this.appointmentApiService
      .Appointment_UpdateCardAppointment(
        {
          cardAppointment: appointment,
        },
        forUserEmail,
      )
      .pipe(
        map(CardBaseModel.create),
        switchMap((_appointment: CardAppointmentModel) => {
          return this.cardService.saveAllAndPublish(forUserEmail, [_appointment]).pipe(map(() => _appointment));
        }),
      );
  }

  deleteAppointment(forUserEmail: string, appointment: CardAppointmentModel): Observable<void> {
    return this.appointmentApiService
      .Appointment_DeleteCardAppointment(
        {
          id: appointment.id,
        },
        forUserEmail,
      )
      .pipe(
        switchMap(_ => {
          return this.cardService.removeAllAndPublish(forUserEmail, [appointment]);
        }),
      );
  }

  fetchAppointmentList(forUserEmail: string, params: AgendaCollectionParams): Observable<CardAppointmentModel[]> {
    return this.appointmentApiService
      .Appointment_GetAgendaList(
        {
          allTeamContexts: params.allTeamContexts,
          dateTimeStart: params.dateTimeStart,
          dateTimeEnd: params.dateTimeEnd,
          size: params.size,
          offset: params.offset,
        },
        forUserEmail,
      )
      .pipe(
        map((response: ListOfResourcesOfCardAppointment) => {
          return CardAppointmentModel.createList(response.resources);
        }),
        switchMap(appointments => {
          return this.cardService.saveAllAndPublish(forUserEmail, appointments).pipe(map(() => appointments));
        }),
      );
  }

  updateAppointmentResponse(
    forUserEmail: string,
    params: AppointmentApiService.Appointment_UpdateAppointmentResponseParams,
  ): Observable<AppointmentResponse> {
    return this.appointmentApiService.Appointment_UpdateAppointmentResponse(params, forUserEmail);
  }

  fetchAppointmentListSettings(
    forUserEmail: string,
    params: AppointmentApiService.Appointment_GetAgendaSettingsListParams,
  ): Observable<ListOfResourcesOfAgendaSettings> {
    return this.appointmentApiService.Appointment_GetAgendaSettingsList(params, forUserEmail, true);
  }
}
