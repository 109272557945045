<div *ngIf="files[currentFileIndex]; let file" class="file-wrapper">
  <div class="loop-dialog-panel-header w-100 text-center">
    <h3>{{ file.name }}</h3>

    <div class="flex gap-half items-center">
      <a (click)="downloadFile(file)">
        <loop-icon name="download" width="14px"></loop-icon>
      </a>
    </div>
    <a (click)="close()">
      <loop-icon name="xmark" width="14px"></loop-icon>
    </a>
  </div>
  <div class="content">
    <loop-file-preview class="w-100" [file]="file"></loop-file-preview>
  </div>

  <div *ngIf="currentFileIndex !== 0" class="back-arrow" (click)="previousFile()">
    <loop-icon name="arrow-right" width="20px"></loop-icon>
  </div>

  <div *ngIf="currentFileIndex < files.length - 1" class="next-arrow" (click)="nextFile()">
    <loop-icon name="arrow-right" width="20px"></loop-icon>
  </div>
</div>
