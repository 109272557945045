import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  isAfter,
  isWithinInterval,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from 'date-fns';

export function isInFourDaysAndLessThanFiveDays(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(addDays(today, 2)),
    end: endOfDay(addDays(today, 4))
  });
}

export function isInMoreThanFiveDaysAndLessThanWeek(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(addDays(today, 5)),
    end: endOfDay(addWeeks(today, 1))
  });
}

export function isInMoreThanWeekAndLessThanMonth(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(addWeeks(today, 1)),
    end: endOfDay(addMonths(today, 1))
  });
}

export function isInMoreThanMonthAndLessThanYear(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(addMonths(today, 1)),
    end: endOfDay(addYears(today, 12))
  });
}

export function isMoreThanTwoDaysAgoAndLessThanWeek(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(subWeeks(today, 1)),
    end: endOfDay(subDays(today, 2))
  });
}

export function isMoreThanFiveDaysAgoAndLessThanWeek(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(subWeeks(today, 1)),
    end: endOfDay(subDays(today, 5))
  });
}

export function isMoreThanWeekAgoAndLessThanMonth(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(subMonths(today, 1)),
    end: endOfDay(subWeeks(today, 1))
  });
}

export function isMoreThanMonthAgoAndLessThanYear(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(subMonths(today, 12)),
    end: endOfDay(subMonths(today, 1))
  });
}

export function isMoreThanYearAgo(date: Date): boolean {
  const today = new Date();
  const endOfTheYear = endOfDay(subYears(today, 1));
  return isAfter(endOfTheYear, date);
}

export function isMoreThanTwoDaysAgoAndLessThanThreeDays(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(subDays(today, 3)),
    end: endOfDay(subDays(today, 2))
  });
}

export function isMoreThanThreeDaysAgoAndLessThanWeek(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(subWeeks(today, 1)),
    end: endOfDay(subDays(today, 3))
  });
}

export function isInMoreThanTwoDaysAndLessThanWeek(date: Date): boolean {
  const today = new Date();
  return isWithinInterval(date, {
    start: endOfDay(addDays(today, 2)),
    end: endOfDay(addWeeks(today, 1))
  });
}
