import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable, OnDestroy } from '@angular/core';
import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { EventUserActiveReply, EventUserTyping, User, UserStatus } from '@shared/api/api-loop/models';
import { ContactModel, UserModel } from '../../../shared/models-api-loop/contact/contact.model';
import {
  bufferTime,
  catchError,
  concatMap,
  delay,
  exhaustMap,
  filter,
  flatMap,
  map,
  mergeMap,
  repeatWhen,
  startWith,
  switchMap,
  tap,
  toArray
} from 'rxjs/operators';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { AutoUnsubscribe } from '../../../shared/utils/subscriptions/auto-unsubscribe';
import { SharedSubjects } from '../../../../shared/services/communication/shared-subjects/shared-subjects';
import { HubEventUserTypingData } from '../../../../shared/services/communication/shared-subjects/shared-subjects-models';
import { PublisherService } from '../../../shared/services/publisher/publisher.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

////////////////
// Parameters
////////////////
// How long should we wait for ping before we deem user inactive
const inactiveUserThresholdSeconds_ActiveReply = 3;
const inactiveUserThresholdSeconds_Typing = 4;

@AutoUnsubscribe()
@Injectable()
export abstract class WebsocketCardEventsAggregationService implements OnDestroy {
  ///////////////////
  // State variables
  ///////////////////
  private currentActiveCards: WebsocketEventCard[] = [];
  private inactiveUserThresholdSecond: number = undefined;

  /////////////
  // Subjects
  /////////////
  private output$: Subject<WebsocketEventCard[]> = new Subject();

  ///////////////
  // Subscribers
  ///////////////
  private userSwitchSub: Subscription;
  private expiredUsersSub: Subscription;

  constructor(
    private _websocketCardEventType: WebsocketCardEventType,
    protected _userManagerService: UserManagerService,
    protected _contactService: ContactService
  ) {
    this.subscribeToUserSwitch();
    this.subscribeToExpiredUsers();

    this.inactiveUserThresholdSecond =
      _websocketCardEventType === WebsocketCardEventType.ActiveReply
        ? inactiveUserThresholdSeconds_ActiveReply
        : inactiveUserThresholdSeconds_Typing;
  }

  ngOnDestroy() {}

  registerCard(cardId: string): Observable<WebsocketEventCard[]> {
    return this.getWebsocketCard(cardId);
  }

  private subscribeToUserSwitch() {
    this.userSwitchSub && this.userSwitchSub.unsubscribe();
    this.userSwitchSub = this._userManagerService.userSwitch$
      .pipe(
        switchMap(() => {
          return this.registerWebsocketEventsListener(this._websocketCardEventType);
        })
      )
      .subscribe();
  }

  private subscribeToExpiredUsers() {
    this.expiredUsersSub && this.expiredUsersSub.unsubscribe();
    this.expiredUsersSub = of(null)
      .pipe(
        flatMap(() => {
          return this.removeExpiredUsersFromCards(this.currentActiveCards);
        }),
        tap((cards: WebsocketEventCard[]) => {
          this.output$.next(cards);
        }),
        repeatWhen((notifier: Observable<any>) => {
          return notifier.pipe(delay(3 * 1000));
        })
      )
      .subscribe();
  }

  private registerWebsocketEventsListener(websocketCardEventType: WebsocketCardEventType): Observable<any> {
    return of(undefined).pipe(
      /**
       * Subscribe to typing events
       * ExhaustMap takes care of only subscribing once per account switch!!
       */
      exhaustMap(() => {
        if (websocketCardEventType === WebsocketCardEventType.ActiveReply) {
          return SharedSubjects._hubEventActiveReply$.forUserEmail(this._userManagerService.getCurrentUserEmail());
        }

        return SharedSubjects._hubEventTyping$.forUserEmail(this._userManagerService.getCurrentUserEmail());
      }),
      /**
       * Unpack
       */
      map((data: HubEventUserTypingData) => {
        return data.event;
      }),
      /**
       * Join events in time span of 100ms
       */
      bufferTime(100),
      filter((events: WebsocketCardEvent[]) => {
        return !_.isEmpty(events);
      }),
      concatMap((events: WebsocketCardEvent[]) => {
        return this.processCardWebsocketEvents(events);
      })
    );
  }

  protected populateUsers(events: WebsocketCardEvent[]): Observable<EventUserTyping[]> {
    return from(events).pipe(
      mergeMap((event: WebsocketCardEvent) => {
        let currentUserEmail = this._userManagerService.getCurrentUserEmail();
        return this._contactService.getContactById(currentUserEmail, event.user.id).pipe(
          map((contact: ContactModel) => {
            event.user = contact;
            return event;
          })
        );
      }),
      toArray(),
      catchError(() => {
        return of([]);
      })
    );
  }

  protected processCardWebsocketEvents(events: WebsocketCardEvent[]): Observable<WebsocketEventCard[]> {
    if (_.isEmpty(events)) {
      return of([]);
    }

    return this.populateUsers(events).pipe(
      flatMap((events: EventUserTyping[]) => {
        return from(events);
      }),
      flatMap((event: EventUserTyping) => {
        return this.addUserToActiveCard(event);
      }),
      toArray(),
      tap((cards: WebsocketEventCard[]) => {
        this.output$.next(cards);
      }),
      flatMap(() => {
        return this.publishUserActiveOnlineStatus(events);
      })
    );
  }

  private publishUserActiveOnlineStatus(events: WebsocketCardEvent[]): Observable<any> {
    return from(events).pipe(
      map((event: WebsocketCardEvent) => {
        return event.user as UserModel;
      }),
      filter((user: UserModel) => {
        return user && user.onlineStatus !== UserStatus.ACTIVE;
      }),
      tap((user: UserModel) => {
        user.onlineStatus = UserStatus.ACTIVE;
      }),
      toArray(),
      tap((users: UserModel[]) => {
        PublisherService.publishEvent(this._userManagerService.getCurrentUserEmail(), users);
      })
    );
  }

  protected getWebsocketCard(cardId: string): Observable<WebsocketEventCard[]> {
    return this.output$.pipe(
      startWith(this.currentActiveCards),
      map((cards: WebsocketEventCard[]) => {
        return cards.filter(card => card.cardId === cardId);
      }),
      filter((cards: WebsocketEventCard[]) => !_.isEmpty(cards))
    );
  }

  protected addUserToActiveCard(event: WebsocketCardEvent): Observable<WebsocketEventCard> {
    if (_.isEmpty(event)) {
      return of(undefined);
    }

    return of(event).pipe(
      map((event: WebsocketCardEvent) => {
        // Try to find card
        let card = this.findCard(this.currentActiveCards, event.cardId);

        if (card) {
          // Update user on existing card
          card.users = _.uniqBy([this.addCurrentTimestampToUser(event.user), ...card.users], 'id');
          card.users = _.sortBy(card.users, (user: UserWithActionOnCard) => user.firstName);
        } else {
          // Create new card
          card = {
            cardId: event.cardId,
            users: [this.addCurrentTimestampToUser(event.user)]
          };

          // Add to array of active users
          this.currentActiveCards.push(card);
        }

        return card;
      })
    );
  }

  private findCard(cards: WebsocketEventCard[], cardId: string): WebsocketEventCard {
    if (_.isEmpty(cards)) {
      return undefined;
    }

    return _.find(cards, card => {
      return card.cardId === cardId;
    });
  }

  private removeExpiredUsersFromCards(cards: WebsocketEventCard[]): Observable<WebsocketEventCard[]> {
    let currentTime = moment();

    return from(cards).pipe(
      map((activeCard: WebsocketEventCard) => {
        activeCard.users = activeCard.users.filter(user => {
          return currentTime.diff(user.timestamp, 'seconds') <= this.inactiveUserThresholdSecond;
        });
        return activeCard;
      }),
      toArray()
    );
  }

  private addCurrentTimestampToUser(user: User): UserWithActionOnCard {
    return Object.assign({ timestamp: moment() }, user);
  }
}

export enum WebsocketCardEventType {
  Typing,
  ActiveReply
}

export type WebsocketCardEvent = EventUserTyping | EventUserActiveReply;

export interface WebsocketEventCard {
  cardId: string;
  users: UserWithActionOnCard[];
}

interface UserWithActionOnCard extends User {
  timestamp: any;
}
