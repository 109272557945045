import { isValid, parse, parseISO, startOfToday, toDate } from 'date-fns';

export function parseDate(date: string | number | Date): Date {
  return typeof date === 'string' ? parseISO(date) : toDate(date);
}

/**
 * Try to create date based on given date string and acceptable formats
 * Date string is parsed with first matching format
 * @param date - date string to be parsed
 * @param formats - array of all acceptable formats (priority from first element to last)
 */
export function parseDateWithFormats(date: string, formats: readonly string[] | string[]): Date | null {
  // Argument `formats` must remain immutable.
  const checkingFormats = [...formats];
  const format = checkingFormats.shift() as string;
  const parsedDate = parse(date, format, startOfToday());

  if (isValid(parsedDate)) {
    return parsedDate;
  }

  if (checkingFormats.length > 0) {
    return parseDateWithFormats(date, checkingFormats);
  }

  return null;
}
