<loop-form-control-wrapper [baseFormControl]="this">
  <div class="position-relative">
    <ng-container *ngIf="startIconName">
      <div class="start-icon-wrapper">
        <ng-container *loopStringTemplateOutlet="startIconName">
          <loop-icon
            class="cursor-pointer"
            (click)="startIconClick.next()"
            [name]="startIconName"
            width="18px"
          ></loop-icon>
        </ng-container>
      </div>
    </ng-container>

    <input
      #inputElement
      [attr.placeholder]="placeholder"
      [ngModel]="value$ | async"
      [ngClass]="size"
      (ngModelChange)="handleModelChange($event)"
      [id]="_id"
      [attr.disabled]="(isDisabled$ | async) ? '' : null"
      [disabled]="(isDisabled$ | async) === true"
      [type]="controlType"
      [readonly]="isReadonly"
      [class.icon-right-padding]="!!endIconName"
      [class.icon-left-padding]="!!startIconName"
      (blur)="controlBlur.next()"
      (focus)="onFocus()"
    />

    <ng-container *ngIf="endIconName">
      <div class="end-icon-wrapper">
        <ng-container *loopStringTemplateOutlet="endIconName">
          <loop-icon class="cursor-pointer" (click)="endIconClick.next()" [name]="endIconName" width="18px"></loop-icon>
        </ng-container>
      </div>
    </ng-container>
  </div>
</loop-form-control-wrapper>
