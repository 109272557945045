import { Injectable } from '@angular/core';
import { IFrameRequestHandlerI } from '@shared/services/integrations/iframe-request-handler.interface';
import { IFrameContactRequest, IFrameMethod } from '@intheloop/loop-integration';
import { Observable } from 'rxjs';
import { ContactService } from '@shared/services/data/contact/contact.service';

@Injectable()
export class ContactHelper implements IFrameRequestHandlerI {
  constructor(private _contactService: ContactService) {}

  handleRequest(forUserEmail: string, request: IFrameContactRequest, integrationId: string): Observable<any> {
    switch (request.method) {
      case IFrameMethod.GET_CONTACT:
        return this._contactService.getContactById(forUserEmail, request.contactId);
    }
  }
}
