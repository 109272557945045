<video
  #videoPlayer
  [class.isInFileDialog]="fileDialog"
  [class.video-not-supported]="videoNotSupported"
  (click)="togglePlay()"
  (loadedmetadata)="onloadedmetadata()"
  controls
  preload="auto"
>
  <source [src]="videoUrl" (error)="onVideoError.emit()" />
</video>

<!-- Thumbnail for non playable videos -->
<div
  *ngIf="videoNotSupported"
  class="video-not-supported-placeholder"
  (click)="preventClick($event)"
  title="Can't preview. Download video to view."
  [class.file-dialog]="fileDialog"
>
  <img class="placeholder-image" [src]="thumbnailPath" />
</div>
