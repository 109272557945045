import * as _ from 'lodash';
import {
  EventBase,
  EventCardCreated,
  EventCardUpdated,
  EventCommentCreated,
  EventCommentUpdated,
  EventContactBase,
  EventSharedTagsUpdated,
  EventTagsUpdated,
  EventUserAvailabilityStatusChanged,
  EventUserSettingsUpdated,
  EventUserStatus,
} from '@shared/api/api-loop/models';
import {
  EventFolderBase,
  EventSignatureBase,
  EventTagBase,
  EventType,
} from '../../event-synchronization/event-synchronization.service';
import {
  CommentChatModel,
  CommentMailModel,
  CommentTemplateModel,
} from '@dta/shared/models-api-loop/comment/comment.model';
import {
  CardAppointmentModel,
  CardChatModel,
  CardDraftModel,
  CardMailModel,
  CardSharedModel,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { GroupModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';

export class EventGroupsModel {
  private _cardCreated: EventCardCreated[] = [];
  private _commentCreated: EventCommentCreated[] = [];
  private _tagsUpdated: TagsUpdatedEventGroups = {
    all: [],
    comments: [],
    cards: [],
    contacts: [],
  };
  private _sharedTagsUpdated: SharedTagsUpdatedEventGroups = {
    all: [],
    cards: [],
    comments: [],
  };
  private _others: OtherEventGroups = {
    all: [],
    cards: [],
    comments: [],
    contacts: [],
    signatures: [],
    tags: [],
    folders: [],
    userSettings: [],
    userAvailability: [],
    others: [],
  };

  private constructor(private _events: EventBase[]) {}

  static buildEventGroups(events: EventBase[]): EventGroupsModel {
    let eventGroups = new EventGroupsModel(events);

    _.forEach(events, event => {
      switch (event.$type) {
        case EventType.CardCreated:
          eventGroups._cardCreated.push(event);
          break;
        case EventType.CommentCreated:
          eventGroups._commentCreated.push(event);
          break;
        case EventType.TagsUpdated:
          eventGroups.addTagsUpdatedEvent(event);
          break;
        case EventType.SharedTagsUpdated:
          eventGroups.addSharedTagsUpdatedEvent(event);
          break;
        default:
          eventGroups.addOtherEvent(event);
          break;
      }
    });

    return eventGroups;
  }

  private addTagsUpdatedEvent(event: EventTagsUpdated) {
    this._tagsUpdated.all.push(event);

    switch (event.tags.parent.$type) {
      case CommentMailModel.type:
      case CommentChatModel.type:
        this._tagsUpdated.comments.push(event);
        break;
      case CardMailModel.type:
      case CardSharedModel.type:
      case CardAppointmentModel.type:
      case CardChatModel.type:
        this._tagsUpdated.cards.push(event);
        break;
      case UserModel.type:
      case GroupModel.type:
        this._tagsUpdated.contacts.push(event);
        break;
    }
  }

  private addSharedTagsUpdatedEvent(event: EventSharedTagsUpdated) {
    this._sharedTagsUpdated.all.push(event);

    switch (event.tags.parent.$type) {
      case CardMailModel.type:
      case CardSharedModel.type:
      case CardAppointmentModel.type:
      case CardChatModel.type:
      case CardDraftModel.type:
        this._sharedTagsUpdated.cards.push(event);
        break;
      case CommentMailModel.type:
      case CommentChatModel.type:
      case CommentTemplateModel.type:
        this._sharedTagsUpdated.comments.push(event);
        break;
    }
  }

  private addOtherEvent(event: EventBase) {
    this._others.all.push(event);

    switch (event.$type) {
      case EventType.CardUpdated:
        this._others.cards.push(event);
        break;
      case EventType.CommentUpdated:
        this._others.comments.push(event);
        break;
      case EventType.ContactCreated:
      case EventType.ContactUpdated:
      case EventType.ContactDeleted:
      case EventType.UserStatus:
        this._others.contacts.push(event);
        break;
      case EventType.SignatureCreated:
      case EventType.SignatureUpdated:
      case EventType.SignatureDeleted:
        this._others.signatures.push(event);
        break;
      case EventType.TagCreated:
      case EventType.TagUpdated:
      case EventType.TagDeleted:
        this._others.tags.push(event);
        break;
      case EventType.FolderCreated:
      case EventType.FolderUpdated:
      case EventType.FolderDeleted:
        this._others.folders.push(event);
        break;
      case EventType.UserSettingsUpdated:
        this._others.userSettings.push(event);
        break;
      default:
        this._others.others.push(event);
        break;
    }
  }

  get cardCreated(): EventCardCreated[] {
    return this._cardCreated;
  }

  get commentCreated(): EventCommentCreated[] {
    return this._commentCreated;
  }

  get tagsUpdated(): TagsUpdatedEventGroups {
    return this._tagsUpdated;
  }

  get sharedTagsUpdated(): SharedTagsUpdatedEventGroups {
    return this._sharedTagsUpdated;
  }

  get others(): OtherEventGroups {
    return this._others;
  }
}

export interface TagsUpdatedEventGroups {
  all: EventTagsUpdated[];
  comments: EventTagsUpdated[];
  cards: EventTagsUpdated[];
  contacts: EventTagsUpdated[];
}

export interface SharedTagsUpdatedEventGroups {
  all: EventSharedTagsUpdated[];
  cards: EventSharedTagsUpdated[];
  comments: EventSharedTagsUpdated[];
}

export interface OtherEventGroups {
  all: EventBase[];
  cards: EventCardUpdated[];
  comments: EventCommentUpdated[];
  contacts: Array<EventContactBase | EventUserStatus>;
  signatures: EventSignatureBase[];
  tags: EventTagBase[];
  folders: EventFolderBase[];
  userSettings: EventUserSettingsUpdated[];
  userAvailability: EventUserAvailabilityStatusChanged[];
  others: EventBase[];
}
