import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy } from '@angular/core';
import { TooltipService } from './tooltip.service';
import { TooltipAlignment, TooltipOptions, TooltipPlacement } from './tooltip.component';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltip;
  data: TooltipOptions = {
    content: '',
    titleContent: '',
    contentSuffix: '',
    element: undefined,
    containerSelector: undefined,
    extraClass: '',
    placement: TooltipPlacement.LEFT,
    alignment: TooltipAlignment.CENTER,
    isTime: false,
    isDisabled: false,
  };

  constructor(
    @Inject(ElementRef) private _el,
    private _tooltipService: TooltipService,
  ) {}

  ngOnDestroy(): void {
    this._tooltipService.removeTooltip();
  }

  @HostListener('mouseenter', ['$event'])
  onMouseOver($event: MouseEvent) {
    if (!this.tooltip) {
      return;
    }

    const {
      content,
      titleContent,
      contentSuffix,
      containerSelector,
      extraClass,
      placement,
      alignment,
      isTime,
      isDisabled,
    } = this.tooltip;

    this.data.element = this._el.nativeElement;
    this.data.content = content;
    this.data.titleContent = titleContent;
    this.data.contentSuffix = contentSuffix;
    this.data.containerSelector = containerSelector;
    this.data.extraClass = extraClass;
    this.data.placement = placement;
    this.data.alignment = alignment;
    this.data.isTime = isTime;
    this.data.isDisabled = isDisabled;

    if (!isDisabled) {
      this._tooltipService.setTooltip(this.data);
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseOut() {
    this._tooltipService.removeTooltip();
  }

  @HostListener('click', ['$event'])
  onMouseClick() {
    this._tooltipService.removeTooltip();
  }
}
