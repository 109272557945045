export const CREATE_SI_COPY = {
  // Step 1 Form
  nameLabel: 'Shared Inbox Name',
  namePlaceholder: 'How does your team call this inbox?',
  descriptionPlaceholder: 'What will your team use this inbox for?',
  overviewSubtitle: 'Add shared inbox name and description below.',

  // Channels notification
  channelNotificationTitle: 'Connecting a shared inbox',
  channelNotificationContent:
    'Connecting your shared inbox is the most effective way to test how Loop can work for your business. Your emails from your shared inbox will come into Loop ready for you to assign, tag and discuss internally. You can reply directly to your customers from within Loop as well.',

  // Members notification
  membersNotificationTitle: 'How to add Members to a shared inbox',
  membersNotificationContent:
    'Grant access to teammates who will be collaborating with you in this shared inbox. You will also be able to edit this later.',
  membersNotificationCtaUrl:
    'https://help.intheloop.io/hc/en-gb/articles/360017945117-Add-admins-and-team-members-to-a-shared-inbox',
  membersNotificationCtaCopy: 'Learn how to edit members',

  // Success
  successTitle: 'Success, your shared inbox has been created',
  successCtaCopy: 'Take me to the shared inbox',
};
