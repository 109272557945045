import { Observable } from 'rxjs';

export interface AfterDialogOpen {
  afterDialogOpen(): void;
}

export interface BeforeDialogClose {
  beforeDialogClose$(): Observable<boolean>;
}

export function isAfterDialogOpen(component: any): component is AfterDialogOpen {
  return !!component && typeof component === 'object' && typeof component['afterDialogOpen'] === 'function';
}

export function isBeforeDialogClose(component: any): component is BeforeDialogClose {
  return !!component && typeof component === 'object' && typeof component['beforeDialogClose$'] === 'function';
}
