import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { statsErrorsToString } from '@angular-devkit/build-angular/src/webpack/utils/stats';
import * as _ from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class LightBackgroundService implements OnDestroy {
  readonly isLightBackground$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  constructor(private _storageService: StorageService) {
    this.isLightBackground$.next(this.getLightBackgroundState());
  }

  get key(): string {
    return StorageKey.lightBackground;
  }

  getLightBackgroundState(nativeElement?: HTMLDivElement): boolean {
    let state = this._storageService.getParsedItem(this.key) || false;
    if (state && nativeElement) {
      this.setLightBackground(nativeElement, state);
    }
    return state;
  }

  setLightBackgroundState(state: boolean, nativeElement?: HTMLDivElement) {
    this._storageService.setItem(this.key, state);
    if (nativeElement) {
      this.setLightBackground(nativeElement, state);
    }
  }

  setLightBackground(nativeElement: HTMLDivElement, lightBackground: boolean) {
    let wrapper = <HTMLDivElement>nativeElement.querySelector('.fr-box.fr-basic .fr-wrapper');
    let element = nativeElement.querySelector('.fr-box.fr-basic .fr-element');

    if (lightBackground) {
      if (!wrapper.classList.contains('force-light-bg')) {
        wrapper.classList.add('force-light-bg');
      }
      if (!element.classList.contains('force-light-bg')) {
        element.classList.add('force-light-bg');
      }
    } else {
      if (wrapper.classList.contains('force-light-bg')) {
        wrapper.classList.remove('force-light-bg');
      }

      if (element.classList.contains('force-light-bg')) {
        element.classList.remove('force-light-bg');
      }
    }
    this.setStyleFromOriginalStyles(nativeElement);
  }

  setStyleFromOriginalStyles(element: HTMLElement) {
    let changedColorElements = element.querySelectorAll(':not([ogsbc="none"]), [obsbc]');
    _.forEach(changedColorElements, (_element: HTMLElement) => {
      let originalBackground = _element.getAttribute('ogsbc');
      _element.setAttribute('ogsbc', _element.style.backgroundColor);
      _element.style.backgroundColor = originalBackground;

      let originalColor = _element.getAttribute('ogsc');
      _element.setAttribute('ogsc', _element.style.color);
      _element.style.color = originalColor;
    });
  }

  ngOnDestroy(): void {
    this.isLightBackground$.complete();
  }
}
