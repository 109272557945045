import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostCommentPlaceholderComponent } from '@dta/ui/components/post-comment/post-comment-placeholder/post-comment-placeholder.component';

@NgModule({
  declarations: [PostCommentPlaceholderComponent],
  imports: [CommonModule],
  exports: [PostCommentPlaceholderComponent],
})
export class PostCommentPlaceholderModule {}
