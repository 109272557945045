import * as _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {
  private L1Cache: { [cacheKey: string]: Cache } = {};

  constructor() {}

  getCacheByType(cacheType: CacheType): Cache {
    switch (cacheType) {
      case CacheType.sharedTags:
        return this.getCache(CacheType.sharedTags, 24);
      default:
        return this.getCache(CacheType.global, 24);
    }
  }

  private getCacheKey(cacheType: CacheType, forUserEmail?: string): string {
    let context = 'app_cache';
    if (forUserEmail) {
      context = forUserEmail;
    }
    return context + cacheType;
  }

  private getCache(cacheType: CacheType, TTL: number): Cache {
    let key = this.getCacheKey(cacheType);
    let cache = this.L1Cache[key];

    if (_.isEmpty(cache)) {
      this.L1Cache[key] = new Cache(TTL);
      cache = this.L1Cache[key];
    }

    return cache;
  }
}

export class Cache {
  private cache: { [id: string]: CacheElement } = {};
  private TTL: number = 24; // number of hours a non-updated entry is valid
  private maxNumberOfEntries: number = 50; //

  constructor(TTL?: number) {
    if (TTL) {
      this.TTL = TTL;
    }
  }

  // For tests
  getMaxNumberOfEntries(): number {
    return this.maxNumberOfEntries;
  }

  getItemById(id: string): any {
    let element = this.cache[id];

    let cutOffDate = new Date(new Date().getTime() + 60 * 60 * this.TTL * 1000);
    if (!element || new Date(element.changed).getTime() > cutOffDate.getTime()) {
      delete this.cache[id];
      return undefined;
    } else {
      // Updated time so we can sort by relevance
      element.changed = new Date().toISOString();
      this.cache[id] = element;
    }

    return element.item;
  }

  cacheItem(id: string, item: any): void {
    let cacheElement: CacheElement = {
      changed: new Date().toISOString(),
      item: item,
    };

    this.cache[id] = cacheElement;

    if (Object.keys(this.cache).length >= this.maxNumberOfEntries) {
      this.cleanUpCache();
    }
  }

  /**
   * Clean up cache to be 70% empty. This way we don't have to sort for every overflown element.
   */
  private cleanUpCache(): void {
    let cutOffIndex = Math.floor(this.maxNumberOfEntries * 0.7);
    let keys = Object.keys(this.cache);
    let entriesToRemove;

    keys = keys.sort((a: string, b: string) => (this.cache[a].changed > this.cache[b].changed ? -1 : 1));
    entriesToRemove = keys.splice(cutOffIndex);
    _.forEach(entriesToRemove, entry => {
      delete this.cache[entry];
    });
  }
}

export interface CacheElement {
  item: any;
  changed: string;
}

export enum CacheType {
  global = 'global',
  sharedTags = 'sharedTags',
  tagType = 'tagType',
}
