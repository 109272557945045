import * as _ from 'lodash';
import { concat, EMPTY, from, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  groupBy,
  map,
  mergeMap,
  startWith,
  tap,
  toArray
} from 'rxjs/operators';
import { BaseModel } from '../../shared/models-api-loop/base/base.model';
import { StorageService } from '../../shared/services/storage/storage.service';
import { Logger } from '@shared/services/logger/logger';
import { ProcessType, StopWatch } from '../../shared/utils/stop-watch';
import { hash } from '../../shared/utils/hashFunc';
import { CollectionCache, CollectionParams, FetchResult } from '../../shared/models/collection.model';
import { Injectable, OnDestroy } from '@angular/core';
import { PublisherService } from '../../shared/services/publisher/publisher.service';
import {
  LoadMoreMethod,
  LoadMoreResult,
  LoadMoreResultStatus
} from '../components/common/load-more/load-more.component';
import { BaseCollectionService } from './base.collection';
import { CONSTANTS } from '@shared/models/constants/constants';
import { ID, Time } from '../../shared/utils/common-utils';
import {
  PublishEvent,
  PublishEventType
} from '../../../shared/services/communication/shared-subjects/shared-subjects-models';
import { AutoUnsubscribe } from '../../shared/utils/subscriptions/auto-unsubscribe';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';

export interface CollectionSubscriberI {
  // Register collection. Will return key that will be unique for collection and its parameters
  registerCollection(
    forUserEmail: string,
    serviceInstance: BaseCollectionService<BaseModel>,
    params: CollectionParams,
    options?: CollectionOptions
  ): Observable<string>;

  // Register observer for key. It will emit data, when new data will be published
  // Collection instances are identified by their type and parameters supplied.
  // [!] NOTE [!]:
  // This method will return 'undefined' when there is no data
  registerObserver<T extends BaseModel>(forUserEmail: string, collectionKey: string): Observable<ObserverResponse<T>>;

  // Will trigger load more data from DB or API, based on parameters and loadMore state/position
  triggerLoadMore(
    forUserEmail: string,
    collectionKey: string,
    offset: number,
    size: number
  ): Observable<LoadMoreResult>;

  // Get parameters that were passed when collection was registered
  getParametersForKey(forUserEmail: string, collectionKey: string): CollectionParams;

  // Get parameters that were passed when collection was registered
  getFetchedDataSize(collectionKey: string): number;

  // Unregister all collection observers or only those of a certain group
  unregisterAllObservers(forUserEmail: string): void;
}

@Injectable()
@AutoUnsubscribe()
export class CollectionSubscriberService implements CollectionSubscriberI, OnDestroy {
  ///////////////////
  // State variables
  ///////////////////
  collectionsMetadataByKey: { [key: string]: CollectionMetadata } = {};
  private static _logEnabled: boolean = false;

  //////////////////
  // Subscriptions
  //////////////////
  private userSwitch: Subscription;
  private publishEvent: Subscription;

  constructor(
    private _storageService: StorageService,
    private _userManagerService: UserManagerService
  ) {
    this.subscribeToUserSwitch();

    if (!CONSTANTS.PRODUCTION) {
      window['Collections'] = this;
    }
  }

  get constructorName(): string {
    return 'CollectionSubscriberService';
  }

  ngOnDestroy(): void {}

  //////////////////
  // PUBLIC METHODS
  //////////////////
  registerCollection(
    forUserEmail: string,
    serviceInstance: BaseCollectionService<BaseModel>,
    params: CollectionParams,
    options: CollectionOptions = {}
  ): Observable<string> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil');
    }
    if (!serviceInstance) {
      throw new Error('serviceInstance cannot be nil');
    }
    if (!params) {
      throw new Error('params cannot be nil');
    }
    if (_.isNil(params.offset)) {
      throw new Error('params.offset cannot be nil');
    }
    if (_.isNil(params.size)) {
      throw new Error('params.size cannot be nil');
    }

    // Create metadata
    let meta = this.createCollectionMetadata(forUserEmail, serviceInstance, params, options);

    CollectionSubscriberService.log(meta.key, 'will register collection', SpecialLogAction.REGISTER_OBSERVER);

    // Return list key
    return of(meta.key);
  }

  registerObserver<T extends BaseModel>(forUserEmail: string, collectionKey: string): Observable<ObserverResponse<T>> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil');
    }
    if (!collectionKey) {
      throw new Error('collectionKey cannot be nil');
    }

    // Get collection metadata
    let meta: CollectionMetadata = this.collectionsMetadataByKey[collectionKey];

    if (!meta) {
      throw new Error('No metadata for key: ' + collectionKey + ' will not register observer');
    }

    CollectionSubscriberService.log(meta.key, 'will register observer', SpecialLogAction.REGISTER_OBSERVER);

    // Return subject
    return meta.subject.asObservable().pipe(
      tap((models: BaseModel[]) => {
        CollectionSubscriberService.log(meta.key, 'subject emitted ' + models.length + ' models');
      }),
      /**
       * Emit initial load more
       */
      startWith([]),
      mergeMap((models: T[]) => {
        if (meta.initDataEmitted) {
          return of(
            new ObserverResponse(
              models,
              new LoadMoreResult(LoadMoreResultStatus.NULL, LoadMoreMethod.QUERY, meta.loadMoreType)
            )
          );
        }

        // Store models that are emitted before initial data is loaded
        this.storeDataToPublish(meta, models);

        let hasDataToPublish = false;

        // Call initial load more
        CollectionSubscriberService.log(meta.key, 'will trigger initial load more');
        return this.triggerLoadMore(meta.forUserEmail, meta.key, meta.params.offset, meta.params.size, true).pipe(
          map((status: LoadMoreResult) => {
            // Mark initial emission
            meta.initDataEmitted = true;
            this.updateCollectionMetadata(meta);

            hasDataToPublish = !_.isEmpty(meta.dataToPublish);

            // Release all accumulated data
            this.publishData(meta);

            return new ObserverResponse([], status);
          }),
          /**
           * Emit empty array only if there is no data to publish
           */
          filter(() => !hasDataToPublish)
        );
      }),
      catchError(err => {
        Logger.error(err, `Error in observer with key: ${meta.key}`);
        return throwError(err);
      }),
      finalize(() => {
        // GC collection when subscription completes / fails
        this.unregisterObserver(forUserEmail, meta.key, meta.sessionId);
      })
    ) as Observable<ObserverResponse<T>>;
  }

  triggerLoadMore(
    forUserEmail: string,
    collectionKey: string,
    offset: number,
    size: number,
    initialLoadMore?: boolean
  ): Observable<LoadMoreResult> {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil');
    }
    if (!collectionKey) {
      throw new Error('collectionKey cannot be nil');
    }

    // Get collection metadata
    let meta: CollectionMetadata = this.collectionsMetadataByKey[collectionKey];

    if (!meta) {
      return throwError('No metadata for key: ' + collectionKey + ' will not register observer');
    }

    if (meta.subject.closed || meta.isLoadMoreInProgress) {
      return EMPTY;
    }

    let watch = new StopWatch(this.constructorName + '.triggerLoadMore', ProcessType.SERVICE, meta.forUserEmail);

    // Update paging params
    meta.params.offset = offset;
    meta.params.size = size;

    return of(meta).pipe(
      /**
       * Enforce only one API call at a time
       */
      filter((_meta: CollectionMetadata) => !_meta.isLoadMoreInProgress),
      tap(() => this.setIsLoadMoreInProgress(collectionKey, true)),
      /**
       * Trigger loadMore
       */
      mergeMap(() => {
        CollectionSubscriberService.log(meta.key, 'loadMore triggered', SpecialLogAction.LOAD_MORE_TRIGGERED);
        return this.callCorrectLoadMoreMethod(meta, watch);
      }),
      /**
       * Release results
       * Initial get will handle separately
       */
      tap(() => {
        if (!initialLoadMore) {
          this.publishData(meta);
        }
      }),
      /**
       * Catch any error to log it and than throw it
       */
      catchError(err => {
        // When token expired keep on trying
        if (err.status === 401 && err.error?.errorCode === 'TokenExpired') {
          Logger.error(
            err,
            `Tried to load more data with expired token for ${meta.service.constructorName}. Will retry.`
          );
          return timer(2_000).pipe(
            tap(() => this.setIsLoadMoreInProgress(meta.key, false)),
            mergeMap(() => this.triggerLoadMore(forUserEmail, collectionKey, offset, size, initialLoadMore))
          );
        }

        Logger.error(err, `Could not load more data for ${meta.service.constructorName}`);
        return throwError(err);
      }),
      /**
       * Fail safe in case of error
       */
      finalize(() => {
        this.finishLoadMore(meta);
        watch.log('done');
      })
    );
  }

  getFetchedDataSize(collectionKey: string): number {
    return this.collectionsMetadataByKey[collectionKey]?.fetchedData || 0;
  }

  setFetchedDataSize(collectionKey: string, size: number) {
    return (this.collectionsMetadataByKey[collectionKey].fetchedData = size);
  }

  private publishData(meta: CollectionMetadata) {
    if (!meta.dataToPublish || meta.dataToPublish.length <= 0) {
      return;
    }

    // Publish in next tick so observable can be subscribed to
    // (works fine on DTA because of IPC)
    setTimeout(() => {
      PublisherService.publishEvent(meta.forUserEmail, meta.dataToPublish, PublishEventType.Upsert, false);
      meta.dataToPublish = undefined;
      this.updateCollectionMetadata(meta);
    }, 1);
  }

  private storeDataToPublish(meta: CollectionMetadata, data: BaseModel[]) {
    if (_.isEmpty(meta.dataToPublish)) {
      meta.dataToPublish = [];
    }

    meta.dataToPublish.push(...data);
    this.updateCollectionMetadata(meta);
  }

  private finishLoadMore(meta: CollectionMetadata) {
    CollectionSubscriberService.log(meta.key, 'loadMore finish', SpecialLogAction.LOAD_MORE_FINISHED);

    this.setIsLoadMoreInProgress(meta.key, false);
  }

  private setIsLoadMoreInProgress(collectionKey: string, isLoadMoreInProgress: boolean) {
    if (!this.collectionsMetadataByKey[collectionKey]) {
      return;
    }
    this.collectionsMetadataByKey[collectionKey].isLoadMoreInProgress = isLoadMoreInProgress;
  }

  getParametersForKey(forUserEmail: string, collectionKey: string): CollectionParams {
    if (!forUserEmail) {
      throw new Error('forUserEmail cannot be nil');
    }
    if (!collectionKey) {
      throw new Error('collectionKey cannot be nil');
    }

    // Get collection metadata
    let meta: CollectionMetadata = this.collectionsMetadataByKey[collectionKey];

    if (!meta) {
      throw new Error('No metadata for key: ' + collectionKey + ' will not register observer');
    }

    return meta.params;
  }

  unregisterAllObservers(forUserEmail: string) {
    _.forEach(this.collectionsMetadataByKey, (collection: CollectionMetadata, key: string) => {
      this.unregisterObserver(forUserEmail, key);
    });
  }

  private callCorrectLoadMoreMethod(meta: CollectionMetadata, watch: StopWatch): Observable<LoadMoreResult> {
    switch (meta.loadMoreType) {
      case LoadMoreType.QUERY_THEN_FETCH:
        watch.log('queryThenFetchCollection');
        CollectionSubscriberService.log(meta.key, 'loadMore method: queryThenFetchCollection');
        return this.queryThenFetchCollection(meta);

      case LoadMoreType.QUERY_AND_FETCH:
        watch.log('queryAndFetchCollection');
        CollectionSubscriberService.log(meta.key, 'loadMore method: queryAndFetchCollection');
        return this.queryAndFetchCollection(meta);

      case LoadMoreType.ONLY_QUERY:
        watch.log('queryCollection');
        CollectionSubscriberService.log(meta.key, 'loadMore method: queryCollection');
        return this.queryOnly(meta);

      default:
        throw new Error('Unsupported meta.loadMoreType: ' + meta.loadMoreType);
    }
  }

  /////////////////////////
  // HANDLE PUBLISHED DATA
  /////////////////////////
  private subscribeToUserSwitch() {
    this.userSwitch?.unsubscribe();
    this.userSwitch = this._userManagerService.userSwitch$
      .pipe(
        tap(() => {
          this.subscribeToPublishEvents();
        })
      )
      .subscribe();
  }

  private subscribeToPublishEvents() {
    let currentUserEmail = this._userManagerService.getCurrentUserEmail();

    this.publishEvent?.unsubscribe();
    this.publishEvent = PublisherService.publishedEventsForUser(currentUserEmail)
      .pipe(
        concatMap((updates: PublishEvent) => {
          return this.processPublishEvent(updates);
        })
      )
      .subscribe();
  }

  // Figure out whether there are any collections/observables registered that should expect
  // the new doc - if so - publish the new doc
  private processPublishEvent(updates: PublishEvent): Observable<any> {
    let stopWatch = new StopWatch(
      this.constructorName + '.processPublishEvent',
      ProcessType.COLLECTION,
      updates.forUserEmail
    );

    let models = updates.models;
    if (_.isEmpty(models)) {
      return <any>EMPTY;
    }

    return this.findCollectionCandidates(updates.forUserEmail, models).pipe(
      mergeMap((collections: CollectionMetadata[]) => {
        return from(collections);
      }),
      tap((meta: CollectionMetadata) => {
        stopWatch.log('preparePublishEvent for: ' + meta.key);

        // Delete ui data
        let data = this.preparePublishEvent(models);

        // Log
        CollectionSubscriberService.log(meta.key, 'will publish new data: ' + models.length);

        // Emit to registered observer
        stopWatch.log('meta.subject.next');
        meta.subject.next(data);
      })
    );

    // console.log("models", models);
    // stopWatch.log('groupModelsByType');
    // return this.groupModelsByType(models).pipe(
    //   mergeMap((models: BaseModel[]) => {
    //     stopWatch.log('findCollectionCandidates');
    //     return this.findCollectionCandidates(updates.forUserEmail, models).pipe(
    //       mergeMap((collections: CollectionMetadata[]) => {
    //         return from(collections);
    //       }),
    //       tap((meta: CollectionMetadata) => {
    //         stopWatch.log('preparePublishEvent for: ' + meta.key);
    //
    //         // Delete ui data
    //         let data = this.preparePublishEvent(models);
    //
    //         // Log
    //         CollectionSubscriberService.log(meta.key, 'will publish new data: ' + models.length);
    //
    //         console.log("EMIT", data);
    //         // Emit to registered observer
    //         stopWatch.log('meta.subject.next');
    //         meta.subject.next(data);
    //       }),
    //       toArray(),
    //     );
    //   }),
  }

  private findCollectionCandidates(forUserEmail: string, models: BaseModel[]): Observable<CollectionMetadata[]> {
    let model = _.first(models);

    return from(_.values(this.collectionsMetadataByKey)).pipe(
      filter((meta: CollectionMetadata) => {
        return (
          !meta.subject.closed && // If subject was not closed jet
          meta.forUserEmail === forUserEmail && // Updates are for correct user
          meta.service.isTypeSupported(model)
        ); // Is type of updates supported
      }),
      toArray()
    );
  }

  private preparePublishEvent(models: BaseModel[]): BaseModel[] {
    return _.map(models, model => {
      let clone = model.clone();
      delete clone._ui;

      return clone;
    });
  }

  private groupModelsByType(models: BaseModel[]): Observable<BaseModel[]> {
    return from(models).pipe(
      groupBy((model: BaseModel) => {
        return model.$type;
      }),
      mergeMap(group => {
        return group.pipe(toArray());
      })
    );
  }

  private processConversationModels(models: BaseModel[]): BaseModel[] {
    return models;
  }

  /////////////////////
  // LOAD-MORE METHODS
  /////////////////////
  private queryThenFetchCollection(meta: CollectionMetadata): Observable<LoadMoreResult> {
    return this.queryCollection(meta).pipe(
      mergeMap((queryResponse: QueryResponse) => {
        // Do API fetch when local query return no results
        if (queryResponse.loadMoreResultStatus === LoadMoreResultStatus.NO_DATA) {
          return this.fetchCollection(meta);
        }

        // Trigger load more if page is not full
        if (!queryResponse.fullPageResponse) {
          return concat(
            of(new LoadMoreResult(queryResponse.loadMoreResultStatus, LoadMoreMethod.QUERY, meta.loadMoreType)),
            this.fetchCollection(meta)
          );
        }

        // Return if full page
        return of(new LoadMoreResult(queryResponse.loadMoreResultStatus, LoadMoreMethod.QUERY, meta.loadMoreType));
      })
    );
  }

  private queryAndFetchCollection(meta: CollectionMetadata): Observable<LoadMoreResult> {
    return concat(
      this.queryCollection(meta).pipe(
        map((queryResponse: QueryResponse) => {
          return new LoadMoreResult(queryResponse.loadMoreResultStatus, LoadMoreMethod.QUERY, meta.loadMoreType);
        })
      ),
      this.fetchCollection(meta)
    );
  }

  private fetchCollection(meta: CollectionMetadata): Observable<LoadMoreResult> {
    CollectionSubscriberService.log(meta.key, 'will fetch API');

    if (!this._userManagerService.isCurrentUserAccountVerified()) {
      return of(new LoadMoreResult(LoadMoreResultStatus.NO_DATA, LoadMoreMethod.FETCH, meta.loadMoreType));
    }

    return meta.service.fetch(meta.params, meta.forUserEmail).pipe(
      map((fetchResult: FetchResult) => {
        CollectionSubscriberService.log(meta.key, 'fetch API got: ' + fetchResult.dataLength + ' cards');

        meta.fetchedData += fetchResult.dataLength;

        if (fetchResult.hasData) {
          return new LoadMoreResult(LoadMoreResultStatus.HAVE_DATA, LoadMoreMethod.FETCH, meta.loadMoreType);
        } else {
          return new LoadMoreResult(LoadMoreResultStatus.NO_DATA, LoadMoreMethod.FETCH, meta.loadMoreType);
        }
      }),
      catchError(err => {
        Logger.error(err, `An error has occurred during ${meta.service.constructorName} API fetch`);
        return throwError(err);
      })
    );
  }

  private queryOnly(meta: CollectionMetadata): Observable<LoadMoreResult> {
    return this.queryCollection(meta).pipe(
      map((queryResponse: QueryResponse) => {
        return new LoadMoreResult(queryResponse.loadMoreResultStatus, LoadMoreMethod.QUERY, meta.loadMoreType);
      })
    );
  }

  private queryCollection(meta: CollectionMetadata): Observable<QueryResponse> {
    return this._queryCollection(meta).pipe(
      // Publish update and return loadMore data
      map((data: BaseModel[]) => {
        CollectionSubscriberService.log(meta.key, 'query DB returned ' + data.length + ' elements');

        if (_.isEmpty(data)) {
          return {
            fullPageResponse: false,
            loadMoreResultStatus: LoadMoreResultStatus.NO_DATA
          };
        }

        // Store local data and release it once load more is marked as done
        this.storeDataToPublish(meta, data);

        let hasFullPageSize = meta.params.size === data.length;
        return {
          fullPageResponse: hasFullPageSize,
          loadMoreResultStatus: LoadMoreResultStatus.HAVE_DATA
        };
      })
    );
  }

  private _queryCollection(meta: CollectionMetadata): Observable<BaseModel[]> {
    CollectionSubscriberService.log(
      meta.key,
      'will query DB: [offset: ' + meta.params.offset + ', size: ' + meta.params.size + ']'
    );

    // Query DB
    return meta.service.query(meta.params, meta.forUserEmail).pipe(
      // Catch error
      catchError(err => {
        let msg = `An error has occurred during ${meta.service.constructorName} DB query`;

        if (err.status === 404) {
          Logger.log(msg, err.message, meta);
          return of([]);
        }

        Logger.error(err, msg);
        return throwError(err);
      })
    );
  }

  ///////////////////
  // PRIVATE HELPERS
  ///////////////////
  private unregisterObserver(forUserEmail: string, key: string, sessionId?: string) {
    let meta: CollectionMetadata = this.collectionsMetadataByKey[key];

    // Check if key is for this session. This will prevent unsubscribing
    // observers of other sessions (with same key).
    if (!_.isEmpty(sessionId) && meta.sessionId !== sessionId) {
      return;
    }

    // Return if empty metadata or not for requested user
    if (_.isEmpty(meta) || meta.forUserEmail !== forUserEmail) {
      return;
    }

    try {
      meta.subject.complete();
      meta.subject.unsubscribe();

      // Cleanup for conversation updates
      meta.subscribeToConversationUpdatesThrottleFunc = undefined;
      meta.conversationUpdatesSubscription?.unsubscribe();
      this._storageService.removeItem(meta.cutOffTimeStorageKey);
    } catch (ignore) {}

    delete this.collectionsMetadataByKey[key];

    CollectionSubscriberService.log(meta.key, 'have unregistered collection', SpecialLogAction.UNREGISTER_OBSERVER);
  }

  private createCollectionMetadata(
    forUserEmail: string,
    serviceInstance: BaseCollectionService<BaseModel>,
    params: CollectionParams,
    options: CollectionOptions
  ): CollectionMetadata {
    // Save to dictionary
    let key: string = this.buildCollectionMetadataKey(forUserEmail, serviceInstance, params, options);
    let meta: CollectionMetadata = this.collectionsMetadataByKey[key];

    // Unregister existing collection observer if it already exists
    if (meta) {
      this.unregisterObserver(forUserEmail, key);
      return this.createCollectionMetadata(forUserEmail, serviceInstance, params, options);
    }

    // Create metadata
    meta = <CollectionMetadata>{
      forUserEmail: forUserEmail,
      key: key,
      sessionId: ID.getUniqueId(8),
      service: serviceInstance,
      subject: new Subject(),
      loadMoreType: serviceInstance.loadMoreType,
      params: params,
      initDataEmitted: false,
      fetchedData: 0
    };

    // Init cache
    meta.params.cache = new CollectionCache();

    // [!] NOTE: [!]
    // Set collection key to parameters. collectionKey is optional but MUST BE SET.
    // It si optional to not be required when registering collection
    meta.params.collectionKey = key;

    // Save to memory
    this.collectionsMetadataByKey[key] = meta;

    CollectionSubscriberService.log(meta.key, 'registering collection');

    return meta;
  }

  private updateCollectionMetadata(meta: CollectionMetadata) {
    CollectionSubscriberService.log(meta.key, 'will update collection metadata');

    this.collectionsMetadataByKey[meta.key] = meta;
  }

  private buildCollectionMetadataKey(
    forUserEmail: string,
    serviceInstance: BaseCollectionService<BaseModel>,
    params: CollectionParams,
    options: CollectionOptions
  ): string {
    let ignoredParams = ['offset', 'size', 'offsetDate', 'cache', 'offsetHistoryId'];

    // Get parameter values that identify list
    let values = _.values(_.omit(params, ignoredParams));

    // Add parameters from options
    if (!_.isEmpty(options.group)) {
      values.push(options.group);
    }

    // Stack them into 1D array
    values = _.flattenDeep(values);

    // Stringify parameters
    values = _.map(values, value => {
      if (value instanceof BaseModel) {
        return value.id;
      }
      if (value instanceof Object) {
        return JSON.stringify(value);
      }

      return value;
    });

    // Create hash from joined values
    let paramsHash = hash(values.join());

    let type = serviceInstance.constructorName;

    // Join all data that uniquely identify list
    return _.join([forUserEmail, type, paramsHash], ':');
  }

  ///////////
  // LOGGING
  ///////////
  static enableLogging(): void {
    this._logEnabled = true;
  }

  static disableLogging(): void {
    this._logEnabled = false;
  }

  // NOTE: turn logging on by calling "IPCBase.enableLogging()" from console
  static log(key: string, message: string, specialLogAction?: SpecialLogAction): void {
    if (!CollectionSubscriberService._logEnabled) {
      return;
    }

    // Header
    let header = '%cCOLLECTIONS: %c ';
    let headerData = ['color: #000000' + '; font-weight: 600', void 0];

    // TimeStamp
    let timeStamp = '%c[%s]%c ~ ';
    let timeStampData = ['color: #54C8E8', Time.getTimestamp(true), void 0];

    // Key
    let keyMsg = '%c[key: %s]%c - ';
    let keyData = ['color: #536157', key, void 0];

    // Message
    let msg = '%c%s%c';
    let msgData: string[] = [void 0, message, void 0];

    if (specialLogAction !== undefined) {
      // Convert enum to number by '+' and compare it
      switch (+specialLogAction) {
        case SpecialLogAction.LOAD_MORE_FINISHED:
          msgData = ['color: #eb34bd', message, void 0];
          break;
        case SpecialLogAction.LOAD_MORE_TRIGGERED:
          msgData = ['color: #eb34bd; font-weight: 600', message, void 0];
          break;
        case SpecialLogAction.REGISTER_OBSERVER:
          msgData = ['color: #357a45; font-weight: 600', message, void 0];
          break;
        case SpecialLogAction.UNREGISTER_OBSERVER:
          msgData = ['color: #a61414; font-weight: 600', message, void 0];
          break;
        default:
          throw new Error('Unsupported specialLogAction: ' + specialLogAction);
      }
    }

    // Combine all
    let logMsg = header + timeStamp + keyMsg + msg;
    let logData = [logMsg, ...headerData, ...timeStampData, ...keyData, ...msgData];

    Logger['log'](...logData);
  }
}

export class ObserverResponse<T extends BaseModel> {
  constructor(
    public models: T[],
    public result: LoadMoreResult
  ) {}
}

export interface CollectionMetadata {
  forUserEmail: string;
  key: string;

  // This will differ between different sessions for same parameters.
  // Example where sessionId is needed:
  //  o Collection: smart-inbox
  //  o Hash of parameters: 1234
  //  o Key: smart-inbox_1234
  //
  // 1. Register smart-inbox collection
  // 2. ...
  // 3. Register smart-inbox collection
  //      3.1. Delete old metadata with same key and complete subject
  //      3.2. Init new metadata
  //      3.3. finalize() called in 'registerObserver' -> metadata deleted
  //
  // Problem: no mechanism in place to determine if at 3.3 we are clearing old metadata or
  //          new one. SessionId tells us that
  sessionId: string;

  service: BaseCollectionService<BaseModel>;
  subject: Subject<BaseModel[]>;
  dataToPublish: BaseModel[];
  initDataEmitted: boolean;
  fetchedData: number;

  loadMoreType: LoadMoreType;
  params: CollectionParams;
  options: CollectionOptions;
  isLoadMoreInProgress: boolean;

  // Conversation updates subscription
  conversationUpdatesSubscription?: Subscription;
  cutOffTimeStorageKey: string;
  minimumCutOffDate: string;
  subscribeToConversationUpdatesThrottleFunc: any;
}

/**
 * @param group - is used with key calculation and garbage collector
 * @param startEmpty - should collection start empty and only listen to updates
 */
export interface CollectionOptions {
  group?: string;
  startEmpty?: boolean;
}

export enum LoadMoreType {
  QUERY_THEN_FETCH = 'QUERY_THEN_FETCH', // Will first query and than fetch if query returns empty results
  QUERY_AND_FETCH = 'QUERY_AND_FETCH', // Will query DB and fetch from API at the same time
  ONLY_QUERY = 'ONLY_QUERY' // Calls to API are not supported
}

export enum SpecialLogAction {
  LOAD_MORE_TRIGGERED,
  LOAD_MORE_FINISHED,
  REGISTER_OBSERVER,
  UNREGISTER_OBSERVER
}

interface QueryResponse {
  // Marking if size of returned elements match requested size
  fullPageResponse: boolean;

  // Load more status (more data or not)
  loadMoreResultStatus: LoadMoreResultStatus;
}
