import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { TagApiService } from '@shared/api/api-loop/services/tag-api.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { SharedTagApiService } from '@shared/api/api-loop/services/shared-tag-api.service';
import { TagLabel } from '@shared/api/api-loop/models/tag-label';
import { SharedTagLabel } from '@shared/api/api-loop/models/shared-tag-label';
import { IsLabelSelectedPipe } from '@shared/modules/shared-tag/pipes/is-label-selected.pipe/is-label-selected.pipe';
import { CustomDropdownBase } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown-base';
import { ConversationActionService } from '@shared/services/data/conversation-action/conversation-action.service';
import { ActionEnum } from '@shared/api/api-loop/models/action-enum';
import { ConversationActionParams } from '@shared/services/data/conversation-action/conversation-action.service.interface';
import { debounceTime, map, publishReplay, refCount, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { SharedTagLabelModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';

@UntilDestroy()
@Component({
  selector: 'loop-labels-dropdown',
  templateUrl: './labels-dropdown.component.html',
  styleUrls: ['./labels-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IsLabelSelectedPipe]
})
export class LabelsDropdownComponent
  extends CustomDropdownBase<TagLabel | SharedTagLabel>
  implements OnInit, OnDestroy
{
  private readonly webOnboardingService: WebOnboardingService = inject(WebOnboardingService);
  private readonly tagApiService: TagApiService = inject(TagApiService);
  private readonly sharedTagApiService: SharedTagApiService = inject(SharedTagApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly isLabelSelectedPipe: IsLabelSelectedPipe = inject(IsLabelSelectedPipe);
  private readonly router: Router = inject(Router);
  private readonly conversationActionService: ConversationActionService = inject(ConversationActionService);
  private readonly trackingService: TrackingService = inject(TrackingService);

  @Input() conversations?: ConversationModel[];

  protected visibleDataLength: number = 0;
  protected readonly folderSearchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  protected readonly filteredLabels$: Observable<(TagLabel | SharedTagLabel)[]> = combineLatest([
    this.folderSearchQuery$.pipe(debounceTime(500)),
    this.tagApiService.Tag_GetUserLabels({}, this.userManagerService.getCurrentUserEmail(), true),
    this.sharedTagApiService.SharedTag_GetWorkspacesLabels({}, this.userManagerService.getCurrentUserEmail(), true)
  ]).pipe(
    map(([searchQuery, privateLabels, sharedLabels]) => {
      const sharedLabelsTags = sharedLabels.resources.map(sharedLabels => sharedLabels.resources ?? []).flat();
      const listOfAllTags = [...privateLabels.resources, ...sharedLabelsTags].filter(tag => !tag.deleted);
      listOfAllTags.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      return listOfAllTags.filter(label => label.name?.toLowerCase().includes(searchQuery.toLowerCase()));
    }),
    publishReplay(1),
    refCount()
  );

  ngOnInit(): void {
    this.filteredLabels$.pipe(untilDestroyed(this)).subscribe(labels => {
      this.currentItems = labels;
      this.visibleDataLength = labels.length;
    });
  }

  protected currentItemClick(): void {
    this.addOrRemoveLabel(this.currentItems[this.currentIndex]);
  }

  openTagsSettings(): void {
    this.webOnboardingService.openWebSettings(undefined, '/personal/tags/', window.open());
    this.closeDropdown.next();
  }

  addOrRemoveLabel(label: TagLabel | SharedTagLabel): void {
    const firstConversation = this.conversations[0];
    if (!firstConversation) {
      return;
    }

    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'LabelsDropdown',
      'Add to folder',
      {
        conversationIds: this.conversations.map(conversation => conversation.cardId),
        label: label
      }
    );

    const conversationIds = [];
    const action = this.isLabelSelectedPipe.transform(firstConversation, label) ? 'remove' : 'add';
    this.conversations = this.conversations.map(conversation => {
      conversation.addOrRemoveLabel(label, action);
      conversation = new ConversationModel({
        ...conversation
      });
      conversationIds.push(conversation.cardId);
      return conversation;
    });

    const conversationActionParams: ConversationActionParams = {
      action: label.$type === SharedTagLabelModel.type ? ActionEnum.CHANGE_LABELS : ActionEnum.CHANGE_PRIVATE_LABELS,
      conversationIds: conversationIds,
      isSharedAction: !this.router.url.includes('myloopinbox')
    };

    if (action === 'add') {
      conversationActionParams.addTags = [label];
    } else {
      conversationActionParams.removeTags = [label];
    }
    this.conversationActionService
      .conversationAction(this.userManagerService.getCurrentUserEmail(), conversationActionParams)
      .pipe(untilDestroyed(this), take(1))
      .subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.folderSearchQuery$.complete();
  }
}
