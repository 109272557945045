import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { AttachmentService } from '@shared/modules/main/common/attachments/attachment/attachment.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { DropdownActionItem, DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { ClipboardService } from '@shared/shared/clipboard/clipboard-service/clipboard.service';
import { ClipboardEvents } from '@shared/shared/clipboard/common/constants/clipboard-events';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileModel, isLoadingAttachment, LoadingAttachment } from '@dta/shared/models-api-loop/file.model';

@UntilDestroy()
@Component({
  selector: 'loop-common-attachment',
  templateUrl: './common-attachment.component.html',
  styleUrls: ['./common-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonAttachmentComponent {
  private readonly attachmentService: AttachmentService = inject(AttachmentService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly clipboardService: ClipboardService = inject(ClipboardService);

  protected isSingleActionVisible: boolean = false;

  @Input() attachment: IFile | LoadingAttachment;
  @Input() isDeleteAvailable?: boolean;
  @Input() onAttachmentClickFn: () => void;
  @Input() dropdownItems: DropdownActionItem[] = [
    {
      title: '',
      icon: 'download',
      action: () => this.downloadAttachment(this.attachment)
    }
  ];

  protected readonly contextDropdownItems: DropdownItem[] = [
    {
      title: 'Copy attachment',
      action: () => {
        this.clipboardService.storeDataToClipboard(ClipboardEvents.loopAttachment, JSON.stringify(this.attachment));
      }
    }
  ];

  @Output() delete: EventEmitter<IFile> = new EventEmitter<IFile>();

  private downloadAttachment(file: IFile | LoadingAttachment): void {
    if (isLoadingAttachment(file)) {
      return;
    }

    this.attachmentService
      .downloadAttachment(this.userManagerService.getCurrentUserEmail(), new FileModel({ ...file }))
      .pipe(untilDestroyed(this), take(1))
      .subscribe();
  }

  onAttachmentClick($event: MouseEvent, attachment: IFile | LoadingAttachment): void {
    if (isLoadingAttachment(attachment)) {
      return;
    }

    if (this.onAttachmentClickFn) {
      this.onAttachmentClickFn();
      return;
    }

    $event.preventDefault();
    $event.stopPropagation();

    this.attachmentService.onFileIconClick({
      forUserEmail: this.userManagerService.getCurrentUserEmail(),
      file: new FileModel(attachment)
    });
  }

  onDropdownItemClick(event: MouseEvent, dropdownItem: DropdownActionItem): void {
    event.stopPropagation();
    event.preventDefault();

    dropdownItem.action();
  }

  deleteAttachment(attachment: IFile): void {
    this.delete.emit(attachment);
  }
}
