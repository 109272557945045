import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { PullSynchronizationService } from '@shared/synchronization/pull-synchronization/pull-synchronization.service';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { DataServiceShared } from '@shared/services/data/data.service';
import { SynchronizationStatusService } from '@shared/synchronization/synchronization-status.service';
import { FlushDatabaseService } from '@shared/services/flush-db/flush-db.service';
import { EventSynchronizationService } from '@shared/synchronization/event-synchronization/event-synchronization.service';
import { PrefetchSyncState } from './prefetch-synchronization/prefetch-synchronization.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { ConversationSynchronizationService } from '@shared/synchronization/conversation-synchronization/conversation-synchronization.service';
import { ConversationSynchronizationServiceWeb } from '../conversation-synchronization/conversation-synchronization.service.web';

export class PullSynchronizationServiceWeb extends PullSynchronizationService {
  constructor(
    protected _userEmail: string,
    protected _apiService: ApiService,
    protected _storageService: StorageService,
    protected _eventSynchronizationService: EventSynchronizationService,
    protected _conversationSynchronizationService: ConversationSynchronizationService,
    protected _syncStatusService: SynchronizationStatusService,
    protected _data: DataServiceShared,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _syncMiddleware: SynchronizationMiddlewareService,
    private _flushDatabaseService: FlushDatabaseService,
  ) {
    super(
      _userEmail,
      _apiService,
      _storageService,
      _eventSynchronizationService,
      _conversationSynchronizationService,
      _syncStatusService,
      _data,
      _sharedUserManagerService,
      _syncMiddleware,
    );
  }

  protected areAllServicesActiveOrComplete(): boolean {
    return (
      this.activeSynchronizationService.active &&
      this.followUpSynchronizationService.active &&
      this.prefetchSynchronizationService.status !== PrefetchSyncState.INACTIVE
    );
  }

  /**
   * Init services
   */
  init() {
    super.init();
  }

  protected conversationInit() {
    this._conversationSynchronizationService = new ConversationSynchronizationServiceWeb(
      this._userEmail,
      this._data,
      this._apiService,
      this._storageService,
      this,
    );
  }
}
