import { Injectable } from '@angular/core';
import { StorageKey, StorageService } from '../../../../shared/services/storage/storage.service';
import { User } from '@shared/api/api-loop/models';
import { SendAsOption } from '../../../../shared/models/send-as.model';

@Injectable()
export class FromSelectorService {
  constructor(private _storageService: StorageService) {}

  /**
   * Key should be per account and per shared-inbox
   */
  private getStickySelectionStorageKey(currentUser: User, sharedInboxId: string) {
    return this._storageService.getKey(currentUser.email + '_' + sharedInboxId, StorageKey.stickyFromSelection);
  }

  getStickySelection(currentUser: User, sharedInboxId: string): SendAsOption {
    let key = this.getStickySelectionStorageKey(currentUser, sharedInboxId);
    return this._storageService.getParsedItem(key);
  }

  persistStickySelection(currentUser: User, sharedInboxId: string, fromSelection: SendAsOption) {
    let key = this.getStickySelectionStorageKey(currentUser, sharedInboxId);
    this._storageService.setStringifiedItem(key, fromSelection);
  }
}
