import * as _ from 'lodash';
import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { AvatarFileComponent } from './avatar-file/avatar-file.component';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { catchError, filter, tap } from 'rxjs/operators';
import { AvatarService } from '@shared/services/data/avatar/avatar.service';
import { SharedAvatarService } from '@shared/services/shared-avatar/shared-avatar.service';
import { User } from '@shared/api/api-loop/models';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { ContactDetailHelperService } from '@shared/services/contact-detail-helper/contact-detail-helper.service';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';

@AutoUnsubscribe()
@Component({
  selector: 'avatar-image',
  templateUrl: './avatar.html',
  styleUrls: ['./avatar.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AvatarFileComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit, OnDestroy, OnChanges {
  private _avatarService: AvatarService = inject(AvatarService);
  private _sharedAvatarService: SharedAvatarService = inject(SharedAvatarService);
  private _fileStorageService: FileStorageService = inject(FileStorageService);
  private _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private _userManagerService: UserManagerService = inject(UserManagerService);
  private contactDetailHelperService: ContactDetailHelperService | null = inject(ContactDetailHelperService, {
    optional: true
  });
  //////////////////
  // Input, Output
  //////////////////
  @Input() contact: ContactBase;
  @Input() extraClass: string = '';
  @Input() spinner: boolean;
  @Input() changed: Subject<boolean>;
  @Input() localUrl: string;
  @Input() multipleRecipients: boolean;
  @Input() sharedTag: boolean;
  @Input() disablePopup: boolean;
  @Input() isSharedView: boolean; // If true, avatars should be personal, not per account
  @Input() width?: number;
  @Input() height?: number;
  @Input() hasBorder?: boolean;

  /////////////////////
  // State variables
  /////////////////////
  file: string;
  forUserEmail: string;

  //////////////////
  // Subscriptions
  //////////////////
  private avatarSub: Subscription;
  private changedSub: Subscription;
  private anonymousSubscriptions: Subscription[] = [];

  ngOnInit() {
    this.subscribeToChanged();
    this.subscribeToAvatar();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    // Do this every time (for component that just change)
    this.setForUserEmail();

    if (changes.contact) {
      this.resolveFile();
    }
  }

  get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  private subscribeToChanged() {
    if (!this.changed) {
      return;
    }

    this.changedSub?.unsubscribe();
    this.changedSub = this.changed
      .pipe(
        tap(() => {
          this.resolveFile(true);
        })
      )
      .subscribe();
  }

  private subscribeToAvatar() {
    if (!this._avatarService.avatarUpdated$) {
      return;
    }

    this.avatarSub?.unsubscribe();
    this.avatarSub = this._avatarService.avatarUpdated$
      .pipe(
        filter((id: string) => {
          return this.contact && this.contact.id === id;
        }),
        tap((id: string) => {
          this.resolveFile(true);
        })
      )
      .subscribe();
  }

  resolveFile(refresh: boolean = false) {
    let filesUri = this._fileStorageService.getFilesUri();

    if (_.isNil(filesUri)) {
      // Electron sometimes can't provide file uri. In that case we try again after 1 second.
      setTimeout(() => this.resolveFile(refresh), 1000);
      return;
    }

    if (!this.contact?.id) {
      this.file = undefined;
      return;
    }

    let avatarFileName = this.getAvatarFileName();
    let timestamps = this._sharedAvatarService.getTimestamps(this.forUserEmail);
    let shouldRefresh =
      _.isEmpty(timestamps) ||
      !timestamps[this.contact.id] ||
      moment(timestamps[this.contact.id]).add(1, 'hours').isBefore(moment());
    let avatarUriInStore = this.getAvatarFile(filesUri, avatarFileName, this.contact.id);

    // Use latest version of avatar (if existing)
    if (!refresh && !shouldRefresh && avatarUriInStore) {
      this.file = avatarUriInStore;

      if (this._sharedAvatarService.isFileNameEmptyPlaceholder(this.file)) {
        this.file = undefined;
      }

      this._changeDetectorRef.detectChanges();
      return;
    }

    this.anonymousSubscriptions.push(
      // Load avatar
      this._avatarService
        .getAvatar(this.forUserEmail, this.contact, true)
        .pipe(
          tap((hasAvatar: boolean) => {
            if (hasAvatar) {
              this.file = this.getAvatarFile(filesUri, avatarFileName, this.contact.id);
            } else {
              this.file = undefined;
            }

            this._changeDetectorRef.detectChanges();
          }),
          catchError(err => {
            // Default to group avatar placeholder
            this.file = 'shared/assets/img/svg/group.svg';
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  openContactPopup($event) {
    if (this.disablePopup) {
      return;
    }

    $event.stopPropagation();
    $event.preventDefault();
    this.contactDetailHelperService?.openDetails(this.contact as ContactModel);
  }

  private getAvatarFileName(): string {
    return SharedAvatarService.getAvatarFileName(this.forUserEmail, this.contact);
  }

  private getAvatarFile(filesUri: string, avatarFileName: string, contactId: string): string {
    return this._sharedAvatarService.getAvatarFile(
      this.forUserEmail,
      filesUri,
      avatarFileName,
      contactId,
      this.contact
    );
  }

  private setForUserEmail() {
    this.forUserEmail = this.isSharedView
      ? // Use personal avatar when showing avatars in side (shared) menu
        (<User>this.contact).email
      : // In all other cases, show personal avatar
        this.currentUserEmail;
  }
}
