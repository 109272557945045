import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { Logger } from '@shared/services/logger/logger';
import { filter, from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap, toArray } from 'rxjs/operators';

export abstract class BaseExDecorateService<T extends BaseModel> {
  constructor() {}

  abstract decorateExtraData(forUserEmail: string, model: T, force?: boolean, ...args: any[]): Observable<T>;

  decorate(forUserEmail: string, model: T, force?: boolean, ...args: any[]): Observable<T> {
    return this.decorateExtraData(forUserEmail, model, force, args).pipe(
      catchError(err => {
        Logger.error(err, 'Could not decorate');
        return throwError(err);
      }),
    );
  }

  decorateListExtraData(forUserEmail: string, models: T[], force?: boolean, ...args: any[]): Observable<T[]> {
    return from(models).pipe(
      filter((model: T) => !!model),
      mergeMap((model: T) => {
        return this.decorateExtraData(forUserEmail, model, force, args);
      }),
      toArray(),
      catchError(err => {
        Logger.error(err, 'Could not decorate list extra data');
        return throwError(err);
      }),
    );
  }
}
