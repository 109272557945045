<div class="empty-state-container white-right">
  <!-- Default empty state -->
  <ng-template [ngIf]="emptyStateType === 'default'">
    <svg-comp *ngIf="mainEmptySvg && !isBatchSelectActive" [src]="mainEmptySvg"> </svg-comp>

    <batch-select
      *ngIf="isBatchSelectActive"
      [markedCardsNumber]="markedCardsNumber"
      [countState]="state"
      [sharedLabels]="sharedLabels"
    >
    </batch-select>
  </ng-template>

  <!-- Shared inboxes empty state -->
  <shared-inboxes-empty-state *ngIf="emptyStateType === 'shared-inboxes'" [sharedInboxes]="true">
  </shared-inboxes-empty-state>

  <!-- Teams empty state -->
  <teams-empty-state *ngIf="emptyStateType === 'teams'"> </teams-empty-state>

  <!-- Private inbox empty state -->
  <shared-inboxes-empty-state *ngIf="emptyStateType === 'personal-inboxes'"> </shared-inboxes-empty-state>
</div>
