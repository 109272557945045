<form *ngIf="form" [formGroup]="form" class="form-layout flex-1">
  <loop-text-control size="big" formControlName="name" [placeholder]="'Add title'"></loop-text-control>

  <div class="flex gap-x form-part">
    <svg-comp class="form-part-svg" [src]="clockSvg"></svg-comp>
    <loop-range-chooser [rangeChooserFormGroup]="form.controls.rangeChooser"> </loop-range-chooser>
  </div>

  <div class="flex gap-x form-part">
    <svg-comp class="form-part-svg" [src]="peopleSvg"></svg-comp>
    <loop-auto-suggest-control
      class="flex-1"
      formControlName="attendees"
      [placeholder]="'Add guest'"
      [additionalContactFilter]="additionalContactFilter"
      [customTemplateRenderer]="customSelectRenderer"
      [mode]="'multiple'"
    >
    </loop-auto-suggest-control>
  </div>

  <div class="flex gap-x form-part">
    <svg-comp class="form-part-svg" [src]="locationSvg"></svg-comp>
    <div class="flex flex-col gap flex-1">
      <loop-location-control
        class="flex-1"
        formControlName="location"
        [placeholder]="'Add a location'"
      ></loop-location-control>
      <ng-container *ngIf="currentInboxSettings?.onlineMeetings?.resources.length > 0">
        <div class="flex gap-x-half online-meeting items-center">
          <loop-checkbox-control formControlName="hasOnlineMeeting"> </loop-checkbox-control>
          <img width="16" height="16" [src]="currentInboxSettings.onlineMeetings.resources[0].iconUrl" />
          <span>
            {{ currentInboxSettings.onlineMeetings.resources[0].name }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="form.controls['hasOnlineMeeting'].value && appointment?.onlineMeeting?.joinUrl">
        <a
          class="link font-new-default"
          target="_blank"
          rel="noreferrer noopener"
          [href]="appointment?.onlineMeeting?.joinUrl"
        >
          Join {{ appointment.onlineMeeting.name }}
        </a>
      </ng-container>
    </div>
  </div>

  <div class="flex gap-x form-part" *ngIf="!appointment?.id">
    <svg-comp class="form-part-svg" [src]="sharedInboxIconSvg"></svg-comp>
    <loop-select-control
      *ngIf="options$ | async; let options"
      class="flex-1"
      formControlName="contextId"
      [hasBorder]="false"
      [placeholder]="'Select inbox'"
      [options]="options"
    >
    </loop-select-control>
  </div>

  <div class="flex gap-x form-part">
    <svg-comp class="form-part-svg" [src]="bellSvg"></svg-comp>
    <loop-select-control
      *ngIf="reminderOptions"
      class="flex-1"
      formControlName="remindersInMinutes"
      [hasBorder]="false"
      [mode]="'multiple'"
      [placeholder]="'Add reminder'"
      [options]="reminderOptions"
    >
    </loop-select-control>
  </div>

  <div class="flex gap-x">
    <svg-comp class="form-part-svg" [src]="notesSvg"></svg-comp>
    <loop-wysiwyg-control
      class="flex-1"
      formControlName="description"
      [placeholder]="'Add a description'"
      [toolbarOptions]="[ToolBarOption.bold, ToolBarOption.italic, ToolBarOption.underline]"
    >
    </loop-wysiwyg-control>
  </div>

  <div class="flex-1"></div>

  <ng-template #customSelectRenderer let-contact="contact" let-index="index">
    <div class="auto-suggest-item" (mouseenter)="hoveredIndex = index" (mouseleave)="hoveredIndex = null">
      <div class="flex gap-x-half">
        <loop-contact class="flex-1" [contact]="contact" [openIntegrationOnAvatarClick]="false">
          <ng-container [ngSwitch]="appointment | getAppointmentResponse: contact" avatarSvg>
            <div class="response accept" *ngSwitchCase="ResponseTypeModel.ACCEPT">
              <svg-comp [src]="checkmarkSvg"></svg-comp>
            </div>
            <div class="response tentative" *ngSwitchCase="ResponseTypeModel.TENTATIVE">
              <svg-comp [src]="questionMarkSvg"></svg-comp>
            </div>
            <div class="response delete" *ngSwitchCase="ResponseTypeModel.DECLINE">
              <svg-comp [src]="declineSvg"></svg-comp>
            </div>
            <div class="response organizer" *ngSwitchCase="ResponseTypeModel.ORGANIZER">
              <svg-comp [src]="organizerSvg"></svg-comp>
            </div>
          </ng-container>
        </loop-contact>
      </div>
      <div *ngIf="!appointment.id || (appointment | isOrganizer)" class="flex items-center">
        <svg-comp
          *ngIf="index === hoveredIndex"
          class="cursor-pointer remove-icon-svg"
          [src]="removeSvg"
          (click)="removeAttendee(contact)"
        ></svg-comp>
      </div>
    </div>
  </ng-template>
</form>
