<!--Favorites-->
<header-dropdown
  #dropdown
  [channel]="hoveredChannel"
  [isOnChannel]="true"
  [additionalDropdownOpenTarget]="additionalDropdownOpenTarget"
  [trackingLocation]="trackingLocation"
>
</header-dropdown>

<div class="items favorites-item" [class.list-opened]="inboxOpened" id="menu-favorites">
  <!-- Channels list -->
  <div class="menu-item__list">
    <!-- Favorite channels -->
    <div
      *ngFor="let channel of favoriteChannels; trackBy: trackByFn"
      class="channel favorite"
      (click)="navigate($event, channel)"
      (mouseenter)="onChannelHover(channel, additionalDropdownOpenTarget)"
      (dblclick)="handleDoubleClick($event, channel)"
      [routerLink]="['/channel/' + channel._id]"
      [routerLinkActive]="['selected']"
      [routerLinkActiveOptions]="{exact: false}"
      [class.unread-chat]="((channel | isGroup) && channel.groupType !== 'Normal' && !channel.showTotalCountBadge) ? channel._ui.unreadEmails > 0 : channel._ui.unreadChats > 0"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Favorite channel', location: trackingLocation}"
    >
      <!-- Icon -->
      <div class="indicator-container favorites">
        <div class="team-icon" *ngIf="channel.$type === 'Group' && channel.groupType !== 'SharedInbox'">
          <svg-comp [src]="teamIconSvg"></svg-comp>
        </div>
        <div class="team-icon" *ngIf="channel.$type === 'Group' && channel.groupType === 'SharedInbox'">
          <svg-comp [src]="sharedInboxIconSvg"></svg-comp>
        </div>
        <div
          class="status"
          *ngIf="channel.$type === 'User'"
          [class.inactive]="channel.onlineStatus && channel.onlineStatus === 'Inactive'"
          [class.active]="channel.onlineStatus && channel.onlineStatus === 'Active'"
        ></div>
      </div>

      <!-- Name -->
      <div class="name">{{ channel.name }}</div>

      <!-- Availability status -->
      <div *ngIf="userAvailabilitiesByUserId[channel.id]?.isValidAvailabilityStatus()">
        {{ userAvailabilitiesByUserId[channel.id].availabilityStatus.emoji }}
      </div>

      <!-- Unread count -->
      <ng-container
        *ngIf="(channel.$type === 'User' || ((channel | isGroup) && channel.groupType === 'Normal')); else mailUnreadsTpl"
      >
        <div
          class="unread-badge-mail"
          [class.offline-state]="offline"
          [class.total-count]="channel.showTotalCountBadge"
          *ngIf="channel.showTotalCountBadge ? channel._ui.totalCount > 0 : channel._ui.unreadChats > 0"
        >
          {{channel.showTotalCountBadge ? channel._ui.totalCount : channel._ui.unreadChats}}
        </div>
      </ng-container>
      <ng-template #mailUnreadsTpl>
        <div
          class="unread-badge-mail"
          [class.offline-state]="offline"
          [class.total-count]="channel.showTotalCountBadge"
          *ngIf="channel._ui.unreadEmails > 0 || (channel.showTotalCountBadge && channel._ui.totalCount > 0)"
        >
          {{ channel.showTotalCountBadge ? channel._ui.totalCount :channel._ui.unreadEmails}}
        </div>
      </ng-template>

      <!-- Edit options -->
      <div class="remove-button" (click)="toggleDropdown($event, channel)" #additionalDropdownOpenTarget>
        <svg-comp class="favorite-remove" [src]="ellipsisVertical"></svg-comp>
      </div>
    </div>

    <!--        &lt;!&ndash; Empty state &ndash;&gt;-->
    <!--        <div class="empty" *ngIf="!favoriteChannels || !favoriteChannels.length"-->
    <!--            [innerHTML]="'Favorite people or team by clicking the heart icon next to their name.'">-->
    <!--        </div>-->
  </div>
</div>

<!-- Personal inboxes -->
<div
  class="items teams-item"
  [class.list-opened]="personalInboxChannels?.length && channelsListPreferences.personalInboxesOpened"
  id="menu-personal-si"
>
  <!-- Header -->
  <h2
    class="menu-item__title"
    [class.selectable]="allPersonalInboxesSupported || !personalInboxChannels?.length"
    (click)="personalInboxChannels?.length && toggleListOpened($event, 'personalInboxesOpened')"
    [routerLink]="!personalInboxChannels?.length ? ['/empty/emptyPersonalInboxes'] : (allPersonalInboxesSupported ? ['/allPersonalInboxes'] : undefined)"
    [routerLinkActive]="['selected']"
    [routerLinkActiveOptions]="{exact: false}"
    [trackUserClick]="{forUserEmail: currentUserEmail, action :'All shared inboxes', location: trackingLocation}"
    id="all-personal-inboxes-button"
  >
    <!-- Dropdown svg -->
    <div
      class="title-dropdown"
      (click)="personalInboxChannels?.length && toggleListOpened($event, 'personalInboxesOpened')"
    >
      <svg-comp class="svg-icon" [src]="chevronSvg"></svg-comp>
    </div>

    <!-- Section name -->
    <div
      class="title-text"
      [class.unread]="!channelsListPreferences.personalInboxesOpened && hasUnreadPersonalInboxChannels"
    >
      PERSONAL INBOXES
    </div>

    <!-- Add svg -->
    <svg-comp
      [src]="addTeamIconSvg"
      class="add-team-icon"
      sleepClickEvent
      (click)="createNewPersonalInbox()"
      [tooltip]="{content: isDarwin ? 'Add a personal inbox (⌥+I)' : 'Add a personal inbox (Alt+I)', placement: TooltipPlacement.RIGHT}"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Add personal inbox', location: trackingLocation}"
    >
    </svg-comp>
  </h2>

  <!-- Shared inbox list -->
  <div class="menu-item__list">
    <!-- Shared inbox channels -->
    <div
      *ngFor="let channel of personalInboxChannels.slice(0, personalInboxChannelsShowLimit); trackBy: trackByFn"
      class="channel"
      (click)="navigate($event, channel)"
      (mouseenter)="onChannelHover(channel, additionalDropdownOpenTarget)"
      (dblclick)="handleDoubleClick($event, channel)"
      [routerLink]="['/channel/' + channel._id]"
      [routerLinkActive]="['selected']"
      [routerLinkActiveOptions]="{exact: false}"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Personal inbox channel', location: trackingLocation}"
      [class.unread-chat]="channel._ui.unreadEmails > 0 && !channel.showTotalCountBadge"
    >
      <!-- Icon -->
      <div class="indicator-container">
        <div class="team-icon" *ngIf="channel.$type === 'Group'">
          <svg-comp [src]="sharedInboxIconSvg"></svg-comp>
        </div>
      </div>

      <!-- Name -->
      <div class="name">{{ channel.name }}</div>

      <!-- Syncing indicator -->
      <loader *ngIf="!channel.syncingComplete" class="syncing-indicator" [style]="'arrows'"> </loader>

      <!-- Unread count -->
      <div
        class="unread-badge-mail"
        [class.offline-state]="offline"
        [class.total-count]="channel.showTotalCountBadge"
        *ngIf="channel.syncingComplete && (channel.showTotalCountBadge ? channel._ui.totalCount > 0 : channel._ui.unreadEmails > 0)"
      >
        {{channel.showTotalCountBadge ? channel._ui.totalCount : channel._ui.unreadEmails}}
      </div>

      <!-- Edit options -->
      <div class="remove-button" (click)="toggleDropdown($event, channel)" #additionalDropdownOpenTarget>
        <svg-comp class="favorite-remove" [src]="ellipsisVertical"></svg-comp>
      </div>
    </div>

    <!-- Show more -->
    <div
      *ngIf="personalInboxChannels.length > 10 && !showMorePersonalInboxes"
      class="show-more"
      sleepClickEvent
      (click)="handleShowMorePersonalInboxes()"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Show more personal inboxes', location: trackingLocation}"
    >
      Show more
    </div>
  </div>
</div>

<!-- Shared inboxes -->
<div
  class="items teams-item"
  [class.list-opened]="sharedInboxChannels?.length && channelsListPreferences.sharedInboxesOpened"
  id="menu-si"
>
  <!-- Header -->
  <h2
    class="menu-item__title"
    [class.selectable]="allSharedInboxesSupported || !sharedInboxChannels?.length"
    (click)="sharedInboxChannels?.length && toggleListOpened($event, 'sharedInboxesOpened')"
    [routerLink]="!sharedInboxChannels?.length ? ['/empty/emptySharedInboxes'] : (allSharedInboxesSupported ? ['/allSharedInboxes'] : undefined)"
    [routerLinkActive]="['selected']"
    [routerLinkActiveOptions]="{exact: false}"
    [trackUserClick]="{forUserEmail: currentUserEmail, action :'All shared inboxes', location: trackingLocation}"
    id="all-shared-inboxes-button"
  >
    <!-- Dropdown svg -->
    <div
      class="title-dropdown"
      (click)="sharedInboxChannels?.length && toggleListOpened($event, 'sharedInboxesOpened')"
    >
      <svg-comp class="svg-icon" [src]="chevronSvg"></svg-comp>
    </div>

    <!-- Section name -->
    <div
      class="title-text"
      [class.unread]="!channelsListPreferences.sharedInboxesOpened && hasUnreadSharedInboxChannels"
    >
      SHARED INBOXES
    </div>

    <!-- Add svg -->
    <svg-comp
      [src]="addTeamIconSvg"
      class="add-team-icon"
      sleepClickEvent
      (click)="createNewSharedInbox()"
      [tooltip]="{content: isDarwin ? 'Add a shared inbox (⌥+I)' : 'Add a shared inbox (Alt+I)', placement: TooltipPlacement.RIGHT}"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Add shared inbox', location: trackingLocation}"
    >
    </svg-comp>
  </h2>

  <!-- Shared inbox list -->
  <div class="menu-item__list">
    <!-- Shared inbox channels -->
    <div
      *ngFor="let channel of sharedInboxChannels.slice(0, sharedInboxChannelsShowLimit); trackBy: trackByFn"
      class="channel"
      (click)="navigate($event, channel)"
      (mouseenter)="onChannelHover(channel, additionalDropdownOpenTarget)"
      (dblclick)="handleDoubleClick($event, channel)"
      [routerLink]="['/channel/' + channel._id]"
      [routerLinkActive]="['selected']"
      [routerLinkActiveOptions]="{exact: false}"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Shared inbox channel', location: trackingLocation}"
      [class.unread-chat]="channel._ui.unreadEmails > 0 && !channel.showTotalCountBadge"
    >
      <!-- Icon -->
      <div class="indicator-container">
        <div class="team-icon" *ngIf="channel.$type === 'Group'">
          <svg-comp [src]="sharedInboxIconSvg"></svg-comp>
        </div>
      </div>

      <!-- Name -->
      <div class="name">{{ channel.name }}</div>

      <!-- Syncing indicator -->
      <loader *ngIf="!channel.syncingComplete" class="syncing-indicator" [style]="'arrows'"> </loader>

      <!-- Unread count -->
      <div
        class="unread-badge-mail"
        [class.offline-state]="offline"
        [class.total-count]="channel.showTotalCountBadge"
        *ngIf="channel.syncingComplete && (channel.showTotalCountBadge ? channel._ui.totalCount > 0 : channel._ui.unreadEmails > 0)"
      >
        {{channel.showTotalCountBadge ? channel._ui.totalCount : channel._ui.unreadEmails}}
      </div>

      <!-- Edit options -->
      <div class="remove-button" (click)="toggleDropdown($event, channel)" #additionalDropdownOpenTarget>
        <svg-comp class="favorite-remove" [src]="ellipsisVertical"></svg-comp>
      </div>
    </div>

    <!-- Show more -->
    <div
      *ngIf="sharedInboxChannels.length > 10 && !showMoreSharedInboxes"
      class="show-more"
      sleepClickEvent
      (click)="handleShowMoreSharedInboxes()"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Show more shared inboxes', location: trackingLocation}"
    >
      Show more
    </div>
  </div>
</div>

<!-- Team -->
<div
  class="items teams-item"
  [class.list-opened]="teamChannels?.length && channelsListPreferences.teamsOpened"
  id="menu-team"
>
  <!-- Header -->
  <h2
    class="menu-item__title"
    [class.selectable]="!teamChannels?.length"
    [routerLink]="!teamChannels?.length ? ['/empty/emptyTeams'] : undefined"
    [routerLinkActive]="['selected']"
    (click)="teamChannels?.length && toggleListOpened($event, 'teamsOpened')"
  >
    <!-- Dropdown svg -->
    <div class="title-dropdown">
      <svg-comp class="svg-icon" [src]="chevronSvg"></svg-comp>
    </div>

    <!-- Section name -->
    <div class="title-text" [class.unread]="!channelsListPreferences.teamsOpened && hasUnreadTeamsChannels">TEAMS</div>

    <!-- Add svg -->
    <svg-comp
      [src]="addTeamIconSvg"
      class="add-team-icon"
      id="add-team-button"
      sleepClickEvent
      (click)="addTeam()"
      [tooltip]="{content: isDarwin ? 'Add a team (⌥+T)' : 'Add a team (Alt+T)', placement: TooltipPlacement.RIGHT}"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Add team', location: trackingLocation}"
    >
    </svg-comp>
  </h2>

  <!-- Teams list -->
  <div class="menu-item__list">
    <!-- Teams channels -->
    <div
      *ngFor="let channel of teamChannels.slice(0, teamChannelsShowLimit); trackBy: trackByFn"
      class="channel"
      (click)="navigate($event, channel)"
      (mouseenter)="onChannelHover(channel, additionalDropdownOpenTarget)"
      [routerLink]="['/channel/' + channel._id]"
      [routerLinkActive]="['selected']"
      [routerLinkActiveOptions]="{exact: false}"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Team channel', location: trackingLocation}"
      [class.unread-chat]="channel._ui.unreadChats > 0"
    >
      <!-- Icon -->
      <div class="indicator-container">
        <div class="team-icon" *ngIf="channel.$type === 'Group'">
          <svg-comp [src]="teamIconSvg"></svg-comp>
        </div>
      </div>

      <!-- Name -->
      <div class="name">{{ channel.name }}</div>

      <!-- Unread count -->
      <div
        class="unread-badge-mail"
        [class.offline-state]="offline"
        [class.total-count]="channel.showTotalCountBadge"
        *ngIf="channel._ui.unreadChats > 0"
      >
        {{ channel._ui.unreadChats }}
      </div>

      <!-- Edit options -->
      <div class="remove-button" (click)="toggleDropdown($event, channel)" #additionalDropdownOpenTarget>
        <svg-comp class="favorite-remove" [src]="ellipsisVertical"></svg-comp>
      </div>
    </div>

    <!-- Show more -->
    <div
      *ngIf="teamChannels.length > 10 && !showMoreTeams"
      class="show-more"
      sleepClickEvent
      (click)="handleShowMoreTeams()"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Show more teams', location: trackingLocation}"
    >
      Show more
    </div>
  </div>
</div>

<!-- Direct chat -->
<div class="items people-item" [class.list-opened]="channelsListPreferences.peopleOpened">
  <!-- Header -->
  <h2 id="menu-people" class="menu-item__title" (click)="toggleListOpened($event, 'peopleOpened')">
    <!-- Dropdown svg -->
    <div class="title-dropdown">
      <svg-comp class="svg-icon" [src]="chevronSvg"></svg-comp>
    </div>

    <!-- Section name -->
    <div class="title-text" [class.unread]="!channelsListPreferences.peopleOpened && hasUnreadPeopleChannels">
      DIRECT CHAT
    </div>

    <!-- Add svg -->
    <svg-comp
      [src]="addTeamIconSvg"
      class="add-team-icon"
      (click)="newChat($event)"
      [tooltip]="{content:'Chat with someone', placement: TooltipPlacement.RIGHT}"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Add chat', location: trackingLocation}"
    >
    </svg-comp>
  </h2>

  <!-- People list -->
  <div class="menu-item__list">
    <!-- People channels -->
    <div
      *ngFor="let channel of peopleChannels.slice(0, peopleChannelsShowLimit); trackBy: trackByFn"
      class="channel"
      (click)="navigate($event, channel)"
      [routerLink]="['/channel/' + channel._id]"
      [routerLinkActive]="['selected']"
      [routerLinkActiveOptions]="{exact: false}"
      [trackUserClick]="{forUserEmail: currentUserEmail, action: 'People channel', location: trackingLocation}"
      [class.unread-chat]="channel._ui.unreadChats"
    >
      <!-- Active/Offline svg -->
      <div class="indicator-container">
        <div
          class="status"
          [class.inactive]="channel.onlineStatus && channel.onlineStatus === 'Inactive'"
          [class.active]="channel.onlineStatus && channel.onlineStatus === 'Active'"
        ></div>
      </div>

      <!-- Name -->
      <div class="name">{{ channel.name }}</div>

      <!-- Availability status -->
      <div *ngIf="userAvailabilitiesByUserId[channel.id]?.isValidAvailabilityStatus()">
        {{ userAvailabilitiesByUserId[channel.id].availabilityStatus.emoji }}
      </div>

      <!-- Unread count -->
      <div
        class="unread-badge-mail"
        [class.offline-state]="offline"
        [class.total-count]="channel.showTotalCountBadge"
        *ngIf="channel._ui.unreadChats > 0"
      >
        {{ channel._ui.unreadChats }}
      </div>

      <!-- Delete -->
      <div class="delete-chatee">
        <svg-comp [src]="deleteChateeSvg" (click)="deleteChatee($event, channel)"></svg-comp>
      </div>
    </div>

    <!-- Show more -->
    <div
      *ngIf="peopleChannels.length > 10 && !showMorePeople"
      class="show-more"
      sleepClickEvent
      (click)="handleShowMorePeople()"
      [trackUserClick]="{forUserEmail: currentUserEmail, action :'Show more people', location: trackingLocation}"
    >
      Show more
    </div>

    <!-- Empty state -->
    <div
      *ngIf="!peopleChannels || !peopleChannels.length"
      class="empty"
      [innerHTML]="'Your contacts will appear here.'"
    ></div>
  </div>
</div>
