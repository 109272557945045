import * as _ from 'lodash';
import { Directive } from '@angular/core';
import {
  CardBaseModel,
  CardDraftModel,
  CardMailModel,
  CardModel
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import {
  CommentChatModel,
  CommentDraftModel,
  CommentMailModel
} from '@dta/shared/models-api-loop/comment/comment.model';
import { DialogSendEmailCommentData } from '@dta/shared/models/dialog.model';
import { SendDraftChatEvent } from '@shared/models/shared-draft/shared-draft.model';
import { forkJoin, from, Observable, of, switchMap } from 'rxjs';
import { BaseService } from '../base/base.service';
import { DraftServiceI } from './draft.service.interface';
import { SendAsOption } from '@dta/shared/models/send-as.model';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { catchError, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { CommentService } from '../comment/comment.service';
import { CardService } from '../card/card.service';
import { CommentBody, CommentDraft, ConvertedTempAttachment, DraftType } from '@shared/api/api-loop/models';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { DraftDeleted } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { DraftCommentData } from '@shared/models/draft-data/draft-data.model';
import { FileService } from '../file/file.service';
import { Logger } from '@shared/services/logger/logger';
import { LogTag } from '@dta/shared/models/logger.model';
import { DraftCardsCollectionParams, DraftCommentCollectionParams } from '@dta/shared/models/collection.model';
import { HistoryAndSignatureHelper } from '@dta/shared/utils/history-and-signature/history-and-signature.helper';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { CardApiService } from '@shared/api/api-loop/services';
import { TagService } from '../tag/tag.service';
import { StateUpdates } from '@dta/shared/models/state-updates';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Directive()
export abstract class DraftService extends BaseService implements DraftServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _conversationService: ConversationService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _fileService: FileService,
    protected _historyAndSignatureHelper: HistoryAndSignatureHelper,
    protected _cardApiService: CardApiService,
    protected _tagService: TagService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'DraftService';
  }

  // Override when needed
  migrateDraftsFromOfflineToOnline(forUserEmail: string): Observable<any> {
    return of(undefined);
  }

  createOrUpdateDraftComment(forUserEmail: string, draftData: DraftCommentData): Observable<CommentDraftModel> {
    let comment = new CommentDraftModel(_.cloneDeep(draftData.existingDraft));

    comment.created = new Date().toISOString();
    comment.author = draftData.author;
    comment.name = draftData.name || draftData.conversation?.name;
    comment.to = BaseModel.createListOfResources(draftData.to);
    comment.cc = BaseModel.createListOfResources(draftData.cc || []);
    comment.bcc = BaseModel.createListOfResources(draftData.bcc || []);
    comment.replyTo = BaseModel.createListOfResources(draftData.replyToRecipients || []);
    comment.snippet = this.buildSnippetForDraft(draftData.body);
    comment.sendAs = draftData.sendAsEmail;
    comment.sendAsGroupId = draftData.sendAsGroupId;
    comment.attachments = draftData.attachments?.length
      ? BaseModel.createListOfResources(draftData.attachments)
      : undefined;

    if (draftData.cardDraftId) {
      comment.parent = CardBaseModel.buildFromBaseAsReference({
        id: draftData.cardDraftId,
        $type: CardDraftModel.type
      });
    }

    // Parent
    if (!_.isEmpty(draftData.conversation)) {
      // TODO fix, conversation is Record<string, string> not model ...
      comment.setExPassthroughData('linkedMailCard', draftData.conversation.snapshotCard?.id);
    }

    // Body
    comment.body = {} as CommentBody;
    comment.body.content = this._historyAndSignatureHelper.prepareDraftBody(
      draftData.body,
      draftData.history,
      draftData.signatureHtml
    );

    // Set additional data
    comment.setExPassthroughData('saveAndClose', draftData.saveAndClose);

    return of(undefined).pipe(
      /**
       * Ensure card is subscribed
       */
      mergeMap(() => this.ensureCardSubscribed(forUserEmail, draftData)),
      /**
       * Ensure comment is linked to card draft
       */
      mergeMap(() => this.ensureCardDraftCreatedAndLinked(forUserEmail, comment, draftData.conversation)),
      /**
       * Save and publish comment
       */
      mergeMap((updates: StateUpdates) =>
        this._commentService
          .saveAllAndPublish(forUserEmail, updates.comments)
          .pipe(map((comments: CommentDraftModel[]) => [comments[0], updates]))
      ),
      /**
       * Save and publish cards
       */
      mergeMap(([savedComment, updates]: [CommentDraftModel, StateUpdates]) =>
        this._cardService.saveAllAndPublish(forUserEmail, updates.cards).pipe(map(() => savedComment))
      ),
      /**
       * Sync
       */
      map((savedComment: CommentDraftModel) => {
        let changesOnCommentModel = savedComment.hasSyncableChanges(draftData.existingDraft);
        savedComment.setExPassthroughData('unSyncedBodyChanges', changesOnCommentModel || draftData.saveAndClose);

        this.enqueuePushSynchronization(forUserEmail, savedComment);

        return savedComment;
      })
    );
  }

  private ensureCardDraftCreatedAndLinked(
    forUserEmail: string,
    commentDraft: CommentDraftModel,
    conversation?: ConversationModel
  ): Observable<StateUpdates> {
    let stateUpdates = new StateUpdates();
    stateUpdates.add([commentDraft]);

    if (commentDraft.parent) {
      return of(stateUpdates);
    }

    return of(undefined).pipe(
      /**
       * Create card draft
       */
      map(() => this.buildCardDraft(commentDraft, conversation)),
      /**
       * Create all links
       */
      mergeMap((cardDraft: CardDraftModel) => {
        let cardsToSave: CardModel[] = [cardDraft];

        let cardDraftAsReference = CardBaseModel.buildFromBaseAsReference(cardDraft);

        // Link draft card to draft comment
        commentDraft.parent = cardDraftAsReference;
        stateUpdates.add(cardsToSave);

        // Link draft card to mail card
        if (conversation) {
          conversation.privateDraft = cardDraftAsReference;
          return this._conversationService
            .saveAllAndPublish(forUserEmail, [new ConversationModel(conversation)])
            .pipe(map(() => stateUpdates));
        }
        return of(stateUpdates);
      })
    );
  }

  private buildCardDraft(commentDraft: CommentMailModel, conversation?: ConversationModel): CardDraftModel {
    let card = new CardDraftModel();

    card.parentCard = conversation?.snapshotCard;
    card.name = commentDraft.name;
    card.commentDraft = commentDraft;

    return card;
  }

  private ensureCardSubscribed(forUserEmail: string, draftData: DraftCommentData): Observable<ConversationModel> {
    let loopInResourceId = draftData.conversation?.id;

    if (draftData.existingDraft || !loopInResourceId) {
      return of(undefined);
    }

    return this._conversationService.findOrFetchByCardId(forUserEmail, loopInResourceId).pipe(
      mergeMap((conversation: ConversationModel) => this._tagService.ensureCardSubscribed(forUserEmail, conversation)),
      catchError(err => {
        Logger.error(
          err,
          `Error when ensuring loopInCard is subscribed when creating draft. cardId: ${loopInResourceId}`,
          LogTag.INTERESTING_ERROR
        );

        return of(undefined);
      })
    );
  }

  private buildSnippetForDraft(html: string): string {
    let wrapper = document.createElement('div');
    wrapper.innerHTML = html;
    let textContent = wrapper.textContent;
    wrapper.remove();
    return textContent?.substring(0, 120);
  }

  convertAttachmentsToTemp(forUserEmail: string, commentDraft: CommentDraftModel): Observable<CommentDraftModel> {
    if (!commentDraft.hasAttachments()) {
      return of(commentDraft);
    }

    return this._fileService.convertNonTempAttachmentsToTemp(forUserEmail, commentDraft.getAttachments()).pipe(
      map((convertedTempAttachment: ConvertedTempAttachment[]) => {
        let bodyContent = commentDraft.body?.content;

        // Replace non-temp file ids in body
        if (bodyContent) {
          _.forEach(
            convertedTempAttachment,
            (attachment: ConvertedTempAttachment) =>
              (bodyContent = bodyContent.replace(attachment.fileAttachmentId, attachment.tempAttachment.id))
          );

          commentDraft.body.content = bodyContent;
        }

        // Replace non-temp files in attachments
        let convertedTempAttachmentByFileId = _.keyBy(
          convertedTempAttachment,
          (attachment: ConvertedTempAttachment) => attachment.fileAttachmentId
        );
        let attachments = _.map(
          commentDraft.getAttachments(),
          (file: FileModel) => convertedTempAttachmentByFileId[file.id]?.tempAttachment || file
        );
        commentDraft.attachments.resources = attachments;

        return commentDraft;
      })
    );
  }

  fetchDraftContent(forUserEmail: string, contentFileId: string): Observable<string> {
    return this._fileService.findOrFetchFileById(forUserEmail, contentFileId).pipe(
      mergeMap((file: FileModel) => {
        return this._fileService.getFileContent(forUserEmail, file);
      }),
      map((data: ArrayBuffer) => {
        let decoder = new TextDecoder();
        return decoder.decode(data);
      }),
      catchError(err => {
        Logger.error(err, `Error when downloading draft comment body. Id: ${contentFileId}.`, LogTag.INTERESTING_ERROR);
        return of('');
      })
    );
  }

  discardDraft(forUserEmail: string, draftCardId: string, conversationId: string): Observable<any> {
    let card = new CardDraftModel();
    card.id = draftCardId;
    let deleteDraftCardOnBe$ = this._cardApiService.Card_DeleteCardDraft({ cardDraft: card }, forUserEmail);

    // Try finding BE id for local card (in case update did not reach end component yet)
    if (draftCardId.startsWith(BaseModel.idPrefix)) {
      deleteDraftCardOnBe$ = this._commentService.findCommentsByParentIdsOrClientIds(forUserEmail, [draftCardId]).pipe(
        mergeMap((comments: CommentDraft[]) => {
          // If draft card is not synced, get ID from synced draft comment
          if (!_.isEmpty(comments) && comments[0].parent.id) {
            card.id = comments[0].parent.id;
            return this._cardApiService.Card_DeleteCardDraft({ cardDraft: card }, forUserEmail);
          }

          return of(undefined);
        })
      );
    }

    if (conversationId) {
      return deleteDraftCardOnBe$.pipe(
        /**
         * Mark card as deleted (for local updates)
         */
        mergeMap(() => this.markDraftCardsAsDeletedLocally(forUserEmail, conversationId))
      );
    }

    return deleteDraftCardOnBe$;
  }

  private markDraftCardsAsDeletedLocally(forUserEmail: string, conversationId: string): Observable<CardDraftModel> {
    return of(undefined).pipe(
      mergeMap(() => this._conversationService.findByCardId(forUserEmail, conversationId)),
      /**
       * Unlink draft card from mail card
       */
      mergeMap((conversation: ConversationModel) =>
        !!conversation.privateDraft
          ? this._conversationService.unlinkPrivateDraft(forUserEmail, conversationId)
          : of(undefined)
      )
    );
  }

  findOrFetchDraftCommentForDraftCard(forUserEmail: string, draftCard: CardDraftModel): Observable<CardDraftModel> {
    return of(undefined).pipe(
      mergeMap(() => {
        return this._commentService.findOrFetchCommentsById(forUserEmail, [
          draftCard.commentDraft.id || draftCard.commentDraft.clientId
        ]);
      }),
      map((comments: CommentDraftModel[]) => {
        draftCard.commentDraft = comments[0];
        return draftCard;
      })
    );
  }

  findDraftUndoData(
    forUserEmail: string,
    draftCardId: string
  ): Observable<{
    draftCardId: string;
    draftComment: CommentDraftModel;
    conversation: ConversationModel;
  }> {
    return this._cardService.findOrFetchCardById(forUserEmail, draftCardId).pipe(
      mergeMap((cardDraft: CardDraftModel) =>
        forkJoin([
          of(cardDraft),
          this._commentService
            .findOrFetchCommentsById(forUserEmail, [cardDraft.commentDraft.id])
            .pipe(map(comments => _.first(comments))),
          cardDraft.parentCard?.id
            ? this._conversationService.findByCardId(forUserEmail, cardDraft.parentCard.id.replace('-copy1T', ''))
            : of(new ConversationModel({ $type: ConversationModel.type }))
        ])
      ),
      switchMap(([draftCard, draftComment, conversation]: [CardDraftModel, CommentDraftModel, ConversationModel]) => {
        conversation.privateDraft = draftCard;
        conversation.draftTypes = [DraftType.PRIVATE];
        return this._conversationService.saveAllAndPublish(forUserEmail, [conversation]).pipe(
          map(() => {
            return [draftCard, draftComment, conversation];
          })
        );
      }),
      map(([draftCard, draftComment, conversation]: [CardDraftModel, CommentDraftModel, ConversationModel]) => {
        return {
          draftCardId: draftCard.id,
          draftComment: draftComment,
          conversation: conversation
        };
      })
    );
  }

  /////////////////
  // Dao wrappers
  /////////////////
  findDraftComment(forUserEmail: string, params: DraftCommentCollectionParams): Observable<CommentDraftModel[]> {
    return this._commentService.findCommentsByIdsOrClientIds(forUserEmail, [
      params.draftComment.id || params.draftComment.clientId
    ]) as Observable<CommentDraftModel[]>;
  }

  findDraftsForCard(forUserEmail: string, params: DraftCardsCollectionParams): Observable<CardDraftModel[]> {
    return of(undefined).pipe(
      /**
       * Find draft cards
       */
      mergeMap(() =>
        this._cardService.findByIds(
          forUserEmail,
          (<CardMailModel>CardMailModel.create(params.forCard)).getDraftCardIds(true)
        )
      ),
      // <-- todo: handle missing
      /**
       * Find draft comments
       */
      mergeMap((draftCards: CardDraftModel[]) => {
        let draftCommentIds = _.map(
          draftCards,
          (draftCard: CardDraftModel) => draftCard.commentDraft.id || draftCard.commentDraft.clientId
        );
        return forkJoin([this._commentService.findByIds(forUserEmail, draftCommentIds), of(draftCards)]);
      }),
      /**
       * Map comments to cards and return cards
       */
      map(([draftComments, draftsCards]: [CommentDraftModel[], CardDraftModel[]]) => {
        let draftCommentsByIds = _.keyBy(draftComments, comment => comment.id || comment.clientId);
        draftsCards = _.map(draftsCards, (draftCard: CardDraftModel) => {
          let commentId = draftCard.commentDraft.id || draftCard.commentDraft.clientId;
          draftCard.commentDraft = draftCommentsByIds[commentId] || draftCard.commentDraft;

          return draftCard;
        });

        return draftsCards;
      })
    );
  }

  createDraftCard(
    forUserEmail: string,
    emailData: DialogSendEmailCommentData,
    data: SendDraftChatEvent,
    sendAs?: any, // <-- todo
    sendAsGroupId?: string
  ): Observable<CardDraftModel> {
    return of(undefined);
  }

  createLocalDraftCard(
    forUserEmail: string,
    commentDraft: CommentMailModel,
    data: SendDraftChatEvent,
    parentCard: CardMailModel
  ): Observable<CardModel[]> {
    return of([]);
  }

  abstract updateSharedDraftContent(forUserEmail: string, contentFileId: string, content: string): Observable<any>;

  abstract updateSharedDraftComment(
    forUserEmail: string,
    sharedDraftComment: CommentDraftModel
  ): Observable<CommentDraftModel>;

  abstract getSharedDraftData(forUserEmail: string, sharedDraft_id: string): Observable<SharedDraftData>;

  sendDraftChat(forUserEmail: string, comments: CommentChatModel[]): Observable<CommentChatModel[]> {
    let _card: CardModel;
    let commentsToReturn: CommentChatModel[];
    return this.getOrCreateCardDraft(forUserEmail, comments[0]).pipe(
      mergeMap((card: CardModel) => {
        _card = card;
        let _parent = CardBaseModel.buildAsReference(card);

        comments.map(comment => (comment.parent = _parent));

        return from(comments);
      }),
      toArray(),
      mergeMap((_comments: CommentChatModel[]) => {
        return this._commentService.createComments(forUserEmail, _comments);
      }),
      mergeMap((_comments: CommentChatModel[]) => {
        commentsToReturn = _comments;
        this.enqueuePushSynchronization(forUserEmail, _comments);
        return this._cardService.saveAndPublish(forUserEmail, _card);
      }),
      map(() => {
        return commentsToReturn;
      })
    );
  }

  private getOrCreateCardDraft(forUserEmail: string, comment: CommentChatModel): Observable<CardModel> {
    return of(undefined);
    // if (!comment.parent) {
    //     return of(this.buildCardDraft(comment));
    // }

    // let card = <CardModel>comment.parent;
    // return this._cardService.findById(forUserEmail, card._id);
  }
}

export interface SharedDraftData {
  sharedDraftComment: CommentDraftModel;
  draftCard: CardDraftModel;
  sourceCard?: CardMailModel;
  parentCard?: CardModel;
  sharedInboxId?: string;
  sendAsOptions?: SendAsOption[];
}
