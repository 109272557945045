import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { TrackingService } from './tracking.service';
import { TrackingConstants } from './tracking.constants';

@Directive({
  selector: '[tracking]',
})
export class TrackingDirective implements OnInit {
  @Input() tracking: TrackingProperties;

  constructor(private _trackingService: TrackingService) {}

  @HostListener('click', ['$event']) onClick($event) {
    let properties = {
      View: this.tracking.view,
      Origin: this.tracking.origin,
      Action: 'Click',
    };
    this._trackingService.track(this.tracking.forUserEmail, TrackingConstants.navigationEventName, properties);
  }

  ngOnInit() {}
}

interface TrackingProperties {
  forUserEmail: string;
  view: string;
  origin: string;
}
