import * as moment from 'moment';
import { combineLatest, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { ApiService } from '@shared/api/api-loop/api.module';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { SyncServiceStatus } from '@shared/models/sync/synchronization-service-status.model';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { StorageService } from '@dta/shared/services/storage/storage.service';
import { SynchronizationStatusService, SynchronizationStatusType } from '../synchronization-status.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { DataServiceShared } from '@shared/services/data/data.service';
import { EventSynchronizationService } from '../event-synchronization/event-synchronization.service';
import { FollowUpSynchronizationService } from './follow-up-synchronization/follow-up-synchronization.service';
import { ActiveSynchronizationService } from './active-synchronization/active-synchronization.service';
import { SynchronizationMiddlewareService } from '../synchronization-middleware/synchronization-middleware.service';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { OnDestroy } from '@angular/core';
import { ConversationSynchronizationService } from '@shared/synchronization/conversation-synchronization/conversation-synchronization.service';
import {
  PrefetchSynchronizationService,
  PrefetchSyncState
} from '../../../web/app/synchronization/pull-synchronization/prefetch-synchronization/prefetch-synchronization.service';
import { CONSTANTS } from '@shared/models/constants/constants';
import { UserAuthService } from '@shared/modules/auth/auth-data-access/user-auth.service';

@AutoUnsubscribe()
export abstract class PullSynchronizationService implements OnDestroy {
  // State variables
  protected _active: boolean = false;
  protected syncServicesIdle: boolean = true;

  //////////////////
  // Subscriptions
  //////////////////
  protected unsubscribe$: Subject<void>;
  private activeSyncSub: Subscription;
  private followUpSyncSub: Subscription;
  private conversationSyncSub: Subscription;
  private allBlockingSyncDoneSub: Subscription;
  private prefetchSyncSub: Subscription;

  //////////////////
  // Sync services
  //////////////////
  protected activeSynchronizationService: ActiveSynchronizationService;
  protected followUpSynchronizationService: FollowUpSynchronizationService;
  // protected contactSynchronizationService: ContactSynchronizationService;
  protected prefetchSynchronizationService: PrefetchSynchronizationService;

  // Subjects (must be here to be available as soon as pull sync is started)
  private followUpSync$: Subject<any> = new Subject();

  constructor(
    protected _userEmail: string,
    protected _apiService: ApiService,
    protected _storageService: StorageService,
    protected _eventSynchronizationService: EventSynchronizationService,
    protected _conversationSynchronizationService: ConversationSynchronizationService,
    protected _syncStatusService: SynchronizationStatusService,
    protected _data: DataServiceShared,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _syncMiddleware: SynchronizationMiddlewareService
  ) {}

  ngOnDestroy(): void {}

  get active(): boolean {
    let unverifiedSyncServicesStatus =
      !this._sharedUserManagerService.isUserAccountVerified(this._userEmail) &&
      this.activeSynchronizationService.active;

    let syncServicesStatus = this.syncServicesIdle || this.areAllServicesActiveOrComplete();

    return this._active && (syncServicesStatus || unverifiedSyncServicesStatus);
  }

  get waitUntilAllBlockingPrefetchActionsDone$(): Observable<any> {
    return combineLatest([this.prefetchSynchronizationService.waitForPrefetchSyncDone$]);
  }

  protected areAllServicesActiveOrComplete(): boolean {
    return this.activeSynchronizationService.active && this.followUpSynchronizationService.active;
  }

  getStatus(): SyncServiceStatus {
    return this.active ? SyncServiceStatus.ACTIVE : SyncServiceStatus.INACTIVE;
  }

  /**
   * Returns sync cut off date
   */
  getCutOffDate(): Date {
    return moment().subtract(CONSTANTS.SYNC_CUTOFF_DAYS, 'days').toDate();
  }

  /**
   * Start active synchronization
   */
  start() {
    // Use "combined" active so that services get restarted if one is down
    if (this.active) {
      return;
    }

    Logger.customLog(
      `[SYNC] - PullSync [${this._userEmail}]: started. Sync statuses: ${this.getDebugLog()}`,
      LogLevel.INFO,
      LogTag.SYNC
    );

    // Fail safe
    this.stop();

    this._active = true;
    this.unsubscribe$ = new Subject<void>();

    // Prepare sync state
    return of(undefined)
      .pipe(
        /**
         * Make sure processes get unsubscribed
         */
        delay(100),
        map(() => {
          // Start all syncs
          this.startAllServices();
          this.syncServicesIdle = false;
        }),
        catchError(err => {
          // fail-safe
          // watchdog will restart the sync
          this.stop();

          Logger.customLog(
            `[SYNC] - PullSync [${this._userEmail}]: Could not start event synchronization`,
            LogLevel.ERROR,
            LogTag.SYNC
          );
          return throwError(err);
        })
      )
      .subscribe();
  }

  /**
   * Stop active synchronization
   */
  stop() {
    this._active = false;
    this.syncServicesIdle = true;

    this.stopAllServices();

    Logger.customLog(`[SYNC] - PullSync [${this._userEmail}]: stopped`, LogLevel.INFO, LogTag.SYNC);
  }

  /**
   * Init services
   */
  init() {
    this.activeSynchronizationService = new ActiveSynchronizationService(
      this._userEmail,
      this._apiService,
      this._storageService,
      this._eventSynchronizationService,
      this,
      this._sharedUserManagerService,
      this._data
    );

    this.followUpSynchronizationService = new FollowUpSynchronizationService(
      this._userEmail,
      this._data,
      this._eventSynchronizationService,
      this
    );

    this.prefetchSynchronizationService = new PrefetchSynchronizationService(this._userEmail, this._data);

    this.conversationInit();
  }

  protected abstract conversationInit();

  protected stopAllServices() {
    // Unsubscribe old sync if it exists
    if (this.unsubscribe$) {
      Logger.customLog(
        `[SYNC] - PullSync [${this._userEmail}]: this.unsubscribe$ defined. Will call next and complete`,
        LogLevel.TRACE,
        LogTag.SYNC
      );

      this.unsubscribe$.next(void 0);
      this.unsubscribe$.complete();
    }

    this.activeSyncSub?.unsubscribe();
    this.followUpSyncSub?.unsubscribe();
    this.conversationSyncSub?.unsubscribe();
    this.prefetchSyncSub?.unsubscribe();

    /**
     * Unset values
     */
    this.prefetchSynchronizationService.status = PrefetchSyncState.INACTIVE;
  }

  protected startAllServices() {
    Logger.customLog(`[SYNC] - PullSync [${this._userEmail}]: startAllServices called`, LogLevel.TRACE, LogTag.SYNC);

    this.activeSyncSub = this.activeSynchronizationService.startActiveSync(this.unsubscribe$);
    this.conversationSyncSub = this._conversationSynchronizationService.startConversationSync(this.unsubscribe$);
    this.followUpSyncSub = this.followUpSynchronizationService.startFollowUpSync();
    this.prefetchSyncSub = this.prefetchSynchronizationService.startPrefetchSync();

    this.subscribeToAllBlockingSyncDone();
  }

  notifyEnqueue(type: SynchronizationStatusType) {
    this._syncStatusService.enqueue({
      id: SynchronizationStatusType[type],
      type: type
    });
  }

  notifyDequeue(type: SynchronizationStatusType) {
    this._syncStatusService.dequeue({
      id: SynchronizationStatusType[type],
      type: type
    });
  }

  getActiveSynchronizationStatus(): SyncServiceStatus {
    if (!this.activeSynchronizationService) {
      return SyncServiceStatus.INACTIVE;
    }
    return this.activeSynchronizationService.getStatus();
  }

  getFollowUpSynchronizationStatus(): SyncServiceStatus {
    if (!this.followUpSynchronizationService) {
      return SyncServiceStatus.INACTIVE;
    }
    return this.followUpSynchronizationService.getStatus();
  }

  getFollowUpSyncSubject(): Subject<void> {
    return this.followUpSync$;
  }

  ///////////
  // HELPERS
  ///////////
  protected handleResetSyncState(newHistoryId: string) {
    // Active sync state parameters
    this.activeSynchronizationService.persistHistoryId(newHistoryId);
  }

  protected wasOfflineForTooLong(nOfDays: number, lastOnlineDate: Date) {
    let limitDate = moment().subtract(nOfDays, 'days').toDate();

    return limitDate > lastOnlineDate;
  }

  protected getDebugLog(): string {
    return (
      'syncServicesIdle: ' +
      this.syncServicesIdle +
      ', activeSynchronizationService: ' +
      (!this.activeSynchronizationService
        ? 'undefined'
        : this.activeSynchronizationService.active
          ? 'active'
          : 'inactive') +
      ', followUpSynchronizationService.active: ' +
      (!this.followUpSynchronizationService
        ? 'undefined'
        : this.followUpSynchronizationService.active
          ? 'active'
          : 'inactive')
    );
  }

  private subscribeToAllBlockingSyncDone() {
    this.allBlockingSyncDoneSub?.unsubscribe();
    this.allBlockingSyncDoneSub = this.waitUntilAllBlockingPrefetchActionsDone$
      .pipe(tap(() => this._syncMiddleware.allBlockingSyncDone$.next({ forUserEmail: this._userEmail })))
      .subscribe();
  }
}

export enum EventFilterType {
  OnboardingComplete = 'OnboardingComplete',
  CommentCreated = 'CommentCreated',
  CardCreated = 'CardCreated',
  CardUpdated = 'CardUpdated',
  CommentUpdated = 'CommentUpdated',
  TagsCreated = 'TagsCreated',
  TagsUpdated = 'TagsUpdated',
  SharedTagsUpdated = 'SharedTagsUpdated',
  UserSettingsUpdated = 'UserSettingsUpdated'
}
