import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { format, isValid } from 'date-fns';
import { RangeDate } from '@dta/ui/components/date-picker/date-picker.component';

@Component({
  selector: 'loop-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerCVAComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerCVAComponent),
      multi: true
    }
  ]
})
export class DatePickerCVAComponent implements ControlValueAccessor, Validator {
  selectedDate?: Date | null;
  openCalendar: boolean = false;

  private isValid: boolean = true;
  private _inputElement?: ElementRef<HTMLInputElement>;
  latestUsedUserValue: string | null = null;

  @ViewChild('inputElement')
  set inputElement(inputElement: ElementRef | undefined) {
    this._inputElement = inputElement;
    if (this.selectedDate) {
      this.updateUserValue(this.formatDate(this.selectedDate));
    }
  }

  @Input() placeholder?: string | null = null;
  @Input() size?: any;
  @Input() isDisabled?: boolean;

  private onChange: (date: Date | null) => void = _date => {
    // noop
  };

  private onTouch: (date: Date | null) => void = _date => {
    // noop
  };

  registerOnChange(fn: (date: Date | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (date: Date | null) => void): void {
    this.onTouch = fn;
  }

  validate(_control: UntypedFormControl): ValidationErrors | null {
    return this.isValid ? null : { invalidDate: true };
  }

  writeValue(date: Date): void {
    this.selectedDate = date;
    this.updateUserValue(date ? this.formatDate(date) : null);
  }

  updateUserValue(value: string | null): void {
    this.latestUsedUserValue = value;
  }

  onDateSelected($event: RangeDate): void {
    this.openCalendar = false;
    this.updateValue($event.from);
  }

  private formatDate(date: Date): string {
    return format(date, 'MMMM d Y');
  }

  updateValue(date: Date | null): void {
    this.selectedDate = date;

    if (date && isValid(date)) {
      this.isValid = true;
      this.updateUserValue(this.formatDate(date));
    }

    this.onTouch(date);
    this.onChange(date);
  }

  textClick(): void {
    if (this.isDisabled) {
      return;
    }
    this.openCalendar = true;
  }
}
