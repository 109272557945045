import { Pipe, PipeTransform } from '@angular/core';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { isLoadingAttachment, LoadingAttachment } from '@dta/shared/models-api-loop/file.model';

@Pipe({
  name: 'isLoadingAttachment',
})
export class IsLoadingAttachmentPipe implements PipeTransform {
  transform(attachment: IFile | LoadingAttachment): attachment is LoadingAttachment {
    return isLoadingAttachment(attachment);
  }
}
