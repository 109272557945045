import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { Group } from '@shared/api/api-loop/models/group';
import { ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';

export function isGroupContact(contact: ContactBase): contact is Group {
  return !!(contact as Group)?.groupType;
}

export function isGroupModel(contact: ContactModel): contact is GroupModel {
  return (contact as GroupModel)?.$type === GroupModel.type;
}
