import { CommandService } from '@shared/services/data/command/command.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { CardService } from '@shared/services/data/card/card.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { Injectable, OnDestroy } from '@angular/core';
import { IFrameRequestHandlerI } from '../iframe-request-handler.interface';
import { from, mergeMap, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { CardMailModel, CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { CommentMailModel, CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { IFrameConversationRequest, IFrameMethod } from '@intheloop/loop-integration';

@Injectable()
export class ConversationHelper implements IFrameRequestHandlerI, OnDestroy {
  readonly openDraft$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(1);

  constructor(
    private _slashCommandService: CommandService,
    private _commentService: CommentService,
    private _cardService: CardService,
    private _conversationService: ConversationService
  ) {}

  handleRequest(forUserEmail: string, request: IFrameConversationRequest, integrationId: string): Observable<any> {
    switch (request.method) {
      case IFrameMethod.RESOLVE_CONVERSATION:
        return this.resolveConversation(forUserEmail, request.conversationId);
      case IFrameMethod.DOWNLOAD_CONVERSATION_EMLS:
        return this.downloadConversationEmls(forUserEmail, request.conversationId);
      case IFrameMethod.DELETE_CONVERSATION:
        return this.deleteConversation(forUserEmail, request.conversationId);
      case IFrameMethod.STAR_CONVERSATION:
        return this.starConversation(forUserEmail, request.conversationId);
      case IFrameMethod.GET_CONVERSATION:
        return this.getConversation(forUserEmail, request.conversationId);
      case IFrameMethod.OPEN_DRAFT_FOR_CONVERSATION:
        this.openDraft$.next(request.conversationId);
        return of(undefined);
    }
  }

  private deleteConversation(currentUserEmail: string, conversationId: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  private getConversation(currentUserEmail: string, conversationId: string): Observable<any> {
    return this._conversationService.findByCardId(currentUserEmail, conversationId);
  }

  private starConversation(currentUserEmail: string, conversationId: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  private downloadConversationEmls(currentUserEmail: string, conversationId: string): Observable<any> {
    return this._commentService
      .findCommentsByParentIdsOrClientIds(currentUserEmail, [conversationId, `${conversationId}-copy1T`])
      .pipe(
        mergeMap((comments: CommentModel[]) => {
          return from(comments);
        }),
        mergeMap((comment: CommentModel) => {
          if (comment.$type === CommentMailModel.type) {
            return this._commentService.downloadCommentContent(
              currentUserEmail,
              comment.id,
              comment.parent.name || conversationId
            );
          }
          return of(undefined);
        })
      );
  }

  private resolveConversation(currentUserEmail: string, conversationId: string): Observable<any> {
    return this._cardService.findOrFetchCardById(currentUserEmail, conversationId).pipe(
      mergeMap((card: CardModel) => {
        return this._slashCommandService.sendCommand(
          currentUserEmail,
          '/resolve',
          [card.id],
          card.$type === CardMailModel.type
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.openDraft$.complete();
  }
}
