import * as _ from 'lodash';
import { EMPTY, Observable, of, Subscription, take, timer } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import {
  CommentChatModel,
  CommentDraftModel,
  CommentMailModel
} from '@dta/shared/models-api-loop/comment/comment.model';
import { DialogSendEmailCommentData, DialogSendEmailOptions } from '@dta/shared/models/dialog.model';
import { NotificationEventEmailSending, NotificationEventType } from '@dta/shared/models/notifications.model';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { User } from '@shared/api/api-loop/models/user';
import { filter, map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { CommentSenderService } from '../data/comment-sender/comment-sender.service';
import { CommentService } from '../data/comment/comment.service';
import { FileStorageService } from '../file-storage/file-storage.service';
import { NotificationsService } from '../notification/notification.service';
import { UserManagerService } from '../user-manager/user-manager.service';
import { UserflowService } from '../userflow/userflow.service';
import { DraftService } from '../data/draft/draft.service';
import { UserflowEventName } from '../userflow/userflow.constants';
import { CommandApiService } from '@shared/api/api-loop/services';
import { Logger } from '../logger/logger';
import { ContactBase, OnboardingCommand, TagType } from '@shared/api/api-loop/models';
import { UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { LogLevel } from '@dta/shared/models/logger.model';
import { AppStateService } from '../data/app-state/app-state.service';
import { ConnectionStatus } from '../communication/shared-subjects/shared-subjects-models';
import { isWebApp } from '../../../dta/shared/utils/common-utils';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { CardModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { SendDraftChatEvent } from '@shared/models/shared-draft/shared-draft.model';
import { DraftCommentCollectionService } from '@dta/ui/collections/drafts/draft-comment.collection';
import { ObserverResponse } from '@dta/ui/collections/collection-subscriber.service';

@AutoUnsubscribe()
@Injectable()
export class DialogHelperService implements OnDestroy {
  ///////////////////
  // State variables
  ///////////////////
  offline: boolean;

  /////////////////
  // Subscriptions
  /////////////////
  connectionStatusSub: Subscription;
  sendDraftChatSub: Subscription;
  sendEmailSub: Subscription;

  constructor(
    private _commentService: CommentService,
    private _commentSenderService: CommentSenderService,
    private _userManagerService: UserManagerService,
    private _fileStorageService: FileStorageService,
    private _notificationsService: NotificationsService,
    private _userflowService: UserflowService,
    private _trackingService: TrackingService,
    private _draftService: DraftService,
    private _commandApiService: CommandApiService,
    private _appStateService: AppStateService,
    private _draftCommentCollectionService: DraftCommentCollectionService
  ) {
    this.subscribeToConnectionStatus();
  }

  ngOnDestroy() {}

  get constructorName(): string {
    return 'DialogHelperService';
  }

  trackUserClick(forUserEmail: string, location: string, action: string) {
    this._trackingService.trackUserClick(forUserEmail, location, action);
  }

  sendEmail({ forUserEmail, commentData, sendAs, draftComment, inviteOrigin, groupId }: DialogSendEmailOptions) {
    if (!FileStorageService.validateTotalFilesSize(commentData.attachments)) {
      // Set notification that size is too large
      this._notificationsService.setInAppNotification(forUserEmail, {
        type: NotificationEventType.TooLargeAttachments
      });
      return false;
    }

    this.sendEmailSub?.unsubscribe();
    this.sendEmailSub = of(undefined)
      .pipe(
        /**
         * Build comment
         */
        mergeMap(() => {
          return this._commentService.buildCommentMail(
            forUserEmail,
            sendAs ? sendAs : this._userManagerService.getUserByEmail(forUserEmail),
            commentData.subject,
            commentData.to,
            commentData.cc,
            commentData.bcc,
            commentData.body,
            commentData.attachments,
            commentData.parent,
            commentData.history,
            commentData.signatureHtml,
            commentData.isForward,
            !sendAs || sendAs.email === this._userManagerService.getCurrentUserEmail(),
            commentData.createdFromDraftCommentId,
            commentData.shouldSplitOnSend
          );
        }),
        mergeMap((comment: CommentMailModel) => {
          comment.setExPassthroughData('sendAsGroupId', groupId);
          return this._commentSenderService.sendMail(forUserEmail, comment);
        }),
        /**
         * Show notification
         */
        tap((comment: CommentMailModel) => {
          this.trackSendAction(comment, commentData, inviteOrigin);
          this.setInAppNotification(forUserEmail, comment);
          this.triggerEventForUserFlow(sendAs);
          this.handleInvite(forUserEmail, commentData);
        })
      )
      .subscribe();
  }

  removeDraft(
    forUserEmail: string,
    draftComment: CommentDraftModel,
    conversationId: string,
    showNotification: boolean = true
  ) {
    if (showNotification) {
      this._notificationsService.setInAppNotification(forUserEmail, { type: NotificationEventType.DraftRemoved });
    }

    if (draftComment.parent) {
      this._draftService
        .discardDraft(forUserEmail, draftComment.parent?.id || draftComment.parent?.clientId, conversationId)
        .pipe(take(1))
        .subscribe();
    }
  }

  sendDraftChat(forUserEmail: string, event: SendDraftChatEvent, parentCard: CardModel) {
    this.sendDraftChatSub?.unsubscribe();
    this.sendDraftChatSub = of(undefined)
      .pipe(
        mergeMap(() => {
          return this._commentService.buildCommentsChats(forUserEmail, {
            author: this._userManagerService.getCurrentUser(),
            message: event.content,
            shareList: event.sharelist || [this._userManagerService.getCurrentUser()],
            parent: parentCard,
            tagTypes: event.isSystemMessage ? [TagType.SYSTEMMESSAGE] : undefined
          });
        }),
        mergeMap((_comments: CommentChatModel[]) => {
          return this._commentSenderService.sendDraftChat(forUserEmail, _comments);
        })
      )
      .subscribe();
  }

  removeSharedDraft(forUserEmail: string, draftCardId: string) {
    throw new Error('Method not implemented.');

    // <- todo
    // this._notificationsService.setInAppNotification(accountEmail, { type: NotificationEventType.DraftRemoved });

    // this._draftService.findSharedDraftCardOrEmpty(accountEmail, draftCardId)
    //     .pipe(
    //         mergeMap((draftCard: CardDraftModel) => {
    //             return this._draftService.discardDraft(accountEmail, draftCard.id);
    //         })
    //     )
    //     .subscribe();
  }

  private trackSendAction(comment: CommentMailModel, commentData: DialogSendEmailCommentData, inviteOrigin: string) {
    this._trackingService.trackNewMessage(
      this._userManagerService.getCurrentUserEmail(),
      comment,
      commentData.isDraft,
      false,
      commentData.isForward,
      false,
      inviteOrigin,
      !_.isEmpty(commentData.signatureHtml)
    );
  }

  private triggerEventForUserFlow(sendAs: User) {
    let isSendAs = !_.isEmpty(sendAs) && sendAs.email !== this._userManagerService.getCurrentUserEmail();
    this._userflowService.triggerEmailSentEvent(this._userManagerService.getCurrentUserEmail(), isSendAs);
  }

  private setInAppNotification(userEmail: string, comment: CommentMailModel) {
    if (isWebApp()) {
      return;
    }

    if (userEmail === this._userManagerService.getCurrentUserEmail()) {
      this._notificationsService.setInAppNotification(userEmail, {
        type: this.offline ? NotificationEventType.EmailToQueue : NotificationEventType.EmailSending,
        value: comment,
        forUserEmail: userEmail
      } as NotificationEventEmailSending);
    }
  }

  private handleInvite(forUserEmail: string, commentData: DialogSendEmailCommentData) {
    if (!commentData.isInvite) {
      return;
    }

    let invitees = [...commentData.to, ...commentData.cc, ...commentData.bcc];

    let command = this.getInviteCommand(invitees);

    // Trigger Userflow event
    this._userflowService.triggerEventWithNoAttributes(
      this._userManagerService.getCurrentUserEmail(),
      UserflowEventName.UserInvited
    );

    // Send invite command
    this._commandApiService
      .Command_RunCommand({ command: command }, forUserEmail)
      .pipe(
        retryWhen((handler: Observable<any>) => {
          return handler.pipe(
            mergeMap((err, retryCount) => {
              // retry when BE not available
              if ([0, 408, 429, 503, 504].includes(err.status)) {
                return timer(Math.pow(2, retryCount) * 1000);
              }

              return EMPTY;
            })
          );
        })
      )
      .subscribe();
  }

  private getInviteCommand(invitees: ContactBase[]): OnboardingCommand {
    // Filter out groups and log (if any)
    let users: User[] = _.filter(invitees, (invitee: ContactBase) => invitee.$type === UserModel.type);
    if (users.length < invitees.length) {
      Logger.customLog(this.constructorName + ':sendEmail Group on invite list', LogLevel.WARN);
    }

    let inviteCommandText = _.reduce(
      users,
      (text, invitee: User) => {
        return _.isEmpty(text) ? invitee.email : text + ',' + invitee.email;
      },
      ''
    );

    return {
      $type: 'OnboardingCommand',
      request: '/feinvite ' + inviteCommandText
    } as OnboardingCommand;
  }

  private subscribeToConnectionStatus() {
    this.connectionStatusSub?.unsubscribe();
    this.connectionStatusSub = this._appStateService.connectionStatus$
      .pipe(
        tap(({ connectionActive }: ConnectionStatus) => {
          this.offline = !connectionActive;
        })
      )
      .subscribe();
  }

  subscribeToDraftComment(forUserEmail: string, draft: CommentDraftModel): Observable<CommentDraftModel> {
    return this._draftCommentCollectionService
      .registerCollection({ offset: 0, size: 1, draftComment: draft }, undefined, forUserEmail)
      .pipe(
        mergeMap((collectionKey: string) => {
          return this._draftCommentCollectionService.registerObserver(collectionKey, forUserEmail);
        }),
        map((response: ObserverResponse<CommentDraftModel>) => {
          return response.models;
        }),
        filter((comments: CommentDraftModel[]) => !_.isEmpty(comments)),
        map(_.first)
      );
  }
}
