import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import { CardMailModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { BehaviorSubject, combineLatest, EMPTY, from, of, Subject, Subscription } from 'rxjs';
import { TagLabelModel } from '@dta/shared/models-api-loop/tag.model';
import { Logger } from '@shared/services/logger/logger';
import { catchError, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { AppStateService } from '@shared/services/data/app-state/app-state.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { CommandService } from '@shared/services/data/command/command.service';
import { UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { memoryLeakGuard } from '@dta/shared/utils/subscriptions/actionable-subscription';
import { ConversationActionService } from '@shared/services/data/conversation-action/conversation-action.service';
import { ActionEnum } from '@shared/api/api-loop/models/action-enum';
import { NavigationBarService } from '@shared/modules/main/navigation-bar/navigation-bar.service';
import { SharedTagFolder } from '@shared/api/api-loop/models/shared-tag-folder';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { SharedTagLabelModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { LabelService } from '@shared/services/data/label/label.service';
import { SharedTagBase } from '@shared/api/api-loop/models/shared-tag-base';
import { Router } from '@angular/router';
import { SpamApiService } from '@shared/api/api-loop/services/spam-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';

@AutoUnsubscribe()
@UntilDestroy()
@Injectable()
export class BatchSelectService implements OnDestroy {
  ////////////////////
  // State variables
  ////////////////////
  private markedConversations: _.Dictionary<ConversationModel> = {};
  private lastAddedConversationId: string = '';
  private offline: boolean = false;
  private sharedActionIds = {};

  /////////////
  // Subjects
  /////////////
  batchSelectionState$: BehaviorSubject<BatchSelectionState> = new BehaviorSubject({
    markedConversationsNumber: 0,
    action: BatchAction.CLEAR,
    cardId: '',
    countState: {},
    sharedLabels: []
  });
  rangeSelectId$: Subject<RangeSelection> = new Subject();

  //////////////////
  // Subscriptions
  //////////////////
  private appStatusSub: Subscription;
  private sendCommandSub: Subscription;

  constructor(
    private _appStateService: AppStateService,
    protected _commandService: CommandService,
    private _userManagerService: UserManagerService,
    private _trackingService: TrackingService,
    private _conversationActionService: ConversationActionService,
    private _labelService: LabelService,
    private _navigationBarService: NavigationBarService,
    private router: Router,
    private readonly spamApiService: SpamApiService
  ) {
    this.subscribeToConnectionStatus();
  }

  private get currentUserEmail(): string {
    return this._userManagerService.getCurrentUserEmail();
  }

  ngOnDestroy(): void {}

  addConversationToList(conversation: ConversationModel): void {
    this.lastAddedConversationId = conversation.id || conversation._id;
    this.markedConversations[conversation.id || conversation._id] = conversation;
    this.updateBatchSelectionState(BatchAction.ADD, conversation);
  }

  addConversationsToList(conversations: ConversationModel[]): void {
    conversations
      .filter(conversation => !this.markedConversations[conversation.id])
      .forEach(conversation => {
        this.addConversationToList(conversation);
      });
  }

  rangeSelectConversationId(id: string): void {
    this.rangeSelectId$.next({
      fromId: this.lastAddedConversationId,
      toId: id
    });
  }

  removeConversationFromList(conversation: ConversationModel): void {
    this.lastAddedConversationId = conversation.cardId || conversation.cardId;
    if (_.isEmpty(this.markedConversations[conversation.cardId || conversation._id])) {
      return;
    }
    delete this.markedConversations[conversation.cardId || conversation._id];
    this.updateBatchSelectionState(BatchAction.REMOVE, conversation);
  }

  cancelBatchSelect(): void {
    this.reset(true);
  }

  removeAllConversationsFromList(): void {
    this.reset();
  }

  updateBatchSelectionState(action: BatchAction, conversation: ConversationModel): void {
    let conversations = _.map(Object.keys(this.markedConversations), key => this.markedConversations[key]);

    if (conversations.length === 0) {
      this.reset();
      return;
    }

    const currentState = this.batchSelectionState$.getValue();
    const unreadCountState = conversation.isUnread ? (action === BatchAction.ADD ? 1 : -1) : 0;
    const readCountState = !conversation.isUnread ? (action === BatchAction.ADD ? 1 : -1) : 0;
    const starredCountState = conversation.isStarred ? (action === BatchAction.ADD ? 1 : -1) : 0;
    const unStarredCountState = !conversation.isStarred ? (action === BatchAction.ADD ? 1 : -1) : 0;
    this.batchSelectionState$.next({
      ...currentState,
      markedConversationsNumber: conversations.length,
      action: action,
      cardId: conversation.cardId,
      countState: {
        starredCount: (currentState.countState.starredCount || 0) + starredCountState,
        unStarredCount: (currentState.countState.unStarredCount || 0) + unStarredCountState,
        readCount: (currentState.countState.readCount || 0) + readCountState,
        unReadCount: (currentState.countState.unReadCount || 0) + unreadCountState
      },
      sharedLabels: [...currentState.sharedLabels, ...conversation.getSharedLabelTags()]
    });
  }

  getSelectedConversations(): ConversationModel[] {
    return _.map(Object.keys(this.markedConversations), key => {
      return this.markedConversations[key];
    });
  }

  batchLabelUpdate(label: SharedTagLabelModel | TagLabelModel, action: 'add' | 'remove'): void {
    let conversations = this.getSelectedConversations();
    let conversationIds = _.map(conversations, conversation => conversation.id || conversation._id);
    this._labelService
      .addOrRemoveLabelForCards(this.currentUserEmail, conversationIds, [label], action)
      .pipe(take(1))
      .subscribe();
  }

  batchTagUpdate(action: ActionEnum, folder?: SharedTagFolder) {
    let conversations = this.getSelectedConversations();
    let conversationIds = _.map(conversations, conversation => conversation.id || conversation._id);
    this._conversationActionService
      .conversationAction(this._userManagerService.getCurrentUserEmail(), {
        action: action,
        conversationIds: conversationIds,
        folder: folder,
        context: this._navigationBarService.getContext(),
        isSharedAction: !this.router.url.includes('myloopinbox')
      })
      .pipe(
        take(1),
        tap(() => {
          this.reset();
        })
      )
      .subscribe();
  }

  batchResolve(trackingLocation: string) {
    let conversations = this.getSelectedConversations();
    let conversationIds = _.map(conversations, c => c.id);
    this._trackingService.trackUserClick(this.currentUserEmail, trackingLocation, 'Batch Resolve');

    this.sendCommandSub?.unsubscribe();
    this.sendCommandSub = this._commandService
      .sendCommand(this.currentUserEmail, '/r', conversationIds, false)
      .pipe(
        catchError(err => {
          Logger.error(err, 'Slash batch command failed');
          return EMPTY;
        }),
        tap(() => {
          this.reset();
        })
      )
      .subscribe();
  }

  batchAssign(trackingLocation: string, user: UserModel) {
    let conversations = this.getSelectedConversations();
    this._trackingService.trackUserClick(this.currentUserEmail, trackingLocation, 'Batch Assign');

    of(undefined)
      .pipe(
        memoryLeakGuard(),
        mergeMap(() => from(conversations)),
        mergeMap((conversation: ConversationModel) => {
          let isMailCard = conversation.cardType === CardMailModel.type;
          let command = `/assign [user id="${user.id}"]${user.name}[/user]` + (isMailCard ? ' create loopin' : '');

          return this._commandService.sendCommand(this.currentUserEmail, command, [conversation.id], isMailCard);
        }),
        toArray()
      )
      .subscribe();

    // Reset and close
    this.reset();
  }

  markAsUnSpam(): void {
    let conversations = this.getSelectedConversations();
    combineLatest(
      conversations.map(conversation => {
        return this.spamApiService
          .Spam_UnSpamMessage(
            {
              messageId: conversation.id
            },
            this.currentUserEmail
          )
          .pipe(
            tap(() => {
              conversation.markAsNotSpam();
              PublisherService.publishEvent(this.currentUserEmail, [conversation]);
            })
          );
      })
    )
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.reset();
      });
  }

  ////////////////////
  // Private helpers
  ////////////////////
  private clearmarkedConversations(): void {
    this.markedConversations = {};
    this.lastAddedConversationId = '';
  }

  private reset(cancel?: boolean) {
    this.clearmarkedConversations();
    this.batchSelectionState$.next({
      markedConversationsNumber: 0,
      action: cancel ? BatchAction.CANCEL : BatchAction.CLEAR,
      cardId: '',
      countState: {
        unReadCount: 0,
        readCount: 0,
        unStarredCount: 0,
        starredCount: 0
      },
      sharedLabels: []
    });
  }

  private subscribeToConnectionStatus() {
    this.appStatusSub?.unsubscribe();
    this.appStatusSub = this._appStateService.connectionStatus$
      .pipe(
        tap(data => {
          this.offline = !data.connectionActive;
        })
      )
      .subscribe();
  }
}

///////////////
// Interfaces
///////////////
export interface BatchSelectionState {
  markedConversationsNumber: number;
  action: BatchAction;
  cardId: string;
  countState: CountState;
  sharedLabels: SharedTagBase[];
}

export interface CountState {
  starredCount?: number;
  unStarredCount?: number;
  readCount?: number;
  unReadCount?: number;
}

export interface RangeSelection {
  fromId: string;
  toId: string;
}

export enum BatchAction {
  ADD = 'add',
  REMOVE = 'remove',
  CLEAR = 'clear',
  CANCEL = 'cancel'
}
