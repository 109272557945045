import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversationTagModule } from '@shared/ui/conversation-tag/conversation-tag.module';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { CustomDropdownModule } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.module';
import { LoopTooltipModule } from '@shared/ui/tooltip/tooltip.module';
import { ClassificationTagComponent } from './classification-tag.component';

@NgModule({
  declarations: [ClassificationTagComponent],
  imports: [CommonModule, ConversationTagModule, GraphicsModule, CustomDropdownModule, LoopTooltipModule],
  exports: [ClassificationTagComponent]
})
export class ClassificationTagModule {}
