import { User } from '@shared/api/api-loop/models/user';
import { CommentMailModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CommentBody } from '@shared/api/api-loop/models/comment-body';

export function buildCommentBody(forUserEmail: string, author: User, body: string = '') {
  let comment = new CommentMailModel();

  comment.created = new Date().toISOString();
  comment.author = author;
  comment.name = '';
  comment.to = BaseModel.createListOfResources([]);
  comment.cc = BaseModel.createListOfResources([]);
  comment.bcc = BaseModel.createListOfResources([]);
  comment.attachments = BaseModel.createListOfResources([]);
  comment.body = {
    $type: 'CommentBody',
    content: body,
  };
  return comment;
}
