import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CheckboxControlComponent } from '@shared/modules/form-controls/checkbox-control/checkbox-control.component';
import { FormControlWrapperModule } from '@shared/modules/form-controls/base/form-control-wrapper/form-control-wrapper.module';

@NgModule({
  declarations: [CheckboxControlComponent],
  imports: [CommonModule, FormsModule, FormControlWrapperModule],
  exports: [CheckboxControlComponent]
})
export class CheckboxControlModule {}
