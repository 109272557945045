import { ChangeDetectionStrategy, Component, inject, Injector, Input, OnDestroy } from '@angular/core';
import { CustomDropdownComponentConfiguration } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.directive';
import { AssignDropdownComponent } from '@shared/modules/conversations/components/conversation-header/dropdowns/assign-dropdown/assign-dropdown.component';
import { SharedTagAssignee } from '@shared/api/api-loop/models/shared-tag-assignee';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { ContactApiService } from '@shared/api/api-loop/services/contact-api.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { distinctUntilChanged, map, publishReplay, refCount, take } from 'rxjs/operators';
import { SharedTagAssigneeModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

@Component({
  selector: 'loop-assignee-tag',
  templateUrl: './assignee-tag.component.html',
  styleUrls: ['./assignee-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssigneeTagComponent implements OnDestroy {
  private readonly injector: Injector = inject(Injector);
  private readonly contactApiService: ContactApiService = inject(ContactApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);

  protected readonly conversation$: ReplaySubject<ConversationModel> = new ReplaySubject<ConversationModel>(1);
  @Input() set conversation(conversation: ConversationModel) {
    this.conversation$.next(conversation);
  }

  protected readonly isResolved$: Observable<boolean> = this.conversation$.pipe(
    map(conversation => conversation.isResolved()),
    publishReplay(1),
    refCount()
  );

  protected readonly resolveUnassignColor$: Observable<string> = this.isResolved$.pipe(
    map(isResolved => {
      if (isResolved) {
        return '#5ABA62';
      }

      return getComputedStyle(document.documentElement).getPropertyValue('--medium-ui-element').trim();
    })
  );

  protected readonly assigneeTagData$: Observable<{
    tag: SharedTagAssignee | undefined;
    contact: ContactBase | undefined;
  }> = this.conversation$.pipe(
    map(conversation => {
      return conversation.getSharedTags().find(tag => {
        return tag.$type === SharedTagAssigneeModel.type;
      }) as SharedTagAssigneeModel | undefined;
    }),
    distinctUntilChanged((prevTag, currTag) => {
      return prevTag?.userId === currTag?.userId;
    }),
    switchMap(sharedTagAssignee => {
      if (!sharedTagAssignee) {
        return of({
          tag: undefined,
          contact: undefined
        });
      }

      return this.contactApiService
        .Contact_GetList(
          {
            contactIds: [sharedTagAssignee.userId],
            size: 1
          },
          this.userManagerService.getCurrentUserEmail()
        )
        .pipe(
          take(1),
          map(contactBase => {
            return {
              tag: sharedTagAssignee,
              contact: contactBase.resources?.[0]
            };
          })
        );
    })
  );

  protected readonly dropdownSvg: string = require('shared/assets/img/svg/dropdown.svg');

  protected readonly assignDropdownComponentConfiguration: CustomDropdownComponentConfiguration<any> = {
    component: {
      component: AssignDropdownComponent,
      parentInjector: this.injector
    }
  };

  ngOnDestroy(): void {
    this.conversation$.complete();
  }
}
