import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';

@Injectable()
export class TrackingServiceWeb extends TrackingService {
  constructor(protected _sharedUserManagerService: SharedUserManagerService) {
    super(_sharedUserManagerService);

    this.setAppVersion();
  }

  protected setAppVersion() {
    this.appVersion = `_${BUILD_TIMESTAMP?.split('.')[0]?.replace('T', '_')}_${COMMIT}_`;
  }
}
