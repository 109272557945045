import { ElementRef, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface DialogContent {
  contentElementRef: ElementRef;
  close: () => void;
  setTitle: (newTitle: string) => void;
  setWindowIdentifier?: (identifier: string) => void;
  isNewWindow?: boolean;
}

export const DIALOG_CONTENT = new InjectionToken<DialogContent>('MODAL_CONTENT_ELEMENT_REF');
