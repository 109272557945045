export enum DateFormat {
  systemDate = 'systemDate',
  systemTime = 'systemTime',
  systemDateTime = 'systemDateTime',
  fancyDate = 'fancyDate',
  fancyTime = 'fancyTime',
  fancyDateTime = 'fancyDateTime',
  wiseDateTime = 'wiseDateTime',
  shortWiseDateTime = 'shortWiseDateTime',
  reducedDate = 'reducedDate',
  dayName = 'dayName',
  dayNameShort = 'dayNameShort',
  year = 'year',
  relativeTodayOffsetShort = 'relativeTodayOffsetShort',
  relativeNowOffsetShort = 'relativeNowOffsetShort',
  yearWeek = 'yearWeek'
}
