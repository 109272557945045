import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClassificationTreeComponent } from '@shared/modules/shared-tag/components/classification-tree/classification-tree.component';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { LoaderModule } from '@dta/ui/components/common/loader/loader.module';

@NgModule({
  declarations: [ClassificationTreeComponent],
  imports: [CommonModule, IconModule, LoaderModule, ReactiveFormsModule, TextControlModule],
  exports: [ClassificationTreeComponent]
})
export class ClassificationTreeModule {}
