import { Pipe, PipeTransform } from '@angular/core';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { getFileExtension } from '@shared/utils/get-file-extension';
import { videoExtensions } from '@shared/modules/files/common/constants/video-extensions';
import { FileModel } from '@dta/shared/models-api-loop/file.model';

@Pipe({
  name: 'isVideo',
})
export class IsVideoPipe implements PipeTransform {
  transform(file: FileModel | IFile): boolean {
    return videoExtensions.includes(getFileExtension(file.name).toLowerCase());
  }
}
