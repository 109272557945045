import { Injectable } from '@angular/core';
import { BaseModel } from '../../../shared/models-api-loop/base/base.model';
import { from, Observable, of } from 'rxjs';
import { FetchResult, UserAvailabilityCollectionParam } from '../../../shared/models/collection.model';
import { CollectionOptions, CollectionSubscriberService, LoadMoreType } from '../collection-subscriber.service';
import { BaseCollectionService } from '../base.collection';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { filter, map, toArray } from 'rxjs/operators';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { UserAvailabilityStatusService } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service';

@Injectable()
export class UserAvailabilityStatusCollectionService extends BaseCollectionService<UserAvailabilityStatusModel> {
  protected supportedTypes: (typeof BaseModel)[] = [UserAvailabilityStatusModel];
  readonly loadMoreType: LoadMoreType = LoadMoreType.ONLY_QUERY;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _collectionSubscriberService: CollectionSubscriberService,
    protected _userAvailabilityStatusService: UserAvailabilityStatusService,
    protected _contactService: ContactService,
  ) {
    super(_userManagerService, _collectionSubscriberService);
  }

  get constructorName(): string {
    return 'UserAvailabilityStatusCollectionService';
  }

  registerCollection(
    params: UserAvailabilityCollectionParam,
    options?: CollectionOptions,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<string> {
    return super.registerCollection(params, options, forUserEmail);
  }

  protected reduce(
    models: UserAvailabilityStatusModel[],
    params: UserAvailabilityCollectionParam,
    forUserEmail: string = this.currentUserEmail,
  ): Observable<UserAvailabilityStatusModel[]> {
    return from(models).pipe(
      filter((userStatus: UserAvailabilityStatusModel) => params.userIds.includes(userStatus.userId)),
      toArray(),
    );
  }

  protected doBeforePublish(
    models: UserAvailabilityStatusModel[],
    params: UserAvailabilityCollectionParam,
    forUserEmail: string,
  ): Observable<UserAvailabilityStatusModel[]> {
    return of(models);
  }

  protected doBeforeReduce(
    models: UserAvailabilityStatusModel[],
    params: UserAvailabilityCollectionParam,
    forUserEmail: string,
  ): Observable<UserAvailabilityStatusModel[]> {
    return of(models);
  }

  count(params: UserAvailabilityCollectionParam, forUserEmail: string): Observable<number> {
    throw new Error('Method not implemented.');
  }

  query(params: UserAvailabilityCollectionParam, forUserEmail: string): Observable<UserAvailabilityStatusModel[]> {
    return this._userAvailabilityStatusService.findAllUserAvailabilityStatuses(forUserEmail);
  }

  fetch(params: UserAvailabilityCollectionParam, forUserEmail: string): Observable<FetchResult> {
    return this._contactService.fetchWorkspaceContacts(forUserEmail).pipe(
      map((userStatuses: UserAvailabilityStatusModel[]) => {
        let fetchResult: FetchResult = {
          offsetHistoryId: undefined,
          dataLength: userStatuses?.length || 0,
          hasData: !!userStatuses,
        };

        return fetchResult;
      }),
    );
  }
}
