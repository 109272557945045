import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { SyncSettingsType } from '@dta/shared/models/sync-settings-type.model';
import { AuthCode, AuthExchange, AuthImapSmtp, AuthRemoteImap } from '@shared/api/api-loop/models';
import { User } from '@shared/api/api-loop/models/user';
import { OAuthService } from '@shared/services/oauth/oauth.service';
import { Observable } from 'rxjs';
import { ExchangeAuthData, ImapAuthData } from '../auth/auth.service.interface';
import { SetupType } from '@shared/services/data/channel-inbox-setup/channel-setup.service';

export const microsoftExtendedPrefixWithSeparator = `microsoft-extended::`;
export const personalInboxStateOriginPrefix = 'personal-inbox-setup';
export const sharedInboxSetupStateOriginPrefix = 'shared-inbox-setup';
export const personalSharedInboxStateOriginPrefix = 'personal_shared-inbox-setup';
export type SetupData = SharedInboxSetupData | PersonalSharedInboxSetupData | PersonalInboxSetupData;
export type PermissionDeniedReason = 'permissionDenied' | 'emailSameAsLoggedInUser' | 'unknown';

export interface ChannelSetupServiceI {
  /////////////////
  // State methods
  /////////////////
  getSetupDataByState(state: string): { data: SetupData; forUserEmail: string };

  getSetupData(forUserEmail: string): SetupData;

  persistSetupData(forUserEmail: string, data: SetupData): void;

  /////////////
  // Create SI
  /////////////
  authenticateInbox(
    forUserEmail: string,
    provider: InboxProvider,
    setupType: SetupType,
    extraData?: AuthenticateSharedInboxExtraData,
  ): Observable<any>;

  createInbox(forUserEmail: string): Observable<ContactModel>;

  createTeam(forUserEmail: string, data: TeamSetupData): Observable<ContactModel>;

  //////////
  // Other
  //////////
  handleSharedInboxOauthDeeplink(
    state: string,
    code: string,
    oAuthRefreshToken: string,
    oAuthEmail: string,
    setupType: SetupType,
    permissionDenied?: boolean,
  ): Observable<any>;
}

export interface AuthenticateSharedInboxExtraData {
  // Authorization data for non-oauth accounts
  exchangeAuthData?: ExchangeAuthData;
  imapAuthData?: ImapAuthData;

  // When connecting alias gmail account, alias email must be provided
  gmailAlias?: string;

  // When connecting microsoft shared inbox account, shared inbox email must be provided
  microsoftSharedInboxEmail?: string;
}

abstract class InboxSetupData {
  protected abstract prefix: string;

  state: string;

  // General inbox meta data
  provider: InboxProvider;
  syncSettingsType: SyncSettingsType | string;

  // oAuth meta data
  oAuthRefreshToken: string;
  oAuthEmail: string;
  microsoftSharedInboxEmail?: string;

  inboxAuth: AuthCode | AuthExchange | AuthRemoteImap | AuthImapSmtp;

  protected getNewState(): string {
    return `${this.prefix}::${OAuthService.getNewOauthState()}`;
  }
}

export class PersonalInboxSetupData extends InboxSetupData {
  protected prefix = personalInboxStateOriginPrefix;

  constructor() {
    super();
    this.state = this.getNewState();
  }
}

export class SharedInboxSetupData extends InboxSetupData {
  protected prefix = sharedInboxSetupStateOriginPrefix;

  state: string;

  // Must be set from UI component
  name: string;
  description?: string;
  members: User[] = [];

  provider: InboxProvider;
  syncSettingsType: SyncSettingsType | string;

  // Non-oAuth meta data
  aliasEmail?: string;

  constructor() {
    super();
    this.state = this.getNewState();
  }
}

export class PersonalSharedInboxSetupData extends SharedInboxSetupData {
  protected prefix = personalSharedInboxStateOriginPrefix;

  constructor() {
    super();
    this.state = this.getNewState();
  }
}

export class TeamSetupData {
  name: string;
  description?: string;
  members: User[] = [];
}

export enum InboxProvider {
  GOOGLE_OAUTH,
  GOOGLE_ALIAS,
  IMAP,
  MICROSOFT_OAUTH,
  MICROSOFT_EXCHANGE,
  MICROSOFT_SHARED_INBOX,
}
