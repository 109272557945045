export enum LogLevel {
  TRACE = 0, // [!] THIS WILL NOT BE VISIBLE IN KIBANA
  INFO = 1,
  LOG = 2,
  WARN = 3,
  ERROR = 4,
  EXCEPTION = 5,
}

export enum ElectronProcessType {
  MAIN = 'main',
  RENDERER_UI = 'renderer_ui',
  RENDERER_BE = 'renderer_be',
}
export enum LogSyncType {
  PAST_SYNC_PAGE = 'PastSyncPage',
  SIGNATURE_SYNC = 'SignatureSync',
  TEMPLATE_SYNC = 'TemplateSync',
  FOLDER_SYNC = 'FolderSync',
  LABEL_SYNC = 'LabelSync',
}

export enum LogTag {
  MENU_ACTION = 'menu-action', // Click on menu action
  PERFORMANCE = 'performance', // Logs regarding performance
  POWER_STATE_CHANGE = 'power-state-change', // Logs for power state change
  HANDLED_ERROR = 'handlederror', // Handled error log (error popup)
  ERROR = 'error', // General error log
  WARN = 'warn', // General warn log
  COUNTERS = 'counters', // Counters log
  TIMING = 'timing', // Logs for timings (time spend on view)
  NEW_INSTALLATION = 'newinstallation', // Marks new installation of app
  STARTUP = 'startup', // Marks startup of the app
  SHORTCUT = 'shortcut', // User shortcut (based on key pattern, not all supported)
  LOAD_MORE = 'loadmore', // Logs every time load more is made (offline or online)
  INTERESTING_ERROR = 'interesting-error', // Error we need to keep an eye on, because we are catching something that should be fixed
  USER_ACTION = 'user-action', // User triggered event (like resize, hide, show)
  KEYBOARD_ACTION = 'keyboard-action', // App action triggered by keyboard (not shortcut)
  AUTH_AND_LOGIN = 'auth-and-login', // Events regarding auth and login/logout for users
  IPC = 'ipc', // Inter process communication (for app/system)
  SYNC = 'sync', // All logs related to sync (any kind of sync)
  DB = 'db', // All logs related to database
  RENDERER_CRASHED = 'renderer-crash', // Will mark crashes in renderer process
  PURGE = 'purge', // All events related to purge
  PURGE_STATISTICS = 'purge-statistics', // Logs that contain statistics for purge
  DURATION = 'duration', // Logs for duration. Starts with start and logs at end
  XSS_CLEANUP = 'xss-cleanup', // Logs from xss cleanup process
  DRAFT = 'draft', // Logs related to drats
  SHARED_INBOX_SETUP = 'shared-inbox-setup', // Logs related to in-app shared inbox setup
  PRIVATE_INBOX_SETUP = 'private-inbox-setup', // Logs related to in-app private setup
}
