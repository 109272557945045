import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversationFoldersComponent } from '@shared/modules/conversations/components/conversation-header/conversation-folders/conversation-folders.component';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';
import { FolderPipeModule } from '@dta/ui/pipes/folder/folder-pipe.module';

@NgModule({
  declarations: [ConversationFoldersComponent],
  imports: [CommonModule, TooltipModule, FolderPipeModule],
  exports: [ConversationFoldersComponent],
})
export class ConversationFoldersModule {}
