import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartWithPipe } from '@shared/ui/pipes/start-with-pipe/start-with.pipe';

@NgModule({
  declarations: [StartWithPipe],
  imports: [CommonModule],
  exports: [StartWithPipe],
})
export class StartWithPipeModule {}
