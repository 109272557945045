import { Directive, HostListener, Input } from '@angular/core';
import { ElectronService } from '@shared/services/electron/electron';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { IPC } from '@dta/shared/communication/ipc-constants';
import { FileViewDecorateService } from '@shared/decorators/view-data-decorators/file-view-decorator/file-view-decorate.service';
import { File as IFile } from '@shared/api/api-loop/models/file';

@Directive({
  selector: '[fileDragDrop]'
})
export class FileDragDropDirective {
  constructor(private _electronService: ElectronService) {}

  @Input('fileDragDrop') file: FileModel | IFile;

  @HostListener('dragstart', ['$event'])
  onDragStart($event) {
    $event.preventDefault();
    const category = FileViewDecorateService.getCategoryFromExtension(this.file);
    const dragIcon = 'shared/assets/img/file-icons/icon-' + category + '.png';
    this._electronService.ipcRenderer.send(IPC.ON_DRAG_START, {
      hash: this.file.hash,
      fileUri: this.file.urlLink,
      fileName: this.file.name,
      dragIcon: dragIcon
    });
  }
}
