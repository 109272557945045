import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatchSelectComponent } from '@dta/ui/components/batch-select/batch-select.component';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';

@NgModule({
  declarations: [BatchSelectComponent],
  imports: [CommonModule, GraphicsModule],
  exports: [BatchSelectComponent],
})
export class BatchSelectModule {}
