<div class="white-right">
  <div class="flex-container">
    <div class="settings-wrapper">
      <div class="settings-container">
        <div class="title">{{ viewCopy.title }}</div>

        <notification-card
          [title]="notificationCopy[route].title"
          [content]="notificationCopy[route].content"
          [ctaUrl]="notificationCopy[route].ctaUrl"
          [ctaCopy]="notificationCopy[route].ctaCopy"
        >
        </notification-card>

        <div class="action-bar">
          <simple-input
            *ngIf="channels?.length > 0"
            [placeholder]="viewCopy.searchPlaceholder"
            [search]="true"
            [autoFocus]="true"
            [(value)]="query"
            [customClasses]="'search-small'"
          >
          </simple-input>

          <button
            class="btn button primary-green"
            [class.disabled]="offline"
            [tooltip]="{content: offline ? viewCopy.createButtonTooltip : undefined, placement: TooltipPlacement.BOTTOM}"
            [routerLink]="viewCopy.linkToCreate"
          >
            {{ viewCopy.createButtonCopy }}
          </button>
        </div>

        <div *ngIf="channels?.length > 0" class="sub-title">{{ viewCopy.listTitle }}</div>

        <div class="loop-users-list" *ngIf="!showLoader">
          <ng-template ngFor [ngForOf]="channels | searchFilter: query:['name', 'description']" let-channel>
            <div class="loop-user-item loop-user-item-clickable" (click)="openWebSettings(channel)">
              <avatar-image [contact]="channel" [extraClass]="'email-avatar'"></avatar-image>

              <div class="name-description">
                <div class="name">{{ channel.name }}</div>
                <div class="description">{{ channel.description }}</div>
              </div>
            </div>
          </ng-template>
        </div>

        <!-- Loader -->
        <loader *ngIf="showLoader" [text]="viewCopy.loadingCopy" id="canned-responses-loader"></loader>
      </div>
    </div>
  </div>
</div>
