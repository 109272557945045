import * as _ from 'lodash';
import * as moment from 'moment';
import {
  CardDraft,
  ContactBase,
  Group,
  ListOfResourcesOfContactBase,
  ListOfResourcesOfTag,
  ListOfTags,
  ResourceBase,
  SharedTagBase,
  SharedTagClassification,
  SharedTagLabel,
  Tag,
  TagLabel,
  TagType
} from '@shared/api/api-loop/models';
import { BaseModel } from '../base/base.model';
import { CardModel, SubscriptionState } from './card/card.model';
import { ConversationModel } from './conversation/conversation.model';
import { ListOfTagsModel, TagLabelModel, TimedTagModel } from '../tag.model';
import {
  SharedTagAssignedByModel,
  SharedTagAssigneeModel,
  SharedTagClassificationModel,
  SharedTagLabelModel,
  SharedTagModel,
  StaticSharedTagIds
} from '../shared-tag/shared-tag.model';
import { GroupModel } from '../contact/contact.model';

export type ConversationCardModel = CardModel | ConversationModel;

export abstract class ConversationCardBaseModel extends BaseModel {
  // Tags and shared-tags
  sharedTags?: ListOfTags;
  tags?: ListOfTags;

  // Users
  shareList?: ListOfResourcesOfContactBase;

  /////////
  // TAGS
  /////////
  hasTags(): boolean {
    return this.tags && this.tags.tags && !_.isEmpty(this.tags.tags.resources);
  }

  hasTagId(id: string): boolean {
    if (!this.hasTags()) {
      return false;
    }

    return _.some(this.tags.tags.resources, { id: id });
  }

  getTags(): Tag[] {
    return this.hasTags() ? this.tags.tags.resources : [];
  }

  getLabelTags(): TagLabelModel[] {
    return this.getTags().filter(t => t.$type === TagLabelModel.type) as TagLabelModel[];
  }

  getClassificationTag(): SharedTagClassificationModel | undefined {
    return this.getSharedTags().find(
      t => t.$type === SharedTagClassificationModel.type
    ) as SharedTagClassificationModel;
  }

  populateWithTags(fullTags: Tag[]) {
    let populatedList = _.map(this.getTags(), (tag: SharedTagBase) => {
      return _.find(fullTags, fullTag => fullTag.id === tag.id) || tag;
    });

    if (!_.isEmpty(populatedList)) {
      this.tags.tags.resources = populatedList;
    }
  }

  ///////////
  // SNOOZE
  ///////////
  hasSnoozeDue(): boolean {
    return this.hasTagId(TagType.SNOOZE_DUE);
  }

  getSnoozeDueDate(): string {
    let snoozeTag = _.find(this.getTags(), (tag: Tag) => tag.id === TagType.SNOOZE) as TimedTagModel;
    return snoozeTag && snoozeTag.dueDate;
  }

  getSnoozeDisplayDate(): string {
    if (this.hasSnoozeSomeday()) {
      return 'Someday';
    }

    const date = this.getSnoozeDueDate();

    if (!date) {
      return undefined;
    }

    return moment(date).format('D MMM');
  }

  hasPendingSnooze(): boolean {
    return this.hasTagId(TagType.SNOOZE) && !this.hasSnoozeDue();
  }

  hasSnoozeSomeday(): boolean {
    if (!this.hasTags()) {
      return false;
    }

    return this.tags.tags.resources.some(tag => moment((<TimedTagModel>tag).dueDate).format('YYYY') === '9999');
  }

  calculateSubscriptionState(): SubscriptionState {
    return !this.isFollowed()
      ? SubscriptionState.UNSUBSCRIBED
      : !this.isMuted()
        ? SubscriptionState.SUBSCRIBED
        : SubscriptionState.MUTED;
  }

  isFollowed() {
    return (
      this.hasTagId(TagType.FOLLOW) ||
      (!this.hasSharedTagId(StaticSharedTagIds.UNFOLLOW_ID) && !this.hasTagId(TagType.UNFOLLOW))
    );
  }

  isMuted() {
    return this.isFollowed() && this.hasTagId(TagType.MUTED);
  }

  ///////////////
  // SHARED TAGS
  ///////////////
  addClassificationTag(tag: SharedTagClassification): void {
    this.removeClassificationTag();
    this.setSharedTags([...this.getSharedTags(), tag]);
  }

  removeClassificationTag(): void {
    this.setSharedTags(_.filter(this.getSharedTags(), tag => tag.$type !== SharedTagClassificationModel.type));
  }

  addOrRemoveLabel(
    labelTag: SharedTagLabelModel | TagLabel | SharedTagLabel | TagLabelModel,
    action: 'add' | 'remove' | 'toggle' = 'toggle'
  ) {
    if (_.isEmpty(labelTag)) {
      return;
    }

    // Set share labels
    if (labelTag.$type === SharedTagLabelModel.type) {
      this.setExclusiveSharedTags(labelTag, this.hasSharedTagId(labelTag.id), [], action);
    }

    if (labelTag.$type !== TagLabelModel.type) {
      return;
    }

    let canRemove = ['remove', 'toggle'].includes(action);
    let canAdd = ['add', 'toggle'].includes(action);

    // Set private labels
    if (this.hasTagId(labelTag.id) && canRemove) {
      this.setTags(_.filter(this.getTags(), tag => tag.id !== labelTag.id));
    } else if (!this.hasTagId(labelTag.id) && canAdd) {
      this.setTags([...this.getTags(), labelTag]);
    }
  }

  private getListOfTags(): ListOfTags {
    let listOfTags: ListOfResourcesOfTag = {
      resources: [],
      offset: 0,
      size: 0,
      totalSize: 0
    };

    return {
      $type: ListOfTagsModel.type,
      revision: '0',
      tags: listOfTags,
      parent: this.toResourceBase()
    };
  }

  setTags(newTags: Tag[]) {
    if (!this.tags) {
      this.tags = this.getListOfTags();
    }

    this.tags.parent = this.toResourceBase();
    this.tags.tags.resources = newTags;
  }

  setSharedTags(newTags: Tag[]) {
    if (!this.setSharedTags) {
      this.sharedTags = this.getListOfTags();
    }

    this.sharedTags.parent = this.toResourceBase();
    this.sharedTags.tags.resources = newTags;
  }

  setExclusiveSharedTags(
    sharedTag: SharedTagBase,
    shouldRemoveIt: boolean,
    exclusiveSharedTagsNames: string[],
    action: 'add' | 'remove' | 'toggle' = 'toggle'
  ) {
    let removedTags = 0;
    let addedTags = 0;

    if (shouldRemoveIt && this.sharedTags && this.sharedTags.tags) {
      if (['remove', 'toggle'].includes(action)) {
        // REMOVE
        removedTags = _.remove(this.sharedTags.tags.resources, (tag: SharedTagBase) => tag.id === sharedTag.id).length;
        this.sharedTags.tags.size -= removedTags;
        this.sharedTags.revision = (parseInt(this.sharedTags.revision, 10) + 1).toString();
      }
    } else {
      if (['add', 'toggle'].includes(action)) {
        // ADD
        let currentSharedTags = this.getSharedTags();
        if (!currentSharedTags || !this.sharedTags) {
          // Build sharedTags
          let parent: ResourceBase = this.toResourceBase();

          let resources: Tag[] = [];

          let tags: ListOfResourcesOfTag = {
            resources: resources,
            offset: 0,
            size: 0,
            totalSize: 0
          };

          let sharedTags: ListOfTags = {
            $type: ListOfTagsModel.type,
            revision: '0',
            tags: tags,
            parent: parent
          };

          this.sharedTags = sharedTags;
        }

        // Remove exclusive tags
        let isSharedTagExclusive = _.some(
          exclusiveSharedTagsNames,
          name => name === sharedTag.name || name === sharedTag.id
        );
        if (isSharedTagExclusive) {
          removedTags = _.remove(currentSharedTags, (tag: SharedTagBase) =>
            _.some(exclusiveSharedTagsNames, name => name === tag.name || name === tag.id)
          ).length;
        }

        // Add new shared tag
        currentSharedTags.push(sharedTag);
        addedTags = 1;

        // Update state
        this.sharedTags.tags.resources = currentSharedTags;
        this.sharedTags.tags.size += addedTags - removedTags;
        this.sharedTags.revision = (parseInt(this.sharedTags.revision, 10) + 1).toString();
      }
    }
  }

  populateWithSharedTags(fullSharedTags: SharedTagModel[]) {
    let populatedList = _.map(this.getSharedTags(), (sharedTag: SharedTagBase) => {
      return _.find(fullSharedTags, fullSharedTag => fullSharedTag.id === sharedTag.id) || sharedTag;
    });

    if (!_.isEmpty(populatedList)) {
      this.sharedTags.tags.resources = populatedList;
    }
  }

  getSharedTags(): SharedTagBase[] {
    if (!this.sharedTags || !this.sharedTags.tags) {
      return [];
    }

    return this.getResources(this.sharedTags.tags);
  }

  getSharedLabelTags(): SharedTagBase[] {
    if (!this.sharedTags || !this.sharedTags.tags) {
      return [];
    }

    return this.getResources(this.sharedTags.tags).filter(tag => tag.$type === SharedTagLabelModel.type);
  }

  hasSharedTags(): boolean {
    return this.sharedTags && this.sharedTags.tags && !_.isEmpty(this.sharedTags.tags.resources);
  }

  isUnresolved(): boolean {
    if (_.isEmpty(this.sharedTags)) {
      return false;
    }

    return !_.some(this.getSharedTags(), (sharedTag: SharedTagBase) => sharedTag.id === StaticSharedTagIds.RESOLVED_ID);
  }

  isResolved(): boolean {
    if (_.isEmpty(this.sharedTags)) {
      return false;
    }

    return _.some(this.getSharedTags(), (sharedTag: SharedTagBase) => sharedTag.id === StaticSharedTagIds.RESOLVED_ID);
  }

  isAssignedByOrToMe(userId: string): boolean {
    if (this.hasSharedTagAssigneeId(userId)) {
      return true;
    }

    if (this.hasSharedTagAssignedById(userId)) {
      return true;
    }

    return false;
  }

  hasSharedTagAssigneeId(userId: string): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, {
      $type: SharedTagAssigneeModel.type,
      userId: userId
    });
  }

  hasSharedTagAssignedById(userId: string): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, {
      $type: SharedTagAssignedByModel.type,
      userId: userId
    });
  }

  hasSharedTagId(id: string): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, { id: id });
  }

  //////////////
  // SHARE LIST
  //////////////
  hasGroupOnSharelist(): boolean {
    return this.getShareList().some(contact => contact.$type === GroupModel.type);
  }

  static isGroupCard(shareList: ContactBase[]): boolean {
    return !!this.findGroupInShareList(shareList);
  }

  static findGroupInShareList(shareList: ContactBase[]): Group {
    return shareList.find(contact => contact.$type === GroupModel.type);
  }

  getShareList(): ContactBase[] {
    return this.getResources(this.shareList);
  }

  abstract getAllContacts(): ContactBase[];

  abstract populateWithContacts(contacts: ContactBase[]): void;
}
