import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containsByProperty',
})
export class ContainsByPropertyPipe<T> implements PipeTransform {
  transform(values: T[] | T, item: any, property: string): boolean {
    if (!values) {
      return false;
    }

    if (!Array.isArray(values)) {
      values = [values];
    }

    return values.some(value => value[property] === item);
  }
}
