import { Injectable } from '@angular/core';
import { EMPTY, from, Observable, of, tap } from 'rxjs';
import { SendAsOption, SendAsType } from 'dta/shared/models/send-as.model';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { UserAlias } from '@shared/api/api-loop/models/user-alias';
import { ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import * as _ from 'lodash';

@Injectable()
export class SendAsService {
  constructor(
    private _userManagerService: UserManagerService,
    private _contactService: ContactService,
    private _settingsService: SettingsService,
  ) {}

  isUserAccountSyncable(forUserEmail: string): boolean {
    return this._userManagerService.isUserAccountSyncable(forUserEmail);
  }

  // If groupId is passed, this will return only allowed Impersonate User for this group
  getSendAsOptionsForUser(
    forUserEmail: string,
    forGroupId?: string,
    aliasesOnly?: boolean,
  ): Observable<SendAsOption[]> {
    let sendAsOptions: SendAsOption[] = [];

    return of(undefined).pipe(
      /**
       * Add personal sharedInboxes
       */
      mergeMap(() => {
        return this._contactService.getPersonalInboxesWithAllowedImpersonatedSenders(forUserEmail);
      }),
      mergeMap((personalInboxes: GroupModel[]) => {
        return from(personalInboxes).pipe(
          tap((personalInbox: GroupModel) => {
            this.getImpersonateUserOption(personalInbox).forEach(impersonateUser => {
              impersonateUser.personalInbox = true;
              impersonateUser.threadingMode = personalInbox.threadingMode;
              sendAsOptions.push(impersonateUser);
            });
          }),
          toArray(),
        );
      }),
      /**
       * Add shared inbox ImpersonateUser options
       */
      mergeMap(() => {
        return this._contactService.getSharedInboxesWithAllowedImpersonatedSenders(forUserEmail);
      }),
      mergeMap((sharedInboxes: GroupModel[]) => {
        if (!sharedInboxes || aliasesOnly) {
          return of(EMPTY);
        }

        return from(sharedInboxes).pipe(
          tap((sharedInbox: GroupModel) => {
            this.getImpersonateUserOption(sharedInbox).forEach(impersonateUser => {
              impersonateUser.personalInbox = true;
              impersonateUser.threadingMode = sharedInbox.threadingMode;
              sendAsOptions.push(impersonateUser);
            });
          }),
          toArray(),
        );
      }),
      /**
       * Return options
       */
      map(res => {
        return sendAsOptions;
      }),
    );
  }

  ////////////////////
  // PRIVATE HELPERS
  ////////////////////

  private getAliasOption(forUserEmail: string, alias: UserAlias): Observable<SendAsOption> {
    if (!alias.enableForSend) {
      return EMPTY;
    }

    return this._contactService.getContactById(forUserEmail, alias.alias.id).pipe(
      mergeMap((upToDateContact: ContactModel) => {
        if (!upToDateContact) {
          upToDateContact = <ContactModel>alias.alias;
        }

        let newOption: SendAsOption = {
          type: SendAsType.Alias,
          user: upToDateContact,
        };

        return of(newOption);
      }),
    );
  }

  private getImpersonateUserOption(sharedInbox: GroupModel): SendAsOption[] {
    return _.map(sharedInbox.allowedImpersonatedSenders?.resources, sender => {
      return {
        type: SendAsType.ImpersonateUser,
        groupId: sharedInbox.id,
        user: sender,
      };
    });
  }
}
