import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { AsPureArgumentForModule } from '@shared/ui/pipes/as-pure-argument-for/as-pure-argument-for.module';
import { ConversationChannelIconComponent } from './conversation-channel-icon.component';

@NgModule({
  declarations: [ConversationChannelIconComponent],
  imports: [CommonModule, IconModule, AsPureArgumentForModule],
  exports: [ConversationChannelIconComponent]
})
export class ConversationChannelIconModule {}
