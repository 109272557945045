import * as _ from 'lodash';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { CardBase } from '@shared/api/api-loop/models/card-base';
import { CommentBase } from '@shared/api/api-loop/models/comment-base';
import { ThumbnailInfo } from '@shared/api/api-loop/models/thumbnail-info';
import { User } from '@shared/api/api-loop/models/user';
import { BaseModel } from './base/base.model';
import { CollectionName } from '@shared/models/constants/collection.names';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';
import { ThumbnailPage } from '@shared/api/api-loop/models/thumbnail-page';
import { ListOfResourcesOfThumbnailPage } from '@shared/api/api-loop/models/list-of-resources-of-thumbnail-page';

export class FileModel extends BaseModel implements IFile {
  static collectionName: CollectionName = CollectionName.File;
  static type: string = 'File';

  readonly $type: string = FileModel.type;

  name: string;
  hash: string;
  size: number;
  hidden: boolean;
  thumbnailInfo: ThumbnailInfo;
  owner: User;
  parentComment?: CommentBase;
  parentCard?: CardBase;
  created: string;
  urlLink?: string;

  _ex?: FileExtraData;
  _ui?: FileViewData;

  constructor(file?: IFile) {
    super(file);
  }

  static createList(docs: IFile[]): FileModel[] {
    return _.map(docs, (doc: IFile) => new FileModel(doc));
  }

  static buildFromLocalFile(
    file: File,
    parentComment?: CommentBase,
    parentCard?: CardBase,
    hidden?: boolean,
  ): FileModel {
    if (!file) {
      throw new Error('Cannot build FileModel, one of the parameters is nil');
    }

    let model: FileModel = new FileModel();

    model.name = file.name;
    model.size = file.size;
    model.hidden = false;
    model.created = file.lastModified ? new Date(file.lastModified).toISOString() : new Date().toISOString();

    if (parentComment) {
      model.parentComment = parentComment;
      model.owner = parentComment.author;
    }
    if (parentCard) {
      model.parentCard = parentCard;
    }

    if (hidden) {
      model.hidden = hidden;
    }

    return model;
  }

  static buildAsReference<T extends ResourceBase>(file: FileModel): T {
    let ref: IFile = BaseModel.buildAsReference(file);
    ref.hidden = file.hidden || false;
    ref.urlLink = file.urlLink || undefined;

    return <any>ref;
  }

  isThumbnailSupported(): boolean {
    return this.thumbnailInfo && this.thumbnailInfo.supported;
  }

  isThumbnailReady(): boolean {
    return this.thumbnailInfo && this.thumbnailInfo.ready;
  }

  hasThumbnailFailed(): boolean {
    return this.thumbnailInfo && this.thumbnailInfo.failed;
  }

  isPdfPreviewSupported(): boolean {
    if (!this.isThumbnailSupported()) {
      return false;
    }

    let extension = this._ex && this._ex.extension;
    let pages = this.getThumbnailPages();
    let pdfPreviewSupported = _.some(pages, { hasPdf: true });

    return pdfPreviewSupported && extension !== 'pdf';
  }

  private getThumbnailPages(): ThumbnailPage[] {
    if (!this.thumbnailInfo || !this.thumbnailInfo.pages || _.isEmpty(this.thumbnailInfo.pages.resources)) {
      return [];
    }

    return this.thumbnailInfo.pages.resources;
  }

  hasPdfPreview(): boolean {
    return this.isThumbnailReady() && this.isPdfPreviewSupported();
  }

  hasThumbnailPages(): boolean {
    return this.thumbnailInfo && !_.isEmpty(this.thumbnailInfo.pages);
  }

  getFirstThumbnailPage(): ThumbnailPage {
    if (!this.hasThumbnailPages()) {
      return undefined;
    }

    let pages = <ListOfResourcesOfThumbnailPage>this.thumbnailInfo.pages;
    return _.first(pages.resources);
  }

  hasValidThumbnailDimensions(): boolean {
    let page = this.getFirstThumbnailPage();
    return page && (page.width > 0 || page.height > 0);
  }

  setDefaultThumbnailInfo() {
    let defaultThumbnailInfo: ThumbnailInfo = {
      failed: false,
      ready: false,
      supported: true,
    };
    this.thumbnailInfo = defaultThumbnailInfo;
  }
}

export interface FileExtraData {
  // Flags
  isSignature: boolean;
  isVideo: boolean;
  isPdf: boolean;
  isImage: boolean;

  extension: string;
  originalPath?: string;
  path?: string;
  channelIds?: string[];
  syncStatus: FileSynchronizationStatus;
}

export interface FileSynchronizationStatus {
  meta: boolean;
  binary: boolean;
  thumbnail: boolean;
  attempt: number;
  failed: boolean;
  preview: boolean;
}

export interface FileViewData {
  synchronized?: boolean;
  isSyncing?: boolean;
  thumbnailPath: string;
  iconPath?: string;
  previewPath?: string;
  category?: string;
  isPdf?: boolean;
  hasBinary?: boolean;
  hasThumbnail?: boolean;
  hasPreview?: boolean;
  hasNativePreview?: boolean;
  thumbnailDimensions?: ImageDimensions;
  base64data?: string;
  hideFooter?: boolean;
}

export class ImageDimensions {
  width?: number;
  height?: number;
}

export type LoadingAttachment = {
  id?: string;
  name: string;
  progress: number;
  size?: number;
};

export const isLoadingAttachment = (item: LoadingAttachment | FileModel | IFile): item is LoadingAttachment =>
  typeof (item as LoadingAttachment).progress === 'number';
