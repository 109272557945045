import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  Type,
  ViewEncapsulation
} from '@angular/core';
import { combineLatest, from, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { CustomButton, isNewWindowModalFormOptions, ModalFormOptions } from '../modal-form-options';
import { ModalFormContentComponentData } from './modal-form-content-component-data';
import { BaseFormComponent } from '@shared/forms/base-form.component';
import {
  AfterDialogOpen,
  BeforeDialogClose,
  isAfterDialogOpen,
  isBeforeDialogClose
} from '@shared/ui/kit/dialog/after-dialog-open';
import { LOOP_DYNAMIC_COMPONENTS } from '@shared/modules/loop/components/loop-dynamic-components';

@UntilDestroy()
@Component({
  templateUrl: './modal-form-content.component.html',
  styleUrls: ['./modal-form-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: ModalFormContentComponentData,
      useExisting: forwardRef(() => ModalFormContentComponent)
    }
  ]
})
export class ModalFormContentComponent<T, C extends BaseFormComponent<T> & { setDefaults?(value: T): void }, R>
  implements ModalFormContentComponentData, OnDestroy, AfterDialogOpen, BeforeDialogClose
{
  protected readonly options$: ReplaySubject<ModalFormOptions<T, C, R>> = new ReplaySubject<ModalFormOptions<T, C, R>>(
    1
  );

  @Input() set options(options: ModalFormOptions<T, C, R>) {
    this.options$.next(options);
  }

  @Input() closeForm!: () => void;

  @Input() isLoading$: Observable<boolean> = of(false);

  private componentRef$: ReplaySubject<ComponentRef<C>> = new ReplaySubject<ComponentRef<C>>(1);

  protected readonly componentData$: Observable<{
    component: Type<T>;
    module: Type<any>;
    componentInjector?: Injector;
    componentParams?: Partial<C>;
  }> = this.options$.pipe(
    switchMap((options: ModalFormOptions<T, C, R>) => {
      if (!isNewWindowModalFormOptions(options)) {
        return of(null);
      }

      const component = LOOP_DYNAMIC_COMPONENTS[options.componentId];
      if (!component.component) {
        return of(null);
      }

      return from(component.component()).pipe(
        map(resolvedComponent => {
          return {
            component: resolvedComponent.component,
            module: resolvedComponent.module,
            componentParams: options.componentParams
          };
        })
      );
    })
  );

  readonly formSubmit$: Observable<T> = this.componentRef$.pipe(
    take(1),
    switchMap(c => c.instance.formSubmit),
    tap(a => console.log('FORM SUBMITS', a))
  );

  constructor() {
    this.observeDefaultValuesChanges();
  }

  setComponentRef(componentRef: ComponentRef<C>): void {
    this.componentRef$.next(componentRef);
  }

  submitForm(): void {
    this.componentRef$.pipe(take(1), untilDestroyed(this)).subscribe(comp => comp.instance.submitForm());
  }

  afterDialogOpen(): void {
    this.componentRef$.pipe(take(1), untilDestroyed(this)).subscribe(comp => {
      const componentInstance = comp.instance;
      if (isAfterDialogOpen(componentInstance)) {
        componentInstance.afterDialogOpen();
      }
    });
  }

  beforeDialogClose$(): Observable<boolean> {
    return this.componentRef$.pipe(
      switchMap(componentRef => {
        if (!isBeforeDialogClose(componentRef.instance)) {
          return of(true);
        }

        return componentRef.instance.beforeDialogClose$();
      })
    );
  }

  observeDefaultValuesChanges(): void {
    const componentInstanceWithSetDefaults$: Observable<BaseFormComponent<T> & { setDefaults(value: T): void }> =
      this.componentRef$.pipe(
        take(1),
        map(
          (dynamicComponentRef): BaseFormComponent<T> & { setDefaults?(value: T): void } => dynamicComponentRef.instance
        ),
        filter(
          (componentInstance): componentInstance is BaseFormComponent<T> & { setDefaults(value: T): void } =>
            !!componentInstance.setDefaults
        )
      );

    const defaults$: Observable<T> = this.options$.pipe(
      map(options => {
        if (options.defaults$) {
          return options.defaults$;
        }

        if (options.defaults !== undefined && options.defaults !== null) {
          return of(options.defaults);
        }

        return undefined;
      }),
      filter((defaults$): defaults$ is Observable<T> => defaults$ !== undefined),
      switchMap(defaults$ => defaults$)
    );

    combineLatest([componentInstanceWithSetDefaults$, defaults$, this.componentRef$])
      .pipe(debounceTime(0), untilDestroyed(this))
      .subscribe(([componentInstanceWithSetDefaults, defaults, componentRef]) => {
        componentInstanceWithSetDefaults.setDefaults(defaults);
        componentRef.injector.get(ChangeDetectorRef)?.detectChanges();
      });
  }

  handleButtonClick(button: CustomButton): void {
    if (button.type === 'action') {
      button.action();
    } else if (button.type === 'submit') {
      this.submitForm();
    } else {
      this.closeForm();
    }
  }

  ngOnDestroy(): void {
    this.options$.complete();
    this.componentRef$.complete();
  }
}
