import * as _ from 'lodash';
import { BaseModel } from './base/base.model';
import { CollectionName } from '@shared/models/constants/collection.names';
import { AvailabilityStatus } from '@shared/api/api-loop/models/availability-status';
import { AvailabilityClearAfterEnum } from '@shared/api/api-loop/models/availability-clear-after-enum';
import { AvailabilityUserStatus } from '@shared/api/api-loop/models/availability-user-status';

export class AvailabilityStatusModel extends BaseModel implements AvailabilityStatus {
  static collectionName: CollectionName = CollectionName.AvailabilityStatus;
  static type: string = 'AvailabilityStatus';

  readonly $type: string = AvailabilityStatusModel.type;

  name?: string;
  description?: string;
  emoji?: string;
  clearAfter?: AvailabilityClearAfterEnum;
  workspaceId?: string;
  enabledAssignments: boolean;
  outOfOffice: boolean;
  clearAfterFromUser?: string;

  constructor(data?: AvailabilityStatus) {
    super(data);
  }

  static create(doc: AvailabilityStatus): AvailabilityStatusModel {
    if (!doc || !doc.$type) {
      throw new Error(`Invalid $type given ${JSON.stringify(doc)}`);
    }
    if (doc instanceof AvailabilityStatusModel) {
      return doc;
    }
    return new AvailabilityStatusModel(doc);
  }

  static createList(docs: AvailabilityStatus[]): AvailabilityStatusModel[] {
    return _.map(docs, (doc: AvailabilityStatus) => new AvailabilityStatusModel(doc));
  }

  static createFromUserStatus(status: AvailabilityUserStatus) {
    let newAvailabilityStatus = new AvailabilityStatusModel();
    newAvailabilityStatus.enabledAssignments = status.enabledAssignments;
    newAvailabilityStatus.emoji = status.emoji;
    newAvailabilityStatus.outOfOffice = status.outOfOffice;
    newAvailabilityStatus.name = status.status;
    newAvailabilityStatus.clearAfterFromUser = status.clearAfter;

    return newAvailabilityStatus;
  }
}
