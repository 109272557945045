import * as _ from 'lodash';
import { BaseModel } from './base/base.model';
import { UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { AvailabilityUserStatus } from '@shared/api/api-loop/models/availability-user-status';
import { CollectionName } from '@shared/models/constants/collection.names';
import { ResourceBase } from '@shared/api/api-loop/models/resource-base';

export interface UserAvailabilityStatusBaseModel extends ResourceBase {
  userId?: string;
  availabilityStatus?: AvailabilityUserStatus;
}

export class UserAvailabilityStatusModel extends BaseModel implements UserAvailabilityStatusBaseModel {
  static collectionName: CollectionName = CollectionName.UserAvailabilityStatus;
  static type: string = 'UserAvailabilityStatus';

  readonly $type: string = UserAvailabilityStatusModel.type;

  userId?: string;
  availabilityStatus?: AvailabilityUserStatus;

  constructor(model?: UserAvailabilityStatusBaseModel) {
    super(model);
    if (model && model.userId && model.availabilityStatus) {
      this.userId = model.userId;
      this.availabilityStatus = model.availabilityStatus;
      this._id = model.userId;
    }
  }

  isValidAvailabilityStatus(): boolean {
    if (!this.availabilityStatus) {
      return false;
    }

    if (this.availabilityStatus.clearAfter) {
      let now = new Date();
      let clearAfterDate = new Date(this.availabilityStatus.clearAfter);

      return clearAfterDate > now;
    }

    // Don't clear option
    return true;
  }

  static createUserAvailabilityFromUser(user: UserModel): UserAvailabilityStatusModel {
    let userAvailability = new UserAvailabilityStatusModel();
    userAvailability.userId = user.id;
    userAvailability._id = user.id;
    userAvailability.availabilityStatus = {
      clearAfter: user.availabilityStatus?.clearAfter,
      emoji: user.availabilityStatus?.emoji,
      enabledAssignments: user.availabilityStatus?.enabledAssignments,
      outOfOffice: user.availabilityStatus?.outOfOffice,
      status: user.availabilityStatus?.status,
    };

    return userAvailability;
  }

  static createUserAvailabilityFromStatus(userId: string, status: AvailabilityUserStatus): UserAvailabilityStatusModel {
    let userAvailability = new UserAvailabilityStatusModel();
    userAvailability.userId = userId;
    userAvailability._id = userId;
    userAvailability.availabilityStatus = {
      clearAfter: status?.clearAfter,
      emoji: status?.emoji,
      enabledAssignments: status?.enabledAssignments,
      outOfOffice: status?.outOfOffice,
      status: status?.status,
    };

    return userAvailability;
  }

  static createUserAvailabilities(users: UserModel[]): UserAvailabilityStatusModel[] {
    return _.map(users, data => UserAvailabilityStatusModel.createUserAvailabilityFromUser(data));
  }

  static createList(models: UserAvailabilityStatusModel[]) {
    return _.map(models, model => new UserAvailabilityStatusModel(model));
  }
}
