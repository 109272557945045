import { CommonModule } from '@angular/common';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { LanguageIdValue } from '@shared/shared/i18n/common/constants/language-id';
import { FancyDateService } from './fancy-date.service';
import { EnResource } from '@shared/shared/fancy-date/shell/internal/resources/en';
import { BaseDateFormatterResource } from '@shared/shared/fancy-date/shell/internal/base-date-formatter-resource';
import { FancyDatePipe } from '@shared/shared/fancy-date/shell/pipes/fancy-date.pipe';
import { DATE_FORMATTER_RESOURCE } from '@shared/shared/fancy-date/shell/internal/date-formatter-resource.injection-token';

export function resolveDateFormatterResource(localeId: LanguageIdValue): BaseDateFormatterResource {
  switch (localeId) {
    default:
      return new EnResource();
  }
}

@NgModule({
  declarations: [FancyDatePipe],
  imports: [CommonModule],
  exports: [FancyDatePipe]
})
export class FancyDateModule {
  static forRoot(): ModuleWithProviders<FancyDateModule> {
    return {
      ngModule: FancyDateModule,
      providers: [
        { provide: DATE_FORMATTER_RESOURCE, useFactory: resolveDateFormatterResource, deps: [LOCALE_ID] },
        FancyDateService
      ]
    };
  }
}
