import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { SharedTagLabelModel } from '../../../../shared/models-api-loop/shared-tag/shared-tag.model';

@Component({
  selector: 'label-dropdown',
  templateUrl: './label-dropdown.html',
  styleUrls: ['./label-dropdown.scss'],
})
export class LabelDropdownComponent extends DropdownComponent {
  ////////////////////
  // Inputs, Outputs
  ////////////////////
  @Input() labelTag: SharedTagLabelModel;
  @Input() isPrivate: boolean;
  @Output() removeLabel = new EventEmitter<SharedTagLabelModel>();

  //////////
  // SVGs
  //////////
  removeTagSvg = require('shared/assets/img/svg/remove.svg');

  /////////////////////
  // State variables
  /////////////////////
  filterQuery: string = undefined;

  onClickRemove(label: SharedTagLabelModel) {
    this.removeLabel.emit(label);
  }
}
