import { Injectable } from '@angular/core';
import { FolderBase, FolderNode } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';
import { SharedTagFolderModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import * as _ from 'lodash';
import { filterEnumToSvg } from '@shared/services/data/folder/filter-enum-to-svg';

@Injectable()
export class FolderHelper {
  public createFolderStructure(folders: SharedTagFolderModel[], pinnedViews: string[]) {
    if (!folders) {
      folders = [];
    }

    folders = folders.sort(function (a: SharedTagFolderModel, b: SharedTagFolderModel) {
      if (a.position && b.position) {
        return a.position > b.position ? 1 : -1;
      }

      if (a.position && !b.position) {
        return -1;
      }
      if (!a.position && b.position) {
        return 1;
      }

      return a.name.split('/').length - b.name.split('/').length;
    });

    let result: FolderNode[] = [];

    _.forEach(folders, folder => {
      this.insert(result, folder, folder.name, undefined, 0, pinnedViews);
    });

    this.indexFolders(result, -1);

    return result;
  }

  private insert(
    result: FolderNode[],
    folder: SharedTagFolderModel,
    folderName: string,
    parent: FolderNode,
    indent: number,
    pinnedViews: string[],
  ) {
    let parentNode = _.find(result, _folder => _folder.name === folderName.split('/')[0]);
    if (folder.name.split('/').length > 1 && parentNode) {
      let newName = folderName.substr(folderName.indexOf('/') + 1);
      indent += 1;

      this.insert(
        _.find(result, _parent => _parent.name === folderName.split('/')[0]).children,
        folder,
        newName,
        parentNode,
        indent,
        pinnedViews,
      );
    } else {
      let _folderName = parent ? folder.name.replace(`${parent.folder.name}/`, '') : folder.name;

      let folderNode = new FolderNode(_folderName, folder.id);

      folderNode.folder = folder;
      folderNode.children = [];
      folderNode.indent = indent;
      folderNode.parent = parent;
      folderNode.filterEnum = folder.filterEnum;
      folderNode.actionEnum = folder.actionEnum;
      folderNode.pinned = pinnedViews && pinnedViews.length > 0 ? pinnedViews.includes(folder.id) : !!folder.filterEnum;
      folderNode.svg = filterEnumToSvg(folder.filterEnum);

      result.push(folderNode);
    }
  }

  private indexFolders(folders: FolderNode[], index: number) {
    _.map(folders, folder => {
      index += 1;
      folder.index = index;
      if (folder.children.length > 0) {
        index = this.indexFolders(folder.children, index);
      }
    });

    return index;
  }

  public getFlatFolders(folders: FolderNode[], initialArray: FolderNode[]) {
    _.forEach(folders, folder => {
      if (folder instanceof FolderNode) {
        initialArray.push(folder);

        if (!_.isEmpty(folder.children)) {
          this.getFlatFolders(folder.children, initialArray);
        }
      }
    });

    return initialArray;
  }
}
