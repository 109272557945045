import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeIntegrationComponent } from '@shared/modules/main/plugins/integration/iframe-integration/iframe-integration.component';
import { SafeModule } from '@dta/ui/pipes/safe/safe.module';

@NgModule({
  declarations: [IframeIntegrationComponent],
  imports: [CommonModule, SafeModule],
  exports: [IframeIntegrationComponent]
})
export class IframeIntegrationModule {}
