import { Injector } from '@angular/core';
import { Router } from '@angular/router';

export function moveToNextCard(conversationId: string, injector: Injector): void {
  const router = injector.get(Router);

  if (router.url.includes('channel')) {
    router.navigate(
      [router.url.split(';')[0], { fragment: 'redirected' }, { outlets: { primary: ['empty'], panel: 'threads' } }],
      {
        queryParams: {
          focusOnNext: true,
          navigatedFromThreadId: conversationId,
        },
      },
    );
    return;
  }

  router.navigate([router.url.split(conversationId)[0]], {
    fragment: 'redirected',
    queryParams: {
      focusOnNext: true,
      navigatedFromThreadId: conversationId,
    },
    queryParamsHandling: 'merge',
  });
}
