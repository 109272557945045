import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { Conversation, ShowInViewObject } from '@shared/api/api-loop/models';
import { Observable } from 'rxjs';
import { BaseDaoService } from '../base/base-dao.service';
import { ConversationCollectionParams, SearchableViewCollectionParams } from '@dta/shared/models/collection.model';

export interface ConversationDaoServiceI {
  findConversationsForView(forUserEmail: string, params: ConversationCollectionParams): Observable<ConversationModel[]>;

  findConversationsWithoutSynchronizedComments(
    forUserEmail,
    conversationIds: string[],
  ): Observable<ConversationModel[]>;

  removeSyncedCommentAttribute(forUserEmail: string, showInViews: ShowInViewObject): Observable<any>;

  findByCardId(forUserEmail: string, cardId: string): Observable<ConversationModel>;

  findByCardIds(forUserEmail: string, cardIds: string[]): Observable<ConversationModel[]>;

  removeByCardIds(forUserEmail: string, cardIds: string[]): Observable<any>;

  setSyncedCommentAttribute(forUserEmail: string, cardIds: string[]): Observable<any>;

  removeAllConversations(forUserEmail: string): Observable<any>;

  unlinkPrivateDraft(forUserEmail: string, conversationId: string): Observable<any>;
}

export abstract class ConversationDaoService
  extends BaseDaoService<ConversationModel, Conversation>
  implements ConversationDaoServiceI
{
  abstract findByCardId(forUserEmail: string, cardId: string): Observable<ConversationModel>;

  abstract findByCardIds(forUserEmail: string, cardIds: string[]): Observable<ConversationModel[]>;

  abstract removeByCardIds(forUserEmail: string, cardIds: string[]): Observable<any>;

  abstract findConversationsForView(
    forUserEmail: string,
    params: ConversationCollectionParams,
  ): Observable<ConversationModel[]>;

  abstract findConversationsWithoutSynchronizedComments(
    forUserEmail,
    conversationIds: string[],
  ): Observable<ConversationModel[]>;

  abstract removeSyncedCommentAttribute(forUserEmail: string, showInViews: ShowInViewObject): Observable<any>;

  abstract setSyncedCommentAttribute(forUserEmail: string, conversationIds: string[]): Observable<any>;

  abstract removeAllConversations(forUserEmail: string): Observable<any>;

  abstract unlinkPrivateDraft(forUserEmail: string, conversationId: string): Observable<any>;
}
