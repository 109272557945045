import { Component, OnInit } from '@angular/core';
import { DeepLinksService } from '@shared/services/deeplinks/deeplinks.service';

@Component({
  templateUrl: './deeplink.html',
  styleUrls: ['./deeplink.scss'],
})
export class DeeplinkComponent implements OnInit {
  constructor(private _deepLinkService: DeepLinksService) {}

  ngOnInit() {
    this._deepLinkService.checkForDeepLink();
  }
}
