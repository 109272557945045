import { UserModel } from './../../../shared/models-api-loop/contact/contact.model';
import { Component, inject, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BatchSelectService, CountState } from '../../services/batch-select/batch-select.service';
import { TagLabelModel } from '../../../shared/models-api-loop/tag.model';
import { map, publishReplay, refCount, startWith, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { KeyboardShortcutsListenerService } from '../../services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import {
  KeyboardActionModeType,
  KeyboardActionType,
  KeyboardShortcutData,
  ModeSwitchKeyboardAction
} from '../../../shared/models/keyboard-shortcut.model';
import { RouteData, RouterService, SupportedRouteParts } from '../../services/router/router.service';
import { ActionEnum, SharedTagBase, SharedTagFolder } from '@shared/api/api-loop/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationBarService } from '@shared/modules/main/navigation-bar/navigation-bar.service';
import { FolderNode } from '@shared/modules/main/navigation-bar/navigation-bar/navigation-bar.component';
import { SharedTagLabelModel, StaticSharedTagIds } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { ConversationHeaderActionService } from '@shared/modules/conversations/components/conversation-header/conversation-header-action.service';
import { ContainerRef } from 'ngx-infinite-scroll';

interface BatchAction {
  action: () => void;
  isAvailable$?: Observable<boolean>;
  name: string;
  icon: string;
}

interface MakeBatchActionParams {
  batchAction: ActionEnum;
  folder?: SharedTagFolder;
  notificationMessage?: string;
}

@UntilDestroy()
@Component({
  selector: 'batch-select',
  templateUrl: './batch-select.html',
  styleUrls: ['./batch-select.scss']
})
export class BatchSelectComponent implements OnInit, OnDestroy {
  private readonly _batchSelectService: BatchSelectService = inject(BatchSelectService);
  private readonly _navigationBarService: NavigationBarService = inject(NavigationBarService);
  private readonly _keyboardShortcutsListenerService: KeyboardShortcutsListenerService = inject(
    KeyboardShortcutsListenerService
  );
  private readonly _routerService: RouterService = inject(RouterService);
  private readonly conversationHeaderActionService: ConversationHeaderActionService = inject(
    ConversationHeaderActionService
  );

  batchSelectBgSvg = require('shared/assets/img/svg/batch-select-bg.svg');
  star22Svg = require('shared/assets/img/svg/star-22-px.svg');
  fullstar22Svg = require('shared/assets/img/svg/full-star-22-px.svg');
  archive22Svg = require('shared/assets/img/svg/archive-22-px.svg');
  trash22Svg = require('shared/assets/img/svg/trash-22-px.svg');
  unread22Svg = require('shared/assets/img/svg/unread-22-px.svg');
  read22Svg = require('shared/assets/img/svg/read-22-px.svg');
  folder22Svg = require('shared/assets/img/svg/folder-22-px.svg');
  unarchiveSvg = require('shared/assets/img/svg/unarchive.svg');
  closeSvg = require('shared/assets/img/svg/medium-close.svg');
  resolveSvg = require('shared/assets/img/svg/resolve.svg');
  assignSvg = require('shared/assets/img/svg/assign.svg');

  @Input() markedCardsNumber: number = 0;
  @Input() navigateAfterClear: boolean;
  @Input() sharedLabels: SharedTagBase[] = [];

  private readonly countState$: ReplaySubject<CountState> = new ReplaySubject<CountState>(1);
  @Input() set countState(countState: CountState) {
    this.countState$.next(countState);
  }

  @ViewChild('moveToFolderTpl', { read: ViewContainerRef }) private moveToFolderContainerRef: ViewContainerRef;
  @ViewChild('assignTpl', { read: ViewContainerRef }) private assignContainerRef: ViewContainerRef;
  @ViewChild('labelTagsTpl', { read: ViewContainerRef }) private labelTagsContainerRef: ViewContainerRef;

  protected sharedInboxId: string | null = null;

  private handledKeyboardActions: KeyboardActionType[] = [KeyboardActionType.MODE_SWITCH];

  private readonly selectedFolderNode$: Observable<FolderNode> = this._navigationBarService.selectedFolderChanged$.pipe(
    startWith(this._navigationBarService.folderSelected),
    publishReplay(1),
    refCount()
  );

  protected readonly areMainActionsVisible$: Observable<boolean> = this.selectedFolderNode$.pipe(
    map(folderNode => !folderNode.id.includes(StaticSharedTagIds.SPAM_ID))
  );

  protected readonly actions$: Observable<BatchAction[]> = this.selectedFolderNode$.pipe(
    map(selectedFolder => {
      return this.getActions(selectedFolder, this.isSharedInboxView(this._routerService.getCurrentRouteData()));
    })
  );

  ngOnInit(): void {
    this.subscribeToKeyboardShortcuts();
    const routeData = this._routerService.getCurrentRouteData();
    this.sharedInboxId = routeData.routeParts[1]?.endsWith('0T') ? routeData.routeParts[1] : null;
  }

  private isSharedInboxView(routeData: RouteData): boolean {
    return routeData.routeParts[0] === SupportedRouteParts.Channel;
  }

  private subscribeToKeyboardShortcuts(): void {
    this._keyboardShortcutsListenerService
      .getActionsByTypes(this.handledKeyboardActions)
      .pipe(
        untilDestroyed(this),
        tap((action: KeyboardShortcutData) => {
          switch (action.action.type) {
            case KeyboardActionType.MODE_SWITCH:
              let _action = <ModeSwitchKeyboardAction>action.action;
              if (_action.triggeredWithKeyboardAction && _action.toMode.type === KeyboardActionModeType.NAVIGATION) {
                this.batchCancel();
              }
              break;
            default:
              break;
          }
        })
      )
      .subscribe();
  }

  private getActions(folder: FolderNode, isSharedInboxView: boolean): BatchAction[] {
    const batchActions = [];

    if (folder.id.includes(StaticSharedTagIds.SPAM_ID)) {
      batchActions.push({
        action: () => this._batchSelectService.markAsUnSpam(),
        icon: this.unarchiveSvg,
        name: 'Mark as not spam'
      });
      return batchActions;
    }

    if (folder.isMoveToInboxAvailable()) {
      batchActions.push({
        action: () =>
          this.makeBatchAction({
            batchAction: ActionEnum.MOVE_TO_INBOX,
            notificationMessage: 'Moving items to inbox'
          }),
        icon: this.unarchiveSvg,
        name: 'Move to inbox'
      });
    }

    batchActions.push({
      action: () =>
        this.makeBatchAction({
          batchAction: ActionEnum.MARK_AS_STARRED,
          notificationMessage: 'Marking items as starred'
        }),
      isAvailable$: this.countState$.pipe(map(countState => countState.starredCount !== this.markedCardsNumber)),
      icon: this.fullstar22Svg,
      name: 'Star'
    });

    batchActions.push({
      action: () =>
        this.makeBatchAction({
          batchAction: ActionEnum.MARK_AS_UNSTARRED,
          notificationMessage: 'Unstarring items'
        }),
      isAvailable$: this.countState$.pipe(map(countState => countState.unStarredCount !== this.markedCardsNumber)),
      icon: this.star22Svg,
      name: 'Unstar'
    });

    if (folder.isDeleteAvailable()) {
      batchActions.push({
        action: () =>
          this.makeBatchAction({
            batchAction: ActionEnum.DELETE,
            notificationMessage: 'Moving items to trash'
          }),
        icon: this.trash22Svg,
        name: 'Delete'
      });
    }

    if (folder.isArchiveAvailable()) {
      batchActions.push({
        action: () =>
          this.makeBatchAction({
            batchAction: ActionEnum.ARCHIVE,
            notificationMessage: 'Moving items to archive'
          }),
        icon: this.archive22Svg,
        name: 'Archive'
      });
    }

    batchActions.push({
      action: () =>
        this.makeBatchAction({
          batchAction: ActionEnum.MARK_AS_READ,
          notificationMessage: 'Marking items as read'
        }),
      isAvailable$: this.countState$.pipe(map(countState => countState.readCount !== this.markedCardsNumber)),
      icon: this.read22Svg,
      name: 'Mark as read'
    });

    batchActions.push({
      action: () =>
        this.makeBatchAction({
          batchAction: ActionEnum.MARK_AS_UNREAD,
          notificationMessage: 'Marking items as unread'
        }),
      isAvailable$: this.countState$.pipe(map(countState => countState.unReadCount !== this.markedCardsNumber)),
      icon: this.unread22Svg,
      name: 'Mark as unread'
    });
    return batchActions;
  }

  private makeBatchAction({ batchAction, folder, notificationMessage = '' }: MakeBatchActionParams) {
    this._batchSelectService.batchTagUpdate(batchAction, folder);
  }

  batchCancel() {
    this._batchSelectService.cancelBatchSelect();
  }

  batchAssign(user: UserModel) {
    this._batchSelectService.batchAssign('BatchSelect', user);
  }

  batchResolve() {
    this._batchSelectService.batchResolve('BatchSelect');
  }

  openAddToFolder(): void {
    const markedConversationIds = this._batchSelectService
      .getSelectedConversations()
      .map(conversation => conversation.cardId);
    this.conversationHeaderActionService.openAddToFolder(
      markedConversationIds,
      this.sharedInboxId,
      this.moveToFolderContainerRef
    );
  }

  openAssign(): void {
    const markedConversationIds = this._batchSelectService.getSelectedConversations();
    this.conversationHeaderActionService
      .assignConversation(markedConversationIds[0], this.assignContainerRef, {
        shouldReturnOnlySelectedUser: true
      })
      .instance.selectedContact.pipe(untilDestroyed(this))
      .subscribe(user => {
        this._batchSelectService.batchAssign('batch', user);
      });
  }

  openAddTag(): void {
    const markedConversations = this._batchSelectService.getSelectedConversations();
    this.conversationHeaderActionService.openLabelsDropdown(markedConversations, this.labelTagsContainerRef);
  }

  addOrRemoveLabel(label: SharedTagLabelModel | TagLabelModel, action: 'add' | 'remove') {
    if (action === 'add') {
      this.sharedLabels = [...this.sharedLabels, label];
    } else {
      this.sharedLabels = this.sharedLabels.filter(_label => _label.id !== label.id);
    }
    this._batchSelectService.batchLabelUpdate(label, action);
  }

  ngOnDestroy(): void {
    this.countState$.complete();
  }
}
