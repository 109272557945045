/* tslint:disable */

/**
 * API version enum
 */
export type EventVersion = 'V1';

export module EventVersion {
  export const V1: EventVersion = 'V1';

  export function values(): EventVersion[] {
    return [V1];
  }
}
