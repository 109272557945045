/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Options } from '../models/options';

@Injectable()
export class HomeApiService extends BaseService {
  Home_HeadResponse(forUserEmail: string): Observable<HttpResponse<string[]>> {
    let __method = 'HEAD';
    let __url = this.rootUrl + `/api`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Home_Head');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: string[];

          body = <string[]>response.body;

          return <HttpResponse<string[]>>response.clone({ body: body });
        })
      );
  }

  Home_Head(forUserEmail: string, cacheEnabled?: boolean): Observable<string[]> {
    const observable$ = this.Home_HeadResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api`;
      const url = this.rootUrl + `/api`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  Home_OptionsResponse(forUserEmail: string): Observable<HttpResponse<Options>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Home_Options');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: Options;

          body = <Options>response.body;

          return <HttpResponse<Options>>response.clone({ body: body });
        })
      );
  }

  Home_Options(forUserEmail: string, cacheEnabled?: boolean): Observable<Options> {
    const observable$ = this.Home_OptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api`;
      const url = this.rootUrl + `/api`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  Home_GetResponse(forUserEmail: string): Observable<HttpResponse<string[]>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Home_Get');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: string[];

          body = <string[]>response.body;

          return <HttpResponse<string[]>>response.clone({ body: body });
        })
      );
  }

  Home_Get(forUserEmail: string, cacheEnabled?: boolean): Observable<string[]> {
    const observable$ = this.Home_GetResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api`;
      const url = this.rootUrl + `/api`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  Home_GetModifiedSwaggerResponse(forUserEmail: string): Observable<HttpResponse<ArrayBuffer>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/modifiedSwagger`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Home_GetModifiedSwagger');

    // body, headers and query params

    return this._http.request(__method, __url, {
      headers: __headers,
      params: __params,
      body: __body,
      responseType: 'arraybuffer',
      reportProgress: __reportProgress,
      observe: __reportProgress ? 'events' : 'response'
    });
  }

  Home_GetModifiedSwagger(forUserEmail: string, cacheEnabled?: boolean): Observable<ArrayBuffer> {
    const observable$ = this.Home_GetModifiedSwaggerResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/modifiedSwagger`;
      const url = this.rootUrl + `/api/modifiedSwagger`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }
}

export module HomeApiService {}
