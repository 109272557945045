import { Directive, OnDestroy, OnInit } from '@angular/core';
import { CreateChannelBaseComponent } from '@shared/modules/main/user-settings/channels/create/create-channel.base.component';
import {
  InboxProvider,
  SharedInboxSetupData,
} from '@shared/services/data/channel-inbox-setup/channel-setup.service.interface';
import { CreateChannelFormType } from '@shared/services/create-channel-form-dialog/create-channel-form-dialog.service';
import { catchError, filter, mergeMap, Observable, Subject, Subscription, tap } from 'rxjs';
import { UserAlias } from '@shared/api/api-loop/models/user-alias';
import { SyncSettingsType } from '@dta/shared/models/sync-settings-type.model';
import * as _ from 'lodash';
import { isWebApp } from '@dta/shared/utils/common-utils';
import { AuthErrorForUI } from '@shared/services/data/auth/auth-error.helper';
import { ExchangeAuthData, ImapAuthData } from '@shared/services/data/auth/auth.service.interface';
import { SetupType } from '@shared/services/data/channel-inbox-setup/channel-setup.service';

@Directive()
export abstract class CreateInboxComponent extends CreateChannelBaseComponent implements OnInit, OnDestroy {
  channelOptions = [
    {
      description: 'Sign in with Gmail',
      svg: this.googleSvg,
      id: 'channel-card-gmail',
      onClick: () =>
        this._channelSetupService.authenticateInbox(this.currentUserEmail, InboxProvider.GOOGLE_OAUTH, this.setupType),
    },
    {
      description: 'Sign in with Gmail alias',
      svg: this.googleSvg,
      id: 'channel-card-gmail-alias',
      onClick: () => this.openFormModal('gmail-alias', this.setupType),
    },
    {
      description: 'Sign in with Email (imap)',
      svg: this.imapSvg,
      id: 'channel-card-imap',
      onClick: () => this.openFormModal('imap', this.setupType),
    },
    {
      description: 'Sign in with Office 365',
      svg: this.microsoftSvg,
      id: 'channel-card-microsoft',
      onClick: () =>
        this._channelSetupService.authenticateInbox(
          this.currentUserEmail,
          InboxProvider.MICROSOFT_OAUTH,
          this.setupType,
        ),
    },
    {
      description: 'Sign in with Microsoft Exchange',
      svg: this.microsoftSvg,
      id: 'channel-card-exchange',
      onClick: () => this.openFormModal('microsoft-exchange', this.setupType),
    },
    {
      description: 'Sign in with Microsoft shared inbox',
      svg: this.microsoftSvg,
      id: 'channel-card-microsoft-shared-inbox',
      onClick: () => this.openFormModal('microsoft-shared-inbox', this.setupType),
    },
  ];
  protected aliases: UserAlias[];

  protected routeChange: Subscription;
  protected modalSub: Subscription;

  ngOnInit() {
    super.ngOnInit();
    this.setAliases();
    this.subscribeToRouteChange();
  }

  protected openFormModal(formType: CreateChannelFormType, setupType: SetupType) {
    let subject: Subject<FormResponseType> = new Subject();

    let aliases =
      formType === 'gmail-alias' ? this.aliases.map(alias => (<any>alias)?.alias?.email).filter(Boolean) : undefined;

    let forbiddenEmail = setupType === SetupType.PERSONAL_SHARED_INBOX ? undefined : this.currentUserEmail;
    this._createChanelFormDialogService.dialogEvents$.next({
      type: 'open',
      data: { subject, formType, aliases, forbiddenEmail: forbiddenEmail },
    });

    this.subscribeToModalSubject(subject);
  }

  protected openErrorDialog(text: string) {
    let data = {
      open: true,
      title: 'Important!',
      text,
      noCancelButton: true,
    };

    this._confirmDialogService.toggle.next(data);
  }

  protected getDataFromRoute() {
    let routeData = this._routerService.getCurrentRouteData();
    let stateFromRoute = routeData.queryParameters.sharedInboxState;

    if (!stateFromRoute) {
      this.resetState();
      return;
    }

    let { data } = this._channelSetupService.getSetupDataByState(stateFromRoute);
    this.data = data as SharedInboxSetupData;

    if (_.isEmpty(this.data)) {
      this.resetState();
      return;
    }

    this.setStepFromData(routeData.queryParameters.permissionDeniedReason);
  }

  private setAliases() {
    let gmailAliasOption = this.channelOptions.find(({ description }) => description.includes('alias'));

    let isGmail =
      this._userManagerService.getAccountSyncSettingsTypeByEmail(this.currentUserEmail) ===
      SyncSettingsType.SYNC_SETTINGS_GMAIL;
    let isSycEnabled = this._userManagerService.isUserAccountSyncable(this.currentUserEmail);

    if (!isGmail || !isSycEnabled) {
      gmailAliasOption['disabled'] = true;
      return;
    }

    this.aliases = this._settingsService.getAliasSettings(this.currentUserEmail)?.aliases?.resources;
    gmailAliasOption['disabled'] = _.isEmpty(this.aliases);
  }

  private subscribeToRouteChange() {
    if (isWebApp()) {
      return;
    }

    this.routeChange?.unsubscribe();
    this.routeChange = this._routerService.navigationEnd$
      .pipe(
        filter(() => {
          let { queryParameters } = this._routerService.getCurrentRouteData();
          return !!(queryParameters.permissionDeniedReason || queryParameters.sharedInboxState);
        }),
        tap(() => {
          this.getDataFromRoute();
        }),
      )
      .subscribe();
  }

  ////////////////
  // Subscribers
  ////////////////
  protected subscribeToModalSubject(subject: Subject<FormResponseType>) {
    this.modalSub?.unsubscribe();
    this.modalSub = subject
      .pipe(
        mergeMap(({ formType, formData }: FormResponseType) => {
          switch (formType) {
            case 'microsoft-exchange':
              return this._channelSetupService.authenticateInbox(
                this.currentUserEmail,
                InboxProvider.MICROSOFT_EXCHANGE,
                this.setupType,
                { exchangeAuthData: formData },
              );
            case 'imap':
              return this._channelSetupService.authenticateInbox(
                this.currentUserEmail,
                InboxProvider.IMAP,
                this.setupType,
                { imapAuthData: formData },
              );
            case 'microsoft-shared-inbox':
              return this._channelSetupService.authenticateInbox(
                this.currentUserEmail,
                InboxProvider.MICROSOFT_SHARED_INBOX,
                this.setupType,
                { microsoftSharedInboxEmail: formData.email },
              );
            case 'gmail-alias':
              return this._channelSetupService.authenticateInbox(
                this.currentUserEmail,
                InboxProvider.GOOGLE_ALIAS,
                this.setupType,
                { gmailAlias: formData.email },
              );
            default:
              break;
          }
        }),
        tap(() => {
          this._createChanelFormDialogService.dialogEvents$.next({ type: 'close' });
          setTimeout(() => this.getDataFromRoute(), 0);
        }),
        catchError((err: AuthErrorForUI, originalObservable: Observable<FormResponseType>) => {
          this._createChanelFormDialogService.dialogEvents$.next({
            type: 'update',
            data: {
              errorCode: err.errorCode,
              error: err.errorMessageForUI,
            },
          });

          return originalObservable;
        }),
      )
      .subscribe();
  }
}

export type FormResponseType = {
  formType: CreateChannelFormType;
  formData: ExchangeAuthData | ImapAuthData;
};
