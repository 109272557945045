/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { FileUploadProgress } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { createKeyFromRequest } from '@shared/api/api-cache/base-api-cache.service';

import { Response } from '../models/response';
import { ResponseConflict } from '../models/response-conflict';
import { EventBase } from '../models/event-base';
import { OptionsEventList } from '../models/options-event-list';
import { ConversationEventList } from '../models/conversation-event-list';
import { EventList } from '../models/event-list';

@Injectable()
export class EventApiService extends BaseService {
  /**
   * @param params The `EventApiService.Event_CreateEventParams` containing the following parameters:
   *
   * - `eventBase`: Event base object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Event_CreateEventResponse(
    params: EventApiService.Event_CreateEventParams,
    forUserEmail: string
  ): Observable<HttpResponse<boolean>> {
    let __method = 'POST';
    let __url = this.rootUrl + `/api/v1/event/event`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Event_CreateEvent');

    // body, headers and query params
    __body = params.eventBase;
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'text',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: boolean;

          body = response.body == 'true';

          return <HttpResponse<boolean>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `EventApiService.Event_CreateEventParams` containing the following parameters:
   *
   * - `eventBase`: Event base object
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Event_CreateEvent(
    params: EventApiService.Event_CreateEventParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<boolean> {
    const observable$ = this.Event_CreateEventResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/event/event`;
      const url = this.rootUrl + `/api/v1/event/event`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Event_GetConversationsOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsEventList>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1/event/conversations`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Event_GetConversationsOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsEventList;

          body = <OptionsEventList>response.body;

          return <HttpResponse<OptionsEventList>>response.clone({ body: body });
        })
      );
  }

  Event_GetConversationsOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsEventList> {
    const observable$ = this.Event_GetConversationsOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/event/conversations`;
      const url = this.rootUrl + `/api/v1/event/conversations`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `EventApiService.Event_GetConversationsEventParams` containing the following parameters:
   *
   * - `size`: Requested page size
   *
   * - `maxChanges`: if set request can return not found status and error code invalididsince if changes are greater than value specified
   *
   * - `historyId`: events greater than historyId(that is, more recent than)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Event_GetConversationsEventResponse(
    params: EventApiService.Event_GetConversationsEventParams,
    forUserEmail: string
  ): Observable<HttpResponse<ConversationEventList>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1/event/conversations`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Event_GetConversationsEvent');

    // body, headers and query params
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.maxChanges != null) __params = __params.set('maxChanges', params.maxChanges.toString());
    if (params.historyId != null) __params = __params.set('historyId', params.historyId.toString());
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: ConversationEventList;

          body = <ConversationEventList>response.body;

          return <HttpResponse<ConversationEventList>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `EventApiService.Event_GetConversationsEventParams` containing the following parameters:
   *
   * - `size`: Requested page size
   *
   * - `maxChanges`: if set request can return not found status and error code invalididsince if changes are greater than value specified
   *
   * - `historyId`: events greater than historyId(that is, more recent than)
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Event_GetConversationsEvent(
    params: EventApiService.Event_GetConversationsEventParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<ConversationEventList> {
    const observable$ = this.Event_GetConversationsEventResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1/event/conversations`;
      const url = this.rootUrl + `/api/v1/event/conversations`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }

  Event_GetListOptionsResponse(forUserEmail: string): Observable<HttpResponse<OptionsEventList>> {
    let __method = 'OPTIONS';
    let __url = this.rootUrl + `/api/v1.2/event/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Event_GetListOptions');

    // body, headers and query params

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let body: OptionsEventList;

          body = <OptionsEventList>response.body;

          return <HttpResponse<OptionsEventList>>response.clone({ body: body });
        })
      );
  }

  Event_GetListOptions(forUserEmail: string, cacheEnabled?: boolean): Observable<OptionsEventList> {
    const observable$ = this.Event_GetListOptionsResponse(forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/event/list`;
      const url = this.rootUrl + `/api/v1.2/event/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail }),
        observable$
      );
    }
    return observable$;
  }

  /**
   * @param params The `EventApiService.Event_GetListParams` containing the following parameters:
   *
   * - `sortOrder`: Order of events by created date time (default is ascending)
   *
   * - `skipOlderThan`: Skip all events that were created before given dateTime
   *
   * - `skipEventTypeList`: Types of events to skip
   *
   * - `size`: Requested page size
   *
   * - `longPolling`: Connection should be open until a new event (or max 50 seconds) or return immediately (default is false)
   *
   * - `htmlFormat`: HTML format of comment body
   *
   * - `historyId`: Return events with an id greater than (that is, more recent than) the specified id for ascending sort order
   *                 or vice versa (events with an id lesser than or older than) for descending sort order.
   *
   * - `eventTypeList`: Types of events to return
   *
   * - `contextList`: Get events only for specified context
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Event_GetListResponse(
    params: EventApiService.Event_GetListParams,
    forUserEmail: string
  ): Observable<HttpResponse<EventList>> {
    let __method = 'GET';
    let __url = this.rootUrl + `/api/v1.2/event/list`;
    let __headers = this.newHeaders(forUserEmail);
    let __params = this.newParams();
    let __body: any;
    let __reportProgress: boolean = ['File_CreateFile'].includes('Event_GetList');

    // body, headers and query params
    if (params.sortOrder != null) __params = __params.set('sortOrder', params.sortOrder.toString());
    if (params.skipOlderThan != null) __params = __params.set('skipOlderThan', params.skipOlderThan.toString());
    (params.skipEventTypeList || []).forEach((val, index) => {
      if (val != null) __params = __params.append('skipEventTypeList', val.toString());
    });
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.longPolling != null) __params = __params.set('longPolling', params.longPolling.toString());
    if (params.htmlFormat != null) __params = __params.set('htmlFormat', params.htmlFormat.toString());
    if (params.historyId != null) __params = __params.set('historyId', params.historyId.toString());
    (params.eventTypeList || []).forEach((val, index) => {
      if (val != null) __params = __params.append('eventTypeList', val.toString());
    });
    (params.contextList || []).forEach((val, index) => {
      if (val != null) __params = __params.append('contextList', val.toString());
    });
    if (params.XImpersonateUser != null)
      __headers = __headers.set('X-Impersonate-User', params.XImpersonateUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());

    return this._http
      .request(__method, __url, {
        headers: __headers,
        params: __params,
        body: __body,
        responseType: 'json',
        reportProgress: __reportProgress,
        observe: __reportProgress ? 'events' : 'response'
      })
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (!__reportProgress) {
            return;
          }

          // Emit file upload progress
          if (response.type === HttpEventType.UploadProgress && params['file']) {
            let fileProgress = new FileUploadProgress();
            fileProgress.fileName = params['file']['name'];
            fileProgress.progress = response.loaded / response.total;

            SharedSubjects._fileUploadProgress$.next(fileProgress);
          }
        }),
        filter((response: HttpEvent<any>) => response.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => {
          let body: EventList;

          body = <EventList>response.body;

          return <HttpResponse<EventList>>response.clone({ body: body });
        })
      );
  }

  /**
   * @param params The `EventApiService.Event_GetListParams` containing the following parameters:
   *
   * - `sortOrder`: Order of events by created date time (default is ascending)
   *
   * - `skipOlderThan`: Skip all events that were created before given dateTime
   *
   * - `skipEventTypeList`: Types of events to skip
   *
   * - `size`: Requested page size
   *
   * - `longPolling`: Connection should be open until a new event (or max 50 seconds) or return immediately (default is false)
   *
   * - `htmlFormat`: HTML format of comment body
   *
   * - `historyId`: Return events with an id greater than (that is, more recent than) the specified id for ascending sort order
   *                 or vice versa (events with an id lesser than or older than) for descending sort order.
   *
   * - `eventTypeList`: Types of events to return
   *
   * - `contextList`: Get events only for specified context
   *
   * - `X-Impersonate-User`: Impersonation header
   *
   * - `Authorization`: Authorization header
   */
  Event_GetList(
    params: EventApiService.Event_GetListParams,
    forUserEmail: string,
    cacheEnabled?: boolean
  ): Observable<EventList> {
    const observable$ = this.Event_GetListResponse(params, forUserEmail).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );

    if (cacheEnabled) {
      const endpoint = `/api/v1.2/event/list`;
      const url = this.rootUrl + `/api/v1.2/event/list`;
      return this._baseApiCacheService.getOrStoreObservable(
        createKeyFromRequest({ endpoint: endpoint, url: url, forUserEmail: forUserEmail, params }),
        observable$
      );
    }
    return observable$;
  }
}

export module EventApiService {
  /**
   * Parameters for Event_CreateEvent
   */
  export type Event_CreateEventParams = {
    /**
     * Event base object
     */
    eventBase: EventBase;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Event_GetConversationsEvent
   */
  export type Event_GetConversationsEventParams = {
    /**
     * Requested page size
     */
    size?: number;

    /**
     * if set request can return not found status and error code invalididsince if changes are greater than value specified
     */
    maxChanges?: number;

    /**
     * events greater than historyId(that is, more recent than)
     */
    historyId?: string;

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };

  /**
   * Parameters for Event_GetList
   */
  export type Event_GetListParams = {
    /**
     * Order of events by created date time (default is ascending)
     */
    sortOrder?: string;

    /**
     * Skip all events that were created before given dateTime
     */
    skipOlderThan?: string;

    /**
     * Types of events to skip
     */
    skipEventTypeList?: string[];

    /**
     * Requested page size
     */
    size?: number;

    /**
     * Connection should be open until a new event (or max 50 seconds) or return immediately (default is false)
     */
    longPolling?: boolean;

    /**
     * HTML format of comment body
     */
    htmlFormat?: string;

    /**
     * Return events with an id greater than (that is, more recent than) the specified id for ascending sort order
     *             or vice versa (events with an id lesser than or older than) for descending sort order.
     */
    historyId?: string;

    /**
     * Types of events to return
     */
    eventTypeList?: string[];

    /**
     * Get events only for specified context
     */
    contextList?: string[];

    /**
     * Impersonation header
     */
    XImpersonateUser?: string;

    /**
     * Authorization header
     */
    Authorization?: string;
  };
}
