import { from, Observable, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, mergeMap, toArray } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';

export abstract class BaseDecorateService<T extends BaseModel> {
  constructor() {}

  abstract decorateExtraData(forUserEmail: string, model: T, force?: boolean): Observable<T>;

  abstract decorateViewData(forUserEmail: string, model: T, force?: boolean): Observable<T>;

  decorate(forUserEmail: string, model: T, force?: boolean): Observable<T> {
    return this.decorateExtraData(forUserEmail, model, force).pipe(
      mergeMap((model: T) => {
        return this.decorateViewData(forUserEmail, model, force);
      }),
      catchError(err => {
        Logger.error(err, `Could not decorate ${model._id}`);
        return throwError(() => err);
      }),
    );
  }

  decorateListData(forUserEmail: string, models: T[], force?: boolean): Observable<T[]> {
    return from(models).pipe(
      mergeMap((model: T) => {
        return this.decorate(forUserEmail, model, force);
      }),
      toArray(),
      defaultIfEmpty([]),
      catchError(err => {
        Logger.error(err, 'Could not decorate list data');
        return throwError(err);
      }),
    );
  }
}
