import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ConfirmOrDeclineParams } from '@shared/shared/confirm/common/interfaces/confirm-or-decline-params';

@Component({
  templateUrl: './confirm-or-decline-dialog-content.component.html',
  styleUrls: ['./confirm-or-decline-dialog-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmOrDeclineDialogContentComponent {
  @Input() params?: ConfirmOrDeclineParams;
  @Output() confirmResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('keyup.enter')
  private handleHostClick(): void {
    this.confirmResult.emit(true);
  }

  abort(): void {
    this.confirmResult.emit(false);
  }
  confirm(): void {
    this.confirmResult.emit(true);
  }
}
