import * as _ from 'lodash';
import { CONSTANTS } from '@shared/models/constants/constants';
import { ContactModel } from '../models-api-loop/contact/contact.model';
import { NavigationExtras } from '@angular/router';
import { KeyboardActionMode } from '../../ui/services/keyboard-shortcuts-listener/keyboard-shortcut-modes';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';
import { DatabaseService } from '@shared/database/database.service';

export class KeyboardShortcut implements ShortcutsData {
  hotKeyG?: boolean;
  shiftKey?: boolean;
  ctrlKey?: boolean;
  altKey?: boolean;
  key: string;
  preventNativeSupport?: boolean;
  suggestToNewUsersOrder?: number;
  suggestToExistingUsersOrder?: number;
  preventDefaultInModes?: KeyboardActionModeType[];

  action: KeyboardAction;

  constructor(data: ShortcutsData) {
    if (data) {
      Object.assign(this, data);
    }
  }

  get displayList() {
    let list: string[] = Object.keys(keyboardShortcutsKeyMappings)
      .filter((key: string) => this[key])
      .map((key: string) => keyboardShortcutsKeyMappings[key]);

    if (this.key) {
      list.push(_.capitalize(this.key));
    }

    return list;
  }

  get stringRepresentation() {
    return this.displayList.join(' + ');
  }
}

const isDarwin: boolean = checkIfOS(OperatingSystem.DARWIN);
export const keyboardShortcutsKeyMappings = {
  hotKeyG: 'G',
  ctrlKey: isDarwin ? '⌘' : 'Ctrl',
  altKey: isDarwin ? '⌥' : 'Alt',
  shiftKey: 'Shift'
};

export enum KeyboardActionCategory {
  QUICK_START = 'Quick start',
  JUMP_TO = 'Jump to',
  ACTIONS = 'Actions',
  OTHER = 'Other',
  FORMAT = 'Format',
  CREATE = 'Create',
  LIST_NAVIGATION = 'List Navigation',
  MODE_SWITCH = 'Mode Switch'
}

export enum KeyboardActionModeType {
  NAVIGATION = 'navigation',
  TEXT = 'text',
  SEARCH_SECONDARY = 'searchSecondary',
  SEARCH = 'search',
  THREAD = 'thread',
  DROPDOWN = 'dropdown',
  MODAL = 'modal',
  SHORTCUTS_MODAL = 'shortcutsModal',
  COMPOSER_MODAL = 'composerModal',
  TEXT_SECONDARY = 'textSecondary',
  FILE_PREVIEWER = 'filePreviewer',
  FORM = 'form'
}

export enum KeyboardActionType {
  // Actions
  DELETE = 'delete',
  STAR = 'star',
  ARCHIVE = 'archive',
  NEW_EMAIL = 'newEmail',
  REPLY = 'reply',
  REPLY_TO_ALL = 'replyToAll',
  REPLY_TO_ALL_QUICK_REPLY = 'replyToAllQuickReply',
  PRINT = 'print',
  FORWARD = 'forward',
  SEND = 'send',
  MARK_AS_READ = 'markAsRead',
  MARK_AS_UNREAD = 'markAsUnread',
  MARK_ALL_AS_READ = 'markAllAsRead',
  MOVE_TO_FOLDER = 'moveToFolder',
  MOVE_TO_TEAM = 'moveToTeam',
  TAG_CONVERSATION = 'tagConversation',
  ASSIGN_SELF = 'assignSelf',
  ASSIGN_SOMEONE = 'assignSomeone',
  RESOLVE = 'resolve',
  INSERT_CANNED_RESPONSE = 'insertCannedResponse',
  EXPAND_THREAD = 'expandThread',
  INSERT_SIGNATURE = 'insertSignature',
  ATTACH_FILES = 'attachFiles',
  CHANNEL_SEARCH = 'channelSearch',
  SHOW_CHAT = 'showChat',
  SHOW_EMAILS = 'showEmails',
  MARK_AS_SPAM = 'markAsSpam',

  // Navigation (jump to) shortcuts
  JUMP_TO_INBOX = 'jumpToInbox',
  JUMP_TO_EMAILS = 'jumpToEmails',
  JUMP_TO_CHATS = 'jumpToChats',
  JUMP_TO_CHANNEL = 'jumpToChannel',
  JUMP_TO_ARCHIVED = 'jumpToArchived',
  JUMP_TO_STARRED = 'jumpToStarred',
  JUMP_TO_SENT = 'jumpToSent',
  JUMP_TO_FOLDERS = 'jumpToFolders',
  JUMP_TO_AGENDA = 'jumpToAgenda',
  JUMP_TO_DRAFTS = 'jumpToDrafts',
  JUMP_TO_ASSIGNED = 'jumpToAssigned',
  JUMP_TO_SETTINGS = 'jumpToSettings',
  JUMP_TO_SEARCH = 'jumpToSearch',
  JUMP_TO_MY_LOOP_INBOX = 'jumpToMyLoopInbox',

  // Format
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  COPY = 'copy',
  PASTE = 'paste',
  PASTE_WITHOUT_FORMATTING = 'pasteWithoutFormatting',
  INSERT_HYPERLINK = 'insertHyperlink',
  NUMBERED_LIST = 'numberedList',
  BULLETED_LIST = 'bulletedList',
  INCREASE_INDENT = 'increaseIndent',
  DECREASE_INDENT = 'decreaseIndent',
  EDIT_LAST_COMMENT = 'editLastComment',

  // Shortcuts for list navigation
  LIST_UP = 'listUp',
  LIST_DOWN = 'listDown',

  // Shortcuts for mode switching
  TEXT_MODE = 'textMode',
  THREAD_MODE = 'threadMode',
  EXIT_THREAD_MODE = 'exitThreadMode',
  PREVIOUS_MODE = 'previousMode',
  MODE_SWITCH = 'modeSwitch',

  // Suggested
  INVITE_TEAMMATES = 'inviteTeammates',
  CHAT_WITH_LOOPBOT = 'chatWithLoopBot',
  TOGGLE_SHORTCUTS_DIALOG = 'toggleShortcutsDialog',

  // Create
  CREATE_SIGNATURE = 'createSignature',
  CREATE_CANNED_RESPONSE = 'createCannedResponse',
  CREATE_SHARED_INBOX = 'CreateSharedInbox',
  CREATE_TEAM = 'createTeam',
  ADD_PERSONAL_ACCOUNT = 'addPersonalAccount'
}

export enum ShortcutTrigger {
  DIALOG_CLICK = 'dialogClick',
  DIALOG_ENTER = 'dialogEnter',
  KEYBOARD_SHORTCUT = 'keyboardShortcut'
}

export interface ShortcutsData {
  hotKeyG?: boolean;
  shiftKey?: boolean;
  ctrlKey?: boolean;
  altKey?: boolean;
  key: string;
  code?: string;
  action?: KeyboardAction;

  // In some cases we only need to prevent default
  // behavior of key event but still propagate
  preventDefaultInModes?: KeyboardActionModeType[];

  // If set, this shortcut will be suggested in specified order
  suggestToNewUsersOrder?: number;
  suggestToExistingUsersOrder?: number;

  // Some shortcuts are supported by browser.
  // By setting this to true, propagation will be stopped
  // and we will handle shortcut on our own
  preventNativeSupport?: boolean;
}

export type KeyboardAction = NavigateKeyboardAction | BaseKeyboardAction | ModeSwitchKeyboardAction;

export interface BaseKeyboardAction {
  type: KeyboardActionType;
  name: string;
  category: KeyboardActionCategory;
  icon?: string;

  // Some shortcuts are supported by browser.
  // By setting this to true, subjects for this shortcut
  // will not be emitted. If called from popup, we will
  // trigger shortcut.
  useNativeSupport?: boolean;

  // List of modes in which keyboard shortcut will be emitted
  // [!] NOTE: if whitelist is defined, blacklist will be disregarded [!]
  whitelistedModes?: KeyboardActionModeType[];

  // List of modes in which keyboard shortcut will NOT be emitted
  blacklistedModes?: KeyboardActionModeType[];

  // List of base modes in which keyboard shortcut will NOT be emitted
  baseModeBlacklisted?: KeyboardActionModeType[];
}

export interface NavigateKeyboardAction extends BaseKeyboardAction {
  navigation: { commands: any[]; extras?: NavigationExtras };
  contact?: ContactModel;
}

export interface ModeSwitchKeyboardAction extends BaseKeyboardAction {
  fromMode: KeyboardActionMode;
  toMode: KeyboardActionMode;
  triggeredWithKeyboardAction?: boolean;
}

export interface KeyboardShortcutFilter {
  query: string;
  category?: KeyboardActionCategory;
}

export interface KeyboardShortcutData {
  action: KeyboardAction;
  mode: KeyboardActionMode;
  forAllListeners?: boolean;

  // Keyboard action trigger type used for tracking
  triggerType?: ShortcutTrigger;

  // Keyboard action trigger location used for tracking
  triggerLocation?: string;
}

const jumpToBlacklistModes = {
  blacklistedModes: [
    KeyboardActionModeType.MODAL,
    KeyboardActionModeType.COMPOSER_MODAL,
    KeyboardActionModeType.SHORTCUTS_MODAL,
    KeyboardActionModeType.TEXT,
    KeyboardActionModeType.TEXT_SECONDARY,
    KeyboardActionModeType.SEARCH
  ],
  baseModeBlacklisted: [KeyboardActionModeType.COMPOSER_MODAL]
};

export const shortcutByActionType: { [actionType: string]: ShortcutsData } = {
  [KeyboardActionType.REPLY_TO_ALL]: {
    shiftKey: true,
    key: 'A',
    action: {
      type: KeyboardActionType.REPLY_TO_ALL,
      name: 'Reply to all',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/reply-all.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.REPLY]: {
    key: 'R',
    action: {
      type: KeyboardActionType.REPLY,
      name: 'Reply',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/reply.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.FORWARD]: {
    key: 'F',
    action: {
      type: KeyboardActionType.FORWARD,
      name: 'Forward',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/forward.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.MARK_AS_READ]: {
    shiftKey: true,
    key: 'I',
    suggestToExistingUsersOrder: 2,
    action: {
      type: KeyboardActionType.MARK_AS_READ,
      name: 'Mark as read',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/mark-read.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.MARK_AS_UNREAD]: {
    shiftKey: true,
    key: 'U',
    suggestToNewUsersOrder: 14,
    suggestToExistingUsersOrder: 3,
    action: {
      type: KeyboardActionType.MARK_AS_UNREAD,
      name: 'Mark as unread',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/mark-unread.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.ASSIGN_SOMEONE]: {
    ctrlKey: true,
    shiftKey: true,
    preventNativeSupport: true,
    key: 'G',
    suggestToNewUsersOrder: 16,
    suggestToExistingUsersOrder: 5,
    action: {
      type: KeyboardActionType.ASSIGN_SOMEONE,
      name: 'Assign to a team member',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/assign.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.ARCHIVE]: {
    key: 'E',
    suggestToNewUsersOrder: 12,
    suggestToExistingUsersOrder: 8,
    action: {
      type: KeyboardActionType.ARCHIVE,
      name: 'Archive',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/archive.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.DELETE]: {
    key: 'DELETE',
    suggestToExistingUsersOrder: 9,
    action: {
      type: KeyboardActionType.DELETE,
      name: 'Delete',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/delete.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.MARK_AS_SPAM]: {
    shiftKey: true,
    ctrlKey: true,
    key: 'X',
    action: {
      type: KeyboardActionType.MARK_AS_SPAM,
      name: 'Mark as spam',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/octagon-exclamation.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.RESOLVE]: {
    ctrlKey: true,
    shiftKey: true,
    key: 'R',
    action: {
      type: KeyboardActionType.RESOLVE,
      name: 'Resolve',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/resolve.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.MOVE_TO_TEAM]: {
    ctrlKey: true,
    shiftKey: true,
    key: 'K',
    action: {
      type: KeyboardActionType.MOVE_TO_TEAM,
      name: 'Move to team',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/arrow-right.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.TAG_CONVERSATION]: {
    shiftKey: true,
    key: 'T',
    action: {
      type: KeyboardActionType.TAG_CONVERSATION,
      name: 'Tag conversation',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/hashtag.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.MOVE_TO_FOLDER]: {
    ctrlKey: true,
    shiftKey: true,
    key: 'F',
    action: {
      type: KeyboardActionType.MOVE_TO_FOLDER,
      name: 'Move to folder',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/folder.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  [KeyboardActionType.EXPAND_THREAD]: {
    ctrlKey: true,
    shiftKey: true,
    preventNativeSupport: true,
    key: 'T',
    action: {
      type: KeyboardActionType.EXPAND_THREAD,
      name: 'Expand / Collapse email',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/expand-conversation.svg'),
      blacklistedModes: [KeyboardActionModeType.SHORTCUTS_MODAL]
    }
  },
  [KeyboardActionType.STAR]: {
    key: 'S',
    action: {
      type: KeyboardActionType.STAR,
      name: 'Star',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/star.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  }
};

/**
 * All shortcuts are windows based.
 * Mac cmd key is mapped to ctrlKey.
 *
 * IMPORTANT!
 * All keys should be uppercase because of OS differences
 */
export const loopKeyboardShortcuts: ShortcutsData[] = [
  ////////////
  // ACTIONS
  ////////////
  {
    ctrlKey: true,
    key: 'N',
    suggestToNewUsersOrder: 2,
    suggestToExistingUsersOrder: 15,
    action: {
      type: KeyboardActionType.NEW_EMAIL,
      name: 'New email',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/new-email.svg'),
      blacklistedModes: [KeyboardActionModeType.SHORTCUTS_MODAL]
    }
  },
  {
    ctrlKey: true,
    shiftKey: true,
    preventNativeSupport: true,
    key: 'T',
    action: {
      type: KeyboardActionType.EXPAND_THREAD,
      name: 'Expand / Collapse email',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/expand-conversation.svg'),
      blacklistedModes: [KeyboardActionModeType.SHORTCUTS_MODAL]
    }
  },
  {
    shiftKey: true,
    key: 'ESCAPE',
    action: {
      type: KeyboardActionType.MARK_ALL_AS_READ,
      name: 'Mark all as read',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/mark-all-read.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION]
    }
  },
  {
    ctrlKey: true,
    key: 'P',
    action: {
      type: KeyboardActionType.PRINT,
      name: 'Print',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/print.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  shortcutByActionType[KeyboardActionType.DELETE],
  shortcutByActionType[KeyboardActionType.ARCHIVE],
  shortcutByActionType[KeyboardActionType.STAR],
  shortcutByActionType[KeyboardActionType.MARK_AS_UNREAD],
  shortcutByActionType[KeyboardActionType.MARK_AS_READ],
  shortcutByActionType[KeyboardActionType.FORWARD],
  shortcutByActionType[KeyboardActionType.MARK_AS_SPAM],
  {
    key: 'A',
    suggestToNewUsersOrder: 13,
    suggestToExistingUsersOrder: 1,
    action: {
      type: KeyboardActionType.REPLY_TO_ALL_QUICK_REPLY,
      name: 'Reply to all (quick reply)',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/quick-reply.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  shortcutByActionType[KeyboardActionType.REPLY_TO_ALL],
  shortcutByActionType[KeyboardActionType.REPLY],
  shortcutByActionType[KeyboardActionType.MOVE_TO_FOLDER],
  shortcutByActionType[KeyboardActionType.MOVE_TO_TEAM],
  shortcutByActionType[KeyboardActionType.TAG_CONVERSATION],
  {
    ctrlKey: true,
    shiftKey: true,
    key: 'A',
    suggestToNewUsersOrder: 15,
    suggestToExistingUsersOrder: 4,
    action: {
      type: KeyboardActionType.ASSIGN_SELF,
      name: 'Assign to myself',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/assign.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  shortcutByActionType[KeyboardActionType.ASSIGN_SOMEONE],
  shortcutByActionType[KeyboardActionType.RESOLVE],
  {
    ctrlKey: true,
    shiftKey: true,
    key: 'C',
    suggestToExistingUsersOrder: 6,
    action: {
      type: KeyboardActionType.INSERT_CANNED_RESPONSE,
      name: 'Insert a canned response',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/canned-response.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.COMPOSER_MODAL]
    }
  },
  {
    ctrlKey: true,
    shiftKey: true,
    key: 'S',
    suggestToExistingUsersOrder: 7,
    action: {
      type: KeyboardActionType.INSERT_SIGNATURE,
      name: 'Insert a signature',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/signature.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.COMPOSER_MODAL]
    }
  },
  {
    ctrlKey: true,
    shiftKey: true,
    key: 'U',
    action: {
      type: KeyboardActionType.ATTACH_FILES,
      name: 'Attach files',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/files.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.COMPOSER_MODAL]
    }
  },
  {
    ctrlKey: true,
    shiftKey: true,
    key: 'L',
    action: {
      type: KeyboardActionType.CHANNEL_SEARCH,
      name: 'Channel search',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/search.svg'),
      whitelistedModes: [
        KeyboardActionModeType.NAVIGATION,
        KeyboardActionModeType.THREAD,
        KeyboardActionModeType.TEXT,
        KeyboardActionModeType.TEXT_SECONDARY,
        KeyboardActionModeType.DROPDOWN
      ]
    }
  },
  {
    altKey: true,
    ctrlKey: true,
    key: '9',
    action: {
      type: KeyboardActionType.SHOW_CHAT,
      name: 'Show chat',
      category: KeyboardActionCategory.ACTIONS,
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD],
      blacklistedModes: [KeyboardActionModeType.SHORTCUTS_MODAL]
    }
  },
  {
    altKey: true,
    ctrlKey: true,
    key: '0',
    action: {
      type: KeyboardActionType.SHOW_EMAILS,
      name: 'Show emails',
      category: KeyboardActionCategory.ACTIONS,
      whitelistedModes: [KeyboardActionModeType.THREAD],
      blacklistedModes: [KeyboardActionModeType.SHORTCUTS_MODAL]
    }
  },
  {
    ctrlKey: true,
    key: 'ENTER',
    action: {
      type: KeyboardActionType.SEND,
      name: 'Send',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/send.svg')
    }
  },
  {
    key: 'ESCAPE',
    action: {
      type: KeyboardActionType.PREVIOUS_MODE,
      name: 'Close current dialog',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/esc.svg')
    }
  },
  {
    key: 'ENTER',
    preventDefaultInModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD],
    action: {
      type: KeyboardActionType.TEXT_MODE,
      name: 'Go to message bar',
      category: KeyboardActionCategory.ACTIONS,
      icon: require('shared/assets/img/svg/shortcuts/enter.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  ////////////
  // JUMP TO
  ////////////
  {
    hotKeyG: true,
    key: 'I',
    suggestToNewUsersOrder: 8,
    suggestToExistingUsersOrder: 11,
    action: {
      type: KeyboardActionType.JUMP_TO_INBOX,
      name: 'Jump to Inbox',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/account/inbox'] },
      icon: require('shared/assets/img/svg/shortcuts/email.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'O',
    action: {
      type: KeyboardActionType.JUMP_TO_CHATS,
      name: 'Jump to Chats',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/inbox/messages'] },
      icon: require('shared/assets/img/svg/shortcuts/messages.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'E',
    suggestToNewUsersOrder: 10,
    suggestToExistingUsersOrder: 13,
    action: {
      type: KeyboardActionType.JUMP_TO_ARCHIVED,
      name: 'Jump to Archived',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/archive'] },
      icon: require('shared/assets/img/svg/shortcuts/archive.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'S',
    action: {
      type: KeyboardActionType.JUMP_TO_STARRED,
      name: 'Jump to Starred',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/starred'] },
      icon: require('shared/assets/img/svg/shortcuts/star-full.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'L',
    suggestToNewUsersOrder: 7,
    suggestToExistingUsersOrder: 10,
    action: {
      type: KeyboardActionType.JUMP_TO_MY_LOOP_INBOX,
      name: 'Jump to My Loop Inbox',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/myloopinbox'] },
      icon: require('shared/assets/img/svg/shortcuts/email.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'K',
    action: {
      type: KeyboardActionType.JUMP_TO_AGENDA,
      name: 'Jump to Agenda',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/agenda'] },
      icon: require('shared/assets/img/svg/shortcuts/agenda.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'T',
    suggestToNewUsersOrder: 9,
    suggestToExistingUsersOrder: 12,
    action: {
      type: KeyboardActionType.JUMP_TO_SENT,
      name: 'Jump to Sent messages',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/sent'] },
      icon: require('shared/assets/img/svg/shortcuts/sent.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'D',
    action: {
      type: KeyboardActionType.JUMP_TO_DRAFTS,
      name: 'Jump to Drafts',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/drafts'] },
      icon: require('shared/assets/img/svg/shortcuts/edit-draft.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'F',
    action: {
      type: KeyboardActionType.JUMP_TO_FOLDERS,
      name: 'Jump to Folders',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/boards'] },
      icon: require('shared/assets/img/svg/shortcuts/folder.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    hotKeyG: true,
    key: 'A',
    suggestToNewUsersOrder: 11,
    suggestToExistingUsersOrder: 14,
    action: {
      type: KeyboardActionType.JUMP_TO_ASSIGNED,
      name: 'Jump to Assigned',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/assigned'] },
      icon: require('shared/assets/img/svg/shortcuts/assign.svg'),
      ...jumpToBlacklistModes
    }
  },
  {
    ctrlKey: true,
    key: ',',
    action: {
      type: KeyboardActionType.JUMP_TO_SETTINGS,
      name: 'Jump to Settings',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/user-settings'] },
      icon: require('shared/assets/img/svg/shortcuts/cogwheel.svg'),
      blacklistedModes: [KeyboardActionModeType.SHORTCUTS_MODAL, KeyboardActionModeType.COMPOSER_MODAL]
    }
  },
  {
    ctrlKey: true,
    key: 'F',
    action: {
      type: KeyboardActionType.JUMP_TO_SEARCH,
      name: 'Jump to Search',
      category: KeyboardActionCategory.JUMP_TO,
      navigation: { commands: ['/search'], extras: { queryParams: { openedViaShortcuts: true } } },
      icon: require('shared/assets/img/svg/shortcuts/search.svg'),
      blacklistedModes: [
        KeyboardActionModeType.SHORTCUTS_MODAL,
        KeyboardActionModeType.COMPOSER_MODAL,
        KeyboardActionModeType.FILE_PREVIEWER
      ]
    }
  },
  ///////////
  // FORMAT
  ///////////
  {
    ctrlKey: true,
    shiftKey: false,
    key: 'B',
    action: {
      type: KeyboardActionType.BOLD,
      name: 'Bold',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/bold.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.TEXT_SECONDARY],
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: false,
    key: 'I',
    action: {
      type: KeyboardActionType.ITALIC,
      name: 'Italic',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/italic.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.TEXT_SECONDARY],
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: false,
    key: 'U',
    action: {
      type: KeyboardActionType.UNDERLINE,
      name: 'Underline',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/underline.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.TEXT_SECONDARY],
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: false,
    key: 'C',
    action: {
      type: KeyboardActionType.COPY,
      name: 'Copy',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/copy.svg'),
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: false,
    key: 'V',
    action: {
      type: KeyboardActionType.PASTE,
      name: 'Paste',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/paste.svg'),
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: true,
    key: 'V',
    action: {
      type: KeyboardActionType.PASTE_WITHOUT_FORMATTING,
      name: 'Paste without formatting',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/paste.svg'),
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: false,
    key: 'K',
    action: {
      type: KeyboardActionType.INSERT_HYPERLINK,
      name: 'Insert hyperlink',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/link.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.TEXT_SECONDARY],
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: true,
    key: '7',
    code: 'Digit7',
    action: {
      type: KeyboardActionType.NUMBERED_LIST,
      name: 'Numbered list',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/numbered-list.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.TEXT_SECONDARY],
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: true,
    key: '8',
    code: 'Digit8',
    action: {
      type: KeyboardActionType.BULLETED_LIST,
      name: 'Bulleted list',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/bulleted-list.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.TEXT_SECONDARY],
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: false,
    key: ']',
    code: 'BracketRight',
    action: {
      type: KeyboardActionType.INCREASE_INDENT,
      name: 'Increase indent',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/increase-indent.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.TEXT_SECONDARY],
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    shiftKey: false,
    key: '[',
    code: 'BracketLeft',
    action: {
      type: KeyboardActionType.DECREASE_INDENT,
      name: 'Decrease indent',
      category: KeyboardActionCategory.FORMAT,
      icon: require('shared/assets/img/svg/shortcuts/decrease-indent.svg'),
      whitelistedModes: [KeyboardActionModeType.TEXT, KeyboardActionModeType.TEXT_SECONDARY],
      useNativeSupport: true
    }
  },
  {
    ctrlKey: true,
    key: 'ARROWUP',
    action: {
      type: KeyboardActionType.EDIT_LAST_COMMENT,
      name: '',
      category: KeyboardActionCategory.OTHER,
      whitelistedModes: [KeyboardActionModeType.TEXT]
    }
  },
  ////////////
  // NAVIGATE
  ////////////
  {
    key: 'ARROWUP',
    preventDefaultInModes: [KeyboardActionModeType.NAVIGATION],
    action: {
      type: KeyboardActionType.LIST_UP,
      name: '',
      category: KeyboardActionCategory.LIST_NAVIGATION,
      whitelistedModes: [KeyboardActionModeType.NAVIGATION]
    }
  },
  {
    key: 'ARROWDOWN',
    preventDefaultInModes: [KeyboardActionModeType.NAVIGATION],
    action: {
      type: KeyboardActionType.LIST_DOWN,
      name: '',
      category: KeyboardActionCategory.LIST_NAVIGATION,
      whitelistedModes: [KeyboardActionModeType.NAVIGATION]
    }
  },
  {
    key: 'ARROWRIGHT',
    action: {
      type: KeyboardActionType.THREAD_MODE,
      name: '',
      category: KeyboardActionCategory.MODE_SWITCH,
      whitelistedModes: [KeyboardActionModeType.NAVIGATION]
    }
  },
  {
    key: 'ARROWLEFT',
    action: {
      type: KeyboardActionType.EXIT_THREAD_MODE,
      name: '',
      category: KeyboardActionCategory.MODE_SWITCH,
      whitelistedModes: [KeyboardActionModeType.THREAD]
    }
  },
  ////////////
  // CREATE
  ////////////
  {
    altKey: true,
    key: 'S',
    code: 'KeyS',
    suggestToNewUsersOrder: 6,
    action: {
      type: KeyboardActionType.CREATE_SIGNATURE,
      name: 'Create a signature',
      category: KeyboardActionCategory.CREATE,
      navigation: { commands: ['/user-settings/signatures'], extras: { queryParams: { createSignature: true } } },
      icon: require('shared/assets/img/svg/shortcuts/signature.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  {
    altKey: true,
    key: 'C',
    code: 'KeyC',
    action: {
      type: KeyboardActionType.CREATE_CANNED_RESPONSE,
      name: 'Create a canned response',
      category: KeyboardActionCategory.CREATE,
      navigation: {
        commands: ['/user-settings/canned-responses'],
        extras: { queryParams: { createCannedResponse: true } }
      },
      icon: require('shared/assets/img/svg/shortcuts/canned-response.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  {
    altKey: true,
    key: 'I',
    code: 'KeyI',
    suggestToNewUsersOrder: 3,
    action: {
      type: KeyboardActionType.CREATE_SHARED_INBOX,
      name: 'Add shared inbox',
      category: KeyboardActionCategory.CREATE,
      icon: require('shared/assets/img/svg/shortcuts/shared-inbox.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  {
    altKey: true,
    key: 'T',
    code: 'KeyT',
    suggestToNewUsersOrder: 4,
    action: {
      type: KeyboardActionType.CREATE_TEAM,
      name: 'Create a team',
      category: KeyboardActionCategory.CREATE,
      icon: require('shared/assets/img/svg/shortcuts/team.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  {
    altKey: true,
    key: 'A',
    code: 'KeyA',
    action: {
      type: KeyboardActionType.ADD_PERSONAL_ACCOUNT,
      name: 'Add a personal account',
      category: KeyboardActionCategory.CREATE,
      icon: require('shared/assets/img/svg/shortcuts/add-more.svg'),
      whitelistedModes: [KeyboardActionModeType.NAVIGATION, KeyboardActionModeType.THREAD]
    }
  },
  /////////////
  // QUICK_START
  /////////////
  {
    key: undefined,
    suggestToNewUsersOrder: 1,
    action: {
      type: KeyboardActionType.INVITE_TEAMMATES,
      name: 'Invite teammates',
      category: KeyboardActionCategory.QUICK_START,
      icon: require('shared/assets/img/svg/shortcuts/invite-teammates.svg')
    }
  },
  {
    key: undefined,
    suggestToNewUsersOrder: 5,
    suggestToExistingUsersOrder: 16,
    action: {
      type: KeyboardActionType.CHAT_WITH_LOOPBOT,
      name: 'Chat with Loopbot',
      category: KeyboardActionCategory.QUICK_START,
      navigation: { commands: ['/channel/' + CONSTANTS.LOOP_BOT_ID + '/chat'] },
      icon: require('shared/assets/img/svg/shortcuts/chat-with-loopbot.svg')
    }
  },
  {
    key: 'D',
    ctrlKey: true,
    preventNativeSupport: true,
    action: {
      type: KeyboardActionType.TOGGLE_SHORTCUTS_DIALOG,
      name: 'Open shortcuts dialog',
      category: KeyboardActionCategory.QUICK_START,
      icon: require('shared/assets/img/svg/shortcuts/shortcuts-dialog.svg'),
      baseModeBlacklisted: [KeyboardActionModeType.COMPOSER_MODAL]
    }
  }
];

export const displayCategories: KeyboardActionCategory[] = [
  KeyboardActionCategory.QUICK_START,
  KeyboardActionCategory.ACTIONS,
  KeyboardActionCategory.JUMP_TO,
  KeyboardActionCategory.FORMAT,
  KeyboardActionCategory.CREATE
];

export const loopModes: KeyboardActionMode[] = [
  {
    type: KeyboardActionModeType.COMPOSER_MODAL,
    level: 0,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.NAVIGATION,
    level: 0,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.FILE_PREVIEWER,
    level: 0,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.SHORTCUTS_MODAL,
    level: 10,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.MODAL,
    level: 10,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.THREAD,
    level: 1,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.TEXT,
    level: 1,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.SEARCH,
    level: 1,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.SEARCH_SECONDARY,
    level: 1,
    isSkippable: false
  },
  {
    type: KeyboardActionModeType.DROPDOWN,
    level: 3,
    isSkippable: true
  },
  {
    type: KeyboardActionModeType.TEXT_SECONDARY,
    level: 11,
    isSkippable: true
  },
  {
    type: KeyboardActionModeType.FORM,
    level: 12,
    isSkippable: false
  }
];
