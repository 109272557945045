import { MonoTypeOperatorFunction, Observable, of, switchMap, take } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DropdownItem } from '../../interfaces/dropdown-item';

interface InputWithDropdownItems<T> {
  input: T;
  items: readonly DropdownItem[] | undefined;
}

export function filterEmptyActions<T>(
  availableItemsFn$: () => Observable<readonly DropdownItem[]> | undefined,
  additionalActions?: readonly DropdownItem[]
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => {
    return source.pipe(
      switchMap((input: T) => {
        const availableItems$ = availableItemsFn$();
        if (!availableItems$) {
          return of(undefined).pipe(
            map(() => {
              return { input: input, items: additionalActions ?? [] };
            })
          );
        }
        return availableItems$.pipe(
          take(1),
          map(items => {
            return { input: input, items: [...items, ...(additionalActions ?? [])] };
          })
        );
      }),
      filter((inputWithDropdownItems: InputWithDropdownItems<T>) => {
        return !inputWithDropdownItems.items || inputWithDropdownItems.items.length > 0;
      }),
      map((inputWithDropdownItems: InputWithDropdownItems<T>) => {
        return inputWithDropdownItems.input;
      })
    );
  };
}
