import * as _ from 'lodash';
import { ContactBase } from '@shared/api/api-loop/models';
import { ContactModel } from '../models-api-loop/contact/contact.model';

export enum HubEventType {
  EventUserTyping = 'EventUserTyping',
  EventConversationUpdated = 'EventConversationUpdated',
  EventBadgeCountChanged = 'EventBadgeCountChanged',
  EventUserActiveReply = 'EventUserActiveReply',
  EventUserStatus = 'EventUserStatus',
}

export class ContactChanges {
  updated: ContactModel[] = [];

  includesUpdateOf(contactId: string): boolean {
    return _.findIndex(this.updated, (update: ContactBase) => update.id === contactId) > -1;
  }
}
