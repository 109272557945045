/* tslint:disable */

/**
 * Split state enum
 */
export type SplitStateEnum = 'None' | 'Closed';

export module SplitStateEnum {
  export const NONE: SplitStateEnum = 'None';
  export const CLOSED: SplitStateEnum = 'Closed';

  export function values(): SplitStateEnum[] {
    return [NONE, CLOSED];
  }
}
