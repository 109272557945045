import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { from, fromEvent, Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { CONSTANTS } from '@shared/models/constants/constants';
import { Injectable } from '@angular/core';
import { SyncServiceStatus } from '@shared/models/sync/synchronization-service-status.model';
import { environment } from '@shared/environments/environment';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { LogTag } from '@dta/shared/models/logger.model';

@Injectable()
export abstract class EventHubObserverService {
  private connection: signalR.HubConnection;
  private _active: boolean = false;
  protected connectionEstablished$: Subject<any> = new Subject();

  constructor(
    protected _userEmail: string,
    protected _sharedUserManagerService: SharedUserManagerService,
  ) {}

  protected init() {
    this.initConnection();
    this.subscribeToConnectionClose();
  }

  private get connectionUrl(): string {
    if (environment.usingProxy) {
      return CONSTANTS.LOOP_WEB_SOCKET_URI_PATH;
    }

    return CONSTANTS.LOOP_WEB_SOCKET_URI;
  }

  private initConnection() {
    let options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return this._sharedUserManagerService.getTokenByEmail(this._userEmail);
      },
      logger: Logger,
    };

    this.connection = new signalR.HubConnectionBuilder().withUrl(this.connectionUrl, options).build();
  }

  private subscribeToConnectionClose() {
    this.connection.onclose((error?: Error) => {
      this._active = false;
      Logger.log('Connection to Loop WebSocket hub was closed', error);
    });
  }

  get active() {
    return this._active;
  }

  getStatus(): SyncServiceStatus {
    return this._active ? SyncServiceStatus.ACTIVE : SyncServiceStatus.INACTIVE;
  }

  startConnection(): Observable<any> {
    // Prevents starting connection multiple times, when connection takes a while to be established
    this._active = true;

    return from(this.connection.start()).pipe(
      tap(() => {
        Logger.log('Connection to Loop WebSocket hub was established');
        this.connectionEstablished$.next(true);
      }),
      catchError(err => {
        this._active = false;
        Logger.error(
          err,
          `Could not start connection to Loop WebSocket hub ${CONSTANTS.LOOP_WEB_SOCKET_URI}`,
          LogTag.SYNC,
        );

        return throwError(err);
      }),
    );
  }

  stopConnection() {
    return from(this.connection.stop()).pipe(
      catchError(err => {
        Logger.error(
          err,
          `Could not stop connection to Loop WebSocket hub:  ${CONSTANTS.LOOP_WEB_SOCKET_URI}`,
          LogTag.SYNC,
        );
        return throwError(err);
      }),
    );
  }

  /**
   * Listens to server event
   */
  protected on(methodName: string): Observable<any> {
    return fromEvent(this.connection, methodName);
  }

  /**
   * Sends a request to server and waits for response
   */
  protected invoke(methodName: string, ...args: any[]): Observable<any> {
    return from(this.connection.invoke(methodName, ...args));
  }

  /**
   * Sends request to server async
   */
  protected send(methodName: string, ...args: any[]): Observable<any> {
    return from(this.connection.send(methodName, ...args));
  }
}
