// ONLY FOR TRANSITION!!!

import { BaseDialogOptions, DialogData, DialogOptions } from '@dta/shared/models/dialog.model';
import { Display, Menu, Point } from 'electron';
import { Observable, Subject } from 'rxjs';
import HeapStatistics = Electron.HeapStatistics;
import BlinkMemoryInfo = Electron.BlinkMemoryInfo;
import ProcessMemoryInfo = Electron.ProcessMemoryInfo;
import SystemMemoryInfo = Electron.SystemMemoryInfo;
import { REMOTE_ELECTRON_METHODS } from '@dta/shared/communication/ipc-constants';

export abstract class ElectronService {
  //////////////
  // Hardcoded
  //////////////
  processType: 'renderer' = 'renderer';

  ///////////////////
  // ELECTRON NATIVE
  ///////////////////
  abstract screen: {
    getDisplayNearestPoint: (point: Point) => Display;
    getCursorScreenPoint: () => Point;
  };
  abstract ipcRenderer: {
    removeListener: (channel: string, listener: Function) => any;
    on: (channel: string, listener: Function) => void;
    once: (channel: string, listener: Function) => void;
    send: (channel: string, ...args: any) => void;
    removeAllListeners: (channel: string) => void;
  };

  ///////////////
  // HOT-WINDOW
  ///////////////
  abstract openDialogWindow(options: BaseDialogOptions, data?: Record<string, any>): Observable<any>;
  abstract openHotWindow(options: DialogOptions, data: DialogData): Observable<BrowserWindow>;
  abstract tryFocusToReplyPopOut(windowIdentifier: string | number): Observable<boolean>;
  abstract setWindowIdentifier(identifier: string, windowId: number): Observable<boolean>;

  //////////////////////////
  // REMOTE BROWSER WINDOW
  //////////////////////////
  abstract getBrowserReference(windowId: number): BrowserWindow;
  abstract createNewBrowserWindow(
    windowOptions: ElectronWindowOptions,
    eventListeners?: { [eventName in BrowserWindowSupportedEvents]?: Subject<any> }
  ): Observable<BrowserWindow>;

  /////////
  // OTHER
  /////////
  abstract get isElectronApp(): boolean;
  abstract setNativeTheme(isDark: boolean): Observable<void>;
  abstract handleDoubleClick(): Observable<void>;
  abstract openFileInNativeApp(path: string): Observable<void>;
  abstract showItemInFolder(path: string): Observable<void>;
  abstract shrinkLoginWindow(): Observable<void>;
  abstract expandLoginWindow(): Observable<void>;
  abstract remoteQuitThisWindow(): Observable<void>;
  abstract replaceMisspelling(text: string): Observable<void>;
  abstract setSpellchecker(languageIndex: number): Observable<void>;
  abstract getMachineId(): Observable<string>;
  abstract getMachineIp(): Observable<string>;
  abstract isForE2E(): Observable<boolean>;
  abstract getProcessData(): Observable<ProcessData>;
  abstract getStatistics(): Observable<Statistics>;
  abstract openExternal(url: string, sameWindow?: boolean): Observable<void>;
  abstract checkForUpdates({ isDeprecatedVersion }: { isDeprecatedVersion: boolean }): Observable<void>;
  abstract setSpellCheckerLanguage(languages: string[]): Observable<void>;
  abstract getSpellCheckerLanguages(): Observable<string[]>;
  abstract disableSpellChecker(): Observable<void>;
  abstract readMailFile(filePath: string, extension: string): Observable<any>;
}

export enum BrowserWindowSupportedEvents {
  CLOSED = 'closed',
  PAGE_TITLE_UPDATED = 'page-title-updated'
}

export interface BrowserWindow {
  windowId: number;

  loadURL(url: string): Observable<any>;
  focus(): Observable<any>;
  center(): Observable<any>;
  show(): Observable<any>;
  close(): Observable<any>;
  setTitle(title: string): Observable<any>;
  previewFile(path: string): Observable<any>;
  setMaximizable(maximizable: boolean): Observable<any>;
  setMenu(menu: Menu | null): Observable<any>;
  setSize(width: number, height: number): Observable<any>;
  setResizable(resizable: boolean): Observable<any>;
}

export interface ElectronWindowOptions {
  width?: any;
  height?: any;
  alwaysOnTop?: boolean;
  maximizable?: boolean;
  minimizable?: boolean;
  fullscreenable?: boolean;
  useContentSize?: boolean;
  modal?: boolean;
  resizable?: boolean;
  center?: boolean;
  show?: boolean;
  title?: string;
  webPreferences?: any;
  titleBarStyle?: 'hidden' | 'default' | 'hiddenInset' | 'customButtonsOnHover';
}

export interface ProcessData {
  systemVersion: string;
  platform: string;
  version: string;
  installedViaMsi: boolean;
  versions: {
    chrome: string;
    electron: string;
  };
}

export interface Statistics {
  heapStatistics: HeapStatistics;
  blinkMemoryInfo: BlinkMemoryInfo;
  processMemoryInfo: ProcessMemoryInfo;
  systemMemoryInfo: SystemMemoryInfo;
}
