import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class EmojiPaletteService {
  private emojiSelectSubject: Subject<string> = new Subject<string>();
  private showEmojiPaletteSubject: Subject<EmojiPaletteData> = new Subject<EmojiPaletteData>();
  private hideEmojiPaletteSubject: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  get showEmojiPalette$(): Observable<EmojiPaletteData> {
    return this.showEmojiPaletteSubject.asObservable();
  }

  get hidePalette$(): Observable<boolean> {
    return this.hideEmojiPaletteSubject.asObservable();
  }

  get selectEmoji$(): Observable<string> {
    return this.emojiSelectSubject.asObservable();
  }

  selectEmoji($event: string) {
    this.emojiSelectSubject.next($event);
  }

  showPalette($event: EmojiPaletteData) {
    this.showEmojiPaletteSubject.next($event);
  }

  hidePalette() {
    this.hideEmojiPaletteSubject.next(true);
  }
}

export interface EmojiPaletteData {
  x: number;
  y: number;
  translate: boolean;
}
