<ng-container *ngIf="baseFormControl">
  <div
    class="form-control-wrapper"
    [class.flex-col]="baseFormControl.labelOnTop"
    [class.items-center]="!baseFormControl.labelOnTop"
  >
    <label *ngIf="baseFormControl.label" [htmlFor]="baseFormControl._id">{{ baseFormControl.label }}</label>
    <div class="flex flex-col flex-1 h-100">
      <ng-content></ng-content>
    </div>
    <!--    <ng-container *ngIf="baseFormControl.addOnAfter">-->
    <!--      <ng-container *ngComponentOutlet="baseFormControl.addOnAfter"></ng-container>-->
    <!--    </ng-container>-->
  </div>
</ng-container>
