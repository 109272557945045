import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedTagBase } from '@shared/api/api-loop/models';
import { SharedTagAssigneeModel } from '../../../../shared/models-api-loop/shared-tag/shared-tag.model';

@Component({
  selector: 'label-tag',
  templateUrl: './label-tag.html',
  styleUrls: ['./label-tag.scss'],
})
export class LabelTagComponent {
  ////////
  // SVGs
  ////////
  assignSvg = require('shared/assets/img/svg/small-assigned-to-me.svg');
  userSelectedSvg = require('shared/assets/img/svg/user-selected.svg');
  removeSvg = require('shared/assets/img/svg/close-icon.svg');
  dropdownSvg = require('shared/assets/img/svg/dropdown.svg');
  lockSvg = require('shared/assets/img/svg/lock.svg');

  ////////////////////
  // Inputs, Outputs
  ////////////////////
  @Input() content: string;
  @Input() color: string;
  @Input() isOnHeader: boolean;
  @Input() titleContent: string = '';
  @Input() delegating: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isClickable: boolean = false;
  @Input() isPrivate: boolean = false;
  @Input() assigneeTag: SharedTagAssigneeModel = undefined;
  @Input() displayWideContent: boolean = false;
  @Input() displayAssignIcon: boolean = false;
  @Input() displaySharelistIcon: boolean = false;
  @Input() displayRemoveIcon: boolean = false;
  @Input() displayDropdownIcon: boolean = false;
  @Input() extraIcon: NodeRequire = undefined;

  @Output() removeIconClick: EventEmitter<SharedTagBase> = new EventEmitter<SharedTagBase>();

  ///////////
  // State
  ///////////
  disabledColor = '#9d9ba0';

  constructor() {}

  get textColor(): string {
    if (this.isDisabled) {
      return this.disabledColor;
    }

    return this.color;
  }

  get borderColor(): string {
    if (this.isDisabled) {
      return this.disabledColor + '1a';
    }

    return this.color;
  }

  get backgroundColor(): string {
    if (this.isDisabled) {
      return this.disabledColor + '1a';
    }

    return this.color + '1a';
  }

  onRemoveIconClick(label: SharedTagBase) {
    this.removeIconClick.emit(label);
  }
}
