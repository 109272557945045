/* tslint:disable */
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/models/constants/constants';

/**
 * Contains global configuration for API services
 */
@Injectable()
export class ApiConfiguration {
  rootUrl: string = CONSTANTS.LOOP_API_ROOT_URI;
}
