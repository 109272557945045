import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { NavigationBarService } from '@shared/modules/main/navigation-bar/navigation-bar.service';
import { GroupType } from '@shared/api/api-loop/models/group-type';
import { ActionEnum } from '@shared/api/api-loop/models/action-enum';
import { SharedTagFolder } from '@shared/api/api-loop/models/shared-tag-folder';
import { FolderHelper } from '@shared/services/data/folder/folder-helper';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { UnreadStateChange } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { CardUnreadService } from '@shared/services/data/card-unread/card-unread.service';
import { FilterEnum } from '@shared/api/api-loop/models/filter-enum';
import { ViewsFilter } from '@dta/shared/models/collection.model';
import { ClickTrackingLocation } from '@dta/shared/services/tracking/tracking.constants';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { TooltipService } from '@dta/ui/components/common/tooltip/tooltip.service';
import { MiddleView } from '@dta/shared/models/middle-view.model';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import * as _ from 'lodash';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { exhaustMap, from, merge, Observable, of, Subscription } from 'rxjs';
import { SharedTagFolderModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';

@Directive()
@AutoUnsubscribe()
export abstract class NavigationBarComponent implements OnInit, OnDestroy {
  protected readonly GroupType: typeof GroupType = GroupType;

  /////////
  // SVGs
  /////////
  protected readonly barsSvg: string = require('@shared/assets/img/svg/bars.svg');
  protected readonly backSvg: string = require('@shared/assets/img/svg/back.svg');
  protected readonly inboxSvg: string = require('@shared/assets/img/svg/inbox-icon.svg');
  protected readonly assignedToMeSvg: string = require('shared/assets/img/svg/small-assigned-to-me.svg');
  protected readonly chatSvg: string = require('@shared/assets/img/svg/chats-with-padding.svg');
  protected readonly draftSvg: string = require('@shared/assets/img/svg/drafts-icon.svg');
  protected readonly sendSvg: string = require('@shared/assets/img/svg/sent-icon-small.svg');
  protected readonly archiveSvg: string = require('@shared/assets/img/svg/archived-small.svg');
  protected readonly starredSvg: string = require('@shared/assets/img/svg/star-full-small.svg');
  protected readonly allMessagesSvg: string = require('@shared/assets/img/svg/all-small.svg');
  protected readonly trashSvg: string = require('@shared/assets/img/svg/delete-icon.svg');
  protected readonly spamSvg: string = require('@shared/assets/img/svg/spam-icon.svg');
  protected readonly mutedSvg: string = require('@shared/assets/img/svg/mute.svg');
  protected readonly snoozeSvg: string = require('@shared/assets/img/svg/snooze.svg');
  protected readonly attachments: string = require('@shared/assets/img/svg/discussion-files.svg');

  ///////////////////
  // State variables
  ///////////////////
  hideBar: boolean = false;
  mergedFolders: FolderNode[] = [];
  pinnedFolders: FolderNode[] = [];
  notPinnedFolder: FolderNode;
  pinnedFolderState: string[] = [];
  inboxUnreads: number;
  chatUnreads: number;
  assignedItems: number;

  protected abstract view: MiddleView;
  abstract navigationTitle: string;
  abstract baseUrl: string;

  private folderSub: Subscription;
  private unreadSub: Subscription;
  private foldersModelSub: Subscription;

  protected readonly FilterEnum: typeof FilterEnum = FilterEnum;

  /////////////////
  // Input/Output
  /////////////////
  @Input() viewBlacklist: ViewsFilter[] = [];
  @Input() noDropdownOptions: boolean = false;

  constructor(
    protected _userManagerService: UserManagerService,
    protected _trackingService: TrackingService,
    protected _tooltipService: TooltipService,
    protected _navigationBarService: NavigationBarService,
    protected _changeDetection: ChangeDetectorRef,
    protected _storageService: StorageService,
    protected _folderHelper: FolderHelper,
    protected _router: Router,
    protected _cardUnreadService: CardUnreadService,
  ) {}

  ngOnInit(): void {
    this.setNavigationBar();
    this.subscribeToFolderChanged();
    this.subscribeToUnreads();
  }

  get folderSelected(): string {
    return this._navigationBarService?.showFolderView ? undefined : this._navigationBarService?.folderSelected?.id;
  }

  ngOnDestroy(): void {}

  initialNavigationBar(): void {}

  abstract getContextFolders(): Observable<FolderNode[]>;

  abstract getUnreads(forUserEmail: string): Observable<any>;

  getAssignedCounts(_forUserEmail: string): Observable<any> {
    return of(undefined);
  }

  onSelectionChange(view: FolderNode): void {
    // Track user click action
    this._trackingService.trackUserClick(
      this._userManagerService.getCurrentUserEmail(),
      ClickTrackingLocation.ViewSearch,
      'Select view',
      { View: view.name },
    );

    this._navigationBarService.viewChanged(view);
    this.closeFolderBrowser();
  }

  protected closeFolderBrowser(): void {
    this._navigationBarService.closeFolderView();
  }

  protected navigateToFolderBrowser(): void {
    this._navigationBarService.toggleFolderView();
  }

  protected getViewKey(): MiddleView | string {
    return this.view;
  }

  protected get pinnedViewsKey(): string {
    let viewContext = this.getViewKey();
    let userContext = this._storageService.getKey(
      this._userManagerService.getCurrentUserEmail(),
      StorageKey.pinnedViews,
    );

    return `${userContext}_${viewContext}`;
  }

  protected triggerChangeDetection(): void {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }

  protected persistPinnedFoldersToLocalStorage(folders: FolderBase[]): void {
    const filterState = _.cloneDeep(folders.filter(option => option.pinned).map(option => option.id));

    this._storageService.setStringifiedItem(this.pinnedViewsKey, filterState);
  }

  protected sortFoldersByPinned(): void {
    this.mergedFolders.sort((a, b) => this.pinnedFolderState.indexOf(a.id) - this.pinnedFolderState.indexOf(b.id));
  }

  private subscribeToUnreads(): void {
    this.unreadSub?.unsubscribe();
    this.unreadSub = merge(
      /**
       * Trigger on unread state change (per user)
       */
      SharedSubjects._unreadStateChange$.pipe(
        map((data: UnreadStateChange) => {
          return [data.forUserEmail];
        }),
      ),
    )
      .pipe(
        mergeMap((emails: string[]) => from(emails)),
        filter((forUserEmail: string) => !!forUserEmail),
        exhaustMap((forUserEmail: string) => {
          return this.getUnreads(forUserEmail);
        }),
        exhaustMap((forUserEmail: string) => {
          return this.getAssignedCounts(forUserEmail);
        }),
      )
      .subscribe();
  }

  protected subscribeToFolderChanged(): void {
    this.folderSub?.unsubscribe();
    this.folderSub = this._navigationBarService.foldersPinned$
      .pipe(
        tap((folder: FolderBase) => {
          // Set initial state if not set
          if (_.isEmpty(this.pinnedFolderState)) {
            this.persistPinnedFoldersToLocalStorage(this._folderHelper.getFlatFolders(this.mergedFolders, []));
          } else {
            if (folder.pinned) {
              this.pinnedFolderState.push(folder.id);
            } else {
              this.pinnedFolderState = _.filter(this.pinnedFolderState, _folder => _folder !== folder.id);
            }
            this._storageService.setStringifiedItem(this.pinnedViewsKey, this.pinnedFolderState);
          }
          this.sortFoldersByPinned();
          this.pinnedFolders = this.mergedFolders.filter(folder => folder.pinned);
        }),
      )
      .subscribe();
  }

  protected setNavigationBar(): void {
    this.initialNavigationBar();

    this.foldersModelSub?.unsubscribe();
    this.foldersModelSub = this.getContextFolders()
      .pipe(
        tap((folders: FolderNode[]) => {
          this.mergedFolders = this._folderHelper.getFlatFolders(folders, []);
          this.pinnedFolders = this.mergedFolders.filter(folder => folder.pinned);
          this._navigationBarService.viewChanged(this.pinnedFolders?.[0]);
          this._navigationBarService.setFolders(folders);
          this.pinnedFolderState = this._storageService.getParsedItem(this.pinnedViewsKey) || [];
          this.sortFoldersByPinned();
        }),
      )
      .subscribe();
  }
}

export class FolderBase {
  constructor(
    public name: string | ViewsFilter,
    public id: string,
    public pinned?: boolean,
    public svg?: string,
    public extraClass?: string,
    public highlightOnHover?: boolean,
  ) {}
}

export class FolderNode extends FolderBase {
  folder?: SharedTagFolder;
  children?: FolderNode[];
  parent?: FolderNode;
  indent?: number;
  openChildren?: boolean;
  index?: number; // Used for indexing in dropdown

  constructor(
    public name: string | ViewsFilter,
    public id: string,
    public pinned?: boolean,
    public svg?: string,
    public extraClass?: string,
    public filterEnum?: FilterEnum,
    public actionEnum?: ActionEnum,
  ) {
    super(name, id, pinned, svg, extraClass, false);
    this.folder = {
      $type: SharedTagFolderModel.type,
    };
  }

  get isSystemFolder(): boolean {
    return !!this.filterEnum;
  }

  isMoveToInboxAvailable(): boolean {
    return (
      this.filterEnum === FilterEnum.DELETED ||
      this.filterEnum === FilterEnum.ARCHIVED ||
      (!!this.folder && this.filterEnum !== FilterEnum.INBOX)
    );
  }

  isDeleteAvailable(): boolean {
    return this.filterEnum !== FilterEnum.DELETED && this.filterEnum !== FilterEnum.STARRED;
  }

  isArchiveAvailable(): boolean {
    return this.filterEnum !== FilterEnum.ARCHIVED && this.filterEnum !== FilterEnum.STARRED;
  }
}

export function getAllFolderChildren(folder: FolderNode): FolderNode[] {
  let result: FolderNode[] = [];

  if (folder.children && folder.children.length > 0) {
    result = [...result, ...folder.children];

    folder.children.forEach(childFolder => {
      result = [...result, ...getAllFolderChildren(childFolder)];
    });
  }

  return result;
}
