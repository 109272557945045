import { inject } from '@angular/core';
import { LRUCache } from 'lru-cache';
import {
  catchError,
  EMPTY,
  EmptyError,
  MonoTypeOperatorFunction,
  Observable,
  pipe,
  ReplaySubject,
  share,
  throwIfEmpty
} from 'rxjs';
import { StorageProviderService } from './storage-provider/storage-provider';

export abstract class BaseObservableCacheService<K> {
  protected storageProviderService: StorageProviderService = inject(StorageProviderService);

  protected abstract readonly cacheStorage: LRUCache<string, any>;

  abstract getOrStoreObservable(key: K, observable$: Observable<any>, expiration: number): Observable<any>;

  protected storeObservable(
    storage: LRUCache<string, Observable<any>>,
    key: string,
    observable$: Observable<any>,
    expiration?: number
  ): Observable<any> {
    const _observable$ = observable$.pipe(
      this.resetCacheWhenObservableCompleteWithEMPTY(storage, key),
      share({
        connector: () => new ReplaySubject(1),
        resetOnComplete: false,
        resetOnRefCountZero: false
      })
    );

    storage.set(key, _observable$, {
      ttl: expiration
    });
    return _observable$;
  }

  clear(key: string): void {
    if (this.cacheStorage.has(key)) {
      this.cacheStorage.delete(key);
    }
  }

  resetCacheWhenObservableCompleteWithEMPTY: (cachedStorage: LRUCache<string, Observable<any>>, key: string) => any = <
    T
  >(
    cachedStorage: LRUCache<string, Observable<any>>,
    key: string
  ): MonoTypeOperatorFunction<T> =>
    pipe(
      throwIfEmpty(),
      catchError(err => {
        if (err instanceof EmptyError) {
          try {
            cachedStorage.delete(key);
          } catch {
            // noop
          }
          return EMPTY;
        }
        try {
          cachedStorage.delete(key);
        } catch {
          // noop
        }
        throw err;
      })
    );
}
