import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { BasePushSynchronizationService } from '../base-push-synchronization/base-push-synchronization.service';
import { ApiService } from '@shared/api/api-loop/api.module';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { ListOfTagsModel } from '@dta/shared/models-api-loop/tag.model';
import { map, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CommentChatModel, CommentMailModel, CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { Logger } from '@shared/services/logger/logger';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { StateUpdates } from '@dta/shared/models/state-updates';
import {
  CardAppointmentModel,
  CardChatModel,
  CardMailModel,
  CardModel,
  CardSharedModel,
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { ContactModel, GroupModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { CardService } from '@shared/services/data/card/card.service';
import { ContactService } from '@shared/services/data/contact/contact.service';

@Injectable()
export class TagPushSynchronizationService extends BasePushSynchronizationService<ListOfTagsModel> {
  constructor(
    protected _api: ApiService,
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
  ) {
    super();
  }

  get constructorName(): string {
    return 'TagPushSynchronizationService';
  }

  protected synchronize(forUserEmail: string, tags: ListOfTagsModel[]): Observable<ListOfTagsModel[]> {
    return this._api.TagApiService.Tag_UpdateTags({ tags: tags }, forUserEmail).pipe(
      map((response: ListOfTagsModel[]) => ListOfTagsModel.createList(response, false)),
      mergeMap((response: ListOfTagsModel[]) => {
        return this.retryCommentTagsUpdateWithCorrectRevision(forUserEmail, response, tags);
      }),
    );
  }

  protected afterSynchronize(forUserEmail: string, tags: ListOfTagsModel[]): Observable<any> {
    let watch = new StopWatch(this.constructorName + '.updateTags', ProcessType.SERVICE, forUserEmail);
    let stateUpdates = new StateUpdates();

    /**
     * Group Tags
     */
    let tagsByParentType = _.groupBy(tags, listOfTags => listOfTags.parent.$type);
    let cardTags = _.compact(
      _.concat(
        tagsByParentType[CardMailModel.type],
        tagsByParentType[CardSharedModel.type],
        tagsByParentType[CardAppointmentModel.type],
        tagsByParentType[CardChatModel.type],
      ),
    );
    let contactTags = _.compact(_.concat(tagsByParentType[UserModel.type], tagsByParentType[GroupModel.type]));

    return of(undefined).pipe(
      mergeMap((updatedCommentTags: ListOfTagsModel[]) => {
        watch.log('updateCommentTags');
        return this._commentService.updateCommentsTags(forUserEmail, updatedCommentTags);
      }),
      mergeMap((comments: CommentModel[]) => {
        stateUpdates.add(comments);

        return this._cardService.findOrFetchCommentCards(forUserEmail, comments);
      }),
      tap((cards: CardModel[]) => {
        stateUpdates.add(cards);
      }),
      /**
       * Cards
       */
      mergeMap(() => {
        watch.log('updateCardsTags');
        return this._cardService.updateCardsTags(forUserEmail, cardTags);
      }),
      tap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);
      }),
      mergeMap(() => {
        watch.log('saveAllCards');
        return this._cardService.saveAll(forUserEmail, stateUpdates.cards);
      }),
      tap((_stateUpdates: StateUpdates) => {
        stateUpdates.mergeWith(_stateUpdates);
      }),
      /**
       * Contacts
       */
      mergeMap(() => {
        watch.log('updateContactsTags');
        return this._contactService.updateContactsTags(forUserEmail, contactTags);
      }),
      tap((contacts: ContactModel[]) => {
        stateUpdates.add(contacts);
      }),
      /**
       * Publish
       */
      tap(() => {
        watch.log('publish');
        PublisherService.publishEvent(forUserEmail, stateUpdates.all);
        PublisherService.publishEvent(forUserEmail, stateUpdates.remove, PublishEventType.Remove);
      }),
      tap(() => {
        watch.log('done');
      }),
    );
  }

  private retryCommentTagsUpdateWithCorrectRevision(
    forUserEmail: string,
    newTags: ListOfTagsModel[],
    oldTags: ListOfTagsModel[],
  ): Observable<ListOfTagsModel[]> {
    let updatedTagsByParentId = _.keyBy(newTags, 'parent.id');
    let tagsWithUpdatedRevision = [];
    let otherTags = [];

    let oldChatMailCommentTags = oldTags.filter(
      tag => tag.parent.$type === CommentChatModel.type || tag.parent.$type === CommentMailModel.type,
    );

    _.forEach(oldChatMailCommentTags, tag => {
      if (tag.revision >= updatedTagsByParentId[tag.parent.id].revision) {
        tag.revision = updatedTagsByParentId[tag.parent.id].revision;
        otherTags.push(tag);
      } else {
        tagsWithUpdatedRevision.push(updatedTagsByParentId[tag.parent.id]);
      }
    });

    if (otherTags && otherTags.length > 0) {
      Logger.customLog(
        'Revision for tags not updated, retrying with revision from response. Revision not updated for the following tags: ' +
          JSON.stringify(otherTags),
        LogLevel.INFO,
        LogTag.INTERESTING_ERROR,
        true,
        'Revision for tags not updated, retrying with revision from response',
      );

      return this._api.TagApiService.Tag_UpdateTags({ tags: otherTags }, forUserEmail).pipe(
        mergeMap((response: ListOfTagsModel[]) => {
          return of([...response, ...tagsWithUpdatedRevision]);
        }),
      );
    }

    return of(tagsWithUpdatedRevision);
  }

  protected generalSynchronizationErrorHandler(
    forUserEmail: string,
    err: HttpErrorResponse,
    tags: ListOfTagsModel[],
  ): Observable<any> {
    if ([0, 503, 504].includes(err.status)) {
      // we lost connection and will retry
      return EMPTY;
    } else if (err.status === 401) {
      // unauthorized - token should be refreshed and we'll retry
      return throwError(err);
    }

    // we will not be able to sync those tags as we will always get the same error.
    // We remove them from the queue.
    return of(tags);
  }
}
