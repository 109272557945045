import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateTimeControlComponent } from '@shared/modules/form-controls/date-time-control/date-time-control.component';
import { DateControlModule } from '@shared/modules/form-controls/date-control/date-control.module';
import { TimeMaskDirective } from '@shared/modules/form-controls/date-time-control/time-mask.directive';

@NgModule({
  declarations: [DateTimeControlComponent, TimeMaskDirective],
  imports: [CommonModule, DateControlModule, FormsModule],
  exports: [DateTimeControlComponent]
})
export class DateTimeControlModule {}
