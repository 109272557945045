import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalFormDefaultFooterButtonsComponent } from './modal-form-default-footer-buttons.component';
import { ButtonModule } from '@shared/ui/button/button.module';

@NgModule({
  declarations: [ModalFormDefaultFooterButtonsComponent],
  imports: [CommonModule, ButtonModule],
  exports: [ModalFormDefaultFooterButtonsComponent]
})
export class ModalFormDefaultFooterButtonsModule {}
