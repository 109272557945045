import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnoozeDropdownComponent } from '@shared/modules/conversations/components/conversation-header/dropdowns/snooze-dropdown/snooze-dropdown.component';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { SnoozeApiActionsModule } from '@shared/modules/tags/services/snooze-api-actions/snooze-api-actions.module';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';
import { DatePickerModule } from '@dta/ui/components/date-picker/date-picker.module';
import { TooltipModule } from '@dta/ui/components/common/tooltip/tooltip-module';

@NgModule({
  declarations: [SnoozeDropdownComponent],
  imports: [CommonModule, DatePickerModule, GraphicsModule, TooltipModule, SnoozeApiActionsModule, ContactModule],
  exports: [SnoozeDropdownComponent],
})
export class SnoozeDropdownModule {}
