/* tslint:disable */

/**
 * Possible event types
 */
export type TagRights = 'Edit' | 'Assign' | 'View';

export module TagRights {
  export const EDIT: TagRights = 'Edit';
  export const ASSIGN: TagRights = 'Assign';
  export const VIEW: TagRights = 'View';

  export function values(): TagRights[] {
    return [EDIT, ASSIGN, VIEW];
  }
}
