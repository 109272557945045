import { Directive } from '@angular/core';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { FileService } from '@shared/services/data/file/file.service';
import { Observable, of, switchMap } from 'rxjs';
import { ThumbnailSize } from '@shared/api/api-loop/models';
import { catchError, defaultIfEmpty, filter, map, mergeMap, tap } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { exists } from 'fs';
import { FileUrlStorageService } from '../../file-storage/file-url-storage/file-url-storage.service';

@Directive()
export class FileServiceWeb extends FileService {
  showLoaderWhenUploadingFile = true;

  get constructorName(): string {
    return 'FileServiceWeb';
  }

  storeLocalFile(
    forUserEmail: string,
    file: File,
    hidden: boolean = false,
    base64: any = undefined
  ): Observable<FileModel> {
    return this.uploadAndDecorateFile(forUserEmail, file, hidden);
  }

  downloadFile(forUserEmail: string, file: FileModel): Observable<any> {
    if (
      !file.urlLink ||
      FileUrlStorageService.isLinkExpired(FileUrlStorageService.getLinkExpirationDate(file.urlLink))
    ) {
      return this._fileApiService.File_GetFile({ id: file.id }, forUserEmail).pipe(
        switchMap(file => {
          return this.fileDownloadService.downloadFromLink(file.urlLink, { fileName: file.name });
        })
      );
    }

    return this.fileDownloadService.downloadFromLink(file.urlLink, { fileName: file.name });
  }

  beforeSyncServiceInit(forUserEmail: string): Observable<any> {
    return this._fileDaoService.removeCollection(forUserEmail);
  }
}
