import * as _ from 'lodash';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { fromEvent, merge, mergeMap, Observable, of, Subscription } from 'rxjs';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ContactModel, GroupModel, UserModel } from '../../../../shared/models-api-loop/contact/contact.model';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { TrackingService } from '../../../../shared/services/tracking/tracking.service';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { debounceTime, distinctUntilChanged, take, tap } from 'rxjs/operators';
import { ContactFilter, FilterByMethod } from '../../../../shared/models/contact.model';
import { WebOnboardingService } from '@shared/services/web-onboarding/web-onboarding.service';
import { checkIfOS, EmailUtils, OperatingSystem } from '@dta/shared/utils/common-utils';
import { ContactBase, ContactType, GroupSubType, GroupType } from '@shared/api/api-loop/models';
import { UserAvailabilityStatusService } from '@shared/services/data/availability-status/user-availability-status/user-availability-status.service';

@AutoUnsubscribe()
@Component({
  selector: 'autosuggest',
  templateUrl: './autosuggest.html',
  styleUrls: ['./autosuggest.scss']
})
export class AutosuggestComponent implements OnInit, OnDestroy, OnChanges {
  ///////////
  // Inputs
  ///////////
  @Input() query$: Observable<any>;
  @Input() filter: ContactBase[] = [];
  @Input() extraFilter: ContactBase[] = [];
  @Input() ignoreGroups: boolean = true;
  @Input() ignoreNotRegistered: boolean = false;
  @Input() currentIndexEvent;
  @Input() searchType;
  @Input() limit: number = 10;
  @Input() assignSuggestionIds: string[];
  @Input() availableContactsIds: string[];
  @Input() extraClass: string = '';
  @Input() startQuery: string = '';
  @Input() closeOnOutsideClick: boolean = true;
  @Input() showWithEmptyQuery: boolean;
  @Input() showAvatar: boolean;
  @Input() selectedContact: boolean;
  @Input() neverHide: boolean;
  @Input() excludeMe: boolean;
  @Input() showEmptyContainer: boolean;
  @Input() isForMentions: boolean;
  @Input() isFocused: boolean;
  @Input() openUp: boolean;
  @Input() selfOnTop: boolean;
  @Input() disablePopup: boolean;
  @Input() firstItemId: string;
  @Input() currentAssigneeId: string;
  @Input() showInvites: boolean;
  @Input() createContactIfEmpty: boolean;
  @Input() filterForWorkspaceMembers: boolean = false;
  @Input() contactTypes: ContactType[] = [ContactType.USER, ContactType.GROUP];

  // View info
  @Input() isAssigneesDropdown: boolean; // Dropdown for assign action
  @Input() isOnSharedInboxLoopin: boolean; // Is loopin in shared inbox
  @Input() isOnCannedResponseCreate: boolean; // For canned responses
  @Input() isOnLoopInPopup: boolean; // Is on loopin (use for )
  @Input() isOnLoopInPopupMentions: boolean; // Mentions for loopin popup (creating loopin from email)
  @Input() isOnSearch: boolean; // Search (also via quick jump)
  @Input() isOnNewMessage: boolean; // New chat message modal
  @Input() isOnChat: boolean; // Chat with group or user
  @Input() isOnReplyTo: boolean; // Suggestions on reply to popup
  @Input() isOnCreateSharedInbox: boolean;
  @Input() isOnComposer: boolean;
  @Input() showAvailabilityStatus: boolean;
  @Input() groupSubTypes: GroupSubType[] = [
    GroupSubType.NORMAL,
    GroupSubType.MANAGED_TEAM,
    GroupSubType.PERSONAL_INBOX,
    GroupSubType.SHARED_INBOX
  ];

  @Output() clicked = new EventEmitter();
  @Output() selectedItemEvent = new EventEmitter();

  /////////
  // SVGs
  /////////
  checkMarkSvg = require('shared/assets/img/svg/filter-chooser-checkmark.svg');

  ////////////////////
  // State variables
  ////////////////////
  contacts: ContactModel[] = [];
  currentIndex: number = 0;
  increment: number = 0;
  teamSeparatorIndex: number = -1;
  currentQuery: string = '';
  initialSelection: boolean = true;
  loading: boolean = true;
  keyboardNavigation: boolean;
  isVisible: boolean;
  isDarwin: boolean;
  showInvite: boolean;
  firstUnavailableContact: UserModel;

  /////////////////
  // Subscriptions
  /////////////////
  private querySub: Subscription;
  private currentIndexSub: Subscription;
  private mouseMoveSub: Subscription;
  private contactSub: Subscription;

  constructor(
    @Inject(ElementRef) public _elementRef,
    private _changeDetection: ChangeDetectorRef,
    private _userManagerService: UserManagerService,
    private _contactService: ContactService,
    private _userAvailabilityStatusService: UserAvailabilityStatusService,
    private _trackingService: TrackingService,
    private _webOnboardingService: WebOnboardingService
  ) {}

  ngOnInit() {
    // Reset state
    this.currentIndex = this.isOnNewMessage ? undefined : 0;
    this.increment = this.isOnNewMessage ? 43 : 42;
    this.isDarwin = checkIfOS(OperatingSystem.DARWIN);

    this.subscribeToCurrentIndexChanges();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.haveExclusionsChanged(changes)) {
      this.processQuery(this.currentQuery);
    }

    if (changes.query$) {
      this.subscribeToQueryChanges();
    }
  }

  get isValidEmail() {
    return EmailUtils.validateEmail(this.currentQuery.trim());
  }

  get currentUserId(): string {
    return this._userManagerService.getCurrentUserId();
  }

  private triggerChangeDetection() {
    if (!this._changeDetection['destroyed']) {
      this._changeDetection.detectChanges();
    }
  }

  onOutsideClick($event) {
    if (this.isClickInsideCommentInput($event) || this.isClickOnSuggestion($event) || !this.closeOnOutsideClick) {
      return;
    }

    this.isVisible = false;
    this.triggerChangeDetection();
  }

  reopen() {
    this.isVisible = true;
    this.triggerChangeDetection();
  }

  private haveExclusionsChanged(changes: SimpleChanges): boolean {
    return (
      (changes.excludeMe &&
        !changes.excludeMe.firstChange &&
        changes.excludeMe.currentValue !== changes.excludeMe.previousValue) ||
      (changes.ignoreGroups &&
        !changes.ignoreGroups.firstChange &&
        changes.ignoreGroups.currentValue !== changes.ignoreGroups.previousValue)
    );
  }

  private subscribeToQueryChanges() {
    if (!this.query$) {
      return;
    }

    this.querySub?.unsubscribe();
    this.querySub = merge(
      // Subject for query changes
      this.query$.pipe(debounceTime(300), distinctUntilChanged()),
      // Initial query value, so we don't miss first invocation
      // of subject above
      of(this.startQuery)
    )
      .pipe(
        tap((query: string) => {
          this.loading = true;
          this.triggerChangeDetection();

          this.selectedItemEvent.emit(undefined);

          if (!this.keyboardNavigation) {
            this.suppressMouseEventsUntilMove();
          }

          this.processQuery(query);
        })
      )
      .subscribe();
  }

  private processQuery(query: string) {
    if (query) {
      this.searchContacts(query);
    } else {
      this.handleEmptyQuery();
    }
  }

  private subscribeToCurrentIndexChanges() {
    if (!this.currentIndexEvent) {
      return;
    }

    this.currentIndexSub?.unsubscribe();
    this.currentIndexSub = this.currentIndexEvent
      .pipe(
        tap(type => {
          // increment/decrement current index
          this.incrementIndex(type);
        })
      )
      .subscribe();
  }

  private getSuggestedContacts() {
    // When search query is empty and will not show top people: show nothing
    if (_.isEmpty(this.currentQuery) && !this.shouldShowTopPeople()) {
      // Input data is empty
      this.isVisible = false;
      this.loading = false;

      // Unsubscribe from previous subscription so it is not set to visible on later emits
      this.contactSub?.unsubscribe();

      this.triggerChangeDetection();

      // broadcast undefined when there is no data
      this.selectedItemEvent.emit(undefined);
      return;
    }

    // Build queryFilter
    let filter: ContactFilter = {
      // Properties
      filterMethod: !_.isEmpty(this.currentQuery) ? FilterByMethod.QUERY : FilterByMethod.SHOW_TOP,
      searchQuery: this.currentQuery,
      size: this.limit || 10,
      currentUserId: this._userManagerService.getCurrentUserId(),

      availableContactsIds: this.filterForWorkspaceMembers
        ? this._userManagerService.getMemberIdsOfUserLicenses()
        : this.availableContactsIds,
      assignSuggestionIds: this.assignSuggestionIds,
      excludeContactIds: [],
      filter: [...this.filter, ...this.extraFilter],
      currentAssigneeId: this.currentAssigneeId,
      firstItemId: this.firstItemId,

      // Filters
      ignoreGroups: this.ignoreGroups,
      excludeMe: this.excludeMe,
      isOnLoopInPopupMentions: this.isOnLoopInPopupMentions,
      isAssigneesDropdown: this.isAssigneesDropdown,
      isOnLoopInPopup: this.isOnLoopInPopup,
      selfOnTop: this.selfOnTop,
      ignoreOnlineStatus: this.shouldIgnoreOnlineStatus(),
      ignoreNotRegistered: this.ignoreNotRegistered,
      groupSubTypes: this.groupSubTypes,
      contactTypes: this.contactTypes
    };

    this.contactSub?.unsubscribe();
    this.contactSub = this._contactService
      .getFilteredContacts(this._userManagerService.getCurrentUserEmail(), filter)
      .pipe(
        tap((contacts: ContactModel[]) => {
          this.contacts = contacts;
          this.createContactIfContactsAreEmpty();

          if (_.isEmpty(this.contacts)) {
            // Input data is empty
            this.isVisible = false;
            // Broadcast undefined when there is no data
            this.selectedItemEvent.emit(undefined);
          } else {
            this.isVisible = true;
            let contact = this.isContactDisabled(this.contacts[this.currentIndex])
              ? undefined
              : this.contacts[this.currentIndex];
            this.selectedItemEvent.emit(contact);
          }

          this.showInvite = this.isOnNewMessage && _.isEmpty(contacts);

          // Set team separator index
          if (this.assignSuggestionIds) {
            let teamSuggestionsNumber = _.intersection(
              contacts.map(contact => contact.id),
              this.assignSuggestionIds
            ).length;

            this.teamSeparatorIndex = teamSuggestionsNumber < contacts.length ? teamSuggestionsNumber - 1 : -1;
          } else {
            this.teamSeparatorIndex = -1;
          }
        }),
        mergeMap((contacts: ContactModel[]) => {
          if (this.showAvailabilityStatus) {
            return this._userAvailabilityStatusService.populateContactsWithUserAvailability(
              this._userManagerService.getCurrentUserEmail(),
              contacts
            );
          }
          return of(contacts);
        }),
        tap((contacts: ContactModel[]) => {
          this.contacts = contacts.sort((a: ContactModel, b: ContactModel) => {
            // Available contacts on first places
            if (a instanceof UserModel && b instanceof UserModel) {
              let aAvailable = a.hasValidAvailabilityStatus() ? a.availabilityStatus.enabledAssignments : true;

              let bAvailable = b.hasValidAvailabilityStatus() ? b.availabilityStatus.enabledAssignments : true;

              if (a.id === this.currentUserId) {
                aAvailable = true;
              }

              if (b.id === this.currentUserId) {
                bAvailable = true;
              }

              if (aAvailable && !bAvailable) {
                return -1;
              }

              if (!aAvailable && bAvailable) {
                return 1;
              }
            }
          });
          this.getFirstUnavailable();
          this.loading = false;
          this.triggerChangeDetection();
        })
      )
      .subscribe();
  }

  private createContactIfContactsAreEmpty() {
    if (!this.createContactIfEmpty) {
      return;
    }

    if (_.isEmpty(this.contacts) && EmailUtils.validateEmail(this.currentQuery)) {
      this.contacts = [
        new UserModel({
          $type: UserModel.type,
          email: this.currentQuery,
          name: this.currentQuery
        })
      ];
    }
  }

  private shouldShowTopPeople(): boolean {
    return !this.currentQuery && this.showWithEmptyQuery;
  }

  private handleEmptyQuery() {
    if (this.isOnNewMessage) {
      this.currentIndex = undefined;
    }

    this.currentQuery = '';

    this.getSuggestedContacts();
  }

  private searchContacts(query: string) {
    this.currentIndex = 0;
    this.currentQuery = query;

    this.getSuggestedContacts();
  }

  click(contact: ContactModel) {
    if (this.loading) {
      return;
    }

    if (this.isContactDisabled(contact)) {
      return;
    }

    this.clicked.emit(contact);

    if (!this.showWithEmptyQuery) {
      this.isVisible = false;
      this.contactSub?.unsubscribe();
    }
  }

  // handle current index
  private incrementIndex(type) {
    if (this.loading) {
      return;
    }

    this.initialSelection = false;

    if (!this.keyboardNavigation) {
      this.suppressMouseEventsUntilMove();
    }

    if (type === 'DOWN') {
      if (this.currentIndex >= this.contacts.length - 1) {
        this.currentIndex = this.contacts.length - 1;
      } else if (this.currentIndex === undefined) {
        this.currentIndex = 0;
      } else {
        this.currentIndex = this.currentIndex + 1;
      }

      this.scrollIfNeeded();
    } else if (type === 'UP') {
      if (this.currentIndex <= 0) {
        this.currentIndex = 0;
      } else {
        this.currentIndex = this.currentIndex - 1;
      }

      this.scrollIfNeeded();
    } else {
      // We can also send the current index (on mouse hover)
      this.currentIndex = type;
    }
    // Broadcast event with current "selected" item in autosuggest list
    let contact = this.isContactDisabled(this.contacts[this.currentIndex])
      ? undefined
      : this.contacts[this.currentIndex];
    this.selectedItemEvent.emit(contact);
    this.triggerChangeDetection();
  }

  setCurrentIndex($event, index) {
    if (this.keyboardNavigation) {
      return;
    }
    this.initialSelection = false;
    this.currentIndex = index;
    // Broadcast event with current "selected" item in autosuggest list
    this.selectedItemEvent.next(this.contacts[index]);
    this.triggerChangeDetection();
  }

  trackById(index: number, contact: ContactModel) {
    return contact.id || contact._id;
  }

  openInvite(email: string = '') {
    const origin = 'Channel Search Invite';
    this._trackingService.trackViewOpened(this._userManagerService.getCurrentUserEmail(), origin);

    this._webOnboardingService.openWebSettings(new URLSearchParams({ email }), '/members/invite', window.open());
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent($event: KeyboardEvent) {
    if ($event.key === 'Escape' && !this.isAssigneesDropdown) {
      this.isVisible = false;
      $event.stopPropagation();
      $event.preventDefault();
      this.triggerChangeDetection();
    }
  }

  private suppressMouseEventsUntilMove() {
    // prevents accidental hover selection when scrolling the list with keyboard
    this.keyboardNavigation = true;

    this.mouseMoveSub?.unsubscribe();
    this.mouseMoveSub = fromEvent(document, 'mousemove')
      .pipe(
        take(1),
        tap(() => {
          this.keyboardNavigation = false;
        })
      )
      .subscribe();
  }

  private scrollIfNeeded() {
    let scrollWrapper = this._elementRef.nativeElement.querySelector('.collapsable-menu');
    let currentScrollTop = scrollWrapper.scrollTop;
    let currentScrollBottom = currentScrollTop + scrollWrapper.clientHeight - this.increment;
    let nextItemLocation = this.currentIndex * this.increment;

    /*
     *  scroll contact list up/down if necessary when navigating with keyboard
     *  Math.max/min prevents scrolling past start/end of list
     *  Math.floor/ceil sets scrollTop nicely to the multiple of item height
     */
    if (nextItemLocation < currentScrollTop) {
      scrollWrapper.scrollTop = Math.max(
        Math.ceil((currentScrollTop - this.increment) / this.increment) * this.increment,
        0
      );
    } else if (nextItemLocation > currentScrollBottom) {
      scrollWrapper.scrollTop = Math.min(
        currentScrollTop + nextItemLocation - currentScrollBottom,
        scrollWrapper.scrollHeight - scrollWrapper.clientHeight
      );
    }
  }

  private isClickInsideCommentInput($event): Boolean {
    // User can click on: svg-comp > svg > use
    let depth = 3;

    let currentNode = $event.targetElement;
    for (let i = 0; i < depth; i++) {
      if (!currentNode) {
        return false;
      }

      if (
        currentNode.id === 'comment-input-commands' ||
        currentNode.id === 'comment-input-mentions' ||
        currentNode.id === 'comment-input'
      ) {
        return true;
      }

      currentNode = currentNode.parentElement;
    }

    return false;
  }

  private isClickOnSuggestion($event): boolean {
    // User can click on: command > command-name > name-hint || command-description
    let depth = 3;

    let currentNode = $event.targetElement;
    for (let i = 0; i < depth; i++) {
      if (!currentNode) {
        return false;
      }

      if (currentNode.classList.contains('command')) {
        return true;
      }

      currentNode = currentNode.parentElement;
    }

    return false;
  }

  ///////////////////////////
  // View based properties
  ///////////////////////////
  shouldShowInvite(contact: ContactModel): boolean {
    return this.showInvites && !contact?.isRegistered();
  }

  getFirstUnavailable() {
    this.firstUnavailableContact = this.contacts.find(contact => {
      return (
        contact instanceof UserModel &&
        contact.availabilityStatus &&
        !contact.availabilityStatus.enabledAssignments &&
        contact.hasValidAvailabilityStatus() &&
        contact.id !== this.currentUserId
      );
    }) as UserModel;
  }

  isContactDisabled(contact: ContactModel): boolean {
    if (!contact) {
      return true;
    }

    return (
      !contact.isRegistered() &&
      (this.isAssigneesDropdown ||
        this.isOnLoopInPopup ||
        this.isOnLoopInPopupMentions ||
        this.isOnSharedInboxLoopin ||
        this.isForMentions)
    );
  }

  hasContactValidAvailabilityStatus(contact: ContactModel) {
    if (!(contact instanceof UserModel)) {
      return false;
    }

    return contact.hasValidAvailabilityStatus();
  }

  shouldIgnoreOnlineStatus(): boolean {
    return this.isOnReplyTo;
  }
}
