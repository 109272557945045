/* tslint:disable */

/**
 * Group types
 */
export type GroupType = 'Normal' | 'SharedInbox' | 'PersonalInbox';

export module GroupType {
  export const NORMAL: GroupType = 'Normal';
  export const SHARED_INBOX: GroupType = 'SharedInbox';
  export const PERSONAL_INBOX: GroupType = 'PersonalInbox';

  export function values(): GroupType[] {
    return [NORMAL, SHARED_INBOX, PERSONAL_INBOX];
  }
}
