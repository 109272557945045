<!-- Search title vol1 -->
<div *ngIf="searchType && contacts.length" class="search-title">
  {{searchType === 'cards' ? 'emails' : searchType}} from/to
</div>

<!-- Search title vol2 -->
<div
  *ngIf="(isOnSearch && !currentQuery) || (isOnNewMessage && !currentQuery && filter.length === 0)"
  class="search-title"
  [class.new-message]="isOnNewMessage"
>
  Suggested
</div>

<!-- List -->
<div
  class="collapsable-menu left-aligned drop-down-menu full-width search ps-container ps-active-y hide-horizontal-simplebar"
  outsideClick
  [class.isOnLoopInPopupMentions]="isOnLoopInPopupMentions"
  [class.isForMentions]="isForMentions"
  [class.isOnSharedInboxLoopin]="isOnSharedInboxLoopin"
  [class.is-assignees-dropdown]="isAssigneesDropdown"
  [class.canned-response-dropdown]="isOnCannedResponseCreate"
  [class.is-on-create-shared-inbox]="isOnCreateSharedInbox"
  [class.is-on-composer]="isOnComposer"
  [class.isOnSearch]="isOnSearch"
  [class.isOnChat]="isOnChat"
  [class.hide]="
        !contacts.length 
        || !isVisible && !neverHide 
        || isOnLoopInPopup && !isFocused 
        || (isOnNewMessage && filter.length > 0)
    "
  [class.openUp]="openUp"
  [ngClass]="!!extraClass ? extraClass : ''"
  (onOutsideClick)="onOutsideClick($event)"
>
  <ng-template ngFor let-item [ngForOf]="contacts" let-i="index" [ngForTrackBy]="trackById">
    <div
      *ngIf="isAssigneesDropdown && showAvailabilityStatus && item.id === firstUnavailableContact?.id"
      class="not-available-section"
    >
      Not available
    </div>

    <div
      class="search-item-wrapper"
      [class.current]="i == currentIndex"
      [id]="'search-item-' + (item.$type === 'User' ? item.email : item.id)"
      [class.mouse-navigation]="!keyboardNavigation && !initialSelection"
      [class.isOnSearch]="isOnSearch"
      [class.loading]="loading"
      [class.isOnQuickJump]="isOnQuickJump"
      [class.isOnLoopInPopup]="isOnLoopInPopup"
      (mouseenter)="setCurrentIndex($event, i)"
      [class.bottom-separator]="i === teamSeparatorIndex"
      (click)="click(item)"
    >
      <div
        class="collapsable-item user-item non-removable clickable"
        [class.mouse-navigation]="!keyboardNavigation && !initialSelection"
        [class.isOnSearch]="isOnSearch"
        [class.isOnQuickJump]="isOnQuickJump"
        [class.isOnLoopInPopup]="isOnLoopInPopup"
        [class.isOnNewMessage]="isOnNewMessage"
        [class.is-assignees-dropdown]="isAssigneesDropdown"
        [class.canned-response-dropdown]="isOnCannedResponseCreate"
        [class.disabled]="isContactDisabled(item)"
        [class.current]="i == currentIndex"
        [tooltip]="{content: isAssigneesDropdown ? item.email || item.name + (item.id === currentUserId ? (isDarwin ? ' (⌘+Shift+A)' : ' (Ctrl+Shift+A)') : '') : ''}"
      >
        <div class="avatar-wrapper">
          <!-- Avatar -->
          <avatar-image
            *ngIf="(!isOnSearch && !isAssigneesDropdown) || showAvatar || isOnQuickJump"
            [extraClass]="isOnQuickJump ? 'autosuggest-search-avatar' : (showAvatar ? 'autosuggest-show-avatar' : 'autosuggest-avatar')"
            [contact]="item"
            [disablePopup]="isOnQuickJump || disablePopup"
            (mouseenter)="setCurrentIndex($event, i)"
          >
          </avatar-image>

          <avatar-image
            *ngIf="isAssigneesDropdown"
            [extraClass]="'autosuggest-assign-avatar'"
            [disablePopup]="disablePopup"
            [contact]="item"
            (mouseenter)="setCurrentIndex($event, i)"
          >
          </avatar-image>

          <!-- Availability Status EMOJI -->
          <div
            class="availability-status-emoji"
            *ngIf="hasContactValidAvailabilityStatus(item) && showAvailabilityStatus"
          >
            {{ item.availabilityStatus.emoji }}
          </div>
        </div>

        <div class="width-85">
          <!-- Name -->
          <div class="name" [class.new-message]="isOnNewMessage" [class.isOnSearch]="isOnSearch || isOnQuickJump">
            {{ item.name }}

            <svg-comp *ngIf="isAssigneesDropdown && currentAssigneeId === item.id" [src]="checkMarkSvg"></svg-comp>

            <!-- Availability Status -->
            <span
              class="availability-status-name"
              [class.margin-left]="isAssigneesDropdown && !(currentAssigneeId === item.id)"
              *ngIf="hasContactValidAvailabilityStatus(item) && showAvailabilityStatus"
            >
              ({{ item.availabilityStatus.status }})
            </span>
          </div>

          <!-- Email -->
          <span
            *ngIf="item.$type === 'User'"
            [class.new-message]="isOnNewMessage"
            [class.isOnSearch]="isOnSearch || isOnQuickJump"
            class="email"
          >
            {{ item.email }}
          </span>

          <!-- Number of members -->
          <span
            *ngIf="item.$type === 'Group'"
            [class.new-message]="isOnNewMessage"
            [class.isOnSearch]="isOnSearch || isOnQuickJump"
            class="email"
          >
            {{ item.members?.totalSize || 0 }} members
          </span>
        </div>
      </div>

      <!-- Invite button -->
      <div *ngIf="shouldShowInvite(item)" class="invite">
        <span (click)="openInvite(item.email)" class="invite-text">Invite</span>
      </div>
    </div>
  </ng-template>
</div>

<div class="search-title" *ngIf="searchType && contacts.length">All results</div>

<!-- Empty view -->
<div class="empty-container" *ngIf="showEmptyContainer && contacts.length === 0 && !loading">
  <div class="empty-background empty-results"></div>
  <p class="empty-results-text">
    Oops, we couldn’t find anything for “<span class="highlight">{{currentQuery}}</span>”. Maybe check your spelling and
    try again.
  </p>
</div>

<div class="empty-invite" *ngIf="showInvite && isValidEmail">
  <div>User with email <strong>{{ currentQuery }}</strong> is not in your workspace.</div>

  <div class="invite-button" sleepClickEvent (click)="openInvite(currentQuery)">Invite {{ currentQuery }}</div>
</div>
