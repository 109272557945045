import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipHoverDirective } from '@shared/ui/tooltip/directives/tooltip-hover.directive';
import { TooltipClickDirective } from '@shared/ui/tooltip/directives/tooltip-click.directive';

@NgModule({
  declarations: [TooltipHoverDirective, TooltipClickDirective],
  imports: [CommonModule],
  exports: [TooltipHoverDirective, TooltipClickDirective],
})
export class LoopTooltipModule {}
