import { ErrorHandler, inject, Injectable, Injector } from '@angular/core';
import { catchError, EMPTY, finalize, Observable, Observer, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { take } from 'rxjs/operators';
import { nanoid } from 'nanoid';
import { MODAL_FORM_PROPERTIES, ModalFormProperties } from './modal-form-properties.injection-token';
import { DialogService } from '@shared/ui/kit/dialog/dialog.service';
import { BaseFormComponent } from '@shared/forms/base-form.component';
import { isNewWindowModalFormOptions, ModalFormOptions } from '@shared/forms/shell/modal-form/modal-form-options';
import { ICON_SUBMIT_BUTTON } from '@shared/ui/kit/dialog/open-dialog-options';
import { ReturnDialogData } from '@shared/ui/kit/dialog/return-dialog-data';
import { ModalFormContentComponent } from '@shared/forms/shell/modal-form/internals/modal-form-content.component';
import { ModalFormContentModule } from '@shared/forms/shell/modal-form/internals/modal-form-content.module';
import { LOOP_DYNAMIC_COMPONENT_NAME } from '@shared/modules/loop/components/loop-dynamic-components';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { KeyboardActionModeType } from '@dta/shared/models/keyboard-shortcut.model';

@Injectable()
export class ModalFormService {
  private readonly dialogService: DialogService = inject(DialogService);
  private readonly errorHandler: ErrorHandler = inject(ErrorHandler);
  private readonly injector: Injector = inject(Injector);
  private readonly keyboardShortcutsListenerService: KeyboardShortcutsListenerService = inject(
    KeyboardShortcutsListenerService
  );

  create<T, C extends BaseFormComponent<T> & { setDefaults?(value: T): void }, R>(
    options: ModalFormOptions<T, C, R>
  ): void {
    const isLoading$: Subject<boolean> = new Subject();
    this.createModal(options, isLoading$)
      .pipe(take(1))
      .subscribe(modal => {
        if (!modal) {
          return;
        }

        this.observeModalFormSubmit(modal, options, isLoading$);
      });
  }

  create$<T, C extends BaseFormComponent<T> & { setDefaults?(value: T): void }, R>(
    options: Omit<ModalFormOptions<T, C, R>, 'observer'>
  ): Observable<R> {
    return new Observable<R>((observer: Observer<R>) => {
      const fullOptions: ModalFormOptions<T, C, R> = { ...options, observer: observer.next } as ModalFormOptions<
        T,
        C,
        R
      >;
      this.create<T, C, R>(fullOptions);
    });
  }

  private createModal<T, C extends BaseFormComponent<T>, R>(
    options: ModalFormOptions<T, C, R>,
    isLoading$: Observable<boolean>
  ): Observable<any> {
    this.keyboardShortcutsListenerService.setMode(KeyboardActionModeType.COMPOSER_MODAL);
    const modalFormId: string = nanoid(16);
    if (isNewWindowModalFormOptions(options)) {
      return this.dialogService.openDialog({
        title$: options.modalTitle$,
        componentId: LOOP_DYNAMIC_COMPONENT_NAME.ModalFormContentComponent,
        windowIdentifier: options.windowIdentifier,
        width: Number.isInteger(options.modalWidth)
          ? `${options.modalWidth}px`
          : ((options.modalWidth as string) ?? undefined),
        componentParams: {
          options: {
            componentParams: options.componentParams,
            componentId: options.componentId,
            useCustomLayout: options.useCustomLayout
          },
          isLoading$: isLoading$
        }
      });
    }

    return this.dialogService
      .openDialog<ModalFormContentComponent<T, C, R>>({
        title$: options.modalTitle$,
        componentData: {
          component: ModalFormContentComponent,
          module: ModalFormContentModule,
          parentInjector: Injector.create({
            providers: [
              {
                provide: MODAL_FORM_PROPERTIES,
                useValue: { isLoading$: isLoading$, modalFormId: modalFormId } as ModalFormProperties
              }
            ],
            parent: options.formComponent.parentInjector ?? this.injector
          })
        },
        componentParams: {
          options: {
            ...options,
            formComponent: {
              ...options.formComponent,
              // Since we (possibly) reuse the parentInjector to render content compoent itself, we must not use it after again!
              parentInjector: undefined
            }
          },
          isLoading$: isLoading$
        },
        // If there are two coustom submit buttons in app, then the first button will be in the top right corner
        primaryButton: {
          action: instance => {
            if (instance && instance.submitForm) {
              instance.submitForm();
            }
          },
          isLoading$: isLoading$,
          icon: options.buttons?.find(button => button.type === 'submit')?.icon ?? ICON_SUBMIT_BUTTON
        },
        width: Number.isInteger(options.modalWidth)
          ? `${options.modalWidth}px`
          : ((options.modalWidth as string) ?? undefined),
        useCustomLayout: options.useCustomLayout,
        isCloseAllowed$: of(true)
      })
      .pipe(tap(dialog => (dialog.componentInstance.closeForm = () => dialog.close())));
  }

  private observeModalFormSubmit<T, C extends BaseFormComponent<T>, R>(
    modal: ReturnDialogData<C>,
    { afterSubmit$, observer }: ModalFormOptions<T, C, R>,
    isLoading$: Subject<boolean>
  ): void {
    const obs$ =
      (modal.componentInstance as unknown as ModalFormContentComponent<T, C, R>)?.formSubmit$ instanceof Observable
        ? (modal.componentInstance as unknown as ModalFormContentComponent<T, C, R>)?.formSubmit$
        : modal.returnData$;

    obs$
      .pipe(
        tap(() => isLoading$.next(true)),
        takeUntil(modal.afterClose$),
        switchMap((value: T) =>
          afterSubmit$(value).pipe(
            take(1),
            catchError(err => {
              this.errorHandler.handleError(err);
              return EMPTY;
            }),
            finalize(() => {
              isLoading$.next(false);
            })
          )
        ),
        // When pipeline reach this point, form was successfully processed, modal could be destroyed and stream completed
        take(1),
        finalize(() => {
          isLoading$.complete();
          modal.close();
        })
      )
      .subscribe(observer);
  }
}
