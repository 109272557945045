import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Interceptors } from '@shared/interceptors/common/enum/interceptors';

@Injectable()
export abstract class IgnorableInterceptor {
  abstract interceptorName: Interceptors | string;

  protected shouldBeIgnored(req: HttpRequest<any>): boolean {
    return req.headers.has(this.interceptorName.toString());
  }

  protected ignoreInterceptor(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = req.headers.delete(this.interceptorName.toString());
    return next.handle(req.clone({ headers }));
  }
}
