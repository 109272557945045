import * as _ from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import {
  InboxProvider,
  PermissionDeniedReason,
  SharedInboxSetupData,
} from '@shared/services/data/channel-inbox-setup/channel-setup.service.interface';
import { Observable } from 'rxjs';
import { ClickTrackingLocation } from '@dta/shared/services/tracking/tracking.constants';
import { CREATE_SI_COPY } from './create-shared-inbox-copy';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { CreateInboxComponent } from '@shared/modules/main/user-settings/channels/create/create-inbox/create-inbox.component';
import { SetupStep, Step } from '@shared/modules/main/user-settings/channels/create/create-channel.base.component';
import { SetupType } from '@shared/services/data/channel-inbox-setup/channel-setup.service';

@AutoUnsubscribe()
@Component({
  selector: 'create-shared-inbox',
  styleUrls: ['./../../../../create-view.scss'],
  templateUrl: './../../create-channel.html',
  host: { class: 'main' },
})
export class CreateSharedInboxComponent extends CreateInboxComponent implements OnInit, OnDestroy {
  //////////////
  // Properties
  //////////////
  data: SharedInboxSetupData;
  step: 0 | 1 = 0;
  steps: Step[] = [
    { id: SetupStep.Channels, title: 'Connect a shared inbox' },
    { id: SetupStep.Members, title: 'Members' },
  ];
  textBank = CREATE_SI_COPY;
  trackingLocation: string = ClickTrackingLocation.SharedInboxSetup;
  setupType: SetupType = SetupType.SHARED_INBOX;

  get backButtonText(): string {
    return {
      0: 'Back to Shared Inboxes',
      1: 'Back to channels',
    }[this.step];
  }

  get nextButtonText(): string {
    return {
      1: this.data?.members?.length > 0 ? 'Send invites' : 'Create',
    }[this.step];
  }

  get additionalTrackingProperties(): Object {
    return {
      workflow: 'SharedInboxSetup',
      step: 'SharedInboxStep' + (this.step + 1),
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.persistData();
  }

  ////////////////
  // View methods
  ////////////////
  nextStep() {
    if (this.step === 1) {
      this.create();
    }

    this.trackView();
  }

  ///////////
  // Helpers
  ///////////
  protected navigateToSettings() {
    this._router.navigate(['/user-settings/shared-inboxes']);
  }

  protected getNewDataModel(): SharedInboxSetupData {
    return new SharedInboxSetupData();
  }

  protected persistData() {
    this._channelSetupService.persistSetupData(this.currentUserEmail, this.data);
  }

  protected setStepFromData(permissionDeniedReason?: PermissionDeniedReason) {
    let step: 0 | 1 = 0;

    if (this.data.name) {
      step = 0;
    }
    if (this.data.inboxAuth || this.data.provider === InboxProvider.GOOGLE_ALIAS) {
      step = 1;
    }

    this.stepStatusToIdle();

    if (permissionDeniedReason) {
      step = 0;
      this.data = _.pick(this.data, ['name', 'description', 'state']) as SharedInboxSetupData;
      this.persistData();

      switch (permissionDeniedReason) {
        case 'permissionDenied':
          this.openErrorDialog('Required permissions were not granted');
          break;
        case 'emailSameAsLoggedInUser':
          this.openErrorDialog(
            'You have already connected this inbox as a personal account. Please sign-in with a different personal account to connect this shared inbox.',
          );
          break;
        case 'unknown':
        default:
          this.openErrorDialog(
            'Something went wrong. Please try again, if the issue persists please contact our support.',
          );
          break;
      }
    }

    this.step = step;
    this.trackView();
  }

  protected setupServiceCreate(): Observable<ContactModel> {
    return this._channelSetupService.createInbox(this.currentUserEmail);
  }
}
