import { ContactBase, ContactType, GroupSubType, GroupType } from '@shared/api/api-loop/models';
import { ContactModel } from '../models-api-loop/contact/contact.model';

export class SidebarContacts {
  favoriteChannels: ContactModel[] = [];
  peopleChannels: ContactModel[] = [];
  teamChannels: ContactModel[] = [];
  sharedInboxChannels: ContactModel[] = [];
  personalInboxChannels: ContactModel[] = [];
}

export class ContactFilter {
  // Properties
  filterMethod: FilterByMethod;
  searchQuery: string;
  size: number;
  currentUserId: string;

  // Lists
  availableContactsIds?: string[] = undefined;
  assignSuggestionIds?: string[] = undefined;
  excludeContactIds?: string[] = undefined;

  // Flags
  ignoreGroups?: boolean;
  excludeMe?: boolean;
  selfOnTop?: boolean;
  ignoreOnlineStatus?: boolean = false;
  isOnLoopInPopupMentions?: boolean;
  isAssigneesDropdown?: boolean;
  isOnLoopInPopup?: boolean;
  ignoreNotRegistered?: boolean = false;

  // Other
  filter: ContactBase[] = [];
  firstItemId?: string;
  currentAssigneeId?: string;

  groupSubTypes?: GroupSubType[];
  contactTypes?: ContactType[];
}

export enum FilterByMethod {
  QUERY,
  SHOW_TOP,
}
