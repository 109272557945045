import * as _ from 'lodash';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { AutoUnsubscribe } from './subscriptions/auto-unsubscribe';
import { TrackingService } from '../services/tracking/tracking.service';
import { ClickTrackingLocation } from '../services/tracking/tracking.constants';
import { LogLevel, LogTag } from '../models/logger.model';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { Logger } from '@shared/services/logger/logger';

// All of this views are on first level (/<xx>/...)
const supportedViewForTiming = [
  'search',
  'inbox',
  'account',
  'channel',
  'myloopinbox',
  'drafts',
  'boards',
  'starred',
  'assigned',
  'archive',
  'thread',
  'trash',
  'files',
  'agenda',
  'sent',
  'user-settings'
];

const trackingLocationByURLPart = {
  'myloopinbox': ClickTrackingLocation.MyLoopInbox,
  'search': ClickTrackingLocation.Search,
  'inbox': ClickTrackingLocation.PersonalInbox,
  'channel': ClickTrackingLocation.Channel,
  'drafts': ClickTrackingLocation.Drafts,
  'boards': ClickTrackingLocation.UserFolder,
  'starred': ClickTrackingLocation.Starred,
  'assigned': ClickTrackingLocation.Assigned,
  'archive': ClickTrackingLocation.Archive,
  'trash': ClickTrackingLocation.Trash,
  'files': ClickTrackingLocation.Files,
  'agenda': ClickTrackingLocation.Agenda,
  'sent': ClickTrackingLocation.Sent,
  'messages': ClickTrackingLocation.Chats,
  'outbox': ClickTrackingLocation.Outbox,
  '': ClickTrackingLocation.Default
};

// All of this views have sub-views that need to be distinctly logged
const viewsWithSubViews = ['account', 'inbox'];

@AutoUnsubscribe()
@Injectable()
export class Breadcrumbs implements OnDestroy {
  // Parameters
  private static captureLast_n_Breadcrumbs = 5; // Size of breadcrumbs buffer
  private static captureLast_n_steps = 5; // How many steps to capture for click events
  private static viewTimeLogThresholdSec = 3; // How long a user must stay in view for it to be logged

  static captureUserClicks = true;
  static captureXHRRequests = true;

  // Variables
  private static last_n_Clicks: string[] = [];
  private static last_n_XHRRequests: string[] = [];
  private static last_n_Shortcuts: string[] = [];
  private static last_n_Routes: string[] = [];
  private static lastUrl: string = '';
  private static lastUrlTimestamp: Date = undefined;

  // Subjects
  private static _userInactiveTrigger$: Subject<any>;

  // Subscriptions
  private routerEvents: Subscription;
  private userInactiveEventsSub: Subscription;

  private static _userManagerService: UserManagerService;
  private static _trackingService: TrackingService;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _router: Router
  ) {}

  ngOnDestroy(): void {}

  //////////
  // INIT
  //////////
  init(userInactiveTrigger$: Subject<any>, userManagerService: UserManagerService, trackingService: TrackingService) {
    Breadcrumbs._userInactiveTrigger$ = userInactiveTrigger$;
    Breadcrumbs._userManagerService = userManagerService;
    Breadcrumbs._trackingService = trackingService;
    /**
     * Check for platform.
     * window, document, navigator, and other browser types - do not exist on the server -
     * so using them, or any library that uses them (jQuery for example) will not work.
     */
    if (isPlatformBrowser(this.platformId)) {
      if (Breadcrumbs.captureUserClicks) {
        this.subscribeToUserInactiveEvent();

        this.captureUserClickEvents();
        this.captureUserKeyDownEvents();
        this.captureUserRouteChanges();
        this.captureUserDropEvents();
      }
    }
  }

  //////////////////
  // GET BREADCRUMBS
  //////////////////

  static getXHRBreadcrumbs(): string {
    return Breadcrumbs.last_n_XHRRequests.join('\r\n') + '\r\n...\r\n';
  }

  static getClicksBreadcrumbs(): string {
    return Breadcrumbs.last_n_Clicks.join('\r\n') + '\r\n...\r\n';
  }

  static getShortcutBreadcrumbs(): string {
    return Breadcrumbs.last_n_Shortcuts.join('\r\n') + '\r\n...\r\n';
  }

  static getRouteBreadcrumbs(): string {
    return Breadcrumbs.last_n_Routes.join('\r\n') + '\r\n...\r\n';
  }

  ///////////////////
  // LOG BREADCRUMBS
  ///////////////////

  static logXHRRequest(request: string) {
    let valueToLog = Breadcrumbs.getLogWithTimestamp(request);

    Breadcrumbs.last_n_XHRRequests.unshift(valueToLog);
    Breadcrumbs.last_n_XHRRequests.length = Breadcrumbs.captureLast_n_Breadcrumbs;
  }

  static logShortcut(shortcut: string) {
    let valueToLog = Breadcrumbs.getLogWithTimestamp(shortcut);

    Breadcrumbs.last_n_Shortcuts.unshift(valueToLog);
    Breadcrumbs.last_n_Shortcuts.length = Breadcrumbs.captureLast_n_Breadcrumbs;
  }

  ///////////////////////
  // CAPTURE BREADCRUMBS
  ///////////////////////

  /**
   * Events propagate in this order:
   *  1. Capture (top to bottom)
   *  2. Target (hit target)
   *  3. Bubble (bubble event from target up)
   *
   * If we catch event in capture phase, we catch events
   * that might later have *.stopPropagation() set.
   *
   * NOTICE: Keep the code in handler as minimal as possible
   */

  ///////////////
  // User click
  ///////////////
  private captureUserClickEvents() {
    document.addEventListener(
      'click',
      function (event: any) {
        let trace = '';
        const eventPath = event.composedPath();
        try {
          let limit = Math.min(eventPath.length, Breadcrumbs.captureLast_n_steps);
          for (let i = 0; i < limit; i++) {
            if (trace) {
              trace += ' < ';
            }
            trace +=
              eventPath[i].localName +
              ' [' +
              (eventPath[i].className ? 'class(' + eventPath[i].className + ')' : '') +
              (eventPath[i].id ? 'id(' + eventPath[i].id + ')' : '') +
              ']';
          }
        } catch (err) {
          trace = '~error in getting target path~';
          console.error(err);
        }

        Breadcrumbs.last_n_Clicks.unshift(Breadcrumbs.getLogWithTimestamp(trace));
        Breadcrumbs.last_n_Clicks.length = Breadcrumbs.captureLast_n_Breadcrumbs;
      },
      { capture: true }
    );
  }

  ///////////////////
  // Key press event
  ///////////////////
  private captureUserKeyDownEvents() {
    let keysDown = {};

    document.addEventListener(
      'keydown',
      function (event: KeyboardEvent) {
        if (event.key in keysDown) {
          // Key already down
          return;
        }

        // Mark key as down
        keysDown[event.key] = true;

        // Track shortcuts
        try {
          if (Breadcrumbs.isShortcutKeyDown(event) && Breadcrumbs.eventNotShortcutKey(event)) {
            Breadcrumbs.getLocationAndTrackKeyShortcut(event);
            Logger.customLog(Breadcrumbs.shortcutToString(event), LogLevel.INFO, LogTag.SHORTCUT, true);
          }
        } catch (err) {
          console.error(err);
        }
      },
      { capture: true }
    );

    // Remove from dict when key up
    document.addEventListener(
      'keyup',
      function (event: any) {
        delete keysDown[event.key];
      },
      { capture: true }
    );

    // Clear list on focus event (reset state)
    window.addEventListener(
      'focus',
      function () {
        keysDown = {};
      },
      { capture: true }
    );
  }

  /////////////////////
  // Route navigation
  /////////////////////
  private captureUserRouteChanges() {
    this.routerEvents && this.routerEvents.unsubscribe();
    this.routerEvents = this._router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        /**
         * Measure time spend in view
         */
        tap((event: NavigationStart) => {
          // Store no matter what
          Breadcrumbs.lastUrl = event.url;
          Breadcrumbs.lastUrlTimestamp = new Date();
        }),
        /**
         * Save navigation to breadcrumbs
         */
        tap((event: NavigationStart) => {
          let url = '';
          try {
            url = event.url;
          } catch (err) {
            url = '~error in getting route url~';
            console.error(err);
          }

          Breadcrumbs.last_n_Routes.unshift(Breadcrumbs.getLogWithTimestamp(url));
          Breadcrumbs.last_n_Routes.length = Breadcrumbs.captureLast_n_Breadcrumbs;
        })
      )
      .subscribe();
  }

  ////////////////
  // Drag & Drop
  ////////////////
  // Capture event that bubbles up (was not stopped by any other action)
  private captureUserDropEvents() {
    document.addEventListener('drop', function (event: any) {
      Breadcrumbs._trackingService.trackDropAction(
        Breadcrumbs._userManagerService.getCurrentUserEmail(),
        'non-accepting'
      );
    });
  }

  ///////////////
  // SUBSCRIBERS
  ///////////////
  private subscribeToUserInactiveEvent() {
    this.userInactiveEventsSub && this.userInactiveEventsSub.unsubscribe();
    this.userInactiveEventsSub = Breadcrumbs._userInactiveTrigger$
      .pipe(
        tap(() => {
          // Reset state when user become inactive
          Breadcrumbs.lastUrl = undefined;
          Breadcrumbs.lastUrlTimestamp = undefined;
        }),
        catchError(err => {
          console.error(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  /////////////
  // HELPERS
  /////////////
  private static isShortcutKeyDown(event: KeyboardEvent): boolean {
    return event.ctrlKey || event.altKey || event.metaKey;
  }

  private static eventNotShortcutKey(event: KeyboardEvent): boolean {
    return (
      event.keyCode !== 17 && // Ctrl
      event.keyCode !== 18 && // Alt
      event.keyCode !== 91 && // Meta
      event.keyCode !== 92 && // Meta
      event.keyCode !== 93
    ); // Meta
  }

  private static shortcutToString(event: KeyboardEvent): string {
    let keys = [];
    if (event.ctrlKey) {
      keys.push('ctrl');
    }
    if (event.altKey) {
      keys.push('alt');
    }
    if (event.metaKey) {
      keys.push('meta');
    }
    keys.push(event.key);

    return keys.join('+');
  }

  private static getLocationAndTrackKeyShortcut(event: KeyboardEvent) {
    let location = 'Unknown';
    let key = Breadcrumbs.shortcutToString(event);
    let urlParts, hasSubview;

    if (Breadcrumbs.lastUrl) {
      urlParts = Breadcrumbs.lastUrl.split('/');
      hasSubview = viewsWithSubViews.includes(urlParts[1]);

      if (urlParts.length > 2 && hasSubview) {
        location = trackingLocationByURLPart[urlParts[2]];
      } else if (urlParts.length > 1) {
        location = trackingLocationByURLPart[urlParts[1]];
      }
    }

    Breadcrumbs._trackingService.trackKeyboardShortcut(
      Breadcrumbs._userManagerService.getCurrentUserEmail(),
      key,
      location
    );
  }
  /////////////////////
  // TIMESTAMP HELPERS
  /////////////////////

  private static getLogWithTimestamp(log: string): string {
    return '[ at ' + Breadcrumbs.getTimestamp() + '] ~ ' + log;
  }

  private static getTimestamp(): string {
    let date = new Date();
    return (
      date.getDate() +
      '.' +
      (date.getMonth() + 1) +
      '.' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds() +
      '.' +
      date.getMilliseconds()
    );
  }
}
