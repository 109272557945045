import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmailUtils } from '@dta/shared/utils/common-utils';

@Component({
  selector: 'simple-input',
  styleUrls: ['./simple-input.scss'],
  template: `
    <label *ngIf="label">{{ label }}</label>

    <div class="input-wrapper" [class]="customClasses" [class.has-err]="showError" [class.search-input]="search">
      <svg-comp *ngIf="search" class="svg-icon" [src]="searchSvg"></svg-comp>

      <input
        class="allow-paste"
        [type]="isPassword && showPassword ? 'text' : type"
        [class.password]="isPassword"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [myAutoFocus]="autoFocus"
        [(ngModel)]="value"
        [focusStateNotifier]="'textSecondary'"
        (input)="onValueChange(value)"
      />

      <div *ngIf="isPassword" class="show-pass" (click)="togglePassword()">
        {{ showPassword ? 'Hide' : 'Show' }}
      </div>

      <div class="error-msg" *ngIf="showError">{{ errorMsg }}</div>
    </div>
  `,
})
export class SimpleInputComponent {
  searchSvg = require('shared/assets/img/svg/search.svg');

  @Input() label: string;
  @Input() search: boolean = false;
  @Input() icon: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autoFocus: boolean = false;
  @Input() placeholder: string = '';
  @Input() value: string = '';
  @Input() customClasses: string = '';
  @Input() password: boolean = false;
  @Input() type: string = 'text';
  @Input() allowSpaces: boolean = true;
  @Input() errorMsg: string = 'Please enter a valid input';

  @Output() valueChange = new EventEmitter<string>();
  @Output() hasError = new EventEmitter<boolean>();

  showPassword: boolean = false;
  showError: boolean = false;

  get isPassword() {
    return this.type === 'password';
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  onValueChange(value: string) {
    let hasError = false;

    if (!this.allowSpaces && value.trim().includes(' ')) {
      hasError = true;
    }
    if (this.type === 'email' && !EmailUtils.validateEmail(value)) {
      hasError = true;
    }
    if (this.type === 'number' && !/^\d+$/.test(value.trim())) {
      hasError = true;
    }
    if (!value) {
      hasError = false;
    }

    this.showError = hasError;

    this.valueChange.emit(value);
    this.hasError.emit(hasError);
  }
}
