/* tslint:disable */

/**
 * Possible Status Types
 */
export type ExportStatus = 'Expired' | 'Failed' | 'Processing' | 'Completed';

export module ExportStatus {
  export const EXPIRED: ExportStatus = 'Expired';
  export const FAILED: ExportStatus = 'Failed';
  export const PROCESSING: ExportStatus = 'Processing';
  export const COMPLETED: ExportStatus = 'Completed';

  export function values(): ExportStatus[] {
    return [EXPIRED, FAILED, PROCESSING, COMPLETED];
  }
}
