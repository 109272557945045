import { Directive } from '@angular/core';
import { SharedAvatarService } from '@shared/services/shared-avatar/shared-avatar.service';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { Observable, Subject } from 'rxjs';
import { AvatarServiceI } from './avatar.service.interface';

@Directive()
export abstract class AvatarService implements AvatarServiceI {
  abstract avatarUpdated$: Subject<string>;

  constructor(protected _sharedAvatarService: SharedAvatarService) {}

  abstract downloadAvatar(forUserEmail: string, contact: ContactBase): Observable<boolean>;

  abstract getAvatar(forUserEmail: string, contact: ContactBase, refresh?: boolean): Observable<boolean>;
}
