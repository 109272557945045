import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormControlWrapperModule } from '@shared/modules/form-controls/base/form-control-wrapper/form-control-wrapper.module';
import { AutoSuggestControlComponent } from '@shared/modules/form-controls/auto-suggest-control/auto-suggest-control.component';
import { ContainsByPropertyPipe } from '@shared/modules/form-controls/auto-suggest-control/contains-by-property.pipe';
import { GraphicsModule } from '@shared/modules/common/svg/graphics.module';
import { ContactModule } from '@shared/modules/contacts/components/contact/contact.module';
import { TagModule } from '@shared/ui/tag/tag.module';
import { ContactsDropdownComponent } from '@shared/modules/form-controls/auto-suggest-control/internal/contacts-dropdown.component';
import { LoopTooltipModule } from '@shared/ui/tooltip/tooltip.module';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { AlertModule } from '@shared/ui/alert/alert.module';
import { OutsideClickModule } from '@dta/ui/directives/outside-click/outside-click.module';

@NgModule({
  declarations: [AutoSuggestControlComponent, ContainsByPropertyPipe, ContactsDropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    FormControlWrapperModule,
    GraphicsModule,
    ContactModule,
    TagModule,
    OutsideClickModule,
    LoopTooltipModule,
    IconModule,
    AlertModule
  ],
  exports: [AutoSuggestControlComponent]
})
export class AutoSuggestControlModule {}
