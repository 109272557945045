import * as _ from 'lodash';
import { Injectable, Renderer2 } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { HtmlImageServiceI } from './html-image.service.interface';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { catchError, defaultIfEmpty, map, mergeMap, toArray } from 'rxjs/operators';
import { FilePathWithHash, FileService } from '../file/file.service';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CommentMailModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { Logger } from '@shared/services/logger/logger';
import { FileUrlStorageService } from '../../../../web/app/services/file-storage/file-url-storage/file-url-storage.service';
import { File as IFile } from '@shared/api/api-loop/models/file';

@Injectable()
export class HtmlImageService implements HtmlImageServiceI {
  private imgSrcRegex: RegExp = new RegExp(' src=', 'g');
  private imgDataSrcRegex: RegExp = new RegExp(` data-src=`, 'g');
  private validCIDPrefixes: string[] = ['TF', 'DF', 'AF'];
  private validCIDPostfixes: string[] = ['0I', '1I', '0C', '1C', '0T', '1T'];

  constructor(
    protected _fileStorageService: FileStorageService,
    protected _fileService: FileService
  ) {}

  get constructorName(): string {
    return 'HtmlImageService';
  }

  replaceImgSrcWithDataSrc(html: string): string {
    let wrapper = this.getWrapper(html);
    return this.getHtmlFromWrapper(wrapper);
  }

  isValidCid(cid: string) {
    return (
      this.validCIDPrefixes.some(prefix => cid?.startsWith(prefix)) ||
      this.validCIDPostfixes.some(postfix => cid?.endsWith(postfix))
    );
  }

  replaceLocalPathWithCid(forUserEmail: string, html: string): Observable<string> {
    let wrapper = this.getWrapper(html);
    let imgList = <NodeListOf<HTMLImageElement>>wrapper.querySelectorAll('img');

    _.forEach(imgList, (img: HTMLImageElement) => {
      let dataSrc = img.getAttribute('data-src');

      // skip when empty, has cid or has base64 image
      if (!dataSrc || dataSrc.startsWith('data:image') || dataSrc.includes('cid')) {
        return;
      }

      // replace path with cid
      let fileId = img.getAttribute('data-cid');
      if (fileId) {
        img.setAttribute('data-src', 'cid:' + fileId);
      }
    });

    return of(this.getHtmlFromWrapper(wrapper));
  }

  protected getWrapper(html: string): HTMLElement {
    let wrapper = document.createElement('div');

    // prevent browser requesting img src that has not been resolved yet
    wrapper.innerHTML = html.replace(this.imgSrcRegex, ` data-src=`);

    return wrapper;
  }

  replaceImgFilePathsWithLinks(html: string, files: FileModel[]): string {
    if (!html || _.isEmpty(files)) {
      return html;
    }
    let wrapper = this.getWrapper(html);
    let imgList = wrapper.querySelectorAll('img');

    _.forEach(imgList, (img: HTMLImageElement) => {
      let path = img.getAttribute('src');
      let srcAttr = img.getAttribute('data-src');

      let _file = _.find(files, file => {
        return path?.includes(file.clientId) || srcAttr?.includes(file.clientId);
      });

      if (_file && _file.urlLink) {
        img.setAttribute('data-src', _file.urlLink);
        img.setAttribute('id', _file.id);
      }
    });

    return this.getHtmlFromWrapper(wrapper);
  }

  replaceImgFilePathsAndLinksWithCid(html: string, files: IFile[]): string {
    if (!html || _.isEmpty(files)) {
      return html;
    }
    let wrapper = this.getWrapper(html);
    let imgList = wrapper.querySelectorAll('img');

    _.forEach(imgList, (img: HTMLImageElement) => {
      let idAttr = img.getAttribute('id');
      let dataSrcAttr = img.getAttribute('data-src');

      if (idAttr && dataSrcAttr) {
        let correspondingFile = _.find(files, file => [file.id, file.clientId].includes(idAttr));
        img.setAttribute('data-src', 'cid:' + correspondingFile?.id || idAttr);
        return;
      }

      if (dataSrcAttr && dataSrcAttr.startsWith('http')) {
        let correspondingFile = _.find(files, file => dataSrcAttr.includes(file.urlLink));
        if (correspondingFile) {
          img.setAttribute('data-src', 'cid:' + correspondingFile.id);
        }
        return;
      }

      if (dataSrcAttr) {
        let correspondingFile = _.find(files, file => dataSrcAttr.includes(file.id));
        if (correspondingFile) {
          img.setAttribute('data-src', 'cid:' + correspondingFile.id);
        }
        return;
      }
    });

    return this.getHtmlFromWrapper(wrapper);
  }

  replaceImgFilePathsAndCidsWithLinks(html: string, files: FileModel[]): string {
    if (!html || _.isEmpty(files)) {
      return html;
    }

    let wrapper = this.getWrapper(html);
    let imgList = wrapper.querySelectorAll('img');

    _.forEach(imgList, (img: HTMLImageElement) => {
      let srcAttr = img.getAttribute('data-src');

      // If src is remote url, all is good
      if (srcAttr && srcAttr.startsWith('http')) {
        return;
      }

      let idAttr = img.getAttribute('id');
      if (!idAttr && !srcAttr) {
        return;
      }

      let correspondingFile = _.find(files, file => [file.id, file.clientId].includes(idAttr));

      if (!correspondingFile && !idAttr && srcAttr) {
        // Try to recover from local path only
        correspondingFile = _.find(files, file => srcAttr.includes(file.id) || srcAttr.includes(file.clientId));
      }

      if (correspondingFile && correspondingFile.urlLink) {
        img.setAttribute('data-src', correspondingFile.urlLink);

        // Replace clientId fit BE one (if possible)
        if ((!idAttr || idAttr.startsWith(BaseModel.idPrefix)) && correspondingFile.id) {
          img.setAttribute('id', correspondingFile.id);
        }
      }
    });

    return this.getHtmlFromWrapper(wrapper);
  }

  fetchMissingInlineImageFileModels(forUserEmail: string, comment: CommentMailModel): Observable<CommentMailModel> {
    let html = comment?.body?.content;
    if (_.isEmpty(html)) {
      return of(comment);
    }

    // All currently present files
    let files = comment.getAttachments();
    let fileById = _.keyBy(files, 'id');
    let fileByClientId = _.keyBy(files, 'clientId');

    // Get images in body
    let wrapper = this.getWrapper(html);
    let imgList = wrapper.querySelectorAll('img');

    // Collect all imageIds that don't have file model in attachments
    let fileIdsWithNoFileModel = [];
    _.forEach(imgList, (img: HTMLImageElement) => {
      let idAttr = img.getAttribute('id');
      if (idAttr && !fileById[idAttr] && !fileByClientId[idAttr] && this.isValidCid(idAttr)) {
        fileIdsWithNoFileModel.push(idAttr);
      }
    });

    return from(fileIdsWithNoFileModel).pipe(
      mergeMap(
        (fileId: string) =>
          this._fileService.findOrFetchFileById(forUserEmail, fileId).pipe(
            catchError(err => {
              Logger.error(err, `Could not find or fetch file by id ${fileId}`);

              return of(undefined);
            })
          ),
        5
      ),
      toArray(),
      defaultIfEmpty([]),
      map((fetchedFiles: FileModel[]) =>
        _.map(_.compact(fetchedFiles), (file: FileModel) => ((file.hidden = true), file))
      ),
      map((fetchedFiles: FileModel[]) => (comment.addAttachments(fetchedFiles), comment))
    );
  }

  removeUnusedInlineImages(comment: CommentMailModel): Observable<CommentMailModel> {
    let html = comment?.body?.content;
    if (_.isUndefined(html)) {
      return of(comment);
    }

    // All currently present files
    let files = comment.getAttachments();

    if (_.isEmpty(files)) {
      return of(comment);
    }

    // Get images in body
    let wrapper = this.getWrapper(html);
    let imgList = wrapper.querySelectorAll('img');

    // Collect all imageIds that don't have file model in attachments
    let inlineImageFileIds = [];
    _.forEach(imgList, (img: HTMLImageElement) => {
      inlineImageFileIds.push(img.getAttribute('id'));
    });

    // Remove attachments that are hidden but not found in HTML body
    comment.attachments.resources = _.filter(
      files,
      (file: FileModel) =>
        !file.hidden || inlineImageFileIds.includes(file.id) || inlineImageFileIds.includes(file.clientId)
    );

    return of(comment);
  }

  processSignaturesInBodyForSend(html: string): string {
    if (!html) {
      return html;
    }

    let wrapper = this.getWrapper(html);
    let imgList = <NodeListOf<HTMLImageElement>>wrapper.querySelectorAll('img');

    _.forEach(imgList, (img: HTMLImageElement) => {
      let dataSrc = img.getAttribute('src') || img.getAttribute('data-src');

      // Skip when empty, has cid or has base64 image
      if (!dataSrc || dataSrc.startsWith('data:image') || dataSrc.startsWith('cid')) {
        return;
      }

      // Skip when not signature
      if (!dataSrc.endsWith('_signature')) {
        return;
      }

      // Replace path with cid
      let fileId = img.getAttribute('data-cid');
      if (fileId) {
        // Set only data-src
        img.removeAttribute('data-src');
        img.removeAttribute('src');

        img.setAttribute('data-src', 'cid:' + fileId);
      }
    });

    return this.getHtmlFromWrapper(wrapper);
  }

  getHtmlFromWrapper(wrapper: HTMLElement): string {
    let html = wrapper.innerHTML.replace(this.imgDataSrcRegex, ' src=');
    wrapper.remove();
    return html;
  }

  /**
   * Replaces cid in images with local paths
   * <img src="cid:A1_34291ab8284b4ddb942278e010c107f3">
   * <img src="file:///path_to_local_files_folder/hash">
   */
  replaceImgCidWithFilePaths(
    forUserEmail: string,
    html: string,
    commentAttachments: FileModel[],
    options: {
      includeFilePrefix?: boolean;
      isSignature?: boolean;
    } = { includeFilePrefix: true, isSignature: false }
  ): Observable<string> {
    let wrapper = this.getWrapper(html);
    let imgList = <NodeListOf<HTMLImageElement>>wrapper.querySelectorAll('img');

    return from(imgList).pipe(
      mergeMap((img: HTMLImageElement) =>
        this.replaceImgCidWithFilePath(forUserEmail, img, commentAttachments, options)
      ),
      map((img: HTMLImageElement) => this.replaceExpiredFilePath(forUserEmail, img, commentAttachments)),
      toArray(),
      map(() => {
        return this.getHtmlFromWrapper(wrapper);
      })
    );
  }

  private replaceImgCidWithFilePath(
    forUserEmail: string,
    img: HTMLImageElement,
    commentAttachments: FileModel[],
    options: {
      includeFilePrefix?: boolean;
      isSignature?: boolean;
    }
  ): Observable<HTMLImageElement> {
    let dataSrc = img.getAttribute('data-src');
    let fileModelById = _.keyBy(commentAttachments, 'id');

    // Do nothing
    if (!dataSrc) {
      return of(img);
    }

    return of(undefined).pipe(
      mergeMap(() => {
        if (dataSrc.includes('cid:')) {
          // Replace CID with local path to file saved as hash
          let cid = this._fileStorageService.getCidFromFilePath(dataSrc);
          img.setAttribute('id', cid);

          // We can get hash from comment attachments
          let file = fileModelById[cid];
          if (file && file.hash) {
            const fileName = this._fileStorageService.getFilePath(file.hash, options.includeFilePrefix, file.urlLink);
            if (!file.hidden) {
              return of(fileName);
            }

            return this._fileStorageService.fileExists(file.hash).pipe(
              map((exists: boolean) => {
                return !exists ? file.urlLink : fileName;
              })
            );
          }

          // In case we have file, but don't have hash we find or fetch it
          if (file || options.isSignature) {
            // In case we don't have file in attachments, we find or. fetch it
            return this._fileService
              .getFilePathWithHash(forUserEmail, cid, options.includeFilePrefix, options.isSignature)
              .pipe(
                mergeMap((filePathWithHash: FilePathWithHash) => {
                  if (filePathWithHash.fetchStatus === 403) {
                    img.setAttribute('class', '403-image');
                  }
                  /**
                   * There is a case for signature inline images, where
                   * GET file/ returns 400
                   */
                  if (filePathWithHash.fetchStatus === 400) {
                    img.setAttribute('class', '400-image');
                  }
                  return of(filePathWithHash.filePath);
                })
              );
          }

          // If there is no file on attachment list, we don't have access to it
          img.setAttribute('class', '403-image');
          return of(cid);
        } else if (!options.includeFilePrefix) {
          // Remove 'file://' prefix
          return of(dataSrc.replace('file://', ''));
        } else {
          return of(dataSrc);
        }
      }),
      mergeMap((transformedDataSrc: string) => {
        if (transformedDataSrc) {
          img.setAttribute('data-src', transformedDataSrc);
        }

        if (transformedDataSrc && transformedDataSrc.startsWith('file://')) {
          img.setAttribute('onerror', "this.classList.add('img-broken')");
        }

        return of(img);
      })
    );
  }

  protected replaceExpiredFilePath(
    forUserEmail: string,
    img: HTMLImageElement,
    commentAttachments: FileModel[]
  ): HTMLImageElement {
    let src = img.getAttribute('data-src');

    // Validate only loop links
    if (!src || !src.startsWith('https://files.intheloop.io')) {
      return img;
    }

    let expirationDate = FileUrlStorageService.getLinkExpirationDate(src);

    // Return current body if not expired
    if (!FileUrlStorageService.isLinkExpired(expirationDate)) {
      return img;
    }

    // Find corresponding file model
    let fileId = img.getAttribute('id');
    let file = _.find(commentAttachments, attachment => attachment.id === fileId);

    if (!file) {
      return img;
    }

    // Replace and return
    let fileUrl = this._fileStorageService.getFilePath(file.hash, false, file.urlLink);

    if (fileUrl) {
      img.setAttribute('data-src', fileUrl);
    }
    return img;
  }
}
