import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from '@shared/ui/alert/alert.component';
import { IconModule } from '@shared/modules/common/icon/icon.module';
import { StringTemplateOutletModule } from '@shared/ui/kit/string-template-outlet/string-template-outlet.module';
import { AtomModule } from '@shared/modules/color-scheme/atom/atom.module';

@NgModule({
  declarations: [AlertComponent],
  imports: [CommonModule, IconModule, StringTemplateOutletModule],
  exports: [AlertComponent, AtomModule]
})
export class AlertModule {}
