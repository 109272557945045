import * as _ from 'lodash';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { Logger } from '@shared/services/logger/logger';
import { ContactService } from '@shared/services/data/contact/contact.service';
import { SharedTagService } from '@shared/services/data/shared-tags/shared-tags.service';
import { ContactStoreFactory } from '@shared/stores/contact-store/contact-store.factory';
import { SharedTagClassificationModel, SharedTagModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { LogTag } from '@dta/shared/models/logger.model';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { ConversationCardBaseModel } from '@dta/shared/models-api-loop/conversation-card/conversation-card.model';
import { BasePopulateService } from '../base-populate/base-populate.service';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { TagLabelModel, TagModel } from '@dta/shared/models-api-loop/tag.model';
import { TagLabelService } from '@shared/services/data/tag-label/tag-label.service';
import { SmartClassificationSharedTagApi } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/smart-classification-shared-tag-api';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

export abstract class ConversationCardPopulateService<T extends BaseModel> extends BasePopulateService<T> {
  constructor(
    protected _contactService: ContactService,
    protected _contactStoreFactory: ContactStoreFactory,
    protected _sharedTagService: SharedTagService,
    protected _tagLabelService: TagLabelService,
    protected readonly smartClassificationSharedTagApi: SmartClassificationSharedTagApi
  ) {
    super(_contactService, _contactStoreFactory);
  }

  ///////////////
  // PrivateTags
  ///////////////
  protected populateWithPrivateTags(
    forUserEmail: string,
    conversations: ConversationCardBaseModel[]
  ): Observable<ConversationCardBaseModel[]> {
    let allTagIds = conversations
      .map(c => c.getTags())
      .flat()
      .filter(t => t.$type === TagLabelModel.type)
      .map(t => t.id);

    // Return conversations when there are no tags to update
    if (_.isEmpty(allTagIds)) {
      return of(conversations);
    }

    return of(undefined).pipe(
      /**
       * Find tags in database
       */
      mergeMap(() => {
        return this._tagLabelService.findByIds(forUserEmail, allTagIds);
      }),
      /**
       * Fetch missing tags
       */
      mergeMap((localTags: TagModel[]) => {
        let localTagsIds = _.map(localTags, c => c?.id);
        let missingTagIds = _.xor(localTagsIds, allTagIds);

        if (_.isEmpty(missingTagIds)) {
          return of(localTags);
        }

        // Fetch missing
        return this._tagLabelService
          .fetchAndSaveTags(forUserEmail, _.compact(missingTagIds))
          .pipe(map(newTags => [...localTags, ...newTags]));
      }),
      /**
       * Populate tags
       */
      map((tags: TagModel[]) => {
        _.forEach(conversations, conversation => conversation.populateWithTags(tags));
        return conversations;
      })
    );
  }

  protected populateWithClassificationTags(
    forUserEmail: string,
    conversations: ConversationModel[]
  ): Observable<ConversationModel[]> {
    if (!conversations?.length) {
      return of(conversations);
    }

    const populatedConversations$ = conversations.map(conversation => {
      const classificationTag = conversation.getClassificationTag();
      if (!classificationTag) {
        return of(conversation);
      }

      return this.smartClassificationSharedTagApi.getSingle$(classificationTag.id, forUserEmail).pipe(
        map(classificationTag => {
          if (!classificationTag) {
            return conversation;
          }
          conversation.populateWithSharedTags([classificationTag]);
          return conversation;
        })
      );
    });

    return zip(...populatedConversations$);
  }

  //////////////
  // SharedTags
  //////////////
  protected populateWithSharedTags(
    forUserEmail: string,
    conversations: ConversationCardBaseModel[]
  ): Observable<ConversationCardBaseModel[]> {
    let allSharedTags = _.flatten(
      _.map(conversations, conversation =>
        conversation.getSharedTags().filter(sharedTag => sharedTag.$type !== SharedTagClassificationModel.type)
      )
    );
    let allSharedTagIds = _.map(allSharedTags, sharedTag => sharedTag.id);

    // Return conversations when there are no shared tags to update
    if (_.isEmpty(allSharedTagIds)) {
      return of(conversations);
    }

    return of(undefined).pipe(
      /**
       * Find shared tags in database
       */
      mergeMap(() => {
        return this._sharedTagService.findByIds(forUserEmail, _.uniq(allSharedTagIds));
      }),
      /**
       * Fetch missing shared tags
       */
      mergeMap((localSharedTags: SharedTagModel[]) => {
        let localSharedTagsIds = _.map(localSharedTags, c => c && c.id);
        let missingSharedTagIds = _.xor(localSharedTagsIds, allSharedTagIds);

        // Fetch missing
        if (!_.isEmpty(missingSharedTagIds)) {
          return this.fetchMissing(forUserEmail, conversations, missingSharedTagIds).pipe(
            map(newSharedTags => [...localSharedTags, ...newSharedTags])
          );
        }

        // Or return when no missing
        return of(localSharedTags);
      }),
      /**
       * Populate shared tags
       */
      map((sharedTags: SharedTagModel[]) => {
        _.forEach(conversations, conversation => conversation.populateWithSharedTags(sharedTags));
        return conversations;
      })
    );
  }

  private fetchMissing(
    forUserEmail: string,
    models: ConversationCardBaseModel[],
    missingSharedTagIds: string[]
  ): Observable<SharedTagModel[]> {
    return of(undefined).pipe(
      /**
       * Fetch missing
       */
      mergeMap(() => {
        let cardIdBySharedTagId = this.buildCardIdBySharedTagId(models, missingSharedTagIds);
        return this._sharedTagService.fetchAndSaveMissingSharedTagsForParents(forUserEmail, cardIdBySharedTagId);
      }),
      /**
       * Catch, log as interesting and handle error, return empty list
       */
      catchError(err => {
        Logger.error(err, `Error in ${this.constructorName}:fetchMissing`, LogTag.INTERESTING_ERROR, true);

        return of([]);
      })
    );
  }

  protected abstract buildCardIdBySharedTagId(
    models: ConversationCardBaseModel[],
    missingSharedTagIds: string[]
  ): { [sharedTagId: string]: string };

  /////////////
  // Contacts
  /////////////
  protected populateWithContacts(
    forUserEmail: string,
    models: ConversationCardBaseModel[],
    populateWithLocalOnly?: boolean
  ): Observable<any> {
    return from(models).pipe(
      mergeMap((model: ConversationCardBaseModel) => {
        let undecoratedContacts = model.getAllContacts();
        let undecoratedContactsIds = _.map(undecoratedContacts, contact => contact.id);

        // Filter out undefined and double values (un-sync contacts)
        undecoratedContactsIds = _.uniq(_.compact(undecoratedContactsIds));

        return (
          populateWithLocalOnly
            ? this.findContactsByCard(forUserEmail, undecoratedContactsIds, true)
            : this.findOrFetchContactsByCard(forUserEmail, undecoratedContactsIds, model.id)
        ).pipe(
          map((contacts: ContactModel[]) => {
            model.populateWithContacts(_.compact(contacts));
            return model;
          })
        );
      }),
      toArray()
    );
  }
}
