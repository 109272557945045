import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contains',
})
export class ContainsPipe<T> implements PipeTransform {
  transform(values: T | T[], item: T): boolean {
    if (!values) {
      return false;
    }

    if (!Array.isArray(values)) {
      values = [values];
    }

    return values.some(value => value === item);
  }
}
