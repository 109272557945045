import { Observable, throwError } from 'rxjs';
import { ContactModel, GroupModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { Directive } from '@angular/core';
import { SubscriptionServiceI } from './subscription.service.interface';
import { ContactService } from '../contact/contact.service';
import { GroupApiService } from '@shared/api/api-loop/services';
import { catchError, mergeMap } from 'rxjs/operators';
import { Logger } from '../../logger/logger';
import { LogTag } from '@dta/shared/models/logger.model';
import { Group } from '@shared/api/api-loop/models';

@Directive()
export class SubscriptionService implements SubscriptionServiceI {
  constructor(
    private _contactService: ContactService,
    private _groupApiService: GroupApiService,
  ) {}

  changeSubscribeStatusOfTeam(
    forUserEmail: string,
    action: 'subscribe' | 'unsubscribe',
    groupId: string,
  ): Observable<ContactModel> {
    let obs =
      action === 'subscribe'
        ? this._groupApiService.Group_GroupSubscribe({ groupId }, forUserEmail)
        : this._groupApiService.Group_GroupUnSubscribe({ groupId }, forUserEmail);

    return obs.pipe(
      /**
       * Save
       */
      mergeMap((group: Group) => {
        let groupModel = GroupModel.create(group);
        return this._contactService.saveAndPublish(forUserEmail, groupModel);
      }),
      catchError(err => {
        Logger.error(
          err,
          'Error while changing subscribe status of team ' + `${groupId} to ${action} for user ${forUserEmail}`,
          LogTag.INTERESTING_ERROR,
        );
        return throwError(err);
      }),
    );
  }
}
