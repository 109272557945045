import { Injectable } from '@angular/core';

@Injectable()
export class BBCodeService {
  static collapse2AsHTML = '<div class="collapse2">';
  static collapseAsHTML = '<div class="collapse">';

  private xbbCodeParser: XBBCodeParser = require('Extendible-BBCode-Parser');
  private urlPattern = /^(?:https?|file|c|intheloop):(?:\/{1,3}|\\{1})[-a-zA-Z0-9:;@#%&()~_?+=\/\\.]*$/;

  constructor() {
    this.initTags();
  }

  process(config: XBBCodeConfig, unescapeHTML: boolean = true): string {
    let result = this.xbbCodeParser.process(config);

    if (unescapeHTML) {
      return result.html
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&nbsp;/g, ' ');
    } else {
      return result.html;
    }
  }

  private initTags() {
    this.xbbCodeParser.addTags({
      time: {
        openTag: (params: string, content: string) => {
          return '<span class="time" data-utc="';
        },
        closeTag: (params: string, content: string) => {
          let date = new Date(content);
          return '">' + date.toLocaleDateString() + ' ' + date.toLocaleTimeString() + '</span>';
        },
      },
      br: {
        openTag: (params: string, content: string) => {
          return '<br>' + content;
        },
        closeTag: (params: string, content: string) => {
          return '';
        },
      },
      hr: {
        openTag: (params: string, content: string) => {
          return '<hr>' + content;
        },
        closeTag: (params: string, content: string) => {
          return '';
        },
      },
      img: {
        openTag: (params: string, content: string) => {
          let imgId = params.replace('src=', '').replace(' ', '');
          return '<img src=' + imgId + ' />';
        },
        closeTag: (params: string, content: string) => {
          return '';
        },
        displayContent: false,
      },
      url: {
        openTag: (params: string, content: string) => {
          let myUrl;
          let hidePreview = params ? params.includes('preview=False') : true;

          if (params) {
            let match = params.match(/"(.*?)"/);
            if (match && match.length > 1) {
              myUrl = match[1];
            }
          } else {
            myUrl = content.replace(/<.*?>/g, '');
          }

          this.urlPattern.lastIndex = 0;
          if (!this.urlPattern.test(myUrl)) {
            myUrl = 'https://' + myUrl;
          }

          return `<a ${hidePreview ? 'class="hide-preview"' : ''} target="_blank" href="${myUrl}">`;
        },
        closeTag: (params: string, content: string) => {
          return '</a>';
        },
      },
      collapse: {
        openTag: (params: string, content: string) => {
          return BBCodeService.collapseAsHTML + content;
        },
        closeTag: (params: string, content: string) => {
          return '</div>';
        },
        displayContent: false,
        removeTags: true,
      },
      collapse2: {
        openTag: (params: string, content: string) => {
          return BBCodeService.collapse2AsHTML + content;
        },
        closeTag: (params: string, content: string) => {
          return '</div>';
        },
        displayContent: false,
        removeTags: true,
      },
      user: {
        openTag: (params: string, content: string) => {
          let contactId = params.split('"')[1];
          if (!contactId) {
            return `<span class="tag">@`;
          }

          return `<span class="tag" contact-id="${contactId}">@`;
        },
        closeTag: (params: string, content: string) => {
          return '</span>';
        },
        removeTags: true,
      },
      group: {
        openTag: (params: string, content: string) => {
          let contactId = params.split('"')[1];
          if (!contactId) {
            return `<span class="tag">@`;
          }

          return `<span class="tag" contact-id="${contactId}">@`;
        },
        closeTag: (params: string, content: string) => {
          return '</span>';
        },
        removeTags: true,
      },
    });
  }
}

interface XBBCodeConfig {
  text: string;
  removeTags?: boolean;
  removeMisalignedTags?: boolean;
  addInLineBreaks?: boolean;
  escapeHtml?: boolean;
}

interface XBBCodeParser {
  process: (config: XBBCodeConfig) => XBBCodeResult;
  addTags: (tags: XBBCodeTags) => void;
}

interface XBBCodeResult {
  html: string;
  error: boolean;
  errQueue: string[];
}

interface XBBCodeTag {
  openTag: (params: string, content: string) => string;
  closeTag: (params: string, content: string) => string;
  displayContent?: boolean;
  restrictChildrenTo?: string[];
  restrictParentsTo?: string[];
  noParse?: boolean;

  removeTags?: boolean;
}

type XBBCodeTags = { [tagName: string]: XBBCodeTag };
