import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  BaseFormControlComponent,
  provideControlValueAccessor
} from '@shared/modules/form-controls/base-form-control.component';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { createOptions } from '@shared/modules/form-controls/wysiwyg-control/internal/create-options';
import { defaultToolbarOptions } from '@shared/modules/form-controls/wysiwyg-control/internal/default-toolbar-options';
import { EnumObjectValue } from '@shared/utils/common/types';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { FileApiService } from '@shared/api/api-loop/services/file-api.service';
import { getTextBeforeCaret } from '@shared/modules/detail-view/common/helpers/get-text-before-caret';
import {
  insertElementAtCursor,
  removeCharactersBeforeCursor
} from '@shared/modules/detail-view/common/helpers/insert-text-at-cursor';
import { MentionDirective, MentionPlacement } from '@shared/modules/contacts/components/mention/mention.directive';
import { ContactApiService } from '@shared/api/api-loop/services';
import { ContactType } from '@shared/api/api-loop/models';
import { DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { checkIfOS, OperatingSystem } from '@dta/shared/utils/common-utils';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, EMPTY, from, Observable, of, ReplaySubject } from 'rxjs';
import * as $ from 'jquery';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import FroalaEditor from 'froala-editor';
import { v1 } from 'uuid';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { ToolbarOption } from './internal/toolbar-option';

export const AUTO_SIZE_CUSTOMS = {
  fullSize: 'fullSize'
} as const;

export interface Autosize {
  minRows: number;
  maxRows: number | null;
}

const STYLES_TO_COPY: string[] = [
  'font-size',
  'font-style',
  'font-weight',
  'color',
  'font-family',
  'text-transform',
  'text-line',
  'text-decoration-color',
  'text-decoration-line',
  'text-decoration-skip-ink',
  'text-decoration-style'
];

const EMPTY_LINE: string = '<div><br></div>';
const emptyLineRegex = /^\s*(<div[^>]*>\s*<br[^>]*>\s*<\/div>\s*)/i;

@UntilDestroy()
@Component({
  selector: 'loop-wysiwyg-control',
  templateUrl: './wysiwyg-control.component.html',
  styleUrls: ['./wysiwyg-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideControlValueAccessor(WysiwygControlComponent)],
  encapsulation: ViewEncapsulation.None
})
export class WysiwygControlComponent extends BaseFormControlComponent<string> implements AfterViewInit, OnDestroy {
  private readonly fileApiService: FileApiService = inject(FileApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly renderer2: Renderer2 = inject(Renderer2);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly storageService: StorageService = inject(StorageService);

  showFroalaToolbar: boolean = false;
  protected copiedStyle: string | null = null;
  options: any;
  private imageIdCounter: number = 0;

  @Input() toolbarOptions: ToolbarOption[] | Record<string, any> = defaultToolbarOptions;
  @Input() toolbarContainerId?: string;
  @Input() autoSize: Autosize | EnumObjectValue<typeof AUTO_SIZE_CUSTOMS> = { minRows: 2, maxRows: 6 };
  @Input() uploadInlineImagesAsBase64: boolean = false;

  @Input() appendedContentTpls: TemplateRef<any>[] = [];
  @Input() isLoopMentionDisabled: boolean = true;
  @Input() focusBeforeElementIds: string[] = [];

  @Output() inlineFileUpload: EventEmitter<IFile> = new EventEmitter();
  @Output() userMentioned: EventEmitter<ContactModel> = new EventEmitter();

  protected readonly MentionPlacement_Bottom: typeof MentionPlacement.bottom = MentionPlacement.bottom;
  protected readonly overriddenContactOptions: ContactApiService.Contact_GetListParams = {
    contactIds: undefined,
    contactTypes: [ContactType.USER]
  };

  private readonly froalaEditor$: ReplaySubject<FroalaEditor> = new ReplaySubject<FroalaEditor>(1);

  protected readonly wysiwygOptions: DropdownItem[] = [
    {
      title: 'Copy',
      action: () => {
        // eslint-disable-next-line
        console.log('Copied to clipboard');
        document.execCommand('copy');
      },
      isAvailable$: this.froalaEditor$.pipe(
        map(froalaEditor => froalaEditor.selection.get()?.toString()?.length > 0),
        take(1)
      )
    },
    {
      title: 'Paste',
      action: () => {
        document.execCommand('paste');
      }
      //   from(navigator.clipboard.read())
      //     .pipe(
      //       switchMap(clipboardItems => {
      //         const textHtmlItem = clipboardItems.find(item => item.types.includes('text/html'));
      //         const imageItem = clipboardItems.find(item => item.types.includes('image/png'));
      //
      //         if (imageItem) {
      //           return combineLatest([
      //             from(imageItem.getType('image/png')),
      //             this.froalaEditor$
      //           ]).pipe(take(1), tap(([image, froalaEditor]) => {
      //             froalaEditor.image.upload([image]);
      //           }));
      //         }
      //
      //
      //         const textObs$ = textHtmlItem
      //         ? combineLatest([
      //           from(textHtmlItem.getType('text/html')).pipe(switchMap(blob => from(blob.text()))),
      //           this.froalaEditor$
      //         ]).pipe(take(1))
      //         : combineLatest([from(navigator.clipboard.readText()), this.froalaEditor$])
      //
      //         return textObs$.pipe(
      //           tap(([text, froala]) => {
      //             if (Object.values(ClipboardEvents).some(value => text.startsWith(getLoopClipboardKey(value)))) {
      //               const pasteEvent = new CustomEvent('customPaste', { detail: { pastedText: text } });
      //               document.dispatchEvent(pasteEvent);
      //               return;
      //             }
      //
      //             froala.html.insert(text);
      //           })
      //         )
      //       }),
      //       take(1)
      //     )
      //     .subscribe();
      // }
    },
    {
      title: 'Paste without formatting',
      action: () => {
        this.pastePlain();
      }
    }
  ];

  private pastePlain(): void {
    combineLatest([from(navigator.clipboard.readText()), this.froalaEditor$])
      .pipe(take(1))
      .subscribe(([text, froala]) => {
        froala.html.insert(text ?? '', true);
      });
  }

  @HostListener('document:mouseup')
  private handleKeyboardEvent(): void {
    this.onMouseUp();
  }

  @ViewChild(MentionDirective) mentionDirective?: MentionDirective;

  ngAfterViewInit(): void {
    this.setupWysiwyg();
    setTimeout(() => {
      this.showFroalaToolbar = true;
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  protected handleMentionContactClick(contact: ContactModel): void {
    const mentionWrapper = document.createElement('div');
    mentionWrapper.innerHTML = `<a class="fr-deletable" href="mailto:${contact.email}" contenteditable="false">${contact.name.split('@')[0].trim()}</a>&nbsp;`;
    const textBeforeCaret = getTextBeforeCaret();
    const lastAtIndex = textBeforeCaret.lastIndexOf('@');
    removeCharactersBeforeCursor(textBeforeCaret.substring(lastAtIndex).length);
    insertElementAtCursor(mentionWrapper.firstChild as Element);
    mentionWrapper.remove();
    this.userMentioned.next(contact);
  }

  private observeIsDisabled(): void {
    combineLatest([this.froalaEditor$, this.isDisabled$])
      .pipe(
        untilDestroyed(this),
        tap(([froalaEditor, isDisabled]) => {
          if (isDisabled) {
            froalaEditor.edit.off();
          } else {
            froalaEditor.edit.on();
          }
        })
      )
      .subscribe();
  }

  private setupWysiwyg(): void {
    this.options = createOptions({
      placeholder: this.placeholder,
      toolbarContainerId: this.toolbarContainerId ?? 'froala-toolbar-container-base',
      isBase64ImageUpload: this.uploadInlineImagesAsBase64
    });
    this.options.toolbarButtons = this.toolbarOptions;

    let _this = this;
    this.options.events = {
      'initialized': function () {
        _this.froalaEditor$.next(this);
        _this.observeIsDisabled();
        _this.setupWysiwygStyling(this);
        _this.setupKeydownEvents(this);
      },
      'focus': () => {
        this.froalaFocusHandler();
      },
      'input': function () {
        _this.handleInput(this);
      },
      'image.beforeUpload': (blobs: Blob[], images: any[]) => _this.froalaBeforeUpload(blobs, images),
      'image.loaded': function ($img) {
        if (_this.imageIdCounter > 0) {
          this.selection.setAfter($img[0]);
          this.selection.restore();
          this.events.trigger('image.hideResizer', [], true);
        }
      },
      'commands.after': (cmd: string, param1?: any) => {
        switch (cmd) {
          case 'fontFamily':
            this.storageService.setItem(StorageKey.userFont, param1);
            break;
          case 'fontSize':
            this.storageService.setItem(StorageKey.userFontSize, param1);
            break;
        }
      }
    };
    this.setFonts();
    this.setFormatPainter();
  }

  private handleInput(froalaEditor: FroalaEditor): void {
    this.mentionDirective.setCustomElementRef(froalaEditor.selection.endElement());
    this.mentionDirective.canOpenMentionComponent();
  }

  private setupKeydownEvents(froalaEditor: FroalaEditor): void {
    froalaEditor.events.on(
      'keydown',
      keydownEvent => {
        if (this.mentionDirective?.isMenuOpened) {
          if (keydownEvent.key === 'Backspace') {
            setTimeout(() => {
              this.handleInput(froalaEditor);
            });
          }

          if (keydownEvent.key === 'Enter') {
            keydownEvent.preventDefault();
            keydownEvent.stopPropagation();
            this.mentionDirective?.componentRef?.instance.handleCurrentContactClick();

            return false;
          }
        }

        if ((keydownEvent.ctrlKey || keydownEvent.metaKey) && keydownEvent.key === 'Enter') {
          froalaEditor.undo.saveStep();
          this.handleModelChange(froalaEditor.html.get());
          keydownEvent.preventDefault();
          keydownEvent.stopPropagation();
          return false;
        }

        return true;
      },
      true
    );
  }

  private setFormatPainter(): void {
    let _this = this;
    FroalaEditor.DefineIconTemplate(
      'material_design',
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14px" height="14px">\n' +
        '  <path\n' +
        '    fill="currentColor"\n' +
        '    d="M0 64C0 28.7 28.7 0 64 0L352 0c35.3 0 64 28.7 64 64l0 64c0 35.3-28.7 64-64 64L64 192c-35.3 0-64-28.7-64-64L0 64zM160 352c0-17.7 14.3-32 32-32l0-16c0-44.2 35.8-80 80-80l144 0c17.7 0 32-14.3 32-32l0-32 0-90.5c37.3 13.2 64 48.7 64 90.5l0 32c0 53-43 96-96 96l-144 0c-8.8 0-16 7.2-16 16l0 16c17.7 0 32 14.3 32 32l0 128c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-128z"/>\n' +
        '</svg>'
    );
    FroalaEditor.DefineIcon('formatPainter', { NAME: 'paint-brush', template: 'material_design' });
    FroalaEditor.RegisterCommand('formatPainter', {
      title: 'Format Painter',
      focus: true,
      undo: true,
      callback: function () {
        if (_this.copiedStyle) {
          _this.copiedStyle = null; // Reset the copied style after applying
        } else {
          // Copy the style of the selected text
          const selectedElement = this.selection.element();
          let style = selectedElement.getAttribute('style');
          const computedStyle = window.getComputedStyle(selectedElement);
          const computedStyleParent = window.getComputedStyle(selectedElement.parentElement);

          STYLES_TO_COPY.forEach(styleToCopy => {
            const styleToCopyValue = computedStyle.getPropertyValue(styleToCopy);
            style += `;${styleToCopy}:${!!computedStyle && styleToCopyValue !== 'none' ? styleToCopyValue : computedStyleParent.getPropertyValue(styleToCopy)}`;
          });
          _this.copiedStyle = style;
          _this.changeDetectorRef.detectChanges();
        }
      },
      refresh: function (button) {
        const extractedButton = button[0];
        if (_this.copiedStyle && extractedButton instanceof HTMLElement) {
          extractedButton.classList.add('fr-active');
        } else {
          extractedButton.classList.remove('fr-active');
        }
      }
    });
  }

  private setupWysiwygStyling(froalaEditor: FroalaEditor): void {
    const froalaElement = froalaEditor.el;

    const lineHeight: number = froalaElement?.childNodes[1]?.firstChild?.clientHeight ?? 20;
    const paddingOffset: number = 9.6;
    const pixelFallbackMultiplier: number = lineHeight >= 10 && lineHeight <= 50 ? lineHeight : 20;
    const fontSize = this.getFontSizeSelection();

    (document.querySelector('.fr-view') as HTMLElement).style.fontSize = fontSize;

    if (document.querySelector('.fr-placeholder')) {
      (document.querySelector('.fr-placeholder') as HTMLElement).style.fontSize = fontSize;
    }

    /* Set default font family */
    const frView = document.querySelector('.fr-view') as HTMLElement;
    if (frView) {
      frView.style.fontFamily =
        Object.entries(this.options.fontFamily).find(
          ([_, val]) => val === this.options.fontFamilyDefaultSelection
        )?.[0] || null;
    }

    if (this.autoSize === AUTO_SIZE_CUSTOMS.fullSize) {
      froalaElement.style.height = `100%`;
      return;
    }

    // set fallback max/min height for browsers not supporting 'lh' unit
    froalaElement.style.minHeight = `calc(${this.autoSize.minRows * pixelFallbackMultiplier}px + ${paddingOffset}px)`;

    if (this.autoSize.maxRows) {
      froalaElement.style.maxHeight = `calc(${this.autoSize.maxRows * pixelFallbackMultiplier}px + ${paddingOffset}px)`;
    }

    // because part of height is also padding we need to add the offset that is created with padding
    froalaElement.style.minHeight = `calc(${this.autoSize.minRows}lh + ${paddingOffset}px)`;

    if (this.autoSize.maxRows) {
      froalaElement.style.maxHeight = `calc(${this.autoSize.maxRows}lh + ${paddingOffset}px)`;
    }
  }

  // https://github.com/froala/wysiwyg-editor/issues/3457 -> workaround for links when disabled...
  onClick($event: MouseEvent): void {
    if ($event.target instanceof HTMLAnchorElement) {
      window.open($event.target.href, '_blank');
    }
  }

  private onMouseUp(): void {
    if (this.copiedStyle) {
      this.froalaEditor$.pipe(take(1), untilDestroyed(this)).subscribe(froalaEditor => {
        const selectedRange: any = froalaEditor.selection.get();
        if (selectedRange && selectedRange.rangeCount > 0) {
          // Check if there's a range selected
          if (selectedRange.rangeCount === 0) {
            return;
          }

          const range = selectedRange.getRangeAt(0);
          const nodes = [];

          // Start with the common ancestor of the range
          const commonAncestor = range.commonAncestorContainer;

          // If the common ancestor is not a full element (text node, etc), traverse upwards
          if (commonAncestor.nodeType === Node.ELEMENT_NODE) {
            nodes.push(...this.getNodesInRange(commonAncestor, range));
          } else {
            nodes.push(commonAncestor);
          }

          const span = document.createElement('span');
          span.style.cssText += `${this.copiedStyle};`; // Add your custom class

          nodes.forEach((node, i) => {
            const styledSpan = span.cloneNode();
            if (node.parentNode instanceof HTMLElement) {
              if (nodes.length > 2 && i !== 0 && i !== nodes.length - 1) {
                node.parentNode.insertBefore(styledSpan, node);
                styledSpan.appendChild(node);
              } else if (nodes.length === 1) {
                const endElement = node.splitText(range.endOffset);
                const element = node.splitText(range.startOffset);
                node.parentNode.insertBefore(styledSpan, endElement);
                styledSpan.appendChild(element);
              } else if (i === 0) {
                const element = node.splitText(range.startOffset);
                node.parentNode.insertBefore(styledSpan, element);
                styledSpan.appendChild(element);
              } else {
                node.splitText(range.endOffset);
                node.parentNode.insertBefore(styledSpan, node);
                styledSpan.appendChild(node);
              }
            }
          });

          // After inserting, collapse the range to remove the selection
          froalaEditor.selection.clear();
        }
        // this.froalaActiveEditor.html.insert(span.outerHTML);
        this.copiedStyle = null;
      });
    }
  }

  private getNodesInRange(node: Node, range: Range): Node[] {
    const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, {
      acceptNode: node => {
        return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
      }
    });

    const nodes = [];
    while (walker.nextNode()) {
      nodes.push(walker.currentNode);
    }

    return nodes;
  }

  private setFonts(): void {
    /* Set first half of fonts so that System is alphabetically correctly placed */
    this.options.fontFamily['Arial,Helvetica,sans-serif'] = 'Arial';
    this.options.fontFamily['Calibri,sans-serif'] = 'Calibri';
    this.options.fontFamily['Georgia,serif'] = 'Georgia';
    this.options.fontFamily['Impact,Charcoal,sans-serif'] = 'Impact';
    this.options.fontFamily['Open Sans,sans-serif'] = 'Open Sans';
    this.options.fontFamily['Roboto,sans-serif'] = 'Roboto';

    /* Windows fonts */
    if (checkIfOS(OperatingSystem.WINDOWS)) {
      this.options.fontFamily['Segoe UI'] = 'System (Segoe UI)';
      this.options.fontFamilyDefaultSelection = 'System (Segoe UI)';
    }

    /* iOS fonts */
    if (checkIfOS(OperatingSystem.DARWIN)) {
      this.options.fontFamily['-apple-system,BlinkMacSystemFont,sans-serif'] = 'System (San Francisco)';
      this.options.fontFamilyDefaultSelection = 'System (San Francisco)';
    }

    /* Linux fonts */
    if (checkIfOS(OperatingSystem.LINUX)) {
      this.options.fontFamily['Ubuntu, Noto, sans-serif'] = 'System (Linux)';
      this.options.fontFamilyDefaultSelection = 'System (Linux)';
    }

    /* Set second half of fonts so that System is alphabetically correctly placed */
    this.options.fontFamily['Tahoma,Geneva,sans-serif'] = 'Tahoma';
    this.options.fontFamily["'Times New Roman',Times,serif"] = 'Times New Roman';
    this.options.fontFamily['Verdana,Geneva,sans-serif'] = 'Verdana';

    let userFont = this.storageService.getItem(StorageKey.userFont);
    if (userFont !== undefined) {
      this.options.fontFamilyDefaultSelection = this.options.fontFamily[userFont];
    }

    this.options.fontSizeDefaultSelection = this.getFontSizeSelection().split('pt')[0];
  }

  private getFontSizeSelection(): string {
    return this.storageService.getItem(StorageKey.userFontSize) || '12pt';
  }

  private froalaFocusHandler(): void {
    this.onFocus();
  }

  private froalaBeforeUpload(blobs: Blob[], images: any[]): void {
    const imageId = this.imageIdCounter;
    this.imageIdCounter++;
    const imagePlacement = images[0];
    imagePlacement.id = `image-${imageId}`;

    this.froalaEditor$
      .pipe(
        switchMap(froalaEditor => {
          if (this.uploadInlineImagesAsBase64) {
            if (blobs.length) {
              // Create a File Reader.
              const reader = new FileReader();
              // Set the reader to insert images when they are loaded.
              reader.onload = function (e) {
                const result = e.target.result as string;
                const image = froalaEditor.image.get();
                froalaEditor.image.insert(result, null, null, image);
              };
              // Read image as base64.
              reader.readAsDataURL(blobs[0]);
            }
            froalaEditor.popups.hideAll();
            // Stop default upload chain.
            return of(null);
          }

          const img = blobs[0];
          const blob = img.slice(0, img.size, img.type);
          const uuid = v1();

          return this.fileApiService
            .File_CreateFile(
              {
                file: new File([blob], `${uuid}.${img.type.split('/')[1]}`, { type: img.type }),
                includeSignedLink: true
              },
              this.userManagerService.getCurrentUserEmail()
            )
            .pipe(
              map((response: IFile) => {
                return new FileModel(response);
              })
            )
            .pipe(
              take(1),
              untilDestroyed(this),
              catchError(() => {
                froalaEditor.image.remove($(`#image-${imageId}`));
                return EMPTY;
              }),
              tap(file => {
                if (!file) {
                  return false;
                }

                const image1 = this.renderer2.selectRootElement(`#image-${imageId}`);
                image1.src = file.urlLink;
                image1.id = file.id;
                froalaEditor.undo.saveStep();
                this.inlineFileUpload.emit(file);
                return false;
              })
            );
        })
      )
      .subscribe();
  }

  insertAtCursor(text: string): void {
    this.froalaEditor$.pipe(take(1), untilDestroyed(this)).subscribe(froalaEditor => {
      this.focusFroala(froalaEditor, () => {
        froalaEditor.selection.restore();
        froalaEditor.html.insert(text);
        froalaEditor.undo.saveStep();
        const lastInsertedElement = froalaEditor.$el.find(text).last();
        if (lastInsertedElement.length) {
          froalaEditor.selection.setAtEnd(lastInsertedElement.get(0));
          froalaEditor.selection.save();
        }
      });
    });
  }

  saveSelection$(): Observable<void> {
    return this.froalaEditor$.pipe(
      take(1),
      tap(froalaEditor => froalaEditor.selection.save()),
      map(() => undefined)
    );
  }

  insertBeforeQuerySelector$({
    text,
    insertBeforeElementId,
    elementToRemoveId
  }: {
    text: string;
    insertBeforeElementId: string;
    elementToRemoveId: string;
  }): Observable<void> {
    return this.froalaEditor$.pipe(
      take(1),
      switchMap(froalaEditor => {
        if (!elementToRemoveId) {
          return of(froalaEditor);
        }
        return this.removeById$(elementToRemoveId).pipe(map(() => froalaEditor));
      }),
      tap(froalaEditor => {
        const targetElement =
          froalaEditor.$el.find(`#${insertBeforeElementId}`)[0] ??
          froalaEditor.$el.find(`.${insertBeforeElementId}`)[0];

        if (targetElement) {
          this.insertBeforeElement(froalaEditor, targetElement, text);
        } else {
          this.insertAtEnd(froalaEditor, text);
        }

        froalaEditor.undo.saveStep();
        this.focusFroala(froalaEditor);
      }),
      map(() => undefined)
    );
  }

  private insertBeforeElement(froalaEditor: FroalaEditor, targetElement: HTMLElement, html: string): void {
    targetElement.focus();
    froalaEditor.selection.setAtStart(targetElement);
    froalaEditor.html.insert(html, true);

    const currentContent = froalaEditor.html.get();
    if (!emptyLineRegex.test(currentContent.substring(0, 500))) {
      html = `${EMPTY_LINE}${currentContent}`;
    }
    froalaEditor.html.set(html);
    froalaEditor.selection.restore();
  }

  private insertAtEnd(froalaEditor: FroalaEditor, html: string): void {
    const currentContent = froalaEditor.html.get();
    if (!emptyLineRegex.test(currentContent.substring(0, 500))) {
      html = `${EMPTY_LINE}${html}`;
    }

    froalaEditor.html.set(`${currentContent}${html}`);
    froalaEditor.undo.saveStep();
  }

  insertAtEnd$({ text, shouldFocus }: { text: string; shouldFocus?: boolean }): Observable<void> {
    return this.froalaEditor$.pipe(
      take(1),
      tap(froalaEditor => {
        this.insertAtEnd(froalaEditor, text);
        if (shouldFocus) {
          this.focusFroala(froalaEditor);
        }
      }),
      map(() => undefined)
    );
  }

  setContent(content: string): void {
    this.froalaEditor$.pipe(take(1), untilDestroyed(this)).subscribe(froalaEditor => {
      froalaEditor.html.set(content);
      froalaEditor.undo.saveStep();
    });
  }

  removeById$(elementId: string): Observable<void> {
    return this.froalaEditor$.pipe(
      take(1),
      tap(froalaEditor => {
        const elementToRemove =
          froalaEditor.$el.find(`#${elementId}`)?.[0] || froalaEditor.$el.find(`.${elementId}`)?.[0];
        if (elementToRemove) {
          elementToRemove.remove();
        }
        froalaEditor.selection.restore();
        this.focusFroala(froalaEditor);
      }),
      map(() => undefined)
    );
  }

  writeValue(value: string): void {
    this.froalaEditor$.pipe(take(1)).subscribe(froalaEditor => {
      froalaEditor.html.set(value);
    });
    // setTimeout(() => {
    //   super.writeValue(value);
    // });
  }

  private focusFroala(froalaEditor: FroalaEditor, callbackFn?: () => void): void {
    froalaEditor.events.focus();
    let elementFocused = false;

    // we need to time out focus handlers as .focus can break selection...
    setTimeout(() => {
      if (this.focusBeforeElementIds.length) {
        for (const elementId of this.focusBeforeElementIds) {
          const focusElement = froalaEditor.$el.find(`#${elementId}`)[0] ?? froalaEditor.$el.find(`.${elementId}`)[0];

          if (focusElement) {
            froalaEditor.selection.setBefore(focusElement);
            froalaEditor.selection.restore();
            elementFocused = true;
            break;
          }
        }
      }

      if (!elementFocused) {
        // WE need to timeout foucs handlers
        froalaEditor.selection.setAtEnd(froalaEditor.$el.get(0));
        froalaEditor.selection.restore();
      }

      callbackFn?.();
    });
  }

  protected focusHandler(): void {
    this.froalaEditor$.pipe(take(1), untilDestroyed(this)).subscribe(froalaEditor => {
      this.focusFroala(froalaEditor);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.froalaEditor$.complete();
  }
}
