import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy } from '@angular/core';
import { ContactAvatarsApiCacheService } from '@shared/modules/files/shell/contact-avatars-api-cache/contact-avatars-api-cache.service';
import { UserApiService } from '@shared/api/api-loop/services/user-api.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { GroupApiService } from '@shared/api/api-loop/services';
import { isGroupContact } from '@shared/modules/contacts/common/helpers/is-group-contact';
import { ContactBaseModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'loop-contact-avatar',
  templateUrl: './contact-avatar.component.html',
  styleUrls: ['./contact-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactAvatarComponent implements OnDestroy {
  private readonly userApiService: UserApiService = inject(UserApiService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly contactAvatarsApiCacheService: ContactAvatarsApiCacheService = inject(ContactAvatarsApiCacheService);
  private readonly groupApiService: GroupApiService = inject(GroupApiService);

  private readonly contact$: ReplaySubject<ContactBaseModel> = new ReplaySubject(1);
  @Input() set contact(contact: ContactBaseModel) {
    this.contact$.next(contact);
  }

  protected readonly contactAvatarLink$: Observable<string | null> = this.contact$.pipe(
    switchMap(contact => {
      const obs$ = isGroupContact(contact)
        ? this.groupApiService.Group_GetUserAvatarSignedLink(
            {
              groupId: contact.id
            },
            this.userManagerService.getCurrentUserEmail()
          )
        : this.userApiService.User_GetUserAvatarSignedLink(
            {
              userId: contact.id
            },
            this.userManagerService.getCurrentUserEmail()
          );

      return this.contactAvatarsApiCacheService.getOrStoreObservable(
        contact.id,
        obs$.pipe(
          catchError(() => {
            return of(null);
          })
        )
      );
    })
  );

  ngOnDestroy(): void {
    this.contact$.complete();
  }
}
