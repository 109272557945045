<div class="emoji-picker" [class]="extraClass">
  <ng-template [ngIf]="emojiSelected" [ngIfElse]="emoji">
    <div (click)="onEmojiIconClick($event)" [class]="extraIconClass">{{ emojiSelected }}</div>
  </ng-template>
  <ng-template #emoji>
    <loop-icon
      class="mention"
      [class]="extraClass"
      [name]="emojiIcon"
      [width]="width"
      [height]="height"
      (click)="onEmojiIconClick($event)"
    ></loop-icon>
  </ng-template>
</div>
