import { Injectable } from '@angular/core';
import { FileModel } from 'dta/shared/models-api-loop/file.model';
import { Observable, of } from 'rxjs';
import { ThumbnailPage } from '@shared/api/api-loop/models/thumbnail-page';
import { map } from 'rxjs/operators';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { BaseExDecorateService } from '../base-ex-decorate.service';
import { isWebApp } from '../../../../dta/shared/utils/common-utils';

@Injectable()
export class FileExDecorateService extends BaseExDecorateService<FileModel> {
  private readonly imageExtensions: string[] = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
  private readonly videoExtensions: string[] = ['quicktime', 'mov', 'mpg', 'mp4', 'ogv', 'webm'];

  constructor(private _fileStorageService: FileStorageService) {
    super();
  }

  decorateExtraDataFromLocalFile(file: FileModel, localFile): Observable<FileModel> {
    if (file._ex) {
      return of(file);
    }

    let extension = this.getExtensionFromName(file);

    file._ex = {
      extension: extension,
      originalPath: localFile.path,
      path: this.getFilePath(file),
      isSignature: false,
      isImage: this.isImage(extension),
      isVideo: this.isVideo(extension),
      isPdf: this.isPdf(extension, file),
      syncStatus: {
        attempt: 0,
        failed: false,
        meta: false,
        binary: false,
        thumbnail: false,
        preview: false,
      },
    };

    return this.setFileThumbnailInfoPage(file, localFile);
  }

  redecorateFilePath(file: FileModel): Observable<FileModel> {
    if (!file._ex) {
      return of(file);
    }

    file._ex.path = this.getFilePath(file);

    return of(file);
  }

  decorateExtraData(forUserId: string, file: FileModel, force?: boolean): Observable<FileModel> {
    if (file._ex && !force) {
      return of(file);
    }

    let extension = this.getExtensionFromName(file);

    file._ex = {
      extension: extension,
      path: this.getFilePath(file),
      isSignature: false,
      isImage: this.isImage(extension),
      isVideo: this.isVideo(extension),
      isPdf: this.isPdf(extension, file),
      syncStatus: {
        attempt: 0,
        failed: false,
        meta: false,
        binary: false,
        thumbnail: false,
        preview: false,
      },
    };

    return of(file);
  }

  private setFileThumbnailInfoPage(file: FileModel, localFile): Observable<FileModel> {
    // thumb not available
    if (!file._ex.syncStatus.thumbnail && !file._ex.originalPath && !localFile.base64data) {
      return of(file);
    }
    // file is not an image or has valid thumbnail info
    if (!this.isImage(file._ex.extension) || file.hasValidThumbnailDimensions()) {
      return of(file);
    }

    let path =
      localFile.base64data && localFile.type
        ? 'data:' + localFile.type + ';base64,' + localFile.base64data
        : this.getThumbnailPath(file);

    return this._fileStorageService.readImageFile(path).pipe(
      map((image: HTMLImageElement) => {
        let page: ThumbnailPage = {
          height: image.height,
          width: image.width,
          pageNumber: 0,
          hasPdf: false,
        };

        file.thumbnailInfo = file.thumbnailInfo || {
          supported: true,
          failed: false,
          ready: true,
          pages: {
            resources: [page],
            offset: 0,
            size: 1,
            totalSize: 1,
          },
        };

        return file;
      }),
    );
  }

  private getFilePath(file: FileModel): string {
    if (file.urlLink) {
      return file.urlLink;
    }

    let basePath = this._fileStorageService.getFilesUri();
    let filePath = file.id ? '/' + file.hash : '/tmp/' + file._id;
    return basePath + filePath;
  }

  private getThumbnailPath(file: FileModel): string {
    if (file._ex.originalPath && this.isImage(file._ex.extension)) {
      return file._ex.originalPath;
    }

    let path = file._ex.path;

    if (file.isThumbnailSupported()) {
      return path + '_preview';
    }

    return path || file._ex.originalPath;
  }

  private getExtensionFromName(file: FileModel): string {
    if (!file.name) {
      return '';
    }

    let split = file.name.split('.');
    let extension = split.length === 1 ? '' : split.pop();

    return extension.toLowerCase();
  }

  private getExtensionFromType(localFile: File): string {
    if (!localFile.type) {
      return '';
    }

    let extension = '';

    switch (localFile.type) {
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        extension = 'xlsx';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        extension = 'docx';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        extension = 'pptx';
        break;
      default:
        let split = localFile.type.split('/');
        extension = split.length === 1 ? '' : split.pop();
    }

    return extension.toLowerCase();
  }

  private isImage(extension: string): boolean {
    return this.imageExtensions.includes(extension);
  }

  private isVideo(extension: string): boolean {
    return this.videoExtensions.includes(extension);
  }

  private isPdf(extension: string, file: FileModel): boolean {
    return extension === 'pdf' || file.isPdfPreviewSupported();
  }
}
