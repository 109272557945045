<ng-container *ngIf="conversation$ | async; let conversation">
  <ng-container *ngIf="hasSnoozeDue$ | async">
    <loop-conversation-tag
      loopCustomDropdown
      [dropdownConfiguration]="snoozeDropdownComponentConfiguration"
      [componentParams]="{ conversation: conversation }"
      [color]="'#f5a623'"
      [content]="'Snooze due'"
      [prefixTpl]="prefixTpl"
      [suffixTpl]="suffixTpl"
    >
    </loop-conversation-tag>

    <ng-template #prefixTpl>
      <svg-comp [style.color]="'#f5a623'" [src]="snoozeSvg"> </svg-comp>
    </ng-template>
    <ng-template #suffixTpl>
      <loop-icon name="xmark" [style.color]="'#f5a623'" width="14px" height="14px" (click)="discardSnooze($event)">
      </loop-icon>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="snoozeDueDate$ | async; let snoozeDueDate">
    <loop-conversation-tag
      loopCustomDropdown
      [dropdownConfiguration]="snoozeDropdownComponentConfiguration"
      [componentParams]="{ conversation: conversation }"
      [prefixTpl]="prefixTpl"
      [content]="snoozeDueDate"
      [color]="'#f5a623'"
    >
    </loop-conversation-tag>
    <ng-template #prefixTpl>
      <svg-comp [src]="snoozeSvg" [style.color]="'#f5a623'"></svg-comp>
    </ng-template>
  </ng-container>
</ng-container>
