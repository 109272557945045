import { Injector, Type } from '@angular/core';
import { Observable, Observer, PartialObserver } from 'rxjs';
import { EnumObjectValue } from '@shared/utils/common/types';
import { LOOP_DYNAMIC_COMPONENT_NAME } from '@shared/modules/loop/components/loop-dynamic-components';
import {
  BaseDialogOptions,
  NewWindowDialogOptions,
  OverlayDialogOptions
} from '@shared/ui/kit/dialog/open-dialog-options';

export interface BaseButton {
  label$: Observable<string>;
  icon?: string;
}

export interface DefaultButton extends BaseButton {
  type: 'close' | 'submit';
}

export interface ActionButton extends BaseButton {
  type: 'action';
  action: () => void;
}

export type CustomButton = DefaultButton | ActionButton;

export interface ModuleComponentDefinition<C> {
  component: Type<C>;
  module: Type<any>;
  parentInjector?: Injector;
}

export interface StandaloneComponentDefinition<C> {
  standaloneComponent: Type<C>;
  parentInjector?: Injector;
}

export interface BaseModalFormOptions<T, R> {
  modalTitle$?: Observable<string>;
  modalWidth?: string;
  useCustomLayout?: boolean;
  afterSubmit$: (value: T) => Observable<R | never>;
  observer: (value: R) => void;
  defaults?: T;
  defaults$?: Observable<T>;
  buttons?: CustomButton[];
}

export interface OverlayModalFormOptions<T, C, R, P = any> extends BaseModalFormOptions<T, R> {
  formComponent: ModuleComponentDefinition<C> | StandaloneComponentDefinition<C>;
  formComponentParams?: Partial<C>;
  formPrependComponent?: {
    component: Type<P>;
    module: Type<any>;
    parentInjector?: Injector;
    params?: Partial<P>;
  };
}

export interface NewWindowModalFormOptions<T, C, R> extends BaseModalFormOptions<T, R> {
  componentId?: EnumObjectValue<typeof LOOP_DYNAMIC_COMPONENT_NAME>;
  windowIdentifier: string | number;
  componentParams?: Partial<C>;
}

export type ModalFormOptions<T, C, R, P = any> =
  | OverlayModalFormOptions<T, C, R, P>
  | NewWindowModalFormOptions<T, C, R>;

export function isNewWindowModalFormOptions(
  options: ModalFormOptions<any, any, any>
): options is NewWindowModalFormOptions<any, any, any> {
  return !!(options as NewWindowModalFormOptions<any, any, any>).componentId;
}

export function isOverlayModalFormOptions(
  options: ModalFormOptions<any, any, any>
): options is OverlayModalFormOptions<any, any, any> {
  return !!(options as OverlayModalFormOptions<any, any, any>).formComponent;
}

export function isStandaloneFormComponent<C>(
  formComponent: ModuleComponentDefinition<C> | StandaloneComponentDefinition<C>
): formComponent is StandaloneComponentDefinition<C> {
  return !!(formComponent as StandaloneComponentDefinition<C>)?.standaloneComponent;
}
