import { Pipe, PipeTransform } from '@angular/core';

const units = ['B', 'KB', 'MB'];

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  transform(bytes: number = 0): string {
    if (!bytes) {
      return '';
    }

    let unit = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    return Math.floor(bytes * 10) / 10 + ' ' + units[unit];
  }
}
