import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactAvatarComponent } from '@shared/modules/contacts/components/contact-avatar/contact-avatar.component';
import { ContactAvatarsApiCacheModule } from '@shared/modules/files/shell/contact-avatars-api-cache/contact-avatars-api-cache.module';

@NgModule({
  declarations: [ContactAvatarComponent],
  imports: [CommonModule, ContactAvatarsApiCacheModule],
  exports: [ContactAvatarComponent]
})
export class ContactAvatarModule {}
