import * as _ from 'lodash';
import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  SubscriptionLicense,
  SubscriptionMember,
  SubscriptionMemberRole,
  SubscriptionMemberType,
  SubscriptionStatusType,
  User
} from '@shared/api/api-loop/models';
import {
  ConfirmationPopupConfirmData,
  ConfirmationPopupData,
  ConfirmationPopupDataType,
  UserRemove,
  UserSet,
  UserSwitch,
  UserSwitchReason,
  UserTokenInvalid,
  UserUpdate
} from '../communication/shared-subjects/shared-subjects-models';
import { LoginUserModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { LogLevel, LogTag } from '@dta/shared/models/logger.model';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { Logger } from '@shared/services/logger/logger';
import { filter, startWith, tap } from 'rxjs/operators';
import { AccountActionType } from '@dta/shared/services/tracking/tracking.constants';
import { SharedSubjects } from '../communication/shared-subjects/shared-subjects';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { NavigationEnd, Router } from '@angular/router';
import { AutoUnsubscribe } from '@dta/shared/utils/subscriptions/auto-unsubscribe';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { ElectronService } from '@shared/services/electron/electron';
import { UserPaymentSettings } from '../../api/api-loop/models/user-payment-settings';

export interface UserManagerServiceI {
  switchUser(user: User, reason: UserSwitchReason): void;
  switchUserByIndex(index: number): void;
  setNewUserOrder(orderedEmails: string[], debugInfo: string): void;

  isUserAccountVerified(userEmail: string): boolean;
  isUserAccountSyncable(userEmail: string): boolean;

  getUsers(): LoginUserModel[];
  getCurrentUser(): UserModel;
  getCurrentUserId(): string;
  getCurrentUserLogin(): LoginUserModel;
  getCurrentUserEmail(): string;
  getUserLoginByEmail(userEmail: string): LoginUserModel;
  isCurrentUserAccountVerified(): boolean;
  isCurrentUserAccountSyncable(): boolean;
  isCurrentUserInOneTrialWorkspaceAndAdmin(): boolean;
  getCurrentUserToken(): string;
  getTokenByEmail(userEmail: string): string;
  rerouteToLast(reason: UserSwitchReason);
  switchAndReroute(userEmail: string, reason: UserSwitchReason);

  updateLoggerInMainProcessUser();

  get userSwitch$(): Observable<UserSwitch>;
  get userRemove$(): Observable<UserRemove>;
  get userUpdate$(): Observable<UserUpdate>;
  get userSet$(): Observable<UserSet>;
}

@Directive()
@AutoUnsubscribe()
export abstract class UserManagerService extends SharedUserManagerService implements UserManagerServiceI, OnDestroy {
  //////////
  // Cache
  //////////
  protected _currentUserEmail: string;
  protected _currentUserLogin: LoginUserModel;
  protected static _users: LoginUserModel[] = [];

  //////////////////
  // Subscriptions
  //////////////////
  private _routerSub: Subscription;
  private _userUpdatedSub: Subscription;
  private _userSetSub: Subscription;
  private _userChildRemovedSub: Subscription;
  private _accountRemovedByPopupSub: Subscription;

  constructor(
    protected _storageService: StorageService,
    protected _trackingService: TrackingService,
    protected _electronService: ElectronService,
    protected _router: Router
  ) {
    super(_storageService, _electronService);
    this.init();
  }

  get constructorName(): string {
    return 'UserManagerService';
  }

  ngOnDestroy(): void {}

  abstract switchUserByIndex(index: number): void;
  abstract updateLoggerInMainProcessUser(): void;
  protected resetFavorites() {}

  init() {
    this.subscribeToUserUpdated();
    this.subscribeToUserSet();
    this.subscribeToChildUserRemoved();
    this.subscribeToAccountRemovedByPopup();
  }

  get userSwitch$(): Observable<UserSwitch> {
    let initialData = new UserSwitch();
    initialData.reason = UserSwitchReason.automatic;
    initialData.newUserEmail = this.getCurrentUserEmail();

    let oldUserEmail = this.getCurrentUserEmail();
    let first = true;

    return SharedSubjects._userSwitch$.pipe(
      filter((_switch: UserSwitch) => {
        let isDifferentUser = this._currentUserEmail !== oldUserEmail;
        oldUserEmail = this._currentUserEmail;

        return isDifferentUser;
      }),
      startWith(initialData),
      tap((_switch: UserSwitch) => {
        // Log every switch but the first one
        if (!first) {
          this._trackingService.trackAccountAction(_switch.newUserEmail, AccountActionType.SWITCH, _switch.reason);
        }

        first = false;
      })
    );
  }

  ///////////////////////////////////
  // Get users or current user data
  ///////////////////////////////////
  getCurrentUser(): UserModel {
    let currentUserLogin = this.getCurrentUserLogin();
    return currentUserLogin ? currentUserLogin.toUserModel() : undefined;
  }

  getCurrentUserId(): string {
    let user = this.getCurrentUser();
    return user ? user.id : undefined;
  }

  getCurrentUserEmail(): string {
    let returnEmpty = this._storageService.getItem(StorageKey.getUserForCodeExchange);

    if (returnEmpty) {
      Logger.customLog(
        StorageKey.getUserForCodeExchange + ' set in local storage. This might be wrong. Let developer know.',
        LogLevel.WARN,
        LogTag.AUTH_AND_LOGIN
      );
      return undefined;
    }

    if (!this._currentUserEmail) {
      this._currentUserEmail = this._storageService.getItem(StorageKey.lastUser);
      if (!this._currentUserEmail) {
        Logger.customLog(
          'last_user not set. Is key in storage: ' + this._storageService.isKeyInStorage(StorageKey.lastUser),
          LogLevel.ERROR
        );
      }
    }

    return this._currentUserEmail;
  }

  getCurrentUserLogin(): LoginUserModel {
    if (!this._currentUserLogin) {
      this._currentUserLogin = this.getUserLoginByEmail(this.getCurrentUserEmail());
    }

    return this._currentUserLogin;
  }

  isCurrentUserAccountVerified(): boolean {
    return this.isUserAccountVerified(this._currentUserEmail);
  }

  isCurrentUserAccountSyncable(): boolean {
    return this.isUserAccountSyncable(this._currentUserEmail);
  }

  getCurrentUserToken(): string {
    return this.getTokenByEmail(this.getCurrentUserEmail());
  }

  getCurrentUserPaymentSettings(): UserPaymentSettings {
    let key = this._storageService.getKey(this._currentUserEmail, StorageKey.userPaymentSettings);

    return this._storageService.getParsedItem(key);
  }

  isCurrentUserWorkspaceAdmin(): boolean {
    if (!this._currentUserLogin) {
      return false;
    }

    let key = this._storageService.getKey(this._currentUserEmail, StorageKey.userTopPriorityLicense);

    let license: SubscriptionLicense = this._storageService.getParsedItem(key);

    if (!license) {
      return false;
    }

    return license?.members?.resources?.some(
      (r: SubscriptionMember) =>
        r.user.id === this._currentUserLogin.id &&
        [SubscriptionMemberRole.ADMIN, SubscriptionMemberRole.OWNER].includes(r.role)
    );
  }

  isCurrentUserInOneTrialWorkspaceAndAdmin(): boolean {
    if (!this._currentUserLogin) {
      return false;
    }

    let key = this._storageService.getKey(this._currentUserEmail, StorageKey.userTopPriorityLicense);

    let license: SubscriptionLicense = this._storageService.getParsedItem(key);

    if (!license) {
      return true;
    }

    if (license.subscriptionStatus !== SubscriptionStatusType.TRIAL) {
      return false;
    }

    return license?.members?.resources?.some(
      (r: SubscriptionMember) =>
        r.user.id === this._currentUserLogin.id &&
        [SubscriptionMemberRole.ADMIN, SubscriptionMemberRole.OWNER].includes(r.role)
    );
  }

  getMemberIdsOfUserLicenses(): string[] {
    let key = this._storageService.getKey(this._currentUserEmail, StorageKey.userTopPriorityLicense);

    let license: SubscriptionLicense = this._storageService.getParsedItem(key);

    if (!license) {
      return [];
    }

    return _.uniq(
      _.reduce(
        license.members.resources,
        (contactIds, licence) => {
          if (licence.subscriptionMemberType === SubscriptionMemberType.USER) {
            contactIds.push(licence.user.id);
          }
          return contactIds;
        },
        []
      )
    );
  }

  shouldFetchTopPriorityLicense(): boolean {
    let key = this._storageService.getKey(this._currentUserEmail, StorageKey.userTopPriorityLicense);

    let license: SubscriptionLicense = this._storageService.getParsedItem(key);

    return !license;
  }

  getWorkspaceId(): string | undefined {
    let key = this._storageService.getKey(this._currentUserEmail, StorageKey.userTopPriorityLicense);

    let license: SubscriptionLicense = this._storageService.getParsedItem(key);

    return license.id;
  }

  ////////////
  // Routing
  ////////////
  /**
   * Switch user
   * - set currentUser
   * - emit event on userSwitch
   */
  switchUser(user: User, reason = UserSwitchReason.manual) {
    if (_.isEmpty(user) || !user.email) {
      throw new Error('Account or userEmail cannot be nil');
    }

    this.setCurrentUserEmail(user.email);
    this.switchAndReroute(user.email, reason);
  }

  protected setCurrentUserEmail(userEmail: string) {
    this._storageService.setItem(StorageKey.lastUser, userEmail);
    this._currentUserEmail = userEmail;
    this._currentUserLogin = this.getUserLoginByEmail(userEmail);
    Logger.setUserEmail(this._currentUserEmail);

    // Send to main process
    this.updateLoggerInMainProcessUser();
  }

  // Reroute to last route and switch to last user
  rerouteToLast(reason = UserSwitchReason.manual) {
    // Reroute only on default route, prevent rerouting on deepLinks
    if (!['/myloopinbox', '/'].includes(this._router.url)) {
      return;
    }
    if (this.rerouteToGettingStarted()) {
      return;
    }

    // Get last user
    let currentUser = this.getCurrentUserEmail();
    let lastUser = this._storageService.getItem(StorageKey.lastUser);

    if (!_.isEmpty(lastUser) && currentUser !== lastUser) {
      this.switchAndReroute(lastUser, reason);
    }
  }

  private rerouteToGettingStarted(): boolean {
    if (this.isCurrentUserInOneTrialWorkspaceAndAdmin()) {
      this._router.navigate(['/getting-started']);
      return true;
    }

    return false;
  }

  // Switch to user and reroute to last route of user
  switchAndReroute(userEmail: string, reason = UserSwitchReason.manual) {
    // Switch to last user
    let userSwitch = new UserSwitch();
    userSwitch.reason = reason;
    userSwitch.newUserEmail = userEmail;

    SharedSubjects._userSwitch$.next(userSwitch);

    // Get last user route
    let lastUserRoute: string = this.getLastRouteByEmail(userEmail);

    if (_.isEmpty(lastUserRoute)) {
      this._router.navigate(['/myloopinbox'], { queryParams: { t: new Date().getTime() } });
    } else {
      // we want to avoid user coming back to app or switching accounts and arriving to see the card on the
      // the right and not seeing it in the middle column FOURTH-2669. Because of that we reroute to main route.
      if (lastUserRoute.startsWith('/myloopinbox')) {
        lastUserRoute = '/myloopinbox';
      }
      if (lastUserRoute.startsWith('/search')) {
        lastUserRoute = '';
      }
      lastUserRoute += `?t=${new Date().getTime()}`;
      this._router.navigateByUrl(lastUserRoute);
    }
  }

  protected getLastRouteByEmail(userEmail: string): string {
    if (!userEmail) {
      return '';
    }

    let key = this._storageService.getKey(userEmail, StorageKey.lastRoute);
    return this._storageService.getItem(key) || '';
  }

  startRouteListener() {
    this._routerSub?.unsubscribe();
    this._routerSub = this._router.events
      .pipe(
        filter(event => {
          return event instanceof NavigationEnd;
        }),
        tap(event => {
          this.setLastAndPreviousRoute(this._router.url);
        })
      )
      .subscribe();
  }

  getUserRegisteredDaysAgo(userEmail: string): number {
    let userLogin = this.getUserLoginByEmail(userEmail);

    if (!userLogin) {
      Logger.customLog(
        `getUserRegisteredDaysAgo() called with ${userEmail} but userLogin is undefined`,
        LogLevel.WARN,
        [LogTag.AUTH_AND_LOGIN, LogTag.INTERESTING_ERROR]
      );
      return 0;
    }

    try {
      // Get dates (now and time user was registered)
      let now = new Date().getTime();
      let registered = new Date(userLogin.userSettings.registeredDate).getTime();

      // Subtract them and convert from ms to days
      return Math.round((now - registered) / (24 * 60 * 60 * 1000));
    } catch (err) {
      Logger.error(
        err,
        `error in getUserRegisteredDaysAgo() called with ${userEmail}`,
        [LogTag.AUTH_AND_LOGIN, LogTag.INTERESTING_ERROR],
        true,
        'error in getUserRegisteredDaysAgo()'
      );

      return 0;
    }
  }

  ensureCurrentUser(desiredCurrentUser: string) {
    if (this.getCurrentUserEmail() !== desiredCurrentUser) {
      let user = this.getUserByEmail(desiredCurrentUser);
      this.switchUser(user, UserSwitchReason.automatic);
    }
  }

  // Set last and previous route for current user
  protected setLastAndPreviousRoute(route: string) {
    let currentUserEmail = this.getCurrentUserEmail();
    if (!currentUserEmail) {
      return;
    }

    // If url is different than last route, store last route as previous route for current user
    let lastUserRoute: string = this.getLastRouteByEmail(currentUserEmail);
    if (!_.isEmpty(lastUserRoute) && lastUserRoute !== route) {
      this.setPreviousRouteByEmail(currentUserEmail, lastUserRoute);
    }

    // Set url as last route for current user
    this.setLastRouteByEmail(currentUserEmail, route);
  }

  private setLastRouteByEmail(userEmail: string, lastRoute?: string) {
    if (!userEmail || lastRoute.includes('/deeplink')) {
      return;
    }

    let key = this._storageService.getKey(userEmail, StorageKey.lastRoute);
    return this._storageService.setItem(key, lastRoute);
  }

  private setPreviousRouteByEmail(userEmail: string, lastRoute: string) {
    if (!userEmail) {
      return;
    }

    let key = this._storageService.getKey(userEmail, StorageKey.previousRoute);
    return this._storageService.setItem(key, lastRoute);
  }

  // Should be public to be called/overridden from child
  private handleUserRemovedEvent(removeEvent: UserRemove) {
    let userEmail = removeEvent.userEmail;

    // Log event
    Logger.customLog(this.constructorName + '.handleUserRemovedEvent() called with ' + userEmail, LogLevel.ERROR);

    // Switch to next user or redirect to login
    if (this.getUsers().length) {
      let currentUserEmail = this.getCurrentUserEmail();

      // check if this is the current active user, switch to next available user
      if (currentUserEmail === userEmail) {
        let nextUser = this.getUsers()[0];
        let nextUserLogin = this.getUserLoginByEmail(nextUser.email);

        this._currentUserLogin = nextUserLogin;
        this.switchUser(nextUserLogin, UserSwitchReason.removeAccount);
      }
    } else {
      this.resetFavorites();

      // If no users are left, redirect to login
      this.setCurrentUserEmail(undefined);
      this._router.navigate(['/login']);
    }
  }

  /////////////////
  // Subscriptions
  /////////////////
  private subscribeToUserUpdated() {
    this._userUpdatedSub?.unsubscribe();
    this._userUpdatedSub = this.userUpdate$
      .pipe(
        tap((updateEvent: UserUpdate) => {
          // Update if current user
          if (this._currentUserLogin && updateEvent.updatedUser.id === this._currentUserLogin.id) {
            this._currentUserLogin = Object.assign(new LoginUserModel(), updateEvent.updatedUser);
          }
        })
      )
      .subscribe();
  }

  private subscribeToUserSet() {
    this._userSetSub?.unsubscribe();
    this._userSetSub = this.userSet$
      .pipe(
        tap((userSet: UserSet) => {
          this.setCurrentUserEmail(userSet.userEmail);
        })
      )
      .subscribe();
  }

  private subscribeToChildUserRemoved() {
    this._userChildRemovedSub?.unsubscribe();
    this._userChildRemovedSub = this.userRemove$
      .pipe(
        tap((removeEvent: UserRemove) => {
          this.handleUserRemovedEvent(removeEvent);
        })
      )
      .subscribe();
  }

  private subscribeToAccountRemovedByPopup() {
    this._accountRemovedByPopupSub?.unsubscribe();
    this._accountRemovedByPopupSub = SharedSubjects._confirmationPopup$
      .pipe(
        filter((data: ConfirmationPopupData) => data.type === ConfirmationPopupDataType.Confirm),
        tap((data: ConfirmationPopupConfirmData) => {
          Logger.customLog(`User response for auth error popup: remove account = ${data.confirmed}`, LogLevel.WARN);
          if (data.confirmed) {
            // Remove account
            this.removeAccountByEmail(data.additionalData, 'UserManagerService.subscribeToAccountRemovedByPopup');

            // Trigger relogin navigation
            let openLoginData = new UserTokenInvalid();
            openLoginData.userEmail = data.additionalData;
            openLoginData.showDialog = true;

            // Don't broadcast if on UI. UI has handler
            SharedSubjects._userTokenInvalid$.next(openLoginData, false);
          }
        })
      )
      .subscribe();
  }
}
