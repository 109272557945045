/* tslint:disable */

/**
 * Possible Draft Types
 */
export type DraftType = 'Private' | 'Shared';

export module DraftType {
  export const PRIVATE: DraftType = 'Private';
  export const SHARED: DraftType = 'Shared';

  export function values(): DraftType[] {
    return [PRIVATE, SHARED];
  }
}
