export function getTextBeforeCaret(): string {
  const selection = window.getSelection();
  if (selection.rangeCount <= 0) {
    return '';
  }

  const range = window.getSelection().getRangeAt(0);
  if (range.collapsed) {
    const text = range.startContainer.textContent.substring(0, range.startOffset);
    return text.split(/(\s+)/).pop();
  }
  return '';
}

export function getNodeBeforeCaret(): Node {
  return window.getSelection().focusNode;
}
