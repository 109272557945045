<div
  *ngIf="show"
  class="tooltip-wrapper {{tooltip.extraClass}} {{tooltip.position}}"
  [class.has-title-content]="tooltip.titleContent"
  [class.medium-content]="tooltip.titleContent && tooltip.content.length > 70"
  [class.long-content]="tooltip.titleContent && tooltip.content.length > 120"
  [style.top]="tooltip.top"
  [style.right]="tooltip.right"
  [style.left]="tooltip.left"
  [@fadeInOut]="animationState"
>
  <div
    class="tooltip-content-wrapper"
    [class.top-bottom]="tooltip.onTop || tooltip.onBottom"
    [class.shift-left]="tooltip.shiftLeft"
    [class.shift-right]="tooltip.shiftRight"
  >
    <!-- Extra content -->
    <div *ngIf="tooltip.titleContent" class="title-content">{{tooltip.titleContent}}</div>

    <!-- Content -->
    <span class="tooltip-content" *ngIf="!tooltip.time">
      {{tooltip.content}}

      <!-- Content suffix -->
      <span *ngIf="tooltip.contentSuffix" class="tooltip-content-suffix"> {{tooltip.contentSuffix}} </span>
    </span>

    <!-- Time -->
    <time-ago *ngIf="tooltip.time" [extraClass]="'tooltip'" [extra]="true" [time]="tooltip.content"></time-ago>

    <!-- Triangle -->
    <div class="tooltip-triangle"></div>
  </div>
</div>
