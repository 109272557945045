import { Injectable } from '@angular/core';
import { UserManagerService } from '../user-manager/user-manager.service';
import { StorageKey, StorageService } from 'dta/shared/services/storage/storage.service';
import { SettingsService } from '@shared/services/settings/settings.service';
import { ThreadingEnum } from '@shared/api/api-loop/models';

@Injectable()
export class UserPreferencesService {
  constructor(
    private _storageService: StorageService,
    private _userManagerService: UserManagerService,
    private _settingsService: SettingsService
  ) {}

  get isPrivateTriagingEnabled(): boolean {
    let userEmail = this._userManagerService.getCurrentUserEmail();
    return this._settingsService.getPrivateTriageSetting(userEmail);
  }

  get isShowSentEnabled(): boolean {
    let userEmail = this._userManagerService.getCurrentUserEmail();
    return this._settingsService.getShowSentSetting(userEmail);
  }

  getCurrentUserThreadingMode(): ThreadingEnum {
    return this._userManagerService.getCurrentUserLogin().syncSettings?.threadingMode;
  }

  // Returns app preference for the key or undefined if preference is not set yet
  getAppPreference(key: string): any {
    let preferences = this._storageService.getParsedItem(StorageKey.appPreferences);
    return preferences ? preferences[key] : undefined;
  }

  // Sets value to the key in app preferences
  setAppPreference(key: string, value: any) {
    let preferences = this._storageService.getParsedItem(StorageKey.appPreferences) || {};

    preferences[key] = value;
    this._storageService.setStringifiedItem(StorageKey.appPreferences, preferences);
  }

  // Returns current user preference for the key or undefined if preference is not set yet
  getCurrentUserPreference(key: string): any {
    let userEmail = this._userManagerService.getCurrentUserEmail();
    let storageKey = this._storageService.getKey(userEmail, StorageKey.preferences);
    let preferences = this._storageService.getParsedItem(storageKey);
    return preferences ? preferences[key] : undefined;
  }

  // Sets value to the key in preferences
  setCurrentUserPreference(key: string, value: any) {
    let userEmail = this._userManagerService.getCurrentUserEmail();
    let storageKey = this._storageService.getKey(userEmail, StorageKey.preferences);
    let preferences = this._storageService.getParsedItem(storageKey) || {};

    preferences[key] = value;
    this._storageService.setStringifiedItem(storageKey, preferences);
  }
}
