import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewEncapsulation } from '@angular/core';
import { TooltipColor, TooltipContent } from '@shared/ui/tooltip/browser/browser-tooltip-initializer';

@Component({
  selector: 'loop-browser-tooltip-renderer',
  templateUrl: './browser-tooltip-renderer.component.html',
  styleUrls: ['./browser-tooltip-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BrowserTooltipRendererComponent {
  readonly elementRef: ElementRef = inject(ElementRef);
  content?: TooltipContent;
  color?: TooltipColor;

  setContent(content: TooltipContent): void {
    this.content = content;
  }

  setBackgroundColor(color: 'dark' | 'light'): void {
    this.color = color;
  }
}
