import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DROPDOWN_INITIALIZERS } from '@shared/ui/dropdown/injection-tokens/dropdown-initializers';
import { BrowserDropdownInitializers } from '@shared/ui/dropdown/browser/browser-dropdown-initializers';
import { BrowserDropdownModule } from '@shared/ui/dropdown/browser/browser-dropdown.module';
import { TOOLTIP_INITIALIZERS } from '@shared/ui/tooltip/injection-tokens/tooltip-initializers';
import { BrowserTooltipInitializer } from '@shared/ui/tooltip/browser/browser-tooltip-initializer';
import { BrowserTooltipModule } from '@shared/ui/tooltip/browser/browser-tooltip.module';
import { BrowserDialogModule } from '@shared/ui/kit/dialog/browser/browser-dialog/browser-dialog.module';
import { DialogService } from '@shared/ui/kit/dialog/dialog.service';
import { BrowserDialogService } from '@shared/ui/kit/dialog/browser/browser-dialog/browser-dialog.service';

@NgModule({
  imports: [CommonModule, BrowserDropdownModule, BrowserTooltipModule, BrowserDialogModule]
})
export class BrowserComponentModule {
  static forRoot(): ModuleWithProviders<BrowserComponentModule> {
    return {
      ngModule: BrowserComponentModule,
      providers: [
        {
          provide: DROPDOWN_INITIALIZERS,
          useValue: BrowserDropdownInitializers
        },
        {
          provide: TOOLTIP_INITIALIZERS,
          useValue: BrowserTooltipInitializer
        },
        {
          provide: DialogService,
          useClass: BrowserDialogService
        }
      ]
    };
  }
}
