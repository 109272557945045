import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserTooltipRendererComponent } from './tooltip-renderer/browser-tooltip-renderer.component';
import { StringTemplateOutletModule } from '@shared/ui/kit/string-template-outlet/string-template-outlet.module';
import { IsTemplateRefPipe } from './tooltip-renderer/internal/is-template-ref.pipe';

@NgModule({
  declarations: [BrowserTooltipRendererComponent, IsTemplateRefPipe],
  imports: [CommonModule, StringTemplateOutletModule],
})
export class BrowserTooltipModule {}
