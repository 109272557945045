import { inject, Injectable } from '@angular/core';
import { SharedTagDataAccessModule } from '@shared/modules/shared-tag/data-access/shared-tag-data-access/shared-tag-data-access.module';
import { SharedTagApiService } from '@shared/api/api-loop/services/shared-tag-api.service';
import { StorageProviderService } from '@shared/cache/storage-provider/storage-provider';
import { SharedTagClassificationModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { defer, Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { LRUCache } from 'lru-cache';

export const CLASSIFICATION_SHARED_TAG_CACHE_NAME: string = 'classificationSharedTagCache' as const;

@Injectable({ providedIn: SharedTagDataAccessModule })
export class SmartClassificationSharedTagApi {
  private readonly sharedTagApiService: SharedTagApiService = inject(SharedTagApiService);
  private readonly storageProviderService: StorageProviderService = inject(StorageProviderService);

  private readonly classificationSharedTagStorage: LRUCache<string, SharedTagClassificationModel[]> =
    this.storageProviderService.provideStorage<string, SharedTagClassificationModel[]>(
      CLASSIFICATION_SHARED_TAG_CACHE_NAME,
      1024,
      1000 * 60 * 5
    );

  private ongoingGetAllRequest$: { [forUserEmail: string]: Observable<SharedTagClassificationModel[]> | null } = {};

  getAll$(forUserEmail: string): Observable<SharedTagClassificationModel[]> {
    const getAll$ = this.sharedTagApiService.SharedTag_GetWorkspacesClassification({}, forUserEmail, true).pipe(
      map(response => (response.resources ?? []).map(list => list.resources ?? []).flat()),
      map(SharedTagClassificationModel.createList),
      tap(classificationModels => {
        this.classificationSharedTagStorage.set(forUserEmail, classificationModels);
      }),
      finalize(() => (this.ongoingGetAllRequest$[forUserEmail] = null))
    );

    this.ongoingGetAllRequest$[forUserEmail] = getAll$;

    return getAll$;
  }

  getSingle$(classificationId: string, forUserEmail: string): Observable<SharedTagClassificationModel | undefined> {
    const getClassification$ = defer(() => {
      return (this.ongoingGetAllRequest$?.[forUserEmail] ?? this.getAll$(forUserEmail)).pipe(
        map(() => {
          return this.classificationSharedTagStorage.get(forUserEmail).find(tag => tag.id === classificationId);
        })
      );
    });

    const classificationTag = this.classificationSharedTagStorage
      .get(forUserEmail)
      ?.find(tag => tag.id === classificationId);
    if (!classificationTag) {
      return getClassification$;
    }

    return of(classificationTag);
  }
}
