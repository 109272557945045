import { Injectable } from '@angular/core';
import { BaseService } from '@shared/services/data/base/base.service';
import { LibraryServiceI } from '@shared/services/data/library/library.service.interface';
import { EMPTY, forkJoin, Observable, of, switchMap } from 'rxjs';
import { LibrarySearchResults, SearchResultType } from '@dta/shared/models/library.model';
import { FileApiService } from '@shared/api/api-loop/services/file-api.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { Logger } from '@shared/services/logger/logger';
import { FileExDecorateService } from '@shared/decorators/extra-data-decorators/file-ex-decorator/file-ex-decorate.service';
import { FileService } from '@shared/services/data/file/file.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { FileSynchronizationService } from '@shared/services/data/file-synchronization/file-synchronization.service';

@Injectable()
export class LibraryService extends BaseService implements LibraryServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _fileApiService: FileApiService,
    protected _fileExDecorateService: FileExDecorateService,
    protected _fileService: FileService,
    protected _fileSyncService: FileSynchronizationService,
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'LibraryService';
  }

  findFilesByQuery(
    forUserEmail: string,
    searchQuery: string,
    offset: number,
    size: number,
    apiOnly?: boolean,
  ): Observable<[LibrarySearchResults, LibrarySearchResults]> {
    if (apiOnly) {
      return forkJoin(
        of({
          $type: SearchResultType.Offline,
          result: [],
          length: 0,
        }),
        this.findFilesFromApi(forUserEmail, searchQuery, offset, size),
      );
    }

    return forkJoin([
      this.findFilesLocally(forUserEmail, searchQuery, offset, size),
      this.findFilesFromApi(forUserEmail, searchQuery, offset, size),
    ]);
  }

  private findFilesLocally(
    forUserEmail: string,
    searchQuery: string,
    offset: number,
    size: number,
  ): Observable<LibrarySearchResults> {
    return this._fileService.findFilesBySearchQuery(forUserEmail, searchQuery, offset, size).pipe(
      switchMap(result => {
        return this._fileSyncService.synchronizeFiles(forUserEmail, result.result).pipe(
          map(syncedFiles => {
            return {
              ...result,
              result: syncedFiles,
            };
          }),
        );
      }),
    );
  }

  protected findFilesFromApi(
    forUserEmail: string,
    searchQuery: string,
    offset: number,
    size: number,
  ): Observable<LibrarySearchResults> {
    let apiParams: FileApiService.File_GetListParams = {
      size: size,
      offset: offset,
      searchQuery: searchQuery,
    };

    return this._fileApiService.File_GetList(apiParams, forUserEmail).pipe(
      map(files => {
        return files.resources.map(file => new FileModel(file));
      }),
      mergeMap((fileModels: FileModel[]) => {
        return this._fileExDecorateService.decorateListExtraData(forUserEmail, fileModels);
      }),
      switchMap(files => this._fileSyncService.synchronizeFiles(forUserEmail, files)),
      map((decoratedFileModels: FileModel[]) => {
        return {
          $type: SearchResultType.Online,
          result: decoratedFileModels,
          length: decoratedFileModels.length,
        } as LibrarySearchResults;
      }),
      catchError(err => {
        Logger.error(err, 'Could not fetch library files');
        return <Observable<LibrarySearchResults>>EMPTY;
      }),
    );
  }
}
