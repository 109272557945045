export enum SubscriptionStatusUpdate {
  INBOX_AND_CHAT = 'Inbox and Chat',
  INBOX = 'Inbox',
  CHAT = 'Chat',
  UNSUBSCRIBE = 'Unsubscribe',
}

export enum FileNameConstants {
  PREVIEW_PDF = '_preview_pdf',
  PREVIEW = '_preview',
  SIGNATURE = '_signature',
  LARGE = '_large',
}
