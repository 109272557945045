import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsPureArgumentForPipe } from './as-pure-argument-for.pipe';

@NgModule({
  declarations: [AsPureArgumentForPipe],
  exports: [AsPureArgumentForPipe],
  imports: [CommonModule],
})
export class AsPureArgumentForModule {}
