import * as _ from 'lodash';
import { Directive } from '@angular/core';
import { BaseService } from '../base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { CommentSenderServiceI } from './comment-sender.service.interface';
import {
  CardBaseModel,
  CardChatModel,
  CardMailModel,
  CardModel,
  CardSharedModel
} from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import {
  CommentBaseModel,
  CommentChatModel,
  CommentMailModel,
  CommentModel
} from '@dta/shared/models-api-loop/comment/comment.model';
import { from, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { ProcessType, StopWatch } from '@dta/shared/utils/stop-watch';
import { ContactBaseModel, GroupModel, UserModel } from '@dta/shared/models-api-loop/contact/contact.model';
import { TagType, User } from '@shared/api/api-loop/models';
import { Logger } from '@shared/services/logger/logger';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';
import { CommentService } from '../comment/comment.service';
import { CardService } from '../card/card.service';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import { ContactService } from '../contact/contact.service';
import {
  DraftDeleted,
  OptimisticResponseState
} from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { TagLabelModel } from '@dta/shared/models-api-loop/tag.model';
import { SharedTagLabelModel } from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { OptimisticResponseHelper } from '@dta/ui/components/common/conversation-list/optimistic-response.helper';

@Directive()
export class CommentSenderService extends BaseService implements CommentSenderServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    protected _sharedUserManagerService: SharedUserManagerService,
    protected _commentService: CommentService,
    protected _cardService: CardService,
    protected _contactService: ContactService,
    protected _conversationService: ConversationService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'CommentSenderService';
  }

  sendDraftChat(forUserEmail: string, comments: CommentChatModel[]): Observable<CommentChatModel[]> {
    throw new Error('Method not implemented.');
  }

  sendMail(forUserEmail: string, comment: CommentMailModel): Observable<CommentMailModel> {
    let _comment: CommentMailModel;
    let _card: CardMailModel;

    return of(undefined).pipe(
      /**
       * Get or create parent
       */
      mergeMap(() => this.getOrCreateCardMail(forUserEmail, comment)),
      tap((card: CardMailModel) => {
        _card = card;
        comment.parent = CardBaseModel.buildAsReference(card);
      }),
      /**
       * Create mail comment
       */
      mergeMap(() => this._commentService.createMail(forUserEmail, comment)),
      tap((createdComment: CommentMailModel) => {
        _comment = createdComment;
      }),
      mergeMap(() => this._cardService.saveAndPublish(forUserEmail, _card)),
      /**
       * Add to push sync queue
       */
      tap(() => {
        this.enqueuePushSynchronization(forUserEmail, _comment);
      }),
      /**
       * Remove draft email comment belongs to
       */
      map(() => _comment)
    );
  }

  sendComments(forUserEmail: string, comments: CommentChatModel[]): Observable<CommentChatModel[]> {
    let commentsToReturn: CommentChatModel[];
    let _card: CardModel;

    let stopWatch = new StopWatch(this.constructorName + '.sendComments', ProcessType.SERVICE, forUserEmail);
    const comment = comments[0];

    return of(undefined).pipe(
      switchMap(() => {
        if (comment.parent) {
          return of(comment.parent);
        }
        return this.getOrCreateCardChat(forUserEmail, comments[0]);
      }),
      tap((card: CardModel) => {
        OptimisticResponseHelper.triggerApplyOptimisticResponse(
          forUserEmail,
          [card.id],
          OptimisticResponseState.SNIPPET_UPDATE,
          { snippet: comments[0].snippet }
        );
      }),
      mergeMap((card: CardModel) => {
        stopWatch.log('buildAsReference');
        _card = card;
        let _parent;
        if (card instanceof CardSharedModel) {
          _parent = CardSharedModel.buildAsReference(card);
        } else {
          _parent = CardBaseModel.buildAsReference(card);
        }

        comments.map(comment => (comment.parent = _parent));

        return from(comments);
      }),
      mergeMap((_comment: CommentChatModel) => {
        stopWatch.log('filterGroupMembersFromShareList');
        return this.filterGroupMembersFromShareList(forUserEmail, _comment);
      }),
      toArray(),
      mergeMap((_comments: CommentChatModel[]) => {
        stopWatch.log('createChat');
        return this._commentService.createComments(forUserEmail, _comments);
      }),
      tap((_comments: CommentChatModel[]) => {
        stopWatch.log('saveAndPublish');
        commentsToReturn = _comments;
        this.enqueuePushSynchronization(forUserEmail, _comments);
      }),
      map(() => {
        stopWatch.log('done');
        return commentsToReturn;
      })
    );
  }

  sendLoopin(
    forUserEmail: string,
    comment: CommentChatModel,
    sourceResourceId?: string,
    labels?: (SharedTagLabelModel | TagLabelModel)[]
  ): Observable<CommentChatModel> {
    return this.createLoopinInLocalState(forUserEmail, comment, sourceResourceId, labels).pipe(
      /**
       * Enqueue to pushSync
       */
      map((_comment: CommentChatModel) => {
        this.enqueuePushSynchronization(forUserEmail, _comment);
        return _comment;
      })
    );
  }

  buildCardShared(comment: CommentChatModel, sourceResource?: CardModel): CardSharedModel {
    let card = new CardSharedModel();
    let shareList = comment.getShareList();

    card.name = comment.name;
    card.shareList = ContactBaseModel.createListOfResources(shareList);
    card.isLive = true;

    if (sourceResource) {
      if (!(sourceResource instanceof CardMailModel)) {
        let msg = `SourceResource(id=${sourceResource.id}, $type=${sourceResource.$type}) of CardShared must be of type CardMail`;
        throw new Error(msg);
      }

      card.name = sourceResource.name;
      card.sourceResource = CardBaseModel.buildAsReference(sourceResource);
    }

    return card;
  }

  resendComment(forUserEmail: string, comment: CommentModel) {
    this.enqueuePushSynchronization(forUserEmail, comment);
  }

  removeComment(forUserEmail: string, comment: CommentModel): Observable<any> {
    return this._commentService.removeComment(forUserEmail, comment).pipe(
      mergeMap(() => {
        let id = comment.parent['_id'];
        return this._cardService.findOrFetchCardById(forUserEmail, id);
      }),
      mergeMap((card: CardModel) => {
        return this._cardService.saveAndPublish(forUserEmail, card);
      })
    );
  }

  createLoopinInLocalState(
    forUserEmail: string,
    comment: CommentChatModel,
    sourceResourceId?: string,
    labels?: (SharedTagLabelModel | TagLabelModel)[]
  ): Observable<CommentChatModel> {
    let _comment: CommentChatModel;
    let _card: CardSharedModel;
    let _sourceResource: CardMailModel;

    let obs = sourceResourceId
      ? this.buildAndLinkCardSharedToSourceResource(forUserEmail, comment, sourceResourceId)
      : of([this.buildCardShared(comment)]);

    return obs.pipe(
      tap((cards: CardModel[]) => {
        _card = <CardSharedModel>cards[0];
        _sourceResource = <CardMailModel>cards[1];

        comment.parent = CardSharedModel.buildAsReference(_card);
      }),
      mergeMap(() => {
        return this.filterGroupMembersFromShareList(forUserEmail, comment);
      }),
      mergeMap((comment: CommentChatModel) => {
        return this._commentService.createLoopin(forUserEmail, comment, sourceResourceId, labels);
      }),
      /**
       * Save & Publish cards
       */
      mergeMap((comment: CommentChatModel) => {
        _comment = comment;

        _.forEach(labels, label => {
          _card.addOrRemoveLabel(label);
        });
        let cards = _.compact([_card, _sourceResource]);
        return this._cardService.saveAllAndPublish(forUserEmail, cards);
      }),
      map(() => {
        return _comment;
      })
    );
  }

  private buildAndLinkCardSharedToSourceResource(
    forUserEmail: string,
    comment: CommentChatModel,
    sourceResourceId: string
  ): Observable<CardModel[]> {
    if (!sourceResourceId) {
      throw new Error('SourceResourceId cannot be nil');
    }

    let card: CardSharedModel;
    let sourceResource: CardMailModel;

    /**
     * Find SourceResource
     */
    return this._cardService.findOrFetchCardById(forUserEmail, sourceResourceId).pipe(
      /**
       * Build CardShared
       */
      map((_sourceResource: CardMailModel) => {
        sourceResource = _sourceResource;
        return this.buildCardShared(comment, _sourceResource);
      }),
      /**
       * Update SourceResource with link to CardShared
       */
      tap((_card: CardSharedModel) => {
        card = _card;

        let copiedCardIds = sourceResource.hasCopiedCardIds()
          ? [...sourceResource.copiedCardIds.resources, card._id]
          : [card._id];
        sourceResource.copiedCardIds = BaseModel.createListOfResources(copiedCardIds);
      }),
      map(() => {
        return [card, sourceResource];
      })
    );
  }

  protected filterGroupMembersFromShareList(
    forUserEmail: string,
    comment: CommentChatModel
  ): Observable<CommentChatModel> {
    let shareList = comment.getShareList();
    if (shareList.length === 1 || comment.parent.$type === CardChatModel.type) {
      return of(comment);
    }

    let group = shareList.find(contact => contact.$type === GroupModel.type);
    if (!group || comment.hasTagId(TagType.SYSTEMMESSAGE)) {
      return of(comment);
    }

    return this._contactService.findById(forUserEmail, group.id).pipe(
      map((group: GroupModel) => {
        return group.getAllMembers();
      }),
      map((members: User[]) => {
        let users = shareList.filter(contact => contact.$type === UserModel.type);
        let nonMembers = _.differenceBy(users, members, 'id');
        let filteredShareList = [group, ...nonMembers];

        comment.shareList = ContactBaseModel.createListOfResources(filteredShareList);
        return comment;
      }),
      catchError(err => {
        Logger.error(err, 'Could not fetch contact');
        return of(comment);
      })
    );
  }

  private getOrCreateCardMail(forUserEmail: string, comment: CommentModel): Observable<CardModel> {
    if (!comment.parent) {
      return of(this.buildCardMail(comment));
    }

    let card = <CardModel>comment.parent;
    return this._cardService.findOrFetchCardById(forUserEmail, card.id || card._id);
  }

  private getOrCreateCardChat(forUserEmail: string, comment: CommentChatModel): Observable<CardChatModel> {
    if (!comment.parent) {
      return of(this.buildCardChat(forUserEmail, comment));
    }

    let card = <CardModel>comment.parent;
    return this._cardService.findById(forUserEmail, card.id || card._id).pipe(
      tap(a => console.log(a)),
      catchError(err => {
        if (err.status !== 404) {
          return throwError(err);
        }

        return of(this.buildCardChat(forUserEmail, comment));
      })
    );
  }

  private buildCardMail(comment: CommentModel): CardMailModel {
    let card = new CardMailModel();
    let shareList = _.unionBy(comment.getShareList(), [comment.author], contact => contact.id);

    card.name = comment.name;
    card.shareList = ContactBaseModel.createListOfResources(shareList);
    card.comments = CommentBaseModel.createListOfResources([comment]);

    return card;
  }

  private buildCardChat(forUserEmail: string, comment: CommentChatModel): CardChatModel {
    let card = new CardChatModel();
    let userId = this._sharedUserManagerService.getUserIdByEmail(forUserEmail);
    let commentShareList = comment.getShareList();
    let contact = commentShareList.find(contact => contact.id !== userId);

    // If current user is the only one on share list then he is the contact
    if (!contact) {
      contact = commentShareList[0];
    }

    // if contact is a group, shareList consists only of that group.
    // If 1-on-1, both me and contact are in shareList.
    let shareList;
    if (commentShareList[0] instanceof GroupModel) {
      shareList = [commentShareList[0]];
    } else {
      shareList = commentShareList;
    }

    card.id = comment.parent?.id;
    card.name = contact.name;
    card.shareList = ContactBaseModel.createListOfResources(shareList);
    card.comments = CommentBaseModel.createListOfResources([comment]);

    return card;
  }
}
