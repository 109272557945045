import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateControlComponent } from '@shared/modules/form-controls/date-control/date-control.component';
import { DatePickerCVAComponent } from '@shared/modules/form-controls/date-control/date-picker/date-picker.component';
import { TextControlModule } from '@shared/modules/form-controls/text-control/text-control.module';
import { ToDateStringPipe } from '@shared/modules/form-controls/date-control/to-date-string.pipe';
import { DatePickerModule } from '@dta/ui/components/date-picker/date-picker.module';
import { OutsideClickModule } from '@dta/ui/directives/outside-click/outside-click.module';

@NgModule({
  declarations: [DateControlComponent, DatePickerCVAComponent, ToDateStringPipe],
  imports: [CommonModule, FormsModule, TextControlModule, DatePickerModule, OutsideClickModule],
  exports: [DateControlComponent]
})
export class DateControlModule {}
