export const COLLAPSE_TAG_CLASSES = {
  loop_quote: 'loop_quote',
  collapse2: 'collapse2',
  gmail_quote: 'gmail_quote',
  missive_quote: 'missive_quote',
  yahoo_quote: 'yahoo_quote',
  outlook_quote: 'outlook_quote',
  apple_quote: 'apple_quote',
  aolmail_quote: 'aolmail_quote',
  hotmail_quote: 'hotmail_quote',
  icloud_quote: 'icloud_quote',
  android_quote: 'android_quote',
  thunderbird_quote: 'thunderbird_quote',
  zoho_quote: 'zoho_quote',
  office365_quote: 'office365_quote',
  yandex_quote: 'yandex_quote',
  protonmail_quote: 'protonmail_quote',
  fastmail_quote: 'fastmail_quote',
  yahoo_quoted_begin: 'yahoo-quoted-begin'
} as const;

export const COLLAPSE_TAG_IDS = {
  appendonsendID: 'appendonsend',
  divRplyFwdMsgID: 'divRplyFwdMsgID',
  divRplyFwdMsg: 'divRplyFwdMsg'
} as const;

export const COLLAPSE_REGEX: RegExp = new RegExp(`\\b(?:${Object.values(COLLAPSE_TAG_CLASSES).join('|')})\\b`, 'g');
