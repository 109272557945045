import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../shared/utils/subscriptions/auto-unsubscribe';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { EmojiPaletteData, EmojiPaletteService } from './emoji-palette/emoji-palette.service';
import { tap } from 'rxjs/operators';
import { KeyboardShortcutsListenerService } from '../../../services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { KeyboardActionModeType } from '../../../../shared/models/keyboard-shortcut.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'emoji-picker',
  templateUrl: './emoji-picker.html',
  styleUrls: ['./emoji-picker.scss'],
})
export class EmojiPickerComponent extends DropdownComponent implements OnInit {
  /////////////////
  // Input, Output
  /////////////////
  @Output() sendEmojiEvent: EventEmitter<any> = new EventEmitter();
  @Output() toggle$: EventEmitter<boolean> = new EventEmitter();
  @Input() emojiSelected: string = undefined;
  @Input() extraClass: string = '';
  @Input() emojiIcon: string = 'emoji';
  @Input() extraIconClass: string = '';
  @Input() positionX: EmojiPosition = EmojiPosition.RIGHT;
  @Input() positionY: EmojiPosition = EmojiPosition.TOP;
  @Input() width: string = '16px';
  @Input() height: string = '16px';

  /////////
  // State
  /////////
  private eventData: EmojiPaletteData;

  /////////////////
  // Subscriptions
  /////////////////
  private emojiSelectSub: Subscription;

  constructor(
    protected _elementRef: ElementRef,
    protected _changeDetection: ChangeDetectorRef,
    protected _keyboardShortcutListenerService: KeyboardShortcutsListenerService,
    private _emojiPaletteService: EmojiPaletteService,
    private renderer: Renderer2,
  ) {
    super(_elementRef, _changeDetection, _keyboardShortcutListenerService);
  }

  ngOnInit() {
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  ////////////////
  // View methods
  ////////////////
  onEmojiIconClick(event: any) {
    if (event) {
      let targetPositions = event.target.getBoundingClientRect();

      // Position emoji palette on top right corner + 12px to top

      let x;
      let y;
      switch (this.positionX) {
        case EmojiPosition.LEFT:
          x = targetPositions.left;
          break;
        case EmojiPosition.RIGHT:
        default:
          x = targetPositions.right;
          break;
      }

      switch (this.positionY) {
        case EmojiPosition.BOTTOM:
          y = targetPositions.y + 12;
          break;
        case EmojiPosition.TOP:
        default:
          y = targetPositions.y - 12;
          break;
      }

      this.eventData = {
        x: x,
        y: y,
        translate: this.positionY === EmojiPosition.TOP,
      };

      event.stopPropagation();
    }

    this.toggleDropdown();
  }

  onDropdownOpen() {
    this.onFocus();
    this.toggle$.emit(true);
    this._emojiPaletteService.showPalette(this.eventData);
    this.additionalDropdownOpenTarget = this.renderer.selectRootElement('.emoji-palette', true);

    this.subscribeToEmojiSelect();
  }

  onDropdownClosed() {
    this.onBlur();
    this.toggle$.emit(false);
    this._emojiPaletteService.hidePalette();
    if (this.emojiSelectSub) {
      this.emojiSelectSub.unsubscribe();
    }
  }

  ///////////////////
  // Private helpers
  ///////////////////
  private handleEmoji(emoji: string) {
    this.sendEmojiEvent.emit(emoji);
  }

  private subscribeToEmojiSelect() {
    if (this.emojiSelectSub) {
      this.emojiSelectSub.unsubscribe();
    }
    this.emojiSelectSub = this._emojiPaletteService.selectEmoji$
      .pipe(
        tap((emoji: string) => {
          this.closeDropdown();
          this.handleEmoji(emoji);
        }),
      )
      .subscribe();
  }

  private onFocus() {
    this._keyboardShortcutListenerService.setMode(KeyboardActionModeType.TEXT_SECONDARY);
  }

  private onBlur() {
    this._keyboardShortcutListenerService.goToPreviousMode(KeyboardActionModeType.TEXT_SECONDARY);
  }
}

export enum EmojiPosition {
  LEFT = 'Left',
  RIGHT = 'Right',
  TOP = 'Top',
  BOTTOM = 'Bottom',
}
