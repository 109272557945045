import {
  ActionEnum,
  CardAppointment,
  CardBase,
  CardDraft,
  CardType,
  CommentBase,
  CommentCount,
  ContactBase,
  ContextEnum,
  ContextType,
  Conversation,
  ConversationAttachment,
  DraftType,
  FilterEnum,
  Group,
  ListOfResourcesOfContactBase,
  SharedTagClassification,
  SharedTagFolder,
  SharedTagLabel,
  SharedTagSystemNames,
  ShowInViewObject,
  Snippet,
  SnippetType,
  Tag,
  TagLabel,
  TagType,
  UnreadCountObject,
  ViewEnum,
  ViewExtraData,
  ViewExtraDataType
} from '@shared/api/api-loop/models';
import { ConversationHelper } from '@shared/services/data/collection/conversation-collection/conversation-helper';
import * as _ from 'lodash';
import { ConversationCollectionParams } from '@dta/shared/models/collection.model';
import { SharedUserManagerService } from '@dta/shared/services/shared-user-manager/shared-user-manager.service';
import {
  ConversationActionChange,
  ConversationChangeModel,
  REMOVE_CLASSIFICATION
} from '@dta/shared/models-api-loop/conversation-card/conversation/conversation-change.model';
import { TagLabelModel, TimedTagModel } from '@dta/shared/models-api-loop/tag.model';
import { OptimisticResponseHelper } from '@dta/ui/components/common/conversation-list/optimistic-response.helper';
import { CardBaseModel, ChannelTagData } from '../card/card.model';
import { ConversationCardBaseModel } from '../conversation-card.model';
import { ContactBaseModel, ContactModel, GroupModel } from '../../contact/contact.model';
import { CommentDraftModel, CommentSnippet } from '../../comment/comment.model';
import {
  SharedTagFolderModel,
  SharedTagLabelModel,
  SharedTagSystemModel,
  StaticSharedTagIds
} from '../../shared-tag/shared-tag.model';
import { BaseModel } from '@dta/shared/models-api-loop/base/base.model';

const SNOOZE_TAGS: string[] = [TagType.SNOOZE, TagType.SNOOZE_DUE, TagType.SNOOZE_INTERRUPT];
interface ConversationFromAction {
  action: ActionEnum;
  folder?: SharedTagFolder;
  context?: ContextType;
  addTags?: (SharedTagLabel | TagLabel | SharedTagClassification)[];
  removeTags?: (SharedTagLabel | TagLabel | SharedTagClassification)[];
}

export class ConversationModel extends ConversationCardBaseModel implements Conversation {
  static type: string = 'Conversation';
  readonly $type: string = ConversationModel.type;

  // Meta data
  cardId?: string;
  cardType?: CardType;
  snapshotCard?: CardBase;
  privateDraft?: CardDraft;
  sharedDraft?: CardDraft;
  replyToCard?: CardBase;

  // Flags and indicators
  attachmentCount?: number;
  isStarred?: boolean;
  isUnread?: boolean;
  hasAccessToLoop?: boolean;
  snippet?: Snippet;
  unreadCount?: UnreadCountObject;
  unreadPositionOffset?: number;
  newestUnreadPositionOffset?: number;
  showInViews?: ShowInViewObject[];
  commentCount?: CommentCount;
  draftTypes?: DraftType[];
  lastComment?: CommentBase;
  lastMailComment?: CommentBase;
  isSubscribed?: boolean;

  // Dates
  viewSortDate?: string;
  modifiedDate?: string;

  // Users
  threadParticipants?: ListOfResourcesOfContactBase;
  toList?: ListOfResourcesOfContactBase;
  importedByContact?: ContactBase;

  // View data
  viewExtraData?: ViewExtraData[];

  appointmentLink: CardAppointment;
  attachments: ConversationAttachment[];

  channelType?: string;

  // Additional brisket fields
  _isDeleted?: boolean;
  _isArchived?: boolean;
  _isSharedArchived?: boolean;
  _isSharedDeleted?: boolean;
  _syncedComments?: boolean;

  //////////////////////
  // Search extra data
  //////////////////////
  fitsSearchQuery: string;

  constructor(conversation?: Conversation) {
    super(conversation);

    this.id = conversation.cardId;

    this._isArchived = this.showInViews?.some(showInView => showInView.filter === FilterEnum.ARCHIVED);
    this._isDeleted = this.showInViews?.some(showInView => showInView.filter === FilterEnum.DELETED);

    this._isSharedDeleted = this.hasSharedTagId(StaticSharedTagIds.DELETED_ID);
    this._isSharedArchived = this.hasSharedTagId(StaticSharedTagIds.ARCHIVED_ID);
  }

  static create(doc: Conversation): ConversationModel {
    return new ConversationModel(doc);
  }

  static createList(conversations: Conversation[]): ConversationModel[] {
    return _.map(conversations, (conversation: Conversation) => new ConversationModel(conversation));
  }

  get isGroupCard(): boolean {
    return ConversationModel.isGroupCard(this.getShareList());
  }

  get isLastCommentChat(): boolean {
    return this.snippet.snippetType === SnippetType.CHAT;
  }

  get hasFailedToSendComments(): boolean {
    return this._ex?.hasFailedToSendComments;
  }

  get hasUnsentComments(): boolean {
    return this._ex?.hasUnsentComments;
  }

  get isDraftConversation(): boolean {
    return this.cardType === CardType.CARD_DRAFT;
  }

  removeAllSnoozeTagsOnCard(): void {
    this.setTags(_.filter(this.getTags(), (tag: Tag) => !SNOOZE_TAGS.includes(tag.id)));
  }

  setSnoozeTag(dueDateUTC: string): void {
    this.setTags([...this.getTags(), TimedTagModel.buildSnoozeTag(dueDateUTC)]);
  }

  markAsNotSpam(): void {
    this.showInViews = this.showInViews.map(showInView => {
      if (showInView.filter === FilterEnum.SPAM) {
        return {
          ...showInView,
          filter: FilterEnum.INBOX
        };
      }

      return showInView;
    });
  }

  /////////////////////////
  // SHOULD SHOW IN VIEW
  /////////////////////////
  hasFilterOrFolder(filter?: FilterEnum, folderId?: string): boolean {
    return this.showInViews.some(showInView => showInView.filter === filter || showInView.folderId === folderId);
  }

  removeShowInView(mainView: ViewEnum, filter?: FilterEnum): void {
    this.showInViews = this.showInViews.filter(showInView => {
      return showInView.view !== mainView && showInView.filter !== filter;
    });
  }

  isArchived(): boolean {
    return this.showInViews?.some(showInView => showInView.filter === FilterEnum.ARCHIVED);
  }

  isDeleted(): boolean {
    return this.showInViews?.some(showInView => showInView.filter === FilterEnum.DELETED);
  }

  get emailAuthorUsers(): ContactBase[] {
    return this.threadParticipants?.resources?.slice().reverse() || [];
  }

  get emailSharelistUsers(): ContactBase[] {
    return this.toList?.resources || [];
  }

  getFolders(): SharedTagFolderModel[] {
    return this.sharedTags.tags.resources.filter(
      tag => tag.$type === SharedTagFolderModel.type
    ) as SharedTagFolderModel[];
  }

  shouldShowIn(mainView: ViewEnum): boolean {
    return _.some(this.showInViews, (view: ShowInViewObject) => view.view === mainView);
  }

  shouldShowInSubView(primaryView: ViewEnum, filter: FilterEnum, folderId?: string): boolean {
    return _.some(this.showInViews, (view: ShowInViewObject) => {
      return folderId
        ? view.view === primaryView && view.folderId === folderId
        : view.view === primaryView && view.filter === filter;
    });
  }

  shouldShowInChannels(): string[] {
    let channelViews = _.filter(this.showInViews, (view: ShowInViewObject) => view.view === ViewEnum.CHANNEL);

    return _.map(channelViews, (view: ShowInViewObject) => view.channelId);
  }

  shouldShowInFolders(): string[] {
    let supportedPersonalInboxViews = _.filter(
      this.showInViews,
      (view: ShowInViewObject) => view.view === ViewEnum.PERSONAL_INBOX
    );

    return _.map(supportedPersonalInboxViews, (view: ShowInViewObject) => view.folderId || view.filter);
  }

  shouldShowInFolder(boardId: string): boolean {
    let shouldShowInFolders = this.shouldShowInFolders();

    // If boardId is one of the defaultFolders -> convert it
    boardId = ConversationHelper.genericBoardIdToFilter(boardId) || boardId;

    return shouldShowInFolders.includes(boardId);
  }

  moveTo(folder: SharedTagFolder, context: ContextType): void {
    let filter = this.getFilterOrFolderFromContext(context);

    this.setExclusiveSharedTags(folder, false, [filter.toString(), folder.id], 'add');
  }

  getFilterOrFolderFromContext(context: ContextType): string {
    if (context.context === ContextEnum.FOLDER) {
      return context.folderId;
    }
    switch (context.context) {
      case ContextEnum.INBOX:
        return FilterEnum.INBOX;
      case ContextEnum.ARCHIVE:
        return FilterEnum.ARCHIVED;
      case ContextEnum.DELETE:
        return FilterEnum.DELETED;
      default:
        return FilterEnum.INBOX;
    }
  }

  isAllSharedInboxesCard(_sharedInboxIds: string[]): boolean {
    return false; // todo
  }

  isMyLoopInboxCard(sentView: boolean = false): boolean {
    if (this.hasAccessToLoop && !sentView) {
      return false;
    }

    return this.shouldShowIn(ViewEnum.LOOP_INBOX);
  }

  isPersonalInboxCard(_currentUserId: string): boolean {
    return this.shouldShowIn(ViewEnum.PERSONAL_INBOX);
  }

  isChannelCard(channelContactId: string, _isCurrentUserChannel: boolean): boolean {
    if (this.hasAccessToLoop) {
      return false;
    }

    return this.shouldShowIn(ViewEnum.CHANNEL) && this.shouldShowInChannels().includes(channelContactId);
  }

  showCardForView(params: ConversationCollectionParams): boolean {
    return this.shouldShowInSubView(params.showInView.view, params.showInView.filter, params.showInView.folderId);
  }

  ///////////
  // DRAFTS
  ///////////
  hasDraft(): boolean {
    return !_.isEmpty(this.draftTypes) || this.cardType === CardType.CARD_DRAFT;
  }

  ///////////////
  // SHARE LIST
  ///////////////
  getContactForChannelTag(): ContactModel {
    const group: GroupModel = this.getShareList().find(
      (contact: ContactBase) => contact.$type === GroupModel.type
    ) as GroupModel;
    return group;
  }

  constructChannelTag(forUserEmail: string, sharedUserManagerService: SharedUserManagerService): ChannelTagData {
    let contact = this.getContactForChannelTag();

    if (!contact) {
      contact = sharedUserManagerService.getUserByEmail(forUserEmail);
    }

    let tagData = {
      displayName: contact.$type === GroupModel.type ? contact.name : contact.email,
      contactId: contact.id
    } as ChannelTagData;

    return tagData;
  }

  /////////////
  // CONTACTS
  /////////////
  getAllContacts(): ContactBase[] {
    let contacts = [
      ...this.getShareList(),
      ...this.getResources(this.threadParticipants),
      ...this.getResources(this.toList)
    ];

    if (this.snippet.sender && !Array.isArray(this.snippet.sender)) {
      contacts.push(this.snippet.sender);
    }

    return ContactBaseModel.getUniqueContactsWithHighestRevision(contacts);
  }

  populateWithContacts(contacts: ContactBase[]): void {
    for (let prop in this) {
      switch (prop) {
        case 'snippet':
          let contact = _.find(contacts, c => c.id === this.snippet?.sender?.id);

          if (contact) {
            this.snippet.sender = contact;
          }
          break;
        case 'shareList':
        case 'threadParticipants':
        case 'toList':
          let populatedList = _.map(this[prop]['resources'], contact => {
            // If contact is not synced, return current
            if (!contact.id) {
              return contact;
            }

            let fullContact = {
              ..._.find(contacts, c => c.id === contact.id)
            };

            // Reduce contact and keep only static fields
            if (!_.isEmpty(fullContact)) {
              ContactBaseModel.toReducedForm(contact);
            }

            // Merge existing static properties and latest contact
            return { ...fullContact, ...contact };
          });
          this[prop]['resources'] = populatedList;
          break;
        default:
          break;
      }
    }
  }

  /////////////
  // UNSORTED
  /////////////
  changeGroupOnShareList(group: GroupModel): void {
    const index = _.findIndex(this.shareList.resources, contact => contact.$type === GroupModel.type);
    if (index === -1) {
      this.shareList.resources.splice(index, 1, group);
    }
  }

  moveToTeam(group: GroupModel): void {
    this.showInViews = _.filter(this.showInViews, showInView => !showInView.channelId);
    this.showInViews.push({
      view: ViewEnum.CHANNEL,
      filter: FilterEnum.INBOX,
      channelId: group.id
    });

    this.changeGroupOnShareList(group);
  }

  updateConversationFromAction({ action, folder, context, addTags, removeTags }: ConversationFromAction): void {
    let removeFilters: string[] = [];
    let addFilter: string;

    switch (action) {
      case ActionEnum.MARK_AS_READ:
        this.isUnread = false;
        break;
      case ActionEnum.MARK_AS_UNREAD:
        this.isUnread = true;
        break;
      case ActionEnum.MARK_AS_STARRED:
        addFilter = FilterEnum.STARRED;
        this.isStarred = true;
        break;
      case ActionEnum.MARK_AS_UNSTARRED:
        removeFilters.push(FilterEnum.STARRED);
        this.isStarred = false;
        break;
      case ActionEnum.DELETE:
        addFilter = FilterEnum.DELETED;
        removeFilters.push(this.getFilterOrFolderFromContext(context));
        this._isDeleted = true;
        break;
      case ActionEnum.ARCHIVE:
        addFilter = FilterEnum.ARCHIVED;
        removeFilters.push(this.getFilterOrFolderFromContext(context));
        this._isArchived = true;
        break;
      case ActionEnum.MARK_AS_JUNK:
        removeFilters.push(this.getFilterOrFolderFromContext(context));
        break;
      case ActionEnum.FOLLOW:
        this.isSubscribed = true;
        break;
      case ActionEnum.UN_FOLLOW:
        this.isSubscribed = false;
        break;
      case ActionEnum.MOVE_TO_INBOX:
        addFilter = FilterEnum.INBOX;
        this.moveTo(SharedTagSystemModel.buildSystemSharedTag(SharedTagSystemNames.INBOX), context);
        removeFilters.push(this.getFilterOrFolderFromContext(context));

        this._isSharedArchived = false;
        this._isSharedDeleted = false;
        this._isArchived = false;
        this._isDeleted = false;
        break;
      case ActionEnum.MOVE_TO_FOLDER:
        this.moveTo(folder, context);
        removeFilters.push(this.getFilterOrFolderFromContext(context));
        addFilter = folder.id;
        break;
      case ActionEnum.CHANGE_LABELS:
      case ActionEnum.CHANGE_PRIVATE_LABELS:
        if (addTags?.length) {
          addTags.forEach(tag => {
            this.addOrRemoveLabel(tag, 'add');
          });
        } else {
          removeTags?.forEach(tag => {
            this.addOrRemoveLabel(tag, 'remove');
          });
        }
        break;
      case ActionEnum.CHANGE_CLASSIFICATION:
        if (addTags?.length) {
          this.addClassificationTag(addTags?.[0]);
        } else if (removeTags?.length) {
          this.removeClassificationTag();
        }
        break;
    }

    if (action !== ActionEnum.MARK_AS_READ) {
      this.showInViews = OptimisticResponseHelper.modifyShowInViewsObject(
        this.showInViews,
        removeFilters,
        addFilter,
        action === ActionEnum.MOVE_TO_FOLDER
      );
    }
  }

  applyLocalChanges(changes: ConversationChangeModel[]): string[] {
    let changesToRemove = [];

    _.forEach(changes, change => {
      if (change.classificationChange) {
        if (change.classificationChange === REMOVE_CLASSIFICATION) {
          this.removeClassificationTag();
        } else {
          this.addClassificationTag(change.classificationChange);
        }
      }

      if (change.labelChanges) {
        _.forEach(change.labelChanges, labelChange => {
          _.forEach(labelChange.tags, label => {
            // Label tag is already added
            if (this.labelChangeAlreadyApplied(label, labelChange.action)) {
              changesToRemove.push(change.id);
            } else {
              this.addOrRemoveLabel(label, labelChange.action);
            }
          });
        });
      }

      if (change.conversationActions) {
        _.forEach(change.conversationActions, action => {
          if (this.actionAlreadyApplied(action)) {
            changesToRemove.push(change.id);
          } else {
            this.updateConversationFromAction({
              action: action.action,
              folder: action.folder,
              context: action.context
            });
          }
        });
      }
    });

    return changesToRemove;
  }

  private actionAlreadyApplied(action: ConversationActionChange): boolean {
    switch (action.action) {
      case ActionEnum.MARK_AS_READ:
        return !this.isUnread;
      case ActionEnum.MARK_AS_UNREAD:
        return this.isUnread;
      case ActionEnum.MARK_AS_STARRED:
        return this.isStarred;
      case ActionEnum.MARK_AS_UNSTARRED:
        return !this.isStarred;
      case ActionEnum.DELETE:
        return this.hasFilterOrFolder(FilterEnum.DELETED);
      case ActionEnum.ARCHIVE:
        return this.hasFilterOrFolder(FilterEnum.ARCHIVED);
      case ActionEnum.MOVE_TO_INBOX:
        return this.hasFilterOrFolder(FilterEnum.INBOX);
      case ActionEnum.FOLLOW:
        return this.isSubscribed;
      case ActionEnum.UN_FOLLOW:
        return !this.isSubscribed;
      case ActionEnum.MOVE_TO_FOLDER:
        return this.hasFilterOrFolder(undefined, action.folder.id);
    }
    return false;
  }

  private labelChangeAlreadyApplied(label: SharedTagLabel | TagLabel, action: 'add' | 'remove'): boolean {
    if (label.$type === SharedTagLabelModel.type) {
      if (this.hasSharedTagId(label.id) && action === 'add') {
        return true;
      }

      if (!this.hasSharedTagId(label.id) && action === 'remove') {
        return true;
      }
    } else if (label.$type === TagLabelModel.type) {
      if (this.hasTagId(label.id) && action === 'add') {
        return true;
      }

      if (!this.hasTagId(label.id) && action === 'remove') {
        return true;
      }
    }

    return false;
  }

  isDraftOnLocalCard(): boolean {
    return false; // todo
  }

  getSearchableContent(): string[] {
    return []; // todo
  }

  /////////////////////////
  // VIEW AND COMMENT DATA
  /////////////////////////
  getViewDate(): string {
    return this.viewSortDate;
  }

  hasCommentTagId(id: string): boolean {
    return this.hasTagId(id);
  }

  getComments(): CommentBase[] {
    return []; // <- needed for outbox but should be removed when rewriter
  }

  get countOfComments(): number {
    return this.commentCount?.totalCount || 0;
  }

  private getExtraViewData(view: ViewExtraDataType): ViewExtraData {
    return _.find(this.viewExtraData || [], (viewExtraData: ViewExtraData) => viewExtraData.viewExtraType === view);
  }

  get lastSentCommentCreated(): string {
    let viewData = this.getExtraViewData(ViewExtraDataType.SENT);

    return viewData ? viewData.date : this.viewSortDate;
  }

  get lastCommentSnippet(): CommentSnippet {
    return {
      _id: undefined,
      created: this.viewSortDate,
      author: this.snippet?.sender,
      snippet: this.snippet?.content,
      isArchived: false
    } as CommentSnippet;
  }

  get lastSentCommentSnippet(): CommentSnippet {
    let viewData = this.getExtraViewData(ViewExtraDataType.SENT);

    return viewData
      ? ({
          _id: undefined,
          created: viewData.date,
          author: viewData.snippet?.sender,
          snippet: viewData.snippet?.content,
          isArchived: false
        } as CommentSnippet)
      : this.lastCommentSnippet;
  }

  getOtherContact(forUserId: string): ContactModel {
    return this.findOtherContact(forUserId, this.getShareList());
  }

  doesMatchTimeQuery(conversation: Conversation, params: ConversationCollectionParams): boolean {
    if (_.isEmpty(params.fromDate) && _.isEmpty(params.toDate)) {
      return true;
    }

    let sortDate = new Date(this.viewSortDate);

    return sortDate <= params.requestDate;
  }

  isSharedWithOthers(forUserId: string): boolean {
    return _.filter(this.getShareList(), (contact: ContactBase) => contact.id !== forUserId)?.length > 0;
  }

  findGroupInShareList(): Group {
    return ConversationCardBaseModel.findGroupInShareList(this.getShareList());
  }

  //////////////////////////
  // PRIVATE CLASS HELPERS
  //////////////////////////
  private findOtherContact(forUserId: string, shareList: ContactBase[]): ContactModel {
    let group = shareList.find((_contact: ContactBase) => _contact.$type === GroupModel.type);

    if (group) {
      return ContactBaseModel.create(group);
    }

    let otherUser =
      shareList.find((_contact: ContactBase) => (<ContactModel>_contact).id !== forUserId) || shareList[0];

    return otherUser ? ContactBaseModel.create(otherUser) : undefined;
  }
}

export function isConversationModel(conversation: BaseModel): conversation is ConversationModel {
  return conversation.$type === ConversationModel.type;
}
