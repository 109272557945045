import { Observable } from 'rxjs';
import {
  SharedTagFolderModel,
  SharedTagLabelModel,
  SharedTagModel,
  SharedTagStatusModel,
} from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { SharedTagBase, SharedTagFolder, TagRights } from '@shared/api/api-loop/models';
import { BaseDaoService } from '../base/base-dao.service';

export interface SharedTagDaoServiceI {
  findLabels(forUserEmail: string, contextId?: string, minimumRight?: TagRights): Observable<SharedTagLabelModel[]>;
  findStatusSharedTags(forUserEmail: string): Observable<SharedTagStatusModel[]>;
  findLocalSharedTagIds(forUserEmail: string, ids: string[]): Observable<SharedTagModel[]>;
  updateRightsOfLabelsForWorkspace(
    forUserEmail: string,
    workspaceId: string,
    rights: TagRights,
  ): Observable<SharedTagModel[]>;
  findAllFolders(forUserEmail: string): Observable<SharedTagFolderModel[]>;
  findFoldersForContext(forUserEmail: string, contextId: string): Observable<SharedTagFolderModel[]>;
}

export abstract class SharedTagDaoService extends BaseDaoService<SharedTagModel, SharedTagBase> {
  abstract findLabels(
    forUserEmail: string,
    contextId?: string,
    minimumRight?: TagRights,
  ): Observable<SharedTagLabelModel[]>;
  abstract findStatusSharedTags(forUserEmail: string): Observable<SharedTagStatusModel[]>;
  abstract findLocalSharedTagIds(forUserEmail: string, ids: string[]): Observable<SharedTagModel[]>;
  abstract updateRightsOfLabelsForWorkspace(
    forUserEmail: string,
    workspaceId: string,
    rights: TagRights,
  ): Observable<SharedTagModel[]>;
  abstract findAllFolders(forUserEmail: string): Observable<SharedTagFolderModel[]>;
  abstract findFoldersForContext(forUserEmail: string, contextId: string): Observable<SharedTagFolderModel[]>;
}
