import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ContextMenuService {
  ////////////
  // Subjects
  ////////////
  private showContextMenuSubject: Subject<ContextMenuData> = new Subject<ContextMenuData>();
  private closeContextMenuSubject: Subject<void> = new Subject<void>();
  private onOptionClickSubject: Subject<ContextOption> = new Subject<ContextOption>();

  constructor() {}

  get showMenu$(): Observable<ContextMenuData> {
    return this.showContextMenuSubject.asObservable();
  }

  get closeMenu$(): Observable<void> {
    return this.closeContextMenuSubject.asObservable();
  }

  get optionClick$(): Observable<ContextOption> {
    return this.onOptionClickSubject.asObservable();
  }

  showMenu(data: ContextMenuData) {
    this.showContextMenuSubject.next(data);
  }

  closeMenu() {
    this.closeContextMenuSubject.next();
  }

  onOptionClick(option: ContextOption) {
    this.onOptionClickSubject.next(option);
  }
}

export interface ContextMenuData {
  x: number;
  y: number;
  context: ContextOptions;
}

export interface ContextOptions {
  options: ContextOption[];
}

export interface ContextOption {
  option: string;
  subsection?: ContextOptions;
  selected?: boolean;
}
