import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

const LENGTH_BEFORE_EXTENSION: number = 3 as const;

@Component({
  selector: 'loop-file-name',
  templateUrl: './file-name.component.html',
  styleUrls: ['./file-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileNameComponent implements OnDestroy {
  private readonly fileName$: ReplaySubject<string> = new ReplaySubject<string>(1);
  @Input() set fileName(fileName: string) {
    this.fileName$.next(fileName);
  }

  protected readonly fileNameWithoutExtension$: Observable<string> = this.fileName$.pipe(
    map(fileName => {
      const dotIndex = fileName.lastIndexOf('.') - LENGTH_BEFORE_EXTENSION;
      return dotIndex > -1 ? fileName.substring(0, dotIndex) : fileName;
    })
  );

  protected readonly fileExtension$: Observable<string> = this.fileName$.pipe(
    map(fileName => {
      const dotIndex = fileName.lastIndexOf('.') - LENGTH_BEFORE_EXTENSION;
      return dotIndex > -1 ? fileName.substring(dotIndex) : '';
    })
  );

  ngOnDestroy(): void {
    this.fileName$.complete();
  }
}
