import * as _ from 'lodash';
import { EventSharedTagsUpdated, EventTagsUpdated } from '@shared/api/api-loop/models';

export enum SupportedCacheTypes {
  TAGS,
  SHARED_TAGS,
}

export type SupportedCacheEventTypes = EventTagsUpdated | EventSharedTagsUpdated;

export class EventCache {
  private tagsCache: { [parentId: string]: EventTagsUpdated } = {};
  private sharedTagsCache: { [parentId: string]: EventSharedTagsUpdated } = {};

  constructor() {}

  isCacheEmpty(cacheType: SupportedCacheTypes): boolean {
    return _.isEmpty(this.getCacheByType(cacheType));
  }

  clearAll() {
    this.tagsCache = {};
    this.sharedTagsCache = {};
  }

  cacheEvent(cacheType: SupportedCacheTypes, key: string, value: SupportedCacheEventTypes) {
    this.getCacheByType(cacheType)[key] = value;
  }

  getEvent(cacheType: SupportedCacheTypes, key: string): EventTagsUpdated | EventSharedTagsUpdated {
    return this.getCacheByType(cacheType)[key];
  }

  private getCacheByType(cacheType: SupportedCacheTypes): { [parentId: string]: SupportedCacheEventTypes } {
    switch (cacheType) {
      case SupportedCacheTypes.TAGS:
        return this.tagsCache;
      case SupportedCacheTypes.SHARED_TAGS:
        return this.sharedTagsCache;
      default:
        throw new Error('Unsupported cache type: ' + cacheType);
    }
  }
}
