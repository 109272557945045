/* tslint:disable */

/**
 * Thumbnail size.
 */
export type ThumbnailSize = 'Small' | 'Medium';

export module ThumbnailSize {
  export const SMALL: ThumbnailSize = 'Small';
  export const MEDIUM: ThumbnailSize = 'Medium';

  export function values(): ThumbnailSize[] {
    return [SMALL, MEDIUM];
  }
}
