import * as _ from 'lodash';
import { PublisherService } from '@dta/shared/services/publisher/publisher.service';
import { merge, Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  UserAvailabilityStatusSubscriberEvent,
  UserAvailabilityStatusSubscriberServiceI,
} from '@shared/services/availability-status-subscriber/user-availability-status-subscriber/user-availability-status-subscriber.interface';
import { PublishEventType } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { UserAvailabilityStatusModel } from '@dta/shared/models-api-loop/user-availability.model';
import { UserAvailabilityStatusCollectionService } from '@dta/ui/collections/user-availability-status/user-availability-status.collection';
import { v1 } from 'uuid';
import { ObserverResponse } from '@dta/ui/collections/collection-subscriber.service';

@Injectable()
export class UserAvailabilityStatusSubscriberService implements UserAvailabilityStatusSubscriberServiceI {
  constructor(private _userAvailabilityStatusCollectionService: UserAvailabilityStatusCollectionService) {}

  static processUserAvailabilityStatusSubscriberEvent(
    event: UserAvailabilityStatusSubscriberEvent,
    currentListOfUserStatuses: UserAvailabilityStatusModel[],
  ) {
    if (event.eventType === 'removed') {
      return UserAvailabilityStatusSubscriberService.processUserAvailabilityStatusRemoveEvent(
        event.userAvailabilityStatuses,
        currentListOfUserStatuses,
      );
    }

    return UserAvailabilityStatusSubscriberService.processUserAvailabilityStatusUpdateEvent(
      event.userAvailabilityStatuses,
      currentListOfUserStatuses,
    );
  }

  static processUserAvailabilityStatusRemoveEvent(
    removedUserStatuses: UserAvailabilityStatusModel[],
    currentListOfStatuses: UserAvailabilityStatusModel[],
  ): UserAvailabilityStatusModel[] {
    let removedStatusesIds = _.map(removedUserStatuses, status => status.userId);
    return currentListOfStatuses.filter(
      (item: UserAvailabilityStatusModel) => !removedStatusesIds.includes(item.userId),
    );
  }

  static processUserAvailabilityStatusUpdateEvent(
    updatedAvailabilityStatuses: UserAvailabilityStatusModel[],
    currentListOfStatuses: UserAvailabilityStatusModel[],
  ) {
    return UserAvailabilityStatusModel.mergeList(updatedAvailabilityStatuses, currentListOfStatuses);
  }

  subscribeToUserAvailabilityStatuses(
    forUserEmail: string,
    userIds: string[],
    emitIfEmpty?: boolean,
  ): Observable<UserAvailabilityStatusSubscriberEvent> {
    return merge(
      this.subscribeToRemoveEvents(forUserEmail),
      this.subscribeToUserAvailabilityStatusUpdates(forUserEmail, userIds, emitIfEmpty),
    );
  }

  private subscribeToRemoveEvents(forUserEmail: string): Observable<UserAvailabilityStatusSubscriberEvent> {
    return PublisherService.getModelsForUserAndModelType(
      forUserEmail,
      [UserAvailabilityStatusModel],
      PublishEventType.Remove,
    ).pipe(
      map((userAvailabilityStatuses: UserAvailabilityStatusModel[]) => {
        return {
          eventType: 'removed',
          userAvailabilityStatuses: userAvailabilityStatuses || [new UserAvailabilityStatusModel()],
        } as UserAvailabilityStatusSubscriberEvent;
      }),
    );
  }

  private subscribeToUserAvailabilityStatusUpdates(
    forUserEmail: string,
    userIds: string[],
    emitIfEmpty?: boolean,
  ): Observable<UserAvailabilityStatusSubscriberEvent> {
    return this._userAvailabilityStatusCollectionService
      .registerCollection(
        {
          offset: -1,
          size: -1,
          userIds: userIds,
          sessionId: v1(),
        },
        undefined,
        forUserEmail,
      )
      .pipe(
        mergeMap((collectionKey: string) => {
          return this._userAvailabilityStatusCollectionService.registerObserver(collectionKey);
        }),
        debounceTime(100),
        map((response: ObserverResponse<UserAvailabilityStatusModel>) => {
          return response.models;
        }),
        filter((statuses: UserAvailabilityStatusModel[]) => {
          return emitIfEmpty || !_.isEmpty(statuses);
        }),
        map((statuses: UserAvailabilityStatusModel[]) => {
          return { eventType: 'update', userAvailabilityStatuses: statuses || [new UserAvailabilityStatusModel()] };
        }),
      );
  }
}
