import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { createEssentialThemeStyles } from '@shared/modules/color-scheme/common/helpers/create-essential-theme-styles';
import { Observable, of } from 'rxjs';

@Injectable()
export class AppLoadService {
  constructor(@Inject(DOCUMENT) private readonly domDocument: Document) {}

  initApp(): Observable<any> {
    this.applyClubStyles();
    return of(undefined);
  }

  private applyClubStyles(): void {
    const style = this.domDocument.createElement('style');
    style.innerHTML = createEssentialThemeStyles();
    this.domDocument.head.appendChild(style);
  }
}
